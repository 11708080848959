////////////////////////////////////////////////////////////
//     							                          //
//  Program: LocationType.jsx                            //
//  Application: Property                                   //
//  Option: to view LocationType data                   //
//  Developer: Ashish Kumar  			                 //
//  Date: 2022-01-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";

import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import LocationTypeService from "../../services/LocationTypeService";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import NoData from "../common/NoData";

export class LocationType extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for Location type
      addLocationTypeModal: false,
      editLocationTypeModal: false,
      deleteLocationTypeModal: false,
      locationTypeList: [],
      locationTypeListTotal: 0,
      location_type_name: "",
      sort_order: "",
    };
    this.changeHandler = this.changeHandler.bind(this);

    /*for location type */
    this.openAddLocationTypeModal = this.openAddLocationTypeModal.bind(this);
    this.openEditLocationTypeModal = this.openEditLocationTypeModal.bind(this);
    this.openDeleteLocationTypeModal =
      this.openDeleteLocationTypeModal.bind(this);
    this.saveLocationType = this.saveLocationType.bind(this);
    this.getLocationTypeDetails = this.getLocationTypeDetails.bind(this);
    this.updateLocationType = this.updateLocationType.bind(this);
    this.deleteLocationType = this.deleteLocationType.bind(this);
  }

  componentDidMount() {
    this.getLocationTypeData();
  }

  /* to get Location type data list */
  async getLocationTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var locationTypeList = [];
    var locationTypeListTotal = 0;

    let res = await LocationTypeService.getLocationType(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      locationTypeList = res.data.data ? res.data.data : [];
      locationTypeListTotal = locationTypeList ? locationTypeList.length : 0;
    }
    this.setState({
      locationTypeList: locationTypeList,
      locationTypeListTotal: locationTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save Location type data */
  saveLocationType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.location_type_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        location_type_name: this.state.location_type_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await LocationTypeService.createLocationType(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddLocationTypeModal();
        this.setState({
          location_type_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getLocationTypeData();
      } else {
        //  let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.result.errorDetail[0].errorMessage
        //     ? res.data.result.errorDetail[0].errorMessage
        //     : "Error!",
        //   alertModalMessage:
        //     alertMessage !== "" ? alertMessage : res.data.errorMessage,
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get Location type details data */
  async getLocationTypeDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await LocationTypeService.getLocationTypeBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.location_type_name = res.data.location_type_name
          ? res.data.location_type_name
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update Location type data */
  updateLocationType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.location_type_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        location_type_name: this.state.location_type_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await LocationTypeService.updateLocationType(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditLocationTypeModal();
        this.setState({
          location_type_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getLocationTypeData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.result.errorDetail[0].errorMessage
        //     ? res.data.result.errorDetail[0].errorMessage
        //     : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete Location type data */
  async deleteLocationType(slug) {
    this.setState({ showSpinner: true });
    let res = await LocationTypeService.deleteLocationType(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteLocationTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getLocationTypeData();
    } else {
      this.closeDeleteLocationTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* for Location type modal */
  openAddLocationTypeModal() {
    this.setState({ addLocationTypeModal: true });
  }

  closeAddLocationTypeModal() {
    this.setState({ addLocationTypeModal: false });
    this.setState({
      location_type_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openEditLocationTypeModal(slug) {
    this.getLocationTypeDetails(slug);
    this.setState({ editLocationTypeModal: true });
    this.setState({ slug: slug });
  }

  closeEditLocationTypeModal() {
    this.setState({ editLocationTypeModal: false });
    this.setState({
      slug: "",
      location_type_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openDeleteLocationTypeModal(slug) {
    this.setState({ deleteLocationTypeModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteLocationTypeModal() {
    this.setState({ deleteLocationTypeModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "auto",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Location Type";
  }

  render() {
    return (
      <>
        {/* Location type card */}
        {/* <Card>
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="9"
              id="9"
              onClick={(e) => this.setOpen(!this.state.open, e)}
            >
              Location Type
              <i className="mdi mdi-view-headline"> </i>
              {this.state.open && this.state.eventKey === "9" ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-up"></i>
              )}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="9">
            <Card.Body>
              <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
                <Button
                  variant="outline-dark btn-block btn-with-icon"
                  onClick={this.openAddLocationTypeModal}
                >
                  <img src={pluswIcon} /> Add
                </Button>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="table-responsive">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-50">Location Type Name</th>
                      <th className="w-25">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.locationTypeListTotal > 0 ? (
                      this.state.locationTypeList.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td
                              className="hand-cursor"
                              onClick={() =>
                                this.openEditLocationTypeModal(item.slug)
                              }
                            >
                              {item.location_type_name}
                            </td>
                            <td>
                              {item.status_id === 1 ? (
                                <b className="active"></b>
                              ) : (
                                <b></b>
                              )}
                            </td>
                            <td>
                              <div className="btn-icon-list d-block text-center">
                                <img
                                  src={deleteicon}
                                  alt="Delete"
                                  onClick={() =>
                                    this.openDeleteLocationTypeModal(item.slug)
                                  }
                                  className="hand-cursor"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          No rows found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}

        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                          <Button
                            variant="outline-dark btn-block btn-with-icon"
                            onClick={this.openAddLocationTypeModal}
                          >
                            <img src={pluswIcon} /> Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      {/* <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
            <Button
              variant="outline-dark btn-block btn-with-icon"
              onClick={this.openAddLocationTypeModal}
            >
              <img src={pluswIcon}/> Add
            </Button>
          </div> */}
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.locationTypeListTotal > 0 ? (
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th className="w-50">Location Type Name</th>
                              <th className="w-25">Status</th>
                              {global.userPermissions.checkPermission(
                                "master-data-update"
                              ) && <th className="text-center">Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.locationTypeListTotal > 0 ? (
                              this.state.locationTypeList.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      className="hand-cursor"
                                      onClick={() => {
                                        global.userPermissions.checkPermission(
                                          "master-data-update"
                                        ) &&
                                          this.openEditLocationTypeModal(
                                            item.slug
                                          );
                                      }}
                                    >
                                      {item.location_type_name}
                                    </td>
                                    <td>
                                      {item.status_id === 1 ? (
                                        <b className="active"></b>
                                      ) : (
                                        <b></b>
                                      )}
                                    </td>
                                    {global.userPermissions.checkPermission(
                                      "master-data-delete"
                                    ) && (
                                      <td>
                                        <div className="btn-icon-list d-block text-center">
                                          <img
                                            src={deleteicon}
                                            alt="Delete"
                                            onClick={() =>
                                              this.openDeleteLocationTypeModal(
                                                item.slug
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                  There are no locaton types added in the
                                  system. You may add them by clicking Add
                                  button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage(
                            "LOCATIONTYPE"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add Location type popup modal */}
        <Modal
          show={this.state.addLocationTypeModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddLocationTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddLocationTypeModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Location Type</h3>
            <label>Location Type Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="location_type_name"
              value={this.state.location_type_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Location Type Name"
            />
            {this.state.errors.location_type_name ? (
              <div className="text-danger">
                {this.state.errors.location_type_name}
              </div>
            ) : (
              this.validator.message(
                "location_type_name",
                this.state.location_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "Active" : "Inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddLocationTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveLocationType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit Location type popup modal */}
        <Modal
          show={this.state.editLocationTypeModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditLocationTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditLocationTypeModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Location Type</h3>
            <label>Location Type Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="location_type_name"
              value={this.state.location_type_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Location Type Name"
            />
            {this.state.errors.location_type_name ? (
              <div className="text-danger">
                {this.state.errors.location_type_name}
              </div>
            ) : (
              this.validator.message(
                "location_type_name",
                this.state.location_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "Active" : "Inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditLocationTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateLocationType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete Location type popup modal */}
        <Modal
          show={this.state.deleteLocationTypeModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteLocationTypeModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteLocationTypeModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteLocationTypeModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteLocationType(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default LocationType;
