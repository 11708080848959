////////////////////////////////////////////////////////////
//  Program: TemplateForm.jsx                             //
//  Application:  Template Form                           //
//  Option: List of Template                              //
//  Developer: Ashish Kumar                               //
//  Date: 2022-05-17                                      //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, Col } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import ChecklistService from "../../services/ChecklistService";
import pluswIcon from "./../../assets/images/plus.svg";
import deleteicon from "../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import Select from "react-select";
import SettingSidebar from "../common/SettingSidebar";

export class TemplatesForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      status_id: true,
      slug: this.props.match.params.slug,
      isSubmit: false,
      sort_order: "",
      pathname: window.location.pathname,
      toggleSwitchDark2: true,
      zoneTypeList: [],
      // formValues: [{ category_name: "", task_name: [{ name: "" }] }],
      formValues: [
        { category_name: "", task_name: [{ task_name: "", new: 0 }] },
      ],
      newlyadded_task: "",
    };
    this.saveOrUpdateTemplate = this.saveOrUpdateTemplate.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.opendeleteModal = this.opendeleteModal.bind(this);
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Cleaning Checklists";
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.eventKey });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  /***** handle all category add and remove  */
  handleChange(i, e) {
    let formValues = this.state.formValues;
    formValues[i][e.target.name] = e.target.value;
    this.setState({ formValues });
  }

  addFormFields() {
    this.setState({
      formValues: [
        ...this.state.formValues,
        { category_name: "", task_name: [{ task_name: "" }] },
      ],
    });
    setTimeout(() => {
      this.nameInput.focus();
    }, 100);
  }

  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  /* handle all task add and remove */
  handleChangeTask(k, f, l) {
    let formValues = this.state.formValues;
    formValues[k].task_name[f].task_name = l.target.value;
    this.setState({ formValues: formValues });
  }

  // addFormFieldsTask(k, m) {
  //   let formValues = this.state.formValues;
  //   formValues[k].task_name.push({ name: "" });
  //   this.setState({ formValues: formValues });
  // }

  addFormFieldsTask(k, m) {
    let formValues = this.state.formValues;
    formValues[k].task_name.push({ name: "", new: 1 });
    this.setState({ formValues: formValues });
    this.setState({ newlyadded_task: "task_input_" + k + "_" + m });
    setTimeout(() => {
      this.nameTaskInput.focus();
    }, 100);
  }

  removeFormFieldsTask(k, m) {
    let formValues = this.state.formValues;
    formValues[k].task_name.splice(m, 1);
    this.setState({ formValues });
  }

  async componentDidMount() {
    if (this.state.slug !== undefined) {
      var resData = {};
      var resDatalist = {};
      var selectedZoneTypeList = [];
      let res = await ChecklistService.getChecklistRowBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resDatalist = resData.data ? resData.data : {};
        resData.checklist_name = resDatalist.checklist_name
          ? resDatalist.checklist_name
          : "";
        resData.zone_type = resDatalist.zone_type
          ? resDatalist.zone_type.slug
          : "";
        selectedZoneTypeList = resDatalist.zone_type
          ? [
              {
                value: resDatalist.zone_type.slug,
                label: resDatalist.zone_type.zone_type_name,
              },
            ]
          : "";

        if (resDatalist.category.length > 0) {
          let tempFormValues = [];
          resDatalist.category.map((item, ind) => {
            tempFormValues.push(item);
            if (tempFormValues[ind].task_name.length == 0) {
              tempFormValues[ind].task_name = [
                {
                  name: "",
                },
              ];
            }
          });

          resData.formValues = tempFormValues;
        } else {
          resData.formValues = [
            {
              category_name: "",
              task_name: [
                {
                  task_name: "",
                },
              ],
            },
          ];
        }

        resData.status_id = resDatalist.status_id === 1 ? true : false;
      }
      this.setState(resData);
      this.setState({
        formValues: resData.formValues,
        selectedZoneTypeList: selectedZoneTypeList,
      });
    } else {
      this.setState({
        formValues: [{ category_name: "", task_name: [{ name: "" }] }],
      });
    }
    this.getZoneTypeData();
  }

  async getZoneTypeData(queryString) {
    var zoneTypeList = [];
    var zoneTypeListTotal = 0;

    let res = await ChecklistService.getZoneTypeList(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      zoneTypeList = res.data ? res.data : [];
      zoneTypeListTotal = zoneTypeList ? zoneTypeList.length : 0;
    }
    const newZoneTypeList =
      zoneTypeListTotal > 0
        ? zoneTypeList.map(({ slug, zone_type_name }) => ({
            value: slug,
            label: zone_type_name,
          }))
        : [];
    this.setState({
      zoneTypeList: zoneTypeList,
      zoneTypeListTotal: zoneTypeListTotal,
      newZoneTypeList: newZoneTypeList,
    });
  }

  saveOrUpdateTemplate = async (e) => {
    e.preventDefault();
    let inputData = {
      checklist_type: "template",
      checklist_name: this.state.checklist_name
        ? this.state.checklist_name
        : "",
      zone_type: this.state.zone_type ? this.state.zone_type : "",
      formValues: this.state.formValues ? this.state.formValues : "",
      status_id: this.state.status_id === true ? 1 : 2,
    };
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ isSubmit: true });
      if (this.state.slug !== undefined) {
        let res = await ChecklistService.updateCheckList(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/templates"),
            global.redirect_time
          );
        } else {
          // let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      } else {
        let res = await ChecklistService.createCheckList(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/templates"),
            global.redirect_time
          );
        } else {
          // let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      }
      this.setState({ isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  async deleteChecklist(slug) {
    this.setState({ isSubmit: true });
    let res = await ChecklistService.deleteCheckList(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.props.history.push("/templates"),
        global.redirect_time
      );
    } else {
      this.closedeleteModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  handleChangeZoneTypeList(value) {
    let zone_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        zone_type.push(item.value);
      });
    }
    const zone_type_value = value !== null ? value.value : [];
    this.setState({
      selectedZoneTypeList: value,
      zone_type: zone_type_value,
    });
    this.setState({ confirm_back: 1 });
  }

  closedeleteModal() {
    this.setState({ deleteModal: false });
  }

  opendeleteModal(slug) {
    this.setState({ deleteModal: true });
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <SettingSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end">
                        {this.state.slug !== undefined ? (
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.props.history.push("/templates");
                                else return false;
                              else this.props.history.push("/templates");
                            }}
                          >
                            Cancel
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.props.history.push("/templates");
                                else return false;
                              else this.props.history.push("/templates");
                            }}
                          >
                            Cancel
                          </button>
                        )}
                        {global.userPermissions.checkPermission(
                          "checklist-template-update"
                        ) && (
                          <button
                            type="button"
                            className="btn-success ml-3"
                            onClick={this.saveOrUpdateTemplate}
                            disabled={this.state.isSubmit ? true : false}
                          >
                            {this.state.isSubmit
                              ? global.loader
                              : this.state.slug !== undefined
                              ? "Save"
                              : "Save"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="scrolling-carousel pd-l-15">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pl-0 pr-0 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <form id="template_form">
                        <div className="m-0">
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                            <h3 className="mg-l-0 mg-t-15 background-head">
                              Info{" "}
                            </h3>
                          </div>
                          <div className="row align-items-center pd-b-0 border-bottom-0">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Template Name
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                name="checklist_name"
                                value={this.state.checklist_name}
                                className="form-control max_width_100"
                                type="text"
                              />
                              {this.state.errors.checklist_name ? (
                                <div className="text-danger">
                                  {this.state.errors.checklist_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "template_name",
                                  this.state.checklist_name,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Applicable
                              </label>

                              <Select
                                styles={customStyles}
                                isClearable={true}
                                className="multiselect"
                                menuPlacement="auto"
                                value={this.state.selectedZoneTypeList}
                                options={this.state.newZoneTypeList}
                                getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangeZoneTypeList(value)
                                }
                                defaultValue={this.state.selectedZoneTypeList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                              {this.state.errors.zone_type ? (
                                <div className="text-danger">
                                  {this.state.errors.zone_type}
                                </div>
                              ) : (
                                this.validator.message(
                                  "applicable",
                                  this.state.zone_type,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                            <h3 className="mg-l-0 mg-t-15 background-head">
                              Tasks{" "}
                            </h3>
                          </div>
                          <div className="row align-items-center pd-b-0 border-bottom-0">
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30 pet-f">
                              <div className="mg-b-30 pd-l-0 pd-r-0">
                                <div className="col-md-12 p-0">
                                  <div className="checklist-table">
                                    {this.state.formValues.map(
                                      (element, index) => (
                                        <React.Fragment key={index}>
                                          <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-0 pd-r-20 border-none">
                                            <div className="col-md-9 col-lg-9 mg-t-5 mg-md-t-0 mg-b-0 pd-b-1 pd-l-0 pd-r-0">
                                              <Col
                                                xs={12}
                                                className="pd-l-0 pd-r-2 colmn1"
                                              >
                                                <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 p-0 mg-b-15">
                                                  <label className="form-label text-left pd-b-5">
                                                    Category
                                                  </label>
                                                  <Form.Control
                                                    name="category_name"
                                                    className="form-control max_width_100"
                                                    type="text"
                                                    ref={(input) => {
                                                      this.nameInput = input;
                                                    }}
                                                    value={
                                                      element.category_name !=
                                                      ""
                                                        ? element.category_name
                                                        : ""
                                                    }
                                                    onKeyPress={(e) => {
                                                      if (e.key === "Enter") {
                                                        this.addFormFields();
                                                      }
                                                    }}
                                                    onChange={(e) =>
                                                      this.handleChange(
                                                        index,
                                                        e
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </Col>{" "}
                                            </div>
                                            <div className="col-lg-3 align-items-center d-flex">
                                              <Col
                                                xs={12}
                                                className="pd-l-2 pd-r-0 pd-t-0 d-flex align-items-center add-delete"
                                              >
                                                {this.state.formValues
                                                  .length === 1 &&
                                                index === 0 ? (
                                                  <>
                                                    {/* {element.slug ? (
                                                      <div className="d-flex  justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                                        <button
                                                          type="button"
                                                          onClick={() => {
                                                            this.removeFormFields(
                                                              index
                                                            );
                                                          }}
                                                          className={
                                                            "btn-danger-outline-x-small"
                                                          }
                                                        >
                                                          <img
                                                            src={deleteicon}
                                                            alt="Delete"
                                                          />
                                                        </button>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )} */}
                                                    <div className="d-flex justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                                      <button
                                                        type="button"
                                                        onClick={() =>
                                                          this.addFormFields()
                                                        }
                                                        className="btn-success-outline-x-small"
                                                      >
                                                        <img
                                                          src={pluswIcon}
                                                          alt="Add"
                                                        />
                                                      </button>
                                                    </div>
                                                  </>
                                                ) : index <
                                                  this.state.formValues.length -
                                                    1 ? (
                                                  <>
                                                    <div className="d-flex  justify-content-end align-items-end h-100- pd-l-0 pd-r-0">
                                                      <button
                                                        type="button"
                                                        onClick={() => {
                                                          this.removeFormFields(
                                                            index
                                                          );
                                                        }}
                                                        className={
                                                          "btn-danger-outline-x-small"
                                                        }
                                                      >
                                                        <img
                                                          src={deleteicon}
                                                          alt="Delete"
                                                        />
                                                      </button>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    {this.state.formValues
                                                      .length !== 0 ? (
                                                      <>
                                                        <div className="d-flex  justify-content-end align-items-end h-100- pd-l-0 pd-r-0">
                                                          <button
                                                            type="button"
                                                            onClick={() => {
                                                              this.removeFormFields(
                                                                index
                                                              );
                                                            }}
                                                            className={
                                                              "btn-danger-outline-x-small"
                                                            }
                                                          >
                                                            <img
                                                              src={deleteicon}
                                                              alt="Delete"
                                                            />
                                                          </button>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <div className="d-flex justify-content-end align-items-end h-100- pd-l-25 pd-r-25">
                                                      <button
                                                        type="button"
                                                        onClick={() =>
                                                          this.addFormFields()
                                                        }
                                                        className="btn-success-outline-x-small"
                                                      >
                                                        <img
                                                          src={pluswIcon}
                                                          alt="Add"
                                                        />
                                                      </button>
                                                    </div>
                                                  </>
                                                )}
                                              </Col>
                                            </div>
                                            <div className="task-list w-100">
                                              {element.task_name !==
                                                undefined &&
                                                element.task_name.map(
                                                  (el, indexes) => (
                                                    <React.Fragment
                                                      key={indexes}
                                                    >
                                                      <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-25 w-100 border-none">
                                                        <div className="col-md-9 col-lg-9 mg-t-5 mg-md-t-0 mg-b-0 pd-b-1 pd-l-70 pd-r-2">
                                                          <Col
                                                            xs={12}
                                                            className="pd-l-0 pd-r-0 colmn1"
                                                          >
                                                            <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 p-0 mg-b-15">
                                                              <label className="form-label text-left pd-b-5">
                                                                Task
                                                              </label>
                                                              <Form.Control
                                                                name="task_name"
                                                                as="textarea"
                                                                row="2"
                                                                className="form-control max_width_100"
                                                                type="text"
                                                                ref={(
                                                                  input
                                                                ) => {
                                                                  if (
                                                                    el.new === 1
                                                                  ) {
                                                                    this.nameTaskInput =
                                                                      input;
                                                                  }
                                                                }}
                                                                value={
                                                                  el.task_name !=
                                                                  ""
                                                                    ? el.task_name
                                                                    : ""
                                                                }
                                                                onKeyPress={(
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    e.key ===
                                                                    "Enter"
                                                                  ) {
                                                                    this.addFormFieldsTask(
                                                                      index,
                                                                      indexes
                                                                    );
                                                                  }
                                                                }}
                                                                onChange={(l) =>
                                                                  this.handleChangeTask(
                                                                    index,
                                                                    indexes,
                                                                    l
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </Col>
                                                        </div>
                                                        <div className="col-lg-3 d-flex align-items-basline pd-t-0 pd-l-15 pt-l-7  mg-b-15">
                                                          <Col
                                                            xs={12}
                                                            className="pd-l-2 pd-r-0 d-flex align-items-center add-delete"
                                                          >
                                                            <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                              {element.task_name
                                                                .length === 1 &&
                                                              indexes === 0 ? (
                                                                <>
                                                                  {/* {el.slug ? (
                                                                 <div className="d-flex  justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                                                   <button
                                                                     type="button"
                                                                     onClick={() => {
                                                                       this.removeFormFieldsTask(
                                                                         index,
                                                                         indexes
                                                                       );
                                                                     }}
                                                                     className={
                                                                       "btn-danger-outline-x-small"
                                                                     }
                                                                   >C
                                                                     <img
                                                                       src={
                                                                         deleteicon
                                                                       }
                                                                       alt="Delete"
                                                                     />
                                                                   </button>
                                                                 </div>
                                                               ) : (
                                                                 ""
                                                               )} */}
                                                                  <div className="d-flex justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                                                    <button
                                                                      type="button"
                                                                      onClick={() =>
                                                                        this.addFormFieldsTask(
                                                                          index,
                                                                          indexes
                                                                        )
                                                                      }
                                                                      className="btn-success-outline-x-small"
                                                                    >
                                                                      <img
                                                                        src={
                                                                          pluswIcon
                                                                        }
                                                                        alt="Add"
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                </>
                                                              ) : indexes <
                                                                element
                                                                  .task_name
                                                                  .length -
                                                                  1 ? (
                                                                <>
                                                                  <div className="d-flex  justify-content-end align-items-end h-100- pd-l-0 pd-r-0">
                                                                    <button
                                                                      type="button"
                                                                      onClick={() => {
                                                                        this.removeFormFieldsTask(
                                                                          index,
                                                                          indexes
                                                                        );
                                                                      }}
                                                                      className={
                                                                        "btn-danger-outline-x-small"
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          deleteicon
                                                                        }
                                                                        alt="Delete"
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  {element
                                                                    .task_name
                                                                    .length !==
                                                                  0 ? (
                                                                    <>
                                                                      <div className="d-flex  justify-content-end align-items-end h-100- pd-l-0 pd-r-0">
                                                                        <button
                                                                          type="button"
                                                                          onClick={() => {
                                                                            this.removeFormFieldsTask(
                                                                              index,
                                                                              indexes
                                                                            );
                                                                          }}
                                                                          className={
                                                                            "btn-danger-outline-x-small"
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={
                                                                              deleteicon
                                                                            }
                                                                            alt="Delete"
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                    </>
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                  <div className="d-flex justify-content-end align-items-end h-100- pd-l-25 pd-r-25">
                                                                    <button
                                                                      type="button"
                                                                      onClick={() =>
                                                                        this.addFormFieldsTask(
                                                                          index,
                                                                          indexes
                                                                        )
                                                                      }
                                                                      className="btn-success-outline-x-small"
                                                                    >
                                                                      <img
                                                                        src={
                                                                          pluswIcon
                                                                        }
                                                                        alt="Add"
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                </>
                                                              )}
                                                            </div>
                                                          </Col>
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center pd-b-30 pd-l-30 pd-r-15 border-bottom-0">
                            <div className="col-md-1 pl-0">
                              <label className="form-label text-left pd-b-5">
                                Status
                              </label>
                            </div>

                            <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                              <b
                                className={
                                  this.state.status_id ? "active" : "inactive"
                                }
                              ></b>
                              <div
                                className={
                                  this.state.status_id
                                    ? "az-toggle on"
                                    : "az-toggle"
                                }
                                onClick={() => this.changeStatus()}
                              >
                                <span></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
      </>
    );
  }
}

export default TemplatesForm;
