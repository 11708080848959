////////////////////////////////////////////////////////////
//  Program: ChecklistForm.jsx                             //
//  Application:  Checklist Form                          //
//  Option: List of Checklist                              //
//  Developer: Ashish Kumar                               //
//  Date: 2022-05-17                                     //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import { Container, Form, Modal, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import PropertyService from "../../services/PropertyService";
import ChecklistService from "../../services/ChecklistService";
import pluswIcon from "./../../assets/images/plus.svg";
import deleteicon from "../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import HouseKeepingSidebar from "../common/HouseKeepingSidebar";
import Select from "react-select";
import modaldeleteicon from "./../../assets/images/delete.svg";
export class ChecklistForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      status_id: true,
      slug: this.props.match.params.slug,

      isSubmit: false,
      sort_order: "",
      pathname: window.location.pathname,
      toggleSwitchDark2: true,
      zoneTypeList: [],
      propertyList: [],
      property_slug: this.props.match.params.propertySlug,
      // formValues: [{ category_name: "", task_name: [{ task_name: "" }] }],
      formValues: [{ category_name: "", task_name: [{ task_name: "",new:0 }] }], 
      newlyadded_task: "",
      userData: global.userData ? global.userData : {},
    };
    this.saveOrUpdateTemplate = this.saveOrUpdateTemplate.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.opendeleteModal = this.opendeleteModal.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }

  async getProperty(queryString = "") {
    this.setState({ loading: true });
    var propertyList = [];
    let res = await PropertyService.getPropertyList(queryString);
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data.data ? res.data.data : [];
    }
    this.setState({
      loading: false,
      propertyList: propertyList,
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.eventKey });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  /***** handle all category add and remove  */
  handleChange(i, e) {
    let formValues = this.state.formValues;
    formValues[i][e.target.name] = e.target.value;
    this.setState({ formValues });
  }

  addFormFields() {
    this.setState({
      formValues: [
        ...this.state.formValues,
        { category_name: "", task_name: [{ task_name: "" }] },
      ],
    });
    setTimeout(() => {
      this.nameInput.focus();
    }, 100)
  }

  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  /* handle all task add and remove */
  handleChangeTask(k, f, l) {
    let formValues = this.state.formValues;
    formValues[k].task_name[f].task_name = l.target.value;
    this.setState({ formValues: formValues });
  }

  // addFormFieldsTask(k, m) {
  //   let formValues = this.state.formValues;
  //   formValues[k].task_name.push({ name: "" });
  //   this.setState({ formValues: formValues });
  //   setTimeout(() => {
  //     this.nameTaskInput.focus();
  //   }, 100)
  // }

  addFormFieldsTask(k, m) {
    let formValues = this.state.formValues;
    formValues[k].task_name.push({ name: "",new:1 });
    this.setState({ formValues: formValues}); 
    this.setState({newlyadded_task:"task_input_"+k+"_"+m }); 
    setTimeout(() => {
      this.nameTaskInput.focus();
    }, 100)
  }

  removeFormFieldsTask(k, m) {
    let formValues = this.state.formValues;
    formValues[k].task_name.splice(m, 1);
    this.setState({ formValues });
  }

  async componentDidMount() {
    this.getProperty();
    this.getTemplateExist();
    this.getZoneTypeData();
    if (this.state.slug !== undefined) {
      var resData = {};
      var resDatalist = {};
      var selectedZoneTypeList = [];
      let res = await ChecklistService.getChecklistRowBySlug(this.state.slug);

      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resDatalist = resData.data ? resData.data : {};
        resData.template_slug = resDatalist.slug ? resDatalist.slug : "";
        resData.checklist_name = resDatalist.checklist_name
          ? resDatalist.checklist_name
          : "";
        resData.property_id = resDatalist.property
          ? resDatalist.property.slug
          : "";
        resData.zone_type = resDatalist.zone_type
          ? resDatalist.zone_type.slug
          : "";
        selectedZoneTypeList = resDatalist.zone_type
          ? [
              {
                value: resDatalist.zone_type.slug,
                label: resDatalist.zone_type.zone_type_name,
              },
            ]
          : "";
        resData.description = resDatalist.description
          ? resDatalist.description
          : "";

        if (resDatalist.category.length > 0) {
          let tempFormValues = [];
          resDatalist.category.map((item, ind) => {
            tempFormValues.push(item);
            if (tempFormValues[ind].task_name.length === 0) {
              tempFormValues[ind].task_name = [
                {
                  checklist_task_id: "",
                  slug: "",
                  task_name: "",
                },
              ];
            }
          });

          resData.formValues = tempFormValues;
        } else {
          resData.formValues = [
            {
              category_name: "",
              checklist_category_id: "",
              slug: "",
              task_name: [
                {
                  checklist_task_id: "",
                  slug: "",
                  task_name: "",
                },
              ],
            },
          ];
        }

        resData.status_id = resDatalist.status_id === 1 ? true : false;
      }

      this.setState(resData);
      this.setState({
        formValues: resData.formValues,
        selectedZoneTypeList: selectedZoneTypeList,
      });
    } else {
      this.setState({
        formValues: [{ category_name: "", task_name: [{ name: "" }] }],
      });
    }
 
  }

  async getZoneTypeData(queryString) {
    this.setState({ showSpinner: true, loading: true });
    var zoneTypeList = [];
    var zoneTypeListTotal = 0;

    let res = await ChecklistService.getZoneTypeList(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      zoneTypeList = res.data ? res.data : [];
      zoneTypeListTotal = zoneTypeList ? zoneTypeList.length : 0;
    }
    const newZoneTypeList =
      zoneTypeListTotal > 0
        ? zoneTypeList.map(({ slug, zone_type_name }) => ({
            value: slug,
            label: zone_type_name,
          }))
        : [];
    this.setState({
      zoneTypeList: zoneTypeList,
      zoneTypeListTotal: zoneTypeListTotal,
      newZoneTypeList: newZoneTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getTemplateExist() {
    this.setState({ loading: true });
    let inputData = {
      user_slug: this.state.userData.slug ? this.state.userData.slug : "",
      role_id: this.state.userData.role_id ? this.state.userData.role_id : "",
      checklist_slug: this.state.slug ? this.state.slug : "",
    };
    var existData = "";
    let res = await ChecklistService.checkListExist(inputData);
    if (global.successStatus.includes(res.status)) {
      existData = res.data ? res.data : [];
    }
    this.setState({ existData: existData });
  }

  saveOrUpdateTemplate = async (e) => {
    e.preventDefault();
    let inputData = {
      checklist_name: this.state.checklist_name
        ? this.state.checklist_name
        : "",
      property_id: this.state.property_slug ? this.state.property_slug : "",
      template_slug: this.state.template_slug ? this.state.template_slug : "",
      zone_type: this.state.zone_type ? this.state.zone_type : "",
      description: this.state.description ? this.state.description : "",
      formValues: this.state.formValues ? this.state.formValues : "",
      existData: this.state.existData ? this.state.existData : "",
      checklist_type: "checklist",
      status_id: this.state.status_id === true ? 1 : 2,
    };
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ isSubmit: true });
      if (this.state.slug !== undefined) {
        let res = await ChecklistService.createImportedCheckList(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/checklists"
              ),
            global.redirect_time
          );
        } else {
          // let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      } else {
        let res = await ChecklistService.createCheckList(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/checklists"
              ),
            global.redirect_time
          );
        } else {
          // let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      }
      this.setState({ isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  async deleteChecklist(slug) {
    let res = await ChecklistService.deleteCheckList(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" + this.state.property_slug + "/checklists"
          ),
        global.redirect_time
      );
    } else {
      this.closedeleteModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  handleChangeZoneTypeList(value) {
    let zone_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        zone_type.push(item.value);
      });
    }
    const zone_type_value = value !== null ? value.value : [];
    this.setState({
      selectedZoneTypeList: value,
      zone_type: zone_type_value,
    });
    this.setState({ confirm_back: 1 });
  }

  closedeleteModal() {
    this.setState({ deleteModal: false });
  }

  opendeleteModal(slug) {
    this.setState({ deleteModal: true, slug: slug });
  }

  getTitle() {
    return "Checklists";
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <HouseKeepingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.slug !== undefined ? (
                        <>
                          {" "}
                          {this.state.pathname.includes("import") ? (
                            ""
                          ) : (
                            global.userPermissions.checkPermission(
                              "checklists-delete"
                            ) && 
                            <span
                              onClick={() => {
                                this.opendeleteModal(this.state.slug);
                              }}
                              className="deleteicon mg-r-20"
                            >
                              <img src={deleteicon} alt="" />
                            </span>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                  this.state.property_slug +
                                  "/checklists"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              "/properties/view/" +
                                this.state.property_slug +
                                "/checklists"
                            );
                        }}
                      >
                        Cancel
                      </button>

                      {global.userPermissions.checkPermission(
                        "checklists-update"
                      ) && (
                      <button
                        type="button"
                        className="btn-success ml-3"
                        onClick={this.saveOrUpdateTemplate}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                          ? "Save"
                          : "Save"}
                      </button> )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pl-lg-l-0 pd-lg-r-30 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <form id="checklist_form">
                      <div className="m-0">
                        <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                          <h3 className="mg-l-0 mg-t-15 background-head">
                            Info{" "}
                          </h3>
                        </div>
                        <div className="row align-items-center pd-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Checklist Name
                            </label>
                            <Form.Control
                              onChange={this.changeHandler}
                              name="checklist_name"
                              value={this.state.checklist_name}
                              className="form-control max_width_100"
                              type="text"
                            />
                            {this.state.errors.checklist_name ? (
                              <div className="text-danger">
                                {this.state.errors.checklist_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "checklist_name",
                                this.state.checklist_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Applicable
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedZoneTypeList}
                              options={this.state.newZoneTypeList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeZoneTypeList(value)
                              }
                              defaultValue={this.state.selectedZoneTypeList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                            {this.state.errors.zone_type ? (
                              <div className="text-danger">
                                {this.state.errors.zone_type}
                              </div>
                            ) : (
                              this.validator.message(
                                "applicable",
                                this.state.zone_type,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>

                        <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                          <h3 className="mg-l-0 mg-t-15 background-head">
                            Tasks{" "}
                          </h3>
                        </div>
                        <div className="row align-items-center pd-b-0 border-bottom-0">
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 pet-f">
                            <div className="mg-lg-b-30 mg-xs-b-10 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0">
                              <div className="col-12 p-0">
                                <div className="checklist-table" id="checklist-table">
                                  {this.state.formValues.map(
                                    (element, index) => (
                                      <React.Fragment key={index}>
                                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-0 pd-lg-r-20 pd-xs-r-0 border-none">
                                          <div className="col-md-9 col-lg-9 mg-t-5 mg-md-t-0 mg-b-0 pd-b-1 pd-l-0 pd-r-0">
                                            <Col
                                              xs={12}
                                              className="pd-l-0 pd-r-2 colmn1"
                                            >
                                              <div className="col-12 col-lg-12 mg-t-5 mg-md-t-0 p-0 mg-b-15">
                                                <label className="form-label text-left pd-b-5">
                                                  Category
                                                </label>
                                                <Form.Control
                                                  name="category_name"
                                                  className="form-control max_width_100 dynamic_category"
                                                  type="text"
                                                  ref={(input) => { this.nameInput = input; }}
                                                  onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                      this.addFormFields();
                                                    }
                                                  }}
                                                  value={
                                                    element.category_name !== ""
                                                      ? element.category_name
                                                      : ""
                                                  }
                                                  onChange={(e) =>
                                                    this.handleChange(index, e)
                                                  }
                                                />
                                              </div>
                                            </Col>{" "}
                                          </div>
                                          <div className="col-lg-3 align-items-center d-flex">
                                            <Col
                                              xs={12}
                                              className="pd-l-2 pd-r-0 pd-t-5 d-flex align-items-center add-delete"
                                            >
                                              {this.state.formValues.length ===
                                                1 && index === 0 ? (
                                                <>
                                                  {/* {element.slug ? (
                                                      <div className="d-flex  justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                                        <button
                                                          type="button"
                                                          onClick={() => {
                                                            this.removeFormFields(
                                                              index
                                                            );
                                                          }}
                                                          className={
                                                            "btn-danger-outline-x-small"
                                                          }
                                                        >
                                                          <img
                                                            src={deleteicon}
                                                            alt="Delete"
                                                          />
                                                        </button>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )} */}
                                                  <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                    <button
                                                      type="button"
                                                      onClick={() =>
                                                        this.addFormFields()
                                                      }
                                                      className="btn-success-outline-x-small"
                                                    >
                                                      <img
                                                        src={pluswIcon}
                                                        alt="Add"
                                                      />
                                                    </button>
                                                  </div>
                                                </>
                                              ) : index <
                                                this.state.formValues.length -
                                                  1 ? (
                                                <>
                                                  <div className="d-flex  justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                    <button
                                                      type="button"
                                                      onClick={() => {
                                                        this.removeFormFields(
                                                          index
                                                        );
                                                      }}
                                                      className={
                                                        "btn-danger-outline-x-small"
                                                      }
                                                    >
                                                      <img
                                                        src={deleteicon}
                                                        alt="Delete"
                                                      />
                                                    </button>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  {this.state.formValues
                                                    .length !== 0 ? (
                                                    <>
                                                      <div className="d-flex  justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                        <button
                                                          type="button"
                                                          onClick={() => {
                                                            this.removeFormFields(
                                                              index
                                                            );
                                                          }}
                                                          className={
                                                            "btn-danger-outline-x-small"
                                                          }
                                                        >
                                                          <img
                                                            src={deleteicon}
                                                            alt="Delete"
                                                          />
                                                        </button>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <div className="d-flex justify-content-end align-items-end h-100- pd-l-25 pd-r-25">
                                                    <button
                                                      type="button"
                                                      onClick={() =>
                                                        this.addFormFields()
                                                      }
                                                      className="btn-success-outline-x-small"
                                                    >
                                                      <img
                                                        src={pluswIcon}
                                                        alt="Add"
                                                      />
                                                    </button>
                                                  </div>
                                                </>
                                              )}
                                            </Col>
                                          </div>
                                          <div className="task-list w-100">
                                            {element.task_name !== undefined &&
                                              element.task_name.map(
                                                (el, indexes) => (
                                                  <React.Fragment key={indexes}>
                                                    <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-lg-l-70 pd-xs-l-20 pd-lg-r-25 pd-xs-r-0 w-100 border-none">
                                                      <div className="col-md-9 col-lg-9 mg-t-5 mg-md-t-0 mg-b-0 pd-b-1 pd-lg-l-70 pd-xs-l-0 pd-r-2">
                                                        <Col
                                                          xs={12}
                                                          className="pd-l-0 pd-r-0 colmn1"
                                                        >
                                                          <div className="col-12 col-lg-12 mg-t-5 mg-md-t-0 p-0 mg-b-15">
                                                            <label className="form-label text-left pd-b-5">
                                                              Task
                                                            </label>
                                                            <Form.Control
                                                              name="task_name"
                                                              as="textarea"
                                                              row="2"
                                                              id= {"dynamic_task"+"_"+index+"_"+indexes}
                                                              // autofous={index === 0 && indexes===1 ? true : "" }
                                                              className={"form-control max_width_100 dynamic_task"+"_"+index+"_"+indexes}
                                                              type="text"
                                                              ref={(input) => { if(el.new===1) {this.nameTaskInput = input;} }}
                                                              
                                                              onKeyPress={(
                                                                e
                                                              ) => {
                                                                if (
                                                                  e.key ===
                                                                  "Enter"
                                                                ) {
                                                                  this.addFormFieldsTask(
                                                                    index,
                                                                    indexes
                                                                  );
                                                                }
                                                              }}
                                                              value={
                                                                el.task_name !==
                                                                ""
                                                                  ? el.task_name
                                                                  : ""
                                                              }
                                                              onChange={(l) =>
                                                                this.handleChangeTask(
                                                                  index,
                                                                  indexes,
                                                                  l
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </Col>
                                                      </div>
                                                      <div className="col-lg-3 d-flex align-items-basline pd-t-0 pd-l-15 pt-l-7  mg-b-15">
                                                        <Col
                                                          xs={12}
                                                          className="pd-l-2 pd-r-0 d-flex align-items-center add-delete"
                                                        >
                                                          <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                            {element.task_name
                                                              .length === 1 &&
                                                            indexes === 0 ? (
                                                              <>
                                                                {/* {el.slug ? (
                                                                 <div className="d-flex  justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                                                   <button
                                                                     type="button"
                                                                     onClick={() => {
                                                                       this.removeFormFieldsTask(
                                                                         index,
                                                                         indexes
                                                                       );
                                                                     }}
                                                                     className={
                                                                       "btn-danger-outline-x-small"
                                                                     }
                                                                   >C
                                                                     <img
                                                                       src={
                                                                         deleteicon
                                                                       }
                                                                       alt="Delete"
                                                                     />
                                                                   </button>
                                                                 </div>
                                                               ) : (
                                                                 ""
                                                               )} */}
                                                                <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                                  <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                      this.addFormFieldsTask(
                                                                        index,
                                                                        indexes
                                                                      )
                                                                    }
                                                                    className="btn-success-outline-x-small"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        pluswIcon
                                                                      }
                                                                      alt="Add"
                                                                    />
                                                                  </button>
                                                                </div>
                                                              </>
                                                            ) : indexes <
                                                              element.task_name
                                                                .length -
                                                                1 ? (
                                                              <>
                                                                <div className="d-flex  justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                                  <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                      this.removeFormFieldsTask(
                                                                        index,
                                                                        indexes
                                                                      );
                                                                    }}
                                                                    className={
                                                                      "btn-danger-outline-x-small"
                                                                    }
                                                                  >
                                                                    <img
                                                                      src={
                                                                        deleteicon
                                                                      }
                                                                      alt="Delete"
                                                                    />
                                                                  </button>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              <>
                                                                {element
                                                                  .task_name
                                                                  .length !==
                                                                0 ? (
                                                                  <>
                                                                    <div className="d-flex  justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                                      <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                          this.removeFormFieldsTask(
                                                                            index,
                                                                            indexes
                                                                          );
                                                                        }}
                                                                        className={
                                                                          "btn-danger-outline-x-small"
                                                                        }
                                                                      >
                                                                        <img
                                                                          src={
                                                                            deleteicon
                                                                          }
                                                                          alt="Delete"
                                                                        />
                                                                      </button>
                                                                    </div>
                                                                  </>
                                                                ) : (
                                                                  ""
                                                                )}
                                                                <div className="d-flex justify-content-end align-items-end h-100- pd-l-25 pd-r-25">
                                                                  <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                      this.addFormFieldsTask(
                                                                        index,
                                                                        indexes
                                                                      )
                                                                    }
                                                                    className="btn-success-outline-x-small"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        pluswIcon
                                                                      }
                                                                      alt="Add"
                                                                    />
                                                                  </button>
                                                                </div>
                                                              </>
                                                            )}
                                                          </div>
                                                        </Col>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                )
                                              )}
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center pd-b-30 pd-l-30 pd-r-15 border-bottom-0">
                          <div className="col-md-1 pl-0">
                            <label className="form-label text-left pd-b-5">
                              Status
                            </label>
                          </div>

                          <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                            <b
                              className={
                                this.state.status_id ? "active" : "inactive"
                              }
                            ></b>
                            <div
                              className={
                                this.state.status_id
                                  ? "az-toggle on"
                                  : "az-toggle"
                              }
                              onClick={() => this.changeStatus()}
                            >
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteChecklist(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default ChecklistForm;
