/////////////////////////////////////////////////////
//     							                               //
//  Program: MroForm.jsx                           //
//  Application: Property                          //
//  Option:  MroForm                               //
//  Developer: Ashish Kumar, Kiran, Chandra Shekhar //
//  Date: 2022-05-30                               //
//                                                 //
/////////////////////////////////////////////////////
import React, { Component } from "react";
import Moment from "moment";
import Select from "react-select";
import { Container, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import SimpleReactValidator from "simple-react-validator";
import HouseKeepingSidebar from "../common/HouseKeepingSidebar";
import Alerts from "../common/Alerts";
// import imgIcon from "../../assets/images/imgIcon.png";
import UnitsService from "../../services/UnitsService";
import ReservationService from "../../services/ReservationService";
import AllPropertiesHouseKeepingSidebar from "../common/AllPropertiesMaintenanceSidebar";

export class HouseKeepingForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      newUnitList: [],
      start_date: "",
      // end_date: "",
      unit_slug: "",
      input: {},
      errors: {},
      loading: false,
      isSubmit: false,
      slug: this.props.match.params.slug,
      disabled_dates: [],
    };
  }

  componentDidMount() {
    this.getUnitData();
    // this.getCleaningAvailableDate();
    this.getCleaningAvailableDate = this.getCleaningAvailableDate.bind(this);
  }
  getTitle() {
    return "Add Cleaning Requests";
  }

  //get cleaning available date
  async getCleaningAvailableDate(slug) {
    this.setState({ showSpinner: true, loading: true });
    var checkDate = {};
    var checkDateLength = 0;
    var dates = [];
    let res = await ReservationService.checkCleaningAvailabilty(slug);
    if (global.successStatus.includes(res.status)) {
      checkDate = res.data ? res.data : {};
      checkDateLength = checkDate ? checkDate.length : 0;
    }
    
    if (checkDateLength > 0) {
      checkDate.map((item, index) => {
        let scheduled_on_data = item.scheduled_on;
        dates.push(new Date(scheduled_on_data));
      });

      this.setState({ disabled_dates: dates });
    }

    this.setState({ showSpinner: false, loading: false });
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    this.getCleaningAvailableDate(unit_slug_value);
    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({
      [event.target.name]: value,
      input: input,
    });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    if (event.target.name === "comment") {
      this.setState({ errors: {} });
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  saveHouseKeepingForm = async (e) => {
    e.preventDefault();
    // let errors = {};
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ loading: true, isSubmit: true });

      let inputData = {
        unit_slug: this.state.unit_slug,
        start_date: this.state.input.start_date
          ? Moment(this.state.input.start_date).format("YYYY-MM-DD")
          : null,
        // end_date: this.state.input.end_date
        // ? Moment(this.state.input.end_date).format("YYYY-MM-DD")
        // : null,
        summary: this.state.input.summary,
        description: this.state.input.description,
        property_slug: this.state.property_slug,
      };
      // console.log(inputData);
      let res = await ReservationService.createHouseCleaning(inputData);
      console.log(res.status);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        // this.setState({ slug: res.data.mroLastInsertSlug });
        setTimeout(
          () =>
            this.props.history.push(
              this.props.match.params.propertySlug !== undefined
                ? "/properties/view/" +
                    this.state.property_slug +
                    "/housekeeping-list"
                : "/housekeeping-list"
            ),
          global.redirect_time
        );
      } else {
        if (res.data.result.errorDetail !== undefined) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        } else {
          let alertMessage = "";
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }

      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <HouseKeepingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertiesHouseKeepingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          this.props.history.push(
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                  this.state.property_slug +
                                  "/housekeeping-list"
                              : "/housekeeping-list"
                          );
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn-success ml-3"
                        onClick={this.saveHouseKeepingForm}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                          ? "Save"
                          : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-l-30 pd-r-30 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <form
                      id="houserkeeping_form"
                      onSubmit={this.onSubmitFun}
                      method="post"
                      encType="multipart/form-data"
                    >
                      <div className="m-0">
                        <div className="row align-items-center pd-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Unit <span className="optional"></span>
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              menuPlacement="auto"
                              options={this.state.newUnitList}
                              onChange={(value) =>
                                this.handleChangeUnitList(value)
                              }
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                            {this.state.errors.unit_slug ? (
                              <div className="text-danger">
                                {this.state.errors.unit_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "Unit",
                                this.state.unit_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Cleaning Date <span className="optional"></span>
                            </label>
                            <DatePicker
                              className="form-control max_width_100"
                              disabledKeyboardNavigation
                              showMonthDropdown
                              showYearDropdown
                              strictParsing
                              minDate={new Date()}
                              scrollableYearDropdown
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="start_date"
                              excludeDates={this.state.disabled_dates}
                              dateFormat="MM/dd/yy"
                              selected={this.state.start_date}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "start_date",
                                    value: data,
                                  },
                                })
                              }
                            />
                            {this.state.errors.start_date ? (
                              <div className="text-danger">
                                {this.state.errors.start_date}
                              </div>
                            ) : (
                              this.validator.message(
                                "Cleaning Date",
                                this.state.start_date,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Private Notes{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              as="textarea"
                              onChange={this.changeHandler}
                              name="summary"
                              maxLength="500"
                            />
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Notes To Teammate{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              as="textarea"
                              onChange={this.changeHandler}
                              name="description"
                              maxLength="500"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default HouseKeepingForm;
