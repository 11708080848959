///////////////////////////////////////////////
//     							             //
//  Program: SchlageService.js                 //
//  Application: Services                    //
//  Option: Used for Igloo Module              //
//  Developer: Ravi                //
//  Date: 2023-08-22                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class SchlageService {
  getSchlageLock(queryString) {
    let endPoint = "get-schlage-units" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = { endPoint, addditionalHeaderData, postData };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateSchlageInstruction(data) {
    let endPoint = "update-schlage-instruction";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = { endPoint, addditionalHeaderData, postData };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getSchlageFob(queryString) {
    let endPoint = "get-schlage-fob" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  addSchlageFob(data) {
    let endPoint = "add-schlage-fob";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  mappingUnits(data) {
    let endPoint = "map-schlage-units";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getSchlageInstruction(queryString) {
    let endPoint =
      "get-schlage-instruction" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}
export default new SchlageService();
