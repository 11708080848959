////////////////////////////////////////////////////////////
//     							                                      //
//  Program: InfluencerView.jsx                                //
//  Application:  Influencer                                   //
//  Option: For view  Influencer details                      //
//  Developer: Ashish Kumar                          //
//  Date: 2022-02-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Container, Modal } from "react-bootstrap";
import InfluencersService from "../../services/InfluencersService";
// import renderHTML from "react-render-html";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
// import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import PropertySidebar from "../common/PropertySidebar";
import Photo from "./../../assets/images/profile-nogender.png";
// import ShowMoreText from "react-show-more-text";
export class InfluencerView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      outside_property_slug: this.props.match.params.outsidePropertySlug,
      isSubmit: false,

      pathname: window.location.pathname,
      deletedFileList: [],
      photo: [],
      photoUrl: Photo,
      descShow: 0,
      descShowPricing: 0,
    };
    this.photoUrl = React.createRef();
    this.fullText = this.fullText.bind(this);
    this.fullTextPricing = this.fullTextPricing.bind(this);
    this.deleteInfluencer = this.deleteInfluencer.bind(this);
    this.opendeleteInfluencerModal = this.opendeleteInfluencerModal.bind(this);
    this.closedeleteInfluencerModal =
      this.closedeleteInfluencerModal.bind(this);
  }

  opendeleteInfluencerModal(slug) {
    this.setState({ deleteInfluencerModal: true, slug: slug });
  }

  closedeleteInfluencerModal() {
    this.setState({ deleteInfluencerModal: false, slug: "" });
  }

  async deleteInfluencer(slug) {
    this.setState({ showSpinner: true });
    let res = await InfluencersService.deleteInfluencers(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteInfluencerModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.props.match.params.propertySlug !== undefined
              ? "/properties/view/" + this.state.property_slug + "/influencers"
              : "/influencers"
          ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteInfluencerModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};

      let res = await InfluencersService.getInfluencersBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.socialMedia = res.data && res.data.socialMedia ? res.data.socialMedia : [];
        resData.socialMediaTotal = res.data ? res.data.socialMedia.length : 0;
      }
      this.setState(resData);
      this.setState({ input: resData });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  getTitle() {
    return "Influencers";
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextPricing() {
    if (this.state.descShowPricing === 0) {
      this.setState({ descShowPricing: 1 });
    } else {
      this.setState({ descShowPricing: 0 });
    }
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ?
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
                </div> : ""}

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.slug !== undefined ? (
                        <>
                          {global.userPermissions.checkPermission(
                            "influencers-delete"
                          ) && (
                            <span
                              onClick={() => {
                                this.opendeleteInfluencerModal(this.state.slug);
                              }}
                              className="deleteicon mg-r-20"
                            >
                              <img src={deleteicon} alt="" />
                            </span>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {global.userPermissions.checkPermission(
                        "influencers-update"
                      ) && (
                        <Link
                          to={
                              this.props.match.params.propertySlug !== undefined
                                ? "/properties/view/" +
                            this.state.property_slug +
                            "/influencers/update/" +
                            this.state.slug
                                : "/influencers/update/" +
                                this.state.outside_property_slug +
                                "/" +
                                this.state.slug
                          }
                          className="mg-l-2"
                        >
                          <button className="btn-success-outline-small">
                            <img src={editIcon} alt="Edit" />
                            Edit
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-30 pr-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {/* <div className="row col-md-12 mg-t-40 mg-md-t-0 pt-0 pl-0 pr-0 ml-0 mr-0">
                      <h3 className="mg-l-0 background-head">Basic</h3>
                    </div> */}
                    <div className="row align-items-center col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 mg-b-20 pd-lg-l-30 pd-lg-r-15">
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <div className="demo-avatar-group d-flex justify-content-left align-items-start flex-column">
                          <div className="az-img-user avatar-md online mg-b-8">
                            <figure>
                              <b
                                className={
                                  this.state.status_id === 1
                                    ? "status-active"
                                    : "status-inactive"
                                }
                              ></b>
                              <img
                                src={
                                  this.state.photo_thumb_url
                                    ? this.state.photo_thumb_url
                                    : Photo
                                }
                                alt=""
                                className="rounded-circle"
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-8 ">
                        <div className="propname">
                          <p className="az-profile-name-text pl-0">Name</p>
                          <p className="media-body">
                            {this.state.name ? this.state.name : ""}
                          </p>
                        </div>
                        <div className="propname">
                          <p className="az-profile-name-text pl-0">Title</p>
                          <p className="media-body">
                            {this.state.title ? this.state.title : ""}
                          </p>
                        </div>
                        <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0">
                          <p className="az-profile-name-text pl-0">Bio</p>
                          <div className="para">
                            {this.state.bio ? (
                                <p className="p_wrap1">
                                {this.state.descShow
                                  ? this.state.bio.slice(
                                      0,
                                      global.showMoreLength
                                    )
                                  : this.state.bio}

                                {this.state.bio.length >
                                  global.showMoreLength && (
                                  <span onClick={this.fullText}>
                                    {this.state.descShow ? (
                                      <p className="hand-cursor showmore">
                                        ...Show more{" "}
                                      </p>
                                    ) : (
                                      <p className="hand-cursor showmore">
                                        Show Less{" "}
                                      </p>
                                    )}
                                  </span>
                                )}
                                </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row col-md-12 mg-t-40 mg-md-t-0">

                    </div> */}
                    <div className="mg-t-18 brandList">
                      <div className="row col-md-12 mg-t-40 mg-xs-t-20 mg-md-t-0 pr-0 pl-0 ml-0 mr-0">
                        <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-l-0 pd-lg-r-30">
                          <h3 className="mg-l-0 background-head">
                            Social Media
                          </h3>
                        </div>
                      </div>
                      <div className="pd-lg-l-0 pd-xs-l-15">
                        <div
                          className="as-react-table m-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-30"
                          id="as-react-datatable-container"
                        >
                          <div
                            className="row table-body asrt-table-body m-0"
                            id="as-react-datatable-table-body"
                          >
                            <div className="col-md-12 petFeeSection">
                              <table
                                className="table table-hoverno-wrap mg-b-30 pd-b-2"
                                id="as-react-datatable"
                              >
                                <thead>
                                  <tr>
                                    <th className=" text-" width="">
                                      Platform
                                    </th>
                                    <th className=" text-" width="">
                                      Link
                                    </th>
                                    <th className=" text-" width="">
                                      Tagline
                                    </th>
                                    <th className=" text-" width="">
                                      Type
                                    </th>
                                    <th className=" text-" width="">
                                      Account Name
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.socialMediaTotal > 0 ? (
                                    this.state.socialMedia.map((option, i) => (
                                      <tr key={i}>
                                        <td>
                                          {option.social_media
                                            ? option.social_media
                                                .social_media_name
                                            : ""}
                                        </td>
                                        <td>
                                          {option.link ? option.link : ""}
                                        </td>
                                        <td>
                                          {option.tag_line
                                            ? option.tag_line
                                            : ""}
                                        </td>
                                        <td>
                                          {option.type ? option.type : ""}
                                        </td>
                                        <td>
                                          {option.account_name
                                            ? option.account_name
                                            : ""}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="9" align="center">
                                        There are no fees added in the system.
                                        You may add them by clicking Edit
                                        button.
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteInfluencerModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteInfluencerModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteInfluencerModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteInfluencer(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default InfluencerView;
