////////////////////////////////////////////////
//     							                          //
//  Program: MaintenanceCalendar.jsx          //
//  Application: House keeping                //
//  Option: Maintenance Requests              //
//  Developer: Namrata Patel		              //
//  Date: 2023-01-20                          //
//                                            //
////////////////////////////////////////////////

import React from "react";
import { useCallback, useState } from "react";
import CalendarService from "../../services/CalendarService";
import {
  Eventcalendar,
  setOptions,
  Popup,
  CalendarNav,
  SegmentedGroup,
  SegmentedItem,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
} from "@mobiscroll/react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertiesMaintenanceSidebar from "../common/AllPropertiesMaintenanceSidebar";
import MaintenanceRequestSidebar from "../common/MaintenanceRequestSidebar";

setOptions({
  theme: "ios",
  themeVariant: "light",
  dateFormatLong: "MM/DD/YY",
});

const MaintenanceCalendar = (props) => {
  const [event, setEvent] = useState({});
  const [events, myEvents] = useState([]);
  const [resources, myResources] = useState([]);
  const [isPopupOpen, setOpen] = useState(false);
  // const property_slug = props.match.params.propertySlug;
  const [property_slug, setPropertySlug] = useState(
    props.match.params.propertySlug !== undefined
      ? props.match.params.propertySlug
      : localStorage.getItem("outSidePorpertySlug")
      ? localStorage.getItem("outSidePorpertySlug")
      : ""
  );
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [view, setView] = React.useState("day");
  const [calView, setCalView] = React.useState({
    agenda: {
      type: "day",
    },
  });

  const onPageLoading = React.useCallback((event, inst) => {
    const year = event.month.getFullYear();
    const month = event.month.getMonth();

    if (props.match.params.propertySlug !== undefined) {
      setPropertySlug(props.match.params.propertySlug);
      setTimeout(() => {
        getReservationdata(props.match.params.propertySlug, year, month);
      }, 200);
    } else {
      const propSlug = localStorage.getItem("outSidePorpertySlug")
        ? localStorage.getItem("outSidePorpertySlug")
        : "";
      setPropertySlug(propSlug);
      setTimeout(() => {
        getReservationdata(
          localStorage.getItem("outSidePorpertySlug")
            ? localStorage.getItem("outSidePorpertySlug")
            : "",
          year,
          month
        );
      }, 200);
    }
  }, []);

  const getReservationdata = async (property_slug, year, month) => {
    setYear(year);
    setMonth(month);
    let res = await CalendarService.getMROs(
      "year=" +
        year +
        "&month=" +
        (month + 1) +
        "&property_slug=" +
        (property_slug ? property_slug : "")
    );

    if (global.successStatus.includes(res.status)) {
      myEvents(res.data.maintenanceData ? res.data.maintenanceData : []);
      myResources(res.data.unitData ? res.data.unitData : []);
    }
  };

  //event pills
  // const myScheduleEvent = React.useCallback((data) => {
  //   const ev = data.original;
  //   const color = data.original.color;
  //   return (
  //     <div
  //       className="md-timeline-template-event"
  //       style={{ borderColor: color, background: color }}
  //     >
  //       <div>{data.original.issue}</div>
  //       <div className="md-timeline-template-event-cont">
  //         <span className="md-timeline-template-title">
  //           {ev.request_number - ev.staff_name}
  //         </span>
  //       </div>
  //     </div>
  //   );
  // }, []);

  const renderEvent = React.useCallback((data) => {
    return (
      <div className="md-full-event">
        <div className="mbsc-event-color mbsc-ios mbsc-ltr"></div>
        <div className="md-full-event-details">
          <div className="md-full-event-title">
            {data.original.listing_name}
          </div>
          <div className="md-full-event-location">
            <div>{data.original.issue}</div>
          </div>
          <div className="md-full-event-time">
            <div>{data.original.status}</div>
          </div>
        </div>
      </div>
    );
  });

  const openPopup = useCallback((event) => {
    setOpen(true);
    setEvent(event);
  }, []);

  const closePopup = useCallback(() => {
    setOpen(false);
  }, []);

  const renderMyHeader = () => {
    return (
      <React.Fragment>
        <CalendarPrev className="md-event-listing-prev" />
        <CalendarNav className="md-event-listing-nav" />
        <CalendarNext className="md-event-listing-next" />
        <div className="md-event-listing-picker">
          <SegmentedGroup value={view} onChange={changeView}>
            <SegmentedItem value="day">Day</SegmentedItem>
            <SegmentedItem value="week">Week</SegmentedItem>
            <SegmentedItem value="year">Year</SegmentedItem>
          </SegmentedGroup>
        </div>
        <CalendarToday className="md-event-listing-today pr-4" />
      </React.Fragment>
    );
  };

  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "day":
        calView = {
          agenda: {
            type: "day",
          },
        };
        break;
      case "week":
        calView = {
          agenda: {
            type: "week",
          },
        };
        break;
      case "year":
        calView = {
          agenda: {
            type: "year",
          },
        };
        break;
      case "month":
      default:
        calView = {
          agenda: {
            type: "month",
          },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };

  const renderCustomResource = (resource) => {
    return (
      <div className="md-resource-header-template-cont" title={resource.name}>
        <div className="md-resource-header-template-name">
          {resource.unit_name ? resource.unit_name : resource.unit_name}
        </div>
        <div className="md-resource-header-template-name">
          {resource.resident_name}
        </div>
        <div className="md-resource-header-template-name">
          {resource.listing_name}
        </div>
      </div>
    );
  };

  const getPropertySlugData = async (property_slug) => {
    setPropertySlug(property_slug);
    getReservationdata(property_slug, year, month);
  };

  return (
    <>
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <MaintenanceRequestSidebar property_slug={property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertiesMaintenanceSidebar
                      property_slug={property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3 className="mb-0">Maintenance Calendar</h3>
                    {/* <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                                    <button
                                        onClick={this.filterToggle}
                                        className="btn-success-outline-small mr-3"
                                    >
                                        <img src={filterIcon} alt="filterIcon" />{" "}
                                        {this.state.showFilter === 1 ? "Filter" : "Filter"}
                                    </button>
                                    </div> */}
                  </div>
                </div>
                {window.location.pathname.includes(
                  "global-maintenance-calendar"
                ) ? (
                  <div className="col-xxl-2 col-sm-3 pd-r-0 pd-lg-l-45 pd-xs-l-15 mg-b-45">
                    <OutSidePropertySelectBox
                      propertySlug={getPropertySlugData}
                      // outSidePropertySlug={outSidePropertySlug}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-45 pd-lg-r-30 mg-t-30">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="calendar-section pd-lg-l-0 pd-xs-l-15 maintance_modify_calander">
                      <Eventcalendar
                        theme="ios"
                        themeVariant="light"
                        clickToCreate={false}
                        dragToCreate={false}
                        dragToMove={false}
                        dragToResize={false}
                        view={calView}
                        data={events}
                        resources={resources}
                        // renderScheduleEvent={myScheduleEvent}
                        renderEvent={renderEvent}
                        cssClass="md-timeline-template md-event-listing"
                        onEventClick={(event) => openPopup(event)}
                        showEventTooltip={false}
                        // onEventHoverIn={onEventHoverIn}
                        // onEventHoverOut={onEventHoverOut}
                        renderResource={renderCustomResource}
                        renderHeader={renderMyHeader}
                        onPageLoading={onPageLoading}
                      />

                      <Popup
                        display="center"
                        // fullScreen={true}
                        contentPadding={true}
                        headerText={
                          event && event.event && event.event.title
                            ? event.event.title
                            : ""
                        }
                        isOpen={isPopupOpen}
                        onClose={closePopup}
                      >
                        <div className="text-center popup">
                          <span
                            className="popupClose hand-cursor"
                            onClick={closePopup}
                          >
                            {global.closee}
                          </span>
                          <p className="az-profile-name-text pl-0">
                          Maintenance date & time{" "}
                          </p>{" "}
                          <p className="media-body">
                            {event && event.event && event.event.view_start
                              ? event.event.view_start
                              : ""}
                          </p>
                          <p className="az-profile-name-text pl-0">
                            Request Number{" "}
                          </p>{" "}
                          <p className="media-body">
                            {event && event.event && event.event.request_number
                              ? event.event.request_number
                              : ""}
                          </p>
                          <p className="az-profile-name-text pl-0">Issue </p>{" "}
                          <p className="media-body">
                            {event && event.event && event.event.issue
                              ? event.event.issue
                              : ""}
                          </p>
                          <p className="az-profile-name-text pl-0">
                            Staff name{" "}
                          </p>{" "}
                          <p className="media-body">
                            {event && event.event && event.event.staff_name
                              ? event.event.staff_name
                              : ""}
                          </p>
                          <p className="az-profile-name-text pl-0">Status </p>{" "}
                          <p className="media-body">
                            {" "}
                            {event && event.event && event.event.status
                              ? event.event.status
                              : "Pending"}
                          </p>
                          <Link
                            to={
                              window.location.pathname.includes(
                                "global-maintenance-calendar"
                              )
                                ? "/mros/view/" +
                                  (property_slug
                                    ? property_slug
                                    : event &&
                                      event.event &&
                                      event.event.property_slug
                                    ? event.event.property_slug
                                    : "") +
                                  "/" +
                                  (event && event.event && event.event.mro_slug
                                    ? event.event.mro_slug
                                    : "")
                                : event && event.event && event.event.mro_slug
                                ? "/properties/view/" +
                                  (property_slug
                                    ? property_slug
                                    : event &&
                                      event.event &&
                                      event.event.property_slug
                                    ? event.event.property_slug
                                    : "") +
                                  "/mros/" +
                                  event.event.mro_slug
                                : ""
                            }
                          >
                            <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                              View
                            </button>
                          </Link>
                        </div>
                      </Popup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
};
export default MaintenanceCalendar;
