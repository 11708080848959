///////////////////////////////////////////////////////////
//  Program: Emails.jsx                                  //
//  Application: Emails                                  //
//  Option: add all Emails                               //
//  Developer:  Raj Kumar                                       //
//  Date: 2022-12-05                                     //
///////////////////////////////////////////////////////////

import CrmSettingSidebar from "../common/CrmSettingSidebar";
import ContactSidebar from "../common/ContactSidebar";
import Alerts from "../common/Alerts";
import ReactDatatable from "@ashvin27/react-datatable";
import React, { Component } from "react";
import { Container, Form, Tab, Nav, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import NoData from "../common/NoData";
import Select from "react-select";
import CrmCampaignService from "../../services/CrmCampaignService";
import SimpleReactValidator from "simple-react-validator";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import DatePicker from "react-datepicker";
import Moment from "moment";
import renderHTML from "react-render-html";
import JoditEditor from "jodit-react";
import { Jodit } from "jodit";
import FileUploadService from "../../services/FileUploadService";
import BrandSidebar from "../common/BrandSidebar";
import ClientSidebar from "../common/ClientSidebar";

class CrmCampaignForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    const pathStr = props.match.path;
    const pathArray = pathStr.split(":");
    this.state = {
      slug: props.match.params.crmSlug,
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : "",
      brand_slug:
        pathArray[0] === "/brand/view/" ? this.props.match.params.slug : "",
      client_slug:
        pathArray[0] === "/client/view/" ? this.props.match.params.slug : "",
      confirm_back: 0,
      input: {},
      errors: {},
      checkboxError: null,
      campaignNameError: null,
      scheduleDateError: null,
      scheduleTimeError: null,
      toEmailTestingError: null,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      showAlertModalEmail: false,
      alertModalTypeEmail: "",
      alertModalTitleEmail: "",
      alertModalMessageEmail: "",
      isSubmit: false,
      isTestEmailSubmit: false,
      activeClass: "info",
      activeClass1: "infos",
      activeClass2: "infosPreview",
      status_id: true,
      schedule_time: new Date("YYYY-MM-DDTHH:mm:ss"),
      schedule_date: new Date(),
      campaign_name: "",
      subject: "",
      dataList: [],
      records: [],
      is_checked_list: 0,
      list_slugs: [],
      is_checkbox: [],
      total_list_record: 0,
      moduleList: global.modulelist,
      moduleTypeList: global.moduleReferlist,
      appmodulelist: global.moduleReferlist,
      subjectVariableList: global.moduleReferlist,
      value_list: [],
      checked_value_list: [],
      mappingArrayList: [],
      templateList: [],
      templateSlug: "",
      onChangetemplateSlugTrue: false,
      templateSlugTrue: false,
      selectedTemplateList: [],
      resTemplateData: "",
      templateError: null,
      subjectError: null,
      bodyError: null,
      texttoError: null,
      bodytextError: null,
      bodyPopup: false,
      appbodyPopup: false,
      selectedTimezoneList: [],
      timezoneList: [],
      timezoneSlug: "",
      timezone_name: "",
      time_offset: "",
      email_to: "",
      email_from: "noreply",
      from_name: "OHAI",
      selectedHeader: [],
      headervalue: "",
      selectedFooter: [],
      footervalue: "",
      is_scheduled: 1,
      headerlogo: global.headerlogo,
      crmFooter: global.crmFooter,
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      columns: [
        {
          key: "list_slug",
          text: "Select",
          className: "list_slug hand-cursor",
          sortable: false,
          cell: (record) => {
            return (
              <div className="d-flex">
                <Form.Check
                  name="list_slug"
                  value={record.slug}
                  checked={this.state.mappingArrayList.find(
                    (item) => item === record.slug
                  )}
                  type={"checkbox"}
                  id={"checkedId" + record.slug}
                  label={""}
                  className={"ckbox "}
                  onChange={(e) => this.changeHandlerCheckbox(e, record)}
                />
              </div>
            );
          },
        },
        {
          key: "list_name",
          text: "List",
          className: "title hand-cursor text-left",
          sortable: false,
          cell: (record) => {
            return record.list_name ? record.list_name : "";
          },
        },
        {
          key: "description",
          text: "Description",
          className: "description hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.description ? record.description : "";
          },
        },
        {
          key: "list_type",
          text: "List Type",
          className: "list_type",
          sortable: false,
          cell: (record) => {
            return record.list_type === 1 ? (
              <p className="m-0">Auto</p>
            ) : record.list_type === 2 ? (
              <p className="m-0">Manual</p>
            ) : record.list_type === 3 ? (
              <p className="m-0">CSV</p>
            ) : (
              ""
            );
          },
        },
        {
          key: "total_recipients",
          text: "Recipients",
          className: "total_recipients hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.listCount ? record.listCount : "";
          },
        },

        {
          key: "status_id",
          text: "Status",
          className: "status_id",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active"></b>
            ) : (
              <b className="status-inactive"></b>
            );
          },
        },
      ],
    };
    this.editorConfig = {
      extraButtons: ["uploadImage", "uploadImageVideo"],
      width: "auto",
      height: "auto",
      minHeight: 400,
      events: {
        afterInit: (instance) => {
          this.jodit = instance;
        },
      },
    };

    this.sendTestingEmail = this.sendTestingEmail.bind(this);
    this.saveOrUpdateCampaign = this.saveOrUpdateCampaign.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerck = this.changeHandlerck.bind(this);
    this.openDeleteNearByAttractionModal =
      this.openDeleteNearByAttractionModal.bind(this);
    this.closeDeleteCampaignModal = this.closeDeleteCampaignModal.bind(this);
    this.changeHandlerCheckbox = this.changeHandlerCheckbox.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.getActiveClass1 = this.getActiveClass1.bind(this);
    this.getActiveClass2 = this.getActiveClass2.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChangeTemplateList = this.handleChangeTemplateList.bind(this);
    this.openListingPopup = this.openListingPopup.bind(this);
    this.openBodyPopup = this.openBodyPopup.bind(this);
    this.openAppBodyPopup = this.openAppBodyPopup.bind(this);
    this.setContent = this.setContent.bind(this);
    this.handleChangeTimezoneList = this.handleChangeTimezoneList.bind(this);
    this.changeHandlerScheduleTime = this.changeHandlerScheduleTime.bind(this);
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "campaignList") {
      this.setState({ isRefreshlistingImages: true });
    } else if (e === "campaignTemplates") {
      this.setState({ isRefreshlistingAmenities: true });
    } else if (e === "campaignPreview") {
      this.setState({ isRefreshlistingPrice: true });
    } else if (e === "scheduleDateTime") {
      this.setState({ isRefreshlistingPetFee: true });
    }
  }

  getActiveClass1(e) {
    this.setState({ activeClass1: e });
    if (e === "infos") {
      this.setState({ isRefreshlistingImages: true });
    } else if (e === "tempEmail") {
      this.setState({ isRefreshlistingAmenities: true });
    } else if (e === "tempAppMessage") {
      this.setState({ isRefreshlistingPrice: true });
    }
  }

  getActiveClass2(e) {
    this.setState({ activeClass2: e });
    if (e === "infosPreview") {
      this.setState({ isRefreshlistingImages: true });
    } else if (e === "tempEmailPreview") {
      this.setState({ isRefreshlistingAmenities: true });
    } else if (e === "tempAppMessagePreview") {
      this.setState({ isRefreshlistingPrice: true });
    }
  }

  componentDidMount() {
    this.getCrmListData();
    this.getCrmTemplateData();
    this.getCampaignDataFromSlug();
    this.uploadImageButton();
    this.uploadVideoButton();
    this.getTimezoneData();
  }

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: "Upload image",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.imageUpload(editor);
      },
    };
  };

  imageUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", imageFile, imageFile.name);
      fileData.append("fileName", imageFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertImage(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editor.selection.insertNode(image);
  };

  uploadVideoButton = () => {
    Jodit.defaultOptions.controls.uploadImageVideo = {
      name: "Upload video",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.videoUpload(editor);
      },
    };
  };

  videoUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = async function () {
      const videoFile = input.files[0];

      if (!videoFile) {
        return;
      }

      if (!videoFile.name.match(/\.(mp3|mp4|mov)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", videoFile, videoFile.name);
      fileData.append("fileName", videoFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertVideo(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertVideo = (editor, url) => {
    const video = editor.selection.j.createInside.element("video");
    video.setAttribute("src", url);
    editor.selection.insertNode(video);
  };

  async getCrmTemplateData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var template = [];
    var templateTotal = 0;
    let res = await CrmCampaignService.getCrmTemplateDatas(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        "&brand_slug=" +
        (this.state.brand_slug ? this.state.brand_slug : "") +
        "&client_slug=" +
        (this.state.client_slug ? this.state.client_slug : "") +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      var total = res.data.length;
      template = res.data ? res.data : [];
      templateTotal = res.data ? total : 0;
    }
    const templateList =
      templateTotal > 0
        ? template.map(({ slug, template_name }) => ({
            value: slug,
            label: template_name ? template_name : "",
          }))
        : [];
    const selectedHeader =
      templateTotal > 0 && res.data.header_logo_temp === 1
        ? [{ key: 1, value: 1, label: "Property" }]
        : templateTotal > 0 && res.data.header_logo_temp === 2
        ? [{ key: 2, value: 2, label: "Orion Haus" }]
        : [];
    const selectedFooter =
      templateTotal > 0 && res.data.footer_temp === 1
        ? [{ key: 1, value: 1, label: "Show" }]
        : templateTotal > 0 && res.data.footer_temp === 2
        ? [{ key: 2, value: 2, label: "Hide" }]
        : [];
    this.setState({
      showSpinner: false,
      loading: false,
      templateTotal: templateTotal,
      templateList: templateList,
      selectedHeader: selectedHeader,
      selectedFooter: selectedFooter,
    });
  }

  handleChangeTemplateList(value) {
    const templateSlug_value = value !== null ? value.value : "";

    this.setState({
      selectedTemplateList: value,
      templateSlug: templateSlug_value,
      onChangetemplateSlugTrue: true,
      templateSlugTrue: false,
    });
    this.setState({ confirm_back: 1 });
    this.GetTemplateBySlug(templateSlug_value);
  }

  async GetTemplateBySlug(value) {
    if (value !== undefined) {
      var resData = {};
      let res = await CrmCampaignService.getCrmTemplateBySlug(value);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.template_name = resData.template_name
          ? resData.template_name
          : "";
        resData.template_desc = resData.template_desc
          ? resData.template_desc
          : "";
        resData.subject = resData.subject ? resData.subject : "";
        resData.email_body = resData.email_body ? resData.email_body : "";
        resData.app_message_body = resData.app_message_body
          ? resData.app_message_body
          : "";
        resData.selectedHeader =
          res.data.header_logo_temp === 1
            ? [{ key: 1, value: 1, label: "Property" }]
            : res.data.header_logo_temp === 2
            ? [{ key: 2, value: 2, label: "Orion Haus" }]
            : [];
        resData.selectedFooter =
          res.data.footer_temp === 1
            ? [{ key: 1, value: 1, label: "Show" }]
            : res.data.footer_temp === 2
            ? [{ key: 2, value: 2, label: "Hide" }]
            : [];
      }
      this.setState(resData);
    }
  }

  async getCrmListData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalListRecords = 0;
    let res = await CrmCampaignService.getCrmListData(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        "&brand_slug=" +
        (this.state.brand_slug ? this.state.brand_slug : "") +
        "&client_slug=" +
        (this.state.client_slug ? this.state.client_slug : "") +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalListRecords = res.data ? res.data.total : 0;
      this.setState({ value_list: list });
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_list_record: totalListRecords,
      records: list,
    });
  }

  async getCampaignDataFromSlug() {
    if (this.state.slug !== undefined) {
      var resData = {};
      var templateSlug = false;
      let res = await CrmCampaignService.getCampaignBySlug(this.state.slug);

      if (global.successStatus.includes(res.status)) {
        var fromemail = res.data.email_from;
        var dataemail = fromemail ? fromemail.split("@") : "";
        var sDate = Moment(res.data.schedule_date).format("YYYY-MM-DD");
        resData.slug = res.data.slug ? res.data.slug : "";
        resData.campaign_name = res.data.campaign_name
          ? res.data.campaign_name
          : "";
        resData.email_from = res.data.email_from ? dataemail[0] : "";
        resData.from_name = res.data.from_name ? res.data.from_name : "";
        resData.schedule_date = res.data.schedule_date
          ? Moment(res.data.schedule_date).toDate(global.dateFormat)
          : "";
        resData.schedule_date_preview = res.data.schedule_date
          ? Moment(res.data.schedule_date).format(global.dateFormat)
          : "";
        resData.schedule_time_preview = res.data.schedule_time
          ? Moment(res.data.schedule_time, "hh:mm A").format("hh:mm A")
          : "";
        resData.scheduletime_preview = res.data.schedule_time
          ? Moment(res.data.schedule_time, "hh:mm A").format("HH:mm:00")
          : "";
        resData.schedule_time = res.data.schedule_time
          ? new Date(sDate + "T" + resData.scheduletime_preview)
          : "";

        resData.timezone_name = res.data.timezone
          ? res.data.timezone.timezone_name
          : "";
        resData.time_offset = res.data.timezone
          ? res.data.timezone.time_offset
          : "";
        resData.timezoneSlug = res.data.timezone
          ? res.data.timezone.timezone_slug
          : "";
        resData.timeZoneValidation = res.data.timezone
          ? res.data.timezone.timezone_name
          : "";
        resData.checked_value_list = res.data.crmLists ? res.data.crmLists : [];
        resData.template_name = res.data.template_name
          ? res.data.template_name
          : res.data.crmTemplate
          ? res.data.crmTemplate.template_name
          : "";
        resData.template_desc = res.data.template_desc
          ? res.data.template_desc
          : res.data.crmTemplate
          ? res.data.crmTemplate.template_desc
          : "";
        resData.subject = res.data.subject
          ? res.data.subject
          : res.data.crmTemplate
          ? res.data.crmTemplate.subject
          : "";
        resData.email_body = res.data.email_body
          ? res.data.email_body
          : res.data.crmTemplate
          ? res.data.crmTemplate.email_body
          : "";
        resData.app_message_body = res.data.app_message_body
          ? res.data.app_message_body
          : res.data.crmTemplate
          ? res.data.crmTemplate.app_message_body
          : "";
        resData.app_notification_body = res.data.app_notification_body
          ? res.data.app_notification_body
          : res.data.crmTemplate
          ? res.data.crmTemplate.app_notification_body
          : "";
        resData.test_message_body = res.data.test_message_body
          ? res.data.test_message_body
          : res.data.crmTemplate
          ? res.data.crmTemplate.test_message_body
          : "";
        resData.is_email_enable = res.data.is_email_enable
          ? res.data.is_email_enable
          : "";
        resData.is_message_enable = res.data.is_message_enable
          ? res.data.is_message_enable
          : "";
        resData.is_notification_enable = res.data.is_notification_enable
          ? res.data.is_notification_enable
          : "";
        resData.is_text_message_enable = res.data.is_text_message_enable
          ? res.data.is_text_message_enable
          : "";
        templateSlug = res.data.crmTemplate ? true : false;
        resData.templateSlug = res.data.crmTemplate
          ? res.data.crmTemplate.template_slug
          : "";
        resData.renderEmailBody = res.data.crmTemplate
          ? renderHTML(res.data.crmTemplate.email_body)
          : "";

        resData.timezoneNameOffset = res.data.timezone
          ? res.data.timezone.timezone_name +
            " (" +
            res.data.timezone.time_offset +
            ")"
          : "";
        resData.selectedHeader =
          res.data.header_logo_temp === 1
            ? [{ key: 1, value: 1, label: "Property" }]
            : res.data.header_logo_temp === 2
            ? [{ key: 2, value: 2, label: "Orion Haus" }]
            : [];
        resData.selectedFooter =
          res.data.footer_temp === 1
            ? [{ key: 1, value: 1, label: "Show" }]
            : res.data.footer_temp === 2
            ? [{ key: 2, value: 2, label: "Hide" }]
            : [];
        var selectedTemplateList = [];
        selectedTemplateList = res.data.crmTemplate
          ? [
              {
                value: res.data.crmTemplate.template_slug,
                label: res.data.crmTemplate.template_name,
              },
            ]
          : "";

        var selectedTimezoneList = [];
        selectedTimezoneList = res.data.timezone
          ? [
              {
                value: res.data.timezone.timezone_slug,
                label:
                  res.data.timezone.timezone_name +
                  " (" +
                  res.data.timezone.time_offset +
                  ")",
              },
            ]
          : "";
      }
      let mappingArrayLists = [];
      if (res.data.crmLists !== null && res.data.crmLists.length > 0) {
        res.data.crmLists.forEach((item) => {
          mappingArrayLists.push(item.list_slug);
        });
      }
      this.setState({
        campaign_name: resData.campaign_name,
        selectedTemplateList: selectedTemplateList,
        selectedTimezoneList: selectedTimezoneList,
        mappingArrayList: mappingArrayLists,
        templateSlugTrue: templateSlug,
        onChangetemplateSlugTrue: false,
      });
      this.setState(resData);
    }
  }

  async getTimezoneData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var timezone = [];
    var timeZoneTotal = 0;
    let res = await CrmCampaignService.getTimezoneDatas(queryString);
    if (global.successStatus.includes(res.status)) {
      var total = res.data.length;
      timezone = res.data ? res.data : [];
      timeZoneTotal = res.data ? total : 0;
    }
    const timezoneList =
      timeZoneTotal > 0
        ? timezone.map((item) => ({
            value: item.slug,
            label: item.timezone_name + " (" + item.time_offset + ")",
            value1: item.timezone_name,
          }))
        : [];
    this.setState({
      showSpinner: false,
      loading: false,
      timeZoneTotal: timeZoneTotal,
      timezoneList: timezoneList,
      timezone_name: timezoneList.label,
    });
  }

  handleChangeTimezoneList(value) {
    const timezoneSlug_value = value !== null ? value.value : "";
    this.setState({
      selectedTimezoneList: value,
      timezoneSlug: timezoneSlug_value,
      onChangetimezoneSlugTrue: true,
      timezoneSlugTrue: false,
      timezone_name: value.label,
      timezoneNameOffset: value.label,
      timeZoneValidation: "",
    });
    this.setState({ confirm_back: 1 });
  }

  validate() {
    let campaignNameError = "";

    if (!this.state.campaign_name) {
      campaignNameError = "This field is required.";
    }
    if (campaignNameError) {
      this.setState({ campaignNameError });
      return false;
    }

    return true;
  }

  saveOrUpdateCampaign = async (e) => {
    e.preventDefault();
    this.setState({
      alertModalMessage: "",
    });

    if (this.validate()) {
      this.setState({ loading: true, isSubmit: true });
      let inputData = {
        slug: this.state.slug ? this.state.slug : "",
        campaign_name: this.state.campaign_name ? this.state.campaign_name : "",
        email_from: this.state.email_from
          ? this.state.email_from + "@orionhaus.com"
          : "",
        from_name: this.state.from_name ? this.state.from_name : "",
        schedule_date: this.state.schedule_date
          ? Moment(this.state.schedule_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        schedule_time: this.state.schedule_time
          ? Moment(this.state.schedule_time, "MM-dd-YY h:m:s A").format(
              "hh:mm A"
            )
          : "",
        timezone: this.state.timezoneSlug ? this.state.timezoneSlug : "",
        crm_template_id: this.state.templateSlug ? this.state.templateSlug : "",
        mappingArrayList: this.state.mappingArrayList
          ? this.state.mappingArrayList
          : "",
        status_id: this.state.status_id ? this.state.status_id : 2,

        template_name: this.state.template_name ? this.state.template_name : "",
        template_desc: this.state.template_desc ? this.state.template_desc : "",
        subject: this.state.subject ? this.state.subject : "",
        email_body: this.state.email_body ? this.state.email_body : "",
        app_message_body: this.state.app_message_body
          ? this.state.app_message_body
          : "",
        test_message_body: this.state.test_message_body
          ? this.state.test_message_body
          : "",
        app_notification_body: this.state.app_notification_body
          ? this.state.app_notification_body
          : "",
        is_scheduled: this.state.is_scheduled ? this.state.is_scheduled : "",
        propertySlug: this.state.property_slug ? this.state.property_slug : "",
        brandSlug: this.state.brand_slug ? this.state.brand_slug : "",
        clientSlug: this.state.client_slug ? this.state.client_slug : "",
        crmType: this.state.property_slug
          ? 2
          : this.state.brand_slug
          ? 3
          : this.state.client_slug
          ? 4
          : 1,
        is_message_enable: this.state.is_message_enable ? 1 : 0,
        is_email_enable: this.state.is_email_enable ? 1 : 0,
        is_notification_enable: this.state.is_notification_enable ? 1 : 0,
        is_text_message_enable: this.state.is_text_message_enable ? 1 : 0,
        header_logo_temp: this.state.headervalue
          ? this.state.headervalue
          : this.state.selectedHeader[0].value,
        footer_temp: this.state.footervalue
          ? this.state.footervalue
          : this.state.selectedFooter[0].value,
      };

      if (this.state.slug !== undefined) {
        this.setState({ loading: true, isSubmit: true });
        let res = await CrmCampaignService.updateCrmCampaign(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.state.property_slug
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/crm-campaign"
                  : this.state.brand_slug
                  ? "/brand/view/" + this.state.brand_slug + "/crm-campaign"
                  : this.state.client_slug
                  ? "/client/view/" + this.state.client_slug + "/crm-campaign"
                  : "/crm-campaign"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        this.setState({ loading: true, isSubmit: true });
        let res = await CrmCampaignService.createCrmCampaign(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.state.property_slug
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/crm-campaign"
                  : this.state.brand_slug
                  ? "/brand/view/" + this.state.brand_slug + "/crm-campaign"
                  : this.state.client_slug
                  ? "/client/view/" + this.state.client_slug + "/crm-campaign"
                  : "/crm-campaign"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ loading: false, isSubmit: false });
      this.validator.showMessages();
    }
  };

  validateEmailTestingField() {
    let toEmailTestingError = "";

    if (!this.state.email_to) {
      toEmailTestingError = "This field is required.";
    }
    if (toEmailTestingError) {
      this.setState({ toEmailTestingError });
      return false;
    }
    return true;
  }

  async sendTestingEmail() {
    if (this.validateEmailTestingField()) {
      this.setState({ loading: true, isTestEmailSubmit: true });
      let inputData = {
        slug: this.state.slug ? this.state.slug : "",
        campaign_name: this.state.campaign_name ? this.state.campaign_name : "",
        email_from: this.state.email_from
          ? this.state.email_from + "@orionhaus.com"
          : "",
        from_name: this.state.from_name ? this.state.from_name : "",
        template_name: this.state.template_name ? this.state.template_name : "",
        subject: this.state.subject ? this.state.subject : "",
        email_body: this.state.email_body ? this.state.email_body : "",
        app_message_body: this.state.app_message_body
          ? this.state.app_message_body
          : "",
        email_to: this.state.email_to ? this.state.email_to : "",
      };

      this.setState({ showSpinner: true, isTestEmailSubmit: true });
      var res = await CrmCampaignService.testSendEmail(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModalEmail: true,
          alertModalTypeEmail: "success",
          alertModalTitleEmail: "Success",
          alertModalMessageEmail: res.message ? res.message : "Success",
        });
        this.setState({ isSearchModal: 0 });
        this.setState({ showSpinner: false, isTestEmailSubmit: false });
        setTimeout(
          () => this.setState({ showAlertModalEmail: false }),
          global.alert_close_time
        );
      } else {
        if (res.data.result.errorDetail) {
          this.setState({
            showAlertModalEmail: true,
            alertModalTypeEmail: "Error",
            alertModalTitleEmail: "Error",
            alertModalMessageEmail: res.data.message
              ? res.data.message
              : "Error!",
          });

          this.setState({ isSearchModal: 0 });
          this.setState({ showSpinner: false, isTestEmailSubmit: false });
          setTimeout(
            () => this.setState({ showAlertModalEmail: false }),
            global.alert_close_time
          );
        }
        this.setState({
          showAlertModalEmail: true,
          alertModalTypeEmail: "Error",
          alertModalTitleEmail: "Error",
          alertModalMessageEmail: res.data.message
            ? res.data.message
            : "Error!",
        });
      }
      setTimeout(
        () => this.setState({ showAlertModalEmail: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ loading: false, isTestEmailSubmit: false });
      this.validator.showMessages();
    }
  }

  changeHandlerCheckbox(event, record) {
    let prevState = [...this.state.value_list];
    prevState[record.item_index] = event.target.checked === true ? 1 : 0;
    this.setState({ prevState });
    if (event.target.checked) {
      let array = this.state.mappingArrayList;
      array.push(record.slug);
      this.setState({
        mappingArrayList: array,
      });
    } else {
      var array = this.state.mappingArrayList;
      var index = array.indexOf(record.slug);
      array.splice(index, 1);
      this.setState({
        mappingArrayList: array,
      });
    }
    if (this.state.checked_value_list.length > 0) {
      var ListArray = this.state.checked_value_list;
      var indexs = ListArray.indexOf(record.slug);
      ListArray.splice(indexs, 1);
      this.setState({
        checked_value_list: ListArray,
      });
    }
  }

  handleCheckboxChange = (e, slug) => {
    var value = e.target.checked ? 1 : 0;
    const dataList = [...this.state.dataList];
    if (value === 1) {
      dataList.push(slug);
    }
    this.setState({ dataList: dataList });
  };

  openListingPopup = () => {
    if (this.state.listingPopup === true) {
      this.setState({ listingPopup: false });
    } else {
      this.setState({ listingPopup: true });
    }
  };

  openBodyPopup = () => {
    if (this.state.bodyPopup === true) {
      this.setState({ bodyPopup: false });
    } else {
      this.setState({ bodyPopup: true });
    }
  };

  openAppBodyPopup = () => {
    if (this.state.appbodyPopup === true) {
      this.setState({ appbodyPopup: false });
    } else {
      this.setState({ appbodyPopup: true });
    }
  };

  changeHandlerScheduled = (event) => {
    let rdoBtnVal = event.target.type === "radio" ? event.target.value : "";
    this.setState({
      is_scheduled: rdoBtnVal,
    });
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? value : value;

    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
    });

    if (this.state.slug === undefined) {
      var dayArr = [...this.state.is_checkbox];
      var checked = event.target.checked;
      if (checked === false) {
        dayArr = [...dayArr.slice(0, -1)];
      } else if (checked === true) {
        dayArr.push(value);
      }
      this.setState({ is_checkbox: dayArr });
    }
    this.setState({ confirm_back: 1 });
  };

  handleChangemodulereferListemail(value) {
    let newTypeList12 = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList12.push(item.value);
      });
    }
    let dynmicvariable = value.label;
    this.setState({
      selectedModulleRferList: value,
      dynmicvariable: dynmicvariable,
    });
  }

  insertbodyemail = (e) => {
    var dynamictext = this.state.body_variable;
    this.jodit.selection.insertHTML("[" + dynamictext + "]");
    this.setState({
      bodyPopup: false,
      body_variable: "",
    });
  };

  handleChangeSubjectVariableList(value) {
    let newTypeList13 = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList13.push(item.value);
      });
    }
    let dynmicvariablesbject = value.label;
    this.setState({
      selectedSubjectList: value,
      dynmicvariablesbject: dynmicvariablesbject,
    });
  }

  insertsubject = (e) => {
    let textToInsert = " [" + this.state.subject_variable + "]";
    this.setState({
      subject: this.state.subject
        ? `${this.state.subject} ${textToInsert}`
        : `${textToInsert}`,
    });
    this.setState({ listingPopup: false });
  };

  handleChangeBodyVariableAppMessage(value) {
    let newTypeList13 = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList13.push(item.value);
      });
    }
    let dynmicvariablesbject = value.label;
    this.setState({
      selectedappmessagevariable: value,
      dynmicvariablesbject: dynmicvariablesbject,
    });
  }

  insertappmessage = (e) => {
    let textToInsert = " [" + this.state.app_body_variable + "]";
    this.setState({
      app_message_body: this.state.app_message_body
        ? `${this.state.app_message_body} ${textToInsert}`
        : `${textToInsert}`,
    });
    this.setState({
      appbodyPopup: false,
      app_body_variable: "",
    });
  };

  handleChangetextmessagevariable(value) {
    let newTypeList13 = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList13.push(item.value);
      });
    }
    let dynmicvariablesbject = value.label;
    this.setState({
      selectedtextmessagevariable: value,
      dynmicvariablesbject: dynmicvariablesbject,
    });
  }

  openDeleteNearByAttractionModal(slug) {
    this.setState({ deleteNearByAttractionModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteCampaignModal() {
    this.setState({ deleteNearByAttractionModal: false });
    this.setState({ slug: "" });
  }

  handleChangeHeader(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedHeader: value,
      headervalue: type,
    });
    this.setState({ headerError: "" });
  }

  handleChangeFooter(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedFooter: value,
      footervalue: type,
    });
    this.setState({ footerError: "" });
  }

  async deleteCampaign(slug) {
    this.setState({ showSpinner: true });
    let res = await CrmCampaignService.deleteCrmCampaign(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteCampaignModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      setTimeout(
        () =>
          this.props.history.push(
            this.state.property_slug
              ? "/properties/view/" + this.state.property_slug + "/crm-campaign"
              : this.state.brand_slug
              ? "/brand/view/" + this.state.brand_slug + "/crm-campaign"
              : this.state.client_slug
              ? "/client/view/" + this.state.client_slug + "/crm-campaign"
              : "/crm-campaign"
          ),
        global.redirect_time
      );
    } else {
      this.closeDeleteCampaignModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandlerScheduleTime(data) {
    let nowD = new Date();
    let timezoneD = Moment.tz(
      nowD,
      "MM-dd-YY h:m:s A",
      this.state.timeZoneValidation
        ? this.state.timeZoneValidation
        : this.state.selectedTimezoneList.value1
    ).format("YYYY-MM-DD HH:mm:00");
    let SelectedTime = Moment(data, "MM-dd-YY h:m:s A").format(
      "YYYY-MM-DD HH:mm:00"
    );

    let timezoneNow = timezoneD.replace(/ /g, "T");
    let SelectedTimeNow = SelectedTime.replace(/ /g, "T");
    let diffDT = new Date(SelectedTimeNow) - new Date(timezoneNow);
    let dateTime30 = Math.floor(diffDT / 1000 / 60);

    if (SelectedTime > timezoneD && dateTime30 > 30) {
      this.setState({
        schedule_time: data,
        schedule_time_preview: data
          ? Moment(data, "MM-dd-YY h:m:s A").format("hh:mm A")
          : "",
        timeValidation: "",
      });
    } else {
      this.setState({
        timeValidation:
          "The schedule time should be 30 minutes greater than current time.",
        schedule_time_preview: "",
      });
    }
  }

  changeHandlerScheduleDate(data) {
    var sDate = Moment(data).format("YYYY-MM-DD");
    var scheduleTitePreview = Moment(
      this.state.schedule_time_preview,
      "hh:mm A"
    ).format("HH:mm:00");
    this.setState({
      schedule_date: data,
      schedule_date_preview: data
        ? Moment(data, "MM-dd-YY").format("MM/DD/YY")
        : "",
      schedule_time: new Date(sDate + "T" + scheduleTitePreview),
    });
  }
  
  changeHandlerck(event, index) {
    let value = event.target.value;
    this.setState({
      email_body: value,
    });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
  }

  setContent(value) {
    this.setState({
      email_body: value,
      renderEmailBody: value ? renderHTML(value) : "",
    });
  }
  
  changeHandlerEmailTest = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
      email_to: value,
    });
  };

  render() {
    var selectedListPreview = [];
    if (
      this.state.records !== null &&
      this.state.records.length > 0 &&
      this.state.mappingArrayList.length > 0
    ) {
      this.state.records.forEach((data) => {
        this.state.mappingArrayList.find((item) =>
          item === data.slug ? selectedListPreview.push(data) : ""
        );
      });
    }
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    {this.state.property_slug ? (
                      <ContactSidebar
                        property_slug={this.state.property_slug}
                      />
                    ) : this.state.brand_slug ? (
                      <BrandSidebar brand_slug={this.state.brand_slug} />
                    ) : this.state.client_slug ? (
                      <ClientSidebar clientSlug={this.state.client_slug} />
                    ) : (
                      <CrmSettingSidebar />
                    )}
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel">
                  <div className="col-md-12 mg-t-0 mg-b-0 pd-t-0 pd-b-0 pd-l-15 pd-r-0">
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Campaign</h3>

                          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                            {this.state.slug !== undefined ? (
                              <>
                                {global.userPermissions.checkPermission(
                                  "crm-campaigns-delete"
                                ) && (
                                  <span
                                    onClick={() => {
                                      this.openDeleteNearByAttractionModal(
                                        this.state.slug
                                      );
                                    }}
                                    className="deleteicon mg-r-20"
                                  >
                                    <img src={deleteicon} alt="" />
                                  </span>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <Link
                              to={
                                this.state.property_slug
                                  ? "/properties/view/" +
                                    this.state.property_slug +
                                    "/crm-campaign"
                                  : this.state.brand_slug
                                  ? "/brand/view/" +
                                    this.state.brand_slug +
                                    "/crm-campaign"
                                  : this.state.client_slug
                                  ? "/client/view/" +
                                    this.state.client_slug +
                                    "/crm-campaign"
                                  : "/crm-campaign"
                              }
                            >
                              <button className="btn-success-outline mg-r-20">
                                Cancel
                              </button>
                            </Link>
                            <button
                              type="button"
                              className="btn-success-outline-small ml-3 button-width"
                              onClick={this.saveOrUpdateCampaign}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : this.state.slug
                                ? "Save"
                                : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="scrolling-carousel ">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="info"
                        onSelect={(e) => this.getActiveClass(e)}
                        eventKey={this.state.activeClass}
                      >
                        <ScrollingCarousel
                          rightArrow={true}
                          leftArrow={true}
                          className="swipesection scroll_nav_mob pd-t-20"
                        >
                          <Nav.Item>
                            <Nav.Link
                              eventKey="info"
                              id="infoForms"
                              className={
                                this.state.activeClass === "info"
                                  ? "active"
                                  : ""
                              }
                            >
                              Info
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link
                              eventKey="campaignList"
                              id="campaignList"
                              className={
                                this.state.activeClass === "campaignList"
                                  ? "active"
                                  : ""
                              }
                            >
                              Lists
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="campaignTemplates"
                              id="campaignTemplates"
                              className={
                                this.state.activeClass === "campaignTemplates"
                                  ? "active"
                                  : ""
                              }
                            >
                              Template
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="campaignPreview"
                              id="campaignPreview"
                              className={
                                this.state.activeClass === "campaignPreview"
                                  ? "active"
                                  : ""
                              }
                            >
                              Test
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="scheduleDateTime"
                              id="scheduleDateTime"
                              className={
                                this.state.activeClass === "scheduleDateTime"
                                  ? "active"
                                  : ""
                              }
                            >
                              Schedule
                            </Nav.Link>
                          </Nav.Item>
                        </ScrollingCarousel>
                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15 ">
                          <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                          />
                        </div>
                        <Tab.Content>
                          <Tab.Pane eventKey="info">
                            <div className="row align-items-center pd-r-15 pd-l-15 pd-b-0 border-bottom-0 mg-t-30">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                <label className="form-label text-left ">
                                  Campaign
                                </label>
                                <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="campaign_name"
                                  value={this.state.campaign_name}
                                  maxLength="50"
                                  autoComplete="off"
                                />
                                {this.state.errors.campaign_name ? (
                                  <div className="text-danger">
                                    {this.state.errors.campaign_name}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "campaign",
                                    this.state.campaign_name,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}
                              </div>
                            </div>
                            <div className="row align-items-center pd-r-15 pd-l-15 pd-b-0 border-bottom-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                <div className="col-md-1 pl-0">
                                  <label className="form-label text-left ">
                                    Status
                                  </label>
                                </div>

                                <div className="col-8 col-md-7 col-lg-7 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                                  <b
                                    className={
                                      this.state.status_id
                                        ? "active"
                                        : "inactive"
                                    }
                                  ></b>
                                  <div
                                    className={
                                      this.state.status_id
                                        ? "az-toggle on"
                                        : "az-toggle"
                                    }
                                    onClick={() => this.changeStatus()}
                                  >
                                    <span></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="campaignList">
                            <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-0 pd-lg-r-0">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                {this.state.total_list_record > 0 ||
                                this.state.filter_value !== "" ? (
                                  <div className="mg-t-0 brandList">
                                    <ReactDatatable
                                      className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                      config={
                                        this.state.total_list_record >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      total_list_record={
                                        this.state.total_list_record
                                      }
                                      onChange={this.tableChangeHandler}
                                    />
                                  </div>
                                ) : (
                                  <NoData msg="active lists" />
                                )}
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="campaignTemplates"
                            className="mg-t-30"
                          >
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Select Template
                                </label>
                                <Select
                                  styles={customStyles}
                                  isClearable={true}
                                  className="multiselect"
                                  menuPlacement="auto"
                                  value={this.state.selectedTemplateList}
                                  options={this.state.templateList}
                                  onChange={(value) =>
                                    this.handleChangeTemplateList(value)
                                  }
                                  defaultValue={this.state.selectedTemplateList}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />
                              </div>
                            </div>
                            {this.state.templateSlugTrue === true ||
                            this.state.onChangetemplateSlugTrue === true ? (
                              <div className="IfSubmit">
                                <Tab.Container
                                  id="left-tabs-examples"
                                  defaultActiveKey="infos"
                                  onSelect={(e) => this.getActiveClass1(e)}
                                  eventKey={this.state.activeClass1}
                                >
                                  <ScrollingCarousel
                                    rightArrow={true}
                                    leftArrow={true}
                                    className="swipesection scroll_nav_mob"
                                  >
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey="infos"
                                        id="infoForm"
                                        className={
                                          this.state.activeClass1 === "infos"
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        Info
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey="tempEmail"
                                        id="tempEmail"
                                        className={
                                          this.state.activeClass1 ===
                                          "tempEmail"
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        Email
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey="tempAppMessage"
                                        id="tempAppMessage"
                                        className={
                                          this.state.activeClass1 ===
                                          "tempAppMessage"
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        App Message
                                      </Nav.Link>
                                    </Nav.Item>
                                  </ScrollingCarousel>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="infos">
                                      <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                        <h3 className="mg-l-5 mg-t-15 background-head d-flex  align-items-center justify-content-start">
                                          Info
                                        </h3>
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                        <label className="form-label text-left ">
                                          Template
                                        </label>
                                        <Form.Control
                                          type="text"
                                          onChange={this.changeHandler}
                                          name="template_name"
                                          value={this.state.template_name}
                                          maxLength="50"
                                          //tabIndex="1"
                                          autoComplete="off"
                                        />
                                        <div className="text-danger">
                                          {this.state.templateError}
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                        <label className="form-label text-left ">
                                          Description
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>
                                        <Form.Control
                                          onChange={this.changeHandler}
                                          name="template_desc"
                                          value={this.state.template_desc}
                                          className="form-control max_width_100"
                                          id="description"
                                          as="textarea"
                                          rows="3"
                                        />
                                      </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tempEmail">
                                      <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                        <h3 className="mg-l-5 mg-t-15 background-head d-flex  align-items-center justify-content-start">
                                          <div>
                                            <Form.Check
                                              onChange={this.changeHandler}
                                              name="is_email_enable"
                                              checked={
                                                this.state.is_email_enable
                                              }
                                              type={"checkbox"}
                                              id="emailcheckbox"
                                              value="1"
                                              label={""}
                                              className={"ckbox mg-r-10"}
                                            />
                                          </div>
                                          Email
                                        </h3>
                                      </div>
                                      {this.state.is_email_enable === true ||
                                      this.state.is_email_enable === 1 ? (
                                        <>
                                          <div className="mg-t-18 brandList table_issue_roles cred_drop_issue">
                                            <div className=" ">
                                              <div className="row ">
                                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-45">
                                                  <div className="flex-wrap- justify-content-between align-items-center">
                                                    <label className="form-label text-left ">
                                                      Sender Mail
                                                    </label>
                                                    <div className="input-group-prepend prefix-dollar percanteg-prefix mail-right-sym">
                                                      <Form.Control
                                                        type="text"
                                                        onChange={
                                                          this.changeHandler
                                                        }
                                                        name="email_from"
                                                        value={
                                                          this.state.email_from
                                                        }
                                                        maxLength="50"
                                                        autoComplete="off"
                                                      />
                                                      <span className="input-group-text percanteg_symbl">
                                                        @orionhaus.com
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row ">
                                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-45">
                                                  <div className="flex-wrap- justify-content-between align-items-center">
                                                    <label className="form-label text-left ">
                                                      Sender Name
                                                    </label>
                                                    <div className="input-group-prepend prefix-dollar percanteg-prefix mail-right-sym">
                                                      <Form.Control
                                                        type="text"
                                                        onChange={
                                                          this.changeHandler
                                                        }
                                                        name="from_name"
                                                        value={
                                                          this.state.from_name
                                                        }
                                                        maxLength="50"
                                                        autoComplete="off"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row ">
                                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-45">
                                                  <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                                    <label className="form-label text-left ">
                                                      Subject
                                                    </label>
                                                  </div>
                                                  <Form.Control
                                                    onChange={
                                                      this.changeHandler
                                                    }
                                                    type="text"
                                                    name="subject"
                                                    value={this.state.subject}
                                                    autoComplete="off"
                                                    id="subjecttext"
                                                  />
                                                  <div className="text-danger">
                                                    {this.state.subjectError}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row ">
                                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-45">
                                                  <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                                    <label className="form-label text-left ">
                                                      Header Logo{" "}
                                                    </label>
                                                  </div>
                                                  <Select
                                                    styles={customStyles}
                                                    // isClearable={true}
                                                    className="multiselect"
                                                    menuPlacement="auto"
                                                    value={
                                                      this.state.selectedHeader
                                                    }
                                                    options={
                                                      this.state.headerlogo
                                                    }
                                                    onChange={(value) =>
                                                      this.handleChangeHeader(
                                                        value
                                                      )
                                                    }
                                                    defaultValue={
                                                      this.state.selectedHeader
                                                    }
                                                    theme={(theme) => ({
                                                      ...theme,
                                                      borderRadius: 2,
                                                      colors: {
                                                        ...theme.colors,
                                                        primary: "#fff",
                                                        primary75: "#000",
                                                        primary50: "#000",
                                                        primary25: "#000",
                                                      },
                                                    })}
                                                  />
                                                </div>
                                              </div>
                                              <div className="row ">
                                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-45">
                                                  <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                                    <label className="form-label text-left ">
                                                      Footer{" "}
                                                    </label>
                                                  </div>
                                                  <Select
                                                    styles={customStyles}
                                                    // isClearable={true}
                                                    className="multiselect"
                                                    menuPlacement="auto"
                                                    value={
                                                      this.state.selectedFooter
                                                    }
                                                    options={
                                                      this.state.crmFooter
                                                    }
                                                    onChange={(value) =>
                                                      this.handleChangeFooter(
                                                        value
                                                      )
                                                    }
                                                    defaultValue={
                                                      this.state.selectedFooter
                                                    }
                                                    theme={(theme) => ({
                                                      ...theme,
                                                      borderRadius: 2,
                                                      colors: {
                                                        ...theme.colors,
                                                        primary: "#fff",
                                                        primary75: "#000",
                                                        primary50: "#000",
                                                        primary25: "#000",
                                                      },
                                                    })}
                                                  />
                                                </div>
                                              </div>
                                              <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30 jodit_cnt_box">
                                                  <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                                    <label className="form-label text-left">
                                                      Body
                                                    </label>
                                                    <button
                                                      className="btn btn_var_temp mg-b-5"
                                                      onClick={
                                                        this.openBodyPopup
                                                      }
                                                    >
                                                      Body Variable
                                                    </button>
                                                  </div>
                                                  <JoditEditor
                                                    config={this.editorConfig}
                                                    ref={null}
                                                    name="email_body"
                                                    id="email_body"
                                                    value={
                                                      this.state.email_body
                                                    }
                                                    onBlur={(newContent) =>
                                                      this.setContent(
                                                        newContent
                                                      )
                                                    }
                                                    onChange={(newContent) =>
                                                      this.setContent(
                                                        newContent
                                                      )
                                                    }
                                                  />

                                                  {this.state.errors
                                                    .email_body ? (
                                                    <div className="text-danger">
                                                      {
                                                        this.state.errors
                                                          .email_body
                                                      }
                                                    </div>
                                                  ) : (
                                                    this.validator.message(
                                                      "body",
                                                      this.state.email_body,
                                                      "required",
                                                      {
                                                        className:
                                                          "text-danger",
                                                      }
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tempAppMessage">
                                      <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                        <h3 className="mg-l-5 mg-t-15 background-head d-flex align-items-center justify-content-start">
                                          <div>
                                            <Form.Check
                                              onChange={this.changeHandler}
                                              name="is_message_enable"
                                              checked={
                                                this.state.is_message_enable
                                              }
                                              type={"checkbox"}
                                              id="appmessagecheckbox"
                                              value="1"
                                              label={""}
                                              className={"ckbox mg-r-10"}
                                            />
                                          </div>
                                          App Message
                                        </h3>
                                      </div>
                                      {this.state.is_message_enable === true ||
                                      this.state.is_message_enable === 1 ? (
                                        <>
                                          <div className="mg-t-18 brandList table_issue_roles cred_drop_issue">
                                            <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                                <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                                  <label className="form-label text-left ">
                                                    Body
                                                  </label>
                                                  <button
                                                    className="btn btn_var_temp mg-b-5"
                                                    onClick={
                                                      this.openAppBodyPopup
                                                    }
                                                  >
                                                    Body Variable
                                                  </button>
                                                </div>
                                                <Form.Control
                                                  onChange={this.changeHandler}
                                                  name="app_message_body"
                                                  value={
                                                    this.state.app_message_body
                                                  }
                                                  className="form-control max_width_100"
                                                  type="text"
                                                  rows="13"
                                                  as="textarea"
                                                  autoComplete="off"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Tab.Container>
                              </div>
                            ) : (
                              <div className="IfSubmit">
                                <Tab.Container
                                  id="left-tabs-examples"
                                  defaultActiveKey="infos"
                                  onSelect={(e) => this.getActiveClass1(e)}
                                  eventKey={this.state.activeClass1}
                                >
                                  <ScrollingCarousel
                                    rightArrow={true}
                                    leftArrow={true}
                                    className="swipesection scroll_nav_mob"
                                  >
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey="infos"
                                        id="infoForm"
                                        className={
                                          this.state.activeClass1 === "infos"
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        Info
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey="tempEmail"
                                        id="tempEmail"
                                        className={
                                          this.state.activeClass1 ===
                                          "tempEmail"
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        Email
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey="tempAppMessage"
                                        id="tempAppMessage"
                                        className={
                                          this.state.activeClass1 ===
                                          "tempAppMessage"
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        App Message
                                      </Nav.Link>
                                    </Nav.Item>
                                  </ScrollingCarousel>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="infos">
                                      <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                        <h3 className="mg-l-5 mg-t-15 background-head d-flex  align-items-center justify-content-start">
                                          Info
                                        </h3>
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                        <label className="form-label text-left ">
                                          Template
                                        </label>
                                        <Form.Control
                                          type="text"
                                          onChange={this.changeHandler}
                                          name="template_name"
                                          value={this.state.template_name}
                                          maxLength="50"
                                          //tabIndex="1"
                                          autoComplete="off"
                                        />
                                        <div className="text-danger">
                                          {this.state.templateError}
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                        <label className="form-label text-left ">
                                          Description
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>
                                        <Form.Control
                                          onChange={this.changeHandler}
                                          name="template_desc"
                                          value={this.state.template_desc}
                                          className="form-control max_width_100"
                                          id="description"
                                          as="textarea"
                                          rows="3"
                                        />
                                      </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tempEmail">
                                      <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                        <h3 className="mg-l-5 mg-t-15 background-head d-flex  align-items-center justify-content-start">
                                          <div>
                                            <Form.Check
                                              onChange={this.changeHandler}
                                              name="is_email_enable"
                                              checked={
                                                this.state.is_email_enable
                                              }
                                              type={"checkbox"}
                                              id="emailcheckbox"
                                              value="1"
                                              label={""}
                                              className={"ckbox mg-r-10"}
                                            />
                                          </div>
                                          Email
                                        </h3>
                                      </div>
                                      {this.state.is_email_enable === true ||
                                      this.state.is_email_enable === 1 ? (
                                        <>
                                          <div className="mg-t-18 brandList table_issue_roles cred_drop_issue">
                                            <div className=" ">
                                              <div className="row ">
                                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-45">
                                                  <div className="flex-wrap- justify-content-between align-items-center">
                                                    <label className="form-label text-left ">
                                                      Sender Mail
                                                    </label>
                                                    <div className="input-group-prepend prefix-dollar percanteg-prefix mail-right-sym">
                                                      <Form.Control
                                                        type="text"
                                                        onChange={
                                                          this.changeHandler
                                                        }
                                                        name="email_from"
                                                        value={
                                                          this.state.email_from
                                                        }
                                                        maxLength="50"
                                                        autoComplete="off"
                                                      />
                                                      <span className="input-group-text percanteg_symbl">
                                                        @orionhaus.com
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row ">
                                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-45">
                                                  <div className="flex-wrap- justify-content-between align-items-center">
                                                    <label className="form-label text-left ">
                                                      Sender Name
                                                    </label>
                                                    <div className="input-group-prepend prefix-dollar percanteg-prefix mail-right-sym">
                                                      <Form.Control
                                                        type="text"
                                                        onChange={
                                                          this.changeHandler
                                                        }
                                                        name="from_name"
                                                        value={
                                                          this.state.from_name
                                                        }
                                                        maxLength="50"
                                                        autoComplete="off"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row ">
                                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-45">
                                                  <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                                    <label className="form-label text-left ">
                                                      Subject
                                                    </label>
                                                  </div>
                                                  <Form.Control
                                                    onChange={
                                                      this.changeHandler
                                                    }
                                                    type="text"
                                                    name="subject"
                                                    value={this.state.subject}
                                                    autoComplete="off"
                                                    id="subjecttext"
                                                  />
                                                  <div className="text-danger">
                                                    {this.state.subjectError}
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30 jodit_cnt_box">
                                                  <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                                    <label className="form-label text-left">
                                                      Body
                                                    </label>
                                                    <button
                                                      className="btn btn_var_temp mg-b-5"
                                                      onClick={
                                                        this.openBodyPopup
                                                      }
                                                    >
                                                      Body Variable
                                                    </button>
                                                  </div>
                                                  <JoditEditor
                                                    config={this.editorConfig}
                                                    ref={null}
                                                    name="email_body"
                                                    id="email_body"
                                                    value={
                                                      this.state.email_body
                                                    }
                                                    onBlur={(newContent) =>
                                                      this.setContent(
                                                        newContent
                                                      )
                                                    }
                                                    onChange={(newContent) =>
                                                      this.setContent(
                                                        newContent
                                                      )
                                                    }
                                                  />

                                                  {this.state.errors
                                                    .email_body ? (
                                                    <div className="text-danger">
                                                      {
                                                        this.state.errors
                                                          .email_body
                                                      }
                                                    </div>
                                                  ) : (
                                                    this.validator.message(
                                                      "body",
                                                      this.state.email_body,
                                                      "required",
                                                      {
                                                        className:
                                                          "text-danger",
                                                      }
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tempAppMessage">
                                      <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                        <h3 className="mg-l-5 mg-t-15 background-head d-flex align-items-center justify-content-start">
                                          <div>
                                            <Form.Check
                                              onChange={this.changeHandler}
                                              name="is_message_enable"
                                              checked={
                                                this.state.is_message_enable
                                              }
                                              type={"checkbox"}
                                              id="appmessagecheckbox"
                                              value="1"
                                              label={""}
                                              className={"ckbox mg-r-10"}
                                            />
                                          </div>
                                          App Message
                                        </h3>
                                      </div>
                                      {this.state.is_message_enable === true ||
                                      this.state.is_message_enable === 1 ? (
                                        <>
                                          <div className="mg-t-18 brandList table_issue_roles cred_drop_issue">
                                            <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                                <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                                  <label className="form-label text-left ">
                                                    Body
                                                  </label>
                                                  <button
                                                    className="btn btn_var_temp mg-b-5"
                                                    onClick={
                                                      this.openAppBodyPopup
                                                    }
                                                  >
                                                    Body Variable
                                                  </button>
                                                </div>
                                                <Form.Control
                                                  onChange={this.changeHandler}
                                                  name="app_message_body"
                                                  value={
                                                    this.state.app_message_body
                                                  }
                                                  className="form-control max_width_100"
                                                  type="text"
                                                  rows="13"
                                                  as="textarea"
                                                  autoComplete="off"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Tab.Container>
                              </div>
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="scheduleDateTime">
                            {this.state.is_scheduled === 1 ? (
                              <>
                                <div className="row align-items-start pd-r-15 pd-l-15 pd-b-0 border-bottom-0 mg-t-30">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                    <label className="form-label text-left ">
                                      Select Timezone
                                    </label>
                                    <Select
                                      styles={customStyles}
                                      isClearable={true}
                                      className="multiselect"
                                      menuPlacement="auto"
                                      value={this.state.selectedTimezoneList}
                                      options={this.state.timezoneList}
                                      onChange={(value) =>
                                        this.handleChangeTimezoneList(value)
                                      }
                                      defaultValue={
                                        this.state.selectedTimezoneList
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                    <label className="form-label text-left ">
                                      Date/Time
                                    </label>
                                    <div className="col-6 col-md-8 col-lg-8 col-xl-8 col-xxl-8 p-0 float-left">
                                      <DatePicker
                                        className="form-control max_width_100"
                                        disabledKeyboardNavigation
                                        strictParsing
                                        scrollableYearDropdown
                                        showMonthDropdown
                                        showYearDropdown
                                        minDate={new Date()}
                                        autoComplete="off"
                                        yearDropdownItemNumber={
                                          global.yearDropdownItemNumber
                                        }
                                        name="schedule_date"
                                        excludeDates={this.state.disabled_dates}
                                        dateFormat="MM/dd/yy"
                                        placeholderText={"Date"}
                                        selected={
                                          this.state.schedule_date_preview
                                            ? this.state.schedule_date
                                            : this.state.schedule_date_preview
                                        }
                                        onChange={(data) =>
                                          this.changeHandlerScheduleDate(data)
                                        }
                                      />
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 p-0 float-left">
                                      <DatePicker
                                        selected={
                                          this.state.schedule_time_preview
                                            ? this.state.schedule_time
                                            : this.state.schedule_time_preview
                                        }
                                        onChange={(data) =>
                                          this.changeHandlerScheduleTime(data)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeFormat="hh:mm aa"
                                        timeIntervals={5}
                                        timeCaption="Time"
                                        dateFormat="hh:mm aa"
                                        placeholderText={"Time"}
                                      />
                                    </div>
                                    {this.state.timeValidation ? (
                                      <div className="text-danger">
                                        {this.state.timeValidation}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </Tab.Pane>

                          <Tab.Pane eventKey="campaignPreview">
                            <div className="row align-items-center pd-r-15 pd-l-15 pd-b-0 border-bottom-0 mg-t-15 mg-b-30">
                              {this.state.campaign_name ? (
                                <>
                                  <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-5  background-head d-flex  align-items-center justify-content-start">
                                      Info
                                    </h3>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                    <label className="form-label text-left mb-0">
                                      Campaign
                                    </label>
                                    <p className="media-body campng_text">
                                      {this.state.campaign_name}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              {this.state.mappingArrayList.length > 0 &&
                              selectedListPreview.length > 0 ? (
                                <>
                                  <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 ">
                                    <h3 className="mg-l-5  background-head d-flex  align-items-center justify-content-start">
                                      Lists
                                    </h3>
                                  </div>
                                  <div className="col-12 mg-t-5 mg-md-t-0  mg-b-30 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                    <label className="form-label text-left mb-0">
                                      Lists
                                    </label>
                                    {selectedListPreview.map((item, i) => {
                                      return (
                                        <>
                                          <span
                                            className="media-body campng_text"
                                            key={i}
                                          >
                                            <a
                                              href={
                                                this.state.property_slug
                                                  ? "/properties/view/" +
                                                    this.state.property_slug +
                                                    "/crm-list/view/" +
                                                    item.slug
                                                  : this.state.brand_slug
                                                  ? "/brand/view/" +
                                                    this.state.brand_slug +
                                                    "/crm-list/view/" +
                                                    item.slug
                                                  : this.state.client_slug
                                                  ? "/client/view/" +
                                                    this.state.client_slug +
                                                    "/crm-list/view/" +
                                                    item.slug
                                                  : "/crm-list/view/" +
                                                    item.slug
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {item.list_name}
                                              <span className="CRMpreviewListCount">
                                                ({item.listCount})
                                              </span>
                                              {i ===
                                              selectedListPreview.length - 1
                                                ? ""
                                                : " | "}
                                            </a>
                                          </span>
                                        </>
                                      );
                                    })}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                <h3 className="mg-l-5  background-head d-flex  align-items-center justify-content-start">
                                  Template
                                </h3>
                              </div>
                              <Tab.Container
                                id="left-tabs-example"
                                defaultActiveKey="infosPreview"
                                onSelect={(e) => this.getActiveClass2(e)}
                                eventKey={this.state.activeClass2}
                              >
                                <ScrollingCarousel
                                  rightArrow={true}
                                  leftArrow={true}
                                  className="swipesection scroll_nav_mob mg-b-30"
                                >
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="infosPreview"
                                      id="infosPreview"
                                      className={
                                        this.state.activeClass2 ===
                                        "infosPreview"
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      Info
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="tempEmailPreview"
                                      id="tempEmailPreview"
                                      className={
                                        this.state.activeClass2 ===
                                        "tempEmailPreview"
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      Email
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="tempAppMessagePreview"
                                      id="tempAppMessagePreview"
                                      className={
                                        this.state.activeClass2 ===
                                        "tempAppMessagePreview"
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      App Message
                                    </Nav.Link>
                                  </Nav.Item>
                                </ScrollingCarousel>
                                <Tab.Content>
                                  <Tab.Pane eventKey="infosPreview">
                                    <div className="col-sm-12 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left mb-0">
                                        Template
                                      </label>
                                      <p className="media-body campng_text">
                                        {this.state.template_name}
                                      </p>
                                    </div>
                                    <div className="col-sm-12 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left mb-0">
                                        Description
                                      </label>
                                      <p className="media-body campng_text">
                                        {this.state.template_desc}
                                      </p>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="tempEmailPreview">
                                    <div className="col-sm-12 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left mb-0">
                                        Sender Mail
                                      </label>
                                      <p className="media-body campng_text">
                                        {this.state.email_from}
                                        {this.state.email_from
                                          ? "@orionhaus.com"
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-12 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left mb-0">
                                        Sender Name
                                      </label>
                                      <p className="media-body campng_text">
                                        {this.state.from_name}
                                      </p>
                                    </div>
                                    <div className="col-sm-12 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left mb-0">
                                        Subject
                                      </label>
                                      <p className="media-body campng_text">
                                        {this.state.subject}
                                      </p>
                                    </div>
                                    {this.state.selectedHeader.length > 0 ? (
                                      <div className="col-sm-12 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                        <label className="form-label text-left mb-0">
                                          Header Logo
                                        </label>
                                        <p className="media-body campng_text">
                                          {this.state.selectedHeader[0]
                                            .value === 1
                                            ? this.state.selectedHeader[0].label
                                            : ""}
                                        </p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.selectedFooter.length > 0 ? (
                                      <div className="col-sm-12 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                        <label className="form-label text-left mb-0">
                                          Footer
                                        </label>
                                        <p className="media-body campng_text">
                                          {this.state.selectedFooter[0]
                                            .value === 1
                                            ? this.state.selectedFooter[0].label
                                            : this.state.selectedFooter[0]
                                                .label}
                                        </p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="col-sm-12 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 para">
                                      <label className="form-label text-left mb-0">
                                        Body
                                      </label>

                                      <p className="schd_email_body">
                                        {this.state.renderEmailBody}
                                      </p>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="tempAppMessagePreview">
                                    <div className="col-sm-12 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left mb-0">
                                        Body
                                      </label>
                                      <p className="">
                                        {this.state.app_message_body}
                                      </p>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </Tab.Container>
                              {this.state.is_scheduled === 1 ||
                              this.state.is_scheduled === 2 ? (
                                <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                  <h3 className="mg-l-5  background-head d-flex  align-items-center justify-content-start">
                                    Schedule Date/Time
                                  </h3>
                                </div>
                              ) : (
                                ""
                              )}
                              {this.state.schedule_date_preview &&
                              this.state.is_scheduled === 1 ? (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                  <label className="form-label text-left mb-0">
                                    Date | Time
                                  </label>

                                  <p className="media-body campng_text">
                                    {this.state.schedule_date_preview} |
                                    {this.state.schedule_time_preview}
                                  </p>
                                </div>
                              ) : (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                  No Schedule
                                </div>
                              )}
                              {this.state.timezoneNameOffset &&
                              this.state.is_scheduled === 1 ? (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                  <label className="form-label text-left mb-0">
                                    Timezone
                                  </label>

                                  <p className="media-body campng_text">
                                    {this.state.timezoneNameOffset}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                              {this.state.renderEmailBody &&
                              this.state.subject &&
                              this.state.email_from ? (
                                <>
                                  <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-5  background-head d-flex  align-items-center justify-content-start">
                                      Test Email
                                    </h3>
                                    <div className="text-danger">
                                      {this.state.toEmailTestingField}
                                      <Alerts
                                        show={this.state.showAlertModalEmail}
                                        type={this.state.alertModalTypeEmail}
                                        title={this.state.alertModalTitleEmail}
                                        message={
                                          this.state.alertModalMessageEmail
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-0">
                                    <label className="form-label text-left ">
                                      Enter Your Email
                                    </label>
                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandlerEmailTest}
                                      name="email_to"
                                      value={this.state.email_to}
                                      maxLength="50"
                                      autoComplete="off"
                                    />
                                    <div className="text-danger">
                                      {this.state.errors.email_to
                                        ? this.state.errors.email_to
                                        : this.validator.message(
                                            "email",
                                            this.state.email_to,
                                            "required",
                                            { className: "text-danger" }
                                          )}
                                    </div>
                                  </div>
                                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-10  mg-b-0  pl-0">
                                    <button
                                      type="button"
                                      className="btn-success-outline-small ml-3 button-width"
                                      onClick={this.sendTestingEmail}
                                      disabled={
                                        this.state.isTestEmailSubmit
                                          ? true
                                          : false
                                      }
                                    >
                                      {this.state.isTestEmailSubmit
                                        ? global.loader
                                        : this.state.slug
                                        ? "Send Mail"
                                        : "Send Mail"}
                                    </button>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Modal show={this.state.deleteNearByAttractionModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteCampaignModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCampaign(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.bodyPopup}
          className=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.openBodyPopup()}>
              {global.closee}
            </span>
            <h3 className="text-center">Insert Body Variable</h3>
            <div className="row col-12 m-0">
              <div className="var_tmp_modal pb-0 mg-t-10">
                <div className="var_tmp_box">
                  {/* <h4 className="text-left var_pop_head">Guest</h4> */}
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="first_name"
                        type={"radio"}
                        id="5"
                        label={"first_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="last_name"
                        type={"radio"}
                        id="6"
                        label={"last_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>

                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="property_name"
                        type={"radio"}
                        id="9"
                        label={"property_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="homesharing_inquiry"
                        type={"radio"}
                        id="10"
                        label={"homesharing_inquiry"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.openBodyPopup()}
              >
                Cancel
              </button>
              {this.state.body_variable ? (
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                  onClick={this.insertbodyemail}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Insert"}
                </button>
              ) : (
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                >
                  {this.state.isSubmit ? global.loader : "Insert"}
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.appbodyPopup}
          className=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.openAppBodyPopup()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Insert App Body Variable</h3>
            <div className="row col-12 m-0">
              <div className="var_tmp_modal pb-0 mg-t-10">
                <div className="var_tmp_box">
                  {/* <h4 className="text-left var_pop_head">Guest</h4> */}
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="first_name"
                        type={"radio"}
                        id="5"
                        label={"first_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="last_name"
                        type={"radio"}
                        id="6"
                        label={"last_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="property_name"
                        type={"radio"}
                        id="9"
                        label={"property_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="homesharing_inquiry"
                        type={"radio"}
                        id="10"
                        label={"homesharing_inquiry"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.openAppBodyPopup()}
              >
                Cancel
              </button>
              {this.state.app_body_variable ? (
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                  onClick={this.insertappmessage}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Insert"}
                </button>
              ) : (
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                >
                  {this.state.isSubmit ? global.loader : "Insert"}
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default CrmCampaignForm;
