import React, { useState, useEffect } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import { Form } from "react-bootstrap";
import NoData from "../../common/NoData";
import Alerts from "../../common/Alerts";
import SettingsSidebar from "../../common/SettingsSidebar";
import IglooService from "../../../services/IglooService";
import PropertyService from "../../../services/PropertyService";
import Instructions from "./Instructions";
// import Select from "react-select";

const Mapping = (props) => {
  const [doors, setDoors] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [activeClass, setActiveClass] = useState("alerts");
  const [lockType, setLockType] = useState(0);
  const [isSubmit, setSubmit] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertModalType, setAlertModalType] = useState("");
  const [alertModalTitle, setAlertModalTitle] = useState("");
  const [alertModalMessage, setAlertModalMessage] = useState("");

  useEffect(() => { getDoorData(); getLockType(); }, []);
  function getActiveClass(e) {
    setActiveClass(e);
  }
  const getDoorData = async () => {
    let doors = [];
    let unitOptions = [];
    let res = await IglooService.getIglooLock("property_slug=" + props.match.params.propertySlug);
    if (global.successStatus.includes(res.status)) {
      doors = res.data.doors || [];
      if (res.data && Array.isArray(res.data.units)) {
        unitOptions = res.data.units.map(item => {
          return { value: item.unit_id, label: item.unit_name }
        });
      }
    }
    setDoors(doors);
  setUnitOptions(unitOptions);
  }

  const getLockType = async () => {
    let lockType = 0;
    let res = await PropertyService.getProperty(props.match.params.propertySlug);
    if (global.successStatus.includes(res.status) && res.data && res.data.lock_type) {
      lockType = res.data.lock_type;
    }
    setLockType(lockType);
  }

  const saveOrUpdateDoors = async (event) => {
    event.preventDefault();
    setSubmit(true);
    let res = [];
    let inputData = { property_slug: props.match.params.propertySlug, selected_doors: doors };
    res = await IglooService.mappingIglooUnits(inputData);
    if (global.successStatus.includes(res.status)) {
      setSubmit(false);
      setShowAlertModal(true);
      setAlertModalType("success");
      setAlertModalTitle("Success");
      setAlertModalMessage(res.message ? res.message : "Success");
      setTimeout(() => setShowAlertModal(false), global.alert_close_time);
    }
  }

  // const customStyles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     color:
  //       state.isSelected && state.isFocused
  //         ? "white"
  //         : state.isSelected
  //         ? "black"
  //         : state.isFocused && "white",
  //     backgroundColor:
  //       state.isSelected && state.isFocused
  //         ? "black"
  //         : state.isFocused && "black",
  //   }),
  // };

  // function changeUnits(event, index) {
  //   let value = event.target.value;
  //   let mapData = [...doors];
  //   mapData[index].unit_id = value;
  //   setDoors(mapData);
  // }
  
  return (
    <main>
      <Container className="innter-container">
        <div className="d-flex flex-column">
          <div className="row row-xs mg-t-0 pd-t-0">
            <div className="col-md-2 left">
              <div className="child-menu-section">
                <aside><SettingsSidebar property_slug={props.match.params.propertySlug} /></aside>
              </div>
            </div>
            <div className="col-md-10 right">
              <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0 keycafe-header-sticky-">
                <div className="dashbaord-pageHeadertitle dashboardRightcard d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns keycafemappingtitle">
                  <h3>Igloo Mapping </h3>
                </div>
              </div>
              {
                lockType === 3
                  ?
                  <div className="scrolling-carousel pd-l-15">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="alerts" onSelect={(e) => getActiveClass(e)} eventKey={activeClass}>
                      <ScrollingCarousel rightArrow={true} leftArrow={true} className="swipesection scroll_nav_mob">
                        <Nav.Item>
                          <Nav.Link eventKey="alerts" id="infoForm" className={activeClass === "alerts" ? "active" : ""}>Unit Mapping</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="alert-activity" id="alert-activity" className={activeClass === "alert-activity" ? "active" : ""}>
                            Pick Up/Drop Off Instructions
                          </Nav.Link>
                        </Nav.Item>
                      </ScrollingCarousel>
                      <Tab.Content className="pd-l-15 pd-r-15">
                        <Tab.Pane eventKey="alerts">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                            <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns pt-3">
                              {
                                global.userPermissions.checkPermission('igloo-adds')
                                &&
                                <button className="btn-success-outline-small ml-3 button-width" type="button" disabled={isSubmit ? true : false} onClick={saveOrUpdateDoors}>
                                  {isSubmit ? global.loader : 'Save'}
                                </button>
                              }
                            </div>
                            <div className="row align-items-center border-bottom-0 mapp_head_desk">
                              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-15 pd-l-30 pd-r-30 pd-r-30 mg-b-0">
                                <label className="form-label text-left mg-b-0">Lock Name</label>
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-15  pd-l-30 pd-r-30 pd-r-30 mg-b-0">
                                <label className="form-label text-left mg-b-0">Device ID</label>
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-15  pd-l-30 pd-r-30 pd-r-30 mg-b-0">
                                <label className="form-label text-left mg-b-0">Units</label>
                              </div>
                            </div>
                            <div className="row align-items-center border-bottom-0">
                              <div className="col-md-12 pd-lg-r-30 pd-lg-l-30 pd-xs-l-15 pd-xs-r-15 mg-t-15">
                                <Alerts show={showAlertModal} type={alertModalType} title={alertModalTitle} message={alertModalMessage} />
                              </div>
                              {doors && doors.length > 0
                                ? doors.map(
                                  (item, index) => {
                                    return (
                                      <>
                                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                          <label className="form-label text-left mg-b-0 mapp_head_mob">Lock Name</label>
                                          <Form.Control
                                            name="door_name"
                                            value={item.deviceName_igloo || ""}
                                            disabled={true}
                                            className="form-control max_width_100"
                                            type="text"
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                          <label className="form-label text-left mg-b-0 mapp_head_mob">Device Id</label>
                                          <Form.Control
                                            name="type"
                                            value={item.igloo_deviceId || ""}
                                            disabled={true}
                                            className="form-control max_width_100"
                                            type="text"
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                          <label className="form-label text-left mg-b-0 mapp_head_mob">Unit</label>
                                          <Form.Control
                                            name="type"
                                            value={unitOptions.find(option => option.value === item.unit_id)?.label || ''} // Use the label property
                                            disabled={true}
                                            className="form-control max_width_100"
                                            type="text"
                                          />
                                          {/* <Select
                                              styles={customStyles}
                                              isClearable={true}
                                              className="multiselect"
                                              menuPlacement="auto"
                                              value={unitOptions.find(option => option.value === item.unit_id)} // Find the corresponding option in unitOptions
                                              options={unitOptions}
                                              isDisabled={true} // Set this to true to make the Select read-only
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 2,
                                                colors: {
                                                  ...theme.colors,
                                                  primary: "#fff",
                                                  primary75: "#000",
                                                  primary50: "#000",
                                                  primary25: "#000",
                                                },
                                              })}
                                            /> */}
                                        </div>
                                      </>
                                    );
                                  })
                                : <NoData msg="Igloo" />
                              }
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="alert-activity">
                          <Instructions propertySlug={props.match.params.propertySlug} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                  :
                  lockType === 1
                    ?
                    <div className="col-md-12 mg-t-0 mg-b-10 pd-t-15 pd-b-10 pd-lg-l-45 pd-xs-l-15 pd-r-0">
                      <p className="pets_no_text text-left font-italic">This property is using Key Cafe.</p>
                    </div>
                    :  lockType === 2
                    ?
                    <div className="col-md-12 mg-t-0 mg-b-10 pd-t-15 pd-b-10 pd-lg-l-45 pd-xs-l-15 pd-r-0">
                      <p className="pets_no_text text-left font-italic">This property is using Latch.</p>
                    </div> :""
              }
            </div>
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Mapping;
