////////////////////////////////////////////////////////////
//     							                          //
//  Program: UnitAval.jsx                                 //
//  Application: Unit                                     //
//  Option: List all unit availability                    //
//  Developer: Np						                              //
//  Date: 2022-02-24                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import UnitsService from "../../services/UnitsService";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { Container, Table, Modal } from "react-bootstrap";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
import Select from "react-select";
import UnitSidebar from "../common/UnitSidebar";

export class UnitAval extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      open: global.showOpen,
      eventKey: "",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      deleteAmenityModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,

      unit_slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug,
      isSubmit: false,
      // sort_order: "",
      pathname: window.location.pathname,
      availability_type: "",
      date_From: "",
      to_date: "",
      avalList: [],
      newAvailabilityTypeList: [],
      selectedAvailabilityTypeList: [],
      avalListTotal: 0,
      aval_slug: "",
      confirm_back: 0,
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addAvailability = this.addAvailability.bind(this);
    this.changeView = this.changeView.bind(this);
    this.getAvalDetails = this.getAvalDetails.bind(this);
    this.deleteAmenity = this.deleteAmenity.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getData();
    this.getAvailabilityTypeList();
  }

  async getAvailabilityTypeList() {
    const newAvailabilityTypeList = [
      { value: "1", label: "Available" },
      { value: "2", label: "Unavailable" },
    ];
    this.setState({ newAvailabilityTypeList: newAvailabilityTypeList });
  }

  async getData(queryString) {
    this.setState({ showSpinner: true, loading: true });
    var avalList = [];
    var avalListTotal = 0;

    let res = await UnitsService.getUnitsAval(
      "property_slug=" +
      this.state.property_slug +
      "&ref_slug=" +
      this.state.unit_slug +
      "&ref_check=units" +
      "&availability_level=1" +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      avalList = res.data ? res.data : [];
      avalListTotal = avalList ? avalList.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      avalList: avalList,
      avalListTotal: avalListTotal,
    });
  }

  addAvailability = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug,
        ref_check: "units",
        availability_level: 1,
        ref_slug: this.state.unit_slug,
        availability_type: this.state.availability_type
          ? parseInt(this.state.availability_type)
          : "",
        from_date: this.state.from_date
          ? Moment(this.state.from_date).format("YYYY-MM-DD")
          : null,
        to_date: this.state.to_date
          ? Moment(this.state.to_date).format("YYYY-MM-DD")
          : null,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.unit_slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        // inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await UnitsService.addUnitsAval(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.changeView();
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  updateAvailability = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug,
        ref_check: "units",
        availability_level: 1,
        ref_slug: this.state.unit_slug,
        availability_type: this.state.availability_type
          ? parseInt(this.state.availability_type)
          : "",
        from_date: this.state.from_date
          ? Moment(this.state.from_date).format("YYYY-MM-DD")
          : null,
        to_date: this.state.to_date
          ? Moment(this.state.to_date).format("YYYY-MM-DD")
          : null,
      };
      if (this.state.aval_slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await UnitsService.updateUnitAval(
          this.state.aval_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.changeView();
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  async getAvalDetails(slug) {
    this.setState({ aval_slug: slug });
    this.changeView();
    this.setState({ showSpinner: true, loading: true });
    if (this.state.unit_slug !== undefined) {
      var resData = {};
      let res = await UnitsService.getUnitAvalBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.availability_type = res.data.availability_type
          ? res.data.availability_type
          : "";
        resData.from_date = res.data.from_date
          ? Moment(res.data.from_date).toDate("dd-MM-YYYY")
          : "";
        resData.to_date = res.data.to_date
          ? Moment(res.data.to_date).toDate("dd-MM-YYYY")
          : "";
        // resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        var selectedAvailabilityTypeList = [];
        selectedAvailabilityTypeList =
          res.data.availability_type === 1
            ? [
              {
                value: 1,
                label: "Available",
              },
            ]
            : [
              {
                value: 2,
                label: "Unavailable",
              },
            ];
      }
      this.setState(resData);
      this.setState({
        selectedAvailabilityTypeList: selectedAvailabilityTypeList,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  /* to delete amenity data */
  async deleteAmenity(slug) {
    this.setState({ showSpinner: true });
    let res = await UnitsService.deleteUnitAval(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAmenityModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({
        viewMode: 0,
        aval_slug: "",
        selectedAvailabilityTypeList: [],
        from_date: "",
        to_date: "",
      });

      this.getData();
    } else {
      this.closeDeleteAmenityModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closeDeleteAmenityModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  handleChangeAvailabilityTypeList(value) {
    let availability_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        availability_type.push(item.value);
      });
    }
    const availability_type_value = value !== null ? value.value : [];
    this.setState({
      selectedAvailabilityTypeList: value,
      availability_type: availability_type_value,
    });
    this.setState({ confirm_back: 1 });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  openDeleteAmenityModal(slug) {
    this.setState({ deleteAmenityModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteAmenityModal() {
    this.setState({ deleteAmenityModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.eventKey });
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.setState({ aval_slug: "", selectedAvailabilityTypeList: [] });
      this.getData();
      this.setState({ from_date: "", to_date: "", availability_type: "" });

      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.setState({ selectedAvailabilityTypeList: [] });
      if (this.state.aval_slug !== "") {
        this.getAvalDetails(this.state.aval_slug);
      }

      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
    }
  }

  getTitle() {
    return "Availability";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <UnitSidebar
                      unit_slug={this.state.unit_slug}
                      property_slug={this.state.property_slug}
                    />
                  </aside>
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="scrolling-carousel pd-l-15">
                  <div className="row d-flex justify-content-between align-items-center pd-l-15 pd-r-15 mg-b-0 pd-b-2 mg-t-20">
                    <h3>{this.getTitle()}</h3>
                  </div>
                  <div className="dashboardRightcard dashbaord-pageHeadertitle">
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.aval_slug !== "" ? (
                        <>
                          {/* <button
                            type="button"
                            onClick={() => {
                              this.openDeleteAmenityModal(this.state.aval_slug);
                            }}
                            className="btn-danger-outline mg-r-20"
                          >
                            <img src={deleteicon} />
                            Delete
                          </button>{" "} */}

                          <span
                            onClick={() => {
                              this.openDeleteAmenityModal(this.state.slug);
                            }}
                            className="deleteicon mg-r-20"
                          >
                            <img src={deleteicon} />
                          </span>
                        </>
                      ) : (
                        ""
                      )}

                      {this.state.viewMode === 0 ? (
                        <button
                          className="btn-success-outline-small"
                          onClick={this.changeView}
                        >
                          <img src={pluswIcon} />
                          Add
                        </button>
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.changeView();
                                else return false;
                              else this.changeView();
                            }}
                          >
                            Cancel
                          </button>
                          {this.state.aval_slug === "" ? (
                            <button
                              className="btn-success ml-3"
                              onClick={this.addAvailability}
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              className="btn-success ml-3"
                              onClick={this.updateAvailability}
                            >
                              Save
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 pl-0 pr-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.state.viewMode === 0 ? (
                      <div className="row">
                        <div className="table-responsive">
                          <Table hover className="mg-b-0 table_last_row">
                            <thead>
                              <tr>
                                <th className="w-20">Availability Type</th>
                                <th className="w-20">From Date</th>
                                <th className="w-20">To Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.avalListTotal > 0 ? (
                                this.state.avalList.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td
                                        className="hand-cursor"
                                        onClick={() =>
                                          this.getAvalDetails(item.slug)
                                        }
                                      >
                                        {item.availability_type === 1
                                          ? "Available"
                                          : "Unavailable"}
                                      </td>
                                      <td
                                        className="hand-cursor"
                                        onClick={() =>
                                          this.getAvalDetails(item.slug)
                                        }
                                      >
                                        {item.from_date
                                          ? Moment(item.from_date).format(
                                            "DD-MM-YYYY"
                                          )
                                          : ""}
                                      </td>
                                      <td
                                        className="hand-cursor"
                                        onClick={() =>
                                          this.getAvalDetails(item.slug)
                                        }
                                      >
                                        {item.to_date
                                          ? Moment(item.to_date).format(
                                            "DD-MM-YYYY"
                                          )
                                          : ""}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="9" align="center">
                                    There are no availability added in the system. You may add them by clicking Add button.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.viewMode === 1 ? (
                      <div className="m-0">
                        <div className="row align-items-center pd-t-30 pd-b-30 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Availability Type{" "}
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedAvailabilityTypeList}
                              options={this.state.newAvailabilityTypeList}
                              getOptionValue={option => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeAvailabilityTypeList(value)
                              }
                              defaultValue={
                                this.state.selectedAvailabilityTypeList
                              }
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />

                            {this.state.errors.availability_type ? (
                              <div className="text-danger">
                                {this.state.errors.availability_type}
                              </div>
                            ) : (
                              this.validator.message(
                                "availability_type",
                                this.state.availability_type,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                        <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div>

                        <div className="row align-items-center pd-t-30 pd-b-30 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              From Date
                            </label>

                            <DatePicker
                              className="form-control max_width_100"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="from_date"
                              dateFormat="dd-MM-yy"
                              selected={this.state.from_date}
                              minDate={new Date()}
                              maxDate={this.state.to_date}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "from_date",
                                    value: data,
                                  },
                                })
                              }
                            />
                            {this.state.errors.from_date ? (
                              <div className="text-danger">
                                {this.state.errors.from_date}
                              </div>
                            ) : (
                              this.validator.message(
                                "from_date",
                                this.state.from_date,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              To Date
                            </label>

                            <DatePicker
                              className="form-control max_width_100"
                              scrollableYearDropdown
                              showMonthDropdown
                              showYearDropdown
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="to_date"
                              dateFormat="dd-MM-yy"
                              selected={this.state.to_date}
                              minDate={this.state.from_date}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "to_date",
                                    value: data,
                                  },
                                })
                              }
                            />

                            {this.state.errors.to_date ? (
                              <div className="text-danger">
                                {this.state.errors.to_date}
                              </div>
                            ) : (
                              this.validator.message(
                                "to_date",
                                this.state.to_date,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* delete availability popup modal */}
        <Modal show={this.state.deleteAmenityModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteAmenityModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAmenityModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteAmenity(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default UnitAval;
