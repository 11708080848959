//                                                           //
//  Program: RentalListingLicenseLookup.jsx                               //
//  Application: Rental United                               //
//  Option: Rental Lookup tables                             //
//  Developer: NP 		                                     //
//  Date: 2022-09-13                                         //
//                                                           //
/////////////////////////////////////////////////////////   ///

import React, { Component } from "react";
import RUSidebar from "../common/RUSidebar";
import Select from "react-select";
import { Container, Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import PropertyService from "../../services/PropertyService";
import ListingService from "../../services/ListingService";
import RentalService from "../../services/RentalService";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import Moment from "moment";

class RentalListingLicenseLookup extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      input: {},
      errors: {},
      propertyList: [],
      newPropertyList:[],
      selectedPropertyList:[],
      property_slug: "",
      listing_slug:"",
      licensenumber : "",
      issue_date : "",
      expiration_date : "",
      exemption_reason : ""
    };
    this.saveData = this.saveData.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  componentDidMount(){
    this.getPropertyData();
  }

  async getPropertyData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var propertyList = [];
    var propertyListTotal = 0;
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0
    }
    const newPropertyList = propertyListTotal > 0 ? propertyList.map(({ slug, property_name }) => ({
      value: slug,
      label: property_name,
    })) : [];
    this.setState({ propertyList: propertyList, newPropertyList: newPropertyList, propertyListTotal: propertyListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  handleChangePropertyList(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    if(value){
        this.getListingData(value.value);   //get listing of selected property
    }
    this.setState({
      selectedPropertyList: value,
      property_slug: value.value,
    });
  }

  async getListingDetails(value) {
    if (value !== undefined) {
      var resData = {};

      let res = await ListingService.getListing(value);
      if (global.successStatus.includes(res.status)) {
        resData.licensenumber = res.data && res.data.ruListingLicenseData && res.data.ruListingLicenseData.licensenumber ? res.data.ruListingLicenseData.licensenumber : ""
        resData.issue_date = res.data && res.data.ruListingLicenseData && res.data.ruListingLicenseData.issue_date ? Moment(res.data.ruListingLicenseData.issue_date).toDate("dd-MM-YYYY") : null;
        resData.expiration_date = res.data && res.data.ruListingLicenseData && res.data.ruListingLicenseData.expiration_date ? Moment(res.data.ruListingLicenseData.expiration_date).toDate("dd-MM-YYYY") : null;
        resData.exemption_reason = res.data && res.data.ruListingLicenseData && res.data.ruListingLicenseData.exemption_reason ? res.data.ruListingLicenseData.exemption_reason : ""
      }
      this.setState(resData);
    }
  }

  async getListingData(value) {
    this.setState({ showSpinner: true, loading: true });
    var listingList = [];
    var listingListTotal = 0;
    let res = await ListingService.getListingList(
        "property_slug=" + value + "&is_dropdown=1"
    );
    if (global.successStatus.includes(res.status)) {
      listingList = res.data ? res.data : [];
      listingListTotal = listingList ? listingList.length : 0
    }
    const newListingList = listingListTotal > 0 ? listingList.map(({ slug, listing_name }) => ({
      value: slug,
      label: listing_name,
    })) : [];
    this.setState({ listingList: listingList, newListingList: newListingList, listingListTotal: listingListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  handleChangeListingList(value) {
    let listing_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        listing_slug.push(item.value);
      });
    }
    if(value){
      this.getListingDetails(value.value);
    }
    this.setState({
      selectedListingList: value,
      listing_slug: value.value
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  saveData = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        listing_slug: this.state.listing_slug ? this.state.listing_slug : "",
        licensenumber: this.state.licensenumber ? this.state.licensenumber : "",
        issue_date: this.state.issue_date ? Moment(this.state.issue_date, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
        expiration_date: this.state.expiration_date ? Moment(this.state.expiration_date, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
        exemption_reason: this.state.exemption_reason ? this.state.exemption_reason : ""
      };
     
      let res = await RentalService.createListingLicenseData(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        // setTimeout(
        //   () =>
        //     this.props.history.push(
        //       "/properties/view/" +
        //       this.state.property_slug +
        //       "/guest/view/" +
        //       this.state.slug
        //     ),
        //   global.redirect_time
        // );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
     
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  render() {
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
          color: (state.isSelected && state.isFocused) ? "white" : (state.isSelected ? "black" : state.isFocused && "white"),
          backgroundColor: (state.isSelected && state.isFocused) ? 'black' : (state.isFocused && "black"),
        })
      }
    return (
      <>
        <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
            <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                <div className="child-menu-section">
                    <aside>
                    <RUSidebar />
                    </aside>
                </div>
                </div>
                <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>Listing License</h3>
                    <div className="d-flex justify-content-end">
                      {global.userPermissions.checkPermission('ru-integration-update') &&
                        <button
                        type="button"
                        className="btn-success ml-3"
                        onClick={this.saveData}
                        disabled={this.state.isSubmit ? true : false}
                        >
                        {this.state.isSubmit
                            ? global.loader
                            : "Save"}
                        </button>
                      }
                    </div>
                    </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                    <form id="units_form">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                        <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                        />
                        </div>
                        <div className="m-0">
                        <div className="row align-items-center pd-b-0 border-bottom-0  pd-xs-l-0 pd-xs-r-0  pd-lg-l-0 pd-lg-r-15">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Property{" "}
                            </label>
                            <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedPropertyList}
                            options={this.state.newPropertyList}
                            getOptionValue={option => `${option.label}`}
                            onChange={(value) => this.handleChangePropertyList(value)}
                            defaultValue={this.state.selectedPropertyList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: '#fff',
                                primary75: '#000',
                                primary50: '#000',
                                primary25: '#000',
                              }
                            })}
                          />
                            {this.state.errors.property_slug ? (
                                <div className="text-danger">
                                {this.state.errors.property_slug}
                                </div>
                            ) : (
                                this.validator.message(
                                "property",
                                this.state.property_slug,
                                "required",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Listing{" "}
                            </label>
                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedListingList || ""}
                              options={this.state.newListingList}
                              onChange={(value) =>
                                this.handleChangeListingList(value)
                              }
                              defaultValue={this.state.selectedListingList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                            {this.state.errors.listing_slug ? (
                              <div className="text-danger">
                                {this.state.errors.listing_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "listing",
                                this.state.listing_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                            </div>
                        </div>
                        {this.state.property_slug && this.state.listing_slug ?
                        <div className="row align-items-center pd-b-0 border-bottom-0  pd-xs-l-0 pd-xs-r-0  pd-lg-l-0 pd-lg-r-15">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                License Number{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="licensenumber"
                                value={this.state.licensenumber}
                                maxLength="50"
                            />
                            {this.state.errors.licensenumber ? (
                                <div className="text-danger">
                                {this.state.errors.licensenumber}
                                </div>
                            ) : (
                                this.validator.message(
                                "licensenumber",
                                this.state.licensenumber,
                                "max:50",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Issue Date{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <DatePicker
                              className="form-control max_width_100"
                              disabledKeyboardNavigation
                              strictParsing
                              scrollableYearDropdown
                              autoComplete="off"
                              showMonthDropdown
                              showYearDropdown
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="issue_date"
                              dateFormat="MM/dd/yy"
                              selected={this.state.issue_date}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "issue_date",
                                    value: data,
                                  },
                                })
                              }
                            />
                            {this.state.errors.issue_date ? (
                                <div className="text-danger">
                                {this.state.errors.issue_date}
                                </div>
                            ) : ''}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Expiration Date{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <DatePicker
                              className="form-control max_width_100"
                              disabledKeyboardNavigation
                              strictParsing
                              scrollableYearDropdown
                              autoComplete="off"
                              showMonthDropdown
                              showYearDropdown
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="expiration_date"
                              dateFormat="MM/dd/yy"
                              selected={this.state.expiration_date}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "expiration_date",
                                    value: data,
                                  },
                                })
                              }
                            />
                            {this.state.errors.expiration_date ? (
                                <div className="text-danger">
                                {this.state.errors.expiration_date}
                                </div>
                            ) : ''}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Exemption Reason{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="exemption_reason"
                                value={this.state.exemption_reason}
                                maxLength="500"
                                as="textarea"
                            />
                            {this.state.errors.exemption_reason ? (
                                <div className="text-danger">
                                {this.state.errors.exemption_reason}
                                </div>
                            ) : (
                                this.validator.message(
                                "exemption_reason",
                                this.state.exemption_reason,
                                "max:500",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                        </div>
                        : ''}
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
        </Container>
        </main>
      </>
    );
  }
}
export default RentalListingLicenseLookup