//     							                          //
//  Program: ReportSidebar.jsx                           //
//  Application: Reports                                 //
//  Option: common component Report Sidebar TAbs         //
//  Developer: NP		                                 //
//  Date: 2022-04-13                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Dropdown } from 'react-bootstrap';
class ReportSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  toggleChildMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".left");
    data.classList.replace("left", "hide");
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Reports
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <span>Reports</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "top-performer-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("top-performer")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/top-performer"
                          }
                        >
                          Top Performer
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "resident-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("resident-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/resident-payout"
                          }
                        >
                          Resident Payout
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "homesharing-activity-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("homesharing-reports")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/homesharing-reports"
                          }
                        >
                          Homesharing Activity
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "occupancy-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("occupancy-reports")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/occupancy-reports"
                          }
                        >
                          Occupancy
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "oh-revenue-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("oh-earning-reports")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/oh-earning-reports"
                          }
                        >
                          OH Revenue
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-tax-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-tax")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-tax"
                          }
                        >
                          Property Tax
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "channel-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("ru-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/ru-payout"
                          }
                        >
                          Channel Payout
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "monthly-investor-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("monthly-investor-report")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/monthly-investor-report"
                          }
                        >
                          Monthly Investor
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span>Incidentals & Waiver</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "guest-form-deposite-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("guest-form-deposit")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/guest-form-deposit"
                          }
                        >
                          Incidentals Deposit
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "guest-form-waiver-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("guest-form-waiver")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/guest-form-waiver"
                          }
                        >
                          Waiver Fee Report
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "guest-form-wavio-transfer-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("wavio-transfer-fee-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/wavio-transfer-fee-list"
                          }
                        >
                          Waiver Payout Report
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span>Property Payout</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "property-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-payout-report")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-payout-report"
                          }
                        >
                          By Unit and Reservation
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-reservation-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-reservation-payout"
                          }
                        >
                          By Reservation
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-unit-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-unit-payout"
                          }
                        >
                          By Unit
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span>Export</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "datahub-export-mapping-view"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("export-automation") ||
                            this.isPathActive(
                              "automation-processing-log-export"
                            )
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/export-automation/report"
                          }
                        >
                          Data Export
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              {/* <a href="/" className="back subMenu"> <i className="fas fa-chevron-left"></i> Listings</a> */}
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Reports
              </span>
              <ul>
                <li>
                  <span>Reports</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "top-performer-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("top-performer")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/top-performer"
                          }
                        >
                          Top Performer
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "resident-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("resident-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/resident-payout"
                          }
                        >
                          Resident Payout
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "homesharing-activity-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("homesharing-reports")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/homesharing-reports"
                          }
                        >
                          Homesharing Activity
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "occupancy-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("occupancy-reports")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/occupancy-reports"
                          }
                        >
                          Occupancy
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "oh-revenue-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("oh-earning-reports")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/oh-earning-reports"
                          }
                        >
                          OH Revenue
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-tax-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-tax")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-tax"
                          }
                        >
                          Property Tax
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "channel-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("ru-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/ru-payout"
                          }
                        >
                          Channel Payout
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "monthly-investor-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("monthly-investor-report")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/monthly-investor-report"
                          }
                        >
                          Monthly Investor
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span>Incidentals & Waiver</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "guest-form-deposite-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("guest-form-deposit")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/guest-form-deposit"
                          }
                        >
                          Incidentals Deposit
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "guest-form-waiver-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("guest-form-waiver")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/guest-form-waiver"
                          }
                        >
                          Waiver Fee Report
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "guest-form-wavio-transfer-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("wavio-transfer-fee-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/wavio-transfer-fee-list"
                          }
                        >
                          Waiver Payout Report
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span>Property Payout</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "property-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-payout-report")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-payout-report"
                          }
                        >
                          By Unit and Reservation
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-reservation-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-reservation-payout"
                          }
                        >
                          By Reservation
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-unit-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-unit-payout"
                          }
                        >
                          By Unit
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span>Export</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "datahub-export-mapping-view"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("export-automation") ||
                            this.isPathActive(
                              "automation-processing-log-export"
                            )
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/export-automation/report"
                          }
                        >
                          Data Export
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ReportSidebar;
