////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ApplicantDetailsForm.jsx                                //
//  Application:  Events                                   //
//  Option: For add or update Events                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-02-09                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import DatePicker from "react-datepicker";
import Moment from "moment";
import ApplicantsDetailService from "../../services/ApplicantsDetailService";
import Select from "react-select";
import LeasingSidebar from "../common/LeasingSidebar";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";

export class ApplicantDetailsForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      status_id: "",
      selectedRentingTypeList: "",
      applicant_info: [],
      tour_date_time: "",
      desired_move_in_date: "",
      comment: "",
      notes: "",
      application_started: "",
      application_submitted: "",
      application_approved: "",
      lease_created: "",
      lease_signed: "",
      move_in_cost_payment: "",
      query_response: "",
      sort_order: "",
      pathname: window.location.pathname,
      priceRangeList: [],
      newPriceRangeList: [],
      selectedPriceRangeList: [],
      StatusList: global.statuslist,
      selectedStatusList: [],
      inquirydata: [],
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      confirm_back: 0,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateApplicantDetails =
      this.saveOrUpdateApplicantDetails.bind(this);
  }

  /* To get PriceRange list data */
  async getPriceRange(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var priceRangeList = [];
    var priceRangeListTotal = 0;

    let res = await ApplicantsDetailService.getPriceRange(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      priceRangeList = res.data ? res.data : [];
      priceRangeListTotal = priceRangeList ? priceRangeList.length : 0;
    }
    const newPriceRangeList =
      priceRangeListTotal > 0
        ? priceRangeList.map(({ slug, min, max }) => ({
            value: slug,
            label: min + "" + max,
          }))
        : [];
    this.setState({
      priceRangeList: priceRangeList,
      priceRangeListTotal: priceRangeListTotal,
      newPriceRangeList: newPriceRangeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    this.getPriceRange();

    if (this.state.slug !== undefined) {
      var resData = {};
      var inquiryData = [];
      let res = await ApplicantsDetailService.getApplicantsDetailBySlug(
        this.state.slug
      );
      if (global.successStatus.includes(res.status)) {
        var selectedStatusList = [];
        inquiryData = res.data.inquiryData ? res.data.inquiryData : [];
        global.statuslist.length > 0 &&
          global.statuslist.forEach((item, i) => {
            if (item.value === res.data.status_id) {
              selectedStatusList = [
                ...selectedStatusList,
                {
                  value: item.value,
                  label: item.label,
                },
              ];
            }
          });

        resData = res.data ? res.data : {};

        if (
          res.data &&
          res.data.applicant_info &&
          res.data.applicant_info.phone_number
        ) {
          this.phoneNumberAutoFormat(res.data.applicant_info.phone_number);
        }

        resData.price_range_slug =
          res.data.price_info && res.data.price_info.slug
            ? res.data.price_info.slug
            : "";
        resData.desired_move_in_date = res.data.desired_move_in_date
          ? Moment(res.data.desired_move_in_date, "MM/DD/yy").format("MM/DD/yy")
          : null;
        resData.tour_date_time = res.data.tour_date_time
          ? Moment(res.data.tour_date_time, "MM/DD/yy").format("MM/DD/yy")
          : null;
        resData.comment = res.data.comment ? res.data.comment : "";
        resData.notes = res.data.notes ? res.data.notes : "";
        resData.application_started = res.data.application_started
          ? res.data.application_started
          : "";
        resData.application_submitted = res.data.application_submitted
          ? res.data.application_submitted
          : "";
        resData.application_approved = res.data.application_approved
          ? res.data.application_approved
          : "";
        resData.lease_created = res.data.lease_created
          ? res.data.lease_created
          : "";
        resData.lease_signed = res.data.lease_signed
          ? res.data.lease_signed
          : "";
        resData.move_in_cost_payment = res.data.move_in_cost_payment
          ? res.data.move_in_cost_payment
          : "";
        resData.query_response = res.data.query_response
          ? res.data.query_response
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id ? res.data.status_id : 1;

        var selectedPriceRangeList = [];
        selectedPriceRangeList = res.data.price_info
          ? [
              {
                value: res.data.price_info.slug,
                label:
                  res.data.price_info.min != 0
                    ? "$" +
                      global.onKeyPressEvent.numberWithCommasForDecimal(
                        res.data.price_info.min
                      ) +
                      "" +
                      "$" +
                      global.onKeyPressEvent.numberWithCommasForDecimal(
                        res.data.price_info.max
                      )
                    : ">$" +
                      global.onKeyPressEvent.numberWithCommasForDecimal(
                        res.data.price_info.max
                      ),
              },
            ]
          : "";
      }
      this.setState(resData);
      this.setState({
        selectedStatusList: selectedStatusList,
        inquirydata: inquiryData,
      });
      this.setState({ selectedPriceRangeList: selectedPriceRangeList }); //  selectedTypeList: selectedTypeList
      this.setState({ input: resData });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    this.setState({ phone_number: finalnumber });
  };

  saveOrUpdateApplicantDetails = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug,
        // tour_date_time: this.state.tour_date_time
        //   ? Moment(this.state.tour_date_time, "YYYY-MM-DD").format("YYYY-MM-DD")
        //   : null,
        // desired_move_in_date: this.state.desired_move_in_date
        //   ? Moment(this.state.desired_move_in_date, "YYYY-MM-DD").format("YYYY-MM-DD")
        //   : null,
        comment: this.state.comment,
        notes: this.state.notes,
        // application_started: this.state.application_started,
        // application_submitted: this.state.application_submitted,
        // application_approved: this.state.application_approved,
        // lease_created: this.state.lease_created,
        // lease_signed: this.state.lease_signed,
        move_in_cost_payment: this.state.move_in_cost_payment,
        query_response: this.state.query_response,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id,
      };
      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        // inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await ApplicantsDetailService.updateApplicantsDetail(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/applicant-detail"
                  : "/applicant-detail/view/" +
                      this.state.property_slug +
                      "/" +
                      this.state.slug
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      } else {
        let res = await ApplicantsDetailService.createApplicantsDetail(
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/applicant-detail"
                  : "/applicant-detail"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  handleChangeStatusList(value) {
    let fee_level = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        fee_level.push(item.value);
      });
    }
    const fee_level_value = value !== null ? value.value : [];
    this.setState({
      selectedStatusList: value,
      status_id: fee_level_value,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    if (event.target.name === "phone_number") {
      if (value.length > 9) this.getResidentDataByPhone(value);
    }
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("room_name");
    }
  }

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.comment !== undefined &&
      input.comment !== "" &&
      input.comment !== null &&
      input.comment.length > 100000
    ) {
      isValid = false;
      errors["comment"] = "The comment character limit has been exceed.";
    }
    if (
      input.notes !== undefined &&
      input.notes !== "" &&
      input.notes !== null &&
      input.notes.length > 100000
    ) {
      isValid = false;
      errors["notes"] = "The notes character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "Inquiry";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    const listItems =
      this.state.inquirydata.length > 0
        ? this.state.inquirydata.map((element, i) => {
            return (
              <li key={i}>
                <p>
                  {" "}
                  {element.new_inquiry_status}{" "}
                  {Moment(element.updated_at).format(global.dateTimeFormat)}
                </p>
              </li>
            );
          })
        : "";

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <LeasingSidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyLeasingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/applicant-detail"
                                  : "/applicant-detail/view/" +
                                      this.state.property_slug +
                                      "/" +
                                      this.state.slug
                              );
                            else return false;
                          else
                            this.props.history.push(
                              this.props.match.params.propertySlug !== undefined
                                ? "/properties/view/" +
                                    this.state.property_slug +
                                    "/applicant-detail"
                                : "/applicant-detail/view/" +
                                    this.state.property_slug +
                                    "/" +
                                    this.state.slug
                            );
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        className="btn-success ml-3"
                        type="button"
                        onClick={this.saveOrUpdateApplicantDetails}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                          ? "Save"
                          : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <form id="event_form">
                    <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="m-0 listing_detail">
                        <div className="row align-items-center col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-xs-l-0 pd-xs-r-0 pd-l-0 pd-r-0 border-bottom-0">
                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                              <p className="az-profile-name-text pl-0">
                                First Name
                              </p>
                              <p className="media-body">
                                {this.state.applicant_info
                                  ? this.state.applicant_info.first_name
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                              <p className="az-profile-name-text pl-0">
                                Last Name
                              </p>
                              <p className="media-body">
                                {this.state.applicant_info
                                  ? this.state.applicant_info.last_name
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                              <p className="az-profile-name-text pl-0">Email</p>
                              <p className="media-body">
                                {this.state.applicant_info
                                  ? this.state.applicant_info.email
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                              <p className="az-profile-name-text pl-0">Phone</p>
                              <p className="media-body">
                                {this.state.phone_number
                                  ? this.state.phone_number
                                  : ""}
                              </p>
                            </div>
                            {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Price Range
                              </p>
                              <p className="media-body">
                                {this.state.price_info
                                  ? this.state.price_info.min != 0 ? ("$" + global.onKeyPressEvent.numberWithCommasForDecimal(this.state.price_info.min) + " - " + "$" + global.onKeyPressEvent.numberWithCommasForDecimal(this.state.price_info.max)) : ">" + "$" + global.onKeyPressEvent.numberWithCommasForDecimal(this.state.price_info.max)
                                  : ""}
                              </p>
                            </div> */}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                              <p className="az-profile-name-text pl-0">
                                Desired Move In Date
                              </p>
                              <p className="media-body">
                                {this.state.desired_move_in_date
                                  ? Moment(
                                      this.state.desired_move_in_date
                                    ).format(global.dateFormat)
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                              <p className="az-profile-name-text pl-0">
                                Application Date
                              </p>
                              <p className="media-body">
                                {this.state.tour_date_time
                                  ? Moment(this.state.tour_date_time).format(
                                      global.dateFormat
                                    )
                                  : ""}
                              </p>
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Application Status
                              </label>
                              <Select
                                styles={customStyles}
                                isClearable={true}
                                menuPlacement="auto"
                                className="multiselect"
                                options={this.state.StatusList}
                                value={this.state.selectedStatusList}
                                defaultValue={this.state.selectedStatusList}
                                getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangeStatusList(value)
                                }
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Move In Cost{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <div className="input-group-prepend prefix-dollar">
                                <span className="input-group-text">$</span>
                                <Form.Control
                                  onChange={this.changeHandler}
                                  type="text"
                                  name="move_in_cost_payment"
                                  value={this.state.move_in_cost_payment || ""}
                                  autoComplete="off"
                                  onKeyPress={
                                    global.onKeyPressEvent.floatValidation
                                  }
                                  maxLength="10"
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-lg-t-20 mg-xs-t-20 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0">
                              <ul className="new_list_inquiry">{listItems}</ul>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row align-items-center pd-b-30 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              First Name
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="first_name"
                              value={this.state.applicant_info.first_name}
                              className="form-control max_width_100"
                              type="text"
                              readOnly
                            />
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Last Name
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="last_name"
                              value={this.state.applicant_info.last_name}
                              className="form-control max_width_100"
                              type="text"
                              readOnly
                            />
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Email
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="email"
                              value={this.state.applicant_info.email}
                              className="form-control max_width_100"
                              type="text"
                              readOnly
                            />
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Phone
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="email"
                              value={this.state.applicant_info.phone_number}
                              className="form-control max_width_100"
                              type="text"
                              readOnly
                            />
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Last Name
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="last_name"
                              value={this.state.applicant_info.last_name}
                              className="form-control max_width_100"
                              type="text"
                              readOnly
                            />
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Price Range
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              isDisabled={true}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedPriceRangeList}
                              options={this.state.newPriceRangeList}
                              getOptionValue={(option) => `${option.label}`}
                              defaultValue={this.state.selectedPriceRangeList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </div>{" "}
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Tour Date Time
                            </label>
                            <Form.Control
                              onChange={this.changeHandler}
                              name="tour_date_time"
                              value={this.state.tour_date_time}
                              className="form-control max_width_100"
                              type="text"
                              readOnly
                            />
                            {this.state.errors.tour_date_time ? (
                              <div className="text-danger">
                                {this.state.errors.tour_date_time}
                              </div>
                            ) : (
                              this.validator.message(
                                "tour_date_time",
                                this.state.tour_date_time,
                                "",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Desired Move In Date
                            </label>
                            <Form.Control
                              onChange={this.changeHandler}
                              name="desired_move_in_date"
                              value={this.state.desired_move_in_date}
                              className="form-control max_width_100"
                              type="text"
                              readOnly
                            />
                          </div>
                        </div> */}
                        <div className="row align-items-center pd-b-30 border-bottom-0">
                          {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Comments
                            </label>

                            <CKEditor
                              editor={ClassicEditor}
                              config={{
                                removePlugins: [
                                  "EasyImage",
                                  "ImageUpload",
                                  "MediaEmbed",
                                  "Table",
                                ],
                              }}
                              name="comment"
                              minHeight="50px"
                              data={this.state.comment}
                              onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                  writer.setStyle(
                                    "height",
                                    "200px",
                                    editor.editing.view.document.getRoot()
                                  );
                                });
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                this.changeHandler({
                                  target: {
                                    type: "ckeditor",
                                    name: "comment",
                                    value: data,
                                  },
                                });
                              }}
                            />
                            {this.state.errors.comment ? (
                              <div className="text-danger">
                                {this.state.errors.comment}
                              </div>
                            ) : (
                              this.validator.message(
                                "comment",
                                this.state.comment,
                                "max:100000",
                                { className: "text-danger" }
                              )
                            )}
                          </div>{" "} */}
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-r-30 pd-lg-r-40 pd-xs-r-15 mg-b-15">
                            <InputGroup className="">
                              <label className="form-label text-left pd-b-5 w-100">
                                Notes{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                name="notes"
                                value={this.state.notes}
                                className="form-control max_width_100"
                                id="notes"
                                as="textarea"
                                rows={global.textareaRowLength}
                                // placeholder="Username"
                              />
                            </InputGroup>
                            {this.state.errors.notes ? (
                              <div className="text-danger">
                                {this.state.errors.notes}
                              </div>
                            ) : (
                              this.validator.message(
                                "notes",
                                this.state.notes,
                                "max:100000",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default ApplicantDetailsForm;
