import React, { Component } from "react";
import PropTypes from "prop-types";
import HtmlTemplate from "./HtmlTemplate";
import CropImageHtml from "./CropImageHtml";
import FileValidations from "./FileValidations";
import axios from "axios";
class FileWidget extends Component {
  a = [];
  constructor(props) {
    super(props);
    this.state = {
      test: "test state",
      loader: false,
      progress: 0,
      removedFiles: [],
    };
    // this.selected_file_name = this.selected_file_name.bind(this);
  }

  componentDidMount() {
    // this.setPercentBar = this.setPercentBar.bind(this);
  }

  // Callback function to receive value from child
  handleValueFromChild = (value) => {
    this.setState({ valueFromChild: value });
    this.props.onValidationChange(value);
  }

  newRelatedFilesPreview = (value) => {
    setTimeout(() => {
      this.props.previewFilesInParent(value)
    }, 50)

  }

  render() {
    return this.props.isModal === "yes" ? (
      <CropImageHtml
        uploadType={this.props.uploadType}
        fieldTitle={this.props.fieldTitle}
        fieldLabel={this.props.fieldLabel}
        labelClassName={this.props.labelClassName}
        inputClassName={this.props.inputClassName}
        helpText={this.props.helpText}
        sampleFileUrl={this.props.sampleFileUrl}
        fieldName={this.props.fieldName}
        allowedType={this.props.allowedType}
        maxFileSize={this.props.maxFileSize}
        maxFileSizeType={this.props.maxFileSizeType}
        maxFileAllowed={this.props.maxFileAllowed}
        uploadOn={this.props.uploadOn}
        slug={this.props.slug}
        refTableId={this.props.refTableId}
        displayFileName={this.props.displayFileName}
        documentTypeSlug={this.props.documentTypeSlug}
        description={this.props.description}
        mediaType={this.props.mediaType}
        dailyDelivery={this.props.dailyDelivery}
        propertySlug={this.props.propertySlug}
        sortOrder={this.props.sortOrder}
        statusId={this.props.statusId}
        h1={this.props.h1}
        h2={this.props.h2}
        resizeOptions={this.props.resizeOptions}
        checkValidation={this.props.checkValidation}
        destinationFolder={this.props.destinationFolder}
        uploadedFiles={this.props.uploadedFiles}
        deletedFiles={this.props.deletedFiles}
        onRemovedFiles={this.handleRemovedFiles}
        percentage={this.state.progress}
        accept={this.props.acceptOnly}
        displayPreviewStyle={this.props.displayPreviewStyle}
        drag={this.props.drag}
        dragText={this.props.dragText}
        newRelatedFilesDescChange={this.props.newRelatedFilesDescChange}
        newRelatedFilesMainImgChange={this.props.newRelatedFilesMainImgChange}
        isModal={this.props.isModal}
        isEditable={this.props.isEditable}
        deleteImgModal={this.props.deleteImgModal}
        closePropImgModal={this.props.closePropImgModal}
        validationFire={this.props.validationFire}
      />
    ) : (
      <HtmlTemplate
        uploadType={this.props.uploadType}
        fieldTitle={this.props.fieldTitle}
        fieldLabel={this.props.fieldLabel}
        labelClassName={this.props.labelClassName}
        inputClassName={this.props.inputClassName}
        helpText={this.props.helpText}
        sampleFileUrl={this.props.sampleFileUrl}
        fieldName={this.props.fieldName}
        allowedType={this.props.allowedType}
        maxFileSize={this.props.maxFileSize}
        maxFileSizeType={this.props.maxFileSizeType}
        maxFileAllowed={this.props.maxFileAllowed}
        uploadOn={this.props.uploadOn}
        slug={this.props.slug}
        refTableId={this.props.refTableId}
        displayFileName={this.props.displayFileName}
        documentTypeSlug={this.props.documentTypeSlug}
        description={this.props.description}
        mediaType={this.props.mediaType}
        dailyDelivery={this.props.dailyDelivery}
        validationFire={this.props.validationFire}
        propertySlug={this.props.propertySlug}
        sortOrder={this.props.sortOrder}
        statusId={this.props.statusId}
        h1={this.props.h1}
        h2={this.props.h2}
        resizeOptions={this.props.resizeOptions}
        checkValidation={this.props.checkValidation}
        destinationFolder={this.props.destinationFolder}
        uploadedFiles={this.props.uploadedFiles}
        deletedFiles={this.props.deletedFiles}
        onRemovedFiles={this.handleRemovedFiles}
        percentage={this.state.progress}
        accept={this.props.acceptOnly}
        displayPreviewStyle={this.props.displayPreviewStyle}
        drag={this.props.drag}
        dragText={this.props.dragText}
        newRelatedFilesPreview={this.newRelatedFilesPreview}
        newRelatedFilesDescChange={this.props.newRelatedFilesDescChange}
        newRelatedFilesMainImgChange={this.props.newRelatedFilesMainImgChange}
        isModal={this.props.isModal}
        isEditable={this.props.isEditable}
        deleteImgModal={this.props.deleteImgModal}
        closePropImgModal={this.props.closePropImgModal}
        onValueChange={this.handleValueFromChild}
        previewFiles={this.props.previewFiles}
      // selected_file_name={this.selected_file_name}
      />
    );
  }

  // selected_file_name(selected_file_name) {
  //    this.props.selected_display_file_name(selected_file_name);
  //   this.setState({ selected_file_name: selected_file_name });
  // }

  handleRemovedFiles = async (props) => {
    //let removedFiles = this.state.removedFiles;
    await this.setState({
      removedFiles: [...this.state.removedFiles, props],
    });

    // await this.setState({ removedFiles: Object.assign({}, this.state.removedFiles, props) });
    // let removedFiles = this.state.removedFiles;
    this.a.push(props);
  };

  fileUploadFun = async (formID, dec, mainImg = [], slug) => {
    let form = document.getElementById(formID);
    const allFiles = form.getElementsByClassName("file_widget_class");
    const validationResponse = await this.validateFiles(allFiles);
    if (validationResponse.validationStatus) {
      const response = await this.uploadFiles(allFiles, formID, dec, mainImg, slug);
      return {
        status: true,
        response: response,
      };
    } else {
      return {
        status: false,
        response: validationResponse,
      };
    }
  };

  validateFiles = async (allFiles) => {
    let finalValidationResponse = [];

    let validationStatus = true;

    for (let i = 0; i < allFiles.length; i++) {
      let validationMessage = [];
      if (allFiles[i].attributes.allowedtype !== undefined) {
        const allowedType = allFiles[i].attributes.allowedtype.value;
        const maxFileSize = allFiles[i].attributes.maxfilesize.value;
        const maxFileSizeType = allFiles[i].attributes.maxfilesizetype.value;
        const maxFileAllowed = allFiles[i].attributes.maxfileallowed.value;
        const fieldName = allFiles[i].attributes.name.value;

        let validationRules = {
          allowedType: allowedType,
          maxFileSize: maxFileSize,
          maxFileSizeType: maxFileSizeType,
          maxFileAllowed: maxFileAllowed,
          fieldName: fieldName,
        };

        //let allFilesValidation = [];
        const FileValidationsObj = new FileValidations();
        for (let j = 0; j < allFiles[i].files.length; j++) {
          const fileObject = allFiles[i].files[j];
          const validationResponse = await FileValidationsObj.checkValidation(
            fileObject,
            validationRules
          );
          if (!validationResponse.validationStatus) {
            validationStatus = false;
            validationMessage.push(validationResponse.validationMessage);
          }
        }

        let finalResponse = {
          validationMessage: validationMessage,
          fieldName: fieldName,
        };
        finalValidationResponse.push(finalResponse);
      }
    }

    finalValidationResponse.validationStatus = validationStatus;
    return finalValidationResponse;
  };

  parseResizeOptions = async (resizeOptionsList) => {
    let finalResizeOptions = [];
    let resizeOptions = resizeOptionsList.split("||");
    for (let m = 0; m < resizeOptions.length; m++) {
      let objectValue = {};
      let options = resizeOptions[m].split(",");
      for (let n = 0; n < options.length; n++) {
        let options2 = options[n].split(":");
        objectValue[options2[0]] = options2[1];
      }
      await finalResizeOptions.push(objectValue);
    }
    return finalResizeOptions;
  };

  uploadFiles = async (allFiles, formID, dec, mainImg = [], slugR) => {
    let finalResponse = [];
    let inputData = [];

    let currentSortOrderImage = allFiles && allFiles[0] ? allFiles[0].attributes.sortOrder.value : '';
    //@logic Loop through all the input type files
    for (let i = 0; i < allFiles.length; i++) {
      //@logic creating constant variables for storing props values.
      if (allFiles[i].attributes.allowedtype !== undefined) {
        const allowedType = allFiles[i].attributes.allowedtype.value;
        const maxFileSize = allFiles[i].attributes.maxfilesize.value;
        const maxFileSizeType = allFiles[i].attributes.maxfilesizetype.value;
        const maxFileAllowed = allFiles[i].attributes.maxfileallowed.value;
        const fieldName = allFiles[i].attributes.name.value;
        const resizeOptionsList = allFiles[i].attributes.resizeoptions.value;

        const checkvalidation = allFiles[i].attributes.checkvalidation.value;
        const uploadon = allFiles[i].attributes.uploadon.value;
        const slug = allFiles[i].attributes.slug.value !== "" || allFiles[i].attributes.slug.value !== null && slugR === "" ? allFiles[i].attributes.slug.value : slugR;
        const refTableId = allFiles[i].attributes.refTableId.value;
        const displayFileName = allFiles[i].attributes.displayFileName.value;
        const documentTypeSlug = allFiles[i].attributes.documentTypeSlug.value;
        const description = allFiles[i].attributes.description.value;
        // const is_main_img = allFiles[i].attributes.is_main_img.value ? allFiles[i].attributes.is_main_img.value : false;
        const mediaType = allFiles[i].attributes.mediaType.value;
        const dailyDelivery = allFiles[i].attributes.dailyDelivery.value;
        const validationFire = allFiles[i].attributes.validationFire.value;
        const propertySlug = allFiles[i].attributes.propertySlug.value;
        const h1 = allFiles[i].attributes.h1.value;
        const h2 = allFiles[i].attributes.h2.value;
        let sortOrder = currentSortOrderImage;
        const statusId = allFiles[i].attributes.statusId.value;
        const destinationfolder =
          allFiles[i].attributes.destinationfolder.value;

        //@logic creating resize array with different size options given in props
        let finalResizeOptions = await this.parseResizeOptions(
          resizeOptionsList
        );
        //resizeOptions = resizeOptions.split(':');

        // let validationRules;
        let validationRules = {
          allowedType: allowedType,
          maxFileSize: maxFileSize,
          maxFileSizeType: maxFileSizeType,
          maxFileAllowed: maxFileAllowed,
        };

        //@logic now loop thorugh each uploaded files
        let allFilesData = [];
        let reStatus = "";

        for (let j = 0; j < allFiles[i].files.length; j++) {
          //@logic load percentbar component

          const fileObject = allFiles[i].files[j];
          sortOrder++;
          const newSortOrder = sortOrder;

          //@logic file data array end
          // let percentage = 0;
          const HEADER_TOKEN = localStorage.getItem("headerToken")
            ? localStorage.getItem("headerToken")
            : "";
          const DEVICE_TYPE = process.env.REACT_APP_DEVICE_TYPE
            ? process.env.REACT_APP_DEVICE_TYPE
            : localStorage.getItem("deviceType")
              ? localStorage.getItem("deviceType")
              : "";
          const PLATFORM_KEY = process.env.REACT_APP_PLATFORM_KEY
            ? process.env.REACT_APP_PLATFORM_KEY
            : localStorage.getItem("platformKey")
              ? localStorage.getItem("platformKey")
              : "";
          const ROLE = global.currerntRoleSlug
            ? global.currerntRoleSlug
            : localStorage.getItem("currerntRoleSlug")
              ? localStorage.getItem("currerntRoleSlug")
              : "";
          // const HEADER_X_TOKEN = process.env.REACT_APP_X_AUTH_TOKEN;

          let axiosConfig = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + HEADER_TOKEN,
              // 'X-Authorization': HEADER_X_TOKEN,
              "device-type": DEVICE_TYPE,
              role: ROLE,
              "platform-key": PLATFORM_KEY,
            },
            // onUploadProgress: (progressEvent) => {
            //   percentage = parseInt(
            //     Math.round((progressEvent.loaded / progressEvent.total) * 100)
            //   );
            //   // this.setState({ progress: percentage })
            //   // var progress = document.getElementById(
            //   //   "progress" + fieldName + j
            //   // );
            //   // var progressBarElement = document.getElementById(
            //   //   "progressBar" + fieldName + j
            //   // );
            //   // progress.classList.remove("hidden");
            //   // document.getElementById(
            //   //   "progressBar" + fieldName + j
            //   // ).style.width = percentage + "%";
            //   // document.getElementById("progressBar" + fieldName + j).innerHTML =
            //   //   percentage + " % ";
            //   // Do something with the progress details
            // },
          };

          /* now upload individual file on server */
          let path = process.env.REACT_APP_API_ENDPOINT + "upload";
          let fileData = new FormData();
          fileData.append(fieldName, fileObject, fileObject.name);
          fileData.append("orgFileDestinationFolder", destinationfolder);
          fileData.append("fieldName", fieldName);
          fileData.append("uploadOn", uploadon);
          fileData.append("slug", slug);
          fileData.append("refTableId", refTableId);
          fileData.append("displayFileName", displayFileName);
          fileData.append("documentTypeSlug", documentTypeSlug);
          // fileData.append("description", dec ? dec[j] : "");
          fileData.append("description", description);
          // fileData.append("is_main_img", mainImg ? mainImg[j] : false);
          fileData.append("mediaType", mediaType);
          fileData.append("dailyDelivery", dailyDelivery);
          fileData.append("validationFire", validationFire);
          fileData.append("propertySlug", propertySlug);
          fileData.append("h1", h1);
          fileData.append("h2", h2);
          fileData.append("sortOrder", newSortOrder);
          fileData.append("statusId", statusId);
          fileData.append("resizeOptions", resizeOptionsList);
          await axios
            .post(path, fileData, axiosConfig)
            .then(async (res) => {
              let uploadResponse = [];

              uploadResponse = {
                orgFileName: res.data.originalName,
                newName: res.data.newName,
                fileStatus: res.data.status,
                fileMessage: res.data.message,
                fileDestination: destinationfolder,
                order: newSortOrder,
                description: res.data.description,
                // is_main_img: res.data.is_main_img,
              };
              await allFilesData.push(uploadResponse);
            })
            .catch((err) => {
              // console.log("err", err);
            });
        }

        inputData = {
          fieldName: fieldName,
          uploadedTo: uploadon,
          slug: slug,
          refTableId: refTableId,
          displayFileName: displayFileName,
          documentTypeSlug: documentTypeSlug,
          description: description,
          // is_main_img: is_main_img,
          mediaType: mediaType,
          dailyDelivery: dailyDelivery,
          validationFire: validationFire,
          propertySlug: propertySlug,
          h1: h1,
          h2: h2,
          sortOrder: sortOrder,
          statusId: statusId,
          checkValidation: checkvalidation,
          validationRules: validationRules,
          resizeOptions: finalResizeOptions,
          filesData: allFilesData,
          resStatus: reStatus,
        };
        await finalResponse.push(inputData);
      }
    }

    // return output
    return finalResponse;
  };
}

// Specifies the default values for props:
FileWidget.defaultProps = {
  uploadType: "single",
  fieldLabel: "",
  labelClassName: "",
  inputClassName: "",
  drag: false,
  preview: false,
  allowDelete: true,
  allowMultipleDelete: false,
  className: "",
  checkValidation: "true",
  allowedType: "", // string
  maxFileSize: 5, // int max size
  maxFileSizeType: "mb", //enum (either kb or mb), in small case.
  maxFileAllowed: 10, // how many files allow to select.
  uploadOn: "s3",
  slug: "",
  refTableId: "",
  displayFileName: "",
  documentTypeSlug: "",
  description: "",
  mediaType: "",
  dailyDelivery: "",
  validationFire: "",
  propertySlug: "",
  h1: "",
  h2: "",
  sortOrder: "",
  statusId: "",
  helpText: "",
  sampleFileUrl: "",
  allowPartialSubmit: false,
  outputType: false,
  fileNamePrefix: "", // string
  resizeOptions: "", // string
};

FileWidget.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldTitle: PropTypes.string.isRequired,
  formID: PropTypes.string.isRequired,
};

export default FileWidget;
