import React from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import KeycafeService from "../../../services/KeycafeService";
import Moment from "moment";

const Keys = (props) => {
  const [state, setState] = useState({
    extraData: window.location.pathname.split("/")[4],
    total_record: 0,
    records: [],
    extraButtons: [],
    tableQueryString: "",
    customQueryString: "",
    filter_value: "",
    isSearch: global.isSearch,
    is_search: 0,
    unit_number: "",
    input: {},
    // data:[],
    property_slug: props.property_slug ? props.property_slug : "",
    activeClass: "alert-activity",
    columns: [
      {
        key: "unit_name",
        text: "Unit Name",
        className: "unit_number hand-cursor",
        sortable: false,
        cell: (record) => {
          return record.unit_name ? record.unit_name : "";
        },
      },
      {
        key: "ref_table_id",
        text: "Key Status",
        className: "tower_id hand-cursor",
        sortable: false,
        cell: (record) => {
          return record.ref_table_id != 0 &&
            record.current_key_status === "DROPOFF"
            ? "Key in Locker"
            : record.ref_table_id == 1 && record.current_key_status != undefined
            ? "Key with Guest"
            : record.ref_table_id == 2 && record.current_key_status != undefined
            ? "Key with Cleaner"
            : record.ref_table_id == 3 && record.current_key_status != undefined
            ? "Key with Resident"
            : record.ref_table_id == 4 && record.current_key_status != undefined
            ? "Key with Vistor"
            : "";
        },
      },
      {
        key: "bin_no",
        text: "Bin",
        className: "unit_number hand-cursor text-left",
        sortable: false,
        cell: (record) => {
          return record.bin_no ? record.bin_no : "";
        },
      },
      {
        key: "current_key_status",
        text: "Activity",
        className: "unit_number hand-cursor text-left",
        sortable: false,
        cell: (record) => {
          return record.current_key_status == "DROPOFF"
            ? "Dropped off"
            : record.current_key_status == "PICKUP"
            ? "Picked up"
            : "";
        },
      },
      {
        key: "key_status_updated_time",
        text: "Updated On",
        className: "unit_number hand-cursor text-left date_tbl_formate",
        sortable: false,
        cell: (record) => {
          return record.key_status_updated_time
            ? Moment(record.key_status_updated_time).format(
                global.dateTimeFormat
              )
            : "";
        },
      },
    ],
    config: {
      sort: {
        column: "",
        order: "",
      },
      key_column: "slug",
      page_size: global.page_size,
      length_menu: global.page_length_menu,
      show_length_menu: true,
      show_filter: global.show_filter,
      show_pagination: global.show_pagination,
      pagination: "advance",
      button: {
        excel: global.excel_button,
        print: global.print_button,
        extra: false,
      },
      language: {
        no_data_text: "No data found.",
      },
    },
  });

  const tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      setState({ ...state, tableQueryString: queryString });
    } else {
      queryString += "&" + state.customQueryString;
      setState({ ...state, tableQueryString: queryString });
    }

    if (data.filter_value !== state.filter_value) {
      clearTimeout(state.filter_time);
      setState({
        ...state,
        filter_time: setTimeout(() => getData(queryString), global.filter_time),
        filter_value: data.filter_value,
      });
    } else {
      getData("", queryString);
    }
  };

  function clearFilter() {
    setState({
      ...state,
      module_search: "",
      unit_number: "",
      is_search: 0,
    });
  }

  const rowClickedHandler = (event, data, rowIndex) => {
    props.history.push(
      props.match.params.propertySlug !== undefined
        ? "keys/keyactivity-view/" + data.slug
        : state.property_slug
        ? "/keys/keyactivity-view/" + state.property_slug + "/" + data.slug
        : "/keys/keyactivity-view/" + data.property_slug + "/" + data.slug
    );
  };
  useEffect(() => {
    getData();
  }, [state.is_search, props.property_slug]);

  const getData = async (tab, queryString = "") => {
    var list = [];
    var totalRecords = 0;
    // var unit_slug = props.unit_slug ? "&unit_slug=" + props.unit_slug : "";
    // var unit_number = state.unit_number
    //   ? "&unit_number=" + state.unit_number
    //   : "";
    // queryString = unit_slug + unit_number;
    // queryString =
    //   queryString !== ""
    //     ? "property_slug=" + props.property_slug
    //       ? props.property_slug
    //       : "" + (queryString ? "&" + queryString : "")
    //     : "property_slug=" + props.property_slug
    //     ? props.property_slug
    //     : "" + (queryString ? "&" + queryString : "");
    var unit_slug = props.unit_slug ? "&unit_slug=" + props.unit_slug : "";
    var unit_number = state.unit_number
      ? "&unit_number=" + state.unit_number
      : "";
    queryString = queryString + unit_slug + unit_number;
    queryString =
      queryString !== ""
        ? "property_slug=" +
          (props.property_slug ? props.property_slug : "") +
          (queryString ? "&" + queryString : "")
        : "property_slug=" +
          (props.property_slug
            ? props.property_slug
            : "" + (queryString ? "&" + queryString : ""));
    let res = await KeycafeService.getKeycafeStatus(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : {};
      totalRecords = res.data ? res.data.total : 0;
    }

    setState({
      ...state,
      total_record: totalRecords,
      records: list,
      isSearch: 0,
    });
  };

  return (
    <>
      <main>
        {state.extraData === "keys" ? (
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-12">
                  <div className="scrolling-carousel">
                    <div>
                      <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                        {state.is_search === 1 ? (
                          <>
                            <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between  scrolling-carousel pd-t-30">
                              {"Search applied on: Unit Number" +
                                " - " +
                                state.unit_number}
                              <button
                                onClick={clearFilter}
                                className="mg-l-15 btn-success-outline-small"
                              >
                                Clear
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block">
                          <div className="pd-lg-l-0 pd-xs-l-15">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                              config={
                                state.total_record > global.page_size
                                  ? state.config
                                  : state.config
                              }
                              records={state.records}
                              columns={state.columns}
                              extraButtons={state.extraButtons}
                              dynamic={true}
                              total_record={state.total_record}
                              onChange={tableChangeHandler}
                              onRowClicked={rowClickedHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        ) : (
          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
            <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block">
              <div className="pd-lg-l-0 pd-xs-l-15">
                <ReactDatatable
                  className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                  config={
                    state.total_record > global.page_size
                      ? state.config
                      : state.config
                  }
                  records={state.records}
                  columns={state.columns}
                  extraButtons={state.extraButtons}
                  dynamic={true}
                  total_record={state.total_record}
                  onChange={tableChangeHandler}
                  onRowClicked={rowClickedHandler}
                />
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default Keys;
