////////////////////////////////////////////////////////////
//     							                          //
//  Program: Dashboard.jsx                        //
//  Application: Dashboard                        //
//  Option: to view Dashboard                     //
//  Developer: Kavita singh 						      //
//  Date: 2023-03-17                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import CompanyService from "../../services/CompanyService";
import companyLogo from "./../../assets/images/company_placeholder.png";
import PhoneNumberFormat from "../common/PhoneNumberFormat";
import WidgetService from "../../services/WidgetService";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Chart from "../Chart";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: "",
      total_client_count: "",
      checkInList: [],
      checkOutList: [],
      no_of_units_count: 0,
      no_of_building_count: 0,
      total_guest_paid_past_count: 0,
      total_guest_paid_past_future_count: 0,
      amount_paid_out_to_property_count: 0,
      future_earning_to_property_count: 0,
      amount_paid_out_to_resident_count: 0,
      future_erning_to_resident_count: 0,
      taxes_collected_count: 0,
      top_performer_guest_paid_past_count: 0,
      top_performer_night_stay_past_count: 0,
      top_performer_night_stay_past_future_count: 0,
      top_performer_guest_paid_past_future_count: 0,
      top_performer_past_unit_name: "",
      top_performer_future_unit_name: "",
      taxes_remitted_count: 0,
      units_allow_to_homeshare_count: 0,
      units_allow_to_homeshare_count_data: 0,
      units_homesharing_count: 0,
      units_participating_count: 0,
      nights_hosted_past_count: 0,
      nights_hosted_past_future_count: 0,
      adr_past_count: 0,
      adr_past_future_count: 0,
      occupancy_past_count: 0,
      occupancy_past_future_count: 0,
      revervation_hosted_past_count: 0,
      revervation_hosted_past_future_count: 0,
      average_length_of_stay_past_count: 0,
      average_length_of_stay_future_count: 0,
      booking_lead_time_past_count: 0,
      booking_lead_time_past_future_count: 0,
      total_reservation_count_val: 0,
      total_resident_count_val: 0,
      total_guest_count_val: 0,
      total_property_count_val: 0,
      total_listing_count_val: 0,
      total_client_count_val: 0,
      total_brand_count_val: 0,
      floorplan_count: 0,
      guest_review_count: 0,
      ru_inquiry_count: 0,
      // ru_inquiry_count: 0,
      property_rating: 0,
      total_no_of_waivers:0,
      total_no_of_deposit:0,
      percentage_waiver:0,
      percentgage_deposit:0,
    };
  }

  componentDidMount() {
    this.getData();
    this.getWidgetData();
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};

    let res = await CompanyService.getCompany();

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {}
      resData.slug = res.data.slug ? res.data.slug : "";
      resData.company_name = res.data.company_name ? res.data.company_name : "";
      resData.company_logo_thumb_url = res.data.company_logo_thumb_url
        ? res.data.company_logo_thumb_url
        : "";
      resData.aka_name = res.data.aka_name ? res.data.aka_name : "";
      resData.legal_entity_name = res.data.legal_entity_name
        ? res.data.legal_entity_name
        : "";
      resData.tax_id = res.data.tax_id ? res.data.tax_id : "";
      resData.contact_name = res.data.contact_name ? res.data.contact_name : "";
      resData.phone_number = res.data.phone_number ? res.data.phone_number : "";
      resData.email = res.data.email ? res.data.email : "";
      resData.website_cookie = res.data.website_cookie
        ? res.data.website_cookie
        : "";
      resData.term_condition = res.data.term_condition
        ? res.data.term_condition
        : "";

      resData.orion_haus_account_manager_name =
        res.data &&
        res.data.orion_haus_account_manager &&
        res.data.orion_haus_account_manager.first_name
          ? res.data.orion_haus_account_manager.first_name
          : "";
      resData.orion_haus_email =
        res.data &&
        res.data.orion_haus_account_manager &&
        res.data.orion_haus_account_manager.email
          ? res.data.orion_haus_account_manager.email
          : "";
      resData.orion_haus_phone_number =
        res.data &&
        res.data.orion_haus_account_manager &&
        res.data.orion_haus_account_manager.phone_number
          ? res.data.orion_haus_account_manager.phone_number
          : "";
      resData.client_contact_name =
        res.data && res.data.client_info && res.data.client_info.first_name
          ? res.data.client_info.first_name
          : "";
      resData.client_contact_email =
        res.data && res.data.client_info && res.data.client_info.email
          ? res.data.client_info.email
          : "";
      resData.client_contact_phone_number =
        res.data && res.data.client_info && res.data.client_info.phone_number
          ? res.data.client_info.phone_number
          : "";

      resData.total_brand_count = res.data.total_brand_count
        ? res.data.total_brand_count
        : "";
      resData.total_property_count = res.data.total_property_count
        ? res.data.total_property_count
        : "";
      resData.total_unit_count = res.data.total_unit_count
        ? res.data.total_unit_count
        : "";
      resData.total_client_count = res.data.total_client_count
        ? res.data.total_client_count
        : "";
      resData.total_listing_count = res.data.total_listing_count
        ? res.data.total_listing_count
        : "";
      resData.total_reservation_count = res.data.total_reservation_count
        ? res.data.total_reservation_count
        : "";
      resData.total_resident_count = res.data.total_resident_count
        ? res.data.total_resident_count
        : "";
      resData.total_guest_count = res.data.total_guest_count
        ? res.data.total_guest_count
        : "";
      resData.total_night_count = res.data.total_night_count
        ? res.data.total_night_count
        : "";
      // resData.checkInList = res.data.check_in_list ? res.data.check_in_list : []
      // resData.checkOutList = res.data.check_out_list ? res.data.check_out_list : []
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  async getWidgetData() {
    this.setState({ spinnerLoader: true, loading: true });
    var resData = {};
    var unitCount = 0;
    var BuildingCount = 0;
    var OhTotalPastRevenue = 0;
    var OhTotalPastFutureRevenue = 0;
    var TotalAmountPaidOutToProperty = 0;
    var TotalAmountFutureEarningToProperty = 0;
    var TotalAmountPaidOutToResident = 0;
    var TotalAmountFutureEarningToResident = 0;
    var TotalAmountTaxCollected = 0;
    var TotalAmountTaxRemitted = 0;
    var TotalUnitsHomesharing = 0;
    var TotalUnitsAllowedHomesharing = 0;
    var TotalUnitsAllowedHomesharingData = 0;
    var TotalAvgLenOfStayPast = 0;
    var TotalAvgLenOfStayPastFuture = 0;
    var TotalNightHostedPast = 0;
    var TotalNightHostedPastFuture = 0;
    var TotalAdrPast = 0;
    var TotalAdrPastFuture = 0;
    var TotalOccupancyPast = 0;
    var TotalOccupancyPastFuture = 0;
    var TotalReservationHostedPast = 0;
    var TotalReservationHostedPastFuture = 0;
    var TotalBookingLeadTimePAST = 0;
    var TotalBookingLeadTimePastFuture = 0;
    var TotalReservationCount = 0;
    var TotalResidentCount = 0;
    var TotalGuestCount = 0;
    var TotalBuildingCount = 0;
    var TotalListingCount = 0;
    var TotalClientCount = 0;
    var TotalBrandCount = 0;
    var TotalTopPerformaerGuestPaidPast = 0;
    var TotalTopPerformaerGuestPaidPastFuture = 0;
    var TotalTopPerformaerNightBookedPast = 0;
    var TotalTopPerformaerNightBookedPastFuture = 0;
    var TopPerformerPastUnitName = "";
    var TopPerformerFutureUnitName = "";
    var floorplanCount = 0;
    var guestReviewCount = 0;
    var ruInquiryCount = 0;
    // var ruInquiryCount = 0;
    var propertyRating = 0;
    var TotalWaivers = 0;
    var TotalDeposit = 0;
    var PercentageWaiver = 0;
    var PercentageDeposit = 0;
    let res = await WidgetService.getWidgetListCount();

    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
      unitCount = resData.units_count ? resData.units_count : 0;
      BuildingCount = resData.building_count ? resData.building_count : 0;
      OhTotalPastRevenue = resData.total_oh_past_revenue
        ? resData.total_oh_past_revenue
        : 0;
      OhTotalPastFutureRevenue = resData.total_oh_past_future_revenue
        ? resData.total_oh_past_future_revenue
        : 0;
      TotalAmountPaidOutToProperty = resData.total_amount_paid_out_to_property
        ? resData.total_amount_paid_out_to_property
        : 0;
      TotalAmountFutureEarningToProperty =
        resData.total_amount_future_earning_to_property
          ? resData.total_amount_future_earning_to_property
          : 0;
      TotalAmountPaidOutToResident = resData.total_amount_paid_out_to_resident
        ? resData.total_amount_paid_out_to_resident
        : 0;
      TotalAmountFutureEarningToResident =
        resData.total_amount_future_earning_to_resident
          ? resData.total_amount_future_earning_to_resident
          : 0;
      TotalAmountTaxCollected = resData.total_amount_tax_collected
        ? resData.total_amount_tax_collected
        : 0;
      TotalTopPerformaerGuestPaidPast =
        resData.top_performer_guest_paid_past_count_data
          ? resData.top_performer_guest_paid_past_count_data
          : 0;
      TotalTopPerformaerGuestPaidPastFuture =
        resData.top_performer_guest_paid_past_future_count_data
          ? resData.top_performer_guest_paid_past_future_count_data
          : 0;
      TopPerformerPastUnitName = resData.top_performer_past_unit_name
        ? resData.top_performer_past_unit_name
        : "";
      TopPerformerFutureUnitName = resData.top_performer_future_unit_name
        ? resData.top_performer_future_unit_name
        : "";

      TotalTopPerformaerNightBookedPast =
        resData.top_performer_nights_booked_past_count_data
          ? resData.top_performer_nights_booked_past_count_data
          : 0;
      TotalTopPerformaerNightBookedPastFuture =
        resData.top_performer_nights_booked_past_future_count_data
          ? resData.top_performer_nights_booked_past_future_count_data
          : 0;

      TotalAmountTaxRemitted = resData.total_amount_tax_remitted
        ? resData.total_amount_tax_remitted
        : 0;
      TotalUnitsAllowedHomesharingData = resData.total_units_allowed_homesharing
        ? resData.total_units_allowed_homesharing
        : 0;
      TotalUnitsAllowedHomesharing = Number.isInteger(
        TotalUnitsAllowedHomesharingData
      );
      TotalUnitsHomesharing = resData.total_units_homesharing
        ? resData.total_units_homesharing
        : 0;
      TotalAvgLenOfStayPast = resData.total_avg_len_of_stay_past
        ? resData.total_avg_len_of_stay_past
        : 0;
      TotalAvgLenOfStayPastFuture = resData.total_avg_len_of_stay_past_future
        ? resData.total_avg_len_of_stay_past_future
        : 0;
      TotalNightHostedPast = resData.total_night_hosted_past
        ? resData.total_night_hosted_past
        : 0;
      TotalNightHostedPastFuture = resData.total_night_hosted_past_future
        ? resData.total_night_hosted_past_future
        : 0;
      TotalAdrPast = resData.total_adr_past ? resData.total_adr_past : 0;
      TotalAdrPastFuture = resData.total_adr_past_future
        ? resData.total_adr_past_future
        : 0;
      TotalOccupancyPast = resData.total_occupancy_past
        ? resData.total_occupancy_past
        : 0;
      TotalOccupancyPastFuture = resData.total_occupancy_past_future
        ? resData.total_occupancy_past_future
        : 0;
      TotalReservationHostedPast = resData.total_reservation_hosted_past
        ? resData.total_reservation_hosted_past
        : 0;
      TotalReservationHostedPastFuture =
        resData.total_reservation_hosted_past_future
          ? resData.total_reservation_hosted_past_future
          : 0;
      TotalBookingLeadTimePAST = resData.total_booking_lead_time_past
        ? resData.total_booking_lead_time_past
        : 0;
      TotalBookingLeadTimePastFuture =
        resData.total_booking_lead_time_past_future
          ? resData.total_booking_lead_time_past_future
          : 0;

      TotalReservationCount = resData.total_reservation_count_data
        ? resData.total_reservation_count_data
        : 0;
      TotalResidentCount = resData.total_resident_count_data
        ? resData.total_resident_count_data
        : 0;
      TotalGuestCount = resData.total_guest_count_data
        ? resData.total_guest_count_data
        : 0;
      TotalBuildingCount = resData.building_count ? resData.building_count : 0;
      TotalListingCount = resData.total_listing_count_data
        ? resData.total_listing_count_data
        : 0;
      TotalClientCount = resData.total_client_count_data
        ? resData.total_client_count_data
        : 0;
      TotalBrandCount = resData.total_brand_count_data
        ? resData.total_brand_count_data
        : 0;
      floorplanCount = resData.floorplan_count ? resData.floorplan_count : 0;
      guestReviewCount = resData.guest_review_count
        ? resData.guest_review_count
        : 0;
      ruInquiryCount = resData.ru_inquiry_count ? resData.ru_inquiry_count : 0;
      // ruInquiryCount = resData.ru_inquiry_count ? resData.ru_inquiry_count : 0;
      propertyRating = resData.all_property_rating
        ? resData.all_property_rating
        : 0;
      TotalWaivers = resData.total_no_of_waivers ? resData.total_no_of_waivers:0;
      TotalDeposit = resData.total_no_of_deposit ? resData.total_no_of_deposit:0;
      PercentageWaiver = resData.percentage_waiver ? resData.percentage_waiver:0;
      PercentageDeposit = resData.percentage_deposit ? resData.percentage_deposit:0;
    }

    this.setState({
      resData: resData,
      no_of_units_count: unitCount,
      no_of_building_count: BuildingCount,
      total_guest_paid_past_count: OhTotalPastRevenue,
      total_guest_paid_past_future_count: OhTotalPastFutureRevenue,
      amount_paid_out_to_property_count: TotalAmountPaidOutToProperty,
      future_earning_to_property_count: TotalAmountFutureEarningToProperty,
      amount_paid_out_to_resident_count: TotalAmountPaidOutToResident,
      future_erning_to_resident_count: TotalAmountFutureEarningToResident,
      taxes_collected_count: TotalAmountTaxCollected,
      top_performer_guest_paid_past_count: TotalTopPerformaerGuestPaidPast,
      top_performer_guest_paid_past_future_count:
        TotalTopPerformaerGuestPaidPastFuture,
      top_performer_past_unit_name: TopPerformerPastUnitName,
      top_performer_future_unit_name: TopPerformerFutureUnitName,
      top_performer_night_stay_past_count: TotalTopPerformaerNightBookedPast,
      top_performer_night_stay_past_future_count:
        TotalTopPerformaerNightBookedPastFuture,
      taxes_remitted_count: TotalAmountTaxRemitted,
      units_allow_to_homeshare_count: TotalUnitsAllowedHomesharing,
      units_allow_to_homeshare_count_data: TotalUnitsAllowedHomesharingData,
      units_homesharing_count: TotalUnitsHomesharing,
      average_length_of_stay_past_count: TotalAvgLenOfStayPast,
      average_length_of_stay_future_count: TotalAvgLenOfStayPastFuture,
      nights_hosted_past_count: TotalNightHostedPast,
      nights_hosted_past_future_count: TotalNightHostedPastFuture,
      adr_past_count: TotalAdrPast,
      adr_past_future_count: TotalAdrPastFuture,
      occupancy_past_count: TotalOccupancyPast,
      occupancy_past_future_count: TotalOccupancyPastFuture,
      revervation_hosted_past_count: TotalReservationHostedPast,
      revervation_hosted_past_future_count: TotalReservationHostedPastFuture,
      booking_lead_time_past_count: TotalBookingLeadTimePAST,
      booking_lead_time_past_future_count: TotalBookingLeadTimePastFuture,
      total_reservation_count_val: TotalReservationCount,
      total_resident_count_val: TotalResidentCount,
      total_guest_count_val: TotalGuestCount,
      total_property_count_val: TotalBuildingCount,
      total_listing_count_val: TotalListingCount,
      total_client_count_val: TotalClientCount,
      total_brand_count_val: TotalBrandCount,
      floorplan_count: floorplanCount,
      guest_review_count: guestReviewCount,
      ru_inquiry_count: ruInquiryCount,
      // ru_inquiry_count: ruInquiryCount,
      property_rating: propertyRating,
      total_no_of_waivers:TotalWaivers,
      total_no_of_deposit:TotalDeposit,
      percentage_waiver:PercentageWaiver,
      percentgage_deposit:PercentageDeposit,
    });
    this.setState({ spinnerLoader: false, loading: false });
  }

  render() {
    return (
      <div>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
          <Container>
            <div className="row row-xs">
              <div className="col-md-12 mg-t-10 mg-b-10 "></div>
              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                  <h3>Dashboard</h3>
                  {/* <div className="d-flex">
                    <Link to={'/company'} ><button className="btn-success-outline mg-r-20">View</button></Link>
                  </div> */}
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div className="row mg-t-30 pd-t-2 cardsection overview_sec">
              <div className="col-md-4 col-lg-3 mg-b-30 cardLeft d-none">
                <div className="dashboardLeftcard az-profile-overview">
                  <div className="az-img-user ml-auto mr-auto align-items-center mt-0">
                    <figure>
                      <img
                        src={
                          this.state.company_logo_thumb_url
                            ? this.state.company_logo_thumb_url
                            : companyLogo
                        }
                        alt=""
                      />
                    </figure>
                  </div>
                  {/* az-img-user */}

                  <div className="d-flex justify-content-center text-center mg-b-20 mg-t-20">
                    <div>
                      <p className="az-profile-name-text">DBA</p>
                      <p className="media-body">
                        {this.state.company_name ? this.state.company_name : ""}
                      </p>

                      <p className="az-profile-name-text">Company SKU</p>
                      <p className="media-body">
                        {this.state.aka_name ? this.state.aka_name : ""}
                      </p>

                      <p className="az-profile-name-text">Legal Entity</p>
                      <p className="media-body">
                        {this.state.legal_entity_name
                          ? this.state.legal_entity_name
                          : ""}
                      </p>

                      <p className="az-profile-name-text">Tax ID</p>
                      <p className="media-body">
                        {this.state.tax_id ? this.state.tax_id : ""}
                      </p>

                      {/* manager info start */}

                      <p className="az-profile-name-text">Client Contact</p>

                      <p className="media-body p-0 m-0">
                        {this.state.client_contact_name
                          ? this.state.client_contact_name
                          : ""}
                      </p>
                      <p className="media-body p-0 m-0">
                        {this.state.client_contact_email
                          ? this.state.client_contact_email
                          : ""}
                      </p>
                      <p className="media-body pd-b-1 mg-b-15">
                        {this.state.client_contact_phone_number ? (
                          <PhoneNumberFormat
                            phone_number={
                              this.state.client_contact_phone_number
                            }
                          />
                        ) : (
                          ""
                        )}
                      </p>

                      <p className="az-profile-name-text">
                        Orion Haus Account Manager
                      </p>

                      <p className="media-body p-0 m-0">
                        {this.state.orion_haus_account_manager_name
                          ? this.state.orion_haus_account_manager_name
                          : ""}
                      </p>
                      <p className="media-body p-0 m-0">
                        {this.state.orion_haus_email
                          ? this.state.orion_haus_email
                          : ""}
                      </p>
                      <p className="media-body p-0 m-0">
                        {this.state.orion_haus_phone_number ? (
                          <PhoneNumberFormat
                            phone_number={this.state.orion_haus_phone_number}
                          />
                        ) : (
                          ""
                        )}
                      </p>
                      {/* manager info end */}
                    </div>
                  </div>
                </div>
                {/* az-profile-overview */}
              </div>

              <div className="col-md-8 col-lg-9 mg-b-30 cardRight d-none">
                <div className="dashboardRightcard overviewSection overview p-0">
                  <div className="az-content-body">
                    <div className="row row-sm pd-t-20">
                      {/* start of count boxes */}
                      <div className="col-sm-6 col-xl-3">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-purple">
                              <i className="typcn typcn-chart-line-outline"></i>
                            </div>
                            <div className="media-body">
                              <h6>
                                {this.state.total_client_count_val
                                  ? this.state.total_client_count_val
                                  : "0"}
                              </h6>
                              <span>
                                {" "}
                                {this.state.total_client_count_val === 0 ||
                                this.state.total_client_count_val === 1
                                  ? "Client"
                                  : "Clients"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3 mg-t-20 mg-sm-t-0">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-primary">
                              <i className="typcn typcn-chart-line-outline"></i>
                            </div>
                            <div className="media-body">
                              <h6>
                                {this.state.total_brand_count_val
                                  ? this.state.total_brand_count_val
                                  : "0"}
                              </h6>
                              <span>
                                {" "}
                                {this.state.total_brand_count_val === 0 ||
                                this.state.total_brand_count_val === 1
                                  ? "Brand"
                                  : "Brands"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-pink">
                              <i className="typcn typcn-chart-line-outline"></i>
                            </div>
                            <div className="media-body">
                              <h6>
                                {this.state.total_property_count_val
                                  ? this.state.total_property_count_val
                                  : "0"}
                              </h6>
                              <span>
                                {" "}
                                {this.state.total_property_count_val === 0 ||
                                this.state.total_property_count_val === 1
                                  ? "Property"
                                  : "Properties"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-teal">
                              <i className="typcn typcn-chart-line-outline"></i>
                            </div>
                            <div className="media-body">
                              <h6>
                                {this.state.no_of_units_count
                                  ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(this.state.no_of_units_count)
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </h6>
                              <span>
                                {" "}
                                {this.state.no_of_units_count === 0 ||
                                this.state.no_of_units_count === 1
                                  ? "Unit"
                                  : "Units"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row-sm mg-t-20">
                      <div className="col-sm-6 col-xl-3">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-purple">
                              <i className="typcn typcn-chart-line-outline"></i>
                            </div>
                            <div className="media-body">
                              <h6>
                                {this.state.total_listing_count_val
                                  ? this.state.total_listing_count_val
                                  : "0"}
                              </h6>
                              <span>
                                {" "}
                                {this.state.total_listing_count_val === 0 ||
                                this.state.total_listing_count_val === 1
                                  ? "Listing"
                                  : "Listings"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3 mg-t-20 mg-sm-t-0">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-primary">
                              <i className="typcn typcn-chart-line-outline"></i>
                            </div>
                            <div className="media-body">
                              <h6>
                                {this.state.total_reservation_count_val
                                  ? this.state.total_reservation_count_val
                                  : "0"}
                              </h6>
                              <span>
                                {" "}
                                {this.state.total_reservation_count_val === 0 ||
                                this.state.total_reservation_count_val === 1
                                  ? "Reservation"
                                  : "Reservations"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-pink">
                              <i className="typcn typcn-chart-line-outline"></i>
                            </div>
                            <div className="media-body">
                              <h6>
                                {this.state.total_resident_count_val
                                  ? this.state.total_resident_count_val
                                  : "0"}
                              </h6>
                              <span>
                                {" "}
                                {this.state.total_resident_count_val === 0 ||
                                this.state.total_resident_count_val === 1
                                  ? "Resident"
                                  : "Residents"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-teal">
                              <i className="typcn typcn-chart-line-outline"></i>
                            </div>
                            <div className="media-body">
                              <h6>
                                {this.state.total_guest_count_val
                                  ? this.state.total_guest_count_val
                                  : "0"}
                              </h6>
                              <span>
                                {" "}
                                {this.state.total_guest_count_val === 0 ||
                                this.state.total_guest_count_val === 1
                                  ? "Guest"
                                  : "Guests"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end of count boxes */}
                    {/* start of checouts boxes */}
                    {/* <div className="row row-sm">
                      <div className="col-lg-6 col-xl-6 mg-t-20">
                        <div className="card card-table-two">
                          <h6 className="card-title">Check-outs</h6>
                          <span className="d-block mg-b-20">List of guests checking out today</span>
                          {this.state.checkInList.length > 0 ? (
                          <div className="table-responsive">
                            <table className="table table-striped table-dashboard-two mg-b-0">
                              <thead>
                                <tr>
                                  <th className="wd-lg-25p tx-left">Unit</th>
                                  <th className="wd-lg-25p tx-left">Guest</th>
                                  <th className="wd-lg-25p tx-left">Date & time</th>
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.checkOutList.map((item,i) => {
                                return(
                                <tr key={i}>
                                  <td className="tx-left tx-medium tx-inverse">{item.unit_name ? item.unit_name : item.unit_number}</td>
                                  <td className="tx-left tx-medium tx-inverse">{item.first_name && item.last_name ? item.first_name + " " + item.last_name : ""}</td>
                                  <td className="tx-left tx-medium tx-inverse">{item.checkout_date ? Moment(item.checkout_date).format(global.dateTimeFormat) : ""}</td>
                                </tr>
                                )
                              })}
                              </tbody>
                            </table>
                          </div>
                          ) : <p className="text-center">no check-outs for today</p>}
                        </div>
                      </div> */}
                    {/* end of checouts boxes */}
                    {/* start of checkins boxes */}
                    {/* <div className="col-lg-6 col-xl-6 mg-t-20">
                        <div className="card card-table-two">
                          <h6 className="card-title">Check-Ins</h6>
                          <span className="d-block mg-b-20">List of guests checking in today</span>
                          {this.state.checkInList.length > 0 ? (
                          <div className="table-responsive">
                            <table className="table table-striped table-dashboard-two mg-b-0">
                              <thead>
                                <tr>
                                  <th className="wd-lg-25p tx-left">Unit</th>
                                  <th className="wd-lg-25p tx-left">Guest</th>
                                  <th className="wd-lg-25p tx-left">Date & time</th>
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.checkInList.map((item,i) => {
                                return(
                                <tr key={i}>
                                  <td className="tx-left tx-medium tx-inverse">{item.unit_name ? item.unit_name : item.unit_number}</td>
                                  <td className="tx-left tx-medium tx-inverse">{item.first_name && item.last_name ? item.first_name + " " + item.last_name : ""}</td>
                                  <td className="tx-left tx-medium tx-inverse">{item.checkout_date ? Moment(item.checkout_date).format(global.dateTimeFormat) : ""}</td>
                                </tr>
                                )
                              })}
                              </tbody>
                            </table>
                          </div>
                          ) : <p className="text-center">no check-outs for today</p>}
                        </div>
                      </div> */}
                    {/* end of checkins boxes */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row overview_fst">
                  <div className="overview_cnt">
                    <h4>
                      {this.state.total_brand_count_val
                        ? global.onKeyPressEvent.numberWithCommasForDecimal(
                            parseFloat(this.state.total_brand_count_val)
                              .toFixed(2)
                              .replace(/\.00$/, "")
                          )
                        : 0}
                    </h4>
                    <p>
                      {this.state.total_brand_count_val === 0 ||
                      this.state.total_brand_count_val === 1
                        ? "Brand"
                        : "Brands"}
                    </p>
                  </div>

                  <div className="overview_cnt">
                    <h4>
                      {this.state.total_client_count_val
                        ? global.onKeyPressEvent.numberWithCommasForDecimal(
                            parseFloat(this.state.total_client_count_val)
                              .toFixed(2)
                              .replace(/\.00$/, "")
                          )
                        : 0}
                    </h4>
                    <p>
                      {" "}
                      {this.state.total_client_count_val === 0 ||
                      this.state.total_client_count_val === 1
                        ? "Client"
                        : "Clients"}
                    </p>
                  </div>

                  <div className="overview_cnt">
                    <h4>
                      {this.state.total_property_count_val
                        ? global.onKeyPressEvent.numberWithCommasForDecimal(
                            parseFloat(this.state.total_property_count_val)
                              .toFixed(2)
                              .replace(/\.00$/, "")
                          )
                        : 0}
                    </h4>
                    <p>
                      {" "}
                      {this.state.total_property_count_val === 0 ||
                      this.state.total_property_count_val === 1
                        ? "Property"
                        : "Properties"}
                    </p>
                  </div>

                  <div className="overview_cnt">
                    <h4>
                      {this.state.no_of_units_count
                        ? global.onKeyPressEvent.numberWithCommasForDecimal(
                            parseFloat(this.state.no_of_units_count)
                              .toFixed(2)
                              .replace(/\.00$/, "")
                          )
                        : 0}
                    </h4>
                    <p>
                      {" "}
                      {this.state.no_of_units_count === 0 ||
                      this.state.no_of_units_count === 1
                        ? "Unit"
                        : "Units"}
                    </p>
                  </div>

                  <div className="overview_cnt">
                    <h4>
                      {this.state.total_listing_count_val
                        ? global.onKeyPressEvent.numberWithCommasForDecimal(
                            parseFloat(this.state.total_listing_count_val)
                              .toFixed(2)
                              .replace(/\.00$/, "")
                          )
                        : 0}
                    </h4>
                    <p>
                      {" "}
                      {this.state.total_listing_count_val === 0 ||
                      this.state.total_listing_count_val === 1
                        ? "Listing"
                        : "Listings"}
                    </p>
                  </div>

                  <div className="overview_cnt">
                    <h4>
                      {this.state.total_reservation_count_val
                        ? global.onKeyPressEvent.numberWithCommasForDecimal(
                            parseFloat(this.state.total_reservation_count_val)
                              .toFixed(2)
                              .replace(/\.00$/, "")
                          )
                        : 0}
                    </h4>
                    <p>
                      {" "}
                      {this.state.total_reservation_count_val === 0 ||
                      this.state.total_reservation_count_val === 1
                        ? "Reservation"
                        : "Reservations"}
                    </p>
                  </div>

                  <div className="overview_cnt">
                    <h4>
                      {this.state.total_resident_count_val
                        ? global.onKeyPressEvent.numberWithCommasForDecimal(
                            parseFloat(this.state.total_resident_count_val)
                              .toFixed(2)
                              .replace(/\.00$/, "")
                          )
                        : 0}
                    </h4>
                    <p>
                      {" "}
                      {this.state.total_resident_count_val === 0 ||
                      this.state.total_resident_count_val === 1
                        ? "Resident"
                        : "Residents"}
                    </p>
                  </div>

                  <div className="overview_cnt">
                    <h4>
                      {this.state.total_guest_count_val
                        ? global.onKeyPressEvent.numberWithCommasForDecimal(
                            parseFloat(this.state.total_guest_count_val)
                              .toFixed(2)
                              .replace(/\.00$/, "")
                          )
                        : 0}
                    </h4>
                    <p>
                      {" "}
                      {this.state.total_guest_count_val === 0 ||
                      this.state.total_guest_count_val === 1
                        ? "Guest"
                        : "Guests"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <Chart />
              </div>
              <div className="col-md-4 dashborad_two_box">
                <div className="dash-dual">
                  <div className="overviewLeft">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="overview_heading">
                          <h4>Earnings</h4>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="overview_info">
                          <label>Guest paid past</label>
                          <p>
                            {this.state.total_guest_paid_past_count
                              ? "$" +
                                global.onKeyPressEvent.numberWithCommasForDecimal(
                                  parseFloat(
                                    this.state.total_guest_paid_past_count
                                  )
                                    .toFixed(2)
                                    .replace(/\.00$/, "")
                                )
                              : 0}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="overview_info">
                          <label>Guest paid past & future</label>
                          <p>
                            {this.state.total_guest_paid_past_future_count
                              ? "$" +
                                global.onKeyPressEvent.numberWithCommasForDecimal(
                                  parseFloat(
                                    this.state.total_guest_paid_past_future_count
                                  )
                                    .toFixed(2)
                                    .replace(/\.00$/, "")
                                )
                              : 0}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="overview_info">
                          <label>Paid out to property</label>
                          <p>
                            {this.state.amount_paid_out_to_property_count
                              ? "$" +
                                global.onKeyPressEvent.numberWithCommasForDecimal(
                                  parseFloat(
                                    this.state.amount_paid_out_to_property_count
                                  )
                                    .toFixed(2)
                                    .replace(/\.00$/, "")
                                )
                              : 0}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="overview_info">
                          <label>Future earnings to property</label>
                          <p>
                            {this.state.future_earning_to_property_count
                              ? "$" +
                                global.onKeyPressEvent.numberWithCommasForDecimal(
                                  parseFloat(
                                    this.state.future_earning_to_property_count
                                  )
                                    .toFixed(2)
                                    .replace(/\.00$/, "")
                                )
                              : 0}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="overview_info">
                          <label>Paid out to residents</label>
                          <p>
                            {this.state.amount_paid_out_to_resident_count
                              ? "$" +
                                global.onKeyPressEvent.numberWithCommasForDecimal(
                                  parseFloat(
                                    this.state.amount_paid_out_to_resident_count
                                  )
                                    .toFixed(2)
                                    .replace(/\.00$/, "")
                                )
                              : 0}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="overview_info">
                          <label>Future earnings to residents</label>
                          <p>
                            {this.state.future_erning_to_resident_count
                              ? "$" +
                                global.onKeyPressEvent.numberWithCommasForDecimal(
                                  parseFloat(
                                    this.state.future_erning_to_resident_count
                                  )
                                    .toFixed(2)
                                    .replace(/\.00$/, "")
                                )
                              : 0}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dash-dual dash-dual-two">
                  <div className="overviewLeft pb-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="overview_heading">
                          <h4>Top performer</h4>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="overview_info">
                          <label>
                          Guest paid past{" "}
                            <span>
                              {this.state.top_performer_past_unit_name
                                ? "(" +
                                  this.state.top_performer_past_unit_name +
                                  ")"
                                : ""}
                            </span>
                          </label>
                          <p>
                            {this.state.top_performer_guest_paid_past_count
                              ? "$" +
                                global.onKeyPressEvent.numberWithCommasForDecimal(
                                  parseFloat(
                                    this.state.top_performer_guest_paid_past_count
                                  )
                                    .toFixed(2)
                                    .replace(/\.00$/, "")
                                )
                              : 0}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="overview_info">
                          <label>
                          Guest paid past & future{" "}
                            <span>
                              {this.state.top_performer_future_unit_name
                                ? "(" +
                                  this.state.top_performer_future_unit_name +
                                  ")"
                                : ""}
                            </span>
                          </label>
                          <p>
                            {this.state.top_performer_guest_paid_past_future_count
                              ? "$" +
                                global.onKeyPressEvent.numberWithCommasForDecimal(
                                  parseFloat(
                                    this.state
                                      .top_performer_guest_paid_past_future_count
                                  )
                                    .toFixed(2)
                                    .replace(/\.00$/, "")
                                )
                              : 0}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="overview_info">
                          <label>
                          Nights booked past{" "}
                            <span>
                              {this.state.top_performer_past_unit_name
                                ? "(" +
                                  this.state.top_performer_past_unit_name +
                                  ")"
                                : ""}
                            </span>
                          </label>
                          <p>
                            {this.state.top_performer_night_stay_past_count
                              ? this.state.top_performer_night_stay_past_count
                              : 0}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="overview_info">
                          <label>
                             Nights booked past & future{" "}
                            <span>
                              {this.state.top_performer_future_unit_name
                                ? "(" +
                                  this.state.top_performer_future_unit_name +
                                  ")"
                                : ""}
                            </span>
                          </label>
                          <p>
                            {this.state.top_performer_night_stay_past_future_count
                              ? this.state
                                  .top_performer_night_stay_past_future_count
                              : 0}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 ">
                <div className="overviewRight">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="overview_shd">
                        <div className="overview_heading">
                          <h4>Reservations Hosted</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Past</label>
                              <p>
                                {this.state.revervation_hosted_past_count
                                  ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(
                                        this.state.revervation_hosted_past_count
                                      )
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Past & future</label>
                              <p>
                                {this.state.revervation_hosted_past_future_count
                                  ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(
                                        this.state
                                          .revervation_hosted_past_future_count
                                      )
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 ">
                      <div className="overview_shd">
                        <div className="overview_heading">
                          <h4>Nights Hosted</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Past</label>
                              <p>
                                {this.state.nights_hosted_past_count
                                  ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(
                                        this.state.nights_hosted_past_count
                                      )
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Past & future</label>
                              <p>
                                {this.state.nights_hosted_past_future_count
                                  ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(
                                        this.state
                                          .nights_hosted_past_future_count
                                      )
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 ">
                      <div className="overview_shd">
                        <div className="overview_heading">
                          <h4>Avg Length of Stay</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Past</label>
                              <p>
                                {this.state.average_length_of_stay_past_count
                                  ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(
                                        this.state
                                          .average_length_of_stay_past_count
                                      )
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Past & future</label>
                              <p>
                                {this.state.average_length_of_stay_future_count
                                  ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(
                                        this.state
                                          .average_length_of_stay_future_count
                                      )
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 ">
                      <div className="overview_shd">
                        <div className="overview_heading">
                          <h4>Occupancy</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Past</label>
                              <p>{this.state.occupancy_past_count + "%"}</p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Past & future</label>
                              <p>
                                {this.state.occupancy_past_future_count + "%"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 ">
                      <div className="overview_shd">
                        <div className="overview_heading">
                          <h4>ADR</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Past</label>
                              <p>
                                {this.state.adr_past_count
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(this.state.adr_past_count)
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Past & future</label>
                              <p>
                                {this.state.adr_past_future_count
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(
                                        this.state.adr_past_future_count
                                      )
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 ">
                      <div className="overview_shd">
                        <div className="overview_heading">
                          <h4>Booking Lead Time</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Past</label>
                              <p>
                                {this.state.booking_lead_time_past_count
                                  ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(
                                        this.state.booking_lead_time_past_count
                                      )
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Past & future</label>
                              <p>
                                {this.state.booking_lead_time_past_future_count
                                  ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(
                                        this.state
                                          .booking_lead_time_past_future_count
                                      )
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 ">
                      <div className="overview_shd">
                        <div className="overview_heading">
                          <h4>Taxes</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Collected</label>
                              <p>
                                {this.state.taxes_collected_count
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(
                                        this.state.taxes_collected_count
                                      )
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Remitted</label>
                              <p>
                                {this.state.taxes_remitted_count
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(
                                        this.state.taxes_remitted_count
                                      )
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 ">
                      <div className="overview_shd">
                        <div className="overview_heading">
                          <h4>Homesharing</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="overview_info">
                              <label>Units Allowed</label>
                              <p>
                                {" "}
                                {this.state.units_allow_to_homeshare_count_data}
                                {/* {this.state.units_allow_to_homeshare_count === true
                            ? this.state.units_allow_to_homeshare_count_data
                            : parseFloat(
                                this.state.units_allow_to_homeshare_count_data
                              ).toFixed(2)} */}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6 pr-0">
                            <div className="overview_info">
                              <label>Units Homesharing</label>
                              <p>
                                {this.state.units_homesharing_count
                                  ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(
                                        this.state.units_homesharing_count
                                      )
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 ">
                      <Link to={"/applicant-detail"}>
                        <div className="overview_shd">
                          <div className="overview_heading">
                            <h4>Lease Inquiry</h4>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="overview_info">
                                <label>Total Inquiries</label>
                                <p>{this.state.floorplan_count}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    {/* <div className="col-md-3 ">
                      <Link to={"/inquiry"}>
                        <div className="overview_shd">
                          <div className="overview_heading">
                            <h4>Reservation Inquiry</h4>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="overview_info">
                                <label className="hand-cursor">
                                  Total Inquiries
                                </label>
                                <p>{this.state.ru_inquiry_count}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div> */}
                    <div className="col-md-3 ">
                      <Link to={"/reviews"}>
                        <div className="overview_shd">
                          <div className="overview_heading">
                            <h4>Guest Reviews</h4>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="overview_info">
                                <label>Total Reviews </label>
                                <p>{this.state.guest_review_count}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-7 star_box_new">
                <div className="overview_shd pb-0">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="overview_heading">
                        <h4>Security Deposits / Damage Waivers</h4>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="overview_info">
                        <label>
                         Total Waivers
                        </label>
                        <p>
                          {this.state.total_no_of_waivers
                            ? this.state.total_no_of_waivers
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="overview_info">
                        <label>
                        Total Deposits
                        </label>
                        <p>
                          {this.state.total_no_of_deposit
                            ? this.state.total_no_of_deposit
                            : 0}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="overview_info">
                        <label>
                         Reservations With Waivers{" "}
                        </label>
                        <p>
                          {this.state.percentage_waiver
                            ? global.onKeyPressEvent.numberWithCommasForDecimal(
                              parseFloat(this.state.percentage_waiver)
                                .toFixed(2)
                                .replace(/\.00$/, "") + "%"
                            )
                            : 0}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="overview_info">
                        <label>
                        Reservations With Deposits{" "}
                        </label>
                        <p>
                          {this.state.percentgage_deposit
                            ?  global.onKeyPressEvent.numberWithCommasForDecimal(
                              parseFloat(this.state.percentgage_deposit)
                                .toFixed(2)
                                .replace(/\.00$/, "") + "%"
                            )
                            : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
      </div>
    );
  }
}
export default Dashboard;
