import React, { useState, useEffect } from "react";
import { Container, Tab, Nav, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import NoData from "../../common/NoData";
import Alerts from "../../common/Alerts";
import SchlageService from "../../../services/SchlageService";
// import KeycafeService from "../../../services/KeycafeService";
import PropertyService from "../../../services/PropertyService";
import SettingsSidebar from "../../common/SettingsSidebar";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
// // import modalswapicon from "./../../../assets/images/swap-keys.png";
// import ErrorLogo from "./../../../assets/images/errorImage.svg";
// import SuccessLogo from "./../../../assets/images/successImage.svg";
import Instructions from "./Instructions";
// import Fob from "./Fob";
import KeyIcon from "./../../../assets/images/swap-key.svg"

const Mapping = (props) => {
  const [units, setUnits] = useState([]);
  const [isSubmit, setSubmit] = useState(false);
  // const [isSwapSubmit, setSwapSubmit] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertModalType, setAlertModalType] = useState("");
  const [alertModalTitle, setAlertModalTitle] = useState("");
  const [alertModalMessage, setAlertModalMessage] = useState("");

  const [activeClass, setActiveClass] = useState("alerts");
  // const [errorMessage, setErrorMessage] = useState("");
  const [lockType, setLockType] = useState(false);

  useEffect(() => {
    getUnitData();
    getLockType();
  }, []);

  function getActiveClass(e) {
    setActiveClass(e);
  }

  const [swapmodalIsOpen, setswapModalIsOpen] = useState(false);
  // const [messagemodalIsOpen, setmessageModalIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(-1);

  useEffect(() => {
    if (selectedValue !== -1) {
      setswapModalIsOpen(true);
    } else {
      setswapModalIsOpen(false);
    }
  }, [selectedValue]);

  function changeHandler(event, index) {
    let value = event.target.value;
    let mapData = [...units];
    mapData[index].device_id = value;
    setUnits(mapData);
  }

  // function changeHandler1(event, index) {
  //   let value = event.target.value;
  //   let mapData = [...units];
  //   mapData[index].backup_key_id = value;
  //   setUnits(mapData);
  // }

  // function swapKey(event, index) {
  //   setSelectedValue(index);
  //   setswapModalIsOpen(true);
  // }

  function resetKey() {
    setSelectedValue(-1);
  }

  // function closePop() {
  //   setmessageModalIsOpen(false);
  // }

  // const confirmSwap = async (event, index) => {
  //   event.preventDefault();
  //   let mainKey = units[index].key_id;
  //   let backupKey = units[index].backup_key_id;
  //   let unitId = units[index].unit_id;
  //   setSwapSubmit(true);
  //   let res = [];
  //   let inputData = {
  //     property_slug: props.match.params.propertySlug,
  //     unit_id: unitId,
  //     current_key: mainKey,
  //     backup_key: backupKey,
  //   };
  //   res = await KeycafeService.changeKey(inputData);
  //   setShowAlertModal(true);
  //   if (global.successStatus.includes(res.status)) {
  //     setmessageModalIsOpen(true);
  //     setErrorMessage("Success");
  //     setAlertModalMessage(res.message ? res.message : "Success");
  //     let mapData = [...units];
  //     mapData[index].key_id = mainKey;
  //     mapData[index].backup_key_id = backupKey;
  //     setUnits(mapData);
  //   } else {
  //     setmessageModalIsOpen(true);
  //     setErrorMessage("error");
  //     setAlertModalTitle(res.data.errorCode ? res.data.errorCode : "Error!");
  //     setAlertModalMessage(
  //       res.data.errorMessage ? res.data.errorMessage : res.data.message
  //     );
  //   }
  //   resetKey();
  //   setSwapSubmit(false);
  //   setTimeout(() => setShowAlertModal(false), global.alert_close_time);
  // };

  const getUnitData = async () => {
    var list = [];
    let res = await SchlageService.getSchlageLock(
      "property_slug=" + props.match.params.propertySlug
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : {};
    }
    setUnits(list);
  };

  const getLockType = async () => {
    var lock_type = false;
    let res = await PropertyService.getProperty(
      props.match.params.propertySlug
    );

    if (global.successStatus.includes(res.status)) {
      lock_type = res.data ? res.data.lock_type : false;
    }
    setLockType(lock_type);
  };

  const saveOrUpdateUnits = async (event) => {
    event.preventDefault();
    setSubmit(true);
    let res = [];
    let inputData = {
      property_slug: props.match.params.propertySlug,
      units: units,
    };

    res = await SchlageService.mappingUnits(inputData);
    if (global.successStatus.includes(res.status)) {
      setSubmit(false);
      setShowAlertModal(true);
      setAlertModalType("success");
      setAlertModalTitle("Success");
      setAlertModalMessage(res.message ? res.message : "Success");
    } else {
      setSubmit(false);
      setShowAlertModal(true);
      setAlertModalType("error");
      setAlertModalTitle(res.data.errorCode ? res.data.errorCode : "Error!");
      setAlertModalMessage(res.data.message ? res.data.message : "Error");
    }
    setTimeout(() => setShowAlertModal(false), global.alert_close_time);
    getUnitData();
  };

  return (
    <main>
      <Container className="innter-container">
        <div className="d-flex flex-column">
          <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
            <div className="col-md-2 left">
              <div className="child-menu-section">
                <aside>
                  <SettingsSidebar
                    property_slug={props.match.params.propertySlug}
                  />
                </aside>
              </div>
            </div>
            <div className="col-md-10 right">
              <div className="col-md-12 mg-t-20 mg-b-10"></div>
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0 keycafe-header-sticky-">
                <div className="dashbaord-pageHeadertitle dashboardRightcard d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns keycafemappingtitle">
                  <h3>Schlage Mapping </h3>
                </div>
              </div>
              {lockType === 5 ? (
                <div className="scrolling-carousel pd-l-15">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="alerts"
                    onSelect={(e) => getActiveClass(e)}
                    eventKey={activeClass}
                  >
                    <ScrollingCarousel
                      rightArrow={true}
                      leftArrow={true}
                      className="swipesection scroll_nav_mob"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="alerts"
                          id="infoForm"
                          className={activeClass === "alerts" ? "active" : ""}
                        >
                          Unit Mapping
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          eventKey="alert-activity"
                          id="alert-activity"
                          className={
                            activeClass === "alert-activity" ? "active" : ""
                          }
                        >
                          Pick Up/Drop Off Instructions
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link
                          eventKey="fobs"
                          id="fobs"
                          className={activeClass === "fobs" ? "active" : ""}
                        >
                          Fobs
                        </Nav.Link>
                      </Nav.Item> */}
                    </ScrollingCarousel>
                    <Tab.Content className="pd-lg-l-15 pd-lg-r-15 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Tab.Pane eventKey="alerts">
                        <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns ">
                            {global.userPermissions.checkPermission(
                              "key-cafe-mapping-edit"
                            ) ? (
                              <button
                                className="btn-success-outline-small ml-3 button-width"
                                type="button"
                                disabled={isSubmit ? true : false}
                                onClick={saveOrUpdateUnits}
                              >
                                {isSubmit ? global.loader : "Save"}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="row align-items-center border-bottom-0 mapp_head_desk m-0">
                            <div className="col-md-11 pd-xs-l-0 pd-xs-r-0">
                              <div className="row w-100">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-15 pd-lg-r-30 pd-xs-r-0 ">
                                  <label className="form-label text-left mg-b-0">
                                    Unit
                                  </label>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-12 pd-lg-r-30 pd-xs-r-0 ">
                                  <label className="form-label text-left mg-b-0">
                                    Key ID
                                  </label>
                                </div>
                                {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-10 pd-lg-r-30 pd-xs-r-0 ">
                                  <label className="form-label text-left mg-b-0">
                                    Backup Key ID
                                  </label>
                                </div> */}
                              </div>
                            </div>

                            {/* <div className="col-md-1 pd-lg-l-10 pd-xs-l-15 pd-lg-b-0 pd-xs-b-15">
                              <label className="form-label text-left mg-b-0">
                                Swap Fob
                              </label>
                            </div> */}
                          </div>
                          <div className="row align-items-center border-bottom-0">
                            <div className="col-md-12 pd-lg-r-30 pd-lg-l-30 pd-xs-l-15 pd-xs-r-15 mg-t-15">
                              <Alerts
                                show={showAlertModal}
                                type={alertModalType}
                                title={alertModalTitle}
                                message={alertModalMessage}
                              />
                            </div>
                            {units.length > 0 ? (
                              units.map((item, index) => {
                                // const isDisabled = !item.backup_key_id && !item.key_id;
                                // const showButton = !isDisabled;
                                return (
                                  <>
                                    <div className="row w-100 m-0">
                                      <div className="col-md-11 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                                        <div className="row w-100">
                                          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                            <label className="form-label text-left mg-b-0 mapp_head_mob">
                                              Unit
                                            </label>
                                            <Form.Control
                                              name="unit_number"
                                              value={
                                                item.unit_name
                                                  ? item.unit_name
                                                  : item.unit_number
                                              }
                                              disabled={true}
                                              className="form-control max_width_100"
                                              type="text"
                                            />
                                          </div>
                                          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                            <label className="form-label text-left mg-b-0 mapp_head_mob">
                                              Key ID
                                            </label>
                                            <Form.Control
                                              onChange={(e) =>
                                                changeHandler(e, index)
                                              }
                                              name="serial_number"
                                              value={item.device_id}
                                              className="form-control max_width_100"
                                              type="text"
                                              disabled={false}
                                            />
                                          </div>
                                          {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                            <label className="form-label text-left mg-b-0 mapp_head_mob">
                                              Backup Key ID
                                            </label>
                                            <Form.Control
                                              onChange={(e) =>
                                                changeHandler1(e, index)
                                              }
                                              name="backup_key_id"
                                              value={item.backup_key_id}
                                              className="form-control max_width_100"
                                              type="text"
                                              disabled={false}
                                            />
                                          </div> */}
                                        </div>
                                      </div>
                                      {/* <div className="col-md-1 pd-lg-l-0 pd-xs-l-15 pd-lg-b-0 pd-xs-b-15">
                                        <div className="text_btn_both tooltip1">
                                          {showButton &&
                                            item.backup_key_id &&
                                            global.userPermissions.checkPermission(
                                              "key-cafe-mapping-edit"
                                            ) && (
                                              <button
                                                type="button"
                                                onClick={(e) =>
                                                  swapKey(e, index)
                                                }
                                                className="btn btn-sync btn-swp-img "
                                              >
                                                <img src={KeyIcon} alt="" />
                                              </button>
                                            )}
                                          <span className="tooltiptext1 tooltiptext-swap">
                                            Swap Fob
                                          </span>
                                        </div>
                                      </div> */}
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <NoData msg="units" />
                            )}
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="alert-activity">
                        <Instructions
                          propertySlug={props.match.params.propertySlug}
                        />
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="fobs">
                        {activeClass === "fobs" && (
                          <Fob propertySlug={props.match.params.propertySlug} />
                        )}
                      </Tab.Pane> */}
                    </Tab.Content>
                  </Tab.Container>{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Container>
      <Modal show={swapmodalIsOpen} centered>
        <Modal.Body className="width_402 p-4 border_radius_14">
          <div className="text-center key-swap-modal-img">
            <img src={KeyIcon} alt="modal-delete-icon" />
            <h3>
              {selectedValue !== -1
                ? "Would you like to exchange the backup key fob with the primary key fob for unit " +
                  " " +
                  units[selectedValue].unit_number +
                  " " +
                  "?"
                : ""}
            </h3>
            <p>
              {selectedValue !== -1
                ? "This will update all future key access for Unit " +
                  units[selectedValue].unit_number +
                  "."
                : ""}
            </p>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => resetKey()}
              >
                Cancel
              </button>
              {/* <button
                type="button"
                className="m-0 btn btn btn-danger btn-block"
                onClick={(e) => confirmSwap(e, selectedValue)}
                disabled={isSubmit ? true : false}
              >
                {isSwapSubmit ? global.loader : "Confirm"}
              </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal show={messagemodalIsOpen} centered>
        <Modal.Body className="width_402 p-4 border_radius_14">
          <div className="text-center">
            {errorMessage !== "Success" ? (
              <div className="msg col-12 text-center mt-4">
                <img src={ErrorLogo} alt="filterIcon" className="mb-2" />{" "}
                <p className="mb-0 text-danger">{alertModalMessage}</p>{" "}
              </div>
            ) : (
              <div className="msg col-12 text-center mt-4">
                <img src={SuccessLogo} alt="filterIcon" className="mb-2" />
                <p className="mb-0 text-success">{alertModalMessage}</p>{" "}
              </div>
            )}
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-block"
                onClick={() => closePop()}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </main>
  );
};

export default Mapping;
