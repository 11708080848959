////////////////////////////////////////////////////////////
//     							                          //
//  Program: CompanyOverview.jsx                                //
//  Application: Dashboard                                //
//  Option: to view Company Information                   //
//  Developer: NP  						                            //
//  Date: 2022-04-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { withRouter } from "react-router";
import CompanyService from "../../services/CompanyService";
// import companyLogo from './../../assets/images/company_placeholder.png'
import NoCheckIn from "../../assets/images/snapshoticons/CheckIn.svg";
import ChatIcon from "./../../assets/images/chat-icon.svg";
import RUmessageService from "../../services/RUmessageService";

class CheckinToday extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertySlug: this.props.propertySlug,
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: "",
      total_client_count: "",
      checkins_today: [],
      checkouts_today: [],
      checkins_tomorrow: [],
      checkouts_tomorrow: [],
      checkInList: [],
      checkOutList: [],
      cleaningscheduled_today: [],
      cleaningscheduled_tomorrow: [],
      occupiedunits: [],
      bookedunits: [],
      signed_leases: [],
      new_booking_list: [],
    };
  }

  componentDidMount() {
    this.setState({ checkins_today: [] });
    this.setState({ spinnerLoader: true, loading: true });
    if (this.state.propertySlug) {
      this.getData(this.state.propertySlug);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ checkins_today: [] });
    this.setState({ spinnerLoader: true, loading: true });
    if (nextProps.propertySlug) {
      this.getData(nextProps.propertySlug);
    }
  }

  // for check_in info
  handleClickCheckinList(property_slug, reservation_slug) {
    // this.props.history.push(
    //     "/properties/view/" + property_slug + "/reservations/view/" + reservation_slug
    // );
    if (reservation_slug) {
      const win = window.open(
        "/properties/view/" +
          property_slug +
          "/reservations/view/" +
          reservation_slug,
        "_blank"
      );
      win.focus();
    } else {
      return false;
    }
  }
  // for check_in info
  async handleChatCheckinList(property_slug, reservation_slug) {
    // this.props.history.push(
    //     "/properties/view/" + property_slug + "/reservations/view/" + reservation_slug
    // );
    let inputData = {
      property_slug: property_slug,
      role_slug: global.currerntRoleSlug,
      reservation_slug: reservation_slug,
      is_support: global.is_support,
      thread_type: global.threadType.Guest,
    };
    let res = await RUmessageService.createThread(inputData);
    if (global.successStatus.includes(res.status)) {
      var threadSlug = res.data ? res.data.slug : "";
    }
    if (property_slug) {
      const win = window.open(
        "/properties/view/" + property_slug + "/messages/" + threadSlug,
        "_blank"
      );
      win.focus();
    } else {
      return false;
    }
  }
  async getData(propertySlug) {
    this.setState({ spinnerLoader: true, loading: true });
    var resData = {};

    let res = await CompanyService.getCheckinTodayList(propertySlug);

    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
      resData.checkins_today = res.data.checkins_today
        ? res.data.checkins_today
        : [];
      resData.checkins_today.sort((a, b) =>
        a.unit_name > b.unit_name ? 1 : -1
      );
    }
    this.setState(resData);
    this.setState({ spinnerLoader: false, loading: false });
  }

  render() {
    return (
      <div className="col-xxl-6 col-md-6 col-sm-12 col-12 mg-b-20">
        {/* <div className="pd-lg-b-15 snap-heading">
                                                    <h3 className="text-uppercase">Today's List</h3>
                                                </div> */}
        <div className="card card-table-two ">
          <div className=" d-flex justify-content-between">
            <div className="">
              <h6 className="card-title">Check-Ins Today</h6>
              <span className="d-block mg-b-0">
                All check-ins on the schedule for today
              </span>
            </div>
            <h6 className="card-title">
              Total Count: {this.state.checkins_today.length}
            </h6>
          </div>
          {this.state.checkins_today.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-dashboard-two mg-b-0">
                <thead>
                  <tr>
                    <th className="wd-lg-25p tx-left">S.No</th>
                    <th className="wd-lg-25p tx-left">Unit</th>
                    <th className="wd-lg-25p tx-left">Guest</th>
                    <th className="wd-lg-25p tx-left">Key Code</th>
                    <th className="wd-lg-25p tx-left">Key Status</th>
                    <th className="wd-lg-25p tx-left">Cleaning Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.checkins_today.map((item, i) => {
                    return (
                      <tr key={i} value={item}>
                        <td
                          className="tx-left tx-medium tx-inverse hand-cursor td_clickable"
                          onClick={() =>
                            this.handleClickCheckinList(
                              item.property_slug,
                              item.reservation_slug
                            )
                          }
                        >
                          {i + 1}
                        </td>
                        <td
                          className="tx-left tx-medium tx-inverse hand-cursor td_clickable"
                          onClick={() =>
                            this.handleClickCheckinList(
                              item.property_slug,
                              item.reservation_slug
                            )
                          }
                        >
                          {item.unit_name ? item.unit_name : item.unit_number}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          <span
                            onClick={() =>
                              this.handleClickCheckinList(
                                item.property_slug,
                                item.reservation_slug
                              )
                            }
                          >
                            {item.guest_name ? item.guest_name : ""}
                          </span>
                          {global.userPermissions.checkPermission(
                            "communication-message-communication-with-guest"
                          ) ? (
                            <span
                              onClick={() =>
                                this.handleChatCheckinList(
                                  item.property_slug,
                                  item.reservation_slug
                                )
                              }
                            >
                              <img src={ChatIcon} alt="" />
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td
                          className="text-left tx-medium tx-inverse hand-cursor td_clickable"
                          onClick={() =>
                            this.handleClickCheckinList(
                              item.property_slug,
                              item.reservation_slug
                            )
                          }
                        >
                          {item.key_id ? item.key_id : ""}
                        </td>
                        <td
                          className="text-left tx-medium tx-inverse hand-cursor td_clickable"
                          onClick={() =>
                            this.handleClickCheckinList(
                              item.property_slug,
                              item.reservation_slug
                            )
                          }
                        >
                          {item.key_status ? item.key_status : ""}
                        </td>
                        <td
                          className="text-left tx-medium tx-inverse hand-cursor td_clickable"
                          onClick={() =>
                            this.handleClickCheckinList(
                              item.property_slug,
                              item.reservation_slug
                            )
                          }
                        >
                          {item.cleaning_status ? item.cleaning_status : ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <div className="table-responsive overflow-hidden">
                {this.state.spinnerLoader ? (
                  global.snapshotloader
                ) : (
                  <div className="text-center">
                    <img src={NoCheckIn} alt="" />
                    <p>No guests check-ins for today.</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(CheckinToday);
