////////////////////////////////////////////////////////////
//     							                          //
//  Program: LeasingView.jsx                             //
//  Application: LeasingView                             //
//  Option: For view leasing View details                //
//  Developer: NP 				                               //
//  Date: 2022-05-18                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
// import ListingService from "../../services/ListingService";
import Info from "./Info";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import roomImage from "./../../assets/images/room_placeholder.png";
import LeasingFees from "./LeasingFees";
import LeasingDocuments from "./LeasingDocuments";
import LeasingSidebar from "../common/LeasingSidebar";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";

export class LeasingView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleSwitchDark2: true,
      addModal: false,
      showSpinner: false,
      loading: false,
      editModal: false,
      deleteModal: false,
      status_id: 1,
      open: true,
      eventKey: "3",
      confirm_back: 0,
      lease_add: 0,
      listing_slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      outside_property_slug: this.props.match.params.outsidePropertySlug,
      pathname: window.location.pathname,
      imageUrl: roomImage,
      activeClass: "info",
      isRefresh: 1,
      listing_name: "",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.getConfirmStatus = this.getConfirmStatus.bind(this);
    this.getLeaseAddStatus = this.getLeaseAddStatus.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.getImgStatus = this.getImgStatus.bind(this);
  }

  getTitle() {
    return "Leases";
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }

  getLeaseAddStatus(leaseAdd) {
    this.setState({ lease_add: leaseAdd });
  }

  getImgStatus(imgFile) {
    this.setState({ imageUrl: imgFile });
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }
  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "info") {
      this.setState({ isRefresh: 1 });
    } else if (e === "leasingPrice") {
      this.setState({ isRefresh: 2 });
    } else if (e === "leasingDocuments") {
      this.setState({ isRefresh: 3 });
    } else if (e === "leasingPreview") {
      this.setState({ isRefresh: 4 });
    } else {
      this.setState({ isRefresh: 0 });
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <LeasingSidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyLeasingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? !this.props.property_slug
                      ? "col-md-10 right"
                      : "col-md-12"
                    : "col-md-12"
                }
              >
                <div className="dashboardRightcard- mg-t-15">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns info_box_model">
                      <h3>{this.getTitle()}</h3>
                      {/* <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                                    {this.props.listing_slug !== undefined ? (
                                      <>
                                        <span
                                          onClick={() => {
                                            this.opendeleteListingModal(this.props.listing_slug);
                                          }}
                                          className="deleteicon mg-r-20"
                                        >
                                          <img src={deleteicon} />
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}{" "}
                                    <Link
                                      to={
                                        "/properties/view/" +
                                        this.props.propertySlug +
                                        "/leasing/update/" +
                                        this.props.listing_slug
                                      }
                                    >
                                      <button className="btn-success-outline-small">
                                        
                                        Edit
                                      </button>
                                    </Link>
                                  </div> */}
                    </div>
                  </div>
                  <div className="scrolling-carousel pd-l-15">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="info"
                      onSelect={(e) => this.getActiveClass(e)}
                      eventKey={this.state.activeClass}
                    >
                      <ScrollingCarousel
                        rightArrow={true}
                        leftArrow={true}
                        className="swipesection scroll_nav_mob"
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="info"
                            id="infoForm"
                            className={
                              this.state.activeClass === "info" ? "active" : ""
                            }
                          >
                            1. Info
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="leasingPrice"
                            id="leasingPrice"
                            className={
                              this.state.activeClass === "leasingPrice"
                                ? "active"
                                : ""
                            }
                          >
                            2. Price
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="leasingDocuments"
                            id="leasingDocuments"
                            className={
                              this.state.activeClass === "leasingDocuments"
                                ? "active"
                                : ""
                            }
                            disabled={
                              this.state.listing_slug !== undefined
                                ? false
                                : true
                            }
                          >
                            3. Documents
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                            <Nav.Link
                              eventKey="leasingPreview"
                              id="leasingPreview"
                              className={
                                this.state.activeClass === "leasingPreview"
                                  ? "active"
                                  : ""
                              }
                            >
                              3. Preview
                            </Nav.Link>
                          </Nav.Item> */}
                      </ScrollingCarousel>
                      <Tab.Content>
                        <Tab.Pane eventKey="info">
                          {this.state.isRefresh === 1 && (
                            <Info
                              listing_slug={this.state.listing_slug}
                              propertySlug={this.state.property_slug}
                              imgFile={this.state.imageUrl}
                              propPropertySlug={
                                this.props.match.params.propertySlug
                              }
                              outside_property_slug={
                                this.state.outside_property_slug
                              }
                            />
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="leasingPrice">
                          {this.state.isRefresh === 2 && (
                            <LeasingFees
                              listing_slug={this.state.listing_slug}
                              property_slug={this.state.property_slug}
                              confirmBackStatus={this.getConfirmStatus}
                              leaseAddStatus={this.getLeaseAddStatus}
                              propPropertySlug={
                                this.props.match.params.propertySlug
                              }
                              outside_property_slug={
                                this.state.outside_property_slug
                              }
                            />
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="leasingDocuments">
                          {this.state.isRefresh === 3 && (
                            <LeasingDocuments
                              listing_slug={this.state.listing_slug}
                              property_slug={this.state.property_slug}
                              confirmBackStatus={this.getConfirmStatus}
                              leaseAddStatus={this.getLeaseAddStatus}
                              propPropertySlug={
                                this.props.match.params.propertySlug
                              }
                              outside_property_slug={
                                this.state.outside_property_slug
                              }
                            />
                          )}
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="leasingPreview">
                            {this.state.isRefresh === 4 && (
                              <Preview
                                listing_slug={this.state.listing_slug}
                                property_slug={this.state.property_slug}
                                confirmBackStatus={this.getConfirmStatus}
                              />
                            )}
                          </Tab.Pane> */}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default LeasingView;
