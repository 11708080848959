////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Metro.jsx                            //
//  Application: Location                                   //
//  Option: to view Metro data                   //
//  Developer: Ashish Kumar  						                  //
//  Date: 2022-01-06                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form, InputGroup } from "react-bootstrap";
import Alerts from "../common/Alerts";
import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import CityService from "../../services/CityService";
import MetroService from "../../services/MetroService";
import modaldeleteicon from "./../../assets/images/delete.svg";
import pluswIcon from "./../../assets/images/plus.svg";
import Select from "react-select";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import NoData from "../common/NoData";

export class Metro extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for metro
      addMetroModal: false,
      editMetroModal: false,
      deleteMetroModal: false,
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      metroList: [],
      metroListTotal: 0,
      metro_name: "",
      city_slug: "",
      sort_order: "",
    };

    this.changeHandler = this.changeHandler.bind(this);

    /* for metro */
    this.openAddMetroModal = this.openAddMetroModal.bind(this);
    this.openEditMetroModal = this.openEditMetroModal.bind(this);
    this.openDeleteMetroModal = this.openDeleteMetroModal.bind(this);
    this.saveMetro = this.saveMetro.bind(this);
    this.updateMetro = this.updateMetro.bind(this);
    this.getMetroDetails = this.getMetroDetails.bind(this);
    this.deleteMetro = this.deleteMetro.bind(this);
  }

  componentDidMount() {
    this.getMetroData();
  }

  /* to get city list data */
  async getCityData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;

    let res = await CityService.getCity(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList =
      cityListTotal > 0
        ? cityList.map(({ slug, city_name }) => ({
            value: slug,
            label: city_name,
          }))
        : [];
    this.setState({
      cityList: cityList,
      cityListTotal: cityListTotal,
      newCityList: newCityList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get  metro list data */
  async getMetroData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var metroList = [];
    var metroListTotal = 0;

    let res = await MetroService.getMetro(queryString ? "&" + queryString : "");
    if (global.successStatus.includes(res.status)) {
      metroList = res.data.data ? res.data.data : [];
      metroListTotal = metroList ? metroList.length : 0;
    }
    this.setState({ metroList: metroList, metroListTotal: metroListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save metro data */
  saveMetro = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.metro_name &&
      !this.validator.errorMessages.city_slug &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        metro_name: this.state.metro_name,
        city_slug: this.state.city_slug,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await MetroService.createMetro(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddMetroModal();
        this.setState({
          metro_name: "",
          city_slug: "",
          sort_order: "",
          status_id: true,
        });
        this.getMetroData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get  metro details data */
  async getMetroDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await MetroService.getMetroBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.metro_name = res.data.metro_name ? res.data.metro_name : "";
        resData.city_slug = res.data.city.slug ? res.data.city.slug : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        var selectedCityList = [];
        selectedCityList = res.data.city
          ? [
              {
                value: res.data.city.slug,
                label: res.data.city.city_name,
              },
            ]
          : "";
      }
      this.setState(resData);
      this.setState({ selectedCityList: selectedCityList });
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update  metro data */
  updateMetro = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.metro_name &&
      !this.validator.errorMessages.city_slug &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        metro_name: this.state.metro_name,
        city_slug: this.state.city_slug,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await MetroService.updateMetro(this.state.slug, inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditMetroModal();
        this.setState({
          metro_name: "",
          city_slug: "",
          sort_order: "",
          status_id: true,
        });
        this.getMetroData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete  metro data */
  async deleteMetro(slug) {
    this.setState({ showSpinner: true });
    let res = await MetroService.deleteMetro(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteMetroModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getMetroData();
    } else {
      this.closeDeleteMetroModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closeDeleteMetroModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  handleChangeCityList(value) {
    let city_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_slug.push(item.value);
      });
    }
    const city_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCityList: value,
      city_slug: city_slug_value,
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* Metro Modals */
  openAddMetroModal() {
    this.getCityData();
    this.setState({ addMetroModal: true });
  }

  closeAddMetroModal() {
    this.setState({ addMetroModal: false });
    this.setState({
      metro_name: "",
      city_slug: "",
      sort_order: "",
      errors: "",
      status_id: true,
      selectedCityList: [],
    });
    this.validator.hideMessages();
  }

  openEditMetroModal(slug) {
    this.getMetroDetails(slug);
    this.getCityData();
    this.setState({ editMetroModal: true });
    this.setState({ slug: slug });
  }

  closeEditMetroModal() {
    this.setState({ editMetroModal: false });
    this.setState({
      slug: "",
      metro_name: "",
      city_slug: "",
      sort_order: "",
      errors: "",
      status_id: true,
      selectedCityList: [],
    });
    this.validator.hideMessages();
  }

  openDeleteMetroModal(slug) {
    this.setState({ deleteMetroModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteMetroModal() {
    this.setState({ deleteMetroModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "auto",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Metro";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        {/* Metro Card */}
        {/* <Card>
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="31"
              id="31"
              onClick={(e) => this.setOpen(!this.state.open, e)}
            >
              Metro
              <i className="mdi mdi-view-headline"> </i>
              {this.state.open && this.state.eventKey === "31" ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-up"></i>
              )}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="31">
            <Card.Body>
              <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
                <Button
                  variant="outline-dark btn-block btn-with-icon"
                  onClick={this.openAddMetroModal}
                >
                  <img src={pluswIcon} /> Add
                </Button>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="table-responsive">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-50">Metro Name</th>
                      <th className="w-50">City Name</th>
                      <th className="w-25">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.metroListTotal > 0 ? (
                      this.state.metroList.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="hand-cursor" onClick={() =>
                              this.openEditMetroModal(item.slug)
                            }>{item.metro_name ? item.metro_name : ''}</td>
                            <td>{item.city ? item.city.city_name : ''}</td>
                            <td>
                              {item.status_id === 1 ? (
                                <b className="active"></b>
                              ) : (
                                <b></b>
                              )}
                            </td>
                            <td>
                              <div className="btn-icon-list d-block text-center">
                                <img
                                  src={deleteicon}
                                  alt="Delete"
                                  onClick={() =>
                                    this.openDeleteMetroModal(item.slug)
                                  }
                                  className="hand-cursor"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          No rows found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}

        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                          <Button
                            variant="outline-dark btn-block btn-with-icon"
                            onClick={this.openAddMetroModal}
                          >
                            <img src={pluswIcon} alt="" /> Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.metroListTotal > 0 ? (
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th className="w-30">Metro Name</th>
                              <th className="w-30">City Name</th>
                              <th className="w-35">Status</th>
                              {global.userPermissions.checkPermission(
                                "master-data-delete"
                              ) && <th className="text-center">Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.metroListTotal > 0 ? (
                              this.state.metroList.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      className="hand-cursor"
                                      onClick={() => {
                                        global.userPermissions.checkPermission(
                                          "master-data-update"
                                        ) && this.openEditMetroModal(item.slug);
                                      }}
                                    >
                                      {item.metro_name ? item.metro_name : ""}
                                    </td>
                                    <td>
                                      {item.city ? item.city.city_name : ""}
                                    </td>
                                    <td>
                                      {item.status_id === 1 ? (
                                        <b className="active"></b>
                                      ) : (
                                        <b></b>
                                      )}
                                    </td>
                                    {global.userPermissions.checkPermission(
                                      "master-data-delete"
                                    ) && (
                                      <td>
                                        <div className="btn-icon-list d-block text-center">
                                          <img
                                            src={deleteicon}
                                            alt=""
                                            onClick={() =>
                                              this.openDeleteMetroModal(
                                                item.slug
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                  There are no metros added in the system. You
                                  may add them by clicking Add button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage("METRO")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add Metro popup modal */}
        <Modal
          show={this.state.addMetroModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddMetroModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddMetroModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Metro</h3>
            <label>Metro Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="metro_name"
              value={this.state.metro_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Metro Name"
            />
            {this.state.errors.metro_name ? (
              <div className="text-danger">{this.state.errors.metro_name}</div>
            ) : (
              this.validator.message(
                "metro_name",
                this.state.metro_name,
                "required|max:50",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>City</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newCityList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCityList(value)}
                  defaultValue={this.state.selectedCityList}
                  value={this.state.selectedCityList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="city_slug"
                value={this.state.city_slug}
              >
                <option key="" value="">
                  Select City
                </option>
                {this.state.cityList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.city_name}
                  </option>
                ))}
              </select> */}
            </InputGroup>
            {this.state.errors.city_slug ? (
              <div className="text-danger">{this.state.errors.city_slug}</div>
            ) : (
              this.validator.message("city", this.state.city_slug, "required", {
                className: "text-danger",
              })
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddMetroModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveMetro}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit Metro popup modal */}
        <Modal
          show={this.state.editMetroModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditMetroModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditMetroModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Metro</h3>
            <label>Metro Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="metro_name"
              value={this.state.metro_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Metro Name"
            />
            {this.state.errors.metro_name ? (
              <div className="text-danger">{this.state.errors.metro_name}</div>
            ) : (
              this.validator.message(
                "metro_name",
                this.state.metro_name,
                "required|max:50",
                { className: "text-danger" }
              )
            )}
            <InputGroup className="mt-3">
              <label>City</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newCityList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCityList(value)}
                  defaultValue={this.state.selectedCityList}
                  value={this.state.selectedCityList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="city_slug"
                value={this.state.city_slug}
              >
                <option key="" value="">
                  Select City
                </option>
                {this.state.cityList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.city_name}
                  </option>
                ))}
              </select> */}
            </InputGroup>
            {this.state.errors.city_slug ? (
              <div className="text-danger">{this.state.errors.city_slug}</div>
            ) : (
              this.validator.message("city", this.state.city_slug, "required", {
                className: "text-danger",
              })
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditMetroModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateMetro}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete Metro popup modal */}
        <Modal
          show={this.state.deleteMetroModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteMetroModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteMetroModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteMetroModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteMetro(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Metro;
