////////////////////////////////////////////////////////////
//     							                                      //
//  Program: FaqsView.jsx                                //
//  Application:  faqs                                   //
//  Option: For view  Faqs details                      //
//  Developer: Saurabh Shukla                          //
//  Date: 2022-02-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Container, Modal } from "react-bootstrap";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import deleteicon from "../../assets/images/delete.svg";
import FaqService from "../../services/FaqService";
import renderHTML from "react-render-html";
export class FaqsView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      status_id: "",
      selectedRentingTypeList: "",
      propertyList: [],
      propertyListTotal: 0,
      newPropertyList: [],
      selectedPropertyList: [],
      kbCategoryList: [],
      newkbCategoryList: [],
      newkbCategoryListTotal: 0,
      selectedkbCategoryList: [],
      kbSubCategoryList: [],
      newkbSubCategoryList: [],
      newkbSubCategoryListTotal: 0,
      selectedkbSubCategoryList: [],
      descShow: 0,
      description: "",
      title: "",
      sort_order: "",
      confirm_back: 0,
      propertySlug: "",
      kb_category_slug: "",
      kb_sub_category_slug: "",
    };
    this.fullText = this.fullText.bind(this);
    this.deleteFaq = this.deleteFaq.bind(this);
    this.opendeleteFaqModal = this.opendeleteFaqModal.bind(this);
    this.closedeleteFaqModal = this.closedeleteFaqModal.bind(this);
    this.fullTextRules = this.fullTextRules.bind(this);
  }

  opendeleteFaqModal(slug) {
    this.setState({ deleteFaqModal: true, slug: slug });
  }

  closedeleteFaqModal() {
    this.setState({ deleteFaqModal: false, slug: "" });
  }

  async deleteFaq(slug) {
    this.setState({ showSpinner: true });
    let res = await FaqService.deleteFaq(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteFaqModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(() => this.props.history.push("/faqs"), global.redirect_time);
    } else {
      let alertMessage = "";
      this.closedeleteFaqModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      var propertyName = [];
      var TotalProperty = 0;
      let res = await FaqService.getFaqBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        TotalProperty = resData.faq_property.length;
        if (res.data.property_id === 0) {
          propertyName = "All Properties";
        } else {
          if (TotalProperty > 0) {
            resData.faq_property.map((item, index) => {
              propertyName[index] = item.property.property_name;
            });
          }
          propertyName =
            propertyName && propertyName.toString().replaceAll(",", ",  ");
        }
      }
      this.setState(resData);
      this.setState({ propertyName: propertyName });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  getTitle() {
    return "FAQ";
  }

  fullTextRules() {
    if (this.state.descShowRules === 0) {
      this.setState({ descShowRules: 1 });
    } else {
      this.setState({ descShowRules: 0 });
    }
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-12">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.slug !== undefined ? (
                        <>
                          {global.userPermissions.checkPermission(
                            "faqs-delete"
                          ) && (
                            <span
                              onClick={() => {
                                this.opendeleteFaqModal(this.state.slug);
                              }}
                              className="deleteicon mg-r-20"
                            >
                              <img src={deleteicon} alt="" />
                            </span>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {global.userPermissions.checkPermission(
                        "faqs-update"
                      ) && (
                        <Link
                          to={"/faq/update/" + this.state.slug}
                          className="mg-l-2"
                        >
                          <button className="btn-success-outline-small">
                            <img src={editIcon} alt="Edit" />
                            Edit
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-30 pr-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>

                    <div className="m-0">
                      <div className="row align-items-center col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-xs-l-0 pd-xs-r-0 pd-l-0 pd-r-0 border-bottom-0">
                        <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-0">
                              Module
                            </label>
                            <p className="media-body">
                              {this.state.module_type
                                ? this.state.module_type
                                : ""}
                            </p>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-0">
                              Property
                            </label>
                            <p className="media-body">
                              {this.state.propertyName
                                ? this.state.propertyName
                                : ""}
                            </p>
                          </div>
                        </div>

                        {/* <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-0">
                              Category
                              <span className="optional">(Optional)</span>
                            </label>
                            <p className="media-body">
                              {this.state.kb_category &&
                              this.state.kb_category.kb_category_name
                                ? this.state.kb_category.kb_category_name
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-0">
                              Sub Category
                              <span className="optional">(Optional)</span>
                            </label>
                            <p className="media-body">
                              {this.state.sub_kb_category &&
                              this.state.sub_kb_category.kb_sub_category_name
                                ? this.state.sub_kb_category
                                    .kb_sub_category_name
                                : ""}
                            </p>
                          </div>
                        </div> */}

                        <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-0">
                              Sort Order
                            </label>
                            <p className="media-body">
                              {this.state.sort_order
                                ? this.state.sort_order
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-0">
                              Private
                            </label>
                            <p className="media-body">
                              {this.state.private_content &&
                              this.state.private_content === 1
                                ? "Yes"
                                : this.state.private_content === 0
                                ? "No"
                                : ""}
                            </p>
                          </div>
                        </div>

                        <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-0">
                              Question{" "}
                              {/* <span className="optional">(Optional)</span> */}
                            </label>
                            <div className="media-body">
                              {this.state.title ? this.state.title : ""}
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15 faq_img_video">
                            <label className="form-label text-left pd-b-0">
                              Answer{" "}
                              {/* <span className="optional">(Optional)</span> */}
                            </label>

                            <div className="para mt-0">
                              {this.state.description ? ( //check if property_rules is exist
                                this.state.description.length >
                                global.showMoreLength ? ( //check if length is long
                                  this.state.descShowRules === 0 ? (
                                    <>
                                      <HTMLEllipsis
                                        unsafeHTML={
                                          this.state.description
                                            ? this.state.description
                                            : ""
                                        }
                                        maxLine="6"
                                        ellipsis=""
                                        trimright="true"
                                        basedOn="letters"
                                      />
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={this.fullTextRules}
                                      >
                                        Show More
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      {renderHTML("" + this.state.description)}
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={this.fullTextRules}
                                      >
                                        Show Less
                                      </p>
                                    </>
                                  )
                                ) : (
                                  renderHTML("" + this.state.description)
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-0">
                              Status{" "}
                            </label>
                            <div className="media-body">
                              {this.state.status_id === 1
                                ? "Active"
                                : this.state.status_id === 2
                                ? "Inactive"
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteFaqModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteFaqModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteFaqModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteFaq(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default FaqsView;
