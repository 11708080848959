import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import defaultImage from "./../../assets/images/profile-nogender.png";
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerToken: global.headerToken ? global.headerToken : "",
      profileImage:
        localStorage.getItem("userData") &&
        JSON.parse(localStorage.getItem("userData")).profile_thumb_url !==
          null &&
        JSON.parse(localStorage.getItem("userData")).profile_thumb_url !==
          undefined &&
        JSON.parse(localStorage.getItem("userData")).profile_thumb_url !== ""
          ? JSON.parse(localStorage.getItem("userData")).profile_thumb_url
          : defaultImage,
      userData: global.userData ? global.userData : {},
      showSpinner: true,
      customSpinner: parseInt(localStorage.getItem("customSpinnerSession"))
        ? false
        : true,
      loading: false,
      brandList: localStorage.getItem("brandList")
        ? JSON.parse(localStorage.getItem("brandList"))
        : [],
    };
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    // Get user profile and set into localstorage
    // if(localStorage.getItem('isLogin') === '1'){
    //     localStorage.removeItem('isLogin');
    //     // window.location.reload(false);
    // }
    this.setState({ showSpinner: false, loading: false, customSpinner: false });
    localStorage.setItem("customSpinnerSession", 1);
  }

  getTitle() {
    return process.env.REACT_APP_PROJECT_TITLE
      ? process.env.REACT_APP_PROJECT_TITLE
      : "";
  }

  toggleChildMenu(e) {
    e.preventDefault();

    const data = document.querySelector(".col-md-2");

    if (data !== null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");

        // Check if .mobchildMenu element exists before removing the class
        const mobchildMenu = document.querySelector(".mobchildMenu");
        if (mobchildMenu) {
          mobchildMenu.classList.remove("d-none");
        }

        // Check if .newSubmenu element exists before removing the class
        const newSubmenu = document.querySelector(".newSubmenu");
        if (newSubmenu) {
          newSubmenu.classList.remove("d-none");
        }
      } else {
        data.classList.replace("left", "hide");

        // Check if .mobchildMenu element exists before adding the class
        const mobchildMenu = document.querySelector(".mobchildMenu");
        if (mobchildMenu) {
          mobchildMenu.classList.add("d-none");
        }

        // Check if .newSubmenu element exists before adding the class
        const newSubmenu = document.querySelector(".newSubmenu");
        if (newSubmenu) {
          newSubmenu.classList.add("d-none");
        }
      }
    }
  }

  render() {
    // Set session expiry logic
    // let sessionExpireTime = localStorage.getItem('sessionExpireTime') ? localStorage.getItem('sessionExpireTime') : 60;
    // let lastSessionExpiry = localStorage.getItem('sessionExpiry') ? localStorage.getItem('sessionExpiry') : '';
    // let currentTimestamp = new Date().getTime();
    // if(!lastSessionExpiry && this.state.headerToken){
    //     let sessionExpiry = new Date(currentTimestamp + sessionExpireTime*60000).getTime();
    //     localStorage.setItem('sessionExpiry', sessionExpiry);
    // }else if(this.state.headerToken){
    //     if(!isNaN(lastSessionExpiry) && (lastSessionExpiry > currentTimestamp)){ // If session expiry > current time then extend session time
    //         let sessionExpiry = new Date(currentTimestamp + sessionExpireTime*60000).getTime();
    //         localStorage.setItem('sessionExpiry', sessionExpiry);
    //     }else{ // If session expiry < current time then logout
    //         return <Redirect to='/logout' />;
    //     }
    // }

    return (
      <div className="az-header">
        <div>
          {this.state.customSpinner ? (
            <div className="customLoad">
              <div className="div1">
                <div>
                  <img
                    className="mg-b-0 customLoadimg"
                    src={require("../../assets/images/logo.jpg").default}
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
          ) : this.state.showSpinner ? (
            global.spinnerLoader
          ) : (
            ""
          )}

          <div className="az-header-menu pd-t-0 pd-l-0 pd-r-0">
            <ul className="nav">
              {/* <li
                className={
                  this.isPathActive("/overview")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/overview">
                  Overview
                </Link>
              </li> */}
              {global.userPermissions.checkPermission("dashboard-list") && (
                <li
                  className={
                    this.isPathActive("/dashboard")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/dashboard">
                    Dashboard
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission(
                "key-data-dashboard-list"
              ) && (
                <>
                  <li
                    className={
                      this.isPathActive("/key-data-dashboard")
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Link className="nav-link" to="/key-data-dashboard">
                      Insights
                    </Link>
                  </li>
                </>
              )}
              {global.userPermissions.checkPermission("snapshot-list") && (
                <li
                  className={
                    this.isPathActive("/snapshot")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/snapshot">
                    Snapshot
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission(
                "communication-message-module"
              ) && (
                <li
                  className={
                    this.isPathActive("/messages")
                      ? "nav-item msg_menu_not active"
                      : "nav-item msg_menu_not"
                  }
                >
                  <Link className="nav-link" to="/messages">
                    Messages
                  </Link>
                  {/* <div className="message_bages"></div> */}
                </li>
              )}
              {global.userPermissions.checkPermission("credentials-list") && (
                <li
                  className={
                    this.isPathActive("/credentials")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to={"/credentials"}>
                    {" "}
                    Credentials{" "}
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("faqs-list") && (
                <li
                  className={
                    this.isPathActive("/faq") ? "nav-item active" : "nav-item"
                  }
                >
                  <Link className="nav-link" to={"/faqs"}>
                    {" "}
                    FAQs{" "}
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("influencers-list") && (
                <li
                  className={
                    this.isPathActive("/influencers")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to={"/influencers"}>
                    {" "}
                    Influencers{" "}
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("ambassadors-list") && (
                <li
                  className={
                    this.isPathActive("/ambassadors")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to={"/ambassadors"}>
                    {" "}
                    Ambassadors{" "}
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("support-list") && (
                <li
                  className={
                    this.isPathActive("/query-support")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to={"/query-support"}>
                    {" "}
                    Support{" "}
                  </Link>
                </li>
              )}

              <div className="menu_bdr_btm"></div>

              {/* Modules OutSide */}
              {global.userPermissions.checkPermission("reservations-list") && (
                <li
                  className={
                    this.isPathActive("/global-unified-calendar")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/global-unified-calendar">
                    Calendar
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("property-list") && (
                <li
                  className={
                    this.isPathActive("/properties")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/properties">
                    Properties
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("leases-list") && (
                <li
                  className={
                    this.isPathActive("/global-lease-avail-calendar") ||
                    this.isPathActive("/availability") ||
                    this.isPathActive("/leasing") ||
                    this.isPathActive("/applicants") ||
                    this.isPathActive("/applicant-detail") ||
                    this.isPathActive("/residents") ||
                    this.isPathActive("/rent") ||
                    this.isPathActive("/resident-payouts")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center"
                    to="/availability"
                  >
                    Leases
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              )}

              {global.userPermissions.checkPermission("single-unit-list") && (
                <li
                  className={
                    this.isPathActive("/listing") ||
                    this.isPathActive("/homesharing-inquiries") ||
                    this.isPathActive("/global-list-calendar")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center"
                    to="/listings"
                  >
                    Listings
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("reservations-list") && (
                <li
                  className={
                    this.isPathActive("/reservations") ||
                    this.isPathActive("/inquiry") ||
                    this.isPathActive("/global-reservation-calendar") ||
                    this.isPathActive("/global-checkin-calender") ||
                    this.isPathActive("/global-checkout-calender") ||
                    this.isPathActive("/guest")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center"
                    to="/reservations"
                  >
                    Reservations
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("cleaning-list") && (
                <li
                  className={
                    this.isPathActive("/global-housekeeping-calender") ||
                    this.isPathActive("/housekeeping-list") ||
                    this.isPathActive("/problems")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center"
                    to="/global-housekeeping-calender"
                  >
                    Cleaning
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission(
                "maintenance-calendar-list"
              ) && (
                <li
                  className={
                    this.isPathActive("/global-maintenance-calendar") ||
                    this.isPathActive("/mros")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center"
                    to="/global-maintenance-calendar"
                  >
                    Maintenance
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              )}

              {global.userPermissions.checkPermission(
                "key-cafe-access-view"
              ) && (
                <li
                  className={
                    this.isPathActive("/keys") ? "nav-item active" : "nav-item "
                  }
                >
                  <Link className="nav-link" to="/keys">
                    Keys
                  </Link>
                </li>
              )}

              <div className="menu_bdr_btm"></div>

              {/* if user has access for one brand */}
              {global.userPermissions.checkPermission("brand-list") &&
              this.state.brandList &&
              this.state.brandList.length === 1 ? (
                <li
                  className={
                    this.isPathActive("/brands") || this.isPathActive("/brand")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    className="nav-link"
                    to={
                      "/brand/view/" +
                      this.state.brandList[0].slug +
                      "/brand-info"
                    }
                  >
                    Brand
                  </Link>
                </li>
              ) : (
                <React.Fragment>
                  {global.userPermissions.checkPermission("brand-list") && (
                    <li
                      className={
                        this.isPathActive("/brands") ||
                        this.isPathActive("/brand")
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/brands">
                        Brands
                      </Link>
                    </li>
                  )}
                </React.Fragment>
              )}

              {global.userPermissions.checkPermission("clients-list") && (
                <li
                  className={
                    this.isPathActive("/clubs") || this.isPathActive("/club")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/clubs">
                    Clubs
                  </Link>
                </li>
              )}

              {global.userPermissions.checkPermission("clients-list") && (
                <li
                  className={
                    this.isPathActive("/clients") ||
                    this.isPathActive("/client")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/clients">
                    Clients
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("company-list") && (
                <li
                  className={
                    this.isPathActive("/company")
                      ? "nav-item  active"
                      : "nav-item "
                  }
                >
                  <Link className="nav-link" to="/company">
                    Company
                  </Link>
                </li>
              )}

              <div className="menu_bdr_btm"></div>

              {global.userPermissions.checkPermission(
                "occupancy-report-list"
              ) && (
                <li
                  className={
                    this.isPathActive("/occupancy-reports") ||
                    this.isPathActive("/homesharing-reports") ||
                    this.isPathActive("/oh-earning-reports") ||
                    this.isPathActive("/resident-payout") ||
                    this.isPathActive("/property-tax") ||
                    this.isPathActive("/property-payout-report") ||
                    this.isPathActive("/property-reservation-payout") ||
                    this.isPathActive("/property-unit-payout") ||
                    this.isPathActive("/ru-payout") ||
                    this.isPathActive("/top-performer")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center"
                    to="/top-performer"
                  >
                    Reports
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              )}

              {global.userPermissions.checkPermission(
                "payout-payments-list"
              ) && (
                <li
                  className={
                    this.isPathActive("/res-payout-summary") ||
                    this.isPathActive("/property-payout-summary") ||
                    this.isPathActive("/create-payout-resident") ||
                    this.isPathActive("/view-payout-resident") ||
                    this.isPathActive("/edit-payout-resident") ||
                    this.isPathActive("/stripe-total-payout") ||
                    this.isPathActive("/stripe-transfer") ||
                    this.isPathActive("/resident-topup")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center"
                    to="/res-payout-summary"
                  >
                    Payouts
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              )}

              {/* {global.userPermissions.checkPermission(
                "payout-payments-list"
              ) && (
                <li
                  className={
                    this.isPathActive("/stripe-total-payout")
                      ? "nav-item  active"
                      : "nav-item "
                  }
                >
                  <Link className="nav-link" to="/stripe-total-payout">
                    Stripe Summary
                  </Link>
                </li>
              )} */}

              {/* {global.userPermissions.checkPermission(
                "payout-payments-list"
              ) && (
                <li
                  className={
                    this.isPathActive("/resident-topup")
                      ? "nav-item  active"
                      : "nav-item "
                  }
                >
                  <Link className="nav-link" to="/resident-topup">
                    Top-ups
                  </Link>
                </li>
              )} */}

              <div className="menu_bdr_btm"></div>

              {global.userPermissions.checkPermission("users-list") && (
                <li
                  className={
                    this.isPathActive("/users") ||
                    this.isPathActive("/roles") ||
                    this.isPathActive("role-permission") ||
                    this.isPathActive("role")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link  d-flex justify-content-between align-items-center"
                    to="/users"
                  >
                    Users
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              )}

              {global.userPermissions.checkPermission("master-data-list") && (
                <li
                  className={
                    this.isPathActive("/master-setup") ||
                    this.isPathActive("/accessibility-feature-type") ||
                    this.isPathActive("/accessibility-features") ||
                    this.isPathActive("/affordable-type") ||
                    this.isPathActive("/agency-category") ||
                    this.isPathActive("/amenity-type") ||
                    this.isPathActive("/amenities") ||
                    this.isPathActive("/attraction-type") ||
                    this.isPathActive("/property-type") ||
                    this.isPathActive("/class-type") ||
                    this.isPathActive("/document-type") ||
                    this.isPathActive("/event-type") ||
                    this.isPathActive("/flooring-type") ||
                    this.isPathActive("/heating-type") ||
                    this.isPathActive("/leed-type") ||
                    this.isPathActive("/location-type") ||
                    this.isPathActive("/mro-category") ||
                    this.isPathActive("/ownership-type") ||
                    this.isPathActive("/parking-type") ||
                    this.isPathActive("/pet-type") ||
                    this.isPathActive("/room-category") ||
                    this.isPathActive("/room-type") ||
                    this.isPathActive("/smoking-type") ||
                    this.isPathActive("/staff-type") ||
                    this.isPathActive("/suitability") ||
                    this.isPathActive("/timezone") ||
                    this.isPathActive("/cost-payable-at") ||
                    this.isPathActive("/cost-payable-by") ||
                    this.isPathActive("/cost-type") ||
                    this.isPathActive("/taxes") ||
                    this.isPathActive("/tax-type") ||
                    this.isPathActive("/country") ||
                    this.isPathActive("/state") ||
                    this.isPathActive("/county") ||
                    this.isPathActive("/city") ||
                    this.isPathActive("/metro") ||
                    this.isPathActive("/sub-metro") ||
                    this.isPathActive("/channel") ||
                    this.isPathActive("/floor-plans-lookup") ||
                    this.isPathActive("/amenity-lookup") ||
                    this.isPathActive("/near-by-lookup") ||
                    this.isPathActive("/fee-lookup") ||
                    this.isPathActive("/master-tax-lookup") ||
                    this.isPathActive("/taxes-lookup") ||
                    this.isPathActive("/countries-lookup") ||
                    this.isPathActive("/lookup-states") ||
                    this.isPathActive("/cities-lookup") ||
                    this.isPathActive("/channels-lookup") ||
                    this.isPathActive("/properties-ota-lookup") ||
                    this.isPathActive("/kb-category") ||
                    this.isPathActive("/kb-sub-category")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link  d-flex justify-content-between align-items-center"
                    to="/master-setup"
                  >
                    Master Data
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("website-cms-list") ||
              global.userPermissions.checkPermission("app-cms-list") ? (
                <li
                  className={
                    this.isPathActive("/corporate-website") ||
                    this.isPathActive("/corporate-apps")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link  d-flex justify-content-between align-items-center"
                    to="/corporate-website"
                  >
                    CMS
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {global.userPermissions.checkPermission("template-list") && (
                <li
                  className={
                    this.isPathActive("/all-template") ||
                    this.isPathActive("/workflows") ||
                    this.isPathActive("/emails") ||
                    this.isPathActive("/templates") ||
                    this.isPathActive("/scheduler") ||
                    this.isPathActive("/quick-replies") ||
                    this.isPathActive("/quick-replies/add") ||
                    this.isPathActive("/quick-replies/update/:slug")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link  d-flex justify-content-between align-items-center"
                    to="/quick-replies"
                  >
                    Templates
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              )}

              {global.userPermissions.checkPermission("crm-campaigns-list") && (
                <li
                  className={
                    this.isPathActive("/crm-campaign") ||
                    this.isPathActive("/crm-templates") ||
                    this.isPathActive("/crm-template") ||
                    this.isPathActive("/crm-list") ||
                    this.isPathActive("/crm-subscribers") ||
                    this.isPathActive("/crm-contact") ||
                    this.isPathActive("/crm-unsubscribers")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link  d-flex justify-content-between align-items-center"
                    to="/crm-campaign"
                  >
                    CRM
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission(
                "datahub-mapping-view"
              ) && (
                <li
                  className={
                    this.isPathActive("/automation-data-hub-settings") ||
                    this.isPathActive("/automation-processing-log-import")
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link className="nav-link" to="/automation-data-hub-settings">
                    Data Import
                  </Link>
                </li>
              )}

              {global.userPermissions.checkPermission(
                "datahub-export-mapping-view"
              ) && (
                <li
                  className={
                    this.isPathActive("/export-automation") ||
                    this.isPathActive("/automation-processing-log-export")
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link className="nav-link" to="/export-automation">
                    Data Export
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("assets-view") && (
                <li
                  className={
                    this.isPathActive("/assets")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    className="nav-link  d-flex justify-content-between align-items-center"
                    to="/assets"
                  >
                    Assets
                  </Link>
                </li>
              )}
            </ul>

            <div className="profileViewmobile">
              <div className="navMob d-flex align-items-center justify-content-center w-100">
                <figure>
                  <img
                    src={
                      this.props.image
                        ? this.props.image
                        : this.state.profileImage
                    }
                    alt="img"
                  />
                </figure>
              </div>
              <ul>
                <li>
                  <a href="/update-profile">Update Profile</a>
                </li>
                <li>
                  <a href="/change-password">Change Password</a>
                </li>
                <li>
                  <a href="/logout">Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  isPathActive(path) {
    document.querySelector("body").classList.remove("az-header-menu-show");
    return this.props.location.pathname.startsWith(path);
  }
}

export default withRouter(Header);
