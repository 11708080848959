////////////////////////////////////////////////////////////
//     							                                      //
//  Program: CostType.jsx                           //
//  Application: Financial                                   //
//  Option: to view CostType data                  //
//  Developer: Ashish Kumar                               //
//  Date: 2022-01-06                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form, InputGroup } from "react-bootstrap";
import Alerts from "../common/Alerts";
import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import CostTypeService from "../../services/CostTypeService";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import Select from "react-select";
import MasterSidebar from "../common/MasterSidebar";
import NoData from "../common/NoData";

export class CostType extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for Cost type
      addCostTypeModal: false,
      editCostTypeModal: false,
      deleteCostTypeModal: false,
      costTypeList: [],
      costTypeListTotal: 0,
      cost_type_name: "",
      sort_order: "",
      feeLevelList: [
        { label: "Property", value: 1 },
        { label: "Floor plan", value: 2 },
      ],
      selectedFeeLevelList: [],
    };
    this.changeHandler = this.changeHandler.bind(this);

    /* for Cost Type */
    this.openAddCostTypeModal = this.openAddCostTypeModal.bind(this);
    this.openEditCostTypeModal = this.openEditCostTypeModal.bind(this);
    this.openDeleteCostTypeModal = this.openDeleteCostTypeModal.bind(this);
    this.saveCostType = this.saveCostType.bind(this);
    this.getCostTypeDetails = this.getCostTypeDetails.bind(this);
    this.updateCostType = this.updateCostType.bind(this);
    this.deleteCostType = this.deleteCostType.bind(this);
  }

  componentDidMount() {
    this.getCostTypeData();
  }

  /* To get Cost Type list data */
  async getCostTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costTypeList = [];
    var costTypeListTotal = 0;

    let res = await CostTypeService.getCostType(
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costTypeList = res.data.data ? res.data.data : [];
      costTypeListTotal = costTypeList ? costTypeList.length : 0;
    }
    this.setState({
      costTypeList: costTypeList,
      costTypeListTotal: costTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To save Cost Type data */
  saveCostType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.fee_type_name &&
      !this.validator.errorMessages.fee_level &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        cost_type_name: this.state.cost_type_name,
        fee_level: this.state.fee_level,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await CostTypeService.createCostType(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddCostTypeModal();
        this.setState({
          cost_type_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getCostTypeData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get Cost Type details data  */
  async getCostTypeDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await CostTypeService.getCostTypeBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        // resData = res.data ? res.data : {};
        resData.cost_type_name = res.data.cost_type_name
          ? res.data.cost_type_name
          : "";
        resData.fee_level = res.data.fee_level ? res.data.fee_level : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;

        var selectedFeeLevelList = [];
        selectedFeeLevelList =
          res.data.fee_level === 1
            ? [
                {
                  value: 1,
                  label: "Property",
                },
              ]
            : [
                {
                  value: 2,
                  label: "Floor plan",
                },
              ];
      }
      this.setState(resData);
      this.setState({ selectedFeeLevelList: selectedFeeLevelList });
      // this.setState({ fee_type: selectedFeeLevelList});
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update Cost Type data */
  updateCostType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.fee_type_name &&
      !this.validator.errorMessages.fee_level &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        cost_type_name: this.state.cost_type_name,
        fee_level: this.state.fee_level,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await CostTypeService.updateCostType(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditCostTypeModal();
        this.setState({
          cost_type_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getCostTypeData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete Cost Type data */
  async deleteCostType(slug) {
    this.setState({ showSpinner: true });
    let res = await CostTypeService.deleteCostType(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteCostTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getCostTypeData();
    } else {
      this.closeDeleteCostTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closeDeleteCostTypeModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* Cost Type Modals */
  openAddCostTypeModal() {
    this.setState({ addCostTypeModal: true });
  }

  closeAddCostTypeModal() {
    this.setState({ addCostTypeModal: false });
    this.setState({
      cost_type_name: "",
      fee_level: "",
      sort_order: "",
      status_id: true,
      errors: "",
      selectedFeeLevelList: [],
    });
    this.validator.hideMessages();
  }

  openEditCostTypeModal(slug) {
    this.getCostTypeDetails(slug);
    this.setState({ editCostTypeModal: true });
    this.setState({ slug: slug });
  }

  closeEditCostTypeModal() {
    this.setState({ editCostTypeModal: false });
    this.setState({
      slug: "",
      cost_type_name: "",
      fee_level: "",
      sort_order: "",
      status_id: true,
      errors: "",
      selectedFeeLevelList: [],
    });
    this.validator.hideMessages();
  }

  openDeleteCostTypeModal(slug) {
    this.setState({ deleteCostTypeModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteCostTypeModal() {
    this.setState({ deleteCostTypeModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  handleChangeCostTypeList(value) {
    let fee_level = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        fee_level.push(item.value);
      });
    }
    const fee_level_value = value !== null ? value.value : [];
    this.setState({
      selectedFeeLevelList: value,
      fee_level: fee_level_value,
    });
  }

  getTitle() {
    return "Fee Type";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        {/* Cost Type card */}
        {/* <Card>
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="24"
              id="24"
              onClick={(e) => this.setOpen(!this.state.open, e)}
            >
              Cost Type
              <i className="mdi mdi-view-headline"> </i>
              {this.state.open && this.state.eventKey === "24" ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-up"></i>
              )}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="24">
            <Card.Body>
              <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
                  {global.userPermissions.checkPermission(
                              "master-data-add"
                            ) && (
                <Button
                  variant="outline-dark btn-block btn-with-icon"
                  onClick={this.openAddCostTypeModal}
                >
                  <img src={pluswIcon} /> Add
                </Button>
                )}
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="table-responsive">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-50">Cost Type Name</th>
                      <th className="w-25">Status</th>
                          {global.userPermissions.checkPermission(
                              "master-data-delete"
                            ) && (
                      <th className="text-center">Action</th> )}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.costTypeListTotal > 0 ? (
                      this.state.costTypeList.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="hand-cursor" onClick={() =>
                                  {global.userPermissions.checkPermission(
                              "master-data-update"
                            ) && (this.openEditCostTypeModal(item.slug) )}
                            }>{item.cost_type_name}</td>
                            <td>
                              {item.status_id === 1 ? (
                                <b className="active"></b>
                              ) : (
                                <b></b>
                              )}
                            </td>
                                {global.userPermissions.checkPermission(
                              "master-data-delete"
                            ) && (
                            <td>
                              <div className="btn-icon-list d-block text-center">
                                <img
                                  src={deleteicon}
                                  alt="Delete"
                                  onClick={() =>
                                    this.openDeleteCostTypeModal(item.slug)
                                  }
                                  className="hand-cursor"
                                />
                              </div>
                            </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          No rows foundFee Type
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}

        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                          <Button
                            variant="outline-dark btn-block btn-with-icon"
                            onClick={this.openAddCostTypeModal}
                          >
                            <img src={pluswIcon} alt="" /> Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      {/* <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
              <Button
                variant="outline-dark btn-block btn-with-icon"
                onClick={this.openAddCostTypeModal}
              >
                <img src={pluswIcon} alt="" /> Add
              </Button>
            </div> */}
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.costTypeListTotal > 0 ? (
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th className="w-40">Fee Type Name</th>
                              <th className="w-40">Fee Level</th>
                              <th className="w-25">Status</th>
                              {global.userPermissions.checkPermission(
                                "master-data-delete"
                              ) && <th className="text-center">Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.costTypeListTotal > 0 ? (
                              this.state.costTypeList.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      className="hand-cursor"
                                      onClick={() => {
                                        global.userPermissions.checkPermission(
                                          "master-data-update"
                                        ) &&
                                          this.openEditCostTypeModal(item.slug);
                                      }}
                                    >
                                      {item.cost_type_name}
                                    </td>
                                    <td
                                      className="hand-cursor"
                                      onClick={() =>
                                        this.openEditCostTypeModal(item.slug)
                                      }
                                    >
                                      {item.fee_level
                                        ? item.fee_level === 1
                                          ? "Property"
                                          : "Floor Plan"
                                        : ""}
                                    </td>
                                    <td>
                                      {item.status_id === 1 ? (
                                        <b className="active"></b>
                                      ) : (
                                        <b></b>
                                      )}
                                    </td>
                                    {global.userPermissions.checkPermission(
                                      "master-data-delete"
                                    ) && (
                                      <td>
                                        <div className="btn-icon-list d-block text-center">
                                          <img
                                            src={deleteicon}
                                            alt="Delete"
                                            onClick={() =>
                                              this.openDeleteCostTypeModal(
                                                item.slug
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                  There are no fee types added in the system.
                                  You may add them by clicking Add button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage("COSTTYPE")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add Cost Type popup modal */}
        <Modal
          show={this.state.addCostTypeModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddCostTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddCostTypeModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Fee Type</h3>
            <label>Fee Type Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="cost_type_name"
              value={this.state.cost_type_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Fee Type Name"
            />
            {this.state.errors.cost_type_name ? (
              <div className="text-danger">
                {this.state.errors.cost_type_name}
              </div>
            ) : (
              this.validator.message(
                "fee_type_name",
                this.state.cost_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Fee level</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.feeLevelList}
                  value={this.state.selectedFeeLevelList}
                  defaultValue={this.state.selectedFeeLevelList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCostTypeList(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>
            {this.state.errors.fee_level ? (
              <div className="text-danger">{this.state.errors.fee_level}</div>
            ) : (
              this.validator.message(
                "fee_level",
                this.state.fee_level,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddCostTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveCostType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit Cost Type popup modal */}
        <Modal
          show={this.state.editCostTypeModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditCostTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditCostTypeModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Fee Type</h3>

            <label>Fee Type Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="cost_type_name"
              value={this.state.cost_type_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Fee Type Name"
            />

            {this.state.errors.cost_type_name ? (
              <div className="text-danger">
                {this.state.errors.cost_type_name}
              </div>
            ) : (
              this.validator.message(
                "fee_type_name",
                this.state.cost_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Fee level</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.feeLevelList}
                  defaultValue={this.state.selectedFeeLevelList}
                  value={this.state.selectedFeeLevelList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCostTypeList(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>
            {this.state.errors.fee_level ? (
              <div className="text-danger">{this.state.errors.fee_level}</div>
            ) : (
              this.validator.message(
                "fee_level",
                this.state.fee_level,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditCostTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateCostType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete Cost Type popup modal */}
        <Modal
          show={this.state.deleteCostTypeModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteCostTypeModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteCostTypeModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteCostTypeModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCostType(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default CostType;
