// //////////////////////////////////////////////////////////////
//  Program: HomeSharingInquiriesAddForm.jsx                   //
//  Application: Homesharing Inquiries                        //
//  Option: Homesharing Inquiries Add or update               //
//  Developer: Devendra Kumar		                           //
//  Date: 12/07/2023                                           //
//                                                             //
/////////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Container, Form } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import Select from "react-select";
import ListingService from "../../services/ListingService";
import LisitingSidebar from "../common/LisitingSidebar";
import AllPropertyLisitingSidebar from "../common/AllPropertyLisitingSidebar";

class HomeSharingInquiriesAddForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
    });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      property_slug: this.props.match.params.outsidePropertySlug
        ? this.props.match.params.outsidePropertySlug
        : this.props.match.params.propertySlug,

      slug: this.props.match.params.slug,
      selected_property_slug: this.props.match.params.selectedPropertySlug,
      isSubmit: false,
      status_id: true,
      pathname: window.location.pathname,
      confirm_back: 0,
      propertyList: [],
      selectedPropertyList: [],
      roleList: [],
      isSearchModal: 0,
      selectedClientList: [],
      selectedBrandList: [],
      property_name: "",
      unit_name: "",
      person_name: "",
      user_id: "",
      property_id: "",
      unit_id: "",
      selectedUnitList: [],
      selectedStatusList: [],
      notes: "",
      role_id: "",
      tab: "active",
      is_email: true,
      is_sms: true,
      newUnitList: [],
      is_notification: true,
      is_property_level: false,
      save_user_error: false,
      user_properties: "",
      usertabData: localStorage.getItem("user_tab_data")
        ? localStorage.getItem("user_tab_data")
        : "",
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
    };

    this.initialState = this.state;
    this.defaultData = {};
  }

  componentDidMount() {
    this.getData();
  }

  searchToggle = () => {
    if (this.state.isSearchModal === 1) {
      this.setState({ isSearchModal: 0 });
    } else {
      this.setState({ isSearchModal: 1 });
    }
  };

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;
    var statusList = [];
    var statusListTotal = 0;
    let res = [];
    let result = [];
    let selectedUnitList = [];
    let selectedStatusList = [];
    if (this.state.property_slug !== undefined) {
      res = await ListingService.getPropertyUnitData(this.state.property_slug);
      if (this.state.slug !== undefined) {
        result = await ListingService.getResidentDataBySlug(this.state.slug);
      }
      if (global.successStatus.includes(result.status)) {
        this.state.property_name = result.data ? result.data.property_name : "";
        this.state.person_name = result.data
          ? result.data.first_name !== null
            ? result.data.first_name
            : "" + " " + result.data.last_name !== null
            ? result.data.last_name
            : ""
          : "";
        this.state.notes = result.data ? result.data.notes : "";
        this.state.unit_id = result.data ? result.data.unit_id : 0;
        this.state.status_list_id = result.data ? result.data.status : "";
        this.state.user_id = result.data ? result.data.person_id : 0;
        selectedUnitList = result.data
          ? [
              {
                value: result.data.unit_id,
                label:
                  result.data && result.data.unit_name
                    ? result.data.unit_name
                    : result.data.unit_number,
              },
            ]
          : "";
        selectedStatusList = result.data
          ? [
              {
                value: result.data.status,
                label:
                  result.data && result.data.inquiries_status
                    ? result.data.inquiries_status
                    : "",
              },
            ]
          : "";
      }
      if (global.successStatus.includes(res.status)) {
        unitList = res.data ? res.data.unit_data : [];
        unitListTotal = unitList ? unitList.length : 0;
        statusList = res.data ? res.data.status_data : [];
        statusListTotal = unitList ? unitList.length : 0;
        this.state.property_name = res.data
          ? res.data.property_data.property_name
          : "";
        this.state.property_id = res.data
          ? res.data.property_data.property_id
          : "";
      }
      const newUnitList =
        unitListTotal > 0
          ? unitList.map(({ unit_slug, unit_number, unit_id, unit_name }) => ({
              value: unit_slug,
              label: unit_name ? unit_name : unit_number,
              unit_id: unit_id ? unit_id : 0,
            }))
          : [];
      const newStatusList =
        statusListTotal > 0
          ? statusList.map(({ status_id, inquiries_status }) => ({
              status_id: status_id,
              label: inquiries_status ? inquiries_status : "",
            }))
          : [];
      this.setState({
        unitList: unitList,
        unitListTotal: unitListTotal,
        newUnitList: newUnitList,
        newStatusList: newStatusList,
        selectedUnitList: selectedUnitList,
        selectedStatusList: selectedStatusList,
      });
      this.setState({
        showSpinner: false,
        loading: false,
      });
    }
    this.setState({ tab: "activeTab" });
  }

  async handleChangeUnitList(value) {
    let unit_list_slug = [];
    let first_name = "";
    let last_name = "";
    let full_name = "";
    let user_id = "";
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_list_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    if (value !== null) {
      let res = await ListingService.getUserData(unit_slug_value);
      if (global.successStatus.includes(res.status)) {
        first_name =
          res.data && res.data.first_name !== null ? res.data.first_name : "";
        last_name =
          res.data && res.data.last_name !== null ? res.data.last_name : "";
        user_id = res.data ? res.data.user_id : "";
        full_name = first_name + " " + last_name;
      }
    }

    this.setState({
      selectedUnitList: value,
      unit_list_slug: value.value,
      person_name: full_name,
      user_id: user_id,
      unit_id: value.unit_id,
    });
    this.setState({ confirm_back: 1 });
  }

  async handleChangeStatusList(value) {
    let status_list_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_list_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_list_id: value.status_id,
    });
    this.setState({ confirm_back: 1 });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
  };

  saveResidentInvite = async () => {
    let inputData = {
      property_id: this.state.property_id,
      unit_id: this.state.unit_id,
      user_id: this.state.user_id,
      status_id: this.state.status_list_id,
      notes: this.state.notes,
    };
    if (this.state.slug !== undefined) {
      if (this.validator.allValid()) {
        this.setState({ isSubmit: true });
        let res = await ListingService.updateHomeSharingInquiry(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.outsidePropertySlug !== undefined
                  ? "/homesharing-inquiries/view/" +
                      this.state.property_slug +
                      "/" +
                      this.state.slug + "/info"
                  : "/properties/view/" +
                      this.state.property_slug +
                      "/homesharing-inquiries/view/" +
                      this.state.slug
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      } else {
        this.validator.showMessages();
      }
    } else {
      if (this.validator.allValid()) {
        this.setState({ isSubmit: true });
        this.setState({ errors: "" });
        let res = await ListingService.createHomeSharingInquiry(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                window.location.pathname.includes("/properties/view/")
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/homesharing-inquiries"
                  : "/homesharing-inquiries"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      } else {
        this.validator.showMessages();
      }
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  redirectToListingPage() {
    this.props.history.push(
      this.state.slug !== undefined
        ? window.location.pathname.includes("/properties/view/")
          ? "/properties/view/" +
            this.state.property_slug +
            "/homesharing-inquiries/view/" +
            this.state.slug
          : "/homesharing-inquiries/view/" +
            this.state.property_slug +
            "/" +
            this.state.slug +
            "/info"
        : window.location.pathname.includes("/properties/view/")
        ? "/properties/view/" +
          this.state.property_slug +
          "/homesharing-inquiries"
        : "/homesharing-inquiries"
    );
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  render() {
    this.validator.purgeFields();
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <LisitingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </aside>
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <AllPropertyLisitingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </aside>
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>Homesharing Inquiries</h3>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => this.redirectToListingPage()}
                      >
                        Cancel
                      </button>
                      <Button
                        variant="btn-success-outline ml-3 mt-0 btn-block button-width"
                        onClick={this.saveResidentInvite}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                          ? "Save"
                          : "Save"}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-lg-l-15 pd-sm-l-30 pd-sm-r-30 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <form id="homesharing_inquiry_form">
                      <div className="m-0">
                        <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Property Name{" "}
                            </label>
                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="property_name"
                              value={this.state.property_name}
                              readOnly
                            />
                            {/* {this.state.errors.property_name ? (
                              <div className="text-danger">
                                {this.state.errors.property_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "property_name",
                                this.state.property_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )} */}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Unit Name{" "}
                            </label>
                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedUnitList}
                              options={this.state.newUnitList}
                              onChange={(value) =>
                                this.handleChangeUnitList(value)
                              }
                              defaultValue={this.state.selectedUnitList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                            {/* {this.state.errors.unit_name ? (
                              <div className="text-danger">
                                {this.state.errors.unit_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "unit_name",
                                this.state.selectedUnitList,
                                "required",
                                { className: "text-danger" }
                              )
                            )} */}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Person Name{" "}
                            </label>
                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="person_name"
                              value={this.state.person_name || ""}
                              readOnly
                            />
                            {/* {this.state.errors.person_name ? (
                              <div className="text-danger">
                                {this.state.errors.person_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "person_name",
                                this.state.person_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )} */}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Status{" "}
                            </label>
                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedStatusList}
                              options={this.state.newStatusList}
                              onChange={(value) =>
                                this.handleChangeStatusList(value)
                              }
                              defaultValue={this.state.selectedStatusList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                            {this.state.errors.status ? (
                              <div className="text-danger">
                                {this.state.errors.status}
                              </div>
                            ) : (
                              this.validator.message(
                                "status",
                                this.state.selectedStatusList,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Notes <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              onChange={this.changeHandler}
                              name="notes"
                              value={this.state.notes || ""}
                              className="form-control max_width_100"
                              id="notes"
                              as="textarea"
                              rows="5"
                            />
                            
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default HomeSharingInquiriesAddForm;
