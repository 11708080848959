import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ThemeService from "../../services/ThemeServices";
import Select from "react-select";
import hostImg from "./../../assets/images/profile-nogender.png";
import cmshomepreview from "./../../assets/images/cms-home-preview.jpg";

class ThemePagesSectionSetup extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      showSpinner: false,
      loading: false,
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      brandslug: this.props.brandSlug,
      property_slug: this.props.propertySlug,
      pathname: window.location.pathname,
      page_id: this.props.page_slug ? this.props.page_slug : "",
      page_name: this.props.page_name ? this.props.page_name : "",
      section_id: this.props.section_slug ? this.props.section_slug : "",
      // page_id:"",
      // section_id:"",
      detailSlug: "",
      //for dropdown 1
      pageList: [],
      pageListTotal: 0,
      selectedPageList: [],
      //for dropdown 2
      sectionList: [],
      sectionListTotal: 0,
      selectedSectionList: [],
      //for cms data
      cmsPageNameList: [],
      cmsPageNameListTotal: 0,
      reference_img: "",
      displayRefImg: false,
      cmsPageSeoList: [],
      cmsPageSeoListTotal: 0,
      cmsPageSeoSlug:"",
      forcefull_checkin: false,
      cmshomepreview:cmshomepreview
    };
    this.updateCmsPages = this.updateCmsPages.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleColorSelection = this.handleColorSelection.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.changeHandlerImage = this.changeHandlerImage.bind(this);
    this.changeHandlerSectionImage = this.changeHandlerSectionImage.bind(this);
    this.backToPages = this.backToPages.bind(this);
  }

  componentDidMount() {
      this.getCmsPageSeoData(this.props.page_slug);
      this.getCmsPageData(this.props.page_slug, "");
    // }
  }

  async getCmsPageSeoData(page_slug) {
    this.setState({ showSpinner: true, loading: true });
    var cmsPageSeoList = [];
    var cmsPageSeoListTotal = 0;
    var cmsPageSeoSlug = "";
    var resData = {};
    let res = "";
    // if(this.state.property_slug !== undefined) {
    if (this.props.level === 3) {
      res = await ThemeService.getcmsPageSeoData(
        "property_slug=" +
          this.props.propertySlug +
          "&page_slug=" +
          (page_slug ? page_slug : this.state.page_id) +
          "&refid=" +
          this.props.level
      );
    } else if (this.props.level === 2) {
      res = await ThemeService.getcmsPageSeoData(
        "brand_slug=" +
          this.props.brandSlug +
          "&page_slug=" +
          (page_slug ? page_slug : this.state.page_id) +
          "&refid=" +
          this.props.level
      );
     } else if (this.props.level === 4) {
      res = await ThemeService.getcmsPageSeoData(
        "club_slug=" +
          this.props.clubSlug +
          "&page_slug=" +
          (page_slug ? page_slug : this.state.page_id) +
          "&refid=" +
          this.props.level
      );
    } else {
      res = await ThemeService.getcmsPageSeoData(
        "page_slug=" +
          (page_slug ? page_slug : this.state.page_id) +
          "&refid=" +
          this.props.level
      );
    }
    if (global.successStatus.includes(res.status)) {
      cmsPageSeoList = res.data && res.data.meta_data ? res.data.meta_data : [];
      cmsPageSeoSlug = res.data && res.data.slug ? res.data.slug : [];
      cmsPageSeoListTotal = cmsPageSeoList ? cmsPageSeoList.length : 0;
    }
    this.setState(resData);
    var cmshomepreview1 = cmsPageSeoList.META ? ((cmsPageSeoList.META.page_ref_image === "" || cmsPageSeoList.META.page_ref_image === null) ? ""  :  cmsPageSeoList.META.page_ref_image ) : "";
    this.setState({
      cmsPageSeoList: cmsPageSeoList,
      cmsPageSeoSlug: cmsPageSeoSlug,
      cmsPageSeoListTotal: cmsPageSeoListTotal,
      cmshomepreview: cmshomepreview1 !== "" ? cmshomepreview1 : cmshomepreview
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getcmsPageDropdown() {
    this.setState({ showSpinner: true, loading: true });
    var pageList = [];
    var pageListTotal = 0;

    let res = await ThemeService.getcmsPageDropdown();
    if (global.successStatus.includes(res.status)) {
      pageList = res.data ? res.data : [];
      pageListTotal = pageList ? pageList.length : 0;
    }
    this.setState({
      pageList: pageList,
      pageListTotal: pageListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getcmsPageSectionDropdown(slug) {
    this.setState({ showSpinner: true, loading: true });
    var sectionList = [];
    var sectionListTotal = 0;

    let res = await ThemeService.getcmsPageSectionDropdown(
      slug,
      this.props.level
    );
    if (global.successStatus.includes(res.status)) {
      sectionList = res.data ? res.data : [];
      sectionListTotal = sectionList ? sectionList.length : 0;
    }
    this.setState({
      sectionList: sectionList,
      sectionListTotal: sectionListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCmsPageData(page_slug, section_slug) {
    this.setState({ showSpinner: true, loading: true });
    var cmsPageNameList = [];
    var cmsPageNameListTotal = 0;
    var resData = {};
    let res = "";
    if (this.props.level === 3) {
      res = await ThemeService.getcmsPageSectionData(
        "property_slug=" +
          this.props.propertySlug +
          "&page_slug=" +
          (page_slug ? page_slug : this.state.page_id) +
          "&section_slug=" +
          (section_slug ? section_slug : this.state.section_id) +
          "&refid=" +
          this.props.level
      );
    } else if (this.props.level === 2) {
      res = await ThemeService.getcmsPageSectionData(
        "brand_slug=" +
          this.props.brandSlug +
          "&page_slug=" +
          (page_slug ? page_slug : this.state.page_id) +
          "&section_slug=" +
          (section_slug ? section_slug : this.state.section_id) +
          "&refid=" +
          this.props.level
      );
    }  else if (this.props.level === 4) {
      res = await ThemeService.getcmsPageSectionData(
        "club_slug=" +
          this.props.clubSlug +
          "&page_slug=" +
          (page_slug ? page_slug : this.state.page_id) +
          "&section_slug=" +
          (section_slug ? section_slug : this.state.section_id) +
          "&refid=" +
          this.props.level
      );
    } else {
      res = await ThemeService.getcmsPageSectionData(
        "page_slug=" +
          (page_slug ? page_slug : this.state.page_id) +
          "&section_slug=" +
          (section_slug ? section_slug : this.state.section_id) +
          "&refid=" +
          this.props.level
      );
    }
    if (global.successStatus.includes(res.status)) {
      cmsPageNameList = res.data && res.data ? res.data : [];
      cmsPageNameListTotal = cmsPageNameList ? cmsPageNameList.length : 0;
    }
   
    this.setState(resData);
    this.setState({
      cmsPageNameList: cmsPageNameList,
      cmsPageNameListTotal: cmsPageNameListTotal,

    });
    this.setState({ showSpinner: false, loading: false });
  }

  checkValidation = () => {
    return true;
  }

  updateCmsPages = async (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {

    // if (this.checkValidation()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.props.propertySlug,
        page_slug: this.state.page_id,
        cmsPageNameList: this.state.cmsPageNameList,
        cmsPageSeoList: this.state.cmsPageSeoList,
        cmsPageSeoSlug: this.state.cmsPageSeoSlug,
        pageId : this.state.forcefull_checkin,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await ThemeService.updateCmsSectionDetails(
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });

        this.getCmsPageData();
        this.getCmsPageSeoData(this.props.page_slug);
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  handleColorSelection = (color) => {
    this.setState({ isColorPicker: false });
  };

  setColorPicker = () => {
    this.setState({ isColorPicker: true });
  };

  handleChangeComplete = (color) => {
    this.setState({ backgroundColor: color.hex });
  };

  changeSeoHandler = (event, mainItemIndex=0) => {
    let cmsPageSeoList1 = this.state.cmsPageSeoList;
    cmsPageSeoList1.META.[event.target.name] = event.target.value;
    this.setState({ cmsPageSeoList: cmsPageSeoList1 });
  };

  changeHandlerSeoImage = async (event) => {
        var name = event.target.name;

    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true, isSubmit: true });
      const fileData = new FormData();
      fileData.append(
        "cms_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await ThemeService.uploadCmsImage(fileData);
      if (global.successStatus.includes(res.status)) {
        
        //set latest image path in array

        let cmsPageSeoList1 = this.state.cmsPageSeoList;
        cmsPageSeoList1.META.[name] = res.data.filePath;
        this.setState({ cmsPageSeoList: cmsPageSeoList1 });
        this.setState({ showSpinner: false, isSubmit: false });

      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          isSubmit: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          isSubmit: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  changeHandler = (event, mainItemIndex, mainSectionIndex = "", mainSectionSubSectionsIndex= "") => {
    let cmsPageNameList1 = this.state.cmsPageNameList;
    if (mainSectionSubSectionsIndex !== "") {
      cmsPageNameList1[mainItemIndex].section_data[mainSectionIndex][mainSectionSubSectionsIndex][event.target.name] = event.target.value;
    } else {
      cmsPageNameList1[mainItemIndex].section_data[mainSectionIndex][event.target.name] = event.target.value;
    }
    this.setState({ cmsPageNameList: cmsPageNameList1 });
  };

  changeHandlerFFTC = (event) =>{
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? value : value;

    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
    });

  }

  changeHandlerImage = async (event) => {
    //api call for image upload and set in state
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true, isSubmit: true });
      const fileData = new FormData();
      fileData.append(
        "cms_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await ThemeService.uploadCmsImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          // backgroundImage: res.data.fileName,
          backgroundImage: res.data.filePath,
        });
        this.setState({ showSpinner: false, isSubmit: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          isSubmit: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          isSubmit: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  changeHandlerSectionImage = async (event, mainItemIndex, mainSectionIndex = "", mainSectionSubSectionsIndex="") => {
    //api call for image upload and set in state

    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true, isSubmit: true });
      const fileData = new FormData();
      fileData.append(
        "cms_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await ThemeService.uploadCmsImage(fileData);
      if (global.successStatus.includes(res.status)) {
        //set latest image path in array
        let cmsPageNameList1 = this.state.cmsPageNameList;

        if (mainSectionSubSectionsIndex !== "") {
          cmsPageNameList1[mainItemIndex].section_data[mainSectionIndex][mainSectionSubSectionsIndex]['url'] = res.data.filePath;
        } else {
          cmsPageNameList1[mainItemIndex].section_data[mainSectionIndex]["url"] = res.data.filePath;
        }
        this.setState({ cmsPageNameList: cmsPageNameList1 });

        this.setState({ showSpinner: false, isSubmit: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          isSubmit: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          isSubmit: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  handleChangePageList(value) {
    this.setState({
      section_id: "",
      selectedSectionList: [],
      cmsPageNameList: [],
      cmsPageNameListTotal: 0,
      backgroundColor: "",
      backgroundImage: "",
      detailSlug: "",
    });
    let page_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        page_id.push(item.value);
      });
    }
    if (value !== null) {
      this.getcmsPageSectionDropdown(value.value);
    }
    const city_id_value = value !== null ? value.value : [];
    this.setState({
      selectedPageList: value,
      page_id: city_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeSectionList(value) {
    let section_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        section_id.push(item.value);
      });
    }
    this.setState({ reference_img: value.reference_img });
    if (value !== null) {
      this.getCmsPageData("", value.value);
    }
    const city_id_value = value !== null ? value.value : [];
    this.setState({
      selectedSectionList: value,
      section_id: city_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  backToPages() {
    var redirectBtn = document.getElementById(
      "left-tabs-example-tab-PageSection"
    );
    redirectBtn.click();
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <div className="row dashboardRightcard background-none row-xs mg-t-15 pd-t-15 swipersectionBtns">
          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
            
            {global.userPermissions.checkPermission("brand-cms-update") && (
              <Button
                className="btn-success-outline-small mr-3 button-width"
                onClick={this.updateCmsPages}
              >
                {this.state.isSubmit ? global.loader : "Update"}
              </Button>
            )}
          </div>
          <div className="col-md-12">
            <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 mg-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>

            <div className="row col-md-12 m-0 pd-lg-r-15 pd-xs-r-0 d-none">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0">
                <label className="form-label text-left pd-b-5">
                  Pages
                  {/* <span className="text-danger">*</span> */}
                </label>
                <Select
                  styles={customStyles}
                  className="multiselect"
                  menuPlacement="auto"
                  isDisabled={
                    this.props.page_slug && this.props.section_slug
                      ? true
                      : false
                  }
                  defaultValue={
                    this.props.page_slug
                      ? [
                          {
                            label: this.props.page_name,
                            value: this.props.page_slug,
                          },
                        ]
                      : this.state.selectedPageList
                  }
                  options={this.state.pageList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangePageList(value)}

                  autoComplete="off"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
                
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                <label className="form-label text-left pd-b-5">
                  Sections
                </label>
                <Select
                  styles={customStyles}
                  className="multiselect text-capitalize"
                  menuPlacement="auto"
                  isDisabled={
                    this.props.page_slug && this.props.section_slug
                      ? true
                      : false
                  }
                  value={
                    this.props.section_slug
                      ? [
                          {
                            label: this.props.section_name,
                            value: this.props.section_slug,
                          },
                        ]
                      : this.state.selectedSectionList
                  }
                  options={this.state.sectionList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeSectionList(value)}
                  autoComplete="off"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
                
              </div>
            </div>

            {/* meta new content */}
            <div className="col-md-12 p-0 mg-b-30">
              <div className="row align-items-start pd-b-5 border-bottom-0 mg-t-15 pd-lg-r-45 pd-xs-r-15 pd-lg-r-30">
                <div className="col-lg-8 pd-b-5 border-bottom-0  pd-lg-l-15  mg-t-15 pd-lg-r-50 pd-xs-r-0 pd-xs-l-30 cmssticky">
                  <div className="cmsleftSection">

                    {/* Cms data start */}
                    <div className="row align-items-center pd-b-30 pd-xs-l-30 pd-lg-l-60 pd-t-0 border-bottom-0">
                      {/* main braket */}
                      {this.state.cmsPageNameListTotal > 0
                        ? this.state.cmsPageNameList.map(
                            (mainItem, mainItemIndex) => (
                              <>
                                <div
                                  className="row col-12 pd-l-0 border-bottom-0 pd-lg-l- pd-xs-r-0"
                                  key={mainItemIndex}
                                >
                                  <h3 className="col-12 pl-0 pd-b-5 background-head mg-t-0">
                                    {mainItem.section_name}
                                  </h3>
                                </div>
                                {mainItem.section_data &&
                                  mainItem.section_data.length > 0 &&
                                  mainItem.section_data.map(
                                    (mainSection, mainSectionIndex) => (
                                      <>
                                        {mainSection[0] ? (
                                          <>
                                            {mainSection.map(
                                              (
                                                mainSectionSubSectionsItem,
                                                mainSectionSubSectionsIndex
                                              ) => (
                                                <>
                                                  <div
                                                    className="row col-12 align-items-center pd-t-0 pd-b-10 pl-0 border-bottom-0 pr-0"
                                                    key={
                                                      mainSectionSubSectionsIndex
                                                    }
                                                  >
                                                    <div className="col-3 mg-t-5 mg-md-t-0 pl-0">
                                                      <label className="form-label mg-b-0 text-left text-capitalize">
                                                        {
                                                          mainSectionSubSectionsItem.label
                                                        }
                                                      </label>
                                                    </div>
                                                    <div className="col-9 mg-t-5 mg-md-t-0 pl-0 pr-0">
                                                      {mainSectionSubSectionsItem.type ===
                                                      "file" ? (
                                                        <div className="demo-avatar-group d-flex justify-content-left align-items-start flex-column">
                                                          <label>
                                                            <div className="az-img-user avatar-md online mg-b-8">
                                                              <img
                                                                src={
                                                                  mainSectionSubSectionsItem.url
                                                                }
                                                                alt=""
                                                                className="rounded-circle hand-cursor"
                                                              />
                                                            </div>
                                                            <Form.Control
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                this.changeHandlerSectionImage(
                                                                  event,
                                                                  mainItemIndex,
                                                                  mainSectionIndex,
                                                                  mainSectionSubSectionsIndex
                                                                )
                                                              }
                                                              type="file"
                                                              name="backgroundImage"
                                                              hidden
                                                              //  value={this.state.backgroundImage}
                                                            />
                                                          </label>
                                                        </div>
                                                      ) : mainSectionSubSectionsItem.type ===
                                                        "textarea" ? (
                                                        <Form.Control
                                                          onChange={(e) =>
                                                            this.changeHandler(
                                                              e,
                                                              mainItemIndex,
                                                              mainSectionIndex,
                                                              mainSectionSubSectionsIndex
                                                            )
                                                          }
                                                          type={
                                                            mainSectionSubSectionsItem.type
                                                          }
                                                          as="textarea"
                                                          name="text"
                                                          value={
                                                            mainSectionSubSectionsItem.text
                                                          }
                                                        />
                                                      ) : (
                                                        <Form.Control
                                                          onChange={(e) =>
                                                            this.changeHandler(
                                                              e,
                                                              mainItemIndex,
                                                              mainSectionIndex,
                                                              mainSectionSubSectionsIndex
                                                            )
                                                          }
                                                          type={
                                                            mainSectionSubSectionsItem.type
                                                          }
                                                          name="text"
                                                          value={
                                                            mainSectionSubSectionsItem.text
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                  </div>   
                                                </>

                                              )
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <div
                                              className="row col-12 align-items-center pd-t-0 pd-b-10 pl-0 border-bottom-0 pr-0"
                                              key={mainSectionIndex}
                                            >
                                              <div className="col-3 mg-t-5 mg-md-t-0 pl-0">
                                                <label className="form-label mg-b-0 text-left text-capitalize">
                                                  {mainSection.label}
                                                </label>
                                              </div>
                                              <div className="col-9 mg-t-5 mg-md-t-0 pl-0 pr-0">
                                                {mainSection.type === "file" ? (
                                                  <div className="demo-avatar-group d-flex justify-content-left align-items-start flex-column">
                                                    <label>
                                                      <div className="az-img-user avatar-md online mg-b-8">
                                                        <img
                                                          src={mainSection.url}
                                                          alt=""
                                                          className="rounded-circle hand-cursor"
                                                        />
                                                      </div>
                                                      <Form.Control
                                                        onChange={(event) =>
                                                          this.changeHandlerSectionImage(
                                                            event,
                                                            mainItemIndex,
                                                            mainSectionIndex
                                                          )
                                                        }
                                                        type="file"
                                                        name="backgroundImage"
                                                        hidden
                                                        //  value={this.state.backgroundImage}
                                                      />
                                                    </label>
                                                  </div>
                                                ) : mainSection.type ===
                                                  "textarea" ? (
                                                  <Form.Control
                                                    onChange={(e) =>
                                                      this.changeHandler(
                                                        e,
                                                        mainItemIndex,
                                                        mainSectionIndex
                                                      )
                                                    }
                                                    type={mainSection.type}
                                                    as="textarea"
                                                    name="text"
                                                    value={mainSection.text}
                                                  />
                                                ): mainSection.type ===
                                                "ckeditor" ? (
                                                  <CKEditor
                                                  editor={ClassicEditor}
                                                  config={{
                                                    removePlugins: [
                                                      "EasyImage",
                                                      "ImageUpload",
                                                      "MediaEmbed",
                                                      "Table",
                                                    ],
                                                  }}
                                                  name="text"
                                                  minHeight="50px"
                                                  data={mainSection.text}
                                                  onReady={(editor) => {
                                                    editor.editing.view.change((writer) => {
                                                      writer.setStyle(
                                                        "height",
                                                        "200px",
                                                        editor.editing.view.document.getRoot()
                                                      );
                                                    });
                                                  }}
                                                  onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    this.changeHandler({
                                                      target: {
                                                        type: "ckeditor",
                                                        name: "text",
                                                        value: data,
                                                      },
                                                    },
                                                    mainItemIndex,
                                                    mainSectionIndex);
                                                  }}
                                                />
                                              ) : (
                                                  <Form.Control
                                                    onChange={(e) =>
                                                      this.changeHandler(
                                                        e,
                                                        mainItemIndex,
                                                        mainSectionIndex
                                                      )
                                                    }
                                                    type={mainSection.type}
                                                    name="text"
                                                    value={mainSection.text}
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </>
                                      /* section return */
                                    )
                                  )}

                                { mainItem.cms_page_id === 9 && (
                                   <Form.Check
                                    onChange={this.changeHandlerFFTC}
                                    name="forcefull_checkin"
                                    checked={this.state.forcefull_checkin}
                                    type={"checkbox"}
                                    id="textmessagecheckbox"
                                    label={"Force Accept "}
                                    className={"ckbox mg-r-10"}
                                  />
                               
                                )
                                }


                              </>
                            )
                          )
                        :
                        //  "No section data found."
                         "Loading..."
                         }{" "}
                      {/* main bracket */}
                    </div>
                      {/* Cms data end */}

                    <div className="col-lg-12 pd-l-30 pd-lg-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15">
                      <h3 className="mg-l-0 background-head">SEO</h3>
                    </div>
                    <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-15 pd-r-0 mg-l-0 mg-r-0 ">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0  pd-xs-l-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Title
                          <span className="optional">(Optional)</span>
                        </label>

                        <Form.Control
                          type="text"
                          name="title"
                          onChange={(e) => this.changeSeoHandler(e)}
                          value={this.state.cmsPageSeoList.META ? this.state.cmsPageSeoList.META.title  : ""}
                          // required
                        />
                        
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Favicon <span className="optional">(Optional)</span>
                        </label>

                        <div className="fileUpload d-flex flex-wrap justify-content-between">
                          <span>
                            <label className="hand-cursor">
                              <input
                                onChange={(e) => this.changeHandlerSeoImage(e)}
                                type="file"
                                name="favicon_url"
                                // required
                              />
                              
                                <img src={this.state.cmsPageSeoList.META ? this.state.cmsPageSeoList.META.favicon_url  : hostImg} alt="" />
                              {/* )} */}
                            </label>
                          </span>
                        </div>
                      
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Keywords
                          <span className="optional">(Optional)</span>
                        </label>

                        <InputGroup>
                          <Form.Control
                            onChange={(e) => this.changeSeoHandler(e)}
                            name="keywords"
                            value={this.state.cmsPageSeoList.META ? this.state.cmsPageSeoList.META.keywords : ""}
                            className="form-control max_width_100"
                            id="keywords"
                            as="textarea"
                            rows={global.textareaRowLength}
                            
                          />
                        </InputGroup>
                      </div>
                      <div className="col-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                        <label className="form-label text-left pd-b-5 w-100">
                          Description
                          <span className="optional">(Optional)</span>
                        </label>
                        <InputGroup>
                          <Form.Control
                            onChange={(e) => this.changeSeoHandler(e)}
                            name="description"
                            value={this.state.cmsPageSeoList.META ? this.state.cmsPageSeoList.META.description : ""}
                            className="form-control max_width_100"
                            id="description"
                            as="textarea"
                            rows={global.textareaRowLength}
                            // required
                            // placeholder="Username"
                          />
                        </InputGroup>
                  
                      </div>
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15">
                        <h3 className="mg-l-0 background-head">
                          Facebook Meta
                        </h3>
                      </div>
                      <div className="col-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                        <label className="form-label text-left pd-b-5 w-100">
                          Meta tag
                          <span className="optional">(Optional)</span>
                        </label>
                        <InputGroup>
                          <Form.Control
                            onChange={(e) => this.changeSeoHandler(e)}
                            name="facebook_meta"
                            value={this.state.cmsPageSeoList.META ? this.state.cmsPageSeoList.META.facebook_meta :  ""}
                            className="form-control max_width_100"
                            id="facebook_meta"
                            as="textarea"
                            rows="2"
                            // placeholder="Username"
                            // required
                          />
                        </InputGroup>
                        
                      </div>
                      <div className="col-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                        <label className="form-label text-left pd-b-5 w-100">
                          Pixel Code
                          <span className="optional">(Optional)</span>
                        </label>
                        <InputGroup>
                          <Form.Control
                            onChange={(e) => this.changeSeoHandler(e)}
                            name="facebook_pixel_code"
                            value={this.state.cmsPageSeoList.META ? this.state.cmsPageSeoList.META.facebook_pixel_code : ""}
                            className="form-control max_width_100"
                            id="facebook_pixel_code"
                            as="textarea"
                            rows={global.textareaRowLength}
                            // placeholder="Username"
                            // required
                          />
                        </InputGroup>
                        
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Page Reference Image
                        </label>

                        <div className="fileUpload d-flex flex-wrap justify-content-between">
                          <span>
                            <label className="hand-cursor">
                              <input
                                onChange={(e) => this.changeHandlerSeoImage(e)}
                                type="file"
                                name="page_ref_image"
                                // required
                              />
                              
                                <img src={this.state.cmshomepreview ? this.state.cmshomepreview   : hostImg} alt="" />
                              {/* )} */}
                            </label>
                          </span>
                        </div>
                        
                      </div>
                    </div>

                    

                    {this.state.backgroundImage ? (
                      <div className="row align-items-center pd-b-30 pd-l-30 pd-t-30 border-bottom-0">
                        <div className="col-3 mg-t-5 mg-md-t-0 pl-0">
                          <label className="form-label text-left pd-b-5">
                            Pick Background Image for Section
                          </label>
                        </div>

                        <div className="demo-avatar-group d-flex justify-content-left align-items-start flex-column">
                          <div className="az-img-user avatar-md online mg-b-8">
                            <figure>
                              <img
                                src={this.state.backgroundImage}
                                alt=""
                                className="rounded-circle hand-cursor"
                              />
                              <Form.Control
                                onChange={(event) =>
                                  this.changeHandlerImage(event)
                                }
                                type="file"
                                name="backgroundImage"
                                hidden
                                // value={this.state.backgroundImage}
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-lg-4 pd-lg-l-0 pd-r-0 pd-xs-l-30 mg-t-15">
                  <div className="col-lg-12 p-0">
                    <h3 className="mg-l-0 background-head">Preview</h3>
                  </div>
                  <div className="cmsrightSection">
                    <a href={this.state.cmshomepreview} target="_blank" rel="noreferrer" >
                    <img
                      src={this.state.cmshomepreview}
                      className="w-100"
                      useMap="#imagemap"
                    />
                    </a>
                    <button className="cmspreviewbtn">
                      <div className="cmshomeheader"></div>
                    </button>
                    <button className="cmspreviewbtn">
                      <div className="cmshomebanner"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ThemePagesSectionSetup;
