/////////////////////////////////////////////////////
//     							                   //
//  Program: MRO.jsx                             //
//  Application: Property                          //
//  Option: List all MRO           //
//  Developer: Ashish Kumar					   //
//  Date: 2022-05-30                               //
//                                                 //
/////////////////////////////////////////////////////
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import MROService from "../../services/MROService";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal, InputGroup, Form } from "react-bootstrap";
import Moment from "moment";
import MROCategoryService from "../../services/MROCategoryService";
import DatePicker from "react-datepicker";
import Alerts from "../common/Alerts"; 
import pluswIcon from "./../../assets/images/plus.svg";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Select from "react-select";
import PropertyStaffService from "../../services/PropertyStaffService";
import UnitsService from "../../services/UnitsService";
import queryString from "query-string";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertiesMaintenanceSidebar from "../common/AllPropertiesMaintenanceSidebar";
import MaintenanceRequestSidebar from "../common/MaintenanceRequestSidebar";

let queryParams = queryString.parse(window.location.search);
export class Mro extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      isSearch: global.isSearch,
      module_search: "",
      is_search: 0,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      records: [],
      user_slug: "",
      userList: [],
      selectedUserList: [],
      mro_category_slug: "",
      mroCategory: [],
      selectedMroCategory: [],
      priority_type: "",
      priorityType: [],
      due_date: "",
      status_id: "",
      statusList: [],
      selectedStatusList: [],
      selectedAllStaffList: [],
      newResidentList: [],
      selectedResidentList: [],
      selectPriorityList: [],
      selectedPriorityList: [],
      selectStatusList: [],
      mroTabNumber: localStorage.getItem("mro_tab_number")
        ? parseInt(localStorage.getItem("mro_tab_number"))
        : 1,
      property_slug: this.props.match.params.propertySlug,
      config: {
        sort: {
          column: "mro_number",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "unit_no",
          text: "Unit",
          className: "unit_no hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.unit
              ? record.unit.unit_name
                ? record.unit.unit_name
                : record.unit.unit_number
              : "";
          },
        },
        {
          key: "full_name",
          text: "Assigned to",
          className: "full_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.staff ? record.staff.staff_contact_name : "";
          },
        },
        {
          key: "mro_number",
          text: "Request No.",
          className: "mro_number hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.mro_number ? record.mro_number : "";
          },
        },
        {
          key: "title",
          text: "Issue",
          className: "title hand-cursor",
          sortable: true,
          cell: (record) => {
            const noteString = record.title ? record.title : "";
            const maxString = 50;
            return noteString.length > maxString
              ? noteString.substr(0, maxString - 1) + " ..."
              : noteString;
          },
        },
        {
          key: "resident_id",
          text: "Reported By",
          className: "resident_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.resident
              ? record.resident.first_name + " " + record.resident.last_name
              : "";
          },
        },
        {
          key: "priority_type",
          text: "Priority",
          className: "priority_type hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.priority_type_name ? record.priority_type_name : "";
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.status_display ? record.status_display : "";
          },
        },
        {
          key: "created_at",
          text: "Create Date",
          className: "created_at hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.created_at
              ? Moment(record.created_at).format(global.dateFormat)
              : "";
          },
        },
        {
          key: "due_date",
          text: "Due Date",
          className: "due_date hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.due_date
              ? Moment(record.due_date).format(global.dateFormat)
              : "";
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    // this.filterToggle = this.filterToggle.bind(this);
    this.resetData = this.resetData.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  resetDate() {
    this.setState({ from_date: "", to_date: "" });
    this.getData();
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      this.getData();
      this.getStaffList();
      this.getMROCategoryList();
      this.getResidentData();
      this.getPriorityList();
      this.getStatusList();
    }
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.getData();
      this.getStaffList();
      this.getMROCategoryList();
      this.getResidentData();
      this.getPriorityList();
      this.getStatusList();
    }, 200);
    this.setState({
      selectedUnitList: [],
      module_search: [],
      is_search: 0,
    });
  }

  async getData(queryString = "") {
    this.setState({ loading: true });
    var list = [];
    var totalRecords = 0;

    let res = await MROService.getMROList(
      "property_slug=" +
      (this.state.property_slug ? this.state.property_slug : "") +
        (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = list ? list.length : 0;
    }
    this.setState({
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  async getStaffList() {
    this.setState({ showSpinner: true, loading: true });
    var allStaffList = [];
    var allStaffListTotal = 0;

    let res = await PropertyStaffService.getPropertyStaff(
      "is_dropdown=1&" + "property_slug=" + (this.state.property_slug ? this.state.property_slug : "")
    );
    if (global.successStatus.includes(res.status)) {
      allStaffList = res.data ? res.data : [];
      allStaffListTotal = allStaffList ? allStaffList.length : 0;
    }
    const newAllStaffList =
      allStaffListTotal > 0
        ? allStaffList.map(({ slug, staff_contact_name }) => ({
            value: slug,
            label: staff_contact_name,
          }))
        : [];
    this.setState({
      allStaffList: allStaffList,
      allStaffListTotal: allStaffListTotal,
      newAllStaffList: newAllStaffList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  // Get Maintenance Category List
  async getMROCategoryList(queryString = "") {
    this.setState({ loading: true });
    var mroCategoryList = [];
    var mroCategoryListTotal = 0;
    let res = await MROCategoryService.getMROCategory(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      mroCategoryList = res.data ? res.data : [];
      mroCategoryListTotal = mroCategoryList ? mroCategoryList.length : 0;
    }
    const newMroCategoryList =
      mroCategoryListTotal > 0
        ? mroCategoryList.map(({ slug, mro_category_name }) => ({
            value: slug,
            label: mro_category_name,
          }))
        : [];
    this.setState({
      mroCategoryList: mroCategoryList,
      mroCategoryListTotal: mroCategoryListTotal,
      newMroCategoryList: newMroCategoryList,
    });
    this.setState({ loading: false });
  }

  async getResidentData(queryString = "") {
    var residentList = [];
    var residentListTotal = 0;

    let res = await MROService.getMROReporterList(
      "property_slug=" + (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      residentList = res.data ? res.data : [];
      residentListTotal = residentList ? residentList.length : 0;
    }
    console.log(residentList);
    const newResidentList =
      residentListTotal > 0
        ? residentList.map(({ slug, first_name , last_name }) => ({
            value: slug,
            label: (first_name != null && first_name !== undefined ? first_name : '') + " " + (last_name != null  && last_name !== undefined ? last_name : ""),
          }))
        : [];
    this.setState({
      residentList: residentList,
      residentListTotal: residentListTotal,
      newResidentList: newResidentList,
    });
  }

  async getPriorityList() {
    const selectPriorityList = [
      { value: "1", label: "High" },
      { value: "2", label: "Medium" },
      { value: "3", label: "Low" },
    ];
    this.setState({ selectPriorityList: selectPriorityList });
  }

  async getStatusList() {
    const selectStatusList = [
      { value: "1", label: "Completed" },
      { value: "2", label: "In Progress" },
      { value: "3", label: "Pending" },
      // { value: "4", label: "Completed" }
    ];
    this.setState({ selectStatusList: selectStatusList });
  }

  async getMROData() {
    this.setState({ loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await MROService.getMROBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData.mroSlug = res.data ? res.data.slug : "";
        resData.title = res.data ? res.data.title : "";
        resData.note = res.data ? res.data.note : "";
        resData.mro_category_slug = res.data.mro_category
          ? res.data.mro_category.slug
          : [];
        resData.zone_type = res.data.zone_type ? res.data.zone_type.slug : [];
        resData.priority_type = res.data ? res.data.priority_type : [];
        resData.due_date = res.data.due_date
          ? Moment(res.data.due_date).toDate("MM/dd/YY")
          : null;

        resData.technician = res.data.staff ? res.data.staff.slug : [];
        resData.status_id = res.data ? res.data.status_id : [];
        resData.unit_slug = res.data.unit ? res.data.unit.slug : "";
        resData.resident_slug = res.data.resident ? res.data.resident.slug : "";
        resData.gallery_img = res.data.images ? res.data.images : "";
        resData.setRadioValue = res.data.is_notification
          ? parseInt(res.data.is_notification)
          : "";
        resData.is_notification = res.data.is_notification
          ? res.data.is_notification
          : "";
        resData.detail_notfication = res.data.detail_notfication
          ? res.data.detail_notfication
          : "";
        var selectedResidentList = [];
        this.setState({ mro_number: res.data.mro_number });
        selectedResidentList = res.data.resident
          ? [
              {
                value: res.data.resident.slug,
                label:
                  res.data.resident.first_name +
                  " " +
                  res.data.resident.last_name,
              },
            ]
          : "";
        var selectedUnitList = [];
        selectedUnitList = res.data.unit
          ? [
              {
                value: res.data.unit.slug,
                label: res.data.unit.unit_name
                  ? res.data.unit.unit_name
                  : res.data.unit.unit_number,
              },
            ]
          : "";
        var selectedMroCategoryList = [];
        selectedMroCategoryList = res.data.mro_category
          ? [
              {
                value: res.data.mro_category.slug,
                label: res.data.mro_category.mro_category_name,
              },
            ]
          : [];
        var selectedZoneTypeList = [];
        selectedZoneTypeList = res.data.zone_type
          ? [
              {
                value: res.data.zone_type.slug,
                label: res.data.zone_type.zone_type_name,
              },
            ]
          : [];

        var selectedPriorityList = [];
        this.state.selectPriorityList.forEach((item, i) => {
          if (parseInt(item.value) == parseInt(res.data.priority_type)) {
            selectedPriorityList = [
              {
                value: item.value,
                label: item.label,
              },
            ];
            return false;
          }
        });

        var selectedStatusList = [];
        this.state.selectStatusList.forEach((item, i) => {
          if (parseInt(item.value) === parseInt(res.data.status_id)) {
            selectedStatusList = [
              {
                value: item.value,
                label: item.label,
              },
            ];
            return false;
          }
        });

        var selectedAllStaffList = [];
        selectedAllStaffList = res.data.staff
          ? [
              {
                value: res.data.staff.slug,
                label: res.data.staff.staff_contact_name,
              },
            ]
          : [];
      }
      this.setState(resData);

      this.setState({
        selectedMroCategoryList: selectedMroCategoryList,
        selectedZoneTypeList: selectedZoneTypeList,
        selectedPriorityList: selectedPriorityList,
        selectedStatusList: selectedStatusList,
        selectedAllStaffList: selectedAllStaffList,
        selectedUnitList: selectedUnitList,
        selectedResidentList: selectedResidentList,
        //imagesArray: selectedResidentList,
      });
      this.setState({ input: resData });
      this.defaultData = resData;
    }

    this.setState({ zoneTypeList: global.zoneTypeList });
    this.setState({ priorityType: global.priorityType });
    this.setState({ statusList: global.mroStatusList });
    this.setState({ loading: false });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getUnitData();
      // this.getResidentData();
      this.setState({ isSearch: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  // Technician/User Change Handler
  handleChangeUserList(value) {
    let user_slug = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        user_slug.push(item.value);
      });
    }
    this.setState({
      selectedUserList: value,
      user_slug: value.value,
    });
  }

  // Maintenance Category Change Handler
  handleChangeMroCategory(value) {
    let mro_category_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        mro_category_slug.push(item.value);
      });
    }
    this.setState({
      selectedMroCategory: value,
      mro_category_slug: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  // Priority  Change Handler
  handleChangePriority(value) {
    let priority_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        priority_type.push(item.value);
      });
    }
    this.setState({
      selectedPriorityType: value,
      priority_type: value.value,
    });
  }

  // Status Change Handler
  handleChangeStatus(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
  }

  handleChangeStaffList(value) {
    let technician = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        technician.push(item.value);
      });
    }
    const technician_value = value !== null ? value.value : [];
    this.setState({
      selectedAllStaffList: value,
      technician: technician_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeResidentList(value) {
    let resident_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        resident_slug.push(item.value);
      });
    }
    const resident_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedResidentList: value,
      resident_slug: resident_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangePriorityList(value) {
    let priority_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        priority_type.push(item.value);
      });
    }
    const priority_type_value = value !== null ? value.value : [];
    this.setState({
      selectedPriorityList: value,
      priority_type: priority_type_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeStatusList(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    const status_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStatusList: value,
      status_id: status_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeUnitList(value) {
    // console.log(value.label);
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_number: value.label,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  customFilter() {
    let date = "";
    if (this.state.due_date) {
      date = Moment(this.state.due_date).format("YYYY-MM-DD");
    }
    let queryString =
      "user_slug=" +
      this.state.user_slug +
      "&is_filter=1&" +
      "mro_category_slug=" +
      this.state.mro_category_slug +
      "&priority_type=" +
      this.state.priority_type +
      "&status_id=" +
      this.state.status_id +
      "&due_date=" +
      date;
    this.getData(queryString);
  }

  resetData() {
    this.setState({
      due_date: "",
      selectedUserList: [],
      selectedMroCategory: [],
      selectedPriorityType: [],
      selectedStatusList: [],
      user_slug: "",
      mro_category_slug: "",
      priority_type: "",
      status_id: "",
    });
    this.getData();
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          } else if (key === "page_size") {
            newKey = "per_page";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {
    global.userPermissions.checkPermission("maintenance-requests-update") &&
      this.props.history.push(
        this.props.match.params.propertySlug !== undefined
          ? "mros/" + data.slug
          : (this.state.property_slug ? "/mros/view/" +
              this.state.property_slug +
              "/" +
              data.slug : "/mros/view/" +
              data.property_slug +
              "/" +
              data.slug)
      );   
  };

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      maintenance_created_date: "",
      maintenance_due_date: "",
      unit_slug: "",
      technician: "",
      resident_slug: "",
      priority_type: "",
      status_id: "",
      request_number: "",
      title: "",
      selectedUnitList: [],
      selectedTypeList: [],
      selectedResidentList: [],
    });
    this.setState({ module_search: "" });
  }

  filterData() {
    // console.log(this.state.selectedStatusList.label);
    let createDate = this.state.maintenance_created_date
      ? Moment(this.state.maintenance_created_date).format("YYYY-MM-DD")
      : "";
    let dueDate = this.state.maintenance_due_date
      ? Moment(this.state.maintenance_due_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "maintenance_created_date=" +
      createDate +
      "&maintenance_due_date=" +
      dueDate +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&assign_to=" +
      (this.state.technician ? this.state.technician : "") +
      "&reported_by=" +
      (this.state.resident_slug ? this.state.resident_slug : "") +
      "&priority=" +
      (this.state.priority_type ? this.state.priority_type : "") +
      "&status=" +
      (this.state.status_id ? this.state.status_id : "") +
      "&request_number=" +
      (this.state.request_number ? this.state.request_number : "") +
      "&title=" +
      (this.state.title ? this.state.title : "") +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.maintenance_created_date
      ? "Created Date - " +
        Moment(this.state.maintenance_created_date).format(global.dateFormat)
      : "";
    moduleSearchString = this.state.maintenance_due_date
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Due Date - " +
          Moment(this.state.maintenance_due_date).format(global.dateFormat)
        : " Due Date - " +
          Moment(this.state.maintenance_due_date).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Unit - " +
          this.state.selectedUnitList.label
        : " Unit - " + this.state.selectedUnitList.label
      : moduleSearchString;
    moduleSearchString = this.state.technician
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Assign To - " +
          this.state.selectedAllStaffList.label
        : " Assign To - " + this.state.selectedAllStaffList.label
      : moduleSearchString;
    // moduleSearchString = this.state.technician ? (moduleSearchString ? (moduleSearchString + " &" + " assign to - " + (this.state.selectedAllStaffList.label ? "assign to " : this.state.selectedAllStaffList.label)) : (" assign to - " + (this.state.selectedAllStaffList.label ? this.state.selectedAllStaffList.label : ""))) : moduleSearchString
    moduleSearchString = this.state.resident_slug
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Reported By - " +
          this.state.selectedResidentList.label
        : " Reported By - " + this.state.selectedResidentList.label
      : moduleSearchString;
    moduleSearchString = this.state.priority_type
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Priority - " +
          this.state.selectedPriorityList.label
        : " Priority - " + this.state.selectedPriorityList.label
      : moduleSearchString;
    moduleSearchString = this.state.status_id
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Status - " +
          this.state.selectedStatusList.label
        : " Status - " + this.state.selectedStatusList.label
      : moduleSearchString;
    moduleSearchString = this.state.request_number
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Request Number - " +
          this.state.request_number
        : " Request Number - " + this.state.request_number
      : moduleSearchString;
    moduleSearchString = this.state.title
      ? moduleSearchString
        ? moduleSearchString + " ," + " Title - " + this.state.title
        : "Title - " + this.state.title
      : moduleSearchString;

    if (
      this.state.maintenance_created_date ||
      this.state.maintenance_due_date ||
      this.state.unit_slug ||
      this.state.technician ||
      this.state.resident_slug ||
      this.state.priority_type ||
      this.state.status_id ||
      this.state.request_number ||
      this.state.title
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(',') });
      this.getData(queryString);
    }
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Maintenance Requests";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0s">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <MaintenanceRequestSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertiesMaintenanceSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {global.userPermissions.checkPermission(
                        "maintenance-requests-add"
                      ) && this.state.property_slug ? (
                        <Link
                          to={
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                this.state.property_slug +
                                "/mros/add"
                              : "/mros/add/" +
                                this.state.property_slug
                          }
                        >
                          <button className="btn-success-outline-small ">
                            <img src={pluswIcon} alt="" /> Add
                          </button>
                        </Link>
                      ) : (
                        ""
                      )}
                      <button
                        onClick={this.searchToggle}
                        className="btn-success-outline-small ml-3"
                      >
                        <img src={filterIcon} alt="filterIcon" />{" "}
                        {this.state.isSearch === 1 ? "Search" : "Search"}
                      </button>
                    </div>
                  </div>
                </div>
                {this.props.match.params.propertySlug === undefined ? (
                    <>
                    <div className="row pd-lg-l-15 pd-xs-l-15">
                      <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                    <OutSidePropertySelectBox
                      propertySlug={this.getPropertySlug.bind(this)}
                      outSidePropertySlug={this.state.outSidePropertySlug}
                    />
                  </div>
                  <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                    {queryParams.isGlobal === "1" ? (
                            <>
                              <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                {"Search applied on: " +
                                  queryParams.global_col_label +
                                  " - " +
                                  queryParams.global_search}
                                <button
                                  onClick={this.clearFilter}
                                  className="mg-l-15 btn-success-outline-small"
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                ) : (
                  ""
                )}
                          {this.state.is_search === 1 ? (
                            <>
                             <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                              <div className="custm-filter">
                                  <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                                  <div className="filter_bg_cust">
                                    {
                                      this.state.module_search.map((item, i) => {
                                        return (
                                          <span key={i}>{item}</span>
                                        )
                                      })
                                    }
                                    <button
                                      onClick={this.clearFilter}
                                      className="btn-fliter-clear"
                                    >Clear</button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                  </div>
                  </div>
                  </>
                ) : (
                  <div className="col-xxl-12 pd-r-15 pd-l-15">
                    {queryParams.isGlobal === "1" ? (
                            <>
                              <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                {"Search applied on: " +
                                  queryParams.global_col_label +
                                  " - " +
                                  queryParams.global_search}
                                <button
                                  onClick={this.clearFilter}
                                  className="mg-l-15 btn-success-outline-small"
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.is_search === 1 ? (
                            <>
                             <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                              <div className="custm-filter">
                                  <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                                  <div className="filter_bg_cust">
                                    {
                                      this.state.module_search.map((item, i) => {
                                        return (
                                          <span key={i}>{item}</span>
                                        )
                                      })
                                    }
                                    <button
                                      onClick={this.clearFilter}
                                      className="btn-fliter-clear"
                                    >Clear</button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                  </div>
                )}
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-l-30 pd-r-30">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.state.showFilter ? (
                      <>
                        <Row className="align-items-end order_form col-md-12 mb-3 ml-0 mr-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                          <Col
                            md={4}
                            lg={4}
                            xl={4}
                            className="mb-md-0 mb-3 pl-3 pr-0"
                          >
                            <label className="form-label text-left pd-b-5">
                              From
                            </label>
                            <DatePicker
                              className="form-control max_width_100"
                              scrollableYearDropdown
                              showMonthDropdown
                              showYearDropdown
                              disabledKeyboardNavigation
                              strictParsing
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="from_date"
                              maxDate={this.state.to_date}
                              //placeholderText="Start Date"
                              dateFormat="MM/dd/yy"
                              selected={this.state.from_date}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "from_date",
                                    value: data,
                                  },
                                })
                              }
                            />
                          </Col>
                          <Col
                            md={4}
                            lg={4}
                            xl={4}
                            className="mb-md-0 mb-3 pl-3 pr-0"
                          >
                            <label className="form-label text-left pd-b-5">
                              To
                            </label>
                            <DatePicker
                              className="form-control max_width_100"
                              scrollableYearDropdown
                              showMonthDropdown
                              showYearDropdown
                              disabledKeyboardNavigation
                              strictParsing
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="to_date"
                              minDate={this.state.from_date}
                              //placeholderText="Start Date"
                              dateFormat="MM/dd/yy"
                              selected={this.state.to_date}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "to_date",
                                    value: data,
                                  },
                                })
                              }
                            />
                          </Col>

                          <Col md={4} lg={4} xl={4} className="mt-0">
                            <Row className="d-flex justify-content-end">
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-0 mg-r-15"
                              >
                                Apply
                              </button>
                              <button
                                onClick={this.resetDate}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.total_record !== 0 ||
                    this.state.filter_value !== "" ? (
                      <div className="mg-t-18 brandList pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-0">
                        <div className="pd-lg-l-0 pd-xs-l-15 pd-lg-r-15 pd-xs-r-0">
                          <ReactDatatable
                            className="table mg-b-30 pd-b-2 add-no-wrap"
                            config={
                              this.state.total_record > global.page_size
                                ? this.state.config
                                : this.state.config
                            }
                            records={this.state.records}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            // loading={this.state.loading}
                            total_record={this.state.total_record}
                            onChange={this.tableChangeHandler}
                            onRowClicked={this.rowClickedHandler}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                        {/* {queryParams.isGlobal === "1" ? (
                          <>
                            <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                              {"Search applied on: " +
                                queryParams.global_col_label +
                                " - " +
                                queryParams.global_search}
                              <button
                                onClick={this.clearFilter}
                                className="mg-l-15 btn-success-outline-small"
                              >
                                Clear
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.is_search === 1 ? (
                          <>
                            <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                              <div className="custm-filter">
                                <div> <label className="form-label text-left pd-b-5 mb-0">Search Applied On</label></div>
                                <div className="filter_bg_cust">
                                  {
                                    this.state.module_search.map((item, i) => {
                                      return (
                                        <span key={i}>{item}</span>
                                      )
                                    })
                                  }
                              <button
                                onClick={this.clearFilter}
                                    className="btn-fliter-clear"
                                  >Clear</button>
                            </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )} */}
                        <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                          <ReactDatatable
                            className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100"
                            config={
                              this.state.total_record > global.page_size
                                ? this.state.config
                                : this.state.config
                            }
                            records={this.state.records}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            // loading={this.state.loading}
                            total_record={this.state.total_record}
                            onChange={this.tableChangeHandler}
                            onRowClicked={this.rowClickedHandler}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* Maintenance Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Maintenance</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label> Assign to </label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      name="full_name"
                      value={this.state.selectedAllStaffList}
                      options={this.state.newAllStaffList}
                      onChange={(value) => this.handleChangeStaffList(value)}
                      defaultValue={this.state.selectedAllStaffList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Reported By</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      name="resident_id"
                      value={this.state.selectedResidentList}
                      options={this.state.newResidentList}
                      onChange={(value) => this.handleChangeResidentList(value)}
                      defaultValue={this.state.selectedResidentList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Priority</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      name="priority_type"
                      value={this.state.selectedPriorityList}
                      options={this.state.selectPriorityList}
                      onChange={(value) => this.handleChangePriorityList(value)}
                      defaultValue={this.state.selectedPriorityList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Request Number</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="request_number"
                      value={this.state.request_number}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Issue</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      type="text"
                      onChange={this.changeHandler}
                      name="title"
                      as="textarea"
                      value={this.state.title}
                      maxLength="500"
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Status</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      name="status_id"
                      value={this.state.selectedStatusList}
                      options={this.state.selectStatusList}
                      onChange={(value) => this.handleChangeStatusList(value)}
                      defaultValue={this.state.selectedStatusList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Create Date</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="maintenance_created_date"
                      selected={this.state.maintenance_created_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "maintenance_created_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Due Date</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="maintenance_due_date"
                      selected={this.state.maintenance_due_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "maintenance_due_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default Mro;
