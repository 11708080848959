////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Info.jsx                                     //
//  Application: Info                                     //
//  Option: For view Info details                         //
//  Developer: Ashish Kumar 				                      //
//  Date: 2022-02-09                                     //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
// import basicRoomimg from "./../../assets/images/basic-prop-img.jpg";
import LeasingService from "../../services/LeasingService";
import PetPolicyService from "../../services/PetPolicyService";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import Moment from "moment";
import hostImg from "./../../assets/images/profile-nogender.png";

export class Info extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bed_type_name: "",
      balcony_name: "",
      toilet_name: "",
      bathroom: "",
      descShow: 0,
      descShow2: 0,
      status_id: "",
      multiList: [],
      multiListTotal: 0,
    };
    this.deleteListing = this.deleteListing.bind(this);
    this.opendeleteListingModal = this.opendeleteListingModal.bind(this);
    this.closedeleteListingModal = this.closedeleteListingModal.bind(this);
  }

  opendeleteListingModal(slug) {
    this.setState({ deleteListingModal: true, slug: slug });
  }

  closedeleteListingModal() {
    this.setState({ deleteListingModal: false, slug: "" });
  }

  async deleteListing(slug) {
    this.setState({ showSpinner: true });
    let res = await LeasingService.deleteLeasing(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteListingModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          (window.location =
            this.props.propPropertySlug !== undefined
              ? "/properties/view/" + this.props.propertySlug + "/leasing"
              : "/leasing"),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteListingModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async componentDidMount() {
    if (this.props.listing_slug !== undefined) {
      var resData = {};
      let res = await LeasingService.getLeasing(this.props.listing_slug);

      if (global.successStatus.includes(res.status)) {
        resData.total_tower_count = res.data.total_tower_count
          ? res.data.total_tower_count
          : "";
        resData.aka_name = res.data.unit ? res.data.unit.tower.aka_name : "";
        resData.unit_number = res.data.unit ? res.data.unit.unit_number : "";
        resData.unit_name = res.data.unit ? res.data.unit.unit_name : "";

        resData.lease_start_date = res.data.lease_start_date
          ? Moment(res.data.lease_start_date, "MM/DD/YYYY").format(
              global.dateFormat
            )
          : null;
        resData.lease_end_date = res.data.lease_end_date
          ? Moment(res.data.lease_end_date, "MM/DD/YYYY").format(
              global.dateFormat
            )
          : null;
        resData.lease_signed_date = res.data.lease_signed_date
          ? Moment(res.data.lease_signed_date, "MM/DD/YYYY").format(
              global.dateFormat
            )
          : null;
        resData.rent_start_date = res.data.rent_start_date
          ? Moment(res.data.rent_start_date, "MM/DD/YYYY").format(
              global.dateFormat
            )
          : null;

        resData.roll_over = res.data.roll_over === 1 ? true : false;
        resData.type = res.data.type;
        resData.monthly_rent = res.data.monthly_rent
          ? parseFloat(res.data.monthly_rent)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.deposit = res.data.deposit
          ? parseFloat(res.data.deposit)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";

        resData.resident_photo_thumb_url = res.data.resident_user
          ? res.data.resident_user.profile_thumb_url
          : "";
        resData.imageUrl = res.data.resident_user
          ? res.data.resident_user.profile_thumb_url
          : "";
        resData.first_name = res.data.resident_user
          ? res.data.resident_user.first_name
          : "";
        resData.last_name = res.data.resident_user
          ? res.data.resident_user.last_name
          : "";
        resData.email = res.data.resident_user
          ? res.data.resident_user.email
          : "";
        if(res.data && res.data.resident_user && res.data.resident_user.phone_number){
          this.phoneNumberAutoFormat(res.data.resident_user.phone_number)
        }
        resData.street_1 = res.data.resident_user
          ? res.data.resident_user.street_1
          : "";
        resData.street_2 = res.data.resident_user
          ? res.data.resident_user.street_2
          : "";
        resData.zipcode = res.data.resident_user
          ? res.data.resident_user.zip_code
          : "";

        resData.country_name =
          res.data.resident_user && res.data.resident_user.country
            ? res.data.resident_user.country.country_name
            : "";
        resData.county_name =
          res.data.resident_user && res.data.resident_user.county
            ? res.data.resident_user.county.county_name
            : "";
        resData.state_name =
          res.data.resident_user && res.data.resident_user.state
            ? res.data.resident_user.state.state_name
            : "";
        resData.city_name =
          res.data.resident_user && res.data.resident_user.city
            ? res.data.resident_user.city.city_name
            : "";

        resData.status_id = res.data.status_id ? res.data.status_id : "";
      }
      this.setState(resData);
    }
    this.getPetPolicyData();
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    this.setState({ phone_number: finalnumber });
  };

  async getPetPolicyData() {
    //this.setState({ showSpinner: true, loading: true });
    var multiList = [];
    var multiListTotal = 0;

    let res = await PetPolicyService.getPetPolicy(
      this.props.propertySlug + "/3"
    );
    if (global.successStatus.includes(res.status)) {
      multiList = res.data.petPolicyRestrictions
        ? res.data.petPolicyRestrictions
        : [];
      multiListTotal = multiList ? multiList.length : 0;
    }
    this.setState({
      multiList: multiList,
      multiListTotal: multiListTotal,
    });
    //this.setState({ showSpinner: false, loading: false });
  }

  leaseStatus() {
    const currentDate = new Date();

    var dateFrom = Moment(this.state.lease_start_date).format("MM/DD/YYYY");
    var dateTo = Moment(this.state.lease_end_date).format("MM/DD/YYYY");
    var dateCheck = Moment(currentDate).format("MM/DD/YYYY");

    var d1 = dateFrom.split("/");
    var d2 = dateTo.split("/");
    var c = dateCheck.split("/");

    if (this.state.status_id === 1 && c >= d1 && c <= d2) {
      return "Active";
    } else if (this.state.status_id === 1 && c <= d1) {
      return "Active";
    } else if (this.state.status_id === 2) {
      return "Inactive";
    } else if (c >= d2) {
      return "Expired";
    }
  }

  render() {
    return (
      <>
        <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
            {this.props.listing_slug !== undefined ? (
              <>
                {global.userPermissions.checkPermission("leases-delete") && (
                  <span
                    onClick={() => {
                      this.opendeleteListingModal(this.props.listing_slug);
                    }}
                    className="deleteicon mg-r-20 hand-cursor"
                  >
                    <img src={deleteicon} alt="Delete" />
                  </span>
                )}
              </>
            ) : (
              ""
            )}{" "}
            {global.userPermissions.checkPermission("leases-update") && (
              <Link
                to={
                  this.props.propPropertySlug !== undefined
                    ? "/properties/view/" +
                      this.props.propertySlug +
                      "/leasing/update/" +
                      this.props.listing_slug
                    : "/leasing/update/" +
                      this.props.outside_property_slug +
                      "/" +
                      this.props.listing_slug
                }
              >
                <button className="btn-success-outline-small mr-3">
                  <img src={editIcon} alt="Edit" />
                  Edit
                </button>
              </Link>
            )}
          </div>
          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          <div className="m-0">
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 background-head">Basic</h3>
            </div>
            <div className="row align-items-basline pd-b-30 border-bottom-0 pd-r-0 listing_detail">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Unit</p>
                <p className="media-body">
                  {/* {this.state.total_tower_count > 1
                  ? this.state.unit_number + " (" + this.state.aka_name + ")"
                  : this.state.unit_number} */}
                  {this.state.unit_name
                    ? this.state.unit_name
                    : this.state.unit_number}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Leasing Type</p>
                <p className="media-body">
                  {this.state.type === 1 ? "Month to Month" : "Fixed"}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Lease Signed Date</p>
                <p className="media-body">
                  {this.state.lease_signed_date
                    ? this.state.lease_signed_date
                    : ""}
                </p>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Lease Start Date</p>
                <p className="media-body">
                  {this.state.lease_start_date
                    ? this.state.lease_start_date
                    : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Lease End Date</p>
                <p className="media-body">
                  {this.state.lease_end_date ? this.state.lease_end_date : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Roll-over</p>
                <p className="media-body">
                  {this.state.roll_over === true ? "Yes" : "No"}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                <p
                  className="az-profile-name-text pl-0"
                  title="Below pets are prohibited at property"
                >
                  Prohibited Pets
                </p>
                <p className="media-body">
                  {this.state.multiListTotal > 0
                    ? this.state.multiList.map(
                        (option, i) =>
                          option.pet_type_name +
                          (i < this.state.multiListTotal - 1 ? ", " : "") +
                          ""
                      )
                    : ""}
                </p>
              </div>
            </div>
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 background-head">Pricing</h3>
            </div>
            <div className="row align-items-basline pd-b-30 border-bottom-0 pd-r-0 listing_detail">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Monthly Rent</p>
                <p className="media-body">
                  {this.state.monthly_rent
                    ? "$" +
                      global.onKeyPressEvent.numberWithCommasForDecimal(
                        this.state.monthly_rent.replace(/\.00$/, "")
                      )
                    : ""}
                </p>
              </div>
              {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-r-30">
              <p className="az-profile-name-text pl-0">Rent Start Date</p>
              <p className="media-body">
                {this.state.rent_start_date ? this.state.rent_start_date : ""}
              </p>
            </div> */}
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Deposit</p>
                <p className="media-body">
                  {this.state.deposit
                    ? "$" +
                      global.onKeyPressEvent.numberWithCommasForDecimal(
                        this.state.deposit.replace(/\.00$/, "")
                      )
                    : ""}
                </p>
              </div>
              {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-r-30">
              <p className="az-profile-name-text pl-0">Lease Status</p>
              <p className="media-body">{this.leaseStatus()}</p>
            </div> */}
            </div>
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 background-head">Resident Contact Info</h3>
            </div>
            <div className="row align-items-basline pd-b-30 border-bottom-0 pd-r-0 listing_detail">
              <div className="row align-items-center col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 mg-b-20 mg-xs-b-0 pd-l-15 pd-r-15 p-0">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                  <div className="row mg-0">
                    <div className="brand-sm-logo mg-b-8">
                      <figure>
                        <img
                          src={
                            this.state.resident_photo_thumb_url
                              ? this.state.resident_photo_thumb_url
                              : hostImg
                          }
                          alt=""
                          className="rounded-circle"
                        />
                      </figure>
                    </div>
                    <div className="mg-l-6">
                      <p className="az-profile-name-text pl-0">First Name</p>
                      <p className="media-body">
                        {this.state.first_name ? this.state.first_name : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                  <p className="az-profile-name-text pl-0">Last Name</p>
                  <p className="media-body">
                    {this.state.last_name ? this.state.last_name : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                  <p className="az-profile-name-text pl-0">Phone</p>
                  <p className="media-body">
                    {this.state.phone_number ? this.state.phone_number : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                  <p className="az-profile-name-text pl-0">Email </p>
                  <p className="media-body">
                    {this.state.email ? this.state.email : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 background-head">Alternative Address</h3>
            </div>
            <div className="row align-items-basline pd-b-30 border-bottom-0 pd-r-0 listing_detail">
              <div className="row align-items-center col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 mg-b-20 pd-l-15 pd-r-15 p-0">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                  <p className="az-profile-name-text pl-0">Street 1</p>
                  <p className="media-body">
                    {this.state.street_1 ? this.state.street_1 : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                  <p className="az-profile-name-text pl-0">Street 2</p>
                  <p className="media-body">
                    {this.state.street_2 ? this.state.street_2 : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                  <p className="az-profile-name-text pl-0">City</p>
                  <p className="media-body">
                    {this.state.city_name ? this.state.city_name : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                  <p className="az-profile-name-text pl-0">State</p>
                  <p className="media-body">
                    {this.state.state_name ? this.state.state_name : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                  <p className="az-profile-name-text pl-0">Zip Code</p>
                  <p className="media-body">
                    {this.state.zipcode ? this.state.zipcode : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                  <p className="az-profile-name-text pl-0">County</p>
                  <p className="media-body">
                    {this.state.county_name ? this.state.county_name : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                  <p className="az-profile-name-text pl-0">Country</p>
                  <p className="media-body">
                    {this.state.country_name ? this.state.country_name : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.deleteListingModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closedeleteListingModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteListingModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteListingModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteListing(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Info;
