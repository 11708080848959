////////////////////////////////////////////////////////////
//     							                          //
//  Program: GuestView.jsx                                //
//  Application: Guest View                               //
//  Option: For view guest details                        //
//  Developer: NP				                          //
//  Date: 2022-04-20                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import GuestService from "../../services/GuestService";
import CalendarSidebar from "../common/CalendarSidebar";
import Alerts from "../common/Alerts";
import { Link } from "react-router-dom";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import { Modal } from "react-bootstrap";
import verifiedImg from "./../../assets/images/online-tick.svg";
import notVerifiedImg from "./../../assets/images/offline-tick.svg";
import AllPropertyReservationSidebar from "../common/AllPropertyReservationSidebar";

export class GuestView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleSwitchDark2: true,
      addModal: false,
      showSpinner: false,
      loading: false,
      deleteGuestModal: false,
      status_id: 1,
      confirm_back: 0,
      guest_slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      pathname: window.location.pathname,
      activeClass: "guest-info",
      tab: "active",
      usertabData: localStorage.getItem("user_tab_data")
        ? localStorage.getItem("user_tab_data")
        : "",
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "action_path",
          text: "Action Name",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.function_name;
          },
        },
        {
          key: "created_at",
          text: "Created At",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.created_at;
          },
        },
      ],
    };
    this.opendeleteGuestModal = this.opendeleteGuestModal.bind(this);
    this.closedeleteGuestModal = this.closedeleteGuestModal.bind(this);
    this.deleteGuest = this.deleteGuest.bind(this);
    this.openWebsite = this.openWebsite.bind(this);
  }

  opendeleteGuestModal(slug) {
    this.setState({ deleteGuestModal: true, slug: slug });
  }

  closedeleteGuestModal() {
    this.setState({ deleteGuestModal: false, slug: "" });
  }

  async deleteGuest(slug) {
    this.setState({ showSpinner: true });
    let res = await GuestService.deleteGuest(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteGuestModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          (window.location =
            this.props.match.params.propertySlug !== undefined
              ? "/properties/view/" + this.props.propertySlug + "/guest"
              : "/guest"),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteGuestModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "guest-info") {
      this.setState({ tab: "guest-info" });
      this.getData();

      setTimeout(() => {
        localStorage.setItem(
          "user_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "guest-activity") {
      this.setState({ tab: "guest-activity" });
      this.UserActivity();

      setTimeout(() => {
        localStorage.setItem(
          "user_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async getData(queryString = "") {
    if (this.state.guest_slug !== undefined) {
      var resData = {};
      let res = await GuestService.getGuest(this.state.guest_slug);
      if (global.successStatus.includes(res.status)) {
        resData.first_name = res.data.first_name ? res.data.first_name : "";
        resData.last_name = res.data.last_name ? res.data.last_name : "";
        resData.email = res.data.email ? res.data.email : "";
        if(res.data && res.data.phone_number){
          this.phoneNumberAutoFormat(res.data.phone_number)
        }
        resData.zip_code = res.data.zip_code ? res.data.zip_code : "";
        resData.street_1 = res.data ? res.data.street_1 : "";
        resData.street_2 = res.data ? res.data.street_2 : "";
        resData.country_name =
          res.data && res.data.country ? res.data.country.country_name : "";
        resData.city_name =
          res.data && res.data.city ? res.data.city.city_name : "";
        resData.county_name =
          res.data && res.data.county ? res.data.county.county_name : "";
        resData.state_name =
          res.data && res.data.state ? res.data.state.state_name : "";
        resData.passport = res.data.passport ? res.data.passport : "";
        resData.comments = res.data.comments ? res.data.comments : "";
        resData.is_id_verified = res.data.is_id_verified
          ? res.data.is_id_verified
          : "";
        resData.verification_url = res.data.verification_url
          ? res.data.verification_url
          : "";

        resData.slug = res.data.slug ? res.data.slug : "";
      }
      this.setState(resData);
    }
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    this.setState({ phone_number: finalnumber });
  };

  async componentDidMount() {
    this.getData();
  }

  getTitle() {
    return "Guest";
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }

  openWebsite() {
    var url = this.state.google_map_link ? this.state.google_map_link : "";
    window.open(url, "_blank");
  }

  render() {
    const url = this.state.google_map_link ? this.state.google_map_link : "";
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <CalendarSidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyReservationSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {global.userPermissions.checkPermission(
                        "guests-delete"
                      ) && (
                        <span
                          onClick={() => {
                            this.opendeleteGuestModal(this.state.guest_slug);
                          }}
                          className="deleteicon mg-r-20 hand-cursor"
                        >
                          <img src={deleteicon} alt="Delete" />
                        </span>
                      )}
                      {global.userPermissions.checkPermission(
                        "guests-update"
                      ) && (
                        <Link
                          to={
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                this.state.property_slug +
                                "/guest/update/" +
                                this.state.guest_slug
                              : "/guest/update/" +
                                this.state.property_slug +
                                "/" +
                                this.state.guest_slug
                          }
                        >
                          <button
                            type="button"
                            className="btn-success-outline-small"
                          >
                            Edit
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-0 mg-t-30">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-l-30 pd-r-30">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="m-0">
                      <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-l-30 pd-r-30">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            First Name
                          </p>
                          <p className="media-body">
                            {this.state.first_name ? this.state.first_name : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Last Name</p>
                          <p className="media-body">
                            {this.state.last_name ? this.state.last_name : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Email</p>
                          <p className="media-body">
                            {this.state.email ? this.state.email : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Phone</p>
                          <p className="media-body">
                            {this.state.phone_number ? this.state.phone_number : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Street 1</p>
                          <p className="media-body">
                            {this.state.street_1 ? this.state.street_1 : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Street 2</p>
                          <p className="media-body">
                            {this.state.street_2 ? this.state.street_2 : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">City</p>
                          <p className="media-body">
                            {this.state.city_name ? this.state.city_name : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">State</p>
                          <p className="media-body">
                            {this.state.state_name ? this.state.state_name : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Zip Code</p>
                          <p className="media-body">
                            {this.state.zip_code ? this.state.zip_code : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">County</p>
                          <p className="media-body">
                            {this.state.county_name
                              ? this.state.county_name
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Country</p>
                          <p className="media-body">
                            {this.state.country_name
                              ? this.state.country_name
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Passport</p>
                          <p className="media-body">
                            {this.state.passport ? this.state.passport : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Verified</p>
                          <p className="media-body">
                            {this.state.is_id_verified === 1 ? (
                              <img src={verifiedImg} alt="" />
                            ) : (
                              <img src={notVerifiedImg} alt="" />
                            )}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Comments</p>
                          <p className="media-body">
                            {this.state.comments ? this.state.comments : ""}
                          </p>
                        </div>
                        <div className="col-12">
                          <p className="az-profile-name-text pl-0">
                            Verification Link
                          </p>
                          <p
                            className="hand-cursor break-word link-url"
                            onClick={this.openWebsite}
                          >
                            {url}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal
          show={this.state.deleteGuestModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closedeleteGuestModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteGuestModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className=" d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteGuestModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteGuest(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default GuestView;
