
////////////////////////////////////////////////////////////
//     							                                      //
//  Program: FloorPlanMapping.jsx                         //
//  Application: FloorPlanMapping                         //
//  Developer: Anil Kumar Gupta	                          //
//  Date: 2023-01-20                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
/** import Select from "react-select";*/
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container, Button, Form, InputGroup } from "react-bootstrap";
import SettingsSidebar from "../common/SettingsSidebar";
import DataHubAutomationService from "../../services/DataHubAutomationService";

class FloorPlanMapping extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
        isPageLoaded: true,
        showAlertModal: false,
        alertModalType: "",
        alertModalTitle: "",
        alertModalMessage: "",
        loading: false,
        showSpinner: false,
        total_record: 0,
        mappingArray: [],
        pathname: window.location.pathname,
        property_slug: this.props.match.params.propertySlug,
        records: [],
        record1: [],
        config: {
          sort: {
            column: "scheduled_on",
            order: "asc",
          },
          key_column: "slug",
          page_size: global.page_size,
          length_menu: global.page_length_menu,
          show_length_menu: false,
          show_filter: false,
          show_info: false,
          show_pagination: false,
          pagination: "advance",
          button: {
            excel: global.excel_button,
            print: global.print_button,
            extra: false,
          },
          language: {
            no_data_text: "No data found",
          },
        },
        extraButtons: [],
        columns: [
          {
            key: "oh_floor_plans_name",
            text: "Floor Plan",
            className: "oh_floor_plans_name",
            sortable: false,
            cell: (record) => {
              return record.property_floor_plan_name;
            },
          },
          {
            key: "pms_floor_plans_dropdown",
            text: "PMS Floor Plan",
            className: "pms_floor_plans_dropdown drop_cust_w",
            sortable: false,
            cell: (record) => { 
              return (
                <div>
                  <InputGroup className="mt-3">
                  <Form.Control
                    value={this.state.pms_floor_plans[record.item_index].pms_floor_plan_name ?? ''}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    onChange={(value) =>
                      this.handleChangeStatusList(value, record)
                    }
                  />
                </InputGroup>
                </div>
              );
            },
          },
        ],
        pms_floor_plans: []
      };
      this.setMapping = this.setMapping.bind(this);
  }

  handleChangeStatusList(event, record) {
    let prevState = [...this.state.pms_floor_plans];
    prevState[record.item_index]['pms_floor_plan_name'] =   event.target.value;
    this.setState({ prevState }); 
    
  }

  async componentDidMount() {
    this.getData();
  }

  async getData() {
    var list = [];
    var totalRecords = 0;
    var inputData = {
      property_slug : this.state.property_slug,
    }
    let res = await DataHubAutomationService.GetPmsFloorPlansList(inputData);

    if (global.successStatus.includes(res.status)) {
      this.setState({'pms_floor_plans': res.data.properties_floor_plans})
      list = res.data ? res.data.properties_floor_plans
      : [];
      totalRecords = res.data.properties_floor_plans
        ? res.data.properties_floor_plans.length
        : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  async setMapping() {
    let inputData = {
      mappingArray: this.state.pms_floor_plans,
    };
    var res = await DataHubAutomationService.setMapping(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      })
      window.location.reload();
    } else {
      if (res.data.result.errorDetail) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
    }
  }

  getTitle() {
    return "Floor Plans Mapping";
  }

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <SettingsSidebar property_slug={this.state.property_slug} />
                </div>
              </div>

              {/* Mapping component will render here */}
              <div className="col-md-10 right settingsidebarNav">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      <Button
                        variant="outline-dark btn-block btn-with-icon"
                        onClick={this.setMapping}
                      >
                        <img src={pluswIcon} alt="" /> Save
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="">

                  <div className="col-md-12 pd-lg-l-45 pd-lg-r-30 pd-md-l-45 pd-md-r-30 pd-xs-l-15 pd-xs-r-0">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>

                  {this.state.total_record !== 0 ? (
                    <div className="mg-t-18 brandList ">
                      <div className="pd-lg-l-15 pd-xs-l-15 pd-md-l-30 lookup_table">
                        <ReactDatatable
                          className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100 table_last_row"
                          config={
                            this.state.total_record > global.page_size
                              ? this.state.config
                              : this.state.config
                          }
                          records={this.state.records}
                          columns={this.state.columns}
                          extraButtons={this.state.extraButtons}
                          dynamic={true}
                          total_record={this.state.total_record}
                          onRowClicked={this.rowClickedHandler}
                          pagination={false}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="mg-t-30 text-center">
                      <p>No floor plans for map.</p>
                    </div>
                  )}
                </div>
              </div>
              {/* End Mapping component rendering */}

            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default FloorPlanMapping