////////////////////////////////////////////////////////////
//     							                          //
//  Program: RolePermissionService.js                     //
//  Application: Services                                 //
//  Option: Used for authentication components            //
//  Developer: CV  						                  //
//  Date: 2021-02-01                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from './ApiService'

class RolePermissionService {
    //for role permission
    getRoles(queryString) {
        let endPoint = 'role' + (queryString ? '?' + queryString : '');
        //let endPoint = 'role';
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    getPermissionList(queryString) {
        let endPoint = 'get-module-permission' + (queryString ? '?' + queryString : '');
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    getRolePermissionList(slug,queryString) {
        let endPoint = 'role/' + slug + (queryString ? '?' + queryString : '');
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    addRolePermission(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'add-role-with-permission';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.postCall(inputData);
        return response;
    }

    updateRolePermission(slug,data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'update-role-with-permission/'+slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.putCall(inputData);
        return response;
    }

    deleteRoles(slug) {
        let endPoint = "delete-role-with-permission/" + slug;
        let addditionalHeaderData = {};
        let postData = {};
    
        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.deleteCall(inputData);
        return response;
      }

    getRolesGroups() {
        let endPoint = 'get-role-groups';
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    getRolePermissionStatusList(slug,queryString) {
        let endPoint = 'get-role-based-permission-status/' + slug + (queryString ? '?' + queryString : '');
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    setRolePermissionStatusList(slug, data) {
        let endPoint = 'set-role-based-permission-status/' + slug;
        let addditionalHeaderData = {};
        let postData = data;
        
        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }        
        let response = ApiService.postCall(inputData);
        return response;
    }

    saveRole(data) {
        let endPoint = 'role-insert';
        let addditionalHeaderData = {};
        let postData = data;
        
        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }        
        let response = ApiService.postCall(inputData);
        return response;
    }  
    
    copyRole(data) {
        let endPoint = 'copy-role';
        let addditionalHeaderData = {};
        let postData = data;
        
        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }        
        let response = ApiService.postCall(inputData);
        return response;
    }  
    
    deleteRole(slug) {
        let endPoint = "role-delete/" + slug;
        let addditionalHeaderData = {};
        let postData = {};
    
        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.deleteCall(inputData);
        return response;
    }

    getRoleGroups(queryString) {
        let endPoint = 'get-role-groups' + (queryString ? '?' + queryString : '');
        //let endPoint = 'role';
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }
}

export default new RolePermissionService()