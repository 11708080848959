import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
// import { Link } from "react-router-dom";
import { Container, Modal, InputGroup, Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import HouseKeepingSidebar from "../common/HouseKeepingSidebar";
import ProblemService from "../../services/ProblemService";
import Moment from "moment";
import filterIcon from "./../../assets/images/filter-gray.svg";
import UnitsService from "../../services/UnitsService";
import Select from "react-select";
import DatePicker from "react-datepicker";
import queryString from "query-string";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertiesCleaningSidebar from "../common/AllPropertiesCleaningSidebar";

let queryParams = queryString.parse(window.location.search);
export class Problems extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      isSearch: global.isSearch,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      records: [],
      user_slug: "",
      userList: [],
      selectedUserList: [],
      mro_category_slug: "",
      mroCategory: [],
      selectedMroCategory: [],
      priority_type: "",
      priorityType: [],
      due_date: "",
      status_id: "",
      statusList: [],
      selectedStatusList: [],
      selectStatusList: [],
      property_slug: this.props.match.params.propertySlug,
      config: {
        sort: {
          column: "created_at",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "unit_id",
          text: "Unit",
          className: "unit_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.unit
              ? record.unit.unit_name
                ? record.unit.unit_name
                : record.unit.unit_number
              : "";
          },
        },
        {
          key: "tbnb_project_id",
          text: "Project No.",
          className: "tbnb_project_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.tbnb_project_id ? record.tbnb_project_id : "";
          },
        },
        // {
        //   key: "partner_problem_id",
        //   text: "TBNB Problem No.",
        //   className: "partner_problem_id hand-cursor",
        //   sortable: true,
        //   cell: (record) => {
        //     return record.partner_problem_id ? record.partner_problem_id : "";
        //   },
        // },
        {
          key: "title",
          text: "Issue",
          className: "title hand-cursor text-left issue_tbl_cols",
          sortable: true,
          cell: (record) => {
            // return record.title ? record.title : "";
            const noteString = record.title ? record.title : "";
            const maxString = 50;
            return noteString.length > maxString
              ? noteString.substr(0, maxString - 1) + " ..."
              : noteString;
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.status_display ? record.status_display : "";
          },
        },
        {
          key: "partner_problem_id",
          text: "Reported By",
          className: "partner_problem_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.tbnb_project &&
              record.tbnb_project.contractor_first_name &&
              record.tbnb_project.contractor_last_name &&
              record.tbnb_project.contractor_first_name !== null &&
              record.tbnb_project.contractor_last_name !== null
              ? record.tbnb_project.contractor_first_name +
                  " " +
                  record.tbnb_project.contractor_last_name
              : "";
          },
        },
        {
          key: "created_at",
          text: "Create Date",
          className: "created_at hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.created_at
              ? Moment(record.created_at).format(global.dateFormat)
              : "";
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getUnitData();
      this.setState({ isSearch: 1 });
    }
  };

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      this.getData();
      this.getStatusList();
    }
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.getData();
      this.getStatusList();
    }, 200);
    this.setState({
      selectedUnitList: [],
      module_search: [],
      is_search: 0,
    });
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getData(queryString = "") {
    this.setState({ loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await ProblemService.getList(
      "property_slug=" + (this.state.property_slug ? this.state.property_slug : "") + "&" + queryString
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = list ? list.length : 0;
    }
    this.setState({
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  async getStatusList() {
    const selectStatusList = [
      { value: "1", label: "Completed" },
      { value: "2", label: "In Progress" },
      { value: "3", label: "Pending" },
    ];
    this.setState({ selectStatusList: selectStatusList });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeStatusList(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    const status_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStatusList: value,
      status_id: status_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeUnitList(value) {
    // console.log(value.label);
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_number: value.label,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          } else if (key === "page_size") {
            newKey = "per_page";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      this.props.match.params.propertySlug !== undefined
        ? "problems/" + data.tbnb_project_id
        : this.state.property_slug
        ? "/problems/" + this.state.property_slug + "/" + data.tbnb_project_id
        : "/problems/" + (data.property && data.property.slug ? data.property.slug : "") + "/" + data.tbnb_project_id
    );
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      problem_created_date: "",
      unit_slug: "",
      status_id: "",
      project_number: "",
      selectedUnitList: [],
    });
    this.setState({ module_search: "" });
  }

  filterData() {
    let createDate = this.state.problem_created_date
      ? Moment(this.state.problem_created_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "problem_created_date=" +
      createDate +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&status=" +
      (this.state.status_id ? this.state.status_id : "") +
      "&project_number=" +
      (this.state.project_number ? this.state.project_number : "") +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.problem_created_date
      ? "Created Date - " +
        Moment(this.state.problem_created_date).format(global.dateFormat)
      : "";
    moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Unit - " +
          this.state.selectedUnitList.label
        : " Unit - " + this.state.selectedUnitList.label
      : moduleSearchString;

    moduleSearchString = this.state.status_id
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Status - " +
          this.state.selectedStatusList.label
        : " Status - " + this.state.selectedStatusList.label
      : moduleSearchString;
    moduleSearchString = this.state.project_number
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Project Number - " +
          this.state.project_number
        : " Project Number - " + this.state.project_number
      : moduleSearchString;

    if (
      this.state.problem_created_date ||
      this.state.unit_slug ||
      this.state.status_id ||
      this.state.project_number
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(',') });
      this.getData(queryString);
    }
  }

  getTitle() {
    return "Problems";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <HouseKeepingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertiesCleaningSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    {/* <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      <Link
                        to={
                          "/properties/view/" +
                          this.state.property_slug +
                          "/mros/add"
                        }
                      >
                        <button className="btn-success-outline-small ">
                          <img src={pluswIcon} alt="" /> Add
                        </button>
                      </Link>
                    </div> */}
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      <button
                        onClick={this.searchToggle}
                        className="btn-success-outline-small ml-3"
                      >
                        <img src={filterIcon} alt="filterIcon" />{" "}
                        {this.state.isSearch === 1 ? "Search" : "Search"}
                      </button>
                    </div>
                  </div>
                </div>
                {this.props.match.params.propertySlug === undefined ? (
                    <>
                    <div className="row pd-lg-l-15 pd-xs-l-15">
                      <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                    <OutSidePropertySelectBox
                      propertySlug={this.getPropertySlug.bind(this)}
                      outSidePropertySlug={this.state.outSidePropertySlug}
                    />
                  </div>
                  <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                          {queryParams.isGlobal === "1" ? (
                            <>
                              <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                {"Search applied on: " +
                                  queryParams.global_col_label +
                                  " - " +
                                  queryParams.global_search}
                                <button
                                  onClick={this.clearFilter}
                                  className="mg-l-15 btn-success-outline-small"
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.is_search === 1 ? (
                            <>
                              <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                                <div className="custm-filter">
                                  <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                                  <div className="filter_bg_cust">
                                    {
                                      this.state.module_search.map((item, i) => {
                                        return (
                                          <span key={i}>{item}</span>
                                        )
                                      })
                                    }
                                    <button
                                      onClick={this.clearFilter}
                                      className="btn-fliter-clear"
                                    >Clear</button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                  </div>
                    </div>
                    </>
                ) : (
                  <div className="col-xxl-12 pd-r-15 pd-l-15">
                    {queryParams.isGlobal === "1" ? (
                            <>
                              <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                {"Search applied on: " +
                                  queryParams.global_col_label +
                                  " - " +
                                  queryParams.global_search}
                                <button
                                  onClick={this.clearFilter}
                                  className="mg-l-15 btn-success-outline-small"
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.is_search === 1 ? (
                            <>
                                <div className="col-xxl-12 col-sm-12 pd-lg-r-15 pd-lg-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                                <div className="custm-filter">
                                  <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                                  <div className="filter_bg_cust">
                                    {
                                      this.state.module_search.map((item, i) => {
                                        return (
                                          <span key={i}>{item}</span>
                                        )
                                      })
                                    }
                                    <button
                                      onClick={this.clearFilter}
                                      className="btn-fliter-clear"
                                    >Clear</button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                  </div>
                )}
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-l-30 pd-r-30">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.state.total_record !== 0 ||
                      this.state.filter_value !== "" ? (
                      <div className="mg-t-18 brandList pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-0">
                        <div className="pd-lg-l-0 pd-xs-l-15 pd-lg-r-15 pd-xs-r-0">
                          <ReactDatatable
                            className="table mg-b-30 pd-b-2 add-no-wrap text-align-left"
                            config={
                              this.state.total_record > global.page_size
                                ? this.state.config
                                : this.state.config
                            }
                            records={this.state.records}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            // loading={this.state.loading}
                            total_record={this.state.total_record}
                            onChange={this.tableChangeHandler}
                            onRowClicked={this.rowClickedHandler}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                        {/* {queryParams.isGlobal === "1" ? (
                          <>
                            <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                              {"Search applied on: " +
                                queryParams.global_col_label +
                                " - " +
                                queryParams.global_search}
                              <button
                                onClick={this.clearFilter}
                                className="mg-l-15 btn-success-outline-small"
                              >
                                Clear
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.is_search === 1 ? (
                          <>
                            <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                              <div className="custm-filter">
                                <div> <label className="form-label text-left pd-b-5 mb-0">Search Applied On</label></div>
                                <div className="filter_bg_cust">
                                  {
                                    this.state.module_search.map((item, i) => {
                                      return (
                                        <span key={i}>{item}</span>
                                      )
                                    })
                                  }
                              <button
                                onClick={this.clearFilter}
                                    className="btn-fliter-clear"
                                  >Clear</button>
                            </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )} */}
                        <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                          <ReactDatatable
                            className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100 text-align-left"
                            config={
                              this.state.total_record > global.page_size
                                ? this.state.config
                                : this.state.config
                            }
                            records={this.state.records}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            // loading={this.state.loading}
                            total_record={this.state.total_record}
                            onChange={this.tableChangeHandler}
                            onRowClicked={this.rowClickedHandler}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* problem Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Problems</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Project Number</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="project_number"
                      value={this.state.project_number}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Status</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      name="status_id"
                      value={this.state.selectedStatusList}
                      options={this.state.selectStatusList}
                      onChange={(value) => this.handleChangeStatusList(value)}
                      defaultValue={this.state.selectedStatusList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Create Date</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="problem_created_date"
                      selected={this.state.problem_created_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "problem_created_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default Problems;
