import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
    // Modal,
    // InputGroup,
    // Form,
    Table,
    Nav,
    Tab,
} from "react-bootstrap";
// import Select from "react-select";
// import PhoneInput from "react-phone-input-2";
import deleteicon from "../assets/images/delete.svg";
import MainHeading from "../assets/images/Component/MainHeading.png";
import InnerHeading from "../assets/images/Component/InnerHeading.png";
import filterIcon from "../assets/images/filter-gray.svg";
import Newpage from "../assets/images/Component/PageCreate.png";
import Fromimg from "../assets/images/Component/formdesign.png";
import TabsImg from "../assets/images/Component/tabs.png";
import ModalImg from "../assets/images/Component/modal.png";
import dataTable from "../assets/images/Component/datatable.png";
import smallModalImg from "../assets/images/Component/smallmodal.png";

import { Alert } from 'react-bootstrap'
// import ReactDatatable from "@ashvin27/react-datatable";
// import NoData from "././common/NoData";
// import { ScrollingCarousel } from "@trendyol-js/react-carousel";
// import { Link } from "react-router-dom";


class Assets extends Component {
    render() {
        // const customStyles = {
        //     option: (provided, state) => ({
        //         ...provided,
        //         // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        //         color:
        //             state.isSelected && state.isFocused
        //                 ? "white"
        //                 : state.isSelected
        //                     ? "black"
        //                     : state.isFocused && "white",
        //         backgroundColor:
        //             state.isSelected && state.isFocused
        //                 ? "black"
        //                 : state.isFocused && "black",
        //     }),
        // };
        return (
            <main>
                <Container>
                    <div className="row row-xs">
                        <div className="col-md-12 mg-t-10 mg-b-10"></div>
                        <div className="col-md-12">
                            <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                                <h3>Assets</h3>
                            </div>
                        </div>
                    </div>
                </Container>
                <Container>
                    <div className="scrolling-carousel material_page">
                        <div className="dashboardRightcard background-none mg-t-30  mg-b-30 pd-l-30 pd-r-30">
                            <Container>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="Font">
                                    <Row>
                                        <Col sm={2}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="Font">Fonts</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="Button">Buttons</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="Alert">Alerts</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="Heading">Headings</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="NewPage">New Page</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="Form">Form</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="Table">Table</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="Tabs">Tabs</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="Modal">Modal</Nav.Link>
                                                </Nav.Item>

                                            </Nav>
                                        </Col>
                                        <Col sm={10}>
                                            <Tab.Content>

                                                <Tab.Pane eventKey="Font">
                                                    <div className="col-md-12">
                                                        <h3 className='text-center'>Font Typography</h3>
                                                        <div className="cust-bdr-material font-materials">
                                                            <p className='theme-font-OrpheusPro m-0 text-cust-black'>OrpheusPro</p>
                                                            <p className='theme-font-lato m-0 text-cust-black'>Regular Lato</p>
                                                            <p className='theme-font-lato-b m-0 text-cust-black'>Bold Lato</p>

                                                        </div>
                                                        <div className='cust-bdr-material-code'>
                                                            <pre>
                                                                &lt;p className='theme-font-OrpheusPro'&gt;OrpheusPro&lt;/p&gt;
                                                            </pre>
                                                            <pre>
                                                                &lt;p className='theme-font-lato'&gt;Regular Lato&lt;/p&gt;
                                                            </pre>
                                                            <pre>
                                                                &lt;p className='theme-font-lato-b'&gt;Bold Lato&lt;/p&gt;
                                                            </pre>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 mg-t-30">
                                                        <h3 className='text-center'> Colors</h3>
                                                        <div className="cust-bdr-material font-materials">
                                                            <p className='text-cust-lightgray bg-cust-black m-0'>.text-cust-lightgray</p>
                                                            <p className='text-cust-gray m-0'>.text-cust-gray</p>
                                                            <p className='text-cust-black m-0'>.text-cust-black</p>
                                                            <p className='text-cust-white bg-cust-black m-0'>.text-cust-white</p>
                                                            <p className='text-danger m-0'>.text-danger</p>
                                                        </div>
                                                        <div className='cust-bdr-material-code'>
                                                            <pre>
                                                                &lt;p className='text-cust-lightgray'&gt;.text-cust-lightgray&lt;/p&gt;
                                                            </pre>
                                                            <pre>
                                                                &lt;p className='text-cust-gray'&gt;.text-cust-gray&lt;/p&gt;
                                                            </pre>
                                                            <pre>
                                                                &lt;p className='text-cust-black'&gt;.text-cust-black&lt;/p&gt;
                                                            </pre>
                                                            <pre>
                                                                &lt;p className='text-danger m-0'&gt;Error text&lt;/p&gt;
                                                            </pre>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 mg-t-30">
                                                        <h3 className='text-center'> Background Colors</h3>
                                                        <div className="cust-bdr-material font-materials">
                                                            <p className='text-cust-white bg-cust-black mg-b-5'>.bg-cust-black</p>
                                                            <p className='text-cust-white bg-cust-green mg-b-5'>.bg-cust-green</p>
                                                            <p className='bg-cust-white text-cust-black mg-b-5'>.bg-cust-white</p>
                                                            <p className='bg-cust-theme text-cust-black mg-b-5'>.bg-cust-theme</p>

                                                        </div>
                                                        <div className='cust-bdr-material-code'>
                                                        <pre>
                                                                &lt;p className='text-cust-white bg-cust-black mg-b-5'&gt;.bg-cust-black&lt;/p&gt;
                                                            </pre>
                                                            <pre>
                                                                &lt;p className='text-cust-white bg-cust-green mg-b-5'&gt;.bg-cust-green&lt;/p&gt;
                                                            </pre>
                                                            <pre>
                                                                &lt;p className='bg-cust-white text-cust-black mg-b-5'&gt;.bg-cust-white&lt;/p&gt;
                                                            </pre>
                                                            <pre>
                                                                &lt;p className='bg-cust-theme text-cust-black mg-b-5'&gt;.bg-cust-theme&lt;/p&gt;
                                                            </pre>
                                                        </div>
                                                    </div>

                                                </Tab.Pane>

                                                <Tab.Pane eventKey="Button">
                                                    <div className="col-md-12">
                                                        <h3 className='text-center'>Buttons</h3>
                                                        <div className="cust-bdr-material">
                                                            <button className="btn btn-cust-small mg-r-5">Small</button>
                                                            <button className="btn btn-cust-small-o mg-r-5">Small-O</button>
                                                            <button className="btn btn-cust-medium mg-r-5">Medium</button>
                                                            <button className="btn btn-cust-medium-o">Medium-O</button>
                                                            <button className="btn btn-cust-delete-icon">
                                                                <img src={deleteicon} alt="delete" />
                                                            </button>
                                                        </div>
                                                        <div className='cust-bdr-material-code'>
                                                            <pre>
                                                                &lt;button className="btn btn-cust-small"&gt;Small&lt;/button&gt;
                                                            </pre>
                                                            <pre>
                                                                &lt;button className="btn btn-cust-small-o"&gt;Small-O&lt;/button&gt;
                                                            </pre>
                                                            <pre>
                                                                &lt;button className="btn btn-cust-medium"&gt;Medium-O&lt;/button&gt;
                                                            </pre>
                                                            <pre>
                                                                &lt;button className="btn btn-cust-delete-icon"&gt;
                                                                &lt;img src={deleteicon} alt="delete" /&gt;
                                                                &lt;/button&gt;
                                                            </pre>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="Alert">
                                                    <div className="col-md-12">
                                                        <h3 className='text-center'>Text Typography</h3>
                                                        <div className="cust-bdr-material font-materials">

                                                            <Alert variant="success">
                                                                <strong className="mr-1"><i className="far fa-check-circle"></i></strong> Content
                                                            </Alert>

                                                            <Alert variant="danger">
                                                                <strong className="mr-1"><i className="far fa-times-circle"></i></strong> Content
                                                            </Alert>

                                                        </div>
                                                        <div className='cust-bdr-material-code'>
                                                            <pre>   &lt;Alert variant="success"&gt;</pre>
                                                            <pre>        &lt;strong className="mr-1"&gt;&lt;i className="far fa-check-circle"&gt;&lt;/i&gt;&lt;/strong&gt; Content</pre>
                                                            <pre>       &lt;/Alert&gt;</pre>
                                                            <pre>      &lt;Alert variant="danger"&gt;</pre>
                                                            <pre>     &lt;strong className="mr-1"&gt;&lt;i className="far fa-times-circle"&gt;&lt;/i&gt;&lt;/strong&gt; Content</pre>
                                                            <pre>   &lt;/Alert&gt;</pre>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="Heading">
                                                    <div className="col-md-12">
                                                        <h3 className='text-center'>Alerts</h3>
                                                        <div className="cust-bdr-material">
                                                            <img src={MainHeading} alt="Main Heading" className='img-fluid' />
                                                        </div>
                                                        <div className='cust-bdr-material-code'>
                                                            <pre>&lt;div className="dashbaord-pageHeadertitle d-flex flex-wrap justify-content-between align-items-center"&gt;</pre>
                                                            <pre>&lt;h3 className="mb-0 brnd_heading"&gt;Credentials&lt;/h3&gt;</pre>
                                                            <pre>&lt;div className="d-flex"&gt;</pre>
                                                            <pre>&lt;Link className="w-100 d-flex justify-content-end"&gt;</pre>
                                                            <pre>&lt;button className="btn-success-outline-small"&gt;</pre>
                                                            <pre>Add</pre>
                                                            <pre>&lt;/button&gt;</pre>
                                                            <pre>&lt;/Link&gt;</pre>
                                                            <pre>&lt;button className="btn-success-outline-small ml-3"&gt;</pre>
                                                            <pre>&lt;img src={filterIcon} alt="filterIcon" /&gt;Search</pre>
                                                            <pre>&lt;/button&gt;</pre>
                                                            <pre>&lt;/div&gt;</pre>
                                                            <pre>&lt;/div&gt; </pre>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="NewPage">
                                                    <div className="col-md-12">
                                                        <h3 className='text-center'>Create New Page</h3>
                                                        <div className="cust-bdr-material text-center">
                                                            <img src={Newpage} alt="New page create" className='img-fluid-cust' />
                                                        </div>
                                                        <div className='cust-bdr-material-code '>
                                                            <pre>&lt;Container className="innter-container pr-0 over-fl"&gt;</pre>
                                                            <pre>&lt;div className="d-flex flex-column"&gt;</pre>
                                                            <pre>&lt;div className="row row-xs mg-t-0 pd-t-0"&gt;</pre>
                                                            <pre>&lt;div className="col-md-12"&gt;</pre>
                                                            <pre>&lt;div className="scrolling-carousel"&gt;</pre>
                                                            <pre>&lt;Container&gt;</pre>
                                                            <pre>&lt;div className="row row-xs"&gt;</pre>
                                                            <pre>&lt;div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"&gt;&lt;/div&gt;</pre>
                                                            <pre>&lt;div className="col-md-12"&gt;</pre>
                                                            <pre>&lt;div className="dashbaord-pageHeadertitle d-flex flex-wrap justify-content-between align-items-center"&gt;</pre>
                                                            <pre>&lt;h3 className="mb-0 brnd_heading"&gt; Credential &lt;/h3&gt;</pre>
                                                            <pre>&lt;div className="d-flex"&gt;</pre>
                                                            <pre>&lt;Link className="w-100 d-flex justify-content-end"&gt;</pre>
                                                            <pre>&lt;button className="btn-success-outline-small "&gt;</pre>
                                                            <pre>Add</pre>
                                                            <pre>&lt;/button&gt;</pre>
                                                            <pre>&lt;/Link&gt;</pre>
                                                            <pre>&lt;button className="btn-success-outline-small ml-3"&gt;</pre>
                                                            <pre>&lt;img src="" alt="filterIcon" /&gt;</pre>
                                                            <pre>Search</pre>
                                                            <pre>&lt;/button&gt;</pre>
                                                            <pre>&lt;/div&gt;</pre>
                                                            <pre>&lt;/div&gt;</pre>
                                                            <pre>&lt;/div&gt;</pre>
                                                            <pre>&lt;/div&gt;</pre>
                                                            <pre>&lt;/Container&gt;</pre>
                                                            <pre>&lt;Container&gt;</pre>
                                                            <pre>&lt;div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30"&gt;</pre>
                                                            <pre>&lt;/div&gt;</pre>
                                                            <pre>&lt;/Container&gt;</pre>
                                                            <pre>&lt;/div&gt;</pre>
                                                            <pre>&lt;/div&gt;</pre>
                                                            <pre>&lt;/div&gt;</pre>
                                                            <pre>&lt;/div&gt;</pre>
                                                            <pre>&lt;/Container&gt;</pre>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="Form">
                                                    <div className="col-md-12">
                                                        <h3 className='text-center'>Main Heading</h3>
                                                        <div className="cust-bdr-material">
                                                            <img src={Fromimg} alt="Main Heading" className='img-fluid' />
                                                        </div>
                                                        <div className='cust-bdr-material-code'>

                                                            <pre>&lt;div className='row'&gt;</pre>
                                                            <pre>   &lt;div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30"&gt;</pre>
                                                            <pre>   &lt;h3 className="mg-l-5 background-head"&gt;</pre>
                                                            <pre>   Contact</pre>
                                                            <pre>   &lt;/h3&gt;</pre>
                                                            <pre>   &lt;/div&gt;</pre>
                                                            <pre>   &lt;div className="row align-items-center pd-lg-b-0 pd-xs-b-0 border-bottom-0 pd-xs-l-15 pd-xs-r-15 pd-lg-l-15 pd-lg-r-15"&gt;</pre>
                                                            <pre>   &lt;div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30"&gt;</pre>
                                                            <pre>   &lt;label className="form-label text-left pd-b-5"&gt;</pre>
                                                            <pre>   Name</pre>
                                                            <pre>   &lt;/label&gt;</pre>
                                                            <pre>   &lt;Form.Control</pre>
                                                            <pre>   type="text"</pre>
                                                            <pre>   name="property_email"</pre>
                                                            <pre>   maxLength="50"</pre>
                                                            <pre>   /&gt;</pre>
                                                            <pre>   &lt;/div&gt;</pre>
                                                            <pre>   &lt;div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30"&gt;</pre>
                                                            <pre>   &lt;label className="form-label text-left pd-b-5"&gt;</pre>
                                                            <pre>   Phone</pre>
                                                            <pre>   &lt;span className="optional"&gt;</pre>
                                                            <pre>   (Optional)</pre>
                                                            <pre>   &lt;/span&gt;</pre>
                                                            <pre>   &lt;/label&gt;</pre>
                                                            <pre>   &lt;PhoneInput</pre>
                                                            <pre>   disableSearchIcon</pre>
                                                            <pre>   enableSearch="true"</pre>
                                                            <pre>   countryCodeEditable="true"</pre>
                                                            <pre>   /&gt;</pre>
                                                            <pre>   &lt;/div&gt;</pre>
                                                            <pre>   &lt;div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30"&gt;</pre>
                                                            <pre>   &lt;label className="form-label text-left pd-b-5"&gt;</pre>
                                                            <pre>   Email</pre>
                                                            <pre>   &lt;/label&gt;</pre>
                                                            <pre>   &lt;Form.Control</pre>
                                                            <pre>   type="text"</pre>
                                                            <pre>   name="property_email"</pre>
                                                            <pre>   maxLength="50"</pre>
                                                            <pre>   /&gt;</pre>
                                                            <pre>   &lt;/div&gt;</pre>
                                                            <pre>   &lt;/div&gt;</pre>
                                                            <pre>   &lt;/div&gt;</pre>
                                                            <pre>      &lt;hr /&gt;</pre>
                                                            <pre>   &lt;div className="row align-items-center border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-45 pd-lg-r-30"&gt;</pre>
                                                            <pre>   &lt;div className="col-md-4 pl-0"&gt;</pre>
                                                            <pre>   &lt;label className="form-label text-left mg-b-0"&gt;</pre>
                                                            <pre>   Status</pre>
                                                            <pre>   &lt;/label&gt;</pre>
                                                            <pre>   &lt;/div&gt;</pre>
                                                            <pre>   &lt;div className="col-md-8 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start "&gt;</pre>
                                                            <pre>   &lt;b</pre>
                                                            <pre>   className={"active"}</pre>
                                                            <pre>   &gt;&lt;/b&gt;</pre>
                                                            <pre>   &lt;div</pre>
                                                            <pre>   className={"az-toggle"}</pre>
                                                            <pre>   &gt;</pre>
                                                            <pre>   &lt;span&gt;&lt;/span&gt;</pre>
                                                            <pre>   &lt;/div&gt;</pre>
                                                            <pre>   &lt;/div&gt;</pre>
                                                            <pre>   &lt;/div&gt;</pre>



                                                        </div>
                                                    </div>

                                                    <div className="col-md-12  mg-t-30">
                                                        <h3 className='text-center'>Inner Heading with detail view </h3>
                                                        <div className="cust-bdr-material">
                                                            <img src={InnerHeading} alt="Main Heading" className='img-fluid' />
                                                        </div>
                                                        <div className='cust-bdr-material-code'>

                                                            <pre>&lt;div className="row m-0 border-0"&gt;</pre>
                                                            <pre> &lt;div className="col-md-12 p-0"&gt;</pre>
                                                            <pre> &lt;div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 p-0"&gt;</pre>
                                                            <pre> &lt;div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30"&gt;</pre>
                                                            <pre> &lt;p className="az-profile-name-text pl-0"&gt;Phone&lt;/p&gt;</pre>
                                                            <pre>  &lt;p className="media-body"&gt;&lt;span id="n481XzedeGa1"&gt;+1 (602) 350-3015&lt;/span&gt;</pre>
                                                            <pre> &lt;div className="d-none react-tel-input "&gt;</pre>
                                                            <pre> &lt;div className="special-label"&gt;Phone&lt;/div&gt;</pre>
                                                            <pre> &lt;input className="form-control " placeholder="1 (702) 123-4567" type="tel" id="phoneNumberFormatn481XzedeGa1" value="+1 (602) 350-3015"&gt;</pre>
                                                            <pre> &lt;div className="flag-dropdown "&gt;</pre>
                                                            <pre> &lt;div className="selected-flag" title="United States: + 1" tabindex="0" role="button" aria-haspopup="listbox"&gt;</pre>
                                                            <pre> &lt;div className="flag us"&gt;</pre>
                                                            <pre> &lt;div className="arrow"&gt;&lt;/div&gt;</pre>
                                                            <pre> &lt;/div&gt;</pre>
                                                            <pre> &lt;/div&gt;</pre>
                                                            <pre> &lt;/div&gt;</pre>
                                                            <pre> &lt;/div&gt;</pre>
                                                            <pre> &lt;/p&gt;</pre>
                                                            <pre> &lt;/div&gt;</pre>
                                                            <pre> &lt;div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30"&gt;</pre>
                                                            <pre> &lt;p className="az-profile-name-text pl-0"&gt;Email&lt;/p&gt;</pre>
                                                            <pre> &lt;p className="media-body"&gt;dev+651@orionhaus.com&lt;/p&gt;</pre>
                                                            <pre> &lt;/div&gt;</pre>
                                                            <pre> &lt;div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30"&gt;</pre>
                                                            <pre> &lt;p className="az-profile-name-text pl-0"&gt;Maintenance Email&lt;/p&gt;</pre>
                                                            <pre> &lt;p className="media-body"&gt;dev+651@orionhaus.com&lt;/p&gt;</pre>
                                                            <pre> &lt;/div&gt;</pre>
                                                            <pre> &lt;div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30"&gt;</pre>
                                                            <pre> &lt;p className="az-profile-name-text pl-0"&gt;Invoice Email&lt;/p&gt;</pre>
                                                            <pre> &lt;p className="media-body"&gt;dev+651@orionhaus.com&lt;/p&gt;</pre>
                                                            <pre> &lt;/div&gt;</pre>
                                                            <pre> &lt;div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30"&gt;</pre>
                                                            <pre> &lt;p className="az-profile-name-text pl-0"&gt;Asset Manager&lt;/p&gt;</pre>
                                                            <pre> &lt;p className="media-body"&gt;&lt;/p&gt;</pre>
                                                            <pre> &lt;/div&gt;</pre>
                                                            <pre> &lt;/div&gt;</pre>
                                                            <pre> &lt;/div&gt;</pre>
                                                            <pre> &lt;/div&gt;</pre>


                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="Table">
                                                    <div className="col-md-12">
                                                        <h3 className='text-center'>Basic Table</h3>
                                                        <div className="cust-bdr-material">
                                                            <div className="table-responsive pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                                                <Table hover className="mg-b-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="w-30">Day</th>
                                                                            <th className="w-30">Start Time</th>
                                                                            <th className="w-30">End Time</th>
                                                                            <th className="w-10">Closed</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Monday</td>
                                                                            <td>00:00 AM</td>
                                                                            <td>00:00 PM</td>
                                                                            <td>No</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Tuesday</td>
                                                                            <td>00:00 AM</td>
                                                                            <td>00:00 PM</td>
                                                                            <td>No</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Wednesday</td>
                                                                            <td>00:00 AM</td>
                                                                            <td>00:00 PM</td>
                                                                            <td>No</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Thursday</td>
                                                                            <td>00:00 AM</td>
                                                                            <td>00:00 PM</td>
                                                                            <td>No</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Friday</td>
                                                                            <td>00:00 AM</td>
                                                                            <td>00:00 PM</td>
                                                                            <td>No</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Saturday</td>
                                                                            <td>00:00 AM</td>
                                                                            <td>00:00 PM</td>
                                                                            <td>No</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Sunday</td>
                                                                            <td>00:00 AM</td>
                                                                            <td>00:00 PM</td>
                                                                            <td>No</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                        <div className='cust-bdr-material-code'>

                                                            <pre>&lt;Table hover className="mg-b-0"&gt;</pre>
                                                            <pre>   &lt;thead&gt;</pre>
                                                            <pre>   &lt;tr&gt;</pre>
                                                            <pre>   &lt;th className="w-30"&gt;Day&lt;/th&gt;</pre>
                                                            <pre>   &lt;th className="w-30"&gt;Start Time&lt;/th&gt;</pre>
                                                            <pre>   &lt;th className="w-30"&gt;End Time&lt;/th&gt;</pre>
                                                            <pre>   &lt;th className="w-10"&gt;Closed&lt;/th&gt;</pre>
                                                            <pre>   &lt;/tr&gt;</pre>
                                                            <pre>   &lt;/thead&gt;</pre>
                                                            <pre>   &lt;tbody&gt;</pre>
                                                            <pre>   &lt;tr&gt;</pre>
                                                            <pre>   &lt;td&gt;Monday&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 AM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 PM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;No&lt;/td&gt;</pre>
                                                            <pre>   &lt;/tr&gt;</pre>
                                                            <pre>   &lt;tr&gt;</pre>
                                                            <pre>   &lt;td&gt;Tuesday&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 AM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 PM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;No&lt;/td&gt;</pre>
                                                            <pre>   &lt;/tr&gt;</pre>
                                                            <pre>    &lt;td&gt;00:00 PM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 PM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;Wednesday&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 AM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 PM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;No&lt;/td&gt;</pre>
                                                            <pre>   &lt;/tr&gt;</pre>
                                                            <pre>   &lt;tr&gt;</pre>
                                                            <pre>   &lt;td&gt;Thursday&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 AM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 PM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;No&lt;/td&gt;</pre>
                                                            <pre>   &lt;/tr&gt;</pre>
                                                            <pre>   &lt;tr&gt;</pre>
                                                            <pre>   &lt;td&gt;Friday&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 AM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 PM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;No&lt;/td&gt;</pre>
                                                            <pre>   &lt;/tr&gt;</pre>
                                                            <pre>   &lt;tr&gt;</pre>
                                                            <pre>   &lt;td&gt;Saturday&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 AM&lt;/td&gt;</pre>
                                                            <pre>    &lt;td&gt;00:00 PM&lt;/td&gt;</pre>
                                                            <pre>    &lt;td&gt;No&lt;/td&gt;</pre>
                                                            <pre>    &lt;/tr&gt;</pre>
                                                            <pre>    &lt;tr&gt;</pre>
                                                            <pre>    &lt;td&gt;Sunday&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;00:00 AM&lt;/td&gt;</pre>
                                                            <pre>    &lt;td&gt;00:00 PM&lt;/td&gt;</pre>
                                                            <pre>   &lt;td&gt;No&lt;/td&gt;</pre>
                                                            <pre>    &lt;/tr&gt;</pre>
                                                            <pre>    &lt;/tbody&gt;</pre>
                                                            <pre>   &lt;/Table&gt;</pre>


                                                        </div>
                                                    </div>

                                                    <div className="col-md-12  mg-t-30">
                                                        <h3 className='text-center'>Data Table </h3>
                                                        <div className="cust-bdr-material">
                                                            <img src={dataTable} alt="Main Heading" className='img-fluid' />
                                                        </div>
                                                        <div className='cust-bdr-material-code'>
                                                            <pre>   &lt;div className="mg-t-18 brandList"&gt;</pre>
                                                            <pre>        &lt;div className="scrolling-carousel pd-l-0 scroll-slide"&gt;</pre>
                                                            <pre>       &lt;div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30"&gt;</pre>
                                                            <pre>      &lt;div className="mg-t-18 brandList"&gt;</pre>
                                                            <pre>       &lt;div className="pd-lg-l-0 pd-xs-l-15"&gt;</pre>
                                                            <pre>      &lt;ReactDatatable</pre>
                                                            <pre>     className="table mg-b-30 pd-b-2 add-no-wrap table_last_row"</pre>
                                                            <pre>     config={ }</pre>
                                                            <pre>     records={ }</pre>
                                                            <pre>      columns={ }</pre>
                                                            <pre>     extraButtons={ }</pre>
                                                            <pre>     dynamic={true}</pre>
                                                            <pre>      total_record={ }</pre>
                                                            <pre>     onChange={ }</pre>
                                                            <pre>     onRowClicked={ }</pre>
                                                            <pre>      /&gt;</pre>
                                                            <pre>     &lt;/div&gt;</pre>
                                                            <pre>      &lt;/div&gt;</pre>
                                                            <pre>     &lt;/div&gt;</pre>
                                                            <pre>     &lt;/div&gt;</pre>
                                                            <pre>     &lt;/div&gt; </pre>

                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="Tabs">
                                                    <div className="col-md-12">
                                                        <h3 className='text-center'>Tabs</h3>
                                                        <div className="cust-bdr-material">
                                                            <img src={TabsImg} alt="Main Heading" className='img-fluid' />
                                                        </div>
                                                        <div className='cust-bdr-material-code'>


                                                            <pre>  &lt;div className="scrolling-carousel scroll-slide"&gt;</pre>
                                                            <pre>           &lt;Tab.Container</pre>
                                                            <pre>           id="left-tabs-example"</pre>
                                                            <pre>           defaultActiveKey="all"</pre>
                                                            {/* onSelect={(e) => this.getActiveClass(e)}
                                                                   eventKey={this.state.activeClass} */}
                                                            <pre>           className="pd-r-60"</pre>
                                                            <pre>          &gt;</pre>
                                                            <pre>          &lt;ScrollingCarousel</pre>
                                                            <pre>          rightArrow={true}</pre>
                                                            <pre>         leftArrow={true}</pre>
                                                            <pre>         className="swipesection pd-t-20"</pre>
                                                            <pre>          &gt;</pre>
                                                            <pre>          &lt;Nav.Item&gt;</pre>
                                                            <pre>          &lt;Nav.Link</pre>
                                                            <pre>          eventKey="activeTab"</pre>
                                                            <pre>          id="activeTab"</pre>
                                                            {/* className={
                                                                        this.state.activeClass === "activeTab"
                                                                            ? "active"
                                                                            : ""
                                                                    } */}
                                                            <pre>     &gt;</pre>
                                                            <pre>           Active</pre>
                                                            <pre>           &lt;/Nav.Link&gt;</pre>
                                                            <pre>           &lt;/Nav.Item&gt;</pre>

                                                            <pre>          &lt;Nav.Item&gt;</pre>
                                                            <pre>           &lt;Nav.Link</pre>
                                                            <pre>           eventKey="inactiveTab"</pre>
                                                            <pre>          id="inactiveTab"</pre>
                                                            {/* className={
                                                                        this.state.activeClass === "inactiveTab"
                                                                            ? "active"
                                                                            : ""
                                                                    } */}
                                                            <pre>         &gt;</pre>
                                                            <pre>        Inactive</pre>
                                                            <pre>         &lt;/Nav.Link&gt;</pre>
                                                            <pre>        &lt;/Nav.Item&gt;</pre>
                                                            <pre>         &lt;/ScrollingCarousel&gt;</pre>
                                                            <pre>         &lt;Tab.Content&gt;</pre>
                                                            <pre>         &lt;Tab.Pane eventKey="activeTab"&gt;</pre>
                                                            <pre>         &lt;div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30"&gt;</pre>
                                                            <pre>         &lt;/div&gt;</pre>
                                                            <pre>         &lt;/Tab.Pane&gt;</pre>
                                                            <pre>        &lt;Tab.Pane eventKey="inactiveTab"&gt;</pre>
                                                            <pre>         &lt;div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30"&gt;</pre>
                                                            <pre>        &lt;/div&gt;</pre>
                                                            <pre>        &lt;/Tab.Pane&gt;</pre>
                                                            <pre>        &lt;/Tab.Content&gt;</pre>
                                                            <pre>        &lt;/Tab.Container&gt;</pre>
                                                            <pre>         &lt;/div&gt;</pre>

                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="Modal">

                                                    <div className="col-md-12 ">
                                                        <h3 className='text-center'>Small Modal</h3>
                                                        <div className="cust-bdr-material text-center">
                                                            <img src={smallModalImg} alt="Main Heading" className='img-fluid' />
                                                        </div>
                                                        <div className='cust-bdr-material-code'>
                                                            <pre>
                                                                <code>
                                                                    &lt;Modal
                                                                    show='true'
                                                                    className="modal"

                                                                    centered
                                                                    &gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;Modal.Body className="width_402 p-4 border_radius_14"&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;span className="welcomeClose"&gt;
                                                                    {global.closee}
                                                                    &lt;/span&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;h3 className="text-center"&gt;Search in &lt;/h3&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0"&gt;
                                                                    Body
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;div className="d-flex justify-content-center mt-3 row"&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;button
                                                                    type="button"
                                                                    className=" m-0 btn btn-outline-light btn-block"

                                                                    &gt;
                                                                    Cancel
                                                                    &lt;/button&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;button
                                                                    type="button"
                                                                    className=" m-0 btn btn btn-success btn-block"

                                                                    &gt;
                                                                    Search
                                                                    &lt;/button&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;/div&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;/Modal.Body&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;/Modal&gt;
                                                                </code>
                                                            </pre>


                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 mg-t-30">
                                                        <h3 className='text-center'>Large Modal</h3>
                                                        <div className="cust-bdr-material text-center">
                                                            <img src={ModalImg} alt="Main Heading" className='img-fluid' />
                                                        </div>
                                                        <div className='cust-bdr-material-code'>
                                                            <pre>
                                                                <code>
                                                                    &lt;Modal
                                                                    show='true'
                                                                    className="modal-large"

                                                                    centered
                                                                    &gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;Modal.Body className="width_402 p-4 border_radius_14"&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;span className="welcomeClose"&gt;
                                                                    {global.closee}
                                                                    &lt;/span&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;h3 className="text-center"&gt;Search in &lt;/h3&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0"&gt;
                                                                    Body
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;div className="d-flex justify-content-center mt-3 row"&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;button
                                                                    type="button"
                                                                    className=" m-0 btn btn-outline-light btn-block"

                                                                    &gt;
                                                                    Cancel
                                                                    &lt;/button&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;button
                                                                    type="button"
                                                                    className=" m-0 btn btn btn-success btn-block"

                                                                    &gt;
                                                                    Search
                                                                    &lt;/button&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;/div&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;/Modal.Body&gt;
                                                                </code>
                                                            </pre>

                                                            <pre>
                                                                <code>
                                                                    &lt;/Modal&gt;
                                                                </code>
                                                            </pre>


                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Container>

                            {/* <div className='row'>
                                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-5 background-head">
                                        Contact
                                    </h3>
                                </div>
                                <div className="row align-items-center pd-lg-b-0 pd-xs-b-0 border-bottom-0 pd-xs-l-15 pd-xs-r-15 pd-lg-l-15 pd-lg-r-15">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">

                                        <label className="form-label text-left pd-b-5">
                                            Name
                                        </label>
                                        <Form.Control
                                            type="text"
                                            name="property_email"
                                            maxLength="50"
                                        />


                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                            Phone
                                            <span className="optional">
                                                (Optional)
                                            </span>
                                        </label>
                                        <PhoneInput
                                            disableSearchIcon
                                            // autoFormat= "true"
                                            enableSearch="true"
                                            countryCodeEditable="true"
                                        />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                            Email
                                        </label>
                                        <Form.Control
                                            type="text"
                                            name="property_email"
                                            maxLength="50"
                                        />

                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                            Select
                                        </label>
                                        <Select
                                            styles={customStyles}
                                            isClearable={true}
                                            className="multiselect"
                                            menuPlacement="auto"
                                            autoComplete="off"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 2,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: "#fff",
                                                    primary75: "#000",
                                                    primary50: "#000",
                                                    primary25: "#000",
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                            Multi Select
                                        </label>
                                        <Select
                                            isMulti
                                            styles={customStyles}
                                            className="multiselect"
                                            menuPlacement="auto"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 2,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: "#fff",
                                                    primary75: "#000",
                                                    primary50: "#000",
                                                    primary25: "#000",
                                                },
                                            })}
                                        />

                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                                        <ul className="formcheck-section addcheckproperty mg-b-0">
                                            <li>
                                                <label className="form-label text-left pd-b-5 mg-l-0">
                                                    ADA Compliant
                                                </label>
                                                <div className="d-flex mg-l-0">
                                                    <Form.Check
                                                        name="ada_compliant"
                                                        value="1"
                                                        type={"radio"}
                                                        id="1"
                                                        label={"Yes"}
                                                        className={"rdiobox mg-r-20"}
                                                    />
                                                    <Form.Check
                                                        name="ada_compliant"
                                                        value="2"
                                                        checked
                                                        type={"radio"}
                                                        id="2"
                                                        label={"No"}
                                                        className={"rdiobox"}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                                        <ul className="formcheck-section addcheckproperty mg-b-0">
                                            <li>
                                                <label htmlFor="insurance_required" className="form-label text-left pd-b-5 hand-cursor">
                                                    Checkbox
                                                </label>
                                                <Form.Check
                                                    name="insurance_required"
                                                    type={"checkbox"}
                                                    id={`insurance_required`}
                                                    label={""}
                                                    className={"ckbox"}
                                                />
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="row align-items-center border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-45 pd-lg-r-30">
                                    <div className="col-md-4 pl-0">
                                        <label className="form-label text-left mg-b-0">
                                            Status
                                        </label>
                                    </div>

                                    <div className="col-md-8 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                                        <b
                                            className={"active"}
                                        ></b>
                                        <div
                                            className={"az-toggle"}
                                        >
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}



                        </div>
                    </div>
                </Container>
            </main >
        )
    }
}

export default Assets


