///////////////////////////////////////////////////////////
//  Program: Emails.jsx                                  //
//  Application: Emails                                  //
//  Option: add all Emails                               //
//  Developer:  Raj KUmar                                       //
//  Date: 2022-12-05                                     //
///////////////////////////////////////////////////////////

import CrmSettingSidebar from "../common/CrmSettingSidebar";
import ContactSidebar from "../common/ContactSidebar";
import Alerts from "../common/Alerts";
import React, { Component } from "react";
import { Container, Form, Tab, Nav, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import CrmTemplateService from "../../services/CrmTemplateService";
import SimpleReactValidator from "simple-react-validator";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import JoditEditor from "jodit-react";
import { Jodit } from "jodit";
import FileUploadService from "../../services/FileUploadService";
import BrandSidebar from "../common/BrandSidebar";
import ClientSidebar from "../common/ClientSidebar";
import Select from "react-select";
class CrmTemplateForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    const pathStr = props.match.path;
    const pathArray = pathStr.split(":");
    this.state = {
      slug: props.match.params.crmSlug,
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : "",
      brand_slug:
        pathArray[0] === "/brand/view/" ? this.props.match.params.slug : "",
      client_slug:
        pathArray[0] === "/client/view/" ? this.props.match.params.slug : "",
      confirm_back: 0,
      input: {},
      errors: {},
      subjectError: null,
      bodyError: null,
      texttoError: null,
      bodytextError: null,
      templateError: null,
      checkboxError: null,
      isSubmit: false,
      showAlertModal: false,
      is_checkbox: [],
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      selectedTypeList: [],
      selectedAppmessage: [],
      selectedsecondlist: [],
      activeClass: "info",
      selectedHeader: [],
      headervalue: "",
      selectedFooter: [],
      footervalue: "",
      isRefreshlistingImages: false,
      isRefreshlistingAmenities: false,
      isRefreshlistingPrice: false,
      isRefreshlistingPetFee: false,
      isRefreshspecialPrice: false,
      isRefreshunavailableDates: false,
      deleteTowerModal: false,
      status_id: true,
      bodyPopup: false,
      appbodyPopup: false,
      moduleList: global.modulelist,
      moduleTypeList: global.moduleReferlist,
      appmodulelist: global.moduleReferlist,
      subjectVariableList: global.moduleReferlist,
      headerlogo: global.headerlogo,
      crmFooter: global.crmFooter,
      template_desc_info: {
        guest:
          "Message ( Available Merge Fields : [Guest Name] [Confirmation Code] [Check-In] [Check-Out] [Addres] [Unit No] )",
        staff: "Message ( Available Merge Fields : [Staff Name] [Unit No] )",
        resident:
          "Message ( Available Merge Fields : [Resident Name] [Unit No] )",
      },
    };
    this.editorConfig = {
      extraButtons: ["uploadImage", "uploadImageVideo"],
      width: "auto",
      height: "auto",
      minHeight: 400,
      events: {
        afterInit: (instance) => {
          this.jodit = instance;
        },
      },
    };

    this.saveOrUpdateEmail = this.saveOrUpdateEmail.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerck = this.changeHandlerck.bind(this);
    this.openDeleteNearByAttractionModal =
      this.openDeleteNearByAttractionModal.bind(this);
    this.closeDeleteAttrcationModal =
      this.closeDeleteAttrcationModal.bind(this);
    this.setContent = this.setContent.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.openListingPopup = this.openListingPopup.bind(this);
    this.openBodyPopup = this.openBodyPopup.bind(this);
    this.openAppBodyPopup = this.openAppBodyPopup.bind(this);
    this.setContent = this.setContent.bind(this);
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "email") {
      this.setState({ isRefreshlistingImages: true });
    } else if (e === "appMessage") {
      this.setState({ isRefreshlistingAmenities: true });
    } else if (e === "appNotification") {
      this.setState({ isRefreshlistingPrice: true });
    } else if (e === "textMessage") {
      this.setState({ isRefreshlistingPetFee: true });
    }
  }
  
  componentDidMount() {
    this.getDataFromSlug();
    this.uploadImageButton();
    this.uploadVideoButton();
  }

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: "Upload image",
      exec: async (editor) => {
        await this.imageUpload(editor);
      },
    };
  };

  imageUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", imageFile, imageFile.name);
      fileData.append("fileName", imageFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertImage(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editor.selection.insertNode(image);
  };

  uploadVideoButton = () => {
    Jodit.defaultOptions.controls.uploadImageVideo = {
      name: "Upload video",
      exec: async (editor) => {
        await this.videoUpload(editor);
      },
    };
  };

  videoUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = async function () {
      const videoFile = input.files[0];

      if (!videoFile) {
        return;
      }

      if (!videoFile.name.match(/\.(mp3|mp4|mov)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", videoFile, videoFile.name);
      fileData.append("fileName", videoFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertVideo(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertVideo = (editor, url) => {
    const video = editor.selection.j.createInside.element("video");
    video.setAttribute("src", url);
    editor.selection.insertNode(video);
  };

  validate() {
    let subjectError = "";
    let bodyError = "";
    let templateError = "";
    let checkboxError = "";
    let headerError = "";
    let footerError = "";
    if (!this.state.template_name) {
      templateError = "This field is required.";
    }
    if (this.state.is_email_enable === true) {
      if (!this.state.subject) {
        subjectError = "This field is required.";
      }
    }
    if (this.state.slug !== undefined) {
      if (this.state.selectedHeader.length === 0) {
        headerError = "This field is required.";
      }
    } else {
      if (!this.state.selectedHeader.value) {
        headerError = "This field is required.";
      }
    }
    if (this.state.slug !== undefined) {
      if (this.state.selectedFooter.length === 0) {
        footerError = "This field is required.";
      }
    } else {
      if (!this.state.selectedFooter.value) {
        footerError = "This field is required.";
      }
    }

    if (
      subjectError ||
      bodyError ||
      templateError ||
      checkboxError ||
      headerError ||
      footerError
    ) {
      this.setState({
        subjectError,
        bodyError,
        templateError,
        checkboxError,
        headerError,
        footerError,
      });
      return false;
    }
    return true;
  }

  saveOrUpdateEmail = async (e) => {
    e.preventDefault();
    this.setState({
      alertModalMessage: "",
    });

    if (this.validate()) {
      this.setState({ loading: true, isSubmit: true });
      let inputData = {
        template_name: this.state.template_name ? this.state.template_name : "",
        template_desc: this.state.template_desc ? this.state.template_desc : "",
        subject: this.state.subject ? this.state.subject : "",
        email_body:
          this.state.email_body !== "" &&
          this.state.email_body !== "<p><br></p>"
            ? this.state.email_body
            : "",
        app_message_body: this.state.app_message_body
          ? this.state.app_message_body
          : "",
        is_message_enable: this.state.is_message_enable ? 1 : 0,
        is_email_enable: this.state.is_email_enable ? 1 : 0,
        is_notification_enable: this.state.is_notification_enable ? 1 : 0,
        is_text_message_enable: this.state.is_text_message_enable ? 1 : 0,
        status_id: this.state.status_id ? this.state.status_id : 2,
        propertySlug: this.state.property_slug ? this.state.property_slug : "",
        brandSlug: this.state.brand_slug ? this.state.brand_slug : "",
        clientSlug: this.state.client_slug ? this.state.client_slug : "",
        crmType: this.state.property_slug
          ? 2
          : this.state.brand_slug
          ? 3
          : this.state.client_slug
          ? 4
          : 1,
        header_logo_temp: this.state.headervalue
          ? this.state.headervalue
          : this.state.selectedHeader[0].value,
        footer_temp: this.state.footervalue
          ? this.state.footervalue
          : this.state.selectedFooter[0].value,
      };

      if (this.state.slug !== undefined) {
        this.setState({ loading: true, isSubmit: true });
        let res = await CrmTemplateService.updateCrmTemplate(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.state.property_slug
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/crm-templates"
                  : this.state.brand_slug
                  ? "/brand/view/" + this.state.brand_slug + "/crm-templates"
                  : this.state.client_slug
                  ? "/client/view/" + this.state.client_slug + "/crm-templates"
                  : "/crm-templates"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        this.setState({ loading: true, isSubmit: true });
        let res = await CrmTemplateService.createCrmTemplate(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.state.property_slug
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/crm-templates"
                  : this.state.brand_slug
                  ? "/brand/view/" + this.state.brand_slug + "/crm-templates"
                  : this.state.client_slug
                  ? "/client/view/" + this.state.client_slug + "/crm-templates"
                  : "/crm-templates"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ loading: false, isSubmit: false });
      this.validator.showMessages();
    }
  };

  async getDataFromSlug() {
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await CrmTemplateService.getCrmTemplate(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.template_name = resData.template_name
          ? resData.template_name
          : "";
        resData.template_desc = resData.template_desc
          ? resData.template_desc
          : "";
        resData.crmType = resData.crm_type
          ? resData.crm_type
          : resData.crm_type;
        resData.propertySlugs = resData.property_slug
          ? resData.property_slug
          : "";
        resData.brandSlugs = resData.brand_slug ? resData.brand_slug : "";
        resData.clientSlugs = resData.client_slug ? resData.client_slug : "";
        resData.is_email_enable = res.data.is_email_enable
          ? res.data.is_email_enable
          : "";
        resData.is_message_enable = res.data.is_message_enable
          ? res.data.is_message_enable
          : "";
        resData.is_notification_enable = res.data.is_notification_enable
          ? res.data.is_notification_enable
          : "";
        resData.is_text_message_enable = res.data.is_text_message_enable
          ? res.data.is_text_message_enable
          : "";
        resData.selectedHeader =
          res.data.header_logo_temp === 1
            ? [{ key: 1, value: 1, label: "Property" }]
            : res.data.header_logo_temp === 2
            ? [{ key: 2, value: 2, label: "Orion Haus" }]
            : [];
        resData.selectedFooter =
          res.data.footer_temp === 1
            ? [{ key: 1, value: 1, label: "Show" }]
            : res.data.footer_temp === 2
            ? [{ key: 2, value: 2, label: "Hide" }]
            : [];
      }
      this.setState({
        is_checkbox: [
          ...this.state.is_checkbox,
          ...[resData.is_email_enable, resData.is_message_enable],
        ],
      });
      this.setState(resData);
    }
  }

  openListingPopup = () => {
    if (this.state.listingPopup === true) {
      this.setState({ listingPopup: false });
    } else {
      this.setState({ listingPopup: true });
    }
  };

  openBodyPopup = () => {
    if (this.state.bodyPopup === true) {
      this.setState({ bodyPopup: false });
    } else {
      this.setState({ bodyPopup: true });
    }
  };

  openAppBodyPopup = () => {
    if (this.state.appbodyPopup === true) {
      this.setState({ appbodyPopup: false });
    } else {
      this.setState({ appbodyPopup: true });
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? value : value;

    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
    });

    if (input.template_name !== "") {
      this.setState({ templateError: null });
    }
    if (input.subject !== "") {
      this.setState({ subjectError: null });
    }
    if (input.app_message_body !== "") {
      this.setState({ bodyError: null });
    }

    if (input.is_email_enable === true || input.is_message_enable === true) {
      this.setState({ checkboxError: null });
    }
    if (this.state.slug === undefined) {
      var dayArr = [...this.state.is_checkbox];
      var checked = event.target.checked;
      if (checked === false) {
        dayArr = [...dayArr.slice(0, -1)];
      } else if (checked === true) {
        dayArr.push(value);
      }
      this.setState({ is_checkbox: dayArr });
    }
    this.setState({ confirm_back: 1 });
  };

  handleChangemodulereferListemail(value) {
    let newTypeList12 = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList12.push(item.value);
      });
    }
    let dynmicvariable = value.label;
    this.setState({
      selectedModulleRferList: value,
      dynmicvariable: dynmicvariable,
    });
  }

  insertbodyemail = (e) => {
    var dynamictext = this.state.body_variable;
    this.jodit.selection.insertHTML("[" + dynamictext + "]");
    this.setState({
      bodyPopup: false,
      body_variable: "",
    });
  };

  handleChangeSubjectVariableList(value) {
    let newTypeList13 = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList13.push(item.value);
      });
    }
    let dynmicvariablesbject = value.label;
    this.setState({
      selectedSubjectList: value,
      dynmicvariablesbject: dynmicvariablesbject,
    });
  }

  insertsubject = (e) => {
    let textToInsert = " [" + this.state.subject_variable + "]";
    this.setState({
      subject: this.state.subject
        ? `${this.state.subject} ${textToInsert}`
        : `${textToInsert}`,
    });
    this.setState({ listingPopup: false });
  };

  handleChangeBodyVariableAppMessage(value) {
    let newTypeList13 = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList13.push(item.value);
      });
    }
    let dynmicvariablesbject = value.label;
    this.setState({
      selectedappmessagevariable: value,
      dynmicvariablesbject: dynmicvariablesbject,
    });
  }

  insertappmessage = (e) => {
    let textToInsert = " [" + this.state.app_body_variable + "]";
    this.setState({
      app_message_body: this.state.app_message_body
        ? `${this.state.app_message_body} ${textToInsert}`
        : `${textToInsert}`,
    });
    this.setState({
      appbodyPopup: false,
      app_body_variable: "",
    });
  };

  handleChangetextmessagevariable(value) {
    let newTypeList13 = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList13.push(item.value);
      });
    }
    let dynmicvariablesbject = value.label;
    this.setState({
      selectedtextmessagevariable: value,
      dynmicvariablesbject: dynmicvariablesbject,
    });
  }

  handleChangeHeader(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedHeader: value,
      headervalue: type,
    });
    this.setState({ headerError: "" });
  }

  handleChangeFooter(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedFooter: value,
      footervalue: type,
    });
    this.setState({ footerError: "" });
  }

  openDeleteNearByAttractionModal(slug) {
    this.setState({ deleteNearByAttractionModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteAttrcationModal() {
    this.setState({ deleteNearByAttractionModal: false });
    this.setState({ slug: "" });
  }

  async deleteCredential(slug) {
    this.setState({ showSpinner: true });
    let res = await CrmTemplateService.deleteCrmTemplate(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAttrcationModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.state.property_slug
              ? "/properties/view/" +
                  this.state.property_slug +
                  "/crm-templates"
              : this.state.brand_slug
              ? "/brand/view/" + this.state.brand_slug + "/crm-templates"
              : this.state.client_slug
              ? "/client/view/" + this.state.client_slug + "/crm-templates"
              : "/crm-templates"
          ),
        global.redirect_time
      );
    } else {
      this.closeDeleteAttrcationModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandlerck(event, index) {
    let value = event.target.value;

    this.setState({
      email_body: value,
    });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
  }

  setContent(value) {
    this.setState({
      email_body: value,
    });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    {this.state.property_slug ? (
                      <ContactSidebar
                        property_slug={this.state.property_slug}
                      />
                    ) : this.state.brand_slug ? (
                      <BrandSidebar brand_slug={this.state.brand_slug} />
                    ) : this.state.client_slug ? (
                      <ClientSidebar clientSlug={this.state.client_slug} />
                    ) : (
                      <CrmSettingSidebar />
                    )}
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel">
                  <div className="col-md-12 mg-t-0 mg-b-0 pd-t-0 pd-b-0 pd-l-15 pd-r-0">
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Templates</h3>

                          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                            {this.state.slug !== undefined ? (
                              <>
                                {global.userPermissions.checkPermission(
                                  "crm-templates-delete"
                                ) && (
                                  <span
                                    onClick={() => {
                                      this.openDeleteNearByAttractionModal(
                                        this.state.slug
                                      );
                                    }}
                                    className="deleteicon mg-r-20"
                                  >
                                    <img src={deleteicon} alt="" />
                                  </span>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <Link
                              to={
                                this.state.property_slug
                                  ? "/properties/view/" +
                                    this.state.property_slug +
                                    "/crm-templates"
                                  : this.state.brand_slug
                                  ? "/brand/view/" +
                                    this.state.brand_slug +
                                    "/crm-templates"
                                  : this.state.client_slug
                                  ? "/client/view/" +
                                    this.state.client_slug +
                                    "/crm-templates"
                                  : "/crm-templates"
                              }
                            >
                              <button className="btn-success-outline mg-r-20">
                                Cancel
                              </button>
                            </Link>
                            <button
                              type="button"
                              className="btn-success-outline-small ml-3 button-width"
                              onClick={this.saveOrUpdateEmail}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : this.state.slug
                                ? "Save"
                                : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pd-lg-l-0 pd-lg-t-0 ">
                    <div className="table-responsive roles">
                      <div className="col-md-12 pd-lg-l-15 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0 mg-t-0 mt-3">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="info"
                          onSelect={(e) => this.getActiveClass(e)}
                          eventKey={this.state.activeClass}
                        >
                          <ScrollingCarousel
                            rightArrow={true}
                            leftArrow={true}
                            className="swipesection scroll_nav_mob"
                          >
                            <Nav.Item>
                              <Nav.Link
                                eventKey="info"
                                id="infoForm"
                                className={
                                  this.state.activeClass === "info"
                                    ? "active"
                                    : ""
                                }
                              >
                                Info
                              </Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                              <Nav.Link
                                eventKey="email"
                                id="email"
                                className={
                                  this.state.activeClass === "email"
                                    ? "active"
                                    : ""
                                }
                              >
                                Email
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="appMessage"
                                id="appMessage"
                                className={
                                  this.state.activeClass === "appMessage"
                                    ? "active"
                                    : ""
                                }
                              >
                                App Message
                              </Nav.Link>
                            </Nav.Item>
                          </ScrollingCarousel>
                          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                            <Alerts
                              show={this.state.showAlertModal}
                              type={this.state.alertModalType}
                              title={this.state.alertModalTitle}
                              message={this.state.alertModalMessage}
                            />
                          </div>
                          <Tab.Content>
                            <Tab.Pane eventKey="info">
                              <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                <h3 className="mg-l-5 mg-t-15 background-head d-flex  align-items-center justify-content-start">
                                  Info
                                </h3>
                              </div>
                              <div className="row align-items-center pd-r-15 pd-l-15 pd-b-0 border-bottom-0 mg-t-30">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                  <label className="form-label text-left ">
                                    Template{" "}
                                  </label>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="template_name"
                                    value={this.state.template_name}
                                    maxLength="50"
                                    autoComplete="off"
                                  />
                                  <div className="text-danger">
                                    {this.state.templateError}
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center pd-r-15 pd-l-15 pd-b-0 border-bottom-0 ">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                  <label className="form-label text-left ">
                                    Description{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="template_desc"
                                    value={this.state.template_desc}
                                    className="form-control max_width_100"
                                    id="description"
                                    rows="5"
                                    as="textarea"
                                  />
                                </div>
                              </div>
                              <div className="row align-items-center pd-r-15 pd-l-15 pd-b-0 border-bottom-0 mg-t-15">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-8 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                  <div className="col-md-1 pl-0">
                                    <label className="form-label text-left pd-b-5">
                                      Status
                                    </label>
                                  </div>

                                  <div className="col-8 col-md-7 col-lg-7 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                                    <b
                                      className={
                                        this.state.status_id
                                          ? "active"
                                          : "inactive"
                                      }
                                    ></b>
                                    <div
                                      className={
                                        this.state.status_id
                                          ? "az-toggle on"
                                          : "az-toggle"
                                      }
                                      onClick={() => this.changeStatus()}
                                    >
                                      <span></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="email">
                              <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                <h3 className="mg-l-5 mg-t-15 background-head d-flex  align-items-center justify-content-start">
                                  <div>
                                    <Form.Check
                                      onChange={this.changeHandler}
                                      name="is_email_enable"
                                      checked={this.state.is_email_enable}
                                      type={"checkbox"}
                                      id="emailcheckbox"
                                      value="1"
                                      label={""}
                                      className={"ckbox mg-r-10"}
                                    />
                                  </div>
                                  Email
                                </h3>
                              </div>
                              {this.state.is_email_enable === true ||
                              this.state.is_email_enable === 1 ? (
                                <>
                                  <div className="mg-t-30 brandList table_issue_roles cred_drop_issue">
                                    <div className=" ">
                                      <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-45">
                                          <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                            <label className="form-label text-left">
                                              Subject{" "}
                                            </label>
                                          </div>
                                          <Form.Control
                                            onChange={this.changeHandler}
                                            type="text"
                                            name="subject"
                                            value={this.state.subject}
                                            autoComplete="off"
                                            id="subjecttext"
                                          />
                                          <div className="text-danger">
                                            {this.state.subjectError}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-45">
                                          <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                            <label className="form-label text-left">
                                              Header Logo{" "}
                                            </label>
                                          </div>
                                          <Select
                                            styles={customStyles}
                                            //isClearable={true}
                                            className="multiselect"
                                            menuPlacement="auto"
                                            value={this.state.selectedHeader}
                                            options={this.state.headerlogo}
                                            onChange={(value) =>
                                              this.handleChangeHeader(value)
                                            }
                                            defaultValue={
                                              this.state.selectedHeader
                                            }
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 2,
                                              colors: {
                                                ...theme.colors,
                                                primary: "#fff",
                                                primary75: "#000",
                                                primary50: "#000",
                                                primary25: "#000",
                                              },
                                            })}
                                          />
                                          <div className="text-danger">
                                            {this.state.headerError}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-45">
                                          <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                            <label className="form-label text-left">
                                              Footer{" "}
                                            </label>
                                          </div>
                                          <Select
                                            styles={customStyles}
                                            //isClearable={true}
                                            className="multiselect"
                                            menuPlacement="auto"
                                            value={this.state.selectedFooter}
                                            options={this.state.crmFooter}
                                            onChange={(value) =>
                                              this.handleChangeFooter(value)
                                            }
                                            defaultValue={
                                              this.state.selectedFooter
                                            }
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 2,
                                              colors: {
                                                ...theme.colors,
                                                primary: "#fff",
                                                primary75: "#000",
                                                primary50: "#000",
                                                primary25: "#000",
                                              },
                                            })}
                                          />
                                          <div className="text-danger">
                                            {this.state.footerError}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30 jodit_cnt_box">
                                          <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                            <label className="form-label text-left">
                                              Body{" "}
                                            </label>
                                            <button
                                              className="btn btn_var_temp mg-b-5"
                                              onClick={this.openBodyPopup}
                                            >
                                              Body Variable
                                            </button>
                                          </div>
                                          <JoditEditor
                                            config={this.editorConfig}
                                            ref={null}
                                            name="email_body"
                                            id="email_body"
                                            value={this.state.email_body}
                                            onBlur={(newContent) =>
                                              this.setContent(newContent)
                                            }
                                            onChange={(newContent) =>
                                              this.setContent(newContent)
                                            }
                                          />

                                          {this.state.errors.email_body ? (
                                            <div className="text-danger">
                                              {this.state.errors.email_body}
                                            </div>
                                          ) : (
                                            this.validator.message(
                                              "body",
                                              this.state.email_body,
                                              "required",
                                              { className: "text-danger" }
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="appMessage">
                              <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                <h3 className="mg-l-5 mg-t-15 background-head d-flex align-items-center justify-content-start">
                                  <div>
                                    <Form.Check
                                      onChange={this.changeHandler}
                                      name="is_message_enable"
                                      checked={this.state.is_message_enable}
                                      type={"checkbox"}
                                      id="appmessagecheckbox"
                                      value="1"
                                      label={""}
                                      className={"ckbox mg-r-10"}
                                    />
                                  </div>
                                  App Message
                                </h3>
                              </div>
                              {this.state.is_message_enable === true ||
                              this.state.is_message_enable === 1 ? (
                                <>
                                  <div className="mg-t-30 brandList table_issue_roles cred_drop_issue">
                                    <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                        <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                          <label className="form-label text-left pd-b-0  ">
                                            Body{" "}
                                          </label>
                                          <button
                                            className="btn btn_var_temp mg-b-5"
                                            onClick={this.openAppBodyPopup}
                                          >
                                            Body Variable
                                          </button>
                                        </div>
                                        <Form.Control
                                          onChange={this.changeHandler}
                                          name="app_message_body"
                                          value={this.state.app_message_body}
                                          className="form-control max_width_100"
                                          type="text"
                                          rows="13"
                                          as="textarea"
                                          autoComplete="off"
                                        />
                                        <div className="text-danger">
                                          {this.state.bodyError}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Modal show={this.state.deleteNearByAttractionModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAttrcationModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCredential(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.bodyPopup}
          className=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.openBodyPopup()}>
              {global.closee}
            </span>
            <h3 className="text-center">Insert Body Variable</h3>
            <div className="row col-12 m-0">
              <div className="var_tmp_modal  mg-t-10">
                <div className="var_tmp_box">
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="first_name"
                        type={"radio"}
                        id="5"
                        label={"first_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="last_name"
                        type={"radio"}
                        id="6"
                        label={"last_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>

                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="property_name"
                        type={"radio"}
                        id="9"
                        label={"property_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="homesharing_inquiry"
                        type={"radio"}
                        id="10"
                        label={"homesharing_inquiry"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.openBodyPopup()}
              >
                Cancel
              </button>
              {this.state.body_variable ? (
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                  onClick={this.insertbodyemail}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Insert"}
                </button>
              ) : (
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                >
                  {this.state.isSubmit ? global.loader : "Insert"}
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.appbodyPopup}
          className=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.openAppBodyPopup()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Insert App Body Variable</h3>
            <div className="row col-12 m-0">
              <div className="var_tmp_modal pb-0  mg-t-10">
                <div className="var_tmp_box">
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="first_name"
                        type={"radio"}
                        id="5"
                        label={"first_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="last_name"
                        type={"radio"}
                        id="6"
                        label={"last_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>

                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="property_name"
                        type={"radio"}
                        id="9"
                        label={"property_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="homesharing_inquiry"
                        type={"radio"}
                        id="10"
                        label={"homesharing_inquiry"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.openAppBodyPopup()}
              >
                Cancel
              </button>
              {this.state.app_body_variable ? (
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                  onClick={this.insertappmessage}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Insert"}
                </button>
              ) : (
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                >
                  {this.state.isSubmit ? global.loader : "Insert"}
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default CrmTemplateForm;
