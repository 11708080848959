import React, { useState, useEffect } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import { Form } from "react-bootstrap";
import NoData from "../../common/NoData";
import TurnOverBnbService from "../../../services/TurnOverBnbService";
import SettingsSidebar from "../../common/SettingsSidebar";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import Alerts from "../../common/Alerts";
import TbnbCleanerList from "./TbnbCleanerList";

const TBNBMapping = (props) => {
  const [units, setUnits] = useState([]);
  const [isSubmit, setSubmit] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertModalType, setAlertModalType] = useState("");
  const [alertModalTitle, setAlertModalTitle] = useState("");
  const [alertModalMessage, setAlertModalMessage] = useState("");
  // const [columnLength, setColumnLength] = useState(["1"]);
  const [activeClass, setActiveClass] = useState("tbnb-mapping");

  useEffect(() => {
    getUnitData();
  }, []);

  function getActiveClass(e) {
    setActiveClass(e);
  }

  function changeHandler(event, index) {
    event.preventDefault();
    let value = event.target.value ? event.target.value : "";
    let mapData = [...units];
    let name1 = event.target.name ? event.target.name : "";
    mapData[index][name1] = value;
    setUnits(mapData);
  }

  const getUnitData = async () => {
    var list = [];
    let res = await TurnOverBnbService.getUnitData(
      "property_slug=" + props.match.params.propertySlug
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : {};
    }
    setUnits(list);
    // if (list.length > 1) {
    //   setColumnLength(["1"]);
    // }
  };

  const saveOrUpdateUnits = async (event) => {
    event.preventDefault();
    setSubmit(true);
    let res = [];
    let inputData = {
      property_slug: props.match.params.propertySlug,
      units: units,
    };

    res = await TurnOverBnbService.mappingUnits(inputData);
    if (global.successStatus.includes(res.status)) {
      setSubmit(false);
      setShowAlertModal(true);
      setAlertModalType("success");
      setAlertModalTitle("Success");
      setAlertModalMessage(res.message ? res.message : "Success");
      getUnitData();
      setTimeout(() => setShowAlertModal(false), global.alert_close_time);
    }
  };

  async function mapUnit(e, unitSlug) {
    let res = [];
    let inputData = {
      unit_slug: unitSlug,
    };
    res = await TurnOverBnbService.mappUnits(inputData);
    if (global.successStatus.includes(res.status)) {
      getUnitData();
      setSubmit(false);
    }
  }

  return (
    <main>
      <Container className="innter-container">
        <div className="d-flex flex-column">
          <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
            <div className="col-md-2 left">
              <div className="child-menu-section">
                <SettingsSidebar
                  property_slug={props.match.params.propertySlug}
                />
              </div>
            </div>
            <div className="col-md-10 right">
              <div className="col-md-12 mg-t-20 mg-b-10"></div>
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns">
                  <h3>TurnoverBnB Mapping </h3>
                  {global.userPermissions.checkPermission(
                    "turnoverbnb-mapping-add"
                  ) && (
                    <button
                      className="btn-success ml-3"
                      type="button"
                      disabled={isSubmit ? true : false}
                      onClick={saveOrUpdateUnits}
                    >
                      {isSubmit ? global.loader : "Save"}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-12 pd-lg-l-45 pd-lg-r-30 pd-xs-r-0 mg-t-15 keycafe-alert-sticky">
                <Alerts
                  show={showAlertModal}
                  type={alertModalType}
                  title={alertModalTitle}
                  message={alertModalMessage}
                />
              </div>

              <div className="scrolling-carousel pd-l-15">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="tbnb-mapping"
                  onSelect={(e) => getActiveClass(e)}
                  eventKey={activeClass}
                >
                  <ScrollingCarousel
                    rightArrow={true}
                    leftArrow={true}
                    className="swipesection scroll_nav_mob"
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="tbnb-mapping"
                        id="infoForm"
                        className={
                          activeClass === "tbnb-mapping" ? "active" : ""
                        }
                      >
                        TBnB Mapping Details
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        eventKey="cleaner-list"
                        id="cleaner-list"
                        className={
                          activeClass === "cleaner-list" ? "active" : ""
                        }
                      >
                        Cleaner List
                      </Nav.Link>
                    </Nav.Item>
                  </ScrollingCarousel>
                  <Tab.Content>
                    <Tab.Pane eventKey="tbnb-mapping">
                      <div className="pd-l-15 pd-r-15">
                        {/* <div className="row align-items-center border-bottom-0">
                          {columnLength.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                  <label className="form-label text-left mg-b-0">
                                    Unit
                                  </label>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                  <label className="form-label text-left mg-b-0">
                                    TurnoverBnB Property ID
                                  </label>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                  <label className="form-label text-left mg-b-0">
                                    TurnoverBnB Cleaner ID
                                  </label>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div> */}
                        <div
                          className="row align-items-center border-bottom-0 mg-t-25
                             mapp_head_desk"
                        >
                          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left mg-b-0">
                              Unit
                            </label>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left mg-b-0">
                              Turno Account ID
                            </label>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left mg-b-0">
                              Cleaner ID
                            </label>
                          </div>
                        </div>
                        <div className="row align-items-center border-bottom-0">
                          {units.length > 0 ? (
                            units.map((item, index) => {
                              return (
                                <>
                                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-lg-r-30 pd-xs-r-15 mg-b-15 ">
                                    <label className="form-label text-left mg-b-0 mapp_head_mob">
                                      Unit
                                    </label>
                                    <div className="text_btn_both">
                                      <Form.Control
                                        name="unit_number"
                                        value={
                                          item.unit_name
                                            ? item.unit_name
                                            : item.unit_number
                                        }
                                        disabled={true}
                                        className="form-control max_width_100"
                                        type="text"
                                      />
                                      {global.userPermissions.checkPermission(
                                        "turnoverbnb-mapping-add"
                                      ) &&
                                        item.mapping_status !== 1 && (
                                          <button
                                            type="button"
                                            className="btn btn-sync"
                                            disabled={
                                              item.mapping_status === 1
                                                ? true
                                                : false
                                            }
                                            onClick={(e) =>
                                              mapUnit(e, item.unit_slug)
                                            }
                                          >
                                            <i className="fas fa-sync"></i>
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-lg-r-30 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left mg-b-0 mapp_head_mob">
                                      Turno Account ID
                                    </label>
                                    <Form.Control
                                      onChange={(e) => changeHandler(e, index)}
                                      name="key_id"
                                      value={item.key_id}
                                      className="form-control max_width_100"
                                      type="text"
                                      disabled={false}
                                    />
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-lg-r-30 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left mg-b-0 mapp_head_mob">
                                      Cleaner ID
                                    </label>
                                    <Form.Control
                                      onChange={(e) => changeHandler(e, index)}
                                      name="tbnb_cleaner_id"
                                      value={item.tbnb_cleaner_id}
                                      className="form-control max_width_100"
                                      type="text"
                                    />
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <NoData msg="units" />
                          )}
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="cleaner-list">
                      <TbnbCleanerList
                        propertySlug={props.match.params.propertySlug}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>{" "}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </main>
  );
};

export default TBNBMapping;
