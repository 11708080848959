////////////////////////////////////////////////////////////
//     							                          //
//  Program: LeasingFees.jsx                              //
//  Application: Leasing Fees                             //
//  Option: List all Leasing Fees                         //
//  Developer: Np						                              //
//  Date: 2022-05-18                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import PropertyCostService from "../../services/PropertyCostService";
import PetPolicyService from "../../services/PetPolicyService";
import { Form, Table, InputGroup, Col } from "react-bootstrap";
import Alerts from "../common/Alerts";
import TaxService from "../../services/TaxService";
import CostTypeService from "../../services/CostTypeService";
import PetTypeService from "../../services/PetTypeService";
// import ListingService from "../../services/ListingService";
import CostPayableByService from "../../services/CostPayableByService";
import CostPayableAtService from "../../services/CostPayableAtService";
import deleteicon from "../../assets/images/delete.svg";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import pluswIcon from "./../../assets/images/plus.svg";
import NumberFormat from "react-number-format";
import Select from "react-select";

export class LeasingFees extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      open: global.showOpen,
      eventKey: "",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      listing_slug: this.props.listing_slug,
      property_slug: this.props.property_slug,
      isSubmit: false,
      cost_name: "",
      estimated: false,
      mandatory: false,
      refundable: false,
      // sort_order: "",
      pathname: window.location.pathname,
      costTypeList: [],
      newCostTypeList: [],
      newSelectedCostTypeList: [],
      costPayableAtList: [],
      newCostPayableAtList: [],
      selectedCostPayableAtList: [],
      costPayableByList: [],
      newCostPayableByList: [],
      selectedCostPayableByList: [],
      selectedCostTypeList: [],
      alreadySelectedCostTypeList: [],
      costList: [],
      costListTotal: 0,
      taxList: [],
      taxListTotal: 0,
      cost_slug: "",
      confirm_back: 0,
      cost_data: [],
      FeeTypeDataTotal: 1,
      FeeTypeData: [
        {
          cost_type_slug: "",
          cost_payable_by_slug: "",
          cost_payable_at_slug: "",
          cost_name: "",
          refundable: 0,
          estimated: 0,
          mandatory: 0,
        },
      ],
      is_fee_error: [
        {
          cost_type_slug: "",
          cost_payable_by_slug: "",
          cost_payable_at_slug: "",
          cost_name: "",
          refundable: 0,
          estimated: 0,
          mandatory: 0,
        },
      ],
      petPolicyTypeDataTotal: 1,
      petPolicyTypeData: [
        {
          pet_type_slug: "",
          allowed_pet_number: "",
          deposite_amount_per_pet: "",
          one_time_fee_per_type: "",
          rent_per_type: "",
          weight_per_type: "",
          policy_slug: "",
        },
      ],
      pet_data: [],
      pet_type_slug: [],
      newPetTypeList: [],
      petTypeListDropdown: [],
      selectedPetTypeListDropdown: [],
      selectedPetTypeList: [],
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateCost = this.saveOrUpdateCost.bind(this);
    // this.saveOrUpdateFees = this.saveOrUpdateFees.bind(this);
    this.changeView = this.changeView.bind(this);
    this.deleteCost = this.deleteCost.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getData();
    this.getPetPolicyData();
    // this.getSTRData();
    this.getTaxData();
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    var costList = [];
    var costListTotal = 0;
    var FeeTypeDataTotal = 0;

    let res = await PropertyCostService.getAllCosts(
      this.props.listing_slug + "/6"
    );
    if (global.successStatus.includes(res.status)) {
      costList = res.data ? res.data : "";
      costListTotal = costList ? costList.length : 0;

      const values = [];
      if (costListTotal > 0) {
        costList.forEach((item, i) => {
          values.push({
            slug: item.slug ? item.slug : "",
            cost_type_slug: item.cost_type ? item.cost_type.slug : "",
            cost_payable_by_slug: item.cost_payable_by
              ? item.cost_payable_by.slug
              : "",
            cost_payable_at_slug: item.cost_payable_at
              ? item.cost_payable_at.slug
              : "",
            cost_name: item.cost_name
              ? parseFloat(item.cost_name)
                  .toFixed(2)
                  .replace(/\.00$/, "")
                  .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
              : "",
            refundable: item.refundable ? item.refundable : 0,
            estimated: item.estimated ? item.estimated : 0,
            mandatory: item.mandatory ? item.mandatory : 0,
            selected_cost_type: {
              value: item.cost_type.slug,
              label: item.cost_type.cost_type_name,
            },
            selected_cost_by: {
              value: item.cost_payable_by.slug,
              label: item.cost_payable_by.cost_payable_by_name,
            },
            selected_cost_at: {
              value: item.cost_payable_at.slug,
              label: item.cost_payable_at.cost_payable_at_name,
            },
          });
        });
      } else {
        values.push({
          cost_type_slug: "",
          cost_payable_by_slug: "",
          cost_payable_at_slug: "",
          cost_name: "",
          refundable: 0,
          estimated: 0,
          mandatory: 0,
        });
      }
      FeeTypeDataTotal = values.length;
      this.setState({
        FeeTypeData: values,
        FeeTypeDataTotal: FeeTypeDataTotal,
      });
    }
    this.setState({ costList: costList, costListTotal: costListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get pet type data list */
  async getPetTypeData(queryString = "") {
    // this.setState({ showSpinner: true, loading: true });
    var petTypeList = [];
    var petTypeListTotal = 0;

    let res = await PetTypeService.getPetType(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      petTypeList = res.data.data ? res.data.data : [];
      petTypeListTotal = petTypeList ? petTypeList.length : 0;
    }
    const newPetTypeList =
      petTypeListTotal > 0
        ? petTypeList.map(({ slug, pet_type_name }) => ({
            value: slug,
            label: pet_type_name,
          }))
        : [];
    this.setState({
      newPetTypeList: newPetTypeList,
      petTypeListDropdown: newPetTypeList,
      petTypeList: petTypeList,
      petTypeListTotal: petTypeListTotal,
    });

    //this.setState({ showSpinner: false, loading: false });
  }

  async deleteCost(slug) {
    if (slug) {
      this.setState({ showSpinner: true });
      let res = await PropertyCostService.deleteCost(slug);
      if (global.successStatus.includes(res.status)) {
        // this.closeDeleteFloorPlanCostModal();
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({ viewMode: 0, cost_slug: "" });
        this.setState({
          selectedCostPayableAtList: [],
          selectedCostPayableByList: [],
          selectedCostTypeList: [],
        });
        this.getData();
      } else {
        // this.closeDeleteFloorPlanCostModal();
        let alertMessage = "";
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      this.setState({ showSpinner: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  }

  async getDefaultPetData() {
    //  this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      var selectedPetTypeList = [];
      var petPolicyTypeDataTotal = 1;
      let res = await PetPolicyService.getPetPolicy(
        this.state.listing_slug + "/11"
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.petPolicyDataLength = res.data.petPolicyData
          ? res.data.petPolicyData.length
          : 0;
        resData.petPolicyData = res.data.petPolicyData
          ? res.data.petPolicyData
          : [
              {
                pet_type_slug: "",
                allowed_pet_number: "",
                deposite_amount_per_pet: "",
                one_time_fee_per_type: "",
                rent_per_type: "",
                weight_per_type: "",
                policy_slug: "",
              },
            ];

        const values = [];
        if (resData.petPolicyDataLength > 0) {
          resData.petPolicyData.forEach((item, i) => {
            values.push({
              pet_type_slug: item.pet_type.slug,
              allowed_pet_number: item.allowed_pet_number,
              one_time_fee_per_type: item.one_time_fee_per_type,
              deposite_amount_per_pet: item.deposite_amount_per_pet,
              rent_per_type: item.rent_per_type,
              weight_per_type: item.weight_per_type,
              policy_slug: item.slug,
              selected_pet_type: {
                value: item.pet_type.slug,
                label: item.pet_type.pet_type_name,
              },
            });
          });
        } else {
          resData.petPolicyTypeData = [
            {
              pet_type_slug: "",
              allowed_pet_number: "",
              deposite_amount_per_pet: "",
              one_time_fee_per_type: "",
              rent_per_type: "",
              weight_per_type: "",
              policy_slug: "",
              // selected_pet_type: {
              //   value: "",
              //   label: "",
              // },
            },
          ];
        }
        petPolicyTypeDataTotal = values.length;
        this.setState({
          petPolicyTypeData: values,
          petPolicyTypeDataTotal: petPolicyTypeDataTotal,
        });
        resData.pet_policy_restrictions = res.data.propertyData[0]
          ? res.data.propertyData[0].pet_policy_restrictions
          : "";
        resData.custom_pet_policy = res.data.propertyData[0]
          ? res.data.propertyData[0].custom_pet_policy
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        res.data.petPolicyRestrictions.forEach((item, i) => {
          selectedPetTypeList = [
            ...selectedPetTypeList,
            {
              value: item.slug,
              label: item.pet_type_name,
            },
          ];
        });
      }
      this.setState(resData);
      this.setState({
        selectedPetTypeList: selectedPetTypeList,
      });
      this.setState({ pet_type_not_allowed: selectedPetTypeList });
    }
    //  this.setState({ showSpinner: false, loading: false });
  }

  // async getSTRData() {
  //   this.setState({ showSpinner: true, loading: true });
  //   var resData = {};

  //   let res = await PropertyCostService.getSTRFees("slug="+
  //     this.state.property_slug+"&ref_table_id=3"
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     resData.pet_fee_for_guest  = res.data ? res.data.fee_value.replace(/\.00$/,'') : "";
  //   }
  //   this.setState(resData);
  //   this.setState({ showSpinner: false, loading: false });
  // }

  async getPetPolicyData() {
    //this.setState({ showSpinner: true, loading: true });
    var petPolicyList = [];
    var petPolicyListTotal = 0;
    let res = await PetPolicyService.getPetPolicy(
      this.state.listing_slug + "/11"
    );
    if (global.successStatus.includes(res.status)) {
      petPolicyList = res.data.petPolicyData ? res.data.petPolicyData : [];
      petPolicyListTotal = petPolicyList ? petPolicyList.length : 0;
    }
    this.setState({
      petPolicyList: petPolicyList,
      petPolicyListTotal: petPolicyListTotal,
    });
    //this.setState({ showSpinner: false, loading: false });
  }

  async getTaxData() {
    this.setState({ showSpinner: true, loading: true });
    var taxList = [];
    var taxListTotal = 0;
    let res = await TaxService.getTax(
      "property_slug=" + this.state.property_slug + "&is_active=1"
    );
    if (global.successStatus.includes(res.status)) {
      taxList = res.data ? res.data.data : [];
      taxListTotal = taxList ? taxList.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      taxListTotal: taxListTotal,
      taxList: taxList,
    });
  }

  /* To get Cost Type list data */
  async getCostTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costTypeList = [];

    let res = await CostTypeService.getCostType(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costTypeList = res.data ? res.data : [];
    }
    const newCostTypeList = costTypeList.map(({ slug, cost_type_name }) => ({
      value: slug,
      label: cost_type_name,
    }));
    this.setState({
      costTypeList: costTypeList,
      newCostTypeList: newCostTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getSelectedCostType() {
    this.setState({ showSpinner: true, loading: true });
    var selectedCostTypeList = [];

    let res = await CostTypeService.getSelectedCostType(
      this.props.listing_slug + "/4"
    );

    if (global.successStatus.includes(res.status)) {
      selectedCostTypeList = res.data ? res.data : [];
    }
    const alreadySelectedCostTypeList = selectedCostTypeList.map(
      ({ slug, cost_type_name }) => ({
        value: slug,
        label: cost_type_name,
      })
    );
    this.setState({
      selectedCostTypeList: selectedCostTypeList,
      alreadySelectedCostTypeList: alreadySelectedCostTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Payable At list data */
  async getCostPayableAtData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costPayableAtList = [];

    let res = await CostPayableAtService.getCostPayableAt(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costPayableAtList = res.data ? res.data : [];
    }
    const newCostPayableAtList = costPayableAtList.map(
      ({ slug, cost_payable_at_name }) => ({
        value: slug,
        label: cost_payable_at_name,
      })
    );
    this.setState({
      costPayableAtList: costPayableAtList,
      newCostPayableAtList: newCostPayableAtList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Payable By list data */
  async getCostPayableByData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costPayableByList = [];

    let res = await CostPayableByService.getCostPayableBy(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costPayableByList = res.data ? res.data : [];
    }
    const newCostPayableByList = costPayableByList.map(
      ({ slug, cost_payable_by_name }) => ({
        value: slug,
        label: cost_payable_by_name,
      })
    );
    this.setState({
      costPayableByList: costPayableByList,
      newCostPayableByList: newCostPayableByList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdateCost = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        level: 6, // 6=leasing
        slug: this.state.listing_slug,
        cost_data: this.state.FeeTypeData,
      };
      if (this.state.listing_slug !== undefined) {
        let res = await PropertyCostService.updateCosts(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          // this.changeView();
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }

      let inputData1 = {
        pet_data: this.state.pet_data ? this.state.pet_data : [],
        // pet_type_not_allowed: multiPetNotAllowed,
        // pet_policy_restrictions: this.state.pet_policy_restrictions,
        // custom_pet_policy: this.state.custom_pet_policy,
        property_slug: this.state.property_slug,
        dataSlug: this.state.listing_slug,
        ref_table_id: 11,
        // sort_order: this.state.sort_order,
        // status_id: this.state.status_id === true ? 1 : 2,
      };
      if (
        this.state.property_slug !== undefined &&
        this.state.isPetChange === 1
      ) {
        //pet policy api call
        let res = await PetPolicyService.updatePetPolicy(inputData1);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          // this.changeView();
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
        this.setState({ pet_data: [] });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
    this.changeView();
  };

  async deletePetPolicy(slug) {
    this.setState({ showSpinner: true });
    if (slug) {
      let res = await PetPolicyService.deletePetPolicy(slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
          selectedPetTypeList: [],
        });
        this.getDefaultPetData();
      } else {
        let alertMessage = "";
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  openDeleteUnitCostModal(slug) {
    this.setState({ deleteUnitCostModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteUnitCostModal() {
    this.setState({ deleteUnitCostModal: false });
    this.setState({ slug: "" });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectedCostTypeList(value) {
    let cost_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        cost_type_slug.push(item.value);
      });
    }
    const cost_type_slug_value = value !== null ? value.value : [];
    this.setState({
      newSelectedCostTypeList: value,
      cost_type_slug: cost_type_slug_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleChangeCostPayableByList(value) {
    let cost_payable_by_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        cost_payable_by_slug.push(item.value);
      });
    }
    const cost_payable_by_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCostPayableByList: value,
      cost_payable_by_slug: cost_payable_by_slug_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleChangeCostPayableAtList(value) {
    let cost_payable_at_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        cost_payable_at_slug.push(item.value);
      });
    }
    const cost_payable_at_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCostPayableAtList: value,
      cost_payable_at_slug: cost_payable_at_slug_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleChangeselect = (index, event) => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    values[index].pet_type_slug = event.value;
    values[index].selected_pet_type = event;
    // values[index].event.target.name = event.target.value;
    this.setState({ petPolicyTypeData: values });
    this.setState({ pet_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChange = (index, event) => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];

    if (event.target.name === "pet_type_slug") {
      values[index].pet_type_slug = event.target.value;
      this.setState({ petPolicyTypeData: values });
    } else if (event.target.name === "allowed_pet_number") {
      values[index].allowed_pet_number = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
    } else if (event.target.name === "deposite_amount_per_pet") {
      values[index].deposite_amount_per_pet = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
    } else if (event.target.name === "one_time_fee_per_type") {
      values[index].one_time_fee_per_type = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
    } else if (event.target.name === "rent_per_type") {
      values[index].rent_per_type = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
    } else if (event.target.name === "weight_per_type") {
      values[index].weight_per_type = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
    }
    this.setState({ pet_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleAddFields = () => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    var petPolicyTypeDataTotal = 1;
    values.push({
      pet_type_slug: "",
      allowed_pet_number: "",
      one_time_fee_per_type: "",
      deposite_amount_per_pet: "",
      rent_per_type: "",
      weight_per_type: "",
      policy_slug: "",
    });
    petPolicyTypeDataTotal = values.length;

    this.setState({
      petPolicyTypeData: values,
      petPolicyTypeDataTotal: petPolicyTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleRemoveFields = (i) => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    values.splice(i, 1);
    var petPolicyTypeDataTotal = 0;
    petPolicyTypeDataTotal = values.length;
    this.setState({ petPolicyTypeData: values, pet_data: values });
    this.setState({
      petPolicyTypeDataTotal: petPolicyTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.getData();
      // this.getDefaultPetData();
      this.getPetPolicyData();
      // this.getSTRData();
      this.setState({
        cost_slug: "",
        newSelectedCostTypeList: [],
        selectedCostPayableAtList: [],
        selectedCostPayableByList: [],
      });
      this.setState({
        cost_name: "",
        cost_type_slug: "",
        cost_payable_at_slug: "",
        cost_payable_by_slug: "",
        refundable: "",
        estimated: "",
        mandatory: "",
        errors: "",
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.getData();
      this.getDefaultPetData();
      this.getPetTypeData();
      // this.getSTRData();
      this.setState({
        cost_name: "",
        cost_type_slug: "",
        cost_payable_at_slug: "",
        cost_payable_by_slug: "",
        refundable: "",
        estimated: "",
        mandatory: "",
        errors: "",
        newSelectedCostTypeList: [],
        selectedCostPayableAtList: [],
        selectedCostPayableByList: [],
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
      if (this.state.cost_slug === "") {
        this.getCostTypeData();
        // this.getSelectedCostType();
        this.getCostPayableAtData();
        this.getCostPayableByData();
      }
    }
  }

  getTitle() {
    return "Fees";
  }

  handleAddFieldsCost = () => {
    const values = [...this.state.FeeTypeData];
    var FeeTypeDataTotal = 1;
    values.push({
      cost_type_slug: "",
      cost_payable_by_slug: "",
      cost_payable_at_slug: "",
      cost_name: "",
      refundable: 0,
      estimated: 0,
      mandatory: 0,
    });
    FeeTypeDataTotal = values.length;

    this.setState({
      FeeTypeData: values,
      FeeTypeDataTotal: FeeTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleRemoveFieldsCost = (i) => {
    const values = [...this.state.FeeTypeData];
    values.splice(i, 1);
    var FeeTypeDataTotal = 0;
    FeeTypeDataTotal = values.length;
    this.setState({ FeeTypeData: values, cost_data: values });
    this.setState({
      FeeTypeDataTotal: FeeTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectOne = (index, event) => {
    const values = [...this.state.FeeTypeData];

    values[index].cost_type_slug = event.value;
    values[index].selected_cost_type = {
      label: event.label,
      value: event.value,
    };

    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectTwo = (index, event) => {
    const values = [...this.state.FeeTypeData];
    values[index].cost_payable_at_slug = event.value;
    values[index].selected_cost_at = {
      label: event.label,
      value: event.value,
    };

    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectThree = (index, event) => {
    const values = [...this.state.FeeTypeData];
    values[index].cost_payable_by_slug = event.value;
    values[index].selected_cost_by = {
      label: event.label,
      value: event.value,
    };

    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeCost = (index, event) => {
    const values = [...this.state.FeeTypeData];

    if (event.target.name === "cost_name") {
      values[index].cost_name = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
    }
    if (event.target.name === "refundable") {
      values[index].refundable = event.target.checked ? 1 : 0;
    }
    if (event.target.name === "estimated") {
      values[index].estimated = event.target.checked ? 1 : 0;
    }
    if (event.target.name === "mandatory") {
      values[index].mandatory = event.target.checked ? 1 : 0;
    }
    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
            {this.state.viewMode === 0 && global.userPermissions.checkPermission('leases-update') ? (
              <>
                <button
                  className="btn-success-outline-small mr-3"
                  onClick={this.changeView}
                >
                  <img src={editIcon} alt="" />
                  Edit
                </button>
              </>
            ) : (
              <>
                {this.state.cost_slug !== "" ? (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        this.openDeleteUnitCostModal(this.state.cost_slug);
                      }}
                      className="btn-danger-outline mg-r-20"
                    >
                      <img src={deleteicon} alt="" />
                      Delete
                    </button>{" "}
                    <span
                      onClick={() => {
                        this.openDeleteUnitCostModal(this.state.slug);
                      }}
                      className="deleteicon mg-r-20"
                    >
                      <img src={deleteicon} alt="" />
                    </span>
                  </>
                ) : (
                  ""
                )}
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn-success-outline-small"
                    onClick={() => {
                      if (this.state.confirm_back === 1)
                        if (
                          window.confirm("Do you want to exit without saving?")
                        )
                          this.changeView();
                        else return false;
                      else this.changeView();
                    }}
                  >
                    Cancel
                  </button>
                  {this.state.cost_slug === "" && global.userPermissions.checkPermission('leases-update') ? (
                    <button
                      className="btn-success ml-3"
                      onClick={this.saveOrUpdateCost}
                      // onClick={this.saveOrUpdateFees}
                    >
                      Save
                    </button>
                  ) : (
                    global.userPermissions.checkPermission('leases-update') ?
                    <button
                      className="btn-success ml-3"
                      onClick={this.saveOrUpdateCost}
                      // onClick={this.saveOrUpdateFees}
                    >
                      Save
                    </button>
                    :''
                  )}
                </div>
              </>
            )}
          </div>
          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0  mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          {this.state.viewMode === 0 ? (
            <div className="row m-0 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 background-head">General Fees</h3>
              <div className="table-responsive">
                <Table hover className="">
                  <thead>
                    <tr>
                      <th className="w-20">Fee Type</th>
                      <th className="w-20">Payable By</th>
                      <th className="w-20">Payable At</th>
                      <th className="w-20">Fee</th>
                      <th className="w-20">Refundable</th>
                      <th className="w-20">Estimated</th>
                      <th className="w-20 text-left">Mandatory</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.costListTotal > 0 ? (
                      this.state.costList.map((option, i) => {
                        return (
                          <tr
                            key={i}
                            // onClick={() => this.getCostDetails(item.slug)}
                          >
                            <td>
                              {option.cost_type
                                ? option.cost_type.cost_type_name
                                : ""}
                            </td>
                            <td>
                              {option.cost_payable_by
                                ? option.cost_payable_by.cost_payable_by_name
                                : ""}
                            </td>
                            <td>
                              {option.cost_payable_at
                                ? option.cost_payable_at.cost_payable_at_name
                                : ""}
                            </td>
                            <td>
                              {option.cost_name
                                ? "$" +
                                  global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(option.cost_name)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                      .replace(
                                        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                        ""
                                      )
                                  )
                                : ""}
                            </td>
                            <td>{option.refundable === 1 ? "Yes" : "No"}</td>
                            <td>{option.estimated === 1 ? "Yes" : "No"}</td>
                            <td className="text-left">
                              {option.mandatory === 1 ? "Yes" : "No"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          There are no fees added in the system.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <h3 className="mg-l-0 background-head ">Pet Fees</h3>
              <div className="table-responsive">
                <Table hover className="">
                  <thead>
                    <tr>
                      <th className="w-20">Pet Type</th>
                      <th className="w-20">Max # Allowed</th>
                      <th className="w-20">Deposit</th>
                      <th className="w-20">One-Time Fee</th>
                      <th className="w-20">Monthly Rent</th>
                      <th className="w-20 text-left">Max # Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.petPolicyListTotal > 0 ? (
                      this.state.petPolicyList.map((option, i) => (
                        <tr key={i}>
                          <td>
                            {option.pet_type
                              ? option.pet_type.pet_type_name
                              : ""}
                          </td>
                          <td>
                            {option.allowed_pet_number
                              ? option.allowed_pet_number
                              : ""}
                          </td>
                          <td>
                            {option.deposite_amount_per_pet ? (
                              <NumberFormat
                                value={parseFloat(
                                  option.deposite_amount_per_pet
                                )
                                  .toFixed(2)
                                  .replace(/\.00$/, "")
                                  .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            {option.one_time_fee_per_type ? (
                              <NumberFormat
                                value={parseFloat(option.one_time_fee_per_type)
                                  .toFixed(2)
                                  .replace(/\.00$/, "")
                                  .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            {option.rent_per_type ? (
                              <NumberFormat
                                value={parseFloat(option.rent_per_type)
                                  .toFixed(2)
                                  .replace(/\.00$/, "")
                                  .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="text-left">
                            {option.weight_per_type
                              ? parseFloat(option.weight_per_type)
                                  .toFixed(2)
                                  .replace(/\.00$/, "")
                                  .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "") +
                                " lbs"
                              : ""}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          There are no fees added in the system.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <h3 className="mg-l-0 background-head ">Taxes</h3>
              <div className="table-responsive">
                <Table hover className="">
                  <thead>
                    <tr>
                      <th className="w-30">Name</th>
                      <th className="w-30">Tax Type</th>
                      <th className="w-30 text-left">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.taxListTotal > 0 ? (
                      this.state.taxList.map((option, i) => {
                        var a = option.tax_type
                          ? option.tax_type.tax_type_name
                          : "";
                        return (
                          <tr key={i}>
                            <td>
                              {option.property_level_tax_name
                                ? option.property_level_tax_name
                                : ""}
                            </td>
                            <td>
                              {option.tax_type
                                ? option.tax_type.tax_type_name
                                : ""}
                            </td>
                            <td className="text-left">
                              {option.property_amount
                                ? a.includes("$")
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(option.property_amount)
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                        .replace(
                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                          ""
                                        )
                                    )
                                  : option.property_amount + "%"
                                : ""}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          There are no taxes added in the system.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : (
            ""
          )}

          {this.state.viewMode === 1 ? (
            <div className="m-0 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              {/* <h3 className="mg-l-0 background-head">General Fees</h3>
              <div className="table-responsive">
                <Table hover className="">
                  <thead>
                    <tr>
                      <th className="w-20">Fee Type</th>
                      <th className="w-20">Payable By</th>
                      <th className="w-20">Payable At</th>
                      <th className="w-20">Fee</th>
                      <th className="w-20">Refundable</th>
                      <th className="w-20">Estimated</th>
                      <th className="w-20 text-left">Mandatory</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.costListTotal > 0 ? (
                      this.state.costList.map((option, i) => {
                        return (
                          <tr
                            key={i}
                          >
                            <td>
                              {option.cost_type
                                ? option.cost_type.cost_type_name
                                : ""}
                            </td>
                            <td>
                              {option.cost_payable_by
                                ? option.cost_payable_by.cost_payable_by_name
                                : ""}
                            </td>
                            <td>
                              {option.cost_payable_at
                                ? option.cost_payable_at.cost_payable_at_name
                                : ""}
                            </td>
                            <td>{option.cost_name ? "$" + global.onKeyPressEvent.numberWithCommasForDecimal(option.cost_name.replace(/\.00$/, '')) : ""}</td>
                            <td>{option.refundable === 1 ? "Yes" : "No"}</td>
                            <td>{option.estimated === 1 ? "Yes" : "No"}</td>
                            <td className="text-left">{option.mandatory === 1 ? "Yes" : "No"}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          There are no fees added in the system.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div> */}
              <h3 className="mg-l-0 background-head">General Fees</h3>
              <div className="pd-lg-l-0 pd-lg-r-0 pet-f mg-b-30">
                <div className="col-md-12 p-0 FloorFeeSection">
                  <table className="petForm">
                    <thead>
                      <tr>
                        <th className="w-1">
                          <label className="mb-1">Fee Type</label>
                        </th>
                        <th className="w-1">
                          <label className="mb-1">Payable By</label>
                        </th>
                        <th className="w-1">
                          <label className="mb-1">Payable At</label>
                        </th>
                        <th className="W-2">
                          <label className="mb-1">Fee</label>
                        </th>
                        <th className="w-3">
                          <label className="mb-1">Refundable</label>
                        </th>
                        <th className="w-3">
                          <label className="mb-1">Estimated</label>
                        </th>
                        <th className="w-3">
                          <label className="mb-1">Mandatory</label>
                        </th>
                        <th className="w-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.FeeTypeData.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td className="w-1">
                              <Col xs={12} className="pd-l-0 pd-r-2 colmn1">
                                <InputGroup className="mt-0 m-b-1">
                                  {/* <label>Pet Type</label> */}
                                  <div className="col-md-12 p-0">
                                    <Select
                                      styles={customStyles}
                                      className="multiselect"
                                      menuPlacement="auto"
                                      onChange={(value) =>
                                        this.handleChangeSelectOne(i, value)
                                      }
                                      options={this.state.newCostTypeList}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                      value={data.selected_cost_type}
                                      defaultValue={data.selected_cost_type}
                                      // isDisabled={data.slug ? true : false}
                                    />
                                  </div>
                                </InputGroup>
                              </Col>
                            </td>
                            <td className="w-1">
                              <Col xs={12} className="pd-l-2 pd-r-2 colmn1">
                                <Select
                                  styles={customStyles}
                                  menuPlacement="auto"
                                  className="multiselect"
                                  name="cost_payable_by_slug"
                                  value={data.selected_cost_by}
                                  options={this.state.newCostPayableByList}
                                  defaultValue={data.selected_cost_by}
                                  onChange={(value) =>
                                    this.handleChangeSelectThree(i, value)
                                  }
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />
                              </Col>
                            </td>
                            <td className="w-1">
                              <Col xs={12} className="pd-l-2 pd-r-2 colmn1">
                                <Select
                                  styles={customStyles}
                                  menuPlacement="auto"
                                  className="multiselect"
                                  name="cost_payable_at_slug"
                                  value={data.selected_cost_at}
                                  options={this.state.newCostPayableAtList}
                                  defaultValue={data.selected_cost_at}
                                  onChange={(value) =>
                                    this.handleChangeSelectTwo(i, value)
                                  }
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />
                              </Col>
                            </td>
                            <td className="w-2">
                              <Col xs={12} className="pd-l-2 pd-r-2 colmn3">
                                <InputGroup className="mt-0 m-b-1">
                                  <div className="input-group-prepend prefix-dollar w-100">
                                    <span className="input-group-text">$</span>
                                    <Form.Control
                                      name="cost_name"
                                      onKeyPress={
                                        global.onKeyPressEvent.floatValidation
                                      }
                                      value={
                                        data.cost_name
                                          ? data.cost_name
                                              .replace(/\.00$/, "")
                                              .replace(
                                                /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                ""
                                              ) || ""
                                          : ""
                                      }
                                      onChange={(event) =>
                                        this.handleChangeCost(i, event)
                                      }
                                    />
                                  </div>
                                </InputGroup>
                              </Col>
                            </td>
                            <td className="w-3">
                              <Col
                                xs={12}
                                className="pd-l-2 pd-r-2 pd-t-2 align-items-center colmn2"
                              >
                                <div className="ckbox form-check">
                                  <input
                                    type="checkbox"
                                    id={`refundable_` + i}
                                    name="refundable"
                                    className="form-check-input ml-0 w-auto"
                                    onChange={(event) =>
                                      this.handleChangeCost(i, event)
                                    }
                                    checked={data.refundable}
                                  />
                                  <label
                                    htmlFor={`refundable_` + i}
                                    className="form-check-label"
                                  ></label>
                                </div>
                              </Col>
                            </td>
                            <td className="w-3">
                              <Col
                                xs={12}
                                className="pd-l-2 pd-r-2 pd-t-2 colmn2"
                              >
                                <div className="ckbox form-check">
                                  <input
                                    type="checkbox"
                                    id={`estimated_` + i}
                                    name="estimated"
                                    className="form-check-input ml-0 w-auto"
                                    onChange={(event) =>
                                      this.handleChangeCost(i, event)
                                    }
                                    checked={data.estimated}
                                  />
                                  <label
                                    htmlFor={`estimated_` + i}
                                    className="form-check-label"
                                  ></label>
                                </div>
                              </Col>
                            </td>
                            <td className="w-3">
                              <Col
                                xs={12}
                                className="pd-l-2 pd-r-2 pd-t-2 colmn2"
                              >
                                <div className="ckbox form-check">
                                  <input
                                    type="checkbox"
                                    id={`mandatory_` + i}
                                    name="mandatory"
                                    className="form-check-input ml-0 w-auto"
                                    onChange={(event) =>
                                      this.handleChangeCost(i, event)
                                    }
                                    checked={data.mandatory}
                                  />
                                  <label
                                    htmlFor={`mandatory_` + i}
                                    className="form-check-label"
                                  ></label>
                                </div>
                              </Col>
                            </td>
                            <td className="w-3">
                              <Col
                                xs={12}
                                className="pd-l-2 pd-r-0 d-flex align-items-center add-delete"
                              >
                                {this.state.FeeTypeDataTotal === 1 &&
                                i === 0 ? (
                                  <>
                                    {data.slug ? (
                                      <div className="d-flex  justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                        <button
                                          onClick={() => {
                                            this.handleRemoveFieldsCost(i);

                                            this.deleteCost(data.slug);
                                          }}
                                          className={
                                            "btn-danger-outline-x-small"
                                          }
                                        >
                                          <img src={deleteicon} alt="Delete" />
                                        </button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="d-flex justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                      <button
                                        onClick={this.handleAddFieldsCost}
                                        className="btn-success-outline-x-small"
                                      >
                                        <img src={pluswIcon} alt="Add" />
                                      </button>
                                    </div>
                                  </>
                                ) : i < this.state.FeeTypeDataTotal - 1 ? (
                                  <>
                                    <div className="d-flex  justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                      <button
                                        onClick={() => {
                                          this.handleRemoveFieldsCost(i);

                                          this.deleteCost(data.slug);
                                        }}
                                        className={"btn-danger-outline-x-small"}
                                      >
                                        <img src={deleteicon} alt="Delete" />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {this.state.FeeTypeDataTotal !== 0 ? (
                                      <>
                                        <div className="d-flex  justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                          <button
                                            onClick={() => {
                                              this.handleRemoveFieldsCost(i);

                                              this.deleteCost(data.slug);
                                            }}
                                            className={
                                              "btn-danger-outline-x-small"
                                            }
                                          >
                                            <img
                                              src={deleteicon}
                                              alt="Delete"
                                            />
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                      <button
                                        onClick={this.handleAddFieldsCost}
                                        className="btn-success-outline-x-small"
                                      >
                                        <img src={pluswIcon} alt="Add" />
                                      </button>
                                    </div>
                                  </>
                                )}
                              </Col>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <h3 className="mg-l-0 background-head ">Pet Fees</h3>
              <div className="pd-lg-l-0 pd-lg-r-0 pet-f mg-b-30 pet">
                <div className="col-md-12 p-0 petFeeSection">
                  <table className="petForm">
                    <thead>
                      <tr>
                        <th className="w-1">
                          <label className="mb-1">Pet Type</label>
                        </th>
                        <th className="w-1">
                          <label className="mb-1">Max # Allowed</label>
                        </th>
                        <th className="w-2">
                          <label className="mb-1">Deposit</label>
                        </th>
                        <th className="w-2">
                          <label className="mb-1">One-Time Fee</label>
                        </th>
                        <th className="w-2">
                          <label className="mb-1">Monthly Rent</label>
                        </th>
                        <th className="w-2">
                          <label className="mb-1">Max # Weight</label>
                        </th>
                        <th className="w-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.petPolicyTypeData.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td className="w-1">
                              <Col xs={12} className="pd-l-0 pd-r-2 colmn1">
                                <InputGroup className="mt-0 m-b-1">
                                  {/* <label>Pet Type</label> */}
                                  <div className="col-md-12 p-0">
                                    <Select
                                      styles={customStyles}
                                      className="multiselect"
                                      menuPlacement="auto"
                                      onChange={(value) =>
                                        this.handleChangeselect(i, value)
                                      }
                                      options={
                                        this.state.petTypeListTotal > 0
                                          ? this.state.petTypeListDropdown
                                              .filter((option) => {
                                                const val1 = this.state
                                                  .petPolicyTypeData
                                                  ? this.state.petPolicyTypeData.find(
                                                      (opt) => {
                                                        if (
                                                          option.value ===
                                                          data.pet_type_slug
                                                        )
                                                          return false;
                                                        return (
                                                          opt.pet_type_slug ===
                                                          option.value
                                                        );
                                                      }
                                                    )
                                                  : "";
                                                if (val1) return false;
                                                else return true;
                                              })
                                              .filter((option) => {
                                                const val2 = this.state
                                                  .selectedPetTypeList
                                                  ? this.state.selectedPetTypeList.find(
                                                      (selOpt) => {
                                                        if (
                                                          selOpt.value ===
                                                          data.pet_type_slug
                                                        )
                                                          return false;
                                                        return (
                                                          selOpt.value ===
                                                          option.value
                                                        );
                                                      }
                                                    )
                                                  : "";
                                                if (val2) return false;
                                                else return true;
                                              })
                                          : []
                                      }
                                      getOptionValue={(option) =>
                                        `${option.label}`
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                      value={
                                        data.selected_pet_type
                                          ? data.selected_pet_type
                                          : []
                                      }
                                      defaultValue={
                                        data.selected_pet_type
                                          ? data.selected_pet_type
                                          : []
                                      }
                                    />
                                  </div>
                                </InputGroup>
                              </Col>
                            </td>
                            <td className="w-1">
                              <Col xs={12} className="pd-l-2 pd-r-2 colmn1">
                                <InputGroup className="mt-0 m-b-1">
                                  {/* <label>Maxs. Pets Allowed</label> */}
                                  <Form.Control
                                    // placeholder="Max # Allowed"
                                    name="allowed_pet_number"
                                    maxLength="2"
                                    onKeyPress={
                                      global.onKeyPressEvent.numberValidation
                                    }
                                    value={
                                      data.allowed_pet_number
                                        ? data.allowed_pet_number || ""
                                        : ""
                                    }
                                    onChange={(event) =>
                                      this.handleChange(i, event)
                                    }
                                  />
                                </InputGroup>
                              </Col>
                            </td>
                            <td className="w-2">
                              <Col xs={12} className="pd-l-2 pd-r-2 colmn3">
                                <InputGroup className="mt-0">
                                  {/* <label>Deposit Amount ($)</label> */}
                                  <div className="input-group-prepend prefix-dollar w-100">
                                    <span className="input-group-text">$</span>
                                    <Form.Control
                                      // placeholder="Deposit ($)"
                                      onKeyPress={
                                        global.onKeyPressEvent.numberValidation
                                      }
                                      name="deposite_amount_per_pet"
                                      value={
                                        data.deposite_amount_per_pet
                                          ? data.deposite_amount_per_pet
                                              .replace(/\.00$/, "")
                                              .replace(
                                                /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                ""
                                              ) || ""
                                          : ""
                                      }
                                      onChange={(event) =>
                                        this.handleChange(i, event)
                                      }
                                    />
                                  </div>
                                </InputGroup>
                              </Col>
                            </td>
                            <td className="w-2">
                              <Col xs={12} className="pd-l-2 pd-r-2 colmn3">
                                <InputGroup className="mt-0">
                                  {/* <label>One Time Fee ($)</label> */}
                                  <div className="input-group-prepend prefix-dollar w-100">
                                    <span className="input-group-text">$</span>
                                    <Form.Control
                                      // placeholder="One-Time Fee ($)"
                                      name="one_time_fee_per_type"
                                      onKeyPress={
                                        global.onKeyPressEvent.numberValidation
                                      }
                                      value={
                                        data.one_time_fee_per_type
                                          ? data.one_time_fee_per_type
                                              .replace(/\.00$/, "")
                                              .replace(
                                                /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                ""
                                              ) || ""
                                          : ""
                                      }
                                      onChange={(event) =>
                                        this.handleChange(i, event)
                                      }
                                    />
                                  </div>
                                </InputGroup>
                              </Col>
                            </td>
                            <td className="w-2">
                              <Col xs={12} className="pd-l-2 pd-r-2 colmn3">
                                <InputGroup className="mt-0">
                                  {/* <label>Rent ($)</label> */}
                                  <div className="input-group-prepend prefix-dollar">
                                    <span className="input-group-text">$</span>
                                    <Form.Control
                                      // placeholder="Monthly Rent ($)"
                                      onKeyPress={
                                        global.onKeyPressEvent.numberValidation
                                      }
                                      name="rent_per_type"
                                      value={
                                        data.rent_per_type
                                          ? data.rent_per_type
                                              .replace(/\.00$/, "")
                                              .replace(
                                                /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                ""
                                              ) || ""
                                          : ""
                                      }
                                      onChange={(event) =>
                                        this.handleChange(i, event)
                                      }
                                    />
                                  </div>
                                </InputGroup>
                              </Col>
                            </td>
                            <td className="w-3">
                              <Col xs={12} className="pd-l-2 pd-r-0 d-flex">
                                <InputGroup className="mt-0">
                                  {/* <label>Weight (lbs)</label> */}
                                  <div className="input-group-prepend prefix-dollar">
                                    <Form.Control
                                      // placeholder="Weight (lbs)"
                                      onKeyPress={
                                        global.onKeyPressEvent.floatValidation
                                      }
                                      name="weight_per_type"
                                      value={
                                        data.weight_per_type
                                          ? data.weight_per_type
                                              .replace(/\.00$/, "")
                                              .replace(
                                                /[&#,+()$~%'":*?<>{}]/gi,
                                                ""
                                              ) || ""
                                          : ""
                                      }
                                      onChange={(event) =>
                                        this.handleChange(i, event)
                                      }
                                    />
                                    <span className="input-group-text">
                                      lbs
                                    </span>
                                  </div>
                                </InputGroup>
                                {/* {this.state.petPolicyTypeDataTotal ===
                            1 && i === 0 ? (
                            <>
                              {data.policy_slug ? (
                                <div className="d-flex  justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                  <button
                                    onClick={() => {
                                      this.handleRemoveFields(i);

                                      this.deletePetPolicy(
                                        data.policy_slug
                                      );
                                    }}
                                    className={
                                      "btn-danger-outline-x-small"
                                    }
                                  >
                                    <img
                                      src={deleteicon}
                                      alt="Delete"
                                    />
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                <button
                                  onClick={this.handleAddFields}
                                  className="btn-success-outline-x-small"
                                >
                                  <img src={pluswIcon} alt="Add" />
                                </button>
                              </div>
                            </>
                          ) : i <
                            this.state.petPolicyTypeDataTotal -
                              1 ? (
                            <>
                              <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                <button
                                  onClick={() => {
                                    this.handleRemoveFields(i);

                                    this.deletePetPolicy(
                                      data.policy_slug
                                    );
                                  }}
                                  className={
                                    "btn-danger-outline-x-small"
                                  }
                                >A
                                  <img
                                    src={deleteicon}
                                    alt="Delete"
                                  />
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.petPolicyTypeDataTotal !==
                              0 ? (
                                <>
                                  <div className="d-flex  justify-content-end align-items-center h-100">
                                    <button
                                      onClick={() => {
                                        this.handleRemoveFields(i);

                                        this.deletePetPolicy(
                                          data.policy_slug
                                        );
                                      }}
                                      className={
                                        "btn-danger-outline-x-small"
                                      }
                                    >
                                      <img
                                        src={deleteicon}
                                        alt="Delete"
                                      />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                <button
                                  onClick={this.handleAddFields}
                                  className="btn-success-outline-x-small"
                                >
                                  <img src={pluswIcon} alt="Add" />
                                </button>
                              </div>
                            </>
                          )} */}
                              </Col>
                            </td>
                            <td>
                              <Col
                                xs={12}
                                className="pd-l-2 pd-r-0 d-flex align-items-center add-delete"
                              >
                                {this.state.petPolicyTypeDataTotal === 1 &&
                                i === 0 ? (
                                  <>
                                    {data.policy_slug ? (
                                      <div className="d-flex  justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                        <button
                                          onClick={() => {
                                            this.handleRemoveFields(i);

                                            this.deletePetPolicy(
                                              data.policy_slug
                                            );
                                          }}
                                          className={
                                            "btn-danger-outline-x-small"
                                          }
                                        >
                                          <img src={deleteicon} alt="Delete" />
                                        </button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                      <button
                                        onClick={this.handleAddFields}
                                        className="btn-success-outline-x-small"
                                      >
                                        <img src={pluswIcon} alt="Add" />
                                      </button>
                                    </div>
                                  </>
                                ) : i <
                                  this.state.petPolicyTypeDataTotal - 1 ? (
                                  <>
                                    <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                      <button
                                        onClick={() => {
                                          this.handleRemoveFields(i);

                                          this.deletePetPolicy(
                                            data.policy_slug
                                          );
                                        }}
                                        className={"btn-danger-outline-x-small"}
                                      >
                                        <img src={deleteicon} alt="Delete" />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {this.state.petPolicyTypeDataTotal !== 0 ? (
                                      <>
                                        <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                          <button
                                            onClick={() => {
                                              this.handleRemoveFields(i);

                                              this.deletePetPolicy(
                                                data.policy_slug
                                              );
                                            }}
                                            className={
                                              "btn-danger-outline-x-small"
                                            }
                                          >
                                            <img
                                              src={deleteicon}
                                              alt="Delete"
                                            />
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                      <button
                                        onClick={this.handleAddFields}
                                        className="btn-success-outline-x-small"
                                      >
                                        <img src={pluswIcon} alt="Add" />
                                      </button>
                                    </div>
                                  </>
                                )}
                              </Col>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <h3 className="mg-l-0 background-head">Taxes</h3>
              <div className="table-responsive">
                <Table hover className="">
                  <thead>
                    <tr>
                      <th className="w-30">Name</th>
                      <th className="w-30">Tax Type</th>
                      <th className="w-30 text-left">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.taxListTotal > 0 ? (
                      this.state.taxList.map((option, i) => {
                        var a = option.tax_type
                          ? option.tax_type.tax_type_name
                          : "";
                        return (
                          <tr key={i}>
                            <td>
                              {option.property_level_tax_name
                                ? option.property_level_tax_name
                                : ""}
                            </td>
                            <td>
                              {option.tax_type
                                ? option.tax_type.tax_type_name
                                : ""}
                            </td>
                            <td className="text-left">
                              {option.property_amount
                                ? a.includes("$")
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      option.property_amount.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : option.property_amount.replace(
                                      /\.00$/,
                                      ""
                                    ) + "%"
                                : ""}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          There are no fees added in the system.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default LeasingFees;
