////////////////////////////////////////////////////////////
//     							                          //
//  Program: PropertyLevelTax.jsx                        //
//  Application: PropertyLevelTax                        //
//  Option: List all PropertyLevelTax                    //
//  Developer: NP						                 //
//  Date: 2022-02-10                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import TaxService from "../../services/TaxService";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import Alerts from "../common/Alerts";
import NoData from "../common/NoData";
import PropertySidebar from "../common/PropertySidebar";
// import NumberFormat from "react-number-format";

export class PropertyLevelTax extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      deleteTaxModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      taxTotalRecords:0,
      taxList:[],
      property_slug: this.props.match.params.propertySlug,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "property_level_tax_name",
          text: "Name",
          className: "property_level_tax_name",
          sortable: true,
          width: 300,
          cell: (record) => {
            return record.is_disable !== true && record.slug && global.userPermissions.checkPermission('taxes-update')
              ? <Link to={"property-level-tax/update/" + record.slug} className="hand-cursor">{record.property_level_tax_name}</Link>
              : record.property_level_tax_name;
          },
        },
        {
          key: "tax_type_id",
          text: "Tax Type",
          className: "tax_type",
          sortable: true,
          width: 300,
          cell: (record) => {
            return record.tax_type_name ? record.tax_type_name : (record.tax_type ? record.tax_type.tax_type_name : "");
          },
        },
        {
          key: "property_amount",
          text: "Value",
          className: "property_amount",
          sortable: true,
          width: 200,
          cell: (record) => {
            const a = record.tax_type_name ? record.tax_type_name : (record.tax_type ? record.tax_type.tax_type_name : '');
            return record.property_amount
              ? a.includes("$")
                ? "$" +
                global.onKeyPressEvent.numberWithCommasForDecimal(
                  parseFloat(record.property_amount).toFixed(2).replace(/\.00$/, "").replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
                )
                : parseFloat(record.property_amount).toFixed(2).replace(/\.00$/, "").replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "") + "%"
              : "";
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id",
          sortable: false,
          width: 200,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active">Active</b>
            ) : (
              <b className="status-inactive">InActive</b>
            );
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  async getData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await TaxService.getTax(
      "property_slug=" + this.state.property_slug + "&is_all_active_inactive=1" +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      // totalRecords = list ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0
    }
    // this.setState({ showSpinner: false, loading: false, });
    this.setState({
      total_record: totalRecords,
      records: list,
    });
    this.getTaxesData();             //from master according to city,county,state and country
  }

  async getTaxesData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var taxList = [];
    var taxTotalRecords = 0;
    var both_total_record = 0;
    // var records = this.state.records;
    let res = await TaxService.getTax(
      "property_slug=" + this.state.property_slug + "&is_property=1" +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      taxList = res.data ? res.data : [];
      taxTotalRecords = taxList ? taxList.length : 0;
    }
    if(taxTotalRecords > 0){
      taxList.map((item,i) => {
        taxList[i]['property_level_tax_name'] = item.general_tax_name;
        taxList[i]['tax_type_name'] = item.tax_type_name;
        taxList[i]['property_amount'] = item.general_tax_value;
        taxList[i]['status_id'] = item.status_id;
        taxList[i]['is_disable'] = true;
      })
    }
    // this.setState({ showSpinner: false, loading: false, });
    this.setState({
      taxTotalRecords: taxTotalRecords,
      taxList: taxList,
    });
    both_total_record = parseInt(taxTotalRecords) + parseInt(this.state.total_record);
    if(this.state.total_record !== 0){
      this.setState({records:[...this.state.records, ...taxList]})
      this.setState({total_record: both_total_record});
    }else{
      this.setState({records:taxList})
      this.setState({total_record:taxTotalRecords})
    }
     
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ start_date: "" });
    this.setState({ end_date: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Taxes";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("property-level-tax/update/" + data.slug);
  };

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  {/* <PropertyViewSidebar /> */}
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    {global.userPermissions.checkPermission('taxes-add') ?
                    <Link
                      to={"property-level-tax/add"}
                      className="w-100- d-flex justify-content-end"
                    >
                      <button className="btn-success-outline-small ">
                        <img src={pluswIcon} alt="" /> Add
                      </button>
                    </Link>
                    :''}
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pl-0 pr-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.state.showFilter ? (
                      <Row className="align-items-center order_form">
                        <Col md={3} lg={5} xl={5} className="mb-md-0 mb-3 pl-0">
                          <input
                            name="filter_value"
                            className="form-control"
                            autoComplete="off"
                            // placeholder="Filter Value"
                            value={this.state.filter_value}
                            onChange={this.changeHandler}
                            maxLength="30"
                          />
                        </Col>
                        <Col md={5} lg={5} xl={5}>
                          <Row>
                            <button
                              onClick={this.customFilter}
                              className="btn-success mg-l-30 mg-r-20"
                            >
                              Search
                            </button>
                            <button
                              onClick={this.resetDate}
                              className="btn-success-outline"
                            >
                              Reset
                            </button>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    {this.state.total_record !== 0  ? (
                      <div className="mg-t-18 brandList pd-l-15 pd-xs-l-15 pr-0 d-block">
                        <div className="table_right_padd pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                        <ReactDatatable
                          className="table no-wrap mg-b-30 pd-b-2"
                          config={
                            this.state.total_record > global.page_size
                              ? this.state.config
                              : this.state.config
                          }
                          records={this.state.records}
                          columns={this.state.columns}
                          extraButtons={this.state.extraButtons}
                          dynamic={true}
                          // loading={this.state.loading}
                          total_record={this.state.total_record}
                          onChange={this.tableChangeHandler}
                          // onRowClicked={global.userPermissions.checkPermission('taxes-update') && this.rowClickedHandler}
                        />
                        </div>
                      </div>
                    ) : (
                      <NoData msg="taxes" />
                    )}

                  {/* {this.state.taxTotalRecords > 0 ? <>
                    <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 mg-t-30">
                      <h3 className="mg-l-0 background-head">Other Taxes</h3>
                    </div>
                    <div className="pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                    <Table hover className="mg-b-0">
                        <thead>
                          <tr>
                            <th style={{width:"450px"}}>Name</th>
                            <th style={{width:"450px"}}>Tax Type</th>
                            <th style={{width:"300px"}}>Value</th>
                            <th style={{width:"300px"}}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.taxTotalRecords > 0 ? (
                            this.state.taxList.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="hand-cursor"
                                    onClick={() =>
                                      this.openEditGeneralTaxModal(item.slug)
                                    }
                                  >
                                    {item.general_tax_name ? item.general_tax_name : ''}
                                  </td>
                                  <td> {item.tax_type_name ? item.tax_type_name : ''} </td>
                                  <td>
                                    {" "}
                                    {item.tax_type_name && item.tax_type_name.includes("$") ? (
                                      <NumberFormat
                                        value={parseFloat(item.general_tax_value).toFixed(2).replace(/\.00$/, "").replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                      />
                                    ) : (
                                      parseFloat(item.general_tax_value).toFixed(2).replace(/\.00$/, "").replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "") + "%"
                                    )}
                                  </td>
                                  <td className="status_id">
                                    {item.status_id === 1 ? (
                                      <b className="active"></b>
                                    ) : (
                                      <b></b>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="9" align="center">
                                There are no taxes setup in master data for this property.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                    </>
                  :''} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default PropertyLevelTax;
