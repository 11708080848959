////////////////////////////////////////////////////////////
//     							                          //
//  Program: Dashboard.jsx                                //
//  Application: Dashboard                                //
//  Option: to view Company Information                   //
//  Developer: NP  						                            //
//  Date: 2021-12-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import CompanyService from "../../services/CompanyService";
import companyLogo from "./../../assets/images/company_placeholder.png";
import editIcon from "./../../assets/images/edit-pen-icon.svg";

class Company extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: "",
    };
    this.fullText = this.fullText.bind(this);
    this.fullText2 = this.fullText2.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullText2() {
    if (this.state.descShow2 === 0) {
      this.setState({ descShow2: 1 });
    } else {
      this.setState({ descShow2: 0 });
    }
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var phoneNumber = "";
    var clientInfophoneNumber = "";
    var orionHausPhoneNumber = "";
    let res = await CompanyService.getCompany();

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.slug = res.data.slug ? res.data.slug : "";
      resData.company_name = res.data.company_name ? res.data.company_name : "";
      resData.company_logo_thumb_url = res.data.company_logo_thumb_url
        ? res.data.company_logo_thumb_url
        : "";
      resData.aka_name = res.data.aka_name ? res.data.aka_name : "";
      resData.legal_entity_name = res.data.legal_entity_name
        ? res.data.legal_entity_name
        : "";
      resData.tax_id = res.data.tax_id ? res.data.tax_id : "";
      resData.contact_name = res.data.contact_name ? res.data.contact_name : "";
      resData.phone_number = res.data.phone_number ? res.data.phone_number : "";
      if (res.data && res.data.phone_number) {
        phoneNumber = await this.phoneNumberAutoFormat(res.data.phone_number);
      }
      resData.email = res.data.email ? res.data.email : "";
      resData.website_cookie = res.data.website_cookie
        ? res.data.website_cookie
        : "";
      resData.term_condition = res.data.term_condition
        ? res.data.term_condition
        : "";

      resData.total_brand_count = res.data.total_brand_count
        ? res.data.total_brand_count
        : "";
      resData.total_property_count = res.data.total_property_count
        ? res.data.total_property_count
        : "";
      resData.total_unit_count = res.data.total_unit_count
        ? res.data.total_unit_count
        : "";
      resData.total_client_count = res.data.total_client_count
        ? res.data.total_client_count
        : "";

      resData.street_1 = res.data.street_1 ? res.data.street_1 : "";
      resData.street_2 = res.data.street_2 ? res.data.street_2 : "";
      resData.zipcode = res.data.zipcode ? res.data.zipcode : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      resData.city_name = res.data.city ? res.data.city.city_name : "";
      resData.country_name = res.data.country
        ? res.data.country.country_name
        : "";
      resData.county_name = res.data.county ? res.data.county.county_name : "";

      resData.orion_haus_account_manager_name =
        res.data &&
        res.data.orion_haus_account_manager &&
        res.data.orion_haus_account_manager.first_name
          ? res.data.orion_haus_account_manager.first_name
          : "";
      resData.orion_haus_email =
        res.data &&
        res.data.orion_haus_account_manager &&
        res.data.orion_haus_account_manager.email
          ? res.data.orion_haus_account_manager.email
          : "";
      resData.orion_haus_phone_number =
        res.data &&
        res.data.orion_haus_account_manager &&
        res.data.orion_haus_account_manager.phone_number
          ? res.data.orion_haus_account_manager.phone_number
          : "";
      if (
        res.data &&
        res.data.orion_haus_account_manager &&
        res.data.orion_haus_account_manager.phone_number
      ) {
        orionHausPhoneNumber = await this.phoneNumberAutoFormat(
          res.data.orion_haus_account_manager.phone_number
        );
      }
      resData.client_contact_name =
        res.data && res.data.client_info && res.data.client_info.first_name
          ? res.data.client_info.first_name
          : "";
      resData.client_contact_email =
        res.data && res.data.client_info && res.data.client_info.email
          ? res.data.client_info.email
          : "";
      resData.client_contact_phone_number =
        res.data && res.data.client_info && res.data.client_info.phone_number
          ? res.data.client_info.phone_number
          : "";
      if (
        res.data &&
        res.data.client_info &&
        res.data.client_info.phone_number
      ) {
        clientInfophoneNumber = await this.phoneNumberAutoFormat(
          res.data.client_info.phone_number
        );
      }
    }
    this.setState(resData);
    this.setState({
      phoneNumber: phoneNumber,
      orionHausPhoneNumber: orionHausPhoneNumber,
      clientInfophoneNumber: clientInfophoneNumber,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    return finalnumber;
  };

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
        <Container className="innter-container ">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {/* <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <SettingSidebar />
                  </aside>
                </div>
              </div> */}
              <div className="col-md-12">
                <div className="scrolling-carousel">
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">
                            {this.state.company_name
                              ? this.state.company_name
                              : ""}
                          </h3>

                          {global.userPermissions.checkPermission(
                            "company-update"
                          ) && (
                            <Link to={"company/update/" + this.state.slug}>
                              <button className="btn-success-outline">
                                <img src={editIcon} alt="" />
                                Edit
                              </button>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs mg-t-0 pd-t-2 cardsection">
                      <div className="col-md-4 col-lg-3 mg-b-30 cardLeft">
                        <div className="dashboardLeftcard az-profile-overview">
                          <div className="az-img-user ml-auto mr-auto align-items-center mt-0">
                            <figure>
                              <img
                                src={
                                  this.state.company_logo_thumb_url
                                    ? this.state.company_logo_thumb_url
                                    : companyLogo
                                }
                                alt=""
                              />
                            </figure>
                          </div>
                          {/* az-img-user */}

                          <div className="d-flex justify-content-center text-center mg-b-20 mg-t-20">
                            <div>
                              <p className="az-profile-name-text">DBA</p>
                              <p className="media-body">
                                {this.state.company_name
                                  ? this.state.company_name
                                  : ""}
                              </p>

                              <p className="az-profile-name-text">
                                Company SKU
                              </p>
                              <p className="media-body">
                                {this.state.aka_name ? this.state.aka_name : ""}
                              </p>

                              <p className="az-profile-name-text">
                                Legal Entity
                              </p>
                              <p className="media-body">
                                {this.state.legal_entity_name
                                  ? this.state.legal_entity_name
                                  : ""}
                              </p>

                              <p className="az-profile-name-text">Tax ID</p>
                              <p className="media-body">
                                {this.state.tax_id ? this.state.tax_id : ""}
                              </p>

                              {/* manager info start */}

                              <p className="az-profile-name-text">
                                Client Contact
                              </p>

                              <p className="media-body p-0 m-0">
                                {this.state.client_contact_name
                                  ? this.state.client_contact_name
                                  : ""}
                              </p>
                              <p className="media-body p-0 m-0">
                                {this.state.client_contact_email
                                  ? this.state.client_contact_email
                                  : ""}
                              </p>
                              <p className="media-body pd-b-1 mg-b-15">
                                {this.state.clientInfophoneNumber
                                  ? this.state.clientInfophoneNumber
                                  : ""}
                              </p>

                              <p className="az-profile-name-text">
                                Orion Haus Account Manager
                              </p>

                              <p className="media-body p-0 m-0">
                                {this.state.orion_haus_account_manager_name
                                  ? this.state.orion_haus_account_manager_name
                                  : ""}
                              </p>
                              <p className="media-body p-0 m-0">
                                {this.state.orion_haus_email
                                  ? this.state.orion_haus_email
                                  : ""}
                              </p>
                              <p className="media-body p-0 m-0">
                                {this.state.orionHausPhoneNumber
                                  ? this.state.orionHausPhoneNumber
                                  : ""}
                              </p>
                              {/* manager info end */}
                            </div>
                          </div>
                        </div>
                        {/* az-profile-overview */}
                      </div>

                      <div className="col-md-8 col-lg-9 mg-b-30 cardRight">
                        <div className="dashboardRightcard overview p-0">
                          <div className="row">
                            <div className="col-md-12 pd-l-15 pd-r-15 mg-b-15">
                              <div className="dashbaord-pageHeadertitle overview-title d-flex flex-wrap- justify-content-between align-items-center rounded-0">
                                <div className="col-md-3">
                                  <h3>
                                    {this.state.total_client_count
                                      ? this.state.total_brand_count
                                      : "0"}{" "}
                                    {this.state.total_client_count === 0 ||
                                    this.state.total_client_count === 1
                                      ? "Client"
                                      : "Clients"}
                                  </h3>
                                </div>
                                <div className="col-md-3">
                                  <h3>
                                    {this.state.total_brand_count
                                      ? this.state.total_brand_count
                                      : "0"}{" "}
                                    {this.state.total_brand_count === 0 ||
                                    this.state.total_brand_count === 1
                                      ? "Brand"
                                      : "Brands"}
                                  </h3>
                                </div>
                                <div className="col-md-3">
                                  <h3>
                                    {this.state.total_property_count
                                      ? this.state.total_property_count
                                      : "0"}{" "}
                                    {this.state.total_property_count === 0 ||
                                    this.state.total_property_count === 1
                                      ? "Property"
                                      : "Properties"}
                                  </h3>
                                </div>
                                <div className="col-md-3">
                                  {" "}
                                  <h3>
                                    {this.state.total_unit_count
                                      ? this.state.total_unit_count
                                      : "0"}{" "}
                                    {this.state.total_unit_count === 0 ||
                                    this.state.total_unit_count === 1
                                      ? "Unit"
                                      : "Units"}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mg-t-0 mg-md-t-0 pd-20 pd-t-30 pd-b-30 ml-0 overview-section">
                            <div className="dashboardRightcard overviewSection">
                              <div className="row m-0 mg-t-0 mg-md-t-0">
                                <div className="col-md-3">
                                  <p className="az-profile-name-text pl-0">
                                    Street 1
                                  </p>
                                  <p className="media-body">
                                    {this.state.street_1
                                      ? this.state.street_1
                                      : ""}
                                  </p>
                                </div>
                                <div className="col-md-3">
                                  <p className="az-profile-name-text pl-0">
                                    Street 2
                                  </p>
                                  <p className="media-body">
                                    {this.state.street_2
                                      ? this.state.street_2
                                      : ""}
                                  </p>
                                </div>
                                <div className="col-md-3">
                                  <p className="az-profile-name-text pl-0">
                                    State
                                  </p>
                                  <p className="media-body">
                                    {this.state.state_name
                                      ? this.state.state_name
                                      : ""}
                                  </p>
                                </div>
                                <div className="col-md-3">
                                  <p className="az-profile-name-text pl-0">
                                    Zip Code
                                  </p>
                                  <p className="media-body">
                                    {this.state.zipcode
                                      ? this.state.zipcode
                                      : ""}
                                  </p>
                                </div>
                              </div>

                              <div className="row m-0 mg-t-0 mg-md-t-0">
                                <div className="col-md-3">
                                  <p className="az-profile-name-text pl-0">
                                    City
                                  </p>
                                  <p className="media-body">
                                    {this.state.city_name
                                      ? this.state.city_name
                                      : ""}
                                  </p>
                                </div>
                                <div className="col-md-3">
                                  <p className="az-profile-name-text pl-0">
                                    Country
                                  </p>
                                  <p className="media-body">
                                    {this.state.country_name
                                      ? this.state.country_name
                                      : ""}
                                  </p>
                                </div>
                              </div>

                              <div className="row m-0 mg-t-0 mg-md-t-0">
                                <div className="col-md-3">
                                  <p className="az-profile-name-text pl-0">
                                    County
                                  </p>
                                  <p className="media-body">
                                    {this.state.county_name
                                      ? this.state.county_name
                                      : ""}
                                  </p>
                                </div>
                              </div>

                              <hr className="mg-t-10 mg-b-30 pd-b-2" />

                              <div className="row m-0 mg-t-0 mg-md-t-0">
                                <div className="col-md-3">
                                  <p className="az-profile-name-text pl-0">
                                    Contact Name
                                  </p>
                                  <p className="media-body">
                                    {this.state.contact_name
                                      ? this.state.contact_name
                                      : ""}
                                  </p>
                                </div>

                                <div className="col-md-3">
                                  <p className="az-profile-name-text pl-0">
                                    Phone
                                  </p>
                                  <p className="media-body">
                                    {this.state.phoneNumber
                                      ? this.state.phoneNumber
                                      : ""}
                                  </p>
                                </div>

                                <div className="col-md-3">
                                  <p className="az-profile-name-text pl-0">
                                    Email
                                  </p>
                                  <p className="media-body">
                                    {this.state.email ? this.state.email : ""}
                                  </p>
                                </div>
                                <div className="col-md-12">
                                  <p className="az-profile-name-text pl-0">
                                    Website Cookies
                                  </p>
                                  <div className="para">
                                    {this.state.website_cookie ? ( //check if website_cookie is exist
                                      <p className="p_wrap1">
                                        {this.state.descShow
                                          ? this.state.website_cookie.slice(
                                              0,
                                              global.showMoreLength
                                            )
                                          : this.state.website_cookie}

                                        {this.state.website_cookie.length >
                                          global.showMoreLength && (
                                          <span onClick={this.fullText}>
                                            {this.state.descShow ? (
                                              <p className="hand-cursor showmore">
                                                ...Show more{" "}
                                              </p>
                                            ) : (
                                              <p className="hand-cursor showmore">
                                                Show Less{" "}
                                              </p>
                                            )}
                                          </span>
                                        )}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <p className="az-profile-name-text pl-0">
                                    Terms & Conditions
                                  </p>
                                  <div className="para">
                                    {this.state.term_condition ? ( //check if term_condition is exist
                                      <p className="p_wrap1">
                                        {this.state.descShow
                                          ? this.state.term_condition.slice(
                                              0,
                                              global.showMoreLength
                                            )
                                          : this.state.term_condition}

                                        {this.state.term_condition.length >
                                          global.showMoreLength && (
                                          <span onClick={this.fullText}>
                                            {this.state.descShow ? (
                                              <p className="hand-cursor showmore">
                                                ...Show more{" "}
                                              </p>
                                            ) : (
                                              <p className="hand-cursor showmore">
                                                Show Less{" "}
                                              </p>
                                            )}
                                          </span>
                                        )}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default Company;
