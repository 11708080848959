////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ReservationForm.jsx                                //
//  Application:  Reservation                                   //
//  Option: For add or update Reservation                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-04-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";

import { Container, Form, Table, InputGroup, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import SimpleReactValidator from "simple-react-validator";
import UnitsService from "../../services/UnitsService";
import ListingService from "../../services/ListingService";
import GuestService from "../../services/GuestService";
import ReservationService from "../../services/ReservationService";
import CountryService from "../../services/CountryService";
import ChannelService from "../../services/ChannelService";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from "moment";
import CalendarSidebar from "../common/CalendarSidebar";
import InputMask from "react-input-mask";
import PhoneInput from "react-phone-input-2";
import PropertyService from "../../services/PropertyService";
import TaxService from "../../services/TaxService";
// import StarRatings from "react-star-ratings";
import GooglePlaces from "../common/GooglePlaces";
import PropertyCostService from "../../services/PropertyCostService";
import deleteicon from "../../assets/images/delete.svg";
import pluswIcon from "./../../assets/images/plus.svg";
import PetPolicyService from "../../services/PetPolicyService";
import NumberFormat from "react-number-format";
// import UserService from "../../services/UserService";
import AllPropertyReservationSidebar from "../common/AllPropertyReservationSidebar";
export class ReservationForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      open: true,
      eventKey: "3",
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      lisitngList: [],
      newListingList: [],
      selectedListingList: [],
      status_id: [],
      set_channel: "",
      pathname: window.location.pathname,
      importModal: false,
      confirm_back: 0,
      activeClass: "basic",
      confirmation_code: "",
      unit_slug: [],
      checkin_date: "",
      checkout_date: "",
      guest_slug: [],
      guestList: [],
      newGuestList: [],
      selectedGuestList: [],
      adults: "",
      children: "",
      infants: "",
      pets: "",
      nightly_rate: "",
      total_stay_nights: 0,
      guest_paid: "",
      host_payout: "",
      pet_fee: "",
      security_deposite: "",
      isGuestExist: 0,
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      checkin_time: "",
      checkout_time: "",
      check_in_time: "",
      check_out_time: "",
      tower_name: "",
      set_channel_name: "",
      disabled_dates: [],
      selectStatusList: [],
      selectedStatusList: [],
      selectChannelList: [],
      selectedChannelList: [],
      newChannelList: [],
      city_name: "",
      state_name: "",
      county_name: "",
      country_name: "",
      guestUserSlug: "",
      cDate: 0,
      maxCheckoutDate: "",
      cityListTotal: 0,
      guest_service_fee: "",
      payment_processing_fee: "",
      total_nightly_fee: "",
      occupancy_taxes: "",
      host_service_fee: "",
      is_id_verified: false,
      is_publish: false,
      cleanliness_rating: 0,
      accuracy_rating: 0,
      communication_rating: 0,
      location_rating: 0,
      check_in_rating: 0,
      value_rating: 0,
      comment: "",
      isMailSend: 0,
      costListTotal: 0,
      petPolicyTypeDataTotal: 1,
      petPolicyTypeData: [
        {
          pet_type_slug: "",
          allowed_pet_number: "",
          one_time_fee_per_type: "",
          weight_per_type: "",
          policy_slug: "",
        },
      ],
      pet_data: [],
      pet_type_slug: [],
      newPetTypeList: [],
      petTypeListDropdown: [],
      selectedPetTypeListDropdown: [],
      selectedPetTypeList: [],
      showPetFee: 0,
      pet_policy_error: false,
      resolution_fee_error: false,
      valid_date_error: false,
      occupancy_taxes_array: [],
      taxOccupancyList: [],
      taxOccupancyListTotal: 0,
      taxList: [],
      taxListTotal: 0,
      resolutionFeeDataTotal: 1,
      resolutionFeeData: [
        {
          resolution_item: "",
          resolution_amount: "",
          to_building_amount: "",
          to_building: "",
          to_oh_amount: "",
          to_oh: "",
          to_resident_amount: "",
          to_resident: "",
          payment_received: 0,
          payment_received_date: "",
          resolution_fee_slug: "",
        },
      ],
      total_resolution_fee: "",
      resolution_fee_data: [],
      earningConfiguration: [],
      earningConfigurationTotal: 0,
      earning_configuration: [],
      earningConfigurationPayoutTotal: 0,
      earningConfigurationPayout: [],
      lock_type: 0,
      bypass_guest_form: "",
      bypass_card_token: "",
      bypass_parking_payment: "",
      bypass_email_verification: "",
      guest_info_keep: "",
    };

    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateReservation = this.saveOrUpdateReservation.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandler1 = this.changeHandler1.bind(this);
    this.getListingAvailableDate = this.getListingAvailableDate.bind(this);
    this.getListingAvailableDateForSameSlug =
      this.getListingAvailableDateForSameSlug.bind(this);
    this.getChildData = this.getChildData.bind(this);
  }

  async componentDidMount() {
    this.getStatusList();
    this.getChannelList();
    // this.getPropertyEarningConfigurationData();
    if (this.state.slug === undefined) {
      this.getPropertyTime();
    }
    this.getListingData();

    this.setState({ confirm_back: 0, isGuestExist: 0 });
    if (this.state.slug !== undefined) {
      this.getReservationOccupancyTaxData();
      var resData = {};
      var petPolicyTypeDataTotal = 1;
      var resolutionFeeDataTotal = 1;
      let res = await ReservationService.getReservation(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData.unit_slug = res.data.unit ? res.data.unit.slug : "";
        resData.unit_number = res.data.unit ? res.data.unit.unit_number : "";
        resData.listing_slug = res.data.listing_details
          ? res.data.listing_details.slug
          : "";
        var selectedUnitList = [];
        selectedUnitList = res.data.unit
          ? [
              {
                value: res.data.unit ? res.data.unit.slug : "",
                label: res.data.unit ? res.data.unit.unit_number : "",
              },
            ]
          : "";
        var selectedListingList = [];
        selectedListingList = res.data.listing_details
          ? [
              {
                value: res.data.listing_details.slug,
                label: res.data.unit
                  ? res.data.listing_details.listing_name +
                    (res.data.unit
                      ? " (" + res.data.unit.unit_number + ") "
                      : "")
                  : res.data.listing_details.listing_name,
              },
            ]
          : "";

        resData.checkin_date_only = res.data.checkin_date
          ? Moment(res.data.checkin_date).format("MM/DD/yy")
          : "";
        resData.checkin_date = res.data.checkin_date
          ? Moment(res.data.checkin_date).toDate("MM/dd/yy")
          : "";

        resData.checkout_date_only = res.data.checkout_date
          ? Moment(res.data.checkout_date).format("MM/DD/yy")
          : "";
        resData.checkout_date = res.data.checkout_date
          ? Moment(res.data.checkout_date).toDate("MM/dd/yy")
          : "";
        resData.checkin_time = res.data.checkin_time
          ? res.data.checkin_time
          : "";
        resData.confirmation_code = res.data.confirmation_code
          ? res.data.confirmation_code
          : "";
        resData.checkout_time = res.data.checkout_time
          ? res.data.checkout_time
          : "";
        resData.phone_number = res.data.phone_number
          ? res.data.phone_number
          : res.data.guest_user && res.data.guest_user.phone_number
          ? res.data.guest_user.phone_number
          : "";

        resData.first_name = res.data.first_name
          ? res.data.first_name
          : res.data.guest_user && res.data.guest_user.first_name
          ? res.data.guest_user.first_name
          : "";

        resData.last_name = res.data.last_name
          ? res.data.last_name
          : res.data.guest_user && res.data.guest_user.last_name
          ? res.data.guest_user.last_name
          : "";

        resData.email = res.data.email
          ? res.data.email
          : res.data.guest_user && res.data.guest_user.email
          ? res.data.guest_user.email
          : "";

        resData.sendEmail = res.data.email
          ? res.data.email
          : res.data.guest_user && res.data.guest_user.email
          ? res.data.guest_user.email
          : "";

        resData.name_for_top = res.data.guest_user
          ? res.data.guest_user.first_name + " " + res.data.guest_user.last_name
          : "";

        resData.guestUserSlug = res.data.guest_user
          ? res.data.guest_user.slug
          : "";
        resData.is_id_verified = res.data.guest_user
          ? res.data.guest_user.is_id_verified === 1
            ? true
            : false
          : false;

        resData.passport = res.data.passport
          ? res.data.passport
          : res.data.guest_user && res.data.guest_user.passport
          ? res.data.guest_user.passport
          : "";

        resData.bypass_guest_form =
          res.data.guest_form && res.data.guest_form.is_form_submited
            ? res.data.guest_form.is_form_submited === 1
              ? true
              : false
            : "";
        resData.bypass_card_token =
          res.data.guest_form && res.data.guest_form.card_tokenise
            ? res.data.guest_form.card_tokenise === 1
              ? true
              : false
            : "";
        resData.bypass_parking_payment =
          res.data.guest_form && res.data.guest_form.parking_payment
            ? res.data.guest_form.parking_payment === 1
              ? true
              : false
            : "";
        resData.bypass_email_verification =
          res.data.guest_form && res.data.guest_form.email_verified
            ? res.data.guest_form.email_verified == 1
              ? true
              : false
            : "";
        resData.guest_info_keep =
          res.data && res.data.guest_info_keep
            ? res.data.guest_info_keep == 1
              ? true
              : false
            : "";

        resData.front_photo_name =
          res.data.guest_form && res.data.guest_form.front_photo_name
            ? res.data.guest_form.front_photo_name
            : "";
        resData.back_photo_name =
          res.data.guest_form && res.data.guest_form.back_photo_name
            ? res.data.guest_form.back_photo_name
            : "";
        resData.selfie_photo_name =
          res.data.guest_form && res.data.guest_form.selfie_photo_name
            ? res.data.guest_form.selfie_photo_name
            : "";
        resData.signature_name =
          res.data.guest_form && res.data.guest_form.signature_name
            ? res.data.guest_form.signature_name
            : "";
        resData.id_type =
          res.data.guest_form && res.data.guest_form.id_type
            ? res.data.guest_form.id_type
            : "";

        resData.adults = res.data.adults ? res.data.adults : "";
        resData.children = res.data.children ? res.data.children : "";
        resData.infants = res.data.infants ? res.data.infants : "";
        resData.pets = res.data.pets ? res.data.pets : "";
        resData.lock_type =
          res.data.property && res.data.property.lock_type
            ? res.data.property.lock_type
            : "";

        if (
          resData.pets &&
          resData.pets !== undefined &&
          resData.pets !== null &&
          resData.pets !== ""
        ) {
          setTimeout(() => {
            this.getReservationPetPolicyData();
          }, 100);
          this.setState({ showPetFee: 1 });
        }
        resData.property_pet_allowed = res.data.property
          ? res.data.property.pet_allowed
          : "";
        resData.listing_pet_allowed = res.data.listing_details
          ? res.data.listing_details.listing_pet_allowed
          : "";
        resData.nightly_rate = res.data.nightly_rate
          ? res.data.nightly_rate
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";

        resData.oh_earning = res.data.total_oh_earning
          ? res.data.total_oh_earning
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.property_earning = res.data.total_property_earning
          ? res.data.total_property_earning
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.oh_earning_resolution = res.data.oh_earning_resolution
          ? res.data.oh_earning_resolution
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.property_earning_resolution = res.data
          .property_earning_resolution
          ? res.data.property_earning_resolution
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.resident_earning_resolution = res.data
          .resident_earning_resolution
          ? res.data.resident_earning_resolution
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.pet_policy_fee = res.data.pet_policy_fee
          ? res.data.pet_policy_fee
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.total_resolution_fee = res.data.resolution_fee
          ? res.data.resolution_fee
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.total_stay_nights = res.data.total_stay_nights
          ? res.data.total_stay_nights
          : "";
        resData.rating = res.data.guestReservationRating
          ? res.data.guestReservationRating.rating
          : 0;
        resData.cleanliness_rating = res.data.guestReservationRating
          ? res.data.guestReservationRating.cleanliness
          : 0;
        resData.accuracy_rating = res.data.guestReservationRating
          ? res.data.guestReservationRating.accuracy
          : 0;
        resData.communication_rating = res.data.guestReservationRating
          ? res.data.guestReservationRating.communication
          : 0;
        resData.location_rating = res.data.guestReservationRating
          ? res.data.guestReservationRating.location
          : 0;
        resData.check_in_rating = res.data.guestReservationRating
          ? res.data.guestReservationRating.check_in
          : 0;
        resData.value_rating = res.data.guestReservationRating
          ? res.data.guestReservationRating.value
          : 0;
        resData.comment = res.data.guestReservationRating
          ? res.data.guestReservationRating.comment
          : "";
        resData.is_publish = res.data.guestReservationRating
          ? res.data.guestReservationRating.is_publish === 1
            ? true
            : false
          : false;

        resData.street_1 = res.data.street_1
          ? res.data.street_1
          : res.data.guest_user && res.data.guest_user.street_1
          ? res.data.guest_user.street_1
          : "";

        resData.street_2 = res.data.street_2
          ? res.data.street_2
          : res.data.guest_user && res.data.guest_user.street_2
          ? res.data.guest_user.street_2
          : "";

        resData.zip_code = res.data.zipcode
          ? res.data.zipcode
          : res.data.guest_user && res.data.guest_user.zip_code
          ? res.data.guest_user.zip_code
          : "";

        resData.city_name =
          res.data.city && res.data.city.city_name
            ? res.data.city.city_name
            : res.data.guest_user && res.data.guest_user.city
            ? res.data.guest_user.city.city_name
            : "";

        resData.country_name =
          res.data.country && res.data.country.country_name
            ? res.data.country.country_name
            : res.data.guest_user && res.data.guest_user.country
            ? res.data.guest_user.country.country_name
            : "";

        resData.county_name =
          res.data.county && res.data.county.county_name
            ? res.data.county.county_name
            : res.data.guest_user && res.data.guest_user.county
            ? res.data.guest_user.county.county_name
            : "";

        resData.state_name =
          res.data.state && res.data.state.state_name
            ? res.data.state.state_name
            : res.data.guest_user && res.data.guest_user.state
            ? res.data.guest_user.state.state_name
            : "";
        resData.resident_earning = res.data.resident_earning
          ? res.data.resident_earning
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.airbnb_earning = res.data.airbnb_earning
          ? res.data.airbnb_earning
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.host_service_fee = res.data.host_service_fee
          ? res.data.host_service_fee
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.total_nightly_fee = res.data.total_nightly_rate
          ? res.data.total_nightly_rate
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.guest_paid = res.data.guest_paid
          ? res.data.guest_paid
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.host_payout = res.data.host_payout
          ? res.data.host_payout
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.pet_fee = res.data.pet_fee
          ? res.data.pet_fee
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.security_deposite = res.data.security_deposite
          ? res.data.security_deposite
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.internal_notes = res.data.internal_notes
          ? res.data.internal_notes
          : "";
        resData.include_owner_report = res.data.include_owner_report
          ? res.data.include_owner_report
          : "";
        resData.payment_processing_fee = res.data.payment_processing_fee
          ? res.data.payment_processing_fee
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";

        resData.status_id = res.data.status_id ? res.data.status_id : [];
        resData.partner_reservation_id = res.data.partner_reservation_id
          ? res.data.partner_reservation_id
          : "";
        resData.to_oh_total = res.data.to_oh_total
          ? res.data.to_oh_total.replace(/\.00$/, "")
          : "";
        resData.to_resident_total = res.data.to_resident_total
          ? res.data.to_resident_total.replace(/\.00$/, "")
          : "";
        resData.to_building_total = res.data.to_building_total
          ? res.data.to_building_total.replace(/\.00$/, "")
          : "";
        // resData.convenience_fee = res.data.convenience_fee
        //   ? res.data.convenience_fee.replace(/\.00$/, "")
        //   : "";
        resData.internal_notes = res.data.internal_notes
          ? res.data.internal_notes
          : "";
        resData.include_owner_report = res.data.include_owner_report
          ? res.data.include_owner_report
          : "";
        resData.set_channel =
          res.data && res.data.channel && res.data.channel.slug
            ? res.data.channel.slug
            : "";
        var selectedStatusList = [];
        selectedStatusList =
          res.data.status_id === 1
            ? [
                {
                  value: 1,
                  label: "Confirmed",
                },
              ]
            : res.data.status_id === 2
            ? [
                {
                  value: 2,
                  label: "Cancelled",
                },
              ]
            : [
                {
                  value: 3,
                  label: "Payment Pending",
                },
              ];
        var set_channel_name =
          res.data && res.data.channel && res.data.channel.channel_name
            ? res.data.channel.channel_name
            : "";
        var selectedChannelList = [];
        selectedChannelList = res.data.channel
          ? [
              {
                value: res.data.channel.slug,
                label: res.data.channel.channel_name,
              },
            ]
          : "";

        if (res.data.feeArray) {
          res.data.feeArray.forEach((item, i) => {
            if (item.fees_name === "Cleaning Fee") {
              resData.cleaning_fee = item.fees_amount
                ? item.fees_amount
                    .replace(/\.00$/, "")
                    .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
                : "";
            }
            if (item.fees_name === "Service Fee") {
              resData.guest_service_fee = item.fees_amount
                ? item.fees_amount
                    .replace(/\.00$/, "")
                    .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
                : "";
            }
          });
        }
        if (res.data.taxArray) {
          if (res.data.taxArray.tax_name === "Occupancy Tax Fee") {
            resData.occupancy_taxes = res.data.taxArray.tax_amount
              ? res.data.taxArray.tax_amount
                  .replace(/\.00$/, "")
                  .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
              : "";
          }
        }
        resData.petPolicyDataLength = res.data.petFeesData
          ? res.data.petFeesData.length
          : 0;
        resData.petPolicyData = res.data.petFeesData
          ? res.data.petFeesData
          : [
              {
                pet_type_slug: "",
                allowed_pet_number: "",
                pet_count: "",
                deposite_amount_per_pet: "",
                one_time_fee_per_type: "",
                rent_per_type: "",
                weight_per_type: "",
                pet_weight: "",
                policy_slug: "",
              },
            ];
        const values = [];
        if (resData.petPolicyDataLength > 0) {
          resData.petPolicyData.forEach((item, i) => {
            values.push({
              pet_type_slug: item.pet_type.slug,
              allowed_pet_number: item.allowed_pet_number,
              pet_count: item.pet_count,
              one_time_fee_per_type: item.one_time_fee_per_type,
              deposite_amount_per_pet: item.deposite_amount_per_pet,
              rent_per_type: item.rent_per_type,
              weight_per_type: item.weight_per_type,
              pet_weight: item.pet_weight,
              policy_slug: item.slug,
              selected_pet_type: {
                value: item.pet_type.slug,
                label: item.pet_type.pet_type_name,
              },
            });
          });
        } else {
          resData.petPolicyTypeData = [
            {
              pet_type_slug: "",
              allowed_pet_number: "",
              pet_count: "",
              deposite_amount_per_pet: "",
              one_time_fee_per_type: "",
              rent_per_type: "",
              weight_per_type: "",
              pet_weight: "",
              policy_slug: "",
            },
          ];
        }
        petPolicyTypeDataTotal = values.length;
        this.setState({
          petPolicyTypeData: values,
          petPolicyTypeDataTotal: petPolicyTypeDataTotal,
        });

        resData.resolutionFeeDataTotal = res.data.reservationResolutionFeeData
          ? res.data.reservationResolutionFeeData.length
          : 0;
        resData.resolutionFeeData1 = res.data.reservationResolutionFeeData
          ? res.data.reservationResolutionFeeData
          : [
              {
                resolution_item: "",
                resolution_amount: "",
                to_building_amount: "",
                to_building: "",
                to_oh_amount: "",
                to_oh: "",
                to_resident_amount: "",
                to_resident: "",
                payment_received: 0,
                payment_received_date: "",
                resolution_fee_slug: "",
              },
            ];
        const resolutionFeeValues = [];
        if (resData.resolutionFeeDataTotal > 0) {
          resData.resolutionFeeData1.forEach((item, i) => {
            resolutionFeeValues.push({
              resolution_item: item.resolution_item,
              resolution_amount: item.resolution_amount,
              to_building_amount: item.to_building_amount,
              to_building: item.to_building,
              to_oh_amount: item.to_oh_amount,
              to_oh: item.to_oh,
              to_resident_amount: item.to_resident_amount,
              to_resident: item.to_resident,
              payment_received: item.payment_received,
              payment_received_date: item.payment_received_date_string
                ? item.payment_received_date_string
                : "",
              // payment_received_date: item.payment_received_date
              //   ? Moment(item.payment_received_date, "YYYY-MM-DD").toDate(
              //       "MM/dd/yy"
              //     )
              //   : "",
              resolution_fee_slug: item.slug,
            });
          });
        } else {
          resData.resolutionFeeData = [
            {
              resolution_item: "",
              resolution_amount: "",
              to_building_amount: "",
              to_building: "",
              to_oh_amount: "",
              to_oh: "",
              to_resident_amount: "",
              to_resident: "",
              payment_received: 0,
              payment_received_date: "",
              resolution_fee_slug: "",
            },
          ];
        }
        resolutionFeeDataTotal = resolutionFeeValues.length;
        this.setState({
          resolution_fee_data: resolutionFeeValues,
          resolutionFeeData: resolutionFeeValues,
          resolutionFeeDataTotal: resolutionFeeDataTotal,
        });
      }
      this.setState(resData);
      this.setState({
        selectedUnitList: selectedUnitList,
        selectedStatusList: selectedStatusList,
        selectedChannelList: selectedChannelList,
        selectedListingList: selectedListingList,
        set_channel_name: set_channel_name,
      });
      if (this.state.slug !== undefined) {
        this.getUnitPayoutConfigurationData(this.state.unit_slug);
        this.getReservationMaxDate(
          this.state.unit_slug,
          this.state.listing_slug,
          Moment(res.data.checkin_date, "YYYY-MM_DD").format("YYYY-MM-DD")
        );
        this.getListingAvailableDateForSameSlug(
          this.state.unit_slug,
          this.state.slug
        );
      }
      // if (this.state.listing_slug !== undefined) {
      //   this.getListingFees(this.state.listing_slug);
      // }
      if (this.state.slug !== undefined) {
        this.getReservationFees(this.state.slug);
      }
      this.defaultData = resData;
    }
  }

  async getUnitPayoutConfigurationData(unitSlug) {
    if (unitSlug !== undefined) {
      var earningConfiguration = [];
      var earningConfigurationTotal = 0;
      var total_nightly_rate_to_resident = 0;
      var cleaning_fee_to_resident = 0;
      var channel_fee_to_resident = 0;
      var total_nightly_rate_to_oh = 0;
      var cleaning_fee_to_oh = 0;
      var channel_fee_to_oh = 0;
      var total_nightly_rate_to_property = 0;
      var cleaning_fee_to_property = 0;
      var channel_fee_to_property = 0;
      var resData = {};
      const values = [];
      var res = "";
      if (this.state.slug !== undefined) {
        res = await ReservationService.getReservationEarningConfiguration(
          "slug=" +
            this.state.slug +
            "&reference=reservation_details" +
            "&list=" +
            12
        );
      } else {
        res = await UnitsService.getUnitEarningConfigurationData(
          "unitSlug=" + unitSlug + "&reference=units" + "&list=" + 5
        );
      }
      if (global.successStatus.includes(res.status)) {
        resData.earningConfigurationDataLength = res.data ? res.data.length : 0;
        resData.earningConfigurationData = res.data
          ? res.data
          : [
              {
                fee_type: 1,
                fee_type_name: "Total Nightly Rate",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 2,
                fee_type_name: "Cleaning Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 3,
                fee_type_name: "Channel Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
            ];

        if (resData.earningConfigurationDataLength > 0) {
          resData.earningConfigurationData.forEach((item, i) => {
            values.push({
              fee_type: item.fee_type,
              fee_type_name: item.fee_type_name,
              payout_oh: item.payout_oh,
              payout_oh_amount: item.payout_oh_amount,
              payout_resident: item.payout_resident,
              payout_resident_amount: item.payout_resident_amount,
              payout_property: item.payout_property,
              payout_property_amount: item.payout_property_amount,
              slug: item.slug,
            });
          });
        } else {
          resData.earningConfigurationData = [
            {
              fee_type: 1,
              fee_type_name: "Total Nightly Rate",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 2,
              fee_type_name: "Cleaning Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 3,
              fee_type_name: "Channel Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
          ];
        }
        earningConfiguration = res.data ? res.data : [];
        earningConfigurationTotal = earningConfiguration
          ? earningConfiguration.length
          : 0;
      }
      if (earningConfigurationTotal > 0) {
        earningConfiguration.map((opt, i) => {
          if (opt.fee_type === 1) {
            total_nightly_rate_to_resident = opt.payout_resident
              ? opt.payout_resident.replace(/\.00$/, "")
              : "";
            total_nightly_rate_to_oh = opt.payout_oh
              ? opt.payout_oh.replace(/\.00$/, "")
              : "";
            total_nightly_rate_to_property = opt.payout_property
              ? opt.payout_property.replace(/\.00$/, "")
              : "";
          }
          if (opt.fee_type === 2) {
            cleaning_fee_to_resident = opt.payout_resident
              ? opt.payout_resident.replace(/\.00$/, "")
              : "";
            cleaning_fee_to_oh = opt.payout_oh
              ? opt.payout_oh.replace(/\.00$/, "")
              : "";
            cleaning_fee_to_property = opt.payout_property
              ? opt.payout_property.replace(/\.00$/, "")
              : "";
          }
          if (opt.fee_type === 3) {
            channel_fee_to_resident = opt.payout_resident
              ? opt.payout_resident.replace(/\.00$/, "")
              : "";
            channel_fee_to_oh = opt.payout_oh
              ? opt.payout_oh.replace(/\.00$/, "")
              : "";
            channel_fee_to_property = opt.payout_property
              ? opt.payout_property.replace(/\.00$/, "")
              : "";
          }
        });
      }
      this.setState({
        total_nightly_rate_to_resident: total_nightly_rate_to_resident,
        total_nightly_rate_to_oh: total_nightly_rate_to_oh,
        total_nightly_rate_to_property: total_nightly_rate_to_property,
        cleaning_fee_to_resident: cleaning_fee_to_resident,
        cleaning_fee_to_oh: cleaning_fee_to_oh,
        cleaning_fee_to_property: cleaning_fee_to_property,
        channel_fee_to_resident: channel_fee_to_resident,
        channel_fee_to_oh: channel_fee_to_oh,
        channel_fee_to_property: channel_fee_to_property,
        earning_configuration: values,
        earningConfigurationData: values,
        earningConfigurationPayout: earningConfiguration,
        earningConfigurationPayoutTotal: earningConfigurationTotal,
      });
      if (resData.earningConfigurationDataLength === 0) {
        this.getPropertyEarningConfigurationData();
      }
    }
  }

  async getPropertyEarningConfigurationData() {
    if (this.state.property_slug !== undefined) {
      var earningConfiguration = [];
      var earningConfigurationTotal = 0;
      var total_nightly_rate_to_resident = 0;
      var cleaning_fee_to_resident = 0;
      var channel_fee_to_resident = 0;
      var total_nightly_rate_to_oh = 0;
      var cleaning_fee_to_oh = 0;
      var channel_fee_to_oh = 0;
      var total_nightly_rate_to_property = 0;
      var cleaning_fee_to_property = 0;
      var channel_fee_to_property = 0;
      var resData = {};
      const values = [];
      var res = "";
      if (this.state.slug !== undefined) {
        res = await ReservationService.getReservationEarningConfiguration(
          "slug=" +
            this.state.slug +
            "&reference=reservation_details" +
            "&list=" +
            12
        );
      } else {
        res = await PropertyService.getPropertyEarningConfiguration(
          "slug=" +
            this.state.property_slug +
            "&reference=properties" +
            "&list=" +
            3
        );
      }
      if (global.successStatus.includes(res.status)) {
        resData.earningConfigurationDataLength = res.data ? res.data.length : 0;
        resData.earningConfigurationData = res.data
          ? res.data
          : [
              {
                fee_type: 1,
                fee_type_name: "Total Nightly Rate",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 2,
                fee_type_name: "Cleaning Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 3,
                fee_type_name: "Channel Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
            ];

        if (resData.earningConfigurationDataLength > 0) {
          resData.earningConfigurationData.forEach((item, i) => {
            values.push({
              fee_type: item.fee_type,
              fee_type_name: item.fee_type_name,
              payout_oh: item.payout_oh,
              payout_oh_amount: item.payout_oh_amount,
              payout_resident: item.payout_resident,
              payout_resident_amount: item.payout_resident_amount,
              payout_property: item.payout_property,
              payout_property_amount: item.payout_property_amount,
              slug: item.slug,
            });
          });
        } else {
          resData.earningConfigurationData = [
            {
              fee_type: 1,
              fee_type_name: "Total Nightly Rate",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 2,
              fee_type_name: "Cleaning Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 3,
              fee_type_name: "Channel Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
          ];
        }
        earningConfiguration = res.data ? res.data : [];
        earningConfigurationTotal = earningConfiguration
          ? earningConfiguration.length
          : 0;
      }
      if (earningConfigurationTotal > 0) {
        earningConfiguration.map((opt, i) => {
          if (opt.fee_type === 1) {
            total_nightly_rate_to_resident = opt.payout_resident
              ? opt.payout_resident.replace(/\.00$/, "")
              : "";
            total_nightly_rate_to_oh = opt.payout_oh
              ? opt.payout_oh.replace(/\.00$/, "")
              : "";
            total_nightly_rate_to_property = opt.payout_property
              ? opt.payout_property.replace(/\.00$/, "")
              : "";
          }
          if (opt.fee_type === 2) {
            cleaning_fee_to_resident = opt.payout_resident
              ? opt.payout_resident.replace(/\.00$/, "")
              : "";
            cleaning_fee_to_oh = opt.payout_oh
              ? opt.payout_oh.replace(/\.00$/, "")
              : "";
            cleaning_fee_to_property = opt.payout_property
              ? opt.payout_property.replace(/\.00$/, "")
              : "";
          }
          if (opt.fee_type === 3) {
            channel_fee_to_resident = opt.payout_resident
              ? opt.payout_resident.replace(/\.00$/, "")
              : "";
            channel_fee_to_oh = opt.payout_oh
              ? opt.payout_oh.replace(/\.00$/, "")
              : "";
            channel_fee_to_property = opt.payout_property
              ? opt.payout_property.replace(/\.00$/, "")
              : "";
          }
        });
      }
      this.setState({
        total_nightly_rate_to_resident: total_nightly_rate_to_resident,
        total_nightly_rate_to_oh: total_nightly_rate_to_oh,
        total_nightly_rate_to_property: total_nightly_rate_to_property,
        cleaning_fee_to_resident: cleaning_fee_to_resident,
        cleaning_fee_to_oh: cleaning_fee_to_oh,
        cleaning_fee_to_property: cleaning_fee_to_property,
        channel_fee_to_resident: channel_fee_to_resident,
        channel_fee_to_oh: channel_fee_to_oh,
        channel_fee_to_property: channel_fee_to_property,
        earning_configuration: values,
        earningConfigurationData: values,
        earningConfigurationPayout: earningConfiguration,
        earningConfigurationPayoutTotal: earningConfigurationTotal,
      });
    }
  }

  async getReservationOccupancyTaxData() {
    this.setState({ showSpinner: true, loading: true });
    var taxOccupancyList = [];
    var taxOccupancyListTotal = 0;
    var occupancyTaxArr = [];
    let res = await ReservationService.getReservationOccupancyTax(
      "property_slug=" +
        this.state.property_slug +
        "&reservation_slug=" +
        this.state.slug
    );
    if (global.successStatus.includes(res.status)) {
      taxOccupancyList = res.data ? res.data : [];
      taxOccupancyListTotal = taxOccupancyList ? taxOccupancyList.length : 0;
    }
    if (taxOccupancyListTotal > 0) {
      taxOccupancyList.map((opt, i) => {
        occupancyTaxArr.push({
          name: opt.name,
          tax_distribution: opt.tax_distribution,
          amount: opt.amount,
          tax_type_id: opt.tax_type_id,
        });
      });
      this.setState({ occupancy_taxes_array: occupancyTaxArr });
    }
    this.setState({
      showSpinner: false,
      loading: false,
      taxOccupancyListTotal: taxOccupancyListTotal,
      taxOccupancyList: taxOccupancyList,
    });
  }

  async getDefaultPetData() {
    if (this.state.property_slug !== undefined) {
      var resData = {};
      var pet_policy_fees_total = 0;
      var petPolicyDataListTotal = 0;
      var petPolicyDataList = [];
      var petPolicyTypeDataTotal = 1;
      let res = await PetPolicyService.getPetPolicy(
        this.state.listing_slug + "/10"
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.petPolicyDataLength = res.data.petPolicyData
          ? res.data.petPolicyData.length
          : 0;
        petPolicyDataList = res.data.petPolicyData
          ? res.data.petPolicyData
          : [];
        petPolicyDataListTotal = petPolicyDataList
          ? petPolicyDataList.length
          : 0;
        resData.petPolicyData = res.data.petPolicyData
          ? res.data.petPolicyData
          : [
              {
                pet_type_slug: "",
                allowed_pet_number: "",
                pet_count: "",
                one_time_fee_per_type: "",
                weight_per_type: "",
                pet_weight: "",
                policy_slug: "",
              },
            ];

        const values = [];
        if (resData.petPolicyDataLength > 0) {
          resData.petPolicyData.forEach((item, i) => {
            values.push({
              pet_type_slug: item.pet_type.slug,
              allowed_pet_number: item.allowed_pet_number,
              pet_count: item.allowed_pet_number,
              one_time_fee_per_type: item.one_time_fee_per_type,
              weight_per_type: item.weight_per_type,
              pet_weight: item.weight_per_type,
              policy_slug: item.slug,
              selected_pet_type: {
                value: item.pet_type.slug,
                label: item.pet_type.pet_type_name,
              },
            });
          });
        } else {
          resData.petPolicyTypeData = [
            {
              pet_type_slug: "",
              allowed_pet_number: "",
              pet_count: "",
              one_time_fee_per_type: "",
              weight_per_type: "",
              pet_weight: "",
              policy_slug: "",
            },
          ];
        }
        const newpetPolicyDataList =
          petPolicyDataListTotal > 0
            ? petPolicyDataList.map(({ pet_type }) => ({
                value: pet_type.slug,
                label: pet_type.pet_type_name,
              }))
            : [];
        petPolicyTypeDataTotal = values.length;
        this.setState({
          petPolicyDataList: newpetPolicyDataList,
          petPolicyDataListTotal: petPolicyDataListTotal,
          petPolicyTypeData: values,
          pet_data: values,
          petPolicyTypeDataTotal: petPolicyTypeDataTotal,
        });
      }
      setTimeout(() => {
        this.state.pet_data.map((opt, i) => {
          pet_policy_fees_total += +(opt.one_time_fee_per_type
            ? (opt.pet_count ? parseInt(opt.pet_count) : 0) *
              parseFloat(opt.one_time_fee_per_type)
            : 0);
        }, 100);
        var guest_paid_pet_v =
          parseFloat(this.state.guest_paid) -
          parseFloat(this.state.pet_policy_fee) +
          parseFloat(pet_policy_fees_total);
        var guest_paid_pet = guest_paid_pet_v
          ? parseFloat(guest_paid_pet_v).toFixed(2).replace(/\.00$/, "")
          : 0;
        var host_payout_pet_v =
          parseFloat(this.state.host_payout) -
          parseFloat(this.state.pet_policy_fee) +
          parseFloat(pet_policy_fees_total);
        var host_payout_pet = host_payout_pet_v
          ? parseFloat(host_payout_pet_v).toFixed(2).replace(/\.00$/, "")
          : 0;
        if (pet_policy_fees_total > 0) {
          this.setState({
            guest_paid: guest_paid_pet,
            host_payout: host_payout_pet,
          });
        }
        this.setState({ pet_policy_fee: pet_policy_fees_total });
      });
      this.setState(resData);
    }
  }

  async getNewPetData(index, val) {
    const values = [...this.state.petPolicyTypeData];
    if (this.state.property_slug !== undefined) {
      var newPetPolicyDataList = [];
      let res = await PetPolicyService.getPetPolicy(
        this.state.listing_slug + "/10"
      );
      if (global.successStatus.includes(res.status)) {
        newPetPolicyDataList = res.data.petPolicyData
          ? res.data.petPolicyData
          : [];

        newPetPolicyDataList.map((opt, i) => {
          if (opt.pet_type.slug === val.value) {
            values[index].allowed_pet_number = opt.allowed_pet_number;
            values[index].weight_per_type = opt.weight_per_type;
            values[index].pet_count = opt.allowed_pet_number;
            values[index].one_time_fee_per_type = opt.one_time_fee_per_type;
            values[index].pet_weight = opt.weight_per_type;
          }
        });
        this.setState({ petPolicyTypeData: values });
      }
    }
  }

  async getReservationPetPolicyData() {
    if (this.state.property_slug !== undefined) {
      var resData = {};
      var petPolicyDataListTotal = 0;
      var petPolicyDataList = [];
      let res = await PetPolicyService.getPetPolicy(
        this.state.listing_slug + "/10"
      );
      if (global.successStatus.includes(res.status)) {
        petPolicyDataList = res.data.petPolicyData
          ? res.data.petPolicyData
          : [];
        petPolicyDataListTotal = petPolicyDataList
          ? petPolicyDataList.length
          : 0;

        const newpetPolicyDataList =
          petPolicyDataListTotal > 0
            ? petPolicyDataList.map(({ pet_type }) => ({
                value: pet_type.slug,
                label: pet_type.pet_type_name,
              }))
            : [];
        this.setState({
          petPolicyDataList: newpetPolicyDataList,
          petPolicyDataListTotal: petPolicyDataListTotal,
        });
      }
      this.setState(resData);
    }
  }

  async getChildData(unit, street1, city, state, county, country, zipcode) {
    this.setState({ street_1: unit });
    this.setState({ street_2: street1 });
    this.setState({ city_name: city });
    this.setState({ state_name: state });
    this.setState({ county_name: county });
    this.setState({ country_name: country });
    this.setState({ zip_code: zipcode });
    //api call for check location
    let inputData = {
      place_country_name: country ? country : "",
      place_state_name: state ? state : "",
      place_county_name: county ? county : "",
      place_city_name: city ? city : "",
    };

    // if(state && country){
    let res = await CountryService.checkLocation(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        countryId: res.data.countryId,
        stateId: res.data.countryId,
        countyId: res.data.countyId,
        cityId: res.data.cityId,
      });
    }
    // }
  }

  async deletePetPolicy(slug) {
    this.setState({ showSpinner: true });

    if (this.state.slug !== undefined) {
      if (slug) {
        let res = await PetPolicyService.deletePetPolicy(slug);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
            selectedPetTypeList: [],
          });
          // this.getDefaultPetData();
        } else {
          let alertMessage = "";
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async getStatusList() {
    var selectedStatusList = [];
    const selectStatusList = [
      { value: "1", label: "Confirmed" },
      { value: "2", label: "Cancelled" },
      { value: "3", label: "Payment Pending" },
    ];

    if (this.state.slug === undefined) {
      selectedStatusList = [{ value: "1", label: "Confirmed" }];
    }
    this.setState({
      selectStatusList: selectStatusList,
      selectedStatusList: selectedStatusList,
      status_id: 1,
    });
  }

  /* to get channel data list */
  async getChannelList() {
    this.setState({ showSpinner: true, loading: true });
    var selectChannelList = [];
    var selectChannelListTotal = 0;

    let res = await ChannelService.getChannel("is_dropdown=1");
    if (global.successStatus.includes(res.status)) {
      selectChannelList = res.data ? res.data : [];
      selectChannelListTotal = selectChannelList ? selectChannelList.length : 0;
    }
    const newChannelList =
      selectChannelListTotal > 0
        ? selectChannelList.map(({ slug, channel_name }) => ({
            value: slug,
            label: channel_name,
          }))
        : [
            { value: "1", label: "Direct Booking" },
            { value: "2", label: "Airbnb" },
            { value: "3", label: "OTA" },
          ];
    this.setState({
      selectChannelList: selectChannelList,
      selectChannelListTotal: selectChannelListTotal,
      newChannelList: newChannelList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to check unit available or not for same reservation slug  */
  async getListingAvailableDateForSameSlug(slug, slug2) {
    this.setState({ showSpinner: true, loading: true });
    var checkDate = {};
    var dates = [];
    var checkDateLength = 0;
    let res = await ReservationService.checkReservationAvailabiltyForSameSlug(
      slug,
      slug2
    );
    if (global.successStatus.includes(res.status)) {
      checkDate = res.data ? res.data : {};
      checkDateLength = checkDate ? checkDate.length : 0;
    }
    if (checkDateLength > 0) {
      checkDate.map((item, index) => {
        let staretDate = new Date(item.checkin_date + " 00:00:00");
        let endDate = new Date(item.checkout_date + " 00:00:00");
        const date = new Date(staretDate.getTime());

        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      });
      this.setState({ disabled_dates: dates });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  async getTaxData() {
    this.setState({ showSpinner: true, loading: true });
    var taxList = [];
    var taxListTotal = 0;
    let res = await TaxService.getTax(
      "property_slug=" + this.state.property_slug + "&is_active=1"
    );
    if (global.successStatus.includes(res.status)) {
      taxList = res.data ? res.data.data : [];
      taxListTotal = taxList ? taxList.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      taxListTotal: taxListTotal,
      taxList: taxList,
    });
    this.getTaxesData();
  }

  async getTaxesData(queryString = "") {
    var masterTaxList = [];
    var masterTaxTotalRecords = 0;
    var both_total_record = 0;
    let res = await TaxService.getTax(
      "property_slug=" +
        this.state.property_slug +
        "&is_property=1" +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      masterTaxList = res.data ? res.data : [];
      masterTaxTotalRecords = masterTaxList ? masterTaxList.length : 0;
    }
    if (masterTaxTotalRecords > 0) {
      masterTaxList.map((item, i) => {
        masterTaxList[i]["property_level_tax_name"] = item.general_tax_name;
        masterTaxList[i]["tax_type_name"] = item.tax_type_name;
        masterTaxList[i]["property_amount"] = item.general_tax_value;
        masterTaxList[i]["status_id"] = item.status_id;
        masterTaxList[i]["is_disable"] = true;
      });
    }
    this.setState({
      masterTaxTotalRecords: masterTaxTotalRecords,
      masterTaxList: masterTaxList,
    });
    both_total_record =
      parseInt(masterTaxTotalRecords) + parseInt(this.state.taxListTotal);
    if (this.state.taxListTotal !== 0) {
      this.setState({ taxList: [...this.state.taxList, ...masterTaxList] });
      this.setState({ taxListTotal: both_total_record });
    } else {
      this.setState({ taxList: masterTaxList });
      this.setState({ taxListTotal: masterTaxTotalRecords });
    }
  }

  /* to check lisitng available or not  */
  async getReservationUpdateMaxDate(slug, unit_slug, date) {
    var checkDateMax = [];
    let res = await ReservationService.getReservationUpdateNextCheckInDate(
      slug,
      unit_slug,
      date
    );
    if (global.successStatus.includes(res.status)) {
      checkDateMax.set_max_date =
        res && res.data && res.data.set_max_date ? res.data.set_max_date : "";

      var maxCheckOutDate = checkDateMax.set_max_date
        ? Moment(checkDateMax.set_max_date).toDate("dd/MM/YY")
        : this.state.listingTodate
        ? this.state.listingTodate
        : "";
    }
    this.setState({ maxCheckoutDate: maxCheckOutDate });
  }

  async getReservationMaxDate(slug, listingSlug, date) {
    var checkDateMax = [];
    let res = await ReservationService.getReservationNextCheckInDate(
      slug,
      listingSlug,
      date
    );
    if (global.successStatus.includes(res.status)) {
      checkDateMax.set_max_date =
        res && res.data && res.data.set_max_date ? res.data.set_max_date : "";

      var maxCheckOutDate = checkDateMax.set_max_date
        ? Moment(checkDateMax.set_max_date).toDate("dd/MM/YY")
        : this.state.listingTodate
        ? this.state.listingTodate
        : "";
    }
    this.setState({ maxCheckoutDate: maxCheckOutDate });
  }

  /* to check lisitng available or not  */
  async getListingAvailableDate(slug, slug2) {
    this.setState({ showSpinner: true, loading: true });
    var checkDate = {};
    var checkDateLength = 0;
    var dates = [];
    let res = await ReservationService.checkReservationAvailabilty(slug, slug2);
    if (global.successStatus.includes(res.status)) {
      checkDate = res.data ? res.data : {};
      checkDateLength = checkDate ? checkDate.length : 0;
    }
    if (checkDateLength > 0) {
      checkDate.map((item, index) => {
        let staretDate = new Date(item.checkin_date + " 00:00:00");
        let endDate = new Date(item.checkout_date + " 00:00:00");
        const date = new Date(staretDate.getTime());

        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      });
      this.setState({ disabled_dates: dates });
    }

    this.setState({ showSpinner: false, loading: false });
  }

  async getPropertyTime() {
    if (this.state.property_slug !== undefined) {
      var resData = {};

      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData.checkin_time = res.data.check_in_time
          ? res.data.check_in_time
          : "";
        resData.checkout_time = res.data.check_out_time
          ? res.data.check_out_time
          : "";
        resData.property_pet_allowed = res.data.pet_allowed
          ? res.data.pet_allowed
          : "";
        resData.lock_type =
          res && res.data && res.data.lock_type ? res.data.lock_type : "";
      }
    }
    this.setState(resData);
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, tower, total_tower_count }) => ({
            value: slug,
            label: unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
  }

  /* to get listing data list */
  async getListingData(queryString = "") {
    var listingList = [];
    var listingListTotal = 0;

    let res = await ListingService.getListingList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      listingList = res.data ? res.data : [];
      listingListTotal = listingList ? listingList.length : 0;
    }
    const newListingList =
      listingListTotal > 0
        ? listingList.map(({ slug, listing_name, unit }) => ({
            value: slug,
            label: unit
              ? listing_name + (unit ? " (" + unit.unit_number + ") " : "")
              : listing_name,
          }))
        : [];
    this.setState({
      listingList: listingList,
      listingListTotal: listingListTotal,
      newListingList: newListingList,
    });
  }

  async getPetPolicyData() {
    var multiList = [];
    var multiListTotal = 0;

    let res = await PetPolicyService.getPetPolicy(
      this.state.property_slug + "/3"
    );
    if (global.successStatus.includes(res.status)) {
      multiList = res.data.petPolicyRestrictions
        ? res.data.petPolicyRestrictions
        : [];
      multiListTotal = multiList ? multiList.length : 0;
    }
    this.setState({
      multiList: multiList,
      multiListTotal: multiListTotal,
    });
  }

  /* to get guest data using phone number */
  async getGuestDataByPhone(phoneNumber, queryString = "") {
    var resGuestData = {};
    var length = 0;
    let res = await GuestService.getGuestByPhone(
      phoneNumber,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      length = res.data ? res.data.length : 0;
      if (length !== 0) {
        resGuestData.first_name = res.data.first_name
          ? res.data.first_name
          : "";
        resGuestData.last_name = res.data.last_name ? res.data.last_name : "";
        resGuestData.email = res.data.email ? res.data.email : "";
        resGuestData.street_1 = res.data.street_1 ? res.data.street_1 : "";
        resGuestData.street_2 = res.data.street_2 ? res.data.street_2 : "";
        resGuestData.city_name = res.data.city_name ? res.data.city_name : "";
        resGuestData.state_name = res.data.state_name
          ? res.data.state_name
          : "";
        resGuestData.county_name = res.data.county_name
          ? res.data.county_name
          : "";
        resGuestData.country_name = res.data.country_name
          ? res.data.country_name
          : "";
        resGuestData.zip_code = res.data.zip_code ? res.data.zip_code : "";
        resGuestData.passport = res.data.passport ? res.data.passport : "";
      }
    }
    this.setState(resGuestData);
  }

  /* to get guest data using email */
  async getGuestDataByEmail(email, queryString = "") {
    var resGuestData = {};
    let res = await GuestService.getGuestByEmail(
      email,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      resGuestData.first_name = res.data.first_name ? res.data.first_name : "";
      resGuestData.last_name = res.data.last_name ? res.data.last_name : "";
      resGuestData.phone_number = res.data.phone_number
        ? res.data.phone_number
        : "";
      resGuestData.street_1 = res.data.street_1 ? res.data.street_1 : "";
      resGuestData.street_2 = res.data.street_2 ? res.data.street_2 : "";
      resGuestData.city_name = res.data.city_name ? res.data.city_name : "";
      resGuestData.state_name = res.data.state_name ? res.data.state_name : "";
      resGuestData.county_name = res.data.county_name
        ? res.data.county_name
        : "";
      resGuestData.country_name = res.data.country_name
        ? res.data.country_name
        : "";
      resGuestData.zip_code = res.data.zip_code ? res.data.zip_code : "";
      resGuestData.passport = res.data.passport ? res.data.passport : "";
    }
    if (resGuestData.first_name) {
      this.setState(resGuestData);
    }
  }

  async getListingDetails(slug) {
    var resListingUnitData = {};

    let res = await ListingService.getListing(slug);
    if (global.successStatus.includes(res.status)) {
      resListingUnitData.unit_slug = res.data
        ? res.data.unit
          ? res.data.unit.slug
          : ""
        : "";
      resListingUnitData.unit_number = res.data
        ? res.data.unit
          ? res.data.unit.unit_number
          : ""
        : "";
      resListingUnitData.cleaning_fee = res.data
        ? res.data.cleaning_fee
          ? res.data.cleaning_fee.replace(/\.00$/, "")
          : ""
        : "";
      resListingUnitData.security_deposite = res.data
        ? res.data.security_deposite
          ? res.data.security_deposite.replace(/\.00$/, "")
          : ""
        : "";

      resListingUnitData.listing_pet_allowed = res.data
        ? res.data.listing_pet_allowed
        : "";
      resListingUnitData.listingFromdate = res.data.from_date
        ? Moment(res.data.from_date).toDate("MM/dd/yy")
        : "";
      resListingUnitData.listingTodate = res.data.to_date
        ? Moment(res.data.to_date).toDate("MM/dd/yy")
        : "";
      this.getUnitPayoutConfigurationData(resListingUnitData.unit_slug);
      if (this.state.slug !== undefined) {
        this.getListingAvailableDateForSameSlug(
          resListingUnitData.unit_slug,
          this.state.slug
        );
      } else {
        this.getListingAvailableDate(resListingUnitData.unit_slug, slug);
      }
    }
    this.setState(resListingUnitData);
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }

  saveOrUpdateReservation = async (e) => {
    e.preventDefault();
    if (
      this.validator.allValid() &&
      this.customValidate() &&
      this.state.pet_policy_error === false &&
      this.state.resolution_fee_error === false &&
      this.state.valid_date_error === false
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        is_form_submited: this.state.bypass_guest_form === true ? 1 : 0,
        earning_configuration: this.state.earning_configuration
          ? this.state.earning_configuration
          : [],
        refTableId: 12,
        pet_data: this.state.pet_data ? this.state.pet_data : [],
        resolution_fee_data: this.state.resolution_fee_data
          ? this.state.resolution_fee_data
          : [],
        property_slug: this.state.property_slug
          ? this.state.property_slug
          : this.props.property_slug,
        unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
        listing_slug: this.state.listing_slug ? this.state.listing_slug : "",
        checkin_date: this.state.checkin_date
          ? Moment(this.state.checkin_date).format("YYYY-MM-DD")
          : "",
        checkout_date: this.state.checkout_date
          ? Moment(this.state.checkout_date).format("YYYY-MM-DD")
          : "",
        confirmation_code: this.state.confirmation_code
          ? this.state.confirmation_code
          : "",
        checkin_time: this.state.checkin_time ? this.state.checkin_time : "",
        checkout_time: this.state.checkout_time ? this.state.checkout_time : "",

        phone_number: this.state.phone_number ? this.state.phone_number : "",
        dial_code: this.state.dial_code ? this.state.dial_code : "",
        first_name: this.state.first_name ? this.state.first_name : "",
        last_name: this.state.last_name ? this.state.last_name : "",
        email: this.state.email ? this.state.email : "",
        address: this.state.address ? this.state.address : "",
        cleanliness: this.state.cleanliness_rating
          ? this.state.cleanliness_rating
          : 0,
        accuracy: this.state.accuracy_rating ? this.state.accuracy_rating : 0,
        communication: this.state.communication_rating
          ? this.state.communication_rating
          : 0,
        location: this.state.location_rating ? this.state.location_rating : 0,
        check_in_rating: this.state.check_in_rating
          ? this.state.check_in_rating
          : 0,
        value_rating: this.state.value_rating ? this.state.value_rating : 0,
        comment: this.state.comment ? this.state.comment : "",
        guestUserSlug: this.state.guestUserSlug ? this.state.guestUserSlug : "",
        is_id_verified: this.state.is_id_verified === true ? 1 : 2,
        is_publish: this.state.is_publish === true ? 1 : 2,

        street_1: this.state.street_1 ? this.state.street_1 : "",
        street_2: this.state.street_2 ? this.state.street_2 : "",
        zip_code: this.state.zip_code ? this.state.zip_code : "",
        country_id: this.state.countryId ? this.state.countryId : "",
        state_id: this.state.stateId ? this.state.stateId : "",
        county_id: this.state.countyId ? this.state.countyId : "",
        city_id: this.state.cityId ? this.state.cityId : "",

        passport: this.state.passport ? this.state.passport : "",
        adults: this.state.adults ? this.state.adults : "",
        children: this.state.children ? this.state.children : "",
        infants: this.state.infants ? this.state.infants : "",
        pets: this.state.pets ? this.state.pets : "",
        pet_policy_fee: this.state.pet_policy_fee
          ? this.state.pet_policy_fee
          : "",

        cleaning_fee: this.state.cleaning_fee ? this.state.cleaning_fee : "",
        service_fee: this.state.guest_service_fee
          ? this.state.guest_service_fee
          : "",
        payment_processing_fee: this.state.payment_processing_fee
          ? this.state.payment_processing_fee
          : "",
        total_stay_nights: this.state.total_stay_nights
          ? this.state.total_stay_nights
          : "",
        occupancy_tax_fee: this.state.occupancy_taxes
          ? this.state.occupancy_taxes
          : "",

        nightly_rate: this.state.nightly_rate ? this.state.nightly_rate : "",
        total_oh_earning: this.state.oh_earning ? this.state.oh_earning : "",
        total_property_earning: this.state.property_earning
          ? this.state.property_earning
          : "",
        oh_earning_resolution: this.state.oh_earning_resolution
          ? this.state.oh_earning_resolution
          : this.state.oh_earning
          ? this.state.oh_earning
          : "",
        property_earning_resolution: this.state.property_earning_resolution
          ? this.state.property_earning_resolution
          : this.state.property_earning
          ? this.state.property_earning
          : "",
        resident_earning: this.state.resident_earning
          ? this.state.resident_earning
          : "",
        resident_earning_resolution: this.state.resident_earning_resolution
          ? this.state.resident_earning_resolution
          : this.state.resident_earning
          ? this.state.resident_earning
          : "",
        airbnb_earning: this.state.airbnb_earning
          ? this.state.airbnb_earning
          : "",
        host_service_fee: this.state.host_service_fee
          ? this.state.host_service_fee
          : "",
        total_nightly_rate: this.state.total_nightly_fee
          ? this.state.total_nightly_fee
          : "",
        guest_paid: this.state.guest_paid ? this.state.guest_paid : "",
        host_payout: this.state.host_payout ? this.state.host_payout : "",
        pet_fee: this.state.pet_fee ? this.state.pet_fee : "",
        security_deposite: this.state.security_deposite
          ? this.state.security_deposite
          : "",
        total_resolution_fee: this.state.total_resolution_fee
          ? this.state.total_resolution_fee
          : "",

        status_id: this.state.status_id ? this.state.status_id : [],
        partner_reservation_id: this.state.partner_reservation_id
          ? this.state.partner_reservation_id
          : "",
        set_channel: this.state.set_channel ? this.state.set_channel : "",
        door_lock_data: this.state.door_type_data
          ? this.state.door_type_data
          : [],
        occupancy_taxes_array: this.state.occupancy_taxes_array
          ? this.state.occupancy_taxes_array
          : [],
        to_building_total: this.state.to_building_total
          ? this.state.to_building_total
          : null,
        to_oh_total: this.state.to_oh_total ? this.state.to_oh_total : null,
        to_resident_total: this.state.to_resident_total
          ? this.state.to_resident_total
          : null,
        internal_notes: this.state.internal_notes
          ? this.state.internal_notes
          : null,
        include_owner_report: this.state.include_owner_report
          ? this.state.include_owner_report
          : null,
        guest_info_keep: this.state.guest_info_keep == true ? 1 : 0,
      };
      let inputData2 = {
        guest_slug: this.state.guestUserSlug,
        signature_name: this.state.signature_name,
        confirmation_code: this.state.confirmation_code,
        front_photo_name: this.state.front_photo_name,
        back_photo_name: this.state.back_photo_name,
        selfie_photo_name: this.state.selfie_photo_name,
        id_type: this.state.id_type,
        sort_order: "",
        status_id: "",
        is_form_submited: this.state.bypass_guest_form === true ? 1 : 0,
        card_tokenise: this.state.bypass_card_token === true ? 1 : 0,
        parking_payment: this.state.bypass_parking_payment === true ? 1 : 0,
        email_verified: this.state.bypass_email_verification == true ? 1 : 0,
      };

      if (this.state.slug !== undefined) {
        let res = await ReservationService.updateReservation(
          this.state.slug,
          inputData
        );
        let res2 = await ReservationService.StoreGuestform(inputData2);
        if (global.successStatus.includes(res2.status)) {
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "success",
          //   alertModalTitle: "Success",
          //   alertModalMessage: res.message ? res.message : "Success",
          // });
        }
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          if (
            this.state.email !== this.state.sendEmail &&
            this.state.lock_type === 1
          ) {
            // this.SendGenerateEmail();
          }
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/reservations/view/" +
                      this.state.slug
                  : "/reservations/view/" +
                      this.state.property_slug +
                      "/" +
                      this.state.slug
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      } else {
        let res = await ReservationService.createReservation(inputData);
        if (global.successStatus.includes(res.status)) {
          if (res.data.reservation_slug) {
            let feeData = {
              level: 12,
              slug: res.data.reservation_slug,
              cost_data: this.state.costList,
              cost_insert: this.state.slug !== undefined ? 1 : 2,
            };
            let res1 = await PropertyCostService.updateCosts(feeData);
            if (
              global.successStatus.includes(res.status) &&
              global.successStatus.includes(res1.status)
            ) {
              this.setState({
                showAlertModal: true,
                alertModalType: "success",
                alertModalTitle: "Success",
                alertModalMessage: res.message ? res.message : "Success",
              });
              if (this.state.email && this.state.lock_type === 1) {
                // this.SendGenerateEmail();
              }
              setTimeout(
                () =>
                  this.props.history.push(
                    this.props.match.params.propertySlug !== undefined
                      ? "/properties/view/" +
                          this.state.property_slug +
                          "/reservations"
                      : "/reservations"
                  ),
                global.redirect_time
              );
            }
          }
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  // async SendGenerateEmail() {
  //   let inputData = {
  //     email: this.state.email,
  //     is_website: 1,
  //   };
  //   this.setState({ showSpinner: true, isSubmit: true });
  //   var res = await UserService.SendEmailGuest(inputData);
  //   if (global.successStatus.includes(res.status)) {
  //     this.setState({
  //       showAlertModal: true,
  //       alertModalType: "success",
  //       alertModalTitle: "Success",
  //       alertModalMessage: res.message ? res.message : "Success",
  //     });
  //     this.setState({ isMailSend: 0 });
  //     this.setState({ showSpinner: false, isSubmit: false });
  //   } else {
  //     if (res.data.result.errorDetail) {
  //       let errors = {};
  //       res.data.errorCode = "Validation Failed";
  //       res.data.result.errorDetail.forEach((item) => {
  //         errors[item.errorField] = item.errorMessage[0];
  //         res.data.errorMessage = "";
  //       });
  //       this.setState({ errors: errors });
  //     }
  //     this.setState({ isMailSend: 0 });
  //     this.setState({ showSpinner: false, isSubmit: false });
  //   }
  //   setTimeout(
  //     () => this.setState({ showAlertModal: false }),
  //     global.alert_close_time
  //   );
  // }

  changeHandler = (event) => {
    this.setState({ errors: "", cDate: 0 });
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    setTimeout(() => {
      const currentDate = new Date();
      var checkinDateCheck = Moment(this.state.checkin_date).format(
        "MM/DD/YYYY"
      );
      var dateCheck = Moment(currentDate).format("MM/DD/YYYY");
      var d1 = checkinDateCheck.split("/");
      var c = dateCheck.split("/");
      var checkInDate = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
      var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

      if (check > checkInDate) {
        this.setState({ cDate: 1 });
      } else {
        this.setState({ cDate: 0 });
      }
    }, 0);

    if (event.target.name === "pets") {
      if (this.state.slug === undefined) {
        this.getDefaultPetData();
      } else {
        setTimeout(() => {
          if (this.state.property_pet_allowed === 1) {
            this.getReservationPetPolicyData();
          }
        }, 100);
      }
      if (
        event.target.value &&
        event.target.value !== undefined &&
        event.target.value !== null &&
        event.target.value !== ""
      ) {
        this.setState({ showPetFee: 1 });
      } else {
        this.setState({ showPetFee: 0 });
      }
    }

    if (
      event.target.name === "checkin_time" ||
      event.target.name === "checkout_time"
    ) {
      var datetime = value.split(":");
      var lengthdatetime = (datetime[0].match(/\d/g) || []).length;
      if (lengthdatetime === 1) {
        datetime[0] = datetime[0].replace("_", "0");
      }
      if (parseInt(datetime[0]) > 12) {
        datetime[0] = "12";
      }
      var minute = datetime[1].split(" ");
      var lengthminute = (minute[0].match(/\d/g) || []).length;

      if (parseInt(minute[0]) > 59 || lengthminute <= 0) {
        minute[0] = "00";
      }
      const meridian = ["AM", "PM", "am", "pm", "A_", "P_", "a_", "p_"];
      const result = meridian.includes(minute[1].toLowerCase());
      minute[1] = result ? minute[1] : "AM";

      value = datetime[0] + ":" + minute[0] + " " + minute[1].toUpperCase();
    }
    input[event.target.name] = value;

    if (event.target.name === "email") {
      if (value !== "") {
        setTimeout(() => this.getGuestDataByEmail(value), 500);
      }
    }
    if (event.target.name === "pets") {
      this.getPetPolicyData();
    }
    this.setState({
      [event.target.name]: value,
      input: input,
    });
    if (event.target.name === "checkin_date") {
      let checkin_date_val = this.state.input.checkin_date;
      let checkInDate = Moment(checkin_date_val).format("YYYY-MM-DD");
      if (this.state.slug !== undefined) {
        this.getReservationUpdateMaxDate(
          this.state.slug,
          this.state.unit_slug,
          checkInDate
        );
      } else {
        this.getReservationMaxDate(
          this.state.unit_slug,
          this.state.listing_slug,
          checkInDate
        );
      }
      this.setState({ checkout_date: "" });
    }
    var total_stay_nights = this.state.total_stay_nights
      ? this.state.total_stay_nights
      : 0;

    if (
      event.target.name === "checkin_date" ||
      event.target.name === "checkout_date"
    ) {
      if (this.state.input.checkin_date || this.state.input.checkout_date) {
        let checkin_date_val = this.state.input.checkin_date
          ? this.state.input.checkin_date
          : this.state.checkin_date;
        let checkout_date_val = this.state.input.checkout_date
          ? this.state.input.checkout_date
          : this.state.checkout_date;
        var date1 = checkin_date_val;
        var date2 = checkout_date_val;
        const diffTime = Math.abs(date2 - date1);
        const diffDays =
          Math.ceil(diffTime / (1000 * 60 * 60 * 24)) === 0
            ? 1
            : Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        total_stay_nights = diffDays;
        this.setState({ total_stay_nights: total_stay_nights });
      }
    }

    if (
      event.target.name === "resident_earning" ||
      event.target.name === "oh_earning" ||
      event.target.name === "property_earning" ||
      event.target.name === "airbnb_earning"
    ) {
      let resident_earning =
        event.target.name === "resident_earning"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.resident_earning
          ? this.state.resident_earning
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
              .replace(/\.00$/, "")
          : 0;
      let oh_earning =
        event.target.name === "oh_earning"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.oh_earning
          ? this.state.oh_earning
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
              .replace(/\.00$/, "")
          : 0;
      let property_earning =
        event.target.name === "property_earning"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.property_earning
          ? this.state.property_earning
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
              .replace(/\.00$/, "")
          : 0;
      let airbnb_earning =
        event.target.name === "airbnb_earning"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.airbnb_earning
          ? this.state.airbnb_earning
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
              .replace(/\.00$/, "")
          : 0;
      this.setState({
        airbnb_earning: airbnb_earning,
        property_earning: property_earning,
        oh_earning: oh_earning,
        resident_earning: resident_earning,
      });
    }
    if (
      event.target.name === "cleaning_fee" ||
      event.target.name === "guest_service_fee" ||
      event.target.name === "checkin_date" ||
      event.target.name === "checkout_date" ||
      event.target.name === "occupancy_taxes" ||
      event.target.name === "total_nightly_fee" ||
      event.target.name === "pet_fee" ||
      event.target.name === "guest_paid" ||
      event.target.name === "host_service_fee" ||
      event.target.name === "pet_policy_fee" ||
      event.target.name === "payment_processing_fee"
    ) {
      let total_nightly_fee = this.state.total_nightly_fee;
      let nightly_rate = this.state.nightly_rate;

      total_nightly_fee =
        event.target.name === "total_nightly_fee"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.total_nightly_fee
          ? this.state.total_nightly_fee.replace(
              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
              ""
            )
          : "";

      nightly_rate =
        event.target.name === "nightly_rate"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : total_stay_nights
          ? total_nightly_fee / total_stay_nights
          : "";

      let cleaning_fee =
        event.target.name === "cleaning_fee"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.cleaning_fee
          ? this.state.cleaning_fee
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
              .replace(/\.00$/, "")
          : 0;

      let guest_service_fee =
        event.target.name === "guest_service_fee"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.guest_service_fee
          ? this.state.guest_service_fee.replace(
              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
              ""
            )
          : 0;

      let payment_processing_fee =
        event.target.name === "payment_processing_fee"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.payment_processing_fee
          ? this.state.payment_processing_fee.replace(
              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
              ""
            )
          : 0;

      setTimeout(() => {
        var additional_fees = 0;
        var tmpAdditionalFees = 0;
        this.state.costList.map((item, index) => {
          if (
            item.cost_payable_at
              ? item.cost_payable_at.cost_payable_at_name === "Daily"
              : ""
          ) {
            tmpAdditionalFees =
              parseFloat(item.cost_name) *
              parseInt(this.state.total_stay_nights);
          } else {
            tmpAdditionalFees = parseFloat(item.cost_name);
          }
          additional_fees += +tmpAdditionalFees;
        });
        this.setState({ pet_fee: additional_fees });
      }, 100);

      /* pet_fee uses as additional fee/general fee */
      let pet_fee =
        event.target.name === "pet_fee"
          ? event.target.value !== ""
            ? event.target.value
            : 0
          : this.state.pet_fee
          ? this.state.pet_fee
          : 0;
      var taxes = 0;
      var taxesArr = [];
      if (this.state.slug !== undefined) {
        if (
          event.target.name === "occupancy_taxes" &&
          event.target.value !== ""
        ) {
          let taxes_val = event.target.value.replace(
            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
            ""
          );
          taxes = taxes_val;
        } else {
          if (this.state.taxOccupancyListTotal > 0) {
            this.state.taxOccupancyList.map((item, index) => {
              var tmpTax = 0;
              if (item.tax_type_id === 1) {
                tmpTax = parseFloat(item.tax_distribution);
              } else {
                tmpTax =
                  ((parseFloat(total_nightly_fee) + parseFloat(cleaning_fee)) *
                    parseFloat(item.tax_distribution)) /
                  100;
              }

              taxesArr.push({
                name: item.name,
                tax_distribution: item.tax_distribution,
                amount: tmpTax,
                tax_type_id: item.tax_type_id,
              });

              let taxes_val = parseFloat(taxes) + parseFloat(tmpTax);
              taxes = taxes_val;
            });
            setTimeout(() => {
              this.setState({ occupancy_taxes_array: taxesArr });
            }, 100);
          } else {
            this.state.taxList.map((item, index) => {
              var a = item.tax_type_name
                ? item.tax_type_name
                : item.tax_type
                ? item.tax_type.tax_type_name
                : "";
              var tmpTax = 0;
              if (a.includes("$")) {
                tmpTax = parseFloat(item.property_amount);
              } else {
                tmpTax =
                  ((parseFloat(total_nightly_fee) + parseFloat(cleaning_fee)) *
                    parseFloat(item.property_amount)) /
                  100;
              }
              taxesArr.push({
                name: item.property_level_tax_name,
                tax_distribution: item.property_amount,
                amount: tmpTax,
                tax_type_id: item.tax_type_id,
              });
              let taxes_val = parseFloat(taxes) + parseFloat(tmpTax);
              taxes = taxes_val;
            });
            setTimeout(() => {
              this.setState({ occupancy_taxes_array: taxesArr });
            }, 100);
          }
        }
      } else {
        if (
          event.target.name === "occupancy_taxes" &&
          event.target.value !== ""
        ) {
          let taxes_val = event.target.value.replace(
            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
            ""
          );
          taxes = taxes_val;
        } else {
          this.state.taxList.map((item, index) => {
            var a = item.tax_type_name
              ? item.tax_type_name
              : item.tax_type
              ? item.tax_type.tax_type_name
              : "";
            var tmpTax = 0;
            if (a.includes("$")) {
              tmpTax = parseFloat(item.property_amount);
            } else {
              tmpTax =
                ((parseFloat(total_nightly_fee) + parseFloat(cleaning_fee)) *
                  parseFloat(item.property_amount)) /
                100;
            }

            taxesArr.push({
              name: item.property_level_tax_name,
              tax_distribution: item.property_amount,
              amount: tmpTax,
              tax_type_id: item.tax_type_id,
            });
            let taxes_val = parseFloat(taxes) + parseFloat(tmpTax);
            taxes = taxes_val;
          });
          setTimeout(() => {
            this.setState({ occupancy_taxes_array: taxesArr });
          }, 100);
        }
      }
      let o_taxtes =
        event.target.name === "occupancy_taxes"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : taxes
          ? parseFloat(taxes).toFixed(2)
          : "";

      let occupancy_taxes = taxes;
      let guest_paid_val =
        parseFloat(total_nightly_fee) +
        parseFloat(cleaning_fee) +
        parseFloat(guest_service_fee) +
        parseFloat(occupancy_taxes);

      let guest_paid_v = guest_paid_val ? parseFloat(guest_paid_val) : "";
      let guest_paid =
        event.target.name === "guest_paid"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : guest_paid_v
          ? parseFloat(guest_paid_v)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";

      let host_service_fee_v =
        event.target.name === "host_service_fee"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.host_service_fee
          ? this.state.host_service_fee
          : 0;

      let host_payout_val =
        parseFloat(guest_paid_v) -
        parseFloat(guest_service_fee) -
        parseFloat(occupancy_taxes) -
        parseFloat(payment_processing_fee) -
        parseFloat(host_service_fee_v);

      let host_payout =
        event.target.name === "host_payout"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : host_payout_val
          ? parseFloat(host_payout_val).toFixed(2).replace(/\.00$/, "")
          : "";

      let resident_earning_val =
        (this.state.total_nightly_rate_to_resident
          ? (parseFloat(total_nightly_fee) *
              this.state.total_nightly_rate_to_resident) /
            100
          : 0) +
        (this.state.cleaning_fee_to_resident
          ? (parseFloat(cleaning_fee) * this.state.cleaning_fee_to_resident) /
            100
          : 0) -
        (this.state.channel_fee_to_resident
          ? (parseFloat(host_service_fee_v) *
              this.state.channel_fee_to_resident) /
            100
          : 0);
      let resident_earning = resident_earning_val
        ? parseFloat(resident_earning_val).toFixed(2)
        : "";
      let oh_earning_val = "";
      if (this.state.set_channel_name === "Direct Booking") {
        oh_earning_val =
          (this.state.total_nightly_rate_to_oh
            ? (parseFloat(total_nightly_fee) *
                this.state.total_nightly_rate_to_oh) /
              100
            : 0) +
          (this.state.cleaning_fee_to_oh
            ? (parseFloat(cleaning_fee) * this.state.cleaning_fee_to_oh) / 100
            : 0) -
          (this.state.channel_fee_to_oh
            ? (parseFloat(host_service_fee_v) * this.state.channel_fee_to_oh) /
              100
            : 0) +
          (guest_service_fee ? parseFloat(guest_service_fee) : 0) +
          (o_taxtes ? parseFloat(o_taxtes) : 0) -
          (payment_processing_fee ? parseFloat(payment_processing_fee) : 0);
      } else {
        oh_earning_val =
          (this.state.total_nightly_rate_to_oh
            ? (parseFloat(total_nightly_fee) *
                this.state.total_nightly_rate_to_oh) /
              100
            : 0) +
          (this.state.cleaning_fee_to_oh
            ? (parseFloat(cleaning_fee) * this.state.cleaning_fee_to_oh) / 100
            : 0) -
          (this.state.channel_fee_to_oh
            ? (parseFloat(host_service_fee_v) * this.state.channel_fee_to_oh) /
              100
            : 0);
      }

      let oh_earning = oh_earning_val
        ? parseFloat(oh_earning_val).toFixed(2)
        : "";

      let property_earning_val =
        (this.state.total_nightly_rate_to_property
          ? (parseFloat(total_nightly_fee) *
              this.state.total_nightly_rate_to_property) /
            100
          : 0) +
        (this.state.cleaning_fee_to_property
          ? (parseFloat(cleaning_fee) * this.state.cleaning_fee_to_property) /
            100
          : 0) -
        (this.state.channel_fee_to_property
          ? (parseFloat(host_service_fee_v) *
              this.state.channel_fee_to_property) /
            100
          : 0);
      let property_earning = property_earning_val
        ? parseFloat(property_earning_val).toFixed(2)
        : "";
      let airbnb_earning_val = 0;
      let airbnb_earning = 0;

      if (this.state.set_channel_name === "Direct Booking") {
        airbnb_earning_val =
          parseFloat(guest_paid) - parseFloat(payment_processing_fee);

        airbnb_earning = airbnb_earning_val
          ? parseFloat(airbnb_earning_val).toFixed(2)
          : "";
      } else {
        airbnb_earning_val =
          parseFloat(guest_service_fee) +
          parseFloat(occupancy_taxes) +
          parseFloat(host_service_fee_v);

        airbnb_earning = airbnb_earning_val
          ? parseFloat(airbnb_earning_val).toFixed(2)
          : "";
      }

      let nightly_rate_v = nightly_rate
        ? parseFloat(nightly_rate)
            .toFixed(2)
            .replace(/\.00$/, "")
            .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
        : "";

      let guest_service_fee_v = guest_service_fee
        ? guest_service_fee
            .replace(/\.00$/, "")
            .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
        : "";

      setTimeout(() => {
        this.setState({
          nightly_rate: nightly_rate_v,
          guest_paid: guest_paid,
          total_nightly_fee: total_nightly_fee,
          host_service_fee: host_service_fee_v,
          host_payout: host_payout,
          occupancy_taxes: o_taxtes,
          resident_earning: resident_earning,
          oh_earning: oh_earning,
          property_earning: property_earning,
          airbnb_earning: airbnb_earning,
          pet_fee: pet_fee,
          guest_service_fee: guest_service_fee_v,
        });
      }, 0);

      setTimeout(() => {
        const payoutValues = [...this.state.earningConfigurationPayout];
        if (this.state.earningConfigurationPayoutTotal > 0) {
          this.state.earningConfigurationPayout.map((opt, k) => {
            if (opt.fee_type === 1) {
              payoutValues[k].slug = opt.slug;
              payoutValues[k].fee_type = opt.fee_type;
              payoutValues[k].fee_type_name = opt.fee_type_name;
              payoutValues[k].payout_oh = opt.payout_oh;
              payoutValues[k].payout_oh_amount = opt.payout_oh
                ? (
                    (parseFloat(this.state.total_nightly_fee) *
                      parseFloat(opt.payout_oh)) /
                    100
                  ).toFixed(2)
                : null;
              payoutValues[k].payout_resident = opt.payout_resident;
              payoutValues[k].payout_resident_amount = opt.payout_resident
                ? (
                    (parseFloat(this.state.total_nightly_fee) *
                      parseFloat(opt.payout_resident)) /
                    100
                  ).toFixed(2)
                : null;
              payoutValues[k].payout_property = opt.payout_property;
              payoutValues[k].payout_property_amount = opt.payout_property
                ? (
                    (parseFloat(this.state.total_nightly_fee) *
                      parseFloat(opt.payout_property)) /
                    100
                  ).toFixed(2)
                : null;
            } else if (opt.fee_type === 2) {
              payoutValues[k].slug = opt.slug;
              payoutValues[k].fee_type = opt.fee_type;
              payoutValues[k].fee_type_name = opt.fee_type_name;
              payoutValues[k].payout_oh = opt.payout_oh;
              payoutValues[k].payout_oh_amount = opt.payout_oh
                ? (
                    (parseFloat(this.state.cleaning_fee) *
                      parseFloat(opt.payout_oh)) /
                    100
                  ).toFixed(2)
                : null;
              payoutValues[k].payout_resident = opt.payout_resident;
              payoutValues[k].payout_resident_amount = opt.payout_resident
                ? (
                    (parseFloat(this.state.cleaning_fee) *
                      parseFloat(opt.payout_resident)) /
                    100
                  ).toFixed(2)
                : null;
              payoutValues[k].payout_property = opt.payout_property;
              payoutValues[k].payout_property_amount = opt.payout_property
                ? (
                    (parseFloat(this.state.cleaning_fee) *
                      parseFloat(opt.payout_property)) /
                    100
                  ).toFixed(2)
                : null;
            } else if (opt.fee_type === 3) {
              payoutValues[k].slug = opt.slug;
              payoutValues[k].fee_type = opt.fee_type;
              payoutValues[k].fee_type_name = opt.fee_type_name;
              payoutValues[k].payout_oh = opt.payout_oh;
              payoutValues[k].payout_oh_amount = opt.payout_oh
                ? (
                    (parseFloat(this.state.host_service_fee) *
                      parseFloat(opt.payout_oh)) /
                    100
                  ).toFixed(2)
                : null;
              payoutValues[k].payout_resident = opt.payout_resident;
              payoutValues[k].payout_resident_amount = opt.payout_resident
                ? (
                    (parseFloat(this.state.host_service_fee) *
                      parseFloat(opt.payout_resident)) /
                    100
                  ).toFixed(2)
                : null;
              payoutValues[k].payout_property = opt.payout_property;
              payoutValues[k].payout_property_amount = opt.payout_property
                ? (
                    (parseFloat(this.state.host_service_fee) *
                      parseFloat(opt.payout_property)) /
                    100
                  ).toFixed(2)
                : null;
            }
          });
        }
        this.setState({ earning_configuration: payoutValues });
        var totalResidentPayout =
          parseFloat(
            this.state.to_resident_total ? this.state.to_resident_total : 0
          ) +
          parseFloat(
            this.state.resident_earning ? this.state.resident_earning : 0
          );
        var totalOhPayout =
          parseFloat(this.state.to_oh_total ? this.state.to_oh_total : 0) +
          parseFloat(this.state.oh_earning ? this.state.oh_earning : 0);
        var totalPropertyPayout =
          parseFloat(
            this.state.to_building_total ? this.state.to_building_total : 0
          ) +
          parseFloat(
            this.state.property_earning ? this.state.property_earning : 0
          );
        this.setState({
          resident_earning_resolution: totalResidentPayout,
          oh_earning_resolution: totalOhPayout,
          property_earning_resolution: totalPropertyPayout,
        });
      }, 1000);
    }

    this.validator.showMessageFor(event.target.name);

    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  changeCleanlinessRating = (newRating, name) => {
    this.setState({
      cleanliness_rating: newRating,
    });
  };

  changeAccuracyRating = (newRating, name) => {
    this.setState({
      accuracy_rating: newRating,
    });
  };

  changeCommunicationRating = (newRating, name) => {
    this.setState({
      communication_rating: newRating,
    });
  };

  changeLocationRating = (newRating, name) => {
    this.setState({
      location_rating: newRating,
    });
  };

  changeCheckInRating = (newRating, name) => {
    this.setState({
      check_in_rating: newRating,
    });
  };

  changeValueRating = (newRating, name) => {
    this.setState({
      value_rating: newRating,
    });
  };

  changeHandler1 = (event, index) => {
    let value = event.target.value;

    let door_type_data1 = [...this.state.door_type_data];
    door_type_data1[index][event.target.name] = value;
    this.setState({ door_type_data: door_type_data1 });

    this.setState({ confirm_back: 1 });
  };

  handleChangeListingList(value) {
    let listing_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        listing_slug.push(item.value);
      });
    }
    const listing_slug_value = value !== null ? value.value : [];
    if (value !== null) {
      if (this.state.slug !== undefined) {
        this.getReservationFees(this.state.slug);
      } else {
        this.getListingFees(listing_slug_value);
      }
      this.getListingDetails(listing_slug_value);
      if (this.state.slug !== undefined) {
        this.getListingAvailableDateForSameSlug(
          this.state.unit_slug,
          this.state.slug
        );
        if (this.state.taxOccupancyListTotal < 1) {
          this.getTaxData(listing_slug_value);
        }
      } else {
        this.getTaxData(listing_slug_value);
      }
    }

    this.setState({
      selectedListingList: value,
      listing_slug: listing_slug_value,
      checkin_date: "",
      checkout_date: "",
      pet_fee: "",
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeselect = (index, event) => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    values[index].pet_type_slug = event.value;
    values[index].selected_pet_type = event;
    this.setState({ pet_data: values });
    this.setState({ confirm_back: 1 });
    this.getNewPetData(index, event);
  };

  handleChange = (index, event) => {
    var pet_policy_fees_total = 0;
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    if (event.target.name === "pet_type_slug") {
      values[index].pet_type_slug = event.target.value;
      this.setState({ petPolicyTypeData: values });
    } else if (event.target.name === "allowed_pet_number") {
      values[index].allowed_pet_number = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
    } else if (event.target.name === "pet_count") {
      values[index].pet_count = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
      if (values[index].allowed_pet_number < event.target.value) {
        values[index].pet_count_error =
          "Pet Count can't be greater than Max # Allowed";
      } else if (event.target.value === "") {
        values[index].pet_count_error = "Pet Count field is required";
      } else {
        values[index].pet_count_error = "";
      }
    } else if (event.target.name === "one_time_fee_per_type") {
      values[index].one_time_fee_per_type = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
      if (event.target.value === "") {
        values[index].one_time_fee_per_type_error = "Fees field is required";
      } else {
        values[index].one_time_fee_per_type_error = "";
      }
    } else if (event.target.name === "weight_per_type") {
      values[index].weight_per_type = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
    } else if (event.target.name === "pet_weight") {
      values[index].pet_weight = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
      if (values[index].weight_per_type < event.target.value) {
        values[index].pet_weight_error =
          "Weight can't be greater than Max # Weight";
      } else {
        values[index].pet_weight_error = "";
      }
    }
    this.setState({ pet_data: values });
    setTimeout(() => {
      this.state.pet_data.map((opt, i) => {
        if (
          opt.pet_count_error != "" &&
          opt.pet_weight_error != "" &&
          opt.one_time_fee_per_type_error != ""
        ) {
          this.setState({ pet_policy_error: true });
        } else if (
          opt.pet_count_error == "" &&
          opt.pet_weight_error == "" &&
          opt.one_time_fee_per_type_error == ""
        ) {
          this.setState({ pet_policy_error: false });
        } else {
          this.setState({ pet_policy_error: false });
        }
        pet_policy_fees_total += +(opt.one_time_fee_per_type
          ? (opt.pet_count ? parseInt(opt.pet_count) : 0) *
            parseFloat(opt.one_time_fee_per_type)
          : 0);
      }, 100);
      var guest_paid_pet_v =
        parseFloat(this.state.guest_paid) -
        parseFloat(this.state.pet_policy_fee) +
        parseFloat(pet_policy_fees_total);
      var guest_paid_pet = guest_paid_pet_v
        ? parseFloat(guest_paid_pet_v).toFixed(2).replace(/\.00$/, "")
        : 0;
      var host_payout_pet_v =
        parseFloat(this.state.host_payout) -
        parseFloat(this.state.pet_policy_fee) +
        parseFloat(pet_policy_fees_total);
      var host_payout_pet = host_payout_pet_v
        ? parseFloat(host_payout_pet_v).toFixed(2).replace(/\.00$/, "")
        : 0;
      if (pet_policy_fees_total > 0) {
        this.setState({
          guest_paid: guest_paid_pet,
          host_payout: host_payout_pet,
        });
      }
      this.setState({ pet_policy_fee: pet_policy_fees_total });
    });
    this.setState({ confirm_back: 1 });
  };

  handleAddFields = () => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    var petPolicyTypeDataTotal = 1;
    values.push({
      pet_type_slug: "",
      allowed_pet_number: "",
      one_time_fee_per_type: "",
      deposite_amount_per_pet: "",
      rent_per_type: "",
      weight_per_type: "",
      policy_slug: "",
    });
    petPolicyTypeDataTotal = values.length;

    this.setState({
      petPolicyTypeData: values,
      petPolicyTypeDataTotal: petPolicyTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleRemoveFields = (i) => {
    var pet_policy_fees_total = 0;
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    values.splice(i, 1);
    var petPolicyTypeDataTotal = 0;
    petPolicyTypeDataTotal = values.length;
    this.setState({ petPolicyTypeData: values, pet_data: values });
    setTimeout(() => {
      this.state.pet_data.map((opt, i) => {
        pet_policy_fees_total += +(opt.one_time_fee_per_type
          ? (opt.pet_count ? parseInt(opt.pet_count) : 0) *
            parseFloat(opt.one_time_fee_per_type)
          : 0);
      }, 100);
      var guest_paid_pet_v =
        parseFloat(this.state.guest_paid) -
        parseFloat(this.state.pet_policy_fee) +
        parseFloat(pet_policy_fees_total);
      var guest_paid_pet = guest_paid_pet_v
        ? parseFloat(guest_paid_pet_v).toFixed(2).replace(/\.00$/, "")
        : 0;
      var host_payout_pet_v =
        parseFloat(this.state.host_payout) -
        parseFloat(this.state.pet_policy_fee) +
        parseFloat(pet_policy_fees_total);
      var host_payout_pet = host_payout_pet_v
        ? parseFloat(host_payout_pet_v).toFixed(2).replace(/\.00$/, "")
        : 0;
      if (pet_policy_fees_total > 0) {
        this.setState({
          guest_paid: guest_paid_pet,
          host_payout: host_payout_pet,
        });
      }
      this.setState({ pet_policy_fee: pet_policy_fees_total });
    });

    this.setState({
      petPolicyTypeDataTotal: petPolicyTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  // datechnageHandle = (event) => {
  //   const values = [...this.state.resolutionFeeData];
  //   if (event.target.type === "date" && event.target.value !== null) {
  //     values[event.target.index].payment_received_date = Moment(
  //       event.target.value
  //     ).toDate();
  //   }
  //   this.setState({ resolutionFeeData: values, resolution_fee_data: values });
  // };

  handleChangeResoultion = (index, event) => {
    var total_resolution_fee = 0;
    var to_building_total = 0;
    var to_oh_total = 0;
    var to_resident_total = 0;
    this.setState({
      isResolutionChange: 1,
      resolution_fee_error: false,
      valid_date_error: false,
    });
    const values = [...this.state.resolutionFeeData];
    if (event.target.name === "resolution_item") {
      values[index].resolution_item = event.target.value;
    } else if (event.target.name === "resolution_amount") {
      values[index].resolution_amount = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
      values[index].to_building_amount =
        values[index].resolution_amount && values[index].to_building
          ? (
              (parseFloat(values[index].resolution_amount) *
                parseFloat(values[index].to_building)) /
              100
            ).toFixed(2)
          : 0;
      values[index].to_oh_amount =
        values[index].resolution_amount && values[index].to_oh
          ? (
              (parseFloat(values[index].resolution_amount) *
                parseFloat(values[index].to_oh)) /
              100
            ).toFixed(2)
          : 0;
      values[index].to_resident_amount =
        values[index].resolution_amount && values[index].to_resident
          ? (
              (parseFloat(values[index].resolution_amount) *
                parseFloat(values[index].to_resident)) /
              100
            ).toFixed(2)
          : 0;
    } else if (event.target.name === "payment_received") {
      values[index].payment_received = event.target.checked === true ? 1 : 0;
    } else if (
      event.target.name === "payment_received_date" &&
      event.target.value !== null
    ) {
      const regexddmmyyyy =
        /^(1[0-2]|0?[1-9])[ /.](3[01]|[12][0-9]|0?[1-9])[ /.](?:[0-9]{2})?[0-9]{2}$/;
      if (regexddmmyyyy.test(event.target.value)) {
        values[index].payment_received_date_error = "";
      } else {
        values[index].payment_received_date_error =
          "Please enter valid date format MM/DD/YY.";
      }
      values[index].payment_received_date = event.target.value;
    } else {
      if (event.target.name === "to_building") {
        if (
          parseFloat(event.target.value) +
            (parseFloat(values[index].to_oh)
              ? parseFloat(values[index].to_oh)
              : 0) +
            (parseFloat(values[index].to_resident)
              ? parseFloat(values[index].to_resident)
              : 0) >
          100
        ) {
          values[index].to_building = event.target.value.replace(
            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
            ""
          );
          values[index].to_building_amount =
            values[index].resolution_amount && values[index].to_building
              ? (
                  (parseFloat(values[index].resolution_amount) *
                    parseFloat(values[index].to_building)) /
                  100
                ).toFixed(2)
              : 0;
          values[index].to_building_error =
            "OH % + Resident % + Property % total can't be greater than 100";
        } else {
          values[index].to_building = event.target.value.replace(
            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
            ""
          );
          values[index].to_building_amount =
            values[index].resolution_amount && values[index].to_building
              ? (
                  (parseFloat(values[index].resolution_amount) *
                    parseFloat(values[index].to_building)) /
                  100
                ).toFixed(2)
              : 0;
          if (values[index].to_building > 100) {
            values[index].to_building_error =
              "Property % can't be greater than 100";
          } else {
            values[index].to_building_error = "";
          }
        }
      }
      if (event.target.name === "to_oh") {
        if (
          parseFloat(event.target.value) +
            (parseFloat(values[index].to_building)
              ? parseFloat(values[index].to_building)
              : 0) +
            (parseFloat(values[index].to_resident)
              ? parseFloat(values[index].to_resident)
              : 0) >
          100
        ) {
          values[index].to_oh = event.target.value.replace(
            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
            ""
          );
          values[index].to_oh_amount =
            values[index].resolution_amount && values[index].to_oh
              ? (
                  (parseFloat(values[index].resolution_amount) *
                    parseFloat(values[index].to_oh)) /
                  100
                ).toFixed(2)
              : 0;
          values[index].to_oh_error =
            "OH % + Resident % + Property % total can't be greater than 100";
        } else {
          values[index].to_oh = event.target.value.replace(
            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
            ""
          );
          values[index].to_oh_amount =
            values[index].resolution_amount && values[index].to_oh
              ? (
                  (parseFloat(values[index].resolution_amount) *
                    parseFloat(values[index].to_oh)) /
                  100
                ).toFixed(2)
              : 0;
          if (values[index].to_oh > 100) {
            values[index].to_oh_error = "OH % can't be greater than 100";
          } else {
            values[index].to_oh_error = "";
          }
        }
      }
      if (event.target.name === "to_resident") {
        if (
          parseFloat(event.target.value) +
            (parseFloat(values[index].to_oh)
              ? parseFloat(values[index].to_oh)
              : 0) +
            (parseFloat(values[index].to_building)
              ? parseFloat(values[index].to_building)
              : 0) >
          100
        ) {
          values[index].to_resident = event.target.value.replace(
            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
            ""
          );
          values[index].to_resident_amount =
            values[index].resolution_amount && values[index].to_resident
              ? (
                  (parseFloat(values[index].resolution_amount) *
                    parseFloat(values[index].to_resident)) /
                  100
                ).toFixed(2)
              : 0;
          values[index].to_resident_error =
            "OH % + Resident % + Property % total can't be greater than 100";
        } else {
          values[index].to_resident = event.target.value.replace(
            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
            ""
          );
          values[index].to_resident_amount =
            values[index].resolution_amount && values[index].to_resident
              ? (
                  (parseFloat(values[index].resolution_amount) *
                    parseFloat(values[index].to_resident)) /
                  100
                ).toFixed(2)
              : 0;
          if (values[index].to_resident > 100) {
            values[index].to_resident_error =
              "Resident % can't be greater than 100";
          } else {
            values[index].to_resident_error = "";
          }
        }
      }
    }
    this.setState({ resolution_fee_data: values });
    this.setState({ resolutionFeeData: values });
    this.setState({ confirm_back: 1 });
    setTimeout(() => {
      this.state.resolution_fee_data.map((opt, i) => {
        if (
          opt.to_building_error &&
          opt.to_building_error != "" &&
          opt.to_oh_error &&
          opt.to_oh_error != "" &&
          opt.to_resident_error &&
          opt.to_resident_error != ""
        ) {
          this.setState({ resolution_fee_error: true });
        } else if (
          opt.to_building_error &&
          opt.to_building_error == "" &&
          opt.to_oh_error &&
          opt.to_oh_error == "" &&
          opt.to_resident_error &&
          opt.to_resident_error == ""
        ) {
          this.setState({ resolution_fee_error: false });
        } else {
          this.setState({ resolution_fee_error: false });
        }

        if (
          opt.payment_received_date_error &&
          opt.payment_received_date_error != ""
        ) {
          this.setState({ valid_date_error: true });
        } else if (
          opt.payment_received_date_error &&
          opt.payment_received_date_error == ""
        ) {
          this.setState({ valid_date_error: false });
        } else {
          this.setState({ valid_date_error: false });
        }

        total_resolution_fee += +(opt.resolution_amount
          ? parseFloat(opt.resolution_amount)
          : 0);
        to_building_total += +(opt.to_building_amount
          ? parseFloat(opt.to_building_amount)
          : 0);
        to_oh_total += +(opt.to_oh_amount ? parseFloat(opt.to_oh_amount) : 0);
        to_resident_total += +(opt.to_resident_amount
          ? parseFloat(opt.to_resident_amount)
          : 0);

        this.setState({
          total_resolution_fee: total_resolution_fee
            .toFixed(2)
            .replace(/\.00$/, ""),
          to_building_total: to_building_total.toFixed(2).replace(/\.00$/, ""),
          to_oh_total: to_oh_total.toFixed(2).replace(/\.00$/, ""),
          to_resident_total: to_resident_total.toFixed(2).replace(/\.00$/, ""),
        });
      });
    }, 100);
    setTimeout(() => {
      let resident_earning_val1 =
        (this.state.total_nightly_rate_to_resident
          ? (parseFloat(this.state.total_nightly_fee) *
              this.state.total_nightly_rate_to_resident) /
            100
          : 0) +
        (this.state.cleaning_fee_to_resident
          ? (parseFloat(this.state.cleaning_fee) *
              this.state.cleaning_fee_to_resident) /
            100
          : 0) -
        (this.state.channel_fee_to_resident
          ? (parseFloat(this.state.host_service_fee) *
              this.state.channel_fee_to_resident) /
            100
          : 0);
      let resident_earning1 = resident_earning_val1
        ? parseFloat(resident_earning_val1).toFixed(2)
        : "";

      var to_resident_earning = (
        (this.state.to_resident_total
          ? parseFloat(this.state.to_resident_total)
          : 0) + (resident_earning1 ? parseFloat(resident_earning1) : 0)
      ).toFixed(2);

      let property_earning_val1 =
        (this.state.total_nightly_rate_to_property
          ? (parseFloat(this.state.total_nightly_fee) *
              this.state.total_nightly_rate_to_property) /
            100
          : 0) +
        (this.state.cleaning_fee_to_property
          ? (parseFloat(this.state.cleaning_fee) *
              this.state.cleaning_fee_to_property) /
            100
          : 0) -
        (this.state.channel_fee_to_property
          ? (parseFloat(this.state.host_service_fee) *
              this.state.channel_fee_to_property) /
            100
          : 0);

      let property_earning1 = property_earning_val1
        ? parseFloat(property_earning_val1).toFixed(2)
        : "";

      var to_building_earning = (
        (this.state.to_building_total
          ? parseFloat(this.state.to_building_total)
          : 0) + (property_earning1 ? parseFloat(property_earning1) : 0)
      ).toFixed(2);

      let oh_earning_val1 =
        (this.state.total_nightly_rate_to_oh
          ? (parseFloat(this.state.total_nightly_fee) *
              this.state.total_nightly_rate_to_oh) /
            100
          : 0) +
        (this.state.cleaning_fee_to_oh
          ? (parseFloat(this.state.cleaning_fee) *
              this.state.cleaning_fee_to_oh) /
            100
          : 0) -
        (this.state.channel_fee_to_oh
          ? (parseFloat(this.state.host_service_fee) *
              this.state.channel_fee_to_oh) /
            100
          : 0);

      let oh_earning1 = oh_earning_val1
        ? parseFloat(oh_earning_val1).toFixed(2)
        : "";

      var to_oh_earning = (
        (this.state.to_oh_total ? parseFloat(this.state.to_oh_total) : 0) +
        (oh_earning1 ? parseFloat(oh_earning1) : 0)
      ).toFixed(2);
      this.setState({
        resident_earning_resolution: to_resident_earning,
        property_earning_resolution: to_building_earning,
        oh_earning_resolution: to_oh_earning,
      });
    }, 200);
  };

  handleAddResolutionFields = () => {
    this.setState({ isResolutionChange: 1 });
    const values = [...this.state.resolutionFeeData];
    var resolutionFeeDataTotal = 1;
    values.push({
      resolution_item: "",
      resolution_amount: "",
      to_building_amount: "",
      to_building: "",
      to_oh_amount: "",
      to_oh: "",
      to_resident_amount: "",
      to_resident: "",
      payment_received: 0,
      payment_received_date: "",
      resolution_fee_slug: "",
    });
    resolutionFeeDataTotal = values.length;

    this.setState({
      resolutionFeeData: values,
      resolutionFeeDataTotal: resolutionFeeDataTotal,
    });
    this.setState({ confirm_back: 1 });
    const input = document.getElementsByClassName("total-payout");
    if (input.length > 0) {
      input[0].scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  handleRemoveResolutionFields = (i) => {
    var total_resolution_fee = 0;
    var to_building_total = 0;
    var to_oh_total = 0;
    var to_resident_total = 0;
    this.setState({ isResolutionChange: 1 });
    const values = [...this.state.resolutionFeeData];
    values.splice(i, 1);
    var resolutionFeeDataTotal = 0;
    resolutionFeeDataTotal = values.length;
    this.setState({ resolutionFeeData: values, resolution_fee_data: values });
    if (resolutionFeeDataTotal > 0) {
      setTimeout(() => {
        this.state.resolution_fee_data.map((opt, i) => {
          total_resolution_fee += +(opt.resolution_amount
            ? parseFloat(opt.resolution_amount)
            : 0);
          to_building_total += +(opt.to_building_amount
            ? parseFloat(opt.to_building_amount)
            : 0);
          to_oh_total += +(opt.to_oh_amount ? parseFloat(opt.to_oh_amount) : 0);
          to_resident_total += +(opt.to_resident_amount
            ? parseFloat(opt.to_resident_amount)
            : 0);
          this.setState({
            total_resolution_fee: total_resolution_fee,
            to_building_total: to_building_total
              .toFixed(2)
              .replace(/\.00$/, ""),
            to_oh_total: to_oh_total.toFixed(2).replace(/\.00$/, ""),
            to_resident_total: to_resident_total
              .toFixed(2)
              .replace(/\.00$/, ""),
          });
        });
      }, 100);
      setTimeout(() => {
        let resident_earning_val1 =
          (this.state.total_nightly_rate_to_resident
            ? (parseFloat(this.state.total_nightly_fee) *
                this.state.total_nightly_rate_to_resident) /
              100
            : 0) +
          (this.state.cleaning_fee_to_resident
            ? (parseFloat(this.state.cleaning_fee) *
                this.state.cleaning_fee_to_resident) /
              100
            : 0) -
          (this.state.channel_fee_to_resident
            ? (parseFloat(this.state.host_service_fee) *
                this.state.channel_fee_to_resident) /
              100
            : 0);
        let resident_earning1 = resident_earning_val1
          ? parseFloat(resident_earning_val1).toFixed(2)
          : "";

        var to_resident_earning = (
          (this.state.to_resident_total
            ? parseFloat(this.state.to_resident_total)
            : 0) + (resident_earning1 ? parseFloat(resident_earning1) : 0)
        ).toFixed(2);

        let property_earning_val1 =
          (this.state.total_nightly_rate_to_property
            ? (parseFloat(this.state.total_nightly_fee) *
                this.state.total_nightly_rate_to_property) /
              100
            : 0) +
          (this.state.cleaning_fee_to_property
            ? (parseFloat(this.state.cleaning_fee) *
                this.state.cleaning_fee_to_property) /
              100
            : 0) -
          (this.state.channel_fee_to_property
            ? (parseFloat(this.state.host_service_fee) *
                this.state.channel_fee_to_property) /
              100
            : 0);

        let property_earning1 = property_earning_val1
          ? parseFloat(property_earning_val1).toFixed(2)
          : "";

        var to_building_earning = (
          (this.state.to_building_total
            ? parseFloat(this.state.to_building_total)
            : 0) + (property_earning1 ? parseFloat(property_earning1) : 0)
        ).toFixed(2);

        let oh_earning_val1 =
          (this.state.total_nightly_rate_to_oh
            ? (parseFloat(this.state.total_nightly_fee) *
                this.state.total_nightly_rate_to_oh) /
              100
            : 0) +
          (this.state.cleaning_fee_to_oh
            ? (parseFloat(this.state.cleaning_fee) *
                this.state.cleaning_fee_to_oh) /
              100
            : 0) -
          (this.state.channel_fee_to_oh
            ? (parseFloat(this.state.host_service_fee) *
                this.state.channel_fee_to_oh) /
              100
            : 0);

        let oh_earning1 = oh_earning_val1
          ? parseFloat(oh_earning_val1).toFixed(2)
          : "";

        var to_oh_earning = (
          (this.state.to_oh_total ? parseFloat(this.state.to_oh_total) : 0) +
          (oh_earning1 ? parseFloat(oh_earning1) : 0)
        ).toFixed(2);
        this.setState({
          resident_earning_resolution: to_resident_earning,
          property_earning_resolution: to_building_earning,
          oh_earning_resolution: to_oh_earning,
        });
      }, 200);
    }
    this.setState({
      resolutionFeeDataTotal: resolutionFeeDataTotal,
    });
    if (resolutionFeeDataTotal === 0) {
      this.setState({
        resident_earning_resolution: "",
        property_earning_resolution: "",
        oh_earning_resolution: "",
        total_resolution_fee: "",
      });
    }
    this.setState({ confirm_back: 1 });
  };

  async getReservationFees(reservation_slug) {
    this.setState({ showSpinner: true, loading: true });
    var costList = [];
    var costListTotal = 0;
    var additionalFee = 0;
    let res = await PropertyCostService.getAllCosts(reservation_slug + "/12");
    if (global.successStatus.includes(res.status)) {
      costList = res.data ? res.data : "";
      costListTotal = costList ? costList.length : 0;
    }
    const newCostList =
      costListTotal > 0
        ? costList.map(({ slug, cost_type }) => ({
            value: slug ? slug : "",
            label:
              cost_type && cost_type.cost_type_name
                ? cost_type.cost_type_name
                : "",
          }))
        : [];
    this.setState({
      costList: costList,
      costListTotal: costListTotal,
      newCostList: newCostList,
    });
    this.setState({ showSpinner: false, loading: false });
    setTimeout(() => {
      var tmpFee = 0;
      this.state.costList.map((opt) => {
        if (
          opt.cost_payable_at
            ? opt.cost_payable_at.cost_payable_at_name === "Daily"
            : ""
        ) {
          tmpFee =
            parseFloat(opt.cost_name) * parseInt(this.state.total_stay_nights);
        } else {
          tmpFee = parseFloat(opt.cost_name);
        }
        additionalFee += +tmpFee;
      });
      this.setState({ pet_fee: additionalFee });
    }, 200);
  }

  async getListingFees(listing_slug_value) {
    this.setState({ showSpinner: true, loading: true });
    var costList = [];
    var costListTotal = 0;
    var additionalFee = 0;
    let res = await PropertyCostService.getAllCosts(listing_slug_value + "/5");
    if (global.successStatus.includes(res.status)) {
      costList = res.data ? res.data : "";
      costListTotal = costList ? costList.length : 0;
    }
    const newCostList =
      costListTotal > 0
        ? costList.map(({ slug, cost_type }) => ({
            value: slug ? slug : "",
            label:
              cost_type && cost_type.cost_type_name
                ? cost_type.cost_type_name
                : "",
          }))
        : [];
    this.setState({
      costList: costList,
      costListTotal: costListTotal,
      newCostList: newCostList,
    });
    this.setState({ showSpinner: false, loading: false });
    setTimeout(() => {
      var tmpFee = 0;
      this.state.costList.map((opt) => {
        if (
          opt.cost_payable_at
            ? opt.cost_payable_at.cost_payable_at_name === "Daily"
            : ""
        ) {
          tmpFee =
            parseFloat(opt.cost_name) * parseInt(this.state.total_stay_nights);
        } else {
          tmpFee = parseFloat(opt.cost_name);
        }
        additionalFee += +tmpFee;
      });
      this.setState({ pet_fee: additionalFee });
    }, 200);
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  handleChangeStatus(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    const status_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStatusList: value,
      status_id: status_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeChannel(value) {
    let set_channel = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        set_channel.push(item.value);
      });
    }
    const set_channel_value = value !== null ? value.value : [];
    const set_channel_name = value !== null ? value.label : "";
    this.setState({
      selectedChannelList: value,
      set_channel: set_channel_value,
      set_channel_name: set_channel_name,
    });
    this.setState({ confirm_back: 1 });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Reservations";
    } else {
      return "Reservations";
    }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <CalendarSidebar
                        property_slug={this.state.property_slug}
                        reservation_slug={this.state.slug}
                      />
                    </aside>
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <AllPropertyReservationSidebar
                        property_slug={this.state.property_slug}
                        reservation_slug={this.state.slug}
                      />
                    </aside>
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      {this.state.slug !== undefined ? (
                        <button
                          type="button"
                          className="btn-success-outline-small"
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/reservations/view/" +
                                        this.state.slug
                                    : "/reservations/view/" +
                                        this.state.property_slug +
                                        "/" +
                                        this.state.slug
                                );
                              else return false;
                            else
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/reservations/view/" +
                                      this.state.slug
                                  : "/reservations/view/" +
                                      this.state.property_slug +
                                      "/" +
                                      this.state.slug
                              );
                          }}
                        >
                          Cancel
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn-success-outline-small"
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/reservations"
                                    : "/reservations"
                                );
                              else return false;
                            else
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/reservations"
                                  : "/reservations"
                              );
                          }}
                        >
                          Cancel
                        </button>
                      )}

                      {global.userPermissions.checkPermission(
                        "reservations-update"
                      ) && (
                        <button
                          type="button"
                          className="btn-success ml-3"
                          onClick={this.saveOrUpdateReservation}
                          disabled={this.state.isSubmit ? true : false}
                        >
                          {this.state.isSubmit
                            ? global.loader
                            : this.state.slug !== undefined
                            ? "Save"
                            : "Save"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-lg-l-15 pd-sm-l-30 pd-sm-r-30 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <form id="reservation_form">
                      <div className="m-0">
                        <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                          <h3 className="mg-l-0 mg-t-15 background-head">
                            Notes
                          </h3>
                        </div>
                        <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Reservation Notes{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              type="text"
                              as="textarea"
                              rows={4}
                              onChange={this.changeHandler}
                              name="internal_notes"
                              value={this.state.internal_notes || ""}
                            />
                            {this.state.errors.internal_notes ? (
                              <div className="text-danger">
                                {this.state.errors.internal_notes}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Include In Owner Report{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              type="text"
                              as="textarea"
                              rows={4}
                              onChange={this.changeHandler}
                              name="include_owner_report"
                              value={this.state.include_owner_report || ""}
                            />
                            {this.state.errors.include_owner_report ? (
                              <div className="text-danger">
                                {this.state.errors.include_owner_report}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                          <h3 className="mg-l-0 mg-t-15 background-head">
                            Basic
                          </h3>
                        </div>
                        <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              First Name
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="first_name"
                              value={this.state.first_name || ""}
                              maxLength="50"
                            />
                            {this.state.errors.first_name ? (
                              <div className="text-danger">
                                {this.state.errors.first_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "first_name",
                                this.state.first_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Last Name
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="last_name"
                              value={this.state.last_name || ""}
                              maxLength="50"
                            />
                            {this.state.errors.last_name ? (
                              <div className="text-danger">
                                {this.state.errors.last_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "last_name",
                                this.state.last_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Listing Name (Unit){" "}
                            </label>
                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedListingList || ""}
                              options={this.state.newListingList}
                              onChange={(value) =>
                                this.handleChangeListingList(value)
                              }
                              defaultValue={this.state.selectedListingList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                            {this.state.errors.listing_slug ? (
                              <div className="text-danger">
                                {this.state.errors.listing_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "listing_name",
                                this.state.listing_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}{" "}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Check-In
                            </label>
                            <div className="col-6 col-md-8 col-lg-8 col-xl-8 col-xxl-8 p-0 float-left">
                              <DatePicker
                                className="form-control max_width_100"
                                disabledKeyboardNavigation
                                strictParsing
                                scrollableYearDropdown
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="checkin_date"
                                excludeDates={this.state.disabled_dates}
                                // excludeDates={this.state.disabled_dates.map(
                                //   (item, index) => {
                                //     return item.dt;
                                //   }
                                // )}
                                // showTimeSelect
                                // dateFormat="MM/dd/yy h:mm a"
                                dateFormat="MM/dd/yy"
                                minDate={
                                  this.state.listingFromdate
                                    ? this.state.listingFromdate
                                    : ""
                                }
                                // maxDate={
                                //   this.state.listingTodate
                                //     ? this.state.listingTodate
                                //     : ""
                                // }
                                maxDate={
                                  this.state.listingTodate
                                    ? this.state.listingTodate
                                      ? new Date(
                                          this.state.listingTodate
                                        ).setDate(
                                          new Date(
                                            this.state.listingTodate
                                          ).getDate() - 1
                                        )
                                      : ""
                                    : ""
                                }
                                selected={this.state.checkin_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "checkin_date",
                                      value: data,
                                    },
                                  })
                                }
                              />
                              {this.state.errors.checkin_date ? (
                                <div className="text-danger">
                                  {this.state.errors.checkin_date}
                                </div>
                              ) : (
                                this.validator.message(
                                  "check-in",
                                  this.state.checkin_date,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 p-0 float-left">
                              <InputMask
                                name="checkin_time"
                                onChange={this.changeHandler}
                                value={
                                  this.state.checkin_time !== ""
                                    ? this.state.checkin_time || ""
                                    : "00:00AM"
                                }
                                mask="99:99 aa"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Check-Out
                            </label>
                            <div className="col-6 col-md-8 col-lg-8 col-xl-8 col-xxl-8 p-0 float-left">
                              <DatePicker
                                className="form-control max_width_100"
                                disabledKeyboardNavigation
                                strictParsing
                                scrollableYearDropdown
                                showMonthDropdown
                                showYearDropdown
                                minDate={
                                  this.state.checkin_date
                                    ? Moment(this.state.checkin_date)
                                        .add(1, "days")
                                        .toDate("dd/MM/YY")
                                    : ""
                                }
                                maxDate={this.state.maxCheckoutDate}
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="checkout_date"
                                // excludeDates={this.state.slug !== undefined ? this.state.disabled_dates.map(
                                //   (item, index) => {
                                //     return item.dt;
                                //   }
                                // ) : ""}
                                // showTimeSelect
                                // dateFormat="MM/dd/yy h:mm a"
                                dateFormat="MM/dd/yy"
                                selected={this.state.checkout_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "checkout_date",
                                      value: data,
                                    },
                                  })
                                }
                              />
                              {this.state.errors.checkout_date ? (
                                <div className="text-danger">
                                  {this.state.errors.checkout_date}
                                </div>
                              ) : (
                                this.validator.message(
                                  "check-out",
                                  this.state.checkout_date,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 p-0 float-left">
                              <InputMask
                                name="checkout_time"
                                onChange={this.changeHandler}
                                value={
                                  this.state.checkout_time !== ""
                                    ? this.state.checkout_time || ""
                                    : "11:59PM"
                                }
                                mask="99:99 aa"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Confirmation Code
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="confirmation_code"
                              value={this.state.confirmation_code || ""}
                              maxLength="12"
                              onKeyPress={
                                global.onKeyPressEvent.alphaNumValidation
                              }
                            />
                            {this.state.errors.confirmation_code ? (
                              <div className="text-danger">
                                {this.state.errors.confirmation_code}
                              </div>
                            ) : (
                              this.validator.message(
                                "confirmation_code",
                                this.state.confirmation_code,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Adults
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="adults"
                              onKeyPress={
                                global.onKeyPressEvent.floatValidation
                              }
                              value={this.state.adults || ""}
                              maxLength="2"
                            />
                            {this.state.errors.adults ? (
                              <div className="text-danger">
                                {this.state.errors.adults}
                              </div>
                            ) : (
                              this.validator.message(
                                "adults",
                                this.state.adults,
                                "required|numeric|min:1,num",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Children{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="children"
                              onKeyPress={
                                global.onKeyPressEvent.floatValidation
                              }
                              value={this.state.children || ""}
                              maxLength="2"
                            />
                            {this.state.errors.children ? (
                              <div className="text-danger">
                                {this.state.errors.children}
                              </div>
                            ) : (
                              this.validator.message(
                                "children",
                                this.state.children,
                                "numeric|min:0,num",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Infants{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="infants"
                              onKeyPress={
                                global.onKeyPressEvent.floatValidation
                              }
                              value={this.state.infants || ""}
                              maxLength="2"
                            />
                            {this.state.errors.infants ? (
                              <div className="text-danger">
                                {this.state.errors.infants}
                              </div>
                            ) : (
                              this.validator.message(
                                "infants",
                                this.state.infants,
                                "numeric|min:0,num",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Pets <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="pets"
                              onKeyPress={
                                global.onKeyPressEvent.floatValidation
                              }
                              value={this.state.pets || ""}
                              maxLength="2"
                            />
                            {this.state.pets &&
                            this.state.multiListTotal > 0 ? (
                              this.state.property_pet_allowed === 1 &&
                              this.state.listing_pet_allowed === 1 ? (
                                <p className="">
                                  Note:{" "}
                                  {this.state.multiList.map(
                                    (option, i) =>
                                      option.pet_type_name +
                                      (i < this.state.multiListTotal - 1
                                        ? ", "
                                        : "") +
                                      ""
                                  )}{" "}
                                  are prohibited at property
                                </p>
                              ) : (
                                <p className="">
                                  Note: Since the listing is not pet friendly,
                                  you cannot select pets in the reservation.
                                </p>
                              )
                            ) : (
                              ""
                            )}

                            {this.state.errors.pets ? (
                              <div className="text-danger">
                                {this.state.errors.pets}
                              </div>
                            ) : (
                              this.validator.message(
                                "pets",
                                this.state.pets,
                                "numeric|min:0,num",
                                { className: "text-danger" }
                              )
                            )}
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Channel{" "}
                            </label>

                            <div className="col-md-12 p-0">
                              <Select
                                styles={customStyles}
                                isClearable={true}
                                menuPlacement="auto"
                                className="multiselect"
                                options={this.state.newChannelList}
                                getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangeChannel(value)
                                }
                                value={this.state.selectedChannelList}
                                defaultValue={this.state.selectedChannelList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                            </div>
                            {this.state.errors.set_channel ? (
                              <div className="text-danger">
                                {this.state.errors.set_channel}
                              </div>
                            ) : (
                              this.validator.message(
                                "channel",
                                this.state.set_channel,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Status
                            </label>

                            <div className="col-md-12 p-0">
                              <Select
                                styles={customStyles}
                                isClearable={true}
                                menuPlacement="auto"
                                className="multiselect"
                                options={this.state.selectStatusList}
                                getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangeStatus(value)
                                }
                                value={this.state.selectedStatusList}
                                defaultValue={this.state.selectedStatusList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                            </div>
                            {this.state.errors.status_id ? (
                              <div className="text-danger">
                                {this.state.errors.status_id}
                              </div>
                            ) : (
                              this.validator.message(
                                "status",
                                this.state.status_id,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>

                          {global.userPermissions.checkPermission(
                            "guests-personal-data-view"
                          ) && (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Email{" "}
                                <span className="optional">(Optional)</span>
                              </label>

                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="email"
                                value={this.state.email || ""}
                                maxLength="50"
                              />
                              {this.state.errors.email ? (
                                <div className="text-danger">
                                  {this.state.errors.email}
                                </div>
                              ) : (
                                this.validator.message(
                                  "email",
                                  this.state.email,
                                  "email",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          )}
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Phone <span className="optional">(Optional)</span>
                            </label>
                            <PhoneInput
                              country={global.country}
                              disableSearchIcon
                              enableSearch="true"
                              countryCodeEditable="true"
                              value={this.state.phone_number}
                              onChange={(phone_number) =>
                                this.setState({ phone_number })
                              }
                            />
                            {this.state.errors.phone_number ? (
                              <div className="text-danger">
                                {this.state.errors.phone_number}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Passport{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="passport"
                              value={this.state.passport || ""}
                              maxLength="50"
                            />
                            {this.state.errors.passport ? (
                              <div className="text-danger">
                                {this.state.errors.passport}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {global.userPermissions.checkPermission(
                          "guests-personal-data-edit"
                        ) && (
                          <GooglePlaces
                            getChildData={this.getChildData.bind(this)}
                            unit={this.state.street_1}
                            street1={this.state.street_2}
                            city={this.state.city_name}
                            state={this.state.state_name}
                            county={this.state.county_name}
                            country={this.state.country_name}
                            zipcode={this.state.zip_code}
                          />
                        )}

                        {this.state.slug !== undefined ? (
                          <>
                            <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                              <h3 className="mg-l-0 mg-t-15 background-head">
                                Bypass
                              </h3>
                            </div>
                            <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-30">
                                <label
                                  htmlFor="bypass_guest_form"
                                  className="form-label text-left pd-b-5 hand-cursor"
                                >
                                  Bypass Guest Form
                                </label>
                                <Form.Check
                                  name="bypass_guest_form"
                                  type={"checkbox"}
                                  id={`bypass_guest_form`}
                                  label={""}
                                  className={"ckbox"}
                                  onChange={this.changeHandler}
                                  checked={this.state.bypass_guest_form}
                                />
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-30">
                                <label
                                  htmlFor="bypass_card_token"
                                  className="form-label text-left pd-b-5 hand-cursor"
                                >
                                  Bypass Security Deposit
                                </label>
                                <Form.Check
                                  name="bypass_card_token"
                                  type={"checkbox"}
                                  id={`bypass_card_token`}
                                  label={""}
                                  className={"ckbox"}
                                  onChange={this.changeHandler}
                                  checked={this.state.bypass_card_token}
                                />
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-30">
                                <label
                                  htmlFor="bypass_parking_payment"
                                  className="form-label text-left pd-b-5 hand-cursor"
                                >
                                  Bypass Parking Payment
                                </label>
                                <Form.Check
                                  name="bypass_parking_payment"
                                  type={"checkbox"}
                                  id={`bypass_parking_payment`}
                                  label={""}
                                  className={"ckbox"}
                                  onChange={this.changeHandler}
                                  checked={this.state.bypass_parking_payment}
                                />
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-30">
                                <label
                                  htmlFor="bypass_email_verification"
                                  className="form-label text-left pd-b-5 hand-cursor"
                                >
                                  Bypass Email Verification
                                </label>
                                <Form.Check
                                  name="bypass_email_verification"
                                  type={"checkbox"}
                                  id={`bypass_email_verification`}
                                  label={""}
                                  className={"ckbox"}
                                  onChange={this.changeHandler}
                                  checked={this.state.bypass_email_verification}
                                />
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-30">
                                <label
                                  htmlFor="guest_info_keep"
                                  className="form-label text-left pd-b-5 hand-cursor"
                                >
                                  Keep Guest Info
                                </label>
                                <Form.Check
                                  name="guest_info_keep"
                                  type={"checkbox"}
                                  id={`guest_info_keep`}
                                  label={""}
                                  className={"ckbox"}
                                  onChange={this.changeHandler}
                                  checked={this.state.guest_info_keep}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {global.userPermissions.checkPermission(
                          "guests-pricing-data-edit"
                        ) && (
                          <>
                            <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                              <h3 className="mg-l-0 mg-t-15 background-head">
                                Price
                              </h3>
                            </div>
                            <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Nightly Rate{" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="nightly_rate"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    disabled={true}
                                    className="custom-disable"
                                    value={
                                      this.state.nightly_rate
                                        ? this.state.nightly_rate.replace(
                                            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                            ""
                                          )
                                        : "" || ""
                                    }
                                    maxLength="10"
                                  />
                                </div>
                                {this.state.errors.nightly_rate ? (
                                  <div className="text-danger">
                                    {this.state.errors.nightly_rate}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Total Nights
                                </label>

                                <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="total_stay_nights"
                                  className="custom-disable"
                                  onKeyPress={
                                    global.onKeyPressEvent.floatValidation
                                  }
                                  value={this.state.total_stay_nights || ""}
                                  maxLength="10"
                                  disabled={true}
                                />

                                {this.state.errors.total_stay_nights ? (
                                  <div className="text-danger">
                                    {this.state.errors.total_stay_nights}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "total_nights",
                                    this.state.total_stay_nights,
                                    "numeric",
                                    { className: "text-danger" }
                                  )
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Total Nightly Rate{" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="total_nightly_fee"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={
                                      this.state.total_nightly_fee
                                        ? this.state.total_nightly_fee
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                            .replace(/\.00$/, "")
                                        : "" || ""
                                    }
                                    maxLength="10"
                                  />
                                </div>
                                {this.state.errors.total_nightly_fee ? (
                                  <div className="text-danger">
                                    {this.state.errors.total_nightly_fee}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Cleaning Fee{" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="cleaning_fee"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={
                                      this.state.cleaning_fee
                                        ? this.state.cleaning_fee
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                            .replace(/\.00$/, "")
                                        : "" || ""
                                    }
                                    maxLength="10"
                                  />
                                </div>
                                {this.state.errors.cleaning_fee ? (
                                  <div className="text-danger">
                                    {this.state.errors.cleaning_fee}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  General Fee{" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="pet_fee"
                                    className="custom-disable"
                                    value={
                                      this.state.pet_fee
                                        ? this.state.pet_fee
                                        : "" || ""
                                    }
                                    maxLength="10"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    disabled={true}
                                  />
                                </div>
                                {this.state.errors.pet_fee ? (
                                  <div className="text-danger">
                                    {this.state.errors.pet_fee}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Pet Fee{" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="pet_policy_fee"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={
                                      this.state.pet_policy_fee
                                        ? this.state.pet_policy_fee
                                        : "" || ""
                                    }
                                    maxLength="10"
                                  />
                                </div>
                                {this.state.errors.pet_policy_fee ? (
                                  <div className="text-danger">
                                    {this.state.errors.pet_policy_fee}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Resolution Fee{" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="total_resolution_fee"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={
                                      this.state.total_resolution_fee
                                        ? this.state.total_resolution_fee
                                        : "" || ""
                                    }
                                    maxLength="10"
                                  />
                                </div>
                                {this.state.errors.total_resolution_fee ? (
                                  <div className="text-danger">
                                    {this.state.errors.total_resolution_fee}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Convenience Fee{" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    className="custom-disable"
                                    name="convenience_fee"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={
                                      this.state.convenience_fee
                                        ? this.state.convenience_fee
                                        : "" || ""
                                    }
                                    maxLength="10"
                                    disabled={true}
                                  />
                                </div>
                                {this.state.errors.convenience_fee ? (
                                  <div className="text-danger">
                                    {this.state.errors.convenience_fee}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div> */}
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Occupancy Taxes{" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    // className="custom-disable"
                                    name="occupancy_taxes"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={
                                      this.state.occupancy_taxes
                                        ? this.state.occupancy_taxes
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                            .replace(/\.00$/, "")
                                        : "" || ""
                                    }
                                    maxLength="10"
                                    // disabled={true}
                                  />
                                </div>
                                {this.state.errors.occupancy_taxes ? (
                                  <div className="text-danger">
                                    {this.state.errors.occupancy_taxes}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Security Deposit{" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="security_deposite"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={
                                      this.state.security_deposite
                                        ? this.state.security_deposite
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                            .replace(/\.00$/, "")
                                        : "" || ""
                                    }
                                    maxLength="10"
                                  />
                                </div>
                                {this.state.errors.security_deposite ? (
                                  <div className="text-danger">
                                    {this.state.errors.security_deposite}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Guest Service Fee{" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="guest_service_fee"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={
                                      this.state.guest_service_fee
                                        ? this.state.guest_service_fee
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                            .replace(/\.00$/, "")
                                        : "" || ""
                                    }
                                    maxLength="10"
                                  />
                                </div>
                                {this.state.errors.guest_service_fee ? (
                                  <div className="text-danger">
                                    {this.state.errors.guest_service_fee}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Channel Fee{" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="host_service_fee"
                                    value={
                                      this.state.host_service_fee
                                        ? this.state.host_service_fee
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                            .replace(/\.00$/, "")
                                        : "" || ""
                                    }
                                    maxLength="10"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                  />
                                </div>
                                {this.state.errors.host_service_fee ? (
                                  <div className="text-danger">
                                    {this.state.errors.host_service_fee}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Payment Processing Fee{" "}
                                  <span className="optional"></span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="payment_processing_fee"
                                    value={
                                      this.state.payment_processing_fee
                                        ? this.state.payment_processing_fee
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                            .replace(/\.00$/, "")
                                        : "" || ""
                                    }
                                    maxLength="10"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                  />
                                </div>
                                {this.state.errors.payment_processing_fee ? (
                                  <div className="text-danger">
                                    {this.state.errors.payment_processing_fee}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {global.userPermissions.checkPermission(
                                "guests-paid-edit"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Guest Paid{" "}
                                    <span className="optional">
                                      (Total Nightly Rate + Cleaning Fee + Guest
                                      Service Fee + Occupancy Taxes){" "}
                                      {this.state.guest_paid ? (
                                        <>
                                          {" "}
                                          {this.state.total_nightly_fee
                                            ? "=  $" +
                                              this.state.total_nightly_fee +
                                              " + "
                                            : ""}{" "}
                                          {this.state.cleaning_fee
                                            ? "$" +
                                              this.state.cleaning_fee +
                                              " + "
                                            : ""}{" "}
                                          {this.state.guest_service_fee
                                            ? "$" +
                                              this.state.guest_service_fee +
                                              " + "
                                            : ""}{" "}
                                          {this.state.occupancy_taxes
                                            ? "$" + this.state.occupancy_taxes
                                            : ""}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {" = "}
                                    </span>
                                  </label>
                                  <div className="input-group-prepend prefix-dollar">
                                    <span className="input-group-text">$</span>
                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="guest_paid"
                                      onKeyPress={
                                        global.onKeyPressEvent.floatValidation
                                      }
                                      value={
                                        this.state.guest_paid
                                          ? this.state.guest_paid
                                          : "" || ""
                                      }
                                      maxLength="10"
                                    />
                                  </div>
                                  {this.state.errors.guest_paid ? (
                                    <div className="text-danger">
                                      {this.state.errors.guest_paid}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  OTA Payout to Orion Haus{" "}
                                  <span className="optional">
                                    {this.state.set_channel_name ===
                                    "Direct Booking"
                                      ? "(Guest Paid - Guest Service Fee - Occupancy Taxes - Channel Fee - Payment Processing Fee)"
                                      : "(Guest Paid - Guest Service Fee - Occupancy Taxes - Channel Fee)"}
                                    {this.state.host_payout ? (
                                      <>
                                        {" = "}
                                        {this.state.set_channel_name ===
                                        "Direct Booking" ? (
                                          <>
                                            {this.state.guest_paid
                                              ? "$" + this.state.guest_paid
                                              : ""}{" "}
                                            {this.state.guest_service_fee
                                              ? "- $" +
                                                this.state.guest_service_fee
                                              : ""}{" "}
                                            {this.state.occupancy_taxes
                                              ? "- $" +
                                                this.state.occupancy_taxes
                                              : ""}{" "}
                                            {this.state.host_service_fee
                                              ? "- $" +
                                                this.state.host_service_fee
                                              : ""}
                                            {this.state.payment_processing_fee
                                              ? "- $" +
                                                this.state
                                                  .payment_processing_fee
                                              : ""}
                                          </>
                                        ) : (
                                          <>
                                            {this.state.guest_paid
                                              ? "$" + this.state.guest_paid
                                              : ""}{" "}
                                            {this.state.guest_service_fee
                                              ? "- $" +
                                                this.state.guest_service_fee
                                              : ""}{" "}
                                            {this.state.occupancy_taxes
                                              ? "- $" +
                                                this.state.occupancy_taxes
                                              : ""}{" "}
                                            {this.state.host_service_fee
                                              ? "- $" +
                                                this.state.host_service_fee
                                              : ""}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {" = "}
                                  </span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="host_payout"
                                    value={
                                      this.state.host_payout
                                        ? this.state.host_payout
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                            .replace(/\.00$/, "")
                                        : "" || ""
                                    }
                                    maxLength="10"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                  />
                                </div>
                                {this.state.errors.host_payout ? (
                                  <div className="text-danger">
                                    {this.state.errors.host_payout}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        {global.userPermissions.checkPermission(
                          "guests-pricing-data-edit"
                        ) && (
                          <>
                            {this.state.costListTotal > 0 ? (
                              <React.Fragment>
                                <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                                  <h3 className="mg-l-0 mg-t-30 background-head">
                                    General Fee
                                  </h3>
                                </div>
                                <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                                  <div className="table-responsive pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <Table hover className="">
                                      <thead>
                                        <tr>
                                          <th className="w-20">Fee Type</th>
                                          <th className="w-20">Payable By</th>
                                          <th className="w-20">Payable At</th>

                                          <th className="w-20">Refundable</th>
                                          <th className="w-20">Estimated</th>
                                          <th className="w-20 text-left">
                                            Mandatory
                                          </th>
                                          <th className="w-20">Fee</th>
                                          <th className="w-20">Amount</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.costListTotal > 0 ? (
                                          <React.Fragment>
                                            {this.state.costList.map(
                                              (option, i) => {
                                                return (
                                                  <tr
                                                    key={i}
                                                    // onClick={() => this.getCostDetails(item.slug)}
                                                  >
                                                    <td>
                                                      {option.cost_type
                                                        ? option.cost_type
                                                            .cost_type_name
                                                        : ""}
                                                    </td>
                                                    <td>
                                                      {option.cost_payable_by
                                                        ? option.cost_payable_by
                                                            .cost_payable_by_name
                                                        : ""}
                                                    </td>
                                                    <td>
                                                      {option.cost_payable_at
                                                        ? option.cost_payable_at
                                                            .cost_payable_at_name
                                                        : ""}
                                                    </td>

                                                    <td>
                                                      {option.refundable === 1
                                                        ? "Yes"
                                                        : "No"}
                                                    </td>
                                                    <td>
                                                      {option.estimated === 1
                                                        ? "Yes"
                                                        : "No"}
                                                    </td>
                                                    <td className="text-left">
                                                      {option.mandatory === 1
                                                        ? "Yes"
                                                        : "No"}
                                                    </td>
                                                    <td>
                                                      {option.cost_name
                                                        ? "$" +
                                                          global.onKeyPressEvent.numberWithCommasForDecimal(
                                                            option.cost_name.replace(
                                                              /\.00$/,
                                                              ""
                                                            )
                                                          )
                                                        : ""}
                                                    </td>
                                                    <td>
                                                      {option.cost_name
                                                        ? option.cost_payable_at
                                                          ? option
                                                              .cost_payable_at
                                                              .cost_payable_at_name ===
                                                            "Daily"
                                                            ? "$" +
                                                              global.onKeyPressEvent.numberWithCommasForDecimal(
                                                                (
                                                                  parseFloat(
                                                                    option.cost_name
                                                                  ) *
                                                                  parseInt(
                                                                    this.state
                                                                      .total_stay_nights
                                                                  )
                                                                )
                                                                  .toFixed(2)
                                                                  .replace(
                                                                    /\.00$/,
                                                                    ""
                                                                  )
                                                              )
                                                            : "$" +
                                                              global.onKeyPressEvent.numberWithCommasForDecimal(
                                                                option.cost_name.replace(
                                                                  /\.00$/,
                                                                  ""
                                                                )
                                                              )
                                                          : ""
                                                        : ""}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                            <tr>
                                              <td>Total General Fee</td>
                                              <td></td>
                                              <td></td>

                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>
                                                {this.state.pet_fee
                                                  ? "$" +
                                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                                      parseFloat(
                                                        this.state.pet_fee
                                                      )
                                                        .toFixed(2)
                                                        .replace(/\.00$/, "")
                                                    )
                                                  : ""}
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        ) : (
                                          <tr>
                                            <td colSpan="9" align="center">
                                              There are no general fees added in
                                              the system.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                        {this.state.showPetFee === 1 ? (
                          <React.Fragment>
                            <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-sm-r-15 pd-xs-r-0">
                              <h3 className="mg-l-0 mg-t-30 background-head">
                                Pet Fees
                              </h3>
                            </div>
                            <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                              <div className="pd-lg-l-30 pd-lg-r-30 pd-sm-l-15 pd-sm-r-15 pd-xs-l-15  pet-f mg-b-30 pet w-100">
                                <div className="col-md-12 p-0 petFeeSection">
                                  {this.state.property_pet_allowed === 1 &&
                                  this.state.listing_pet_allowed === 1 ? (
                                    <table className="petForm">
                                      <thead>
                                        <tr>
                                          <th className="w-1">
                                            <label className="mb-1">
                                              Pet Type
                                            </label>
                                          </th>
                                          <th className="w-1">
                                            <label className="mb-1">
                                              Max # Allowed
                                            </label>
                                          </th>
                                          <th className="w-2">
                                            <label className="mb-1">
                                              Max # Weight
                                            </label>
                                          </th>

                                          {/* <th className="w-2">
                                          <label className="mb-1">
                                            Deposit
                                          </label>
                                        </th> */}
                                          <th className="w-2">
                                            <label className="mb-1">
                                              One-Time Fee
                                            </label>
                                          </th>
                                          <th className="w-1">
                                            <label className="mb-1">
                                              Pet Count
                                            </label>
                                          </th>
                                          <th className="w-2">
                                            <label className="mb-1">
                                              Pet Weight
                                            </label>
                                          </th>
                                          <th className="w-3"></th>
                                        </tr>
                                      </thead>
                                      <tbody className="table_cnt_top">
                                        {this.state.petPolicyTypeData.map(
                                          (data, i) => {
                                            return (
                                              <tr key={i}>
                                                <td className="w-1">
                                                  <Col
                                                    xs={12}
                                                    className="pd-l-0 pd-r-2 colmn1"
                                                  >
                                                    <InputGroup className="mt-0 m-b-1">
                                                      <div className="col-md-12 p-0">
                                                        <Select
                                                          styles={customStyles}
                                                          className="multiselect"
                                                          menuPlacement="auto"
                                                          onChange={(value) =>
                                                            this.handleChangeselect(
                                                              i,
                                                              value
                                                            )
                                                          }
                                                          options={
                                                            this.state
                                                              .petPolicyDataListTotal >
                                                            0
                                                              ? this.state.petPolicyDataList.filter(
                                                                  (option) => {
                                                                    const val1 =
                                                                      this.state
                                                                        .petPolicyTypeData
                                                                        ? this.state.petPolicyTypeData.find(
                                                                            (
                                                                              opt
                                                                            ) => {
                                                                              if (
                                                                                option.value ===
                                                                                data.pet_type_slug
                                                                              )
                                                                                return false;
                                                                              return (
                                                                                opt.pet_type_slug ===
                                                                                option.value
                                                                              );
                                                                            }
                                                                          )
                                                                        : "";
                                                                    if (val1)
                                                                      return false;
                                                                    else
                                                                      return true;
                                                                  }
                                                                )
                                                              : []
                                                          }
                                                          getOptionValue={(
                                                            option
                                                          ) =>
                                                            `${option.label}`
                                                          }
                                                          theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 2,
                                                            colors: {
                                                              ...theme.colors,
                                                              primary: "#fff",
                                                              primary75: "#000",
                                                              primary50: "#000",
                                                              primary25: "#000",
                                                            },
                                                          })}
                                                          value={
                                                            data.selected_pet_type
                                                              ? data.selected_pet_type
                                                              : []
                                                          }
                                                          defaultValue={
                                                            data.selected_pet_type
                                                              ? data.selected_pet_type
                                                              : []
                                                          }
                                                        />
                                                      </div>
                                                    </InputGroup>
                                                  </Col>
                                                </td>
                                                <td className="w-1">
                                                  <Col
                                                    xs={12}
                                                    className="pd-l-2 pd-r-2 colmn1"
                                                  >
                                                    <InputGroup className="mt-0 m-b-1">
                                                      <Form.Control
                                                        name="allowed_pet_number"
                                                        maxLength="2"
                                                        onKeyPress={
                                                          global.onKeyPressEvent
                                                            .numberValidation
                                                        }
                                                        value={
                                                          data.allowed_pet_number
                                                            ? data.allowed_pet_number ||
                                                              ""
                                                            : ""
                                                        }
                                                        onChange={(event) =>
                                                          this.handleChange(
                                                            i,
                                                            event
                                                          )
                                                        }
                                                        disabled={true}
                                                      />
                                                    </InputGroup>
                                                  </Col>
                                                </td>
                                                <td className="w-3">
                                                  <Col
                                                    xs={12}
                                                    className="pd-l-2 pd-r-0 d-flex"
                                                  >
                                                    <InputGroup className="mt-0">
                                                      <div className="input-group-prepend prefix-dollar">
                                                        <Form.Control
                                                          onKeyPress={
                                                            global
                                                              .onKeyPressEvent
                                                              .floatValidation
                                                          }
                                                          name="weight_per_type"
                                                          value={
                                                            data.weight_per_type
                                                              ? data.weight_per_type
                                                                  .replace(
                                                                    /\.00$/,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /[&#,+()$~%'":*?<>{}]/gi,
                                                                    ""
                                                                  ) || ""
                                                              : ""
                                                          }
                                                          disabled={true}
                                                          onChange={(event) =>
                                                            this.handleChange(
                                                              i,
                                                              event
                                                            )
                                                          }
                                                        />
                                                        <span className="input-group-text">
                                                          lbs
                                                        </span>
                                                      </div>
                                                    </InputGroup>
                                                  </Col>
                                                </td>

                                                <td className="w-2">
                                                  <Col
                                                    xs={12}
                                                    className="pd-l-2 pd-r-2 colmn3"
                                                  >
                                                    <InputGroup className="mt-0">
                                                      <div className="input-group-prepend prefix-dollar w-100">
                                                        <span className="input-group-text">
                                                          $
                                                        </span>
                                                        <Form.Control
                                                          name="one_time_fee_per_type"
                                                          onKeyPress={
                                                            global
                                                              .onKeyPressEvent
                                                              .numberValidation
                                                          }
                                                          value={
                                                            data.one_time_fee_per_type
                                                              ? data.one_time_fee_per_type
                                                                  .replace(
                                                                    /\.00$/,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /[&#,+()$~%'":*?<>{}]/gi,
                                                                    ""
                                                                  ) || ""
                                                              : ""
                                                          }
                                                          onChange={(event) =>
                                                            this.handleChange(
                                                              i,
                                                              event
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </InputGroup>
                                                  </Col>
                                                  {data.one_time_fee_per_type_error ? (
                                                    <div className="text-danger">
                                                      {
                                                        data.one_time_fee_per_type_error
                                                      }
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>
                                                <td className="w-1">
                                                  <Col
                                                    xs={12}
                                                    className="pd-l-2 pd-r-2 colmn1"
                                                  >
                                                    <InputGroup className="mt-0 m-b-1">
                                                      <Form.Control
                                                        name="pet_count"
                                                        maxLength="2"
                                                        onKeyPress={
                                                          global.onKeyPressEvent
                                                            .numberValidation
                                                        }
                                                        value={
                                                          data.pet_count
                                                            ? data.pet_count ||
                                                              ""
                                                            : ""
                                                        }
                                                        onChange={(event) =>
                                                          this.handleChange(
                                                            i,
                                                            event
                                                          )
                                                        }
                                                      />
                                                    </InputGroup>
                                                  </Col>
                                                  {data.pet_count_error ? (
                                                    <div className="text-danger">
                                                      {data.pet_count_error}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>
                                                <td className="w-3">
                                                  <Col
                                                    xs={12}
                                                    className="pd-l-2 pd-r-0 d-flex"
                                                  >
                                                    <InputGroup className="mt-0">
                                                      <div className="input-group-prepend prefix-dollar">
                                                        <Form.Control
                                                          onKeyPress={
                                                            global
                                                              .onKeyPressEvent
                                                              .floatValidation
                                                          }
                                                          name="pet_weight"
                                                          value={
                                                            data.pet_weight
                                                              ? data.pet_weight
                                                                  .replace(
                                                                    /\.00$/,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /[&#,+()$~%'":*?<>{}]/gi,
                                                                    ""
                                                                  ) || ""
                                                              : ""
                                                          }
                                                          onChange={(event) =>
                                                            this.handleChange(
                                                              i,
                                                              event
                                                            )
                                                          }
                                                        />
                                                        <span className="input-group-text">
                                                          lbs
                                                        </span>
                                                      </div>
                                                    </InputGroup>
                                                  </Col>
                                                  {data.pet_weight_error ? (
                                                    <div className="text-danger">
                                                      {data.pet_weight_error}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>
                                                <td>
                                                  <Col
                                                    xs={12}
                                                    className="pd-l-2 pd-r-0 d-flex align-items-center add-delete"
                                                  >
                                                    {this.state
                                                      .petPolicyTypeDataTotal ===
                                                      1 && i === 0 ? (
                                                      <React.Fragment>
                                                        {data.policy_slug ? (
                                                          <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                            <button
                                                              type="button"
                                                              onClick={() => {
                                                                this.handleRemoveFields(
                                                                  i
                                                                );

                                                                this.deletePetPolicy(
                                                                  data.policy_slug
                                                                );
                                                              }}
                                                              className={
                                                                "btn-danger-outline-x-small"
                                                              }
                                                            >
                                                              <img
                                                                src={deleteicon}
                                                                alt="Delete"
                                                              />
                                                            </button>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                        <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                          <button
                                                            onClick={
                                                              this
                                                                .handleAddFields
                                                            }
                                                            className="btn-success-outline-x-small"
                                                          >
                                                            <img
                                                              src={pluswIcon}
                                                              alt="Add"
                                                            />
                                                          </button>
                                                        </div>
                                                      </React.Fragment>
                                                    ) : i <
                                                      this.state
                                                        .petPolicyTypeDataTotal -
                                                        1 ? (
                                                      <React.Fragment>
                                                        <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                          <button
                                                            type="button"
                                                            onClick={() => {
                                                              this.handleRemoveFields(
                                                                i
                                                              );

                                                              this.deletePetPolicy(
                                                                data.policy_slug
                                                              );
                                                            }}
                                                            className={
                                                              "btn-danger-outline-x-small"
                                                            }
                                                          >
                                                            <img
                                                              src={deleteicon}
                                                              alt="Delete"
                                                            />
                                                          </button>
                                                        </div>
                                                      </React.Fragment>
                                                    ) : (
                                                      <React.Fragment>
                                                        {this.state
                                                          .petPolicyTypeDataTotal !==
                                                        0 ? (
                                                          <React.Fragment>
                                                            <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                              <button
                                                                type="button"
                                                                onClick={() => {
                                                                  this.handleRemoveFields(
                                                                    i
                                                                  );

                                                                  this.deletePetPolicy(
                                                                    data.policy_slug
                                                                  );
                                                                }}
                                                                className={
                                                                  "btn-danger-outline-x-small"
                                                                }
                                                              >
                                                                <img
                                                                  src={
                                                                    deleteicon
                                                                  }
                                                                  alt="Delete"
                                                                />
                                                              </button>
                                                            </div>
                                                          </React.Fragment>
                                                        ) : (
                                                          ""
                                                        )}
                                                        {i <
                                                        parseInt(
                                                          this.state
                                                            .petPolicyDataListTotal
                                                        ) -
                                                          1 ? (
                                                          <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                            <button
                                                              onClick={
                                                                this
                                                                  .handleAddFields
                                                              }
                                                              className="btn-success-outline-x-small"
                                                            >
                                                              <img
                                                                src={pluswIcon}
                                                                alt="Add"
                                                              />
                                                            </button>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </React.Fragment>
                                                    )}
                                                  </Col>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-30 pd-lg-b-20">
                                      Since the listing is not pet friendly, you
                                      cannot select pets in the reservation.
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                        <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0">
                          <h3 className="mg-l-0 mg-t-15 background-head">
                            Occupancy Tax Distribution
                          </h3>
                        </div>
                        <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                          <div className="table-responsive pd-lg-l-30 pd-lg-r-30 pd-xs-l-15">
                            <Table hover className="mg-b-0">
                              <thead>
                                <tr>
                                  <th className="w-30">Name</th>
                                  <th className="w-30">Tax Distribution</th>
                                  <th className="w-30">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.taxListTotal > 0 ||
                                this.state.taxOccupancyListTotal > 0 ? (
                                  this.state.slug !== undefined ? (
                                    <React.Fragment>
                                      {this.state.taxOccupancyListTotal > 0
                                        ? this.state.taxOccupancyList.map(
                                            (option, i) => {
                                              return (
                                                <tr key={i}>
                                                  <td>
                                                    {option.name
                                                      ? option.name
                                                      : ""}
                                                  </td>
                                                  <td>
                                                    {option.tax_distribution
                                                      ? option.tax_type_id === 1
                                                        ? "$" +
                                                          global.onKeyPressEvent.numberWithCommasForDecimal(
                                                            option.tax_distribution.replace(
                                                              /\.00$/,
                                                              ""
                                                            )
                                                          )
                                                        : option.tax_distribution.replace(
                                                            /\.00$/,
                                                            ""
                                                          ) + "%"
                                                      : ""}
                                                  </td>
                                                  <td>
                                                    {option.tax_type_id === 1
                                                      ? option.amount
                                                        ? "$" +
                                                          global.onKeyPressEvent.numberWithCommasForDecimal(
                                                            option.amount.replace(
                                                              /\.00$/,
                                                              ""
                                                            )
                                                          )
                                                        : ""
                                                      : option.amount
                                                      ? "$" +
                                                        global.onKeyPressEvent.numberWithCommasForDecimal(
                                                          (
                                                            ((parseFloat(
                                                              this.state
                                                                .total_nightly_fee
                                                                ? this.state
                                                                    .total_nightly_fee
                                                                : 0
                                                            ) +
                                                              parseFloat(
                                                                this.state
                                                                  .cleaning_fee
                                                                  ? this.state
                                                                      .cleaning_fee
                                                                  : 0
                                                              )) *
                                                              // +
                                                              // parseFloat(this.state.pet_policy_fee ? this.state.pet_policy_fee: 0) +
                                                              //  parseFloat(
                                                              //   this.state
                                                              //    .pet_fee
                                                              //   ? this.state
                                                              //       .pet_fee
                                                              //  : 0
                                                              //)
                                                              parseFloat(
                                                                option.tax_distribution
                                                              )) /
                                                            100
                                                          ).toFixed(2)
                                                        )
                                                      : ""}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        : this.state.taxList.map(
                                            (option, i) => {
                                              var aa = option.tax_type_name
                                                ? option.tax_type_name
                                                : option.tax_type
                                                ? option.tax_type.tax_type_name
                                                : "";
                                              return (
                                                <tr key={i}>
                                                  <td>
                                                    {option.property_level_tax_name
                                                      ? option.property_level_tax_name
                                                      : ""}
                                                  </td>

                                                  <td>
                                                    {option.property_amount
                                                      ? aa.includes("$")
                                                        ? "$" +
                                                          global.onKeyPressEvent.numberWithCommasForDecimal(
                                                            parseFloat(
                                                              option.property_amount
                                                            )
                                                              .toFixed(2)
                                                              .replace(
                                                                /\.00$/,
                                                                ""
                                                              )
                                                              .replace(
                                                                /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                                ""
                                                              )
                                                          )
                                                        : parseFloat(
                                                            option.property_amount
                                                          )
                                                            .toFixed(2)
                                                            .replace(
                                                              /\.00$/,
                                                              ""
                                                            )
                                                            .replace(
                                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                              ""
                                                            ) + "%"
                                                      : ""}
                                                  </td>
                                                  <td>
                                                    {(aa.includes("$")
                                                      ? "$" +
                                                        option.property_amount
                                                      : "$" +
                                                        (
                                                          ((parseFloat(
                                                            this.state
                                                              .total_nightly_fee
                                                              ? this.state
                                                                  .total_nightly_fee
                                                              : 0
                                                          ) +
                                                            parseFloat(
                                                              this.state
                                                                .cleaning_fee
                                                                ? this.state
                                                                    .cleaning_fee
                                                                : 0
                                                            )) *
                                                            parseFloat(
                                                              option.property_amount
                                                            )) /
                                                          100
                                                        ).toFixed(2)
                                                    ).replace(/\.00$/, "")}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      <tr>
                                        <td>Total Occupancy Taxes</td>
                                        <td></td>
                                        <td>
                                          {this.state.occupancy_taxes
                                            ? "$" +
                                              global.onKeyPressEvent.numberWithCommasForDecimal(
                                                parseFloat(
                                                  this.state.occupancy_taxes
                                                )
                                                  .toFixed(2)
                                                  .replace(/\.00$/, "")
                                              )
                                            : ""}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {this.state.taxList.map((option, i) => {
                                        var aa = option.tax_type_name
                                          ? option.tax_type_name
                                          : option.tax_type
                                          ? option.tax_type.tax_type_name
                                          : "";
                                        return (
                                          <tr key={i}>
                                            <td>
                                              {option.property_level_tax_name
                                                ? option.property_level_tax_name
                                                : ""}
                                            </td>
                                            <td>
                                              {option.property_amount
                                                ? aa.includes("$")
                                                  ? "$" +
                                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                                      parseFloat(
                                                        option.property_amount
                                                      )
                                                        .toFixed(2)
                                                        .replace(/\.00$/, "")
                                                        .replace(
                                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                          ""
                                                        )
                                                    )
                                                  : parseFloat(
                                                      option.property_amount
                                                    )
                                                      .toFixed(2)
                                                      .replace(/\.00$/, "")
                                                      .replace(
                                                        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                        ""
                                                      ) + "%"
                                                : ""}
                                            </td>
                                            <td>
                                              {(aa.includes("$")
                                                ? "$" + option.property_amount
                                                : "$" +
                                                  (
                                                    ((parseFloat(
                                                      this.state
                                                        .total_nightly_fee
                                                        ? this.state
                                                            .total_nightly_fee
                                                        : 0
                                                    ) +
                                                      parseFloat(
                                                        this.state.cleaning_fee
                                                          ? this.state
                                                              .cleaning_fee
                                                          : 0
                                                      )) *
                                                      parseFloat(
                                                        option.property_amount
                                                      )) /
                                                    100
                                                  ).toFixed(2)
                                              ).replace(/\.00$/, "")}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                      <tr>
                                        <td>Total Occupancy Taxes</td>
                                        <td></td>
                                        <td>
                                          {this.state.occupancy_taxes
                                            ? "$" +
                                              global.onKeyPressEvent.numberWithCommasForDecimal(
                                                parseFloat(
                                                  this.state.occupancy_taxes
                                                )
                                                  .toFixed(2)
                                                  .replace(/\.00$/, "")
                                              )
                                            : ""}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan="9" align="center">
                                      There are no occupancy taxes added in the
                                      system.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        {global.userPermissions.checkPermission(
                          "guests-pricing-data-edit"
                        ) && (
                          <>
                            <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-sm-r-15 pd-xs-r-0">
                              <h3 className="mg-l-0 mg-t-15 background-head">
                                Reservation Earnings
                              </h3>
                            </div>
                            <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-sm-r-15 pd-sm-l-15 pd-xs-r-0 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Guest Paid{" "}
                                  <span className="optional">
                                    (Total Nightly Rate + Cleaning Fee + Guest
                                    Service Fee + Occupancy Taxes){" "}
                                    {this.state.guest_paid ? (
                                      <>
                                        {" "}
                                        {this.state.total_nightly_fee
                                          ? "=  $" +
                                            this.state.total_nightly_fee +
                                            " + "
                                          : ""}{" "}
                                        {this.state.cleaning_fee
                                          ? "$" +
                                            this.state.cleaning_fee +
                                            " + "
                                          : ""}{" "}
                                        {this.state.guest_service_fee
                                          ? "$" +
                                            this.state.guest_service_fee +
                                            " + "
                                          : ""}{" "}
                                        {this.state.occupancy_taxes
                                          ? "$" + this.state.occupancy_taxes
                                          : ""}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {" = "}
                                  </span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="guest_paid"
                                    className="custom-disable"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={
                                      this.state.guest_paid
                                        ? this.state.guest_paid
                                        : "" || ""
                                    }
                                    // value={
                                    //   this.state.guest_paid
                                    //     ? this.state.guest_paid
                                    //         .replace(
                                    //           /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                    //           ""
                                    //         )
                                    //         .replace(/\.00$/, "")
                                    //     : "" || ""
                                    // }
                                    maxLength="10"
                                    disabled={true}
                                  />
                                </div>
                                {this.state.errors.guest_paid ? (
                                  <div className="text-danger">
                                    {this.state.errors.guest_paid}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-sm-r-15 pd-sm-l-15 pd-xs-r-0 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Resident Earnings
                                  <span className="optional">
                                    {this.state.resident_earning ? (
                                      <>
                                        {" "}
                                        (
                                        {this.state
                                          .total_nightly_rate_to_resident &&
                                        this.state.total_nightly_fee
                                          ? "Total Nightly Rate *" +
                                            this.state
                                              .total_nightly_rate_to_resident +
                                            "%"
                                          : ""}
                                        {this.state.cleaning_fee_to_resident &&
                                        this.state.cleaning_fee
                                          ? "+ Cleaning Fee *" +
                                            this.state
                                              .cleaning_fee_to_resident +
                                            "%"
                                          : ""}
                                        {this.state.channel_fee_to_resident &&
                                        this.state.host_service_fee
                                          ? " - Channel Fee *" +
                                            this.state.channel_fee_to_resident +
                                            "%"
                                          : ""}
                                        )
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.resident_earning ? (
                                      <>
                                        {" = "}
                                        {this.state
                                          .total_nightly_rate_to_resident &&
                                        this.state.total_nightly_fee
                                          ? "($" +
                                            this.state.total_nightly_fee +
                                            "* " +
                                            this.state
                                              .total_nightly_rate_to_resident +
                                            "%)"
                                          : ""}
                                        {this.state.cleaning_fee_to_resident &&
                                        this.state.cleaning_fee
                                          ? " + ($" +
                                            this.state.cleaning_fee +
                                            "* " +
                                            this.state
                                              .cleaning_fee_to_resident +
                                            "%)"
                                          : ""}
                                        {this.state.channel_fee_to_resident &&
                                        this.state.host_service_fee
                                          ? " - ($" +
                                            this.state.host_service_fee +
                                            "* " +
                                            this.state.channel_fee_to_resident +
                                            "%)"
                                          : ""}
                                        {" = "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="resident_earning"
                                    //className="custom-disable"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={
                                      this.state.resident_earning
                                        ? this.state.resident_earning
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                            .replace(/\.00$/, "")
                                        : "" || ""
                                    }
                                    maxLength="10"
                                    //disabled={true}
                                  />
                                </div>
                                {this.state.errors.resident_earning ? (
                                  <div className="text-danger">
                                    {this.state.errors.resident_earning}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-sm-r-15 pd-sm-l-15 pd-xs-r-0 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  OH Earnings{" "}
                                  {this.state.set_channel_name ===
                                  "Direct Booking" ? (
                                    <span className="optional">
                                      {this.state.oh_earning ? (
                                        <>
                                          {" "}
                                          (
                                          {this.state
                                            .total_nightly_rate_to_oh &&
                                          this.state.total_nightly_fee
                                            ? "(Total Nightly Rate *" +
                                              this.state
                                                .total_nightly_rate_to_oh +
                                              "%"
                                            : ""}
                                          {this.state.cleaning_fee_to_oh &&
                                          this.state.cleaning_fee
                                            ? " + Cleaning Fee *" +
                                              this.state.cleaning_fee_to_oh +
                                              "%"
                                            : ""}
                                          {this.state.channel_fee_to_oh &&
                                          this.state.host_service_fee
                                            ? " - Channel Fee *" +
                                              this.state.channel_fee_to_oh +
                                              "%"
                                            : ""}
                                          {this.state.occupancy_taxes
                                            ? " + Occupancy Taxes"
                                            : ""}
                                          {this.state.guest_service_fee
                                            ? " + Guest Service Fee"
                                            : ""}
                                          {this.state.payment_processing_fee
                                            ? " - Payment Processing Fee"
                                            : ""}
                                          )
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.oh_earning ? (
                                        <>
                                          {" = "}
                                          {this.state
                                            .total_nightly_rate_to_oh &&
                                          this.state.total_nightly_fee
                                            ? "($" +
                                              this.state.total_nightly_fee +
                                              "* " +
                                              this.state
                                                .total_nightly_rate_to_oh +
                                              "%)"
                                            : ""}
                                          {this.state.cleaning_fee_to_oh &&
                                          this.state.cleaning_fee
                                            ? " + ($" +
                                              this.state.cleaning_fee +
                                              "* " +
                                              this.state.cleaning_fee_to_oh +
                                              "%)"
                                            : ""}
                                          {this.state.channel_fee_to_oh &&
                                          this.state.host_service_fee
                                            ? " - ($" +
                                              this.state.host_service_fee +
                                              "* " +
                                              this.state.channel_fee_to_oh +
                                              "%)"
                                            : ""}
                                          {this.state.occupancy_taxes
                                            ? " + ($" +
                                              this.state.occupancy_taxes +
                                              ")"
                                            : ""}
                                          {this.state.guest_service_fee
                                            ? " + ($" +
                                              this.state.guest_service_fee +
                                              ")"
                                            : ""}
                                          {this.state.payment_processing_fee
                                            ? " - ($" +
                                              this.state
                                                .payment_processing_fee +
                                              ")"
                                            : ""}
                                          {" = "}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  ) : (
                                    <span className="optional">
                                      {this.state.oh_earning ? (
                                        <>
                                          {" "}
                                          (
                                          {this.state
                                            .total_nightly_rate_to_oh &&
                                          this.state.total_nightly_fee
                                            ? "(Total Nightly Rate *" +
                                              this.state
                                                .total_nightly_rate_to_oh +
                                              "%"
                                            : ""}
                                          {this.state.cleaning_fee_to_oh &&
                                          this.state.cleaning_fee
                                            ? " + Cleaning Fee *" +
                                              this.state.cleaning_fee_to_oh +
                                              "%"
                                            : ""}
                                          {this.state.channel_fee_to_oh &&
                                          this.state.host_service_fee
                                            ? " - Channel Fee *" +
                                              this.state.channel_fee_to_oh +
                                              "%"
                                            : ""}
                                          )
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.oh_earning ? (
                                        <>
                                          {" = "}
                                          {this.state
                                            .total_nightly_rate_to_oh &&
                                          this.state.total_nightly_fee
                                            ? "($" +
                                              this.state.total_nightly_fee +
                                              "* " +
                                              this.state
                                                .total_nightly_rate_to_oh +
                                              "%)"
                                            : ""}
                                          {this.state.cleaning_fee_to_oh &&
                                          this.state.cleaning_fee
                                            ? " + ($" +
                                              this.state.cleaning_fee +
                                              "* " +
                                              this.state.cleaning_fee_to_oh +
                                              "%)"
                                            : ""}
                                          {this.state.channel_fee_to_oh &&
                                          this.state.host_service_fee
                                            ? " - ($" +
                                              this.state.host_service_fee +
                                              "* " +
                                              this.state.channel_fee_to_oh +
                                              "%)"
                                            : ""}
                                          {" = "}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  )}
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="oh_earning"
                                    //className="custom-disable"
                                    value={
                                      this.state.oh_earning
                                        ? this.state.oh_earning
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                            .replace(/\.00$/, "")
                                        : "" || ""
                                    }
                                    maxLength="10"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    //disabled={true}
                                  />
                                </div>
                                {this.state.errors.oh_earning ? (
                                  <div className="text-danger">
                                    {this.state.errors.oh_earning}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-sm-r-15 pd-sm-l-15 pd-xs-r-0 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Property Earnings{" "}
                                  <span className="optional">
                                    {this.state.property_earning ? (
                                      <>
                                        {" "}
                                        (
                                        {this.state
                                          .total_nightly_rate_to_property &&
                                        this.state.total_nightly_fee
                                          ? "Total Nightly Rate *" +
                                            this.state
                                              .total_nightly_rate_to_property +
                                            "%"
                                          : ""}
                                        {this.state.cleaning_fee_to_property &&
                                        this.state.cleaning_fee
                                          ? " + Cleaning Fee *" +
                                            this.state
                                              .cleaning_fee_to_property +
                                            "%"
                                          : ""}
                                        {this.state.channel_fee_to_property &&
                                        this.state.host_service_fee
                                          ? " - Channel Fee *" +
                                            this.state.channel_fee_to_property +
                                            "%"
                                          : ""}
                                        )
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.property_earning ? (
                                      <>
                                        {" = "}
                                        {this.state
                                          .total_nightly_rate_to_property &&
                                        this.state.total_nightly_fee
                                          ? "($" +
                                            this.state.total_nightly_fee +
                                            "* " +
                                            this.state
                                              .total_nightly_rate_to_property +
                                            "%)"
                                          : ""}
                                        {this.state.cleaning_fee_to_property &&
                                        this.state.cleaning_fee
                                          ? " + ($" +
                                            this.state.cleaning_fee +
                                            "* " +
                                            this.state
                                              .cleaning_fee_to_property +
                                            "%)"
                                          : ""}
                                        {this.state.channel_fee_to_property &&
                                        this.state.host_service_fee
                                          ? " - ($" +
                                            this.state.host_service_fee +
                                            "* " +
                                            this.state.channel_fee_to_property +
                                            "%)"
                                          : ""}
                                        {" = "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="property_earning"
                                    value={
                                      this.state.property_earning
                                        ? this.state.property_earning
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                            .replace(/\.00$/, "")
                                        : "" || ""
                                    }
                                    maxLength="10"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                  />
                                </div>
                                {this.state.errors.property_earning ? (
                                  <div className="text-danger">
                                    {this.state.errors.property_earning}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Channel Earnings{" "}
                                  <span className="optional">
                                    {this.state.set_channel_name ===
                                    "Direct Booking"
                                      ? "(Guest Paid - Payment Processing Fee)"
                                      : "(Guest Service Fee + Occupancy Taxes + Channel Fee)"}
                                    {this.state.airbnb_earning ? (
                                      <>
                                        {" "}
                                        {" = "}
                                        {this.state.set_channel_name ===
                                        "Direct Booking" ? (
                                          <>
                                            {this.state.guest_paid
                                              ? "$" + this.state.guest_paid
                                              : ""}
                                            {this.state.payment_processing_fee
                                              ? " - $" +
                                                this.state
                                                  .payment_processing_fee
                                              : ""}
                                          </>
                                        ) : (
                                          <>
                                            {this.state.guest_service_fee
                                              ? "$" +
                                                this.state.guest_service_fee
                                              : ""}{" "}
                                            {this.state.occupancy_taxes
                                              ? " + $" +
                                                this.state.occupancy_taxes
                                              : ""}{" "}
                                            {this.state.host_service_fee
                                              ? " + $" +
                                                this.state.host_service_fee
                                              : ""}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {" = "}
                                  </span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="airbnb_earning"
                                    value={
                                      this.state.airbnb_earning
                                        ? this.state.airbnb_earning
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                            .replace(/\.00$/, "")
                                        : "" || ""
                                    }
                                    maxLength="10"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                  />
                                </div>
                                {this.state.errors.airbnb_earning ? (
                                  <div className="text-danger">
                                    {this.state.errors.airbnb_earning}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {/* <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0">
                          <h3 className="mg-l-0 mg-t-15 background-head">
                            Guest Reviews
                          </h3>
                        </div>
                        <div className="row align-items-top pd-r-15 pd-b-0 border-bottom-0">
                          <div className="row col-md-6 border-bottom-0 pd-lg-l-15 pd-xs-l-0">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-l-15 pd-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Cleanliness
                              </label>

                              <StarRatings
                                rating={this.state.cleanliness_rating}
                                starRatedColor="rgb(255,165,52)"
                                starHoverColor="rgb(255,165,52)"
                                numberOfStars={5}
                                starDimension="25px"
                                changeRating={this.changeCleanlinessRating}
                                name="cleanliness_rating"
                              />
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-l-15 pd-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Accuracy
                              </label>

                              <StarRatings
                                rating={this.state.accuracy_rating}
                                starRatedColor="rgb(255,165,52)"
                                starHoverColor="rgb(255,165,52)"
                                numberOfStars={5}
                                starDimension="25px"
                                changeRating={this.changeAccuracyRating}
                                name="accuracy_rating"
                              />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-l-15 pd-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Communication
                              </label>

                              <StarRatings
                                rating={this.state.communication_rating}
                                starRatedColor="rgb(255,165,52)"
                                starHoverColor="rgb(255,165,52)"
                                numberOfStars={5}
                                starDimension="25px"
                                changeRating={this.changeCommunicationRating}
                                name="communication_rating"
                              />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-l-15 pd-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Location
                              </label>

                              <StarRatings
                                rating={this.state.location_rating}
                                starRatedColor="rgb(255,165,52)"
                                starHoverColor="rgb(255,165,52)"
                                numberOfStars={5}
                                starDimension="25px"
                                changeRating={this.changeLocationRating}
                                name="location_rating"
                              />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-l-15 pd-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Check-in
                              </label>

                              <StarRatings
                                rating={this.state.check_in_rating}
                                starRatedColor="rgb(255,165,52)"
                                starHoverColor="rgb(255,165,52)"
                                numberOfStars={5}
                                starDimension="25px"
                                changeRating={this.changeCheckInRating}
                                name="check_in_rating"
                              />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-l-15 pd-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Value
                              </label>

                              <StarRatings
                                rating={this.state.value_rating}
                                starRatedColor="rgb(255,165,52)"
                                starHoverColor="rgb(255,165,52)"
                                numberOfStars={5}
                                starDimension="25px"
                                changeRating={this.changeValueRating}
                                name="value_rating"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-0 pd-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Comment
                              </label>
                              <Form.Control
                                type="text"
                                as="textarea"
                                rows={4}
                                onChange={this.changeHandler}
                                name="comment"
                                value={this.state.comment || ""}
                              />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-0 pd-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Show on Direct Booking
                              </label>
                              <Form.Check
                                name="is_publish"
                                type={"checkbox"}
                                id={`is_publish`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.is_publish}
                              />
                            </div>
                          </div>
                        </div> */}
                        {global.userPermissions.checkPermission(
                          "guests-pricing-data-edit"
                        ) && (
                          <>
                            <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-sm-r-15 pd-xs-r-0">
                              <h3 className="mg-l-0 mg-t-30 background-head">
                                Resolution Fees
                              </h3>
                            </div>
                            <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                              <div className="pd-lg-l-30 pd-lg-r-30 pd-sm-l-15 pd-sm-r-15 pd-xs-l-15  pet-f mg-b-15 pet w-100">
                                <div className="col-md-12 p-0 petFeeSection resolution_fee_tbl resolution-fees-new resolution_table_form">
                                  <table className="petForm ">
                                    <thead>
                                      <tr>
                                        <th className="w-30">
                                          <label className="mb-1 ">
                                            Resolution Item
                                          </label>
                                        </th>
                                        <th className="w-35">
                                          <label className="mb-1">Amount</label>
                                        </th>

                                        <th className="w-20">
                                          <label className="mb-1">OH % </label>
                                        </th>
                                        <th className="w-20">
                                          <label className="mb-1">
                                            Resident %
                                          </label>
                                        </th>
                                        <th className="w-20">
                                          <label className="mb-1">
                                            Property %
                                          </label>
                                        </th>
                                        <th className="w-35">
                                          <label className="mb-1">
                                            Payment Received
                                          </label>
                                        </th>
                                        <th className="w-90">
                                          <label className="mb-1">
                                            Payment Received Date
                                          </label>
                                        </th>
                                        <th className="w-40 text-right">
                                          <label className="mb-1">OH $ </label>
                                        </th>
                                        <th className="w-40 text-right">
                                          <label className="mb-1">
                                            Resident $
                                          </label>
                                        </th>
                                        <th className="w-40 text-right">
                                          <label className="mb-1">
                                            Property $
                                          </label>
                                        </th>
                                        <th className=""></th>
                                      </tr>
                                    </thead>
                                    <tbody className="table_cnt_top">
                                      {this.state.resolutionFeeData.map(
                                        (data, i) => {
                                          return (
                                            <tr key={i}>
                                              <td className="w-30">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-0 pd-r-1 colmn1"
                                                >
                                                  <InputGroup className="mt-0 m-b-1">
                                                    <Form.Control
                                                      name="resolution_item"
                                                      value={
                                                        data.resolution_item
                                                          ? data.resolution_item ||
                                                            ""
                                                          : ""
                                                      }
                                                      onChange={(event) =>
                                                        this.handleChangeResoultion(
                                                          i,
                                                          event
                                                        )
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td className="w-35">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-0 pd-r-2 colmn1"
                                                >
                                                  <InputGroup className="mt-0 m-b-1">
                                                    <div className="input-group-prepend prefix-dollar">
                                                      <span className="input-group-text">
                                                        $
                                                      </span>
                                                      <Form.Control
                                                        onKeyPress={
                                                          global.onKeyPressEvent
                                                            .floatDashValidation
                                                        }
                                                        name="resolution_amount"
                                                        value={
                                                          data.resolution_amount
                                                            ? data.resolution_amount
                                                                .replace(
                                                                  /\.00$/,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /[&#,+()$~%'":*?<>{}]/gi,
                                                                  ""
                                                                ) || ""
                                                            : ""
                                                        }
                                                        onChange={(event) =>
                                                          this.handleChangeResoultion(
                                                            i,
                                                            event
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </InputGroup>
                                                </Col>
                                              </td>

                                              <td className="w-20">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-0 pd-r-2 colmn1"
                                                >
                                                  <InputGroup className="mt-0 m-b-1">
                                                    <div className="input-group-prepend prefix-dollar percanteg-prefix">
                                                      <Form.Control
                                                        onKeyPress={
                                                          global.onKeyPressEvent
                                                            .floatValidation
                                                        }
                                                        name="to_oh"
                                                        maxLength="5"
                                                        max="100"
                                                        value={
                                                          data.to_oh
                                                            ? data.to_oh
                                                                .replace(
                                                                  /\.00$/,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /[&#,+()$~%'":*?<>{}]/gi,
                                                                  ""
                                                                ) || ""
                                                            : ""
                                                        }
                                                        onChange={(event) =>
                                                          this.handleChangeResoultion(
                                                            i,
                                                            event
                                                          )
                                                        }
                                                      />
                                                      <span className="input-group-text percanteg_symbl">
                                                        %
                                                      </span>
                                                    </div>
                                                  </InputGroup>
                                                </Col>
                                                {data.to_oh_error ? (
                                                  <div className="text-danger">
                                                    {data.to_oh_error}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                              <td className="w-20">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-0 pd-r-2 colmn1"
                                                >
                                                  {" "}
                                                  <InputGroup className="mt-0 m-b-1">
                                                    <div className="input-group-prepend prefix-dollar percanteg-prefix">
                                                      <Form.Control
                                                        onKeyPress={
                                                          global.onKeyPressEvent
                                                            .floatValidation
                                                        }
                                                        name="to_resident"
                                                        maxLength="5"
                                                        max="100"
                                                        value={
                                                          data.to_resident
                                                            ? data.to_resident
                                                                .replace(
                                                                  /\.00$/,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /[&#,+()$~%'":*?<>{}]/gi,
                                                                  ""
                                                                ) || ""
                                                            : ""
                                                        }
                                                        onChange={(event) =>
                                                          this.handleChangeResoultion(
                                                            i,
                                                            event
                                                          )
                                                        }
                                                      />
                                                      <span className="input-group-text percanteg_symbl">
                                                        %
                                                      </span>
                                                    </div>
                                                  </InputGroup>
                                                </Col>
                                                {data.to_resident_error ? (
                                                  <div className="text-danger">
                                                    {data.to_resident_error}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                              <td className="w-20">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-0 pd-r-2 colmn1"
                                                >
                                                  <InputGroup className="mt-0 m-b-1">
                                                    <div className="input-group-prepend prefix-dollar percanteg-prefix">
                                                      <Form.Control
                                                        onKeyPress={
                                                          global.onKeyPressEvent
                                                            .floatValidation
                                                        }
                                                        name="to_building"
                                                        maxLength="5"
                                                        max="100"
                                                        value={
                                                          data.to_building
                                                            ? data.to_building
                                                                .replace(
                                                                  /\.00$/,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /[&#,+()$~%'":*?<>{}]/gi,
                                                                  ""
                                                                ) || ""
                                                            : ""
                                                        }
                                                        onChange={(event) =>
                                                          this.handleChangeResoultion(
                                                            i,
                                                            event
                                                          )
                                                        }
                                                      />
                                                      <span className="input-group-text percanteg_symbl">
                                                        %
                                                      </span>
                                                    </div>
                                                  </InputGroup>
                                                </Col>
                                                {data.to_building_error ? (
                                                  <div className="text-danger">
                                                    {data.to_building_error}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                              <td className="w-35 text-center">
                                                <Col
                                                  xs={12}
                                                  className=" colmn1 text-center"
                                                >
                                                  <Form.Check
                                                    name="payment_received"
                                                    type={"checkbox"}
                                                    id={`payment_received` + i}
                                                    label={""}
                                                    className={
                                                      "ckbox recv_paytm_info"
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangeResoultion(
                                                        i,
                                                        event
                                                      )
                                                    }
                                                    checked={
                                                      data.payment_received ===
                                                      1
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                </Col>
                                              </td>
                                              <td className="w-90">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-0 pd-r-5 colmn1"
                                                >
                                                  <InputGroup className="mt-0 m-b-1">
                                                    <Form.Control
                                                      name="payment_received_date"
                                                      max="100"
                                                      value={
                                                        data.payment_received_date
                                                          ? data.payment_received_date
                                                          : ""
                                                      }
                                                      placeholder="MM/DD/YY"
                                                      onChange={(event) =>
                                                        this.handleChangeResoultion(
                                                          i,
                                                          event
                                                        )
                                                      }
                                                    />
                                                  </InputGroup>
                                                  {/* <DatePicker
                                                    className="form-control max_width_100"
                                                    disabledKeyboardNavigation
                                                    strictParsing
                                                    scrollableYearDropdown
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    autoComplete="off"
                                                    yearDropdownItemNumber={
                                                      global.yearDropdownItemNumber
                                                    }
                                                    name="payment_received_date"
                                                    dateFormat="MM/dd/yy"
                                                    selected={
                                                      data.payment_received_date
                                                    }
                                                    onChange={(event) =>
                                                      this.datechnageHandle({
                                                        target: {
                                                          type: "date",
                                                          name: "payment_received_date",
                                                          value: event,
                                                          index: i,
                                                        },
                                                      })
                                                    }
                                                  /> */}
                                                </Col>
                                                {data.payment_received_date_error ? (
                                                  <div className="text-danger">
                                                    {
                                                      data.payment_received_date_error
                                                    }
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </td>

                                              <td className="w-40 text-right">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-0 pd-r-2 colmn1"
                                                >
                                                  {data.to_oh_amount ? (
                                                    <NumberFormat
                                                      value={data.to_oh_amount.replace(
                                                        /\.00$/,
                                                        ""
                                                      )}
                                                      displayType={"text"}
                                                      thousandSeparator={true}
                                                      prefix={"$"}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                  <InputGroup className="mt-0">
                                                    <input
                                                      type="hidden"
                                                      name="to_oh_amount"
                                                      value={
                                                        data.to_oh_amount
                                                          ? data.to_oh_amount.replace(
                                                              /\.00$/,
                                                              ""
                                                            ) || ""
                                                          : ""
                                                      }
                                                      onChange={(event) =>
                                                        this.handleChangeResoultion(
                                                          i,
                                                          event
                                                        )
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td className="w-40 text-right">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-0 pd-r-2 colmn1"
                                                >
                                                  {data.to_resident_amount ? (
                                                    <NumberFormat
                                                      value={data.to_resident_amount.replace(
                                                        /\.00$/,
                                                        ""
                                                      )}
                                                      displayType={"text"}
                                                      thousandSeparator={true}
                                                      prefix={"$"}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}

                                                  <InputGroup className="mt-0">
                                                    <input
                                                      type="hidden"
                                                      name="to_resident_amount"
                                                      value={
                                                        data.to_resident_amount
                                                          ? data.to_resident_amount.replace(
                                                              /\.00$/,
                                                              ""
                                                            ) || ""
                                                          : ""
                                                      }
                                                      onChange={(event) =>
                                                        this.handleChangeResoultion(
                                                          i,
                                                          event
                                                        )
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td className="w-40 text-right">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-0 pd-r-3 colmn1"
                                                >
                                                  {data.to_building_amount ? (
                                                    <NumberFormat
                                                      value={data.to_building_amount.replace(
                                                        /\.00$/,
                                                        ""
                                                      )}
                                                      displayType={"text"}
                                                      thousandSeparator={true}
                                                      prefix={"$"}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                  <InputGroup className="mt-0">
                                                    <input
                                                      type="hidden"
                                                      name="to_building_amount"
                                                      value={
                                                        data.to_building_amount
                                                          ? data.to_building_amount.replace(
                                                              /\.00$/,
                                                              ""
                                                            ) || ""
                                                          : ""
                                                      }
                                                      onChange={(event) =>
                                                        this.handleChangeResoultion(
                                                          i,
                                                          event
                                                        )
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td>
                                                <Col
                                                  xs={12}
                                                  className="pd-l-2 pd-r-0 d-flex align-items-center add-delete pd-t-5"
                                                >
                                                  {this.state
                                                    .resolutionFeeDataTotal ===
                                                    1 && i === 0 ? (
                                                    <React.Fragment>
                                                      {data.resolution_fee_slug ? (
                                                        <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                          <button
                                                            type="button"
                                                            onClick={() => {
                                                              this.handleRemoveResolutionFields(
                                                                i
                                                              );
                                                            }}
                                                            className={
                                                              "btn-danger-outline-x-small"
                                                            }
                                                          >
                                                            <img
                                                              src={deleteicon}
                                                              alt="Delete"
                                                            />
                                                          </button>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                        <button
                                                          onClick={
                                                            this
                                                              .handleAddResolutionFields
                                                          }
                                                          className="btn-success-outline-x-small"
                                                        >
                                                          <img
                                                            src={pluswIcon}
                                                            alt="Add"
                                                          />
                                                        </button>
                                                      </div>
                                                    </React.Fragment>
                                                  ) : i <
                                                    this.state
                                                      .resolutionFeeDataTotal -
                                                      1 ? (
                                                    <React.Fragment>
                                                      <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                        <button
                                                          type="button"
                                                          onClick={() => {
                                                            this.handleRemoveResolutionFields(
                                                              i
                                                            );
                                                          }}
                                                          className={
                                                            "btn-danger-outline-x-small"
                                                          }
                                                        >
                                                          <img
                                                            src={deleteicon}
                                                            alt="Delete"
                                                          />
                                                        </button>
                                                      </div>
                                                    </React.Fragment>
                                                  ) : (
                                                    <React.Fragment>
                                                      {this.state
                                                        .resolutionFeeDataTotal !==
                                                      0 ? (
                                                        <React.Fragment>
                                                          <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                            <button
                                                              type="button"
                                                              onClick={() => {
                                                                this.handleRemoveResolutionFields(
                                                                  i
                                                                );
                                                              }}
                                                              className={
                                                                "btn-danger-outline-x-small"
                                                              }
                                                            >
                                                              <img
                                                                src={deleteicon}
                                                                alt="Delete"
                                                              />
                                                            </button>
                                                          </div>
                                                        </React.Fragment>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {
                                                        //i < parseInt(this.state.resolutionFeeDataTotal) - 1 ? (
                                                        <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                          <button
                                                            onClick={
                                                              this
                                                                .handleAddResolutionFields
                                                            }
                                                            className="btn-success-outline-x-small"
                                                          >
                                                            <img
                                                              src={pluswIcon}
                                                              alt="Add"
                                                            />
                                                          </button>
                                                        </div>
                                                        //) : ""
                                                      }
                                                    </React.Fragment>
                                                  )}
                                                </Col>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                      {this.state.total_resolution_fee ? (
                                        <tr className="reasolution_final_pay">
                                          <td>
                                            <b>Total</b>
                                          </td>

                                          <td>
                                            <b>
                                              {this.state
                                                .total_resolution_fee ? (
                                                <NumberFormat
                                                  value={parseFloat(
                                                    this.state
                                                      .total_resolution_fee
                                                  )
                                                    .toFixed(2)
                                                    .replace(/\.00$/, "")
                                                    .replace(
                                                      /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                      ""
                                                    )}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  prefix={"$"}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </b>
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td className="text-right">
                                            <div className="row w-100">
                                              <div className="col-12 p-0">
                                                <b>
                                                  {this.state.to_oh_total ? (
                                                    <NumberFormat
                                                      value={parseFloat(
                                                        this.state.to_oh_total
                                                      )
                                                        .toFixed(2)
                                                        .replace(/\.00$/, "")
                                                        .replace(
                                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                          ""
                                                        )}
                                                      displayType={"text"}
                                                      thousandSeparator={true}
                                                      prefix={"$"}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </b>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="text-right">
                                            <div className="row w-100">
                                              <div className="col-12 p-0">
                                                <b>
                                                  {this.state
                                                    .to_resident_total ? (
                                                    <NumberFormat
                                                      value={parseFloat(
                                                        this.state
                                                          .to_resident_total
                                                      )
                                                        .toFixed(2)
                                                        .replace(/\.00$/, "")
                                                        .replace(
                                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                          ""
                                                        )}
                                                      displayType={"text"}
                                                      thousandSeparator={true}
                                                      prefix={"$"}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </b>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="text-right">
                                            <div className="row w-100">
                                              <div className="col-12 p-0 ">
                                                <b>
                                                  {this.state
                                                    .to_building_total ? (
                                                    <NumberFormat
                                                      value={parseFloat(
                                                        this.state
                                                          .to_building_total
                                                      )
                                                        .toFixed(2)
                                                        .replace(/\.00$/, "")
                                                        .replace(
                                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                          ""
                                                        )}
                                                      displayType={"text"}
                                                      thousandSeparator={true}
                                                      prefix={"$"}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </b>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {global.userPermissions.checkPermission(
                          "guests-pricing-data-edit"
                        ) && (
                          <>
                            <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-sm-r-15 pd-xs-r-0">
                              <h3 className="mg-l-0 mg-t-15 background-head total-payout">
                                Total Payout
                              </h3>
                            </div>
                            <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-sm-r-15 pd-sm-l-15 pd-xs-r-0 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Resident Payout
                                  <span className="optional">
                                    {(this.state.resident_earning_resolution ||
                                      this.state.to_resident_total) &&
                                    this.state.resident_earning_resolution >
                                      0 ? (
                                      <>
                                        {" "}
                                        (
                                        {this.state
                                          .total_nightly_rate_to_resident &&
                                        this.state.total_nightly_fee
                                          ? "Total Nightly Rate *" +
                                            this.state
                                              .total_nightly_rate_to_resident +
                                            "%"
                                          : ""}
                                        {this.state.cleaning_fee_to_resident &&
                                        this.state.cleaning_fee
                                          ? "+ Cleaning Fee *" +
                                            this.state
                                              .cleaning_fee_to_resident +
                                            "%"
                                          : ""}
                                        {this.state.channel_fee_to_resident &&
                                        this.state.host_service_fee
                                          ? " - Channel Fee *" +
                                            this.state.channel_fee_to_resident +
                                            "%"
                                          : ""}
                                        {this.state.to_resident_total
                                          ? " + Resident Resolution Fee"
                                          : ""}
                                        )
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.resident_earning_resolution &&
                                    this.state.resident_earning > 0 ? (
                                      <>
                                        {" = "}
                                        {this.state
                                          .total_nightly_rate_to_resident &&
                                        this.state.total_nightly_fee
                                          ? "($" +
                                            this.state.total_nightly_fee +
                                            "* " +
                                            this.state
                                              .total_nightly_rate_to_resident +
                                            "%)"
                                          : ""}
                                        {this.state.cleaning_fee_to_resident &&
                                        this.state.cleaning_fee
                                          ? " + ($" +
                                            this.state.cleaning_fee +
                                            "* " +
                                            this.state
                                              .cleaning_fee_to_resident +
                                            "%)"
                                          : ""}
                                        {this.state.channel_fee_to_resident &&
                                        this.state.host_service_fee
                                          ? " - ($" +
                                            this.state.host_service_fee +
                                            "* " +
                                            this.state.channel_fee_to_resident +
                                            "%)"
                                          : ""}
                                        {this.state.to_resident_total
                                          ? " + $" +
                                            this.state.to_resident_total
                                          : ""}
                                        {" = "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="resident_earning_resolution"
                                    className="custom-disable"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={
                                      this.state.resident_earning_resolution
                                        ? this.state.resident_earning_resolution
                                        : this.state.resident_earning || ""
                                    }
                                    maxLength="10"
                                    disabled={true}
                                  />
                                </div>
                                {this.state.errors
                                  .resident_earning_resolution ? (
                                  <div className="text-danger">
                                    {
                                      this.state.errors
                                        .resident_earning_resolution
                                    }
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-sm-r-15 pd-sm-l-15 pd-xs-r-0 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Property Payout{" "}
                                  <span className="optional">
                                    {(this.state.property_earning_resolution ||
                                      this.state.to_building_total) &&
                                    this.state.total_property_earning > 0 ? (
                                      <>
                                        {" "}
                                        (
                                        {this.state
                                          .total_nightly_rate_to_property &&
                                        this.state.total_nightly_fee
                                          ? "Total Nightly Rate *" +
                                            this.state
                                              .total_nightly_rate_to_property +
                                            "%"
                                          : ""}
                                        {this.state.cleaning_fee_to_property &&
                                        this.state.cleaning_fee
                                          ? " + Cleaning Fee *" +
                                            this.state
                                              .cleaning_fee_to_property +
                                            "%"
                                          : ""}
                                        {this.state.channel_fee_to_property &&
                                        this.state.host_service_fee
                                          ? " - Channel Fee *" +
                                            this.state.channel_fee_to_property +
                                            "%"
                                          : ""}
                                        {this.state.to_building_total
                                          ? " + Property Resolution Fee"
                                          : ""}
                                        )
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.property_earning_resolution &&
                                    this.state.total_property_earning > 0 ? (
                                      <>
                                        {" = "}
                                        {this.state
                                          .total_nightly_rate_to_property &&
                                        this.state.total_nightly_fee
                                          ? "($" +
                                            this.state.total_nightly_fee +
                                            "* " +
                                            this.state
                                              .total_nightly_rate_to_property +
                                            "%)"
                                          : ""}
                                        {this.state.cleaning_fee_to_property &&
                                        this.state.cleaning_fee
                                          ? " + ($" +
                                            this.state.cleaning_fee +
                                            "* " +
                                            this.state
                                              .cleaning_fee_to_property +
                                            "%)"
                                          : ""}
                                        {this.state.channel_fee_to_property &&
                                        this.state.host_service_fee
                                          ? " - ($" +
                                            this.state.host_service_fee +
                                            "* " +
                                            this.state.channel_fee_to_property +
                                            "%)"
                                          : ""}
                                        {this.state.to_building_total
                                          ? " + $" +
                                            this.state.to_building_total
                                          : ""}
                                        {" = "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </label>
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="property_earning_resolution"
                                    className="custom-disable"
                                    value={
                                      this.state.property_earning_resolution
                                        ? this.state.property_earning_resolution
                                        : this.state.property_earning || ""
                                    }
                                    maxLength="10"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    disabled={true}
                                  />
                                </div>
                                {this.state.errors
                                  .property_earning_resolution ? (
                                  <div className="text-danger">
                                    {
                                      this.state.errors
                                        .property_earning_resolution
                                    }
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default ReservationForm;
