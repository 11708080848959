//////////////////////////////////////////////////////////////
//     							                                        //
//  Program: UnifiedCalendar.jsx                            //
//  Application: Calendar                                   //
//  Option: List all reservations,resident stays,cleanings  //
//  Developer: NP  						                              //
//  Date: 2023-03-01                                        //
//                                                          //
//////////////////////////////////////////////////////////////

import React from "react";
import { useCallback, useState } from "react";
import CalendarService from "../../services/CalendarService";
import copyIcon from "./../../assets/images/copy.png";
import { Form } from "react-bootstrap";
import {
  Eventcalendar,
  setOptions,
  CalendarNav,
  SegmentedGroup,
  SegmentedItem,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
  Popup,
} from "@mobiscroll/react";
import { Link } from "react-router-dom";
import { Container, Popover, OverlayTrigger, Button } from "react-bootstrap";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
// import LisitingSidebar from "../common/LisitingSidebar";

setOptions({
  theme: "ios",
  themeVariant: "light",
  dateFormatLong: "MM/DD/YY",
});

const UnifiedCalendar = (props) => {
  const [view, setView] = React.useState("month");
  const [calView, setCalView] = React.useState({
    timeline: {
      type: "month",
      rowHeight: "equal",
    },
  });
  
  const [is_copied,setIsCopied]= useState(true);
  const [yearValue, setYearValue] = useState(0);
  const [event, setEvent] = useState({});
  const [events, myEvents] = useState([]);
  const [resources, myResources] = useState([]);
  const [availableDates, setAvailableDates] = React.useState([]);
  const [isPopupOpen, setOpen] = useState(false);
  const [isReservationData, setReservationData] = useState(true);
  const [isResidentStayData, setResidentStayData] = useState(true);
  const [isCleaningData, setCleaningData] = useState(true);
  const [isListAval, setListAval] = useState(false);
  const [isLeasingData, setLeasingData] = useState(true);
  const [availData, setAvailData] = useState([]);
  const [unavailData, setUnavailData] = useState([]);
  const [resData, setResData] = useState([]);
  const [cleanData, setCleanData] = useState([]);
  const [leaseData, setLeaseData] = useState([]);
  const [property_slug, setPropertySlug] = useState(
    props.match.params.propertySlug !== undefined
      ? props.match.params.propertySlug
      : localStorage.getItem("outSidePorpertySlug")
      ? localStorage.getItem("outSidePorpertySlug")
      : ""
  );
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  // const outSidePropertySlug = localStorage.getItem("outSidePorpertySlug") ? localStorage.getItem("outSidePorpertySlug") : ""

  const onPageLoading = React.useCallback(
    (event, inst) => {
      const year = event.month.getFullYear();
      const month = event.month.getMonth();

      if (props.match.params.propertySlug !== undefined) {
        setPropertySlug(props.match.params.propertySlug);
        if (yearValue === 1) {
          setTimeout(() => {
            getReservationdata(props.match.params.propertySlug, "", "");
          }, 200);
        } else {
          setTimeout(() => {
            getReservationdata(props.match.params.propertySlug, year, month);
          }, 200);
        }
      } else {
        const propSlug = localStorage.getItem("outSidePorpertySlug")
          ? localStorage.getItem("outSidePorpertySlug")
          : "";
        setPropertySlug(propSlug);
        if (yearValue === 1) {
          setTimeout(() => {
            getReservationdata(
              localStorage.getItem("outSidePorpertySlug")
                ? localStorage.getItem("outSidePorpertySlug")
                : "",
              "",
              ""
            );
          }, 200);
        } else {
          setTimeout(() => {
            getReservationdata(
              localStorage.getItem("outSidePorpertySlug")
                ? localStorage.getItem("outSidePorpertySlug")
                : "",
              year,
              month
            );
          }, 200);
        }
      }
    },
    [
      property_slug,
      year,
      month,
      isReservationData,
      isResidentStayData,
      isCleaningData,
      isListAval,
      isLeasingData,
      yearValue,
    ]
  );

  const getReservationdata = async (property_slug, year, month) => {
    if (yearValue === 1) {
      setYear("");
      setMonth("");
    } else {
      setYear(year);
      setMonth(month);
    }
    let res = await CalendarService.getReservations(
      "all?calendar=unified&year=" +
        year +
        "&month=" +
        (month + 1) +
        "&property_slug=" +
        (property_slug ? property_slug : "")
    );

    if (global.successStatus.includes(res.status)) {
      myResources(res.data.unitData ? res.data.unitData : []);
      if (isListAval) {
        setAvailableDates(
          res.data.availableDatesData ? res.data.availableDatesData : []
        );
      }
      if (
        isReservationData &&
        isResidentStayData &&
        isCleaningData &&
        isLeasingData
      ) {
        myEvents([
          ...res.data.reservationData,
          ...res.data.unavailableDatesData,
          ...res.data.checklistData,
          ...res.data.leasingData,
        ]);
      } else if (
        isReservationData &&
        isResidentStayData &&
        isCleaningData &&
        !isLeasingData
      ) {
        myEvents([
          ...res.data.reservationData,
          ...res.data.unavailableDatesData,
          ...res.data.checklistData,
        ]);
      } else if (
        isReservationData &&
        isResidentStayData &&
        !isCleaningData &&
        isLeasingData
      ) {
        myEvents([
          ...res.data.reservationData,
          ...res.data.unavailableDatesData,
          ...res.data.leasingData,
        ]);
      } else if (
        isReservationData &&
        isResidentStayData &&
        !isCleaningData &&
        !isLeasingData
      ) {
        myEvents([
          ...res.data.reservationData,
          ...res.data.unavailableDatesData,
        ]);
      } else if (
        isReservationData &&
        !isResidentStayData &&
        isCleaningData &&
        isLeasingData
      ) {
        myEvents([
          ...res.data.reservationData,
          ...res.data.checklistData,
          ...res.data.leasingData,
        ]);
      } else if (
        isReservationData &&
        !isResidentStayData &&
        isCleaningData &&
        !isLeasingData
      ) {
        myEvents([...res.data.reservationData, ...res.data.checklistData]);
      } else if (
        isReservationData &&
        !isResidentStayData &&
        !isCleaningData &&
        isLeasingData
      ) {
        myEvents([...res.data.reservationData, ...res.data.leasingData]);
      } else if (
        isReservationData &&
        !isResidentStayData &&
        !isCleaningData &&
        !isLeasingData
      ) {
        myEvents([...res.data.reservationData]);
      } else if (
        !isReservationData &&
        isResidentStayData &&
        isCleaningData &&
        isLeasingData
      ) {
        myEvents([
          ...res.data.unavailableDatesData,
          ...res.data.checklistData,
          ...res.data.leasingData,
        ]);
      } else if (
        !isReservationData &&
        isResidentStayData &&
        isCleaningData &&
        !isLeasingData
      ) {
        myEvents([...res.data.unavailableDatesData, ...res.data.checklistData]);
      } else if (
        !isReservationData &&
        isResidentStayData &&
        !isCleaningData &&
        isLeasingData
      ) {
        myEvents([...res.data.unavailableDatesData, ...res.data.leasingData]);
      } else if (
        !isReservationData &&
        isResidentStayData &&
        !isCleaningData &&
        !isLeasingData
      ) {
        myEvents([...res.data.unavailableDatesData]);
      } else if (
        !isReservationData &&
        !isResidentStayData &&
        isCleaningData &&
        isLeasingData
      ) {
        myEvents([...res.data.checklistData, ...res.data.leasingData]);
      } else if (
        !isReservationData &&
        !isResidentStayData &&
        isCleaningData &&
        !isLeasingData
      ) {
        myEvents([...res.data.checklistData]);
      } else if (
        !isReservationData &&
        !isResidentStayData &&
        !isCleaningData &&
        isLeasingData
      ) {
        myEvents([...res.data.leasingData]);
      } else {
        myEvents([]);
      }

      // myEvents([...res.data.reservationData, ...res.data.unavailableDatesData,...res.data.checklistData]);
      setLeaseData(res.data.leasingData ? res.data.leasingData : []);
      setAvailData(
        res.data.availableDatesData ? res.data.availableDatesData : []
      );
      setUnavailData(
        res.data.unavailableDatesData ? res.data.unavailableDatesData : []
      );
      setResData(res.data.reservationData ? res.data.reservationData : []);
      setCleanData(res.data.checklistData ? res.data.checklistData : []);
    }
  };

  //event pills html
  const myScheduleEvent = React.useCallback((data) => {
    const ev = data.original;
    const color = data.original.color;
    return (
      <>
        {/* reservation and their cleaning pill */}
        {ev.title && ev.title !== null && ev.reservation_slug ? (
          <div
            className="md-timeline-template-event"
            style={{ borderColor: color, background: color }}
          >
            <div className="md-timeline-template-event-cont md-meeting-planner-wrapper">
              <span className="md-timeline-template-title md-meeting-planner-title">
                {ev.title}
              </span>
            </div>
          </div>
        ) : // resident stay pill
        ev.title && ev.title !== null && ev.title === "Resident Stay" ? (
          <div
            className="md-timeline-template-event"
            style={{ borderColor: color, background: color }}
          >
            <div className="md-timeline-template-event-cont md-meeting-planner-wrapper">
              <span className="md-timeline-template-title md-meeting-planner-title">
                {ev.title}
              </span>
            </div>
          </div>
        ) : // resident stay cleaning pill
        ev.staff_name && ev.status ? (
          <div
            className="md-timeline-template-event"
            style={{ borderColor: color, background: color }}
          >
            <div className="md-timeline-template-event-cont md-meeting-planner-wrapper">
              <span className="md-timeline-template-title">
                {ev.staff_name + " - " + ev.status}
              </span>
            </div>
          </div>
        ) : // leasing pill
        ev.lease_slug ? (
          <div
            className="md-timeline-template-event"
            style={{ borderColor: color, background: color }}
          >
            <div className="md-timeline-template-event-cont md-meeting-planner-wrapper">
              <span className="md-timeline-template-title">{ev.title}</span>
            </div>
          </div>
        ) : (
          // pricing pill
          <div className="">
            <div className="">
              <span className="md-timeline-template-title">{ev.title}</span>
            </div>
          </div>
        )}
      </>
    );
  }, []);

  //open onclick of pill
  const openPopup = useCallback((event) => {
    if (event && event.event && event.event.title !== "") {
      setOpen(true);
      setEvent(event);
    }
  }, []);

  //close onclick of pill
  const closePopup = useCallback(() => {
    setOpen(false);
  }, []);

  const copyText = useCallback((confirmation_code) => {
    navigator.clipboard.writeText(confirmation_code);
    setTimeout(
      () =>
      setIsCopied(false),
      1000
    );
    setTimeout(
      () =>
      setIsCopied(true),
      1000
    );
  }, []);

  //Custom Resource on left
  const renderCustomResource = (resource) => {
    return (
      <div className="md-resource-header-template-cont" title={resource.name}>
        <div className="md-resource-header-template-name">
          {resource.unit_name_new ? resource.unit_name_new : resource.unit_name}
        </div>
        <div className="md-resource-header-template-name">
          {resource.resident_name}
        </div>
        <div className="md-resource-header-template-name">
          {resource.listing_name}
        </div>
      </div>
    );
  };

  //render header html
  const renderMyHeader = () => {
    return (
      <React.Fragment>
        <CalendarPrev className="md-event-listing-prev" />
        <CalendarNav className="md-event-listing-nav" />
        <CalendarNext className="md-event-listing-next" />
        <div className="md-event-listing-picker">
          <SegmentedGroup value={view} onChange={changeView}>
            <SegmentedItem value="day">Day</SegmentedItem>
            <SegmentedItem value="week">Week</SegmentedItem>
            <SegmentedItem value="month">Month</SegmentedItem>
            <SegmentedItem value="year">Year</SegmentedItem>
          </SegmentedGroup>
        </div>
        <div className="md-meeting-planner-zone md-meeting-planner-green">
          Reservations
        </div>
        <div className="md-meeting-planner-zone md-meeting-planner-red">
          Resident Stays
        </div>
        <div className="md-meeting-planner-zone md-meeting-planner-yellow">
          Cleanings
        </div>
        <div className="md-meeting-planner-zone md-meeting-planner-sky-blue">
          Leasings
        </div>
        <div className="md-meeting-planner-zone md-meeting-planner-blue">
          Listing Availability
        </div>
        <CalendarToday className="md-event-listing-today mr-4" />
      </React.Fragment>
    );
  };

  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "year":
        var a = document.getElementsByClassName("md-event-listing-today");
        if (a && a[0]) {
          a[0].click();
        }
        setYearValue(1);
        calView = {
          timeline: {
            type: "year",
            rowHeight: "equal",
          },
        };

        break;
      case "day":
        setYearValue(0);
        calView = {
          timeline: {
            type: "day",
            rowHeight: "equal",
          },
        };
        break;
      case "week":
        setYearValue(0);
        calView = {
          timeline: {
            type: "week",
            rowHeight: "equal",
          },
        };
        break;
      case "month":
      default:
        setYearValue(0);
        calView = {
          timeline: {
            type: "month",
            rowHeight: "equal",
          },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };

  const changeHandleCheck1 = async (evt) => {
    let value = evt.target.checked;
    setReservationData(value);

    if (value && isResidentStayData && isCleaningData && isLeasingData) {
      myEvents([...resData, ...unavailData, ...cleanData, ...leaseData]);
    } else if (
      value &&
      isResidentStayData &&
      isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...resData, ...unavailData, ...cleanData]);
    } else if (
      value &&
      isResidentStayData &&
      !isCleaningData &&
      isLeasingData
    ) {
      myEvents([...resData, ...unavailData, ...leaseData]);
    } else if (
      value &&
      isResidentStayData &&
      !isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...resData, ...unavailData]);
    } else if (
      value &&
      !isResidentStayData &&
      isCleaningData &&
      isLeasingData
    ) {
      myEvents([...resData, ...cleanData, ...leaseData]);
    } else if (
      value &&
      !isResidentStayData &&
      isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...resData, ...cleanData]);
    } else if (
      value &&
      !isResidentStayData &&
      !isCleaningData &&
      isLeasingData
    ) {
      myEvents([...resData, ...leaseData]);
    } else if (
      value &&
      !isResidentStayData &&
      !isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...resData]);
    } else if (
      !value &&
      isResidentStayData &&
      isCleaningData &&
      isLeasingData
    ) {
      myEvents([...unavailData, ...cleanData, ...leaseData]);
    } else if (
      !value &&
      isResidentStayData &&
      isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...unavailData, ...cleanData]);
    } else if (
      !value &&
      isResidentStayData &&
      !isCleaningData &&
      isLeasingData
    ) {
      myEvents([...unavailData, ...leaseData]);
    } else if (
      !value &&
      isResidentStayData &&
      !isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...unavailData]);
    } else if (
      !value &&
      !isResidentStayData &&
      isCleaningData &&
      isLeasingData
    ) {
      myEvents([...cleanData, ...leaseData]);
    } else if (
      !value &&
      !isResidentStayData &&
      isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...cleanData]);
    } else if (
      !value &&
      !isResidentStayData &&
      !isCleaningData &&
      isLeasingData
    ) {
      myEvents([...leaseData]);
    } else {
      myEvents([]);
    }
  };

  const changeHandleCheck2 = async (evt) => {
    let value = evt.target.checked;
    setResidentStayData(value);

    if (isReservationData && value && isCleaningData && isLeasingData) {
      myEvents([...resData, ...unavailData, ...cleanData, ...leaseData]);
    } else if (isReservationData && value && isCleaningData && !isLeasingData) {
      myEvents([...resData, ...unavailData, ...cleanData]);
    } else if (isReservationData && value && !isCleaningData && isLeasingData) {
      myEvents([...resData, ...unavailData, ...leaseData]);
    } else if (
      isReservationData &&
      value &&
      !isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...resData, ...unavailData]);
    } else if (isReservationData && !value && isCleaningData && isLeasingData) {
      myEvents([...resData, ...cleanData, ...leaseData]);
    } else if (
      isReservationData &&
      !value &&
      isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...resData, ...cleanData]);
    } else if (
      isReservationData &&
      !value &&
      !isCleaningData &&
      isLeasingData
    ) {
      myEvents([...resData, ...leaseData]);
    } else if (
      isReservationData &&
      !value &&
      !isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...resData]);
    } else if (!isReservationData && value && isCleaningData && isLeasingData) {
      myEvents([...unavailData, ...cleanData, ...leaseData]);
    } else if (
      !isReservationData &&
      value &&
      isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...unavailData, ...cleanData]);
    } else if (
      !isReservationData &&
      value &&
      !isCleaningData &&
      isLeasingData
    ) {
      myEvents([...unavailData, ...leaseData]);
    } else if (
      !isReservationData &&
      value &&
      !isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...unavailData]);
    } else if (
      !isReservationData &&
      !value &&
      isCleaningData &&
      isLeasingData
    ) {
      myEvents([...cleanData, ...leaseData]);
    } else if (
      !isReservationData &&
      !value &&
      isCleaningData &&
      !isLeasingData
    ) {
      myEvents([...cleanData]);
    } else if (
      !isReservationData &&
      !value &&
      !isCleaningData &&
      isLeasingData
    ) {
      myEvents([...leaseData]);
    } else {
      myEvents([]);
    }
  };

  const changeHandleCheck3 = async (evt) => {
    let value = evt.target.checked;
    setCleaningData(value);

    if (isReservationData && isResidentStayData && value && isLeasingData) {
      myEvents([...resData, ...unavailData, ...cleanData, ...leaseData]);
    } else if (
      isReservationData &&
      isResidentStayData &&
      value &&
      !isLeasingData
    ) {
      myEvents([...resData, ...unavailData, ...cleanData]);
    } else if (
      isReservationData &&
      isResidentStayData &&
      !value &&
      isLeasingData
    ) {
      myEvents([...resData, ...unavailData, ...leaseData]);
    } else if (
      isReservationData &&
      isResidentStayData &&
      !value &&
      !isLeasingData
    ) {
      myEvents([...resData, ...unavailData]);
    } else if (
      isReservationData &&
      !isResidentStayData &&
      value &&
      isLeasingData
    ) {
      myEvents([...resData, ...cleanData, ...leaseData]);
    } else if (
      isReservationData &&
      !isResidentStayData &&
      value &&
      !isLeasingData
    ) {
      myEvents([...resData, ...cleanData]);
    } else if (
      isReservationData &&
      !isResidentStayData &&
      !value &&
      isLeasingData
    ) {
      myEvents([...resData, ...leaseData]);
    } else if (
      isReservationData &&
      !isResidentStayData &&
      !value &&
      !isLeasingData
    ) {
      myEvents([...resData]);
    } else if (
      !isReservationData &&
      isResidentStayData &&
      value &&
      isLeasingData
    ) {
      myEvents([...unavailData, ...cleanData, ...leaseData]);
    } else if (
      !isReservationData &&
      isResidentStayData &&
      value &&
      !isLeasingData
    ) {
      myEvents([...unavailData, ...cleanData]);
    } else if (
      !isReservationData &&
      isResidentStayData &&
      !value &&
      isLeasingData
    ) {
      myEvents([...unavailData, ...leaseData]);
    } else if (
      !isReservationData &&
      isResidentStayData &&
      !value &&
      !isLeasingData
    ) {
      myEvents([...unavailData]);
    } else if (
      !isReservationData &&
      !isResidentStayData &&
      value &&
      isLeasingData
    ) {
      myEvents([...cleanData, ...leaseData]);
    } else if (
      !isReservationData &&
      !isResidentStayData &&
      value &&
      !isLeasingData
    ) {
      myEvents([...cleanData]);
    } else if (
      !isReservationData &&
      !isResidentStayData &&
      !value &&
      isLeasingData
    ) {
      myEvents([...leaseData]);
    } else {
      myEvents([]);
    }
  };

  const changeHandleCheck5 = async (evt) => {
    let value = evt.target.checked;
    setLeasingData(value);

    if (isReservationData && isResidentStayData && isCleaningData && value) {
      myEvents([...resData, ...unavailData, ...cleanData, ...leaseData]);
    } else if (
      isReservationData &&
      isResidentStayData &&
      isCleaningData &&
      !value
    ) {
      myEvents([...resData, ...unavailData, ...cleanData]);
    } else if (
      isReservationData &&
      isResidentStayData &&
      !isCleaningData &&
      value
    ) {
      myEvents([...resData, ...unavailData, ...leaseData]);
    } else if (
      isReservationData &&
      isResidentStayData &&
      !isCleaningData &&
      !value
    ) {
      myEvents([...resData, ...unavailData]);
    } else if (
      isReservationData &&
      !isResidentStayData &&
      isCleaningData &&
      value
    ) {
      myEvents([...resData, ...cleanData, ...leaseData]);
    } else if (
      isReservationData &&
      !isResidentStayData &&
      isCleaningData &&
      !value
    ) {
      myEvents([...resData, ...cleanData]);
    } else if (
      isReservationData &&
      !isResidentStayData &&
      !isCleaningData &&
      value
    ) {
      myEvents([...resData, ...leaseData]);
    } else if (
      isReservationData &&
      !isResidentStayData &&
      !isCleaningData &&
      !value
    ) {
      myEvents([...resData]);
    } else if (
      !isReservationData &&
      isResidentStayData &&
      isCleaningData &&
      value
    ) {
      myEvents([...unavailData, ...cleanData, ...leaseData]);
    } else if (
      !isReservationData &&
      isResidentStayData &&
      isCleaningData &&
      !value
    ) {
      myEvents([...unavailData, ...cleanData]);
    } else if (
      !isReservationData &&
      isResidentStayData &&
      !isCleaningData &&
      value
    ) {
      myEvents([...unavailData, ...leaseData]);
    } else if (
      !isReservationData &&
      isResidentStayData &&
      !isCleaningData &&
      !value
    ) {
      myEvents([...unavailData]);
    } else if (
      !isReservationData &&
      !isResidentStayData &&
      isCleaningData &&
      value
    ) {
      myEvents([...cleanData, ...leaseData]);
    } else if (
      !isReservationData &&
      !isResidentStayData &&
      isCleaningData &&
      !value
    ) {
      myEvents([...cleanData]);
    } else if (
      !isReservationData &&
      !isResidentStayData &&
      !isCleaningData &&
      value
    ) {
      myEvents([...leaseData]);
    } else {
      myEvents([]);
    }
  };

  const changeHandleCheck4 = async (evt) => {
    let value = evt.target.checked;
    setListAval(value);
    if (value) {
      setAvailableDates(availData);
    } else {
      setAvailableDates([]);
    }
  };

  const getPropertySlugData = async (property_slug) => {
    setPropertySlug(property_slug);
    getReservationdata(property_slug, year, month);
  };

  return (
    <>
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              {/* <div className="col-md-2 left">
                <div className="child-menu-section">
                  <LisitingSidebar property_slug={property_slug} />
                </div>
              </div> */}
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 unifiedtitle">
                    <h3 className="mb-0">Calendar</h3>
                  </div>

                  <div className="unifiedblock">
                    {window.location.pathname.includes(
                      "global-unified-calendar"
                    ) ? (
                      <div className="col-xxl-2 col-sm-3 pd-lg-r-30 pd-xs-r-0 pd-lg-l-0 pdd-left">
                        <OutSidePropertySelectBox
                          propertySlug={getPropertySlugData}
                          // outSidePropertySlug={outSidePropertySlug}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="d-flex justify-content-start flex-wrap pd-l-0 pd-r-0 mg-b-0">
                      <Form.Check
                        onChange={changeHandleCheck1}
                        checked={isReservationData}
                        type={"checkbox"}
                        id="isReservationData"
                        label={"Reservations"}
                        className={"ckbox mg-r-50"}
                      />
                      <Form.Check
                        onChange={changeHandleCheck2}
                        checked={isResidentStayData}
                        type={"checkbox"}
                        id="isResidentStayData"
                        label={"Resident Stays"}
                        className={"ckbox mg-r-50"}
                      />
                      <Form.Check
                        onChange={changeHandleCheck3}
                        checked={isCleaningData}
                        type={"checkbox"}
                        id="isCleaningData"
                        label={"Cleanings"}
                        className={"ckbox mg-r-50"}
                      />
                      <Form.Check
                        onChange={changeHandleCheck5}
                        checked={isLeasingData}
                        type={"checkbox"}
                        id="isLeasingData"
                        label={"Leasings"}
                        className={"ckbox mg-r-50"}
                      />
                      <Form.Check
                        onChange={changeHandleCheck4}
                        checked={isListAval}
                        type={"checkbox"}
                        id="isListAval"
                        label={"Listing Availability"}
                        className={"ckbox mg-r-50"}
                      />
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-45 pd-lg-r-30">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="calendar-section pd-lg-l-0 pd-xs-l-15 pd-t-0">
                      <Eventcalendar
                        theme="ios"
                        themeVariant="light"
                        clickToCreate={false}
                        dragToCreate={false}
                        dragToMove={false}
                        dragToResize={false}
                        view={calView}
                        data={events}
                        resources={resources}
                        renderScheduleEvent={myScheduleEvent}
                        cssClass="md-timeline-template md-event-listing md-timeline-height"
                        onEventClick={(event) => openPopup(event)}
                        showEventTooltip={false}
                        // onEventHoverIn={onEventHoverIn}
                        // onEventHoverOut={onEventHoverOut}
                        renderResource={renderCustomResource}
                        renderHeader={renderMyHeader}
                        colors={availableDates}
                        onPageLoading={onPageLoading}
                      />
                      <Popup
                        display="center"
                        // fullScreen={true}
                        contentPadding={true}
                        headerText={
                          event && event.event && event.event.title
                            ? event.event.title
                            : ""
                        }
                        isOpen={isPopupOpen}
                        onClose={closePopup}
                      >
                        <div className="text-center popup">
                          <span
                            className="popupClose hand-cursor"
                            onClick={closePopup}
                          >
                            {global.closee}
                          </span>
                          {event && event.event && event.event.slug ? (
                            <>
                              {/* resident stay popup start */}
                              <p className="az-profile-name-text pl-0">
                                Check-in date & time{" "}
                              </p>{" "}
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.view_start
                                  ? event.event.view_start
                                  : ""}
                              </p>
                              <p className="az-profile-name-text pl-0">
                                Check-out date & time{" "}
                              </p>
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.view_end
                                  ? event.event.view_end
                                  : ""}
                              </p>
                              <p className="az-profile-name-text pl-0">
                                Resident Access Code
                              </p>
                              <p className="media-body">
                                {" "}
                                {event &&
                                event.event &&
                                event.event.resident_access_code
                                  ? event.event.resident_access_code
                                  : ""}
                              </p>
                              <p className="az-profile-name-text pl-0">
                                Cleaner Access Code
                              </p>
                              <p className="media-body">
                                {" "}
                                {event &&
                                event.event &&
                                event.event.cleaner_access_code
                                  ? event.event.cleaner_access_code
                                  : ""}
                              </p>
                              {/* button for global menu */}
                              {event &&
                              event.event &&
                              event.event.listing_slug &&
                              window.location.pathname.includes(
                                "global-unified-calendar"
                              ) ? (
                                <Link
                                  target="_blank"
                                  to={
                                    "/listing/view/" +
                                    (property_slug
                                      ? property_slug
                                      : event &&
                                        event.event &&
                                        event.event.property_slug
                                      ? event.event.property_slug
                                      : "") +
                                    "/" +
                                    (event &&
                                    event.event &&
                                    event.event.listing_slug
                                      ? event.event.listing_slug +
                                        "/info?request_from=cleaning"
                                      : "")
                                  }
                                >
                                  <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                                    View
                                  </button>
                                </Link>
                              ) : event &&
                                event.event &&
                                event.event.listing_slug ? (
                                <>
                                  {/* button for regular menu */}
                                  <Link
                                    target="_blank"
                                    to={
                                      "/properties/view/" +
                                      (property_slug
                                        ? property_slug
                                        : event &&
                                          event.event &&
                                          event.event.property_slug
                                        ? event.event.property_slug
                                        : "") +
                                      "/listing/" +
                                      (event &&
                                      event.event &&
                                      event.event.listing_slug
                                        ? event.event.listing_slug +
                                          "/info?request_from=cleaning"
                                        : "")
                                    }
                                  >
                                    <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                                      View
                                    </button>
                                  </Link>
                                </>
                              ) : (
                                ""
                              )}
                              {/* resident stay popup end */}
                            </>
                          ) : event &&
                            event.event &&
                            event.event.status &&
                            event.event.staff_name ? (
                            <>
                              {/* cleaning popup start */}
                              <p className="az-profile-name-text pl-0">
                                Cleaning date & time{" "}
                              </p>{" "}
                              <p className="media-body">
                                {event && event.event && event.event.view_start
                                  ? event.event.view_start
                                  : ""}
                              </p>
                              {/* <p>Check-out date & time : {event && event.event && event.event.view_end ? event.event.view_end : ''}</p> */}
                              {event &&
                              event.event &&
                              event.event.guest_name ? (
                                <>
                                  <p className="az-profile-name-text pl-0">
                                    Guest name{" "}
                                  </p>{" "}
                                  <p className="media-body">
                                    {event &&
                                    event.event &&
                                    event.event.guest_name
                                      ? event.event.guest_name
                                      : ""}
                                  </p>{" "}
                                </>
                              ) : (
                                ""
                              )}
                              <p className="az-profile-name-text pl-0">
                                Staff name{" "}
                              </p>{" "}
                              <p className="media-body">
                                {event && event.event && event.event.staff_name
                                  ? event.event.staff_name
                                  : ""}
                              </p>
                              <p className="az-profile-name-text pl-0">
                                Status{" "}
                              </p>{" "}
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.status
                                  ? event.event.status
                                  : "Pending"}
                              </p>
                              {/* button for global menu */}
                              {event &&
                              event.event &&
                              event.event.reservation_slug &&
                              window.location.pathname.includes(
                                "global-unified-calendar"
                              ) ? (
                                <Link
                                  target="_blank"
                                  to={
                                    "/reservations/view/" +
                                    (property_slug
                                      ? property_slug
                                      : event &&
                                        event.event &&
                                        event.event.property_slug
                                      ? event.event.property_slug
                                      : "") +
                                    "/" +
                                    (event &&
                                    event.event &&
                                    event.event.reservation_slug
                                      ? event.event.reservation_slug
                                      : "")
                                  }
                                >
                                  <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                                    View
                                  </button>
                                </Link>
                              ) : event &&
                                event.event &&
                                event.event.reservation_slug ? (
                                <>
                                  {/* button for regular menu */}
                                  <Link
                                    target="_blank"
                                    to={
                                      "/properties/view/" +
                                      (property_slug
                                        ? property_slug
                                        : event &&
                                          event.event &&
                                          event.event.property_slug
                                        ? event.event.property_slug
                                        : "") +
                                      "/reservations/view/" +
                                      (event &&
                                      event.event &&
                                      event.event.reservation_slug
                                        ? event.event.reservation_slug
                                        : "")
                                    }
                                  >
                                    <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                                      View
                                    </button>
                                  </Link>
                                </>
                              ) : (
                                ""
                              )}
                              {/* cleaning popup end */}
                            </>
                          ) : event &&
                            event.event &&
                            event.event.reservation_slug ? (
                            <>
                              {/* reservation popup start */}
                              <div className="calendar_event_copy">
                                <p className="az-profile-name-text pl-0">
                                  Reservation code{" "}
                                </p>{" "}
                                <p className="media-body">
                                  {" "}
                                  {event &&
                                  event.event &&
                                  event.event.confirmation_code
                                    ? event.event.confirmation_code
                                    : " "}
                                    <span>
                                    {is_copied ? (
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="top"
                                        overlay={
                                          <Popover
                                            id="popover-basic"
                                            className="popover-primary copide_box"
                                          >
                                            <Popover.Title as="h3" className="p-0">
                                              Copied
                                            </Popover.Title>
                                          </Popover>
                                        }
                                      >
                                        <Button className="btn btn-copy_bg">
                                          <img
                                            src={copyIcon}
                                            alt="Copy"
                                            onClick={() => copyText(event.event.confirmation_code)}
                                            className="copyClass"
                                          />
                                        </Button>
                                      </OverlayTrigger>
                                    )
                                    : (
                                      <Button className="btn btn-copy_bg">
                                        <img
                                          src={copyIcon}
                                          alt="copy"
                                          className="copyClass"
                                          onClick={() => copyText(event.event.confirmation_code)}
                                          // onClick={() => {
                                          //   navigator.clipboard.writeText(
                                          //     event.event.confirmation_code
                                          //   );
                                          // }}
                                          
                                        />
                                      </Button>
                                    )}
                                    </span>
                                </p>{" "}
                              </div>
                              <p className="az-profile-name-text pl-0">
                                Check-in date & time{" "}
                              </p>{" "}
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.view_start
                                  ? event.event.view_start
                                  : ""}
                              </p>
                              <p className="az-profile-name-text pl-0">
                                Check-out date & time{" "}
                              </p>
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.view_end
                                  ? event.event.view_end
                                  : ""}
                              </p>
                              <p className="az-profile-name-text pl-0">
                                No. of guests
                              </p>{" "}
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.guest
                                  ? event.event.guest
                                  : ""}
                              </p>
                              {/* button for global menu */}
                              {event &&
                              event.event &&
                              event.event.reservation_slug &&
                              window.location.pathname.includes(
                                "global-unified-calendar"
                              ) ? (
                                <Link
                                  target="_blank"
                                  to={
                                    "/reservations/view/" +
                                    (property_slug
                                      ? property_slug
                                      : event &&
                                        event.event &&
                                        event.event.property_slug
                                      ? event.event.property_slug
                                      : "") +
                                    "/" +
                                    (event &&
                                    event.event &&
                                    event.event.reservation_slug
                                      ? event.event.reservation_slug
                                      : "")
                                  }
                                >
                                  <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                                    View
                                  </button>
                                </Link>
                              ) : event &&
                                event.event &&
                                event.event.reservation_slug ? (
                                <>
                                  {/* button for regular menu */}
                                  <Link
                                    target="_blank"
                                    to={
                                      "/properties/view/" +
                                      (property_slug
                                        ? property_slug
                                        : event &&
                                          event.event &&
                                          event.event.property_slug
                                        ? event.event.property_slug
                                        : "") +
                                      "/reservations/view/" +
                                      (event &&
                                      event.event &&
                                      event.event.reservation_slug
                                        ? event.event.reservation_slug
                                        : "")
                                    }
                                  >
                                    <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                                      View
                                    </button>
                                  </Link>
                                </>
                              ) : (
                                ""
                              )}
                              {/* reservation popup end */}
                            </>
                          ) : event && event.event && event.event.lease_slug ? (
                            <>
                              {/* leasing popup start */}
                              <p className="az-profile-name-text pl-0">
                                Check-in date & time{" "}
                              </p>{" "}
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.view_start
                                  ? event.event.view_start
                                  : ""}
                              </p>
                              <p className="az-profile-name-text pl-0">
                                Check-out date & time{" "}
                              </p>
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.view_end
                                  ? event.event.view_end
                                  : ""}
                              </p>
                              {/* button for global menu */}
                              {event &&
                              event.event &&
                              event.event.reservation_slug &&
                              window.location.pathname.includes(
                                "global-unified-calendar"
                              ) ? (
                                <Link
                                  target="_blank"
                                  to={
                                    "/leasing/view/" +
                                    (property_slug
                                      ? property_slug
                                      : event &&
                                        event.event &&
                                        event.event.property_slug
                                      ? event.event.property_slug
                                      : "") +
                                    (event &&
                                    event.event &&
                                    event.event.lease_slug
                                      ? event.event.lease_slug
                                      : "") +
                                    "/info"
                                  }
                                >
                                  <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                                    View
                                  </button>
                                </Link>
                              ) : event &&
                                event.event &&
                                event.event.reservation_slug ? (
                                <>
                                  {/* button for regular menu */}
                                  <Link
                                    target="_blank"
                                    to={
                                      "/properties/view/" +
                                      (property_slug
                                        ? property_slug
                                        : event &&
                                          event.event &&
                                          event.event.property_slug
                                        ? event.event.property_slug
                                        : "") +
                                      "/leasing/" +
                                      (event &&
                                      event.event &&
                                      event.event.lease_slug
                                        ? event.event.lease_slug
                                        : "") +
                                      "/info"
                                    }
                                  >
                                    <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                                      View
                                    </button>
                                  </Link>
                                </>
                              ) : (
                                ""
                              )}
                              {/* leasing popup end */}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </Popup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
};
export default UnifiedCalendar;
