////////////////////////////////////////////////////////////
//     							                          //
//  Program: VendorForm.jsx                               //
//  Application: Vendor                                   //
//  Option: to view all Property Vendors                  //
//  Developer: Ashish Kumar	,NP					                  //
//  Date: 2022-03-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, InputGroup, Form } from "react-bootstrap";
// import editIcon from "./../../assets/images/edit-pen-icon.svg";
import CountryService from "../../services/CountryService";
import CountyService from "../../services/CountyService";
import StateService from "../../services/StateService";
import AgencyCategoryService from "../../services/AgencyCategoryService";
import PropertyVendorService from "../../services/PropertyVendorService";
import CityService from "../../services/CityService";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import Select from "react-select";
// import { Link } from "react-router-dom";
import ContactSidebar from "../common/ContactSidebar";
import PhoneInput from 'react-phone-input-2'

export class VendorForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      eventKey: "",
      open: false,
      viewMode: 0,
      propertyAgencyList: [],
      propertyAgencyListTotal: 0,
      input: {},
      errors: {},
      //for all agency
      addOtherAgencyModal: false,
      editOtherAgencyModal: false,
      deleteOtherAgencyModal: false,
      set_custom_data: true,
      agencyList: [],
      newAgencyList: [],
      selectedAgencyList: [],
      agencyListTotal: 0,
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      countryListTotal: 0,
      countyListTotal: 0,
      stateListTotal: 0,
      cityListTotal: 0,
      confirm_back: 0,
      third_party_agency_slug: "",
      third_party_agency_name: "",
      street_1: "",
      street_2: "",
      country_slug: "",
      county_slug: "",
      state_slug: "",
      city_slug: "",
      zipcode: "",
      contact_name: "",
      phone_number: "",
      email: "",
      notes: "",
      agencyCategoryList: [],
      newAgencyCategoryList: [],
      selectedAgencyCategoryList: [],
      agencyCategoryListTotal: 0,
      agency_category_slug: "",
      allAgencyCategoryList: [],
      newAllAgencyCategoryList: [],
      selectedAllAgencyCategoryList: [],
      allAgencyCategoryListTotal: 0,
      categorySlug: "",
      selectedCostTypeList: [],
      status_id: true,
      pathname: window.location.pathname,
      slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug,
    };
    this.changeView = this.changeView.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    //for all agency

    this.opendeleteOtherAgencyModal =
      this.opendeleteOtherAgencyModal.bind(this);
    this.saveOrUpdateOtherAgency = this.saveOrUpdateOtherAgency.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);

    this.deleteOtherAgency = this.deleteOtherAgency.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }

  componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getAgencyDetails();
    this.getCityData();
    this.getAgencyCategoryList();
  }

  async getAgencyCategoryList(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var allAgencyCategoryList = [];
    var allAgencyCategoryListTotal = 0;

    let res = await AgencyCategoryService.getAgencyCategory(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      allAgencyCategoryList = res.data ? res.data : [];
      allAgencyCategoryListTotal = res.data ? res.data.length : 0;
    }
    const newAllAgencyCategoryList =
      allAgencyCategoryListTotal > 0
        ? allAgencyCategoryList.map(({ slug, agency_category_name }) => ({
          value: slug,
          label: agency_category_name,
        }))
        : [];
    this.setState({
      allAgencyCategoryList: allAgencyCategoryList,
      allAgencyCategoryListTotal: allAgencyCategoryListTotal,
      newAllAgencyCategoryList: newAllAgencyCategoryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getAllRealetedData(slug, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await CityService.getDataByCitySlug(
      slug,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.country_slug = res.data.countryData
        ? res.data.countryData.country.slug
        : "";
      resData.country_name = res.data.countryData
        ? res.data.countryData.country.country_name
        : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.countryData.country
        ? [
          {
            value: res.data.countryData.country
              ? res.data.countryData.country.slug
              : "",
            label: res.data.countryData.country
              ? res.data.countryData.country.country_name
              : "",
          },
        ]
        : "";
      resData.county_slug = res.data.county ? res.data.county.slug : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county
        ? [
          {
            value: res.data.county.slug ? res.data.county.slug : "",
            label: res.data.county.county_name
              ? res.data.county.county_name
              : "",
          },
        ]
        : "";
      resData.state_slug = res.data.state ? res.data.state.slug : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      var selectedStateList = [];
      selectedStateList = res.data.state
        ? [
          {
            value: res.data.state.slug ? res.data.state.slug : "",
            label: res.data.state.state_name ? res.data.state.state_name : "",
          },
        ]
        : "";
    }
    this.setState(resData);
    this.setState({
      selectedStateList: selectedStateList,
      selectedCountyList: selectedCountyList,
      selectedCountryList: selectedCountryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  // Custom validation rules
  customValidate() {
    let isValid = true;
    return isValid;
  }

  handleChangeAllAgencyCategoryList(value) {
    let agency_category_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        agency_category_slug.push(item.value);
      });
    }
    const agency_category_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedAllAgencyCategoryList: value,
      agency_category_slug: agency_category_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCityList(value) {
    let city_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_slug.push(item.value);
      });
    }
    if (value !== null) {
      this.getAllRealetedData(value.value);
    }
    const city_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCityList: value,
      city_slug: city_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeStateList(value) {
    let state_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_slug.push(item.value);
      });
    }
    const state_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedStateList: value,
      state_slug: state_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCountyList(value) {
    let county_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        county_slug.push(item.value);
      });
    }
    const county_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCountyList: value,
      county_slug: county_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCountryList(value) {
    let country_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_slug.push(item.value);
      });
    }
    const country_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCountryList: value,
      country_slug: country_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* to get country list data */
  async getCountryDataForActive(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countryList = [];
    var countryListTotal = 0;

    let res = await CountryService.getCountry(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
      countryListTotal = countryList ? countryList.length : 0;
    }
    const newCountryList =
      countryListTotal > 0
        ? countryList.map(({ slug, country_name }) => ({
          value: slug,
          label: country_name,
        }))
        : "";
    this.setState({
      countryList: countryList,
      countryListTotal: countryListTotal,
      newCountryList: newCountryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get county list data */
  async getCountyData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countyList = [];
    var countyListTotal = 0;

    let res = await CountyService.getCounty(
      "is_dropdown=1&state_slug=" + value,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
      countyListTotal = countyList ? countyList.length : 0;
    }
    const newCountyList =
      countyListTotal > 0
        ? countyList.map(({ slug, county_name }) => ({
          value: slug,
          label: county_name,
        }))
        : [];
    this.setState({
      countyList: countyList,
      countyListTotal: countyListTotal,
      newCountyList: newCountyList,
    });
    this.setState({ showSpinner: false, loading: false });
  }
  onChangePhone(value, country, e, formattedValue){
    //console.log(value, country, e, formattedValue);
    this.setState({phone_number:value});
    this.setState({dial_code:country.dialCode});
}
  /* to get state list data */
  async getStatesData(countryValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var stateList = [];
    var stateListTotal = 0;

    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" + countryValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
      stateListTotal = stateList ? stateList.length : 0;
    }
    const newStateList =
      stateListTotal > 0
        ? stateList.map(({ slug, state_name }) => ({
          value: slug,
          label: state_name,
        }))
        : "";
    this.setState({
      stateList: stateList,
      stateListTotal: stateListTotal,
      newStateList: newStateList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get city list data */
  async getCityData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;

    let res = await CityService.getCity(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList =
      cityListTotal > 0
        ? cityList.map(({ slug, city_name }) => ({
          value: slug,
          label: city_name,
        }))
        : [];
    this.setState({
      cityList: cityList,
      cityListTotal: cityListTotal,
      newCityList: newCityList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save ad agency data */
  saveOrUpdateOtherAgency = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug,
        third_party_agency_name: this.state.third_party_agency_name,
        // third_party_agency_slug: this.state.third_party_agency_slug,
        agency_category_slug: this.state.agency_category_slug,
        set_custom_data: this.state.set_custom_data === true ? 1 : 0,
        street_1: this.state.street_1,
        street_2: this.state.street_2 ? this.state.street_2 : "",
        city_slug: this.state.city_slug ? this.state.city_slug : 0,
        country_slug: this.state.country_slug ? this.state.country_slug : 0,
        county_slug: this.state.county_slug ? this.state.county_slug : 0,
        state_slug: this.state.state_slug ? this.state.state_slug : 0,
        zipcode: this.state.zipcode,
        contact_name: this.state.contact_name,
        phone_number: this.state.phone_number,
        dial_code: this.state.dial_code,
        email: this.state.email,
        notes: this.state.notes,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.slug !== undefined) {
        let res = await PropertyVendorService.updateOtherAgency(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                this.state.property_slug +
                "/vendor/first/" +
                this.state.slug
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      } else {
        let res = await PropertyVendorService.createOtherAgency(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/vendor"
              ),
            global.redirect_time
          );
        } else {
          //   let alertMessage = "";
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.result.errorDetail[0].errorMessage
          //     ? res.data.result.errorDetail[0].errorMessage
          //     : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.errorMessage,
          // });
        }
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get ad agency details data */
  async getAgencyDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await PropertyVendorService.getAgencyBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};

        resData.agency_category_slug = res.data.agency_category
          ? res.data.agency_category.slug
          : "";
        resData.street_1 = res.data.street_1 ? res.data.street_1 : "";
        resData.street_2 = res.data.street_2 ? res.data.street_2 : "";
        resData.city_slug = res.data.city_slug ? res.data.city_slug : "";
        resData.country_slug = res.data.country_slug
          ? res.data.country_slug
          : "";
        resData.county_slug = res.data.county_slug ? res.data.county_slug : "";
        resData.state_slug = res.data.state_slug ? res.data.state_slug : "";
        resData.zipcode = res.data.zipcode ? res.data.zipcode : "";
        resData.contact_name = res.data.contact_name
          ? res.data.contact_name
          : "";
        resData.phone_number = res.data.phone_number
          ? res.data.phone_number
          : "";
        resData.email = res.data.email ? res.data.email : "";
        resData.notes = res.data.notes ? res.data.notes : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        var selectedAllAgencyCategoryList = [];
        selectedAllAgencyCategoryList = res.data.agency_category
          ? [
            {
              value: res.data.agency_category.slug,
              label: res.data.agency_category.agency_category_name,
            },
          ]
          : "";
        var selectedCityList = [];
        selectedCityList = res.data.city
          ? [
            {
              value: res.data.city.slug,
              label: res.data.city.city_name,
            },
          ]
          : "";
        var selectedStateList = [];
        selectedStateList = res.data.state
          ? [
            {
              value: res.data.state.slug,
              label: res.data.state.state_name,
            },
          ]
          : "";
        var selectedCountyList = [];
        selectedCountyList = res.data.county
          ? [
            {
              value: res.data.county.slug,
              label: res.data.county.county_name,
            },
          ]
          : "";
        var selectedCountryList = [];
        selectedCountryList = res.data.country
          ? [
            {
              value: res.data.country.slug,
              label: res.data.country.country_name,
            },
          ]
          : "";
      }
      this.setState(resData);
      this.setState({
        selectedAllAgencyCategoryList: selectedAllAgencyCategoryList,
        selectedCityList: selectedCityList,
        selectedStateList: selectedStateList,
        selectedCountyList: selectedCountyList,
        selectedCountryList: selectedCountryList,
      });
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to delete ad agency data */
  async deleteOtherAgency(slug) {
    this.setState({ showSpinner: true });
    let res = await PropertyVendorService.deleteOtherAgency(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  opendeleteOtherAgencyModal(slug) {
    this.setState({ deleteOtherAgencyModal: true });
    this.setState({ slug: slug });
    this.setState({ confirm_back: 0 });
  }

  closedeleteOtherAgencyModal() {
    this.setState({ deleteOtherAgencyModal: false });
    this.setState({ slug: "" });
    this.setState({ confirm_back: 0 });
  }

  changeView() {
    var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
    }
  }

  getTitle() {
    return "Vendor";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <ContactSidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-30 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                this.state.property_slug +
                                "/vendor"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              "/properties/view/" +
                              this.state.property_slug +
                              "/vendor"
                            );
                        }}
                      >
                        Cancel
                      </button>
                      {global.userPermissions.checkPermission(
                        "vendors-update"
                      ) && (
                      <button
                        className="btn-success ml-3"
                        type="button"
                        onClick={this.saveOrUpdateOtherAgency}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                            ? "Save"
                            : "Save"}
                      </button> )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pl-0 pr-0">
                    <div className="col-md-12 pl-0 pr-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <form id="vendor_form">
                      <div className="m-0 pd-l-15 pd-r-15">
                        <div className="row align-items-baseline pd-t-30 pd-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Category Type
                            </label>

                            <InputGroup>
                              <div className="col-md-12 pl-0 pr-0">
                                <Select
                                  styles={customStyles}
                                  isClearable={true}
                                  className="multiselect"
                                  menuPlacement="auto"
                                  options={this.state.newAllAgencyCategoryList}
                                  getOptionValue={(option) => `${option.label}`}
                                  onChange={(value) =>
                                    this.handleChangeAllAgencyCategoryList(
                                      value
                                    )
                                  }
                                  value={
                                    this.state.selectedAllAgencyCategoryList
                                  }
                                  defaultValue={
                                    this.state.selectedAllAgencyCategoryList
                                  }
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />{" "}
                              </div>
                            </InputGroup>
                            {this.state.errors.agency_category_slug ? (
                              <div className="text-danger">
                                {this.state.errors.agency_category_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "agency_category",
                                this.state.agency_category_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Vendor Name
                            </label>

                            <InputGroup className="mt-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="third_party_agency_name"
                                value={this.state.third_party_agency_name || ""}
                                className="form-control max_width_100"
                                id="inlineFormInput"
                                type="text"
                                autoComplete="off"

                              // disabled={!this.state.set_custom_data ? "true" : ""}
                              />
                              {this.state.errors.third_party_agency_name ? (
                                <div className="text-danger">
                                  {this.state.errors.third_party_agency_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "third_party_agency_name",
                                  this.state.third_party_agency_name,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Contact Person Name{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <InputGroup className="mt-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="contact_name"
                                value={this.state.contact_name || ""}
                                className="form-control max_width_100"
                                id="inlineFormInput"
                                type="text"
                                maxLength="50"
                                autoComplete="off"

                              // disabled={!this.state.set_custom_data ? "true" : ""}
                              />
                              {this.state.errors.contact_name ? (
                                <div className="text-danger">
                                  {this.state.errors.contact_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "contact_name",
                                  this.state.contact_name,
                                  "max:50",
                                  {
                                    className: "text-danger",
                                  }
                                )
                              )}
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Phone{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <InputGroup className="mt-0">
                              <PhoneInput
                                country={global.country}
                                disableSearchIcon
                                // autoFormat= "true"
                                enableSearch= "true"
                                countryCodeEditable= "true"
                                value={this.state.phone_number}
                                onChange={this.onChangePhone}

                              />
                              {this.state.errors.phone_number ? (
                                <div className="text-danger">
                                  {this.state.errors.phone_number}
                                </div>
                              ) : (
                                ""
                              )}
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Email <span className="optional">(Optional)</span>
                            </label>

                            <InputGroup className="mt-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="email"
                                value={this.state.email || ""}
                                className="form-control max_width_100"
                                id="inlineFormInput"
                                type="text"
                                maxLength="50"
                                autoComplete="off"

                              // disabled={!this.state.set_custom_data ? "true" : ""}
                              />
                              {this.state.errors.email ? (
                                <div className="text-danger">
                                  {this.state.errors.email}
                                </div>
                              ) : (
                                this.validator.message(
                                  "email",
                                  this.state.email,
                                  "email",
                                  {
                                    className: "text-danger",
                                  }
                                )
                              )}
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Street 1{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <InputGroup className="mt-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="street_1"
                                value={this.state.street_1 || ""}
                                className="form-control max_width_100"
                                id="inlineFormInput"
                                type="text"
                                maxLength="50"
                                autoComplete="off"

                              // disabled={!this.state.set_custom_data ? "true" : ""}
                              />
                              {this.state.errors.street_1 ? (
                                <div className="text-danger">
                                  {this.state.errors.street_1}
                                </div>
                              ) : (
                                this.validator.message(
                                  "street_1",
                                  this.state.street_1,
                                  "max:100",
                                  { className: "text-danger" }
                                )
                              )}
                            </InputGroup>
                          </div>
                        </div>{" "}
                        {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div> */}
                        <div className="row align-items-center pd-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Street 2{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <InputGroup className="mt-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="street_2"
                                value={this.state.street_2 || ""}
                                className="form-control max_width_100"
                                id="inlineFormInput"
                                type="text"
                                maxLength="50"
                                autoComplete="off"

                              // disabled={!this.state.set_custom_data ? "true" : ""}
                              />
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              City <span className="optional">(Optional)</span>
                            </label>

                            <InputGroup className="mt-0">
                              <div className="col-md-12 pl-0 pr-0">
                                <Select
                                  styles={customStyles}
                                  isClearable={true}
                                  className="multiselect"
                                  menuPlacement="auto"
                                  value={this.state.selectedCityList}
                                  options={this.state.newCityList}
                                  getOptionValue={(option) => `${option.label}`}
                                  onChange={(value) =>
                                    this.handleChangeCityList(value)
                                  }
                                  defaultValue={this.state.selectedCityList}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />{" "}
                              </div>

                              {this.state.errors.city_slug ? (
                                <div className="text-danger">
                                  {this.state.errors.city_slug}
                                </div>
                              ) : (
                                this.validator.message(
                                  "city",
                                  this.state.city_slug,
                                  "max:50",
                                  {
                                    className: "text-danger",
                                  }
                                )
                              )}
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              State
                            </label>

                            <InputGroup className="mt-0">
                              <div className="col-md-12 pl-0 pr-0">
                                <Form.Control
                                  type="text"
                                  className="custom-disable"
                                  value={this.state.state_name || ""}
                                  disabled={true}
                                />
                              </div>

                              {this.state.errors.state_slug ? (
                                <div className="text-danger">
                                  {this.state.errors.state_slug}
                                </div>
                              ) : (
                                ""
                              )}
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Zipcode{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <InputGroup className="mt-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="zipcode"
                                onKeyPress={
                                  global.onKeyPressEvent.numberValidation
                                }
                                value={this.state.zipcode || ""}
                                className="form-control max_width_100"
                                id="inlineFormInput"
                                type="text"
                                maxLength="5"
                                autoComplete="off"
                              // disabled={!this.state.set_custom_data ? "true" : ""}
                              />
                              {this.state.errors.zipcode ? (
                                <div className="text-danger">
                                  {this.state.errors.zipcode}
                                </div>
                              ) : (
                                this.validator.message(
                                  "zipcode",
                                  this.state.zipcode,
                                  "max:5",
                                  {
                                    className: "text-danger",
                                  }
                                )
                              )}
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              County{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <InputGroup className="mt-0">
                              <div className="col-md-12 pl-0 pr-0">
                                <Select
                                  styles={customStyles}
                                  isClearable={true}
                                  className="multiselect"
                                  menuPlacement="auto"
                                  value={this.state.selectedCountyList}
                                  options={this.state.newCountyList.filter(
                                    (option) => {
                                      const val1 = this.state.county_slug
                                        ? this.state.county_slug
                                        : "";
                                      if (val1 === option.slug) return true;
                                    }
                                  )}
                                  getOptionValue={(option) => `${option.label}`}
                                  onChange={(value) =>
                                    this.handleChangeCountyList(value)
                                  }
                                  defaultValue={this.state.selectedCountyList}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />{" "}
                              </div>
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Country
                            </label>

                            <InputGroup className="mt-0">
                              <div className="col-md-12 p-0">
                                <Form.Control
                                  type="text"
                                  className="custom-disable"
                                  value={this.state.country_name || ""}
                                  disabled={true}
                                />
                              </div>

                              {this.state.errors.country_slug ? (
                                <div className="text-danger">
                                  {this.state.errors.country_slug}
                                </div>
                              ) : (
                                ""
                              )}
                            </InputGroup>
                          </div>
                        </div>{" "}
                        {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div> */}
                        <div className="row align-items-center pd-b-30 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Notes <span className="optional">(Optional)</span>
                            </label>

                            <InputGroup className="mt-0">
                              <div className="col-md-12 p-0">
                                <Form.Control
                                  onChange={this.changeHandler}
                                  name="notes"
                                  value={this.state.notes || ""}
                                  className="form-control max_width_100"
                                  rows="1"
                                  as="textarea"
                                  maxLength="1000"
                                  autoComplete="off"
                                />
                              </div>
                              {this.state.errors.notes ? (
                                <div className="text-danger">
                                  {this.state.errors.notes}
                                </div>
                              ) : (
                                this.validator.message(
                                  "notes",
                                  this.state.notes,
                                  "max:1000",
                                  {
                                    className: "text-danger",
                                  }
                                )
                              )}
                            </InputGroup>
                          </div>
                        </div>
                        {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div> */}
                        <div className="row align-items-center pd-b-30 pd-l-30 pd-r-30 border-bottom-0">
                          <div className="col-md-1 pl-0">
                            <label className="form-label text-left pd-b-5">
                              Status
                            </label>
                          </div>
                          <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                            <div
                              className={
                                this.state.status_id
                                  ? "az-toggle on"
                                  : "az-toggle"
                              }
                              onClick={() =>
                                this.setState({
                                  status_id: !this.state.status_id,
                                })
                              }
                            >
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default VendorForm;
