const AutomationModules = [
    {
        "label" : "Unit Availability",
        "value" : "unit_availability"
    },
    {
        "label" : "Unit Import",
        "value" : "unit_listing"
    },
    {
        "label" : "Current Leases",
        "value" : "leasing_listing"
    },
    {
        "label" : "Rent Collection",
        "value" : "unit_rent"
    }
];
const ReadIntervalTypes = [
    {
        "label" : "Minutes",
        "value" : "1",
        "interval_type": "1"
    },
    {
        "label" : "Hourly",
        "value" : "2",
        "interval_type": "2"
    },
    {
        "label" : "Daily",
        "value" : "3",
        "interval_type": "3"
    }
];
const ReadIntervalDurations = [
    {
        "label": "1 Minute",
        "value": 1,
        "interval_type": "1"
    },
    {
        "label": "2 Minutes",
        "value": 2,
        "interval_type": "1"
    },
    {
        "label": "3 Minutes",
        "value": 3,
        "interval_type": "1"
    },
    {
        "label": "4 Minutes",
        "value": 4,
        "interval_type": "1"
    },
    {
        "label": "5 Minutes",
        "value": 5,
        "interval_type": "1"
    },
    {
        "label": "6 Minutes",
        "value": 6,
        "interval_type": "1"
    },
    {
        "label": "7 Minutes",
        "value": 7,
        "interval_type": "1"
    },
    {
        "label": "8 Minutes",
        "value": 8,
        "interval_type": "1"
    },
    {
        "label": "9 Minutes",
        "value": 9,
        "interval_type": "1"
    },
    {
        "label": "10 Minutes",
        "value": 10,
        "interval_type": "1"
    },
    {
        "label": "11 Minutes",
        "value": 11,
        "interval_type": "1"
    },
    {
        "label": "12 Minutes",
        "value": 12,
        "interval_type": "1"
    },
    {
        "label": "13 Minutes",
        "value": 13,
        "interval_type": "1"
    },
    {
        "label": "14 Minutes",
        "value": 14,
        "interval_type": "1"
    },
    {
        "label": "15 Minutes",
        "value": 15,
        "interval_type": "1"
    },
    {
        "label": "16 Minutes",
        "value": 16,
        "interval_type": "1"
    },
    {
        "label": "17 Minutes",
        "value": 17,
        "interval_type": "1"
    },
    {
        "label": "18 Minutes",
        "value": 18,
        "interval_type": "1"
    },
    {
        "label": "19 Minutes",
        "value": 19,
        "interval_type": "1"
    },
    {
        "label": "20 Minutes",
        "value": 20,
        "interval_type": "1"
    },
    {
        "label": "21 Minutes",
        "value": 21,
        "interval_type": "1"
    },
    {
        "label": "22 Minutes",
        "value": 22,
        "interval_type": "1"
    },
    {
        "label": "23 Minutes",
        "value": 23,
        "interval_type": "1"
    },
    {
        "label": "24 Minutes",
        "value": 24,
        "interval_type": "1"
    },
    {
        "label": "25 Minutes",
        "value": 25,
        "interval_type": "1"
    },
    {
        "label": "26 Minutes",
        "value": 26,
        "interval_type": "1"
    },
    {
        "label": "27 Minutes",
        "value": 27,
        "interval_type": "1"
    },
    {
        "label": "28 Minutes",
        "value": 28,
        "interval_type": "1"
    },
    {
        "label": "29 Minutes",
        "value": 29,
        "interval_type": "1"
    },
    {
        "label": "30 Minutes",
        "value": 30,
        "interval_type": "1"
    },
    {
        "label": "31 Minutes",
        "value": 31,
        "interval_type": "1"
    },
    {
        "label": "32 Minutes",
        "value": 32,
        "interval_type": "1"
    },
    {
        "label": "33 Minutes",
        "value": 33,
        "interval_type": "1"
    },
    {
        "label": "34 Minutes",
        "value": 34,
        "interval_type": "1"
    },
    {
        "label": "35 Minutes",
        "value": 35,
        "interval_type": "1"
    },
    {
        "label": "36 Minutes",
        "value": 36,
        "interval_type": "1"
    },
    {
        "label": "37 Minutes",
        "value": 37,
        "interval_type": "1"
    },
    {
        "label": "38 Minutes",
        "value": 38,
        "interval_type": "1"
    },
    {
        "label": "39 Minutes",
        "value": 39,
        "interval_type": "1"
    },
    {
        "label": "40 Minutes",
        "value": 40,
        "interval_type": "1"
    },
    {
        "label": "41 Minutes",
        "value": 41,
        "interval_type": "1"
    },
    {
        "label": "42 Minutes",
        "value": 42,
        "interval_type": "1"
    },
    {
        "label": "43 Minutes",
        "value": 43,
        "interval_type": "1"
    },
    {
        "label": "44 Minutes",
        "value": 44,
        "interval_type": "1"
    },
    {
        "label": "45 Minutes",
        "value": 45,
        "interval_type": "1"
    },
    {
        "label": "46 Minutes",
        "value": 46,
        "interval_type": "1"
    },
    {
        "label": "47 Minutes",
        "value": 47,
        "interval_type": "1"
    },
    {
        "label": "48 Minutes",
        "value": 48,
        "interval_type": "1"
    },
    {
        "label": "49 Minutes",
        "value": 49,
        "interval_type": "1"
    },
    {
        "label": "50 Minutes",
        "value": 50,
        "interval_type": "1"
    },
    {
        "label": "51 Minutes",
        "value": 51,
        "interval_type": "1"
    },
    {
        "label": "52 Minutes",
        "value": 52,
        "interval_type": "1"
    },
    {
        "label": "53 Minutes",
        "value": 53,
        "interval_type": "1"
    },
    {
        "label": "54 Minutes",
        "value": 54,
        "interval_type": "1"
    },
    {
        "label": "55 Minutes",
        "value": 55,
        "interval_type": "1"
    },
    {
        "label": "56 Minutes",
        "value": 56,
        "interval_type": "1"
    },
    {
        "label": "57 Minutes",
        "value": 57,
        "interval_type": "1"
    },
    {
        "label": "58 Minutes",
        "value": 58,
        "interval_type": "1"
    },
    {
        "label": "59 Minutes",
        "value": 59,
        "interval_type": "1"
    },
    {
        "label": "60 Minutes",
        "value": 60,
        "interval_type": "1"
    },
    {
        "label": "1 Hours",
        "value": 1,
        "interval_type": "2"
    },
    {
        "label": "2 Hours",
        "value": 2,
        "interval_type": "2"
    },
    {
        "label": "3 Hours",
        "value": 3,
        "interval_type": "2"
    },
    {
        "label": "4 Hours",
        "value": 4,
        "interval_type": "2"
    },
    {
        "label": "5 Hours",
        "value": 5,
        "interval_type": "2"
    },
    {
        "label": "6 Hours",
        "value": 6,
        "interval_type": "2"
    },
    {
        "label": "7 Hours",
        "value": 7,
        "interval_type": "2"
    },
    {
        "label": "8 Hours",
        "value": 8,
        "interval_type": "2"
    },
    {
        "label": "9 Hours",
        "value": 9,
        "interval_type": "2"
    },
    {
        "label": "10 Hours",
        "value": 10,
        "interval_type": "2"
    },
    {
        "label": "11 Hours",
        "value": 11,
        "interval_type": "2"
    },
    {
        "label": "12 Hours",
        "value": 12,
        "interval_type": "2"
    },
    {
        "label": "13 Hours",
        "value": 13,
        "interval_type": "2"
    },
    {
        "label": "14 Hours",
        "value": 14,
        "interval_type": "2"
    },
    {
        "label": "15 Hours",
        "value": 15,
        "interval_type": "2"
    },
    {
        "label": "16 Hours",
        "value": 16,
        "interval_type": "2"
    },
    {
        "label": "17 Hours",
        "value": 17,
        "interval_type": "2"
    },
    {
        "label": "18 Hours",
        "value": 18,
        "interval_type": "2"
    },
    {
        "label": "19 Hours",
        "value": 19,
        "interval_type": "2"
    },
    {
        "label": "20 Hours",
        "value": 20,
        "interval_type": "2"
    },
    {
        "label": "21 Hours",
        "value": 21,
        "interval_type": "2"
    },
    {
        "label": "22 Hours",
        "value": 22,
        "interval_type": "2"
    },
    {
        "label": "23 Hours",
        "value": 23,
        "interval_type": "2"
    },
    {
        "label": "24 Hours",
        "value": 24,
        "interval_type": "2"
    },
    {
        "label": "12 AM",
        "value": '00',
        "interval_type": "3"
    },
    {
        "label": "1 AM",
        "value": '01',
        "interval_type": "3"
    },
    {
        "label": "2 AM",
        "value": '02',
        "interval_type": "3"
    },
    {
        "label": "3 AM",
        "value": '03',
        "interval_type": "3"
    },
    {
        "label": "4 AM",
        "value": '04',
        "interval_type": "3"
    },
    {
        "label": "5 AM",
        "value": '05',
        "interval_type": "3"
    },
    {
        "label": "6 AM",
        "value": '06',
        "interval_type": "3"
    },
    {
        "label": "7 AM",
        "value": '07',
        "interval_type": "3"
    },
    {
        "label": "8 AM",
        "value": '08',
        "interval_type": "3"
    },
    {
        "label": "9 AM",
        "value": '09',
        "interval_type": "3"
    },
    {
        "label": "10 AM",
        "value": '10',
        "interval_type": "3"
    },
    {
        "label": "11 AM",
        "value": 11,
        "interval_type": "3"
    },
    {
        "label": "12 PM",
        "value": '12',
        "interval_type": "3"
    },
    {
        "label": "1 PM",
        "value": '13',
        "interval_type": "3"
    },
    {
        "label": "2 PM",
        "value": '14',
        "interval_type": "3"
    },
    {
        "label": "3 PM",
        "value": '15',
        "interval_type": "3"
    },
    {
        "label": "4 PM",
        "value": '16',
        "interval_type": "3"
    },
    {
        "label": "5 PM",
        "value": '17',
        "interval_type": "3"
    },
    {
        "label": "6 PM",
        "value": '18',
        "interval_type": "3"
    },
    {
        "label": "7 PM",
        "value": '19',
        "interval_type": "3"
    },
    {
        "label": "8 PM",
        "value": '20',
        "interval_type": "3"
    },
    {
        "label": "9 PM",
        "value": '21',
        "interval_type": "3"
    },
    {
        "label": "10 PM",
        "value": '22',
        "interval_type": "3"
    },
    {
        "label": "11 PM",
        "value": '23',
        "interval_type": "3"
    }
];

const DatabaseCols = [
    /** Unit Availability */
    {
        "label" : "Property Name",
        "value" : "property",
        "module_name" : "unit_availability"
    },
    {
        "label" : "Unit Number",
        "value" : "unit_number",
        "module_name" : "unit_availability"
    },
    {
        "label" : "Available On",
        "value" : "available_from",
        "module_name" : "unit_availability"
    },
    {
        "label" : "Market Rent",
        "value" : "base_price",
        "module_name" : "unit_availability"
    },
    /** Unit Listing */
    {
        "label" : "Property Name",
        "value" : "property",
        "module_name" : "unit_listing"
    },
    {
        "label" : "Unit Number",
        "value" : "unit_number",
        "module_name" : "unit_listing"
    },
    {
        "label" : "Tower Name",
        "value" : "tower_id",
        "module_name" : "unit_listing"
    },
    {
        "label" : "Floor Name",
        "value" : "floor_id",
        "module_name" : "unit_listing"
    },
    {
        "label" : "Floor Plan",
        "value" : "floor_plan_id",
        "module_name" : "unit_listing"
    },
    {
        "label" : "Market Rent",
        "value" : "base_price",
        "module_name" : "unit_listing"
    },
    {
        "label" : "Deposit",
        "value" : "deposit",
        "module_name" : "unit_listing"
    },
    {
        "label" : "Affordable Type",
        "value" : "affordable_type_id",
        "module_name" : "unit_listing"
    },
    {
        "label" : "Furnished",
        "value" : "furnished",
        "module_name" : "unit_listing"
    },
    {
        "label" : "Available On",
        "value" : "available_from",
        "module_name" : "unit_listing"
    },

    /** Leasing Listing */
    {
        "label" : "Property Name",
        "value" : "property",
        "module_name" : "leasing_listing"
    },
    {
        "label" : "Unit Number",
        "value" : "unit_number",
        "module_name" : "leasing_listing"
    },
    {
        "label" : "Type",
        "value" : "type",
        "module_name" : "leasing_listing"
    },
    {
        "label" : "Lease Start Date",
        "value" : "lease_start_date",
        "module_name" : "leasing_listing"
    },
    {
        "label" : "Lease End Date",
        "value" : "lease_end_date",
        "module_name" : "leasing_listing"
    },
    {
        "label" : "Monthly Rent ($)",
        "value" : "monthly_rent",
        "module_name" : "leasing_listing"
    },
    {
        "label" : "Deposit",
        "value" : "deposit",
        "module_name" : "leasing_listing"
    },
    {
        "label" : "Roll Over",
        "value" : "roll_over",
        "module_name" : "leasing_listing"
    },

    {
        "label" : "First Name",
        "value" : "first_name",
        "module_name" : "leasing_listing"
    },
    {
        "label" : "Last Name",
        "value" : "last_name",
        "module_name" : "leasing_listing"
    },
    {
        "label" : "Email",
        "value" : "email",
        "module_name" : "leasing_listing"
    },
    {
        "label" : "Phone Number",
        "value" : "phone_number",
        "module_name" : "leasing_listing"
    },
    /* Unit Rent */
    {
        "label" : "Property Name",
        "value" : "property",
        "module_name" : "unit_rent"
    },
    {
        "label" : "Unit Number",
        "value" : "unit_number",
        "module_name" : "unit_rent"
    },
    {
        "label" : "Amount",
        "value" : "amount",
        "module_name" : "unit_rent"
    },
    {
        "label" : "Month",
        "value" : "month",
        "module_name" : "unit_rent"
    },
    {
        "label" : "Paid Date",
        "value" : "pay_date",
        "module_name" : "unit_rent"
    },
    {
        "label" : "Paid By",
        "value" : "paid_by",
        "module_name" : "unit_rent"
    },

];

const DatabaseColMapping = [
    /** Unit Availability */
    {
        "file_column" : "",
        "database_column" : "property",
        "module_name" : "unit_availability"
    },
    {
        "file_column" : "",
        "database_column" : "unit_number",
        "module_name" : "unit_availability"
    },
    {
        "file_column" : "",
        "database_column" : "available_from",
        "module_name" : "unit_availability"
    },
    {
        "file_column" : "",
        "database_column" : "base_price",
        "module_name" : "unit_availability"
    },
    
    /** Unit Listing */
    {
        "file_column" : "",
        "database_column" : "property",
        "module_name" : "unit_listing"
    },
    {
        "file_column" : "",
        "database_column" : "unit_number",
        "module_name" : "unit_listing"
    },
    {
        "file_column" : "",
        "database_column" : "tower_id",
        "module_name" : "unit_listing"
    },
    {
        "file_column" : "",
        "database_column" : "floor_id",
        "module_name" : "unit_listing"
    },
    {
        "file_column" : "",
        "database_column" : "floor_plan_id",
        "module_name" : "unit_listing"
    },
    {
        "file_column" : "",
        "database_column" : "base_price",
        "module_name" : "unit_listing"
    },
    {
        "file_column" : "",
        "database_column" : "deposit",
        "module_name" : "unit_listing"
    },
    {
        "file_column" : "",
        "database_column" : "affordable_type_id",
        "module_name" : "unit_listing"
    },
    {
        "file_column" : "",
        "database_column" : "furnished",
        "module_name" : "unit_listing"
    },
    {
        "file_column" : "",
        "database_column" : "available_from",
        "module_name" : "unit_listing"
    },

    /** Leasing Listing */
    {
        "file_column" : "",
        "database_column" : "property",
        "module_name" : "leasing_listing"
    },
    {
        "file_column" : "",
        "database_column" : "unit_number",
        "module_name" : "leasing_listing"
    },
    {
        "file_column" : "",
        "database_column" : "type",
        "module_name" : "leasing_listing"
    },
    {
        "file_column" : "",
        "database_column" : "lease_start_date",
        "module_name" : "leasing_listing"
    },
    {
        "file_column" : "",
        "database_column" : "lease_end_date",
        "module_name" : "leasing_listing"
    },
    {
        "file_column" : "",
        "database_column" : "monthly_rent",
        "module_name" : "leasing_listing"
    },
    {
        "file_column" : "",
        "database_column" : "deposit",
        "module_name" : "leasing_listing"
    },
    {
        "file_column" : "",
        "database_column" : "roll_over",
        "module_name" : "leasing_listing"
    },
    {
        "file_column" : "",
        "database_column" : "first_name",
        "module_name" : "leasing_listing"
    },
    {
        "file_column" : "",
        "database_column" : "last_name",
        "module_name" : "leasing_listing"
    },
    {
        "file_column" : "",
        "database_column" : "email",
        "module_name" : "leasing_listing"
    },
    {
        "file_column" : "",
        "database_column" : "phone_number",
        "module_name" : "leasing_listing"
    },
    /** Unit rent*/
    {
        "file_column" : "",
        "database_column" : "property",
        "module_name" : "unit_rent"
    },
    {
        "file_column" : "",
        "database_column" : "unit_number",
        "module_name" : "unit_rent"
    },
    {
        "file_column" : "",
        "database_column" : "amount",
        "module_name" : "unit_rent"
    },
    {
        "file_column" : "",
        "database_column" : "month",
        "module_name" : "unit_rent"
    },
    {
        "file_column" : "",
        "database_column" : "rent_pay_date",
        "module_name" : "unit_rent"
    },
    {
        "file_column" : "",
        "database_column" : "paid_by",
        "module_name" : "unit_rent"
    },
];

const EncriptionTypes = [
    {
        "label" : "SSL",
        "value" : "ssl"
    },
    {
        "label" : "TLS",
        "value" : "tls"
    }
];

const ExportAutomationModules = [
    {
        "label" : "Unit",
        "value" : "unit_listing"
    },
    {
        "label" : "Lease",
        "value" : "leasing_listing"
    },
    {
        "label" : "Reservations",
        "value" : "reservation_listing"
    }
];

const ExportReadIntervalTypes = [
    {
        "label" : "Daily",
        "value" : 1,
        "interval_type": 1
    },
    {
        "label" : "Weekly",
        "value" : 2,
        "interval_type": 2
    },
    {
        "label" : "Monthly",
        "value" : 3,
        "interval_type": 3
    },
    {
        "label" : "Manually",
        "value" : 4,
        "interval_type": 4
    }
];
const ExportReadIntervalDurations = [
    {
        "label": "12 AM",
        "value": 24,
        "interval_type": 1
    },
    {
        "label": "1 AM",
        "value": 1,
        "interval_type": 1
    },
    {
        "label": "2 AM",
        "value": 2,
        "interval_type": 1
    },
    {
        "label": "3 AM",
        "value": 3,
        "interval_type": 1
    },
    {
        "label": "4 AM",
        "value": 4,
        "interval_type": 1
    },
    {
        "label": "5 AM",
        "value": 5,
        "interval_type": 1
    },
    {
        "label": "6 AM",
        "value": 6,
        "interval_type": 1
    },
    {
        "label": "7 AM",
        "value": 7,
        "interval_type": 1
    },
    {
        "label": "8 AM",
        "value": 8,
        "interval_type": 1
    },
    {
        "label": "9 AM",
        "value": 9,
        "interval_type": 1
    },
    {
        "label": "10 AM",
        "value": 10,
        "interval_type": 1
    },
    {
        "label": "11 AM",
        "value": 11,
        "interval_type": 1
    },
    {
        "label": "12 PM",
        "value": 12,
        "interval_type": 1
    },
    {
        "label": "1 PM",
        "value": 13,
        "interval_type": 1
    },
    {
        "label": "2 PM",
        "value": 14,
        "interval_type": 1
    },
    {
        "label": "3 PM",
        "value": 15,
        "interval_type": 1
    },
    {
        "label": "4 PM",
        "value": 16,
        "interval_type": 1
    },
    {
        "label": "5 PM",
        "value": 17,
        "interval_type": 1
    },
    {
        "label": "6 PM",
        "value": 18,
        "interval_type": 1
    },
    {
        "label": "7 PM",
        "value": 19,
        "interval_type": 1
    },
    {
        "label": "8 PM",
        "value": 20,
        "interval_type": 1
    },
    {
        "label": "9 PM",
        "value": 21,
        "interval_type": 1
    },
    {
        "label": "10 PM",
        "value": 22,
        "interval_type": 1
    },
    {
        "label": "11 PM",
        "value": 23,
        "interval_type": 1
    },
    {
        "label": "12 AM",
        "value": 24,
        "interval_type": 2
    },
    {
        "label": "1 AM",
        "value": 1,
        "interval_type": 2
    },
    {
        "label": "2 AM",
        "value": 2,
        "interval_type": 2
    },
    {
        "label": "3 AM",
        "value": 3,
        "interval_type": 2
    },
    {
        "label": "4 AM",
        "value": 4,
        "interval_type": 2
    },
    {
        "label": "5 AM",
        "value": 5,
        "interval_type": 2
    },
    {
        "label": "6 AM",
        "value": 6,
        "interval_type": 2
    },
    {
        "label": "7 AM",
        "value": 7,
        "interval_type": 2
    },
    {
        "label": "8 AM",
        "value": 8,
        "interval_type": 2
    },
    {
        "label": "9 AM",
        "value": 9,
        "interval_type": 2
    },
    {
        "label": "10 AM",
        "value": 10,
        "interval_type": 2
    },
    {
        "label": "11 AM",
        "value": 11,
        "interval_type": 2
    },
    {
        "label": "12 PM",
        "value": 12,
        "interval_type": 2
    },
    {
        "label": "1 PM",
        "value": 13,
        "interval_type": 2
    },
    {
        "label": "2 PM",
        "value": 14,
        "interval_type": 2
    },
    {
        "label": "3 PM",
        "value": 15,
        "interval_type": 2
    },
    {
        "label": "4 PM",
        "value": 16,
        "interval_type": 2
    },
    {
        "label": "5 PM",
        "value": 17,
        "interval_type": 2
    },
    {
        "label": "6 PM",
        "value": 18,
        "interval_type": 2
    },
    {
        "label": "7 PM",
        "value": 19,
        "interval_type": 2
    },
    {
        "label": "8 PM",
        "value": 20,
        "interval_type": 2
    },
    {
        "label": "9 PM",
        "value": 21,
        "interval_type": 2
    },
    {
        "label": "10 PM",
        "value": 22,
        "interval_type": 2
    },
    {
        "label": "11 PM",
        "value": 23,
        "interval_type": 2
    },
    {
        "label": "12 AM",
        "value": 24,
        "interval_type": 3
    },
    {
        "label": "1 AM",
        "value": 1,
        "interval_type": 3
    },
    {
        "label": "2 AM",
        "value": 2,
        "interval_type": 3
    },
    {
        "label": "3 AM",
        "value": 3,
        "interval_type": 3
    },
    {
        "label": "4 AM",
        "value": 4,
        "interval_type": 3
    },
    {
        "label": "5 AM",
        "value": 5,
        "interval_type": 3
    },
    {
        "label": "6 AM",
        "value": 6,
        "interval_type": 3
    },
    {
        "label": "7 AM",
        "value": 7,
        "interval_type": 3
    },
    {
        "label": "8 AM",
        "value": 8,
        "interval_type": 3
    },
    {
        "label": "9 AM",
        "value": 9,
        "interval_type": 3
    },
    {
        "label": "10 AM",
        "value": 10,
        "interval_type": 3
    },
    {
        "label": "11 AM",
        "value": 11,
        "interval_type": 3
    },
    {
        "label": "12 PM",
        "value": 12,
        "interval_type": 3
    },
    {
        "label": "1 PM",
        "value": 13,
        "interval_type": 3
    },
    {
        "label": "2 PM",
        "value": 14,
        "interval_type": 3
    },
    {
        "label": "3 PM",
        "value": 15,
        "interval_type": 3
    },
    {
        "label": "4 PM",
        "value": 16,
        "interval_type": 3
    },
    {
        "label": "5 PM",
        "value": 17,
        "interval_type": 3
    },
    {
        "label": "6 PM",
        "value": 18,
        "interval_type": 3
    },
    {
        "label": "7 PM",
        "value": 19,
        "interval_type": 3
    },
    {
        "label": "8 PM",
        "value": 20,
        "interval_type": 3
    },
    {
        "label": "9 PM",
        "value": 21,
        "interval_type": 3
    },
    {
        "label": "10 PM",
        "value": 22,
        "interval_type": 3
    },
    {
        "label": "11 PM",
        "value": 23,
        "interval_type": 3
    },
];

const ExportWeeklyInterval = [
    {
        "label": "Sunday",
        "value": 1,
        "interval_type": 2
    },
    {
        "label": "Monday",
        "value": 2,
        "interval_type": 2
    },
    {
        "label": "Tuesday",
        "value": 3,
        "interval_type": 2
    },
    {
        "label": "Wednesday",
        "value": 4,
        "interval_type": 2
    },
    {
        "label": "Thursday",
        "value": 5,
        "interval_type": 2
    },
    {
        "label": "Friday",
        "value": 6,
        "interval_type": 2
    },
    {
        "label": "Saturday",
        "value": 7,
        "interval_type": 2
    },
]
const ExportMonthlyInterval = [
    {
        "label": "January",
        "value": 1,
        "interval_type": 3
    },
    {
        "label": "February",
        "value": 2,
        "interval_type": 3
    },
    {
        "label": "March",
        "value": 3,
        "interval_type": 3
    },
    {
        "label": "April",
        "value": 4,
        "interval_type": 3
    },
    {
        "label": "May",
        "value": 5,
        "interval_type": 3
    },
    {
        "label": "June",
        "value": 6,
        "interval_type": 3
    },
    {
        "label": "July",
        "value": 7,
        "interval_type": 3
    },
    {
        "label": "August",
        "value": 8,
        "interval_type": 3
    },
    {
        "label": "September",
        "value": 9,
        "interval_type": 3
    },
    {
        "label": "October",
        "value": 10,
        "interval_type": 3
    },
    {
        "label": "November",
        "value": 11,
        "interval_type": 3
    },
    {
        "label": "December",
        "value": 12,
        "interval_type": 3
    },
]
const ExportMonthlyDayInterval = [
    {
        "label": "01",
        "value": 1,
        "interval_type": 3
    },
    {
        "label": "02",
        "value": 2,
        "interval_type": 3
    },
    {
        "label": "03",
        "value": 3,
        "interval_type": 3
    },
    {
        "label": "04",
        "value": 4,
        "interval_type": 3
    },
    {
        "label": "05",
        "value": 5,
        "interval_type": 3
    },
    {
        "label": "06",
        "value": 6,
        "interval_type": 3
    },
    {
        "label": "07",
        "value": 7,
        "interval_type": 3
    },
    {
        "label": "08",
        "value": 8,
        "interval_type": 3
    },
    {
        "label": "09",
        "value": 9,
        "interval_type": 3
    },
    {
        "label": "10",
        "value": 10,
        "interval_type": 3
    },
    {
        "label": "11",
        "value": 11,
        "interval_type": 3
    },
    {
        "label": "12",
        "value": 12,
        "interval_type": 3
    },
    {
        "label": "13",
        "value": 13,
        "interval_type": 3
    },
    {
        "label": "14",
        "value": 14,
        "interval_type": 3
    },
    {
        "label": "15",
        "value": 15,
        "interval_type": 3
    },
    {
        "label": "16",
        "value": 16,
        "interval_type": 3
    },
    {
        "label": "17",
        "value": 17,
        "interval_type": 3
    },
    {
        "label": "18",
        "value": 18,
        "interval_type": 3
    },
    {
        "label": "19",
        "value": 19,
        "interval_type": 3
    },
    {
        "label": "20",
        "value": 20,
        "interval_type": 3
    },
    {
        "label": "21",
        "value": 21,
        "interval_type": 3
    },
    {
        "label": "22",
        "value": 22,
        "interval_type": 3
    },
    {
        "label": "23",
        "value": 23,
        "interval_type": 3
    },
    {
        "label": "24",
        "value": 24,
        "interval_type": 3
    },
    {
        "label": "25",
        "value": 25,
        "interval_type": 3
    },
    {
        "label": "26",
        "value": 26,
        "interval_type": 3
    },
    {
        "label": "27",
        "value": 27,
        "interval_type": 3
    },
    {
        "label": "28",
        "value": 28,
        "interval_type": 3
    },
    {
        "label": "29",
        "value": 29,
        "interval_type": 3
    },
    {
        "label": "30",
        "value": 30,
        "interval_type": 3
    },
    {
        "label": "31",
        "value": 31,
        "interval_type": 3
    },
]
const ExportSendType = [
    {
        "label" : "Send via Email",
        "value" : 1
    },
    /* {
        "label" : "Send via FTP",
        "value" : "2"
    }, */
]

const mockData = [
    /** Unit Availability */
    {
      "key": 1,
      "title": "Property Name",
      "value": "property_name",
      "module_name": "unit_listing"
    },
    {
      "key": 2,
      "title": "Unit Number",
      "value": "unit_id",
      "module_name": "unit_listing"
    },
    {
      "key": 3,
      "title": "Tower Name",
      "value": "tower_id",
      "module_name": "unit_listing"
    },
    {
      "key": 4,
      "title": "Floor Name",
      "value": "floor_id",
      "module_name": "unit_listing"
    },
    {
      "key": 5,
      "title": "Floor Plan",
      "value": "floor_plan_id",
      "module_name": "unit_listing"
    },
    {
        "key": 6,
        "title": "Affordable Type",
        "value": "affordable_type_id",
        "module_name": "unit_listing"
    },
    {
        "key": 7,
        "title": "Unit SKU",
        "value": "unit_code",
        "module_name": "unit_listing"
    },
    {
        "key": 8,
        "title": "Unit Description",
        "value": "unit_description",
        "module_name": "unit_listing"
    },
    {
        "key": 9,
        "title": "Airbnb Notes",
        "value": "airbnb_notes",
        "module_name": "unit_listing"
    },
    {
        "key": 10,
        "title": "Kitchen",
        "value": "kitchen",
        "module_name": "unit_listing"
    },
    {
        "key": 11,
        "title": "Furnished",
        "value": "furnished",
        "module_name": "unit_listing"
    },
    {
        "key": 12,
        "title": "Living Room",
        "value": "living_room",
        "module_name": "unit_listing"
    },
    {
        "key": 13,
        "title": "Renting Type",
        "value": "renting_type",
        "module_name": "unit_listing"
    },
    {
        "key": 14,
        "title": "Total Size",
        "value": "total_size",
        "module_name": "unit_listing"
    },
    {
        "key": 15,
        "title": "Rent",
        "value": "rent",
        "module_name": "unit_listing"
    },
    {
        "key": 16,
        "title": "Deposit",
        "value": "deposit",
        "module_name": "unit_listing"
    },
    {
        "key": 17,
        "title": "Maximum Guests",
        "value": "maximum_guests",
        "module_name": "unit_listing"
    },
    {
        "key": 18,
        "title": "Available",
        "value": "available",
        "module_name": "unit_listing"
    },

    /** Leasing Listing */
    /* {
      "key": 29,
      "title": "Lease Id",
      "value": "lease_id",
      "module_name": "leasing_listing"
    }, */
    {
      "key": 19,
      "title": "Unit Number",
      "value": "unit_id",
      "module_name": "leasing_listing"
    },
    {
      "key": 20,
      "title": "Property Name",
      "value": "property_id",
      "module_name": "leasing_listing"
    },
    {
      "key": 21,
      "title": "Type",
      "value": "type",
      "module_name": "leasing_listing"
    },
    {
      "key": 22,
      "title": "Roll Over",
      "value": "roll_over",
      "module_name": "leasing_listing"
    },
    {
      "key": 23,
      "title": "Lease Start Date",
      "value": "lease_start_date",
      "module_name": "leasing_listing"
    },
    {
      "key": 24,
      "title": "Lease End Date",
      "value": "lease_end_date",
      "module_name": "leasing_listing"
    },
    {
      "key": 25,
      "title": "Lease Sign Date",
      "value": "lease_signed_date",
      "module_name": "leasing_listing"
    },
  
    {
      "key": 26,
      "title": "Rent Start Date",
      "value": "rent_start_date",
      "module_name": "leasing_listing"
    },
    {
      "key": 27,
      "title": "Monthly Rent",
      "value": "monthly_rent",
      "module_name": "leasing_listing"
    },
    {
      "key": 28,
      "title": "Deposit",
      "value": "deposit",
      "module_name": "leasing_listing"
    },
    {
      "key": 29,
      "title": "Resident First Name",
      "value": "first_name",
      "module_name": "leasing_listing"
    },
    {
      "key": 30,
      "title": "Resident Last Name",
      "value": "last_name",
      "module_name": "leasing_listing"
    },
    {
        "key": 31,
        "title": "Resident Email",
        "value": "email",
        "module_name": "leasing_listing"
    },
    {
        "key": 32,
        "title": "Phone No",
        "value": "phone_number",
        "module_name": "leasing_listing"
    },
    {
        "key": 33,
        "title": "Street 1",
        "value": "street_1",
        "module_name": "leasing_listing"
    },
    {
        "key": 34,
        "title": "Street 2",
        "value": "street_2",
        "module_name": "leasing_listing"
    },
    {
        "key": 35,
        "title": "City",
        "value": "city",
        "module_name": "leasing_listing"
    },
    {
        "key": 36,
        "title": "State",
        "value": "state",
        "module_name": "leasing_listing"
    },
    {
        "key": 37,
        "title": "County",
        "value": "county",
        "module_name": "leasing_listing"
    },
    {
        "key": 38,
        "title": "Country",
        "value": "country",
        "module_name": "leasing_listing"
    },
    {
        "key": 39,
        "title": "Zip Code",
        "value": "zip_code",
        "module_name": "leasing_listing"
    },
    {
        "key": 40,
        "title": "Property Name",
        "value": "property_id",
        "module_name": "reservation_listing"
    },
    {
        "key": 41,
        "title": "Unit Number",
        "value": "unit_id",
        "module_name": "reservation_listing"
    },
    {
        "key": 42,
        "title": "Channel By",
        "value": "channel",
        "module_name": "reservation_listing"
    },
    {
        "key": 43,
        "title": "Listing Name",
        "value": "availability_id",
        "module_name": "reservation_listing"
    },
    {
        "key": 44,
        "title": "Check In Date",
        "value": "check_in_date",
        "module_name": "reservation_listing"
    },
    {
        "key": 45,
        "title": "Check Out Date",
        "value": "check_out_date",
        "module_name": "reservation_listing"
    },
    {
        "key": 46,
        "title": "Booked Date",
        "value": "booked_date",
        "module_name": "reservation_listing"
    },
    {
        "key": 47,
        "title": "Check In Time",
        "value": "check_in_time",
        "module_name": "reservation_listing"
    },
    {
        "key": 48,
        "title": "Check Out Time",
        "value": "check_out_time",
        "module_name": "reservation_listing"
    },
    {
        "key": 49,
        "title": "Total Nights",
        "value": "total_stay_nights",
        "module_name": "reservation_listing"
    },
    {
        "key": 50,
        "title": "Confirmation Code",
        "value": "confirmation_code",
        "module_name": "reservation_listing"
    },
    {
        "key": 51,
        "title": "Guest Paid",
        "value": "guest_paid",
        "module_name": "reservation_listing"
    },
    {
        "key": 52,
        "title": "Channel Earning",
        "value": "airbnb_earning",
        "module_name": "reservation_listing"
    },
    {
        "key": 53,
        "title": "Resident Earning",
        "value": "resident_earning",
        "module_name": "reservation_listing"
    },
    {
        "key": 87,
        "title": "Total Resident Earning",
        "value": "resident_earning_resolution",
        "module_name": "reservation_listing"
    },
    {
        "key": 54,
        "title": "Nightly Rate",
        "value": "nightly_rate",
        "module_name": "reservation_listing"
    },
    {
        "key": 55,
        "title": "Host Service Fee",
        "value": "host_service_fee",
        "module_name": "reservation_listing"
    },
    {
        "key": 56,
        "title": "Host Payout",
        "value": "host_payout",
        "module_name": "reservation_listing"
    },
    {
        "key": 57,
        "title": "Total Nightly Rate",
        "value": "total_nightly_rate",
        "module_name": "reservation_listing"
    },
    {
        "key": 58,
        "title": "Security Deposit",
        "value": "security_deposite",
        "module_name": "reservation_listing"
    },
    {
        "key": 59,
        "title": "Total Accommodation",
        "value": "total_accommodation",
        "module_name": "reservation_listing"
    },
    {
        "key": 60,
        "title": "Total Property Earning",
        "value": "total_property_earning",
        "module_name": "reservation_listing"
    },
    {
        "key": 61,
        "title": "Total OH Earning",
        "value": "total_oh_earning",
        "module_name": "reservation_listing"
    },
    {
        "key": 62,
        "title": "Total Rent",
        "value": "total_rent",
        "module_name": "reservation_listing"
    },
    {
        "key": 63,
        "title": "Total Tax Fees",
        "value": "total_tax_fees",
        "module_name": "reservation_listing"
    },
    {
        "key": 64,
        "title": "Total Fees",
        "value": "total_fees",
        "module_name": "reservation_listing"
    },
    {
        "key": 65,
        "title": "Total Amount",
        "value": "total_amount",
        "module_name": "reservation_listing"
    },
    {
        "key": 66,
        "title": "Cleaning Fee",
        "value": "cleaning_fee",
        "module_name": "reservation_listing"
    },
    {
        "key": 67,
        "title": "General Fee",
        "value": "general_fee",
        "module_name": "reservation_listing"
    },
    {
        "key": 68,
        "title": "Guest No",
        "value": "number_of_guest",
        "module_name": "reservation_listing"
    },
    {
        "key": 69,
        "title": "Adults",
        "value": "adults",
        "module_name": "reservation_listing"
    },
    {
        "key": 70,
        "title": "Children",
        "value": "children",
        "module_name": "reservation_listing"
    },
    {
        "key": 71,
        "title": "Pets",
        "value": "pets",
        "module_name": "reservation_listing"
    },
    {
        "key": 72,
        "title": "Guest Name",
        "value": "guest_id",
        "module_name": "reservation_listing"
    },
    {
        "key": 73,
        "title": "First Name",
        "value": "first_name",
        "module_name": "reservation_listing"
    },
    {
        "key": 74,
        "title": "Last Name",
        "value": "last_name",
        "module_name": "reservation_listing"
    },
    {
        "key": 75,
        "title": "Email",
        "value": "email",
        "module_name": "reservation_listing"
    },
    {
        "key": 76,
        "title": "Phone No",
        "value": "phone_number",
        "module_name": "reservation_listing"
    },
    {
        "key": 77,
        "title": "Zip Code",
        "value": "zip_code",
        "module_name": "reservation_listing"
    },
    {
        "key": 78,
        "title": "Passport No",
        "value": "passport_no",
        "module_name": "reservation_listing"
    },
    {
        "key": 79,
        "title": "Street 1",
        "value": "street_1",
        "module_name": "reservation_listing"
    },
    {
        "key": 80,
        "title": "Street 2",
        "value": "street_2",
        "module_name": "reservation_listing"
    },
    {
        "key": 81,
        "title": "Country Name",
        "value": "country",
        "module_name": "reservation_listing"
    },
    {
        "key": 82,
        "title": "County",
        "value": "county",
        "module_name": "reservation_listing"
    },
    {
        "key": 83,
        "title": "State",
        "value": "state",
        "module_name": "reservation_listing"
    },
    {
        "key": 84,
        "title": "City",
        "value": "city",
        "module_name": "reservation_listing"
    },
    {
        "key": 85,
        "title": "Payment Status",
        "value": "payment_status",
        "module_name": "reservation_listing"
    },
    {
        "key": 86,
        "title": "Status",
        "value": "status",
        "module_name": "reservation_listing"
    },
    /* {
        "key": 87,
        "title": "Company Id",
        "value": "company_id",
        "module_name": "reservation_listing"
    }, */
    /* {
        "key": 88,
        "title": "Is Id Verified",
        "value": "is_id_verified",
        "module_name": "reservation_listing"
    }, */
    /* {
        "key": 89,
        "title": "Id Verified Method",
        "value": "id_verified_method",
        "module_name": "reservation_listing"
    }, */
    {
        "key": 90,
        "title": "Occupancy Taxes",
        "value": "occupancy_taxes",
        "module_name": "reservation_listing"
    },
  ];
    const ExportAttachmentExtention = [
        {
            "label" : "Excel",
            "value" : 1
        },
        {
            "label" : "CSV",
            "value" : 2
        },
    ];
    const ExportDownloadType = [
        {
            "label" : "New Data",
            "value" : 1
        },
        {
            "label" : "All Data",
            "value" : 2
        },
    ];
  
const LeaseRequiredFields = ['unit_number','lease_start_date','lease_end_date'];
const unitRequiredFields = ['unit_number','tower_id'];
const unitAvailabilityRequiredFields = ['property','unit_number','base_price'];
const unitRentRequiredFields = ['unit_number','amount'];


export default { AutomationModules, ReadIntervalTypes, ReadIntervalDurations, DatabaseCols, DatabaseColMapping, EncriptionTypes,LeaseRequiredFields,unitRequiredFields, unitAvailabilityRequiredFields,unitRentRequiredFields, ExportReadIntervalTypes, ExportReadIntervalDurations, ExportSendType ,mockData, ExportWeeklyInterval, ExportMonthlyInterval, ExportAutomationModules, ExportMonthlyDayInterval, ExportAttachmentExtention, ExportDownloadType};
