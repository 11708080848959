////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Info.jsx                                     //
//  Application: Info                                     //
//  Option: For view Info details                         //
//  Developer: Ashish Kumar 				                      //
//  Date: 2022-02-09                                     //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
// import basicRoomimg from "./../../assets/images/basic-prop-img.jpg";
import ListingService from "../../services/ListingService";
import ReservationService from "../../services/ReservationService";
import PetPolicyService from "../../services/PetPolicyService";
// import renderHTML from "react-render-html";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import { Link } from "react-router-dom";
// import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import { Modal, InputGroup, Form } from "react-bootstrap";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import Moment from "moment";
import hostImg from "./../../assets/images/profile-nogender.png";
// import ShowMoreText from "react-show-more-text";
export class Info extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
      errors: {},
      bed_type_name: "",
      balcony_name: "",
      toilet_name: "",
      bathroom: "",
      descShow: 0,
      descShowBio: 0,
      descShowTheSpace: 0,
      descShowGuestManual: 0,
      // descShow1: 0,
      descShow2: 0,
      status_id: "",
      multiList: [],
      multiListTotal: 0,
      listing_pet_allowed: false,
      submitsendAccess: false,
      submitrevokeAccess: false,
      checkin_date: "",
      checkout_date: "",
      checkin_time: "",
      checkout_time: "",
      inspectionData: [],
      inspectionDataTotal: 0,
      residentAccessData: [],
      residentAccessDataTotal: 0,
      is_listed: "1",
      is_open: false,
      ohListingId: 0,
    };
    this.fullText = this.fullText.bind(this);
    this.fullTextBio = this.fullTextBio.bind(this);
    this.fullTextTheSpace = this.fullTextTheSpace.bind(this);
    this.fullTextGuestManual = this.fullTextGuestManual.bind(this);
    // this.fullText1 = this.fullText1.bind(this);
    this.fullText2 = this.fullText2.bind(this);
    this.deleteListing = this.deleteListing.bind(this);
    this.opendeleteListingModal = this.opendeleteListingModal.bind(this);
    this.closedeleteListingModal = this.closedeleteListingModal.bind(this);
    this.openWebsite = this.openWebsite.bind(this);
    this.sendAccess = this.sendAccess.bind(this);
    this.revokeAccess = this.revokeAccess.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.listUnlistListing = this.listUnlistListing.bind(this);
  }

  async getKeyCafeAccessDetails() {
    if (this.state.user_slug) {
      var residentAccessData = [];
      var residentAccessDataTotal = 0;

      let res = await ReservationService.getResidentKeyCafeAccess(
        "property_slug=" +
          this.props.propertySlug +
          "&user_slug=" +
          this.state.user_slug
      );
      if (global.successStatus.includes(res.status)) {
        residentAccessData = res.data ? res.data : [];
        residentAccessDataTotal = residentAccessData
          ? residentAccessData.length
          : 0;
      }
      this.setState({
        residentAccessData: residentAccessData,
        residentAccessDataTotal: residentAccessDataTotal,
      });
    }
  }

  changeHandler = (event) => {
    this.setState({ errors: "", cDate: 0 });
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    if (
      event.target.name === "checkin_time" ||
      event.target.name === "checkout_time"
    ) {
      var datetime = value.split(":");
      var lengthdatetime = (datetime[0].match(/\d/g) || []).length;
      if (lengthdatetime === 1) {
        datetime[0] = datetime[0].replace("_", "0");
      }
      if (parseInt(datetime[0]) > 12) {
        datetime[0] = "12";
      }
      var minute = datetime[1].split(" ");
      var lengthminute = (minute[0].match(/\d/g) || []).length;

      /* const minute = [
        '__',''
      
      ]; */
      if (parseInt(minute[0]) > 59 || lengthminute <= 0) {
        minute[0] = "00";
      }
      const meridian = ["AM", "PM", "am", "pm", "A_", "P_", "a_", "p_"];
      const result = meridian.includes(minute[1].toLowerCase());
      minute[1] = result ? minute[1] : "AM";

      value = datetime[0] + ":" + minute[0] + " " + minute[1].toUpperCase();
    }
    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
    });
  };

  opendeleteListingModal(slug) {
    this.setState({ deleteListingModal: true, slug: slug });
  }

  closedeleteListingModal() {
    this.setState({ deleteListingModal: false, slug: "" });
  }

  async deleteListing(slug) {
    this.setState({ showSpinner: true });
    let res = await ListingService.deleteListing(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteListingModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          (window.location =
            this.props.propPropertySlug !== undefined
              ? "/properties/view/" + this.props.propertySlug + "/listing"
              : "/listings"),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteListingModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async componentDidMount() {
    if (this.props.listing_slug !== undefined) {
      var resData = {};
      let res = await ListingService.getListing(this.props.listing_slug);

      if (global.successStatus.includes(res.status)) {
        resData.listing_name = res.data.listing_name
          ? res.data.listing_name
          : "";
        resData.total_tower_count = res.data.total_tower_count
          ? res.data.total_tower_count
          : "";
        resData.airbnb_notes = res.data.airbnb_notes
          ? res.data.airbnb_notes
          : "";
        resData.custom_link = res.data.custom_link ? res.data.custom_link : "";
        resData.wi_fi_name = res.data.wi_fi_name ? res.data.wi_fi_name : "";
        resData.wi_fi_password = res.data.wi_fi_password
          ? res.data.wi_fi_password
          : "";
        resData.parking_slot = res.data.parking_slot
          ? res.data.parking_slot
          : "";
        resData.status_id = res.data.status_id;
        resData.is_listed = res.data.status_id === 1 ? "1" : "2";
        resData.ohListingId = res.data.listing_unit_id;
        resData.listing_pet_allowed =
          res.data.listing_pet_allowed === 1 ? true : false;
        resData.property_pet_allowed = res.data.property
          ? res.data.property.pet_allowed
          : false;
        resData.unit_number = res.data.unit ? res.data.unit.unit_number : "";
        resData.unit_name = res.data.unit ? res.data.unit.unit_name : "";
        resData.floor_plan_name =
          res.data &&
          res.data.unit &&
          res.data.unit.floor_plan &&
          res.data.unit.floor_plan.property_floor_plan_name
            ? res.data.unit.floor_plan.property_floor_plan_name
            : "";
        resData.floor_plan_slug =
          res.data &&
          res.data.unit &&
          res.data.unit.floor_plan &&
          res.data.unit.floor_plan.slug
            ? res.data.unit.floor_plan.slug
            : "";
        resData.room_type = res.data.room_type
          ? res.data.room_type.room_type_name
          : "";
        resData.room_category_name = res.data.room_category
          ? res.data.room_category.room_category_name
          : "";

        resData.bedroom =
          res.data &&
          res.data.unit &&
          res.data.unit.floor_plan &&
          res.data.unit.floor_plan.bedroom
            ? res.data.unit.floor_plan.bedroom
            : "";
        resData.bath =
          res.data &&
          res.data.unit &&
          res.data.unit.floor_plan &&
          res.data.unit.floor_plan.bath
            ? res.data.unit.floor_plan.bath
            : "";
        resData.maximum_number_of_guests =
          res.data.unit && res.data.unit.maximum_number_of_guests
            ? res.data.unit.maximum_number_of_guests
            : "";
        resData.tower_name = res.data.unit
          ? res.data.unit.tower
            ? res.data.unit.tower.tower_name
            : ""
          : "";
        resData.aka_name = res.data.unit
          ? res.data.unit.tower
            ? res.data.unit.tower.aka_name
            : ""
          : "";
        resData.room_size = res.data.room_size ? res.data.room_size : "";
        resData.bedroom_lock = res.data.bedroom_lock
          ? res.data.bedroom_lock
          : "";
        resData.suit = res.data.suit ? res.data.suit : "";
        resData.host_photo = res.data.host_photo ? res.data.host_photo : "";

        resData.host_photo_thumb_url =
          res.data &&
          res.data.property &&
          res.data.property.property_logo_thumb_url
            ? res.data.property.property_logo_thumb_url
            : hostImg;
        resData.host_name =
          res.data && res.data.property && res.data.property.property_name
            ? res.data.property.property_name
            : "";
        resData.host_email =
          res.data && res.data.property && res.data.property.property_email
            ? res.data.property.property_email
            : "";
        resData.host_phone_number =
          res.data &&
          res.data.property &&
          res.data.property.property_phone_number
            ? res.data.property.property_phone_number
            : "";
        resData.host_bio = res.data.ruListingData
          ? res.data.ruListingData.owner_story
          : "";
        resData.listing_type = res.data.listing_type
          ? res.data.listing_type
          : "";
        resData.bed_type = res.data.bed_type ? res.data.bed_type : "";
        resData.balcony = res.data.balcony ? res.data.balcony : "";
        resData.toilet = res.data.toilet ? res.data.toilet : "";
        resData.bathroom = res.data.bathroom ? res.data.bathroom : "";
        resData.kitchen = res.data.kitchen ? res.data.kitchen : "";
        resData.living_room = res.data.living_room ? res.data.living_room : "";
        //for resident
        resData.user_slug =
          res.data && res.data.user && res.data.user.slug
            ? res.data.user.slug
            : "";
        resData.first_name =
          res.data.residentUser && res.data.residentUser.first_name
            ? res.data.residentUser.first_name
            : "";
        resData.last_name =
          res.data.residentUser && res.data.residentUser.last_name
            ? res.data.residentUser.last_name
            : "";
        resData.email =
          res.data.residentUser && res.data.residentUser.email
            ? res.data.residentUser.email
            : "";

        if (res.data.residentUser && res.data.residentUser.phone_number) {
          this.phoneNumberAutoFormat(res.data.residentUser.phone_number);
        }

        resData.from_date = res.data.from_date
          ? Moment(res.data.from_date, "MM/DD/YYYY").format(global.dateFormat)
          : "";
        resData.to_date = res.data.to_date
          ? Moment(res.data.to_date, "MM/DD/YYYY").format(global.dateFormat)
          : "";
        resData.listing_description = res.data.listing_description
          ? res.data.listing_description
          : "";
        resData.the_space = res.data.the_space ? res.data.the_space : "";
        resData.guest_manual = res.data.guest_manual
          ? res.data.guest_manual
          : "";

        //for rental
        resData.is_exempt = res.data.ruListingData
          ? res.data.ruListingData.is_exempt === 1
            ? "Yes"
            : "No"
          : "No";
        resData.is_vat_registered = res.data.ruListingData
          ? res.data.ruListingData.is_vat_registered === 1
            ? "Yes"
            : "No"
          : "No";
        // resData.headline = res.data.ruListingData
        //   ? res.data.ruListingData.headline
        //   : "";
        resData.why_purchased = res.data.ruListingData
          ? res.data.ruListingData.why_purchased
          : "";
        resData.owner_story = res.data.ruListingData
          ? res.data.ruListingData.owner_story
          : "";
        // resData.wi_fi = res.data.ruListingData ? res.data.ruListingData.wi_fi : ""
        resData.host_intraction = res.data.ruListingData
          ? res.data.ruListingData.host_intraction
          : "";

        resData.license_number = res.data.ruListingLicenseData
          ? res.data.ruListingLicenseData.licensenumber
          : "";
        resData.airbnb_account_id = res.data.airbnb_account_id
          ? res.data.airbnb_account_id
          : "";

        resData.issue_date =
          res.data.ruListingLicenseData &&
          res.data.ruListingLicenseData.issue_date
            ? Moment(
                res.data.ruListingLicenseData.issue_date,
                "YYYY-MM-DD"
              ).format(global.dateFormat)
            : null;
        resData.expiration_date =
          res.data.ruListingLicenseData &&
          res.data.ruListingLicenseData.expiration_date
            ? Moment(
                res.data.ruListingLicenseData.expiration_date,
                "YYYY-MM-DD"
              ).format(global.dateFormat)
            : null;
        resData.exemption_reason = res.data.ruListingLicenseData
          ? res.data.ruListingLicenseData.exemption_reason
          : "";

        res.data.masterData.map((item) => {
          if (resData.listing_type === item.slug) {
            const renting_type = item.name;
            this.setState({ renting_type_name: renting_type });
          }
          if (resData.bed_type === item.slug) {
            const bed_type = item.name;
            this.setState({ bed_type_name: bed_type });
          }
          if (resData.balcony === item.slug) {
            const balcony = item.name;
            this.setState({ balcony_name: balcony });
          }
          if (resData.toilet === item.slug) {
            const toilet = item.name;
            this.setState({ toilet_name: toilet });
          }
          if (resData.bathroom === item.slug) {
            const bathroom = item.name;
            this.setState({ bathroom_name: bathroom });
          }
          if (resData.kitchen === item.slug) {
            const kitchen = item.name;
            this.setState({ kitchen_name: kitchen });
          }
          if (resData.living_room === item.slug) {
            const living_room = item.name;
            this.setState({ living_room_name: living_room });
          }
        });
      }
      this.setState(resData);
    }
    this.getPetPolicyData();
    this.getKeyCafeAccessDetails();
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    this.setState({ phone_number: finalnumber });
  };

  async sendAccess() {
    this.setState({ submitsendAccess: true });
    let inputData = {
      property_slug: this.props.propertySlug ? this.props.propertySlug : "",
      unit_slug: this.props.unit_slug ? this.props.unit_slug : "",
      listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
      user_slug: this.state.user_slug ? this.state.user_slug : "",

      checkin_date: this.state.checkin_date
        ? Moment(this.state.checkin_date).format("YYYY-MM-DD")
        : "",
      checkout_date: this.state.checkout_date
        ? Moment(this.state.checkout_date).format("YYYY-MM-DD")
        : "",
      checkin_time: this.state.checkin_time ? this.state.checkin_time : "",
      checkout_time: this.state.checkout_time ? this.state.checkout_time : "",

      first_name: this.state.first_name ? this.state.first_name : "",
      last_name: this.state.last_name ? this.state.last_name : "",
      email: this.state.email ? this.state.email : "",
      phone_number: this.state.phone_number ? this.state.phone_number : "",
      from_where: 3,
    };

    if (this.state.user_slug) {
      let res = await ReservationService.sendAccess(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.getKeyCafeAccessDetails();
      } else {
        // if (res.data.result.errorDetail !== null) {
        //   let errors = {};
        //   res.data.errorCode = "Validation Failed";
        //   res.data.result.errorDetail.forEach((item) => {
        //     errors[item.errorField] = item.errorMessage[0];
        //     res.data.errorMessage = "";
        //   });
        //   this.setState({ errors: errors });
        // }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
        const input = document.getElementsByClassName("alert-danger");
        if (input.length > 0) {
          input[0].scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
      }
    }
    this.setState({ submitsendAccess: false });
  }

  async revokeAccess() {
    this.setState({ submitrevokeAccess: true });

    if (this.state.user_slug) {
      let res = await ReservationService.revokeAccess(
        this.state.keycafe_access_id
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({
          booking_code: "",
          keycafe_key_id: "",
          serial_number: "",
          access_start_date: "",
          access_start_time: "",
          access_end_date: "",
          acess_end_time: "",
          status: "",
          status_time: "",
          keycafe_access_id: "",
        });
        // this.getKeyCafeAccessDetails();
      } else {
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
    }
    this.setState({ submitrevokeAccess: false });
  }

  changeHandler = (event) => {
    this.setState({ errors: "", cDate: 0 });
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    if (
      event.target.name === "checkin_time" ||
      event.target.name === "checkout_time"
    ) {
      var datetime = value.split(":");
      var lengthdatetime = (datetime[0].match(/\d/g) || []).length;
      if (lengthdatetime === 1) {
        datetime[0] = datetime[0].replace("_", "0");
      }
      if (parseInt(datetime[0]) > 12) {
        datetime[0] = "12";
      }
      var minute = datetime[1].split(" ");
      var lengthminute = (minute[0].match(/\d/g) || []).length;

      /* const minute = [
        '__',''
      
      ]; */
      if (parseInt(minute[0]) > 59 || lengthminute <= 0) {
        minute[0] = "00";
      }
      const meridian = ["AM", "PM", "am", "pm", "A_", "P_", "a_", "p_"];
      const result = meridian.includes(minute[1].toLowerCase());
      minute[1] = result ? minute[1] : "AM";

      value = datetime[0] + ":" + minute[0] + " " + minute[1].toUpperCase();
    }
    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
    });
  };

  async getPetPolicyData() {
    //this.setState({ showSpinner: true, loading: true });
    var multiList = [];
    var multiListTotal = 0;

    let res = await PetPolicyService.getPetPolicy(
      this.props.propertySlug + "/3"
    );
    if (global.successStatus.includes(res.status)) {
      multiList = res.data.petPolicyRestrictions
        ? res.data.petPolicyRestrictions
        : [];
      multiListTotal = multiList ? multiList.length : 0;
    }
    this.setState({
      multiList: multiList,
      multiListTotal: multiListTotal,
    });
    //this.setState({ showSpinner: false, loading: false });
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextBio() {
    if (this.state.descShowBio === 0) {
      this.setState({ descShowBio: 1 });
    } else {
      this.setState({ descShowBio: 0 });
    }
  }

  fullTextTheSpace() {
    if (this.state.descShowTheSpace === 0) {
      this.setState({ descShowTheSpace: 1 });
    } else {
      this.setState({ descShowTheSpace: 0 });
    }
  }

  fullTextGuestManual() {
    if (this.state.descShowGuestManual === 0) {
      this.setState({ descShowGuestManual: 1 });
    } else {
      this.setState({ descShowGuestManual: 0 });
    }
  }

  // fullText1() {
  //   if (this.state.descShow1 === 0) {
  //     this.setState({ descShow1: 1 });
  //   } else {
  //     this.setState({ descShow1: 0 });
  //   }
  // }

  fullText2() {
    if (this.state.descShow2 === 0) {
      this.setState({ descShow2: 1 });
    } else {
      this.setState({ descShow2: 0 });
    }
  }

  openWebsite() {
    // var path = process.env.REACT_AIRBNB_LISITING_ENDPOINT
    //   ? process.env.REACT_AIRBNB_LISITING_ENDPOINT
    //   : "https://www.airbnb.com/h/";
    var url = this.state.custom_link
      ? this.state.custom_link.toLowerCase()
      : "";
    window.open(url, "_blank");
  }

  changeStatus() {
    this.setState({ is_open: true });
  }
  closeUnlistListingModal() {
    this.setState({ is_open: false });
  }
  async listUnlistListing(slug) {
    this.setState({ showSpinner: true });
    let res = await ListingService.listUnlistListing(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeUnlistListingModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
    } else {
      let alertMessage = "";
      this.closeUnlistListingModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  changeExport(evt) {
    let value = evt.target.value;
    console.log(value);
    this.setState({ is_listed: value });
  }

  async ExportData() {
    this.setState({ showSpinner: true, isSubmit: true });
    let res = await ListingService.listUnlistListing(
      "ohListingId=" +
        this.state.ohListingId +
        "&request_status=" +
        this.state.is_listed
    );
    let status_new = 0;
    console.log(res);
    console.log(res.status);
    if (global.successStatus.includes(res.status)) {
      status_new = this.state.is_listed === 1 ? "1" : "2";
      this.setState({
        showSpinner: false,
        isSubmit: false,
        is_open: false,
        is_listed: status_new,
        status_id: status_new,
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
    } else {
      this.setState({
        showSpinner: false,
        isSubmit: false,
        is_open: false,
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: res.data.message,
      });
    }
  }

  render() {
    // var path = process.env.REACT_AIRBNB_LISITING_ENDPOINT
    //   ? process.env.REACT_AIRBNB_LISITING_ENDPOINT
    //   : "https://www.airbnb.com/h/";
    const url = this.state.custom_link
      ? this.state.custom_link.toLowerCase()
      : "";
    return (
      <>
        <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
            {this.props.listing_slug !== undefined ? (
              <>
                {global.userPermissions.checkPermission(
                  "single-unit-delete"
                ) ? (
                  <span
                    onClick={() => {
                      this.opendeleteListingModal(this.props.listing_slug);
                    }}
                    className="deleteicon mg-r-20 hand-cursor"
                  >
                    <img src={deleteicon} alt="Delete" />
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}{" "}
            {global.userPermissions.checkPermission("single-unit-update") ? (
              <Link
                to={
                  this.props.propPropertySlug !== undefined
                    ? "/properties/view/" +
                      this.props.propertySlug +
                      "/listing/update/" +
                      this.props.listing_slug
                    : "/listing/update/" +
                      this.props.outside_property_slug +
                      "/" +
                      this.props.listing_slug
                }
              >
                <button className="btn-success-outline-small ml-3 mr-3">
                  <img src={editIcon} alt="Edit" />
                  Edit
                </button>
              </Link>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-12 pd-l-30 pd-r-30 mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          <div className="m-0 listing_detail">
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 background-head">Info</h3>
            </div>
            <div className="row align-items-basline pd-b-0 border-bottom-0 pd-lg-l-0 pd-xs-l-15 pd-r-15">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">Listing Name</p>
                <p className="media-body">
                  {this.state.listing_name ? this.state.listing_name : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">Unit</p>
                <p className="media-body">
                  {this.state.unit_name ? (
                    <a
                      className="link-url"
                      href={
                        "/properties/view/" +
                        this.props.propertySlug +
                        "/units/" +
                        this.props.unit_slug +
                        "/unit-basic"
                      }
                    >
                      {this.state.unit_name}
                    </a>
                  ) : (
                    this.state.unit_number
                  )}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">Floor Plan</p>
                <p className="media-body">
                  <a
                    className="link-url"
                    href={
                      "/properties/view/" +
                      this.props.propertySlug +
                      "/floor-plans/view/" +
                      this.state.floor_plan_slug
                    }
                  >
                    {this.state.floor_plan_name}
                  </a>
                </p>
              </div>
              {global.userType !== 4 ? (
                <>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Listing Type</p>
                    <p className="media-body">
                      {this.state.renting_type_name
                        ? this.state.renting_type_name
                        : ""}
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">From</p>
                <p className="media-body">
                  {this.state.from_date ? this.state.from_date : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">To</p>
                <p className="media-body">
                  {this.state.to_date ? this.state.to_date : ""}
                </p>
              </div>
              {global.userType !== 4 ? (
                <>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Airbnb Link</p>
                    {/* <p className="media-body">
                {this.state.custom_link ? this.state.custom_link : ""}
              </p> */}
                    <p
                      className="media-body hand-cursor break-word link-url"
                      onClick={this.openWebsite}
                    >
                      {url}
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">
                  {this.state.bedroom > 1 ? "Bedrooms" : "Bedroom"}
                </p>
                <p className="media-body">
                  {this.state.bedroom ? this.state.bedroom : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">
                  {this.state.bath > 1 ? "Bathrooms" : "Bathroom"}
                </p>
                <p className="media-body">
                  {this.state.bath ? this.state.bath : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">Guest Count</p>
                <p className="media-body">
                  {this.state.maximum_number_of_guests
                    ? this.state.maximum_number_of_guests
                    : ""}
                </p>
              </div>
              {global.userType !== 4 ? (
                <>
                  {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p
                className="az-profile-name-text pl-0"
                title="Below pets are prohibited at property"
              >
                Prohibited Pets
              </p>
              <p className="media-body">
                {this.state.multiListTotal > 0
                  ? this.state.multiList.map(
                      (option, i) =>
                        option.pet_type_name +
                        (i < this.state.multiListTotal - 1 ? ", " : "") +
                        ""
                    )
                  : ""}
              </p>
            </div> */}
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Parking Space</p>
                    <p className="media-body">
                      {this.state.parking_slot ? this.state.parking_slot : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Wi-Fi Name</p>
                    <p className="media-body">
                      {this.state.wi_fi_name ? this.state.wi_fi_name : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Wi-Fi Password</p>
                    <p className="media-body">
                      {this.state.wi_fi_password
                        ? this.state.wi_fi_password
                        : ""}
                    </p>
                  </div>
                  {this.state.property_pet_allowed === 1 ? (
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                      <p className="az-profile-name-text pl-0">
                        Do you want to allow pets?
                      </p>
                      <p className="media-body">
                        {this.state.property_pet_allowed === 1
                          ? this.state.listing_pet_allowed === true
                            ? "Yes"
                            : "No"
                          : "Since the property is not pet friendly, you can not select pets in the listing."}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">License Number</p>
                    <p className="media-body">
                      {this.state.license_number
                        ? this.state.license_number
                        : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Issue Date</p>
                    <p className="media-body">
                      {this.state.issue_date ? this.state.issue_date : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Expiration Date</p>
                    <p className="media-body">
                      {this.state.expiration_date
                        ? this.state.expiration_date
                        : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">
                      Airbnb Account ID
                    </p>
                    <p className="media-body">
                      {this.state.airbnb_account_id
                        ? this.state.airbnb_account_id
                        : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Status</p>
                    <p className="media-body">
                      {this.state.status_id === 1 ? "Listed" : "Unlisted"}
                      {/* <button className="btn-success-outline-small ml-3 mr-3"> */}
                      <span
                        className="ml-3 hand-cursor"
                        onClick={() => this.changeStatus()}
                      >
                        <img src={editIcon} alt="Edit" />
                      </span>
                      {/* Edit */}
                      {/* </button> */}
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">Headline</p>
                <div className="media-body">
                  {this.state.headline ? (
                    <p className="p_wrap1">
                      {this.state.descShow1
                        ? this.state.headline
                        : this.state.headline.slice(0, global.showMoreLength)}

                      {this.state.headline.length > global.showMoreLength && (
                        <span onClick={this.fullText1}>
                          {this.state.descShow1 ? (
                            <p className="hand-cursor showmore">Show Less </p>
                          ) : (
                            <p className="hand-cursor showmore">
                              ...Show more{" "}
                            </p>
                          )}
                        </span>
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div> */}

              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">Listing Description</p>
                <div className="media-body">
                  {this.state.listing_description ? (
                    <p className="p_wrap1">
                      {this.state.descShow
                        ? this.state.listing_description
                        : this.state.listing_description.slice(
                            0,
                            global.showMoreLength
                          )}

                      {this.state.listing_description.length >
                        global.showMoreLength && (
                        <span onClick={this.fullText}>
                          {this.state.descShow ? (
                            <p className="hand-cursor showmore">Show Less </p>
                          ) : (
                            <p className="hand-cursor showmore">
                              ...Show more{" "}
                            </p>
                          )}
                        </span>
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-12 col-xxl-6 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">The Space</p>
                <div className="media-body">
                  {this.state.the_space ? (
                    <p className="p_wrap1">
                      {this.state.descShowTheSpace
                        ? this.state.the_space
                        : this.state.the_space.slice(0, global.showMoreLength)}

                      {this.state.the_space.length > global.showMoreLength && (
                        <span onClick={this.fullTextTheSpace}>
                          {this.state.descShowTheSpace ? (
                            <p className="hand-cursor showmore">Show Less </p>
                          ) : (
                            <p className="hand-cursor showmore">
                              ...Show more{" "}
                            </p>
                          )}
                        </span>
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">Guest Manual</p>
                <div className="media-body">
                  {this.state.guest_manual ? (
                    <p className="p_wrap1">
                      {this.state.descShowGuestManual
                        ? this.state.guest_manual
                        : this.state.guest_manual.slice(
                            0,
                            global.showMoreLength
                          )}
                      {this.state.guest_manual.length >
                        global.showMoreLength && (
                        <span onClick={this.fullTextGuestManual}>
                          {this.state.descShowGuestManual ? (
                            <p className="hand-cursor showmore">Show Less </p>
                          ) : (
                            <p className="hand-cursor showmore">
                              ...Show more{" "}
                            </p>
                          )}
                        </span>
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {this.state.renting_type_name !== "Entire Unit" ? (
              <>
                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <h3 className="mg-l-0 mg-t-15 background-head">Room</h3>
                </div>

                <div className="row align-items-basline pd-b-0 border-bottom-0 pd-lg-l-0 pd-xs-l-15 pd-r-15">
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Room Category</p>
                    <p className="media-body">
                      {this.state.room_category_name
                        ? this.state.room_category_name
                        : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Room Type</p>
                    <p className="media-body">
                      {this.state.room_type ? this.state.room_type : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Bed Type</p>
                    <p className="media-body">
                      {this.state.bed_type_name ? this.state.bed_type_name : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">
                      Room Size (Sq. ft)
                    </p>
                    <p className="media-body">
                      {this.state.room_size
                        ? this.state.room_size.replace(/\.00$/, "")
                        : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Balcony</p>
                    <p className="media-body">
                      {this.state.balcony_name ? this.state.balcony_name : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Toilet </p>
                    <p className="media-body">
                      {this.state.toilet_name ? this.state.toilet_name : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Bathroom </p>
                    <p className="media-body">
                      {this.state.bathroom_name ? this.state.bathroom_name : ""}
                    </p>
                  </div>

                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Kitchen </p>
                    <p className="media-body">
                      {this.state.kitchen_name ? this.state.kitchen_name : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Living Room </p>
                    <p className="media-body">
                      {this.state.living_room_name
                        ? this.state.living_room_name
                        : ""}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <p className="az-profile-name-text pl-0">Bedroom Lock </p>
                    <p className="media-body">
                      {this.state.bedroom_lock === 1 ? "Yes" : "No"}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {/* {global.userType !== 4 ? (
            <>
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 background-head">Host</h3>
              </div>
              <div className="row m-0 border-0">
                <div className="col-md-12 p-0">
                  <div className="row align-items-basline pd-b-0 border-bottom-0 pd-lg-l-0 pd-xs-l-15 pd-r-15">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                      <div className="row mg-0">
                        <div className="brand-sm-logo mg-b-8">
                          <figure>
                            <img
                              src={
                                this.state.host_photo_thumb_url
                                  ? this.state.host_photo_thumb_url
                                  : hostImg
                              }
                              alt=""
                              className="rounded-circle"
                            />
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="row align-items-basline pd-b-0 border-bottom-0 pd-lg-l-0 pd-xs-l-15 pd-r-15">
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                        <p className="az-profile-name-text pl-0">Bio/Story</p>
                        <div className="media-body">
                          {this.state.host_bio ? (
                            <p className="p_wrap1">
                              {this.state.descShowBio
                                ? this.state.host_bio.slice(
                                    0,
                                    global.showMoreLength
                                  )
                                : this.state.host_bio}

                              {this.state.host_bio.length >
                                global.showMoreLength && (
                                <span onClick={this.fullTextBio}>
                                  {this.state.descShowBio ? (
                                    <p className="hand-cursor showmore">
                                      ...Show more{" "}
                                    </p>
                                  ) : (
                                    <p className="hand-cursor showmore">
                                      Show Less{" "}
                                    </p>
                                  )}
                                </span>
                              )}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )} */}

            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 mg-t-15 background-head">Resident</h3>
            </div>
            <div className="row align-items-basline pd-b-0 border-bottom-0 pd-lg-l-0 pd-xs-l-15 pd-r-15">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">First Name</p>
                <p className="media-body">
                  {this.state.first_name ? this.state.first_name : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">Last Name</p>
                <p className="media-body">
                  {this.state.last_name ? this.state.last_name : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">Phone</p>
                <p className="media-body">
                  {this.state.phone_number ? this.state.phone_number : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <p className="az-profile-name-text pl-0">Email</p>
                <p className="media-body">
                  {this.state.email ? this.state.email : ""}
                </p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
            <h3 className="mg-l-0 mg-t-15 background-head">
              Rental United (optional){" "}
            </h3>
          </div>
          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Why Purchased</p>
              <p className="media-body">
                {this.state.why_purchased ? this.state.why_purchased : ""}
              </p>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Host Interaction</p>
              <p className="media-body">
                {this.state.host_intraction ? this.state.host_intraction : ""}
              </p>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">VAT Registered</p>
              <p className="media-body">
                {this.state.is_vat_registered
                  ? this.state.is_vat_registered
                  : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Exempt</p>
              <p className="media-body">
                {this.state.is_exempt ? this.state.is_exempt : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Exemption Reason</p>
              <p className="media-body">
                {this.state.exemption_reason ? this.state.exemption_reason : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">License Number</p>
              <p className="media-body">
                {this.state.license_number ? this.state.license_number : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Issue Date</p>
              <p className="media-body">
                {this.state.issue_date ? this.state.issue_date : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Expiration Date</p>
              <p className="media-body">
                {this.state.expiration_date ? this.state.expiration_date : ""}
              </p>
            </div>
          </div> */}
        </div>
        <Modal
          show={this.state.deleteListingModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closedeleteListingModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteListingModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteListingModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteListing(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.is_open} className="modal-xs" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeUnlistListingModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Change Status</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <InputGroup className="mt-0">
                  <label>Select Listing Status : </label>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="1"
                        checked={this.state.is_listed === "1" ? true : false}
                        type={"radio"}
                        id="listed"
                        label={"Listed"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="2"
                        checked={this.state.is_listed === "2" ? true : false}
                        type={"radio"}
                        id="unlisted"
                        label={"Unlisted"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                  </div>
                </InputGroup>
                {this.state.exportMesssage !== "" && (
                  <div>
                    {this.state.exportMesssage}
                    <br></br>
                    <br></br>
                    {/* {this.state.exportFilePath !== undefined && (
                      <a href={this.state.exportFilePath}> Download File </a>
                    )} */}
                  </div>
                )}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="mx-auto- mg-r-30 btn btn btn-success btn-block"
                    onClick={() => this.ExportData()}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Save"}
                  </button>
                  <button
                    type="button"
                    className="mx-auto- m-0  btn btn-outline-light btn-block"
                    onClick={() => this.closeUnlistListingModal()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Info;
