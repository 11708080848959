import React, { Component, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Dashboard from "./dashboard/Dashboard";
import KDD from "./dashboard/KDD";
// import Landing from "./dashboard/Landing";

import Signin from "./authentication/Signin";
import ForgotPassword from "./authentication/ForgotPassword";
import ResetPassword from "./authentication/ResetPassword";
import BrandLegalFinance from "./brand/BrandLegalFinance";
import GeneratePassword from "./authentication/GeneratePassword";
import Logout from "./authentication/Logout";
import Page403 from "./authentication/Page403";
import UpdateProfile from "./authentication/UpdateProfile";
import ChangePassword from "../components/authentication/ChangePassword";
import CorporateApp from "./corporate/CorporateApp";
import CorporateAppForm from "./corporate/CorporateAppForm";
import CorporateAppView from "./corporate/CorporateAppView";

// import RolePermissionList from "./authentication/RolePermissionList";
// import RolePermission from "./authentication/RolePermission";

import Assets from "./Assets";

import Company from "./company/Company";
import CompanyForm from "./company/CompanyForm";

import Brand from "./brand/Brand";
import BrandForm from "./brand/BrandForm";
import BrandView from "./brand/BrandView";

import Club from "./club/Club";
import ClubForm from "./club/ClubForm";
import ClubView from "./club/ClubView";

import Client from "./client/Client";
import ClientForm from "./client/ClientForm";
import ClientView from "./client/ClientView";
// import Master from "./master/Master";

import Properties from "./property/Properties";
import PropertiesView from "./property/PropertiesView";
import PropertiesForm from "./property/PropertiesForm";
import PropertiesAddForm from "./property/PropertiesAddForm";
import Tower from "./property/Tower";
import TowerForm from "./property/TowerForm";
import PropertyAmenities from "./property/PropertyAmenities";
import PropertyNearByAttractionOld from "./property/PropertyNearByAttractionOld";
import PropertyNearByAttraction from "./property/PropertyNearByAttraction";
import PropertyNearByAttractionForm from "./property/PropertyNearByAttractionForm";

import FloorPlanForm from "./property/FloorPlanForm";
import FloorPlan from "./property/FloorPlan";
import FloorPlanView from "./property/FloorPlanView";
import Floor from "./property/Floor";
import FloorForm from "./property/FloorForm";
import Units from "./unit/Units";
import UnitsForm from "./unit/UnitsFrom";
// import Rooms from "./room/Rooms";
// import RoomsForm from "./room/RoomsForm";
// import RoomsView from "./room/RoomsView";
import Influencers from "./property/Influencers";
import InfluencersForm from "./property/InfluencersForm";
import InfluencerView from "./property/InfluencerView";
import Applicants from "./property/Applicants";
import ApplicantsForm from "./property/ApplicantsForm";
import ApplicantView from "./property/ApplicantView";
import Events from "./marketing/Events";
import EventsForm from "./marketing/EventsForm";
import UnitsView from "./unit/UnitsView";
import PetPolicy from "./property/PetPolicy";
import PetPolicyForm from "./property/PetPolicyForm";
import PropertyLevelTax from "./property/PropertyLevelTax";
import PropertyLevelTaxForm from "./property/PropertyLevelTaxForm";
import PropertyPrice from "./property/PropertyPrice";
import PropertyPriceForm from "./property/PropertyPriceForm";
import PropertyCost from "./property/PropertyCost";
import EventsView from "./marketing/EventView";
import Staff from "./property/Staff";
import StaffForm from "./property/StaffForm";
import PropertyCostForm from "./property/PropertyCostForm";
import RentForm from "./leasing/RentForm";
import StaffView from "./property/StaffView";
import PropertyDocuments from "./property/PropertyDocuments";
import PropertyDocumentsForm from "./property/PropertyDocumentsForm";
import UnitsDocForm from "./unit/UnitsDocForm";

//master routes
// import MasterSetup from "./master/MasterSetup";
import AffordableType from "./misc-setup/AffordableType";
import RoomCategory from "./room-setup/RoomCategory";
import Amenity from "./property-setup/Amenity";
import AmenityType from "./property-setup/AmenityType";
import AgencyCategory from "./third-party-setup/AgencyCategory";
import AttractionType from "./misc-setup/AttractionType";
import PropertyType from "./property-setup/PropertyType";
import CommServed from "./property-setup/CommServed";
import EventType from "./misc-setup/EventType";
import Flooring from "./property-setup/Flooring";
import Heating from "./property-setup/Heating";
import LeedBuilding from "./property-setup/LeedBuilding";
import LocationType from "./property-setup/LocationType";
import DocumentType from "./misc-setup/DocumentType";
import OwnershipType from "./property-setup/OwnershipType";
import ParkingType from "./property-setup/ParkingType";
import PetType from "./unit-setup/PetType";
import RoomType from "./room-setup/RoomType";
import SmokingType from "./property-setup/SmokingType";
import StaffContactType from "./property-setup/StaffContactType";
import PropertySuitability from "./property-setup/PropertySuitability";
import Timezone from "./misc-setup/Timezone";
import CostPayableAt from "./financial-setup/CostPayableAt";
import CostPayableBy from "./financial-setup/CostPayableBy";
import CostType from "./financial-setup/CostType";
import GeneralTax from "./financial-setup/GeneralTax";
import TaxType from "./financial-setup/TaxType";
import Country from "./location-setup/Country";
import County from "./location-setup/County";
import State from "./location-setup/State";
import City from "./location-setup/City";
import Metro from "./location-setup/Metro";
import SubMetro from "./location-setup/SubMetro";
import Vendor from "./property/Vendor";
import VendorForm from "./property/VendorForm";
import PropertyLegalEntity from "./property/PropertyLegalEntity";
import PropertyOwner from "./property/PropertyOwner";
import PropertyOwnerForm from "./property/PropertyOwnerForm";
// import PropertiesImages from "./property/PropertiesImages";
import HomeSharingListing from "./homesharing/HomeSharingListing";
import Basic from "./unit/Basic";
import UnitsImages from "./unit/UnitsImages";
import UnitAval from "./unit/UnitAval";
import UnitDocuments from "./unit/UnitDocuments";
import UnitAmenities from "./unit/UnitAmenities";
import UnitCost from "./unit/UnitCost";
// import UnitRooms from "./unit/UnitRooms";
import Contact from "./property/Contact";
import BasicNew from "./property/BasicNew";
import PropertyPayoutConfiguration from "./property/PropertyPayoutConfiguration";
import LeasingListing from "./leasing/LeasingListing";
import LeasingRents from "./leasing/Rents";
import Checklist from "./housekeeping/Checklist";
import HouseKeepingCalendar from "./housekeeping/HouseKeepingCalendar";
import Keys from "./partner/keycafe/Activity";
import HouseKeepingForm from "./housekeeping/HouseKeepingForm";

import HouseKeepingList from "./housekeeping/HouseKeepingList";
import HouseKeepingPayments from "./housekeeping/HouseKeepingPayments";
import Request from "./guests/Request";
import RequestChat from "./guests/Chat";
import UnifiedCalendar from "./calendar/UnifiedCalendar";
import ReservationCalendar from "./calendar/ReservationCalendar";
import ReservationWebViewCalendar from "./calendar/ReservationWebViewCalendar";
import CheckinCalendar from "./calendar/CheckinCalendar";
import CheckoutCalendar from "./calendar/CheckoutCalendar";
import Orders from "./shop/Orders";
import Items from "./shop/Items";
import Inventory from "./shop/Inventory";
import Sales from "./accounting/Sales";

import MaintenanceChecklist from "./maintenance/MaintenanceChecklist";
import MaintenanceInbox from "./maintenance/MaintenanceInbox";
import MaintenanceCalendar from "./maintenance/MaintenanceCalendar";
import ActivityAdd from "./partner/keycafe/ActivityAdd";
import ActivityAddLatch from "./partner/latch/ActivityAddLatch";
import ActivityViewLatch from "./partner/latch/ActivityViewLatch";
import Vendors from "./vendors/Vendors";
import Bills from "./vendors/Bills";
import Payments from "./vendors/Payments";
import Invoices from "./vendors/Invoices";
import WorkOrders from "./vendors/WorkOrders";

import EarningsReports from "./reports/EarningsReports";
import HomeSharingReport from "./reports/HomeSharingReport";
import ResidentPayoutsReports from "./reports/ResidentPayoutsReports";
import ResidentPayoutReportList from "./reports/ResidentPayoutReportList";
import PropertyPayoutReportList from "./reports/PropertyPayoutReportList";
import PropertyPayoutReportListCopy from "./reports/PropertyPayoutReportListCopy";
import ResidentPayoutsReportsCreate from "./reports/ResidentPayoutsReportsCreate";
import PropertyPayoutsReportsCreate from "./reports/PropertyPayoutsReportsCreate";
import ResidentPayoutsReportsEdit from "./reports/ResidentPayoutsReportsEdit";
import PropertyPayoutsReportsEdit from "./reports/PropertyPayoutsReportsEdit";
import ResidentTopUp from "./reports/ResidentTopUp";
//import ResidentPayoutsReportsUpdate from "./reports/ResidentPayoutsReportsUpdate";
import PayoutsReports from "./reports/PayoutsReports";
import PropertyPayoutsReportsByUnit from "./reports/PropertyPayoutsReportsByUnit";
import PropertyPayoutsReportsByReservation from "./reports/PropertyPayoutsReportsByReservation";
import OhEarningReport from "./reports/OhEarningReport";
import PropertyTaxReports from "./reports/PropertyTaxReports";
import TopPerformerReport from "./reports/TopPerformerReport";
import QuarterlyTaxReports from "./reports/QuarterlyTaxReports";
import PropertyReports from "./reports/PropertyReports";
import LeasingReports from "./reports/LeasingReports";
import ReservationReports from "./reports/ReservationReports";
import HousekeepingReports from "./reports/HousekeepingReports";
import GuestReports from "./reports/GuestReports";
import ResidentReports from "./reports/ResidentReports";
import AccountingReports from "./reports/AccountingReports";
import ShopReports from "./reports/ShopReports";

import Locks from "./settings/Locks";
import Chatbot from "./settings/Chatbot";
import NoiseWare from "./settings/NoiseWare";
import Airbnb from "./settings/Airbnb";
import Google from "./settings/Google";
import IlsExport from "./settings/IlsExport";
import OtaExport from "./settings/OtaExport";

import Users from "./users/Users";
import UsersForm from "./users/UsersForm";

import Roles from "./roles/Roles";
import RolesForm from "./roles/RolesForm";
import RolePermission from "./roles/RolePermission";

import LeadTrackingContacts from "./marketing/LeadTrackingContacts";
import MarketingCopy from "./marketing/MarketingCopy";
import HouseRules from "./marketing/HouseRules";
import ArrivalInstructions from "./marketing/ArrivalInstructions";
import CheckoutInstructions from "./marketing/CheckoutInstructions";
import GuestManual from "./marketing/GuestManual";
import HealthSafety from "./marketing/HealthSafety";
import GettingAround from "./marketing/GettingAround";
import NoiseawareTemplate from "./partner/noiseaware/NoiseawareTemplate";
import NoiseawareTemplateForm from "./partner/noiseaware/NoiseawareTemplateForm";
import Amenities from "./marketing/Amenities";
import EcommerceList from "./marketing/EcommerceList";
import EcommerceView from "./marketing/EcommerceView";
import EcommerceForm from "./marketing/EcommerceForm";
import Neighborhood from "./marketing/Neighborhood";
import NeighborhoodList from "./marketing/NeighborhoodList";
import NeighborhoodView from "./marketing/NeighbourhoodView";
import NeighborhoodForm from "./marketing/NeighbourhoodForm";
import NearByAttraction from "./marketing/NearByAttraction";
import NearByAttractionView from "./marketing/NearByAttractionView";
import NearByAttractionForm from "./marketing/NearByAttractionForm";
import Images from "./marketing/Images";
import Retail from "./marketing/Retail";
import Services from "./marketing/Services";
import Features from "./marketing/Features";
import Websites from "./marketing/Websites";
import BrandCms from "./brand/BrandCms";
import HomeSharingReservation from "./homesharing/Reservation";
// import HomeSharingGuests from "./homesharing/Guests";
import HomeSharingPayments from "./homesharing/Payments";
import PropertyFees from "./property/PropertyFees";
import Parking from "./property/Parking";
import PropertyCredential from "./property/PropertyCredential";
import Compliance from "./property/Compliance";

import Store from "./Store";
import Analytics from "./Analytics";

import ListingCalendar from "./listing/ListingCalendar";
import Listings from "./listing/Listings";
import ListingForm from "./listing/ListingForm";
import ListingView from "./listing/ListingView";

import Leasing from "./lease/Leasing";
import LeasingForm from "./lease/LeasingForm";
import LeasingView from "./lease/LeasingView";
import LeasingChecklist from "./lease/LeasingChecklist.jsx";
import LeaseAvalCalendar from "./lease/LeaseAvalCalendar";

import MaintenanceRequest from "./guests/MaintenanceRequest";
// import Availability from "./leasing/Availability";
// import Application from "./leasing/Application";
import Tours from "./leasing/Tours";
import MoneyPaid from "./accounting/MoneyPaid";
import Expenses from "./accounting/Expenses";

import Leads from "./CRM/Leads";
import Inquiry from "./inquiry/Inquiry";
import ContactLists from "./CRM/ContactLists";
import ContactList from "./CRM/ContactList";
import Campaigns from "./CRM/Campaigns";
import Segments from "./CRM/Segments";
import PropertyContactLists from "./CRM/PropertyContactLists";
import PropertyCampaigns from "./CRM/PropertyCampaigns";
import PropertySegments from "./CRM/PropertySegments";

// website Routes
import CompanyOverview from "./company/CompanyOverview";
import SnapshotOverview from "./snapshot/SnapshotOverview";
import PropertyOverview from "./property/PropertyOverview";
import BuildingOnboarding from "./building-onboarding/BuildingOnboarding";
import PropertyDashboardOverview from "./property/PropertyDashboardOverview";
import VirtualTour from "./marketing/VirtualTour";

import Reservations from "./reservation/Reservations";
import ReservationForm from "./reservation/ReservationForm";
import ReservationView from "./reservation/ReservationView";

import Guest from "./guests/Guest";
import GuestForm from "./guests/GuestForm";
import GuestView from "./guests/GuestView";

import Resident from "./resident/Resident";
import ResidentForm from "./resident/ResidentForm";
import ResidentView from "./resident/ResidentView";
import ResidentChecklist from "./resident/ResidentChecklist";
import ResidentPayout from "./resident/ResidentPayout";
import ResidentInbox from "./resident/ResidentInbox";

import Templates from "./templates/Templates";
import TemplatesForm from "./templates/TemplatesForm";
import ChecklistForm from "./housekeeping/ChecklistForm";
import AccessibilityFeatureType from "./property-setup/AccessibilityFeatureType";
import AccessibilityFeature from "./property-setup/AccessibilityFeature";
import Accessibility from "./marketing/Accessibility";
import Inspections from "./inspection/inspections";
import InspectionView from "./inspection/InspectionView";
import ApplicantsDetail from "./property/ApplicantsDetail";
import ApplicantDetailsForm from "./property/ApplicantDetailsForm";
import ApplicantDetailView from "./property/ApplicantDetailView";
import UnitsAvailability from "./unit/UnitsAvailability";
import MROCategory from "./property-setup/MROCategory";
import Mro from "./mro/Mro";
import MroForm from "./mro/MroForm";
import CRMResident from "./property/CRMResident";
import CRMResidentFrom from "./property/CRMResidentFrom";
import AppPropertySetting from "./marketing/AppPropertySetting";

// import messages from "./property/messages";
import MessageIndex from "./message/MessageIndex";
import QuickyReply from "./settings/QuickyReply";
import QuickyReplyAdd from "./settings/QuickyReplyAdd";
import Emails from "./settings/Emails";
import EmailView from "./settings/EmailView";
import EmailForm from "./settings/EmailForm";

import AllTemplates from "./all-templates/Templates";
// import AllTemplateView from "./all-templates/TemplateView";
import AllTemplateForm from "./all-templates/TemplateForm";
import Schedular from "./schedular/Schedular";
import SchedularForm from "./schedular/SchedularForm";

import RefundablePolicy from "./marketing/RefundablePolicy";

import RentalLookups from "./ru/RentalLookups";
import RentalMasterData from "./ru/RentalMasterData";
import RentalPropertyLookup from "./ru/RentalPropertyLookup";
import RentalUnitLookup from "./ru/RentalUnitLookup";
import RentalListingLookup from "./ru/RentalListingLookup";
import RentalListingLicenseLookup from "./ru/RentalListingLicenseLookup";
import RentalOtherData from "./ru/RentalOtherData";
import WebsiteCookies from "./marketing/WebsiteCookies";
import TermsConditions from "./marketing/TermsConditions";
import CompanyCms from "./company/CompanyCms";
import Channel from "./property-setup/Channel";
import KeycafeMapping from "./partner/keycafe/Mapping";
import YaleMapping from "./partner/yale/Mapping";
import SchlageMapping from "./partner/schlage/Mapping";
import PropertyTypeLookup from "./rulookup-mapping/PropertyTypeLookup";
import AmenityTypeLookup from "./rulookup-mapping/AmenityTypeLookup";
import DestinationLookup from "./rulookup-mapping/DestinationLookup";
import FeeTypeLookup from "./rulookup-mapping/FeeTypeLookup";
import TaxesLookup from "./rulookup-mapping/TaxesLookup";
import CountryLookup from "./rulookup-mapping/CountryLookup";
import StateLookup from "./rulookup-mapping/StateLookup";
import CityLookup from "./rulookup-mapping/CityLookup";
import MasterTaxLookup from "./rulookup-mapping/MasterTaxLookup";
import AgentTypeLookup from "./rulookup-mapping/AgentTypeLookup";
import OtaTypeLookup from "./rulookup-mapping/OtaTypeLookup";
import QuerySupport from "./query-support/QuerySupport";
import Credentails from "./all-credentails/Credentails";
import CredentailsAdd from "./all-credentails/CredentailsFrom";
import TBNBMapping from "./partner/turnoverbnb/TBNBMapping";
import Faqs from "./faqs/Faqs";
import FaqsForm from "./faqs/FaqsForm";
import FaqsView from "./faqs/FaqsView";
import KBCategory from "./property-setup/KBCategory";
import KBSubCategory from "./property-setup/KBSubCategory";
import Problems from "./mro/Problems";
import ProblemForm from "./mro/ProblemForm";
import HouseKeepingView from "./housekeeping/HouseKeepingView";
import ActivityView from "./partner/keycafe/ActivityView";
import AutomationProcessingLog from "./property/AutomationProcessingLog";
import FloorPlanMapping from "./property/FloorPlanMapping";
import AutomationDataHubSeetings from "./property/AutomationDataHubSeetings";
import ExportAutomationDataHubSeetings from "./property/ExportAutomationDataHubSeetings";
import KeyView from "./partner/keycafe/KeyView";
import KeyLogs from "./partner/keycafe/KeyLogs";
import LatchMapping from "./partner/latch/Mapping";
import IglooMapping from "./partner/Igloo/Mapping";
import IglooAdd from "./partner/Igloo/IglooAdd";
import LatchActivityHistory from "./partner/latch/Activity";
import LatchAccessView from "./partner/latch/AccessView";
import CrmTemplates from "./CRM/CrmTemplates";
import CrmTemplateForm from "./CRM/CrmTemplateForm";
import CrmListForm from "./CRM/CrmListForm";
import CrmManualListForm from "./CRM/CrmManualListForm";
import CrmCsvListForm from "./CRM/CrmCsvListForm";
import CrmList from "./CRM/CrmList";
import CrmContact from "./CRM/CrmContact";
import CrmSubscribers from "./CRM/CrmSubscribers";
import CrmListView from "./CRM/CrmListView";
import CrmCampaignForm from "./CRM/CrmCampaignForm";
import CrmCampaign from "./CRM/CrmCampaign";
import CrmCampaignReportView from "./CRM/CrmCampaignReportView";
import StripeTotalPayout from "./reports/StripeTotalPayout";
import StripePayoutSummary from "./reports/StripePayoutSummary";

import RUPayout from "./reports/RUPayout";
import AirDNACityMapping from "./rulookup-mapping/AirDNACityMapping";
import CrmUnsubscribes from "./CRM/CrmUnsubscribes";
import UploadVideo from "./corporate/UploadVideo";
import UploadedVideoView from "./corporate/UploadedVideoView";
import UploadVideoAddForm from "./corporate/UploadVideoAddForm";

import InquiryView from "./inquiry/InquiryView";
import GuestReviews from "./reservation/GuestReviews";

import ClubCms from "./club/ClubCms";
/* Cms Import */
// import CommonThemeSetting from "./theme/CommonThemeSetting";
// import ThemePages from "./theme/ThemePages";
// import ThemePageSections from "./theme/ThemePageSections";

/* Ambassadors */
import Ambassadors from "./property/Ambassadors";
import AmbassadorsForm from "./property/AmbassadorsForm";
import AmbassadorsView from "./property/AmbassadorsView";
import GuestDeposite from "./reports/GuestDeposite";
import GuestWaiver from "./reports/GuestWaiver";
import HomeSharingInquiries from "./home-sharing-inquiries/HomeSharingInquiries";
import HomeSharingInquiriesAddForm from "./home-sharing-inquiries/HomeSharingInquiriesAddForm";
import HomeSharingInquiryView from "./home-sharing-inquiries/HomeSharingInquiryView";

import GuestWavioTransfer from "./reports/GuestWavioTransfer";
import GuestWavioTransferList from "./reports/GuestWavioTransferList";
import GuestWavioTransferView from "./reports/GuestWavioTransferView";
import MonthlyInvestorReport from "./reports/MonthlyInvestorReport.jsx";
export class AppRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Add routes name to bypass login
      byPassLoginRoutes: [
        "/",
        "/signin",
        "/2fa-signin",
        "/logout",
        "/page-403",
        "/forgot-password",
        "/reset-password",
        "/generate-password",
      ],
    };
  }

  render() {
    // Check headerToken exist or not
    let pathname = window.location.pathname;
    let items = pathname.split("/").slice(-2);
    let routeName = items[0] ? "/" + items[0] : pathname;
    if (pathname.includes("mobile-reservation-calendar")) {
      //allow to open page
    } else if (
      !global.headerToken &&
      !this.state.byPassLoginRoutes.includes(routeName)
    ) {
      return <Redirect to="/logout" />;
    }
    if (localStorage.getItem("headerToken")) {
      !sessionStorage.getItem("property_slug") &&
        sessionStorage.setItem(
          "property_slug",
          localStorage.getItem("property_slug")
        );
      !sessionStorage.getItem("property_name") &&
        sessionStorage.setItem(
          "property_name",
          localStorage.getItem("property_name")
        );
      !sessionStorage.getItem("property_sku") &&
        sessionStorage.setItem(
          "property_sku",
          localStorage.getItem("property_sku")
        );
    }
    return (
      <Suspense fallback="">
        <Switch>
          <Route exact path="/">
            <Redirect to="/signin"></Redirect>
          </Route>
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <Route
            exact
            path="/generate-password/:token"
            component={GeneratePassword}
          />
          <Route
            exact
            path="/update-profile"
            render={(props) => (
              <UpdateProfile imageUpload={this.props.updateProfileImage} />
            )}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/update-profile"
            render={(props) => (
              <UpdateProfile imageUpload={this.props.updateProfileImage} />
            )}
          />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route
            exact
            path="/properties/view/:propertySlug/change-password"
            component={ChangePassword}
          />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/page-403" component={Page403} />
          {/* <Route
            exact
            path="/role-permission"
            component={
              global.userPermissions.checkPermission("manage-role")
                ? RolePermissionList
                : Page403
            }
          />
          <Route
            exact
            path="/role-permission/add"
            component={
              global.userPermissions.checkPermission("manage-role")
                ? RolePermission
                : Page403
            }
          />
          <Route
            exact
            path="/role-permission/update/:slug"
            component={
              global.userPermissions.checkPermission("manage-role")
                ? RolePermission
                : Page403
            }
          />*/}
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/key-data-dashboard" component={KDD} />
          {/* <Route exact path="/landing" component={Landing} /> */}
          <Route exact path="/store" component={Store} />
          <Route exact path="/analytics" component={Analytics} />
          <Route exact path="/properties" component={Properties} />
          <Route
            exact
            path="/properties/view/:propertySlug"
            component={PropertiesView}
          />
          {/* <Route
            exact
            path="/properties/view/:propertySlug/images"
            component={() => (
              <PropertiesImages
                imageUploadLogo={this.props.updatePropertyLogo}
              />
            )}
          /> */}
          <Route exact path="/properties/add" component={PropertiesAddForm} />
          <Route
            exact
            path="/properties/update/:slug"
            component={PropertiesForm}
          />
          <Route
            exact
            path="/properties/update/:slug/:view"
            component={PropertiesForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units"
            component={Units}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/add"
            component={UnitsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/update/:slug"
            component={UnitsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/view/:slug"
            component={UnitsView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/unit-basic"
            component={UnitsView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/unit-images"
            component={UnitsImages}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/unit-availability"
            component={UnitAval}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/unit-documents"
            component={UnitDocuments}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/unit-amenities"
            component={UnitAmenities}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/unit-cost"
            component={UnitCost}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/view/:unitSlug/unit-documents/update/:slug"
            component={UnitsDocForm}
          />
          {/* <Route exact path="/properties/view/:propertySlug/units/:slug/:rooms" component={UnitRooms} /> */}
          <Route
            exact
            path="/properties/view/:propertySlug/property-fees"
            component={PropertyFees}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-parking"
            component={Parking}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-credential"
            component={PropertyCredential}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/compliance"
            component={Compliance}
          />
          {/* <Route exact path="/properties/view/:propertySlug/rooms" component={Rooms} />
          <Route exact path="/properties/view/:propertySlug/rooms/add" component={RoomsForm} />
          <Route exact path="/properties/view/:propertySlug/rooms/update/:slug" component={RoomsForm} />
          <Route exact path="/properties/view/:propertySlug/rooms/view/:slug" component={RoomsView} /> */}
          <Route
            exact
            path="/properties/view/:propertySlug/marketing/lead-tracking-contacts"
            component={Contact}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/app-property-setting"
            component={AppPropertySetting}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/events"
            component={Events}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/events/add"
            component={EventsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/events/update/:slug"
            component={EventsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/events/view/:slug"
            component={EventsView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/pet-policy"
            component={PetPolicy}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/pet-policy/add"
            component={PetPolicyForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/pet-policy/update/:slug"
            component={PetPolicyForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-dashboard-overview"
            render={(props) => (
              <PropertyDashboardOverview
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-overview"
            render={(props) => (
              <PropertyOverview
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                propertyLogo={this.props.propertyLogo}
                propertySKU={this.props.propertySKU}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/building-onboarding"
            render={(props) => (
              <BuildingOnboarding
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                propertyLogo={this.props.propertyLogo}
                propertySKU={this.props.propertySKU}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/basic-new"
            render={(props) => (
              <BasicNew
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          {/* <Route exact path="/properties/view/:propertySlug/basic-new" component={BasicNew} /> */}
          <Route
            exact
            path="/properties/view/:propertySlug/basic"
            component={PropertiesView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/legal-finance"
            component={PropertyLegalEntity}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/owners"
            component={PropertyOwner}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/owners/add"
            component={PropertyOwnerForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/owners/update/:ownerSlug"
            component={PropertyOwnerForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/tower"
            component={Tower}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/tower/add"
            component={TowerForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/tower/update/:slug"
            component={TowerForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/amenitites"
            component={PropertyAmenities}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/nearby-attractions-old"
            component={PropertyNearByAttractionOld}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/nearby-attractions"
            component={PropertyNearByAttraction}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/nearby-attractions/add"
            component={PropertyNearByAttractionForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/nearby-attractions/update/:slug"
            component={PropertyNearByAttractionForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floor-plans"
            component={FloorPlan}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floor-plans/add"
            component={FloorPlanForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floor-plans/update/:slug"
            component={FloorPlanForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floor-plans/view/:slug"
            component={FloorPlanView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-level-tax"
            component={PropertyLevelTax}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-level-tax/add"
            component={PropertyLevelTaxForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-level-tax/update/:slug"
            component={PropertyLevelTaxForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-pricing"
            component={PropertyPrice}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-pricing/add"
            component={PropertyPriceForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-pricing/update/:slug"
            component={PropertyPriceForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-cost"
            component={PropertyCost}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-cost/add"
            component={PropertyCostForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-cost/update/:slug"
            component={PropertyCostForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-documents"
            component={PropertyDocuments}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-documents/add"
            component={PropertyDocumentsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-documents/update/:slug"
            component={PropertyDocumentsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floors"
            component={Floor}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floors/add"
            component={FloorForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floors/update/:slug"
            component={FloorForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/staff"
            component={Staff}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/staff/add"
            component={StaffForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/staff/update/:slug"
            component={StaffForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/staff/view/:slug"
            component={StaffView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/staff/first/:slug"
            component={Staff}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/vendor"
            component={Vendor}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/vendor/add"
            component={VendorForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/vendor/update/:slug"
            component={VendorForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/vendor/first/:slug"
            component={Vendor}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-resident"
            component={CRMResident}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-resident/add"
            component={CRMResidentFrom}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-resident/update/:slug"
            component={CRMResidentFrom}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-resident/first/:slug"
            component={CRMResident}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/homesharing-listings"
            component={HomeSharingListing}
          />
          {/* <Route exact path="/properties/view/:propertySlug/reservations" component={HomeSharingReservation} /> */}
          {/* <Route exact path="/properties/view/:propertySlug/guests" component={HomeSharingGuests} /> */}
          <Route
            exact
            path="/properties/view/:propertySlug/homesharing-payments"
            component={HomeSharingPayments}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/availability"
            component={UnitsAvailability}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/leasing-listings"
            component={LeasingListing}
          />
          {/* <Route
            exact
            path="/properties/view/:propertySlug/residents"
            component={LeasingResidents}
          /> */}
          {/* <Route
            exact
            path="/properties/view/:propertySlug/application"
            component={Application}
          /> */}
          <Route
            exact
            path="/properties/view/:propertySlug/tours"
            component={Tours}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/virtual-tour"
            component={VirtualTour}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/rent"
            component={LeasingRents}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/unit-rent/add"
            component={RentForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/unit-rent/update/:slug"
            component={RentForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/checklists"
            component={Checklist}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/checklists/add"
            component={ChecklistForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/checklists/:slug"
            component={ChecklistForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/checklists/import/:slug"
            component={ChecklistForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/checklists/update/:slug"
            component={ChecklistForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/inspections"
            component={Inspections}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/inspections/:slug"
            component={InspectionView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/mros"
            component={Mro}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/mros/add"
            component={MroForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/mros/:slug"
            component={MroForm}
          />
          {/* <Route
            exact
            path="/properties/view/:propertySlug/templates"
            component={Templates}
          />
           <Route
            exact
            path="/properties/view/:propertySlug/templates/add"
            component={TemplatesForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/templates/:slug"
            component={TemplatesForm}
          /> */}
          <Route
            exact
            path="/properties/view/:propertySlug/housekeeping-calender"
            component={HouseKeepingCalendar}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/keys"
            component={Keys}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/housekeeping-list"
            render={(props) => (
              <HouseKeepingList
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/housekeeping/add"
            component={HouseKeepingForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/maintenance-checklists"
            component={MaintenanceChecklist}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/maintenance-calendar"
            component={MaintenanceCalendar}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/maintenance-inbox"
            component={MaintenanceInbox}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/housekeeping-vendoor-list"
            component={Vendors}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/bills"
            component={Bills}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/payments"
            component={Payments}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/invoices"
            component={Invoices}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/work-order"
            component={WorkOrders}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/housekeeping-payments"
            component={HouseKeepingPayments}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/requests"
            component={Request}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/maintenance-request"
            component={MaintenanceRequest}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/guest-chat"
            component={RequestChat}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/unified-calendar"
            component={UnifiedCalendar}
          />
          <Route
            exact
            path="/global-unified-calendar"
            component={UnifiedCalendar}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/reservation-calendar"
            component={ReservationCalendar}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/mobile-reservation-calendar"
            component={ReservationWebViewCalendar}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/checkin-calender"
            component={CheckinCalendar}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/checkout-calender"
            component={CheckoutCalendar}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/orders"
            component={Orders}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/items"
            component={Items}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/inventory"
            component={Inventory}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/sales"
            component={Sales}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/money-paid"
            component={MoneyPaid}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/expenses"
            component={Expenses}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/locks"
            component={Locks}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/chatbot"
            component={Chatbot}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/noiseware"
            component={NoiseWare}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/airbnb"
            component={Airbnb}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/google"
            component={Google}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/ils-export"
            component={IlsExport}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/ota-export"
            component={OtaExport}
          />
          {/* for reports */}
          <Route
            exact
            path="/properties/view/:propertySlug/occupancy-reports"
            render={(props) => (
              <EarningsReports
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/homesharing-reports"
            component={HomeSharingReport}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/monthly-investor-report"
            component={MonthlyInvestorReport}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/resident-payout"
            component={ResidentPayoutsReports}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/res-payout-summary"
            component={ResidentPayoutReportList}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/create-payout-resident"
            component={ResidentPayoutsReportsCreate}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/edit-payout-resident/:slug"
            component={ResidentPayoutsReportsCreate}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-tax"
            component={PropertyTaxReports}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/top-performer"
            component={TopPerformerReport}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/ru-payout"
            component={RUPayout}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/guest-form-deposit"
            component={GuestDeposite}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/guest-form-waiver"
            component={GuestWaiver}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/wavio-transfer-fee-list/create"
            component={GuestWavioTransfer}
          />
          <Route
            exact
            path="/wavio-transfer-fee-list/create/:outsidePropertySlug"
            component={GuestWavioTransfer}
          />
          <Route
            exact
            path="/wavio-transfer-fee-list/create"
            component={GuestWavioTransfer}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/wavio-transfer-fee-list"
            component={GuestWavioTransferList}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/wavio-transfer-fee-list/:reportSlug"
            component={GuestWavioTransferView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-payout-report"
            component={PayoutsReports}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-unit-payout"
            component={PropertyPayoutsReportsByUnit}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-reservation-payout"
            component={PropertyPayoutsReportsByReservation}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/oh-earning-reports"
            component={OhEarningReport}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/quarterly-tax-reports"
            component={QuarterlyTaxReports}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-reports"
            component={PropertyReports}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/leasing-reports"
            component={LeasingReports}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/reservation-reports"
            component={ReservationReports}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/housekeeping-reports"
            component={HousekeepingReports}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/guest-reports"
            component={GuestReports}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/resident-reports"
            component={ResidentReports}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/accounting-reports"
            component={AccountingReports}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/shop-reports"
            component={ShopReports}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/leads"
            component={Leads}
          />
          <Route exact path="/contact-lists" component={ContactLists} />
          <Route exact path="/contact-list" component={ContactList} />
          <Route exact path="/campaigns" component={Campaigns} />
          <Route exact path="/segments" component={Segments} />
          <Route
            exact
            path="/properties/view/:propertySlug/property-contact-lists"
            component={PropertyContactLists}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-campaigns"
            component={PropertyCampaigns}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-segments"
            component={PropertySegments}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units"
            component={Units}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/add"
            component={UnitsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/update/:slug"
            component={UnitsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/view/:slug"
            component={UnitsView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/basic"
            component={Basic}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/images"
            component={UnitsImages}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/availability"
            component={UnitAval}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/documents"
            component={UnitDocuments}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/amenities"
            component={UnitAmenities}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/cost"
            component={UnitCost}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/view/:unitSlug/documents/update/:slug"
            component={UnitsDocForm}
          />
          {/* <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/:rooms"
            component={UnitRooms}
          />

          <Route
            exact
            path="/properties/view/:propertySlug/rooms"
            component={Rooms}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/rooms/add"
            component={RoomsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/rooms/update/:roomSlug"
            component={RoomsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/units/:slug/rooms/view/:roomSlug"
            component={RoomsView}
          /> */}
          <Route
            exact
            path="/properties/view/:propertySlug/lead-tracking-contacts"
            component={LeadTrackingContacts}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/marketing-copy"
            component={MarketingCopy}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/refundable-policy"
            component={RefundablePolicy}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/website-cookies"
            component={WebsiteCookies}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/terms-conditions"
            component={TermsConditions}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/house-rules"
            component={HouseRules}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/arrival-instructions"
            component={ArrivalInstructions}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/checkout-instructions"
            component={CheckoutInstructions}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/house-manual"
            component={GuestManual}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/health-safety"
            component={HealthSafety}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/getting-around"
            component={GettingAround}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/noiseaware-template"
            component={NoiseawareTemplate}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/noiseaware-template/add"
            component={NoiseawareTemplateForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/noiseaware-template/update/:slug"
            component={NoiseawareTemplateForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/keycafe-mapping"
            component={KeycafeMapping}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/yale-mapping"
            component={YaleMapping}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/schlage-mapping"
            component={SchlageMapping}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-amenities"
            component={Amenities}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-accessibility"
            component={Accessibility}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/neighborhood"
            component={Neighborhood}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/near-by-attractions"
            component={NearByAttraction}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/near-by-attractions/view/:slug"
            component={NearByAttractionView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/near-by-attractions/add"
            component={NearByAttractionForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/near-by-attractions/update/:slug"
            component={NearByAttractionForm}
          />

          <Route
            exact
            path="/properties/view/:propertySlug/ecommerce"
            component={EcommerceList}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/ecommerce/add"
            component={EcommerceForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/ecommerce/update/:slug"
            component={EcommerceForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/ecommerce/view/:slug"
            component={EcommerceView}
          />

          <Route
            exact
            path="/properties/view/:propertySlug/neighbourhood"
            component={NeighborhoodList}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/neighbourhood/view/:slug"
            component={NeighborhoodView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/neighbourhood/add"
            component={NeighborhoodForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/neighbourhood/update/:slug"
            component={NeighborhoodForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/images"
            component={Images}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/retail"
            component={Retail}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/services"
            component={Services}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/features"
            component={Features}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-cms-setting"
            component={Websites}
          />
          <Route
            exact
            path="/brand/view/:slug/brand-cms"
            component={BrandCms}
          />
          <Route exact path="/club/view/:slug/club-cms" component={ClubCms} />
          <Route
            exact
            path="/brand/view/:slug/brand-legal-finance"
            component={BrandLegalFinance}
          />
          <Route exact path="/corporate-website" component={CompanyCms} />
          <Route
            exact
            path="/properties/view/:propertySlug/pet-policy"
            component={PetPolicy}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/pet-policy/add"
            component={PetPolicyForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/pet-policy/update/:slug"
            component={PetPolicyForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/basic-new"
            component={BasicNew}
          />

          <Route
            exact
            path="/properties/view/:propertySlug/property-payout-configuration"
            component={PropertyPayoutConfiguration}
          />

          <Route
            exact
            path="/properties/view/:propertySlug/legal-finance"
            component={PropertyLegalEntity}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/owners"
            component={PropertyOwner}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/owners/add"
            component={PropertyOwnerForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/owners/update/:ownerSlug"
            component={PropertyOwnerForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/tower"
            component={Tower}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/tower/add"
            component={TowerForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/tower/update/:slug"
            component={TowerForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/amenitites"
            component={PropertyAmenities}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/nearby-attractions-old"
            component={PropertyNearByAttractionOld}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/nearby-attractions"
            component={PropertyNearByAttraction}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/nearby-attractions/add"
            component={PropertyNearByAttractionForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/nearby-attractions/update/:slug"
            component={PropertyNearByAttractionForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/events"
            component={Events}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/events/add"
            component={EventsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/events/update/:slug"
            component={EventsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/events/view/:slug"
            component={EventsView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/influencers"
            component={Influencers}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/influencers/add"
            component={InfluencersForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/influencers/update/:slug"
            component={InfluencersForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/influencers/view/:slug"
            component={InfluencerView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/ambassadors"
            component={Ambassadors}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/ambassadors/add"
            component={AmbassadorsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/ambassadors/update/:slug"
            component={AmbassadorsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/ambassadors/view/:slug"
            component={AmbassadorsView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/applicants"
            component={Applicants}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/applicants/add"
            component={ApplicantsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/applicants/update/:slug"
            component={ApplicantsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/applicants/view/:slug"
            component={ApplicantView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/applicant-detail"
            component={ApplicantsDetail}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/applicant-detail/update/:slug"
            component={ApplicantDetailsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/applicant-detail/view/:slug"
            component={ApplicantDetailView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floor-plans"
            component={FloorPlan}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floor-plans/add"
            component={FloorPlanForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floor-plans/update/:slug"
            component={FloorPlanForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floor-plans/view/:slug"
            component={FloorPlanView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-level-tax"
            component={PropertyLevelTax}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-level-tax/add"
            component={PropertyLevelTaxForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-level-tax/update/:slug"
            component={PropertyLevelTaxForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-pricing"
            component={PropertyPrice}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-pricing/add"
            component={PropertyPriceForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-pricing/update/:slug"
            component={PropertyPriceForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-cost"
            component={PropertyCost}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-cost/add"
            component={PropertyCostForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-cost/update/:slug"
            component={PropertyCostForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-documents"
            component={PropertyDocuments}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-documents/add"
            component={PropertyDocumentsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-documents/update/:slug"
            component={PropertyDocumentsForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floors"
            component={Floor}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floors/add"
            component={FloorForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floors/update/:slug"
            component={FloorForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/staff"
            component={Staff}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/staff/add"
            component={StaffForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/staff/update/:slug"
            component={StaffForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/staff/view/:slug"
            component={StaffView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/staff/first/:slug"
            component={Staff}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/vendor"
            component={Vendor}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/vendor/add"
            component={VendorForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/vendor/update/:slug"
            component={VendorForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/vendor/first/:slug"
            component={Vendor}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/homesharing-listings"
            component={HomeSharingListing}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/reservation"
            component={HomeSharingReservation}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/homesharing-payments"
            component={HomeSharingPayments}
          />
          <Route exact path="/ru-lookups" component={RentalLookups} />
          <Route exact path="/ru-master-data" component={RentalMasterData} />
          <Route
            exact
            path="/ru-property-data"
            component={RentalPropertyLookup}
          />
          <Route exact path="/ru-unit-data" component={RentalUnitLookup} />
          <Route
            exact
            path="/ru-listing-data"
            component={RentalListingLookup}
          />
          <Route
            exact
            path="/ru-listing-license-data"
            component={RentalListingLicenseLookup}
          />
          <Route exact path="/ru-other-data" component={RentalOtherData} />
          <Route exact path="/company" component={Company} />
          <Route exact path="/overview" component={CompanyOverview} />
          <Route exact path="/snapshot" component={SnapshotOverview} />
          <Route exact path="/company/update/:slug" component={CompanyForm} />
          <Route exact path="/brands" component={Brand} />
          <Route exact path="/brand/add" component={BrandForm} />
          <Route
            exact
            path="/brand/view/:slug/brand-info"
            component={BrandView}
          />
          <Route exact path="/brand/update/:slug" component={BrandForm} />

          <Route exact path="/clubs" component={Club} />
          <Route exact path="/club/add" component={ClubForm} />
          <Route exact path="/club/view/:slug/club-info" component={ClubView} />
          <Route exact path="/club/update/:slug" component={ClubForm} />

          <Route exact path="/clients" component={Client} />
          <Route exact path="/client/add" component={ClientForm} />
          <Route
            exact
            path="/client/view/:slug/client-info"
            component={ClientView}
          />
          <Route exact path="/client/update/:slug" component={ClientForm} />
          {/* Query support routes */}
          <Route exact path="/query-support" component={QuerySupport} />

          {/* all credentials routes */}
          <Route exact path="/credentials" component={Credentails} />
          <Route exact path="/credentials/add" component={CredentailsAdd} />
          <Route
            exact
            path="/credentials/update/:slug"
            component={CredentailsAdd}
          />

          {/** faqs routes */}
          <Route exact path="/faqs" component={Faqs} />
          <Route exact path="/faq/add" component={FaqsForm} />
          <Route exact path="/faq/update/:slug" component={FaqsForm} />
          <Route exact path="/faq/view/:slug" component={FaqsView} />

          {/** user routes */}
          <Route exact path="/users" component={Users} />
          <Route exact path="/users/add" component={UsersForm} />
          <Route exact path="/users/update/:slug" component={UsersForm} />
          <Route exact path="/roles" component={Roles} />
          <Route exact path="/role/add" component={RolesForm} />
          <Route
            exact
            path="/role-permission/:slug"
            component={RolePermission}
          />

          {/* master routes */}
          <Route
            exact
            path="/master-setup"
            component={AccessibilityFeatureType}
          />
          <Route exact path="/affordable-type" component={AffordableType} />
          <Route
            exact
            path="/accessibility-feature-type"
            component={AccessibilityFeatureType}
          />
          <Route
            exact
            path="/accessibility-features"
            component={AccessibilityFeature}
          />
          {/* <Route exact path="/affordable-type" component={MasterSetup} /> */}
          <Route exact path="/agency-category" component={AgencyCategory} />
          <Route exact path="/amenity-type" component={AmenityType} />
          <Route exact path="/amenities" component={Amenity} />
          <Route exact path="/attraction-type" component={AttractionType} />
          <Route exact path="/property-type" component={PropertyType} />
          <Route exact path="/class-type" component={CommServed} />
          <Route exact path="/document-type" component={DocumentType} />
          <Route exact path="/event-type" component={EventType} />
          <Route exact path="/flooring-type" component={Flooring} />
          <Route exact path="/heating-type" component={Heating} />
          <Route exact path="/faq-category" component={KBCategory} />
          <Route exact path="/faq-sub-category" component={KBSubCategory} />
          <Route exact path="/leed-type" component={LeedBuilding} />
          <Route exact path="/location-type" component={LocationType} />
          <Route exact path="/mro-category" component={MROCategory} />
          <Route exact path="/ownership-type" component={OwnershipType} />
          <Route exact path="/parking-type" component={ParkingType} />
          <Route exact path="/pet-type" component={PetType} />
          <Route exact path="/room-category" component={RoomCategory} />
          <Route exact path="/room-type" component={RoomType} />
          <Route exact path="/smoking-type" component={SmokingType} />
          <Route exact path="/staff-type" component={StaffContactType} />
          <Route exact path="/suitability" component={PropertySuitability} />
          <Route exact path="/templates" component={Templates} />
          <Route exact path="/timezone" component={Timezone} />
          <Route exact path="/cost-payable-at" component={CostPayableAt} />
          <Route exact path="/cost-payable-by" component={CostPayableBy} />
          <Route exact path="/cost-type" component={CostType} />
          <Route exact path="/taxes" component={GeneralTax} />
          <Route exact path="/tax-type" component={TaxType} />
          <Route exact path="/country" component={Country} />
          <Route exact path="/county" component={County} />
          <Route exact path="/state" component={State} />
          <Route exact path="/city" component={City} />
          <Route exact path="/metro" component={Metro} />
          <Route exact path="/sub-metro" component={SubMetro} />
          <Route exact path="/channel" component={Channel} />
          <Route
            exact
            path="/floor-plans-lookup"
            component={PropertyTypeLookup}
          />
          <Route exact path="/amenity-lookup" component={AmenityTypeLookup} />
          <Route exact path="/near-by-lookup" component={DestinationLookup} />
          <Route exact path="/master-tax-lookup" component={MasterTaxLookup} />
          <Route exact path="/taxes-lookup" component={TaxesLookup} />
          <Route exact path="/fee-lookup" component={FeeTypeLookup} />
          <Route exact path="/countries-lookup" component={CountryLookup} />
          <Route exact path="/lookup-states" component={StateLookup} />
          <Route exact path="/cities-lookup" component={CityLookup} />
          <Route
            exact
            path="/airDNA-cities-lookup"
            component={AirDNACityMapping}
          />
          <Route exact path="/channels-lookup" component={AgentTypeLookup} />
          <Route
            exact
            path="/properties-ota-lookup"
            component={OtaTypeLookup}
          />
          <Route exact path="/templates/add" component={TemplatesForm} />
          <Route exact path="/templates/:slug" component={TemplatesForm} />
          <Route
            exact
            path="/properties/view/:propertySlug/list-calendar"
            component={ListingCalendar}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/multi-unit-listing"
            component={Listings}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/listing"
            component={Listings}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/listing/add"
            component={ListingForm}
          />
          {/* <Route
            exact
            path="/properties/view/:propertySlug/listing/:slug/info"
            component={ListingView}
          /> */}
          <Route
            exact
            path="/properties/view/:propertySlug/listing/:slug/info"
            render={(props) => (
              <ListingView
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/listing/update/:slug"
            component={ListingForm}
          />

          {/** corporateApp routes*/}
          <Route exact path="/corporate-apps" component={CorporateApp} />
          <Route exact path="/upload-video" component={UploadVideo} />
          <Route
            exact
            path="/upload-video/update/:slug/info"
            component={UploadedVideoView}
          />
          <Route
            exact
            path="/corporate-apps/add"
            component={CorporateAppForm}
          />
          <Route
            exact
            path="/upload-video/add"
            component={UploadVideoAddForm}
          />
          <Route
            exact
            path="/upload-video/update/:slug"
            component={UploadVideoAddForm}
          />

          <Route
            exact
            path="/corporate-apps/update/:slug"
            component={CorporateAppForm}
          />
          <Route
            exact
            path="/corporate-apps/view/:slug"
            component={CorporateAppView}
          />

          {/* for leasing start */}
          <Route
            exact
            path="/properties/view/:propertySlug/leasing"
            component={Leasing}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/leasing/add"
            component={LeasingForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/leasing/:slug/info"
            component={LeasingView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/leasing/update/:slug"
            component={LeasingForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/leasing-checklists"
            component={LeasingChecklist}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/lease-avail-calendar"
            component={LeaseAvalCalendar}
          />
          {/* for leasing start */}
          <Route
            exact
            path="/properties/view/:propertySlug/reservations"
            render={(props) => (
              <Reservations
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/reservations/date/:listingSlug"
            render={(props) => (
              <Reservations
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/reservations/add"
            component={ReservationForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/reservations/update/:slug"
            component={ReservationForm}
          />
          {/* <Route
            exact
            path="/properties/view/:propertySlug/reservations/view/:slug"
            component={ReservationView}
          /> */}
          <Route
            exact
            path="/properties/view/:propertySlug/reservations/view/:slug"
            render={(props) => (
              <ReservationView
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/reservations-activity/:slug"
            render={(props) => (
              <ReservationView
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />

          <Route
            exact
            path="/properties/view/:propertySlug/guest"
            component={Guest}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/guest/add"
            component={GuestForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/guest/update/:slug"
            component={GuestForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/guest/view/:slug"
            component={GuestView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/residents"
            component={Resident}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/residents/add"
            component={ResidentForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/residents/update/:slug"
            component={ResidentForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/residents/view/:slug"
            component={ResidentView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/resident-checklists"
            component={ResidentChecklist}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/resident-payouts"
            component={ResidentPayout}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/resident-inbox"
            component={ResidentInbox}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/reservations/view/:reservationSlug/messages/:threadSlug"
            render={(props) => (
              <MessageIndex
                propertySlug={this.props.propertySlug}
                socketData={this.props.socketData}
                updatePropsGlobalmsg={this.props.updatePropsGlobalmsg}
                msgCountGlobal={this.props.msgCountGlobal}
                {...props}
              />
            )}
            //component={MessageIndex}
          />

          <Route
            exact
            path="/properties/view/:propertySlug/messages"
            render={(props) => (
              <MessageIndex
                propertySlug={this.props.propertySlug}
                socketData={this.props.socketData}
                updatePropsGlobalmsg={this.props.updatePropsGlobalmsg}
                msgCountGlobal={this.props.msgCountGlobal}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/messages/:threadSlug"
            //component={MessageIndex}
            render={(props) => (
              <MessageIndex
                propertySlug={this.props.propertySlug}
                socketData={this.props.socketData}
                updatePropsGlobalmsg={this.props.updatePropsGlobalmsg}
                msgCountGlobal={this.props.msgCountGlobal}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/messages"
            render={(props) => (
              <MessageIndex
                propertySlug={this.props.propertySlug}
                socketData={this.props.socketData}
                updatePropsGlobalmsg={this.props.updatePropsGlobalmsg}
                msgCountGlobal={this.props.msgCountGlobal}
              />
            )}
          />
          <Route
            exact
            path="/messages/:threadSlug"
            render={(props) => (
              <MessageIndex
                propertySlug={this.props.propertySlug}
                socketData={this.props.socketData}
                updatePropsGlobalmsg={this.props.updatePropsGlobalmsg}
                msgCountGlobal={this.props.msgCountGlobal}
                {...props}
              />
            )}
            // component={MessageIndex}
          />
          <Route exact path="/quick-replies" component={QuickyReply} />
          <Route exact path="/quick-replies/add" component={QuickyReplyAdd} />
          <Route
            exact
            path="/quick-replies/update/:slug"
            component={QuickyReplyAdd}
          />
          <Route exact path="/emails" component={Emails} />
          <Route exact path="/email/add" component={EmailForm} />
          <Route exact path="/email/view/:slug" component={EmailView} />
          <Route exact path="/email/update/:slug" component={EmailForm} />

          <Route exact path="/scheduler" component={Schedular} />
          <Route exact path="/scheduler/add" component={SchedularForm} />
          <Route
            exact
            path="/scheduler/update/:slug"
            component={SchedularForm}
          />

          <Route exact path="/all-template" component={AllTemplates} />
          <Route exact path="/all-template/add" component={AllTemplateForm} />
          {/* <Route exact path="/all-template/view/:slug" component={AllTemplateView} /> */}
          <Route
            exact
            path="/all-template/update/:slug"
            component={AllTemplateForm}
          />

          <Route
            exact
            path="/properties/view/:propertySlug/tbnb-mapping"
            component={TBNBMapping}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/problems"
            component={Problems}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/problems/:slug"
            component={ProblemForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/housekeeping-list/:slug"
            component={HouseKeepingView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/keys/activity-add"
            component={ActivityAdd}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/keys/activity-view/:slug"
            component={ActivityView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/keys/activity-update/:slug"
            component={ActivityAdd}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/latches/activity-add"
            component={ActivityAddLatch}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/latches/activity-view/:slug"
            component={ActivityViewLatch}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/latches/activity-update/:slug"
            component={ActivityAddLatch}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/automation-processing-log-import/:module_name"
            component={AutomationProcessingLog}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/automation-processing-log-export/:module_name"
            component={AutomationProcessingLog}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/keys/keyactivity-view/:slug"
            component={KeyView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/keys/keyactivity-logs/:slug"
            component={KeyLogs}
          />
          {/* OutSide Modules Start */}
          {/* Listing Start */}
          <Route exact path="/listings" component={Listings} />
          <Route
            exact
            path="/homesharing-inquiries"
            component={HomeSharingInquiries}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/homesharing-inquiries"
            component={HomeSharingInquiries}
          />
          <Route
            exact
            path="/homesharing-inquiries/add/:outsidePropertySlug"
            component={HomeSharingInquiriesAddForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/homesharing-inquiries/add"
            component={HomeSharingInquiriesAddForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/homesharing-inquiries/view/:slug"
            component={HomeSharingInquiryView}
          />
          <Route
            exact
            path="/homesharing-inquiries/view/:outsidePropertySlug/:slug/info"
            component={HomeSharingInquiryView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/homesharing-inquiries/update/:slug"
            component={HomeSharingInquiriesAddForm}
          />
          <Route
            exact
            path="/homesharing-inquiries/update/:outsidePropertySlug/:slug"
            component={HomeSharingInquiriesAddForm}
          />
          <Route
            exact
            path="/listing/add/:outsidePropertySlug"
            component={ListingForm}
          />
          <Route
            exact
            path="/listing/view/:outsidePropertySlug/:slug/info"
            render={(props) => (
              <ListingView
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/listing/update/:outsidePropertySlug/:slug"
            component={ListingForm}
          />
          <Route
            exact
            path="/global-list-calendar"
            component={ListingCalendar}
          />
          {/* Listing End */}
          {/* Lead Start */}
          <Route exact path="/inquiry" component={Inquiry} />
          <Route
            exact
            path="/properties/view/:propertySlug/inquiry"
            component={Inquiry}
          />
          <Route
            exact
            path="/inquiry/view/:outsidePropertySlug/:inquirySlug"
            component={InquiryView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/inquiry/view/:inquirySlug"
            component={InquiryView}
          />
          {/* Lead End */}
          {/* Reservation Start */}
          <Route exact path="/reservations" component={Reservations} />
          <Route
            exact
            path="/reservations/add/:outsidePropertySlug"
            component={ReservationForm}
          />
          <Route
            exact
            path="/reservations/view/:outsidePropertySlug/:slug"
            render={(props) => (
              <ReservationView
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/reservations-activity/:outsidePropertySlug/:slug"
            render={(props) => (
              <ReservationView
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/reservations/update/:outsidePropertySlug/:slug"
            component={ReservationForm}
          />
          <Route
            exact
            path="/reservations/view/:outsidePropertySlug/:reservationSlug/messages/:threadSlug"
            component={MessageIndex}
          />
          <Route
            exact
            path="/reservations/view/:outsidePropertySlug/:reservationSlug/messages"
            component={MessageIndex}
          />
          <Route
            exact
            path="/global-reservation-calendar"
            component={ReservationCalendar}
          />
          <Route
            exact
            path="/global-checkin-calender"
            component={CheckinCalendar}
          />
          <Route
            exact
            path="/global-checkout-calender"
            component={CheckoutCalendar}
          />
          <Route exact path="/guest" component={Guest} />
          <Route exact path="/guest/add" component={GuestForm} />
          <Route
            exact
            path="/guest/update/:outsidePropertySlug/:slug"
            component={GuestForm}
          />
          <Route
            exact
            path="/guest/view/:outsidePropertySlug/:slug"
            component={GuestView}
          />
          {/* Reservation End */}
          {/* Leases Start */}
          <Route exact path="/leasing" component={Leasing} />
          <Route
            exact
            path="/leasing/add/:outsidePropertySlug"
            component={LeasingForm}
          />
          <Route
            exact
            path="/leasing/view/:outsidePropertySlug/:slug"
            render={(props) => (
              <LeasingView
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/leasing/update/:outsidePropertySlug/:slug"
            component={LeasingForm}
          />
          <Route exact path="/availability" component={UnitsAvailability} />
          <Route
            exact
            path="/global-lease-avail-calendar"
            component={LeaseAvalCalendar}
          />
          <Route exact path="/applicants" component={Applicants} />
          <Route
            exact
            path="/applicants/update/:outsidePropertySlug/:slug"
            component={ApplicantsForm}
          />
          <Route
            exact
            path="/applicants/view/:outsidePropertySlug/:slug"
            component={ApplicantView}
          />
          <Route exact path="/applicant-detail" component={ApplicantsDetail} />
          <Route
            exact
            path="/applicant-detail/update/:outsidePropertySlug/:slug"
            component={ApplicantDetailsForm}
          />
          <Route
            exact
            path="/applicant-detail/view/:outsidePropertySlug/:slug"
            component={ApplicantDetailView}
          />
          <Route exact path="/residents" component={Resident} />
          <Route
            exact
            path="/residents/update/:outsidePropertySlug/:slug"
            component={ResidentForm}
          />
          <Route
            exact
            path="/residents/view/:outsidePropertySlug/:slug"
            component={ResidentView}
          />
          <Route exact path="/rent" component={LeasingRents} />
          <Route
            exact
            path="/unit-rent/add/:outsidePropertySlug"
            component={RentForm}
          />
          <Route exact path="/resident-payouts" component={ResidentPayout} />
          {/* Leases End */}
          {/* Cleaning Start */}
          <Route
            exact
            path="/housekeeping-list"
            render={(props) => (
              <HouseKeepingList
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/global-housekeeping-calender"
            component={HouseKeepingCalendar}
          />
          <Route
            exact
            path="/housekeeping/add/:outsidePropertySlug"
            component={HouseKeepingForm}
          />
          <Route
            exact
            path="/housekeeping-list/view/:outsidePropertySlug/:slug"
            component={HouseKeepingView}
          />
          <Route
            exact
            path="/global-maintenance-calendar"
            component={MaintenanceCalendar}
          />
          {/* Cleaning End */}
          {/* Maintenance Requests Start */}
          <Route exact path="/mros" component={Mro} />
          <Route
            exact
            path="/mros/add/:outsidePropertySlug"
            component={MroForm}
          />
          <Route
            exact
            path="/mros/view/:outsidePropertySlug/:slug"
            render={(props) => (
              <MroForm
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route exact path="/work-order" component={WorkOrders} />
          <Route exact path="/housekeeping-vendoor-list" component={Vendors} />
          {/* Maintenance Requests End */}
          {/* Reports Start */}
          <Route exact path="/occupancy-reports" component={EarningsReports} />
          <Route
            exact
            path="/homesharing-reports"
            component={HomeSharingReport}
          />
          <Route
            exact
            path="/monthly-investor-report"
            component={MonthlyInvestorReport}
          />
          <Route exact path="/oh-earning-reports" component={OhEarningReport} />
          <Route
            exact
            path="/resident-payout"
            component={ResidentPayoutsReports}
          />
          <Route exact path="/top-performer" component={TopPerformerReport} />
          <Route exact path="/guest-form-deposit" component={GuestDeposite} />
          <Route exact path="/guest-form-waiver" component={GuestWaiver} />
          <Route
            exact
            path="/wavio-transfer-fee-list"
            component={GuestWavioTransferList}
          />
          <Route
            exact
            path="/wavio-transfer-fee-list/:reportSlug"
            component={GuestWavioTransferView}
          />
          <Route exact path="/property-tax" component={PropertyTaxReports} />
          <Route
            exact
            path="/property-payout-report"
            component={PayoutsReports}
          />
          <Route
            exact
            path="/property-reservation-payout"
            component={PropertyPayoutsReportsByReservation}
          />
          <Route
            exact
            path="/property-unit-payout"
            component={PropertyPayoutsReportsByUnit}
          />
          <Route
            exact
            path="/res-payout-summary"
            component={ResidentPayoutReportList}
          />
          <Route exact path="/resident-topup" component={ResidentTopUp} />
          <Route
            exact
            path="/properties/view/:propertySlug/resident-topup"
            component={ResidentTopUp}
          />
          <Route
            exact
            path="/property-payout-summary"
            component={PropertyPayoutReportList}
          />
          <Route
            exact
            path="/property-payout-summary-copy"
            component={PropertyPayoutReportListCopy}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/property-payout-summary"
            component={PropertyPayoutReportList}
          />
          <Route
            exact
            path="/view-payout-resident/:outsidePropertySlug/:slug"
            component={ResidentPayoutsReportsEdit}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/view-payout-resident/:slug"
            component={ResidentPayoutsReportsEdit}
          />
          <Route
            exact
            path="/view-payout-property/:outsidePropertySlug/:slug"
            component={PropertyPayoutsReportsEdit}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/view-payout-property/:slug"
            component={PropertyPayoutsReportsEdit}
          />
          <Route
            exact
            path="/edit-payout-resident/:outsidePropertySlug/:slug"
            component={ResidentPayoutsReportsCreate}
          />
          <Route
            exact
            path="/create-payout-resident/:outsidePropertySlug"
            component={ResidentPayoutsReportsCreate}
          />
          <Route
            exact
            path="/edit-payout-property/:outsidePropertySlug/:slug"
            component={PropertyPayoutsReportsCreate}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/edit-payout-property/:slug"
            component={PropertyPayoutsReportsCreate}
          />
          <Route
            exact
            path="/create-payout-property/:outsidePropertySlug"
            component={PropertyPayoutsReportsCreate}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/create-payout-property"
            component={PropertyPayoutsReportsCreate}
          />
          <Route exact path="/ru-payout" component={RUPayout} />
          {/* Reports End */}
          {/* Keys Start */}
          <Route exact path="/keys" component={Keys} />
          <Route
            exact
            path="/keys/activity-add/:outsidePropertySlug"
            component={ActivityAdd}
          />
          <Route
            exact
            path="/keys/activity-view/:outsidePropertySlug/:slug"
            component={ActivityView}
          />
          <Route
            exact
            path="/keys/activity-update/:outsidePropertySlug/:slug"
            component={ActivityAdd}
          />
          <Route
            exact
            path="/keys/keyactivity-view/:outsidePropertySlug/:slug"
            component={KeyView}
          />
          {/* Keys End */}
          {/* latches Start */}
          <Route exact path="/keys" component={Keys} />
          <Route
            exact
            path="/latches/activity-add/:outsidePropertySlug"
            component={ActivityAddLatch}
          />
          <Route exact path="/influencers" component={Influencers} />
          <Route
            exact
            path="/influencers/add/:outsidePropertySlug"
            component={InfluencersForm}
          />
          <Route
            exact
            path="/influencers/view/:outsidePropertySlug/:slug"
            render={(props) => (
              <InfluencerView
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/influencers/update/:outsidePropertySlug/:slug"
            component={InfluencersForm}
          />
          <Route exact path="/ambassadors" component={Ambassadors} />
          <Route
            exact
            path="/ambassadors/update/:outsidePropertySlug/:slug"
            component={AmbassadorsForm}
          />
          <Route
            exact
            path="/ambassadors/add/:outsidePropertySlug"
            component={AmbassadorsForm}
          />
          <Route
            exact
            path="/ambassadors/view/:outsidePropertySlug/:slug"
            render={(props) => (
              <AmbassadorsView
                propertyName={this.props.propertyName}
                propertySlug={this.props.propertySlug}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/latches/activity-view/:outsidePropertySlug/:slug"
            component={ActivityViewLatch}
          />
          <Route
            exact
            path="/latches/activity-update/:outsidePropertySlug/:slug"
            component={ActivityAddLatch}
          />
          {/* latches End */}
          {/* Problems Start */}
          <Route exact path="/problems" component={Problems} />
          <Route
            exact
            path="/problems/:outsidePropertySlug/:slug"
            component={ProblemForm}
          />
          {/* Problems End */}
          {/* OutSide Modules End */}

          <Route
            exact
            path="/properties/view/:propertySlug/automation-processing-log/:module_name"
            component={AutomationProcessingLog}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floor-plans-mapping"
            component={FloorPlanMapping}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/automation-data-hub-settings"
            component={AutomationDataHubSeetings}
          />
          <Route
            exact
            path="/automation-data-hub-settings"
            component={AutomationDataHubSeetings}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/export-automation"
            component={ExportAutomationDataHubSeetings}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/export-automation/report"
            component={ExportAutomationDataHubSeetings}
          />
          <Route
            exact
            path="/export-automation"
            component={ExportAutomationDataHubSeetings}
          />
          <Route
            exact
            path="/export-automation/report"
            component={ExportAutomationDataHubSeetings}
          />
          {/* --------------------- Latch Start --------------------- */}
          <Route
            exact
            path="/properties/view/:propertySlug/latch-mapping"
            component={LatchMapping}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/Latches"
            component={LatchActivityHistory}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/latches/access-view/:latch_access_slug"
            component={LatchAccessView}
          />

          {/*------------ CRM Template List Campaign Start ---------------------*/}
          <Route exact path="/crm-templates" component={CrmTemplates} />
          <Route exact path="/crm-template/add" component={CrmTemplateForm} />
          <Route
            exact
            path="/crm-template/update/:crmSlug"
            component={CrmTemplateForm}
          />
          <Route exact path="/crm-list" component={CrmList} />
          <Route exact path="/crm-list/add-auto" component={CrmListForm} />
          <Route
            exact
            path="/crm-list/update-auto/:crmSlug"
            component={CrmListForm}
          />
          <Route exact path="/crm-list/view/:crmSlug" component={CrmListView} />

          <Route
            exact
            path="/crm-list/add-manual"
            component={CrmManualListForm}
          />
          <Route
            exact
            path="/crm-list/update-manual/:crmSlug"
            component={CrmManualListForm}
          />
          <Route exact path="/crm-list/add-csv/" component={CrmCsvListForm} />
          <Route
            exact
            path="/crm-list/update-csv/:crmSlug"
            component={CrmCsvListForm}
          />

          <Route exact path="/crm-campaign" component={CrmCampaign} />
          <Route exact path="/crm-campaign/add" component={CrmCampaignForm} />
          <Route
            exact
            path="/crm-campaign/update/:crmSlug"
            component={CrmCampaignForm}
          />
          <Route
            exact
            path="/crm-campaign/report/:crmSlug"
            component={CrmCampaignReportView}
          />

          {/*--------------- CRM Template List Campaign End -------------------*/}
          {/* Property CRM Start */}
          <Route
            exact
            path="/properties/view/:propertySlug/crm-list"
            component={CrmList}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-list/add-auto"
            component={CrmListForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-list/update-auto/:crmSlug"
            component={CrmListForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-list/add-manual"
            component={CrmManualListForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-list/update-manual/:crmSlug"
            component={CrmManualListForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-list/add-csv"
            component={CrmCsvListForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-list/update-csv/:crmSlug"
            component={CrmCsvListForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-list/view/:crmSlug"
            component={CrmListView}
          />

          {/* ------- Templates---------- */}
          <Route
            exact
            path="/properties/view/:propertySlug/crm-templates"
            component={CrmTemplates}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-template/add"
            component={CrmTemplateForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-template/update/:crmSlug"
            component={CrmTemplateForm}
          />

          {/* -----------Campaigns------------- */}
          <Route
            exact
            path="/properties/view/:propertySlug/crm-campaign"
            component={CrmCampaign}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-campaign/add"
            component={CrmCampaignForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-campaign/update/:crmSlug"
            component={CrmCampaignForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/crm-campaign/report/:crmSlug"
            component={CrmCampaignReportView}
          />

          {/*--------------- Property CRM End -------------------*/}
          {/* -----------Brand's CRM------------- */}
          <Route
            exact
            path="/brand/view/:slug/crm-campaign"
            component={CrmCampaign}
          />
          <Route
            exact
            path="/brand/view/:slug/crm-campaign/add"
            component={CrmCampaignForm}
          />
          <Route
            exact
            path="/brand/view/:slug/crm-campaign/update/:crmSlug"
            component={CrmCampaignForm}
          />
          <Route
            exact
            path="/brand/view/:slug/crm-campaign/report/:crmSlug"
            component={CrmCampaignReportView}
          />
          {/* ------- Templates---------- */}
          <Route
            exact
            path="/brand/view/:slug/crm-templates"
            component={CrmTemplates}
          />
          <Route
            exact
            path="/brand/view/:slug/crm-template/add"
            component={CrmTemplateForm}
          />
          <Route
            exact
            path="/brand/view/:slug/crm-template/update/:crmSlug"
            component={CrmTemplateForm}
          />

          {/*---- List ----- */}
          <Route exact path="/brand/view/:slug/crm-list" component={CrmList} />
          <Route
            exact
            path="/brand/view/:slug/crm-list/add-auto"
            component={CrmListForm}
          />
          <Route
            exact
            path="/brand/view/:slug/crm-list/update-auto/:crmSlug"
            component={CrmListForm}
          />
          <Route
            exact
            path="/brand/view/:slug/crm-list/add-manual"
            component={CrmManualListForm}
          />
          <Route
            exact
            path="/brand/view/:slug/crm-list/update-manual/:crmSlug"
            component={CrmManualListForm}
          />
          <Route
            exact
            path="/brand/view/:slug/crm-list/add-csv"
            component={CrmCsvListForm}
          />
          <Route
            exact
            path="/brand/view/:slug/crm-list/update-csv/:crmSlug"
            component={CrmCsvListForm}
          />
          <Route
            exact
            path="/brand/view/:slug/crm-list/view/:crmSlug"
            component={CrmListView}
          />

          {/*--------------- Brand's CRM End -------------------*/}
          {/* -----------Clients's CRM Start------------- */}
          <Route
            exact
            path="/client/view/:slug/crm-campaign"
            component={CrmCampaign}
          />
          <Route
            exact
            path="/client/view/:slug/crm-campaign/add"
            component={CrmCampaignForm}
          />
          <Route
            exact
            path="/client/view/:slug/crm-campaign/update/:crmSlug"
            component={CrmCampaignForm}
          />
          <Route
            exact
            path="/client/view/:slug/crm-campaign/report/:crmSlug"
            component={CrmCampaignReportView}
          />
          {/* ------- Templates---------- */}
          <Route
            exact
            path="/client/view/:slug/crm-templates"
            component={CrmTemplates}
          />
          <Route
            exact
            path="/client/view/:slug/crm-template/add"
            component={CrmTemplateForm}
          />
          <Route
            exact
            path="/client/view/:slug/crm-template/update/:crmSlug"
            component={CrmTemplateForm}
          />

          {/*---- List ----- */}
          <Route exact path="/client/view/:slug/crm-list" component={CrmList} />
          <Route
            exact
            path="/client/view/:slug/crm-list/add-auto"
            component={CrmListForm}
          />
          <Route
            exact
            path="/client/view/:slug/crm-list/update-auto/:crmSlug"
            component={CrmListForm}
          />
          <Route
            exact
            path="/client/view/:slug/crm-list/add-manual"
            component={CrmManualListForm}
          />
          <Route
            exact
            path="/client/view/:slug/crm-list/update-manual/:crmSlug"
            component={CrmManualListForm}
          />
          <Route
            exact
            path="/client/view/:slug/crm-list/add-csv"
            component={CrmCsvListForm}
          />
          <Route
            exact
            path="/client/view/:slug/crm-list/update-csv/:crmSlug"
            component={CrmCsvListForm}
          />
          <Route
            exact
            path="/client/view/:slug/crm-list/view/:crmSlug"
            component={CrmListView}
          />

          <Route exact path="/assets" component={Assets} />

          {/*--------------- Clients's CRM End -------------------*/}
          {/* --------------------- stripe Start --------------------- */}
          <Route exact path="/stripe-transfer" component={StripeTotalPayout} />
          <Route
            exact
            path="/properties/view/:propertySlug/stripe-transfer"
            component={StripeTotalPayout}
          />
          <Route
            exact
            path="/stripe-transfer/:type"
            component={StripeTotalPayout}
          />
          <Route
            exact
            path="/stripe-transfer/:batchSlug"
            component={StripeTotalPayout}
          />
          <Route
            exact
            path="/stripe-payout-summary"
            component={StripePayoutSummary}
          />
          <Route
            exact
            path="/res-payout-summary/:batchSlug"
            component={ResidentPayoutReportList}
          />
          <Route exact path="/crm-contact" component={CrmContact} />
          <Route exact path="/crm-subscribers" component={CrmSubscribers} />
          <Route exact path="/crm-unsubscribers" component={CrmUnsubscribes} />
          {/* Reviews Start */}
          <Route exact path="/reviews" component={GuestReviews} />
          <Route
            exact
            path="/properties/view/:propertySlug/reviews"
            component={GuestReviews}
          />
          {/* Reviews End */}
          {/* igloo Start */}
          <Route
            exact
            path="/properties/view/:propertySlug/igloo-mapping"
            component={IglooMapping}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/keys/igloo-add"
            component={IglooAdd}
          />
          {/* igloo End */}
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
