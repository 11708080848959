////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ResidentForm.jsx                                //
//  Application:  Residents                                   //
//  Option: For add or update Resident                       //
//  Developer: NP                                         //
//  Date: 2022-05-20                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";

import { Container, Form } from "react-bootstrap";
// import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import SimpleReactValidator from "simple-react-validator";
import ResidentService from "../../services/ResidentService";
import LeasingService from "../../services/LeasingService";
import CountryService from "../../services/CountryService";
import LeasingSidebar from "../common/LeasingSidebar";
import hostImg from "./../../assets/images/profile-nogender.png";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import Moment from "moment";
import GooglePlaces from "../common/GooglePlaces";
import GooglePlacesW9 from "../common/GooglePlacesW9";
import Select from "react-select";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";

export class ResidentForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      status_id: true,
      pathname: window.location.pathname,
      confirm_back: 0,
      imageUrl: hostImg,
      city_name: "",
      state_name: "",
      county_name: "",
      country_name: "",
      city_name1: "",
      state_name1: "",
      county_name1: "",
      country_name1: "",
      street_22: "",
      street_12: "",
      zipcode1: "",
      disgraded_entity_name: "",
      zipcode: "",
      street_2: "",
      street_1: "",
      delivery_electronically: 1,
      newUnitList: [],
      // accepting_turno_payment: false,
      turnoDetails: "",
    };

    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateResident = this.saveOrUpdateResident.bind(this);
    this.getChildData = this.getChildData.bind(this);
    this.getChildData1 = this.getChildData1.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await ResidentService.getResident(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData.first_name = res.data ? res.data.first_name : "";
        resData.last_name = res.data ? res.data.last_name : "";
        resData.email = res.data ? res.data.email : "";
        resData.phone_number = res.data ? res.data.phone_number : "";
        resData.resident_photo = res.data ? res.data.resident_photo_thumb : "";
        resData.imageUrl = res.data ? res.data.resident_photo_thumb_url : "";
        resData.street_1 = res.data ? res.data.street_1 : "";
        resData.street_2 = res.data ? res.data.street_2 : "";
        resData.zipcode = res.data.zip_code ? res.data.zip_code : "";
        resData.country_name =
          res.data && res.data.country ? res.data.country.country_name : "";
        resData.city_name =
          res.data && res.data.city ? res.data.city.city_name : "";
        resData.county_name =
          res.data && res.data.county ? res.data.county.county_name : "";
        resData.state_name =
          res.data && res.data.state ? res.data.state.state_name : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        resData.business_name = res.data.w9info
          ? res.data.w9info.business_name
          : "";
        resData.delivery_electronically = res.data.w9info
          ? res.data.w9info.delivery_electronically
          : 2;
        resData.countryId =
          res.data && res.data.country && res.data.country.country_id
            ? res.data.country.country_id
            : "";
        resData.stateId =
          res.data && res.data.state && res.data.state.state_id
            ? res.data.state.state_id
            : "";
        resData.countyId =
          res.data && res.data.county && res.data.county.county_id
            ? res.data.county.county_id
            : "";
        resData.cityId =
          res.data && res.data.city && res.data.city.city_id
            ? res.data.city.city_id
            : "";
        // resData.delivery_to_mail = res.data.w9info? res.data.w9info.delivery_to_mail:"";
        // resData.delivery_electronically =  res.data.w9info.delivery_electronically  === 1 ? true : false;
        // resData.accepting_turno_payment =
        //   res.data &&
        //   res.data.tbnbDetails &&
        //   res.data.tbnbDetails.is_payment_done &&
        //   res.data.tbnbDetails.is_payment_done === 1
        //     ? true
        //     : false;
        resData.turnoDetails =
          res.data && res.data.tbnbDetails ? res.data.tbnbDetails : "";
        resData.paye_code = res.data.w9info ? res.data.w9info.paye_code : "";
        resData.fatca_code = res.data.w9info ? res.data.w9info.fatca_code : "";
        resData.account_number = res.data.w9info
          ? res.data.w9info.account_number
          : "";
        resData.signature_date =
          res.data.w9info && res.data.w9info.signature_date
            ? Moment(res.data.w9info.signature_date).toDate("MM/dd/yy")
            : "";
        resData.signature_name = res.data.w9info
          ? res.data.w9info.signature_name
          : "";

        resData.street_12 = res.data.w9info ? res.data.w9info.street_1 : "";
        resData.street_22 = res.data.w9info ? res.data.w9info.street_2 : "";
        resData.zipcode1 = res.data.w9info ? res.data.w9info.zipcode : "";
        resData.country_name1 =
          res.data.w9info && res.data.w9info.country
            ? res.data.w9info.country.country_name
            : res.data.w9info
            ? res.data.w9info.country_name
            : "";
        resData.city_name1 =
          res.data.w9info && res.data.w9info.city
            ? res.data.w9info.city.city_name
            : res.data.w9info
            ? res.data.w9info.city_name
            : "";
        resData.county_name1 =
          res.data.w9info && res.data.w9info.county
            ? res.data.w9info.county.county_name
            : "";
        resData.state_name1 =
          res.data.w9info && res.data.w9info.state
            ? res.data.w9info.state.state_name
            : res.data.w9info
            ? res.data.w9info.state_name
            : "";
        resData.disgraded_entity_name = res.data.w9info
          ? res.data.w9info.disgraded_entity_name
          : "";

        resData.countryId1 =
          res.data &&
          res.data.w9info &&
          res.data.w9info.country &&
          res.data.w9info.country.country_id
            ? res.data.w9info.country.country_id
            : "";
        resData.stateId1 =
          res.data &&
          res.data.w9info &&
          res.data.w9info.state &&
          res.data.w9info.state.state_id
            ? res.data.w9info.state.state_id
            : "";
        resData.countyId1 =
          res.data &&
          res.data.w9info &&
          res.data.w9info.county &&
          res.data.w9info.county.county_id
            ? res.data.w9info.county.county_id
            : "";
        resData.cityId1 =
          res.data &&
          res.data.w9info &&
          res.data.w9info.city &&
          res.data.w9info.city.city_id
            ? res.data.w9info.city.city_id
            : "";

        resData.delivery_to_mail = res.data.w9info
          ? res.data.w9info.delivery_to_mail
          : "";
        resData.ein_number = res.data.w9info ? res.data.w9info.ein_number : "";
        resData.ssn_number = res.data.w9info ? res.data.w9info.ssn_number : "";
        resData.is_new_llc_note = res.data.w9info
          ? res.data.w9info.is_new_llc_note
          : "";
        resData.is_other_note = res.data.w9info
          ? res.data.w9info.is_other_note
          : "";

        resData.is_new_llc = res.data.w9info ? res.data.w9info.is_new_llc : "";
        resData.is_other = res.data.w9info ? res.data.w9info.is_other : "";
        resData.is_llc = res.data.w9info ? res.data.w9info.is_llc : "";
        resData.is_c_corporation = res.data.w9info
          ? res.data.w9info.is_c_corporation
          : "";

        resData.is_s_corporation = res.data.w9info
          ? res.data.w9info.is_s_corporation
          : "";
        resData.is_trust = res.data.w9info ? res.data.w9info.is_trust : "";
        resData.is_partnership = res.data.w9info
          ? res.data.w9info.is_partnership
          : "";
        var selectedUnitList = [];
        // var selectedStatusList = [];
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_llc &&
          res.data.w9info.is_llc === 1
        ) {
          selectedUnitList = [
            {
              value: 1,
              label: "LLC",
            },
          ];
        }
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_c_corporation &&
          res.data.w9info.is_c_corporation === 1
        ) {
          selectedUnitList = [
            {
              value: 1,
              label: "C Corporation",
            },
          ];
        }
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_s_corporation &&
          res.data.w9info.is_s_corporation === 1
        ) {
          selectedUnitList = [
            {
              value: 1,
              label: "S Corporation",
            },
          ];
        }
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_partnership &&
          res.data.w9info.is_partnership === 1
        ) {
          selectedUnitList = [
            {
              value: 1,
              label: "Partnership",
            },
          ];
        }
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_trust &&
          res.data.w9info.is_trust === 1
        ) {
          selectedUnitList = [
            {
              value: 1,
              label: "Trust/Estate",
            },
          ];
        }
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_new_llc &&
          res.data.w9info.is_new_llc === 1
        ) {
          selectedUnitList = [
            {
              value: 1,
              label: "Limited Liability Company",
            },
          ];
        }
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_other &&
          res.data.w9info.is_other === 1
        ) {
          selectedUnitList = [
            {
              value: 1,
              label: "Other",
            },
          ];
        }
      }

      this.setState(resData);
      this.setState({
        selectedUnitList: selectedUnitList,
      });

      this.defaultData = resData;
    }
  }
  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedUnitList: value,
      unit_number: value.id,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }
  async getChildData(unit, street1, city, state, county, country, zipcode) {
    this.setState({ street_1: unit });
    this.setState({ street_2: street1 });
    this.setState({ city_name: city });
    this.setState({ state_name: state });
    this.setState({ county_name: county });
    this.setState({ country_name: country });
    this.setState({ zipcode: zipcode });
    //api call for check location
    let inputData = {
      place_country_name: country ? country : "",
      place_state_name: state ? state : "",
      place_county_name: county ? county : "",
      place_city_name: city ? city : "",
    };

    const newUnitList = [
      {
        value: 1,
        label: "LLC",
        id: "is_llc",
      },
      {
        value: 1,
        label: "C Corporation",
        id: "is_c_corporation",
      },
      {
        value: 1,
        label: "S Corporation",
        id: "is_s_corporation",
      },
      {
        value: 1,
        label: "Partnership",
        id: "is_partnership",
      },
      {
        value: 1,
        label: "Trust/Estate",
        id: "is_trust",
      },
      {
        value: 1,
        label: "Limited Liability Company",
        id: "is_new_llc",
      },
      {
        value: 1,
        label: "Other",
        id: "is_other",
      },
    ];
    this.setState({
      newUnitList: newUnitList,
    });

    // if(state && country){
    let res = await CountryService.checkLocation(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        countryId: res.data.countryId,
        stateId: res.data.stateId,
        countyId: res.data.countyId,
        cityId: res.data.cityId,
      });
    }
    // }
  }

  selectOnlyThis(id) {
    var myCheckbox = document.getElementsByName("ckbox h-40-cust");
    Array.prototype.forEach.call(myCheckbox, function (el) {
      el.checked = false;
    });
    id.checked = true;
  }

  async getChildData1(unit, street1, city, state, county, country, zipcode) {
    this.setState({ street_12: unit });
    this.setState({ street_22: street1 });
    this.setState({ city_name1: city });
    this.setState({ state_name1: state });
    this.setState({ county_name1: county });
    this.setState({ country_name1: country });
    this.setState({ zipcode1: zipcode });
    let inputData = {
      place_country_name: country ? country : "",
      place_state_name: state ? state : "",
      place_county_name: county ? county : "",
      place_city_name: city ? city : "",
    };

    let res = await CountryService.checkLocation(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        countryId1: res.data.countryId,
        stateId1: res.data.stateId,
        countyId1: res.data.countyId,
        cityId1: res.data.cityId,
      });
    }
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }

  saveOrUpdateResident = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        remove_redis: this.state.slug !== undefined ? "yes" : "",
        property_slug: this.state.property_slug
          ? this.state.property_slug
          : this.props.property_slug,
        resident_photo: this.state.resident_photo
          ? this.state.resident_photo
          : "",
        // accepting_turno_payment:
        //   this.state.accepting_turno_payment === true ? 1 : 0,
        first_name: this.state.first_name ? this.state.first_name : "",
        last_name: this.state.last_name ? this.state.last_name : "",
        email: this.state.email ? this.state.email : "",
        phone_number: this.state.phone_number ? this.state.phone_number : "",
        dial_code: this.state.dial_code ? this.state.dial_code : "",
        street_1: this.state.street_1 ? this.state.street_1 : "",
        street_2: this.state.street_2 ? this.state.street_2 : "",
        zipcode: this.state.zipcode ? this.state.zipcode : "",
        country_id: this.state.countryId ? this.state.countryId : "",
        state_id: this.state.stateId ? this.state.stateId : "",
        county_id: this.state.countyId ? this.state.countyId : "",
        city_id: this.state.cityId ? this.state.cityId : "",
        sort_order: this.state.sort_order ? this.state.sort_order : "",
        status_id: this.state.status_id === true ? 1 : 2,
        street_12: this.state.street_12 ? this.state.street_12 : "",
        street_22: this.state.street_22 ? this.state.street_22 : "",
        zipcode1: this.state.zipcode1 ? parseInt(this.state.zipcode1) : "",
        country_id1: this.state.countryId1
          ? parseInt(this.state.countryId1)
          : "",
        state_id1: this.state.stateId1 ? parseInt(this.state.stateId1) : "",
        county_id1: this.state.countyId1 ? parseInt(this.state.countyId1) : "",
        city_id1: this.state.cityId1 ? parseInt(this.state.cityId1) : "",
        city_name: this.state.city_name1 ? this.state.city_name1 : "",
        state_name: this.state.state_name1 ? this.state.state_name1 : "",
        country_name: this.state.country_name1 ? this.state.country_name1 : "",
        business_name: this.state.business_name ? this.state.business_name : "",
        delivery_to_mail: this.state.delivery_electronically === 2 ? 1 : 0,
        delivery_electronically:
          this.state.delivery_electronically === 1 ? 1 : 0,
        disgraded_entity_name: this.state.disgraded_entity_name
          ? this.state.disgraded_entity_name
          : "",
        paye_code: this.state.paye_code ? this.state.paye_code : "",
        fatca_code: this.state.fatca_code ? this.state.fatca_code : "",
        account_number: this.state.account_number
          ? this.state.account_number
          : "",
        ein_number: this.state.ein_number ? this.state.ein_number : "",
        // is_new_llc_note : this.state.is_new_llc_note ? this.state.unit_number : "",
        // is_other_note : this.state.is_other_note ? this.state.is_other_note: "",
        ssn_number: this.state.ssn_number ? this.state.ssn_number : "",
        signature_date: this.state.signature_date
          ? this.state.signature_date
          : "",
        signature_name: this.state.signature_name
          ? this.state.signature_name
          : "",
        is_new_llc:
          this.state.unit_number === "is_new_llc" || this.state.is_new_llc == 1
            ? 1
            : 0,
        is_other:
          this.state.unit_number === "is_other" || this.state.is_other == 1
            ? 1
            : 0,

        is_llc:
          this.state.unit_number === "is_llc" || this.state.is_llc == 1 ? 1 : 0,
        is_c_corporation:
          this.state.unit_number === "is_c_corporation" ||
          this.state.is_c_corporation == 1
            ? 1
            : 0,
        is_s_corporation:
          this.state.unit_number === "is_s_corporation" ||
          this.state.is_s_corporation == 1
            ? 1
            : 0,
        is_trust:
          this.state.unit_number === "is_trust" || this.state.is_trust == 1
            ? 1
            : 0,
        is_partnership:
          this.state.unit_number === "is_partnership" ||
          this.state.is_partnership == 1
            ? 1
            : 0,
      };
      if (this.state.slug !== undefined) {
        let res = await ResidentService.updateResident(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/residents/view/" +
                      this.state.slug
                  : "/residents/view/" +
                      this.state.property_slug +
                      "/" +
                      this.state.slug
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }
      } else {
        let res = await ResidentService.createResident(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/residents"
                  : "/residents"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  // customValidate() {
  //   let isValid = true;
  //   return isValid;
  // }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({
      [event.target.name]: value,
      input: input,
    });

    this.validator.showMessageFor(event.target.name);

    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Residents";
    } else {
      return "Residents";
    }
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "profile_thumb",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await LeasingService.uploadResidentImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          resident_photo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  removePhoto() {
    this.setState({ resident_photo: "", imageUrl: "" });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container over-fl">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <LeasingSidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyLeasingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      {this.state.slug !== undefined ? (
                        <button
                          type="button"
                          className="btn-success-outline-small"
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/residents/view/" +
                                        this.state.slug
                                    : "/residents/view/" +
                                        this.state.property_slug +
                                        "/" +
                                        this.state.slug
                                );
                              else return false;
                            else
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/residents/view/" +
                                      this.state.slug
                                  : "/residents/view/" +
                                      this.state.property_slug +
                                      "/" +
                                      this.state.slug
                              );
                          }}
                        >
                          Cancel
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn-success-outline-small"
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/residents"
                                    : "/residents"
                                );
                              else return false;
                            else
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/residents"
                                  : "/residents"
                              );
                          }}
                        >
                          Cancel
                        </button>
                      )}

                      {this.state.slug !== undefined &&
                      global.userPermissions.checkPermission(
                        "residents-update"
                      ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveOrUpdateResident}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : global.userPermissions.checkPermission(
                          "residents-add"
                        ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveOrUpdateResident}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <form id="units_form">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pl-lg-l-30 pd-lg-r-30 pd-xs-r-0 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="m-0">
                        <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 pd-xs-l-15">
                          <h3 className="mg-l-0 background-head">
                            Contact Info
                          </h3>
                        </div>
                        <div className="row pd-b-5 border-bottom-0 mg-t-15 pd-lg-r-15 pd-xs-r-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              First Name
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="first_name"
                              value={this.state.first_name || ""}
                              maxLength="50"
                            />
                            {this.state.errors.first_name ? (
                              <div className="text-danger">
                                {this.state.errors.first_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "first_name",
                                this.state.first_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Last Name{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="last_name"
                              value={this.state.last_name || ""}
                              maxLength="50"
                            />
                            {
                              this.state.errors.last_name ? (
                                <div className="text-danger">
                                  {this.state.errors.last_name}
                                </div>
                              ) : (
                                ""
                              )
                              //  (
                              //   this.validator.message(
                              //     "last_name",
                              //     this.state.last_name,
                              //     "required",
                              //     { className: "text-danger" }
                              //   )
                              // )
                            }
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Phone <span className="optional">(Optional)</span>
                            </label>
                            <PhoneInput
                              country={global.country}
                              disableSearchIcon
                              // autoFormat= "true"
                              enableSearch="true"
                              countryCodeEditable="true"
                              value={this.state.phone_number}
                              onChange={(phone_number) =>
                                this.setState({ phone_number })
                              }
                            />
                            {
                              this.state.errors.phone_number ? (
                                <div className="text-danger">
                                  {this.state.errors.phone_number}
                                </div>
                              ) : (
                                ""
                              )
                              //  (
                              //   this.validator.message(
                              //     "phone_number",
                              //     this.state.phone_number,
                              //     "required",
                              //     { className: "text-danger" }
                              //   )
                              // )
                            }
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Email <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="email"
                              value={this.state.email || ""}
                              maxLength="50"
                            />
                            {
                              this.state.errors.email ? (
                                <div className="text-danger">
                                  {this.state.errors.email}
                                </div>
                              ) : (
                                ""
                              )
                              //  (
                              //   this.validator.message(
                              //     "email",
                              //     this.state.email,
                              //     "required|email",
                              //     { className: "text-danger" }
                              //   )
                              // )
                            }
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Photo <span className="optional">(Optional)</span>
                            </label>

                            <div className="fileUpload d-flex flex-wrap justify-content-between">
                              <span>
                                <label className="hand-cursor">
                                  <input
                                    onChange={this.onImageChange}
                                    type="file"
                                    name="resident_photo"
                                  />
                                  {this.state.imageUrl ? (
                                    <img src={this.state.imageUrl} alt="" />
                                  ) : (
                                    <img src={hostImg} alt="" />
                                  )}
                                </label>
                              </span>
                              <p
                                className="hand-cursor remove-pic remove-pic-three m-0"
                                onClick={this.removePhoto}
                              ></p>
                            </div>

                            {/* <span className="d-inline-flex align-items-start justify-content-start infosize">
                                    <img src={infosize} className="mg-r-5" />
                                    <p>
                                      {" "}
                                      Recommended size - 1024px by 683px (3:2 ratio). Max file
                                      size - 5 MB
                                    </p>
                                  </span> */}
                            {/* <div className="row align-items-center pd-l-0 pd-t-20 border-bottom-0">
                              <div className="col-md-3">
                                <p className="hand-cursor text-danger" onClick={this.removePhoto}>Remove</p>
                              </div>
                            </div> */}
                          </div>

                          <div className="col-lg-12 pd-xs-l-0 pd-lg-l-30 pd-xs-r-15 pd-lg-r-30 pd-xs-l-15">
                            <h3 className="mg-l-0 background-head">
                              Address Info
                            </h3>
                          </div>
                          <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-15 pd-lg-r-15 pd-xs-r-30 pd-lg-l-0 pd-xs-l-0">
                            <GooglePlaces
                              getChildData={this.getChildData.bind(this)}
                              unit={this.state.street_1}
                              street1={this.state.street_2}
                              city={this.state.city_name}
                              state={this.state.state_name}
                              county={this.state.county_name}
                              country={this.state.country_name}
                              zipcode={this.state.zipcode}
                            />
                          </div>

                          {global.userPermissions.checkPermission(
                            "residents-w9-view"
                          ) && (
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 pd-xs-l-15">
                              <h3 className="mg-l-0 background-head">
                                W9 Info
                              </h3>
                            </div>
                          )}
                          {global.userPermissions.checkPermission(
                            "residents-w9-view"
                          ) && (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                SSN
                              </label>

                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="ssn_number"
                                value={this.state.ssn_number || ""}
                                maxLength="09"
                              />
                              {this.state.errors.ssn_number ? (
                                <div className="text-danger">
                                  {this.state.errors.ssn_number}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                          {global.userPermissions.checkPermission(
                            "residents-w9-view"
                          ) && (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Name or Business Name
                                <span className="optional">(if different)</span>
                              </label>

                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="business_name"
                                value={this.state.business_name || ""}
                                maxLength="50"
                              />
                              {this.state.errors.business_name ? (
                                <div className="text-danger">
                                  {this.state.errors.business_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "business_name",
                                  this.state.business_name,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          )}
                          {global.userPermissions.checkPermission(
                            "residents-w9-view"
                          ) && (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Disregarded entity name
                                <span className="optional">(Optional)</span>
                              </label>

                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="disgraded_entity_name"
                                value={this.state.disgraded_entity_name || ""}
                                maxLength="50"
                              />
                              {this.state.errors.disgraded_entity_name ? (
                                <div className="text-danger">
                                  {this.state.errors.disgraded_entity_name}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}

                          {global.userPermissions.checkPermission(
                            "residents-w9-view"
                          ) && (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                EIN
                                {/* <span className="optional">(Optional)</span> */}
                              </label>

                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="ein_number"
                                value={this.state.ein_number || ""}
                                maxLength="09"
                              />
                              {this.state.errors.ein_number ? (
                                <div className="text-danger">
                                  {this.state.errors.ein_number}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                          {global.userPermissions.checkPermission(
                            "residents-w9-view"
                          ) && (
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-0 d-flex align-items-start affortable pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                              <div className="w-100 mg-xs-b-15 mg-lg-b-0">
                                <label className="form-label text-left pd-b-5">
                                  Tax classification
                                  {/* <span className="optional">(Optional)</span> */}
                                </label>
                                <Select
                                  styles={customStyles}
                                  isClearable={true}
                                  className="multiselect"
                                  menuPlacement="auto"
                                  value={this.state.selectedUnitList}
                                  options={this.state.newUnitList}
                                  onChange={(value) =>
                                    this.handleChangeUnitList(value)
                                  }
                                  defaultValue={this.state.selectedUnitList}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />
                              </div>
                            </div>
                          )}
                          {global.userPermissions.checkPermission(
                            "residents-w9-view"
                          ) && (
                            <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-0 pd-lg-r-15 pd-xs-r-30 pd-lg-l-0 pd-xs-l-0 p-0 w-100">
                              <GooglePlacesW9
                                getChildData1={this.getChildData1.bind(this)}
                                unit={this.state.street_12}
                                street1={this.state.street_22}
                                city={this.state.city_name1}
                                state={this.state.state_name1}
                                county={this.state.county_name1}
                                country={this.state.country_name1}
                                zipcode={this.state.zipcode1}
                              />
                            </div>
                          )}
                        </div>
                        {global.userPermissions.checkPermission(
                          "residents-w9-view"
                        ) && (
                          <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-0 pd-lg-r-15 pd-xs-r-30 pd-lg-l-0 pd-xs-l-0">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15 d-none">
                              <label className="form-label text-left pd-b-5">
                                Form 1099 delivery
                                {/* <span className="optional">(Optional)</span> */}
                              </label>
                              <Form.Check
                                onChange={this.changeHandler}
                                name="delivery_electronically"
                                value="2"
                                checked={
                                  this.state.delivery_electronically !== 1
                                    ? true
                                    : false
                                }
                                type={"radio"}
                                id="2"
                                label={"Deliver by post"}
                                className={"rdiobox mg-r-20"}
                              />
                              <Form.Check
                                onChange={this.changeHandler}
                                name="delivery_electronically"
                                value="1"
                                checked={
                                  this.state.delivery_electronically === 1
                                    ? true
                                    : false
                                }
                                type={"radio"}
                                id="1"
                                label={"Deliver electronically only"}
                                className={"rdiobox"}
                              />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Your Signature
                              </label>

                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="signature_name"
                                value={this.state.signature_name || ""}
                                maxLength="20"
                              />
                              {/* {this.state.errors.signature_name ? (
                              <div className="text-danger">
                                {this.state.errors.signature_name}
                              </div>
                            ) : ''
                            } */}
                            </div>
                            {/* {this.state.slug !== undefined ?()} */}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Signed Date
                              </label>

                              <DatePicker
                                className="form-control max_width_100"
                                disabledKeyboardNavigation
                                strictParsing
                                scrollableYearDropdown
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                minDate={Moment().toDate()}
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="signature_date"
                                excludeDates={this.state.disabled_dates}
                                dateFormat="MM/dd/yy"
                                selected={this.state.signature_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "signature_date",
                                      value: data,
                                    },
                                  })
                                }
                              />
                              {this.state.errors.signature_date ? (
                                <div className="text-danger">
                                  {this.state.errors.signature_date}
                                </div>
                              ) : (
                                this.validator.message(
                                  "signature_date",
                                  this.state.signature_date,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                        )}
                        {/* {this.state.turnoDetails !== "" && (
                          <>
                            <div className="col-lg-12 pd-xs-l-0 pd-lg-l-30 pd-xs-r-15 pd-lg-r-30 pd-xs-l-15">
                              <h3 className="mg-l-0 background-head">
                                TurnoverBnb Details
                              </h3>
                            </div>
                            <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-15 pd-lg-r-15 pd-xs-r-30 pd-lg-l-0 pd-xs-l-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Accepting Turno Payment
                                </label>
                                <Form.Check
                                  name="accepting_turno_payment"
                                  type={"checkbox"}
                                  id={`accepting_turno_payment`}
                                  label={""}
                                  className={"ckbox"}
                                  onChange={this.changeHandler}
                                  checked={this.state.accepting_turno_payment}
                                />
                              </div>
                            </div>
                          </>
                        )} */}
                      </div>
                    </div>
                    {/* </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default ResidentForm;
