/////////////////////////////////////////////////////
//     							                   //
//  Program: Contact.jsx                             //
//  Application: Property                          //
//  Option: List all Property Contact info           //
//  Developer: NP, Prakash 						   //
//  Date: 2022-02-09                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
import SimpleReactValidator from "simple-react-validator";
import publishicon from "./../../assets/images/publish-icon.svg";
import Alerts from "../common/Alerts";
import MarketingSidebar from "../common/MarketingSidebar";
import PhoneInput from "react-phone-input-2";

export class Contact extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      property_slug: this.props.match.params.propertySlug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      confirm_back: 0,
    };
    this.changeView = this.changeView.bind(this);
    this.saveOrUpdateProperty = this.saveOrUpdateProperty.bind(this);
    this.onChangePhoneListing = this.onChangePhoneListing.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getData();
  }

  async getData() {
    if (this.state.property_slug !== undefined) {
      var resData = {};
      var listingPhone = "";
      var propertyPhoneNumber = "";
      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        if (res.data && res.data.listing_phone) {
          listingPhone = await this.phoneNumberAutoFormat(res.data.listing_phone);
        }
        if (res.data && res.data.property_phone_number) {
          propertyPhoneNumber = await this.phoneNumberAutoFormat(res.data.property_phone_number);
        }
      }
      this.setState(resData);
      this.setState({
        listingPhone: listingPhone,
        propertyPhoneNumber: propertyPhoneNumber,
      });
    }
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    return finalnumber;
  };

  saveOrUpdateProperty = async (e) => {
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        tab: 3,
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        property_phone_number: this.state.property_phone_number,
        dial_code: this.state.dial_code,
        listing_phone: this.state.listing_phone,
        property_email: this.state.property_email,
        invoice_email: this.state.invoice_email,
        property_distribution_email: this.state.property_distribution_email,
        maintenance_email: this.state.maintenance_email,
        property_website_link: this.state.property_website_link,
      };

      if (this.state.property_slug !== undefined) {
        let res = await PropertyService.updateProperty(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getData();
          this.setState({ viewMode: 0 });
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };
  onChangePhoneListing(value, country, e, formattedValue) {
    //console.log(value, country, e, formattedValue);
    this.setState({ listing_phone: value });
    this.setState({ dial_code: country.dialCode });
  }
  customValidate() {
    let isValid = true;
    return isValid;
  }
  onChangePhone(value, country, e, formattedValue) {
    //console.log(value, country, e, formattedValue);
    this.setState({ property_phone_number: value });
    this.setState({ dial_code: country.dialCode });
  }
  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
    }
  }

  getTitle() {
    return "Lead Tracking Contacts";
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <MarketingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-10 right">
                  <div className="scrolling-carousel pd-l-15">
                    <div className="row d-flex justify-content-between align-items-center pd-l-15 pd-r-15 mg-b-0 pd-b-2 mg-t-20">
                      <h3>{this.getTitle()}</h3>
                    </div>
                    <div className="dashboardRightcard">
                      {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
                      <div className="row- d-flex justify-content-end">
                        {/* <Link to={'/properties/update/'+this.state.property_slug+"/contact"} ><button className="btn-success-outline-small"><img src={editIcon}/>Edit</button></Link> */}
                        {this.state.viewMode === 0 ? (
                          <button
                            className="btn-success-outline-small"
                            onClick={this.changeView}
                          >
                            <img src={editIcon} />
                            Edit
                          </button>
                        ) : (
                          <div className="d-flex">
                            <button
                              type="button"
                              className="btn-success-outline-small"
                              onClick={() => {
                                if (this.state.confirm_back === 1)
                                  if (
                                    window.confirm(
                                      "Do you want to exit without saving?"
                                    )
                                  )
                                    this.changeView();
                                  else return false;
                                else this.changeView();
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn-success ml-3"
                              onClick={this.saveOrUpdateProperty}
                            >
                              {" "}
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      {this.state.viewMode === 0 ? (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                              <div className="col-md-6">
                                <p className="az-profile-name-text pl-0">
                                  Phone
                                </p>
                                <p className="media-body">
                                  {this.state.propertyPhoneNumber
                                    ? this.state.propertyPhoneNumber
                                    : ""}
                                </p>
                              </div>

                              <div className="col-md-6">
                                <p className="az-profile-name-text pl-0">
                                  Marketing Phone
                                </p>
                                <p className="media-body">
                                  {this.state.listingPhone
                                    ? this.state.listingPhone
                                    : ""}
                                </p>
                              </div>
                            </div>

                            <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                              <div className="col-md-6">
                                <p className="az-profile-name-text pl-0">
                                  Email
                                </p>
                                <p className="media-body">
                                  {this.state.property_email
                                    ? this.state.property_email
                                    : ""}
                                </p>
                              </div>

                              <div className="col-md-6">
                                <p className="az-profile-name-text pl-0">
                                  Invoice Email
                                </p>
                                <p className="media-body">
                                  {this.state.invoice_email
                                    ? this.state.invoice_email
                                    : ""}
                                </p>
                              </div>
                            </div>

                            <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                              <div className="col-md-6">
                                <p className="az-profile-name-text pl-0">
                                  Marketing Email
                                </p>
                                <p className="media-body">
                                  {this.state.property_distribution_email
                                    ? this.state.property_distribution_email
                                    : ""}
                                </p>
                              </div>

                              <div className="col-md-6">
                                <p className="az-profile-name-text pl-0">
                                  Maintenance Email
                                </p>
                                <p className="media-body">
                                  {this.state.maintenance_email
                                    ? this.state.maintenance_email
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                          <hr className="mg-t-10 mg-b-30 pd-b-2" />
                          <div className="row col-md-12 mg-t-0 mg-md-t-0 mg-l-0">
                            <div className="col-md-12">
                              <p className="az-profile-name-text pl-0">
                                Website
                              </p>
                              {this.state.property_website_link ? (
                                <p className="media-body">
                                  <a
                                    href={this.state.property_website_link}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <span className="maplink">
                                      {/* {this.state.property_website_link} */}
                                    </span>

                                    <button className="btn-success-outline-small-link">
                                      <img src={publishicon} alt="open" /> Open
                                      Link
                                    </button>
                                  </a>
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.viewMode === 1 ? (
                        <div className="m-0">
                          <div className="col-md-12 pl-0 pr-0"></div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label text-left mg-b-0">
                                Phone
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <PhoneInput
                                country={global.country}
                                disableSearchIcon
                                // autoFormat= "true"
                                enableSearch="true"
                                countryCodeEditable="true"
                                value={this.state.property_phone_number}
                                onChange={this.onChangePhone}
                              />
                              {this.state.errors.property_phone_number ? (
                                <div className="text-danger">
                                  {this.state.errors.property_phone_number}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label text-left mg-b-0">
                                Marketing Phone
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <PhoneInput
                                country={global.country}
                                disableSearchIcon
                                // autoFormat= "true"
                                enableSearch="true"
                                countryCodeEditable="true"
                                value={this.state.listing_phone}
                                onChange={this.onChangePhoneListing}
                              />
                              {this.state.errors.listing_phone ? (
                                <div className="text-danger">
                                  {this.state.errors.listing_phone}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label text-left mg-b-0">
                                Email
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="property_email"
                                value={this.state.property_email}
                                // placeholder="Email"
                              />
                              {this.state.errors.property_email ? (
                                <div className="text-danger">
                                  {this.state.errors.property_email}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label text-left mg-b-0">
                                Invoice Email
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="invoice_email"
                                value={this.state.invoice_email}
                                // placeholder="Invoice Email"
                              />
                              {this.state.errors.invoice_email ? (
                                <div className="text-danger">
                                  {this.state.errors.invoice_email}
                                </div>
                              ) : (
                                this.validator.message(
                                  "invoice_email",
                                  this.state.invoice_email,
                                  "email",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label text-left mg-b-0">
                                Marketing Email
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="property_distribution_email"
                                value={this.state.property_distribution_email}
                                // placeholder="Marketing Email"
                              />
                              {this.state.errors.property_distribution_email ? (
                                <div className="text-danger">
                                  {
                                    this.state.errors
                                      .property_distribution_email
                                  }
                                </div>
                              ) : (
                                this.validator.message(
                                  "property_distribution_email",
                                  this.state.property_distribution_email,
                                  "email",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label text-left mg-b-0">
                                Maintenance Email
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="maintenance_email"
                                value={this.state.maintenance_email}
                                // placeholder="Maintenance Email"
                              />
                              {this.state.errors.maintenance_email ? (
                                <div className="text-danger">
                                  {this.state.errors.maintenance_email}
                                </div>
                              ) : (
                                this.validator.message(
                                  "maintenance_email",
                                  this.state.maintenance_email,
                                  "email",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label text-left mg-b-0">
                                Website
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="property_website_link"
                                value={this.state.property_website_link}
                                // placeholder="Website"
                              />
                              {this.state.errors.property_website_link ? (
                                <div className="text-danger">
                                  {this.state.errors.property_website_link}
                                </div>
                              ) : (
                                this.validator.message(
                                  "property_website_link",
                                  this.state.property_website_link,
                                  "url|max:200",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default Contact;
