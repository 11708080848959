////////////////////////////////////////////////////////////
//     							                          //
//  Program: MROCategory.jsx                            //
//  Application: Property                                   //
//  Option: to view MROCategory data                   //
//  Developer: Ashish Kumar  			                 //
//  Date: 2022-05-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";

import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import MROCategoryService from "../../services/MROCategoryService";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import NoData from "../common/NoData";

export class MROCategory extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for Location type
      addMROCategoryModal: false,
      editMROCategoryModal: false,
      deleteMROCategoryModal: false,
      mroCategoryList: [],
      mroCategoryListTotal: 0,
      mro_category_name: "",
      sort_order: "",
    };
    this.changeHandler = this.changeHandler.bind(this);

    /*for location type */
    this.openAddMROCategoryModal = this.openAddMROCategoryModal.bind(this);
    this.openEditMROCategoryModal = this.openEditMROCategoryModal.bind(this);
    this.openDeleteMROCategoryModal =
      this.openDeleteMROCategoryModal.bind(this);
    this.saveMROCategory = this.saveMROCategory.bind(this);
    this.getMROCategoryDetails = this.getMROCategoryDetails.bind(this);
    this.updateMROCategory = this.updateMROCategory.bind(this);
    this.deleteMROCategory = this.deleteMROCategory.bind(this);
  }

  componentDidMount() {
    this.getMROCategoryData();
  }

  /* to get Location type data list */
  async getMROCategoryData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var mroCategoryList = [];
    var mroCategoryListTotal = 0;

    let res = await MROCategoryService.getMROCategory(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      mroCategoryList = res.data.data ? res.data.data : [];
      mroCategoryListTotal = mroCategoryList ? mroCategoryList.length : 0;
    }
    this.setState({
      mroCategoryList: mroCategoryList,
      mroCategoryListTotal: mroCategoryListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save Location type data */
  saveMROCategory = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.mro_category_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        mro_category_name: this.state.mro_category_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await MROCategoryService.createMROCategory(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddMROCategoryModal();
        this.setState({
          mro_category_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getMROCategoryData();
      } else {
        //  let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.result.errorDetail[0].errorMessage
        //     ? res.data.result.errorDetail[0].errorMessage
        //     : "Error!",
        //   alertModalMessage:
        //     alertMessage !== "" ? alertMessage : res.data.errorMessage,
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get Location type details data */
  async getMROCategoryDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await MROCategoryService.getMROCategoryBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.mro_category_name = res.data.mro_category_name
          ? res.data.mro_category_name
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update Location type data */
  updateMROCategory = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.mro_category_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        mro_category_name: this.state.mro_category_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await MROCategoryService.updateMROCategory(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditMROCategoryModal();
        this.setState({
          mro_category_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getMROCategoryData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.result.errorDetail[0].errorMessage
        //     ? res.data.result.errorDetail[0].errorMessage
        //     : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete Location type data */
  async deleteMROCategory(slug) {
    this.setState({ showSpinner: true });
    let res = await MROCategoryService.deleteMROCategory(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteMROCategoryModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getMROCategoryData();
    } else {
      this.closeDeleteMROCategoryModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* for Location type modal */
  openAddMROCategoryModal() {
    this.setState({ addMROCategoryModal: true });
  }

  closeAddMROCategoryModal() {
    this.setState({ addMROCategoryModal: false });
    this.setState({
      mro_category_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openEditMROCategoryModal(slug) {
    this.getMROCategoryDetails(slug);
    this.setState({ editMROCategoryModal: true });
    this.setState({ slug: slug });
  }

  closeEditMROCategoryModal() {
    this.setState({ editMROCategoryModal: false });
    this.setState({
      slug: "",
      mro_category_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openDeleteMROCategoryModal(slug) {
    this.setState({ deleteMROCategoryModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteMROCategoryModal() {
    this.setState({ deleteMROCategoryModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "auto",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Maintenance Category";
  }

  render() {
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                          <Button
                            variant="outline-dark btn-block btn-with-icon"
                            onClick={this.openAddMROCategoryModal}
                          >
                            <img src={pluswIcon} alt="" /> Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.mroCategoryListTotal > 0 ? (
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th className="w-50">
                                Maintenance Category Name
                              </th>
                              <th className="w-25">Status</th>
                              {global.userPermissions.checkPermission(
                                "master-data-delete"
                              ) && <th className="text-center">Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.mroCategoryListTotal > 0 ? (
                              this.state.mroCategoryList.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      className="hand-cursor"
                                      onClick={() => {
                                        global.userPermissions.checkPermission(
                                          "master-data-update"
                                        ) &&
                                          this.openEditMROCategoryModal(
                                            item.slug
                                          );
                                      }}
                                    >
                                      {item.mro_category_name}
                                    </td>
                                    <td>
                                      {item.status_id === 1 ? (
                                        <b className="active"></b>
                                      ) : (
                                        <b></b>
                                      )}
                                    </td>
                                    {global.userPermissions.checkPermission(
                                      "master-data-delete"
                                    ) && (
                                      <td>
                                        <div className="btn-icon-list d-block text-center">
                                          <img
                                            src={deleteicon}
                                            alt="Delete"
                                            onClick={() =>
                                              this.openDeleteMROCategoryModal(
                                                item.slug
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                  There are no mro category added in the system.
                                  You may add them by clicking Add button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage(
                            "MAROCATEGORY"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add Location type popup modal */}
        <Modal
          show={this.state.addMROCategoryModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddMROCategoryModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddMROCategoryModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Maintenance Category</h3>
            <label>Maintenance Category Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="mro_category_name"
              value={this.state.mro_category_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
            />
            {this.state.errors.mro_category_name ? (
              <div className="text-danger">
                {this.state.errors.mro_category_name}
              </div>
            ) : (
              this.validator.message(
                "mro_category_name",
                this.state.mro_category_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "Active" : "Inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddMROCategoryModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveMROCategory}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit Location type popup modal */}
        <Modal
          show={this.state.editMROCategoryModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditMROCategoryModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditMROCategoryModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Maintenance Category</h3>
            <label>Maintenance Category Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="mro_category_name"
              value={this.state.mro_category_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
            />
            {this.state.errors.mro_category_name ? (
              <div className="text-danger">
                {this.state.errors.mro_category_name}
              </div>
            ) : (
              this.validator.message(
                "mro_category_name",
                this.state.mro_category_name,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "Active" : "Inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditMROCategoryModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateMROCategory}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete Location type popup modal */}
        <Modal
          show={this.state.deleteMROCategoryModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteMROCategoryModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteMROCategoryModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteMROCategoryModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteMROCategory(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default MROCategory;
