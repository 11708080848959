//                                                           //
//  Program: CrmCampaign.jsx                                 //
//  Application: Report                                      //
//  Option: CRM Contact List Listing                         //
//  Developer: Raj Kumar	                                     //
//  Date: 2023-04-06                                         //
//                                                           //
/////////////////////////////////////////////////////////   ///

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import CrmCampaignService from "../../services/CrmCampaignService";
import { Link } from "react-router-dom";
import { Container, Nav, Tab, Modal } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import NoData from "../common/NoData";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import CrmSettingSidebar from "../common/CrmSettingSidebar";
import ContactSidebar from "../common/ContactSidebar";
import Moment from "moment";
import BrandSidebar from "../common/BrandSidebar";
import ClientSidebar from "../common/ClientSidebar";
export class CrmCampaign extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    const pathStr = props.match.path;
    const pathArray = pathStr.split(":");
    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      isSearch: global.isSearch,
      module_search: "",
      is_search: 0,
      input: {},
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : "",
      brand_slug:
        pathArray[0] === "/brand/view/" ? this.props.match.params.slug : "",
      client_slug:
        pathArray[0] === "/client/view/" ? this.props.match.params.slug : "",
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      campaign_name: "",
      schedule_date: "",
      schedule_time: "",
      description: "",
      no_of_list: "",
      stopStart: "",
      records: [],
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      selectedStatusList: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "campaign_name",
          text: "Campaign",
          className: "title hand-cursor",
          sortable: true,
          cell: (record) => {
            return (record.campaign_status === 7 ||
              record.campaign_status === 6 ||
              record.campaign_status === 5 ||
              record.campaign_status === 4 ||
              record.campaign_status === 3 ||
              record.campaign_status === 2 ||
              record.campaign_status === 1) &&
              record.slug &&
              this.state.property_slug &&
              this.state.brand_slug === "" &&
              this.state.client_slug === "" ? (
              <Link
                to={
                  "/properties/view/" +
                  this.state.property_slug +
                  "/crm-campaign/report/" +
                  record.slug
                }
              >
                {record.campaign_name}
              </Link>
            ) : (record.campaign_status === 7 ||
                record.campaign_status === 6 ||
                record.campaign_status === 5 ||
                record.campaign_status === 4 ||
                record.campaign_status === 3 ||
                record.campaign_status === 2 ||
                record.campaign_status === 1) &&
              record.slug &&
              this.state.property_slug === "" &&
              this.state.brand_slug &&
              this.state.client_slug === "" ? (
              <Link
                to={
                  "/brand/view/" +
                  this.state.brand_slug +
                  "/crm-campaign/report/" +
                  record.slug
                }
              >
                {record.campaign_name}
              </Link>
            ) : (record.campaign_status === 7 ||
                record.campaign_status === 6 ||
                record.campaign_status === 5 ||
                record.campaign_status === 4 ||
                record.campaign_status === 3 ||
                record.campaign_status === 2 ||
                record.campaign_status === 1) &&
              record.slug &&
              this.state.property_slug === "" &&
              this.state.brand_slug === "" &&
              this.state.client_slug ? (
              <Link
                to={
                  "/client/view/" +
                  this.state.client_slug +
                  "/crm-campaign/report/" +
                  record.slug
                }
              >
                {record.campaign_name}
              </Link>
            ) : (record.campaign_status === 7 ||
                record.campaign_status === 6 ||
                record.campaign_status === 5 ||
                record.campaign_status === 4 ||
                record.campaign_status === 3 ||
                record.campaign_status === 2 ||
                record.campaign_status === 1) &&
              record.slug &&
              this.state.property_slug === "" &&
              this.state.brand_slug === "" &&
              this.state.client_slug === "" ? (
              <Link to={"/crm-campaign/report/" + record.slug}>
                {record.campaign_name}
              </Link>
            ) : (
              record.campaign_name
            );
          },
        },

        {
          key: "schedule_date",
          text: "Scheduled On",
          className: "schedule_date date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return (record.campaign_status === 7 ||
              record.campaign_status === 6 ||
              record.campaign_status === 5 ||
              record.campaign_status === 4 ||
              record.campaign_status === 3 ||
              record.campaign_status === 2 ||
              record.campaign_status === 1) &&
              record.slug &&
              this.state.property_slug &&
              record.starts_at_date !== "" &&
              this.state.brand_slug === "" &&
              this.state.client_slug === "" ? (
              <Link
                to={
                  "/properties/view/" +
                  this.state.property_slug +
                  "/crm-campaign/report/" +
                  record.slug
                }
              >
                <div className="">
                  {Moment(record.starts_at_date).format(global.dateFormat)}
                  {record.starts_at_time ? (
                    <p className="m-0">{record.starts_at_time}</p>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            ) : (record.campaign_status === 7 ||
                record.campaign_status === 6 ||
                record.campaign_status === 5 ||
                record.campaign_status === 4 ||
                record.campaign_status === 3 ||
                record.campaign_status === 2 ||
                record.campaign_status === 1) &&
              record.slug &&
              this.state.property_slug === "" &&
              record.starts_at_date !== "" &&
              this.state.brand_slug !== "" &&
              this.state.client_slug === "" ? (
              <Link
                to={
                  "/brand/view/" +
                  this.state.brand_slug +
                  "/crm-campaign/report/" +
                  record.slug
                }
              >
                {" "}
                <div className="">
                  {Moment(record.starts_at_date).format(global.dateFormat)}
                  {record.starts_at_time ? (
                    <p className="m-0">{record.starts_at_time}</p>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            ) : (record.campaign_status === 7 ||
                record.campaign_status === 6 ||
                record.campaign_status === 5 ||
                record.campaign_status === 4 ||
                record.campaign_status === 3 ||
                record.campaign_status === 2 ||
                record.campaign_status === 1) &&
              record.slug &&
              this.state.property_slug === "" &&
              record.starts_at_date !== "" &&
              this.state.brand_slug === "" &&
              this.state.client_slug ? (
              <Link
                to={
                  "/client/view/" +
                  this.state.client_slug +
                  "/crm-campaign/report/" +
                  record.slug
                }
              >
                <div className="">
                  {Moment(record.starts_at_date).format(global.dateFormat)}
                  {record.starts_at_time ? (
                    <p className="m-0">{record.starts_at_time}</p>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            ) : (record.campaign_status === 7 ||
                record.campaign_status === 6 ||
                record.campaign_status === 5 ||
                record.campaign_status === 4 ||
                record.campaign_status === 3 ||
                record.campaign_status === 2 ||
                record.campaign_status === 1) &&
              record.slug &&
              this.state.property_slug === "" &&
              record.starts_at_date !== "" &&
              this.state.brand_slug === "" &&
              this.state.client_slug === "" ? (
              <Link to={"/crm-campaign/report/" + record.slug}>
                <div className="">
                  {Moment(record.starts_at_date).format(global.dateFormat)}
                  {record.starts_at_time ? (
                    <p className="m-0">{record.starts_at_time}</p>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "created_at",
          text: "Created at",
          className: "created_at date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return (record.campaign_status === 7 ||
              record.campaign_status === 6 ||
              record.campaign_status === 5 ||
              record.campaign_status === 4 ||
              record.campaign_status === 3 ||
              record.campaign_status === 2 ||
              record.campaign_status === 1) &&
              record.slug &&
              record.timezone_name &&
              this.state.property_slug &&
              this.state.brand_slug === "" &&
              this.state.client_slug === "" ? (
              <Link
                to={
                  "/properties/view/" +
                  this.state.property_slug +
                  "/crm-campaign/report/" +
                  record.slug
                }
              >
                <div className="">
                  {Moment(record.createdDate).format(global.dateFormat)}
                  {record.createdTime ? (
                    <p className="m-0">{record.createdTime}</p>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            ) : (record.campaign_status === 7 ||
                record.campaign_status === 6 ||
                record.campaign_status === 5 ||
                record.campaign_status === 4 ||
                record.campaign_status === 3 ||
                record.campaign_status === 2 ||
                record.campaign_status === 1) &&
              record.slug &&
              record.timezone_name &&
              this.state.property_slug === "" &&
              this.state.brand_slug !== "" &&
              this.state.client_slug === "" ? (
              <Link
                to={
                  "/brand/view/" +
                  this.state.brand_slug +
                  "/crm-campaign/report/" +
                  record.slug
                }
              >
                <div className="">
                  {Moment(record.createdDate).format(global.dateFormat)}
                  {record.createdTime ? (
                    <p className="m-0">{record.createdTime}</p>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            ) : (record.campaign_status === 7 ||
                record.campaign_status === 6 ||
                record.campaign_status === 5 ||
                record.campaign_status === 4 ||
                record.campaign_status === 3 ||
                record.campaign_status === 2 ||
                record.campaign_status === 1) &&
              record.slug &&
              record.timezone_name &&
              this.state.property_slug === "" &&
              this.state.brand_slug === "" &&
              this.state.client_slug ? (
              <Link
                to={
                  "/client/view/" +
                  this.state.client_slug +
                  "/crm-campaign/report/" +
                  record.slug
                }
              >
                <div className="">
                  {Moment(record.createdDate).format(global.dateFormat)}
                  {record.createdTime ? (
                    <p className="m-0">{record.createdTime}</p>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            ) : (record.campaign_status === 7 ||
                record.campaign_status === 6 ||
                record.campaign_status === 5 ||
                record.campaign_status === 4 ||
                record.campaign_status === 3 ||
                record.campaign_status === 2 ||
                record.campaign_status === 1) &&
              record.slug &&
              record.timezone_name &&
              this.state.property_slug === "" &&
              this.state.brand_slug === "" &&
              this.state.client_slug === "" ? (
              <Link to={"/crm-campaign/report/" + record.slug}>
                <div className="">
                  {Moment(record.createdDate).format(global.dateFormat)}
                  {record.createdTime ? (
                    <p className="m-0">{record.createdTime}</p>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "total_sent",
          text: "Recipients",
          className: "total_sent",
          sortable: false,
          cell: (record) => {
            return record.total_recipient
              ? ` ${record.total_sent} /  ${record.total_recipient}`
              : record.totalListData
              ? ` ${record.total_sent} /  ${record.totalListData}`
              : "";
          },
        },
        {
          key: "campaign_status",
          text: "Campaign Status",
          className: "campaign_status",
          sortable: true,
          cell: (record) => {
            return record.campaign_status === 1
              ? "Scheduled"
              : record.campaign_status === 2
              ? "Draft"
              : record.campaign_status === 3
              ? "Data Preparation"
              : record.campaign_status === 4
              ? "Schedule Locked"
              : record.campaign_status === 5 &&
                record.campaign_start_stop === null
              ? "Running"
              : record.campaign_status === 5 &&
                record.campaign_start_stop !== null
              ? "Stoped"
              : record.campaign_status === 6
              ? "Delivered"
              : record.campaign_status === 7
              ? "Failed"
              : "Failed";
          },
        },

        {
          key: "action",
          text: "Action",
          className: "action hand-cursor td_clickable",
          sortable: false,
          cell: (record) => {
            return record.campaign_status === 5 ? (
              <p className="m-0">
                <span
                  className="blue-link-cust"
                  onClick={() =>
                    this.openStopStartCampaignModal(
                      record.slug,
                      record.startStop
                    )
                  }
                >
                  {record.startStop}
                </span>
              </p>
            ) : (
              ""
            );
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active"></b>
            ) : (
              <b className="status-inactive"></b>
            );
          },
        },
      ],
      tab: "active",
      clientTabData: localStorage.getItem("client_tab_data")
        ? localStorage.getItem("client_tab_data")
        : "",
      activeClass: "activeTab",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.stopAndStartCampaign = this.stopAndStartCampaign.bind(this);
    this.openStopStartCampaignModal =
      this.openStopStartCampaignModal.bind(this);
    this.closeStopStartCampaignModal =
      this.closeStopStartCampaignModal.bind(this);
  }

  componentDidMount() {
    switch (this.state.clientTabData) {
      case "active":
        document.getElementById("activeTab").click();
        break;
      case "inactive":
        document.getElementById("inactiveTab").click();
        break;
      default:
        document.getElementById("activeTab").click();
    }
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "client_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "client_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await CrmCampaignService.getCampaignData(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        "&brand_slug=" +
        (this.state.brand_slug ? this.state.brand_slug : "") +
        "&client_slug=" +
        (this.state.client_slug ? this.state.client_slug : "") +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  openStopStartCampaignModal(slug, startStops) {
    this.setState({ stopStart: startStops });
    setTimeout(
      () => this.setState({ stopStartModal: true }),
      this.setState({ slug: slug }),
      global.redirect_time
    );
  }

  closeStopStartCampaignModal() {
    this.setState({ stopStartModal: false });
    this.setState({ slug: "" });
  }
  async stopAndStartCampaign(slug) {
    this.setState({ showSpinner: true });
    let res = await CrmCampaignService.stopStartCampaignBySlug(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeStopStartCampaignModal();

      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.getData();
    } else {
      this.closeStopStartCampaignModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  handleChangeStatusList(value) {
    let status_id = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData("tab=" + this.state.tab + "&" + queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  render() {
    return (
      <>
        <div>
          <main>
            <Container className="innter-container">
              <div className="d-flex flex-column">
                <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <aside>
                        {this.state.property_slug ? (
                          <ContactSidebar
                            property_slug={this.state.property_slug}
                          />
                        ) : this.state.brand_slug ? (
                          <BrandSidebar brand_slug={this.state.brand_slug} />
                        ) : this.state.client_slug ? (
                          <ClientSidebar clientSlug={this.state.client_slug} />
                        ) : (
                          <CrmSettingSidebar />
                        )}
                      </aside>
                    </div>
                  </div>
                  <div className="col-md-10 right">
                    <div className="scrolling-carousel">
                      <div className="col-md-12 mg-t-0 mg-b-0 pd-t-0 pd-b-0 pd-l-15 pd-r-0">
                        <div className="row row-xs">
                          <div className="col-md-12 mg-t-10 mg-b-10"></div>
                          <div className="col-md-12">
                            <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                              <h3 className="brnd_heading mb-0">Campaign</h3>
                              <div className="d-flex flex-wrap">
                                {global.userPermissions.checkPermission(
                                  "crm-campaigns-add"
                                ) && (
                                  <Link
                                    to={
                                      this.state.property_slug
                                        ? "/properties/view/" +
                                          this.state.property_slug +
                                          "/crm-campaign/add"
                                        : this.state.brand_slug
                                        ? "/brand/view/" +
                                          this.state.brand_slug +
                                          "/crm-campaign/add"
                                        : this.state.client_slug
                                        ? "/client/view/" +
                                          this.state.client_slug +
                                          "/crm-campaign/add"
                                        : "/crm-campaign/add"
                                    }
                                  >
                                    <button className="btn-success-outline ">
                                      <img src={pluswIcon} alt="Add" /> Add
                                    </button>
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Container className="p-0 container">
                        <div className="pd-lg-l-15 pd-xs-l-15">
                          <div className="scrolling-carousel scroll-slide">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="all"
                              onSelect={(e) => this.getActiveClass(e)}
                              eventKey={this.state.activeClass}
                              className="pd-r-60"
                            >
                              <ScrollingCarousel
                                rightArrow={true}
                                leftArrow={true}
                                className="swipesection pd-t-20"
                              >
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="activeTab"
                                    id="activeTab"
                                    className={
                                      this.state.activeClass === "activeTab"
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Active
                                  </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="inactiveTab"
                                    id="inactiveTab"
                                    className={
                                      this.state.activeClass === "inactiveTab"
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Inactive
                                  </Nav.Link>
                                </Nav.Item>
                              </ScrollingCarousel>
                              <Tab.Content>
                                <Tab.Pane eventKey="activeTab">
                                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                    {this.state.total_record > 0 ||
                                    this.state.filter_value !== "" ? (
                                      <div className="mg-t-20 brandList">
                                        <ReactDatatable
                                          className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                          config={
                                            this.state.total_record >
                                            global.page_size
                                              ? this.state.config
                                              : this.state.config
                                          }
                                          records={this.state.records}
                                          columns={this.state.columns}
                                          extraButtons={this.state.extraButtons}
                                          dynamic={true}
                                          total_record={this.state.total_record}
                                          onChange={this.tableChangeHandler}
                                        />
                                      </div>
                                    ) : (
                                      <NoData msg="active campaign" />
                                    )}
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="inactiveTab">
                                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                    {this.state.total_record > 0 ||
                                    this.state.filter_value !== "" ? (
                                      <div className="mg-t-20 brandList">
                                        <ReactDatatable
                                          className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                          config={
                                            this.state.total_record >
                                            global.page_size
                                              ? this.state.config
                                              : this.state.config
                                          }
                                          records={this.state.records}
                                          columns={this.state.columns}
                                          extraButtons={this.state.extraButtons}
                                          dynamic={true}
                                          total_record={this.state.total_record}
                                          onChange={this.tableChangeHandler}
                                        />
                                      </div>
                                    ) : (
                                      <NoData msg="inactive campaign" />
                                    )}
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
            <Modal show={this.state.stopStartModal} centered>
              <Modal.Body className="width_402 p-4 border_radius_14">
                <div className="text-center">
                  <h3>{this.state.stopStart} the Campaign Process?</h3>
                  <p>
                    Do you really want to {this.state.stopStart} this campaign?
                    This process cannot be undone.
                  </p>
                  <div className="d-flex justify-content-center mt-3 row">
                    <button
                      type="button"
                      className="m-0 btn btn-outline-light btn-block"
                      onClick={() => this.closeStopStartCampaignModal()}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="m-0 btn btn btn-danger btn-block"
                      onClick={() => this.stopAndStartCampaign(this.state.slug)}
                      disabled={this.state.isSubmit ? true : false}
                    >
                      {this.state.isSubmit
                        ? global.loader
                        : this.state.stopStart}
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </main>
        </div>
      </>
    );
  }
}
export default CrmCampaign;
