import React, { Component } from "react";
import defaultImage from "./../../assets/images/profile-nogender.png";
import { Navbar, Container } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
// import propertyLogo from "./../../assets/images/property_placeholder.png";
import { Link } from "react-router-dom";
import searchIcon from "./../../assets/images/search-meg.png";
import MultiImageService from "./../../services/MultiImageService";
import RUmessageService from "../../services/RUmessageService";
import NotificationService from "../../services/NotificationService";
import guestimg from "./../../assets/images/guest.jpeg";
import Moment from "moment";
// import ChatIcon from "./../../assets/images/message_chat.png";
import BellIcon from "./../../assets/images/bell_icon.svg";
import ChatIcon from "./../../assets/images/chat_mini.svg";

import { Drawer, Button } from "rsuite";

class HeaderTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerToken: localStorage.getItem("headerToken"),
      profileImage:
        localStorage.getItem("userData") &&
        JSON.parse(localStorage.getItem("userData")).profile_thumb_url !==
          null &&
        JSON.parse(localStorage.getItem("userData")).profile_thumb_url !==
          undefined &&
        JSON.parse(localStorage.getItem("userData")).profile_thumb_url !== ""
          ? JSON.parse(localStorage.getItem("userData")).profile_thumb_url
          : defaultImage,
      first_name: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).first_name
        : "",
      url_slug: window.location.pathname,
      // property_name:sessionStorage.getItem('property_name') ? sessionStorage.getItem('property_name') : ''
      property_name: this.props.property_name
        ? this.props.property_name
        : sessionStorage.getItem("property_name") !== undefined &&
          sessionStorage.getItem("property_name") !== null &&
          sessionStorage.getItem("property_name") !== ""
        ? sessionStorage.getItem("property_name")
        : localStorage.getItem("property_name")
        ? localStorage.getItem("property_name")
        : "",

      property_slug: this.props.property_slug
        ? this.props.property_slug
        : sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
      proSlug: localStorage.getItem("currerntPropSlug"),
      role_slug: global.currerntRoleSlug ? global.currerntRoleSlug : null,
      refList: [],
      newRefList: [],
      colList: [],
      searchList: [],
      searchListTotal: 0,
      ref_id: "",
      ref_col_name: "",
      ref_col_label: "",
      search_value: "",
      isSearchModal: false,
      show: false,
      openWithHeader: false,
      defaultLandingPage: JSON.parse(localStorage.getItem("roleList")),
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.redirectToModule = this.redirectToModule.bind(this);
    this.openSearchModal = this.openSearchModal.bind(this);
    this.setOpenWithHeader = this.setOpenWithHeader.bind(this);
    this.setCloseWithHeader = this.setCloseWithHeader.bind(this);
    // this.messageCount = this.messageCount.bind(this);
    this.NotificationList = this.NotificationList.bind(this);
    this.NotificationCount = this.NotificationCount.bind(this);
    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.searchClick = this.searchClick.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
  }

  setOpenWithHeader() {
    this.setState({ openWithHeader: true });
  }

  setCloseWithHeader() {
    this.setState({ openWithHeader: false });
  }

  async componentDidMount() {
    this.setState({
      showSpinner: true,
      loading: true,
    });
    if (localStorage.getItem("message_page") === "1") {
      this.messageCount();
    }
    if (localStorage.getItem("notifyCount") === 1) {
      this.NotificationCount();
      this.NotificationList();
    }

    this.getRefData();
    this.setState({ showSpinner: false, loading: false, customSpinner: false });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (localStorage.getItem("message_page") == 1) {
      this.messageCount();
    }
  }

  async messageCount() {
    this.setState({ showSpinner: true, loading: true });
    var queryString =
      "property_slug=" +
      (this.props.property_slug
        ? this.props.property_slug
        : sessionStorage.getItem("property_slug")) +
      "&role_slug=" +
      this.state.role_slug;
    let res = await RUmessageService.messageCountPropertyWise(queryString);
    var msgCount = "";
    if (global.successStatus.includes(res.status)) {
      msgCount = res.data && res.data[0] ? res.data[0].unread_count : 0;
    }
    this.setState({ msgCountApi: msgCount });
    this.setState({ showSpinner: false, loading: false });
  }

  async getRefData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var refList = [];

    let res = await MultiImageService.getActiveRefTables(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      refList = res.data ? res.data : {};

      var newRefList = [];
      newRefList =
        refList.length > 0
          ? refList.map(
              ({ ref_table_id, ref_table_label, ref_table_name }) => ({
                value: ref_table_id,
                label: ref_table_label,
              })
            )
          : [];
    }
    this.setState({ refList: refList, newRefList: newRefList });
    this.setState({ showSpinner: false, loading: false });
  }

  // async getGlobalFilterData(value, queryString = "") {
  //   // this.setState({ showSpinner: true, loading: true });
  //   var searchList = [];
  //   var searchListTotal = 0;

  //   let res = await MultiImageService.getSearchFilterData("refTableId=" + (this.state.ref_id ? this.state.ref_id : '') + "&globalSearch=" + (value ? value : this.state.search_value));
  //   if (global.successStatus.includes(res.status)) {
  //     searchList = res.data ? res.data : [];
  //     searchListTotal = searchList ? searchList.length : 0
  //   }
  //   this.setState({ searchList: searchList, searchListTotal: searchListTotal });
  //   // this.setState({ showSpinner: false, loading: false });
  // }

  async getGlobalFilterData(value, queryString = "") {
    // ref table id with name
    // 1. company
    // 2. brand
    // 3. property
    // 4. florr plan
    // 5. unit
    // 6. room
    // 7. staff
    // 8. events
    // 9. near by attraction
    // 10. listing
    // 11. leasing
    // 12. reservation
    // 15. tower
    // 16. floor
    // 17. applicants
    // 18. Guest User
    // 19. Resident
    // 20. User
    if (value && this.state.ref_id) {
      if (this.state.ref_id === 2) {
        // this.props.history.push("/properties?isGlobal=1&global_search="+value);
        window.location.href =
          "/brands?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 3) {
        // this.props.history.push("/properties?isGlobal=1&global_search="+value);
        window.location.href =
          "/properties?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 4) {
        // this.props.history.push("/properties/view/"+this.state.property_slug+"/floor-plans?isGlobal=1&global_search="+value);
        window.location.href =
          "/properties/view/" +
          (this.props.property_slug
            ? this.props.property_slug
            : sessionStorage.getItem("property_slug")) +
          "/floor-plans?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 5) {
        // this.props.history.push("/properties/view/"+this.state.property_slug+"/units?isGlobal=1&global_search="+value);
        if (this.state.ref_col_name == "available_from") {
          value = Moment(value).format("YYYY-MM-DD");
        }
        window.location.href =
          "/properties/view/" +
          (this.props.property_slug
            ? this.props.property_slug
            : sessionStorage.getItem("property_slug")) +
          "/units?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 8) {
        // this.props.history.push("/properties/view/"+this.state.property_slug+"/units?isGlobal=1&global_search="+value);
        window.location.href =
          "/properties/view/" +
          (this.props.property_slug
            ? this.props.property_slug
            : sessionStorage.getItem("property_slug")) +
          "/events?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 10) {
        // this.props.history.push("/properties/view/"+this.state.property_slug+"/listing?isGlobal=1&global_search="+value);
        if (this.state.ref_col_name == "from_date") {
          value = Moment(value).format("YYYY-MM-DD");
        } else if (this.state.ref_col_name == "to_date") {
          value = Moment(value).format("YYYY-MM-DD");
        }
        window.location.href =
          "/properties/view/" +
          (this.props.property_slug
            ? this.props.property_slug
            : sessionStorage.getItem("property_slug")) +
          "/listing?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 11) {
        // this.props.history.push("/properties/view/"+this.state.property_slug+"/leasing?isGlobal=1&global_search="+value);
        window.location.href =
          "/properties/view/" +
          (this.props.property_slug
            ? this.props.property_slug
            : sessionStorage.getItem("property_slug")) +
          "/leasing?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 12) {
        // this.props.history.push("/properties/view/"+this.state.property_slug+"/reservations?isGlobal=1&global_search="+value);
        window.location.href =
          "/properties/view/" +
          (this.props.property_slug
            ? this.props.property_slug
            : sessionStorage.getItem("property_slug")) +
          "/reservations?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 15) {
        // this.props.history.push("/properties/view/"+this.state.property_slug+"/reservations?isGlobal=1&global_search="+value);
        window.location.href =
          "/properties/view/" +
          (this.props.property_slug
            ? this.props.property_slug
            : sessionStorage.getItem("property_slug")) +
          "/tower?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 16) {
        // this.props.history.push("/properties/view/"+this.state.property_slug+"/reservations?isGlobal=1&global_search="+value);
        window.location.href =
          "/properties/view/" +
          (this.props.property_slug
            ? this.props.property_slug
            : sessionStorage.getItem("property_slug")) +
          "/floors?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 17) {
        // this.props.history.push("/properties/view/"+this.state.property_slug+"/reservations?isGlobal=1&global_search="+value);
        window.location.href =
          "/properties/view/" +
          (this.props.property_slug
            ? this.props.property_slug
            : sessionStorage.getItem("property_slug")) +
          "/applicants?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 18) {
        // this.props.history.push("/properties/view/"+this.state.property_slug+"/reservations?isGlobal=1&global_search="+value);
        window.location.href =
          "/properties/view/" +
          (this.props.property_slug
            ? this.props.property_slug
            : sessionStorage.getItem("property_slug")) +
          "/guest?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 19) {
        // this.props.history.push("/properties/view/"+this.state.property_slug+"/reservations?isGlobal=1&global_search="+value);
        window.location.href =
          "/properties/view/" +
          (this.props.property_slug
            ? this.props.property_slug
            : sessionStorage.getItem("property_slug")) +
          "/residents?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else if (this.state.ref_id === 20) {
        // this.props.history.push("/properties/view/"+this.state.property_slug+"/reservations?isGlobal=1&global_search="+value);
        window.location.href =
          "/properties/view/" +
          (this.props.property_slug
            ? this.props.property_slug
            : sessionStorage.getItem("property_slug")) +
          "/users?isGlobal=1&global_search=" +
          value +
          "&global_col_name=" +
          this.state.ref_col_name +
          "&global_col_label=" +
          this.state.ref_col_label;
      } else {
        this.props.history.push(
          "/properties?isGlobal=1&global_search=" +
            value +
            "&global_col_name=" +
            this.state.ref_col_name +
            "&global_col_label=" +
            this.state.ref_col_label
        );
      }
    }
  }

  changeHandler = (event) => {
    if (event.target.name === "search_value") {
      this.setState({ [event.target.name]: event.target.value });
      // if (event.target.value) {
      //   this.getGlobalFilterData(event.target.value);
      // } else {
      //   this.setState({ searchListTotal: 0 });
      // }
    }
  };

  // searchClick(){
  //   this.setState({search:!this.state.search});
  // }

  closeMenu(e) {
    e.target.closest(".dropdown").classList.remove("show");
    e.target.closest(".dropdown .dropdown-menu").classList.remove("show");
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      document.querySelector("body").classList.remove("az-header-menu-show");
    }
  }

  handleChangeRefTableList(value) {
    let selectedRefId = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        selectedRefId.push(item.value);
      });
    } else {
      this.setState({
        colList: [],
      });
    }
    this.setState({
      selectedRefId: value,
      ref_id: value.value,
    });
    this.getColNames(value.value);
    // this.setState({ confirm_back: 1 });
  }

  getColNames(value) {
    let data = [];
    if (value === 2) {
      data = global.brandColList;
      this.setState({ colList: data });
    } else if (value === 3) {
      data = global.propertyColList;
      this.setState({ colList: data });
    } else if (value === 4) {
      data = global.floorplanColList;
      this.setState({ colList: data });
    } else if (value === 5) {
      data = global.unitColList;
      this.setState({ colList: data });
    } else if (value === 8) {
      data = global.eventColList;
      this.setState({ colList: data });
    } else if (value === 10) {
      data = global.listingColList;
      this.setState({ colList: data });
    } else if (value === 11) {
      data = global.leasingColList;
      this.setState({ colList: data });
    } else if (value === 12) {
      data = global.reservationColList;
      this.setState({ colList: data });
    } else if (value === 15) {
      data = global.towerColList;
      this.setState({ colList: data });
    } else if (value === 16) {
      data = global.floorColList;
      this.setState({ colList: data });
    } else if (value === 17) {
      data = global.applicantColList;
      this.setState({ colList: data });
    } else if (value === 18) {
      data = global.guestColList;
      this.setState({ colList: data });
    } else if (value === 19) {
      data = global.residentColList;
      this.setState({ colList: data });
    } else if (value === 20) {
      data = global.userColList;
      this.setState({ colList: data });
    } else {
      data = global.commonColList;
      this.setState({ colList: data });
    }
    return data;
  }

  handleChangeColList(value) {
    let colLabel = value.label;
    let colName = value.value;
    this.setState({ ref_col_label: colLabel, ref_col_name: colName });
  }

  openSearchModal() {
    this.setState({ isSearchModal: !this.state.isSearchModal });
    // this.setState({ isSearchModal: false });
  }

  redirectToModule(ref_table_id, slug, property_slug) {
    // ref table id with name
    // 1. company
    // 2. brand
    // 3. property
    // 4. florr plan
    // 5. unit
    // 6. room
    // 7. staff
    // 8. events
    // 9. near by attraction
    // 10. listing
    // 11. leasing
    // 12. reservation
    if (ref_table_id === 1) {
      return "/company";
    } else if (ref_table_id === 2) {
      return "/brands";
    } else if (ref_table_id === 3) {
      return "/properties";
    } else if (ref_table_id === 4) {
      return "/properties/view/" + property_slug + "/floor-plans/view/" + slug;
    } else if (ref_table_id === 5) {
      return (
        "/properties/view/" + property_slug + "/unit/" + slug + "/unit-basic"
      );
    } else if (ref_table_id === 6) {
      return "/properties";
    } else if (ref_table_id === 7) {
      return "/properties/view/" + property_slug + "/staff";
    } else if (ref_table_id === 8) {
      return "/properties/view/" + property_slug + "/events/view/" + slug;
    } else if (ref_table_id === 9) {
      return (
        "/properties/view/" +
        property_slug +
        "/near-by-attractions/view/" +
        slug
      );
    } else if (ref_table_id === 10) {
      return "/properties/view/" + property_slug + "/listing/" + slug + "/info";
    } else if (ref_table_id === 11) {
      return "/properties/view/" + property_slug + "/leasing/" + slug + "/info";
    } else if (ref_table_id === 12) {
      return "/properties/view/" + property_slug + "/reservations/view/" + slug;
    } else {
      return "/properties";
    }
  }

  // handleShow() {
  //   this.setState({ show: true });
  // }

  // handleClose() {
  //   this.setState({ show: false });
  // }

  //Notification List
  async NotificationList() {
    this.setState({ showSpinner: true, loading: true });
    var queryString =
      "property_slug=" +
      (this.props.property_slug
        ? this.props.property_slug
        : sessionStorage.getItem("property_slug")) +
      "&request_type=1";
    var notificationList = [];
    let res = await NotificationService.NotificationList(queryString);

    if (global.successStatus.includes(res.status)) {
      notificationList = res.data ? res.data.data : {};
    }
    this.setState({ notificationList: notificationList });
  }

  //NotificationCount
  async NotificationCount() {
    this.setState({ showSpinner: true, loading: true });

    var queryString =
      "property_slug=" +
      (this.props.property_slug
        ? this.props.property_slug
        : sessionStorage.getItem("property_slug")) +
      "&request_type=1";
    let res = await NotificationService.NotificationCount(queryString);
    var notificationCount = [];
    if (global.successStatus.includes(res.status)) {
      notificationCount = res.data ? res.data.unread : {};
    }
    this.setState({
      notificationCount: notificationCount,
    });
    localStorage.setItem("notifyCount", 0);
    localStorage.setItem("notifyCount", this.state.notificationCount);
  }

  // Notification Read All
  async notificationReadAll() {
    this.setState({ showSpinner: true, loading: true });
    var queryString =
      "property_slug=" +
      (this.props.property_slug
        ? this.props.property_slug
        : sessionStorage.getItem("property_slug")) +
      "&request_type=1";
    let res = await NotificationService.NotificationReadAll(queryString);
    var notificationReadAll = [];
    if (global.successStatus.includes(res.status)) {
      notificationReadAll = res.data ? res.data : {};
    }
    this.setState({ notificationReadAll: notificationReadAll });
    this.NotificationList();
  }

  async notificationClearAll() {
    this.setState({ showSpinner: true, loading: true });
    var queryString =
      "property_slug=" +
      (this.props.property_slug
        ? this.props.property_slug
        : sessionStorage.getItem("property_slug")) +
      "&request_type=1";
    let res = await NotificationService.notificationClearAll(queryString);
    var notificationClearAll = [];
    if (global.successStatus.includes(res.status)) {
      notificationClearAll = res.data ? res.data : {};
    }
    this.setState({ notificationClearAll: notificationClearAll });
    this.NotificationList();
  }

  removeMsgCountInn() {
    localStorage.setItem("messageCount", 0);
    this.setState({ msgCount: 0, msgCountGlobal: 0 });
    this.props.updatePropsGlobalmsg(0);
  }

  changePassword() {
    this.props.history.push(
      this.props.property_slug !== undefined
        ? "/properties/view/" +
            (this.props.property_slug
              ? this.props.property_slug
              : sessionStorage.getItem("property_slug")) +
            "/update-profile"
        : "/update-profile"
    );
  }

  updatePassword() {
    this.props.history.push(
      this.props.property_slug !== undefined
        ? "/properties/view/" +
            (this.props.property_slug
              ? this.props.property_slug
              : sessionStorage.getItem("property_slug")) +
            "/change-password"
        : "/change-password"
    );
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    var env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "";

    return (
      <div className="adminHeadertop">
        <Navbar expand="lg" className="mobheadertwo">
          <Container fluid className="d-flex justify-content-between pd-l-0">
            <div className="mobView col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3 text-left pd-l-0">
              <div className="searchboxai">
                <Link to="/snapshot" className="logo-contnet">
                  {" "}
                  <h4>OHAI</h4>
                </Link>
                {env ? <h6>{env}</h6> : ""}
              </div>
            </div>
            <Container className="leftNavbar">
              <div className="prop-logo-name" id="prop-name">
                <Link to="/snapshot" className="childback">
                  <i className="fas fa-chevron-left"></i>
                </Link>
                <h4>
                  {this.props.property_name !== "" &&
                  this.props.property_name !== undefined &&
                  this.props.property_name !== null
                    ? this.props.property_name
                    : this.state.property_name !== "" &&
                      this.state.property_name !== undefined &&
                      this.state.property_name !== "null" &&
                      this.state.property_name !== null
                    ? this.state.property_name
                    : "Property"}
                </h4>
              </div>
            </Container>
            <div className="rightNavbar col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
              <div className="d-flex justify-content-end search_pop_align">
                <div className="position-relative">
                  {/* open when search click */}
                  {this.state.isSearchModal && (
                    <div className="searchbar">
                      <div className="serach-select">
                        <div className="az-header-center">
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            options={this.state.newRefList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeRefTableList(value)
                            }
                            placeholder="Select module"
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                        </div>
                        <div className="az-header-center">
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            options={this.state.colList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeColList(value)
                            }
                            placeholder="Select column"
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                        </div>
                        <div className="az-header-center">
                          <input
                            type="search"
                            className="form-control"
                            onChange={this.changeHandler}
                            placeholder="Search for anything and hit enter..."
                            name="search_value"
                            value={this.state.search_value}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                this.getGlobalFilterData(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>

                      {/* result div */}
                      <div className="az-notification-list">
                        {this.state.searchListTotal > 0
                          ? this.state.searchList.map((item, i) => {
                              return (
                                <>
                                  {/* <h6 className="az-notification-title">Search Results....</h6> */}
                                  <div className="media new" key={i}>
                                    {/* <div className="az-img-user online">
                          <img
                            src={this.state.profileImage}
                            alt=""
                          ></img>
                        </div> */}
                                    <Link
                                      to={() =>
                                        this.redirectToModule(
                                          item.ref_table_id,
                                          item.finalDataArr.Slug,
                                          item.finalDataArr.propertySlug
                                        )
                                      }
                                    >
                                      <div className="media-body">
                                        <p>
                                          {item.searchable_text
                                            ? item.searchable_text
                                            : ""}
                                        </p>
                                        <span>
                                          {item.finalDataArr &&
                                          item.finalDataArr.refTableName
                                            ? item.finalDataArr.refTableName
                                            : ""}
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </>
                              );
                            })
                          : ""}
                        {/* <div className="dropdown-footer">
                  <a href="#/">View All Related Data</a>
                </div> */}
                      </div>
                    </div>
                  )}
                  {this.state.defaultLandingPage &&
                  this.state.defaultLandingPage[0] &&
                  this.state.defaultLandingPage[0].defaultLandingPage &&
                  this.state.defaultLandingPage[0].defaultLandingPage !== 3 ? (
                    <React.Fragment>
                      {/* globalsearchbar */}
                      <div className="d-flex justify-content-end globalsearchbar dropleft">
                        <div
                          className="navMob d-flex align-items-center hand-cursor"
                          onClick={this.openSearchModal}
                        >
                          <span className="mr-3">
                            <img src={searchIcon} alt="img" />
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
                <div className="messges-alert d-flex align-items-center mg-r-15">
                  {/* messeging */}
                  {global.userPermissions.checkPermission(
                    "communication-message-module"
                  ) &&
                    (this.state.msgCountApi != undefined &&
                    this.state.msgCountApi > 0 &&
                    this.props.socketThread == undefined ? (
                      <Link
                        to={
                          "/properties/view/" +
                          (this.props.property_slug
                            ? this.props.property_slug
                            : sessionStorage.getItem("property_slug")) +
                          "/messages"
                        }
                        className="mg-r-15"
                      >
                        <img src={ChatIcon} alt="" />
                        <span className={"active-msg"}></span>
                      </Link>
                    ) : (
                      <Link
                        to={
                          "/properties/view/" +
                          (this.props.property_slug
                            ? this.props.property_slug
                            : sessionStorage.getItem("property_slug")) +
                          "/messages"
                        }
                        className="mg-r-15"
                      >
                        <img src={ChatIcon} alt="" />
                        {this.props.socketThread != undefined &&
                        localStorage.getItem("current_thread") !=
                          this.props.socketThread ? (
                          <span className={"active-msg"}></span>
                        ) : (
                          ""
                        )}
                      </Link>
                    ))}

                  {/* no*tifications */}
                  {this.state.defaultLandingPage &&
                  this.state.defaultLandingPage[0] &&
                  this.state.defaultLandingPage[0].defaultLandingPage &&
                  this.state.defaultLandingPage[0].defaultLandingPage !== 3 ? (
                    <Button
                      onClick={this.setOpenWithHeader}
                      className="new dropdown-toggle"
                    >
                      <img src={BellIcon} alt="" />
                      {/* {localStorage.getItem("notifyCount") > 0 ? (
                      <>
                        <span className="active-notification">
                          {localStorage.getItem("notifyCount")}
                        </span>
                      </>
                    ) : (
                      ""
                    )} */}
                    </Button>
                  ) : (
                    ""
                  )}

                  {this.state.notificationList ? (
                    <>
                      <Drawer
                        open={this.state.openWithHeader}
                        onClose={this.setCloseWithHeader}
                      >
                        <Drawer.Body>
                          <h6>Notifications</h6>
                          <div className="notification-header">
                            <ul>
                              <li onClick={() => this.notificationReadAll()}>
                                <a href="#">Read All</a>
                              </li>
                              <li onClick={() => this.notificationClearAll()}>
                                <a href="#">Clear All</a>
                              </li>
                            </ul>
                          </div>
                          <div className="notificationlisting">
                            <ul>
                              {this.state.notificationList.map((rec, i) => {
                                return (
                                  <li
                                    key={i}
                                    className={rec.is_read == 1 ? "" : "unread"}
                                  >
                                    <a href="#">
                                      <figure>
                                        <img
                                          src={
                                            rec.sender
                                              ? rec.sender.profile_thumb_url
                                              : guestimg
                                          }
                                          alt=""
                                        />
                                      </figure>
                                      <div className="content">
                                        <div className="d-flex flex-column">
                                          <b>{rec.title}</b>
                                          <p>{rec.message}</p>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                          <i>
                                            {Moment(rec.created_at).format(
                                              global.dateTimeFormat
                                            )}
                                          </i>
                                        </div>
                                      </div>
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </Drawer.Body>
                      </Drawer>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <Dropdown className="d-flex justify-content-end">
                  <Dropdown.Toggle variant="secondary-" className="pd-0">
                    <div className="navMob d-flex align-items-center">
                      <figure>
                        {/* <img src={this.state.profileImage} alt="img"/> */}
                        <img
                          src={
                            this.props.image
                              ? this.props.image
                              : this.state.profileImage
                          }
                          alt="img"
                        />
                      </figure>
                      {/* <span className='profileContent d-flex flex-column text-left mg-l-5'>
                                                  <b>{this.state.first_name}</b>
                                                  <i className='tx-12'>Admin</i>
                                            </span>
                                            <span className='ml-3'>
                                                <i className='fas fa-chevron-down'></i>
                                            </span>    */}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="tx-13">
                    <Dropdown.Item
                      href="#/"
                      className="navMob d-flex align-items-center flex-row"
                    >
                      <figure>
                        {/* <img src={this.state.profileImage} alt="img"/>  */}
                        <img
                          src={
                            this.props.image
                              ? this.props.image
                              : this.state.profileImage
                          }
                          alt="img"
                        />
                      </figure>
                      {/* <span className='profileContent d-flex flex-column text-left mg-l-5'>
                                                        <b>{this.state.first_name}</b>
                                                        <i className='tx-12'>Admin</i>
                                                  </span> */}
                    </Dropdown.Item>
                    {/* {console.log("propertySlug", this.props.match.params.propertySlug, this.state.property_slug, this.props.property_slug)} */}
                    <Dropdown.Item
                      onClick={this.changePassword}
                      // href={
                      //   this.props.property_slug !== undefined
                      //     ? "/properties/view/" +
                      //       (this.props.property_slug
                      //         ? this.props.property_slug
                      //         : sessionStorage.getItem("property_slug")) +
                      //       "/update-profile"
                      //     : "/update-profile"
                      // }
                    >
                      Update Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={this.updatePassword}
                      // href={
                      //   this.props.property_slug !== undefined
                      //     ? "/properties/view/" +
                      //       (this.props.property_slug
                      //         ? this.props.property_slug
                      //         : sessionStorage.getItem("property_slug")) +
                      //       "/change-password"
                      //     : "/change-password"
                      // }
                    >
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item href="/logout">Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default HeaderTop;
