//     							                          //
//  Program: Websites.jsx                            //
//  Application: Property                                   //
//  Option: Home Sharing Listing                               //
//  Developer: Somin Dadhaniya  						              //
//  Date: 2023-09-08                                               //
//                                                             //
/////////////////////////////////////////////////////////     ///

import React, { Component } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import ClubSidebar from "../common/ClubSidebar";
import ThemeServices from "../../services/ThemeServices";
import CommonThemeSetting from "../theme/CommonThemeSetting";
import ThemePagesSectionSetup from "../theme/ThemePageSectionSetup";
import ThemeGeneralSectionSetup from "../theme/ThemeGeneralSectionSetup";
class ClubCms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleSwitchDark2: true,
      addModal: false,
      showSpinner: false,
      loading: false,
      editModal: false,
      deleteModal: false,
      status_id: 1,
      open: true,
      eventKey: "3",
      confirm_back: 0,
      club_slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      imageUrl: "",
      activeClass: "Socials",
      isRefresh: 0,
      pageList: [],
      pageListTotal: 0,
    };
    this.getActiveClass = this.getActiveClass.bind(this);
    this.getConfirmStatus = this.getConfirmStatus.bind(this);
  }

  getConfirmStatus(page_slug, page_name, section_slug, section_name) {
    this.setState({ page_slug: page_slug });
    this.setState({ page_name: page_name });
    this.setState({ section_slug: section_slug });
    this.setState({ section_name: section_name });
  }

  async componentDidMount() {
    this.setState({ isRefresh: 2 });
    this.getcmsPageDropdown();
  }

  getTitle() {
    return "Club CMS";
  }


  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "Theme") {
      this.setState({ isRefresh: 1 });
    } else if (e === "Socials") {
      this.setState({ isRefresh: 2 });
    } else {
      this.setState({ isRefresh: 3 });
    }
   
  }

  async getcmsPageDropdown() {
    this.setState({ showSpinner: true, loading: true });
    var pageList = [];
    var pageListTotal = 0;

    let res = await ThemeServices.getcmsPageDropdown();
    if (global.successStatus.includes(res.status)) {
      pageList = res.data ? res.data : [];
      pageListTotal = pageList ? pageList.length : 0;
    }
    this.setState({
      pageList: pageList,
      pageListTotal: pageListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  render() {
    return (
      <main>
        <Container className="innter-container ">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <ClubSidebar club_slug={this.state.club_slug} />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns info_box_model">
                    <h3>{this.getTitle()}</h3>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="Socials"
                    onSelect={(e) => this.getActiveClass(e)}
                    eventKey={this.state.activeClass}
                  >
                    <ScrollingCarousel
                      rightArrow={true}
                      leftArrow={true}
                      className="swipesection scroll_nav_mob"
                    >
                      {/* <Nav  > */}
                      <Nav.Item>
                        <Nav.Link
                          eventKey="Theme"
                          id="Theme"
                          className={
                            this.state.activeClass === "Theme" ? "active" : ""
                          }
                        >
                          Style
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="Socials"
                          id="Socials"
                          className={
                            this.state.activeClass === "Socials" ? "active" : ""
                          }
                        >
                          Socials
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                     
                      </Nav.Item>
                      {this.state.pageList &&
                        this.state.pageList.map((item, index) => {
                          return (
                            <Nav.Item key={index}>
                              <Nav.Link
                                eventKey={item.value}
                                id={item.value}
                                className={
                                  this.state.activeClass === item.value
                                    ? "active"
                                    : ""
                                }
                              >
                                {item.label}
                              </Nav.Link>
                            </Nav.Item>
                          );
                        })}
                      {/* </Nav> */}
                    </ScrollingCarousel>
                    <Tab.Content>
                      <Tab.Pane eventKey="Theme">
                        {this.state.isRefresh === 1 && (
                          <CommonThemeSetting
                            section="theme"
                            clubSlug={this.state.club_slug}
                            level={4}
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="Socials">
                        {this.state.isRefresh === 2 && (
                          <ThemeGeneralSectionSetup
                           clubSlug={this.state.club_slug}
                            section="pagesectiondata"
                            page_slug={this.state.page_slug}
                            page_name={this.state.page_name}
                            section_slug={this.state.section_slug}
                            section_name={this.state.section_name}
                            level={4}
                          />
                        )}
                      </Tab.Pane>
               
                      {this.state.pageList.length > 0 &&
                        this.state.pageList.map((item, index) => {
                          return (
                            <Tab.Pane key={index} eventKey={item.value}>
                              {this.state.isRefresh === 3 &&
                              this.state.activeClass === item.value ? (
                                <ThemePagesSectionSetup
                                  clubSlug={this.state.club_slug}
                                  section={item.value}
                                  page_slug={item.value}
                                  page_name={item.label}
                                  section_slug={this.state.section_slug}
                                  section_name={this.state.section_name}
                                  level={4}
                                />
                              ) : (
                                ""
                              )}
                            </Tab.Pane>
                          );
                        })}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default ClubCms;
