///////////////////////////////////////////////////////////
//  Program: lista.jsx                                  //
//  Application: lista                                  //
//  Option: add all lista                               //
//  Developer:  Raj KUmar                                       //
//  Date: 2022-12-05                                     //
///////////////////////////////////////////////////////////

import CrmSettingSidebar from "../common/CrmSettingSidebar";
import ContactSidebar from "../common/ContactSidebar";
import Alerts from "../common/Alerts";
import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import CrmListService from "../../services/CrmListService";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import pluswIcon from "./../../assets/images/plus.svg";
import deleteicon from "../../assets/images/delete.svg";
import BrandSidebar from "../common/BrandSidebar";
import ClientSidebar from "../common/ClientSidebar";
import DatePicker from "react-datepicker";
import Moment from "moment";

class CrmListForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    const pathStr = props.match.path;
    const pathArray = pathStr.split(":");
    this.state = {
      //tags: [],
      slug: props.match.params.crmSlug,
      confirm_back: 0,
      input: {},
      errors: {},
      usertotype: "",
      listNameError: null,
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      newPaiUnpaidList: global.crmPaiUnpaid,
      newactionAttributeList: global.actionAttribute,
      newactionAttributeConditionList: global.actionAttributeCondition,
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : "",
      brand_slug:
        pathArray[0] === "/brand/view/" ? this.props.match.params.slug : "",
      client_slug:
        pathArray[0] === "/client/view/" ? this.props.match.params.slug : "",
      list_name: "",
      noOfDays: "",
      from_date: "",
      to_date: "",
      from_amount: "",
      to_amount: "",
      selectedPropertiesList: [],
      selectedPropertySegmentList: [],
      selectedPropertySegmentLists: [],
      newPropertyList: [],
      selectedEntitiesList: [],
      entityList: [],
      newEntityList: [],
      selectedHistoricalCampaignList: [],
      newHistoricalCampaignList: [],
      historicalCampaignListTotal: 0,
      selectedFiltersList: [],
      filtersListTotal: 0,
      filtersList: [],
      selectedReservationAttrList: [],
      reservationAttrTotal: 0,
      reservationAttrList: [],
      selectedPayoutList: [],
      payoutAttrTotalList: 0,
      newPayoutAttrList: [],
      selectedAttributeList: [],
      attributeTotalList: 0,
      newAttributeList: [],
      selectedLeaseAttrList: [],
      leaseAttrListTotal: 0,
      newLeaseAttrList: [],
      selectedCampaignAttrList: [],
      campaignAttrListTotal: 0,
      newCampaignAttrList: [],
      selectedCalendarAttrList: [],
      calendarAttrListTotal: 0,
      newCalendarAttrList: [],
      deleteTowerModal: false,
      status_id: true,
      CrmListFormDataGroup: [
        {
          CrmListFormDataTotal: 1,
          CrmListFormData: [
            {
              crm_entity_slug: "",
              crm_attribute_slug: "",
              crm_campaigns_slug: [],
              reviews: [],
              leaseStatus: [],
              floorPlans: [],
              crm_filter_slug: "",
              condition: 0,
              noOfDays: "",
              from_date: "",
              to_date: "",
              from_amount: "",
              to_amount: "",
            },
          ],
        },
      ],

      CrmListFormDataGroup1: [
        {
          CrmListFormData: [
            {
              crm_entity_slug: "",
              crm_attribute_slug: "",
              crm_campaigns_slug: [],
              reviews: [],
              leaseStatus: [],
              floorPlans: [],
              crm_filter_slug: "",
              condition: 0,
              noOfDays: "",
              from_date: "",
              to_date: "",
              from_amount: "",
              to_amount: "",
            },
          ],
        },
      ],

      moduleTypeList: global.moduleReferlist.filter((item) => {
        return item.slug;
      }),
    };
    this.saveOrUpdateList = this.saveOrUpdateList.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerRow = this.changeHandlerRow.bind(this);
    this.handleChangeEntitySegmentList =
      this.handleChangeEntitySegmentList.bind(this);
  }

  componentDidMount() {
    this.getSegmentEntities();
    this.getHistoricalCampaigns();
    if (this.state.brand_slug || this.state.client_slug) {
      this.getbrandClientPropertiesFromSlug();
    } else {
      this.getPropertyData();
    }
    this.getDataFromSlug();
  }

  saveOrUpdateList = async (e) => {
    e.preventDefault();
    var properties = [];
    this.setState({
      alertModalMessage: "",
    });
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ loading: true, isSubmit: true });
      if (this.state.selectedPropertySegmentList !== null) {
        this.state.selectedPropertySegmentList.forEach((item) => {
          properties = [...properties, item.value];
        });
      }
      if (this.state.selectedPropertySegmentLists != null) {
        this.state.selectedPropertySegmentLists.forEach((item) => {
          properties = [...properties, item.value];
        });
      }

      let properties_arr = [...new Set(properties)];
      let inputData = {
        list_name: this.state.list_name ? this.state.list_name : "",
        description: this.state.description ? this.state.description : "",
        propertySlug: this.state.property_slug ? this.state.property_slug : "",
        brandSlug: this.state.brand_slug ? this.state.brand_slug : "",
        clientSlug: this.state.client_slug ? this.state.client_slug : "",
        crmType: this.state.property_slug
          ? 2
          : this.state.brand_slug
          ? 3
          : this.state.client_slug
          ? 4
          : 1,
        properties: properties_arr.length > 0 ? properties_arr : [],
        crmListFormDataGroup: this.state.CrmListFormDataGroup
          ? this.state.CrmListFormDataGroup
          : "",
        crmListFormDataGroup1: this.state.CrmListFormDataGroup1
          ? this.state.CrmListFormDataGroup1
          : "",
        status_id: this.state.status_id ? this.state.status_id : 2,
        list_type: 1,
      };
      if (this.state.slug !== undefined) {
        this.setState({ loading: true, isSubmit: true });
        let res = await CrmListService.updateCrmList(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.state.property_slug
                  ? "/properties/view/" + this.state.property_slug + "/crm-list"
                  : this.state.brand_slug
                  ? "/brand/view/" + this.state.brand_slug + "/crm-list"
                  : this.state.client_slug
                  ? "/client/view/" + this.state.client_slug + "/crm-list"
                  : "/crm-list"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        this.setState({ loading: true, isSubmit: true });
        let res = await CrmListService.createCrmlist(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.state.property_slug
                  ? "/properties/view/" + this.state.property_slug + "/crm-list"
                  : this.state.brand_slug
                  ? "/brand/view/" + this.state.brand_slug + "/crm-list"
                  : this.state.client_slug
                  ? "/client/view/" + this.state.client_slug + "/crm-list"
                  : "/crm-list"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ loading: false, isSubmit: false });
      this.validator.showMessages();
    }
  };

  async getDataFromSlug() {
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await CrmListService.getCrmList(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        var list_name = resData.list_name ? resData.list_name : "";
        var description = resData.description ? resData.description : "";
        var propertiesdata = resData.properties ? resData.properties : [];
        var manualLists = resData.manualLists ? resData.manualLists : [];
        var crmType = resData.crm_type ? resData.crm_type : resData.crm_type;
        var propertySlugs = resData.property_slug ? resData.property_slug : "";
        var CrmListFormDataGroup1 = resData.groupData ? resData.groupData : "";
        var CrmListFormDataGroupList = resData.groupData1
          ? resData.groupData1
          : "";
        var selectedPropertySegmentList = [];

        if (CrmListFormDataGroup1 !== "") {
          var CrmListFormDataGroup = [];
          CrmListFormDataGroup1.forEach((item, i) => {
            var listData = [];
            item.CrmListFormData.forEach((element, j) => {
              let CrmListFormData = {
                crm_entity_slug: element.crm_entity_slug,
                crm_attribute_slug: element.crm_attribute_slug,
                crm_campaigns_slug: element.crm_campaigns_slug,
                reviews: element.reviews,
                leaseStatus: element.leaseStatus,
                floorPlans: element.floorPlans,
                crm_filter_slug: element.crm_filter_slug,
                condition: element.condition,
                noOfDays: element.noOfDays,
                from_date: element.from_date ? new Date(element.from_date) : "",
                to_date: element.from_date ? new Date(element.to_date) : "",
                from_amount: element.from_amount,
                to_amount: element.to_amount,
                selectedConditionList:
                  element.condition !== 0
                    ? element.selectedConditionList
                    : undefined,
                selectedEntitiesList: element.selectedEntitiesList,
                is_attribute: element.is_attribute,
                selectedAttributeList: element.selectedAttributeList,
                is_filter: element.is_filter,
                selectedFiltersList: element.selectedFiltersList,
                newAttributeList: element.newAttributeList,
                attribute_type: element.attribute_type,
                selectedCampaignsList: element.selectedCampaignsList,
                selectedReviewsList: element.selectedReviewsList,
                selectedLeaseInquiryList: element.selectedLeaseInquiryList,
                selectedFloorPlanList: element.selectedFloorPlanList,
                newFloorPlans : element.newFloorPlans,
                filtersList: element.filtersList,
              };
              listData.push(CrmListFormData);
            });
            var groupWiseListData = {
              CrmListFormDataTotal: item.CrmListFormDataTotal,
              CrmListFormData: listData,
            };
            CrmListFormDataGroup.push(groupWiseListData);
          });
        }

        if (propertiesdata !== "null") {
          propertiesdata.forEach((item, i) => {
            selectedPropertySegmentList = [
              ...selectedPropertySegmentList,
              {
                value: item.slug,
                label: item.property_name,
              },
            ];
          });
        }

        var manualListData = [];
        if (manualLists !== "null") {
          manualLists.forEach((item, i) => {
            manualListData.push(item.email);
          });
        }
      }
      this.setState({
        tags: manualListData,
        selectedPropertySegmentList: selectedPropertySegmentList,
        crmType: crmType,
        propertySlugs: propertySlugs,
        list_name: list_name,
        description: description,
        CrmListFormDataGroup: CrmListFormDataGroup,
        CrmListFormDataGroup1: CrmListFormDataGroupList,
      });
    }
  }

  async getPropertyData(queryString = "") {
    var propertyList = [];
    var propertyListTotal = 0;

    let res = await CrmListService.getProperties("is_dropdown=1");
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;
    }
    var newPropertyList =
      propertyListTotal > 0
        ? propertyList.map(({ slug, property_name }) => ({
            value: slug,
            label: property_name,
          }))
        : [];
    if (this.state.property_slug) {
      var thisPropertySlug = propertyList.filter(
        (item) => item.slug === this.state.property_slug
      );

      var selectedPropertySegmentLists = [];
      thisPropertySlug.forEach((element) => {
        var a = { value: element.slug, label: element.property_name };
        selectedPropertySegmentLists.push(a);
      });

      var thisPropertSlug0 = thisPropertySlug[0].slug;
      var thisPropertName0 = thisPropertySlug[0].property_name;
      this.setState({
        selectedPropertySegmentList: selectedPropertySegmentLists,
      });
    }

    this.setState({
      propertyListTotal: propertyListTotal,
      newPropertyList: newPropertyList,
      thisPropertySlug: this.state.property_slug ? thisPropertSlug0 : "",
      thiss_property_name: this.state.property_slug ? thisPropertName0 : "",
    });
  }

  async getbrandClientPropertiesFromSlug() {
    if (this.state.brand_slug || this.state.client_slug) {
      var resData = {};
      let brandClientSlug = this.state.brand_slug
        ? this.state.brand_slug
        : this.state.client_slug
        ? this.state.client_slug
        : "";
      let res = await CrmListService.getbrandClientProperties(brandClientSlug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        var propertiesdata = resData.properties ? resData.properties : [];
        var name = resData.brand_name
          ? resData.brand_name
          : resData.client_name
          ? resData.client_name
          : "";
        var type = resData.type ? resData.type : "";
        var propertyListTotal = propertiesdata ? propertiesdata.length : 0;

        var newPropertyList =
          propertyListTotal > 0
            ? propertiesdata.map(({ slug, property_name }) => ({
                value: slug,
                label: property_name,
              }))
            : [];
        var selectedPropertySegmentList = [];
        if (propertiesdata !== "null") {
          propertiesdata.forEach((item, i) => {
            selectedPropertySegmentList = [
              ...selectedPropertySegmentList,
              {
                value: item.slug,
                label: item.property_name,
              },
            ];
          });
        }
      }
      this.setState({
        selectedPropertySegmentLists: selectedPropertySegmentList,
        brandClientName: name,
        newPropertyList: newPropertyList,
        type: type,
      });
    }
  }

  async getSegmentEntities(queryString = "") {
    var entityLists = [];
    var entityListTotal = 0;
    let res = await CrmListService.getSegmentEntities("is_dropdown=1");
    if (global.successStatus.includes(res.status)) {
      entityLists = res.data ? res.data : [];
      entityListTotal = entityLists ? entityLists.length : 0;
    }
    var newEntityList =
      entityListTotal > 0
        ? entityLists.map(
            ({
              slug,
              entity_name,
              is_filter,
              is_attribute,
              attribute_type,
              crm_entity_id,
              is_filter_type,
            }) => ({
              value: slug,
              label: entity_name,
              is_filter: is_filter,
              is_attribute: is_attribute,
              attribute_type: attribute_type,
              entity_id: crm_entity_id,
              is_filter_type: is_filter_type,
            })
          )
        : [];
    this.setState({
      entityListTotal: entityListTotal,
      entityList: entityLists,
      newEntityList: newEntityList,
    });
  }

  async getAttributeLists(index, indexes, queryString = "") {
    var attributes = [];
    var attributeTotal = 0;

    let res = await CrmListService.getAttributeLists(
      "is_attribute=" + queryString
    );
    if (global.successStatus.includes(res.status)) {
      attributes = res.data ? res.data : [];
      attributeTotal = attributes ? attributes.length : 0;
    }
    var newAttributesAttr =
      attributeTotal > 0
        ? attributes.map(
            ({
              slug,
              attribute_name,
              is_filter,
              attribute_id,
              crm_entity_slug,
              is_filter_type,
            }) => ({
              value: slug,
              label: attribute_name,
              is_filter: is_filter,
              attribute_id: attribute_id,
              crm_entity_slug: crm_entity_slug,
              is_filter_type: is_filter_type,
            })
          )
        : [];
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    CrmListFormDataGroup[index].CrmListFormData[indexes].newAttributeList =
      newAttributesAttr;
    this.setState({ CrmListFormDataGroup: CrmListFormDataGroup });
  }

  async getFilterAttributes(index, indexes, queryString = "") {
    var filterLists = [];
    var filtersListTotal = 0;

    let res = await CrmListService.getFilterAttributes(
      "is_filter_type=" + queryString
    );
    if (global.successStatus.includes(res.status)) {
      filterLists = res.data ? res.data : [];
      filtersListTotal = filterLists ? filterLists.length : 0;
    }
    var newFiltersList =
      filtersListTotal > 0
        ? filterLists.map(
            ({
              slug,
              filter_name,
              attribute_type,
              crm_filter_id,
              is_filter_type,
              is_filter_date_day,
            }) => ({
              value: slug,
              label: filter_name,
              attribute_type: attribute_type,
              crm_filter_id: crm_filter_id,
              is_filter_type: is_filter_type,
              is_filter_date_day: is_filter_date_day,
            })
          )
        : [];
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    const CrmListFormDataGroup1 = [...this.state.CrmListFormDataGroup1];
    CrmListFormDataGroup[index].CrmListFormData[indexes].filtersList =
      newFiltersList;
    if (filtersListTotal === 1) {
      let selectedFiltersList =
        filtersListTotal === 1
          ? filterLists.map(({ slug, filter_name, attribute_type }) => ({
              value: slug,
              label: filter_name,
              attribute_type: attribute_type,
            }))
          : [];
      let AttributeTypeFilter = filterLists[0].attribute_type;
      let filterSlug = filterLists[0].slug;

      CrmListFormDataGroup[index].CrmListFormData[indexes].selectedFiltersList =
        selectedFiltersList;
      CrmListFormDataGroup[index].CrmListFormData[indexes].attribute_type =
        AttributeTypeFilter;
      CrmListFormDataGroup1[index].CrmListFormData[indexes].attribute_type =
        AttributeTypeFilter;
      CrmListFormDataGroup[index].CrmListFormData[indexes].crm_filter_slug =
        filterSlug;
      CrmListFormDataGroup1[index].CrmListFormData[indexes].crm_filter_slug =
        filterSlug;
    }
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
  }

  async getHistoricalCampaigns(queryString = "") {
    var historicalCampaigns = [];
    var historicalCampaignsTotal = 0;

    let res = await CrmListService.getHistoricalCampaigns(
      "query=" + queryString
    );
    if (global.successStatus.includes(res.status)) {
      historicalCampaigns = res.data ? res.data : [];
      historicalCampaignsTotal = historicalCampaigns
        ? historicalCampaigns.length
        : 0;
    }
    var newHistoricalCampaigns =
      historicalCampaignsTotal > 0
        ? historicalCampaigns.map(({ slug, campaign_name, recipients }) => ({
            value: slug,
            label: campaign_name + ` (${recipients})`,
            recipients: recipients,
          }))
        : [];
    this.setState({
      historicalCampaignsTotal: historicalCampaignsTotal,
      newHistoricalCampaigns: newHistoricalCampaigns,
    });
  }

  async getPropertiesFloorPlans(index, indexes, queryString = "") {
    var floorPlans = [];
    var floorPlansTotal = 0;
    let res = await CrmListService.getPropertiesFloorplans(
      "properties=" + queryString
    );
    if (global.successStatus.includes(res.status)) {
      floorPlans = res.data ? res.data : [];
      floorPlansTotal = floorPlans ? floorPlans.length : 0;
    }
    var newPropertyFloorplans =
      floorPlansTotal > 0
        ? floorPlans.map(({ property_floor_plan_id, floor_Plan_name }) => ({
            value: property_floor_plan_id,
            label: floor_Plan_name,
          }))
        : [];
    var propertyFloorPlans = [];
    if (floorPlansTotal > 0) {
      floorPlans.forEach((item, i) => {
        propertyFloorPlans.push(item.property_floor_plan_id);
      });
    }
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    const CrmListFormDataGroup1 = [...this.state.CrmListFormDataGroup];
    CrmListFormDataGroup1[index].CrmListFormData[indexes].floorPlans =
      propertyFloorPlans;
    CrmListFormDataGroup[index].CrmListFormData[indexes].floorPlans =
      propertyFloorPlans;
    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedFloorPlanList =
      newPropertyFloorplans;
    CrmListFormDataGroup[index].CrmListFormData[indexes].newFloorPlans =
      newPropertyFloorplans;

    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
      floorPlansTotal: floorPlansTotal,
      newFloorPlans: newPropertyFloorplans,
    });
  }

  handleChangeFloorPlan(index, indexes, value) {
    var floorPlans = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        floorPlans.push(item.value);
      });
    }
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    const CrmListFormDataGroup1 = [...this.state.CrmListFormDataGroup1];
    CrmListFormDataGroup1[index].CrmListFormData[indexes].floorPlans =
      floorPlans;
    CrmListFormDataGroup[index].CrmListFormData[indexes].floorPlans =
      floorPlans;

    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedFloorPlanList =
      value;
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
  }

  handleChangePropertySegmentList(value) {
    let is_property = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        is_property.push(item.value);
      });
    }
    this.setState({
      selectedPropertySegmentList: value,
      selectedPropertySegmentLists: value,
      is_property: is_property,
    });
  }

  handleChangeEntitySegmentList(index, indexes, value) {
    // CrmListFormDataGroup1 used for backend
    // CrmListFormDataGroup used for frontend with large amount of data
    const entitySlugValue = value !== null ? value.value : "";
    const entityLabel = value !== null ? value.label : "";
    const IsAttribute = value !== null ? value.is_attribute : "";
    const AttributeType = value !== null ? value.attribute_type : "";
    const IsFilter = value !== null ? value.is_filter : "";
    const IsFilterType = value !== null ? value.is_filter_type : "";
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    const CrmListFormDataGroup1 = [...this.state.CrmListFormDataGroup1];
    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedAttributeList =
      [];
    CrmListFormDataGroup[index].CrmListFormData[indexes].crm_attribute_slug =
      "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].crm_attribute_slug =
      "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].crm_filter_slug = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].crm_filter_slug = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].attribute_type = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].attribute_type = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].noOfDays = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].noOfDays = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].from_date = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].from_date = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].to_date = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].to_date = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].from_amount = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].from_amount = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].to_amount = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].to_amount = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedFiltersList =
      [];
    CrmListFormDataGroup[index].CrmListFormData[indexes].crm_campaigns_slug =
      [];
    CrmListFormDataGroup1[index].CrmListFormData[indexes].crm_campaigns_slug =
      [];
    CrmListFormDataGroup[index].CrmListFormData[indexes].reviews = [];
    CrmListFormDataGroup1[index].CrmListFormData[indexes].reviews = [];
    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedReviewsList =
      [];
    CrmListFormDataGroup[index].CrmListFormData[indexes].leaseStatus = [];
    CrmListFormDataGroup1[index].CrmListFormData[indexes].leaseStatus = [];
    CrmListFormDataGroup[index].CrmListFormData[
      indexes
    ].selectedLeaseInquiryList = [];
    CrmListFormDataGroup1[index].CrmListFormData[indexes].floorPlans = [];
    CrmListFormDataGroup[index].CrmListFormData[indexes].floorPlans = [];
    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedFloorPlanList =
      [];

    if (IsAttribute === 2 || IsAttribute === 3 || IsAttribute === 10) {
      this.getFilterAttributes(index, indexes, IsFilterType);
    }
    if (IsAttribute !== 2 || IsAttribute !== 3 || IsAttribute !== 10) {
      CrmListFormDataGroup1[index].CrmListFormData[indexes].is_filter = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].is_filter = "";
      this.getAttributeLists(index, indexes, entitySlugValue);
    }
    CrmListFormDataGroup[index].CrmListFormData[indexes].crm_entity_slug =
      entitySlugValue;
    CrmListFormDataGroup1[index].CrmListFormData[indexes].crm_entity_slug =
      entitySlugValue;
    CrmListFormDataGroup[index].CrmListFormData[indexes].is_filter = IsFilter;
    CrmListFormDataGroup1[index].CrmListFormData[indexes].is_filter = IsFilter;
    let selectedEntitiesList = {
      label: entityLabel,
      value: entitySlugValue,
      is_attribute: IsAttribute,
      attribute_type: AttributeType,
      is_filter: IsFilter,
    };
    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedEntitiesList =
      selectedEntitiesList;
    CrmListFormDataGroup[index].CrmListFormData[indexes].is_attribute =
      IsAttribute;
    CrmListFormDataGroup1[index].CrmListFormData[indexes].is_attribute =
      IsAttribute;
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeAttribute(index, indexes, value) {
    const attributeSlug = value !== null ? value.value : "";
    const attributeLabel = value !== null ? value.label : "";
    const IsFilter = value !== null ? value.is_filter : "";
    const AttributeId = value !== null ? value.attribute_id : "";
    const IsFilterType = value !== null ? value.is_filter_type : "";
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    const CrmListFormDataGroup1 = [...this.state.CrmListFormDataGroup1];
    /* Refresh Filter data start */
    CrmListFormDataGroup[index].CrmListFormData[indexes].crm_filter_slug = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].crm_filter_slug = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].attribute_type = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].attribute_type = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].noOfDays = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].noOfDays = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].from_date = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].from_date = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].to_date = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].to_date = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].from_amount = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].from_amount = "";
    CrmListFormDataGroup1[index].CrmListFormData[indexes].to_amount = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].to_amount = "";
    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedFiltersList =
      [];
    if (
      attributeSlug === "STATUSCHANGED" ||
      attributeSlug === "FLOORPLAN" ||
      attributeSlug === "INQUIRYSTATUS"
    ) {
      CrmListFormDataGroup[index].CrmListFormData[indexes].leaseStatus = [];
      CrmListFormDataGroup1[index].CrmListFormData[indexes].leaseStatus = [];
      CrmListFormDataGroup[index].CrmListFormData[
        indexes
      ].selectedLeaseInquiryList = [];

      CrmListFormDataGroup1[index].CrmListFormData[indexes].floorPlans = [];
      CrmListFormDataGroup[index].CrmListFormData[indexes].floorPlans = [];
      CrmListFormDataGroup[index].CrmListFormData[
        indexes
      ].selectedFloorPlanList = [];
    }
    if (attributeSlug === "FLOORPLAN") {
      var properties = [];
      if (this.state.selectedPropertySegmentList !== null) {
        this.state.selectedPropertySegmentList.forEach((item) => {
          properties = [...properties, item.value];
        });
      }
      if (this.state.selectedPropertySegmentLists != null) {
        this.state.selectedPropertySegmentLists.forEach((item) => {
          properties = [...properties, item.value];
        });
      }
      let properties_arr = [...new Set(properties)];
      let propertyStr =
        properties_arr.length > 0 ? properties_arr.toString() : "";
      this.getPropertiesFloorPlans(index, indexes, propertyStr);
    }
    /* Refresh Filter data end */
    this.getFilterAttributes(index, indexes, IsFilterType);
    CrmListFormDataGroup[index].CrmListFormData[indexes].crm_attribute_slug =
      attributeSlug;
    CrmListFormDataGroup1[index].CrmListFormData[indexes].crm_attribute_slug =
      attributeSlug;
    let selectedAttributeList = {
      label: attributeLabel,
      value: attributeSlug,
      is_filter: IsFilter,
      attribute_id: AttributeId,
    };
    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedAttributeList =
      selectedAttributeList;
    CrmListFormDataGroup[index].CrmListFormData[indexes].is_filter = IsFilter;
    CrmListFormDataGroup1[index].CrmListFormData[indexes].is_filter = IsFilter;
    if (IsFilter === 2) {
      CrmListFormDataGroup[index].CrmListFormData[indexes].selectedFiltersList =
        {};
      CrmListFormDataGroup[index].CrmListFormData[indexes].to_date = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].from_date = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].noOfDays = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].attribute_type = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].to_amount = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].from_amount = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].to_date = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].from_date = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].noOfDays = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].attribute_type = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].to_amount = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].from_amount = "";
    }
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeFiltersList(index, indexes, value) {
    const filterSlugValue = value !== null ? value.value : "";
    const filterLabel = value !== null ? value.label : "";
    const AttributeType = value !== null ? value.attribute_type : "";
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    const CrmListFormDataGroup1 = [...this.state.CrmListFormDataGroup1];
    CrmListFormDataGroup[index].CrmListFormData[indexes].crm_filter_slug =
      filterSlugValue;
    CrmListFormDataGroup1[index].CrmListFormData[indexes].crm_filter_slug =
      filterSlugValue;
    let selectedFiltersList = {
      label: filterLabel,
      value: filterSlugValue,
      attribute_type: AttributeType,
    };
    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedFiltersList =
      selectedFiltersList;
    CrmListFormDataGroup[index].CrmListFormData[indexes].attribute_type =
      AttributeType;
    CrmListFormDataGroup1[index].CrmListFormData[indexes].attribute_type =
      AttributeType;
    if (AttributeType === 7) {
      CrmListFormDataGroup[index].CrmListFormData[indexes].to_date = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].from_date = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].to_amount = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].from_amount = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].to_date = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].from_date = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].to_amount = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].from_amount = "";
    } else if (AttributeType === 4) {
      CrmListFormDataGroup[index].CrmListFormData[indexes].noOfDays = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].noOfDays = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].to_amount = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].from_amount = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].to_amount = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].from_amount = "";
    } else if (AttributeType === 5) {
      CrmListFormDataGroup[index].CrmListFormData[indexes].noOfDays = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].noOfDays = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].to_date = "";
      CrmListFormDataGroup[index].CrmListFormData[indexes].from_date = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].to_date = "";
      CrmListFormDataGroup1[index].CrmListFormData[indexes].from_date = "";
    }
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
  }

  handleChangeConditionList(index, indexes, value) {
    const conditionValue = value !== null ? value.value : 0;
    const conditionLabel = value !== null ? value.label : 0;
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    const CrmListFormDataGroup1 = [...this.state.CrmListFormDataGroup1];
    CrmListFormDataGroup[index].CrmListFormData[indexes].condition =
      conditionValue;
    CrmListFormDataGroup1[index].CrmListFormData[indexes].condition =
      conditionValue;
    let selectedConditionList = {
      label: conditionLabel,
      value: conditionValue,
    };
    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedConditionList =
      selectedConditionList;
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
  }

  handleCampaignInputChange = (keyword) => {
    const searchKeyword = keyword ? keyword : "";
    this.getHistoricalCampaigns(searchKeyword);
  };

  handleChangeHistoricalCampaign(index, indexes, value) {
    let historicalCampaignSlug = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        historicalCampaignSlug.push(item.value);
      });
    }
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    const CrmListFormDataGroup1 = [...this.state.CrmListFormDataGroup1];
    CrmListFormDataGroup[index].CrmListFormData[indexes].crm_campaigns_slug =
      historicalCampaignSlug;
    CrmListFormDataGroup1[index].CrmListFormData[indexes].crm_campaigns_slug =
      historicalCampaignSlug;

    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedCampaignsList =
      value;
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
  }

  handleChangeReviews(index, indexes, value) {
    let reviewsData = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        reviewsData.push(item.value);
      });
    }
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    const CrmListFormDataGroup1 = [...this.state.CrmListFormDataGroup1];
    CrmListFormDataGroup[index].CrmListFormData[indexes].reviews = reviewsData;
    CrmListFormDataGroup1[index].CrmListFormData[indexes].reviews = reviewsData;
    CrmListFormDataGroup[index].CrmListFormData[indexes].selectedReviewsList =
      value;
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
  }

  handleChangeLeaseInquiries(index, indexes, value) {
    let leaseInquiriesData = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        leaseInquiriesData.push(item.value);
      });
    }
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    const CrmListFormDataGroup1 = [...this.state.CrmListFormDataGroup1];
    CrmListFormDataGroup[index].CrmListFormData[indexes].leaseStatus =
      leaseInquiriesData;
    CrmListFormDataGroup1[index].CrmListFormData[indexes].leaseStatus =
      leaseInquiriesData;
    CrmListFormDataGroup[index].CrmListFormData[
      indexes
    ].selectedLeaseInquiryList = value;
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
    });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  changeHandlerRow = (index, indexes, event) => {
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    const CrmListFormDataGroup1 = [...this.state.CrmListFormDataGroup1];
    if (event.target.name === "noOfDays") {
      CrmListFormDataGroup[index].CrmListFormData[indexes].noOfDays = parseInt(
        event.target.value
      );
      CrmListFormDataGroup1[index].CrmListFormData[indexes].noOfDays = parseInt(
        event.target.value
      );
    }
    if (event.target.name === "from_amount") {
      CrmListFormDataGroup[index].CrmListFormData[indexes].from_amount =
        parseInt(event.target.value);
      CrmListFormDataGroup1[index].CrmListFormData[indexes].from_amount =
        parseInt(event.target.value);
    }
    if (event.target.name === "to_amount") {
      CrmListFormDataGroup[index].CrmListFormData[indexes].to_amount = parseInt(
        event.target.value
      );
      CrmListFormDataGroup1[index].CrmListFormData[indexes].to_amount =
        parseInt(event.target.value);
    }
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
  };

  changeHandlerDateRow = (event) => {
    const CrmListFormDataGroup = [...this.state.CrmListFormDataGroup];
    const CrmListFormDataGroup1 = [...this.state.CrmListFormDataGroup1];
    if (event.target.name === "from_date") {
      CrmListFormDataGroup[event.target.indexI].CrmListFormData[
        event.target.indexJ
      ].from_date = event.target.value ? event.target.value : "";
      CrmListFormDataGroup1[event.target.indexI].CrmListFormData[
        event.target.indexJ
      ].from_date = event.target.value ? event.target.value : "";
    }
    if (event.target.name === "to_date") {
      CrmListFormDataGroup[event.target.indexI].CrmListFormData[
        event.target.indexJ
      ].to_date = event.target.value ? event.target.value : "";
      CrmListFormDataGroup1[event.target.indexI].CrmListFormData[
        event.target.indexJ
      ].to_date = event.target.value ? event.target.value : "";
    }
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
  };

  handleAddFieldsRow = (index, indexes) => {
    let CrmListFormDataGroup = this.state.CrmListFormDataGroup;
    let CrmListFormDataGroup1 = this.state.CrmListFormDataGroup1;
    CrmListFormDataGroup[index].CrmListFormData.push({
      crm_entity_slug: "",
      crm_attribute_slug: "",
      crm_campaigns_slug: [],
      reviews: [],
      leaseStatus: [],
      floorPlans: [],
      crm_filter_slug: "",
      condition: 1,
      noOfDays: "",
      from_date: "",
      to_date: "",
      from_amount: "",
      to_amount: "",
      selectedConditionList: { label: "AND", value: 1 },
    });
    CrmListFormDataGroup1[index].CrmListFormData.push({
      crm_entity_slug: "",
      crm_attribute_slug: "",
      crm_campaigns_slug: [],
      reviews: [],
      leaseStatus: [],
      floorPlans: [],
      crm_filter_slug: "",
      condition: 1,
      noOfDays: "",
      from_date: "",
      to_date: "",
      from_amount: "",
      to_amount: "",
      selectedConditionList: { label: "AND", value: 1 },
    });
    CrmListFormDataGroup[index].CrmListFormDataTotal =
      CrmListFormDataGroup[index].CrmListFormData.length;
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
  };

  handleRemoveFieldsRow = (index, indexes) => {
    let CrmListFormDataGroup = this.state.CrmListFormDataGroup;
    let CrmListFormDataGroup1 = this.state.CrmListFormDataGroup1;
    if (CrmListFormDataGroup[index].CrmListFormData.length > 1) {
      CrmListFormDataGroup[index].CrmListFormData.splice(indexes, 1);
      CrmListFormDataGroup1[index].CrmListFormData.splice(indexes, 1);
      CrmListFormDataGroup[index].CrmListFormDataTotal =
        CrmListFormDataGroup[index].CrmListFormData.length;
    }
    this.setState({
      CrmListFormDataGroup: CrmListFormDataGroup,
      CrmListFormDataGroup1: CrmListFormDataGroup1,
    });
  };

  addFormFieldsGroup() {
    this.setState({
      CrmListFormDataGroup: [
        ...this.state.CrmListFormDataGroup,
        {
          CrmListFormDataTotal: 1,
          CrmListFormData: [
            {
              crm_entity_slug: "",
              crm_attribute_slug: "",
              crm_campaigns_slug: [],
              reviews: [],
              leaseStatus: [],
              floorPlans: [],
              crm_filter_slug: "",
              condition: 1,
              noOfDays: "",
              from_date: "",
              to_date: "",
              from_amount: "",
              to_amount: "",
            },
          ],
        },
      ],
      CrmListFormDataGroup1: [
        ...this.state.CrmListFormDataGroup1,
        {
          CrmListFormData: [
            {
              crm_entity_slug: "",
              crm_attribute_slug: "",
              crm_campaigns_slug: [],
              reviews: [],
              leaseStatus: [],
              floorPlans: [],
              crm_filter_slug: "",
              condition: 1,
              noOfDays: "",
              from_date: "",
              to_date: "",
              from_amount: "",
              to_amount: "",
            },
          ],
        },
      ],
    });
  }

  removeFormFieldsGroup(index) {
    let CrmListFormDataGroup = this.state.CrmListFormDataGroup;
    let CrmListFormDataGroup1 = this.state.CrmListFormDataGroup1;
    CrmListFormDataGroup.splice(index, 1);
    CrmListFormDataGroup1.splice(index, 1);
    var CrmListGroupDataTotal = 0;
    this.setState({
      CrmListFormDataGroup,
      CrmListFormDataGroup1,
      CrmListGroupDataTotal: CrmListGroupDataTotal,
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    {this.state.property_slug ? (
                      <ContactSidebar
                        property_slug={this.state.property_slug}
                      />
                    ) : this.state.brand_slug ? (
                      <BrandSidebar brand_slug={this.state.brand_slug} />
                    ) : this.state.client_slug ? (
                      <ClientSidebar clientSlug={this.state.client_slug} />
                    ) : (
                      <CrmSettingSidebar />
                    )}
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel drop_user">
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Lists</h3>
                          <div className="d-flex justify-content-end">
                            <Link
                              to={
                                this.state.property_slug
                                  ? "/properties/view/" +
                                    this.state.property_slug +
                                    "/crm-list"
                                  : this.state.brand_slug
                                  ? "/brand/view/" +
                                    this.state.brand_slug +
                                    "/crm-list"
                                  : this.state.client_slug
                                  ? "/client/view/" +
                                    this.state.client_slug +
                                    "/crm-list"
                                  : "/crm-list"
                              }
                            >
                              <button className="btn-success-outline mg-r-20">
                                Cancel
                              </button>
                            </Link>
                            <button
                              type="button"
                              className="btn-success-outline-small ml-3 button-width"
                              onClick={this.saveOrUpdateList}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : this.state.slug
                                ? "Save"
                                : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pd-lg-l-0 pd-lg-t-0 ">
                    <div className="table-responsive roles   ">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0 mt-3">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      <div className="mg-t-18 brandList table_issue_roles">
                        <div className=" ">
                          <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-15">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                              <div className="mg-b-15">
                                <label className="form-label text-left">
                                  List{" "}
                                </label>
                                <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="list_name"
                                  value={this.state.list_name}
                                  maxLength="50"
                                  autoComplete="off"
                                />
                                {this.state.errors.list_name ? (
                                  <div className="text-danger">
                                    {this.state.errors.list_name}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "list",
                                    this.state.list_name,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}
                              </div>

                              <div className="mg-t-15">
                                {this.state.property_slug ? (
                                  <div className="col-12 p-0">
                                    <label className="form-label text-left">
                                      Property{" "}
                                    </label>
                                    <Form.Control
                                      type="text"
                                      name="propertyName"
                                      value={this.state.thiss_property_name}
                                      maxLength="50"
                                      autoComplete="off"
                                      className="mg-b-15"
                                      readOnly
                                    />
                                  </div>
                                ) : this.state.brand_slug ||
                                  this.state.client_slug ? (
                                  <div className="col-12 p-0">
                                    <label className="form-label text-left">
                                      {this.state.type === "brand"
                                        ? "Brand"
                                        : this.state.type === "client"
                                        ? "Client"
                                        : ""}{" "}
                                      ({this.state.brandClientName}) Properties{" "}
                                      <span className="optional"></span>
                                    </label>
                                    <Select
                                      isMulti
                                      styles={customStyles}
                                      isClearable={true}
                                      menuPlacement="auto"
                                      className="multiselect"
                                      value={
                                        this.state.slug
                                          ? this.state
                                              .selectedPropertySegmentList
                                          : this.state
                                              .selectedPropertySegmentLists
                                      }
                                      options={this.state.newPropertyList}
                                      getOptionValue={(option) =>
                                        `${option.label}`
                                      }
                                      onChange={(value) =>
                                        this.handleChangePropertySegmentList(
                                          value
                                        )
                                      }
                                      defaultValue={
                                        this.state.slug
                                          ? this.state
                                              .selectedPropertySegmentList
                                          : this.state
                                              .selectedPropertySegmentLists
                                      }
                                      selected
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />
                                  </div>
                                ) : (
                                  <div className="col-12 p-0">
                                    <label className="form-label text-left">
                                      Properties{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>
                                    <Select
                                      isMulti
                                      styles={customStyles}
                                      isClearable={true}
                                      menuPlacement="auto"
                                      className="multiselect"
                                      value={
                                        this.state.selectedPropertySegmentList
                                      }
                                      options={this.state.newPropertyList}
                                      getOptionValue={(option) =>
                                        `${option.label}`
                                      }
                                      onChange={(value) =>
                                        this.handleChangePropertySegmentList(
                                          value
                                        )
                                      }
                                      defaultValue={
                                        this.state.selectedPropertySegmentList
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-12 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                              <label className="form-label text-left ">
                                List Description{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                name="description"
                                value={this.state.description}
                                className="form-control max_width_100"
                                id="description"
                                as="textarea"
                                rows="5"
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 pd-xs-l-15 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                            {this.state.CrmListFormDataGroup.map(
                              (element, i) => (
                                <>
                                  <div className="CRMDynamicListGroup" key={i}>
                                    {element.CrmListFormData !== undefined &&
                                      element.CrmListFormData.map((data, j) => (
                                        <>
                                          <div className="group_heading col-12 d-flex justify-content-between align-items-center flex-wrap mg-b-0 mg-t-15">
                                            <h3 className="mb-0">
                                              Group {i + 1}
                                            </h3>
                                            <div className="d-flex">
                                              {i ? (
                                                <button
                                                  type="button"
                                                  className="btn-cust-delete"
                                                  onClick={() =>
                                                    this.removeFormFieldsGroup(
                                                      i
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={deleteicon}
                                                    alt="Delete"
                                                  />
                                                </button>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div
                                            className="CRMDynamicListInsideGroup"
                                            key={j}
                                          >
                                            {j > 0 ? (
                                              <>
                                                <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30 automatics end_or_cond">
                                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-15 mg-b-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2 col-xxl-2 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                                      <Select
                                                        styles={customStyles}
                                                        // isClearable={true}
                                                        className="multiselect"
                                                        menuPlacement="auto"
                                                        name="condition"
                                                        value={
                                                          data.selectedConditionList
                                                        }
                                                        options={
                                                          global.crmConditions
                                                        }
                                                        onChange={(value) =>
                                                          this.handleChangeConditionList(
                                                            i,
                                                            j,
                                                            value
                                                          )
                                                        }
                                                        defaultValue={
                                                          data.selectedConditionList
                                                        }
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30 automatics">
                                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-30">
                                                <div className="col-12 p-0">
                                                  <Select
                                                    styles={customStyles}
                                                    placeholder="Choose Entities"
                                                    // isClearable={true}
                                                    className="multiselect"
                                                    menuPlacement="auto"
                                                    name="crm_entity_id"
                                                    value={
                                                      data.selectedEntitiesList
                                                    }
                                                    options={
                                                      this.state.newEntityList
                                                    }
                                                    onChange={(value) =>
                                                      this.handleChangeEntitySegmentList(
                                                        i,
                                                        j,
                                                        value
                                                      )
                                                    }
                                                    defaultValue={
                                                      data.selectedEntitiesList
                                                    }
                                                    theme={(theme) => ({
                                                      ...theme,
                                                      borderRadius: 2,
                                                      colors: {
                                                        ...theme.colors,
                                                        primary: "#fff",
                                                        primary75: "#000",
                                                        primary50: "#000",
                                                        primary25: "#000",
                                                      },
                                                    })}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-30">
                                                {data.is_attribute === 5 ? (
                                                  <>
                                                    <div>
                                                      <Select
                                                        placeholder="Choose Attribute"
                                                        styles={customStyles}
                                                        //isClearable={true}
                                                        menuPlacement="auto"
                                                        name="crm_attribute_id"
                                                        className="multiselect"
                                                        value={
                                                          data.selectedAttributeList
                                                        }
                                                        options={
                                                          data.newAttributeList
                                                        }
                                                        onChange={(value) =>
                                                          this.handleChangeAttribute(
                                                            i,
                                                            j,
                                                            value
                                                          )
                                                        }
                                                        defaultValue={
                                                          data.selectedAttributeList
                                                        }
                                                        ///onInputChange={(inputAttr)=>this.handleAttributeInputChange(data.crm_entity_slug)}
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  </>
                                                ) : data.is_attribute === 1 ? (
                                                  <>
                                                    <div>
                                                      <Select
                                                        placeholder="Choose Attribute"
                                                        styles={customStyles}
                                                        //isClearable={true}
                                                        menuPlacement="auto"
                                                        name="crm_attribute_id"
                                                        className="multiselect"
                                                        value={
                                                          data.selectedAttributeList
                                                        }
                                                        options={
                                                          data.newAttributeList
                                                        }
                                                        onChange={(value) =>
                                                          this.handleChangeAttribute(
                                                            i,
                                                            j,
                                                            value
                                                          )
                                                        }
                                                        defaultValue={
                                                          data.selectedAttributeList
                                                        }
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  </>
                                                ) : data.is_attribute === 4 ? (
                                                  <>
                                                    <div>
                                                      <Select
                                                        placeholder="Choose Attribute"
                                                        styles={customStyles}
                                                        //isClearable={true}
                                                        menuPlacement="auto"
                                                        name="crm_attribute_id"
                                                        className="multiselect"
                                                        value={
                                                          data.selectedAttributeList
                                                        }
                                                        options={
                                                          data.newAttributeList
                                                        }
                                                        onChange={(value) =>
                                                          this.handleChangeAttribute(
                                                            i,
                                                            j,
                                                            value
                                                          )
                                                        }
                                                        defaultValue={
                                                          data.selectedAttributeList
                                                        }
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  </>
                                                ) : data.is_attribute === 7 ? (
                                                  <>
                                                    <div>
                                                      <Select
                                                        placeholder="Choose Attribute"
                                                        styles={customStyles}
                                                        //isClearable={true}
                                                        menuPlacement="auto"
                                                        name="crm_attribute_id"
                                                        className="multiselect"
                                                        value={
                                                          data.selectedAttributeList
                                                        }
                                                        options={
                                                          data.newAttributeList
                                                        }
                                                        onChange={(value) =>
                                                          this.handleChangeAttribute(
                                                            i,
                                                            j,
                                                            value
                                                          )
                                                        }
                                                        defaultValue={
                                                          data.selectedAttributeList
                                                        }
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  </>
                                                ) : data.is_attribute === 8 ? (
                                                  <>
                                                    <div>
                                                      <Select
                                                        placeholder="Choose Attribute"
                                                        styles={customStyles}
                                                        //isClearable={true}
                                                        menuPlacement="auto"
                                                        name="crm_attribute_id"
                                                        className="multiselect"
                                                        value={
                                                          data.selectedAttributeList
                                                        }
                                                        options={
                                                          data.newAttributeList
                                                        }
                                                        onChange={(value) =>
                                                          this.handleChangeAttribute(
                                                            i,
                                                            j,
                                                            value
                                                          )
                                                        }
                                                        defaultValue={
                                                          data.selectedAttributeList
                                                        }
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  </>
                                                ) : data.is_attribute === 6 ? (
                                                  <>
                                                    <div>
                                                      <Select
                                                        placeholder="Choose Attribute"
                                                        styles={customStyles}
                                                        //isClearable={true}
                                                        menuPlacement="auto"
                                                        name="crm_attribute_id"
                                                        className="multiselect"
                                                        value={
                                                          data.selectedAttributeList
                                                        }
                                                        options={
                                                          data.newAttributeList
                                                        }
                                                        onChange={(value) =>
                                                          this.handleChangeAttribute(
                                                            i,
                                                            j,
                                                            value
                                                          )
                                                        }
                                                        defaultValue={
                                                          data.selectedAttributeList
                                                        }
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  </>
                                                ) : data.is_attribute === 12 ? (
                                                  <>
                                                    <div>
                                                      <Select
                                                        placeholder="Choose Attribute"
                                                        styles={customStyles}
                                                        //isClearable={true}
                                                        menuPlacement="auto"
                                                        name="crm_attribute_id"
                                                        className="multiselect"
                                                        value={
                                                          data.selectedAttributeList
                                                        }
                                                        options={
                                                          data.newAttributeList
                                                        }
                                                        onChange={(value) =>
                                                          this.handleChangeAttribute(
                                                            i,
                                                            j,
                                                            value
                                                          )
                                                        }
                                                        defaultValue={
                                                          data.selectedAttributeList
                                                        }
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  </>
                                                ) : data.is_attribute === 11 ? (
                                                  <>
                                                    <div>
                                                      <Select
                                                        placeholder="Choose Attribute"
                                                        styles={customStyles}
                                                        //isClearable={true}
                                                        menuPlacement="auto"
                                                        name="crm_attribute_id"
                                                        className="multiselect"
                                                        value={
                                                          data.selectedAttributeList
                                                        }
                                                        options={
                                                          data.newAttributeList
                                                        }
                                                        onChange={(value) =>
                                                          this.handleChangeAttribute(
                                                            i,
                                                            j,
                                                            value
                                                          )
                                                        }
                                                        defaultValue={
                                                          data.selectedAttributeList
                                                        }
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  </>
                                                ) : data.is_attribute === 10 ? (
                                                  <>
                                                    <div>
                                                      <Select
                                                        placeholder="Choose Reviews"
                                                        isMulti
                                                        styles={customStyles}
                                                        isClearable={true}
                                                        menuPlacement="auto"
                                                        name="crm_attribute_id"
                                                        className="multiselect"
                                                        value={
                                                          data.selectedReviewsList
                                                        }
                                                        options={
                                                          global.crmReviews
                                                        }
                                                        getOptionValue={(
                                                          option
                                                        ) => `${option.label}`}
                                                        onChange={(value) =>
                                                          this.handleChangeReviews(
                                                            i,
                                                            j,
                                                            value
                                                          )
                                                        }
                                                        defaultValue={
                                                          data.selectedReviewsList
                                                        }
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  </>
                                                ) : data.is_attribute === 9 ? (
                                                  <>
                                                    <div>
                                                      <Select
                                                        placeholder="Choose Attribute"
                                                        styles={customStyles}
                                                        //isClearable={true}
                                                        menuPlacement="auto"
                                                        name="crm_attribute_id"
                                                        className="multiselect"
                                                        value={
                                                          data.selectedAttributeList
                                                        }
                                                        options={
                                                          data.newAttributeList
                                                        }
                                                        onChange={(value) =>
                                                          this.handleChangeAttribute(
                                                            i,
                                                            j,
                                                            value
                                                          )
                                                        }
                                                        defaultValue={
                                                          data.selectedAttributeList
                                                        }
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>

                                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 p-0">
                                                {j <=
                                                element.CrmListFormDataTotal -
                                                  1 ? (
                                                  <>
                                                    <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                      <button
                                                        onClick={() => {
                                                          this.handleRemoveFieldsRow(
                                                            i,
                                                            j
                                                          );
                                                        }}
                                                        className={"btn"}
                                                      >
                                                        <img
                                                          src={deleteicon}
                                                          alt="Delete"
                                                        />
                                                      </button>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>

                                            {data.is_attribute === 1 ? (
                                              <>
                                                <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30 automatics CRMOnboardingStepAttribute">
                                                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-30">
                                                    {" "}
                                                    <Select
                                                      placeholder="Choose Historical Campaigns"
                                                      isMulti
                                                      styles={customStyles}
                                                      isClearable={true}
                                                      menuPlacement="auto"
                                                      name="crm_attribute_id"
                                                      className="multiselect"
                                                      value={
                                                        data.selectedCampaignsList
                                                      }
                                                      options={
                                                        this.state
                                                          .newHistoricalCampaigns
                                                      }
                                                      getOptionValue={(
                                                        option
                                                      ) => `${option.label}`}
                                                      onChange={(value) =>
                                                        this.handleChangeHistoricalCampaign(
                                                          i,
                                                          j,
                                                          value
                                                        )
                                                      }
                                                      onInputChange={
                                                        this
                                                          .handleCampaignInputChange
                                                      }
                                                      defaultValue={
                                                        data.selectedCampaignsList
                                                      }
                                                      theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 2,
                                                        colors: {
                                                          ...theme.colors,
                                                          primary: "#fff",
                                                          primary75: "#000",
                                                          primary50: "#000",
                                                          primary25: "#000",
                                                        },
                                                      })}
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            ) : data.is_attribute === 9 &&
                                              data.crm_attribute_slug ===
                                                "INQUIRYSTATUS" ? (
                                              <>
                                                <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30 automatics CRMOnboardingStepAttribute">
                                                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-30">
                                                    {" "}
                                                    <Select
                                                      placeholder="Choose Lease Inquiries Status"
                                                      isMulti
                                                      styles={customStyles}
                                                      isClearable={true}
                                                      menuPlacement="auto"
                                                      name="crm_attribute_id"
                                                      className="multiselect"
                                                      value={
                                                        data.selectedLeaseInquiryList
                                                      }
                                                      options={
                                                        global.statuslist
                                                      }
                                                      getOptionValue={(
                                                        option
                                                      ) => `${option.label}`}
                                                      onChange={(value) =>
                                                        this.handleChangeLeaseInquiries(
                                                          i,
                                                          j,
                                                          value
                                                        )
                                                      }
                                                      defaultValue={
                                                        data.selectedLeaseInquiryList
                                                      }
                                                      theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 2,
                                                        colors: {
                                                          ...theme.colors,
                                                          primary: "#fff",
                                                          primary75: "#000",
                                                          primary50: "#000",
                                                          primary25: "#000",
                                                        },
                                                      })}
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            ) : data.is_attribute === 9 &&
                                              data.crm_attribute_slug ===
                                                "FLOORPLAN" ? (
                                              <>
                                                <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30 automatics CRMOnboardingStepAttribute">
                                                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-30">
                                                    {" "}
                                                    <Select
                                                      placeholder="Choose Properties Floor Plans"
                                                      isMulti
                                                      styles={customStyles}
                                                      //isClearable={true}
                                                      readonly="true"
                                                      menuPlacement="auto"
                                                      name="crm_attribute_id"
                                                      className="multiselect"
                                                      value={
                                                        data.selectedFloorPlanList
                                                      }
                                                      options={
                                                        data.newFloorPlans
                                                      }
                                                      getOptionValue={(
                                                        option
                                                      ) => `${option.label}`}
                                                      onChange={(value) =>
                                                        this.handleChangeFloorPlan(
                                                          i,
                                                          j,
                                                          value
                                                        )
                                                      }
                                                      defaultValue={
                                                        data.selectedFloorPlanList
                                                      }
                                                      theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 2,
                                                        colors: {
                                                          ...theme.colors,
                                                          primary: "#fff",
                                                          primary75: "#000",
                                                          primary50: "#000",
                                                          primary25: "#000",
                                                        },
                                                      })}
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}

                                            {data.is_filter === 1 ? (
                                              <>
                                                <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30 automatics CRMOnboardingStepAttribute">
                                                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-30">
                                                    <Select
                                                      placeholder={
                                                        "Choose Filter"
                                                      }
                                                      styles={customStyles}
                                                      //isClearable={true}
                                                      className="multiselect"
                                                      menuPlacement="auto"
                                                      name="crm_filter_id"
                                                      value={
                                                        data.selectedFiltersList
                                                      }
                                                      options={data.filtersList}
                                                      onChange={(value) =>
                                                        this.handleChangeFiltersList(
                                                          i,
                                                          j,
                                                          value
                                                        )
                                                      }
                                                      defaultValue={
                                                        data.selectedFiltersList
                                                      }
                                                      theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 2,
                                                        colors: {
                                                          ...theme.colors,
                                                          primary: "#fff",
                                                          primary75: "#000",
                                                          primary50: "#000",
                                                          primary25: "#000",
                                                        },
                                                      })}
                                                    />
                                                  </div>

                                                  {data.attribute_type === 4 &&
                                                  data.is_filter === 1 ? (
                                                    <>
                                                      {/* <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-10"></div> */}
                                                      <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-30">
                                                        <DatePicker
                                                          className="form-control max_width_100"
                                                          disabledKeyboardNavigation
                                                          strictParsing
                                                          scrollableYearDropdown
                                                          showMonthDropdown
                                                          showYearDropdown
                                                          autoComplete="off"
                                                          placeholderText={
                                                            "From"
                                                          }
                                                          yearDropdownItemNumber={
                                                            global.yearDropdownItemNumber
                                                          }
                                                          value={data.from_date}
                                                          name="from_date"
                                                          excludeDates={
                                                            this.state
                                                              .disabled_dates
                                                          }
                                                          dateFormat="MM/dd/yy"
                                                          selected={
                                                            data.from_date
                                                          }
                                                          onChange={(value) =>
                                                            this.changeHandlerDateRow(
                                                              {
                                                                target: {
                                                                  type: "date",
                                                                  name: "from_date",
                                                                  value: value,
                                                                  indexI: i,
                                                                  indexJ: j,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-30">
                                                        <DatePicker
                                                          className="form-control max_width_100"
                                                          disabledKeyboardNavigation
                                                          strictParsing
                                                          scrollableYearDropdown
                                                          showMonthDropdown
                                                          showYearDropdown
                                                          popperPlacement="top-left"
                                                          placeholderText={"To"}
                                                          minDate={
                                                            data.from_date
                                                              ? Moment(
                                                                  data.from_date
                                                                )
                                                                  .add(
                                                                    1,
                                                                    "days"
                                                                  )
                                                                  .toDate(
                                                                    "dd/MM/YY"
                                                                  )
                                                              : ""
                                                          }
                                                          autoComplete="off"
                                                          yearDropdownItemNumber={
                                                            global.yearDropdownItemNumber
                                                          }
                                                          value={data.to_date}
                                                          name="to_date"
                                                          dateFormat="MM/dd/yy"
                                                          selected={
                                                            data.to_date
                                                          }
                                                          onChange={(value) =>
                                                            this.changeHandlerDateRow(
                                                              {
                                                                target: {
                                                                  type: "date",
                                                                  name: "to_date",
                                                                  value: value,
                                                                  indexI: i,
                                                                  indexJ: j,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {data.attribute_type === 5 &&
                                                  data.is_filter === 1 ? (
                                                    <>
                                                      {/* <div className="col-sm-3 col-md-6 col-lg-6 col-xl-8 col-xxl-8 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15"></div> */}
                                                      <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-15">
                                                        <Form.Control
                                                          type="text"
                                                          name="from_amount"
                                                          value={
                                                            !isNaN(
                                                              data.from_amount
                                                            )
                                                              ? data.from_amount
                                                              : ""
                                                          }
                                                          autoComplete="off"
                                                          placeholder="From"
                                                          onChange={(event) =>
                                                            this.changeHandlerRow(
                                                              i,
                                                              j,
                                                              event
                                                            )
                                                          }
                                                          onKeyPress={
                                                            global
                                                              .onKeyPressEvent
                                                              .numberValidation
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-15">
                                                        <Form.Control
                                                          type="text"
                                                          name="to_amount"
                                                          value={
                                                            !isNaN(
                                                              data.to_amount
                                                            )
                                                              ? data.to_amount
                                                              : ""
                                                          }
                                                          autoComplete="off"
                                                          placeholder="To"
                                                          onChange={(event) =>
                                                            this.changeHandlerRow(
                                                              i,
                                                              j,
                                                              event
                                                            )
                                                          }
                                                          onKeyPress={
                                                            global
                                                              .onKeyPressEvent
                                                              .numberValidation
                                                          }
                                                        />
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {data.attribute_type === 7 &&
                                                  data.is_filter === 1 ? (
                                                    <>
                                                      {/* <div className="col-sm-3 col-md-6 col-lg-6 col-xl-8 col-xxl-8 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15"></div> */}

                                                      <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-30">
                                                        <Form.Control
                                                          type="text"
                                                          name="noOfDays"
                                                          value={
                                                            !isNaN(
                                                              data.noOfDays
                                                            )
                                                              ? data.noOfDays
                                                              : ""
                                                          }
                                                          autoComplete="off"
                                                          placeholder="days"
                                                          onChange={(event) =>
                                                            this.changeHandlerRow(
                                                              i,
                                                              j,
                                                              event
                                                            )
                                                          }
                                                          onKeyPress={
                                                            global
                                                              .onKeyPressEvent
                                                              .numberValidation
                                                          }
                                                        />
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}

                                            <div className="col-sm-12 p-0">
                                              {j ===
                                              element.CrmListFormDataTotal -
                                                1 ? (
                                                <>
                                                  <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                    <button
                                                      onClick={() => {
                                                        this.handleAddFieldsRow(
                                                          i,
                                                          j
                                                        );
                                                      }}
                                                      className="btn py-0"
                                                    >
                                                      <img
                                                        src={pluswIcon}
                                                        alt="Add"
                                                      />
                                                    </button>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                </>
                              )
                            )}
                          </div>
                          <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30 automatics mg-t-15 mg-b-15">
                            <div className="col-lg-12 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30 mg-t-15 mg-b-5">
                              <button
                                type="button"
                                onClick={() => {
                                  this.addFormFieldsGroup();
                                }}
                                className="btn-snap-view"
                              >
                                Add Group
                              </button>
                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mg-t-15 mg-b-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                              <div className="col-md-1 pl-0">
                                <label className="form-label text-left">
                                  Status
                                </label>
                              </div>
                              <div>
                                <b
                                  className={
                                    this.state.status_id ? "active" : "inactive"
                                  }
                                ></b>
                                <div
                                  className={
                                    this.state.status_id
                                      ? "az-toggle on"
                                      : "az-toggle"
                                  }
                                  onClick={() => this.changeStatus()}
                                >
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default CrmListForm;

