/*-------------------------------------------------------
||Program           : LeaseInquiry.jsx
||Application       : Snapshot
||Option            : Snapshot - Lease inquiry needs to display a widget in snapshot section
||Developer         : Chandra Shekhar
||Date              : 12-July-2023
||Status            : In Use
----------------------------------------------------------*/
import React, { Component } from "react";
import { withRouter } from "react-router";
import RUmessageService from "../../services/RUmessageService";
import NoBooking from "../../assets/images/snapshoticons/Bookings.svg";


class MessagesOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertySlug: this.props.propertySlug,
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
    };
  }

  componentDidMount() {
    this.setState({ spinnerLoader: true, loading: true });
    if (this.state.propertySlug) {
      this.getData(this.state.propertySlug);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ spinnerLoader: true, loading: true });
    this.setState({listCount : 0});
    if (nextProps.propertySlug) {
      this.getData(nextProps.propertySlug);
    }
  }

  async getData(propertySlug) {
    this.setState({ spinnerLoader: true, loading: true });
    var resData = {};
    let res = await RUmessageService.getOverviewdata(propertySlug);
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : [];
      this.setState({
        resData: resData,
      });
    }
    this.setState({ spinnerLoader: false, loading: false });
  }

  render() {
    return (
      <div className="col-xxl-6 col-md-6 col-sm-12 col-12 mg-b-20">
        <div className="card card-table-two">
          <div className=" d-flex justify-content-between">
            <div className="">
              <h6 className="card-title">Messages Overview</h6>
              <span className="d-block mg-b-0">Recent Messages Overview</span>
            </div>
            {/* <Link className="btn-snap-view" to={"/applicant-detail"}>View all</Link> */}
          </div>
          {this.state.resData ? (
            <div className="table-responsive">
              <table className="table table-dashboard-two mg-b-0">
                <thead>
                  <tr>
                    <th className="wd-lg-25p tx-left">S.No</th>
                    <th className="wd-lg-25p tx-left">Label</th>
                    <th className="wd-lg-25p text-left">Value</th>
                  </tr>
                </thead>
                <tbody>            
                    <tr>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                        1
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                        Total Message
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">
                        {this.state.resData  && this.state.resData.total_msg ? this.state.resData.total_msg : '' }
                        </td>
                    </tr>
                    <tr>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                        2
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                        Percent messages answered by Assist
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">
                        {this.state.resData  && this.state.resData.percent_answer_by_assit ? this.state.resData.percent_answer_by_assit : '' }
                        </td>
                    </tr>
                    <tr>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                        3
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                        Percent messages escalated to Resident
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">
                        {this.state.resData  && this.state.resData.percent_esclated_msg ? this.state.resData.percent_esclated_msg : '' }
                        </td>
                    </tr>
                    <tr>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                        4
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                        Avg Resident response rate (in minutes) between escalation
                        and reply
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">
                      
                      {this.state.resData  && this.state.resData.response_rate_for_esclation ? this.state.resData.response_rate_for_esclation : '' }
                        </td>
                    </tr>              
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <div className="table-responsive overflow-hidden">
                {this.state.spinnerLoader ? (
                  global.snapshotloader
                ) : (
                  <div className="text-center">
                    <img src={NoBooking} alt="" />
                    <p>No Message data.</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(MessagesOverview);
