///////////////////////////////////////////////
//     							             //
//  Program: YaleService.js                 //
//  Application: Services                    //
//  Option: Used for Igloo Module              //
//  Developer: Ravi                //
//  Date: 2023-08-22                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class YaleService {
  getYaleLock(queryString) {
    let endPoint = "get-yale-units" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {endPoint,addditionalHeaderData, postData};
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateYaleInstruction(data) {
    let endPoint = "update-yale-instruction";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {endPoint, addditionalHeaderData, postData};
    let response = ApiService.postCall(inputData);
    return response;
  }

  getYaleFob(queryString) {
    let endPoint = "get-yale-fob" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  addYaleFob(data) {
    let endPoint = "add-yale-fob";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  mappingUnits(data) {
    let endPoint = "map-yale-units";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

getYaleInstruction(queryString) {
  let endPoint = "get-yale-instruction" + (queryString ? "?" + queryString : "");
  let addditionalHeaderData = {};
  let postData = {};

  let inputData = {
    endPoint: endPoint,
    addditionalHeaderData: addditionalHeaderData,
    postData: postData,
  };
  let response = ApiService.getCall(inputData);
  return response;
}

}
export default new YaleService();
