/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
import { Form } from "react-bootstrap";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import CountryService from "../../services/CountryService";
import CountyService from "../../services/CountyService";
import StateService from "../../services/StateService";
import CityService from "../../services/CityService";
import MetroService from "../../services/MetroService";
import SubMetroService from "../../services/SubMetroService";
import LocationTypeService from "../../services/LocationTypeService";
import Alerts from "../common/Alerts";
import exportIcon from "./../../assets/images/location-pin.svg";
// import copyIcon from "./../../assets/images/copy.svg";

export class Location extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      // propertySlug: this.props.property_slug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      street_1: "",
      street_2: "",
      locationTypeList: [],
      location_type_id: [],
      newLocationTypeList: [],
      selectedLocationTypeList: [],
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      metroList: [],
      selectedMetroList: [],
      newMetroList: [],
      subMetroList: [],
      newSubMetroList: [],
      selectedSubMetroList: [],
      confirm_back: 0,
      cityListTotal: 0,
      countyListTotal: 0,
      metroListTotal: 0,
      subMetroListTotal: 0,
      locationTypeListTotal: 0,
    };
    this.changeView = this.changeView.bind(this);
    this.saveOrUpdateProperty = this.saveOrUpdateProperty.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 1 });
    this.getData();
  }

  async getData() {
    if (this.props.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.props.property_slug);
      if (global.successStatus.includes(res.status)) {
        // resData = res.data ? res.data : {}
        //for view
        resData.street_1 = res.data.street_1 ? res.data.street_1 : "";
        resData.street_2 = res.data.street_2 ? res.data.street_2 : "";
        resData.country = res.data.country ? res.data.country.country_name : "";
        resData.county_name = res.data.county
          ? res.data.county.county_name
          : "";
        resData.state_name = res.data.state ? res.data.state.state_name : "";
        resData.city = res.data.city ? res.data.city.city_name : "";
        resData.metro = res.data.metro ? res.data.metro.metro_name : "";
        resData.sub_metro = res.data.sub_metro
          ? res.data.sub_metro.sub_metro_name
          : "";
        resData.zip_code = res.data.zip_code ? res.data.zip_code : "";
        resData.latitude = res.data.latitude ? res.data.latitude : "";
        resData.longitude = res.data.longitude ? res.data.longitude : "";
        resData.google_map_link = res.data.google_map_link
          ? res.data.google_map_link
          : "";
        resData.location_type_name_data = res.data.location_type_name_data
          ? res.data.location_type_name_data
          : "";
        //for edit
        resData.property_name = res.data.property_name
          ? res.data.property_name
          : "";
        resData.aka_name = res.data.aka_name ? res.data.aka_name : "";
        resData.country_id = res.data.country ? res.data.country.slug : "";
        resData.county_id = res.data.county ? res.data.county.slug : "";
        resData.state_id = res.data.state ? res.data.state.slug : "";
        resData.city_id = res.data.city ? res.data.city.slug : "";
        resData.metro_id = res.data.metro ? res.data.metro.slug : "";
        resData.sub_metro_id = res.data.sub_metro
          ? res.data.sub_metro.slug
          : "";
        if (this.state.viewMode === 1) {
          var selectedLocationTypeList = [];
          res.data.LocationData.forEach((item, i) => {
            selectedLocationTypeList = [
              ...selectedLocationTypeList,
              {
                value: item.slug,
                label: item.location_type_name,
              },
            ];
          });

          var selectedCityList = [];
          selectedCityList = res.data.city ? ([
            {
              value: res.data.city.slug,
              label: res.data.city.city_name,
            },
          ]) : "";
          var selectedStateList = [];
          selectedStateList = res.data.state ? ([
            {
              value: res.data.state.slug,
              label: res.data.state.state_name,
            },
          ]) : "";
          var selectedCountyList = [];
          selectedCountyList = res.data.county ? ([
            {
              value: res.data.county.slug,
              label: res.data.county.county_name,
            },
          ]) : "";
          var selectedCountryList = [];
          selectedCountryList = res.data.country ? ([
            {
              value: res.data.country.slug,
              label: res.data.country.country_name,
            },
          ]) : "";
          var selectedMetroList = [];
          selectedMetroList = res.data.metro ? ([
            {
              value: res.data.metro.slug,
              label: res.data.metro.metro_name,
            },
          ]) : "";
          var selectedSubMetroList = [];
          selectedSubMetroList = res.data.sub_metro ? ([
            {
              value: res.data.sub_metro.slug,
              label: res.data.sub_metro.sub_metro_name,
            },
          ]) : "";
        }
      }
      this.setState(resData);
      this.setState({
        selectedLocationTypeList: selectedLocationTypeList,
        selectedCityList: selectedCityList,
        selectedStateList: selectedStateList,
        selectedCountyList: selectedCountyList,
        selectedCountryList: selectedCountryList,
        selectedMetroList: selectedMetroList,
        selectedSubMetroList: selectedSubMetroList,
      });
      this.setState({ location_type_id: selectedLocationTypeList });
    }
  }

  async getCountryData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countryList = [];

    let res = await CountryService.getCountry(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
    }
    const newCountryList = countryList.map(({ slug, country_name }) => ({
      value: slug,
      label: country_name,
    }));
    this.setState({ countryList: countryList, newCountryList: newCountryList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCountyData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countyList = [];
    var countyListTotal = 0;
    let res = await CountyService.getCounty(
      "is_dropdown=1&country_slug=" +
      value +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
      countyListTotal = countyList ? countyList.length : 0;
    }
    const newCountyList = countyListTotal > 0 ? countyList.map(({ slug, county_name }) => ({
      value: slug,
      label: county_name,
    })) : [];
    this.setState({ countyList: countyList, newCountyList: newCountyList, countyListTotal: countyListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  async getStateData(countryValue, countyValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var stateList = [];

    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
      countryValue +
      "&county_slug=" +
      countyValue +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
    }
    const newStateList = stateList.map(({ slug, state_name }) => ({
      value: slug,
      label: state_name,
    }));
    this.setState({ stateList: stateList, newStateList: newStateList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCityData(stateValue, countyValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;
    let res = await CityService.getCity(
      "is_dropdown=1&state_slug=" + stateValue + "&county_slug=" + countyValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList = cityListTotal > 0 ? cityList.map(({ slug, city_name }) => ({
      value: slug,
      label: city_name,
    })) : [];
    this.setState({
      cityList: cityList,
      newCityList: newCityList,
      cityListTotal: cityListTotal
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getAllRealetedData(slug, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await CityService.getDataByCitySlug(
      slug,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.country_id = res.data.countryData
        ? res.data.countryData.country.slug
        : "";
      resData.country_name = res.data.countryData ? res.data.countryData.country.country_name : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.countryData.country ? ([
        {
          value: res.data.countryData.country
            ? res.data.countryData.country.slug
            : "",
          label: res.data.countryData.country
            ? res.data.countryData.country.country_name
            : "",
        },
      ]) : "";
      resData.county_id = res.data.county ? res.data.county.slug : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county ? ([
        {
          value: res.data.county.slug ? res.data.county.slug : "",
          label: res.data.county.county_name
            ? res.data.county.county_name
            : "",
        },
      ])
        : "";
      resData.state_id = res.data.state ? res.data.state.slug : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      var selectedStateList = [];
      selectedStateList = res.data.state ? ([
        {
          value: res.data.state.slug ? res.data.state.slug : "",
          label: res.data.state.state_name ? res.data.state.state_name : "",
        },
      ]) : "";
    }
    this.setState(resData);
    this.setState({
      selectedStateList: selectedStateList,
      selectedCountyList: selectedCountyList,
      selectedCountryList: selectedCountryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getMetroData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var metroList = [];
    var metroListTotal = 0;
    let res = await MetroService.getMetro(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      metroList = res.data ? res.data : [];
      metroListTotal = metroList ? metroList.length : 0;
    }
    const newMetroList = metroListTotal > 0 ? metroList.map(({ slug, metro_name }) => ({
      value: slug,
      label: metro_name,
    })) : [];
    this.setState({ metroList: metroList, newMetroList: newMetroList, metroListTotal: metroListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  async getSubMetroData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var subMetroList = [];
    var subMetroListTotal = 0;
    let res = await SubMetroService.getSubMetro(
      "is_dropdown=1&metro_slug=" +
      value +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      subMetroList = res.data ? res.data : [];
      subMetroListTotal = subMetroList ? subMetroList.length : 0;
    }
    const newSubMetroList = subMetroListTotal > 0 ? subMetroList.map(({ slug, sub_metro_name }) => ({
      value: slug,
      label: sub_metro_name,
    })) : [];
    this.setState({
      subMetroList: subMetroList,
      newSubMetroList: newSubMetroList,
      subMetroListTotal: subMetroListTotal
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getLocationTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var locationTypeList = [];
    var locationTypeListTotal = 0;
    let res = await LocationTypeService.getLocationType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      locationTypeList = res.data ? res.data : [];
      locationTypeListTotal = locationTypeList ? locationTypeList.length : 0;
    }
    const newLocationTypeList = locationTypeListTotal > 0 ? locationTypeList.map(
      ({ slug, location_type_name }) => ({
        value: slug,
        label: location_type_name,
      })
    ) : [];
    this.setState({
      locationTypeList: locationTypeList,
      newLocationTypeList: newLocationTypeList,
      locationTypeListTotal: locationTypeListTotal
    });
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdateProperty = async (e) => {
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      this.state.location_type_id = this.state.selectedLocationTypeList
        ? this.state.selectedLocationTypeList.map(function (obj) {
          return obj.value;
        })
        : "";

      let multiLocationTypeString = this.state.location_type_id
        ? this.state.location_type_id.join(",")
        : "";
      let inputData = {
        tab: 2,
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        street_1: this.state.street_1,
        street_2: this.state.street_2,
        city_slug: this.state.city_id,
        zip_code: this.state.zip_code,
        country_slug: this.state.country_id,
        county_slug: this.state.county_id,
        state_slug: this.state.state_id,
        metro_slug: this.state.metro_id,
        sub_metro_slug: this.state.sub_metro_id,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        google_map_link: this.state.google_map_link,
        location_type_slug: multiLocationTypeString,
      };

      if (this.props.property_slug !== undefined) {
        let res = await PropertyService.updateProperty(
          this.props.property_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.changeView();
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
      this.setState({ selectedLocationTypeList: [] });
      this.setState({ newLocationTypeList: [] });
      this.setState({ location_type_id: "" });
      this.getData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
      this.setState({ selectedLocationTypeList: [] });
      this.setState({ newLocationTypeList: [] });
      this.setState({ location_type_id: "" });
      this.getData();
      this.getCountryData();
      this.getCountyData();
      this.getStateData();
      this.getCityData();
      this.getMetroData();
      this.getSubMetroData();
      this.getLocationTypeData();
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
          ? 1
          : 0
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    if (event.target.name === "city_id") {
      this.getAllRealetedData(event.target.value);
    }

    // if (event.target.name === "country_id") {
    //   if (event.target.value === "") {
    //     this.setState({
    //       state_id: "",
    //       county_id: "",
    //       city_id: "",
    //       stateList: [],
    //       countyList: [],
    //       cityList: [],
    //     });
    //   } else {
    //     this.getStateData(event.target.value);
    //   }
    // }
    // if (event.target.name === "state_id") {
    //   if (event.target.value === "") {
    //     this.setState({
    //       county_id: "",
    //       city_id: "",
    //       countyList: [],
    //       cityList: [],
    //     });
    //   } else {
    //     this.getCountyData(event.target.value);
    //     this.getCityData(event.target.value, "");
    //   }
    // }
    // if (event.target.name === "county_id") {
    //   if (event.target.value === "") {
    //     this.getCityData(input.state_id, "");
    //   } else {
    //     this.getCityData(input.state_id, event.target.value);
    //   }
    // }

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  };

  handleChangeLocationTypeList(value) {
    let location_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        location_type_id.push(item.value);
      });
    }
    this.setState({
      selectedLocationTypeList: value,
      location_type_id: location_type_id,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  handleChangeCityList(value) {

    let city_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_id.push(item.value);
      });
    }
    if (value !== null) {
      this.getAllRealetedData(value.value);
    }
    const city_id_value = value !== null ? value.value : [];
    this.setState({
      selectedCityList: value,
      city_id: city_id_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  handleChangeStateList(value) {
    let state_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_id.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStateList: value,
      state_id: state_id_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  handleChangeCountyList(value) {
    let county_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        county_id.push(item.value);
      });
    }
    const county_id_value = value !== null ? value.value : [];
    this.setState({
      selectedCountyList: value,
      county_id: county_id_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  handleChangeCountryList(value) {
    let country_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_id.push(item.value);
      });
    }
    const country_id_value = value !== null ? value.value : [];
    this.setState({
      selectedCountryList: value,
      country_id: country_id_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }
  handleChangeMetroList(value) {
    let metro_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        metro_id.push(item.value);
      });
    }
    const metro_id_value = value !== null ? value.value : [];
    this.setState({
      selectedMetroList: value,
      metro_id: metro_id_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }
  handleChangeSubMetroList(value) {
    let sub_metro_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        sub_metro_id.push(item.value);
      });
    }
    const sub_metro_id_value = value !== null ? value.value : [];
    this.setState({
      selectedSubMetroList: value,
      sub_metro_id: sub_metro_id_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }
  render() {

    const customStyles = {

      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),   
        color: (state.isSelected && state.isFocused) ? "white" : (state.isSelected ? "black" : state.isFocused && "white"),
        backgroundColor: (state.isSelected && state.isFocused) ? 'black' : (state.isFocused && "black"),
      })
    }


    return (
      <div className="dashboardRightcard">
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <div className="row- d-flex justify-content-end">
          {/* <Link to={'/properties/update/'+this.props.property_slug+"/location"} ><button className="btn-success-outline-small"><img src={editIcon}/>Edit</button></Link> */}
          {this.state.viewMode === 0 ? (
            <button
              className="btn-success-outline-small"
              onClick={this.changeView}
            >
              <img src={editIcon} alt="Edit" />
              Edit
            </button>
          ) : (
            <div className="d-flex">
              <button
                type="button"
                className="btn-success-outline-small"
                onClick={() => {
                  if (this.state.confirm_back === 1)
                    if (window.confirm("Do you want to exit without saving?"))
                      this.changeView();
                    else return false;
                  else this.changeView();
                }}
              >
                Cancel
              </button>
              <button
                className="btn-success ml-3"
                onClick={this.saveOrUpdateProperty}
              >
                Save
              </button>
            </div>
          )}
        </div>
        <div className="col-md-12 pl-0 pr-0 mg-t-15">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>

        {/* view mode */}
        {this.state.viewMode === 0 ? (
          <>
            <div className="row">
              <div className="col-md-12">
                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Street 1</p>
                    <p className="media-body">
                      {this.state.street_1 ? this.state.street_1 : ""}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Street 2</p>
                    <p className="media-body">
                      {this.state.street_2 ? this.state.street_2 : ""}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">City</p>
                    <p className="media-body">
                      {this.state.city ? this.state.city : ""}
                    </p>
                  </div>
                </div>

                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">State</p>
                    <p className="media-body">
                      {this.state.state_name ? this.state.state_name : ""}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Zip Code</p>
                    <p className="media-body">
                      {this.state.zip_code ? this.state.zip_code : ""}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">County</p>
                    <p className="media-body">
                      {this.state.county_name ? this.state.county_name : ""}
                    </p>
                  </div>
                </div>

                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Country</p>
                    <p className="media-body">
                      {this.state.country ? this.state.country : ""}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Metro</p>
                    <p className="media-body">
                      {this.state.metro ? this.state.metro : ""}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Sub Metro</p>
                    <p className="media-body">
                      {this.state.sub_metro ? this.state.sub_metro : ""}
                    </p>
                  </div>
                </div>

                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Latitude</p>
                    <p className="media-body">
                      {this.state.latitude ? this.state.latitude : ""}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Longitude</p>
                    <p className="media-body">
                      {this.state.longitude ? this.state.longitude : ""}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Location Type</p>
                    <p className="media-body">
                      {this.state.location_type_name_data
                        ? this.state.location_type_name_data
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mg-t-10 mg-b-30 pd-b-2" />
            <div className="row">
              <div className="col-md-12">
                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                  <div className="col-md-12">
                    <p className="az-profile-name-text pl-0">Google Map Link</p>
                    {this.state.google_map_link ? (
                      <p className="media-body">
                        <a
                          href={this.state.google_map_link}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {/* <span className="maplink">
                          
                          </span> */}
                          <button className="btn-success-outline-small-link">
                            <img src={exportIcon} alt="open" />Open Link
                          </button>
                        </a>

                        {/* <button className="btn-success-outline-small"><img src={copyIcon} alt="open" /> Copy Address</button> */}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {/* edit mode */}
        {this.state.viewMode === 1 ? (
          <div className="m-0">
            <div className="col-md-12 pl-0 pr-0"></div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Street 1 <span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  onChange={this.changeHandler}
                  type="text"
                  name="street_1"
                  value={this.state.street_1}
                // placeholder="Street 1"
                />
                {this.state.errors.street_1 ? (
                  <div className="text-danger">
                    {this.state.errors.street_1}
                  </div>
                ) : (
                  this.validator.message(
                    "street_1",
                    this.state.street_1,
                    "required|max:100",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">Street 2 <span className="optional">(Optional)</span></label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="street_2"
                  value={this.state.street_2}
                // placeholder="Street 2"
                />
                {this.state.errors.street_2 ? (
                  <div className="text-danger">
                    {this.state.errors.street_2}
                  </div>
                ) : (
                  this.validator.message(
                    "street_2",
                    this.state.street_2,
                    "max:100",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  City <span className="text-danger">*</span>
                </label>
              </div>
              {/* col */}
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedCityList}
                  options={this.state.newCityList}
                  getOptionValue={option => `${option.label}`}
                  onChange={(value) => this.handleChangeCityList(value)}
                  defaultValue={this.state.selectedCityList}
                  autoComplete="off"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                  })}
                />
                {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="city_id"
                  value={this.state.city_id}
                >
                  <option key="" value="">
                    Select City
                  </option>
                  {this.state.cityList.map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.city_name}
                      </option>
                    ))}
                </select> */}
                {this.state.errors.city_id ? (
                  <div className="text-danger">{this.state.errors.city_id}</div>
                ) : (
                  this.validator.message(
                    "city",
                    this.state.city_id,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>
              {/* col */}
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  State <span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                {/* <Select
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedStateList}
                  options={this.state.newStateList.filter((option) => {
                    const val1 = this.state.state_id ? this.state.state_id : "";
                    if (val1 === option.slug) return true;
                  })}
                  onChange={(value) => this.handleChangeStateList(value)}
                  defaultValue={this.state.selectedStateList}
                /> */}
                <Form.Control
                  type="text"
                  className="custom-disable"
                  value={this.state.state_name}
                  // placeholder="State"
                  disabled="true"
                />
                {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="state_id"
                  value={this.state.state_id}
                >
                  <option key="" value="">
                    Select State
                  </option>
                  {this.state.stateList.filter((option) => {
                    const val1 = this.state.state_id ? this.state.state_id : "";
                     if(val1 === option.slug)
                     return true;
                  }).map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.state_name}
                      </option>
                    ))}
                </select> */}
                {this.state.errors.state_id ? (
                  <div className="text-danger">
                    {this.state.errors.state_id}
                  </div>
                ) : (
                  this.validator.message(
                    "state",
                    this.state.state_id,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Zip Code <span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  onChange={this.changeHandler}
                  type="text"
                  name="zip_code"
                  value={this.state.zip_code}
                  // placeholder="Zip Code"
                  maxLength="5"
                  onKeyPress={global.onKeyPressEvent.numberValidation}
                />
                {this.state.errors.zip_code ? (
                  <div className="text-danger">
                    {this.state.errors.zip_code}
                  </div>
                ) : (
                  this.validator.message(
                    "zip_code",
                    this.state.zip_code,
                    "required|max:5",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>

            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">County <span className="optional">(Optional)</span></label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedCountyList}
                  options={this.state.newCountyList.filter((option) => {
                    const val1 = this.state.county_id
                      ? this.state.county_id
                      : "";
                    if (val1 === option.slug) return true;
                  })}
                  getOptionValue={option => `${option.label}`}
                  onChange={(value) => this.handleChangeCountyList(value)}
                  defaultValue={this.state.selectedCountyList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                  })}
                />
                {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="county_id"
                  value={this.state.county_id}
                >
                  <option key="" value="">
                    Select County
                  </option>
                  {this.state.countyList
                    .filter((option) => {
                      const val1 = this.state.county_id
                        ? this.state.county_id
                        : "";
                      if (val1 === option.slug) return true;
                    })
                    .map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.county_name}
                      </option>
                    ))}
                </select> */}
                {this.state.errors.county_id ? (
                  <div className="text-danger">
                    {this.state.errors.county_id}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Country <span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                {/* <Select
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedCountryList}
                  options={this.state.newCountryList.filter((option) => {
                    const val1 = this.state.country_id
                      ? this.state.country_id
                      : "";
                    if (val1 === option.slug) return true;
                  })}
                  onChange={(value) => this.handleChangeCountryList(value)}
                  defaultValue={this.state.selectedCountryList}
                /> */}

                <Form.Control
                  type="text"
                  className="custom-disable"
                  value={this.state.country_name}
                  // placeholder="Country"
                  disabled="true"
                />

                {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="country_id"
                  value={this.state.country_id}
                >
                  <option key="" value="">
                    Select Country
                  </option>
                  {this.state.countryList
                    .filter((option) => {
                      const val1 = this.state.country_id
                        ? this.state.country_id
                        : "";
                      if (val1 === option.slug) return true;
                    })
                    .map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.country_name}
                      </option>
                    ))}
                </select> */}
                {this.state.errors.country_id ? (
                  <div className="text-danger">
                    {this.state.errors.country_id}
                  </div>
                ) : (
                  this.validator.message(
                    "country",
                    this.state.country_id,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>

            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">Metro <span className="optional">(Optional)</span></label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedMetroList}
                  options={this.state.newMetroList}
                  getOptionValue={option => `${option.label}`}
                  onChange={(value) => this.handleChangeMetroList(value)}
                  defaultValue={this.state.selectedMetroList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                  })}
                />
                {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="metro_id"
                  value={this.state.metro_id}
                >
                  <option key="" value="">
                    Select Metro
                  </option>
                  {this.state.metroList.map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.metro_name}
                      </option>
                    ))}
                </select> */}
                {this.state.errors.metro_id ? (
                  <div className="text-danger">
                    {this.state.errors.metro_id}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">Sub Metro <span className="optional">(Optional)</span></label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedSubMetroList}
                  options={this.state.newSubMetroList}
                  getOptionValue={option => `${option.label}`}
                  onChange={(value) => this.handleChangeSubMetroList(value)}
                  defaultValue={this.state.selectedSubMetroList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                  })}
                />
                {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="sub_metro_id"
                  value={this.state.sub_metro_id}
                >
                  <option key="" value="">
                    Select Sub Metro
                  </option>
                  {this.state.subMetroList.map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.sub_metro_name}
                      </option>
                    ))}
                </select> */}
                {this.state.errors.sub_metro_id ? (
                  <div className="text-danger">
                    {this.state.errors.sub_metro_id}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">Latitude <span className="optional">(Optional)</span></label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  onChange={this.changeHandler}
                  type="text"
                  name="latitude"
                  value={this.state.latitude}
                // placeholder="Latitude"
                />
                {this.state.errors.latitude ? (
                  <div className="text-danger">
                    {this.state.errors.latitude}
                  </div>
                ) : (
                  this.validator.message(
                    "latitude",
                    this.state.latitude,
                    "max:50",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">Longitude <span className="optional">(Optional)</span></label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  onChange={this.changeHandler}
                  type="text"
                  name="longitude"
                  value={this.state.longitude}
                // placeholder="Longitude"
                />
                {this.state.errors.longitude ? (
                  <div className="text-danger">
                    {this.state.errors.longitude}
                  </div>
                ) : (
                  this.validator.message(
                    "longitude",
                    this.state.longitude,
                    "max:50",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Google Map Link <span className="optional">(Optional)</span>
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  onChange={this.changeHandler}
                  type="text"
                  name="google_map_link"
                  value={this.state.google_map_link}
                // placeholder="Google Map Link"
                />
                {this.state.errors.google_map_link ? (
                  <div className="text-danger">
                    {this.state.errors.google_map_link}
                  </div>
                ) : (
                  this.validator.message(
                    "google_map_link",
                    this.state.google_map_link,
                    "max:500",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-start pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Location Type <span className="optional">(Optional)</span>
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  isMulti
                  styles={customStyles}
                  menuPlacement="auto"
                  className="multiselect"
                  value={this.state.selectedLocationTypeList}
                  options={this.state.newLocationTypeList}
                  getOptionValue={option => `${option.label}`}
                  onChange={(value) => this.handleChangeLocationTypeList(value)}
                  defaultValue={this.state.selectedLocationTypeList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                  })}
                />
                {this.state.errors.location_type_id ? (
                  <div className="text-danger">
                    {this.state.errors.location_type_id}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Location;
