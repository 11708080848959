import React, { useState, useEffect, useRef } from "react";
import JoditEditor from "jodit-react";
import Alerts from "../../common/Alerts";
import YaleService from "../../../services/YaleService";
import SimpleReactValidator from "simple-react-validator";

const Instructions = ({ propertySlug }) => {
    const simpleValidator = useRef(new SimpleReactValidator());
    const [instruction, setInstruction] = useState("");
    const [isSubmit, setSubmit] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalType, setAlertModalType] = useState("");
    const [alertModalTitle, setAlertModalTitle] = useState("");
    const [alertModalMessage, setAlertModalMessage] = useState("");
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchInstruction = async () => {
            const res = await YaleService.getYaleInstruction(`property_slug=${propertySlug}`);
            if (global.successStatus.includes(res.status)) {
                setInstruction(res.data?.yale_description || "");
            }
        };
        fetchInstruction();
    }, [propertySlug]);

    const saveOrUpdateInstruction = async (event) => {
        event.preventDefault();
        setSubmit(true);

        if (simpleValidator.current.allValid()) {
            const inputData = { property_slug: propertySlug, instruction };
            const res = await YaleService.updateYaleInstruction(inputData);

            if (global.successStatus.includes(res.status)) {
                setSubmit(false);
                showAlert("success", "Success", res.message || "Success");
            }
        } else {
            setSubmit(false);
            simpleValidator.current.showMessages();
            setErrors(simpleValidator.current.errorMessages);
        }
    };

    const showAlert = (type, title, message) => {
        setAlertModalType(type);
        setAlertModalTitle(title);
        setAlertModalMessage(message);
        setShowAlertModal(true);
        setTimeout(() => setShowAlertModal(false), global.alert_close_time);
    };

    return (
        <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
            <div className="d-flex flex-wrap justify-content-end bothBtn swiperBtns">
                <button
                    className="btn-success-outline-small button-width"
                    type="button"
                    disabled={isSubmit}
                    onClick={saveOrUpdateInstruction}
                >
                    {isSubmit ? global.loader : "Save"}
                </button>
            </div>
            <div className="col-md-12 pd-lg-r-15 pd-lg-l-15 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                <Alerts
                    show={showAlertModal}
                    type={alertModalType}
                    title={alertModalTitle}
                    message={alertModalMessage}
                />
            </div>
            <div className="mg-t-18 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0 d-block">
                <div className="pd-l-0 mg-t-15">
                    <JoditEditor
                        value={instruction}
                        tabIndex={1}
                        onBlur={(newContent) => setInstruction(newContent)}
                        onChange={() => {}}
                    />
                    {simpleValidator.current.message("description", instruction, "required")}
                    {errors.description && <div className="text-danger">{errors.description}</div>}
                </div>
            </div>
        </div>
    );
};

export default Instructions;
