////////////////////////////////////////////////////////////
//     							                          //
//  Program: FloorPlanCost.jsx                                 //
//  Application: Property                                 //
//  Option: Floor Plan Cost                            //
//  Developer: Ashish Kumar						                  //
//  Date: 2022-04-06                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import PropertyCostService from "../../services/PropertyCostService";
import { Form, Table, Modal, InputGroup, Col } from "react-bootstrap";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import CostTypeService from "../../services/CostTypeService";
import CostPayableByService from "../../services/CostPayableByService";
import CostPayableAtService from "../../services/CostPayableAtService";
import Select from "react-select";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
import editIcon from "./../../assets/images/edit-pen-icon.svg";

export class FloorPlanCost extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      open: global.showOpen,
      eventKey: "",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      floor_plan_slug: this.props.floor_plan_slug,
      property_slug: this.props.property_slug,
      isSubmit: false,
      cost_name: "",
      estimated: false,
      mandatory: false,
      refundable: false,
      // sort_order: "",
      pathname: window.location.pathname,
      costTypeList: [],
      newCostTypeList: [],
      newSelectedCostTypeList: [],
      costPayableAtList: [],
      newCostPayableAtList: [],
      selectedCostPayableAtList: [],
      costPayableByList: [],
      newCostPayableByList: [],
      selectedCostPayableByList: [],
      selectedCostTypeList: [],
      alreadySelectedCostTypeList: [],
      costList: [],
      costListTotal: 0,
      cost_slug: "",
      confirm_back: 0,
      cost_data: [],
      FeeTypeDataTotal: 1,
      FeeTypeData: [
        {
          cost_type_slug: "",
          cost_payable_by_slug: "",
          cost_payable_at_slug: "",
          cost_name: "",
          refundable: 0,
          estimated: 0,
          mandatory: 0,
        },
      ],
      is_fee_error: [
        {
          cost_type_slug: "",
          cost_payable_by_slug: "",
          cost_payable_at_slug: "",
          cost_name: "",
          refundable: 0,
          estimated: 0,
          mandatory: 0,
        },
      ],
      parking_fee_for_guest: ""
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateCost = this.saveOrUpdateCost.bind(this);
    this.getCostDetails = this.getCostDetails.bind(this);
    this.changeView = this.changeView.bind(this);
    this.openDeleteFloorPlanCostModal =
      this.openDeleteFloorPlanCostModal.bind(this);
    this.closeDeleteFloorPlanCostModal =
      this.closeDeleteFloorPlanCostModal.bind(this);
    this.deleteCost = this.deleteCost.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getData();
    // this.getSTRData();
  }

  openDeleteFloorPlanCostModal(slug) {
    this.setState({ deleteFloorPlanCostModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteFloorPlanCostModal() {
    this.setState({ deleteFloorPlanCostModal: false });
    this.setState({ slug: "" });
  }

  async getData(queryString = "") {
    // this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    var FeeTypeDataTotal = 0;

    let res = await PropertyCostService.getAllCosts(
      this.state.floor_plan_slug + "/4"
    );
    // if (global.successStatus.includes(res.status)) {
    //   costList = res.data ? res.data : "";
    //   costListTotal = costList ? costList.length : 0;
    // let res = await PropertyCostService.getCost("property_slug=" + this.state.property_slug + (queryString ? "&" + queryString : ""));
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      totalRecords = list ? list.length : 0;

      const values = [];
      if (totalRecords > 0) {
        list.forEach((item, i) => {
          values.push({
            slug: item.slug ? item.slug : '',
            cost_type_slug: item.cost_type ? item.cost_type.slug : '',
            cost_payable_by_slug: item.cost_payable_by ? item.cost_payable_by.slug : '',
            cost_payable_at_slug: item.cost_payable_at ? item.cost_payable_at.slug : '',
            cost_name: item.cost_name ? parseFloat(item.cost_name)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "") : '',
            refundable: item.refundable ? item.refundable : 0,
            estimated: item.estimated ? item.estimated : 0,
            mandatory: item.mandatory ? item.mandatory : 0,
            selected_cost_type: {
              value: item.cost_type.slug,
              label: item.cost_type.cost_type_name,
            },
            selected_cost_by: {
              value: item.cost_payable_by.slug,
              label: item.cost_payable_by.cost_payable_by_name,
            },
            selected_cost_at: {
              value: item.cost_payable_at.slug,
              label: item.cost_payable_at.cost_payable_at_name,
            },
          });
        });
      } else {
        values.push({
          cost_type_slug: "",
          cost_payable_by_slug: "",
          cost_payable_at_slug: "",
          cost_name: "",
          refundable: 0,
          estimated: 0,
          mandatory: 0,
        },
        );
      }
      FeeTypeDataTotal = values.length;
      this.setState({
        FeeTypeData: values,
        FeeTypeDataTotal: FeeTypeDataTotal,
      });
    }
    this.setState({

      fees_total_record: totalRecords,
      fees_records: list
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getSTRData() {
    //  this.setState({ showSpinner: true, loading: true });
    var resData = {};

    let res = await PropertyCostService.getSTRFees("slug=" +
      this.state.floor_plan_slug + "&ref_table_id=4"
    );
    if (global.successStatus.includes(res.status)) {
      resData.parking_fee_for_guest = res.data ? res.data.fee_value : "";
    }
    this.setState(resData);
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getCostDetails(slug) {
    this.setState({ cost_slug: slug });
    this.changeView();
    //  this.setState({ showSpinner: true, loading: true });
    if (this.state.floor_plan_slug !== undefined) {
      var resData = {};
      let res = await PropertyCostService.getCostsBySlug(
        this.state.floor_plan_slug + "/4/" + slug
      );
      if (global.successStatus.includes(res.status)) {
        resData.cost_name = res.data.cost_name ? parseFloat(res.data.cost_name)
          .toFixed(2)
          .replace(/\.00$/, "")
          .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "") : "";
        resData.cost_type_slug = res.data.cost_type
          ? res.data.cost_type.slug
          : "";
        resData.cost_payable_at_slug = res.data.cost_payable_at
          ? res.data.cost_payable_at.slug
          : "";
        resData.cost_payable_by_slug = res.data.cost_payable_by
          ? res.data.cost_payable_by.slug
          : "";
        resData.estimated = res.data.estimated === 1 ? true : false;
        resData.mandatory = res.data.mandatory === 1 ? true : false;
        resData.refundable = res.data.refundable === 1 ? true : false;
        resData.status_id = res.data.status_id === 1 ? true : false;

        var newSelectedCostTypeList = [];
        newSelectedCostTypeList = res.data.cost_type
          ? [
            {
              value: res.data.cost_type ? res.data.cost_type.slug : "",
              label: res.data.cost_type
                ? res.data.cost_type.cost_type_name
                : "",
            },
          ]
          : "";

        var selectedCostPayableAtList = [];
        selectedCostPayableAtList = res.data.cost_payable_at
          ? [
            {
              value: res.data.cost_payable_at
                ? res.data.cost_payable_at.slug
                : "",
              label: res.data.cost_payable_at
                ? res.data.cost_payable_at.cost_payable_at_name
                : "",
            },
          ]
          : "";

        var selectedCostPayableByList = [];
        selectedCostPayableByList = res.data.cost_payable_by
          ? [
            {
              value: res.data.cost_payable_by
                ? res.data.cost_payable_by.slug
                : "",
              label: res.data.cost_payable_by
                ? res.data.cost_payable_by.cost_payable_by_name
                : "",
            },
          ]
          : "";
      }
      this.setState(resData);
      this.setState({
        newSelectedCostTypeList: newSelectedCostTypeList,
        selectedCostPayableAtList: selectedCostPayableAtList,
        selectedCostPayableByList: selectedCostPayableByList,
      });
    }
    //  this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Type list data */
  async getCostTypeData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var costTypeList = [];

    let res = await CostTypeService.getCostType(
      "is_dropdown=1&cost_type=2",                   //2 for floor plan
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costTypeList = res.data ? res.data : [];
    }
    const newCostTypeList = costTypeList.map(({ slug, cost_type_name }) => ({
      value: slug,
      label: cost_type_name,
    }));
    this.setState({
      costTypeList: costTypeList,
      newCostTypeList: newCostTypeList,
    });
    //   this.setState({ showSpinner: false, loading: false });
  }

  async getSelectedCostType() {
    //   this.setState({ showSpinner: true, loading: true });
    var selectedCostTypeList = [];

    let res = await CostTypeService.getSelectedCostType(
      this.state.floor_plan_slug + "/4"
    );

    if (global.successStatus.includes(res.status)) {
      selectedCostTypeList = res.data ? res.data : [];
    }

    this.setState({
      selectedCostTypeList: selectedCostTypeList,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Payable At list data */
  async getCostPayableAtData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var costPayableAtList = [];

    let res = await CostPayableAtService.getCostPayableAt(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costPayableAtList = res.data ? res.data : [];
    }
    const newCostPayableAtList = costPayableAtList.map(
      ({ slug, cost_payable_at_name }) => ({
        value: slug,
        label: cost_payable_at_name,
      })
    );
    this.setState({
      costPayableAtList: costPayableAtList,
      newCostPayableAtList: newCostPayableAtList,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Payable By list data */
  async getCostPayableByData(queryString = "") {
    // this.setState({ showSpinner: true, loading: true });
    var costPayableByList = [];

    let res = await CostPayableByService.getCostPayableBy(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costPayableByList = res.data ? res.data : [];
    }
    const newCostPayableByList = costPayableByList.map(
      ({ slug, cost_payable_by_name }) => ({
        value: slug,
        label: cost_payable_by_name,
      })
    );
    this.setState({
      costPayableByList: costPayableByList,
      newCostPayableByList: newCostPayableByList,
    });
    // this.setState({ showSpinner: false, loading: false });
  }

  async deleteCost(slug) {
    if (slug) {
      this.setState({ showSpinner: true });
      let res = await PropertyCostService.deleteCost(slug);
      if (global.successStatus.includes(res.status)) {
        this.closeDeleteFloorPlanCostModal();
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({ viewMode: 0, cost_slug: "" });
        this.setState({
          selectedCostPayableAtList: [],
          selectedCostPayableByList: [],
          selectedCostTypeList: []
        });
        this.getData();
      } else {
        this.closeDeleteFloorPlanCostModal();
        let alertMessage = "";
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      this.setState({ showSpinner: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  }

  saveOrUpdateCost = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        level: 4,
        slug: this.state.floor_plan_slug,
        cost_data: this.state.FeeTypeData
      };
      if (this.state.floor_plan_slug !== undefined) {
        let res = await PropertyCostService.updateCosts(
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.changeView();
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }

      // if(this.state.floor_plan_slug !== undefined){
      //   let strFeeData = {
      //     slug : this.state.floor_plan_slug,
      //     ref_table_id: 4,    //floor plan table
      //     fee_type: 2,      //parking fee for property
      //     fee_value: this.state.parking_fee_for_guest ? this.state.parking_fee_for_guest: 0,    //pet fee per day
      //   };
      //   let res = await PropertyCostService.updateStrFees(
      //     strFeeData
      //   );
      //   if (global.successStatus.includes(res.status)) {
      //     this.setState({
      //       showAlertModal: true,
      //       alertModalType: "success",
      //       alertModalTitle: "Success",
      //       alertModalMessage: res.message ? res.message : "Success",
      //     });
      //     this.changeView();
      //   } else {
      //     let alertMessage = "";
      //     if (
      //       res.data.errorCode === "validationFailed" &&
      //       res.data.errorDetail !== null
      //     ) {
      //       let errors = {};
      //       res.data.errorCode = "Validation Failed";
      //       res.data.errorDetail.forEach((item) => {
      //         errors[item.errorField] = item.errorMessage[0];
      //         // alertMessage += item.errorMessage[0]+'\n';
      //         res.data.errorMessage = "";
      //       });
      //       this.setState({ errors: errors });
      //     }
      //     this.setState({
      //       showAlertModal: true,
      //       alertModalType: "error",
      //       alertModalTitle: res.data.message ? res.data.message : "Error!",
      //       alertModalMessage:
      //         alertMessage !== "" ? alertMessage : res.data.message,
      //     });
      //   }
      // }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  // handleChangeSelectedCostTypeList(value) {
  //   let cost_type_slug = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       cost_type_slug.push(item.value);
  //     });
  //   }
  //   const cost_type_slug_value = value !== null ? value.value : [];
  //   this.setState({
  //     newSelectedCostTypeList: value,
  //     cost_type_slug: cost_type_slug_value,
  //   });

  //   this.setState({ confirm_back: 1 });
  // }

  // handleChangeCostPayableByList(value) {
  //   let cost_payable_by_slug = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       cost_payable_by_slug.push(item.value);
  //     });
  //   }
  //   const cost_payable_by_slug_value = value !== null ? value.value : [];
  //   this.setState({
  //     selectedCostPayableByList: value,
  //     cost_payable_by_slug: cost_payable_by_slug_value,
  //   });

  //   this.setState({ confirm_back: 1 });
  // }

  // handleChangeCostPayableAtList(value) {
  //   let cost_payable_at_slug = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       cost_payable_at_slug.push(item.value);
  //     });
  //   }
  //   const cost_payable_at_slug_value = value !== null ? value.value : [];
  //   this.setState({
  //     selectedCostPayableAtList: value,
  //     cost_payable_at_slug: cost_payable_at_slug_value,
  //   });

  //   this.setState({ confirm_back: 1 });
  // }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.getData();
      // this.getSTRData();
      this.setState({
        cost_slug: "",
        newSelectedCostTypeList: [],
        selectedCostPayableAtList: [],
        selectedCostPayableByList: [],
      });
      this.setState({
        cost_name: "",
        cost_type_slug: "",
        cost_payable_at_slug: "",
        cost_payable_by_slug: "",
        refundable: "",
        estimated: "",
        mandatory: "",
        errors: "",
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();

    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.getData();
      // this.getSTRData();
      this.setState({
        cost_name: "",
        cost_type_slug: "",
        cost_payable_at_slug: "",
        cost_payable_by_slug: "",
        refundable: "",
        estimated: "",
        mandatory: "",
        errors: "",
        newSelectedCostTypeList: [],
        selectedCostPayableAtList: [],
        selectedCostPayableByList: [],
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
      if (this.state.cost_slug === "") {
        this.getCostTypeData();
        // this.getSelectedCostType();
        this.getCostPayableAtData();
        this.getCostPayableByData();
      } else {
        this.getCostDetails(this.state.cost_slug);
      }
    }
  }

  getTitle() {
    return "Fees";
  }

  handleAddFieldsCost = () => {
    const values = [...this.state.FeeTypeData];
    var FeeTypeDataTotal = 1;
    values.push({
      cost_type_slug: "",
      cost_payable_by_slug: "",
      cost_payable_at_slug: "",
      cost_name: "",
      refundable: 0,
      estimated: 0,
      mandatory: 0,
    });
    FeeTypeDataTotal = values.length;

    this.setState({
      FeeTypeData: values,
      FeeTypeDataTotal: FeeTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleRemoveFieldsCost = (i) => {
    const values = [...this.state.FeeTypeData];
    values.splice(i, 1);
    var FeeTypeDataTotal = 0;
    FeeTypeDataTotal = values.length;
    this.setState({ FeeTypeData: values, cost_data: values });
    this.setState({
      FeeTypeDataTotal: FeeTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectOne = (index, event) => {
    const values = [...this.state.FeeTypeData];

    values[index].cost_type_slug = event.value;
    values[index].selected_cost_type = {
      label: event.label,
      value: event.value
    };

    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectTwo = (index, event) => {
    const values = [...this.state.FeeTypeData];
    values[index].cost_payable_at_slug = event.value;
    values[index].selected_cost_at = {
      label: event.label,
      value: event.value
    };

    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectThree = (index, event) => {
    const values = [...this.state.FeeTypeData];
    values[index].cost_payable_by_slug = event.value;
    values[index].selected_cost_by = {
      label: event.label,
      value: event.value
    };

    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeCost = (index, event) => {
    const values = [...this.state.FeeTypeData];

    if (event.target.name === "cost_name") {
      values[index].cost_name = (event.target.value).replace(/\.00$/, '').replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "");
    }
    if (event.target.name === "refundable") {
      values[index].refundable = event.target.checked ? 1 : 0;
    }
    if (event.target.name === "estimated") {
      values[index].estimated = event.target.checked ? 1 : 0;
    }
    if (event.target.name === "mandatory") {
      values[index].mandatory = event.target.checked ? 1 : 0;
    }
    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pl-4 pd-r-15 swipersectionBtns propfees floorPlan">
          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
            {this.state.viewMode === 0 && global.userPermissions.checkPermission('floor-plans-update') ? (
              <button
                className="btn-success-outline-small mr-3"
                onClick={this.changeView}
              >
                <img src={editIcon} alt="edit" /> Edit
              </button>
            ) : (
              <>
                {this.state.cost_slug !== "" ? (
                  <>
                    {/* <button
                      type="button"
                      onClick={() => {
                        this.openDeleteFloorPlanCostModal(this.state.cost_slug);
                      }}
                      className="btn-danger-outline mg-r-20"
                    >
                      <img src={deleteicon} alt="Delete" />
                      Delete
                    </button>{" "} */}
                    <span
                      onClick={() => {
                        this.openDeleteFloorPlanCostModal(this.state.slug);
                      }}
                      className="deleteicon mg-r-20"
                    >
                      <img src={deleteicon} />
                    </span>
                  </>
                ) : (
                  ""
                )}
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn-success-outline-small mr-3"
                    onClick={() => {
                      if (this.state.confirm_back === 1)
                        if (
                          window.confirm("Do you want to exit without saving?")
                        )
                          this.changeView();
                        else return false;
                      else this.changeView();
                    }}
                  >
                    Cancel
                  </button>
                  {this.state.cost_slug === "" && global.userPermissions.checkPermission('floor-plans-update') ? (
                    <button
                      className="btn-success mr-3"
                      onClick={this.saveOrUpdateCost}
                    >
                      Save
                    </button>
                  ) : (
                    global.userPermissions.checkPermission('floor-plans-update') ?
                    <button
                      className="btn-success mr-3"
                      onClick={this.saveOrUpdateCost}
                    >
                      Save
                    </button>
                  :''
                  )}
                </div>
              </>
            )}
          </div>
          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          {this.state.viewMode === 0 ? (
            <div className="row pd-l-30 pd-r-30">
              <div className="table-responsive fplan pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-20">Fee Type</th>
                      <th className="w-20">Payable By</th>
                      <th className="w-20">Payable At</th>
                      <th className="w-20">Fee</th>
                      <th className="w-20">Refundable</th>
                      <th className="w-20">Estimated</th>
                      <th className="w-20 text-left">Mandatory</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fees_total_record > 0 ?
                      this.state.fees_records.map((option, i) => (
                        <tr key={i}>
                          <td>
                            {option.cost_type
                              ? option.cost_type.cost_type_name
                              : ""}
                          </td>
                          <td>
                            {option.cost_payable_by
                              ? option.cost_payable_by.cost_payable_by_name
                              : ""}
                          </td>
                          <td>
                            {option.cost_payable_at
                              ? option.cost_payable_at.cost_payable_at_name
                              : ""}
                          </td>
                          <td>{option.cost_name ? "$" + global.onKeyPressEvent.numberWithCommasForDecimal(parseFloat(option.cost_name)
                            .toFixed(2).replace(/\.00$/, '').replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")) : ""}</td>
                          <td>
                            {option.refundable === 1 ? "Yes" : "No"}
                          </td>
                          <td>
                            {option.estimated === 1 ? "Yes" : "No"}
                          </td>
                          <td className="text-left">
                            {option.mandatory === 1 ? "Yes" : "No"}
                          </td>
                        </tr>
                      ))
                      :
                      <tr>
                        <td colSpan="9" align="center">
                          There are no fees added in the system. You may add them by clicking Edit button.
                        </td>
                      </tr>}
                  </tbody>
                </Table>
              </div>
              {/* <div className="row col-md-12 mg-t-40 mg-md-t-0">
                <div className="col-md-12 pd-l-30 pd-r-30 pl-0">
                  <p className="az-profile-name-text pl-0">
                    Parking Fee For Guest
                  </p>
                  <p className="media-body">
                    {this.state.parking_fee_for_guest ? "$"+this.state.parking_fee_for_guest : ""}
                  </p>
                </div>
              </div> */}
            </div>
          ) : (
            ""
          )}

          {this.state.viewMode === 1 ? (
            // <div className="m-0">
            //   <div className="row align-items-center pd-t-30 pd-b-30 border-bottom-0">
            //     <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
            //       <label className="form-label text-left pd-b-5">
            //         Fee Type
            //       </label>

            //       <Select
            //         styles={customStyles}
            //         isClearable={true}
            //         className="multiselect"
            //         menuPlacement="auto"
            //         value={this.state.newSelectedCostTypeList}
            //         options={
            //           this.state.newCostTypeList
            //             ? this.state.newCostTypeList.filter((option) => {
            //                 const val1 =
            //                   this.state.alreadySelectedCostTypeList.find(
            //                     (opt) => {
            //                       return opt === option.slug;
            //                     }
            //                   );
            //                 if (val1) return false;
            //                 else return true;
            //               })
            //             : ""
            //         }
            //         getOptionValue={option => `${option.label}`}
            //         onChange={(value) =>
            //           this.handleChangeSelectedCostTypeList(value)
            //         }
            //         defaultValue={this.state.newSelectedCostTypeList}
            //         isDisabled={this.state.cost_slug !== "" ? true : false}
            //         theme={(theme) => ({
            //           ...theme,
            //           borderRadius: 2,
            //           colors: {
            //             ...theme.colors,
            //             primary: "#fff",
            //             primary75: "#000",
            //             primary50: "#000",
            //             primary25: "#000",
            //           },
            //         })}
            //       />

            //       {this.state.errors.cost_type_slug ? (
            //         <div className="text-danger">
            //           {this.state.errors.cost_type_slug}
            //         </div>
            //       ) : (
            //         this.validator.message(
            //           "fee_type",
            //           this.state.cost_type_slug,
            //           "required",
            //           { className: "text-danger" }
            //         )
            //       )}
            //     </div>

            //     <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
            //       <label className="form-label text-left pd-b-5">
            //         Amount ($){" "}
            //       </label>

            //       <Form.Control
            //         onChange={this.changeHandler}
            //         name="cost_name"
            //         value={this.state.cost_name}
            //         className="form-control max_width_100"
            //         type="text"
            //         onKeyPress={global.onKeyPressEvent.floatValidation}
            //       />
            //       {this.state.errors.cost_name ? (
            //         <div className="text-danger">
            //           {this.state.errors.cost_name}
            //         </div>
            //       ) : (
            //         this.validator.message(
            //           "amount",
            //           this.state.cost_name,
            //           "required",
            //           { className: "text-danger" }
            //         )
            //       )}
            //     </div>

            //     <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
            //       <label className="form-label text-left pd-b-5">
            //         Payable At{" "}
            //       </label>

            //       <Select
            //         styles={customStyles}
            //         isClearable={true}
            //         className="multiselect"
            //         menuPlacement="auto"
            //         value={this.state.selectedCostPayableAtList}
            //         options={this.state.newCostPayableAtList}
            //         getOptionValue={option => `${option.label}`}
            //         onChange={(value) =>
            //           this.handleChangeCostPayableAtList(value)
            //         }
            //         defaultValue={this.state.selectedCostPayableAtList}
            //         theme={(theme) => ({
            //           ...theme,
            //           borderRadius: 2,
            //           colors: {
            //             ...theme.colors,
            //             primary: "#fff",
            //             primary75: "#000",
            //             primary50: "#000",
            //             primary25: "#000",
            //           },
            //         })}
            //       />

            //       {this.state.errors.cost_payable_at_slug ? (
            //         <div className="text-danger">
            //           {this.state.errors.cost_payable_at_slug}
            //         </div>
            //       ) : (
            //         this.validator.message(
            //           "payable_at",
            //           this.state.cost_payable_at_slug,
            //           "required",
            //           { className: "text-danger" }
            //         )
            //       )}
            //     </div>

            //     <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
            //       <label className="form-label text-left pd-b-5">
            //          Payable By{" "}
            //       </label>

            //       <Select
            //         styles={customStyles}
            //         isClearable={true}
            //         className="multiselect"
            //         menuPlacement="auto"
            //         value={this.state.selectedCostPayableByList}
            //         options={this.state.newCostPayableByList}
            //         getOptionValue={option => `${option.label}`}
            //         onChange={(value) =>
            //           this.handleChangeCostPayableByList(value)
            //         }
            //         defaultValue={this.state.selectedCostPayableByList}
            //         theme={(theme) => ({
            //           ...theme,
            //           borderRadius: 2,
            //           colors: {
            //             ...theme.colors,
            //             primary: "#fff",
            //             primary75: "#000",
            //             primary50: "#000",
            //             primary25: "#000",
            //           },
            //         })}
            //       />

            //       {this.state.errors.cost_payable_by_slug ? (
            //         <div className="text-danger">
            //           {this.state.errors.cost_payable_by_slug}
            //         </div>
            //       ) : (
            //         this.validator.message(
            //           "payable_by",
            //           this.state.cost_payable_by_slug,
            //           "required",
            //           { className: "text-danger" }
            //         )
            //       )}
            //     </div>
            //   </div>
            //   <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
            //     <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
            //   </div>

            //   <div className="row align-items-center pd-t-10 pd-b-40 pd-l-30 pd-r-30 border-bottom-0">
            //     <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0">
            //       <ul className="formcheck-section">
            //         <li>
            //           <label className="form-label text-left mg-b-0">
            //             Refundable
            //           </label>
            //           <Form.Check
            //             name="refundable"
            //             type={"checkbox"}
            //             id={`refundable`}
            //             label={""}
            //             className={"ckbox"}
            //             onChange={this.changeHandler}
            //             checked={this.state.refundable}
            //           />
            //         </li>
            //         <li>
            //           <label className="form-label text-left mg-b-0  pd-l-0">
            //             Estimated
            //           </label>
            //           <Form.Check
            //             name="estimated"
            //             type={"checkbox"}
            //             id={`estimated`}
            //             label={""}
            //             className={"ckbox"}
            //             onChange={this.changeHandler}
            //             checked={this.state.estimated}
            //           />
            //         </li>
            //         <li>
            //           <label className="form-label text-left mg-b-0">
            //             Mandatory
            //           </label>
            //           <Form.Check
            //             name="mandatory"
            //             type={"checkbox"}
            //             id={`mandatory`}
            //             label={""}
            //             className={"ckbox"}
            //             onChange={this.changeHandler}
            //             checked={this.state.mandatory}
            //           />
            //         </li>
            //       </ul>
            //     </div>
            //   </div>

            //   <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
            //     <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
            //   </div>

            //   <div className="row align-items-center pd-t-30 pd-b-30 pd-l-30 pd-r-30 border-bottom-0">
            //     <div className="col-md-1 pl-0">
            //       <label className="form-label text-left pd-b-5">Status</label>
            //     </div>

            //     <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
            //       <b
            //         className={this.state.status_id ? "active" : "inactive"}
            //       ></b>
            //       <div
            //         className={
            //           this.state.status_id ? "az-toggle on" : "az-toggle"
            //         }
            //         onClick={() =>
            //           this.setState({
            //             status_id: !this.state.status_id,
            //           })
            //         }
            //       >
            //         <span></span>
            //       </div>
            //     </div>
            //   </div>
            // </div>
            <div className="pd-lg-l-15 pd-lg-r-30 mg-xs-l-15 pet-f mg-b-30">


              <div className="col-md-12 p-0 FloorFeeSection">
                <table className="petForm">
                  <thead>
                    <tr>
                      <th className="w-1"><label className="mb-1">Fee Type</label></th>
                      <th className="w-1"><label className="mb-1">Payable By</label></th>
                      <th className="w-1"><label className="mb-1">Payable At</label></th>
                      <th className="W-2"><label className="mb-1">Fee</label></th>
                      <th className="w-3"><label className="mb-1">Refundable</label></th>
                      <th className="w-3"><label className="mb-1">Estimated</label></th>
                      <th className="w-3"><label className="mb-1">Mandatory</label></th>
                      <th className="w-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.FeeTypeData.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td className="w-1">
                            <Col xs={12} className="pd-l-0 pd-r-2 colmn1">
                              <InputGroup className="mt-0 m-b-1">
                                {/* <label>Pet Type</label> */}
                                <div className="col-md-12 p-0">
                                  <Select
                                    styles={customStyles}
                                    className="multiselect"
                                    menuPlacement="auto"
                                    onChange={(value) =>
                                      this.handleChangeSelectOne(i, value)
                                    }
                                    options={this.state.newCostTypeList}
                                    // options={this.state.newCostTypeList
                                    // .filter((option) => {

                                    //     const val2 = this.state
                                    //     .selectedCostTypeList
                                    //     ? this.state.selectedCostTypeList.find(
                                    //         (selOpt) => {
                                    //             if (
                                    //             selOpt ===
                                    //             data.cost_type_slug
                                    //             )
                                    //             return false;
                                    //             return (
                                    //               selOpt === option.value
                                    //             );
                                    //           }
                                    //         )
                                    //     : "";
                                    //     if (val2) return false;
                                    //     else return true;
                                    // })}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: '#fff',
                                        primary75: '#000',
                                        primary50: '#000',
                                        primary25: '#000',
                                      }
                                    })}

                                    value={data.selected_cost_type}
                                    defaultValue={data.selected_cost_type}
                                  // isDisabled={data.slug ? true : false}
                                  />
                                </div>

                              </InputGroup>
                            </Col>
                          </td>
                          <td className="w-1">
                            <Col xs={12} className="pd-l-2 pd-r-2 colmn1">
                              <Select
                                styles={customStyles}
                                menuPlacement="auto"
                                className="multiselect"
                                name="cost_payable_by_slug"
                                value={data.selected_cost_by}
                                options={this.state.newCostPayableByList}
                                defaultValue={data.selected_cost_by}
                                onChange={(value) => this.handleChangeSelectThree(i, value)}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: '#fff',
                                    primary75: '#000',
                                    primary50: '#000',
                                    primary25: '#000',
                                  }
                                })}
                              />
                            </Col>
                          </td>
                          <td className="w-1">
                            <Col xs={12} className="pd-l-2 pd-r-2 colmn1">
                              <Select
                                styles={customStyles}
                                menuPlacement="auto"
                                className="multiselect"
                                name="cost_payable_at_slug"
                                value={data.selected_cost_at}
                                options={this.state.newCostPayableAtList}
                                defaultValue={data.selected_cost_at}
                                onChange={(value) => this.handleChangeSelectTwo(i, value)}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: '#fff',
                                    primary75: '#000',
                                    primary50: '#000',
                                    primary25: '#000',
                                  }
                                })}
                              />
                            </Col>
                          </td>
                          <td className="w-2">
                            <Col xs={12} className="pd-l-2 pd-r-2 colmn3">
                              <InputGroup className="mt-0 m-b-1">
                                <div className="input-group-prepend prefix-dollar w-100"><span className="input-group-text">$</span>
                                  <Form.Control

                                    name="cost_name"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={data.cost_name ? data.cost_name.replace(/\.00$/, '').replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "") || "" : ""}
                                    onChange={(event) =>
                                      this.handleChangeCost(i, event)
                                    }
                                  />
                                </div>
                              </InputGroup>
                            </Col>
                          </td>
                          <td className="w-3">
                            <Col xs={12} className="pd-l-2 pd-r-2 pd-t-2 align-items-center colmn2">
                              <div className="ckbox form-check">
                                <input
                                  type="checkbox"
                                  id={`refundable_` + i}
                                  name="refundable"
                                  className="form-check-input ml-0 w-auto"
                                  onChange={(event) => this.handleChangeCost(i, event)}
                                  checked={data.refundable}
                                />
                                <label htmlFor={`refundable_` + i} className="form-check-label"></label>
                              </div>
                            </Col>
                          </td>
                          <td className="w-3">
                            <Col xs={12} className="pd-l-2 pd-r-2 pd-t-2 colmn2">
                              <div className="ckbox form-check">
                                <input
                                  type="checkbox"
                                  id={`estimated_` + i}
                                  name="estimated"
                                  className="form-check-input ml-0 w-auto"
                                  onChange={(event) => this.handleChangeCost(i, event)}
                                  checked={data.estimated}
                                />
                                <label htmlFor={`estimated_` + i} className="form-check-label"></label>
                              </div>
                            </Col>
                          </td>
                          <td className="w-3">
                            <Col xs={12} className="pd-l-2 pd-r-2 pd-t-2 colmn2">
                              <div className="ckbox form-check">
                                <input
                                  type="checkbox"
                                  id={`mandatory_` + i}
                                  name="mandatory"
                                  className="form-check-input ml-0 w-auto"
                                  onChange={(event) => this.handleChangeCost(i, event)}
                                  checked={data.mandatory}
                                />
                                <label htmlFor={`mandatory_` + i} className="form-check-label"></label>
                              </div>
                            </Col>
                          </td>
                          <td className="w-3">
                            <Col xs={12} className="pd-l-2 pd-r-0 d-flex align-items-center add-delete">
                              {this.state.FeeTypeDataTotal === 1 &&
                                i === 0 ? (
                                <>
                                  {data.slug ? (
                                    <div className="d-flex  justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                      <button
                                        onClick={() => {
                                          this.handleRemoveFieldsCost(i);

                                          this.deleteCost(
                                            data.slug
                                          );
                                        }}
                                        className={
                                          "btn-danger-outline-x-small"
                                        }
                                      >
                                        <img src={deleteicon} alt="Delete" />
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="d-flex justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                    <button
                                      onClick={this.handleAddFieldsCost}
                                      className="btn-success-outline-x-small"
                                    >
                                      <img src={pluswIcon} alt="Add" />
                                    </button>
                                  </div>
                                </>
                              ) : i <
                                this.state.FeeTypeDataTotal - 1 ? (
                                <>
                                  <div className="d-flex  justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                    <button
                                      onClick={() => {
                                        this.handleRemoveFieldsCost(i);

                                        this.deleteCost(
                                          data.slug
                                        );
                                      }}
                                      className={"btn-danger-outline-x-small"}
                                    >
                                      <img src={deleteicon} alt="Delete" />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {this.state.FeeTypeDataTotal !== 0 ? (
                                    <>
                                      <div className="d-flex  justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                        <button
                                          onClick={() => {
                                            this.handleRemoveFieldsCost(i);

                                            this.deleteCost(
                                              data.slug
                                            );
                                          }}
                                          className={
                                            "btn-danger-outline-x-small"
                                          }
                                        >
                                          <img
                                            src={deleteicon}
                                            alt="Delete"
                                          />
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                    <button
                                      onClick={this.handleAddFieldsCost}
                                      className="btn-success-outline-x-small"
                                    >
                                      <img src={pluswIcon} alt="Add" />
                                    </button>
                                  </div>
                                </>
                              )}
                            </Col>
                          </td>

                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {/* <Row className="mt-3">
                                  <Col xs={2} className="pd-l-0 pd-r-2 colmn1">
                                    <label className="mb-1">Fee Type</label>
                                  </Col>
                                  <Col xs={2} className="pd-l-2 pd-r-2 colmn1">
                                    <label className="mb-1">Payable By</label>
                                  </Col>
                                  <Col xs={2} className="pd-l-2 pd-r-2 colmn1">
                                    {" "}
                                    <label className="mb-1">Payable At</label>
                                  </Col>
                                  <Col xs={1} className="pd-l-2 pd-r-2 colmn3">
                                    <label className="mb-1">Fee</label>
                                  </Col>
                                  <Col xs={1} className="pd-l-2 pd-r-2 pt-0 colmn2">
                                    <label className="mb-1">Refundable</label>
                                  </Col>
                                  <Col xs={1} className="pd-l-2 pd-r-2 pt-0 colmn2">
                                    <label className="mb-1">Estimated</label>
                                  </Col>
                                  <Col xs={1} className="pd-l-2 pd-r-2 pt-0 colmn2">
                                    <label className="mb-1">Mandatory</label>
                                  </Col>
                                </Row> */}
              </div>

            </div>
          ) : (
            ""
          )}
        </div>

        <Modal show={this.state.deleteFloorPlanCostModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteFloorPlanCostModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteFloorPlanCostModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCost(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default FloorPlanCost;
