////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ReservationForm.jsx                                //
//  Application:  Reservation                                   //
//  Option: For add or update Reservation                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-04-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";

import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
//
// import { Modal} from "react-bootstrap";
import editIcon from "./../../../assets/images/edit-pen-icon.svg";
import Alerts from "../../common/Alerts";
import modaldeleteicon from "./../../../assets/images/delete.svg";
import Moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import UnitsService from "../../../services/UnitsService";
import ListingService from "../../../services/ListingService";
import KeycafeService from "../../../services/KeycafeService";
import LatchService from "../../../services/LatchService";
import deleteicon from "../../../assets/images/delete.svg";
export class ActivityViewLatch extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      open: true,
      // eventKey: "3",
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      lisitngList: [],
      newListingList: [],
      selectedListingList: [],
      status_id: [],
      set_channel: "",
      pathname: window.location.pathname,
      importModal: false,
      confirm_back: 0,
      activeClass: "basic",
      unit_slug: [],
      start_date: "",
      checkout_date: "",
      guest_slug: [],
      guestList: [],
      newGuestList: [],
      selectedGuestList: [],
      adults: "",
      children: "",
      infants: "",
      pets: "",
      nightly_rate: "",
      total_stay_nights: 0,
      guest_paid: "",
      host_payout: "",
      pet_fee: "",
      security_deposite: "",
      isGuestExist: 0,
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      end_date: "",
      email: "",
      email_slug: "",
      start_time: "00:00AM",
      end_time: "00:00AM",
      isLoaded: false,
      unit_name: "",
      access_start_date: "",
      access_end_date: "",
      access_code: "",
      key_name: "",
      keycafe_key_id: "",
      fob: "",
      acess_end_time: "",
      access_start_time: "",
      keycafe_access_id: "",
      key_type: "",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandler1 = this.changeHandler1.bind(this);
    this.opendeleteKeyModal = this.opendeleteKeyModal.bind(this);
    this.closedeleteKeyModal = this.closedeleteKeyModal.bind(this);
  }

  opendeleteKeyModal(slug) {
    this.setState({ deleteKeycafeModal: true, slug: slug });
  }

  closedeleteKeyModal() {
    this.setState({ deleteKeycafeModal: false, slug: "" });
  }

  async deleteKeyModal(slug) {
    this.setState({ showSpinner: true });
    let res = await LatchService.createlatchmanualaccess(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteKeyModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.props.match.params.propertySlug !== undefined
              ? "/properties/view/" + this.state.property_slug + "/latches"
              : "/latches"
          ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteKeyModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  async getData(tab, queryString = "") {
    if (this.state.slug !== undefined) {
      var resData = {};
      var totalRecords = 0;
      let res = await KeycafeService.getKeycafeAccessdetail(
        "property_slug=" +
          this.state.property_slug +
          "&key_slug=" +
          this.state.slug +
          "&tab=" +
          (tab ? tab : this.state.tab) +
          (queryString ? "&" + queryString : "")
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data.data[0] : {};
        totalRecords = res.data ? res.data.total : 0;
        resData.unit_name = resData.unit_name ? resData.unit_name : "";
        resData.access_start_date = resData.access_start_date
          ? resData.access_start_date
          : "";
        resData.access_end_date = resData.access_end_date
          ? resData.access_end_date
          : "";
        resData.email = resData.email ? resData.email : "";
        resData.access_code = resData.access_code ? resData.access_code : "";
        resData.key_name = resData.key_name ? resData.key_name : "";
        resData.keycafe_key_id = resData.keycafe_key_id
          ? resData.keycafe_key_id
          : "";
        resData.fob = resData.fob ? resData.fob : "";
        resData.access_start_time = resData.access_start_time
          ? resData.access_start_time
          : "";
        resData.acess_end_time = resData.acess_end_time
          ? resData.acess_end_time
          : "";
        resData.keycafe_access_id = resData.keycafe_access_id
          ? resData.keycafe_access_id
          : "";
        resData.key_type = resData.key_type ? resData.key_type : "";
        resData.current_key_status = resData.current_key_status
          ? resData.current_key_status
          : "";
        resData.ref_table_id = resData.ref_table_id ? resData.ref_table_id : "";
        resData.ref_id = resData.ref_id ? resData.ref_id : "";
      }
      this.setState({
        total_record: totalRecords,
        records: resData,
        isLoaded: true,
        unit_name: resData.unit_name ? resData.unit_name : "",
        access_start_date: resData.access_start_date
          ? resData.access_start_date
          : "",
        access_end_date: resData.access_end_date ? resData.access_end_date : "",
        email: resData.email ? resData.email : "",
        access_code: resData.access_code ? resData.access_code : "",
        key_name: resData.key_name ? resData.key_name : "",
        keycafe_key_id: resData.keycafe_key_id ? resData.keycafe_key_id : "",
        fob: resData.fob ? resData.fob : "",
        access_start_time: resData.access_start_time
          ? resData.access_start_time
          : "",
        acess_end_time: resData.acess_end_time ? resData.acess_end_time : "",
        keycafe_access_id: resData.keycafe_access_id
          ? resData.keycafe_access_id
          : "",
        key_type: resData.key_type ? resData.key_type : "",
        current_key_status: resData.current_key_status
          ? resData.current_key_status
          : "",
        ref_table_id: resData.ref_table_id ? resData.ref_table_id : "",
        ref_id: resData.ref_id ? resData.ref_id : "",
      });
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;

    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  changeHandler1 = (event) => {
    let input = this.state.input;
    this.setState({ email: input.email });
    this.getEmailData(this.state.email);
  };

  async componentDidMount() {
    this.getEmailData();
    this.getUnitData();
    this.getData();

    this.getListingData();
  }

  /* to get slug  on behalf email ID */
  async getEmailData(email) {
    var emailList = [];
    var unitListTotal = 0;
    let res = await KeycafeService.UserEmaildetail("email=" + email);

    if (global.successStatus.includes(res.status)) {
      if (res.data) {
        emailList = res.data ? res.data : [];
        unitListTotal = emailList ? emailList.length : 0;
        this.setState({
          email_slug: emailList.slug,
          unitListTotal: unitListTotal,
        });
        this.setState(emailList)
      }
    }
  }

  userData = async () => {
    if (this.validator.allValid()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        access_start_date: this.state.start_date
          ? Moment(this.state.start_date).format("YYYY-MM-DD")
          : "",
        access_end_date: this.state.end_date
          ? Moment(this.state.end_date).format("YYYY-MM-DD")
          : "",
        accessStartTime: this.state.start_time,
        accessEndTime: this.state.end_time,
        property_slug: this.state.property_slug,
        email: this.state.email ? this.state.email : "",
        unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
        // convertedTime : Moment(this.state.state.end_time, 'hh:mm A').format('HH:mm'),
        user_slug: this.state.email_slug ? this.state.email_slug : 0,
        key_name: this.state.key_name ? this.state.key_name : "",
        from_where: 4,
      };
      if (this.state.slug !== undefined) {
        let res = await KeycafeService.getkeycafeaccess(
          this.state.slug,
          inputData
        );

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () => this.props.history.push("keys"),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      } else {
        let res = await KeycafeService.getkeycafeaccess(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () => this.props.history.push("keys"),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;
    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get listing data list */
  async getListingData(queryString = "") {
    var listingList = [];
    var listingListTotal = 0;

    let res = await ListingService.getListingList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      listingList = res.data ? res.data : [];
      listingListTotal = listingList ? listingList.length : 0;
    }
    const newListingList =
      listingListTotal > 0
        ? listingList.map(({ slug, listing_name, unit }) => ({
            value: slug,
            label: unit
              ? listing_name + (unit ? " (" + unit.unit_number + ") " : "")
              : listing_name,
          }))
        : [];
    this.setState({
      listingList: listingList,
      listingListTotal: listingListTotal,
      newListingList: newListingList,
    });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_number: value.label,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  // datechnageHandle = (event) => {
  //   const values = [...this.state.resolutionFeeData];
  //   if (event.target.type === "date" && event.target.value !== null) {
  //     values[event.target.index].payment_received_date = Moment(
  //       event.target.value
  //     ).toDate();
  //   }
  //   this.setState({ resolutionFeeData: values, resolution_fee_data: values });
  // };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  handleChangeStatus(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    const status_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStatusList: value,
      status_id: status_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeChannel(value) {
    let set_channel = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        set_channel.push(item.value);
      });
    }
    const set_channel_value = value !== null ? value.value : [];
    this.setState({
      selectedChannelList: value,
      set_channel: set_channel_value,
    });
    this.setState({ confirm_back: 1 });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Access";
    } else {
      return "Access";
    }
  }

  render() {
    return (
      
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
                      {global.userPermissions.checkPermission(
                        "latch-manual-delete"
                      ) &&
                        this.state.key_type === "Manual" && (
                          <span
                            onClick={() => {
                              this.opendeleteKeyModal(this.state.slug);
                            }}
                            className="deleteicon mg-r-20 hand-cursor"
                          >
                            <img src={deleteicon} alt="Delete" />
                          </span>
                        )}

                      {global.userPermissions.checkPermission(
                        "latch-manual-update"
                      ) && (
                        <Link
                          to={
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                this.state.property_slug +
                                "/latches/activity-update/" +
                                this.state.slug
                              : "/latches/activity-update/" +
                                this.state.property_slug +
                                "/" +
                                this.state.slug
                          }
                        >
                       
                          {this.state.key_type === "Manual" ? (
                            <button className="btn-success-outline ml-3">
                              <img src={editIcon} alt="" />
                              Edit
                            </button>
                          ) : (
                            ""
                          )}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-lg-l-15 pd-sm-l-30 pd-sm-r-30 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <form id="key_form">
                      <div className="m-0">
                        <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Unit Name
                            </p>
                            <p className="media-body">
                              {this.state.unit_name ? this.state.unit_name : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Start Date
                            </p>
                            <p className="media-body">
                              {this.state.access_start_date
                                ? Moment(this.state.access_start_date).format(
                                    global.dateFormat
                                  ) +
                                  " | " +
                                  this.state.access_start_time
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              End Date
                            </p>
                            <p className="media-body">
                              {this.state.access_end_date
                                ? Moment(this.state.access_end_date).format(
                                    global.dateFormat
                                  ) +
                                  " | " +
                                  this.state.acess_end_time
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">Email</p>
                            <p className="media-body">
                              {this.state.email ? this.state.email : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Access Code
                            </p>
                            <p className="media-body">
                              {this.state.access_code
                                ? this.state.access_code
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Exchanger Name
                            </p>
                            <p className="media-body">
                              {this.state.key_name ? this.state.key_name : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">Key ID</p>
                            <p className="media-body">
                              {this.state.keycafe_key_id
                                ? this.state.keycafe_key_id
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">Type</p>
                            <p className="media-body">
                              {this.state.ref_table_id == 1
                                ? "Guest"
                                : this.state.ref_table_id == 2
                                ? "Cleaner"
                                : this.state.ref_table_id == 3
                                ? "Resident"
                                : this.state.ref_table_id == 4
                                ? "Visitor"
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteKeycafeModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteKeyModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteKeyModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() =>
                    this.deleteKeyModal(this.state.slug)
                  }
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default ActivityViewLatch;
