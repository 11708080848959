////////////////////////////////////////////////////////////
//     							                          //
//  Program: ListingUnavailableDates.jsx                  //
//  Application: ListingUnavailableDates                  //
//  Option: For view ListingUnavailableDates details      //
//  Developer: Ashish Kumar 				              //
//  Date: 2022-02-09                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import ListingService from "../../services/ListingService";
import ReservationService from "../../services/ReservationService";
import Alerts from "../common/Alerts";
import DatePicker from "react-datepicker";
import SimpleReactValidator from "simple-react-validator";
import Moment from "moment";
import deleteicon from "../../assets/images/delete.svg";
// import editIcon from "./../../assets/images/edit-pen-icon.svg";
import { Tab, Nav, Table, Modal, InputGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
//import queryString from "query-string";
//import ReactDatatable from "@ashvin27/react-datatable";
//let queryParams = queryString.parse(window.location.search);

export class ListingUnavailableDates extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      from_date: "",
      to_date: "",
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      confirm_back: 0,
      unavailable_slug: "",
      disabled_dates: [],
      cleaning_method: "",
      maxCheckoutDate: "",
      latchAccess: [],
      tab: 4,
      sortOrder: "asc",
      sortColumn: "from_date",
      sortOrdercheckout: "asc",
    };
    this.changeView = this.changeView.bind(this);
    this.saveOrUpdateListingUnavailableDate =
      this.saveOrUpdateListingUnavailableDate.bind(this);
    this.openDeleteUnavailableDateModal =
      this.openDeleteUnavailableDateModal.bind(this);
    this.closeDeleteUnavailableDateModal =
      this.closeDeleteUnavailableDateModal.bind(this);
    this.openEditUnavailableDateModal =
      this.openEditUnavailableDateModal.bind(this);
    this.publishListing = this.publishListing.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerRadio = this.changeHandlerRadio.bind(this);
    this.getLatchAccessDetails = this.getLatchAccessDetails.bind(this);
    this.SortHandlerCheckin = this.SortHandlerCheckin.bind(this);
    this.SortHandlerCheckout = this.SortHandlerCheckout.bind(this);
  }

  async componentDidMount() {
    this.getUnavailableData();
    this.getInfoData();
    this.getListingReservationUnavailableDate();
    this.getData();
    if (this.props.listing_slug !== undefined) {
      switch (this.state.residentTabNumber) {
        case 1:
          document.getElementById("current").click();
          break;
        case 2:
          document.getElementById("upcoming").click();
          break;
        case 3:
          document.getElementById("past").click();
          break;
        default:
          document.getElementById("all").click();
      }
    }
    this.getLatchAccessDetails();
  }

  async publishListing() {
    this.setState({ errorList: [] });
    let inputData = {
      data: {
        listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
        property_slug: this.props.property_slug ? this.props.property_slug : "",
        unit_slug: this.props.unit_slug ? this.props.unit_slug : "",
        floor_plan_slug: this.props.floor_plan_slug
          ? this.props.floor_plan_slug
          : "",
      },
    };
    let res = await ListingService.publishAvailability(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      // setTimeout(
      //   () => this.setState({ showAlertModal: false }),
      //   global.alert_close_time
      // );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
        errorList:
          res.data && res.data.result && res.data.result.error_list
            ? res.data.result.error_list
            : [],
      });
    }
  }

  async getLatchAccessDetails() {
    if (this.state.resident_stay_slug) {
      let latchAccess = [];
      let res = await ListingService.getLatchAccess(
        this.state.resident_stay_slug
      );

      if (global.successStatus.includes(res.status)) {
        latchAccess = res.data ? res.data : [];
      }
      this.setState({ latchAccess: latchAccess });
    }
  }

  openDeleteUnavailableDateModal(from, to, slug) {
    this.setState({ deleteUnavailableDateModal: true });
    this.setState({ fromDate: from, toDate: to, unavailableSlug: slug });
  }

  async openEditUnavailableDateModal(slug) {
    this.setState({ resident_stay_slug: slug });
    this.setState({ viewMode: 1 });
    if (slug) {
      var resData = {};

      let res = await ListingService.getListingUnavailableDate(
        slug + "?key=resident_stay"
      );

      if (global.successStatus.includes(res.status)) {
        resData.unavailable_slug = res.data.slug ? res.data.slug : "";
        resData.from_date = res.data.from_date
          ? Moment(res.data.from_date).toDate("dd-MM-YYYY")
          : null;
        resData.to_date = res.data.to_date
          ? Moment(res.data.to_date).toDate("dd-MM-YYYY")
          : null;
        resData.cleaning_method = res.data.cleaning_method
          ? String(res.data.cleaning_method)
          : 2;
        resData.key_status = res.data.key_status ? res.data.key_status : "";
        resData.status_time = res.data.status_time ? res.data.status_time : "";

        resData.access_start_date = res.data.resident_access_start_date
          ? res.data.resident_access_start_date
          : "";
        resData.access_start_time = res.data.resident_access_start_time
          ? res.data.resident_access_start_time
          : "";
        resData.access_end_date = res.data.resident_access_end_date
          ? res.data.resident_access_end_date
          : "";
        resData.access_end_time = res.data.resident_access_end_time
          ? res.data.resident_access_end_time
          : "";
        resData.booking_code = res.data.resident_booking_code
          ? res.data.resident_booking_code
          : "";
        resData.keycafe_key_id = res.data.resident_keycafe_key_id
          ? res.data.resident_keycafe_key_id
          : "";

        resData.access_start_date1 = res.data.cleaner_access_start_date
          ? res.data.cleaner_access_start_date
          : "";
        resData.access_start_time1 = res.data.cleaner_access_start_time
          ? res.data.cleaner_access_start_time
          : "";
        resData.access_end_date1 = res.data.cleaner_access_end_date
          ? res.data.cleaner_access_end_date
          : "";
        resData.access_end_time1 = res.data.cleaner_access_end_time
          ? res.data.cleaner_access_end_time
          : "";
        resData.booking_code1 = res.data.cleaner_booking_code
          ? res.data.cleaner_booking_code
          : "";
        resData.keycafe_key_id1 = res.data.cleaner_keycafe_key_id
          ? res.data.cleaner_keycafe_key_id
          : "";

        resData.scheduled_on = res.data.scheduled_on
          ? res.data.scheduled_on
          : "";
        resData.started_on = res.data.started_on ? res.data.started_on : "";
        resData.completed_on = res.data.completed_on
          ? res.data.completed_on
          : "";
        resData.actual_started_on = res.data.actual_started_on
          ? res.data.actual_started_on
          : "";
        resData.actual_completed_on = res.data.actual_completed_on
          ? res.data.actual_completed_on
          : "";
        resData.time_taken = res.data.time_taken ? res.data.time_taken : "";
        resData.cleaner_name = res.data.cleaner_name
          ? res.data.cleaner_name
          : "";
        resData.inspection_status = res.data.inspection_status
          ? res.data.inspection_status
          : "";
        resData.project_id = res.data.project_id ? res.data.project_id : "";
        resData.lock_type = res.data.lock_type ? res.data.lock_type : "2";
      }
      this.setState(resData);
      this.getLatchAccessDetails();
      setTimeout(() => {
        this.getListingReservationUnavailableDate();
      });
    }
  }

  async getListingReservationUnavailableDate() {
    this.setState({ showSpinner: true, loading: true });
    var checkDate = {};
    var checkDateLength = 0;
    var dates = [];
    let res = await ReservationService.checkReservationListingUnavailable(
      this.props.listing_slug
    );
    if (global.successStatus.includes(res.status)) {
      checkDate = res.data ? res.data : {};
      checkDateLength = checkDate ? checkDate.length : 0;
    }
    if (checkDateLength > 0) {
      checkDate.map((item, index) => {
        let staretDate = new Date(item.checkin_date + " 00:00:00");
        let endDate = new Date(item.checkout_date + " 00:00:00");
        const date = new Date(staretDate.getTime());

        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      });
      this.setState({ disabled_dates: dates });
    }

    this.setState({ showSpinner: false, loading: false });
  }

  /* async getListingUpdateUnavailableData(listingSlug, unavailableSlug) {
    var totalRecords = 0;
    var checkDate = {};
    var dates = [];
    let res = await ListingService.getListingUpdateUnavailableData(
      listingSlug,
      unavailableSlug
    );

    if (global.successStatus.includes(res.status)) {
      checkDate = res.data ? res.data : {};
      totalRecords = checkDate.length ? checkDate.length : 0;
    }
    if (totalRecords > 0) {
      checkDate.map((item, index) => {
        let staretDate = new Date(item.from_date);
        let endDate = new Date(item.to_date);
        const date = new Date(staretDate.getTime());

        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      });
      // this.setState({ dateFrom: checkDate });
      this.setState({ disabled_dates: dates });
    }
  } */

  closeDeleteUnavailableDateModal() {
    this.setState({ deleteUnavailableDateModal: false });
    this.setState({ fromDate: "", toDate: "" });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    this.setState({ is_search: 0 });
    this.setState({ module_search: "" });
    if (e === "current") {
      this.setState({ tab: 1 });
      this.getData(1);
      setTimeout(() => {
        localStorage.setItem(
          "resident_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "upcoming") {
      this.setState({ tab: 2 });
      this.getData(2);
      setTimeout(() => {
        localStorage.setItem(
          "resident_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "past") {
      this.setState({ tab: 3 });
      this.getData(3);
      setTimeout(() => {
        localStorage.setItem(
          "resident_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "all") {
      this.setState({ tab: 4 });
      this.getData(4);
      setTimeout(() => {
        localStorage.setItem(
          "resident_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else {
      this.setState({ tab: 1 });
      this.getData(1);
      setTimeout(() => {
        localStorage.setItem(
          "resident_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async getData(tab, sortOrder, sortColumn, queryString = "") {
    var list = [];
    var totalRecords = 0;
    let res = await ListingService.getListingUnavailableDateList(
      "listing_slug=" +
        this.props.listing_slug +
        "&key=resident_stay&tab=" +
        (tab ? tab : this.state.tab) +
        "&sort_column=" +
        (sortColumn ? sortColumn : this.state.sortColumn) +
        "&sort_order=" +
        (sortOrder ? sortOrder : this.state.sortOrder) +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      totalRecords = res.data ? res.data.length : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  async getUnavailableData(queryString = "") {
    // var list = [];
    var totalRecords = 0;
    var checkDate = {};
    var dates = [];
    let res = await ListingService.getListingUnavailableDateList(
      "listing_slug=" +
        this.props.listing_slug +
        "&key=resident_stay" +
        (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      // list = res.data ? res.data : [];
      checkDate = res.data ? res.data : {};

      totalRecords = checkDate.length ? checkDate.length : 0;
      //totalRecords = res.data ? res.data.total : 0
    }
    if (totalRecords > 0) {
      checkDate.map((item, index) => {
        // checkDate[index].from_date = Moment(item.from_date).toDate("dd/MM/YY");
        // checkDate[index].to_date = Moment(item.to_date).toDate("dd/MM/YY");
        // var startDate = Moment(item.from_date).subtract(1, "days");
        // var timeEnd = Moment(item.to_date);
        // var diff = timeEnd.diff(startDate);
        // var diff_days = Moment.duration(diff)._data.days;
        // var i = 0;
        // for (i = 0; i < diff_days; i++) {
        //   disabled_dates.push({
        //     dt: startDate.add(1, "days").toDate("dd/MM/YY"),
        //   });
        // }
        let staretDate = new Date(item.from_date + " 00:00:00");
        let endDate = new Date(item.to_date + " 00:00:00");
        const date = new Date(staretDate.getTime());

        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      });
      // this.setState({ dateFrom: checkDate });
      this.setState({ disabled_dates: dates });
    }

    // this.setState({
    //   total_record: totalRecords,
    //   records: list,
    // });
  }

  async getInfoData() {
    var resData = {};
    let res = await ListingService.getListing(this.props.listing_slug);

    if (global.successStatus.includes(res.status)) {
      resData.listing_from_date = res.data.from_date
        ? Moment(res.data.from_date, "MM/DD/YYYY").toDate("MM/DD/YYYY")
        : null;
      resData.listing_to_date = res.data.to_date
        ? Moment(res.data.to_date, "MM/DD/YYYY").toDate("MM/DD/YYYY")
        : null;
      resData.status_id = res.data.status_id ? res.data.status_id : 0;
    }
    this.setState(resData);
  }

  async getReservationMaxDate(slug, listingSlug, date) {
    var checkDateMax = [];
    let res = await ReservationService.getReservationNextCheckInDate(
      slug,
      listingSlug,
      date
    );
    if (global.successStatus.includes(res.status)) {
      checkDateMax.set_max_date =
        res && res.data && res.data.set_max_date ? res.data.set_max_date : "";

      var maxCheckOutDate = checkDateMax.set_max_date
        ? Moment(checkDateMax.set_max_date).toDate("dd/MM/YY")
        : this.state.listing_to_date
        ? this.state.listing_to_date
        : "";
    }
    this.setState({ maxCheckoutDate: maxCheckOutDate });
  }

  saveOrUpdateListingUnavailableDate = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
        unit_slug: this.props.unit_slug ? this.props.unit_slug : "",
        property_slug: this.props.property_slug ? this.props.property_slug : "",
        from_date: this.state.from_date
          ? Moment(this.state.from_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        to_date: this.state.to_date
          ? Moment(this.state.to_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        cleaning_method:
          this.state.status_id == 2
            ? 1
            : this.state.cleaning_method
            ? parseInt(this.state.cleaning_method)
            : '',
       
        key: "resident_stay",
      };
      if (this.state.unavailable_slug) {
        let res = await ListingService.updateListingUnavailableDate(
          this.state.unavailable_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          this.changeView();
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            
            this.setState({ errors: errors });
          }
        }
      } else {
        let res = await ListingService.createListingUnavailableDate(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          var last_insert_slug = res.data.last_insert_slug;
          this.setState({ tabWizard: 1, listing_slug: last_insert_slug });

          this.changeView();
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }

      this.setState({ showSpinner: false, isSubmit: false });
      // setTimeout(
      //   () => this.setState({ showAlertModal: false }),
      //   global.alert_close_time
      // );
    } else {
      this.validator.showMessages();
    }
  };

  async deleteUnavailableDate(from, to, slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    let dateData = {
      listing_slug: this.props.listing_slug,
      fromDate: from,
      toDate: to,
      unavailable_slug: slug,
      key: "resident_stay",
    };
    let res = await ListingService.deleteListingUnavailableDate(dateData);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteUnavailableDateModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({ disabled_dates: [] });
      this.getData();
      this.getUnavailableData();
      this.getListingReservationUnavailableDate();
    } else {
      this.closeDeleteUnavailableDateModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false, isSubmit: false });
    this.closeDeleteUnavailableDateModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    if (event.target.name === "from_date") {
      let fromDateVal = Moment(event.target.value, "MM-DD-YYYY").toDate();
      let fromDateValue = Moment(fromDateVal).format("YYYY-MM-DD");
      this.getReservationMaxDate(
        this.props.unit_slug,
        this.props.listing_slug,
        fromDateValue
      );
      if (this.state.viewMode === 1) {
        this.setState({ to_date: "" });
      }
    }

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  changeHandlerRadio(evt) {
    let value = evt.target.value;
    if (value == 1) {
      const textData = document.getElementById("cleaning_doc");
      textData.style.display = "block";
    } else {
      const textData = document.getElementById("cleaning_doc");
      textData.style.display = "none";
    }
    this.setState({ cleaning_method: value });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }
  SortHandlerCheckin = (sortOrder, sortColumn) => {
    this.setState((prevstate) => ({
      sortOrder: prevstate.sortOrder === "asc" ? "desc" : "asc",
    }));
    var queryString = "";
    this.getData(this.state.tab, sortOrder, sortColumn, queryString);
  };
  SortHandlerCheckout = (sortOrdercheckout, sortColumn) => {
    this.setState((prevstate) => ({
      sortOrdercheckout: prevstate.sortOrdercheckout === "asc" ? "desc" : "asc",
    }));
    var queryString = "";
    this.getData(this.state.tab, sortOrdercheckout, sortColumn, queryString);
  };

  changeView(slug) {
    if (this.state.viewMode === 1) {
      this.setState({
        viewMode: 0,
        from_date: "",
        to_date: "",
        unavailable_slug: "",
        cleaning_method:"",
        errors: {},
      });
      this.setState({ confirm_back: 0 });
      this.getData();
      this.getUnavailableData();
      this.getListingReservationUnavailableDate();
    } else {
      this.setState({
        viewMode: 1,
        from_date: "",
        to_date: "",
        unavailable_slug: "",
        cleaning_method:"",
        errors: {},
      });
      this.setState({ confirm_back: 0 });
      if (slug !== undefined) {
        this.openEditUnavailableDateModal(slug);
      }
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }
  }

  render() {
    return (
      <>
        <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
          <div className="d-flex flex-wrap justify-content-end bothBtn swiperBtns">
            {this.state.viewMode === 0 &&
            global.userPermissions.checkPermission("single-unit-update") ? (
              <>
                {/* <button
                  className="btn-success-outline-small button-width"
                  onClick={this.publishListing}
                >
                  Publish Dates
                </button> */}
                <button
                  className="btn-success-outline-small mr-3"
                  onClick={() => this.changeView()}
                >
                  Add
                </button>
              </>
            ) : (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn-success-outline-small"
                  // className="btn-success-outline-small mg-r-20"
                  onClick={() => this.changeView()}
                >
                  Cancel
                </button>
                {!this.state.unavailable_slug &&
                global.userPermissions.checkPermission("single-unit-update") ? (
                  <button
                    className="btn-success-outline-small ml-3 mr-3 button-width"
                    onClick={this.saveOrUpdateListingUnavailableDate}
                  >
                    {this.state.isSubmit ? global.loader : "Save"}
                  </button>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>

          {this.state.viewMode === 0 ? (
            <React.Fragment>
              <div className="scrolling-carousel pd-xs-l-0 pd-lg-l-0 mg-b-15 pd-xs-r-0 pd-lg-r-0 scroll-slide">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="current"
                  onSelect={(e) => this.getActiveClass(e)}
                  eventKey={this.state.activeClass}
                  className="pd-r-60"
                >
                  <ScrollingCarousel
                    rightArrow={true}
                    leftArrow={true}
                    className="swipesection scroll_nav_mob"
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="current"
                        id="current"
                        className={
                          this.state.activeClass === "current" ? "active" : ""
                        }
                      >
                        Current
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="upcoming"
                        id="upcoming"
                        className={
                          this.state.activeClass === "upcoming" ? "active" : ""
                        }
                      >
                        Upcoming
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="past"
                        id="past"
                        className={
                          this.state.activeClass === "past" ? "active" : ""
                        }
                      >
                        Past
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="all"
                        id="all"
                        className={
                          this.state.activeClass === "all" ? "active" : ""
                        }
                      >
                        All
                      </Nav.Link>
                    </Nav.Item>
                  </ScrollingCarousel>
                </Tab.Container>
              </div>
              <div className="m-0">
                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15">
                  <div className="table-responsive lstng_asc_desc">
                    <Table hover className="">
                      <thead>
                        <tr>
                          <th
                            className={
                              this.state.sortOrder === "asc"
                                ? "w-40 sortable asc"
                                : this.state.sortOrder === "desc"
                                ? "w-40 sortable desc"
                                : "w-40"
                            }
                            onClick={() =>
                              this.SortHandlerCheckin(
                                this.state.sortOrder === "asc" ? "desc" : "asc",
                                "from_date"
                              )
                            }
                          >
                            Check-in
                          </th>
                          <th
                            className={
                              this.state.sortOrdercheckout === "asc"
                                ? "w-40 sortable asc"
                                : this.state.sortOrdercheckout === "desc"
                                ? "w-40 sortable desc"
                                : "w-40"
                            }
                            onClick={() =>
                              this.SortHandlerCheckout(
                                this.state.sortOrdercheckout === "asc"
                                  ? "desc"
                                  : "asc",
                                "to_date"
                              )
                            }
                          >
                            Check-out
                          </th>
                          <th className="w-40">Total Days</th>
                          {global.userPermissions.checkPermission(
                            "single-unit-update"
                          ) ? (
                            <th className="text-center">Action</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.total_record > 0
                          ? this.state.records.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="hand-cursor"
                                    onClick={() => this.changeView(item.slug)}
                                  >
                                    {Moment(
                                      item.from_date,
                                      "YYYY/MM/DD"
                                    ).format(global.dateFormat)}
                                  </td>
                                  <td
                                    className="hand-cursor"
                                    onClick={() => this.changeView(item.slug)}
                                  >
                                    {Moment(item.to_date, "YYYY/MM/DD").format(
                                      global.dateFormat
                                    )}
                                  </td>
                                  <td className="hand-cursor">
                                    {" "}
                                    {item.total_days}
                                  </td>
                                  {global.userPermissions.checkPermission(
                                    "single-unit-update"
                                  ) ? (
                                    <td>
                                      <div className="btn-icon-list d-block text-center">
                                        <img
                                          src={deleteicon}
                                          alt="Delete"
                                          onClick={() =>
                                            this.openDeleteUnavailableDateModal(
                                              Moment(
                                                item.from_date,
                                                "YYYY/MM/DD"
                                              ).format("YYYY-MM-DD"),
                                              Moment(
                                                item.to_date,
                                                "YYYY/MM/DD"
                                              ).format("YYYY-MM-DD"),
                                              item.slug
                                            )
                                          }
                                          className="hand-cursor"
                                        />
                                      </div>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </Table>
                    <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                      <div className="pd-lg-l-0 pd-xs-l-15">
                        {/* <ReactDatatable
                              className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList mg-b-100"
                              config={
                              this.state.total_record >
                              global.page_size
                              ? this.state.config
                              : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                                      // loading={this.state.loading}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              onRowClicked={this.rowClickedHandler}
                            /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            ""
          )}

          {this.state.viewMode === 1 ? (
            <div className="m-0">
              <div className="row align-items-basline pd-b-0 border-bottom-0 pd-r-15">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">
                    Check-in
                  </label>

                  <DatePicker
                    className="form-control max_width_100"
                    disabledKeyboardNavigation
                    strictParsing
                    scrollableYearDropdown
                    autoComplete="off"
                    popperPlacement="top-start"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    excludeDates={this.state.disabled_dates}
                    //maxDate={this.state.to_date}
                    name="from_date"
                    dateFormat="MM/dd/yy"
                    // minDate={
                    //   this.state.listing_from_date
                    //     ? this.state.listing_from_date
                    //     : new Date()
                    // }
                    minDate={new Date()}
                    // maxDate={
                    //   this.state.listing_to_date
                    //     ? this.state.listing_to_date
                    //     : ""
                    // }
                    maxDate={
                      this.state.listing_to_date
                        ? this.state.listing_to_date
                          ? new Date(this.state.listing_to_date).setDate(
                              new Date(this.state.listing_to_date).getDate() - 1
                            )
                          : ""
                        : ""
                    }
                    // maxDate={this.state.to_date}
                    selected={this.state.from_date}
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "from_date",
                          value: data,
                        },
                      })
                    }
                    disabled={this.state.unavailable_slug ? true : ""}
                  />
                  {this.state.errors.from_date ? (
                    <div className="text-danger">
                      {this.state.errors.from_date}
                    </div>
                  ) : (
                    this.validator.message(
                      "from_date",
                      this.state.from_date,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">
                    Check-out
                  </label>
                  <DatePicker
                    className="form-control max_width_100"
                    disabledKeyboardNavigation
                    strictParsing
                    scrollableYearDropdown
                    autoComplete="off"
                    popperPlacement="top-start"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    // excludeDates={this.state.disabled_dates}
                    minDate={
                      this.state.from_date
                        ? this.state.from_date
                          ? new Date(this.state.from_date).setDate(
                              new Date(this.state.from_date).getDate() + 1
                            )
                          : new Date()
                        : new Date()
                    }
                    // maxDate={
                    //   this.state.listing_to_date
                    //     ? this.state.listing_to_date
                    //     : ""
                    // }
                    maxDate={this.state.maxCheckoutDate}
                    name="to_date"
                    dateFormat="MM/dd/yy"
                    selected={this.state.to_date}
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "to_date",
                          value: data,
                        },
                      })
                    }
                    disabled={this.state.unavailable_slug ? true : ""}
                  />
                  {this.state.errors.to_date ? (
                    <div className="text-danger">
                      {this.state.errors.to_date}
                    </div>
                  ) : (
                    this.validator.message(
                      "to_date",
                      this.state.to_date,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
              </div>
              <div className="row align-items-basline pd-b-0 border-bottom-0 pd-r-15">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <InputGroup className="mt-0">
                    <label className="form-label text-left cleaning_label xs-w-100">
                      Cleaning Method :{" "}
                    </label>
                    <div className="clean-method-w">
                      <div className="col-sm-12 col-md-6 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-10 pd-lg-r-0 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                        <Form.Check
                          onChange={(e) => this.changeHandlerRadio(e)}
                          name="cleaning_method"
                          value="1"
                          type={"radio"}
                          id="1"
                          label={"Self Clean"}
                          className={"rdiobox mg-r-20"}
                          checked={
                            this.state.cleaning_method == 1 ? true : false
                          }
                        />
                        {this.state.errors.cleaning_method ? (
                          <div className="text-danger cleaing_mth_error">
                            {this.state.errors.cleaning_method}
                          </div>
                        ) : (
                          this.validator.message(
                            "cleaning_method",
                            this.state.cleaning_method,
                            "required",
                            { className: "text-danger cleaing_mth_error" }
                          )
                        )}
                        <div>
                          <>
                            <div
                              className="row align-items-basline pd-b-0 border-bottom-0 pd-r-0 cust-ds-play"
                              id="cleaning_doc"
                            >
                              {this.state.cleaning_method == 1 ? (
                              <Link
                                className="pd-l-25"
                                to="/fw9.pdf"
                                target="_blank"
                                download="Form W-9.pdf"
                              >
                                How to clean?
                              </Link>
                              ) : (' ')}
                            </div>
                          </>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-10 pd-lg-r-0 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                        <Form.Check
                          onChange={(e) => this.changeHandlerRadio(e)}
                          name="cleaning_method"
                          value="2"
                          type={"radio"}
                          id="2"
                          label={"Hire Cleaner"}
                          className={"rdiobox mg-r-20 w-100"}
                          disabled={this.state.status_id == 2 ? true : false}
                          checked={
                            this.state.cleaning_method == 2 ? true : false
                          }
                        />
                      </div>
                    </div>
                  </InputGroup>
                </div>
              </div>

              {this.state.unavailable_slug ? (
                <>
                  {this.state.cleaning_method == 2 ? (
                    <>
                      {/* Turn over BNB start */}
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                        <h3 className="mg-l-0 mg-t-15 background-head">
                          Turnoverbnb Cleaning Schedule
                        </h3>
                        <div className="row border-bottom-0">
                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-0 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 border-bottom-0">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-30">
                              <p className="az-profile-name-text pl-0">
                                Schedule Start
                              </p>
                              <p className="media-body">
                                {this.state.scheduled_on
                                  ? Moment(this.state.scheduled_on).format(
                                      global.dateTimeFormat
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-30">
                              <p className="az-profile-name-text pl-0">
                                Schedule Completion
                              </p>
                              <p className="media-body">
                                {this.state.completed_on
                                  ? Moment(this.state.completed_on).format(
                                      global.dateTimeFormat
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-30">
                              <p className="az-profile-name-text pl-0">
                                Cleaning Status{" "}
                              </p>
                              <p className="media-body">
                                {this.state.inspection_status === 1
                                  ? "Completed"
                                  : this.state.inspection_status === 3
                                  ? "Assigned"
                                  : "Pending"}
                              </p>
                            </div>
                          </div>

                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-0 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 border-bottom-0">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-30">
                              <p className="az-profile-name-text pl-0">
                                Actual Start
                              </p>
                              <p className="media-body">
                                {this.state.actual_started_on
                                  ? Moment(this.state.actual_started_on).format(
                                      global.dateTimeFormat
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-30">
                              <p className="az-profile-name-text pl-0">
                                Actual Completion
                              </p>
                              <p className="media-body">
                                {this.state.actual_completed_on
                                  ? Moment(
                                      this.state.actual_completed_on
                                    ).format(global.dateTimeFormat)
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-30">
                              <p className="az-profile-name-text pl-0">
                                Time Taken{" "}
                              </p>
                              <p className="media-body">
                                {this.state.time_taken
                                  ? this.state.time_taken
                                  : ""}
                              </p>
                            </div>
                          </div>

                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-0 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 border-bottom-0">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-30">
                              <p className="az-profile-name-text pl-0">
                                Cleaner Name{" "}
                              </p>
                              <p className="media-body">
                                {this.state.cleaner_name
                                  ? this.state.cleaner_name
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-30">
                              <p className="az-profile-name-text pl-0">
                                Turnoverbnb Project ID
                              </p>
                              <p className="media-body">
                                {this.state.project_id
                                  ? this.state.project_id
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Turn over BNB End */}
                    </>
                  ) : (
                    ""
                  )}

                  {global.userPermissions.checkPermission(
                    "key-cafe-access-view"
                  ) &&
                    this.state.lock_type === 1 && (
                      <>
                        {/* key cafe start */}
                        <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                          <h3 className="mg-l-0 mg-t-15 background-head">
                            Key Cafe Access
                          </h3>
                        </div>
                        <div className="row col-12 pd-lg-l-15 pd-sm-l-30 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- border-bottom-0">
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-r-15 pd-lg-l-15">
                              <h3 className="mg-l-0 mg-t-15">Resident</h3>
                            </div>

                            <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 border-bottom-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                <p className="az-profile-name-text pl-0">
                                  Key Start Time
                                </p>
                                <p className="media-body">
                                  {this.state.access_start_date &&
                                  this.state.access_start_time
                                    ? Moment(
                                        this.state.access_start_date +
                                          " " +
                                          this.state.access_start_time
                                      ).format(global.dateTimeFormat)
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                <p className="az-profile-name-text pl-0">
                                  Key End Time
                                </p>
                                <p className="media-body">
                                  {this.state.access_end_date &&
                                  this.state.access_end_time
                                    ? Moment(
                                        this.state.access_end_date +
                                          " " +
                                          this.state.access_end_time
                                      ).format(global.dateTimeFormat)
                                    : ""}
                                </p>
                              </div>
                            </div>

                            <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 border-bottom-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- border-bottom-0">
                                <p className="az-profile-name-text pl-0">
                                  Key Cafe Access Code
                                </p>
                                <p className="media-body">
                                  {this.state.booking_code
                                    ? this.state.booking_code
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                <p className="az-profile-name-text pl-0">
                                  Key ID{" "}
                                </p>
                                <p className="media-body">
                                  {this.state.keycafe_key_id
                                    ? this.state.keycafe_key_id
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                          {this.state.cleaning_method == 2 ? (
                            <>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-xs-l-0 pd-lg-l-15">
                                  <h3 className="mg-l-0 mg-t-15">Cleaner</h3>
                                </div>

                                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 border-bottom-0">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                    <p className="az-profile-name-text pl-0">
                                      Key Start Time
                                    </p>
                                    <p className="media-body">
                                      {this.state.access_start_date1 &&
                                      this.state.access_start_time1
                                        ? Moment(
                                            this.state.access_start_date1 +
                                              " " +
                                              this.state.access_start_time1
                                          ).format(global.dateTimeFormat)
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                    <p className="az-profile-name-text pl-0">
                                      Key End Time
                                    </p>
                                    <p className="media-body">
                                      {this.state.access_end_date1 &&
                                      this.state.access_end_time1
                                        ? Moment(
                                            this.state.access_end_date1 +
                                              " " +
                                              this.state.access_end_time1
                                          ).format(global.dateTimeFormat)
                                        : ""}
                                    </p>
                                  </div>
                                </div>

                                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 border-bottom-0">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                    <p className="az-profile-name-text pl-0">
                                      Key Cafe Access Code
                                    </p>
                                    <p className="media-body">
                                      {this.state.booking_code1
                                        ? this.state.booking_code1
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                    <p className="az-profile-name-text pl-0">
                                      Key ID{" "}
                                    </p>
                                    <p className="media-body">
                                      {this.state.keycafe_key_id1
                                        ? this.state.keycafe_key_id1
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  {global.userPermissions.checkPermission(
                    "latch-access-view"
                  ) &&
                    this.state.lock_type === 2 &&
                    this.state.latchAccess &&
                    this.state.latchAccess.length > 0 && (
                      <>
                        <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                          <div className="background-head pd-b-15 d-flex justify-content-between align-items-center flex-wrap mg-b-30">
                            <h3 className="m-0">Latch Access</h3>
                          </div>
                        </div>
                        <div className="row col-12 pd-lg-l-15 pd-sm-l-30">
                          {this.state.latchAccess.map((item, index) => (
                            <div
                              key={index}
                              className="col-12 pd-lg-l-15 pd-xs-l-0"
                            >
                              <div className="col-lg-12 p-0">
                                <h3 className="mg-l-0 mg-t-15">
                                  {item.ref_table_id == 1
                                    ? "Guest"
                                    : item.ref_table_id == 25
                                    ? "Resident"
                                    : item.ref_table_id == 3
                                    ? "Cleaner"
                                    : ""}
                                </h3>
                              </div>
                              <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 p-0">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-30">
                                  <p className="az-profile-name-text pl-0">
                                    Start Time
                                  </p>
                                  <p className="media-body">
                                    {item.start_time
                                      ? Moment(item.created_at).format(
                                          global.dateTimeFormat
                                        )
                                      : ""}
                                  </p>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-30">
                                  <p className="az-profile-name-text pl-0">
                                    End Time
                                  </p>
                                  <p className="media-body">
                                    {item.end_time
                                      ? Moment(item.end_time).format(
                                          global.dateTimeFormat
                                        )
                                      : ""}
                                  </p>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-30">
                                  <p className="az-profile-name-text pl-0">
                                    Door Name
                                  </p>
                                  <p className="media-body">{item.door_name}</p>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-30">
                                  <p className="az-profile-name-text pl-0">
                                    Invite Status
                                  </p>
                                  <p className="media-body">
                                    {item.status_id == 4
                                      ? "Created"
                                      : "Pending"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  {/* Latch Access ---------------------------------- */}
                  {/* key cafe end */}
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <Modal
          show={this.state.deleteUnavailableDateModal}
          onHide={() => {
            if (
              window.confirm("Are you sure to exit the pop up without delete?")
            )
              this.closeDeleteUnavailableDateModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeDeleteUnavailableDateModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={deleteicon} alt="modal-delete-icon" />
              <h3>Are you sure?</h3>
              <p>
                Do you really want to delete this resident stay? This process
                cannot be undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteUnavailableDateModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() =>
                    this.deleteUnavailableDate(
                      this.state.fromDate,
                      this.state.toDate,
                      this.state.unavailableSlug
                    )
                  }
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ListingUnavailableDates;
