/////////////////////////////////////////////////////
//     							                   //
//  Program: Accessibility.jsx                             //
//  Application: Property                          //
//  Option: List all Property Accessibility info           //
//  Developer: Ashish Kumar					   //
//  Date: 2022-04-04                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import PropertySidebar from "../common/PropertySidebar";
import AccessibilityFeaturesService from "../../services/AccessibilityFeaturesService";
import { Container } from "react-bootstrap";
import NoData from "../common/NoData";

export class Accessibility extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      viewMode: 0,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      importModal: false,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      accessibilityList: [],
      accessibilityListTotal: 0,
      propertyAccessibilityList: [],
      propertyAccessibilityListTotal: 0,
      selectedAccessibility: [],
      slugUncheckList: [],
      select_all: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.confirmImportModal = this.confirmImportModal.bind(this);
    this.closeConfirmImportModal = this.closeConfirmImportModal.bind(this);
    this.addAccessibility = this.addAccessibility.bind(this);
    this.getAccessibilityDetails = this.getAccessibilityDetails.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.changeView = this.changeView.bind(this);
  }

  async componentDidMount() {
    this.getAccessibilityData();
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ selectedAccessibility: [] });
      this.setState({ slugUncheckList: [] });
      this.getPropertyAccessibilityData();
    } else {
      this.setState({ viewMode: 1 });
      // this.getAccessibilityData();
      this.getPropertyAccessibilityData();
    }
  }

  /* to accessibility data */
  async getAccessibilityData(queryString = "") {
    var accessibilityList = [];
    var accessibilityListTotal = 0;

    let res = await AccessibilityFeaturesService.getAccessibilityFeature(
      "level=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      accessibilityList = res.data ? res.data.data : [];
      accessibilityListTotal = accessibilityList ? accessibilityList.length : 0;
    }
    this.setState({
      accessibilityList: accessibilityList,
      accessibilityListTotal: accessibilityListTotal,
    });

    this.getPropertyAccessibilityData();
  }

  /* to get property accessibility data */
  async getPropertyAccessibilityData() {
    var propertyAccessibilityList = [];
    var selectedAccessibility = [];
    var propertyAccessibilityListTotal = 0;
    let res = await AccessibilityFeaturesService.getPropertyAccessibility(
      this.state.property_slug + "/1"
    );
    if (global.successStatus.includes(res.status)) {
      propertyAccessibilityList = res.data ? res.data : [];
      propertyAccessibilityListTotal = propertyAccessibilityList
        ? propertyAccessibilityList.length
        : 0;
    }
    propertyAccessibilityList.forEach((opt) => {
      selectedAccessibility.push(opt.slug);
    });
    var accessibilityList = this.state.accessibilityList;
    this.state.accessibilityList.map((item, i) => {
      propertyAccessibilityList.map((options) => {
        if (item.slug === options.slug) {
          accessibilityList[i].checked = true;
        } else {
          if (accessibilityList[i].checked !== true) {
            accessibilityList[i].checked = false;
          }
        }
      });
    });
    this.setState({
      selectedAccessibility,
      accessibilityList: accessibilityList,
      propertyAccessibilityList: propertyAccessibilityList,
      propertyAccessibilityListTotal: propertyAccessibilityListTotal,
    });
    if (
      this.state.accessibilityListTotal ===
      this.state.propertyAccessibilityListTotal
    ) {
      this.setState({ select_all: true });
    } else {
      this.setState({ select_all: false });
    }
  }

  /* to save accessibility data */
  addAccessibility = async (e) => {
    e.preventDefault();

    this.setState({ showSpinner: true, isSubmit: true });
    let inputData = {
      accessibilityData:
        this.state.selectedAccessibility.length > 0
          ? this.state.selectedAccessibility
          : [""],
    };

    let res = await AccessibilityFeaturesService.addOrUpdateAccessibility(
      this.state.property_slug + "/1",
      inputData
    );
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      var check = document.getElementsByName("one");
      for (var i = 0; i < check.length; i++) {
        document.getElementsByName("one")[i].checked = false;
      }
      this.setState({ selectedAccessibility: [] });
      this.getPropertyAccessibilityData();
      this.setState({ viewMode: 0 });
    } else {
      if (res.data.result.errorDetail) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
      });
    }
    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  /* To accessibility details data */
  async getAccessibilityDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res =
        await AccessibilityFeaturesService.getAccessibilityFeatureBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.accessibility_feature_name = res.data.accessibility_feature_name
          ? res.data.accessibility_feature_name
          : "";
        resData.accessibility_features_location_name = res.data
          .accessibility_features_location
          ? res.data.accessibility_features_location
            .accessibility_features_location_name
          : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.tag_line = res.data.tag_line ? res.data.tag_line : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  confirmImportModal(slug) {
    this.getAccessibilityDetails(slug);
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  changeHandler = (event) => {
    this.setState({ select_all: "" });
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    var accessibilityList = this.state.accessibilityList;
    let newArray = [...this.state.accessibilityList];
    if (event.target.name === "select_all") {
      if (event.target.checked === true) {
        accessibilityList.forEach((item, i) => {
          accessibilityList[i].checked = true;
        });
        newArray.forEach((item, i) => {
          newArray[i] = item.slug;
        });
      } else {
        accessibilityList.forEach((item, i) => {
          accessibilityList[i].checked = false;
        });
        newArray.forEach((item, i) => {
          newArray[i] = "";
        });
      }
    }
    this.setState({ selectedAccessibility: newArray });
  };

  handleCheckboxChange = (event, index) => {
    this.setState({ select_all: "" });
    var accessibilityList = this.state.accessibilityList;
    let newArray = [...this.state.selectedAccessibility, event.target.id];
    if (this.state.selectedAccessibility.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    accessibilityList[index].checked =
      event.target.checked === true ? true : false;
    this.setState({ selectedAccessibility: newArray });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Accessibility Features";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {this.state.viewMode === 0 ? (
                        <button
                          className="btn-success-outline-small"
                          onClick={this.changeView}
                        >
                          {" "}
                          {this.state.propertyAccessibilityListTotal > 0
                            ? "Edit"
                            : "Add"}
                        </button>
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small mg-r-20"
                            onClick={this.changeView}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn-success-outline-small"
                            onClick={this.addAccessibility}
                          >
                            Save{" "}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="aminitesSection">
                    <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                      <div className="col-md-12 pd-l-30 pd-r-30 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.viewMode === 0 ? (
                        this.state.propertyAccessibilityListTotal > 0 ? (
                          <div className="m-0">
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 ">
                              <h3>What this place offers</h3>
                            </div>
                            <div className="aminityListView pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                              <ul className="attraction">
                                {this.state.propertyAccessibilityListTotal >
                                  0 ? (
                                  this.state.propertyAccessibilityList.map(
                                    (item, i) => {
                                      return (
                                        <li className="m-2" key={i}>
                                          {item.accessibility_feature_name
                                            ? item.accessibility_feature_name
                                            : ""}
                                        </li>
                                      );
                                    }
                                  )
                                ) : (
                                  <h6 className="mg-t-20">
                                    There are no accessibility features added in
                                    the system. You may add them by clicking Add
                                    button.
                                  </h6>
                                )}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <NoData msg="accessibility features" />
                        )
                      ) : (
                        <div className="m-0">
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                            <h3 className="mg-l-0 background-head">
                              All Available Accessibility Features
                            </h3>
                          </div>

                          <div className="row align-items-center pd-b-30 pd-l-30 pd-r-30 border-bottom-0 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-0 pd-r-0 pd-r-40 mg-b-0">
                              <div className="select-all-checkbox d-block w-100 mg-b-20">
                                <div className="ckbox form-check">
                                  <label className="form-label text-left pd-b-5 mg-l-20 mg-r-10">
                                    <b>Select All</b>
                                  </label>
                                  <input
                                    name="select_all"
                                    type={"checkbox"}
                                    id={`select_all`}
                                    onChange={this.changeHandler}
                                    className="form-check-input"
                                    checked={this.state.select_all}
                                  />
                                  <label
                                    title=""
                                    htmlFor="select_all"
                                    className="form-check-label"
                                  ></label>
                                </div>
                              </div>
                              <div className="aminityList">
                                {this.state.accessibilityListTotal > 0 ? (
                                  <ul className="formcheck-section mg-b-0 flex-wrap">
                                    {this.state.accessibilityListTotal > 0
                                      ? this.state.accessibilityList.map(
                                        (item, i) => {
                                          var check_res =
                                            item.checked === true
                                              ? "checked"
                                              : "";
                                          return (
                                            <React.Fragment key={i}>
                                              <li>
                                                <div className="ckbox form-check">
                                                  <label className="form-label text-left mg-l-20 pd-b-5">
                                                    {
                                                      item.accessibility_feature_name
                                                    }
                                                  </label>
                                                  <input
                                                    name="accessibility_check"
                                                    type="checkbox"
                                                    id={item.slug}
                                                    className="form-check-input"
                                                    onChange={(e) =>
                                                      this.handleCheckboxChange(
                                                        e,
                                                        i
                                                      )
                                                    }
                                                    checked={check_res}
                                                  />
                                                  <label
                                                    title=""
                                                    htmlFor={item.slug}
                                                    className="form-check-label"
                                                  ></label>
                                                </div>
                                              </li>
                                            </React.Fragment>
                                          );
                                        }
                                      )
                                      : ""}
                                  </ul>
                                ) : (
                                  <h6 className="mg-t-20">
                                    There are no accessibility features added in
                                    the system.
                                  </h6>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default Accessibility;
