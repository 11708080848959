/////////////////////////////////////////////////////
//     							                               //
//  Program: WebsiteCookies.jsx                  //
//  Application: Property                          //
//  Option: List all Property Marketing Copy info  //
//  Developer: Ashish Kumar					                //
//  Date: 2022-04-04                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
// import renderHTML from "react-render-html";
import { Container, Form, InputGroup } from "react-bootstrap";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
// import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import PropertySidebar from "../common/PropertySidebar";
// import ShowMoreText from "react-show-more-text";
export class WebsiteCookies extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      property_slug: this.props.match.params.propertySlug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      confirm_back: 0,
      descShow: 0,
      descShowRules: 0,
      descShowHealthSafety: 0,
      descShowGettingAround: 0,
    };
    this.changeView = this.changeView.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.fullText = this.fullText.bind(this);
    this.fullTextRules = this.fullTextRules.bind(this);
    this.fullTextHealthSafety = this.fullTextHealthSafety.bind(this);
    this.fullTextGettingAround = this.fullTextGettingAround.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    this.getData();
    this.setState({ showSpinner: false, loading: false });
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
      }

      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  // Custom validation rules
  customValidate() {
    let isValid = true;
    return isValid;
  }

  saveOrUpdateProperty = async (e) => {
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputData = {
        tab: "website-cookie",
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        website_cookie: this.state.website_cookie,
      };

      if (this.state.slug !== undefined) {
        let res = await PropertyService.updateProperty(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getData();
          this.setState({ viewMode: 0 });
        } else {
          //let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      } else {
        let res = await PropertyService.createProperty(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getData();
          this.setState({ viewMode: 0 });
        } else {
          // let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      }
    }
  };

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.getData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.getData();
    }
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextRules() {
    if (this.state.descShowRules === 0) {
      this.setState({ descShowRules: 1 });
    } else {
      this.setState({ descShowRules: 0 });
    }
  }

  fullTextHealthSafety() {
    if (this.state.descShowHealthSafety === 0) {
      this.setState({ descShowHealthSafety: 1 });
    } else {
      this.setState({ descShowHealthSafety: 0 });
    }
  }

  fullTextGettingAround() {
    if (this.state.descShowGettingAround === 0) {
      this.setState({ descShowGettingAround: 1 });
    } else {
      this.setState({ descShowGettingAround: 0 });
    }
  }

  getTitle() {
    return "Website Cookies";
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {this.state.viewMode === 0 ? (
                        <button
                          className="btn-success-outline-small"
                          onClick={this.changeView}
                        >
                          <img src={editIcon} alt="" />
                          Edit
                        </button>
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.changeView();
                                else return false;
                              else this.changeView();
                            }}
                          >
                            Cancel
                          </button>

                          <button
                            className="btn-success ml-3"
                            onClick={this.saveOrUpdateProperty}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                    <div className="col-md-12 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        // show="true"
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.state.viewMode === 0 ? (
                      <>
                        <div className="row mg-b-15">
                          <div className="col-md-12 pd-l-30 pd-r-30">
                            <p className="az-profile-name-text pl-0">
                              Website Cookies:
                            </p>
                            <div className="para">
                              {this.state.website_cookie ? ( //check if website_cookie is exist
                              <p className="p_wrap1">
                              {this.state.descShow
                                ? this.state.website_cookie.slice(
                                    0,
                                    global.showMoreLength
                                  )
                                : this.state.website_cookie}

                              {this.state.website_cookie.length >
                                global.showMoreLength && (
                                <span onClick={this.fullText}>
                                  {this.state.descShow ? (
                                    <p className="hand-cursor showmore">
                                      ...Show more{" "}
                                    </p>
                                  ) : (
                                    <p className="hand-cursor showmore">
                                      Show Less{" "}
                                    </p>
                                  )}
                                </span>
                              )}
                              </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {this.state.viewMode === 1 ? (
                      <div className="m-0">
                        <div className="row align-items-center pd-t-30 pd-b-30 border-bottom-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-15">
                            <InputGroup className="mt-3">
                              <label className="form-label text-left pd-b-5 w- 100">
                                Website Cookies{" "}
                                <span className="optional">(Optional)</span>
                              </label>

                              <Form.Control
                                onChange={this.changeHandler}
                                name="website_cookie"
                                value={this.state.website_cookie}
                                className="form-control max_width_100"
                                id="website_cookie"
                                as="textarea"
                                rows={global.textareaRowLength}
                              />
                            </InputGroup>
                            {this.state.errors.website_cookie ? (
                              <div className="text-danger">
                                {this.state.errors.website_cookie}
                              </div>
                            ) : (
                              this.validator.message(
                                "website_cookie",
                                this.state.website_cookie,
                                "max:100000",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default WebsiteCookies;
