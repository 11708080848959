////////////////////////////////////////////////////////////
//     							                          //
//  Program: CompanyOverview.jsx                                //
//  Application: Dashboard                                //
//  Option: to view Company Information                   //
//  Developer: NP  						                            //
//  Date: 2022-04-11                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react"
import { withRouter } from "react-router";
import CompanyService from '../../services/CompanyService'
// import companyLogo from './../../assets/images/company_placeholder.png'
import NoOccupancy from "../../assets/images/snapshoticons/Occupancy.svg";

class OccupancyList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            propertySlug: this.props.propertySlug,
            input: {},
            errors: {},
            isSubmit: false,
            showAlertModal: false,
            alertModalType: '',
            alertModalTitle: '',
            alertModalMessage: '',
            slug: '',
            total_client_count: "",
            checkins_today: [],
            checkouts_today: [],
            checkins_tomorrow: [],
            checkouts_tomorrow: [],
            checkInList: [],
            checkOutList: [],
            cleaningscheduled_today: [],
            cleaningscheduled_tomorrow: [],
            occupiedunits: [],
            bookedunits: [],
            signed_leases: [],
            new_booking_list: [],
            avg_occupancy_total: 0,
            total_unit_booked: 0,
            total_unit_available: 0,
            total_resident_stays: 0
        }
    }

    componentDidMount() {
        this.setState({ bookedunits: [] });
        this.setState({ spinnerLoader: true, loading: true });
        if (this.state.propertySlug) {
            this.getData(this.state.propertySlug)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ bookedunits: [] });
        this.setState({ spinnerLoader: true, loading: true });
        if (nextProps.propertySlug) {
            this.getData(nextProps.propertySlug)
        }
    }

    // for occupied unit 
    handleClickOccupiedList(property_slug) {
        // this.props.history.push(
        //     "/properties/view/" + property_slug + "/occupancy-reports"
        // );
        const win = window.open("/properties/view/" + property_slug + "/occupancy-reports", "_blank");
        win.focus();
    }
    async getData(propertySlug) {
        this.setState({ spinnerLoader: true, loading: true });
        var resData = {};

        let res = await CompanyService.getOccupancyUnitList(propertySlug);

        if ((global.successStatus).includes(res.status)) {
            resData = res.data ? res.data : {}
            resData.bookedunits = res.data.bookedunits ? res.data.bookedunits : ''
        }
        this.setState(resData);
        if (resData.bookedunits != '') {
            // for total unit available
            this.state.total_unit_available = this.state.bookedunits.reduce((total, currentValue) => total = total + currentValue.total_available_days, 0);
            // for total unit booked
            this.state.total_unit_booked = this.state.bookedunits.reduce((total, currentValue) => total = total + parseInt(currentValue.total_vacant_days), 0);
            // for total resident stays
            this.state.total_resident_stays = this.state.bookedunits.reduce((total, currentValue) => total = total + parseInt(currentValue.resident_stay), 0);
            this.state.avg_occupancy_total = (this.state.total_unit_booked + this.state.total_resident_stays) / this.state.total_unit_available * 100;
        }

        this.setState({ spinnerLoader: false, loading: false });
    }

    render() {
        return (
            <div className="col-xxl-6 col-md-6 col-sm-12 col-12 mg-b-20">
                <div className="card card-table-two">
                    <div className=" d-flex justify-content-between">
                        <div className="">
                            <h6 className="card-title">Occupancy</h6>
                            <span className="d-block mg-b-0">Current Occupancy</span>
                        </div>
                        <h6 className="card-title">Avg Occupancy: {this.state.avg_occupancy_total.toFixed(2)}{'%'}</h6>
                    </div>
                    {this.state.bookedunits.length > 0 ? (
                        <div className="table-responsive">

                            {/* <ul>
                                                                {this.state.bookedunits.length > 0 ? this.state.bookedunits.map((item, i) => {
                                                                    return (
                                                                        <>

                                                                            <li>
                                                                                {item.property_name ? item.property_name : ''} - {item.total_vacant_days ? item.total_vacant_days : ""} / {item.total_available_days ? item.total_available_days : ""}  Units are booked today - {item.total_occupied_perchantage ? item.total_occupied_perchantage : ""} Occupancy
                                                                            </li>

                                                                        </>
                                                                    )
                                                                }) : ""}
                                                            </ul> */}
                            <table className="table table-dashboard-two mg-b-0">
                                <thead>
                                    <tr>
                                        <th className="wd-lg-25p tx-left">S.No</th>
                                        <th className="wd-lg-25p tx-left">Property Name</th>
                                        <th className="wd-lg-25p text-left">Occupancy</th>
                                        <th className="wd-lg-25p tx-left">Unit Available</th>
                                        <th className="wd-lg-25p tx-left">Unit Booked</th>
                                        <th className="wd-lg-25p tx-left">Resident Stays</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.bookedunits.map((item, i) => {
                                        return (
                                            <tr key={i} value={item} onClick={() =>
                                                this.handleClickOccupiedList(item.property_slug)
                                            }>
                                                <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">{i + 1}</td>
                                                <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">{item.property_name ? item.property_name : ""}</td>
                                                <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">{item.total_occupied_perchantage ? item.total_occupied_perchantage : "0"}{"%"}</td>
                                                <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">{item.total_available_days ? item.total_available_days : 0}</td>
                                                <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">{item.total_vacant_days ? item.total_vacant_days : 0}</td>
                                                <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">{item.resident_stay ? item.resident_stay : 0}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) :

                        <>
                            <div className="table-responsive overflow-hidden">
                                {this.state.spinnerLoader ? global.snapshotloader : <div className="text-center">

                                    <img src={NoOccupancy} alt="" />
                                    <p>No occupancy list.</p>
                                </div>}
                            </div>
                        </>

                    }
                </div>
            </div>
        );
    }
}
export default withRouter(OccupancyList)