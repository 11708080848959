////////////////////////////////////////////////////////////
//     							                                      //
//  Program: FaqsForm.jsx                                //
//  Application:  faqs                                   //
//  Option: For add or update faqs                       //
//  Developer: Saurabh Shukla                          //
//  Date: 2022-02-09                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form } from "react-bootstrap"; // InputGroup
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import JoditEditor from "jodit-react";
import { Jodit } from "jodit";
import FileUploadService from "../../services/FileUploadService";
import FaqService from "../../services/FaqService";
import PropertyService from "../../services/PropertyService";
import Select from "react-select";
import FileWidget from "../fileUploader/FileWidget";
import MultiImageService from "../../services/MultiImageService";

export class FaqsForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      status_id: true,
      selectedRentingTypeList: "",
      propertyList: [],
      propertyListTotal: 0,
      newPropertyList: [],
      selectedPropertyList: [],
      kbCategoryList: [],
      newkbCategoryList: [],
      newkbCategoryListTotal: 0,
      // selectedkbCategoryList: [],
      kbSubCategoryList: [],
      newkbSubCategoryList: [],
      newkbSubCategoryListTotal: 0,
      // selectedkbSubCategoryList: [],
      description: "",
      title: "",
      sort_order: "",
      module_type: "OHAI Assist Knowledge Only",
      selectedModuleList: [{ value: "7", label: "OHAI Assist Knowledge Only" }],
      confirm_back: 0,
      // kb_category_slug: "",
      // kb_sub_category_slug: "",
      private_content: false,
      imageorvideo_validation: "",
    };
    this.editorConfig = {
      extraButtons: ["uploadImage"],
      width: "auto",
      height: "auto",
      minHeight: 400,
      events: {
        afterInit: (instance) => {
          this.jodit = instance;
        },
      },
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangePropertyList = this.handleChangePropertyList.bind(this);
    // this.handleChangeKbCategoryList =
    //   this.handleChangeKbCategoryList.bind(this);
    // this.handleChangeKbSubCategoryList =
    //   this.handleChangeKbSubCategoryList.bind(this);
    this.handleChangeModuleList = this.handleChangeModuleList.bind(this);
    this.saveOrUpdateAttachments = this.saveOrUpdateAttachments.bind(this);
    this.setContent = this.setContent.bind(this);
  }

  // /* To get KbCategory list data */
  // async getKbCategory(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var kbCategoryList = [];
  //   var kbCategoryListTotal = 0;

  //   let res = await FaqService.getKbCategory(
  //     "is_dropdown=1",
  //     queryString ? "&" + queryString : ""
  //   );

  //   if (global.successStatus.includes(res.status)) {
  //     kbCategoryList = res.data ? res.data : [];
  //     kbCategoryListTotal = kbCategoryList ? kbCategoryList.length : 0;
  //   }
  //   const newkbCategoryList =
  //     kbCategoryListTotal > 0
  //       ? kbCategoryList.map(({ slug, kb_category_name }) => ({
  //           value: slug,
  //           label: kb_category_name,
  //         }))
  //       : [];
  //   this.setState({
  //     kbCategoryList: kbCategoryList,
  //     kbCategoryListTotal: kbCategoryListTotal,
  //     newkbCategoryList: newkbCategoryList,
  //   });
  //   this.setState({ showSpinner: false, loading: false });
  // }

  // /* To get KbSubCategory list data */
  // async getKbSubCategory(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var kbSubCategoryList = [];
  //   var kbSubCategoryListTotal = 0;

  //   let res = await FaqService.getKbSubCategory(
  //     "is_dropdown=1",
  //     queryString ? "&" + queryString : ""
  //   );

  //   if (global.successStatus.includes(res.status)) {
  //     kbSubCategoryList = res.data.data ? res.data.data : [];
  //     kbSubCategoryListTotal = kbSubCategoryList ? kbSubCategoryList.length : 0;
  //   }
  //   const newkbSubCategoryList =
  //     kbSubCategoryListTotal > 0
  //       ? kbSubCategoryList.map(({ slug, kb_sub_category_name }) => ({
  //           value: slug,
  //           label: kb_sub_category_name,
  //         }))
  //       : [];
  //   this.setState({
  //     kbSubCategoryList: kbSubCategoryList,
  //     kbSubCategoryListTotal: kbSubCategoryListTotal,
  //     newkbSubCategoryList: newkbSubCategoryList,
  //   });
  //   this.setState({ showSpinner: false, loading: false });
  // }

  async getPropertyData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var propertyList = [];
    var newPropertyList = [];
    // var selectedPropertyList = [{value: '', label: "All Properties"}];
    var propertyListTotal = 0;

    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;

      newPropertyList =
        propertyListTotal > 0
          ? propertyList.map(({ slug, property_name }) => ({
              value: slug,
              label: property_name,
            }))
          : [];
    }
    const customOptions = [
      {
        value: "A3B9C2DU1E6F",
        label: "All Properties",
      },
      ...newPropertyList,
    ];

    this.setState({
      propertyList: propertyList,
      newPropertyList: customOptions,
      // selectedPropertyList: selectedPropertyList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    // this.getKbCategory();
    // this.getKbSubCategory();
    this.getPropertyData();
    this.uploadImageButton();
    this.uploadVideoButton();
    this.setState({
      Module: [
        { value: "1", label: "Reservation" },
        { value: "2", label: "Listing" },
        { value: "3", label: "Property" },
        { value: "4", label: "General" },
        { value: "5", label: "Access Support-Latch" },
        { value: "6", label: "Access Support-Keycafe" },
        { value: "7", label: "OHAI Assist Knowledge Only" },
        { value: "8", label: "Viewable in Guest FAQs" },
      ],
    });
    if (this.state.slug !== undefined) {
      var resData = {};
      // var selectedkbCategory = [];

      let res = await FaqService.getFaqBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.description = res.data ? res.data.description : "";
        resData.sort_order = res.data ? res.data.sort_order : "";

        resData.status_id = res.data
          ? res.data.status_id === 1
            ? true
            : false
          : false;
        // resData.kb_category_slug =
        //   res.data && res.data.kb_category && res.data.kb_category.slug
        //     ? res.data.kb_category.slug
        //     : "";
        // resData.kb_sub_category_slug =
        //   res.data && res.data.sub_kb_category && res.data.sub_kb_category.slug
        //     ? res.data.sub_kb_category.slug
        //     : "";
        // selectedkbCategory = res.data.kb_category
        //   ? [
        //       {
        //         value: res.data.kb_category.slug,
        //         label: res.data.kb_category.kb_category_name,
        //       },
        //     ]
        //   : "";

        // var selectedkbSubCategory = [];
        // selectedkbSubCategory = res.data.sub_kb_category
        //   ? [
        //       {
        //         value: res.data.sub_kb_category.slug,
        //         label: res.data.sub_kb_category.kb_sub_category_name,
        //       },
        //     ]
        //   : "";
        var selectedPropertyList = [];
        if (res.data.property_id === 0) {
          selectedPropertyList = [
            {
              value: "A3B9C2DU1E6F",
              label: "All Properties",
            },
          ];
        } else {
          res.data.faq_property.forEach((item, i) => {
            selectedPropertyList = [
              ...selectedPropertyList,
              {
                value: item.property.slug,
                label: item.property.property_name,
              },
            ];
          });
        }

        var selectedModuleList =
          res.data.module_type === "Reservation"
            ? [{ value: 1, label: "Reservation" }]
            : res.data.module_type === "Property"
            ? [{ value: 2, label: "Property" }]
            : res.data.module_type === "Listing"
            ? [{ value: 3, label: "Listing" }]
            : res.data.module_type === "General"
            ? [{ value: 4, label: "General" }]
            : res.data.module_type === "Access Support-Latch"
            ? [{ value: 5, label: "Access Support-Latch" }]
            : res.data.module_type === "Access Support-Keycafe"
            ? [{ value: 6, label: "Access Support-Keycafe" }]
            : res.data.module_type === "OHAI Assist Knowledge Only"
            ? [{ value: 7, label: "OHAI Assist Knowledge Only" }]
            : res.data.module_type === "Viewable in Guest FAQs"
            ? [{ value: 8, label: "Viewable in Guest FAQs" }]
            : "";
      }
      this.setState(resData);

      this.setState({
        faqImage: res.data.faqImage,
        // selectedkbCategoryList: selectedkbCategory,
        // selectedkbSubCategoryList: selectedkbSubCategory,
        selectedPropertyList: selectedPropertyList,
        selectedModuleList: selectedModuleList,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: "Upload image",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.imageUpload(editor);
      },
    };
  };

  imageUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];
      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        var imageorvideo_validation = "Image should be jpg|jpeg|png";
        this.setState({ imageorvideo_validation: imageorvideo_validation });
        return;
      } else {
        this.setState({ imageorvideo_validation: "" });
      }
      let fileData = new FormData();

      fileData.append("file", imageFile, imageFile.name);
      fileData.append("fileName", imageFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertImage(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editor.selection.insertNode(image);
  };

  uploadVideoButton = () => {
    Jodit.defaultOptions.controls.uploadImageVideo = {
      name: "Upload video",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.videoUpload(editor);
      },
    };
  };

  videoUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = async function () {
      const videoFile = input.files[0];
      if (!videoFile) {
        return;
      }

      if (!videoFile.name.match(/\.(mp3|mp4|mov)$/)) {
        var imageorvideo_validation = "Video should be mp3|mp4|mov";
        this.setState({ imageorvideo_validation: imageorvideo_validation });
        return;
      } else {
        this.setState({ imageorvideo_validation: "" });
      }
      let fileData = new FormData();

      fileData.append("file", videoFile, videoFile.name);
      fileData.append("fileName", videoFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertVideo(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertVideo = (editor, url) => {
    const video = editor.selection.j.createInside.element("video");
    video.setAttribute("src", url);
    editor.selection.insertNode(video);
  };

  //upload imagesData
  saveOrUpdateAttachments = async (data) => {
    let errors = this.state.errors;
    //var displayFileName = "";
    if (this.validator.allValid()) {
      this.setState({ showSpinner: true, isSubmit: true });
      const FileWidgetObj = new FileWidget();
      const output = await FileWidgetObj.fileUploadFun(
        "register_from",
        "",
        "",
        data
      );
      if (output.status !== false) {
        let inputData = {
          display_file_name: this.state.display_file_name
            ? this.state.display_file_name
            : "",
          document_type_slug: this.state.document_type_slug
            ? this.state.document_type_slug
            : "",
          file_name: this.state.file_name ? this.state.file_name : "",
          description: this.state.description ? this.state.description : "",
          media_type: 2,
          ref_id: this.state.slug ? this.state.slug : "",
          sort_order: this.state.sort_order ? this.state.sort_order : "",
          status_id: this.state.status_id === true ? 1 : 2,
          availableFiles: output.response,
          deletedFiles: this.state.deletedFileList,
          docsSlug: this.state.prop_doc_slug ? this.state.prop_doc_slug : "",
          property_slug: this.state.property_slug
            ? this.state.property_slug
            : "",
        };

        if (this.state.prop_doc_slug !== undefined) {
          // For active status 1:Active, 2:Inactive
          inputData.status_id = this.state.status_id === true ? 1 : 2;
          let res = await MultiImageService.submitMultiImage(inputData);
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            setTimeout(
              () => this.props.history.push("/faqs"),
              global.redirect_time
            );
          } else {
            if (res.data.result.errorDetail) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
          }
        } else {
          if (output.response[0].filesData) {
            if (output.response[0].filesData.length > 0) {
              let res = await MultiImageService.submitMultiImage(inputData);
              if (global.successStatus.includes(res.status)) {
                this.setState({
                  showAlertModal: true,
                  alertModalType: "success",
                  alertModalTitle: "Success",
                  alertModalMessage: res.message ? res.message : "Success",
                });
                setTimeout(
                  () => this.props.history.push("/faqs"),
                  global.redirect_time
                );
              } else {
                if (res.data.result.errorDetail) {
                  let errors = {};
                  res.data.errorCode = "Validation Failed";
                  res.data.result.errorDetail.forEach((item) => {
                    errors[item.errorField] = item.errorMessage[0];
                    res.data.errorMessage = "";
                  });
                  this.setState({ errors: errors });
                }
              }
            } else {
              errors["document"] = "Please select recommended file size.";
              this.setState({ errors: errors });
            }
          }
        }
      } else {
        let checkError = output.response[0].validationMessage[0];

        if (checkError[0].includes("30 mb") === true) {
          errors["document"] = "The file should not be greater than 30 mb.";
          this.setState({ errors: errors });
        } else {
          errors["document"] =
            "The file should be pdf, doc, docx, xls, xlsx, csv, png, jpg.";
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
    this.setState({ selected_file_name: "" });
  };

  createFaq = async () => {
    //upload images
    // await this.saveOrUpdateAttachments();

    if (this.validator.allValid()) {
      let property = [];

      this.state.selectedPropertyList.forEach((item) => {
        property = [...property, item.value];
      });

      let inputData = {
        title: this.state.title,
        description: this.state.description,
        property_slug: property.toString(),

        // kb_category_slug: this.state.kb_category_slug
        //   ? this.state.kb_category_slug
        //   : "",
        // kb_sub_category_slug: this.state.kb_sub_category_slug
        //   ? this.state.kb_sub_category_slug
        //   : "",
        status_id: this.state.status_id === true ? 1 : 2,
        private_content: this.state.private_content === true ? 1 : 0,

        module_type: this.state.module_type ? this.state.module_type : "",
        sort_order: this.state.sort_order ? this.state.sort_order : "",
      };
      if (this.state.slug !== undefined) {
        let res = await FaqService.updateFaq(this.state.slug, inputData);

        //set state of sql to upload image
        if (global.successStatus.includes(res.status)) {
          let slugList = [];
          res.data.forEach((data) => {
            slugList = [...slugList, data.slug];
          });
          slugList.forEach(async (data) => {
            await this.saveOrUpdateAttachments(data);
          });
        }
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () => this.props.history.push("/faq/view/" + this.state.slug),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      } else {
        let res = await FaqService.createFaq(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () => this.props.history.push("/faqs"),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  setContent(value) {
    this.setState({
      description: value,
    });
  }

  handleChangePropertyList(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
      setTimeout(() => {
        if (property_slug !== null && property_slug.includes("A3B9C2DU1E6F")) {
          this.setState({
            selectedPropertyList: [
              { value: "A3B9C2DU1E6F", label: "All Properties" },
            ],
            property_slug: ["A3B9C2DU1E6F"],
          });
        } else {
          this.setState({
            selectedPropertyList: value,
            property_slug: value.value,
          });
        }
      }, 100);
    } else {
      this.setState({
        selectedPropertyList: [],
        property_slug: "",
      });
    }
  }

  // handleChangeKbCategoryList(value) {
  //   let kb_category_slug = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       kb_category_slug.push(item.value);
  //     });
  //   }
  //   const state_id_value = value !== null ? value.value : [];
  //   this.setState({
  //     selectedkbCategoryList: value,
  //     kb_category_slug: state_id_value,
  //   });
  // }

  handleChangeModuleList(value) {
    let module_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        module_type.push(item.label);
      });
    }

    this.setState({
      selectedModuleList: value,
      module_type: value.label,
    });
  }

  // handleChangeKbSubCategoryList(value) {
  //   let kb_sub_category_slug = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       kb_sub_category_slug.push(item.value);
  //     });
  //   }
  //   const state_id_value = value !== null ? value.value : [];
  //   this.setState({
  //     selectedkbSubCategoryList: value,
  //     kb_sub_category_slug: state_id_value,
  //   });
  // }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "FAQ";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-12">
                <div className="col-md-12 mg-t-20 mg-b-10 "></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.state.slug !== undefined
                                ? this.props.history.push(
                                    "/faq/view/" + this.state.slug
                                  )
                                : this.props.history.push("/faqs");
                            else return false;
                          else
                            this.state.slug !== undefined
                              ? this.props.history.push(
                                  "/faq/view/" + this.state.slug
                                )
                              : this.props.history.push("/faqs");
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        className="btn-success ml-3"
                        type="button"
                        onClick={this.createFaq}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                          ? "Save"
                          : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <form id="event_form">
                    <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="m-0">
                        <div className="row align-items-center col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-xs-l-0 pd-xs-r-0 pd-l-0 pd-r-0 border-bottom-0">
                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Module
                              </label>
                              <Select
                                styles={customStyles}
                                className="multiselect"
                                menuPlacement="auto"
                                value={this.state.selectedModuleList}
                                options={this.state.Module}
                                // getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangeModuleList(value)
                                }
                                defaultValue={this.state.selectedModuleList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                              {this.state.errors.catergory ? (
                                <div className="text-danger">
                                  {this.state.errors.catergory}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Module",
                                  this.state.module_type,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Property
                              </label>
                              <Select
                                isMulti
                                styles={customStyles}
                                className="multiselect"
                                menuPlacement="auto"
                                value={this.state.selectedPropertyList}
                                options={
                                  this.state.newPropertyList &&
                                  this.state.newPropertyList.length > 0
                                    ? this.state.newPropertyList
                                        .filter((opt) => {
                                          if (
                                            this.state.selectedPropertyList.some(
                                              (itm) =>
                                                itm.value === "A3B9C2DU1E6F"
                                            )
                                          ) {
                                            return (
                                              opt.value === "A3B9C2DU1E6F"
                                            );
                                          }
                                          return true;
                                        })
                                        .map((option) => ({
                                          ...option,
                                          isDisabled:
                                            this.state.selectedPropertyList.some(
                                              (itm) =>
                                                itm.value === "A3B9C2DU1E6F"
                                            ),
                                        }))
                                    : []
                                }
                                getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangePropertyList(value)
                                }
                                defaultValue={this.state.selectedPropertyList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                              {this.state.errors.selectedPropertyList ? (
                                <div className="text-danger">
                                  {this.state.errors.selectedPropertyList}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Property",
                                  this.state.selectedPropertyList,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>

                          {/* <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Category
                                <span className="optional">(Optional)</span>
                              </label>
                              <Select
                                styles={customStyles}
                                isClearable={true}
                                className="multiselect"
                                menuPlacement="auto"
                                value={this.state.selectedkbCategoryList}
                                options={this.state.newkbCategoryList}
                                getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangeKbCategoryList(value)
                                }
                                defaultValue={this.state.selectedkbCategoryList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Sub Category
                                <span className="optional">(Optional)</span>
                              </label>
                              <Select
                                styles={customStyles}
                                isClearable={true}
                                className="multiselect"
                                menuPlacement="auto"
                                value={this.state.selectedkbSubCategoryList}
                                options={this.state.newkbSubCategoryList}
                                getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangeKbSubCategoryList(value)
                                }
                                defaultValue={
                                  this.state.selectedkbSubCategoryList
                                }
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                            </div>
                          </div> */}

                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Sort Order{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="sort_order"
                                value={this.state.sort_order}
                                autoComplete="off"
                              />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Private{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Check
                                name="private_content"
                                type={"checkbox"}
                                id={`private_content`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.private_content}
                              />
                            </div>
                          </div>

                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Question{" "}
                                {/* <span className="optional">(Optional)</span> */}
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="title"
                                value={this.state.title || ""}
                                autoComplete="off"
                              />
                              {this.state.errors.title ? (
                                <div className="text-danger">
                                  {this.state.errors.title}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Question",
                                  this.state.title,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>

                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Answer{" "}
                                {/* <span className="optional">(Optional)</span> */}
                              </label>

                              <JoditEditor
                                config={this.editorConfig}
                                ref={null}
                                name="description"
                                id="description"
                                value={this.state.description}
                                onBlur={(newContent) =>
                                  this.setContent(newContent)
                                }
                                onChange={(newContent) =>
                                  this.setContent(newContent)
                                }
                                rows="5"
                              />
                              {this.state.errors.description ? (
                                <div className="text-danger">
                                  {this.state.errors.description}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Answer",
                                  this.state.description,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                              <div className="text-danger">
                                {this.state.imageorvideo_validation}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-30 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15 d-none">
                            <div className="documnet-section-upload">
                              <label className="form-label text-left pd-b-5">
                                Upload File
                                <span className="optional">(Optional)</span>
                              </label>
                              <form
                                id="register_from"
                                className="text-left d-none"
                              >
                                <div className="d-flex flex-row align-items-center upload upload-files-section">
                                  <FileWidget
                                    fieldName="file_name"
                                    fieldTitle="Select File"
                                    checkValidation="true"
                                    allowedType="image/png,image/jpg,text/csv,application/*,doc,docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                                    maxFileSize={30}
                                    maxFileSizeType="mb"
                                    maxFileAllowed={10}
                                    uploadOn="s3"
                                    slug={this.state.slug}
                                    refTableId="23"
                                    displayFileName={
                                      this.state.display_file_name
                                    }
                                    documentTypeSlug={
                                      this.state.document_type_slug
                                    }
                                    // description={this.state.description}
                                    mediaType="2"
                                    sortOrder={
                                      this.state.sort_order !== ""
                                        ? this.state.sort_order
                                        : 0
                                    }
                                    statusId={
                                      this.state.status_id === true ? 1 : 2
                                    }
                                    helpText="to download sample file."
                                    sampleFileUrl="/sample.jpg"
                                    drag="true"
                                    dragText="Select or drop file size upto 30 MB"
                                    inputClassName="form-control"
                                    labelClassName="form-label"
                                    formID="property_document_form"
                                    uploadType="multiple"
                                    resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
                                    property_slug={
                                      this.state.property_slug
                                        ? this.state.property_slug
                                        : null
                                    }
                                    destinationFolder="knowledge_base"
                                    uploadedFiles={this.state.faqImage}
                                    deletedFiles={this.state.deletedFileList}
                                    acceptOnly="image/png,image/jpg,text/csv,application/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                                    displayPreviewStyle="horizontal"
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center pd-b-30 pd-l-30 pd-r-30 border-bottom-0">
                      <div className="col-md-1">
                        <label className="form-label text-left pd-b-5">
                          Status
                        </label>
                      </div>
                      <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                        <b
                          className={
                            this.state.status_id ? "active" : "inactive"
                          }
                        ></b>
                        <div
                          className={
                            this.state.status_id ? "az-toggle on" : "az-toggle"
                          }
                          onClick={() =>
                            this.setState({
                              status_id: !this.state.status_id,
                            })
                          }
                        >
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default FaqsForm;
