////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ReservationForm.jsx                                //
//  Application:  Reservation                                   //
//  Option: For add or update Reservation                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-04-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";

import { Container, Form } from "react-bootstrap";
// import { Link } from "react-router-dom";
import Alerts from "../../common/Alerts";
import Moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import UnitsService from "../../../services/UnitsService";
import ListingService from "../../../services/ListingService";
// import GuestService from "../../../services/GuestService";
import KeycafeService from "../../../services/KeycafeService";
//import ReservationService from "../../../services/ReservationService";
// import CountryService from "../../../services/CountryService";
// import ChannelService from "../../../services/ChannelService";
import Select from "react-select";
import DatePicker from "react-datepicker";
//import Moment from "moment";
// import CalendarSidebar from "../../common/CalendarSidebar";
import InputMask from "react-input-mask";
// import PhoneInput from "react-phone-input-2";
// import PropertyService from "../../../services/PropertyService";
// import TaxService from "../../../services/TaxService";
// import StarRatings from "react-star-ratings";
// import GooglePlaces from "../../common/GooglePlaces";
// import PropertyCostService from "../../../services/PropertyCostService";
// import deleteicon from "../../../assets/images/delete.svg";
// import pluswIcon from "./../../../assets/images/plus.svg";
// import PetPolicyService from "../../../services/PetPolicyService";
// import NumberFormat from "react-number-format";
// import UserService from "../../../services/UserService";
export class ActivityAdd extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      open: true,
      eventKey: "3",
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      lisitngList: [],
      newListingList: [],
      selectedListingList: [],
      status_id: [],
      set_channel: "",
      pathname: window.location.pathname,
      importModal: false,
      confirm_back: 0,
      activeClass: "basic",
      confirmation_code: "",
      unit_slug: [],
      checkout_date: "",
      guest_slug: [],
      guestList: [],
      newGuestList: [],
      selectedGuestList: [],
      adults: "",
      children: "",
      infants: "",
      pets: "",
      nightly_rate: "",
      total_stay_nights: 0,
      guest_paid: "",
      host_payout: "",
      pet_fee: "",
      security_deposite: "",
      isGuestExist: 0,
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      end_date: "",
      email: "",
      email_slug: "",
      access_start_time: "",
      acess_end_time: "",
      unit_name: "",
      access_start_date: "",
      access_end_date: "",
      access_code: "",
      key_name: "",
      keycafe_key_id: "",
      fob: "",
      key_type: "",
      // acess_end_time:"",
      // access_start_time:"",
    };
    this.changeHandler = this.changeHandler.bind(this);
    // this.saveOrUpdateKey = this.saveOrUpdateKey.bind(this);
    // this.timeFormat = this.timeFormat.bind(this);
  }

  changeHandler = (event) => {
    let input = this.state.input;
    //console.log(input);

    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    // if (event.target.name === "access_start_date") {
    //   let fromDateVal = Moment(event.target.value, "MM-DD-YYYY").toDate();
    //   let fromDateValue = Moment(fromDateVal).format("YYYY-MM-DD");
    //   // this.getReservationMaxDate(this.props.unit_slug, this.props.listing_slug, fromDateValue);
    //   if (this.state.viewMode === 1) {
    //     this.setState({ access_end_date: "" });
    //   }
    // }
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    // setTimeout(() => {
    //   const currentDate = new Date();
    //   var checkinDateCheck = Moment(this.state.start_date).format(
    //     "MM/DD/YYYY"
    //   );
    //   var dateCheck = Moment(currentDate).format("MM/DD/YYYY");
    //   var d1 = checkinDateCheck.split("/");
    //   var c = dateCheck.split("/");
    //   var checkInDate = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
    //   var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

    //   if (check > checkInDate) {
    //     this.setState({ cDate: 1 });
    //   } else {
    //     this.setState({ cDate: 0 });
    //   }
    // }, 0);
  };

  // changeHandler1 = (event) => {
  //   let input = this.state.input;
  //   // console.log("email", input.email);
  //   this.setState({ email: input.email });
  //   this.getEmailData(this.state.email);
  // };

  async componentDidMount() {
    // this.getStatusList();
    // this.getChannelList();
    // this.getEmailData();
    this.getUnitData();
    this.getData();
    // this.getkeyData();
    // this.getPropertyEarningConfigurationData();

    this.getListingData();
  }

  /* to get slug  on behalf email ID */
  // async getEmailData(email) {
  //   // this.setState({ showSpinner: true, loading: true });
  //   var emailList = [];
  //   var unitListTotal = 0;
  //   let res = await KeycafeService.UserEmaildetail(
  //     "&email=" + email
  //     // queryString ? "&" + queryString : ""
  //   );

  //   if (global.successStatus.includes(res.status)) {
  //     if (res.data) {
  //       emailList = res.data ? res.data : [];
  //       // console.log("emails", emailList.slug);
  //       unitListTotal = emailList ? emailList.length : 0;
  //       this.setState({
  //         email_slug: emailList.slug,
  //         unitListTotal: unitListTotal,
  //       });
  //     }
  //   }

  //   // this.setState({ showSpinner: false, loading: false });
  // }

  async getData(tab, queryString = "") {
    if (this.state.slug !== undefined) {
      var resData = {};
      var totalRecords = 0;
      let res = await KeycafeService.getKeycafeAccessdetail(
        "property_slug=" +
          this.state.property_slug +
          "&key_slug=" +
          this.state.slug +
          "&tab=" +
          (tab ? tab : this.state.tab) +
          (queryString ? "&" + queryString : "")
      );
      console.log("res", res);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data.data[0] : {};
        totalRecords = res.data ? res.data.total : 0;
        resData.unit_name = resData.unit_name ? resData.unit_name : "";
        resData.access_start_date = resData.access_start_date
          ? Moment(resData.access_start_date).toDate("MM/dd/yy")
          : "";
        resData.access_end_date = resData.access_end_date
          ? Moment(resData.access_end_date).toDate("MM/dd/yy")
          : "";
        resData.email = resData.email ? resData.email : "";
        resData.access_code = resData.access_code ? resData.access_code : "";
        resData.key_name = resData.key_name ? resData.key_name : "";
        resData.keycafe_key_id = resData.keycafe_key_id
          ? resData.keycafe_key_id
          : "";
        resData.fob = resData.fob ? resData.fob : "";
        resData.access_start_time = resData.access_start_time
          ? resData.access_start_time
          : "";
        resData.acess_end_time = resData.acess_end_time
          ? resData.acess_end_time
          : "";
        resData.key_type = resData.key_type ? resData.key_type : "";
        // resData.acess_end_time = resData.acess_end_time ? Moment(resData.acess_end_time, "hh").format('LT') : "";
        var selectedUnitList = [];
        selectedUnitList = resData.unit_name
          ? [
              {
                value: resData.unit_slug ? resData.unit_slug : "",
                label: resData.unit_name
                  ? resData.unit_name
                  : resData.unit_number,
              },
            ]
          : "";
      }
      this.setState(resData);

      this.setState({
        // selectedUnitList: value,
        // unit_number: value.label,
        total_record: totalRecords,
        records: resData,
        isLoaded: true,
        unit_name: resData.unit_name ? resData.unit_name : "",
        access_start_date: resData.access_start_date
          ? resData.access_start_date
          : "",
        access_end_date: resData.access_end_date ? resData.access_end_date : "",
        email: resData.email ? resData.email : "",
        access_code: resData.access_code ? resData.access_code : "",
        key_name: resData.key_name ? resData.key_name : "",
        keycafe_key_id: resData.keycafe_key_id ? resData.keycafe_key_id : "",
        fob: resData.fob ? resData.fob : "",
        access_start_time: resData.access_start_time
          ? resData.access_start_time
          : "",
        acess_end_time: resData.acess_end_time ? resData.acess_end_time : "",
        selectedUnitList: selectedUnitList,
      });
    }

    // this.setState(resData);
  }

  async timeFormat(time) {
    // let timess = '';
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes;
  }

  userData = async () => {
    if (this.validator.allValid()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        access_start_date: this.state.access_start_date
          ? Moment(this.state.access_start_date).format("YYYY-MM-DD")
          : "",
        access_end_date: this.state.access_end_date
          ? Moment(this.state.access_end_date).format("YYYY-MM-DD")
          : "",
        accessStartTime: this.state.access_start_time,
        accessEndTime: this.state.acess_end_time,
        property_slug: this.state.property_slug,
        email: this.state.email ? this.state.email : "",
        unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
        // convertedTime : Moment(this.state.state.end_time, 'hh:mm A').format('HH:mm'),
        user_slug: this.state.email_slug ? this.state.email_slug : 0,
        key_name: this.state.key_name ? this.state.key_name : "",
        from_where: 4,
      };
      if (this.state.slug !== undefined) {
        //  console.log("test_slug",this.state.slug,"input-update =>",inputData);
        let res = await KeycafeService.getkeycafeaccessupdate(
          this.state.slug,
          inputData
        );
        // this.state.slug,inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" + this.state.property_slug + "/keys"
                  : "/keys/activity-view/" +
                      this.state.property_slug +
                      "/" +
                      this.state.slug
              ),
            global.redirect_time
          );
        } else {
          // console.log("error check update=>",res.data.message);
          if (res.data) {
            var mesgs = res.data.message
              ? res.data.message
              : "Validation Failed";
            this.setState({
              showAlertModal: true,
              alertModalType: "error",
              alertModalTitle: "error",
              alertModalMessage: mesgs,
            });
          }
        }
      } else {
        //  console.log("input=>", inputData);
        let res = await KeycafeService.getkeycafeaccess(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" + this.state.property_slug + "/keys"
                  : "/keys"
              ),
            global.redirect_time
          );
        } else {
          //   console.log("error=>",res);
          if (res.data) {
            var mesg = res.data.message
              ? res.data.message
              : "Validation Failed";
            this.setState({
              showAlertModal: true,
              alertModalType: "error",
              alertModalTitle: "error",
              alertModalMessage: mesg,
            });

            // this.setState({ errors: errors });
          }
        }
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;
    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get listing data list */
  async getListingData(queryString = "") {
    var listingList = [];
    var listingListTotal = 0;

    let res = await ListingService.getListingList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      listingList = res.data ? res.data : [];
      listingListTotal = listingList ? listingList.length : 0;
    }
    const newListingList =
      listingListTotal > 0
        ? listingList.map(({ slug, listing_name, unit }) => ({
            value: slug,
            label: unit
              ? listing_name + (unit ? " (" + unit.unit_number + ") " : "")
              : listing_name,
          }))
        : [];
    this.setState({
      listingList: listingList,
      listingListTotal: listingListTotal,
      newListingList: newListingList,
    });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_number: value.label,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
    // console.log(unit_slug_value);
  }

  // datechnageHandle = (event) => {
  //   const values = [...this.state.resolutionFeeData];
  //   if (event.target.type === "date" && event.target.value !== null) {
  //     values[event.target.index].payment_received_date = Moment(
  //       event.target.value
  //     ).toDate();
  //   }
  //   this.setState({ resolutionFeeData: values, resolution_fee_data: values });
  // };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  handleChangeStatus(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    const status_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStatusList: value,
      status_id: status_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeChannel(value) {
    let set_channel = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        set_channel.push(item.value);
      });
    }
    const set_channel_value = value !== null ? value.value : [];
    this.setState({
      selectedChannelList: value,
      set_channel: set_channel_value,
    });
    this.setState({ confirm_back: 1 });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update Access";
    } else {
      return "Add Access";
    }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      {this.state.slug !== undefined ? (
                        <button
                          type="button"
                          className="btn-success-outline-small"
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/keys"
                                    : "/keys/activity-view/" +
                                        this.state.property_slug +
                                        "/" +
                                        this.state.slug
                                );
                              else return false;
                            else
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/keys"
                                  : "/keys"
                              );
                          }}
                        >
                          Cancel
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn-success-outline-small"
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/keys"
                                    : "/keys/activity-view/" +
                                        this.state.property_slug +
                                        "/" +
                                        this.state.slug
                                );
                              else return false;
                            else
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/keys"
                                  : "/keys"
                              );
                          }}
                        >
                          Cancel
                        </button>
                      )}

                      {
                        <button
                          type="button"
                          className="btn-success ml-3"
                          onClick={this.userData}
                          disabled={this.state.isSubmit ? true : false}
                        >
                          {this.state.isSubmit
                            ? global.loader
                            : this.state.slug !== undefined
                            ? "Update"
                            : "Save"}
                        </button>
                      }
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-lg-l-15 pd-sm-l-30 pd-sm-r-30 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <form id="reservation_form">
                      <div className="m-0">
                        <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Unit
                            </label>
                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedUnitList}
                              options={this.state.newUnitList}
                              onChange={(value) =>
                                this.handleChangeUnitList(value)
                              }
                              defaultValue={this.state.selectedUnitList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Start Date
                            </label>

                            <div className="col-6 col-md-8 col-lg-8 col-xl-8 col-xxl-8 p-0 float-left">
                              <DatePicker
                                className="form-control max_width_100"
                                disabledKeyboardNavigation
                                strictParsing
                                scrollableYearDropdown
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="access_start_date"
                                excludeDates={this.state.disabled_dates}
                                dateFormat="MM/dd/yy "
                                minDate={new Date()}
                                selected={this.state.access_start_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "access_start_date",
                                      value: data,
                                    },
                                  })
                                }
                              />
                              {this.state.errors.access_start_date ? (
                                <div className="text-danger">
                                  {this.state.errors.access_start_date}
                                </div>
                              ) : (
                                this.validator.message(
                                  "access_start_date",
                                  this.state.access_start_date,
                                  "access_start_date|required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 p-0 float-left">
                              <InputMask
                                name="access_start_time"
                                onChange={this.changeHandler}
                                style={{
                                  textTransform: "uppercase",
                                  display: "inline",
                                }}
                                value={
                                  this.state.access_start_time !== ""
                                    ? this.state.access_start_time || ""
                                    : "00:00 AM"
                                }
                                mask="99:99 aa"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              End Date
                            </label>
                            <div className="col-6 col-md-8 col-lg-8 col-xl-8 col-xxl-8 p-0 float-left">
                              <DatePicker
                                className="form-control max_width_100"
                                disabledKeyboardNavigation
                                strictParsing
                                scrollableYearDropdown
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                // minDate={Moment().toDate()}
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                minDate={
                                  this.state.access_start_date
                                    ? this.state.access_start_date
                                      ? new Date(
                                          this.state.access_start_date
                                        ).setDate(
                                          new Date(
                                            this.state.access_start_date
                                          ).getDate()
                                        )
                                      : new Date()
                                    : new Date()
                                }
                                name="access_end_date"
                                excludeDates={this.state.disabled_dates}
                                dateFormat="MM/dd/yy"
                                selected={this.state.access_end_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "access_end_date",
                                      value: data,
                                    },
                                  })
                                }
                              />
                              {this.state.errors.access_end_date ? (
                                <div className="text-danger">
                                  {this.state.errors.access_end_date}
                                </div>
                              ) : (
                                this.validator.message(
                                  "access_end_date",
                                  this.state.access_end_date,
                                  "access_end_date|required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 p-0 float-left">
                              <InputMask
                                name="acess_end_time"
                                onChange={this.changeHandler}
                                style={{ textTransform: "uppercase" }}
                                value={
                                  this.state.acess_end_time !== ""
                                    ? this.state.acess_end_time || ""
                                    : "00:00 AM"
                                }
                                mask="99:99 aa"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Email<span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              type="email"
                              onChange={this.changeHandler}
                              name="email"
                              value={this.state.email || ""}
                              maxLength="50"
                            />
                            {this.state.errors.email ? (
                              <div className="text-danger">
                                {this.state.errors.email}
                              </div>
                            ) : (
                              this.validator.message(
                                "email",
                                this.state.email,
                                "email",
                                { className: "text-danger" }
                              )
                            )}
                          </div>

                          {this.state.slug !== undefined ? (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Visitor Name
                              </p>
                              <p className="media-body">
                                {this.state.key_name ? this.state.key_name : ""}
                              </p>
                            </div>
                          ) : (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Visitor Name
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="key_name"
                                value={this.state.key_name || ""}
                                maxLength="50"
                              />
                            </div>
                          )}
                          {this.state.slug !== undefined ? (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Key ID
                              </p>
                              <p className="media-body">
                                {this.state.keycafe_key_id
                                  ? this.state.keycafe_key_id
                                  : ""}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {/* {
                            this.state.slug !== undefined ? (  
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                          <p className="az-profile-name-text pl-0">
                              Fob #
                              </p>
                              <p className="media-body">
                               
                              </p>
                          </div>

                            ):''
                            
                          
                      } */}
                          {this.state.slug !== undefined ? (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                              <p className="az-profile-name-text pl-0">Type</p>
                              <p className="media-body">
                                {this.state.key_type ? this.state.key_type : ""}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default ActivityAdd;
