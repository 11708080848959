//     							                               //
//  Program: UsersForm.jsx                                     //
//  Application: users add or update                           //
//  Option: Users Add or update                                //
//  Developer: Mitesh Prajapati 		                       //
//  Date: 2022-09-06                                           //
//                                                             //
/////////////////////////////////////////////////////////////////

import React, { Component } from "react";
import {
  Button,
  Container,
  Form,
  Tab,
  Nav,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import AuthenticationService from "../../services/AuthenticationService";
import UserService from "../../services/UserService";
import ClientService from "../../services/ClientService";
import Alerts from "../common/Alerts";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import PropertyService from "../../services/PropertyService";
import RolePermissionService from "../../services/RolePermissionService";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import NoData from "../common/NoData";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment-timezone";
import UsersSidebar from "../common/UsersSidebar";
import renderHTML from "react-render-html";
import BrandService from "../../services/BrandService";

class UsersForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
    });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      status_id: true,
      pathname: window.location.pathname,
      confirm_back: 0,
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      countryListTotal: 0,
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      is_admin: false,
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      cityListTotal: 0,
      is_id_verified: false,
      propertyList: [],
      propertyListTotal: 0,
      newPropertyList: [],
      selectedPropertyList: [],
      phone_no: "",
      roleList: [],
      isSearchModal: 0,
      roleListTotal: 0,
      newRoleList: [],
      newRoleGroupList: [],
      clientList: [],
      selectedClientList: [],
      selectedBrandList: [],
      newClientList: [],
      newBrandList: [],
      clientListTotal: 0,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      phone_number: "",
      selectedRoleList: [],
      role_id: "",
      activeClass: "activeTab",
      tab: "active",
      is_email: true,
      is_sms: true,
      is_notification: true,
      is_property_level: false,
      is_brand_level: false,
      is_client_level: false,
      save_user_error: false,
      all_properties: false,
      selectedRoleGroupList: [],
      usertabData: localStorage.getItem("user_tab_data")
        ? localStorage.getItem("user_tab_data")
        : "",
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "response title",
          text: "Action Name",
          className: "hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.activity_title;
          },
        },
        {
          key: "device-type",
          text: "Platform",
          className: "hand-cursor",
          sortable: false,
          cell: (record) => {
            if (record.device_type === "1") {
              return "iOS";
            } else if (record.device_type === "2") {
              return "Android";
            } else if (record.device_type === "3") {
              return "OHAI";
            } else if (record.device_type === "4") {
              return "Web";
            } else {
              return "N/A";
            }
          },
        },
        {
          key: "response_msg",
          text: "Response Message",
          className: "hand-cursor",
          sortable: false,
          cell: (record) => {
            if (record.activity_title === "T&C Accepted") {
              let StringInArray = record.response_msg
                ? record.response_msg.split('"')
                : null;
              let Link = StringInArray !== null ? StringInArray[1] : null;
              let reponse =
                Link !== null ??
                renderHTML(
                  `User Accepted <u><a href="${Link}" target='_blank'> Terms and condition </a>`
                );
              return reponse
                ? renderHTML(
                    `User Accepted <u><a href="${Link}" target='_blank'> Terms and condition </a>`
                  )
                : "";
            } else {
              return record.response_msg;
            }
          },
        },
        {
          key: "created_at",
          text: "Created At",
          className: "hand-cursor text-left",
          sortable: false,
          cell: (record) => {
            return moment(moment.utc(record.created_at).toDate())
              .local()
              .format(global.dateTimeFormat);
          },
        },
      ],
    };

    this.initialState = this.state;
    this.defaultData = {};
    this.handleChangePropertyList = this.handleChangePropertyList.bind(this);
    this.handleChangeClientList = this.handleChangeClientList.bind(this);
    this.handleChangeBrandList = this.handleChangeBrandList.bind(this);
    this.handleChangeRoleList = this.handleChangeRoleList.bind(this);
    this.handleChangeRoleGroupList = this.handleChangeRoleGroupList.bind(this);
    this.SendGenerateEmail = this.SendGenerateEmail.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.getRoleGroupData = this.getRoleGroupData.bind(this);
  }

  componentDidMount() {
    document.getElementById("activeTab").click();
    this.getData();
  }

  searchToggle = () => {
    if (this.state.isSearchModal === 1) {
      this.setState({ isSearchModal: 0 });
    } else {
      this.setState({ isSearchModal: 1 });
    }
  };

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    if (this.state.slug !== undefined) {
      let res = await AuthenticationService.getUserDetails(this.state.slug);
      let userSettingResponse = await AuthenticationService.getUserSetting(
        this.state.slug
      );
      // let userProperyMapping =
      //   await AuthenticationService.getUserClintBrandData(this.state.slug);
      if (
        global.successStatus.includes(res.status) &&
        global.successStatus.includes(userSettingResponse.status)
      ) {
        this.state.first_name = res.data.user_data
          ? res.data.user_data.first_name
          : "";
        this.state.last_name = res.data.user_data
          ? res.data.user_data.last_name
          : "";
        this.state.email = res.data.user_data ? res.data.user_data.email : "";
        this.state.password = res.data.user_data
          ? res.data.user_data.password
          : "";
        this.state.phone_number = res.data.user_data
          ? res.data.user_data.phone_number
          : "";
        this.state.role_id = res.data.user_data
          ? res.data.user_data.role_slug
          : "";
        this.state.role_group = res.data.user_data
          ? res.data.user_data.role_group
          : "";
        this.state.set_password = res.data.user_data
          ? res.data.user_data.is_password_set_mail
          : null;

        this.state.set_password_at = res.data.user_data
          ? moment(
              moment.utc(res.data.user_data.is_password_set_mail_at).toDate()
            )
              .local()
              .format(global.dateTimeFormat)
          : null;

        this.state.slug = res.data.user_data ? res.data.user_data.slug : "";

        this.state.status_id = res.data.user_data
          ? res.data.user_data.status_id === 1
            ? true
            : false
          : false;

        this.state.is_email = userSettingResponse.data
          ? userSettingResponse.data.is_email
          : null;

        this.state.is_sms = userSettingResponse.data
          ? userSettingResponse.data.is_sms
          : null;

        this.state.is_notification = userSettingResponse.data
          ? userSettingResponse.data.is_notification
          : null;

        this.state.all_properties = res.data.user_data
          ? res.data.user_data.all_properties
          : null;
        var selectedRoleList = [];

        res.data.roleList.length > 0 &&
          res.data.roleList.forEach((item, i) => {
            selectedRoleList = [
              ...selectedRoleList,
              {
                value: item.slug,
                label: item.role_title,
                group: item.role_group,
              },
            ];
          });

        const groups = {
          is_admin: 1,
          is_property_level: 3,
          is_brand_level: 2,
          is_client_level: 3,
        };

        const newState = {};
        if (this.state.selectedRoleList.length > 0) {
          for (const [key, group] of Object.entries(groups)) {
            newState[key] = this.state.selectedRoleList.some(
              (item) => item.group === group
            );
          }
          this.setState(newState);
        }
        var selectedRoleGroupList = [];

        res.data.roleGroupList.length > 0 &&
          res.data.roleGroupList.forEach((item, i) => {
            selectedRoleGroupList = [
              ...selectedRoleGroupList,
              {
                value: item.role_group,
                label: item.role_group_name,
              },
            ];
          });

        var selectedPropertyList = [];

        res.data.propertyList.length > 0 &&
          res.data.propertyList.forEach((item, i) => {
            selectedPropertyList = [
              ...selectedPropertyList,
              {
                value: item.slug,
                label: item.property_name,
              },
            ];
          });

        var selectedClientList = [];
        res.data.clientList.length > 0 &&
          res.data.clientList.forEach((item, i) => {
            selectedClientList = [
              ...selectedClientList,
              {
                value: item.slug,
                label: item.client_name,
              },
            ];
          });

        var selectedBrandList = [];
        res.data.brandList.length > 0 &&
          res.data.brandList.forEach((item, i) => {
            selectedBrandList = [
              ...selectedBrandList,
              {
                value: item.slug,
                label: item.brand_name,
              },
            ];
          });
      }
    }
    this.setState({
      showSpinner: false,
      loading: false,
    });
    this.setState({
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      password: this.state.password,
      phone_number: this.state.phone_number,
    });
    this.setState({
      selectedRoleList: selectedRoleList,
      selectedPropertyList: selectedPropertyList,
      selectedClientList: selectedClientList,
      selectedBrandList: selectedBrandList,
      selectedRoleGroupList: selectedRoleGroupList,
    });

    this.setState({ tab: "activeTab" });

    const groups = {
      is_admin: 1,
      is_property_level: 3,
      is_brand_level: 2,
      is_client_level: 6,
    };

    const newState = {};
    if (this.state.selectedRoleList.length > 0) {
      for (const [key, group] of Object.entries(groups)) {
        newState[key] = this.state.selectedRoleList.some(
          (item) => item.group === group
        );
      }
      this.setState(newState);
    }

    this.defaultData = resData;
    this.getClientData();
    this.getRoleData();
    this.getRoleGroupData();
    this.getBrandList();

    //get peroperties based on client and brand list if neither of then selected the get all peroperties
    if (
      this.state.selectedBrandList &&
      this.state.selectedBrandList.length > 0
    ) {
      this.getPropertiesWithBrands(selectedBrandList);
    } else if (
      this.state.selectedClientList &&
      this.state.selectedClientList.length > 0
    ) {
      this.getPropertiesWithClients(selectedClientList);
    } else {
      this.getPropertyData();
    }
  }

  async getPropertyData(queryString = "", fromChangeHandaler = false) {
    this.setState({ showSpinner: true, loading: true });
    var propertyList = [];
    var newPropertyList = [];
    var propertyListTotal = 0;

    let res = await PropertyService.getPropertyList(
      "is_dropdown=1&is_user=1" + (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;

      newPropertyList =
        propertyListTotal > 0
          ? propertyList.map(({ slug, property_name }) => ({
              value: slug,
              label: property_name,
            }))
          : [];
    }
    this.setState({
      propertyList: propertyList,
      newPropertyList: newPropertyList,
    });

    if (fromChangeHandaler) {
      this.setState({ selectedPropertyList: newPropertyList });
    }

    this.setState({ showSpinner: false, loading: false });
  }

  async getClientData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var clientList = [];
    var clientListTotal = 0;
    let res = await ClientService.getClientList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      clientList = res.data ? res.data : [];
      clientListTotal = clientList ? clientList.length : 0;
    }
    const newClientList =
      clientListTotal > 0
        ? clientList.map(({ slug, client_name }) => ({
            value: slug,
            label: client_name,
          }))
        : [];
    this.setState({
      clientList: clientList,
      newClientList: newClientList,
      clientListTotal: clientListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getBrandList(queryString = "", is_from_dropdown = false) {
    this.setState({ showSpinner: true, loading: true });
    var BrandList = [];
    var BrandListTotal = 0;

    let res = await BrandService.getBrandList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      BrandList = res.data ? res.data : [];
      BrandListTotal = BrandList ? BrandList.length : 0;
    }
    if (!is_from_dropdown) {
      const newBrandList =
        BrandListTotal > 0
          ? BrandList.map(({ slug, brand_name }) => ({
              value: slug,
              label: brand_name,
            }))
          : [];

      this.setState({
        BrandList: BrandList,
        newBrandList: newBrandList,
        BrandListTotal: BrandListTotal,
      });
    } else {
      const newBrandList =
        BrandListTotal > 0
          ? BrandList.map(({ slug, brand_name }) => ({
              value: slug,
              label: brand_name,
            }))
          : [];
      this.setState({
        BrandList: BrandList,
        newBrandList: newBrandList,
        BrandListTotal: BrandListTotal,
      });
      var selectedBrandList = [];
      selectedBrandList =
        BrandListTotal > 0
          ? BrandList.map(({ slug, brand_name }) => ({
              value: slug,
              label: brand_name,
            }))
          : [];

      this.setState({
        selectedBrandList: selectedBrandList,
      });
    }

    this.setState({ showSpinner: false, loading: false });
  }

  async getRoleData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var roleList = [];
    var newRoleList = [];
    var roleListTotal = 0;

    let res = await RolePermissionService.getRoles(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      roleList = res.data ? res.data : [];
      roleListTotal = roleList ? roleList.length : 0;

      newRoleList = roleList =
        roleListTotal > 0
          ? roleList.map(({ slug, role_title, role_group }) => ({
              value: slug,
              label: role_title,
              group: role_group,
            }))
          : [];
    }
    this.setState({ roleList: roleList, newRoleList: newRoleList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getRoleGroupData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var roleGroupList = [];
    var newRoleGroupList = [];
    var roleGroupListTotal = 0;

    let res = await RolePermissionService.getRoleGroups(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      roleGroupList = res.data ? res.data.roleGroupList : [];
      roleGroupListTotal = roleGroupList ? roleGroupList.length : 0;
      newRoleGroupList =
        roleGroupListTotal > 0
          ? roleGroupList.map(({ value, key }) => ({
              value: value,
              label: key,
            }))
          : [];
    }
    this.setState({
      roleGroupList: roleGroupList,
      newRoleGroupList: newRoleGroupList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  handleChangePropertyList(value) {
    const property_id = (value || []).map((item) => item.value);
    // const all_properties = !!(value && value.length > 0);

    this.setState({
      selectedPropertyList: value,
      property_id,
    });
  }

  async handleChangeClientList(value) {
    let client_id = [];

    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        client_id.push(item.value);
      });
    }
    if (value != null && value.length > 0) {
      this.setState({
        selectedClientList: value != null ? value : [],
        client_id: value.value != null ? value.value : [],
      });
    } else {
      this.setState({
        selectedClientList: [],
        brand_id: [],
        all_properties: false,
      });
    }
    await this.getPropertiesWithClients(value, true);
    this.setState({ confirm_back: 1 });
  }

  async handleChangeBrandList(value) {
    let brand_id = [];

    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        brand_id.push(item.value);
      });
    }

    if (value != null && value.length > 0) {
      this.setState({
        selectedBrandList: value != null ? value : [],
        brand_id: value.value != null ? value.value : [],
      });
    } else {
      this.setState({
        selectedBrandList: [],
        brand_id: [],
        all_properties: false,
      });
    }

    //get properties of selected brands
    await this.getPropertiesWithBrands(value, true);

    // this.setState({ all_properties: false });
    this.setState({ confirm_back: 1 });
  }

  getPropertiesWithBrands(value = null, fromChangeHandaler = false) {
    var brandSlugList = [];
    //fetch properties as brand change if all selected brans is selecred
    // this.setState({ selectedPropertyList: [] });
    value != null &&
      value.length > 0 &&
      value.forEach((data) => {
        brandSlugList = [...brandSlugList, data.value];
      });
    let brandListString = brandSlugList.toString();
    this.getPropertyData(
      `is_all_properties=2&brands=${brandListString}`,
      fromChangeHandaler
    );
    // this.setState({ all_properties: false });
  }

  getPropertiesWithClients(value = null, fromChangeHandaler = false) {
    var clientSlugList = [];
    //fetch all the properties as client selectection change if all selcted clients si selcted
    // this.setState({ selectedPropertyList: [] });
    value != null &&
      value.length > 0 &&
      value.forEach((data) => {
        clientSlugList = [...clientSlugList, data.value];
      });

    let clientListString = clientSlugList.toString();
    this.getPropertyData(
      `is_all_properties=3&clients=${clientListString}`,
      fromChangeHandaler
    );

    // this.setState({ all_properties: false });
  }

  handleChangeRoleList(value) {
    let role_id = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        role_id.push(item.value);
      });
    }

    this.setState({
      selectedRoleList: value,
      role_id: value !== null ? value.value : null,
    });

    const groups = {
      is_admin: 1,
      is_property_level: 3,
      is_brand_level: 2,
      is_client_level: 6,
    };

    const newState = {};
    for (const [key, group] of Object.entries(groups)) {
      newState[key] =
        value !== null ? value.some((item) => item.group === group) : [];
    }

    // If no role selected then property list will be empty.
    if (value == null) {
      this.setState({ selectedPropertyList: [] });
    }

    this.setState(newState);
  }

  handleChangeRoleGroupList(value) {
    // Set new role group list as per secelcted role group
    const newRoleList = this.state.roleList.filter((data) =>
      value?.some((data1) => data.group === data1.value)
    );

    // If remove role group then also removes selected role from that role group
    const selectedRoleList =
      this.state.selectedRoleList &&
      this.state.selectedRoleList.length > 0 &&
      this.state.selectedRoleList.filter((data) =>
        value?.some((data1) => data.group === data1.value)
      );

    this.setState({
      selectedRoleGroupList: value,
      role_group_id: value?.[0]?.value || null,
      newRoleList,
    });

    if (selectedRoleList !== undefined) {
      this.setState({ selectedRoleList: selectedRoleList });
    }
  }

  changeHandler = (event) => {
    this.setState({ errors: {} });
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;

    this.setState({ [event.target.name]: value, input: input });

    if (event.target.type === "checkbox") {
      this.setState({ selectedPropertyList: [] });
      if (
        this.state.selectedBrandList &&
        this.state.selectedBrandList.length > 0
      ) {
        this.getPropertiesWithBrands(this.state.selectedBrandList, true);
      } else if (
        this.state.selectedClientList &&
        this.state.selectedClientList.length > 0
      ) {
        this.getPropertiesWithClients(this.state.selectedClientList, true);
      }
    }
    this.validator.showMessageFor(event.target.name);
  };

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("status_id");
    }
  }

  async SendGenerateEmail() {
    var IsResident = this.state.role_group === 4 ? true : false;

    let inputData = {
      email: this.state.email,
      is_website: 0,
      is_resident: IsResident,
    };
    this.setState({ showSpinner: true, isSend: true });
    var res = await UserService.SendEmail(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({ isSearchModal: 0 });
      this.setState({ showSpinner: false, isSend: false });
      // setTimeout(() => this.props.history.push("/users"), global.redirect_time);
    } else {
      if (res.data.result.errorDetail) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ isSearchModal: 0 });
        this.setState({ showSpinner: false, isSend: false });
        this.setState({ errors: errors });
      }
    }

    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async UserActivity() {
    var list = [];
    var totalRecords = 0;
    let res = await UserService.UserActivity(this.state.slug);

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = list ? res.data.total : 0;
      //totalRecords = res.data ? res.data.total : 0
    }

    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "user-activity") {
      this.setState({ tab: "user-activity" });
      this.UserActivity();
      setTimeout(() => {
        localStorage.setItem(
          "user_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "activeTab") {
      this.setState({ tab: "activeTab" });
      this.getData();
      setTimeout(() => {
        localStorage.setItem(
          "user_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  saveOrUpdateData = async () => {
    this.saveUserData();
  };

  saveUserData = async () => {
    var property = [];
    var brand = [];
    var client = [];
    var roles = [];
    var roleGroup = [];
    if (this.validator.allValid()) {
      let propertyList =
        this.state.selectedPropertyList &&
        this.state.selectedPropertyList.length > 0
          ? this.state.selectedPropertyList
          : null;
      propertyList !== null &&
        this.state.selectedPropertyList.forEach((item) => {
          property = [...property, item.value];
        });

      if (this.state.is_brand_level) {
        let brandList = this.state.selectedBrandList;
        brandList !== null &&
          this.state.selectedBrandList.forEach((item) => {
            brand = [...brand, item.value];
          });
      }

      if (this.state.is_client_level) {
        let clientList = this.state.selectedClientList;
        clientList !== null &&
          this.state.selectedClientList.forEach((item) => {
            client = [...client, item.value];
          });
      }

      //selected roles
      this.state.selectedRoleList.forEach((item) => {
        roles = [...roles, item.value];
      });

      //selected role group
      this.state.selectedRoleGroupList.forEach((item) => {
        roleGroup = [...roleGroup, item.value];
      });

      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        password: this.state.password,
        phone_number: this.state.phone_number,
        dial_code: this.state.dial_code,
        property,
        client,
        brand,
        roleGroup,
        role: roles,
        all_properties: this.state.all_properties ?? false,
        status_id: this.state.status_id === true ? 1 : 2,
        is_email: this.state.is_email,
        is_sms: this.state.is_sms,
        is_notification: this.state.is_notification,
      };

      if (this.state.slug !== undefined) {
        let res = await AuthenticationService.updateUserData(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      } else {
        let res = await AuthenticationService.saveUserData(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  saveRolePropertiesData = async () => {
    let brands = [];
    var clients = [];
    if (this.validator.allValid()) {
      this.state.selectedBrandList.forEach((item) => {
        brands = [...brands, item.value];
      });
      this.state.selectedClientList.forEach((item) => {
        clients = [...clients, item.value];
      });

      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        brands: brands,
        clients: clients,
        all_properties: this.state.all_properties,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      if (this.state.slug !== undefined) {
        let res = await AuthenticationService.updateUserRoleProperties(
          inputData,
          this.state.slug
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      } else {
        let res = await AuthenticationService.updateUserRoleProperties(
          inputData,
          this.state.responseSlug
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  onChangePhone(value, country, e, formattedValue) {
    this.setState({ phone_number: value });
    this.setState({ dial_code: country.dialCode });
  }

  redirectToUserPage() {
    this.props.history.push("/users");
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  handleEmailInputFocus = () => {
    this.emailInput.removeAttribute("readonly");
  };

  handlePasswordInputFocus = () => {
    this.passwordInput.removeAttribute("readonly");
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,

        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    this.validator.purgeFields();
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
        <Container className="innter-container pr-0 over-fl container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 ">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <UsersSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel drop_user">
                  <Container>
                    <div className="col-md-12 mg-t-20 mg-b-10"></div>
                    <div className="col-md-12 p-0">
                      <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                        <h3 className="mb-0 brnd_heading">Users</h3>
                        <div className="d-flex">
                          {this.state.slug !== undefined &&
                          this.state.email &&
                          global.userPermissions.checkPermission(
                            "users-update"
                          ) &&
                          this.state.tab === "activeTab" ? (
                            this.state.set_password ? (
                              <OverlayTrigger
                                placement="top"
                                trigger="hover"
                                overlay={
                                  <Popover>
                                    <span className="completeBookigHover">
                                      Mail sent at {this.state.set_password_at}
                                    </span>
                                  </Popover>
                                }
                              >
                                <button
                                  type="button"
                                  className=" m-0 btn-success btn-block"
                                  onClick={() => this.SendGenerateEmail()}
                                >
                                  {this.state.isSend
                                    ? global.loader
                                    : "Send Mail "}
                                </button>
                              </OverlayTrigger>
                            ) : (
                              <button
                                type="button"
                                className=" m-0 btn-success btn-block"
                                onClick={() => this.SendGenerateEmail()}
                              >
                                {this.state.isSend
                                  ? global.loader
                                  : "Send Mail "}
                              </button>
                            )
                          ) : null}
                          <button
                            type="button"
                            className="ml-3 btn-outline-light btn-block ml-3 mt-0"
                            onClick={() => this.redirectToUserPage()}
                          >
                            Cancel
                          </button>

                          {global.userPermissions.checkPermission(
                            "users-update"
                          ) && this.state.tab === "activeTab" ? (
                            <Button
                              variant="btn-success-outline ml-3 mt-0 btn-block button-width"
                              onClick={this.saveOrUpdateData}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : this.state.slug !== undefined
                                ? "Save"
                                : "Save"}
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0 mt-3">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="scrolling-carousel pd-l-15 w-100"></div>

                    <div className="scrolling-carousel pd-lg-l-0 pd-xs-l-15 scroll-slide">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="all"
                        onSelect={(e) => this.getActiveClass(e)}
                        eventKey={this.state.activeClass}
                        className="pd-r-60"
                      >
                        <ScrollingCarousel
                          rightArrow={true}
                          leftArrow={true}
                          className="swipesection pd-t-20"
                        >
                          <Nav.Item>
                            <Nav.Link
                              eventKey="activeTab"
                              id="activeTab"
                              className={
                                this.state.activeClass === "activeTab"
                                  ? "active"
                                  : ""
                              }
                            >
                              Detail
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link
                              eventKey="user-activity"
                              id="inactiveTab"
                              className={
                                this.state.activeClass === "user-activity"
                                  ? "active"
                                  : ""
                              }
                            >
                              Activity
                            </Nav.Link>
                          </Nav.Item>
                        </ScrollingCarousel>
                        <Tab.Content>
                          <Tab.Pane eventKey="activeTab">
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <form id="units_form">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  <div className="form-group mg-t-30">
                                    <div className="row align-items- pd-b-0 border-bottom-0  pd-xs-l-0 pd-xs-r-0  pd-lg-l-15 pd-lg-r-15">
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                        <label className="form-label text-left pd-b-5">
                                          First Name{" "}
                                        </label>
                                        <Form.Control
                                          type="text"
                                          onChange={this.changeHandler}
                                          name="first_name"
                                          value={this.state.first_name}
                                          maxLength="50"
                                        />
                                        {this.state.errors.first_name ? (
                                          <div className="text-danger">
                                            {this.state.errors.first_name}
                                          </div>
                                        ) : (
                                          this.validator.message(
                                            "first_name",
                                            this.state.first_name,
                                            "required",
                                            { className: "text-danger" }
                                          )
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                        <label className="form-label text-left pd-b-5">
                                          Last Name{" "}
                                        </label>
                                        <Form.Control
                                          type="text"
                                          onChange={this.changeHandler}
                                          name="last_name"
                                          value={this.state.last_name}
                                          maxLength="50"
                                        />
                                        {this.state.errors.last_name ? (
                                          <div className="text-danger">
                                            {this.state.errors.last_name}
                                          </div>
                                        ) : (
                                          this.validator.message(
                                            "last_name",
                                            this.state.last_name,
                                            "required",
                                            { className: "text-danger" }
                                          )
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                        <label className="form-label text-left pd-b-5">
                                          Email
                                        </label>
                                        <Form.Control
                                          type="text"
                                          onChange={this.changeHandler}
                                          name="email"
                                          value={this.state.email}
                                          maxLength="50"
                                          autoComplete="off"
                                          className="read_only_bg"
                                          ref={(input) =>
                                            (this.emailInput = input)
                                          }
                                          onFocus={this.handleEmailInputFocus}
                                          readOnly
                                        />
                                        {this.state.errors.email ? (
                                          <div className="text-danger">
                                            {this.state.errors.email}
                                          </div>
                                        ) : (
                                          this.validator.message(
                                            "email",
                                            this.state.email,
                                            "required|email",
                                            { className: "text-danger" }
                                          )
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                        <label className="form-label text-left pd-b-5">
                                          Password (Optional)
                                        </label>
                                        <Form.Control
                                          type="password"
                                          onChange={this.changeHandler}
                                          name="password"
                                          value={this.state.password}
                                          maxLength="50"
                                          autoComplete="off"
                                          className="read_only_bg"
                                          ref={(input) =>
                                            (this.passwordInput = input)
                                          }
                                          onFocus={
                                            this.handlePasswordInputFocus
                                          }
                                          readOnly
                                        />
                                        {this.state.errors.password ? (
                                          <div className="text-danger">
                                            {this.state.errors.password}
                                          </div>
                                        ) : (
                                          this.validator.message(
                                            "password",
                                            this.state.password,
                                            "min:8|max:20",
                                            { className: "text-danger" }
                                          )
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                        <label className="form-label text-left pd-b-5">
                                          Phone
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>
                                        <PhoneInput
                                          country={global.country}
                                          disableSearchIcon
                                          // autoFormat= "true"
                                          enableSearch="true"
                                          countryCodeEditable="true"
                                          value={this.state.phone_number}
                                          onChange={this.onChangePhone}
                                        />
                                      </div>

                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                        <label className="form-label text-left pd-b-5">
                                          Role Group
                                          {/* <span className="text-danger">*</span> */}
                                        </label>
                                        <Select
                                          isMulti
                                          styles={customStyles}
                                          isClearable={true}
                                          className="multiselect roleselect"
                                          menuPlacement="auto"
                                          name="role_group"
                                          // isDisabled={true}
                                          value={
                                            this.state.selectedRoleGroupList
                                          }
                                          options={this.state.newRoleGroupList}
                                          getOptionValue={(option) =>
                                            `${option.label}`
                                          }
                                          onChange={(value) =>
                                            this.handleChangeRoleGroupList(
                                              value
                                            )
                                          }
                                          defaultValue={
                                            this.state.selectedRoleGroupList
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#fff",
                                              primary75: "#000",
                                              primary50: "#000",
                                              primary25: "#000",
                                            },
                                          })}
                                        />

                                        {this.state.errors.role_group ? (
                                          <div className="text-danger">
                                            {this.state.errors.role_group}
                                          </div>
                                        ) : (
                                          this.validator.message(
                                            "role_group",
                                            this.state.selectedRoleGroupList,
                                            "required",
                                            { className: "text-danger" }
                                          )
                                        )}
                                      </div>

                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                        <label className="form-label text-left pd-b-5">
                                          Role
                                          {/* <span className="text-danger">*</span> */}
                                        </label>
                                        {/* {this.state.selectedRoleGroupList && this.state.selectedRoleGroupList.length > 0 &&  ( */}
                                        <Select
                                          isMulti
                                          styles={customStyles}
                                          isClearable={true}
                                          className="multiselect roleselect"
                                          menuPlacement="auto"
                                          isDisabled={
                                            this.state.selectedRoleGroupList &&
                                            this.state.selectedRoleGroupList
                                              .length > 0
                                              ? false
                                              : true
                                          }
                                          value={this.state.selectedRoleList}
                                          options={this.state.newRoleList}
                                          getOptionValue={(option) =>
                                            `${option.label}`
                                          }
                                          onChange={(value) =>
                                            this.handleChangeRoleList(value)
                                          }
                                          defaultValue={
                                            this.state.selectedRoleList
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#fff",
                                              primary75: "#000",
                                              primary50: "#000",
                                              primary25: "#000",
                                            },
                                          })}
                                        />
                                        {/* )} */}

                                        {this.state.errors.role ? (
                                          <div className="text-danger">
                                            {this.state.errors.role}
                                          </div>
                                        ) : (
                                          this.validator.message(
                                            "role",
                                            this.state.selectedRoleList,
                                            "required",
                                            { className: "text-danger" }
                                          )
                                        )}
                                      </div>

                                      {/* {this.state.is_property_level === true && ( */}
                                      {/* <> */}
                                      {this.state.is_client_level == true &&
                                      this.state.selectedRoleList &&
                                      this.state.selectedRoleList.length > 0 &&
                                      this.state.selectedRoleGroupList &&
                                      this.state.selectedRoleGroupList.length >
                                        0 ? (
                                        <>
                                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                            <label className="form-label text-left pd-b-5">
                                              Client(s)
                                              {/* {!this.state.is_client_level ? (
                                            <span className="optional">
                                              (Optional)
                                            </span>
                                          ) : ( */}
                                              {/* <span className="text-danger">
                                            *
                                          </span> */}
                                              {/* )} */}
                                              {/* <span className="text-danger">*</span> */}
                                            </label>
                                            <Select
                                              isMulti
                                              name="clients"
                                              styles={customStyles}
                                              className="multiselect roleselect"
                                              menuPlacement="auto"
                                              value={
                                                this.state.selectedClientList
                                              }
                                              options={this.state.newClientList}
                                              getOptionValue={(option) =>
                                                `${option.label}`
                                              }
                                              onChange={(value) =>
                                                this.handleChangeClientList(
                                                  value
                                                )
                                              }
                                              defaultValue={
                                                this.state.selectedClientList
                                              }
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 2,
                                                colors: {
                                                  ...theme.colors,
                                                  primary: "#fff",
                                                  primary75: "#000",
                                                  primary50: "#000",
                                                  primary25: "#000",
                                                },
                                              })}
                                            />

                                            {this.state.is_client_level ==
                                            false ? null : this.state.errors
                                                .clients ? (
                                              <div className="text-danger">
                                                {this.state.errors.clients}
                                              </div>
                                            ) : (
                                              this.validator.message(
                                                "clients ",
                                                this.state.selectedClientList,
                                                "required",
                                                { className: "text-danger" }
                                              )
                                            )}
                                          </div>{" "}
                                        </>
                                      ) : null}

                                      {this.state.is_brand_level == true &&
                                      this.state.selectedRoleList &&
                                      this.state.selectedRoleList.length > 0 &&
                                      this.state.selectedRoleGroupList &&
                                      this.state.selectedRoleGroupList.length >
                                        0 ? (
                                        <>
                                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                            <label className="form-label text-left pd-b-5">
                                              Brand(s)
                                              {/* <span className="text-danger">
                                            *
                                          </span> */}
                                              {/* <span className="text-danger">*</span> */}
                                            </label>
                                            {this.state.selectedClientList ||
                                            this.state.is_brand_level ? (
                                              <>
                                                <Select
                                                  isMulti
                                                  name="brands"
                                                  styles={customStyles}
                                                  className="multiselect roleselect"
                                                  menuPlacement="auto"
                                                  value={
                                                    this.state.selectedBrandList
                                                  }
                                                  options={
                                                    this.state.newBrandList
                                                  }
                                                  getOptionValue={(option) =>
                                                    `${option.label}`
                                                  }
                                                  onChange={(value) =>
                                                    this.handleChangeBrandList(
                                                      value
                                                    )
                                                  }
                                                  defaultValue={
                                                    this.state.selectedBrandList
                                                  }
                                                  theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 2,
                                                    colors: {
                                                      ...theme.colors,
                                                      primary: "#fff",
                                                      primary75: "#000",
                                                      primary50: "#000",
                                                      primary25: "#000",
                                                    },
                                                  })}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <Select
                                                  isMulti
                                                  name="brands"
                                                  styles={customStyles}
                                                  isDisabled={true}
                                                  className="multiselect roleselect"
                                                  menuPlacement="auto"
                                                  value={
                                                    this.state.selectedBrandList
                                                  }
                                                  options={
                                                    this.state.newBrandList
                                                  }
                                                  getOptionValue={(option) =>
                                                    `${option.label}`
                                                  }
                                                  onChange={(value) =>
                                                    this.handleChangeBrandList(
                                                      value
                                                    )
                                                  }
                                                  defaultValue={
                                                    this.state.selectedBrandList
                                                  }
                                                  theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 2,
                                                    colors: {
                                                      ...theme.colors,
                                                      primary: "#fff",
                                                      primary75: "#000",
                                                      primary50: "#000",
                                                      primary25: "#000",
                                                    },
                                                  })}
                                                />
                                              </>
                                            )}
                                            {this.state.is_brand_level ==
                                            false ? null : this.state.errors
                                                .brands ? (
                                              <div className="text-danger">
                                                {this.state.errors.brands}
                                              </div>
                                            ) : (
                                              this.validator.message(
                                                "brands",
                                                this.state.selectedBrandList,
                                                "required",
                                                { className: "text-danger" }
                                              )
                                            )}
                                          </div>{" "}
                                        </>
                                      ) : null}

                                      {/* propertiess  dropdown */}
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                        <label className="form-label text-left pd-b-5">
                                          Properties
                                          {/* <span className="text-danger">
                                            *
                                          </span> */}
                                          {/* {this.state.is_admin ? (
                                            <span className="optional">
                                              (Optional)
                                            </span>
                                          ) : (

                                          )} */}
                                        </label>
                                        {
                                          <>
                                            <Select
                                              isMulti
                                              styles={customStyles}
                                              isDisabled={
                                                (this.state
                                                  .selectedRoleGroupList &&
                                                  this.state
                                                    .selectedRoleGroupList
                                                    .length > 0) ||
                                                (this.state.selectedBrandList &&
                                                  this.state.selectedBrandList
                                                    .length > 0) ||
                                                (this.state
                                                  .selectedClientList &&
                                                  this.state.selectedClientList
                                                    .length > 0) ||
                                                !this.state.is_property_level
                                                  ? this.state.all_properties ==
                                                      true ||
                                                    this.state.is_admin
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              isClearable={true}
                                              className="multiselect roleselect"
                                              menuPlacement="auto"
                                              value={
                                                !this.state.all_properties
                                                  ? this.state
                                                      .selectedPropertyList
                                                  : []
                                              }
                                              options={
                                                !this.state.all_properties
                                                  ? this.state.newPropertyList
                                                  : null
                                              }
                                              getOptionValue={(option) =>
                                                `${option.label}`
                                              }
                                              onChange={(value) =>
                                                this.handleChangePropertyList(
                                                  value
                                                )
                                              }
                                              defaultValue={
                                                this.state.selectedPropertyList
                                              }
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 2,
                                                colors: {
                                                  ...theme.colors,
                                                  primary: "#fff",
                                                  primary75: "#000",
                                                  primary50: "#000",
                                                  primary25: "#000",
                                                },
                                              })}
                                            />

                                            <>
                                              {/* If selected  role is  not corporate role group  */}
                                              {this.state.selectedRoleList &&
                                                this.state.selectedRoleList
                                                  .length > 0 &&
                                                (this.state.is_brand_level ||
                                                  this.state
                                                    .is_client_level) && (
                                                  <div className="mg-t-15 mg-b-15">
                                                    <Form>
                                                      <Form.Check
                                                        onChange={(e) =>
                                                          this.changeHandler(e)
                                                        }
                                                        name="all_properties"
                                                        type={"checkbox"}
                                                        id="1"
                                                        checked={
                                                          this.state
                                                            .all_properties
                                                            ? true
                                                            : false
                                                        }
                                                        label={
                                                          this.state
                                                            .is_client_level
                                                            ? "All properties associated with the chosen client(s)"
                                                            : "All properties associated with the chosen brand(s)"
                                                        }
                                                        className={
                                                          "ckbox mg-r-20"
                                                        }
                                                      />
                                                    </Form>
                                                  </div>
                                                )}

                                              {/*  if seelcted role  is corporate role group  */}
                                              {this.state.selectedRoleList &&
                                                this.state.selectedRoleList
                                                  .length > 0 &&
                                                this.state.is_admin == true && (
                                                  <div className="mg-t-15 mg-b-15">
                                                    <Form>
                                                      <Form.Check
                                                        name="all_properties"
                                                        type={"checkbox"}
                                                        id="1"
                                                        checked={
                                                          this.state.is_admin
                                                        }
                                                        label={"All Properties"}
                                                        className={
                                                          "ckbox mg-r-20"
                                                        }
                                                      />
                                                    </Form>
                                                  </div>
                                                )}
                                            </>
                                          </>
                                        }

                                        {this.state.is_admin ? null : this.state
                                            .errors.Properties ? (
                                          <div className="text-danger">
                                            {this.state.errors.Properties}
                                          </div>
                                        ) : (
                                          this.validator.message(
                                            "Properties ",
                                            this.state.selectedPropertyList &&
                                              this.state.newPropertyList,
                                            "required",
                                            { className: "text-danger" }
                                          )
                                        )}
                                      </div>

                                      {this.state.slug !== undefined && (
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                          <div className="row">
                                            <div className="col-md-6 col-lg-6 mg-md-t-0">
                                              <label className="form-label text-left pd-b-5">
                                                Status
                                              </label>
                                              <b
                                                className={
                                                  this.state.status_id
                                                    ? "active"
                                                    : "inactive"
                                                }
                                              ></b>
                                              <div
                                                className={
                                                  this.state.status_id
                                                    ? "az-toggle on"
                                                    : "az-toggle"
                                                }
                                                onClick={() =>
                                                  this.changeStatus()
                                                }
                                              >
                                                <span></span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                        <h3 className="mg-l-0 mg-t-15 background-head d-flex justify-content-between align-items-center">
                                          Notifications settings
                                        </h3>
                                        <div className="d-flex">
                                          <Form.Check
                                            onChange={(e) =>
                                              this.changeHandler(e)
                                            }
                                            name="is_email"
                                            checked={this.state.is_email}
                                            type={"checkbox"}
                                            id="textmessagecheckbox1"
                                            label={"Email"}
                                            className={"ckbox mg-r-20"}
                                          />

                                          <Form.Check
                                            onChange={(e) =>
                                              this.changeHandler(e)
                                            }
                                            name="is_sms"
                                            checked={this.state.is_sms}
                                            type={"checkbox"}
                                            id="textmessagecheckbox2"
                                            label={"SMS"}
                                            className={"ckbox mg-r-20"}
                                          />

                                          <Form.Check
                                            onChange={(e) =>
                                              this.changeHandler(e)
                                            }
                                            name="is_notification"
                                            checked={this.state.is_notification}
                                            type={"checkbox"}
                                            id="textmessagecheckbox3"
                                            label={"Notification"}
                                            className={"ckbox mg-r-20"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="user-activity">
                            {this.state.total_record > 0 ||
                            this.state.filter_value !== "" ? (
                              <div className="mg-t-20 brandList">
                                <ReactDatatable
                                  className="table no-wrap mg-b-30 pd-b-2 brandtable table_th_last"
                                  config={
                                    this.state.total_record > global.page_size
                                      ? this.state.config
                                      : this.state.config
                                  }
                                  records={this.state.records}
                                  columns={this.state.columns}
                                  extraButtons={this.state.extraButtons}
                                  dynamic={true}
                                  // loading={this.state.loading}
                                  total_record={this.state.total_record}
                                  onChange={this.tableChangeHandler}
                                  onRowClicked={this.rowClickedHandler}
                                />
                              </div>
                            ) : (
                              <NoData msg="inactive users" />
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default UsersForm;
