////////////////////////////////////////////////////////////
//     							                          //
//  Program: CompanyForm.jsx                              //
//  Application: Company                                  //
//  Option: to update Company Information                 //
//  Developer: NP,PS  						              //
//  Date: 2022-01-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Tabs, Tab, Form ,InputGroup } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import BackButton from "../../assets/images/back-button.svg"
import companyLogo from "./../../assets/images/company_placeholder.png";
import CompanyService from "../../services/CompanyService";
import CountryService from "../../services/CountryService";
import CountyService from "../../services/CountyService";
import CityService from "../../services/CityService";
import StateService from "../../services/StateService";
import UserService from "../../services/UserService";
import SimpleReactValidator from "simple-react-validator";
// import Toast from '../common/Toast'
import Alerts from "../common/Alerts";
// import infosize from "../../assets/images/Info.svg";
import Select from "react-select";
// import SettingSidebar from "../common/SettingSidebar";
import PhoneInput from "react-phone-input-2";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
class CompanyForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      input: {},
      errors: {},
      slug: this.props.match.params.slug,
      company_name: "",
      aka_name: "",
      legal_entity_name: "",
      tax_id: "",
      company_logo: "",
      street_1: "",
      street_2: "",
      city: "",
      state_slug: "",
      zipcode: "",
      country_slug: "",
      county_slug: "",
      contact_name: "",
      phone_number: "",
      email: "",
      status_id: "",
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      imageUrl: "",
      confirm_back: 0,
      cityListTotal: 0,
      orion_haus_account_manager_slug:"",
      client_contact_slug:"",
      // orion_haus_account_manager: "",
      // orion_haus_email: "",
      // orion_haus_phone_number: "",
      // client_contact: "",
      // client_contact_email: "",
      // client_contact_phone_number: "",
      accountManagerList:[],
      newAccountManagerList:[],
      selectedAccountManagerList:[],
      clientContactList:[],
      newClientContactList:[],
      selectedClientContactList:[]
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.updateCompany = this.updateCompany.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangePhoneOrian = this.onChangePhoneOrian.bind(this);
    this.onChangePhoneAsset = this.onChangePhoneAsset.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getData();
    this.getCountryData();
    this.getStateData();
    this.getCountyData();
    this.getCityData();
    this.getAccountManagerList();
    this.getClientContactList();
  }

  async getAccountManagerList(){
  //  this.setState({ showSpinner: true, loading: true });
    var accountManagerList = [];

    let res = await UserService.getUserDropdown("roleTitle=ADMIN");
    if (global.successStatus.includes(res.status)) {
      accountManagerList = res.data ? res.data : [];
    }
    const newAccountManagerList = accountManagerList.map(({ slug, first_name, last_name }) => ({
      value: slug,
      label: first_name + " " + last_name,
    }));
    this.setState({ newAccountManagerList: newAccountManagerList, accountManagerList: accountManagerList });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getClientContactList(){
    //  this.setState({ showSpinner: true, loading: true });
      var clientContactList = [];
  
      let res = await UserService.getUserDropdown("roleTitle=ADMIN");
      if (global.successStatus.includes(res.status)) {
        clientContactList = res.data ? res.data : [];
      }
      const newClientContactList = clientContactList.map(({ slug, first_name, last_name }) => ({
        value: slug,
        label: first_name + " " + last_name,
      }));
      this.setState({ newClientContactList: newClientContactList,clientContactList:clientContactList});
      //  this.setState({ showSpinner: false, loading: false });
  }

  handleChangeAccountManagerList(value){
    let orion_haus_account_manager_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        orion_haus_account_manager_slug.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedAccountManagerList: value,
      orion_haus_account_manager_slug: state_id_value,
    });
    this.setState({ confirm_back: 1 });
}

handleChangeClientContact(value){
  let client_contact_slug = [];
  if (value != null && value.length > 0) {
    value.forEach((item, i) => {
      client_contact_slug.push(item.value);
    });
  }
  const state_id_value = value !== null ? value.value : [];
  this.setState({
    selectedClientContactList: value,
    client_contact_slug: state_id_value,
  });
  this.setState({ confirm_back: 1 });
}

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};

    let res = await CompanyService.getCompany();
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
      resData.country_slug = res.data.country ? res.data.country.slug : "";
      resData.county_slug = res.data.county ? res.data.county.slug : "";
      resData.state_slug = res.data.state ? res.data.state.slug : "";
      resData.city_slug = res.data.city ? res.data.city.slug : "";
      resData.country_name = res.data.country
        ? res.data.country.country_name
        : "";
      resData.term_condition = res.data.term_condition
        ? res.data.term_condition
        : null;
      resData.website_cookie = res.data.website_cookie
        ? res.data.website_cookie
        : null;
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      resData.imageUrl = res.data.company_logo_url
        ? res.data.company_logo_url
        : companyLogo;

      // resData.orion_haus_account_manager = res.data.orion_haus_account_manager ? res.data.orion_haus_account_manager : ""
      // resData.orion_haus_email = res.data.orion_haus_email ? res.data.orion_haus_email : ""
      // resData.orion_haus_phone_number = res.data.orion_haus_phone_number ? res.data.orion_haus_phone_number : ""
      // resData.asset_manager = res.data.asset_manager ? res.data.asset_manager : ""
      // resData.asset_manager_email = res.data.asset_manager_email ? res.data.asset_manager_email : ""
      // resData.asset_manager_phone_number = res.data.asset_manager_phone_number ? res.data.asset_manager_phone_number : ""
      resData.orion_haus_account_manager_slug = res.data && res.data.orion_haus_account_manager && res.data.orion_haus_account_manager.slug ? res.data.orion_haus_account_manager.slug : ""
      resData.client_contact_slug = res.data && res.data.client_info && res.data.client_info.slug ? res.data.client_info.slug : ""

      var selectedCityList = [];
      selectedCityList = res.data.city
        ? [
            {
              value: res.data.city.slug,
              label: res.data.city.city_name,
            },
          ]
        : "";
      var selectedStateList = [];
      selectedStateList = res.data.state
        ? [
            {
              value: res.data.state.slug,
              label: res.data.state.state_name,
            },
          ]
        : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county
        ? [
            {
              value: res.data.county.slug,
              label: res.data.county.county_name,
            },
          ]
        : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.country
        ? [
            {
              value: res.data.country.slug,
              label: res.data.country.country_name,
            },
          ]
        : "";
        var selectedAccountManagerList = [];
        selectedAccountManagerList = res.data.orion_haus_account_manager
          ? [
              {
                value: res.data.orion_haus_account_manager.slug,
                label: res.data.orion_haus_account_manager.first_name + " " + res.data.orion_haus_account_manager.last_name,
              },
            ]
          : "";
        var selectedClientContactList = [];
        selectedClientContactList = res.data.client_info
          ? [
              {
                value: res.data.client_info.slug,
                label: res.data.client_info.first_name + " " + res.data.client_info.last_name,
              },
            ]
          : "";
    }
    this.setState(resData);
    this.setState({
      selectedCityList: selectedCityList,
      selectedStateList: selectedStateList,
      selectedCountyList: selectedCountyList,
      selectedCountryList: selectedCountryList,
      selectedAccountManagerList: selectedAccountManagerList,
      selectedClientContactList: selectedClientContactList,
    });
    // this.getCountyData(resData.state_slug);
    // this.getStateData(resData.country_slug);
    // this.getCityData(resData.state_slug, resData.county_slug);
    this.setState({ showSpinner: false, loading: false });
  }

  async getCountryData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countryList = [];

    let res = await CountryService.getCountry(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
    }
    const newCountryList = countryList.map(({ slug, country_name }) => ({
      value: slug,
      label: country_name,
    }));
    this.setState({ countryList: countryList, newCountryList: newCountryList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getStateData(countryValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var stateList = [];

    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
        countryValue +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
    }
    const newStateList = stateList.map(({ slug, state_name }) => ({
      value: slug,
      label: state_name,
    }));
    this.setState({ stateList: stateList, newStateList: newStateList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCountyData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countyList = [];
    var countyListTotal = 0;

    let res = await CountyService.getCounty(
      "is_dropdown=1&state_slug=" +
        value +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
      countyListTotal = countyList ? countyList.length : 0;
    }
    const newCountyList =
      countyListTotal > 0
        ? countyList.map(({ slug, county_name }) => ({
            value: slug,
            label: county_name,
          }))
        : [];
    this.setState({
      countyList: countyList,
      newCountyList: newCountyList,
      countyListTotal: countyListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCityData(stateValue, countyValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;

    let res = await CityService.getCity(
      "is_dropdown=1&state_slug=" + stateValue + "&county_slug=" + countyValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList =
      cityListTotal > 0
        ? cityList.map(({ slug, city_name }) => ({
            value: slug,
            label: city_name,
          }))
        : [];
    this.setState({
      cityList: cityList,
      newCityList: newCityList,
      cityListTotal: cityListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "company_logo",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
      );
      fileData.append("mediaType", "1");
      let res = await CompanyService.uploadCompanyLogo(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          company_logo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  updateCompany = async (e) => {
    e.preventDefault();
    if (this.customValidate() && this.validator.allValid()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        company_name: this.state.company_name,
        aka_name: this.state.aka_name,
        legal_entity_name: this.state.legal_entity_name,
        tax_id: this.state.tax_id,
        company_logo: this.state.company_logo,
        street_1: this.state.street_1,
        street_2: this.state.street_2,
        city_slug: this.state.city_slug,
        state_slug: this.state.state_slug,
        website_cookie: this.state.website_cookie,
        term_condition: this.state.term_condition,
        zipcode: this.state.zipcode,
        country_slug: this.state.country_slug,
        county_slug: this.state.county_slug,
        contact_name: this.state.contact_name,
        phone_number: this.state.phone_number,
        email: this.state.email,
        status_id: this.state.status_id === true ? 1 : 2,

        orion_haus_account_manager_slug: this.state.orion_haus_account_manager_slug,
        client_contact_slug: this.state.client_contact_slug,
        // orion_haus_account_manager: this.state.orion_haus_account_manager,
        // orion_haus_email: this.state.orion_haus_email,
        // orion_haus_phone_number: this.state.orion_haus_phone_number,
        // asset_manager: this.state.asset_manager,
        // asset_manager_email: this.state.asset_manager_email,
        // asset_manager_phone_number: this.state.asset_manager_phone_number,
      };

      let res = await CompanyService.updateCompany(this.state.slug, inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        setTimeout(
          () => this.props.history.push("/company"),
          global.redirect_time
        );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
          //scroll to error logic
          const input = document.getElementsByClassName("text-danger");
          if (input.length > 0) {
            input[0].scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "start",
            });
          }
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      //scroll to error logic
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  async getAllRealetedData(slug, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await CityService.getDataByCitySlug(
      slug,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.country_slug = res.data.countryData
        ? res.data.countryData.country.slug
        : "";
      resData.country_name = res.data.countryData
        ? res.data.countryData.country.country_name
        : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.countryData.country
        ? [
            {
              value: res.data.countryData.country
                ? res.data.countryData.country.slug
                : "",
              label: res.data.countryData.country
                ? res.data.countryData.country.country_name
                : "",
            },
          ]
        : "";
      resData.county_slug = res.data.county ? res.data.county.slug : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county
        ? [
            {
              value: res.data.county.slug ? res.data.county.slug : "",
              label: res.data.county.county_name
                ? res.data.county.county_name
                : "",
            },
          ]
        : "";
      resData.state_slug = res.data.state ? res.data.state.slug : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      var selectedStateList = [];
      selectedStateList = res.data.state
        ? [
            {
              value: res.data.state.slug ? res.data.state.slug : "",
              label: res.data.state.state_name ? res.data.state.state_name : "",
            },
          ]
        : "";
    }
    this.setState(resData);
    this.setState({
      selectedStateList: selectedStateList,
      selectedCountyList: selectedCountyList,
      selectedCountryList: selectedCountryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    if (event.target.name === "city_slug") {
      this.getAllRealetedData(event.target.value);
    }

    // if (event.target.name === "country_slug") {
    //   if (event.target.value === "") {
    //     this.setState({
    //       state_slug: "",
    //       county_slug: "",
    //       city_slug: "",
    //       stateList: [],
    //       countyList: [],
    //       cityList: [],
    //     });
    //   } else {
    //     this.getStateData(event.target.value);
    //     this.setState({
    //       county_slug: "",
    //       city_slug: "",
    //       countyList: [],
    //       cityList: [],
    //     });
    //   }
    // }
    // if (event.target.name === "state_slug") {
    //   if (event.target.value === "") {
    //     this.setState({
    //       county_slug: "",
    //       city_slug: "",
    //       countyList: [],
    //       cityList: [],
    //     });
    //   } else {
    //     this.getCountyData(event.target.value);
    //     this.getCityData(event.target.value, "");
    //   }
    // }
    // if (event.target.name === "county_slug") {
    //   if (event.target.value === "") {
    //     this.getCityData(input.state_slug, "");
    //   } else {
    //     this.getCityData(input.state_slug, event.target.value);
    //   }
    // }
    this.setState({ confirm_back: 1 });
  };

  handleChangeCityList(value) {
    let city_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_slug.push(item.value);
      });
    }
    if (value !== null) {
      this.getAllRealetedData(value.value);
    }
    const city_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCityList: value,
      city_slug: city_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }
  onChangePhone(value, country, e, formattedValue){
    //   console.log(value, country, e, formattedValue);
      this.setState({phone_number:value});
      this.setState({dial_code:country.dialCode});
    }

    onChangePhoneOrian(value, country, e, formattedValue){
      //   console.log(value, country, e, formattedValue);
        this.setState({orion_haus_phone_number:value});
        this.setState({dial_code:country.dialCode});
      }

    onChangePhoneAsset(value, country, e, formattedValue){
      //   console.log(value, country, e, formattedValue);
        this.setState({client_contact_phone_number:value});
        this.setState({dial_code:country.dialCode});
      }
  handleChangeStateList(value) {
    let state_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_slug.push(item.value);
      });
    }
    const state_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedStateList: value,
      state_slug: state_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCountyList(value) {
    let county_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        county_slug.push(item.value);
      });
    }
    const county_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCountyList: value,
      county_slug: county_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCountryList(value) {
    let country_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_slug.push(item.value);
      });
    }
    const country_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCountryList: value,
      country_slug: country_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  removePhoto(){
    this.setState({company_logo:"",imageUrl:""});
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
        <Container className="innter-container over-fl ">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {/* <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <SettingSidebar />
                  </aside>
                </div>
              </div> */}
              <div className="col-md-10 right settingsidebarNav">
                <div className="scrolling-carousel mg-t-40">
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs">
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap">
                          <h3 className="mb-0">
                            {this.state.company_name
                              ? this.state.company_name
                              : ""}
                          </h3>
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn-success-outline"
                              onClick={() => {
                                if (this.state.confirm_back === 1)
                                  if (
                                    window.confirm(
                                      "Do you want to exit without saving?"
                                    )
                                  )
                                    this.props.history.push("/company");
                                  else return false;
                                else this.props.history.push("/company");
                              }}
                            >
                              Cancel
                            </button>
                            {global.userPermissions.checkPermission(
                              "company-update"
                            ) && (
                              <button
                                className="btn-success ml-3"
                                type="button"
                                disabled={this.state.isSubmit ? true : false}
                                onClick={this.updateCompany}
                              >
                                {this.state.isSubmit ? global.loader : "Save"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 pd-lg-l-30 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="col-md-12 mg-t-0">
                        <div className="tabNavigation">
                          <form id="a">
                            <Tabs
                              defaultActiveKey="Basics"
                              id="uncontrolled-tab-example"
                            >
                              <Tab eventKey="Basics" title="Basics">
                                <div className="m-0">
                                  <div className="row align-items-center pd-t-0 pd-lg-b-0 pd-xs-b-15 border-bottom-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        DBA{" "}
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="company_name"
                                        value={this.state.company_name || ""}
                                        maxLength="50"
                                        tabIndex="1"
                                        // placeholder="DBA"
                                      />
                                      {this.state.errors.company_name ? (
                                        <div className="text-danger">
                                          {this.state.errors.company_name}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "dba",
                                          this.state.company_name,
                                          "required|max:50",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Company SKU
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        tabIndex="4"
                                        onKeyPress={
                                          global.onKeyPressEvent
                                            .alphaNumValidation
                                        }
                                        type="text"
                                        // placeholder="SKU"
                                        name="aka_name"
                                        value={this.state.aka_name || ""}
                                        maxLength="3"
                                      />
                                      {this.state.errors.aka_name ? (
                                        <div className="text-danger">
                                          {this.state.errors.aka_name}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "company_sku",
                                          this.state.aka_name,
                                          "required|alpha_num_dash|max:3",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Legal Entity{" "}
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        maxLength="50"
                                        tabIndex="3"
                                        type="text"
                                        name="legal_entity_name"
                                        value={
                                          this.state.legal_entity_name || ""
                                        }
                                        // placeholder="Legal Entity"
                                      />
                                      {this.state.errors.legal_entity_name ? (
                                        <div className="text-danger">
                                          {this.state.errors.legal_entity_name}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "legal_entity_name",
                                          this.state.legal_entity_name,
                                          "required|max:50",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Tax ID
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="tax_id"
                                        value={this.state.tax_id || ""}
                                        maxLength="50"
                                        tabIndex="5"
                                        type="text"
                                        // placeholder="Tax ID"
                                      />
                                      {this.state.errors.tax_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.tax_id}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "tax_id",
                                          this.state.tax_id,
                                          "required|max:50",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Street 1
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="street_1"
                                        value={this.state.street_1 || ""}
                                        // placeholder="Street 1"
                                        tabIndex="6"
                                      />
                                      {this.state.errors.street_1 ? (
                                        <div className="text-danger">
                                          {this.state.errors.street_1}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "street_1",
                                          this.state.street_1,
                                          "required|max:100",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Street 2{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="street_2"
                                        value={this.state.street_2 || ""}
                                        // placeholder="Street 2"
                                        tabIndex="7"
                                      />
                                      {this.state.errors.street_2 ? (
                                        <div className="text-danger">
                                          {this.state.errors.street_2}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "street_2",
                                          this.state.street_2,
                                          "max:100",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        City
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedCityList}
                                        options={this.state.newCityList}
                                        onChange={(value) =>
                                          this.handleChangeCityList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedCityList
                                        }
                                        autoComplete="off"
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />

                                      {/* <select
                                      className=""
                                      onChange={this.changeHandler}
                                      name="city_slug"
                                      value={this.state.city_slug}
                                      tabIndex="11"
                                    >
                                      <option key="" value="">
                                        Select City
                                      </option>
                                      {this.state.cityList
                                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                                        .map((option) => (
                                          <option
                                            key={option.slug}
                                            value={option.slug}
                                          >
                                            {option.city_name}
                                          </option>
                                        ))}
                                    </select> */}
                                      {this.state.errors.city_slug ? (
                                        <div className="text-danger">
                                          {this.state.errors.city_slug}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "city",
                                          this.state.city_slug,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        State
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      {/* disable field */}
                                      <Form.Control
                                        type="text"
                                        className="custom-disable"
                                        value={this.state.state_name || ""}
                                        // placeholder="State"
                                        disabled={true}
                                      />
                                      {/* theme select box */}
                                      {/* <Select
                                      className="multiselect"
                                      menuPlacement="auto"
                                      value={this.state.selectedStateList}
                                      options={this.state.newStateList.filter(
                                        (option) => {
                                          const val1 = this.state.state_slug
                                            ? this.state.state_slug
                                            : "";
                                          if (val1 === option.slug) return true;
                                        }
                                      )}
                                      onChange={(value) =>
                                        this.handleChangeStateList(value)
                                      }
                                      defaultValue={this.state.selectedStateList}
                                    /> */}

                                      {/* default select box */}
                                      {/* <select
                                      className=""
                                      onChange={this.changeHandler}
                                      name="state_slug"
                                      value={this.state.state_slug}
                                      tabIndex="12"
                                    >
                                      <option key="" value="">
                                        Select State
                                      </option>
                                      {this.state.stateList
                                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                                        .map((option) => (
                                          <option
                                            key={option.slug}
                                            value={option.slug}
                                          >
                                            {option.state_name}
                                          </option>
                                        ))}
                                    </select> */}
                                      {this.state.errors.state_slug ? (
                                        <div className="text-danger">
                                          {this.state.errors.state_slug}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "state",
                                          this.state.state_slug,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Zip Code
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="zipcode"
                                        value={this.state.zipcode || ""}
                                        // placeholder="Zipcode"
                                        tabIndex="9"
                                        maxLength="5"
                                        onKeyPress={
                                          global.onKeyPressEvent
                                            .numberValidation
                                        }
                                      />
                                      {this.state.errors.zipcode ? (
                                        <div className="text-danger">
                                          {this.state.errors.zipcode}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "zipcode",
                                          this.state.zipcode,
                                          "required|max:5",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        County{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedCountyList}
                                        options={this.state.newCountyList.filter(
                                          (option) => {
                                            const val1 = this.state.county_slug
                                              ? this.state.county_slug
                                              : "";
                                            if (val1 === option.slug)
                                              return true;
                                          }
                                        )}
                                        onChange={(value) =>
                                          this.handleChangeCountyList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedCountyList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {/* <select
                                      className=""
                                      onChange={this.changeHandler}
                                      name="county_slug"
                                      value={this.state.county_slug}
                                      tabIndex="11"
                                    >
                                      <option key="" value="">
                                        Select County
                                      </option>
                                      {this.state.countyList
                                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                                        .map((option) => (
                                          <option
                                            key={option.slug}
                                            value={option.slug}
                                          >
                                            {option.county_name}
                                          </option>
                                        ))}
                                    </select> */}
                                      {this.state.errors.county_slug ? (
                                        <div className="text-danger">
                                          {this.state.errors.county_slug}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Country
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Form.Control
                                        type="text"
                                        className="custom-disable"
                                        value={this.state.country_name || ""}
                                        // placeholder="Country"
                                        disabled={true}
                                      />
                                      {/* <div className="single-select">
                                      <Select
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedCountryList}
                                        options={this.state.newCountryList.filter(
                                          (option) => {
                                            const val1 = this.state.country_slug
                                              ? this.state.country_slug
                                              : "";
                                            if (val1 === option.slug) return true;
                                          }
                                        )}
                                        onChange={(value) =>
                                          this.handleChangeCountryList(value)
                                        }
                                        defaultValue={this.state.selectedCountryList}
                                      />
                                      {/* <select
                                        className=""
                                        onChange={this.changeHandler}
                                        name="country_slug"
                                        value={this.state.country_slug}
                                        tabIndex="10"
                                      >
                                        <option key="" value="">
                                          Select Country
                                        </option>
                                        {this.state.countryList
                                          .sort((a, b) =>
                                            a.label > b.label ? 1 : -1
                                          )
                                          .map((option) => (
                                            <option
                                              key={option.slug}
                                              value={option.slug}
                                            >
                                              {option.country_name}
                                            </option>
                                          ))}
                                      </select>
                                    </div> */}

                                      {this.state.errors.country_slug ? (
                                        <div className="text-danger">
                                          {this.state.errors.country_slug}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "country",
                                          this.state.country_slug,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15"></div>

                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Contact Name{" "}
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="contact_name"
                                        value={this.state.contact_name || ""}
                                        // placeholder="Contact Name"
                                        tabIndex="13"
                                        maxLength="50"
                                      />
                                      {this.state.errors.contact_name ? (
                                        <div className="text-danger">
                                          {this.state.errors.contact_name}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "contact_name",
                                          this.state.contact_name,
                                          "required|max:50",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Email
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="email"
                                        value={this.state.email || ""}
                                        // placeholder="Email"
                                        tabIndex="15"
                                        maxLength="70"
                                      />
                                      {this.state.errors.email ? (
                                        <div className="text-danger">
                                          {this.state.errors.email}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "email",
                                          this.state.email,
                                          "required|email|max:70",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Phone{" "}
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <PhoneInput
                                        country={global.country}
                                        disableSearchIcon
                                        // autoFormat= "true"
                                        enableSearch="true"
                                        countryCodeEditable="true"
                                        value={this.state.phone_number}
                                        onChange={this.onChangePhone}
                                      />
                                      {this.state.errors.phone_number ? (
                                        <div className="text-danger">
                                          {this.state.errors.phone_number}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "phone",
                                          this.state.phone_number,
                                          "required|max:15",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Orion Haus Account Manager{" "}
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedAccountManagerList}
                                        options={this.state.newAccountManagerList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeAccountManagerList(value)
                                        }
                                        defaultValue={this.state.selectedAccountManagerList}
                                        autoComplete="off"
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.errors.orion_haus_account_manager_slug ? (
                                        <div className="text-danger">
                                          {this.state.errors.orion_haus_account_manager_slug}
                                        </div>
                                      ) : ""}
                                    
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Client Contact{" "}
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedClientContactList}
                                        ooptions={this.state.newClientContactList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeClientContact(value)
                                        }
                                        defaultValue={this.state.selectedClientContactList}
                                        autoComplete="off"
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.errors.client_contact_slug ? (
                                        <div className="text-danger">
                                          {this.state.errors.client_contact_slug}
                                        </div>
                                      ) : ""}
                                    </div>
                                    {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Orion Haus Account Manager{" "}
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="orion_haus_account_manager"
                                        value={this.state.orion_haus_account_manager || ""}
                                        maxLength="100"
                                      />
                                      {this.state.errors.orion_haus_account_manager ? (
                                        <div className="text-danger">
                                          {this.state.errors.orion_haus_account_manager}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "orion_haus_account_manager",
                                          this.state.orion_haus_account_manager,
                                          "max:100",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Orion Haus Email{" "}
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="orion_haus_email"
                                        value={this.state.orion_haus_email || ""}
                                        maxLength="70"
                                      />
                                      {this.state.errors.orion_haus_email ? (
                                        <div className="text-danger">
                                          {this.state.errors.orion_haus_email}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "orion_haus_email",
                                          this.state.orion_haus_email,
                                          "email|max:70",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Orion Haus Phone{" "}
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <PhoneInput
                                        country={global.country}
                                        enableSearch="true"
                                        countryCodeEditable="true"
                                        value={this.state.orion_haus_phone_number}
                                        onChange={(orion_haus_phone_number) =>
                                          this.setState({ orion_haus_phone_number })
                                        }
                                      />
                                      {this.state.errors.orion_haus_phone_number ? (
                                        <div className="text-danger">
                                          {this.state.errors.orion_haus_phone_number}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "orion_haus_phone_number",
                                          this.state.orion_haus_phone_number,
                                          "max:15",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Client Contact{" "}
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="client_contact"
                                        value={this.state.client_contact || ""}
                                        maxLength="100"
                                      />
                                      {this.state.errors.client_contact ? (
                                        <div className="text-danger">
                                          {this.state.errors.client_contact}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "client_contact",
                                          this.state.client_contact,
                                          "max:100",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                      <label className="form-label pd-b-5">
                                        Client Contact Email{" "}
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="client_contact_email"
                                        value={this.state.client_contact_email || ""}
                                        maxLength="70"
                                      />
                                      {this.state.errors.client_contact_email ? (
                                        <div className="text-danger">
                                          {this.state.errors.client_contact_email}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "client_contact_email",
                                          this.state.client_contact_email,
                                          "email|max:70",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                      <label className="form-label pd-b-5">
                                         Client Contact Phone{" "}
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <PhoneInput
                                        country={global.country}
                                        enableSearch="true"
                                        countryCodeEditable="true"
                                        value={this.state.client_contact_phone_number}
                                        onChange={(client_contact_phone_number) =>
                                          this.setState({ client_contact_phone_number })
                                        }
                                      />
                                      {this.state.errors.client_contact_phone_number ? (
                                        <div className="text-danger">
                                          {this.state.errors.client_contact_phone_number}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "client_contact_phone_number",
                                          this.state.client_contact_phone_number,
                                          "max:15",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div> */}

                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <InputGroup className="">
                                        <label className="form-label text-left pd-b-5 w-100">
                                          Website Cookies{" "}
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>
                                        <Form.Control
                                          onChange={this.changeHandler}
                                          name="website_cookie"
                                          value={this.state.website_cookie}
                                          className="form-control max_width_100"
                                          id="website_cookie"
                                          as="textarea"
                                          rows={global.textareaRowLength}
                                          // placeholder="Username"
                                        />
                                      </InputGroup>
                                      {this.state.errors.website_cookie ? (
                                        <div className="text-danger">
                                          {this.state.errors.website_cookie}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "website_cookie",
                                          this.state.website_cookie,
                                          "max:100000",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <InputGroup className="">
                                      <label className="form-label text-left pd-b-5 w-100">
                                        Terms & Conditions{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="term_condition"
                                        value={this.state.term_condition}
                                        className="form-control max_width_100"
                                        id="term_condition"
                                        as="textarea"
                                        rows={global.textareaRowLength}
                                        // placeholder="Username"
                                      />
                                    </InputGroup>
                                      {this.state.errors.term_condition ? (
                                        <div className="text-danger">
                                          {this.state.errors.term_condition}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "term_condition",
                                          this.state.term_condition,
                                          "max:100000",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    {/* col */}
                                  </div>

                                  {/* row */}
                                  <div className="row align-items-center pd-lg-l-45 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 border-bottom-0">
                                    <div className="col-md-1 pl-0">
                                      <label className="form-label pd-b-5">
                                        Logo{" "}
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                    </div>
                                    {/* col */}
                                    <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                                      <div className="fileUpload d-flex flex-wrap justify-content-between">
                                        <span>
                                          <label className="hand-cursor">
                                            <input
                                              onChange={this.onImageChange}
                                              type="file"
                                              name="company_logo"
                                              tabIndex="2"
                                            />
                                            {this.state.imageUrl ? (
                                              <img
                                                src={this.state.imageUrl}
                                                alt="Logo"
                                              />
                                            ) : (
                                              <img
                                              src={companyLogo}
                                              alt="Logo"
                                            />
                                            )}
                                          </label>
                                        </span>

                                        <ul className="ruLink">
                                          <li>
                                            <label>
                                              <input
                                                onChange={this.onImageChange}
                                                type="file"
                                                name="company_logo"
                                                tabIndex="2"
                                              />
                                            </label>
                                            Change Logo
                                          </li>
                                        </ul>
                                        <p className="hand-cursor remove-pic remove-pic-two m-0" onClick={this.removePhoto}></p>
                                      </div>
                                      {this.state.errors.company_logo ? (
                                        <div className="text-danger">
                                          {this.state.errors.company_logo}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "company_logo",
                                          this.state.company_logo,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}

                                      {/* <span className="d-inline-flex  align-items-start justify-content-start infosize">
                                      <img src={infosize} className="mg-r-5" />
                                      <p>
                                        {" "}
                                        Recommended size - 512px by 512px (1:1 ratio).
                                        Max file size - 5 MB
                                      </p>
                                    </span> */}
                                    </div>

                                    {/* col */}
                                  </div>

                                  {/* <div className="row align-items-center pd-lg-b-30 pd-xs-b-0 pd-lg-l-50 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 border-bottom-0 pd-t-20">
                                    <div className="col-md-1 pl-0">
                                      <label className="form-label mg-b-0"></label>
                                    </div>
                                    <div className="col-md-3">
                                      <p className="hand-cursor text-danger" onClick={this.removePhoto}>Remove</p>
                                    </div>
                                  </div> */}
                                  {/* row */}

                                  {/* row */}
                                </div>
                              </Tab>
                            </Tabs>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default CompanyForm;
