////////////////////////////////////////////////////////////
//  Program: QuickReplyList.jsx                           //
//  Application: messages                                 //
//  Option: Home Sharing Listing                          //
//  Developer: Kiran                                      //
//  Date: 2022-11-02                                      //
////////////////////////////////////////////////////////////

import SettingSidebar from "../common/SettingSidebar";
import NoData from "../common/NoData";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import RUmessageService from "../../services/RUmessageService";
class QuickyReply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: window.location.pathname,
      showSpinner: false,
      loading: false,
      total_record: 0,
      records: [],
      userCount: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      deleteRoleModal: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "guest",
          text: "Quick reply for",
          className: "guest hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.role_group ? record.role_group.role_title : "";
          },
        },
        {
          key: "title",
          text: "Name",
          className: "title hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.title ? record.title : "";
          },
        },
        {
          key: "message",
          text: "Message",
          className: "message hand-cursor ",
          sortable: true,
          cell: (record) => {
            return record.message
              ? record.message.substring(0, 100) + "...."
              : "";
          },
        },
      ],
    };
  }
  componentDidMount() {
    this.getData();
  }
  // to get data of template
  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await RUmessageService.getQuickReplyTemplate(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }
  getActiveClass(e) {
    this.setState({ activeClass: e });
  }
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };
  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("/quick-replies/update/" + data.slug);
  };
  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <SettingSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel">
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs ">
                      <div className="col-md-12 mg-t-10 mg-b-10 "></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Quick Replies</h3>
                          {global.userPermissions.checkPermission(
                            "quick-replies-add"
                          ) && (
                          <Link to={"/quick-replies/add"}>
                            <button className="btn-success-outline mg-r-20">
                              <img src={pluswIcon} alt="Add" /> Add
                            </button>
                          </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pd-lg-l-30 ">
                    <div className="table-responsive roles   ">
                      <div className="col-md-12 pl-0 pr-0  ">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.total_record > 0 ||
                      this.state.filter_value !== "" ? (
                        <div className="mg-t-18 brandList table_issue_roles">
                          <div className="pd-lg-r-0 pd-lg-l-0 pd-xs-l-15 pd-xs-r-0">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2 table_last_row"
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              // loading={this.state.loading}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              onRowClicked={this.rowClickedHandler}
                            />
                          </div>
                        </div>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage(
                            "QUICK_REPLIES"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default QuickyReply;
