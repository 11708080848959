////////////////////////////////////////////////////////////
//     							                                      //
//  Program: BrandForm.jsx                                //
//  Application:  Brand                                   //
//  Option: For add or update brand                       //
//  Developer: Ashish Kumar ,NP                           //
//  Date: 2021-12-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Tabs, Tab, Form, InputGroup } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import CountryService from "../../services/CountryService";
import CountyService from "../../services/CountyService";
import StateService from "../../services/StateService";
import BrandService from "../../services/BrandService";
import ClientService from "../../services/ClientService";
import UserService from "../../services/UserService";
import brandLogo from "./../../assets/images/brand_placeholder.png";
import Alerts from "../common/Alerts";
// import infosize from "../../assets/images/Info.svg";
import CityService from "../../services/CityService";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
// import BackButton from "../../assets/images/back-button.svg";
// import SettingSidebar from "../common/SettingSidebar"
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export class BrandForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      errors: {},
      slug: this.props.match.params.slug,
      isSubmit: false,
      brand_logo: "",
      imageUrl: brandLogo,
      status_id: false,
      sort_order: "",
      confirm_back: 0,
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      clientList: [],
      selectedClientList: [],
      newClientList: [],
      clientListTotal: 0,
      countyListTotal: 0,
      cityListTotal: 0,
      orion_haus_account_manager_slug: "",
      asset_manager_slug: "",
      // orion_haus_account_manager: "",
      // orion_haus_email: "",
      // orion_haus_phone_number: "",
      // asset_manager: "",
      // asset_manager_email: "",
      // asset_manager_phone_number: "",
      currerntRoleSlug: global.currerntRoleSlug
        ? global.currerntRoleSlug
        : localStorage.getItem("currerntRoleSlug")
        ? localStorage.getItem("currerntRoleSlug")
        : "",
      currentRoleTitle: global.currentRoleTitle
        ? global.currentRoleTitle
        : localStorage.getItem("currentRoleTitle")
        ? localStorage.getItem("currentRoleTitle")
        : "",
      accountManagerList: [],
      newAccountManagerList: [],
      selectedAccountManagerList: [],
      assetManagerList: [],
      newAssetManagerList: [],
      selectedAssetManagerList: [],
    };
    this.brandLogo = React.createRef();
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateBrand = this.saveOrUpdateBrand.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleChangeClientList = this.handleChangeClientList.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    // this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      this.getCountyData();
      this.getStateData();
      this.getCityData();
      var resData = {};
      let res = await BrandService.getBrand(this.state.slug);

      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.brand_name = res.data.brand_name;
        resData.aka_name = res.data.aka_name;
        resData.legal_entity_name = res.data.legal_entity_name;
        resData.tax_id = res.data.tax_id;
        resData.brand_logo = res.data.brand_logo;
        resData.imageUrl = res.data.brand_logo_url
          ? res.data.brand_logo_url
          : brandLogo;
        resData.street_1 = res.data.street_1;
        resData.street_2 = res.data.street_2;
        resData.city = res.data.city;
        resData.country_id = res.data.country_slug ? res.data.country_slug : "";
        resData.county_id = res.data.county_slug ? res.data.county_slug : "";
        resData.state_id = res.data.state_slug ? res.data.state_slug : "";
        resData.city_id = res.data.city_slug ? res.data.city_slug : "";
        resData.country_name = res.data.country
          ? res.data.country.country_name
          : "";
        resData.state_name = res.data.state ? res.data.state.state_name : "";
        resData.zipcode = res.data.zipcode;
        resData.contact_name = res.data.contact_name;
        resData.phone_number = res.data.phone_number;
        resData.email = res.data.email;
        resData.term_condition = res.data.term_condition
          ? res.data.term_condition
          : "";
        resData.website_cookie = res.data.website_cookie
          ? res.data.website_cookie
          : "";
        resData.brand_domain = res.data.brand_domain;
        resData.sort_order =
          res.data.sort_order === 0 ? "" : res.data.sort_order;
        resData.status_id = res.data.status_id === 1 ? true : false;

        resData.orion_haus_account_manager_slug =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.slug
            ? res.data.orion_haus_account_manager.slug
            : "";
        resData.asset_manager_slug =
          res.data && res.data.asset_manager && res.data.asset_manager.slug
            ? res.data.asset_manager.slug
            : "";
        // resData.orion_haus_account_manager = res.data.orion_haus_account_manager ? res.data.orion_haus_account_manager : ""
        // resData.orion_haus_email = res.data.orion_haus_email ? res.data.orion_haus_email : ""
        // resData.orion_haus_phone_number = res.data.orion_haus_phone_number ? res.data.orion_haus_phone_number : ""
        // resData.asset_manager = res.data.asset_manager ? res.data.asset_manager : ""
        // resData.asset_manager_email = res.data.asset_manager_email ? res.data.asset_manager_email : ""
        // resData.asset_manager_phone_number = res.data.asset_manager_phone_number ? res.data.asset_manager_phone_number : ""

        var selectedClientList = [];
        selectedClientList = res.data.client
          ? [
              {
                value: res.data.client.slug,
                label: res.data.client.client_name,
              },
            ]
          : "";
        var selectedCityList = [];
        selectedCityList = res.data.city
          ? [
              {
                value: res.data.city.slug,
                label: res.data.city.city_name,
              },
            ]
          : "";
        var selectedStateList = [];
        selectedStateList = res.data.state
          ? [
              {
                value: res.data.state.slug,
                label: res.data.state.state_name,
              },
            ]
          : "";
        var selectedCountyList = [];
        selectedCountyList = res.data.county
          ? [
              {
                value: res.data.county.slug,
                label: res.data.county.county_name,
              },
            ]
          : "";
        var selectedCountryList = [];
        selectedCountryList = res.data.country
          ? [
              {
                value: res.data.country.slug,
                label: res.data.country.country_name,
              },
            ]
          : "";
        var selectedAccountManagerList = [];
        selectedAccountManagerList = res.data.orion_haus_account_manager
          ? [
              {
                value: res.data.orion_haus_account_manager.slug,
                label:
                  res.data.orion_haus_account_manager.first_name +
                  " " +
                  res.data.orion_haus_account_manager.last_name,
              },
            ]
          : "";
        var selectedAssetManagerList = [];
        selectedAssetManagerList = res.data.asset_manager
          ? [
              {
                value: res.data.asset_manager.slug,
                label:
                  res.data.asset_manager.first_name +
                  " " +
                  res.data.asset_manager.last_name,
              },
            ]
          : "";
      }
      this.setState(resData);
      this.setState({
        selectedClientList: selectedClientList,
        selectedCityList: selectedCityList,
        selectedStateList: selectedStateList,
        selectedCountyList: selectedCountyList,
        selectedCountryList: selectedCountryList,
        selectedAccountManagerList: selectedAccountManagerList,
        selectedAssetManagerList: selectedAssetManagerList,
      });
      // this.getCountyData(resData.state_slug);
      // this.getStateData(resData.country_slug);
      // this.getCityData(resData.state_slug, resData.county_slug);
      this.defaultData = resData;
    }
    // this.setState({ showSpinner: false, loading: false });
    this.getClientData();
    this.getCountryData();
    this.getCityData();
    this.getAccountManagerList();
    this.getAssetManagerList();
  }

  onImageChange = async (event) => {
    this.setState({ showSpinner: true, loading: true });
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "brand_logo",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
      );
      fileData.append("mediaType", "1");
      let res = await BrandService.uploadBrandLogo(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          brand_logo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
    this.setState({ showSpinner: false, loading: false });
  };

  async getCountryData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var countryList = [];

    let res = await CountryService.getCountry(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
    }
    const newCountryList = countryList.map(({ slug, country_name }) => ({
      value: slug,
      label: country_name,
    }));
    this.setState({ countryList: countryList, newCountryList: newCountryList });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getStateData(countryValue, queryString = "") {
    //this.setState({ showSpinner: true, loading: true });
    var stateList = [];

    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
        countryValue +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
    }
    const newStateList = stateList.map(({ slug, state_name }) => ({
      value: slug,
      label: state_name,
    }));
    this.setState({ stateList: stateList, newStateList: newStateList });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getCountyData(value, queryString = "") {
    // this.setState({ showSpinner: true, loading: true });
    var countyList = [];
    var countyListTotal = 0;

    let res = await CountyService.getCounty(
      "is_dropdown=1&state_slug=" +
        value +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
      countyListTotal = countyList ? countyList.length : 0;
    }
    const newCountyList =
      countyListTotal > 0
        ? countyList.map(({ slug, county_name }) => ({
            value: slug,
            label: county_name,
          }))
        : [];
    this.setState({
      countyList: countyList,
      newCountyList: newCountyList,
      countyListTotal: countyListTotal,
    });
    // this.setState({ showSpinner: false, loading: false });
  }

  async getCityData(stateValue, countyValue, queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;
    let res = await CityService.getCity(
      "is_dropdown=1&state_slug=" + stateValue + "&county_slug=" + countyValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList =
      cityListTotal > 0
        ? cityList.map(({ slug, city_name }) => ({
            value: slug,
            label: city_name,
          }))
        : [];
    this.setState({
      cityList: cityList,
      newCityList: newCityList,
      cityListTotal: cityListTotal,
    });
    //   this.setState({ showSpinner: false, loading: false });
  }

  async getClientData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var clientList = [];
    var clientListTotal = 0;
    let res = await ClientService.getClientList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      clientList = res.data ? res.data : [];
      clientListTotal = clientList ? clientList.length : 0;
    }
    const newClientList =
      clientListTotal > 0
        ? clientList.map(({ slug, client_name }) => ({
            value: slug,
            label: client_name,
          }))
        : [];
    this.setState({
      clientList: clientList,
      newClientList: newClientList,
      clientListTotal: clientListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  onChangePhone(value, country, e, formattedValue) {
    this.setState({ phone_number: value });
    this.setState({ dial_code: country.dialCode });
  }

  saveOrUpdateBrand = async (e) => {
    e.preventDefault();
    if (
      this.state.status_id === true
        ? this.validator.allValid() && this.customValidate()
        : !this.validator.errorMessages.brand_name
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        brand_name: this.state.brand_name,
        aka_name: this.state.aka_name,
        client_slug: this.state.client_id,
        legal_entity_name: this.state.legal_entity_name,
        tax_id: this.state.tax_id,
        brand_logo: this.state.brand_logo,
        street_1: this.state.street_1,
        street_2: this.state.street_2,
        city_slug: this.state.city_id,
        state_slug: this.state.state_id,
        zipcode: this.state.zipcode,
        country_slug: this.state.country_id,
        county_slug: this.state.county_id,
        contact_name: this.state.contact_name,
        phone_number: this.state.phone_number,
        dial_code: this.state.dial_code,
        email: this.state.email,
        brand_domain: this.state.brand_domain,
        term_condition: this.state.term_condition,
        website_cookie: this.state.website_cookie,
        status_id: this.state.status_id === true ? 1 : 2,
        sort_order: this.state.sort_order,
        orion_haus_account_manager_slug:
          this.state.orion_haus_account_manager_slug,
        asset_manager_slug: this.state.asset_manager_slug,
        // orion_haus_account_manager: this.state.orion_haus_account_manager,
        // orion_haus_email: this.state.orion_haus_email,
        // orion_haus_phone_number: this.state.orion_haus_phone_number,
        // asset_manager: this.state.asset_manager,
        // asset_manager_email: this.state.asset_manager_email,
        // asset_manager_phone_number: this.state.asset_manager_phone_number,
      };

      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        // inputData.status_id = this.state.status_id ? this.state.status_id : 2;
        let res = await BrandService.updateBrand(this.state.slug, inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/brand/view/" + this.state.slug + "/brand-info"
              ),
            global.redirect_time
          );
        } else {
          // let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
            const input = document.getElementsByClassName("text-danger");
            if (input.length > 0) {
              input[0].scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start",
              });
            }
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      } else {
        let res = await BrandService.createBrand(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/brands"),
            global.redirect_time
          );
        } else {
          // let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
            //scroll to error logic
            const input = document.getElementsByClassName("text-danger");
            if (input.length > 0) {
              input[0].scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start",
              });
            }
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      if (this.state.status_id !== true) {
        this.validator.showMessageFor("brand_name");
      } else {
        this.validator.showMessages();
        //scroll to error logic
        const input = document.getElementsByClassName("text-danger");
        if (input.length > 0) {
          input[0].scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
      }
    }
  };

  async getAllRealetedData(slug, queryString = "") {
    //this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await CityService.getDataByCitySlug(
      slug,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.country_id = res.data.countryData
        ? res.data.countryData.country.slug
        : "";
      resData.country_name = res.data.countryData
        ? res.data.countryData.country.country_name
        : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.countryData.country
        ? [
            {
              value: res.data.countryData.country
                ? res.data.countryData.country.slug
                : "",
              label: res.data.countryData.country
                ? res.data.countryData.country.country_name
                : "",
            },
          ]
        : "";
      resData.county_id = res.data.county ? res.data.county.slug : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county
        ? [
            {
              value: res.data.county.slug ? res.data.county.slug : "",
              label: res.data.county.county_name
                ? res.data.county.county_name
                : "",
            },
          ]
        : "";
      resData.state_id = res.data.state ? res.data.state.slug : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      var selectedStateList = [];
      selectedStateList = res.data.state
        ? [
            {
              value: res.data.state.slug ? res.data.state.slug : "",
              label: res.data.state.state_name ? res.data.state.state_name : "",
            },
          ]
        : "";
    }
    this.setState(resData);
    this.setState({
      selectedStateList: selectedStateList,
      selectedCountyList: selectedCountyList,
      selectedCountryList: selectedCountryList,
    });
    // this.setState({ showSpinner: false, loading: false });
  }

  changeHandler = (event) => {
    // console.log(event.target.name,event.target.value,event.target.value.length);
    this.setState({ errors: "" });
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    if (event.target.name === "city_id") {
      this.getAllRealetedData(event.target.value);
    }

    if (
      event.target.name === "orion_haus_account_manager" &&
      event.target.value.length > 3
    ) {
      this.getAllAccountManagerData(event.target.value);
    }

    if (
      event.target.name === "asset_manager" &&
      event.target.value.length > 3
    ) {
      this.getAllAssetManagerData(event.target.value);
    }

    // if (event.target.name === "country_id") {
    //   if (event.target.value === "") {
    //     this.setState({
    //       state_id: "",
    //       county_id: "",
    //       city_id: "",
    //       stateList: [],
    //       countyList: [],
    //       cityList: [],
    //     });
    //   } else {
    //     this.getStateData(event.target.value);
    //     this.setState({
    //       county_id: "",
    //       city_id: "",
    //       countyList: [],
    //       cityList: [],
    //     });
    //   }
    // }
    // if (event.target.name === "state_id") {
    //   if (event.target.value === "") {
    //     this.setState({
    //       county_id: "",
    //       city_id: "",
    //       countyList: [],
    //       cityList: [],
    //     });
    //   } else {
    //     this.getCountyData(event.target.value);
    //     this.getCityData(event.target.value, "");
    //   }
    // }
    // if (event.target.name === "county_id") {
    //   if (event.target.value === "") {
    //     this.getCityData(input.state_id, "");
    //   } else {
    //     this.getCityData(input.state_id, event.target.value);
    //   }
    // }

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  handleChangeClientList(value) {
    let client_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        client_id.push(item.value);
      });
    }
    this.setState({
      selectedClientList: value,
      client_id: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  async getAccountManagerList() {
    //  this.setState({ showSpinner: true, loading: true });
    var accountManagerList = [];

    let res = await UserService.getUserDropdown("roleTitle=ADMIN");
    if (global.successStatus.includes(res.status)) {
      accountManagerList = res.data ? res.data : [];
    }
    const newAccountManagerList = accountManagerList.map(
      ({ slug, first_name, last_name }) => ({
        value: slug,
        label: first_name + " " + last_name,
      })
    );
    this.setState({
      newAccountManagerList: newAccountManagerList,
      accountManagerList: accountManagerList,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getAssetManagerList() {
    //  this.setState({ showSpinner: true, loading: true });
    var assetManagerList = [];

    let res = await UserService.getUserDropdown("roleTitle=ASSET_MANAGER");
    if (global.successStatus.includes(res.status)) {
      assetManagerList = res.data ? res.data : [];
    }
    const newAssetManagerList = assetManagerList.map(
      ({ slug, first_name, last_name }) => ({
        value: slug,
        label: first_name + " " + last_name,
      })
    );
    this.setState({
      newAssetManagerList: newAssetManagerList,
      assetManagerList: assetManagerList,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  handleChangeAccountManagerList(value) {
    let orion_haus_account_manager_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        orion_haus_account_manager_slug.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedAccountManagerList: value,
      orion_haus_account_manager_slug: state_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeAssetManagerList(value) {
    let asset_manager_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        asset_manager_slug.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedAssetManagerList: value,
      asset_manager_slug: state_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCityList(value) {
    let city_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_id.push(item.value);
      });
    }
    if (value !== null) {
      this.getAllRealetedData(value.value);
    }
    const city_id_value = value !== null ? value.value : [];
    this.setState({
      selectedCityList: value,
      city_id: city_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeStateList(value) {
    let state_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_id.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStateList: value,
      state_id: state_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCountyList(value) {
    let county_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        county_id.push(item.value);
      });
    }

    const county_id_value = value !== null ? value.value : [];
    this.setState({
      selectedCountyList: value,
      county_id: county_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCountryList(value) {
    let country_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_id.push(item.value);
      });
    }
    const country_id_value = value !== null ? value.value : [];
    this.setState({
      selectedCountryList: value,
      country_id: country_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("brand_name");
    }
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update Brand";
    } else {
      return "Add Brand";
    }
  }

  removePhoto() {
    this.setState({ brand_logo: "", imageUrl: "" });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <div>
        <main>
          <Container className="innter-container pr-0 over-fl">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                {/* <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <BrandSidebar brandSlug={this.state.slug}/>
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav"> */}
                <div className="col-md-12">
                  {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
                  <Container>
                    {/* <div className="row row-xs">
                      <div className="col-md-10 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
                        <div className="backpage">
                          <Link
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm("Do you want to exit without saving?")
                                )
                                  this.props.history.push("/brands");
                                else return false;
                              else this.props.history.push("/brands");
                            }}
                          >
                            BRAND{" "}
                          </Link>
                          <span> / </span>
                          {this.state.slug !== undefined ? (
                            <span>EDIT</span>
                          ) : (
                            <span>ADD</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-2 mt-3">
                        <p className="d-flex justify-content-end">
                          <span className="back-parent d-flex align-items-center">
                            <Link
                              onClick={() => {
                                if (this.state.confirm_back === 1)
                                  if (
                                    window.confirm("Do you want to exit without saving?")
                                  )
                                    this.props.history.push("/brands");
                                  else return false;
                                else this.props.history.push("/brands");
                              }}
                            >
                              <img src={BackButton} alt="" className="back-button" />
                            </Link>
                            <Link
                              onClick={() => {
                                if (this.state.confirm_back === 1)
                                  if (
                                    window.confirm("Do you want to exit without saving?")
                                  )
                                    this.props.history.push("/brands");
                                  else return false;
                                else this.props.history.push("/brands");
                              }}>Back</Link>
                          </span>
                        </p>
                      </div>
                    </div> */}
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap ">
                          <h3>
                            {this.state.slug !== undefined
                              ? this.state.brand_name
                                ? this.state.brand_name
                                : ""
                              : "Brands"}
                          </h3>
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn-success-outline"
                              onClick={() => {
                                if (this.state.confirm_back === 1)
                                  if (
                                    window.confirm(
                                      "Do you want to exit without saving?"
                                    )
                                  )
                                    this.state.slug !== undefined
                                      ? this.props.history.push(
                                          "/brand/view/" +
                                            this.state.slug +
                                            "/brand-info"
                                        )
                                      : this.props.history.push("/brands");
                                  else return false;
                                else
                                  this.state.slug !== undefined
                                    ? this.props.history.push(
                                        "/brand/view/" +
                                          this.state.slug +
                                          "/brand-info"
                                      )
                                    : this.props.history.push("/brands");
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn-success ml-3"
                              type="button"
                              onClick={this.saveOrUpdateBrand}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : this.state.slug !== undefined
                                ? "Save"
                                : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 pd-lg-l-30 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="col-md-12 mg-t-15 pd-lg-r-0 pd-xs-r-15">
                      <div className="tabNavigation">
                        <Tabs
                          defaultActiveKey="Basics"
                          id="uncontrolled-tab-example"
                        >
                          <Tab eventKey="Basics" title="Basics">
                            <div className="m-0">
                              {/* withoui tabing */}
                              <div className="row align-items-center pd-t-0 pd-lg-b-30 pd-xs-b-0 border-bottom-0 mg-lg-t-15 mg-xs-t-15">
                                {/* col */}
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    DBA
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="brand_name"
                                    value={this.state.brand_name || ""}
                                    maxLength="50"
                                    // tabIndex="1"
                                    // placeholder="DBA"
                                  />
                                  {this.state.errors.brand_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.brand_name}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "dba",
                                      this.state.brand_name,
                                      "required|max:50",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Brand SKU
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    // tabIndex="3"
                                    type="text"
                                    onKeyPress={
                                      global.onKeyPressEvent.alphaNumValidation
                                    }
                                    // placeholder="AKA Name"
                                    name="aka_name"
                                    value={this.state.aka_name || ""}
                                    maxLength="3"
                                  />
                                  {this.state.errors.aka_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.aka_name}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "brand_sku",
                                      this.state.aka_name,
                                      "required|alpha_num_dash|max:3",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Client
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    className="multiselect"
                                    menuPlacement="auto"
                                    value={this.state.selectedClientList}
                                    options={this.state.newClientList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeClientList(value)
                                    }
                                    defaultValue={this.state.selectedClientList}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />

                                  {this.state.errors.client_id ? (
                                    <div className="text-danger">
                                      {this.state.errors.client_id}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Legal Entity{" "}
                                    <span className="optional">(Optional)</span>
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    maxLength="50"
                                    // tabIndex="4"
                                    type="text"
                                    name="legal_entity_name"
                                    value={this.state.legal_entity_name || ""}
                                    // placeholder="Legal Entity Name"
                                  />
                                  {this.state.errors.legal_entity_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.legal_entity_name}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "legal_entity_name",
                                      this.state.legal_entity_name,
                                      "max:50",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Tax ID{" "}
                                    <span className="optional">(Optional)</span>
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="tax_id"
                                    value={this.state.tax_id || ""}
                                    maxLength="50"
                                    // tabIndex="5"
                                    type="text"
                                    // placeholder="Tax ID"
                                  />
                                  {this.state.errors.tax_id ? (
                                    <div className="text-danger">
                                      {this.state.errors.tax_id}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "tax_id",
                                      this.state.tax_id,
                                      "max:50",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>

                                {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Street 1{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="street_1"
                                    value={this.state.street_1 || ""}
                                  />
                                  {this.state.errors.street_1 ? (
                                    <div className="text-danger">
                                      {this.state.errors.street_1}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "street_1",
                                      this.state.street_1,
                                      "max:100",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Street 2{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="street_2"
                                    value={this.state.street_2 || ""}
                                  />
                                  {this.state.errors.street_2 ? (
                                    <div className="text-danger">
                                      {this.state.errors.street_2}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "street_2",
                                      this.state.street_2,
                                      "max:100",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    City{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    menuPlacement="auto"
                                    value={this.state.selectedCityList}
                                    options={this.state.newCityList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeCityList(value)
                                    }
                                    defaultValue={this.state.selectedCityList}
                                    autoComplete="off"
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />

                                  {this.state.errors.city_id ? (
                                    <div className="text-danger">
                                      {this.state.errors.city_id}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    State{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    className="custom-disable"
                                    value={this.state.state_name || ""}
                                    disabled={true}
                                  />

                                  {this.state.errors.state_id ? (
                                    <div className="text-danger">
                                      {this.state.errors.state_id}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Zip Code{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="zipcode"
                                    value={this.state.zipcode || ""}
                                    maxLength="5"
                                    onKeyPress={
                                      global.onKeyPressEvent.numberValidation
                                    }
                                  />
                                  {this.state.errors.zipcode ? (
                                    <div className="text-danger">
                                      {this.state.errors.zipcode}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "zipcode",
                                      this.state.zipcode,
                                      "max:5",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    County
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    menuPlacement="auto"
                                    value={this.state.selectedCountyList}
                                    options={this.state.newCountyList.filter(
                                      (option) => {
                                        const val1 = this.state.county_id
                                          ? this.state.county_id
                                          : "";
                                        if (val1 === option.slug) return true;
                                      }
                                    )}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeCountyList(value)
                                    }
                                    defaultValue={this.state.selectedCountyList}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />

                                  {this.state.errors.county_id ? (
                                    <div className="text-danger">
                                      {this.state.errors.county_id}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Country{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Form.Control
                                    type="text"
                                    className="custom-disable"
                                    value={this.state.country_name || ""}
                                    disabled={true}
                                  />

                                  {this.state.errors.country_id ? (
                                    <div className="text-danger">
                                      {this.state.errors.country_id}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div> */}

                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Domain Name
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="brand_domain"
                                    value={this.state.brand_domain || ""}
                                    maxLength="70"
                                  />
                                  {this.state.errors.brand_domain ? (
                                    <div className="text-danger">
                                      {this.state.errors.brand_domain}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "domain name",
                                      this.state.brand_domain,
                                      "url|required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Contact Name{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="contact_name"
                                    value={this.state.contact_name || ""}
                                    maxLength="50"
                                  />
                                  {this.state.errors.contact_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.contact_name}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "contact_name",
                                      this.state.contact_name,
                                      "max:50",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>

                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Phone{" "}
                                    <span className="optional">(Optional)</span>
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <PhoneInput
                                    country={global.country}
                                    // autoFormat= "true"
                                    enableSearch="true"
                                    countryCodeEditable="true"
                                    value={this.state.phone_number}
                                    onChange={this.onChangePhone}
                                  />
                                  {this.state.errors.phone_number ? (
                                    <div className="text-danger">
                                      {this.state.errors.phone_number}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "phone",
                                      this.state.phone_number,
                                      "max:15",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Email{" "}
                                    <span className="optional">(Optional)</span>
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="email"
                                    value={this.state.email || ""}
                                    // placeholder="Email"
                                    maxLength="70"
                                  />
                                  {this.state.errors.email ? (
                                    <div className="text-danger">
                                      {this.state.errors.email}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "email",
                                      this.state.email,
                                      "email|max:70",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Orion Haus Account Manager{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    menuPlacement="auto"
                                    value={
                                      this.state.selectedAccountManagerList
                                    }
                                    options={this.state.newAccountManagerList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeAccountManagerList(value)
                                    }
                                    defaultValue={
                                      this.state.selectedAccountManagerList
                                    }
                                    autoComplete="off"
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />
                                  {/* <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="orion_haus_account_manager"
                                    value={this.state.orion_haus_account_manager || ""}
                                    maxLength="100"
                                  />
                                  {this.state.newAccountManagerList.map((item,i) => {
                                    return(<>
                                      <p key={i} onClick={() => this.handleChangeAccountManagerList(item.slug)}>{item.label}</p>
                                    </>)
                                  }) */}

                                  {this.state.errors
                                    .orion_haus_account_manager_slug ? (
                                    <div className="text-danger">
                                      {
                                        this.state.errors
                                          .orion_haus_account_manager_slug
                                      }
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Orion Haus Email{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="orion_haus_email"
                                    value={this.state.orion_haus_email || ""}
                                    maxLength="70"
                                    disabled
                                  />
                                  {this.state.errors.orion_haus_email ? (
                                    <div className="text-danger">
                                      {this.state.errors.orion_haus_email}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "orion_haus_email",
                                      this.state.orion_haus_email,
                                      "email|max:70",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Orion Haus Phone{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <PhoneInput
                                    country={global.country}
                                    enableSearch="true"
                                    countryCodeEditable="true"
                                    value={this.state.orion_haus_phone_number}
                                    onChange={(orion_haus_phone_number) =>
                                      this.setState({ orion_haus_phone_number })
                                    }
                                    disabled
                                  />
                                  {this.state.errors.orion_haus_phone_number ? (
                                    <div className="text-danger">
                                      {this.state.errors.orion_haus_phone_number}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "orion_haus_phone_number",
                                      this.state.orion_haus_phone_number,
                                      "max:15",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div> */}
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Asset Manager{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    menuPlacement="auto"
                                    value={this.state.selectedAssetManagerList}
                                    options={this.state.newAssetManagerList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeAssetManagerList(value)
                                    }
                                    defaultValue={
                                      this.state.selectedAssetManagerList
                                    }
                                    autoComplete="off"
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />
                                  {this.state.errors.asset_manager_slug ? (
                                    <div className="text-danger">
                                      {this.state.errors.asset_manager_slug}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Asset Manager Email{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="asset_manager_email"
                                    value={this.state.asset_manager_email || ""}
                                    maxLength="70"
                                    disabled
                                  />
                                  {this.state.errors.asset_manager_email ? (
                                    <div className="text-danger">
                                      {this.state.errors.asset_manager_email}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "asset_manager_email",
                                      this.state.asset_manager_email,
                                      "email|max:70",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Asset Manager Phone{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <PhoneInput
                                    country={global.country}
                                    enableSearch="true"
                                    countryCodeEditable="true"
                                    value={this.state.asset_manager_phone_number}
                                    onChange={(asset_manager_phone_number) =>
                                      this.setState({ asset_manager_phone_number })
                                    }
                                    disabled
                                  />
                                  {this.state.errors.asset_manager_phone_number ? (
                                    <div className="text-danger">
                                      {this.state.errors.asset_manager_phone_number}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "asset_manager_phone_number",
                                      this.state.asset_manager_phone_number,
                                      "max:15",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div> */}
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <InputGroup className="">
                                    <label className="form-label text-left pd-b-5 w-100">
                                      Website Cookies{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>
                                    <Form.Control
                                      onChange={this.changeHandler}
                                      name="website_cookie"
                                      value={this.state.website_cookie}
                                      className="form-control max_width_100"
                                      id="website_cookie"
                                      as="textarea"
                                      rows={global.textareaRowLength}
                                      // placeholder="Username"
                                    />
                                  </InputGroup>
                                  {this.state.errors.website_cookie ? (
                                    <div className="text-danger">
                                      {this.state.errors.website_cookie}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "website_cookie",
                                      this.state.website_cookie,
                                      "max:100000",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <InputGroup className="">
                                    <label className="form-label text-left pd-b-5 w-100">
                                      Terms & Conditions{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>
                                    <Form.Control
                                      onChange={this.changeHandler}
                                      name="term_condition"
                                      value={this.state.term_condition}
                                      className="form-control max_width_100"
                                      id="term_condition"
                                      as="textarea"
                                      rows={global.textareaRowLength}
                                      // placeholder="Username"
                                    />
                                  </InputGroup>
                                  {this.state.errors.term_condition ? (
                                    <div className="text-danger">
                                      {this.state.errors.term_condition}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "term_condition",
                                      this.state.term_condition,
                                      "max:100000",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row align-items-center pd-lg-b-10 pd-xs-b-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 border-bottom-0">
                              <div className="col-md-1 pl-0">
                                <label className="form-label mg-b-0">
                                  Logo
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                              </div>
                              {/* col */}
                              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                                <div className="fileUpload d-flex flex-wrap justify-content-between">
                                  <span>
                                    <label className="hand-cursor">
                                      <input
                                        onChange={this.onImageChange}
                                        type="file"
                                        name="brand_logo"
                                        // tabIndex="2"
                                      />
                                      {this.state.imageUrl ? (
                                        <img
                                          src={this.state.imageUrl}
                                          alt="Logo"
                                        />
                                      ) : (
                                        <img src={brandLogo} alt="Logo" />
                                      )}
                                    </label>
                                  </span>

                                  <ul className="ruLink">
                                    <li>
                                      <label>
                                        <input
                                          onChange={this.onImageChange}
                                          type="file"
                                          name="brand_logo"
                                          tabIndex="2"
                                        />
                                      </label>
                                      Change Logo
                                    </li>
                                  </ul>
                                  <p
                                    className="hand-cursor remove-pic remove-pic-two m-0"
                                    onClick={this.removePhoto}
                                  ></p>
                                </div>
                                {this.state.errors.brand_logo ? (
                                  <div className="text-danger">
                                    {this.state.errors.brand_logo}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "brand_logo",
                                    this.state.brand_logo,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}

                                {/* <span className="d-inline-flex align-items-start justify-content-start infosize">
                                    <img src={infosize} className="mg-r-5" />
                                    <p>
                                      {" "}
                                      Recommended size - 512px by 512px (1:1 ratio).
                                      Max file size - 5 MB
                                    </p>
                                  </span> */}
                              </div>
                              {/* col */}
                            </div>
                            {/* <div className="row align-items-center pd-lg-b-30 pd-xs-b-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 border-bottom-0 pd-t-0 mg-t-30">
                              <div className="col-md-1 pl-0">
                                <label className="form-label mg-b-0"></label>
                              </div>
                              <div className="col-md-3">
                                <p className="hand-cursor text-danger" onClick={this.removePhoto}>Remove</p>
                              </div>
                            </div> */}

                            <div className="m-0">
                              {/* row */}
                              <div className="row align-items-center pd-lg-b-30 pd-xs-b-15 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 border-bottom-0 mg-t-30">
                                <div className="col-md-1 pl-0">
                                  <label className="form-label text-left mg-b-0">
                                    Status
                                  </label>
                                </div>
                                {/* col */}
                                <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start">
                                  <b
                                    className={
                                      this.state.status_id
                                        ? "active"
                                        : "inactive"
                                    }
                                  >
                                    {this.state.status_id
                                      ? "Active"
                                      : "Inactive"}
                                  </b>
                                  <div
                                    className={
                                      this.state.status_id
                                        ? "az-toggle on"
                                        : "az-toggle"
                                    }
                                    onClick={() => this.changeStatus()}
                                  >
                                    <span></span>
                                  </div>
                                </div>
                                {/* col */}
                                {/* row */}
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </Container>
        </main>
      </div>
    );
  }
}

export default BrandForm;
