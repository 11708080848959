////////////////////////////////////////////////////////////
//     							                          //
//  Program: ChangePassword.jsx                           //
//  Application: authentication                           //
//  Option: For Change Password into project              //
//  Developer: NP 						                            //
//  Date: 2021-12-29                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import AuthenticationService from "../../services/AuthenticationService";
import SimpleReactValidator from "simple-react-validator";
import { Container } from "react-bootstrap";
import Alerts from "../common/Alerts";

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };

    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changePassword = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      // Validate all fields before data submission
      this.setState({ isSubmit: true });
      let input = {
        old_password: this.state.oldPassword,
        new_password: this.state.newPassword,
      };
      let res = await AuthenticationService.changePassword(input);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        setTimeout(
          () => this.props.history.push("/dashboard"),
          global.redirect_time
        );
      } else {
        if (
          res.data.result.errorDetail !== null &&
          res.data.result.errorDetail !== undefined
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    this.setState({ errors: {} });
    var errors = this.state.errors;
    this.setState({ [event.target.name]: event.target.value });

    if (
      event.target.name === "confirmPassword" &&
      event.target.value.length >= 6
    ) {
      var pw =
        event.target.name === "newPassword"
          ? event.target.value
          : this.state.newPassword;
      var cpw =
        event.target.name === "confirmPassword"
          ? event.target.value
          : this.state.confirmPassword;

      if (pw !== cpw) {
        errors["confirmPassword"] =
          "Password and confirm password must be same!";
        this.setState({ errors: errors });
      } else {
        errors["confirmPassword"] = "";
        this.setState({ errors: errors });
      }
    }

    // Validate
    this.validator.showMessageFor(event.target.name);
  };

  render() {
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <Container>
            <div className="row row-xs">
              <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>

              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center">
                  <h3>Change Password</h3>
                  <button
                    className="btn-success"
                    onClick={this.changePassword}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Save"}
                  </button>
                </div>
              </div>
              <div className="col-md-12 pl-0 pr-0 mg-t-30">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
            </div>
          </Container>
          <Container>
            <div className="row row-xs  pd-l-5  pd-r-5">
              <div className="dashboardRightcard background-none col-md-12 mg-t-0 mg-b-10 pt-4 pd-b-10">
                <div className="row align-items-center pd-t-0 pd-b-30 border-bottom-0">
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15 form-group">
                    <label className="form-label text-left  pd-b-5">
                      Current Password
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <input
                      type="password"
                      name="oldPassword"
                      className="form-control"
                      value={this.state.oldPassword}
                      onChange={this.changeHandler}
                      maxLength="25"
                    />

                    {this.validator.message(
                      "current_password",
                      this.state.oldPassword,
                      "required|min:6|max:20",
                      { className: "text-danger" }
                    )}
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15 form-group">
                    <label className="form-label text-left  pd-b-5">
                      New Password
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <input
                      type="password"
                      name="newPassword"
                      className="form-control"
                      value={this.state.newPassword}
                      onChange={this.changeHandler}
                      maxLength="25"
                      autoComplete="off"
                    />

                    {this.state.errors.new_password ? (
                      <div className="text-danger">
                        {this.state.errors.new_password}
                      </div>
                    ) : (
                      this.validator.message(
                        "newPassword",
                        this.state.newPassword,
                        "required|min:8|max:20",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15 form-group">
                    <label className="form-label text-left  pd-b-5">
                      Confirm New Password
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                      value={this.state.confirmPassword}
                      onChange={this.changeHandler}
                      maxLength="25"
                      autoComplete="off"
                    />
                    {this.state.errors.confirmPassword ? (
                      <div className="text-danger">
                        {this.state.errors.confirmPassword}
                      </div>
                    ) : (
                      this.validator.message(
                        "confirmPassword",
                        this.state.confirmPassword,
                        "required|min:8|max:20",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
      </div>
    );
  }
}

export default ChangePassword;
