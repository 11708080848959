///////////////////////////////////////////////////////////////
//     							                             //
//  Program: ReservationList.jsx                             //
//  Application: Snapshot                                   //
//  Option: to view Reservation list which not pass by guest form  //
//  Developer: Kavita Singh  						       //
//  Date: 2023-05-03                                       //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import { withRouter } from "react-router";
import CompanyService from "../../services/CompanyService";
import NoBooking from "../../assets/images/snapshoticons/Bookings.svg";
import Moment from "moment";
class ReservationList extends Component {
  constructor(props) {
    super(props);
    // console.log(props);

    this.state = {
      propertySlug: this.props.propertySlug,
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      spinnerLoader: false,
      loading: false,
      reservationCount: 0,
    };
  }

  componentDidMount() {
    this.setState({ reservationCount: 0 });
    this.setState({ spinnerLoader: true, loading: true });
    if (this.state.propertySlug) {
      this.getData(this.state.propertySlug);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ reservationCount: 0 });
    this.setState({ spinnerLoader: true, loading: true });
    if (nextProps.propertySlug) {
      this.getData(nextProps.propertySlug);
    }
  }

  handleClickCheckinList(property_slug, reservation_slug) {
    if (reservation_slug) {
      const win = window.open(
        "/properties/view/" +
          property_slug +
          "/reservations/view/" +
          reservation_slug,
        "_blank"
      );
      win.focus();
    } else {
      return false;
    }
  }

  async getData(propertySlug) {
    this.setState({ spinnerLoader: true, loading: true });
    var resData = {};
    let res = await CompanyService.getReservationList(propertySlug);
    if (global.successStatus.includes(res.status)) {
      resData.reservation_list =
        res.data && res.data.reservation_list ? res.data.reservation_list : [];
      this.setState({
        resData: resData,
        reservationCount: res.data.reservation_list.length,
      });
    }
    this.setState({ spinnerLoader: false, loading: false });
  }

  render() {
    return (
      <div className="col-xxl-6 col-md-6 col-sm-12 col-12 mg-b-20">
        <div className="card card-table-two">
          <div className=" d-flex justify-content-between">
            <div className="">
              <h6 className="card-title">Reservations</h6>
              <span className="d-block mg-b-0">
                All reservations list which are not verified by guest
              </span>
            </div>
            <h6 className="card-title">
              Total Count : {this.state.reservationCount}
            </h6>
          </div>
          {this.state.reservationCount > 0 ? (
            <div className="table-responsive">
              <table className="table table-dashboard-two mg-b-0">
                <thead>
                  <tr>
                    <th className="wd-lg-25p tx-left">S.No</th>
                    <th className="wd-lg-25p tx-left">Unit Name</th>
                    <th className="wd-lg-25p tx-left">Guest</th>
                    <th className="wd-lg-25p text-left">Check-In Date</th>
                    <th className="wd-lg-25p tx-left">Check-Out Date</th>
                    {/* <th className="wd-lg-25p tx-left">Amount Paid</th> */}
                    <th className="wd-lg-25p tx-left">Booked at</th>
                    <th className="wd-lg-25p tx-left">Channel</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.resData.reservation_list.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        value={item}
                        onClick={() =>
                          this.handleClickCheckinList(
                            item.property_slug,
                            item.reservation_slug
                          )
                        }
                      >
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {i + 1}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.unit_name ? item.unit_name : ""}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          <span>
                            {item.first_name
                              ? item.first_name + " " + item.last_name
                              : ""}
                          </span>
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable date_tbl_formate">
                          {item.checkin_date
                            ? Moment(item.checkin_date).format(
                                global.dateTimeFormat
                              )
                            : ""}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable date_tbl_formate">
                          {item.checkout_date
                            ? Moment(item.checkout_date).format(
                                global.dateTimeFormat
                              )
                            : ""}
                        </td>
                        {/* <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.amount_paid ? "$" + item.amount_paid : ""}
                        </td> */}
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.booked_at ? item.booked_at : ""}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.channel ? item.channel : ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <div className="table-responsive overflow-hidden">
                {this.state.spinnerLoader ? (
                  global.snapshotloader
                ) : (
                  <div className="text-center">
                    <img src={NoBooking} alt="" />
                    <p>No Reservation list.</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(ReservationList);
