//                                                           //
//  Program: TopPerformerReport.jsx                              //
//  Application: Report                                      //
//  Option: Property Reports Listing                         //
//  Developer: NP 		                                     //
//  Date: 2022-08-30                                         //
//                                                           //
/////////////////////////////////////////////////////////   ///

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { Container, Tab, Modal, InputGroup, Form } from "react-bootstrap";
import ReportSidebar from "../common/ReportSidebar";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import ReportService from "../../services/ReportService";
import Select from "react-select";
import UnitsService from "../../services/UnitsService";
import Moment from "moment";
import MultiImageService from "./../../services/MultiImageService";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyReportSidebar from "../common/AllPropertyReportSidebar";
class GuestDeposite extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      errors: {},
      importModal: false,
      file_url: "",
      file_name: "",
      open: true,
      eventKey: "0",
      is_inactive: false,
      isSubmit: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      module_search: "",
      module_search_display: "",
      exportMesssage: "",
      exportFilePath: "",
      exportResult: "",
      alertType: "xlsx",
      is_search: 0,
      isSearch: global.isSearch,
      is_pay_out_modal: false,
      input: {},
      unitList: [],
      selectedUnitList: [],
      newSecurityList: [{
        label:"Security deposit",
        value: 1
      },
      {label:"Damage waiver",
      value: 2}
      ],
      selectedSecurityList: [],
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      totalPayOutList: {},
      deleteRoleModal: false,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: true,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      configPay: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: true,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      newUnitList: [],
      extraButtons: [],
      columns: [
        
        // {
        //   key: "stripe_id",
        //   text: "Transaction Id",
        //   className: "unit_number hand-cursor text-center td_clickable",
        //   width: 300,
        //   cell: (record) => {
        //     return record.stripe_id;
        //   },
        // },
        // {
        //   key: "listing_name",
        //   text: "Listing Name",
        //   className: "unit_number hand-cursor text-center td_clickable",
        //   width: 300,
        //   cell: (record) => {
        //     return record.listing_name;
        //   },
        // },
        {
          key: "unit_number",
          text: "Unit",
          className: "unit_number hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            return record.unit_name;
          },
        },
        {
          key: "confirmation_code",
          text: "Reservation Code",
          className: "unit_number hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            return record.confirmation_code;
          },
        },
        {
          key: "checkin_date",
          text: "Check In Date",
          className: "unit_number hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            return record.checkin_date;
          },
        },
        {
          key: "checkout_date",
          text: "Check Out Date",
          className: "unit_number hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            return record.checkout_date;
          },
        },
        {
          key: "amount",
          text: "Security Amount",
          className: "amount hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            return "$" + record.amount;
          },
        },
        {
          key: "guest_name",
          text: "Guest Name",
          className: "resident_name hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            return record.guest_name;
          },
        },
        
        {
          key: "security_status",
          text: "Security Status",
          className: "security_status hand-cursor text-center td_clickable",
          width: 300,
          sortable: true,
          cell: (record) => {
            return record.security_status;
          },
        },
        {
          key: "deposite_validity",
          text: "Deposit Validity",
          className: "deposite_validity hand-cursor text-center td_clickable",
          width: 300,
          sortable: true,
          cell: (record) => {
            return record.deposite_validity;
          },
        },
      ],
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.removeTotalClass = this.removeTotalClass.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      this.getPayOutReports();
      this.gerDefaultInOutDate();
    }
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.getPayOutReports();
      this.gerDefaultInOutDate();
    }, 200);
    this.setState({
      selectedUnitList: [],
      module_search: [],
      is_search: 0,
    });
  }

  async clearFilter() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.setState({ is_search: 0 });
    this.setState({
      checkout_date_from: firstDay,
      checkout_date_to: lastDay,
      unit_slug: "",
      deposite_type:"",
      security_slug:"",
      confirmation_code: "",
      selectedUnitList: [],
    });
    this.setState({ module_search: "" });
    await this.removeTotalClass();
    this.getPayOutReports();
  }

  async ExportData() {
    this.setState({ showSpinner: true, isSubmit: true });
    let data = {
      property_slug: this.state.property_slug,
    };

    let checkOutFromDate = this.state.checkout_date_from
      ? Moment(this.state.checkout_date_from).format("YYYY-MM-DD")
      : "";
    let checkOutDateTo = this.state.checkout_date_to
      ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
      : "";
     let  security_type = this.state.security_slug ? this.state.security_slug:1; 
     let checkout_date = this.state.checkout_date ? Moment(this.state.checkout_date).format("YYYY-MM-DD"):"";
     let checkin_date = this.state.checkin_date ?  Moment(this.state.checkin_date).format("YYYY-MM-DD"):"";
    let queryString =
     "deposite_type=" +
     security_type +"&checkout_date=" +checkout_date+
     "&checkin_date=" + checkin_date +
      "&property_slug=" +
      (this.state.property_slug ? this.state.property_slug : "") +
      "&is_export=1" +
      "&file_type=" +
      this.state.alertType +
      "&created_at_from=" +
      checkOutFromDate +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&created_at_to=" +
      checkOutDateTo + "&confirmation_code=" + (this.state.confirmation_code ? this.state.confirmation_code :"");
    let res = await ReportService.getDepositeReport(queryString);
    if (res.data.filePath) {
      this.getDownloadFile(
        res.data.filePath,
        res.data.fileName
      );
      this.setState({ exportMesssage: res.message });
      this.setState({ exportResult: data });
      this.setState({ exportFilePath: res.data.filePath });
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  async getDownloadFile(url, name) {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  exportToggle = () => {
    if (this.state.isModel === 1) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: 0 });
      this.setState({ showSpinner: false, isSubmit: false });
    } else {
      this.setState({ isModel: 1 });
    }
  };

  gerDefaultInOutDate() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.setState({ checkout_date_from: firstDay });
    this.setState({ checkout_date_to: lastDay });
  }

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }

  async showPayoutReport(unitSlug) {
    this.setState({ showSpinner: true, loading: true });
    var listPay = [];
    var totalRecordsPay = 0;
    let payoutDetailsTitle;
    let data = {
      property_slug: this.state.property_slug,
    };
    let checkOutFromDate = this.state.checkout_date_from
      ? Moment(this.state.checkout_date_from).format("YYYY-MM-DD")
      : "";
    let checkOutDateTo = this.state.checkout_date_to
      ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
      : "";
    let queryString =
      "unit_slug=" +
      unitSlug +
      "&created_at_from=" +
      checkOutFromDate +
      "&created_at_to=" +
      checkOutDateTo;
    let res = await ReportService.showPayOutReport(data, queryString);
    if (global.successStatus.includes(res.status)) {
      listPay = res.data.data ? res.data.data : [];
      totalRecordsPay = res.data.data.length ? res.data.data.length : 0;
      payoutDetailsTitle = res.data.data.length ? res.data.data[0].aka_name + "-" + res.data.data[0].unit_number : "Pay Out Report";
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record_pay: totalRecordsPay,
      record_pay: listPay,
      payoutDetailsTitle: payoutDetailsTitle,
    });
    this.payoutDataModalToggle();
  }

  payoutDataModalToggle = () => {
    if (this.state.is_pay_out_modal === 1) {
      this.setState({ is_pay_out_modal: 0 });
    } else {
      this.setState({ is_pay_out_modal: 1 });
    }
  };

  getPayOutReports = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await ReportService.getDepositeReport(
      "property_slug=" +
      (this.state.property_slug ? this.state.property_slug : "") +"&deposite_type=" + 1+
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      let get_data = res.data;
      list = Object.keys(get_data).length !== 0 ? get_data.data : [];
      totalRecords = Object.keys(get_data).length !== 0 ? get_data.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
    this.addTotalClass();
  };

  /** Add Class on page load */
  addTotalClass = async () => {
    const child = document.getElementsByClassName('to-pay1');
    let childNo = child.length;
    for (let i = 0; i < childNo; i++) {
      let ele = child[i].parentElement.parentElement;
      ele.classList.add("to_total");
    }
    const childDiff = document.getElementsByClassName('diff-line1');
    let childNoDiff = childDiff.length;
    for (let i = 0; i < childNoDiff; i++) {
      let ele = childDiff[i].parentElement.parentElement;
      if (ele) {
        ele.classList.add("to_diff");
      }
    }
    const childFut = document.getElementsByClassName('future-row1');
    let childNoFut = childFut.length;
    for (let i = 0; i < childNoFut; i++) {
      let ele = childFut[i].parentElement.parentElement;
      if (ele) {
        ele.classList.add("to_future");
      }
    }
    if (document.getElementsByClassName('grand_total1').length > 0) {
      const grand_total = document.getElementsByClassName('grand_total1')[0];
      let ele1 = grand_total.parentElement.parentElement;
      ele1.classList.add("grand_total");
    }
    if (document.getElementsByClassName('future-grand1').length > 0) {
      const grand_total_fu = document.getElementsByClassName('future-grand1')[0];
      let ele2 = grand_total_fu.parentElement.parentElement;
      ele2.classList.add("grand_total_future");
    }
    if (document.getElementsByClassName('past-grand1').length > 0) {
      const grand_total_fu = document.getElementsByClassName('past-grand1')[0];
      let ele2 = grand_total_fu.parentElement.parentElement;
      ele2.classList.add("grand_total_past");
    }
  }

  removeTotalClass = async () => {
    const child = document.getElementsByClassName('to-pay1');
    let childNo = child.length;
    for (let i = 0; i < childNo; i++) {
      let ele = child[i].parentElement.parentElement;
      ele.classList.remove("to_total");
    }
    const childFut = document.getElementsByClassName('future-row1');
    let childNoFut = childFut.length;
    for (let i = 0; i < childNoFut; i++) {
      let ele = childFut[i].parentElement.parentElement;
      if (ele) {
        ele.classList.remove("to_future");
      }
    }
    const childDiff = document.getElementsByClassName('diff-line1');
    let childNoDiff = childDiff.length;
    for (let i = 0; i < childNoDiff; i++) {
      let ele = childDiff[i].parentElement.parentElement;
      if (ele) {
        ele.classList.remove("to_diff");
      }
    }
    if (document.getElementsByClassName('grand_total1').length > 0) {
      const grand_total = document.getElementsByClassName('grand_total1')[0];
      let ele1 = grand_total.parentElement.parentElement;
      ele1.classList.remove("grand_total");
    }
    if (document.getElementsByClassName('future-grand1').length > 0) {
      const grand_total_fu = document.getElementsByClassName('future-grand1')[0];
      let ele2 = grand_total_fu.parentElement.parentElement;
      ele2.classList.remove("grand_total_future");
    }
    if (document.getElementsByClassName('past-grand1').length > 0) {
      const grand_total_fu = document.getElementsByClassName('past-grand1')[0];
      let ele2 = grand_total_fu.parentElement.parentElement;
      ele2.classList.remove("grand_total_past");
    }
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;
    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_name, tower, total_tower_count }) => ({
          value: slug,
          label: unit_name,
        }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getUnitData();
      this.setState({ isSearch: 1 });
    }
  };

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    const selected_unit_number = value !== null ? value.label : [];
    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
      unit_lable: selected_unit_number,
    });
    this.setState({ confirm_back: 1 });
  }
  handleChangeSecurityList(value) {
    let security_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        security_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    const selected_unit_number = value !== null ? value.label : [];
    this.setState({
      selectedSecurityList: value,
      security_slug: unit_slug_value,
      security_lable: selected_unit_number,
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeHandler = async (event) => {
    // event.persist();
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    await this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  changeHandlerforConf = async (event) => {
    // event.persist();
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    await this.setState({ [event.target.name]: value, input: input });
    // Validate
  //  this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  async filterData() {
    let checkOutFromDate = this.state.checkout_date_from
      ? Moment(this.state.checkout_date_from).format("YYYY-MM-DD")
      : "";
    let checkOutDateTo = this.state.checkout_date_to
      ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
      : "";
     let security_type = this.state.security_slug ? this.state.security_slug:1; 
     let checkout_date = this.state.checkout_date ? Moment(this.state.checkout_date).format("YYYY-MM-DD"):"";
     let checkin_date = this.state.checkin_date ?  Moment(this.state.checkin_date).format("YYYY-MM-DD"):"";
    let queryString =
    "deposite_type=" +
    security_type +
    "&checkout_date=" +checkout_date+
    "&checkin_date=" + checkin_date +
      "&created_at_from=" +
      checkOutFromDate +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&created_at_to=" +
      checkOutDateTo +
      "&confirmation_code=" +
      (this.state.confirmation_code ? this.state.confirmation_code : "") +
      "&is_search=1" ;
    let moduleSearchString = "";
    let searchDisplay = "";
    moduleSearchString = this.state.checkout_date_from
      ? "Created at From Date- " + this.state.checkout_date_from
      : "";
    moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString + " ," + " Unit - " + this.state.unit_slug
        : " Unit - " + this.state.unit_slug
      : moduleSearchString;
      moduleSearchString = this.state.confirmation_code
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Confirmation Code - " +
          this.state.confirmation_code
        : " Confirmation Code - " + this.state.confirmation_code
      : moduleSearchString; 
    searchDisplay =
      "Created at From Date- " +
      Moment(checkOutFromDate).format("MM/DD/YY") +
      ", " +
      "Created at To Date- " +
      Moment(checkOutDateTo).format("MM/DD/YY");
    if (this.state.unit_lable) {
      searchDisplay = searchDisplay + ", Unit- " + this.state.unit_lable;
    }
    if (this.state.confirmation_code) {
      searchDisplay = searchDisplay + ", Confirmation code- " + this.state.confirmation_code;
    }
    if (this.state.security_lable) {
      searchDisplay = searchDisplay + ", Security type- " + this.state.security_lable;
    }
    if (
      this.state.checkout_date_from ||
      this.state.unit_slug ||
      this.state.confirmation_code ||
      this.state.checkout_date_to
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString });
      this.setState({ module_search_display: searchDisplay.split(',') });
      await this.removeTotalClass();
      this.getPayOutReports(queryString);
    }
  }

  /* table events */
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getPayOutReports(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getPayOutReports(queryString);
    }
  };

  // rowClickedHandlerPay = (event, data, rowIndex) => {
  //   if (data.unit_slug) {
  //     this.showPayoutReport(data.unit_slug);
  //   }
  // };

  // rowClickedHandler = (event, data, rowIndex) => {
  //   if (data.reservation_slug) {
  //     let route = "reservations/view/" + data.reservation_slug;
  //     window.open(route, "_blank");
  //   }
  // };
  rowClickedHandler = (event, data, rowIndex) => {
    if (data.reservation_slug) {
      let route =
        this.props.match.params.propertySlug !== undefined
          ? "reservations/view/" + data.reservation_slug
          : this.state.property_slug
          ? "/reservations/view/" +
            this.state.property_slug +
            "/" +
            data.reservation_slug
          : "/reservations/view/" +
            data.property_slug +
            "/" +
            data.reservation_slug;
           window.open(route, "_blank");
    }
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0s">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <ReportSidebar property_slug={this.state.property_slug} />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyReportSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>Incidentals Deposit</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        <button
                          className="btn-success-outline-small mr-3"
                          onClick={this.searchToggle}
                        >
                          Search
                        </button>
                        {global.userPermissions.checkPermission(
                          "guest-form-deposite-report-export"
                        ) ? (
                          <button
                            className="btn-success-outline-small mr-0"
                            onClick={this.exportToggle}
                          >
                            Export
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  
                  {this.props.match.params.propertySlug === undefined ? (
                    
                    <>
                    <div className="row pd-lg-l-15 pd-xs-l-15">
                      <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                      <OutSidePropertySelectBox
                        propertySlug={this.getPropertySlug.bind(this)}
                        outSidePropertySlug={this.state.outSidePropertySlug}
                      />
                    </div>
                    <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                      {this.state.is_search === 1 ? (
                    <div className="col-xxl-12 col-sm-12 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                    <div className="custm-filter">
                        <div> <label className="form-label text-left pd-b-5 mb-0">Search Applied On</label></div>
                        <div className="filter_bg_cust">
                          {
                            this.state.module_search_display.map((item, i) => {
                              return (
                                <span key={i}>{item}</span>
                              )
                            })
                          }
                          <button
                            onClick={this.clearFilter}
                            className="btn-fliter-clear"
                          >Clear</button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // <div className="col-md-12 pd-lg-l-45 pd-md-l-45  pd-lg-r-45 pd-md-r-30   mg-t-0 d-inline-flex align-items-center justify-content-between">
                    //   {`Search applied on: Check Out From Date -  ${Moment(
                    //     this.state.checkout_date_from
                    //   ).format("MM/DD/YY")} , Check Out To Date - ${Moment(
                    //     this.state.checkout_date_to
                    //   ).format("MM/DD/YY")}`}
                    // </div>
                    <div className="col-xxl-12 col-sm-12 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                      <div className="custm-filter">
                        <div> <label className="form-label text-left pd-b-5 mb-0">Search Applied On</label></div>
                        <div className="filter_bg_cust">
                          <span>
                            {`Check In From Date -  ${Moment(
                              this.state.checkout_date_from
                            ).format("MM/DD/YY")} `}
                          </span>
                          <span>
                            {` Check In To Date - ${Moment(
                              this.state.checkout_date_to
                            ).format("MM/DD/YY")}
                          `}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                    </div>
                     </div>
                     </>         
                  ) : (
                    <div className="col-xxl-12 pd-r-15 pd-l-15">
                      {this.state.is_search === 1 ? (
                    <div className="col-xxl-12 col-sm-12 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                    <div className="custm-filter">
                        <div> <label className="form-label text-left pd-b-5 mb-0">Search Applied On</label></div>
                        <div className="filter_bg_cust">
                          {
                            this.state.module_search_display.map((item, i) => {
                              return (
                                <span key={i}>{item}</span>
                              )
                            })
                          }
                          <button
                            onClick={this.clearFilter}
                            className="btn-fliter-clear"
                          >Clear</button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // <div className="col-md-12 pd-lg-l-45 pd-md-l-45  pd-lg-r-45 pd-md-r-30   mg-t-0 d-inline-flex align-items-center justify-content-between">
                    //   {`Search applied on: Check Out From Date -  ${Moment(
                    //     this.state.checkout_date_from
                    //   ).format("MM/DD/YY")} , Check Out To Date - ${Moment(
                    //     this.state.checkout_date_to
                    //   ).format("MM/DD/YY")}`}
                    // </div>
                    <div className="col-xxl-12 col-sm-12 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                      <div className="custm-filter">
                        <div> <label className="form-label text-left pd-b-5 mb-0">Search Applied On</label></div>
                        <div className="filter_bg_cust">
                          <span>
                            {`Check In From Date -  ${Moment(
                              this.state.checkout_date_from
                            ).format("MM/DD/YY")} `}
                          </span>
                          <span>
                            {` Check In To Date - ${Moment(
                              this.state.checkout_date_to
                            ).format("MM/DD/YY")}
                          `}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                    </div>
                  )}
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-lg-l-0 pd-lg-r-15 pd-xs-r-0">
                    <div className="scrolling-carousel pd-l-15 scroll-slide">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="all"
                        onSelect={(e) => this.getActiveClass(e)}
                        eventKey={this.state.activeClass}
                        className="pd-r-60"
                      >
                        <Tab.Content className="m-0">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <div className="mg-t-18 brandList pd-l-15 pd-r-15 payout-report-tbl- resident-payout-table earning-report-tbl">
                              <div className=" pd-lg-l-0 pd-xs-l-0">
                                <div className="scroll-report-table">
                                  <ReactDatatable
                                    className="table no-wrap mg-b-30 pd-b-2  brandtable top-per-tbl border table_last_row"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Incidentals Deposit Report</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Check In From Date</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="checkout_date_from"
                    selected={this.state.checkout_date_from}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "checkout_date_from",
                          value: data,
                        },
                      })
                    }
                  />
                  </InputGroup>
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Check In  To Date</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="checkout_date_to"
                      selected={this.state.checkout_date_to}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "checkout_date_to",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>
                
                <InputGroup className="mt-3">
                  <label>Confirmation Code/Internal Code</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandlerforConf}
                      name="confirmation_code"
                      value={this.state.confirmation_code}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
                {/* <InputGroup className="mt-3">
                  <label>Security Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedSecurityList}
                      options={this.state.newSecurityList}
                      onChange={(value) => this.handleChangeSecurityList(value)}
                      defaultValue={this.state.selectedSecurityList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup> */}
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={() => this.filterData()}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Export  popup modal */}
        <Modal show={this.state.isModel} className="modal-xs" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.exportToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Export</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <InputGroup className="mt-0">
                  <label>Select Export File Type : </label>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="xlsx"
                        checked={this.state.alertType === "xlsx" ? true : false}
                        type={"radio"}
                        id="xlsx"
                        label={"XLSX"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="csv"
                        checked={this.state.alertType === "csv" ? true : false}
                        type={"radio"}
                        id="csv"
                        label={"CSV"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                  </div>
                </InputGroup>

                {this.state.exportMesssage !== "" && (
                  <div>
                    {this.state.exportMesssage}
                    <br></br>
                    <br></br>
                    {/* {this.state.exportFilePath !== undefined && (
                      <a href={this.state.exportFilePath}> Download File </a>
                    )} */}
                  </div>
                )}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="mx-auto- mg-r-30 btn btn btn-success btn-block"
                    onClick={() => this.ExportData()}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Export"}
                  </button>
                  <button
                    type="button"
                    className="mx-auto- m-0  btn btn-outline-light btn-block"
                    onClick={() => this.exportToggle()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Pay Out Report Data */}
        <Modal
          show={this.state.is_pay_out_modal}
          className="modal-large searchModalbox"
          centered
          size="lg"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.payoutDataModalToggle()}
            >
              {global.closee}
            </span>
            <h3 className="text-center mb-0">{this.state.payoutDetailsTitle} Payout Detail</h3>
            <div className="row col-12 m-auto pd-lg-l-15 pd-lg-r-0 pd-xs-l-0 pd-xs-r-0">
              <div className="payout-report-tbl payout-details-tbl">
                <ReactDatatable
                  className="table no-wrap mg-b-30 pd-b-2 brandtable border table_last_row"
                  config={
                    this.state.total_record_pay > global.page_size
                      ? this.state.configPay
                      : this.state.configPay
                  }
                  records={this.state.record_pay}
                  columns={this.state.columns_pay}
                  extraButtons={this.state.extraButtons}
                  dynamic={true}
                  total_record={this.state.total_record_pay}
                  onChange={this.tableChangeHandler}
                  onRowClicked={this.rowClickedHandler}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default GuestDeposite;

