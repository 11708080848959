import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
// import propimg from "./../../assets/images/basic-prop-img.jpg"
import BuildingOnboardingService from "../../services/BuildingOnboardingService";

class HeaderTwo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerToken: global.headerToken ? global.headerToken : "",
      userData: global.userData ? global.userData : {},
      showSpinner: true,
      customSpinner: parseInt(localStorage.getItem("customSpinnerSession"))
        ? false
        : true,
      loading: false,
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
      propertyList: localStorage.getItem("propertyList")
        ? JSON.parse(localStorage.getItem("propertyList"))
        : [],
      menuPermission: localStorage.getItem("roleList")
        ? JSON.parse(localStorage.getItem("roleList"))
        : [],
      setpsCount: 0,
      totalCount: 11,
    };
    this.getOnboardingSetpsCount = this.getOnboardingSetpsCount.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    // Get user profile and set into localstorage
    // if (localStorage.getItem("isLogin") === "1") {
    //   localStorage.removeItem("isLogin");
    //   // window.location.reload(false);
    // }
    this.setState({ showSpinner: false, loading: false, customSpinner: false });
    localStorage.setItem("customSpinnerSession", 1);
    setTimeout(() => {
      sessionStorage.setItem(
        "property_logo_thumb_url",
        localStorage.getItem("property_logo_thumb_url")
      );
      sessionStorage.setItem(
        "property_sku",
        localStorage.getItem("property_sku")
      );
      sessionStorage.setItem(
        "property_name",
        localStorage.getItem("property_name")
      );
      var property_logo_thumb_url =
        localStorage.getItem("property_logo_thumb_url") ??
        sessionStorage.getItem("property_logo_thumb_url");
      var property_sku =
        localStorage.getItem("property_sku") ??
        sessionStorage.getItem("property_sku");
      var property_name =
        localStorage.getItem("property_name") ??
        sessionStorage.getItem("property_name");
      this.setState({
        property_logo_thumb_url: property_logo_thumb_url,
        property_sku: property_sku,
        property_name: property_name,
      });
    }, 200);
    setTimeout(() => {
      this.getOnboardingSetpsCount(
        localStorage.getItem("property_slug")
          ? localStorage.getItem("property_slug")
          : localStorage.getItem("currerntPropSlug")
      );
    }, 1100);
  }

  async getOnboardingSetpsCount(slug) {
    var totalCount = 0;
    var setpsCount = 0;
    if (slug) {
      let res = await BuildingOnboardingService.getOnboardingStepsCount(slug);
      if (global.successStatus.includes(res.status)) {
        totalCount = res.data ? res.data.totalCount : 0;
        setpsCount = res.data ? res.data.setpsCount : 0;
      }

      this.setState({
        totalCount: totalCount,
        setpsCount: setpsCount,
      });
    }
  }

  getTitle() {
    return process.env.REACT_APP_PROJECT_TITLE
      ? process.env.REACT_APP_PROJECT_TITLE
      : "";
  }

  toggleChildMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
      } else {
        data.classList.replace("left", "hide");
      }
    }
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    if (document.querySelector(".mobchildMenu")) {
      document.querySelector(".mobchildMenu").classList.toggle("d-none");
    }
    if (document.querySelector(".newSubmenu")) {
      document.querySelector(".newSubmenu").classList.toggle("d-none");
    }
  }

  render() {
    // Set session expiry logic
    // let sessionExpireTime = localStorage.getItem("sessionExpireTime")
    //   ? localStorage.getItem("sessionExpireTime")
    //   : 60;
    // let lastSessionExpiry = localStorage.getItem("sessionExpiry")
    //   ? localStorage.getItem("sessionExpiry")
    //   : "";
    // let currentTimestamp = new Date().getTime();
    // if (!lastSessionExpiry && this.state.headerToken) {
    //   let sessionExpiry = new Date(
    //     currentTimestamp + sessionExpireTime * 60000
    //   ).getTime();
    //   localStorage.setItem("sessionExpiry", sessionExpiry);
    // } else if (this.state.headerToken) {
    //   if (!isNaN(lastSessionExpiry) && lastSessionExpiry > currentTimestamp) {
    //     // If session expiry > current time then extend session time
    //     let sessionExpiry = new Date(
    //       currentTimestamp + sessionExpireTime * 60000
    //     ).getTime();
    //     localStorage.setItem("sessionExpiry", sessionExpiry);
    //   } else {
    //     // If session expiry < current time then logout
    //     return <Redirect to="/logout" />;
    //   }
    // }

    return (
      <div className="az-header">
        <div>
          {this.state.customSpinner ? (
            <div className="customLoad">
              <div className="div1">
                <div>
                  <img
                    className="mg-b-0 customLoadimg"
                    src={require("../../assets/images/logo.jpg").default}
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
          ) : this.state.showSpinner ? (
            global.spinnerLoader
          ) : (
            ""
          )}

          <div className="az-header-menu pd-t-0 pd-l-0 pd-r-0">
            {
            this.state.menuPermission &&
            this.state.menuPermission.length > 0 &&
            ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
              this.state.totalCount === (this.state.setpsCount ?? 0)) ||
              (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) ? (
              <React.Fragment>
                <Link to="/properties" className="backtosubMenu">
                  <i className="fas fa-chevron-left"></i>Back to main
                </Link>
                <div className="property_menu">
                  <figure>
                    <img
                      src={
                        this.props.propertyLogo ||
                        this.state.property_logo_thumb_url
                      }
                      alt=""
                    />
                  </figure>
                  <p>{this.props.propertySKU || this.state.property_sku}</p>
                </div>
              </React.Fragment>
            ) : null}

            {/* {this.state.menuPermissonLoad === true &&
            this.state.menuPermission &&
            this.state.menuPermission.length > 0 &&
            this.state.menuPermission.some((obj) => obj.roleId === 2) &&
            this.state.totalCount ===
              (this.state.setpsCount !== undefined
                ? this.state.setpsCount
                : this.props.setpsCount
                ? this.props.setpsCount
                : 0) ? (
              <React.Fragment>
                <Link to="/properties" className="backtosubMenu">
                  <i className="fas fa-chevron-left"></i>Back to main
                </Link>
                <div className="property_menu">
                  <figure>
                    <img
                      src={
                        this.props.propertyLogo
                          ? this.props.propertyLogo
                          : this.state.property_logo_thumb_url
                      }
                      alt=""
                    />
                  </figure>
                  <p>
                    {this.props.propertySKU
                      ? this.props.propertySKU
                      : this.state.property_sku}
                  </p>
                </div>
              </React.Fragment>
            ) : this.state.menuPermissonLoad === true &&
              this.state.menuPermission &&
              this.state.menuPermission.length > 0 &&
              this.state.menuPermission.some((obj) => obj.roleId === 1) ? (
              <React.Fragment>
                <Link to="/properties" className="backtosubMenu">
                  <i className="fas fa-chevron-left"></i>Back to main
                </Link>
                <div className="property_menu">
                  <figure>
                    <img
                      src={
                        this.props.propertyLogo
                          ? this.props.propertyLogo
                          : this.state.property_logo_thumb_url
                      }
                      alt=""
                    />
                  </figure>
                  <p>
                    {this.props.propertySKU
                      ? this.props.propertySKU
                      : this.state.property_sku}
                  </p>
                </div>
              </React.Fragment>
            ) : null} */}
            <ul className="nav" id="property-ul">
              <li className="nav-item">
                {/* <Dropdown
                    className={
                      this.isPathActive("/update-profile") || this.isPathActive("/change-password")
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Dropdown.Toggle as={"a"} className="nav-link with-sub nav-link-custom d-flex">
                     <span>Company</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="az-menu-sub">
                      <Link to="/update-profile" className={this.isPathActive("/update-profile")? "nav-link active": "nav-link"} >
                         Brands
                      </Link>
                      <Link to="/change-password" className={this.isPathActive("/change-password")? "nav-link active": "nav-link"} >
                      Properties
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown> */}
              </li>
              {global.userPermissions.checkPermission(
                "building-onboarding-view"
              ) && (
                <li
                  className={
                    this.isPathActive("building-onboarding")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleHeaderMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/building-onboarding"
                    }
                  >
                    Building Onboarding
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("property-list") &&
                
                this.state.menuPermission &&
                this.state.menuPermission.length > 0 &&
                ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                  this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                  (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) && (
                  <li
                    className={
                      this.isPathActive("property-overview")
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={(event) => this.toggleHeaderMenu(event)}
                  >
                    <Link
                      className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                      to={
                        "/properties/view/" +
                        (this.props.property_slug
                          ? this.props.property_slug
                          : this.state.property_slug) +
                        "/property-overview"
                      }
                    >
                      Overview
                    </Link>
                  </li>
                )}

              {global.userPermissions.checkPermission(
                "communication-message-module"
              ) &&
                
                this.state.menuPermission &&
                this.state.menuPermission.length > 0 &&
                ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                  this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                  (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) && (
                  <li
                    className={
                      this.isPathActive("/messages")
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={(event) => this.toggleChildMenu(event)}
                  >
                    {/* <Link className="nav-link" to="/messages"> */}
                    <Link
                      className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                      to={
                        "/properties/view/" +
                        (this.props.property_slug
                          ? this.props.property_slug
                          : this.state.property_slug) +
                        "/messages"
                      }
                    >
                      Messages
                      {/* <span className="rightArrow">
                        <i className="fas fa-chevron-right"></i>
                      </span> */}
                    </Link>
                  </li>
                )}
              {!global.userPermissions.checkPermission(
                "building-onboarding-view"
              ) && <div className="menu_bdr_btm"></div>}

              {global.userPermissions.checkPermission("-list") &&
                
                this.state.menuPermission &&
                this.state.menuPermission.length > 0 &&
                ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                  this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                  (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) && (
                  <li
                    className={
                      this.isPathActive("property-dashboard-overview")
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={(event) => this.toggleHeaderMenu(event)}
                  >
                    <Link
                      className="nav-link"
                      to={
                        "/properties/view/" +
                        (this.props.property_slug
                          ? this.props.property_slug
                          : this.state.property_slug) +
                        "/property-dashboard-overview"
                      }
                    >
                      Dashboard
                    </Link>
                  </li>
                )}

              {global.userPermissions.checkPermission(
                "listing-calendar-list"
              ) &&
              global.userPermissions.checkPermission(
                "reservation-calendar-list"
              ) &&
              global.userPermissions.checkPermission(
                "cleaning-calendar-list"
              ) &&
              
              this.state.menuPermission &&
              this.state.menuPermission.length > 0 &&
              ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) ? (
                <li
                  className={
                    this.isPathActive("unified-calendar")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/unified-calendar"
                    }
                  >
                    Calendar
                    {/* <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span> */}
                  </Link>
                </li>
              ) : (
                ""
              )}

              {/* {global.userType === 1 ?  */}
              {global.userPermissions.checkPermission("property-list") &&
              
              this.state.menuPermission &&
              this.state.menuPermission.length > 0 &&
              ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) ? (
                <li
                  className={
                    this.isPathActive("basic-new") ||
                    this.isPathActive("property-payout-configuration") ||
                    this.isPathActive("units") ||
                    this.isPathActive("legal-finance") ||
                    this.isPathActive("owners") ||
                    this.isPathActive("parking") ||
                    this.isPathActive("property-level-tax") ||
                    this.isPathActive("property-documents") ||
                    this.isPathActive("tower") ||
                    this.isPathActive("floors") ||
                    this.isPathActive("floor-plans") ||
                    this.isPathActive("property-fees") ||
                    this.isPathActive("property-amenities") ||
                    this.isPathActive("property-credential") ||
                    this.isPathActive("property-accessibility") ||
                    this.isPathActive("images") ||
                    this.isPathActive("marketing-copy") ||
                    this.isPathActive("refundable-policy") ||
                    this.isPathActive("terms-conditions") ||
                    this.isPathActive("website-cookies") ||
                    this.isPathActive("house-rules") ||
                    this.isPathActive("house-manual") ||
                    this.isPathActive("health-safety") ||
                    this.isPathActive("getting-around") ||
                    this.isPathActive("lead-tracking-contacts") ||
                    this.isPathActive("near-by-attractions") ||
                    this.isPathActive("neighbourhood") ||
                    this.isPathActive("events") ||
                    this.isPathActive("ecommerce") ||
                    this.isPathActive("arrival-instructions") ||
                    this.isPathActive("influencers") ||
                    this.isPathActive("compliance")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/basic-new"
                    }
                  >
                    Property
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {(global.userPermissions.checkPermission("leases-list") ||
                global.userPermissions.checkPermission("lease-calendar-list") ||
                global.userPermissions.checkPermission("availability-list") ||
                global.userPermissions.checkPermission("applicants-list") ||
                global.userPermissions.checkPermission("inquiry-list") ||
                global.userPermissions.checkPermission("residents-list")) &&
              
              this.state.menuPermission &&
              this.state.menuPermission.length > 0 &&
              ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) ? (
                <li
                  className={
                    this.isPathActive("availability") ||
                    this.isPathActive("applicant-detail") ||
                    this.isPathActive("applicants") ||
                    this.isPathActive("leasing") ||
                    this.isPathActive("lease-avail-calendar") ||
                    this.isPathActive("residents") ||
                    this.isPathActive("rent") ||
                    this.isPathActive("payouts")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/availability"
                    }
                  >
                    Leasing
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {/* <li
                  className={
                      this.isPathActive("residents") ||
                      this.isPathActive("rent") ||
                      this.isPathActive("payouts") ||
                      this.isPathActive("resident-inbox") ||
                      this.isPathActive("resident-checklist")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={event => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/residents"
                    }
                  >
                    Residents
                  </Link>
                </li> */}
              {/* {global.userType === 1 || global.userType === 2 ? */}
              {global.userPermissions.checkPermission("single-unit-list") &&
                
                this.state.menuPermission &&
                this.state.menuPermission.length > 0 &&
                ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                  this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                  (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                  this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                  this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                  this.state.menuPermission.some((obj) => obj.roleId === 5))) && (
                  <li
                    className={
                      this.isPathActive("listing") ||
                      this.isPathActive("homesharing-inquiries") ||
                      this.isPathActive("list-calendar")
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={(event) => this.toggleChildMenu(event)}
                  >
                    <Link
                      className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                      to={
                        "/properties/view/" +
                        (this.props.property_slug
                          ? this.props.property_slug
                          : this.state.property_slug) +
                        "/listing"
                      }
                    >
                      Listings
                      <span className="rightArrow">
                        <i className="fas fa-chevron-right"></i>
                      </span>
                    </Link>
                  </li>
                )}
              {/* {global.userType === 1 || global.userType === 2 || global.userType === 4 ? */}
              {(global.userPermissions.checkPermission("reservations-list") ||
                global.userPermissions.checkPermission(
                  "reservation-calendar-list"
                ) ||
                global.userPermissions.checkPermission("check-ins-list") ||
                global.userPermissions.checkPermission("check-outs-list") ||
                global.userPermissions.checkPermission("guests-list")) &&
              
              this.state.menuPermission &&
              this.state.menuPermission.length > 0 &&
              ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) ? (
                <li
                  className={
                    this.isPathActive("reservation-calendar") ||
                    this.isPathActive("checkin-calender") ||
                    this.isPathActive("checkout-calender") ||
                    this.isPathActive("reservations") ||
                    this.isPathActive("inquiry") ||
                    this.isPathActive("requests") ||
                    this.isPathActive("guest")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/reservation-calendar"
                    }
                  >
                    Reservations
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {/* {global.userType === 1 || global.userType === 2 || global.userType === 3 ? */}
              {(global.userPermissions.checkPermission(
                "cleaning-calendar-list"
              ) ||
                global.userPermissions.checkPermission("cleaning-list") ||
                global.userPermissions.checkPermission("inspections-list")) &&
              
              this.state.menuPermission &&
              this.state.menuPermission.length > 0 &&
              ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) ? (
                <li
                  className={
                    this.isPathActive("housekeeping-calender") ||
                    this.isPathActive("housekeeping-list") ||
                    this.isPathActive("checklists") ||
                    this.isPathActive("inspections") ||
                    this.isPathActive("problems")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/housekeeping-calender"
                    }
                  >
                    Cleaning
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {(global.userPermissions.checkPermission(
                "maintenance-calendar-list"
              ) ||
                global.userPermissions.checkPermission(
                  "maintenance-requests-list"
                )) &&
              
              this.state.menuPermission &&
              this.state.menuPermission.length > 0 &&
              ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) ? (
                <li
                  className={
                    this.isPathActive("maintenance-calendar") ||
                    this.isPathActive("mros") ||
                    this.isPathActive("maintenance-checklists") ||
                    this.isPathActive("maintenance-inbox")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/mros"
                    }
                  >
                    Maintenance
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {/* {global.userType === 1 || global.userType === 2 || global.userType === 3 ? */}
              {global.userPermissions.checkPermission("key-cafe-access-view") &&
              
              this.state.menuPermission &&
              this.state.menuPermission.length > 0 &&
              ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) ? (
                <li
                  className={
                    this.isPathActive("keys") ? "nav-item active" : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/keys"
                    }
                  >
                    Keys
                    {/* <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span> */}
                  </Link>
                </li>
              ) : (
                ""
              )}
              {/* Start: Latch Activities */}
              {/* {global.userPermissions.checkPermission("latch-access-view") && (
                <li
                  className={
                    this.isPathActive("latches")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/latches"
                    }
                  >
                    Latches
                  </Link>
                </li>
              )} */}
              {/* End: Latch Activities */}
              {!global.userPermissions.checkPermission(
                "building-onboarding-view"
              ) && <div className="menu_bdr_btm"></div>}

              {(global.userPermissions.checkPermission("analytics-list") ||
                global.userPermissions.checkPermission("earning-report-list") ||
                global.userPermissions.checkPermission("payouts-report-list") ||
                global.userPermissions.checkPermission(
                  "quarterly-tax-report-list"
                )) &&
              
              this.state.menuPermission &&
              this.state.menuPermission.length > 0 &&
              ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) ? (
                <li
                  className={
                    // this.isPathActive("property-reports") ||
                    //   this.isPathActive("leasing-reports") ||
                    //   this.isPathActive("reservation-reports") ||
                    //   this.isPathActive("housekeeping-reports") ||
                    //   this.isPathActive("guest-reports") ||
                    //   this.isPathActive("resident-reports") ||
                    //   this.isPathActive("accounting-reports") ||
                    //   this.isPathActive("shop-reports") ||
                    this.isPathActive("occupancy-reports") ||
                    this.isPathActive("homesharing-reports") ||
                    this.isPathActive("resident-payout") ||
                    this.isPathActive("property-payout") ||
                    this.isPathActive("oh-earning-reports") ||
                    this.isPathActive("quarterly-tax-reports")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/top-performer"
                    }
                  >
                    Reports
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )}

              {/* <li
                  className={
                      this.isPathActive("maintenance-calendar") ||
                      this.isPathActive("mros") ||
                      this.isPathActive("maintenance-checklists") ||
                      this.isPathActive("maintenance-inbox") ||
                      this.isPathActive("housekeeping-vendoor-list") ||
                      this.isPathActive("work-order")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={event => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/maintenance-calendar"
                    }
                  >
                    Maintenance
                  </Link>
                </li> */}

              {/* <li
                  className={
                    this.isPathActive("housekeeping-vendoor-list") ||
                    this.isPathActive("work-order") ||
                    this.isPathActive("bills") ||
                    this.isPathActive("payments")
                    ? "nav-item active"
                    : "nav-item"
                  }
                  onClick={event => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/housekeeping-vendoor-list"
                    }
                  >
                    Vendors
                  </Link>
                </li> */}

              {/* : ''} */}
              {/* <li
                  className={
                    this.isPathActive("requests") || this.isPathActive("guests")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={event => this.toggleChildMenu(event)}
                >
                  <Link className="nav-link d-flex justify-content-between align-items-center desksubmenu" to={'/properties/view/'+(this.props.property_slug ? this.props.property_slug : this.state.property_slug)+'/guests'}>Guests</Link>
                </li> */}
              {/* <li
                  className={
                    this.isPathActive("residents") || this.isPathActive("maintenance-request") || this.isPathActive("rent")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={event => this.toggleChildMenu(event)}
                >
                  <Link className="nav-link d-flex justify-content-between align-items-center desksubmenu" to={'/properties/view/'+(this.props.property_slug ? this.props.property_slug : this.state.property_slug)+'/residents'}>Residents</Link>
                </li> */}
              {/* {global.userType === 1 ? <> */}
              {/* <li
                  className={
                    this.isPathActive("maintenance-request") || this.isPathActive("inspections") || this.isPathActive("checklists")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={event => this.toggleChildMenu(event)}
                >
                  <Link className="nav-link d-flex justify-content-between align-items-center desksubmenu" to={'/properties/view/'+(this.props.property_slug ? this.props.property_slug : this.state.property_slug)+'/maintenance-request'}>Maintenance</Link>
                </li> */}
              {(global.userPermissions.checkPermission("website-cms-list") ||
                global.userPermissions.checkPermission("app-cms-list")) &&
              
              this.state.menuPermission &&
              this.state.menuPermission.length > 0 &&
              ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) ? (
                <li
                  className={
                    this.isPathActive("property-cms-setting") ||
                    this.isPathActive("app-property-setting")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/property-cms-setting"
                    }
                  >
                    CMS
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {/* {global.userPermissions.checkPermission("store-list") ||
              global.userPermissions.checkPermission("sales-list") ||
              global.userPermissions.checkPermission("purchases-list") ? (
                <li
                  className={
                    this.isPathActive("orders") ||
                    this.isPathActive("items") ||
                    this.isPathActive("inventory")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={event => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/items"
                    }
                  >
                    E-Commerce
                    <span className="rightArrow">
                    <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )} */}
              {global.userPermissions.checkPermission("crm-lists-list") &&
              
              this.state.menuPermission &&
              this.state.menuPermission.length > 0 &&
              ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) ? (
                <li
                  className={
                    this.isPathActive("staff") ||
                    this.isPathActive("vendor") ||
                    this.isPathActive("crm-resident") ||
                    this.isPathActive("leads") ||
                    this.isPathActive("property-contact-lists") ||
                    this.isPathActive("property-segments") ||
                    this.isPathActive("property-campaigns") ||
                    this.isPathActive("crm-campaign") ||
                    this.isPathActive("crm-templates") ||
                    this.isPathActive("crm-list")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/staff"
                    }
                  >
                    CRM
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {/* {global.userPermissions.checkPermission("bill-list") ||
              global.userPermissions.checkPermission("payments-list") ||
              global.userPermissions.checkPermission("invoices-list") ? (
                <li
                  className={
                    this.isPathActive("invoices") ||
                    this.isPathActive("bills") ||
                    this.isPathActive("payments")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={event => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/bills"
                    }
                  >
                    Accounting
                    <span className="rightArrow">
                    <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )} */}

              {global.userPermissions.checkPermission("payout-payments-list") &&
                
                this.state.menuPermission &&
                this.state.menuPermission.length > 0 &&
                ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                  this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                  (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) && (
                  <li
                    className={
                      this.isPathActive("/res-payout-summary") ||
                      this.isPathActive("create-payout-resident") ||
                      this.isPathActive("edit-payout-resident") ||
                      this.isPathActive("view-payout-resident") ||
                      this.isPathActive("/property-payout-summary") ||
                      this.isPathActive("create-payout-property") ||
                      this.isPathActive("edit-payout-property") ||
                      this.isPathActive("view-payout-property")
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={(event) => this.toggleChildMenu(event)}
                  >
                    {/* <Link className="nav-link" to="/res-payout-summary"> */}
                    <Link
                      className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                      to={
                        "/properties/view/" +
                        (this.props.property_slug
                          ? this.props.property_slug
                          : this.state.property_slug) +
                        "/res-payout-summary"
                      }
                    >
                      Payouts
                    </Link>
                  </li>
                )}
              {(global.userPermissions.checkPermission("noiseaware-list") ||
                global.userPermissions.checkPermission(
                  "key-cafe-mapping-view"
                ) ||
                global.userPermissions.checkPermission(
                  "datahub-export-mapping-view"
                ) ||
                global.userPermissions.checkPermission(
                  "datahub-mapping-view"
                )) &&
              
              this.state.menuPermission &&
              this.state.menuPermission.length > 0 &&
              ((this.state.menuPermission.some((obj) => obj.roleId === 2) &&
                this.state.totalCount === (this.state.setpsCount ?? 0)) ||
                (this.state.menuPermission.some((obj) => obj.roleId === 1) ||
                this.state.menuPermission.some((obj) => obj.roleId === 3) ||
                this.state.menuPermission.some((obj) => obj.roleId === 4) ||
                this.state.menuPermission.some((obj) => obj.roleId === 5))) ? (
                <li
                  className={
                    this.isPathActive("users") ||
                    this.isPathActive("locks") ||
                    this.isPathActive("chatbot") ||
                    this.isPathActive("noiseaware-template") ||
                    this.isPathActive("airbnb") ||
                    this.isPathActive("google") ||
                    this.isPathActive("ils-export") ||
                    this.isPathActive("ota-export")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  onClick={(event) => this.toggleChildMenu(event)}
                >
                  <Link
                    className="nav-link d-flex justify-content-between align-items-center desksubmenu"
                    to={
                      "/properties/view/" +
                      (this.props.property_slug
                        ? this.props.property_slug
                        : this.state.property_slug) +
                      "/noiseaware-template"
                    }
                  >
                    Integrations
                    <span className="rightArrow">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  isPathActive(path) {
    document.querySelector("body").classList.remove("az-header-menu-show");
    return window.location.pathname.includes(path);
  }
}

export default withRouter(HeaderTwo);
