////////////////////////////////////////////////////////////
//     							                          //
//  Program: Amenity.jsx                                  //
//  Application: Master                                   //
//  Option: to view Property Setup data                   //
//  Developer: Ashish Kumar  			                  //
//  Date: 2022-01-05                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Modal, Form, InputGroup,Tab,Nav } from "react-bootstrap";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import hostImg from "./../../assets/images/profile-nogender.png";
import deleteicon from "../../assets/images/delete.svg";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import AmenityService from "../../services/AmenityService";
import AmenityTypeService from "../../services/AmenityTypeService";
import Alerts from "../common/Alerts";
import Select from "react-select";
import NoData from "../common/NoData";
import filterIcon from "./../../assets/images/filter-gray.svg";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";

export class Amenity extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      tab: "active",
      amnityMasterTabData: localStorage.getItem("amenity_master_tab_data") ? localStorage.getItem("amenity_master_tab_data") : "",
      //for Amenity
      amenity_icon:"",
      tableQueryString: "",
      customQueryString: "",
      currentSort: 'asc',
      addAmenityModal: false,
      searchAmenityModal: false,
      module_search: "",
      filter_string:"",
      is_search: 0,
      editAmenityModal: false,
      deleteAmenityModal: false,
      amenityList: [],
      amenityListTotal: 0,
      amenityTypeList: [],
      amenityTypeListTotal: 0,
      amenity_name: "",
      tag_line: "",
      description: "",
      available_level: [],
      amenity_type_slug: "",
      selectedOption: null,
      availableLevel: [],
      selectedLevelList: [],
      selectLevelList: [],
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      selectedStatusList:[],
      filter_status_id:"",
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "amenity_name",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "amenity_name",
          text: "Amenity Name",
          className: "amenity_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return (record.amenity_name && record.slug ? <p className="m-0" onClick={()=>this.openEditAmenityModal(record.slug)}>{record.amenity_name}</p> : "");
           
          },
        },
        {
          key: "amenity_type_name",
          text: "Amenity Type",
          className: "amenity_type_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.amenity_type_name
              ? <p className="m-0" onClick={()=>this.openEditAmenityModal(record.slug)}>{record.amenity_type_name}</p>
              : "";
          },
        },
        {
          key: "available_level",
          text: "Amenity Level",
          className: "available_level hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.available_at
              ? <p className="m-0" onClick={()=>this.openEditAmenityModal(record.slug)}>{record.available_at}</p>
              : "";
          },
        },
        {
          key: "amenity_icon_url",
          text: "Amenity Icon",
          className: "amenity_icon_url amenity_img_icon hand-cursor text-left",
          sortable: false,
          cell: (record) => {
            return record.amenity_icon_url
              ? <p className="m-0" onClick={()=>this.openEditAmenityModal(record.slug)}><img src={record.amenity_icon_url} alt="" title="Amenity Icon" /></p>
              : <img src={hostImg} alt="" title="Amenity Icon" />;
          },
        },
        {
          key: "sort_order",
          text: "Sort Order",
          className: "sort_order hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.sort_order
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor text-left",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b onClick={()=>this.openEditAmenityModal(record.slug)} className="active"></b>
            ) : (
              <b></b>
            );
          },
        },
        global.userPermissions.checkPermission(
          "master-data-delete"
        )&& (
          {
            key: "action",
            text: "Action",
            className: "contact_phone hand-cursor text-left",
            sortable: false,
            cell: (record) => {
              // record.sort_order
              return<div className="btn-icon-list d-block text-center">
              <img
                src={deleteicon}
                alt="Delete"
                onClick={() =>
                  this.openDeleteAmenityModal(record.slug)
                }
                className="hand-cursor"
              />
            </div>
              ;
            },
          }
        )
      ],
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangeNew = this.handleChangeNew.bind(this);
    this.handleChangeNewSearch = this.handleChangeNewSearch.bind(this);

    /* for amenity */
    this.openAddAmenityModal = this.openAddAmenityModal.bind(this);
    this.opensearchAmenityModal = this.opensearchAmenityModal.bind(this);
    this.openEditAmenityModal = this.openEditAmenityModal.bind(this);
    this.openDeleteAmenityModal = this.openDeleteAmenityModal.bind(this);
    this.saveAmenity = this.saveAmenity.bind(this);
    this.searchAmenity = this.searchAmenity.bind(this);
    this.getAmenityDetails = this.getAmenityDetails.bind(this);
    this.updateAmenity = this.updateAmenity.bind(this);
    this.deleteAmenity = this.deleteAmenity.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
  }

  componentDidMount() {
    switch (this.state.amnityMasterTabData) {
      case "active":
        document.getElementById("activeTab").click();
        break;
      case "inactive":
        document.getElementById("inactiveTab").click();
        break;
      default:
        document.getElementById("activeTab").click();
    }
    this.getLevelList();
  }
  onSortChange = () => {
		const { currentSort } = this.state;
		let nextSort;

		if (currentSort === 'desc'){ 
     nextSort = 'asc';
    }else if (currentSort === 'asc'){
      nextSort = 'desc';
    }

		this.setState({
			currentSort: nextSort
		});
    this.getAmenityData();
	};

  clearFilter() {
    var moduleSearchString = "";
    moduleSearchString = this.state.amenity_name
      ? "amenity_name- " + this.state.amenity_name
      : "";
      moduleSearchString = this.state.amenity_type_slug
      ? moduleSearchString
        ? moduleSearchString + " &" + " amenity_type_slug - " + this.state.amenity_type_slug
        : " amenity_type_slug - " + this.state.amenity_type_slug
      : moduleSearchString;

      moduleSearchString = this.state.available_level
      ? moduleSearchString
        ? moduleSearchString + " &" + " available_level - " + this.state.available_level
        : " available_level - " + this.state.available_level
      : moduleSearchString;

    moduleSearchString = this.state.filter_status_id
      ? moduleSearchString
        ? moduleSearchString + " &" + " status_id - " + this.state.filter_status_id
        : " status_id - " + this.state.filter_status_id
      : moduleSearchString;
    this.setState({ module_search: moduleSearchString });

    if (this.state.amenity_type_slug && this.state.amenity_name && this.state.available_level && this.state.filter_status_id) {
      this.setState({
        amenity_name: "",
        amenity_type_slug: "",
        available_level: "",
        filter_status_id: "",
        selectedStatusList: [],
      });
      this.setState({ module_search: "", is_search: 0 });
      // this.props.history.push("/amenities");
    } else {
       window.location.search = "";
      this.setState({ is_search: 0 });
      this.setState({
        amenity_name: "",
        amenity_type_slug: "",
        available_level: "",
        filter_status_id: "",
        selectedStatusList: [],
      });
      this.setState({ module_search: "" });
      this.getAmenityData("tab=" + this.state.tab + "&" + moduleSearchString);
    }
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getAmenityData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "amenity_master_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getAmenityData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "amenity_master_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  onImageChange = async (event) => {
    this.setState({ showSpinner: true, loading: true });
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "amenity_icon",
        event.target.files[0],
        event.target.files[0].name
      );
      // fileData.append(
      //   "resizeOptions",
      //   "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
      // );
      fileData.append("mediaType", "1");
      let res = await AmenityService.uploadAmenityIcon(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          amenity_icon: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          if(res.data && res.data.result && res.data.result.errorDetail){
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
          }
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
    this.setState({ showSpinner: false, loading: false });
  };
  /* to get amenity type data */
  async getAmenityTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var amenityTypeList = [];
    var amenityTypeListTotal = 0;

    let res = await AmenityTypeService.getAmenityType("is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      amenityTypeList = res.data ? res.data : [];
      amenityTypeListTotal = amenityTypeList ? amenityTypeList.length : 0;
    }
    const newAmenityTypeList =
      amenityTypeListTotal > 0
        ? amenityTypeList.map(({ slug, amenity_type_name }) => ({
          value: slug,
          label: amenity_type_name,
        }))
        : [];
    this.setState({
      amenityTypeList: amenityTypeList,
      amenityTypeListTotal: amenityTypeListTotal,
      newAmenityTypeList: newAmenityTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getLevelList() {
    const selectLevelList = [
      { value: "1", label: "Property" },
      { value: "2", label: "Listing" },
      { value: "3", label: "Floor Plan" },
      { value: "4", label: "Unit" }
    ];
    this.setState({ selectLevelList: selectLevelList });
  }

  /* to get amenity  data */
  async getAmenityData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var amenityList = [];
    var amenityListTotal = 0;
    var list = [];

    let res = await AmenityService.getAmenity(
      "order_by=" +
      this.state.currentSort + 
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      amenityList = res.data.data ? res.data.data : [];
      list = res.data.data ? res.data.data : [];
      amenityListTotal = res.data ? res.data.total : 0;
    }
    this.setState({
      amenityList: amenityList,
      records: list,
      amenityListTotal: amenityListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }


  /* to search amenity data */
  searchAmenity = async (e) => {
    this.setState({ showSpinner: true, loading: true });
    // var amenityList = [];
    // var list = [];
    // var amenityListTotal = 0;

    e.preventDefault();
    let inputData = 'amenity_name='+this.state.amenity_name +'&amenity_type_slug='+this.state.amenity_type_slug +'&available_level='+this.state.available_level+'&status_id='+this.state.filter_status_id;
    this.setState({filter_string:inputData});
    this.getAmenityData(inputData+"&tab=" + this.state.tab);
    // let res = await AmenityService.getAmenity(inputData);

    var moduleSearchString = "";

    moduleSearchString = this.state.amenity_name
    ? "Amenity Name - " + this.state.amenity_name
    : moduleSearchString;

  moduleSearchString = this.state.amenity_type_slug
  ? moduleSearchString
    ? moduleSearchString +
        " ," +
      " Amenity Type - " +
      this.state.selectedAmenityTypeList.label
    : " Amenity Type - " + this.state.selectedAmenityTypeList.label
  : moduleSearchString;
    if (this.state.selectedLevelList.length > 0 || this.state.selectedLevelList.length === undefined) {
    moduleSearchString = this.state.available_level
        ? moduleSearchString
          ? moduleSearchString +
              " ," +
            " Amenity Level - " +
            this.state.selectedLevelList.label 
          : " Amenity Level - " + this.state.selectedLevelList.label
        : moduleSearchString;
    }
    if (this.state.selectedStatusList.length > 0 || this.state.selectedStatusList.length === undefined) {
      moduleSearchString = this.state.filter_status_id
        ? moduleSearchString
          ? moduleSearchString +
          " ," +
          " Status Id - " +
          this.state.selectedStatusList.label
          : " Status Id - " + this.state.selectedStatusList.label
        : moduleSearchString;
    }
    this.setState({ module_search: moduleSearchString.split(',') });

    // if (global.successStatus.includes(res.status)) {
    //   amenityList = res.data.data ? res.data.data : [];
    //   list = res.data.data ? res.data.data : [];
    //   amenityListTotal = amenityList ? amenityList.length : 0;
    // }
    // this.setState({
    //   amenityList: amenityList,
    //   records: list,
    //   amenityListTotal: amenityListTotal,

    // });
    this.setState({ showSpinner: false, loading: false, is_search: 1 });
    this.setState({ searchAmenityModal: false });
    this.setState({
      amenity_name: ""});
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      // queryString += "&" + this.state.customQueryString;
      queryString += "&" + this.state.filter_string;
      this.setState({ tableQueryString: queryString });
      this.setState({ customQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getAmenityData(queryString+"&tab=" + this.state.tab),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getAmenityData(queryString+"&tab=" + this.state.tab);
    }
  };
  
  /* to save amenity data */
  saveAmenity = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.amenity_name &&
      !this.validator.errorMessages.amenity_type_slug &&
      !this.validator.errorMessages.sort_order &&
      !this.validator.errorMessages.tag_line &&
      !this.validator.errorMessages.description &&
      !this.validator.errorMessages.amenity_icon &&
      !this.validator.errorMessages.available_level && (this.state.available_level && this.state.available_level.length > 0)
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let availableLevel = this.state.available_level
        ? this.state.available_level.join(",")
        : [];
      let inputData = {
        amenity_name: this.state.amenity_name,
        amenity_type_slug: this.state.amenity_type_slug,
        tag_line: this.state.tag_line,
        description: this.state.description,
        amenity_icon: this.state.amenity_icon,
        available_level: availableLevel ? availableLevel : "",
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await AmenityService.createAmenity(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddAmenityModal();
        this.closesearchAmenityModal();
        this.setState({
          amenity_name: "",
          amenity_type_slug: "",
          sort_order: "",
          tag_line: "",
          description: "",
          amenity_icon:"",
          available_level: "",
          status_id: true,
        });
        this.getAmenityData("tab=" + this.state.tab);
      } else {
        //let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.result.errorDetail[0].errorMessage
        //     ? res.data.result.errorDetail[0].errorMessage
        //     : "Error!",
        //   alertModalMessage:
        //     alertMessage !== "" ? alertMessage : res.data.errorMessage,
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get amenity details data */
  async getAmenityDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      var selectedLevelList = [];
      let res = await AmenityService.getAmenityBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.amenity_name = res.data.amenity_name
          ? res.data.amenity_name
          : "";
        resData.amenity_type_slug = res.data.amenity_type
          ? res.data.amenity_type.slug
          : "";
        resData.tag_line = res.data.tag_line ? res.data.tag_line : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.amenity_icon = res.data.amenity_icon;
        resData.imageUrl = res.data.amenity_icon_url
          ? res.data.amenity_icon_url
          : hostImg;
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        var selectLevelList = [];
        if(res.data && res.data.available_level_on){
          res.data.available_level_on.forEach((item, i) => {
            selectedLevelList = [
                ...selectedLevelList,
                {
                  value: item.id ? item.id : "",
                  label: item.name ? item.name : "",
                },
              ];
              selectLevelList.push(item.id);
            });
        }
        var selectedAmenityTypeList = [];
        selectedAmenityTypeList = res.data.amenity_type
          ? [
            {
              value: res.data.amenity_type.slug,
              label: res.data.amenity_type.amenity_type_name,
            },
          ]
          : "";

        this.setState({
          selectedLevelList: selectedLevelList,
          selectedAmenityTypeList: selectedAmenityTypeList,
        });
        // this.setState({ selectLevelList: selectLevelList });
        this.setState({ available_level: selectLevelList });
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update amenity data */
  updateAmenity = async (e) => {
    e.preventDefault();
    let availableLevel = Array.isArray(this.state.available_level)
      ? this.state.available_level.join(",")
      : this.state.available_level;
    if (
      this.customValidate() &&
      !this.validator.errorMessages.amenity_name &&
      !this.validator.errorMessages.amenity_type_slug &&
      !this.validator.errorMessages.amenity_icon &&
      !this.validator.errorMessages.sort_order &&
      !this.validator.errorMessages.tag_line &&
      !this.validator.errorMessages.description &&
      !this.validator.errorMessages.available_level &&
      (this.state.available_level && this.state.available_level.length > 0)
    ) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputData = {
        amenity_name: this.state.amenity_name,
        amenity_type_slug: this.state.amenity_type_slug,
        tag_line: this.state.tag_line,
        amenity_icon: this.state.amenity_icon,
        description: this.state.description,
        available_level: availableLevel,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await AmenityService.updateAmenity(this.state.slug, inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditAmenityModal();
        this.setState({
          amenity_name: "",
          amenity_type_slug: "",
          sort_order: "",
          tag_line: "",
          description: "",
          available_level: [],
          status_id: true,
          selectedLevelList: [],
        });
        this.getAmenityData(this.state.filter_string + "&" + this.state.customQueryString + "&" + "tab=" + this.state.tab);
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete amenity data */
  async deleteAmenity(slug) {
    this.setState({ showSpinner: true });
    let res = await AmenityService.deleteAmenity(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAmenityModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getAmenityData("tab=" + this.state.tab);
    } else {
      this.closeDeleteAmenityModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closeDeleteAmenityModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  handleChangeAmenityTypeList(value) {
    let amenity_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        amenity_type_slug.push(item.value);
      });
    }
    const amenity_type_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedAmenityTypeList: value,
      amenity_type_slug: amenity_type_slug_value,
    });
  }

  handleChangeNew(value) {
    let available_level = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        available_level.push(item.value);
      });
    }

    //  const available_level_value = value !== null ? value.value : [];
    this.setState({
      selectedLevelList: value,
      available_level: available_level,
    });
  }

  handleChangeNewSearch(value) {
    let available_level = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        available_level.push(item.value);
      });
    }

    const available_level_value = value !== null ? value.value : [];
    this.setState({
      selectedLevelList: value,
      available_level: available_level_value,
    });
  }

  handleChangeStatusList(value) {
    let f_status_id = [];
    if (value != null && value.length > 0) {
        value.forEach((item, i) => {
          f_status_id.push(item.value);
        });
    }
    this.setState({
        selectedStatusList: value,
        filter_status_id: value.value,
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /*for amenity modal */
  openAddAmenityModal() {
    this.getAmenityTypeData();
    this.setState({imageUrl: ""});
    this.setState({ addAmenityModal: true });
    this.setState({imageUrl: ""})
  }

  /*for amenity search modal */
  opensearchAmenityModal() {
    this.getAmenityTypeData();
    this.setState({ searchAmenityModal: true });
  }

  closeAddAmenityModal() {
    this.setState({ addAmenityModal: false });
    this.setState({
      amenity_name: "",
      amenity_type_slug: "",
      sort_order: "",
      tag_line: "",
      description: "",
      amenity_icon:"",
      amenity_icon_url:"",
      available_level: [],
      errors: "",
      status_id: true,
      selectedLevelList: [],
      selectedAmenityTypeList: [],
    });
    this.validator.hideMessages();
  }

  closesearchAmenityModal() {
    this.setState({ searchAmenityModal: false });
    this.setState({
      amenity_name: "",
      amenity_type_slug: "",
      available_level: [],
      status_id: true,
      selectedLevelList: [],
      selectedAmenityTypeList: [],
    });
    this.validator.hideMessages();
  }

  openEditAmenityModal(slug) {
    this.getAmenityTypeData();
    this.getAmenityDetails(slug);
    this.setState({ editAmenityModal: true });
    this.setState({ slug: slug });
  }

  closeEditAmenityModal() {
    this.setState({ editAmenityModal: false });
    this.setState({
      amenity_name: "",
      amenity_type_slug: "",
      sort_order: "",
      tag_line: "",
      description: "",
      menity_icon:"",
      amenity_icon_url:"",
      available_level: [],
      errors: "",
      status_id: true,
      selectedLevelList: [],
      selectedAmenityTypeList: [],
    });
    this.validator.hideMessages();
  }

  openDeleteAmenityModal(slug) {
    this.setState({ deleteAmenityModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteAmenityModal() {
    this.setState({ deleteAmenityModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Amenities";
  }
  

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {global.userPermissions.checkPermission(
                        "master-data-add"
                      ) && (
                        <Button
                          variant="outline-dark btn-block btn-with-icon"
                          onClick={this.openAddAmenityModal}
                          
                        >
                          <img src={pluswIcon} /> Add
                        </Button>
                        
                      )}
                       <Button
                         
                          onClick={this.opensearchAmenityModal}
                          className="outline-dark btn-block btn-with-icon mt-0 ml-3"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </Button>
                        {/* <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button> */}
                        {/* <button
                                  onClick={this.searchToggle}
                                  className="btn-success-outline-small"
                                >
                                  <img src={filterIcon} alt="filterIcon" />{" "}
                                  {this.state.isSearch === 1
                                    ? "Search"
                                    : "Search"}
                                </button> */}
                       
                      </div>
                    </div>
                  </div>
                  <div className="pd-lg-l-15">
                  {this.state.is_search === 1 ? (
                <>
                        <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                          <div className="custm-filter pd-lg-l-0 pd-xs-l-15">
                            <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                            <div className="filter_bg_cust">
                              {
                                this.state.module_search.map((item, i) => {
                                  return (
                                    <span key={i}>{item}</span>
                                  )
                                })
                              }
                    <button
                      onClick={this.clearFilter}
                                className="btn-fliter-clear"
                              >Clear</button>
                  </div>
                          </div>
                        </div>
                </>
              ) : (
                ""
              )}
                    <div className="table-responsive">
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                    </div>
                      <div className="scrolling-carousel scroll-slide mg-lg-l-0 mg-xs-l-15">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="all"
                          onSelect={(e) => this.getActiveClass(e)}
                          eventKey={this.state.activeClass}
                          className="pd-r-60"
                        >
                          <ScrollingCarousel
                            rightArrow={true}
                            leftArrow={true}
                            className="swipesection"
                          >
                            <Nav.Item>
                              <Nav.Link
                                eventKey="activeTab"
                                id="activeTab"
                                className={
                                  this.state.activeClass === "activeTab"
                                    ? "active"
                                    : ""
                                }
                              >
                                Active
                              </Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                              <Nav.Link
                                eventKey="inactiveTab"
                                id="inactiveTab"
                                className={
                                  this.state.activeClass === "inactiveTab"
                                    ? "active"
                                    : ""
                                }
                              >
                                Inactive
                              </Nav.Link>
                            </Nav.Item>
                          </ScrollingCarousel>
                          <Tab.Content>
                            <Tab.Pane eventKey="activeTab">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                {this.state.amenityListTotal > 0 ? (
                                  <div className="mg-t-18 brandList">
                                   {/* active amenities table */}
                                   <ReactDatatable
                                    className="table no-wrap mg-b-30 pd-b-2 text-align-left neighbor_des ameniy_master_tbl link_td_click_tbl"
                                    config={
                                      this.state.amenityListTotal > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    // loading={this.state.loading}
                                    total_record={this.state.amenityListTotal}
                                    onChange={this.tableChangeHandler}
                                  // onRowClicked={this.openEditAmenityModal}
                                  />
                                  </div>
                                ) : (
                                  <NoData msg="active amenities" />
                                )}
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="inactiveTab">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                {this.state.amenityListTotal > 0 ? (
                                  <div className="mg-t-18 brandList">
                                    {/* inactive amenities table */}
                                    <ReactDatatable
                                      className="table no-wrap mg-b-30 pd-b-2 text-align-left neighbor_des ameniy_master_tbl link_td_click_tbl"
                                      config={
                                        this.state.amenityListTotal > global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      // loading={this.state.loading}
                                      total_record={this.state.amenityListTotal}
                                      onChange={this.tableChangeHandler}
                                    // onRowClicked={this.openEditAmenityModal}
                                    />
                                  </div>
                                ) : (
                                  <NoData msg="inactive amenities" />
                                )}
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </Container>
          <Container>
            
              {/* {this.state.is_search === 1 ? (
                <>
                  <div className="col-md-12 pd-l-30 pd-r-30 mg-t-30 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                    {"Search applied on: " + this.state.module_search}
                    <button
                      onClick={this.clearFilter}
                      className="mg-l-15 btn-success-outline-small"
                    >
                      Clear
                    </button>
                  </div>
                </>
              ) : (
                ""
              )} */}
             
            </Container>
        </main>

        {/* add amenity popup modal */}
        <Modal
          show={this.state.addAmenityModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddAmenityModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddAmenityModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Amenity</h3>
            <InputGroup className="mt-3">
              <label>Amenity Name</label>
              <Form.Control
                onChange={this.changeHandler}
                name="amenity_name"
                value={this.state.amenity_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
              // placeholder="Amenity Name"
              />
            </InputGroup>
            {this.state.errors.amenity_name ? (
              <div className="text-danger">
                {this.state.errors.amenity_name}
              </div>
            ) : (
              this.validator.message(
                "amenity_name",
                this.state.amenity_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>
                Level
              </label>
              <div className="col-md-12 p-0">
                <Select
                  isMulti
                  styles={customStyles}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.selectLevelList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeNew(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>
            {this.state.errors.available_level ? (
              <div className="text-danger">
                {this.state.errors.available_level}
              </div>
            ) : (
              this.validator.message(
                "level",
                this.state.available_level,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Amenity Type</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newAmenityTypeList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeAmenityTypeList(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="amenity_type_slug"
                value={this.state.amenity_type_slug}
              >
                <option key="" value="">
                  Select Amenity Type
                </option>
                {this.state.amenityTypeList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.amenity_type_name}
                  </option>
                ))}
              </select> */}
            </InputGroup>
            {this.state.errors.amenity_type_slug ? (
              <div className="text-danger">
                {this.state.errors.amenity_type_slug}
              </div>
            ) : (
              this.validator.message(
                "amenity_type",
                this.state.amenity_type_slug,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>
                Amenity Tagline <span className="optional">(Optional)</span>
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="tag_line"
                value={this.state.tag_line}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
              // placeholder="Amenity Tagline"
              />
            </InputGroup>
            {this.state.errors.tag_line ? (
              <div className="text-danger">{this.state.errors.tag_line}</div>
            ) : (
              this.validator.message(
                "tag_line",
                this.state.tag_line,
                "max:100",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
              // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )}
              <div className="mt-3">
                <label>
                  Icon <span className="optional">(Optional)</span>
                </label>

                <div className="fileUpload d-flex flex-wrap justify-content-between aminitiesupload">
                  <span className="aSection">
                    <label className="hand-cursor">
                      <input
                        onChange={this.onImageChange}
                        type="file"
                        name="amenity_icon"
                      />
                      {this.state.imageUrl ? (
                        <img
                          src={this.state.imageUrl}
                          alt=""
                        />
                      ) : (
                        <img src={hostImg} alt="" />
                      )}
                    </label>
                  </span>
                </div>
              </div>

            <div className="d-flex align-items-center justify-content-start mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddAmenityModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveAmenity}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>


 {/* search  amenity popup modal */}
        <Modal
          show={this.state.searchAmenityModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without Searching?"))
              this.closesearchAmenityModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closesearchAmenityModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Search Amenity</h3>
            <InputGroup className="mt-3">
              <label>Amenity Name</label>
              <Form.Control
                onChange={this.changeHandler}
                name="amenity_name"
                value={this.state.amenity_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
              // placeholder="Amenity Name"
              />
            </InputGroup>

            <InputGroup className="mt-3">
              <label>
              Amenity Level
              </label>
              <div className="col-md-12 p-0">
                <Select

                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.selectLevelList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeNewSearch(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>

            <InputGroup className="mt-3">
              <label>Amenity Type</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newAmenityTypeList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeAmenityTypeList(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              
            </InputGroup>

            {/* <InputGroup className="mt-3">
              <label>
                Status Id
              </label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newStatusList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeStatusList(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup> */}

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closesearchAmenityModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.searchAmenity}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>



        {/* edit amenity popup modal */}
        <Modal
          show={this.state.editAmenityModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditAmenityModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditAmenityModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Amenity</h3>
            <InputGroup className="mt-3">
              <label>Amenity Name</label>
              <Form.Control
                onChange={this.changeHandler}
                name="amenity_name"
                value={this.state.amenity_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
              // placeholder="Amenity Name"
              />
            </InputGroup>
            {this.state.errors.amenity_name ? (
              <div className="text-danger">
                {this.state.errors.amenity_name}
              </div>
            ) : (
              this.validator.message(
                "amenity_name",
                this.state.amenity_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>
                Level
              </label>
              <div className="col-md-12 p-0">
                <Select
                  isMulti
                  styles={customStyles}
                  menuPlacement="auto"
                  defaultValue={this.state.selectedLevelList}
                  value={this.state.selectedLevelList}
                  className="multiselect"
                  options={this.state.selectLevelList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeNew(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>
            {this.state.errors.available_level ? (
              <div className="text-danger">
                {this.state.errors.available_level}
              </div>
            ) : (
              this.validator.message(
                "level",
                this.state.available_level,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Amenity Type</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newAmenityTypeList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeAmenityTypeList(value)}
                  defaultValue={this.state.selectedAmenityTypeList}
                  value={this.state.selectedAmenityTypeList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="amenity_type_slug"
                value={this.state.amenity_type_slug}
              >
                <option key="" value="">
                  Select Amenity Type
                </option>
                {this.state.amenityTypeList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.amenity_type_name}
                  </option>
                ))}
              </select> */}
            </InputGroup>
            {this.state.errors.amenity_type_slug ? (
              <div className="text-danger">
                {this.state.errors.amenity_type_slug}
              </div>
            ) : (
              this.validator.message(
                "amenity_type",
                this.state.amenity_type_slug,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>
                Amenity Tagline <span className="optional">(Optional)</span>
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="tag_line"
                value={this.state.tag_line}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
              // placeholder="Amenity Tagline"
              />
            </InputGroup>
            {this.state.errors.tag_line ? (
              <div className="text-danger">{this.state.errors.tag_line}</div>
            ) : (
              this.validator.message(
                "tag_line",
                this.state.tag_line,
                "max:100",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
              // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )}
              <div className="mt-3">
                <label>Icon <span className="optional">(Optional)</span></label>
                <div className="fileUpload d-flex flex-wrap justify-content-between">
                  <span>
                    <label className="hand-cursor">
                      <input
                        onChange={this.onImageChange}
                        type="file"
                        name="amenity_icon"
                      />
                      {this.state.imageUrl ? (
                        <img
                          src={this.state.imageUrl}
                          alt=""
                        />
                      ) : (
                        <img src={hostImg} alt="" />
                      )}
                    </label>
                  </span>
                </div>
              </div>

            <div className="d-flex align-items-center justify-content-start mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditAmenityModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateAmenity}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete amenity popup modal */}
        <Modal
          show={this.state.deleteAmenityModal}
          onHide={() => {
            if (
              window.confirm("Are you sure to exit the pop up without delete?")
            )
              this.closeDeleteAmenityModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeDeleteAmenityModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAmenityModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteAmenity(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Amenity;