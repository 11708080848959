import React, { Component } from "react";
import { Link } from "react-router-dom";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import { Container, Carousel, Form, Modal } from "react-bootstrap";
import unitsImage from "./../../assets/images/PropPlaceMainImg.png";
import UnitsService from "../../services/UnitsService";
import MultiImageService from "../../services/MultiImageService";
import FileWidget from "../fileUploader/FileWidget";
import close from "../../assets/images/close-wel.svg";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import deleteImg from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
// import infosize from "../../assets/images/Info.svg";
import UnitSidebar from "../common/UnitSidebar";
export class UnitsImages extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      importModal: false,
      multiImageList: [],
      multiImageListTotal: 0,
      newRelatedFilesDesc: [],
      unit_main_image: "",
      imageUrl: unitsImage,
      unitImageList: [],
      unitImageListTotal: 0,
      property_slug: this.props.match.params.propertySlug,
      unit_slug: this.props.match.params.slug,
      unitName: sessionStorage.getItem("unit_name")
        ? sessionStorage.getItem("unit_name")
        : "",
    };
    this.unitsImage = React.createRef();
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeView = this.changeView.bind(this);
    this.changeView2 = this.changeView2.bind(this);
    this.confirmImportModal = this.confirmImportModal.bind(this);
    this.closeConfirmImportModal = this.closeConfirmImportModal.bind(this);
    this.openUnitListImageModal = this.openUnitListImageModal.bind(this);
    this.closeUnitListImageModal = this.closeUnitListImageModal.bind(this);
    this.deleteUnitImg = this.deleteUnitImg.bind(this);
    this.opendeleteUnitImgModal = this.opendeleteUnitImgModal.bind(this);
    this.newRelatedFilesDescChange = this.newRelatedFilesDescChange.bind(this);
  }

  newRelatedFilesDescChange(index, value) {
    let newRelatedFilesDesc = this.state.newRelatedFilesDesc;
    newRelatedFilesDesc[index] = value;
    this.setState({ newRelatedFilesDesc: newRelatedFilesDesc });
  }

  async getMulitImagesData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.unit_slug !== undefined) {
      let inputData = {
        path: "units",
        refTableId: 5,
        slug: this.state.unit_slug,
        mediaType: 1,
      };
      let res = await MultiImageService.getImages(inputData);
      var multiImageList = [];
      var multiImageListTotal = 0;
      if (global.successStatus.includes(res.status)) {
        multiImageList = res.data ? res.data : [];
        multiImageListTotal = multiImageList.length;
      }
      this.setState({
        multiImageList: multiImageList,
        multiImageListTotal: multiImageListTotal,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  opendeleteUnitImgModal(slug) {
    this.setState({ deleteUnitImgModal: true, slug: slug });
  }

  closedeleteUnitImgModal() {
    this.setState({ deleteUnitImgModal: false, slug: "" });
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "unit_main_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("resizeOptions","width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200");
      fileData.append("mediaType", "1");
      let res = await UnitsService.uploadUnitsLogo(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          unit_main_image: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  saveOrImage = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true, isSubmit: true });
    if (this.state.unit_main_image) {
      let inputImgData = {
        tab: 2,
        property_slug: this.state.property_slug,
        unit_name: this.state.unitName,
        unit_main_image: this.state.unit_main_image,
      };

      if (this.state.unit_slug !== undefined) {
        let res = await UnitsService.updateUnits(this.state.unit_slug, inputImgData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }

      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }

    const FileWidgetObj = new FileWidget();
    const output = await FileWidgetObj.fileUploadFun(
      "units_form",
      this.state.newRelatedFilesDesc
    );
    if (output.status !== false) {
      if (output.response[0].filesData) {
        if (output.response[0].filesData.length > 0) {
          let inputData = {
            availableFiles: output.response,
            deletedFiles: this.state.deletedFileList,
          };

          if (this.state.unit_slug !== undefined) {
            let res = await MultiImageService.submitMultiImage(inputData);
            if (global.successStatus.includes(res.status)) {
              this.closedeleteUnitImgModal();
              this.setState({
                showAlertModal: true,
                alertModalType: "success",
                alertModalTitle: "Success",
                alertModalMessage: res.message ? res.message : "Success",
              });
              this.setState({ viewMode: 0 });
              this.setState({ newRelatedFilesDesc: [] });
              this.getMulitImagesData();
            } else {
              this.closedeleteUnitImgModal();
              let alertMessage = "";
              if (res.data.result.errorDetail !== null) {
                let errors = {};
                res.data.errorCode = "Validation Failed";
                res.data.result.errorDetail.forEach((item) => {
                  errors[item.errorField] = item.errorMessage[0];
                  // alertMessage += item.errorMessage[0]+'\n';
                  res.data.errorMessage = "";
                });
                this.setState({ errors: errors });
              }
              this.setState({
                showSpinner: false,
                showAlertModal: true,
                alertModalType: "error",
                alertModalTitle: res.data.errorCode
                  ? res.data.errorCode
                  : "Error!",
                alertModalMessage:
                  alertMessage !== "" ? alertMessage : res.data.message,
              });
            }
          }
        }
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: "The file should be png or jpg.",
      });
    }
    if (this.state.unitImageListTotal > 0) {
      let inputUpdateData = {
        image_data: this.state.unitImageList ? this.state.unitImageList : [],
      };

      let res = await MultiImageService.updateMultiImage(inputUpdateData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({ viewMode: 0, unitImageListTotal: 0 });
        this.getMulitImagesData();
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }

    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
    this.setState({ viewMode: 0 });
    this.getImagesData();
  };

  async getImagesData() {
    var resData = {};
    let res = await UnitsService.getUnits(this.state.unit_slug);
    if (global.successStatus.includes(res.status)) {
      resData.imageUrl = res.data.unit_main_image_url
        ? res.data.unit_main_image_url
        : "";
      this.setState(resData);
      // this.props.imgFile(resData.imageUrl);
    }
  }

  async componentDidMount() {
    this.getMulitImagesData();
    if (this.state.unit_slug !== undefined) {
      var resData = {};
      let res = await UnitsService.getUnits(this.state.unit_slug);
      if (global.successStatus.includes(res.status)) {
        // resData = res.data ? res.data : {};

        resData.unit_main_image = res.data.unit_main_image
          ? res.data.unit_main_image
          : "";
        resData.imageUrl = res.data.unit_main_image_url
          ? res.data.unit_main_image_url
          : "";
      }
      this.setState(resData);
    }
  }

  async deleteUnitImg(slug) {
    this.setState({ showSpinner: true });
    let inputData = {
      path: "units",
      refTableId: 5,
      slug: this.state.unit_slug,
      mediaType: 1,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteUnitImgModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.state.viewMode === 2
        ? this.setState({ viewMode: 2 })
        : this.setState({ viewMode: 0 });
      this.getMulitImagesData();
    } else {
      this.closedeleteUnitImgModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
    this.getImgData();
    this.setState({ viewMode: 0 });
  }

  async getImgData() {
    if (this.state.unit_slug !== undefined) {
      var resData = {};
      let res = await UnitsService.getUnits(this.state.unit_slug);
      if (global.successStatus.includes(res.status)) {
        resData.imageUrl = res.data.unit_main_image_url
          ? res.data.unit_main_image_url
          : "";
      }
      this.setState(resData);
    }
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  confirmImportModal(i) {
    this.setState({ importModal: true });
    this.setState({ carouselZoom: i });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  openUnitListImageModal() {
    this.setState({ unitListImageModal: true });
  }

  closeUnitListImageModal() {
    this.setState({ unitListImageModal: false });
  }

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value = event.target.value;
    let unitImageList = [...this.state.multiImageList];
    var unitImageListTotal = 0;
    if (event.target.name === "description") {
      unitImageList[index][event.target.name] = value;
      unitImageListTotal = unitImageList.length;
      this.setState({
        unitImageList: unitImageList,
        unitImageListTotal: unitImageListTotal,
      });
    } else {
      input[event.target.name] = value;
      this.setState({ [event.target.name]: value, input: input });
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
    } else {
      this.setState({ viewMode: 1 });
    }
  }

  changeView2() {
    if (this.state.viewMode === 2) {
      this.setState({ viewMode: 0 });
    } else {
      this.setState({ viewMode: 2 });
    }
  }

  getTitle() {
    return "Images";
  }

  render() {
    return (

      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <UnitSidebar
                    unit_slug={this.state.unit_slug}
                    property_slug={this.state.property_slug}
                  />
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="scrolling-carousel pd-l-15">
                  <div className="row d-flex justify-content-between align-items-center pd-l-15 pd-r-15 mg-b-0 pd-b-2 mg-t-20">
                    <h3>{this.getTitle()}</h3>
                  </div>
                  <div className="dashboardRightcard">
                    <div className="col-md-12 d-flex justify-content-end pd-l-0 pd-r-0 mg-b-15 ">
                      {this.state.viewMode === 0 ? (
                        <button
                          className="btn-success-outline-small"
                          onClick={this.changeView}
                        >
                          <img src={editIcon} />
                          Edit
                        </button>
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small mr-3"
                            onClick={
                              this.state.viewMode === 2
                                ? this.changeView2
                                : this.changeView
                            }
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn-success mr-3"
                            onClick={
                              this.state.viewMode === 2
                                ? this.updateImgDec
                                : this.saveOrImage
                            }
                            disabled={this.state.isSubmit ? true : false}
                          >
                            {" "}
                            {this.state.viewMode === 2
                              ? "Save"
                              : "Save"}{" "}
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 pl-0 pr-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.state.viewMode === 0 ? (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="demo-avatar-group d-flex justify-content-start flex-row">
                              <div className="avatar-round mg-b-0">
                                <p className="az-profile-name-text pl-0 pd-b-20 pd-b-2">
                                  Image
                                </p>

                                <div className="thmbImg">
                                  {this.state.imageUrl ? (
                                    <Link
                                      to={"#"}
                                      className="overlay"
                                      onClick={this.openUnitListImageModal}
                                    >
                                      <span></span>
                                      <img
                                        src={
                                          this.state.imageUrl
                                            ? this.state.imageUrl
                                            : ""
                                        }
                                        alt="Unit Image"
                                      />
                                    </Link>
                                  ) : (
                                    <img src={unitsImage} alt="Unit Image" />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="mg-t-10 row border-top-0 mg-b-50 pd-b-2 pl-0 pr-0 w-100 mw-100" />
                        <div className="row">
                          <div className="col-md-12 pd-l-0 pd-r-0">
                            <p className="az-profile-name-text pd-l-30 pd-r-30 pd-b-20 pd-b-">
                              Other Images
                            </p>

                            <div className="row pd-l-30 pd-r-30">
                              {this.state.multiImageListTotal > 0
                                ? this.state.multiImageList.map((option, i) => (
                                  <div
                                    className="col-md-4 pd-l-7 pd-r-7 mg-b-6 mg-t-6"
                                    key={i}
                                  >
                                    <div className="avatar-squre-listing mg-b-0">
                                      <figure>
                                        <img
                                          src={deleteImg}
                                          className="del-img"
                                          alt="delete image"
                                          onClick={() =>
                                            this.opendeleteUnitImgModal(
                                              option.slug
                                            )
                                          }
                                        />
                                        <Link
                                          to={"#"}
                                          className="overlay"
                                          onClick={() =>
                                            this.confirmImportModal(i)
                                          }
                                        >
                                          <span>
                                            <img
                                              src={deleteImg}
                                              alt="delete image"
                                              onClick={() => {
                                                this.opendeleteUnitImgModal(
                                                  option.slug
                                                );
                                              }}
                                            />
                                          </span>
                                          <img
                                            src={
                                              option.file_name
                                                ? option.file_name
                                                : ""
                                            }
                                            alt="Basic Unit Photo"
                                          />
                                        </Link>
                                      </figure>
                                    </div>
                                  </div>
                                ))
                                : "There are no images added in the system. You may add them by clicking Add button."}
                            </div>
                          </div>
                        </div>
                        {/* unit image images modal */}
                        <Modal
                          show={this.state.unitListImageModal}
                          onHide={() => this.closeUnitListImageModal()}
                          centered
                          className="propsinglemodal"
                        >
                          <Modal.Body>
                            <span
                              className="welcomeClose"
                              onClick={() => this.closeUnitListImageModal()}
                            >
                              <img src={close} />
                            </span>
                            <img
                              src={
                                this.state.imageUrl
                                  ? this.state.imageUrl
                                  : unitsImage
                              }
                              alt="Unit Image"
                            />
                          </Modal.Body>
                        </Modal>

                        {/* units listing images modal */}
                        <Modal
                          show={this.state.importModal}
                          onHide={() => this.closeConfirmImportModal()}
                          centered
                          className="propertylistmodal"
                        >
                          <Modal.Body>
                            <span
                              className="welcomeClose"
                              onClick={() => this.closeConfirmImportModal()}
                            >
                              <img src={close} />
                            </span>
                            <Carousel
                              activeIndex={this.state.carouselZoom}
                              onSelect={this.confirmImportModal}
                              prevIcon={
                                this.state.multiImageListTotal === 1 ? (
                                  false
                                ) : (
                                  <span
                                    aria-hidden="true"
                                    className="carousel-control-prev-icon"
                                  />
                                )
                              }
                              nextIcon={
                                this.state.multiImageListTotal === 1 ? (
                                  false
                                ) : (
                                  <span
                                    aria-hidden="true"
                                    className="carousel-control-next-icon"
                                  />
                                )
                              }
                            >
                              {this.state.multiImageListTotal > 0
                                ? this.state.multiImageList.map((option, i) => (
                                  <Carousel.Item key={i}>
                                    <img
                                      src={option.file_name}
                                      className="d-block w-100 op-3"
                                      alt="..."
                                    />
                                    {option.description !== undefined &&
                                      option.description !== null &&
                                      option.description !== "" &&
                                      option.description !== "undefined" ? (
                                      <Carousel.Caption>
                                        <p>
                                          {option.description
                                            ? option.description
                                            : ""}
                                        </p>
                                      </Carousel.Caption>
                                    ) : (
                                      ""
                                    )}
                                  </Carousel.Item>
                                ))
                                : ""}
                            </Carousel>
                          </Modal.Body>
                        </Modal>
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.viewMode === 1 ? (
                      <>
                        <div className="dashboardRightcard pl-0 pr-0">
                          <div className="m-0">
                            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                              <div className="col-md-3 pl-0">
                                <label className="form-label az-profile-name-text pl-0 pd-b-0 text-left">
                                  Image
                                </label>
                              </div>

                              <div className="col-md-9 col-lg-9 mg-t-5 mg-md-t-0 pl-0">
                                <div className="fileUpload d-flex flex-wrap justify-content-between">
                                  <span>
                                    <label className="hand-cursor">
                                      <input
                                        onChange={this.onImageChange}
                                        type="file"
                                        name="unit_main_image"
                                      />
                                      {this.state.imageUrl ? (
                                        <img
                                          src={this.state.imageUrl}
                                          alt="Unit Image"
                                        />
                                      ) : (
                                        <img
                                          src={unitsImage}
                                          alt="Unit Image"
                                        />
                                      )}
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <form id="units_form">
                              <div className="row row-xs align-items-center pd-t-50 pd-b-0">
                                <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0">
                                  <FileWidget
                                    fieldName="file_name"
                                    fieldTitle="Select Other Images"
                                    fieldLabel="Images"
                                    checkValidation="true"
                                    allowedType="image/jpg,image/png"
                                    maxFileSize={1000}
                                    maxFileSizeType="mb"
                                    maxFileAllowed={10}
                                    uploadOn="s3"
                                    slug={this.state.unit_slug}
                                    refTableId={5}
                                    mediaType={1}
                                    sortOrder=""
                                    statusId=""
                                    drag="false"
                                    dragText="Add Image (Multiple)"
                                    inputClassName="form-control"
                                    labelClassName="form-label"
                                    uploadType="multiple"
                                    formID="units_form"
                                    resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
                                    propertySlug = {this.state.property_slug}
                                    destinationFolder="units"
                                    uploadedFiles={this.state.file_name}
                                    deletedFiles={this.state.deletedFileList}
                                    acceptOnly="image/jpg,image/png"
                                    displayPreviewStyle="vertical"
                                    newRelatedFilesDescChange={
                                      this.newRelatedFilesDescChange
                                    }
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="m-0">
                            <div className="row row-xs align-items-center pd-t-0 pd-b-30">
                              <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0">
                                <div className="form-group mt-1 mb-0">
                                  <hr className="mg-t-10 row border-top-0 mg-b-50 pd-b-2 pl-0 pr-0 w-100 mw-100" />
                                  <label className="form-label az-profile-name-text pl-0 pd-b-0 text-left">
                                    Uploaded Images
                                  </label>
                                  <ul className="multiImgsection p-0">
                                    {this.state.multiImageListTotal > 0
                                      ? this.state.multiImageList.map(
                                        (option, i) => (
                                          <>
                                            <li
                                              className="list-group-item"
                                              key={i}
                                            >
                                              <div className="row pd-b-20 pr-3 ml-0">
                                                <div className="col-md-12  pl-0 pr-0 d-flex">
                                                  <figure className="mg-r-15">
                                                    <img
                                                      src={
                                                        option.file_name
                                                          ? option.file_name
                                                          : ""
                                                      }
                                                      alt=""
                                                    />
                                                  </figure>
                                                  <Form.Control
                                                    onChange={(e) =>
                                                      this.changeHandler(e, i)
                                                    }
                                                    name="description"
                                                    value={option.description}
                                                    className="form-control max_width_100"
                                                    type="text"
                                                    rows="4"
                                                    as="textarea"
                                                    autoComplete="off"
                                                    // placeholder="Description"
                                                    resize="resize"
                                                    maxLength="255"
                                                  />
                                                  <div className="mg-l-15 mg-r-0 text-center">
                                                    <button
                                                      onClick={() =>
                                                        this.opendeleteUnitImgModal(
                                                          option.slug
                                                        )
                                                      }
                                                      type="button"
                                                      className="btn-multilist-outline-danger"
                                                    >
                                                      <img
                                                        src={deleteicon}
                                                        alt=""
                                                      />
                                                    </button>
                                                    <span className="danger">
                                                      Remove <b>image</b>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          </>
                                        )
                                      )
                                      : "There are no images added in the system. You may add them by clicking Add button."}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteUnitImgModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteUnitImgModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className=" m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteUnitImg(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default UnitsImages;
