////////////////////////////////////////////////////////////
//
//  Program: CorporateAppForm.jsx
//  Application:  CorporateApp
//  Option: For add or update CorporateAppForm
//  Developer: Raj Kumar
//  Date: 2023-03-23
//
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, Modal } from "react-bootstrap"; // InputGroup
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import { Jodit } from "jodit";
import FileUploadService from "../../services/FileUploadService";
import CorporateAppService from "../../services/CorporateAppService";
import PropertyService from "../../services/PropertyService";
import CMSSidebar from "../common/CMSSidebar";
import JoditEditor from "jodit-react";
import Select from "react-select";
export class CorporateAppForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: this.props.match.params.slug,
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      isSubmit: false,
      status_id: false,
      private_content_status: false,
      bodyPopup: false,
      description: "",
      video_url: "",
      image_file: "",
      video_thumb: "",
      image_url: "",
      title: "",
      reference: "",
      sort_order: "",
      category: "",
      isResources: 0,

      selectedContentTypeList: [],
      selectedArticleContent: [],
      selectedProperty: [],

      // New Variable
      contentType: 1, //Consider as default - Rich Text Editor
      contentTypeStr: 1, //Consider as default - Rich Text Editor
      title_slug: "",
      selectedModulename: [{ label: "Miscellaneous", value: 2 }],
      selectedContentType: [{ label: "Rich Text Editor", value: 1 }],
      isVideo: false,
      isImage: false,
      module_name: 2,
      file_url: "",
      isImageValidation: false,
      video_file_url: "",
      property_slug: "",
    };
    this.editorConfig = {
      extraButtons: ["uploadImage", "uploadImageVideo"],
      width: "auto",
      height: "auto",
      minHeight: 500,
      events: {
        afterInit: (instance) => {
          this.jodit = instance;
        },
      },
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerDes = this.changeHandlerDes.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.privateContentStatus = this.privateContentStatus.bind(this);
    this.setDescriptionCard = this.setDescriptionCard.bind(this);
    this.openBodyPopup = this.openBodyPopup.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    this.getArticleCategory();
    this.getPropertyList();
    this.uploadImageButton();
    this.uploadVideoButton();
    if (this.state.slug !== undefined) {
      var resData = {};

      let res = await CorporateAppService.getCorporateAppBySlug(
        this.state.slug
      );
      if (global.successStatus.includes(res.status)) {
        if (res.data) {
          this.setState({ isImage: false, isVideo: false });
          resData = res.data ? res.data : {};
          resData.status_id = res.data.status_id === 1 ? true : false;
          resData.private_content_status =
            res.data.private_content_status === 1 ? true : false;

          resData.contentTypeStr = res.data.content_type
            ? res.data.content_type
            : this.state.contentTypeStr;

          resData.module_name = res.data.module_type ? res.data.module_type : 0;

          resData.description = res.data.description
            ? res.data.description
            : "";

          resData.video_url = res.data.file_type === 2 ? res.data.file_url : "";
          resData.image_url = res.data.file_type === 1 ? res.data.file_url : "";

          this.setState({
            video_file_url: res.data.file_url ? res.data.file_url : "",
            title_slug: res.data.slug_title ? res.data.slug_title : "",
          });
          resData.file_url =
            res.data.file_type === 1 ? res.data.file_url : res.data.thumbnail;

          resData.file_type = res.data.file_type ? res.data.file_type : "";
          resData.image_file = res.data.file_name ? res.data.file_name : "";

          resData.video_thumb =
            res.data.file_type === 2 ? res.data.thumbnail : "";

          // Module Name start
          if (res.data.module_type) {
            let moduleType = global.cmsModuleName.find(
              (module) => module.value === res.data.module_type
            );
            let selectedModulenameVal = [
              { label: moduleType.label, value: moduleType.value },
            ];
            this.setState({ selectedModulename: selectedModulenameVal });
          }
          // Module Name end

          // property Name start
          var selectedProperty = [];
          if (res.data.property_slug) {
            selectedProperty = [
              {
                value: res.data.property_slug,
                label: res.data.property_name,
              },
            ];
          }
          // property Name end

          // Content Type start
          if (res.data.content_type) {
            this.setState({ selectedContentType: [] });
            let contentTypeVal = global.cmsContentType.find(
              (module) => module.value === res.data.content_type
            );
            let selectedContentTypeVal = [
              { label: contentTypeVal.label, value: contentTypeVal.value },
            ];

            this.setState({ selectedContentType: selectedContentTypeVal });

            let richTextEditor = [1, 3, 4];
            let simpleTextEditor = [2, 5, 6];
            if (richTextEditor.includes(res.data.content_type)) {
              this.setState({ contentType: 1 });
            } else if (simpleTextEditor.includes(res.data.content_type)) {
              this.setState({ contentType: 2 });
            }
            if (res.data.content_type === 3 || res.data.content_type === 5) {
              this.setState({
                isImage: true,
                isVideo: false,
                isImageValidation: true,
              });
            }

            if (res.data.content_type === 4 || res.data.content_type === 6) {
              this.setState({
                isImage: true,
                isVideo: true,
                isImageValidation: false,
              });
            }
          }
          // Content Type end

          resData.article_category =
            res.data.kb_category && res.data.kb_category.kb_category_id
              ? res.data.kb_category.kb_category_id
              : "";
          let kb_category = [res.data.kb_category];

          var selectedArticleContent = [];
          selectedArticleContent =
            res.data.kb_category || kb_category[0]
              ? [
                  {
                    value:
                      res.data.kb_category !== null
                        ? res.data.kb_category.kb_category_id
                        : kb_category[0].kb_category_id,
                    label:
                      res.data.kb_category !== null
                        ? res.data.kb_category.kb_category_name
                        : kb_category[0].kb_category_name,
                  },
                ]
              : "";
        }
        this.setState(resData);
        this.setState({
          selectedArticleContent: selectedArticleContent,
          selectedProperty: selectedProperty,
        });
      }
    }
    this.setState({ isSubmit: false, loading: false });
  }

  async getArticleCategory() {
    let category = [];
    let categoryTotal = 0;
    let res = await CorporateAppService.getArticleCategory();

    if (global.successStatus.includes(res.status)) {
      category = res.data ? res.data : {};
      categoryTotal = category ? category.length : 0;
    }

    const kb_category_name = category.map((item) => ({
      label: item.kb_category_name,
      value: item.kb_category_id,
    }));
    kb_category_name.push({ label: "Resource", value: "RESOURCE" });
    kb_category_name.push({ label: "Mics", value: "MICS" });
    this.setState({
      category: category,
      categoryTotal: categoryTotal,
      kb_category_name: kb_category_name,
    });
  }

  async getPropertyList(queryString = "") {
    var propertyList = [];
    var propertyListTotal = 0;
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1&is_onboarding=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;
    }
    var newPropertyList =
      propertyListTotal > 0
        ? propertyList.map(({ slug, property_name }) => ({
            value: slug,
            label: property_name,
          }))
        : [];

    this.setState({
      propertyList: propertyList,
      propertyListTotal: propertyListTotal,
      newPropertyList: newPropertyList,
    });
  }

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: "Upload image",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.imageUpload(editor);
      },
    };
  };

  imageUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async function () {
      this.setState({ isSubmit: true });
      const imageFile = input.files[0];
      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", imageFile, imageFile.name);
      fileData.append("fileName", imageFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertImage(editor, response.data.filePath);
        this.setState({ isSubmit: false });
      }
    }.bind(this);
    this.setState({ isSubmit: false });
  };

  insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editor.selection.insertNode(image);
  };

  textImageUpload = async (event) => {
    this.setState({ file_url: "" });
    if (event.target.files[0] !== undefined) {
      this.setState({ isSubmit: true });
      const imageFile = event.target.files[0];

      const fileData = new FormData();
      fileData.append(
        "file",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
      );
      fileData.append("mediaType", "1");
      fileData.append("folderName", "jodit");
      fileData.append("fileName", imageFile.name);
      const res = await FileUploadService.uploadFromEditor(fileData);

      if (global.successStatus.includes(res.status)) {
        this.setState({
          image_file: res.data.fileName,
          image_url: res.data.filePath,
          video_thumb: res.data.filePath,
        });
        this.setState({ isSubmit: false });
      } else if (res.status === 413) {
        this.setState({
          isSubmit: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          isSubmit: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
    this.setState({ isSubmit: false, loading: false });
  };

  previewFile = (e) => {
    const reader = new FileReader();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
    reader.onload = (readerEvent) => {
      this.setState({ file_url: readerEvent.target.result });
    };
    document.getElementById("image_file").classList.remove("d-none");
  };

  uploadVideoButton = () => {
    Jodit.defaultOptions.controls.uploadImageVideo = {
      name: "Upload video",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.videoUpload(editor);
      },
    };
  };

  videoUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();
    input.onchange = async function () {
      this.setState({ isSubmit: true });
      const videoFile = input.files[0];

      if (!videoFile) {
        return;
      }

      if (!videoFile.name.match(/\.(mp3|mp4|mov)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", videoFile, videoFile.name);
      fileData.append("fileName", videoFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertVideo(editor, response.data.filePath);
        this.setState({ isSubmit: false });
      }
    }.bind(this);
    this.setState({ isSubmit: false });
  };

  insertVideo = (editor, url) => {
    const video = editor.selection.j.createInside.element("video");
    video.setAttribute("src", url);
    editor.selection.insertNode(video);
  };

  createCorporateApp = async () => {
    let validator = this.validator.allValid();
    console.log("validator", this.validator);
    if (validator) {
      this.setState({ isSubmit: true });
      let inputData = {
        title: this.state.title,
        content_type: this.state.contentTypeStr,
        description: this.state.description,
        status_id: this.state.status_id === true ? 1 : 2,
        private_content_status:
          this.state.private_content_status === true ? 1 : 2,
        sort_order: this.state.sort_order,
        article_category: this.state.article_category,
        reference: this.state.reference,
        video_url: this.state.video_url ? this.state.video_url : "",
        image_file: this.state.image_file ? this.state.image_file : "",
        image_url: this.state.image_url ? this.state.image_url : "",
        video_thumb: this.state.video_thumb ? this.state.video_thumb : "",

        module_name: this.state.module_name ? this.state.module_name : "",
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        title_slug: this.state.title_slug ? this.state.title_slug : "",
      };

      if (this.state.slug !== undefined) {
        let res = await CorporateAppService.updateCorporateApp(
          this.state.slug,
          inputData
        );

        //set state of sql to upload image
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () => this.props.history.push("/corporate-apps"),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      } else {
        let res = await CorporateAppService.createCorporateApp(inputData);
        //set state of sql to upload image

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () => this.props.history.push("/corporate-apps"),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }

      this.setState({ isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };
  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
  }

  openBodyPopup = () => {
    if (this.state.bodyPopup === true) {
      this.setState({ bodyPopup: false });
    } else {
      this.setState({ bodyPopup: true });
    }
  };

  insertbodyemail = (e) => {
    var dynamictext = "[" + this.state.body_variable + "]";
    if (this.state.contentType === 0) {
      this.setState({
        description: this.state.description
          ? `${this.state.description} ${dynamictext}`
          : `${dynamictext}`,
      });
    } else {
      this.jodit.selection.insertHTML(dynamictext);
    }
    this.setState({ bodyPopup: false });
  };

  privateContentStatus() {
    this.setState({
      private_content_status: !this.state.private_content_status,
    });
  }
  corporateChangeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
  };

  changeHandler = (event) => {
    let editor_description = this.state.description;
    let regex = /(&nbsp;|<([^>]+)>)/gi;
    let result = editor_description.replace(regex, "");
    if (
      this.state.contentType === 1 ||
      this.state.contentType === 4 ||
      this.state.contentType === 5
    ) {
      this.setState({
        description: result,
      });
    } else {
      this.setState({
        description: editor_description,
      });
    }
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
  };

  changeHandlerDes = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? value : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
  };
  closeModal() {
    this.setState({ showAlertModal: false });
  }
  setDescriptionCard(value) {
    this.setState({
      description: value,
    });
  }

  handleChangeProperty(value) {
    let property_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_id.push(item.value);
      });
    }
    this.setState({
      selectedProperty: value,
      property_slug: value.value,
    });
  }

  getTitle() {
    return "Corporate App";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  handleChangeArticleCategory(value) {
    let article_category = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        article_category.push(item.value);
      });
    }
    this.setState({
      selectedArticleContent: value,
      article_category: value.value,
    });
  }

  handleModuleName(value) {
    let moduleName = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        moduleName.push(item.value);
      });
    }
    this.setState({
      selectedModulename: value,
      module_name: value.value,
    });
  }

  handleContentType(value) {
    this.validator.hideMessages();
    // let selectedContentType = [];
    let contentTypeValue = 0;
    this.setState({
      isImage: false,
      isVideo: false,
      isImageValidation: false,
    });

    this.setState({
      selectedContentType: value,
      contentType: value.value,
      contentTypeStr: value.value,
    });

    contentTypeValue = value.value;
    if (contentTypeValue === 3 || contentTypeValue === 5) {
      if (contentTypeValue === 3) {
        this.setState({
          contentType: 1,
        });
      } else if (contentTypeValue === 5) {
        this.setState({
          contentType: 2,
        });
      }
      this.setState({
        isImage: true,
        isImageValidation: true,
      });
    }

    if (contentTypeValue === 4 || contentTypeValue === 6) {
      if (contentTypeValue === 4) {
        this.setState({
          contentType: 1,
        });
      } else if (contentTypeValue === 6) {
        this.setState({
          contentType: 2,
        });
      }
      this.setState({
        isImage: true,
        isVideo: true,
      });
    }

    let editor_description = this.state.description;
    let regex = /(&nbsp;|<([^>]+)>)/gi;
    let result = editor_description.replace(regex, "");
    this.setState({
      description: result,
    });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <CMSSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          this.props.history.push("/corporate-apps");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn-success ml-3"
                        type="button"
                        onClick={this.createCorporateApp}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                          ? "Save"
                          : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <form id="cms_form" name="cms_form">
                    <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="m-0">
                        <div className="row align-items-center col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-xs-l-0 pd-xs-r-0 pd-l-0 pd-r-0 border-bottom-0">
                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                            {/* Title section start  */}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Title{" "}
                                {/* <span className="optional">(Optional)</span> */}
                              </label>
                              <Form.Control
                                onChange={this.corporateChangeHandler}
                                type="text"
                                name="title"
                                value={this.state.title || ""}
                                autoComplete="off"
                              />
                              {this.state.errors.title ? (
                                <div className="text-danger">
                                  {this.state.errors.title}
                                </div>
                              ) : (
                                this.validator.message(
                                  "title",
                                  this.state.title,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Title section end  */}

                            {/* Title slug section start  */}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Title Slug
                                {/* <span className="optional">
                                  (If you want to custom slug then put the value
                                  otherwise it auto generated by your title.)
                                </span> */}
                              </label>
                              <Form.Control
                                onChange={this.corporateChangeHandler}
                                type="text"
                                name="title_slug"
                                value={this.state.title_slug || ""}
                                autoComplete="off"
                                disabled={this.state.slug ? true : false}
                              />
                            </div>
                            {/* Title slug section end  */}

                            {/* Article Category start  */}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Article Category{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Select
                                styles={customStyles}
                                className="multiselect"
                                menuPlacement="auto"
                                options={this.state.kb_category_name}
                                onChange={(value) =>
                                  this.handleChangeArticleCategory(value)
                                }
                                value={this.state.selectedArticleContent}
                                defaultValue={this.state.selectedArticleContent}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                            </div>
                            {/* Article Category end  */}

                            {/* Private Content section start  */}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-5">
                              <div className="col-md-4 col-lg-4 pl-0 d-flex align-items-center justify-content-start mg-t-30">
                                <Form.Check
                                  name="private_content_status"
                                  type={"checkbox"}
                                  id={`private_content_status`}
                                  label="Private Content"
                                  className={"ckbox"}
                                  onChange={this.privateContentStatus}
                                  checked={this.state.private_content_status}
                                />
                              </div>
                            </div>
                            {/* Private Content section end  */}

                            {/* Module section start  */}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Module Name{" "}
                              </label>
                              <Select
                                styles={customStyles}
                                className="multiselect"
                                menuPlacement="auto"
                                options={global.cmsModuleName}
                                onChange={(value) =>
                                  this.handleModuleName(value)
                                }
                                value={this.state.selectedModulename}
                                defaultValue={this.state.selectedModulename}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                            </div>
                            {/* Module section end  */}

                            {/* Content Type start  */}

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Content Type
                              </label>
                              <Select
                                styles={customStyles}
                                className="multiselect"
                                menuPlacement="auto"
                                options={global.cmsContentType}
                                onChange={(value) =>
                                  this.handleContentType(value)
                                }
                                value={this.state.selectedContentType}
                                defaultValue={this.state.selectedContentType}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                            </div>
                            {/* Content Type end  */}
                            {/* property started  */}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Property{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Select
                                styles={customStyles}
                                isClearable={true}
                                className="multiselect"
                                menuPlacement="auto"
                                value={this.state.selectedProperty}
                                options={this.state.newPropertyList}
                                onChange={(value) =>
                                  this.handleChangeProperty(value)
                                }
                                defaultValue={this.state.selectedProperty}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                            </div>
                            {/* property end  */}
                            {/* Internal Notes start  */}
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Internal Notes{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.corporateChangeHandler}
                                type="text"
                                name="reference"
                                value={this.state.reference || ""}
                                autoComplete="off"
                              />
                            </div>
                            {/* Internal Notes end  */}

                            {/* Description start  */}
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-0">
                              <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                <label className="form-label text-left pd-b-5">
                                  Description
                                </label>
                                <button
                                  type="button"
                                  className="btn btn_var_temp mg-b-5"
                                  onClick={this.openBodyPopup}
                                >
                                  Body Variable
                                </button>
                              </div>
                            </div>

                            {/* Rich Text Editor Start */}

                            {this.state.contentType === 1 ? (
                              <>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                                  <JoditEditor
                                    ref={null}
                                    name="description"
                                    id="description"
                                    value={this.state.description}
                                    onBlur={(newContent) =>
                                      this.setDescriptionCard(newContent)
                                    }
                                    // onChange={(newContent) =>
                                    //   this.setDescriptionCard(newContent)
                                    // }
                                    config={this.editorConfig}
                                  />
                                  {this.state.errors.description ? (
                                    <div className="text-danger">
                                      {this.state.errors.description}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "description",
                                      this.state.description,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {/* Rich Text Editor end */}

                            {/* Simple Text Editor start */}
                            {this.state.contentType === 2 ? (
                              <>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="description"
                                    value={this.state.description}
                                    className="form-control max_width_100"
                                    id="description"
                                    as="textarea"
                                    rows="5"
                                  />
                                  {this.state.errors.description ? (
                                    <div className="text-danger">
                                      {this.state.errors.description}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "description",
                                      this.state.description,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {/* Simple Text Editor end */}

                            {/*  Video URL  Start */}
                            {this.state.isVideo === true ? (
                              <>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Video URL{" "}
                                  </label>
                                  <Form.Control
                                    onChange={this.corporateChangeHandler}
                                    type="text"
                                    name="video_url"
                                    id="video_url"
                                    value={this.state.video_url || ""}
                                    autoComplete="off"
                                  />
                                  {this.state.errors.video_url ? (
                                    <div className="text-danger">
                                      {this.state.errors.video_url}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "video_url",
                                      this.state.video_url,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                  {this.state.slug &&
                                  this.state.video_file_url !== "" ? (
                                    <>
                                      <div className="video_preview_cms mg-t-5">
                                        <video alt="" controls>
                                          <source
                                            src={this.state.video_file_url}
                                            type="video/mp4"
                                          />
                                        </video>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {/*  Video URL end */}

                            {/*  Image/Thumbnail Start */}
                            {this.state.isImage === true ? (
                              <>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5 mg-t-0">
                                    {this.state.isVideo === true ? (
                                      <>Video Thumbnail </>
                                    ) : (
                                      <>Image File </>
                                    )}
                                  </label>
                                  <Form.Control
                                    onChange={(e) => {
                                      this.textImageUpload(e);
                                      this.previewFile(e);
                                    }}
                                    type="file"
                                    accept="image/*"
                                    name="image_file"
                                    id="image_file"
                                    autoComplete="off"
                                  />

                                  {/* {this.state.errors.image_file &&
                                  this.state.isVideo !== true && this.state.file_url === "" ? (
                                    <div className="text-danger">
                                      {this.state.errors.image_file}
                                    </div>
                                  ) : this.state.isVideo !== true &&
                                    this.state.file_url === "" ? (
                                    this.validator.message(
                                      "image_file",
                                      this.state.image_file,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  ) : (
                                    ""
                                  )} */}
                                  {this.state.errors.image_file &&
                                  this.state.isImageValidation === true &&
                                  this.state.isVideo === false ? (
                                    <div className="text-danger">
                                      {this.state.errors.image_file}
                                    </div>
                                  ) : this.state.isImageValidation === true &&
                                    this.state.isVideo === false ? (
                                    this.validator.message(
                                      "image_file",
                                      this.state.image_file,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  ) : (
                                    ""
                                  )}
                                  {this.state.file_url ? (
                                    <>
                                      <div className="img_preview_cms">
                                        <img src={this.state.file_url} alt="" />
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {/*  Image/Thumbnail end */}

                            {/* Description end  */}

                            {/* Sort Order start  */}
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Sort Order{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.corporateChangeHandler}
                                type="text"
                                name="sort_order"
                                value={this.state.sort_order}
                                autoComplete="off"
                              />
                            </div>
                            {/* Sort Order end  */}

                            {/* Status start  */}
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Status
                              </label>
                              <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start">
                                <b
                                  style={{ display: "none" }}
                                  className={
                                    this.state.status_id ? "active" : "inactive"
                                  }
                                >
                                  {this.state.status_id ? "Active" : "Inactive"}
                                </b>
                                <div
                                  className={
                                    this.state.status_id
                                      ? "az-toggle on"
                                      : "az-toggle"
                                  }
                                  onClick={() => this.changeStatus()}
                                >
                                  <span></span>
                                </div>
                              </div>
                            </div>
                            {/* Status end  */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal
          show={this.state.bodyPopup}
          className=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.openBodyPopup()}>
              {global.closee}
            </span>
            <h3 className="text-center">Insert Body Variable</h3>
            <div className="row col-12 m-0">
              <div className="var_tmp_modal cms_var_modal_inner pb-0 mg-t-10">
                <div className="var_tmp_box">
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandlerDes}
                        name="body_variable"
                        value="Property-Nightly-Cap"
                        type={"radio"}
                        id="1"
                        label={"Property-Nightly-Cap"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandlerDes}
                        name="body_variable"
                        value="Cleaning-Resident"
                        type={"radio"}
                        id="2"
                        label={"Cleaning-Resident"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandlerDes}
                        name="body_variable"
                        value="Nightly-Resident"
                        type={"radio"}
                        id="3"
                        label={"Nightly-Resident"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandlerDes}
                        name="body_variable"
                        value="Property-Percent-Cap"
                        type={"radio"}
                        id="4"
                        label={"Property-Percent-Cap"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandlerDes}
                        name="body_variable"
                        value="Fob-1"
                        type={"radio"}
                        id="5"
                        label={"Fob-1"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandlerDes}
                        name="body_variable"
                        value="Fob-2"
                        type={"radio"}
                        id="6"
                        label={"Fob-2"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandlerDes}
                        name="body_variable"
                        value="Booking-1"
                        type={"radio"}
                        id="7"
                        label={"Booking-1"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandlerDes}
                        name="body_variable"
                        value="Booking-2"
                        type={"radio"}
                        id="8"
                        label={"Booking-2"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandlerDes}
                        name="body_variable"
                        value="PHONE_NUMBER"
                        type={"radio"}
                        id="9"
                        label={"Phone-Number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandlerDes}
                        name="body_variable"
                        value="Date"
                        type={"radio"}
                        id="10"
                        label={"Date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandlerDes}
                        name="body_variable"
                        value="Appear-Time"
                        type={"radio"}
                        id="11"
                        label={"Appear-Time"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandlerDes}
                        name="body_variable"
                        value="Access-Time"
                        type={"radio"}
                        id="12"
                        label={"Access-Time"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br></br>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.openBodyPopup()}
              >
                Cancel
              </button>
              {this.state.body_variable ? (
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                  onClick={this.insertbodyemail}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Insert"}
                </button>
              ) : (
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                >
                  {this.state.isSubmit ? global.loader : "Insert"}
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default CorporateAppForm;
