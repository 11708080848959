////////////////////////////////////////////////////////////
//     							                          //
//  Program: ListingRentalActivity.jsx                  //
//  Application: ListingRentalActivity                  //
//  Option: For view ListingRentalActivity details      //
//  Developer:NP				                        //
//  Date: 2022-09-19                                    //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import RentalService from "../../services/RentalService";
import ListingService from "../../services/ListingService";
import TaxService from "../../services/TaxService";
import PropertyCostService from "../../services/PropertyCostService";
import MultiImageService from "../../services/MultiImageService";
import AmenityService from "../../services/AmenityService";
import PetPolicyService from "../../services/PetPolicyService";
import Alerts from "../common/Alerts";
import ReactDatatable from "@ashvin27/react-datatable";
import Moment from "moment";
import zoomImg from "../../assets/images/zoom.svg";
import hostImg from "./../../assets/images/profile-nogender.png";
import copyIcon from "./../../assets/images/copy.png";
import { Modal , Popover, OverlayTrigger, Button} from "react-bootstrap";

export class ListingRentalActivity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total_record: 0,
      publishData: false,
      publishPrice: false,
      publishDate: false,
      is_copied: true,
      records: [],
      priceList:[],
      dateList:[],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No Sync History",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No Sync History",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "sync_id",
          text: "Sync ID",
          className: "sync_id ",
          sortable: true,
          cell: (record) => {
            return record.sync_id ? <><p>{record.sync_id}</p>{this.state.is_copied ? (
              <OverlayTrigger
                trigger="click"
                placement="top"
                overlay={
                  <Popover
                    id="popover-basic"
                    className="popover-primary copide_box"
                  >
                    <Popover.Title as="h3" className="p-0">
                      Copied
                    </Popover.Title>
                  </Popover>
                }
              >
                <Button className="btn btn-copy_bg">
                  <img
                    src={copyIcon}
                    alt="Copy"
                    onClick={() => this.copyText(record.sync_id)}
                    className="copyClass"
                  />
                </Button>
              </OverlayTrigger>
            ) : (
              <Button className="btn btn-copy_bg">
                <img
                  src={copyIcon}
                  alt="Copy"
                  onClick={() => this.copyText(record.sync_id)}
                  className="copyClass"
                />
              </Button>
            )}</> : "";

          },
        },
        {
          key: "status_text",
          text: "Reason",
          className: "status_text ",
          sortable: false,
          cell: (record) => {
            return record.status_text ? record.status_text : "";
          },
        },
        // {
        //   key: "request",
        //   text: "Request",
        //   className: "request hand-cursor",
        //   sortable: true,
        //   cell: (record) => {
        //     return <p title={record.request ? record.request : ''} onClick={() => {navigator.clipboard.writeText(record.request)}}>Request</p>;
        //   },
        // },
        {
          key: "response",
          text: "Details",
          className: "response hand-cursor",
          sortable: false,
           cell: (record) =>{
            if (record.response.indexOf('{') > 0) {
              return record.response ? record.response : "";
            } else {
              let list = record.response.split(',');
              let html = '';
              list.map((item, index) => {
                html += '<p class="m-0" key="' + index + '">' + item + '</p>';
              })

              return <div  dangerouslySetInnerHTML ={{ __html: html}} />;
            }
          },
        },
        {
          key: "sync_status",
          text: "Sync Status",
          className: "sync_status ",
          sortable: false,
          cell: (record) => {
            return record.status_text && record.status_text === "Success" ? "Success" : "Failed";
          },
        },
        {
          key: "updated_at",
          text: "Sync Time",
          className: "updated_at",
          sortable: false,
          cell: (record) => {
            return record.updated_at ? Moment(record.updated_at).format(global.dateTimeFormat) : "";
          },
        },
        {
          key: "sync_name",
          text: "Sync Type",
          className: "sync_name",
          sortable: false,
          cell: (record) => {
            return record.sync_name ? record.sync_name : "";
          },
        },
        {
          key: "status_text",
          text: "Request/Response Xml",
          className: "status_text ",
          sortable: false,
          cell: (record) => { 
            let btn_class = record.push_listing_request_xml && record.push_listing_response_xml ? "fa fa-eye hand-cursor" : " ";
            return (
              <i className={btn_class} aria-hidden="true" onClick={() => record.push_listing_request_xml && record.push_listing_response_xml ? this.openXMlModal(record) : "" }></i>
            )
          },
        },  
      ]
    };
    this.publishListing = this.publishListing.bind(this);
    this.publishPricing = this.publishPricing.bind(this);
    this.publishDates = this.publishDates.bind(this);
  }

  async componentDidMount() {
    this.getData();
    this.setState({ publishData: true});
    if (this.props.listing_slug !== undefined) {
      var resData = {};
      var listingData = {};
      let res = await ListingService.getListing(this.props.listing_slug);

      if (global.successStatus.includes(res.status)) {
        listingData = res.data ? res.data : {};
        resData.brand_id = res.data.property ? res.data.property.brand_id : "";
        resData.theme = res.data.property ? res.data.property.theme : "";
        resData.property_seo_slug = res.data.property
          ? res.data.property.property_seo_slug
          : "";
        resData.listing_seo_slug = res.data ? res.data.listing_seo_slug : "";
        resData.listing_name = res.data.listing_name
          ? res.data.listing_name
          : "";
        resData.unit_number = res.data.unit ? res.data.unit.unit_number : "";
        resData.unit_name = res.data.unit ? res.data.unit.unit_name : "";
        resData.room_type = res.data.room_type
          ? res.data.room_type.room_type_name
          : "";
        resData.room_category_name = res.data.room_category
          ? res.data.room_category.room_category_name
          : "";
        
        resData.room_size = res.data.room_size ? res.data.room_size : "";
        resData.bedroom_lock = res.data.bedroom_lock
          ? res.data.bedroom_lock
          : "";
        resData.suit = res.data.suit ? res.data.suit : "";
        resData.host_photo = res.data.host_photo ? res.data.host_photo : "";
        resData.host_photo_url = res.data.host_photo_url
          ? res.data.host_photo_url
          : hostImg;
        resData.host_photo_thumb_url = res.data.host_photo_thumb_url
          ? res.data.host_photo_thumb_url
          : hostImg;

        resData.host_name = res.data.host_name ? res.data.host_name : "";
        resData.host_email = res.data.host_email ? res.data.host_email : "";
        resData.host_phone_number = res.data.host_phone_number
          ? res.data.host_phone_number
          : "";
        resData.host_bio = res.data.host_bio ? res.data.host_bio : "";
        resData.listing_type = res.data.listing_type
          ? res.data.listing_type
          : "";
        resData.bed_type = res.data.bed_type ? res.data.bed_type : "";
        resData.balcony = res.data.balcony ? res.data.balcony : "";
        resData.toilet = res.data.toilet ? res.data.toilet : "";
        resData.bathroom = res.data.bathroom ? res.data.bathroom : "";
        resData.kitchen = res.data.kitchen ? res.data.kitchen : "";
        resData.living_room = res.data.living_room ? res.data.living_room : "";
        resData.status_id = res.data.status_id === 1 ? "Active" : "Inactive";
        resData.bedroom =
          res.data &&
            res.data.unit &&
            res.data.unit.floor_plan &&
            res.data.unit.floor_plan.bedroom
            ? res.data.unit.floor_plan.bedroom
            : "";
        resData.bath =
          res.data &&
            res.data.unit &&
            res.data.unit.floor_plan &&
            res.data.unit.floor_plan.bath
            ? res.data.unit.floor_plan.bath
            : "";
        resData.maximum_number_of_guests =
          res.data.unit && res.data.unit.maximum_number_of_guests
            ? res.data.unit.maximum_number_of_guests
            : "";
        resData.custom_link = res.data.custom_link ? res.data.custom_link : "";
        resData.nightly_rate = res.data.nightly_rate
          ? parseFloat(res.data.nightly_rate)
            .toFixed(2)
            .replace(/\.00$/, "")
            .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";

        resData.from_date = res.data.from_date
          ? Moment(res.data.from_date, "MM/DD/YYYY").format(global.dateFormat)
          : "";
        resData.to_date = res.data.to_date
          ? Moment(res.data.to_date, "MM/DD/YYYY").format(global.dateFormat)
          : "";
        resData.listing_description = res.data.listing_description
          ? res.data.listing_description
          : "";
        resData.nightly_rate = res.data.nightly_rate
          ? res.data.nightly_rate
          : "";
        resData.security_deposite = res.data.security_deposite
          ? parseFloat(res.data.security_deposite)
            .toFixed(2)
            .replace(/\.00$/, "")
            .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.taxes = res.data.taxes
          ? parseFloat(res.data.taxes)
            .toFixed(2)
            .replace(/\.00$/, "")
            .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.additional_fee = res.data.additional_fee
          ? parseFloat(res.data.additional_fee)
            .toFixed(2)
            .replace(/\.00$/, "")
            .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.total_nightly_fee = res.data.total_nightly_fee
          ? parseFloat(res.data.total_nightly_fee)
            .toFixed(2)
            .replace(/\.00$/, "")
            .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.cleaning_fee = res.data.cleaning_fee
          ? parseFloat(res.data.cleaning_fee)
            .toFixed(2)
            .replace(/\.00$/, "")
            .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        // resData.total_oh_earning = res.data.total_oh_earning
        //   ? res.data.total_oh_earning
        //   : "";
        // resData.total_property_earning = res.data.total_property_earning
        //   ? res.data.total_property_earning
        //   : "";
        // resData.total_amount = res.data.total_amount
        //   ? res.data.total_amount
        //   : "";
        res.data.masterData.map((item) => {
          if (resData.listing_type === item.slug) {
            const renting_type = item.name;
            this.setState({ renting_type_name: renting_type });
          }
          if (resData.bed_type === item.slug) {
            const bed_type = item.name;
            this.setState({ bed_type_name: bed_type });
          }
          if (resData.balcony === item.slug) {
            const balcony = item.name;
            this.setState({ balcony_name: balcony });
          }
          if (resData.toilet === item.slug) {
            const toilet = item.name;
            this.setState({ toilet_name: toilet });
          }
          if (resData.bathroom === item.slug) {
            const bathroom = item.name;
            this.setState({ bathroom_name: bathroom });
          }
          if (resData.kitchen === item.slug) {
            const kitchen = item.name;
            this.setState({ kitchen_name: kitchen });
          }
          if (resData.living_room === item.slug) {
            const living_room = item.name;
            this.setState({ living_room_name: living_room });
          }
        });
        //for resident
        resData.first_name =
          res.data.residentUser && res.data.residentUser.first_name
            ? res.data.residentUser.first_name
            : "";
        resData.last_name =
          res.data.residentUser && res.data.residentUser.last_name
            ? res.data.residentUser.last_name
            : "";
        resData.email =
          res.data.residentUser && res.data.residentUser.email
            ? res.data.residentUser.email
            : "";
        resData.phone_number =
          res.data.residentUser && res.data.residentUser.phone_number
            ? res.data.residentUser.phone_number
            : "";
      }

      this.getPetPolicyData();
      this.getMulitImagesData();
      // this.getListingLevelAmenitiesData();
      // this.getPropertyLevelAmenitiesData();
      // this.getFloorPlanLevelAmenitiesData();
      // this.getUnitLevelAmenitiesData();
      this.getAllAmenitiesData();
      this.getFeesData();
      this.getTaxData();
      this.getSTRData();
      this.setState(resData);
      this.setState({ listingData: listingData });
      

      this.getSpecialPriceData();
      this.getUnavailableData();
    }
    this.setState({ publishData: false });
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    var resData = {};

    let res = await RentalService.getRentalActivity(
      "listing_slug=" +
      this.props.listing_slug +
      (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.activity_data_finalResult.data : [];
      // totalRecords = res.data ? res.data.total : 0
      totalRecords = res.data ? res.data.activity_data_finalResult.total : 0;

      resData.last_published_date_time = res.data.last_published_date_time ? res.data.last_published_date_time : ''
      resData.last_sync_date_time = res.data.last_sync_date_time ? res.data.last_sync_date_time : ''
      resData.last_sync_status = res.data.last_sync_status ? res.data.last_sync_status : ''
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
    this.setState(resData);
  }

  async getPetPolicyData() {
    //this.setState({ showSpinner: true, loading: true });
    var multiList = [];
    var multiListTotal = 0;

    let res = await PetPolicyService.getPetPolicy(
      this.props.property_slug + "/3"
    );
    if (global.successStatus.includes(res.status)) {
      multiList = res.data.petPolicyRestrictions
        ? res.data.petPolicyRestrictions
        : [];
      multiListTotal = multiList ? multiList.length : 0;
    }
    this.setState({
      multiList: multiList,
      multiListTotal: multiListTotal,
    });
    //this.setState({ showSpinner: false, loading: false });
  }

  async getMulitImagesData() {
    if (this.props.listing_slug !== undefined) {
      let inputData = {
        path: "listing_units",
        refTableId: 10,
        slug: this.props.listing_slug,
        mediaType: 1,
        property_slug: this.props.property_slug,
      };
      let res = await MultiImageService.getImages(inputData);

      var multiImageList = [];
      var multiImageListTotal = 0;
      if (global.successStatus.includes(res.status)) {
        multiImageList = res.data ? res.data : [];
        multiImageListTotal = multiImageList.length;
      }
      // const newMultiImageList =
      //   multiImageListTotal > 0
      //     ? multiImageList.map((opt, i) => ({
      //       src: opt.file_name,
      //       thumbnail: opt.mediumImg,
      //       caption: opt.description,
      //       tags: [{ value: <img src={zoomImg} alt="" />, key: i }],
      //     }))
      //     : [];
      multiImageList.map((opt, i) => {
        var orgFile = opt.file_name;
        var mediumImg = opt.medium_img;
        opt.file_name = mediumImg;
        opt.medium_img = mediumImg;
        multiImageList[i]["caption"] = opt.description ? opt.description : "";
        multiImageList[i]["src"] = orgFile;
        multiImageList[i]["thumbnail"] = mediumImg;
        // multiImageList[i]["thumbnailWidth"] = 1;
        // multiImageList[i]["thumbnailHeight"] = 1;
        // multiImageList[i]["rowHeight"] = 1;
        // multiImageList[i]["maxRows"] = 3;
        // multiImageList[i]["vwidth"] = 1;
        multiImageList[i]["main_image"] = orgFile;
        multiImageList[i]["tags"] = [
          {
            value: <img src={zoomImg} alt="" />,
            key: `zoom image ${i}`,
            title: `zoom image ${i}`,
          },
        ];
      });
      const newMultiImageList = multiImageList;
      this.setState({
        multiImageList: multiImageList,
        multiImageListTotal: multiImageListTotal,
        newMultiImageList: newMultiImageList,
      });
    }
  }

  openXMlModal(records) {
    this.setState({ xmlRoleModal: true });
    this.setState({ pushListingRequestXml: records.push_listing_request_xml, pushListingResponseXml:records.push_listing_response_xml});
  }

  closeXMlModal() {
    this.setState({ xmlRoleModal: false });
    this.setState({ pushListingRequestXml: "" ,pushListingResponseXml: ""});
  }

  copyText(sync_id){
    navigator.clipboard.writeText(sync_id);
    setTimeout(
      () =>
      this.setState({is_copied:false}),
      1000
    );
    setTimeout(
      () =>
      this.setState({is_copied:true}),
      1000
    );
    
  }

  /* get all amenities data */
  async getAllAmenitiesData() {
    var propertyAmenityList = [];

    var inputData = {
      slug: this.props.listing_slug
    }

    let res = await AmenityService.getAllAmenity(inputData);
    if (global.successStatus.includes(res.status)) {
      if (res.data && res.data.propertyAmenity && res.data.propertyAmenity.length > 0) {
        res.data.propertyAmenity.map((item, i) => {
          propertyAmenityList.push({ amenity_id: item.amenity_id, amenity_name: item.amenity_name });
        })
      }
    }

    this.setState({
      propertyAmenityList: propertyAmenityList
    });
  }

  // /* to get property amenities data */
  // async getListingLevelAmenitiesData() {
  //   this.setState({ showSpinner: true, loading: true });
  //   var listingAmenitiesList = [];
  //   var listingAmenitiesListTotal = 0;
  //   var selectedAmenities = [];
  //   let res = await AmenityService.getPropertyAmenities(
  //     this.props.listing_slug + "/2"
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     listingAmenitiesList = res.data ? res.data : [];
  //     listingAmenitiesListTotal = listingAmenitiesList
  //       ? listingAmenitiesList.length
  //       : 0;
  //   }
  //   listingAmenitiesList.forEach((opt) => {
  //     selectedAmenities.push(opt.slug);
  //   });

  //   this.setState({
  //     listingAmenitiesList: listingAmenitiesList,
  //     listingAmenitiesListTotal: listingAmenitiesListTotal,
  //   });
  //   this.setState({ showSpinner: false, loading: false });
  // }

  // /* to get property level amenities data */
  // async getPropertyLevelAmenitiesData() {
  //   var propertyLevelAmenitiesList = [];
  //   var propertyLevelAmenitiesListTotal = 0;
  //   let res = await AmenityService.getPropertyAmenities(
  //     this.props.property_slug + "/1"
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     propertyLevelAmenitiesList = res.data ? res.data : [];
  //     propertyLevelAmenitiesListTotal = propertyLevelAmenitiesList
  //       ? propertyLevelAmenitiesList.length
  //       : 0;
  //   }
  //   this.setState({
  //     propertyLevelAmenitiesList: propertyLevelAmenitiesList,
  //     propertyLevelAmenitiesListTotal: propertyLevelAmenitiesListTotal,
  //   });
  // }

  // /* to get floor plan level amenities data */
  // async getFloorPlanLevelAmenitiesData() {
  //   var floorPlanLevelAmenitiesList = [];
  //   var floorPlanLevelAmenitiesListTotal = 0;
  //   let res = await AmenityService.getPropertyAmenities(
  //     this.props.floor_plan_slug + "/3"
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     floorPlanLevelAmenitiesList = res.data ? res.data : [];
  //     floorPlanLevelAmenitiesListTotal = floorPlanLevelAmenitiesList
  //       ? floorPlanLevelAmenitiesList.length
  //       : 0;
  //   }
  //   this.setState({
  //     floorPlanLevelAmenitiesList: floorPlanLevelAmenitiesList,
  //     floorPlanLevelAmenitiesListTotal: floorPlanLevelAmenitiesListTotal,
  //   });
  // }

  // /* to get unit level amenities data */
  // async getUnitLevelAmenitiesData() {
  //   var unitLevelAmenitiesList = [];
  //   var unitLevelAmenitiesListTotal = 0;
  //   let res = await AmenityService.getPropertyAmenities(
  //     this.props.unit_slug + "/4"
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     unitLevelAmenitiesList = res.data ? res.data : [];
  //     unitLevelAmenitiesListTotal = unitLevelAmenitiesList
  //       ? unitLevelAmenitiesList.length
  //       : 0;
  //   }
  //   this.setState({
  //     unitLevelAmenitiesList: unitLevelAmenitiesList,
  //     unitLevelAmenitiesListTotal: unitLevelAmenitiesListTotal,
  //   });
  // }

  async getFeesData() {
    this.setState({ showSpinner: true, loading: true });
    var costList = [];
    var costListTotal = 0;

    let res = await PropertyCostService.getAllCosts(
      this.props.listing_slug + "/5"
    );
    if (global.successStatus.includes(res.status)) {
      costList = res.data ? res.data : "";
      costListTotal = costList ? costList.length : 0;
    }
    this.setState({ costList: costList, costListTotal: costListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  async getTaxData() {
    this.setState({ showSpinner: true, loading: true });
    var taxList = [];
    var taxListTotal = 0;
    let res = await TaxService.getTax(
      "property_slug=" + this.props.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      taxList = res.data ? res.data.data : [];
      taxListTotal = taxList ? taxList.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      taxListTotal: taxListTotal,
      taxList: taxList,
    });
  }

  async getSTRData() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};

    let res = await PropertyCostService.getSTRFees(
      "slug=" + this.props.property_slug + "&ref_table_id=3"
    );
    if (global.successStatus.includes(res.status)) {
      resData.pet_fee_for_guest = res.data ? res.data.fee_value : "";
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  async getSpecialPriceData() {
    var priceList = [];
    let res = await ListingService.getListingSpecialPriceList("listing_slug=" + this.props.listing_slug);

    if (global.successStatus.includes(res.status)) {
      priceList = res.data ? res.data : [];
    }
    this.setState({
      priceList: priceList,
    });
  }

  async getUnavailableData() {
    var dateList = [];
    let res = await ListingService.getListingUnavailableDateList("listing_slug=" + this.props.listing_slug);

    if (global.successStatus.includes(res.status)) {
      dateList = res.data ? res.data : [];
    }

    this.setState({
      dateList: dateList,
    });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = 'asc';
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      // queryString += "&";
      // this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  async publishListing() {
    this.setState({ publishData: true });
    //errors will be shown in table so commenting
    // this.setState({ errorList: [] });
    let inputData = {
      //first tab data
      listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
      property_slug: this.props.property_slug ? this.props.property_slug : "",
      unit_slug: this.props.unit_slug ? this.props.unit_slug : "",
      floor_plan_slug: this.props.floor_plan_slug ? this.props.floor_plan_slug : "",
      listing_data: this.state.listingData ? this.state.listingData : {},
      //for host
      host_name: this.state.host_name ? this.state.host_name : "",
      host_email: this.state.host_email ? this.state.host_email : "",
      host_phone_number: this.state.host_phone_number
        ? this.state.host_phone_number
        : "",
      host_bio: this.state.host_bio ? this.state.host_bio : "",
      host_photo: this.state.host_photo ? this.state.host_photo : "",
      //for images
      images: this.state.newMultiImageList,
      //for all amenities
      listing_amenities: this.state.propertyAmenityList ? this.state.propertyAmenityList : [],
      //for amenities
      // listing_amenities: this.state.listingAmenitiesList
      //   ? this.state.listingAmenitiesList
      //   : [],
      // property_amenities: this.state.propertyLevelAmenitiesList
      //   ? this.state.propertyLevelAmenitiesList
      //   : [],
      // floorplan_amenities: this.state.floorPlanLevelAmenitiesList
      //   ? this.state.floorPlanLevelAmenitiesList
      //   : [],
      // unit_amenities: this.state.unitLevelAmenitiesList
      //   ? this.state.unitLevelAmenitiesList
      //   : [],
      //fees
      fees: this.state.costList ? this.state.costList : [],
      //taxes
      taxes: this.state.taxList ? this.state.taxList : [],
      //for price
      nightly_rate: this.state.nightly_rate ? this.state.nightly_rate : "",
      total_nightly_fee: this.state.total_nightly_fee
        ? this.state.total_nightly_fee
        : "",
      cleaning_fee: this.state.cleaning_fee ? this.state.cleaning_fee : "",
    };
    let res = await ListingService.publishListing(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
        //errors will be shown in table so commenting

        // errorList:
        //   res.data && res.data.result && res.data.result.error_list
        //     ? res.data.result.error_list
        //     : [],
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );

    }
    this.setState({ publishData: false });
    this.getData();
  }

  async publishPricing(){
    this.setState({ publishPrice: true });

    let inputData = {
      listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
      property_slug: this.props.property_slug ? this.props.property_slug : "",
      pricing_data: this.state.priceList ? this.state.priceList : [],
    };
    let res = await ListingService.publishPricing(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
    }
    this.getData();
    this.setState({ publishPrice: false });
  }

  async publishDates(){
    this.setState({ publishDate: true });

    let inputData = {
      listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
      property_slug: this.props.property_slug ? this.props.property_slug : "",
      pricing_data: this.state.dateList ? this.state.dateList : [],
    };
    let res = await ListingService.publishAvailability(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
    }
    this.getData();
    this.setState({ publishDate: false });
  }

  render() {
    return (
      <>
        <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
          <div className="d-flex flex-wrap justify-content-end bothBtn swiperBtns publish_btn">
            {/* <button
              className="btn-success-outline-small"
              onClick={this.publishListing}
            >
              {this.state.publishData ? global.loader : "Sync Now"}
            </button> */}
          </div>
          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          <div className="row mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-0">
            {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Last Published Date & Time</p>
              <p className="media-body">
                {this.state.last_published_date_time ? Moment(this.state.last_published_date_time).format(global.dateTimeFormat) : ""}
              </p>
            </div> */}
            <div className="col-6">
              <p className="az-profile-name-text pl-0">Last Sync Date & Time</p>
              <p className="media-body">
                {this.state.last_published_date_time ? Moment(this.state.last_published_date_time).format(global.dateTimeFormat) : ""}
              </p>
            </div>
            {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Last Sync Status</p>
              <p className="media-body">
                {this.state.last_sync_status ? this.state.last_sync_status : ""}
              </p>
            </div> */}
            <div className="col-6 pd-lg-r-45 pd-xs-r-15">
            {global.userPermissions.checkPermission('single-unit-update') &&
              <button
                className="btn-success-outline-small float-right syncBtn"
                onClick={this.publishListing}
              >
                {this.state.publishData ? global.loader : "Sync Now"}
              </button>
            }
              {/* <button
                className="btn-success-outline-small float-right syncBtn button-width mr-3"
                onClick={this.publishDates}
              >
                {this.state.publishDate ? global.loader : "Push Resident stay"}
              </button>
              <button
                className="btn-success-outline-small float-right syncBtn button-width mr-3"
                onClick={this.publishPricing}
              >
                {this.state.publishPrice ? global.loader : "Push Custom Pricing"}
              </button> */}
           
            </div>
          </div>
          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
            <h3 className="mg-l-5 background-head">Sync History</h3>
          </div>
          <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block resident-payout-table">
            <div className="pd-lg-l-0 pd-xs-l-15 syn_tbl_scroll">
            <div className="scroll-report-table">
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2 propertytable syncTable Sync History list_syn_tbl"
                config={this.state.total_record > global.page_size ? this.state.config : this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                // loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
              </div>
              <Modal 
              show={this.state.xmlRoleModal} 
              className="showquickreplyModal model-lg_custome xml_detail_modal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              size="lg"
              >
              <Modal.Body className="">
              <div className="header">
              <h3 className="xml_modal_heading">Details</h3>

              <span
                className="welcomeClose"
                onClick={() => this.closeXMlModal()}
              >
                {global.closee}
              </span>
            </div>
              <div className="listSetction xml_cnt_popup">
              <h4>Request</h4>
              <p>
              {this.state.pushListingRequestXml !== undefined && this.state.pushListingRequestXml !== null ? this.state.pushListingRequestXml : "Data Not Found"}
              </p>
              <h4>Response</h4>
              <p>
              {this.state.pushListingResponseXml !== undefined  && this.state.pushListingResponseXml !== null ? this.state.pushListingResponseXml : "Data Not Found"}
              </p>
              {/* <div className="d-flex justify-content-center mt-3 row w-100">
                <button
                  type="button"
                  className="btn btn-outline-light btn-block"
                  onClick={() => this.closeXMlModal()}
                >
                  Cancel
                </button>
              </div> */}
            </div>
          </Modal.Body>
        </Modal>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ListingRentalActivity;