////////////////////////////////////////////////////////////
//     							                                      //
//  Program: LeasingForm.jsx                              //
//  Application: Leasing                                  //
//  Option: add/update Leasing form                       //
//  Developer: NP				                                  //
//  Date: 2022-08-18                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import LeasingService from "../../services/LeasingService";
import ResidentService from "../../services/ResidentService";
import CountryService from "../../services/CountryService";
import { Container, Form, Tab, Nav } from "react-bootstrap";
import UnitsService from "../../services/UnitsService";
import Alerts from "../common/Alerts";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import LeasingFees from "./LeasingFees";
import hostImg from "./../../assets/images/profile-nogender.png";
import LeasingDocuments from "./LeasingDocuments";
import LeasingSidebar from "../common/LeasingSidebar";
import PhoneInput from "react-phone-input-2";
import GooglePlaces from "../common/GooglePlaces";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";
// import CityService from "../../services/CityService";

export class LeasingForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      isSubmit: false,
      viewMode: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      errors: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      confirm_back: 0,
      listing_slug: this.props.match.params.slug,
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : this.props.match.params.outsidePropertySlug,
      slug: "",
      imageUrl: hostImg,
      status_id: false,
      pathname: window.location.pathname,
      records: [],
      activeClass: "info",
      isRefresh: false,
      roll_over: false,
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      // countryList: [],
      // newCountryList: [],
      // selectedCountryList: [],
      // countyList: [],
      // newCountyList: [],
      // selectedCountyList: [],
      // stateList: [],
      // newStateList: [],
      // selectedStateList: [],
      // cityList: [],
      // newCityList: [],
      // selectedCityList: [],
      city_name: "",
      state_name: "",
      county_name: "",
      country_name: "",
      statusList: [
        { label: "Active", value: 1 },
        { label: "Draft", value: 2 },
        { label: "Expired", value: 3 },
      ],
      selectedStatusList: [],
      typeList: [
        { key: 1, value: 1, label: "Month to Month" },
        { key: 2, value: 2, label: "Fixed" },
      ],
      newTypeList: [],
      selectedTypeList: [],
      monthly_rent: "",
      deposit: "",
      lease_signed_date: "",
      lease_start_date: "",
      lease_end_date: "",
      rent_start_date: "",
      disabled_dates: [],
      maxCheckoutDate: "",
    };
    this.changeView = this.changeView.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeView = this.changeView.bind(this);
    this.getChildData = this.getChildData.bind(this);

    this.roomImage = React.createRef();
    this.initialState = this.state;
    this.defaultData = {};
    this.saveOrUpdateLeasingData = this.saveOrUpdateLeasingData.bind(this);
    // this.changeStatus = this.changeStatus.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    // this.getTabData = this.getTabData.bind(this);
    this.getConfirmStatus = this.getConfirmStatus.bind(this);
    this.getListingAvailableDate = this.getListingAvailableDate.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.getListingAvailableDateForSameSlug =
      this.getListingAvailableDateForSameSlug.bind(this);
      this.removePhoto = this.removePhoto.bind(this);
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "listingPreview") {
      this.setState({ isRefresh: true });
    } else {
      this.setState({ isRefresh: false });
    }
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }

  componentDidMount() {
    this.setState({ confirm_back: 0, checkListingType: 0 });
    this.setState({ showSpinner: true, loading: true });
    if (this.state.listing_slug !== undefined) {
      this.getInfoData(); //api call in only update form
    }
    // this.getCityData();
    this.getUnitData();
    this.setState({ showSpinner: false, loading: false });
    //api call in add/update both form
  }

  async getChildData(unit, street1, city, state, county, country, zipcode) {
    this.setState({ street_1: unit });
    this.setState({ street_2: street1 });
    this.setState({ city_name: city });
    this.setState({ state_name: state });
    this.setState({ county_name: county });
    this.setState({ country_name: country });
    this.setState({ zipcode: zipcode });
    //api call for check location
    let inputData = {
      place_country_name: country ? country : "",
      place_state_name: state ? state : "",
      place_county_name: county ? county : "",
      place_city_name: city ? city : "",
    };

    // if(state && country){
    let res = await CountryService.checkLocation(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        country_id: res.data.countryId,
        state_id: res.data.countryId,
        county_id: res.data.countyId,
        city_id: res.data.cityId,
      });
    }
    // }
  }

  // getTabData(slug) {
  //   this.setState({ listingSlug: slug });
  // }

  async getInfoData(slug) {
    var resData = {};
    let res = await LeasingService.getLeasing(
      slug ? slug : this.state.listing_slug
    );

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.unit_slug = res.data.unit ? res.data.unit.slug : "";
      var selectedUnitList = [];
      selectedUnitList = res.data.unit
        ? [
            {
              value: res.data.unit ? res.data.unit.slug : "",
              label:
                res.data.unit && res.data.unit.unit_name
                  ? res.data.unit.unit_name
                  : res.data.unit.unit_number,
              // label:
              //   res.data.total_tower_count > 1
              //     ? res.data.unit.unit_number +
              //       (res.data.unit.tower
              //         ? " (" + res.data.unit.tower.aka_name + ")"
              //         : "")
              //     : res.data.unit.unit_number,
            },
          ]
        : "";

      var selectedTypeList = [];
      selectedTypeList =
        res.data.type === 1
          ? [{ key: 1, value: 1, label: "Month to Month" }]
          : [{ key: 2, value: 2, label: "Fixed" }];

      resData.lease_start_date = res.data.lease_start_date
        ? Moment(res.data.lease_start_date, "MM-DD-YYYY").toDate("MM-DD-YYYY")
        : null;
      resData.lease_end_date = res.data.lease_end_date
        ? Moment(res.data.lease_end_date, "MM-DD-YYYY").toDate("MM-DD-YYYY")
        : null;
      resData.lease_signed_date = res.data.lease_signed_date
        ? Moment(res.data.lease_signed_date, "MM-DD-YYYY").toDate("MM-DD-YYYY")
        : null;
      resData.rent_start_date = res.data.rent_start_date
        ? Moment(res.data.rent_start_date, "MM-DD-YYYY").toDate("MM-DD-YYYY")
        : null;

      resData.type = res.data.type ? res.data.type : "";
      resData.roll_over = res.data.roll_over === 1 ? true : false;
      resData.monthly_rent = res.data.monthly_rent
        ? parseFloat(res.data.monthly_rent)
            .toFixed(2)
            .replace(/\.00$/, "")
            .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
        : "";
      resData.deposit = res.data.deposit
        ? parseFloat(res.data.deposit)
            .toFixed(2)
            .replace(/\.00$/, "")
            .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
        : "";

      resData.resident_photo = res.data.resident_user
        ? res.data.resident_user.profile_thumb
        : "";
      resData.imageUrl = res.data.resident_user
        ? res.data.resident_user.profile_thumb_url
        : "";
      resData.first_name = res.data.resident_user
        ? res.data.resident_user.first_name
        : "";
      resData.last_name = res.data.resident_user
        ? res.data.resident_user.last_name
        : "";
      resData.email = res.data.resident_user
        ? res.data.resident_user.email
        : "";
      resData.phone_number = res.data.resident_user
        ? res.data.resident_user.phone_number
        : "";
      resData.street_1 = res.data.resident_user
        ? res.data.resident_user.street_1
        : "";
      resData.street_2 = res.data.resident_user
        ? res.data.resident_user.street_2
        : "";
      resData.zipcode = res.data.resident_user
        ? res.data.resident_user.zip_code
        : "";

      // resData.country_id = res.data.resident_user && res.data.resident_user.country ? res.data.resident_user.country.slug : "";
      // resData.county_id = res.data.resident_user && res.data.resident_user.county ? res.data.resident_user.county.slug : "";
      // resData.state_id = res.data.resident_user && res.data.resident_user.state ? res.data.resident_user.state.slug : "";
      // resData.city_id = res.data.resident_user && res.data.resident_user.city ? res.data.resident_user.city.slug : "";

      resData.country_id =
        res.data.resident_user && res.data.resident_user.country_id
          ? res.data.resident_user.country_id
          : "";
      resData.county_id =
        res.data.resident_user && res.data.resident_user.county_id
          ? res.data.resident_user.county_id
          : "";
      resData.state_id =
        res.data.resident_user && res.data.resident_user.state_id
          ? res.data.resident_user.state_id
          : "";
      resData.city_id =
        res.data.resident_user && res.data.resident_user.city_id
          ? res.data.resident_user.city_id
          : "";

      resData.country_name =
        res.data.resident_user && res.data.resident_user.country
          ? res.data.resident_user.country.country_name
          : "";
      resData.county_name =
        res.data.resident_user && res.data.resident_user.county
          ? res.data.resident_user.county.county_name
          : "";
      resData.state_name =
        res.data.resident_user && res.data.resident_user.state
          ? res.data.resident_user.state.state_name
          : "";
      resData.city_name =
        res.data.resident_user && res.data.resident_user.city
          ? res.data.resident_user.city.city_name
          : "";

      // var selectedCityList = [];
      //   selectedCityList = res.data.resident_user.city ? ([
      //     {
      //       value: res.data.resident_user.city.slug,
      //       label: res.data.resident_user.city.city_name,
      //     },
      //   ]) : [];
      //   var selectedStateList = [];
      //   selectedStateList = res.data.resident_user.state ? ([
      //     {
      //       value: res.data.resident_user.state.slug,
      //       label: res.data.resident_user.state.state_name,
      //     },
      //   ]) : [];
      //   var selectedCountyList = [];
      //   selectedCountyList = res.data.resident_user.county ? ([
      //     {
      //       value: res.data.resident_user.county.slug,
      //       label: res.data.resident_user.county.county_name,
      //     },
      //   ]) : [];
      //   var selectedCountryList = [];
      //   selectedCountryList = res.data.resident_user.country ? ([
      //     {
      //       value: res.data.resident_user.country.slug,
      //       label: res.data.resident_user.country.country_name,
      //     },
      //   ]) : [];

      resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
      resData.status_id = res.data.status_id === 1 ? true : false;

      var selectedStatusList = [];
      selectedStatusList =
        res.data.status_id === 1
          ? [
              {
                value: 1,
                label: "Active",
              },
            ]
          : res.data.status_id === 2
          ? [
              {
                value: 2,
                label: "Draft",
              },
            ]
          : [
              {
                value: 3,
                label: "Expired",
              },
            ];
      this.getListingAvailableDateForSameSlug(
        resData.unit_slug,
        this.state.listing_slug
      );
      this.getReservationMaxDate(
        resData.unit_slug,
        Moment(resData.lease_start_date, "YYYY-MM_DD").format("YYYY-MM-DD"),
        this.state.listing_slug
      );
    }
    this.setState(resData);
    // this.setState({
    //   selectedCityList: selectedCityList,
    //   selectedStateList: selectedStateList,
    //   selectedCountyList: selectedCountyList,
    //   selectedCountryList: selectedCountryList,
    // });
    this.setState({
      selectedUnitList: selectedUnitList,
      selectedTypeList: selectedTypeList,
      selectedStatusList: selectedStatusList,
    });
    this.defaultData = resData;
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to check unit available or not for same reservation slug  */
  async getListingAvailableDateForSameSlug(slug, slug2) {
    this.setState({ showSpinner: true, loading: true });
    var checkDate = {};
    var disabled_dates = [];
    let res = await LeasingService.checkReservationAvailabiltyForSameSlug(
      slug,
      slug2
    );
    if (global.successStatus.includes(res.status)) {
      checkDate = res.data ? res.data : {};
    }

    checkDate.map((item, index) => {
      checkDate[index].lease_start_date = Moment(item.lease_start_date).toDate(
        "dd/MM/YY"
      );
      checkDate[index].lease_end_date = Moment(item.lease_end_date).toDate(
        "dd/MM/YY"
      );
      var startDate = Moment(item.lease_start_date).subtract(1, "days");
      var timeEnd = Moment(item.lease_end_date);
      var diff = timeEnd.diff(startDate);
      var diff_days = Moment.duration(diff)._data.days;
      var i = 0;
      for (i = 0; i < diff_days; i++) {
        disabled_dates.push({
          dt: startDate.add(1, "days").toDate("dd/MM/YY"),
        });
      }
    });
    this.setState({ dateFrom: checkDate });
    this.setState({ disabled_dates: disabled_dates });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to check lisitng available or not  */
  async getListingAvailableDate(slug) {
    this.setState({ showSpinner: true, loading: true });
    var checkDate = {};
    var disabled_dates = [];
    let res = await LeasingService.checkReservationAvailabilty(slug);
    if (global.successStatus.includes(res.status)) {
      checkDate = res.data ? res.data : {};
    }

    checkDate.map((item, index) => {
      checkDate[index].lease_start_date = Moment(item.lease_start_date).toDate(
        "dd/MM/YY"
      );
      checkDate[index].lease_end_date = Moment(item.lease_end_date).toDate(
        "dd/MM/YY"
      );
      var startDate = Moment(item.lease_start_date).subtract(1, "days");
      var timeEnd = Moment(item.lease_end_date);
      var diff = timeEnd.diff(startDate);
      var diff_days = Moment.duration(diff)._data.days;
      var i = 0;
      for (i = 0; i < diff_days; i++) {
        disabled_dates.push({
          dt: startDate.add(1, "days").toDate("dd/MM/YY"),
        });
      }
    });
    this.setState({ dateFrom: checkDate });
    this.setState({ disabled_dates: disabled_dates });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to check lisitng available or not  */
  async getReservationMaxDate(slug, date, leaseSlug) {
    var checkDateMax = [];
    let res = await LeasingService.getReservationNextCheckInDate(
      slug,
      date,
      leaseSlug ? leaseSlug : ""
    );

    if (global.successStatus.includes(res.status)) {
      checkDateMax.set_max_date = res.data ? res.data.set_max_date : [];

      var maxCheckOutDate = checkDateMax.set_max_date
        ? Moment(checkDateMax.set_max_date).toDate("dd/MM/YY")
        : "";
    }

    this.setState({ maxCheckoutDate: maxCheckOutDate });
  }

  // async getCityData(stateValue, countyValue, queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var cityList = [];
  //   var cityListTotal = 0;
  //   let res = await CityService.getCity(
  //     "is_dropdown=1&state_slug=" + stateValue + "&county_slug=" + countyValue,
  //     queryString ? "&" + queryString : ""
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     cityList = res.data ? res.data : [];
  //     cityListTotal = cityList ? cityList.length : 0;
  //   }
  //   const newCityList = cityListTotal > 0 ? cityList.map(({ slug, city_name }) => ({
  //     value: slug,
  //     label: city_name,
  //   })) : [];
  //   this.setState({
  //     cityList: cityList,
  //     newCityList: newCityList,
  //     cityListTotal: cityListTotal
  //   });
  //   this.setState({ showSpinner: false, loading: false });
  // }

  // async getAllRealetedData(slug, queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var resData = {};
  //   let res = await CityService.getDataByCitySlug(
  //     slug,
  //     queryString ? "&" + queryString : ""
  //   );

  //   if (global.successStatus.includes(res.status)) {
  //     // resData = res.data ? res.data : {};
  //     resData.country_id = res.data.countryData
  //       ? res.data.countryData.country.slug
  //       : "";
  //     resData.country_name = res.data.countryData ? res.data.countryData.country.country_name : "";
  //     var selectedCountryList = [];
  //     selectedCountryList = res.data.countryData.country ? ([
  //       {
  //         value: res.data.countryData.country
  //           ? res.data.countryData.country.slug
  //           : "",
  //         label: res.data.countryData.country
  //           ? res.data.countryData.country.country_name
  //           : "",
  //       },
  //     ]) : "";
  //     resData.county_id = res.data.county ? res.data.county.slug : "";
  //     var selectedCountyList = [];
  //     selectedCountyList = res.data.county ? ([
  //       {
  //         value: res.data.county.slug ? res.data.county.slug : "",
  //         label: res.data.county.county_name
  //           ? res.data.county.county_name
  //           : "",
  //       },
  //     ])
  //       : "";
  //     resData.state_id = res.data.state ? res.data.state.slug : "";
  //     resData.state_name = res.data.state ? res.data.state.state_name : "";
  //     var selectedStateList = [];
  //     selectedStateList = res.data.state ? ([
  //       {
  //         value: res.data.state.slug ? res.data.state.slug : "",
  //         label: res.data.state.state_name ? res.data.state.state_name : "",
  //       },
  //     ]) : "";
  //   }
  //   this.setState(resData);
  //   this.setState({
  //     selectedStateList: selectedStateList,
  //     selectedCountyList: selectedCountyList,
  //     selectedCountryList: selectedCountryList,
  //   });
  //   this.setState({ showSpinner: false, loading: false });
  // }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "profile_thumb",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
      );
      fileData.append("mediaType", "1");
      let res = await LeasingService.uploadResidentImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          resident_photo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  /* to get guest data using phone number */
  async getResidentDataByPhone(phoneNumber, queryString = "") {
    var resData = {};

    let res = await ResidentService.getResidentByPhone(
      phoneNumber ? phoneNumber : "-",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      resData.first_name = res.data ? res.data.first_name : "";
      resData.last_name = res.data ? res.data.last_name : "";
      // resData.email = res.data ? res.data.email : "";
      resData.phone_number = res.data ? res.data.phone_number : "";
      resData.resident_photo = res.data ? res.data.resident_photo : "";
      resData.imageUrl = res.data ? res.data.resident_photo_thumb_url : "";
      resData.street_1 = res.data ? res.data.street_1 : "";
      resData.street_2 = res.data ? res.data.street_2 : "";
      resData.zipcode = res.data.zipcode ? res.data.zipcode : "";

      // resData.country_id = res.data.country ? res.data.country.slug : "";
      // resData.county_id = res.data.county ? res.data.county.slug : "";
      // resData.state_id = res.data.state ? res.data.state.slug : "";
      // resData.city_id = res.data.city ? res.data.city.slug : "";

      resData.country_name = res.data.country_name ? res.data.country_name : "";
      resData.county_name = res.data.county_name ? res.data.county_name : "";
      resData.state_name = res.data.state_name ? res.data.state_name : "";
      resData.city_name = res.data.city_name ? res.data.city_name : "";

      var selectedCityList = [];
      selectedCityList = res.data.city
        ? [
            {
              value: res.data.city.slug,
              label: res.data.city.city_name,
            },
          ]
        : "";
      var selectedStateList = [];
      selectedStateList = res.data.state
        ? [
            {
              value: res.data.state.slug,
              label: res.data.state.state_name,
            },
          ]
        : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county
        ? [
            {
              value: res.data.county.slug,
              label: res.data.county.county_name,
            },
          ]
        : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.country
        ? [
            {
              value: res.data.country.slug,
              label: res.data.country.country_name,
            },
          ]
        : "";

      // resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
      // resData.status_id = res.data.status_id === 1 ? true : false;
    }
    if (resData.first_name) {
      this.setState(resData);
      this.setState({
        selectedCityList: selectedCityList,
        selectedStateList: selectedStateList,
        selectedCountyList: selectedCountyList,
        selectedCountryList: selectedCountryList,
      });
    }
    // else{
    //   this.setState({first_name:"",last_name:"",phone_number:"",resident_photo_thumb_url:"",imageUrl:""});
    // }
  }

  async getAvailableFromDateByUnit(unit_slug_value) {
    var resData = {};
    let res = await UnitsService.getUnits(unit_slug_value);
    if (global.successStatus.includes(res.status)) {
      // resData.available_from = res.data.available_from
      //   ? Moment(res.data.available_from).toDate("MM/dd/YY")
      //   : null;
      resData.lease_start_date = res.data.available_from
        ? Moment(res.data.available_from).toDate("MM/dd/YY")
        : null;
    }
    this.setState(resData);
  }

  saveOrUpdateLeasingData = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
        type: this.state.type ? this.state.type : "",
        lease_signed_date: this.state.lease_signed_date
          ? Moment(this.state.lease_signed_date, "YYYY-MM-DD").format(
              "YYYY-MM-DD"
            )
          : null,
        lease_start_date: this.state.lease_start_date
          ? Moment(this.state.lease_start_date, "YYYY-MM-DD").format(
              "YYYY-MM-DD"
            )
          : null,
        lease_end_date: this.state.lease_end_date
          ? Moment(this.state.lease_end_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        rent_start_date: this.state.rent_start_date
          ? Moment(this.state.rent_start_date, "YYYY-MM-DD").format(
              "YYYY-MM-DD"
            )
          : null,
        roll_over: this.state.roll_over === true ? 1 : 0,
        monthly_rent: this.state.monthly_rent ? this.state.monthly_rent : "",
        deposit: this.state.deposit ? this.state.deposit : "",

        resident_photo: this.state.resident_photo
          ? this.state.resident_photo
          : "",
        first_name: this.state.first_name ? this.state.first_name : "",
        last_name: this.state.last_name ? this.state.last_name : "",
        email: this.state.email ? this.state.email : "",
        phone_number: this.state.phone_number ? this.state.phone_number : "",
        dial_code: this.state.dial_code ? this.state.dial_code : "",
        street_1: this.state.street_1 ? this.state.street_1 : "",
        street_2: this.state.street_2 ? this.state.street_2 : "",
        zipcode: this.state.zipcode ? this.state.zipcode : "",

        country_id: this.state.country_id ? this.state.country_id : "",
        state_id: this.state.state_id ? this.state.state_id : "",
        county_id: this.state.county_id ? this.state.county_id : "",
        city_id: this.state.city_id ? this.state.city_id : "",

        sort_order: this.state.sort_order ? this.state.sort_order : "",
        // status_id: this.state.status_id,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.listing_slug !== undefined) {
        let res = await LeasingService.updateLeasing(
          this.state.listing_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          // setTimeout(
          //   () => document.getElementById("lisitngImages").click(),
          //   global.redirect_time
          // );
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/leasing/" +
                      this.state.listing_slug +
                      "/info"
                  : "/leasing/view/" +
                      this.state.property_slug +
                      "/" +
                      this.state.listing_slug
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      } else {
        let res = await LeasingService.createLeasing(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          var last_insert_slug = res.data.last_insert_slug;
          this.setState({ tabWizard: 1, listing_slug: last_insert_slug });
          // setTimeout(
          //   () => document.getElementById("lisitngImages").click(),
          //   global.redirect_time
          // );
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/leasing/" +
                      this.state.listing_slug +
                      "/info"
                  : "/leasing"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };
  onChangePhone(value, country, e, formattedValue) {
    //console.log(value, country, e, formattedValue);
    this.setState({ phone_number: value });
    this.setState({ dial_code: country.dialCode });
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    if (event.target.name === "email") {
      if (value !== this.state.email) {
        clearTimeout(this.state.filter_time);
        this.setState({
          filter_time: setTimeout(
            () => this.getResidentDataByPhone(value),
            global.filter_time
          ),
          filter_value: value,
        });
      }
    }
    if (event.target.name === "lease_start_date") {
      let lease_start_date_val = this.state.input.lease_start_date;
      let startDate = Moment(lease_start_date_val).format("YYYY-MM-DD");
      this.getReservationMaxDate(
        this.state.unit_slug,
        startDate,
        this.state.listing_slug
      );
      this.setState({ lease_end_date: "" });
    }
    this.setState({ [event.target.name]: value, input: input });
    if (event.target.name === "monthly_rent") {
      let monthly_rent =
        event.target.name === "monthly_rent"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.monthly_rent
          ? this.state.monthly_rent.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
      this.setState({ monthly_rent: monthly_rent });
    }
    if (event.target.name === "deposit") {
      let deposit =
        event.target.name === "deposit"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.deposit
          ? this.state.deposit.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
      this.setState({ deposit: deposit });
    }

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    if (value !== null) {
      this.getAvailableFromDateByUnit(unit_slug_value);
      if (this.state.listing_slug !== undefined) {
        this.getListingAvailableDateForSameSlug(
          unit_slug_value,
          this.state.listing_slug
        );
      } else {
        this.getListingAvailableDate(unit_slug_value);
      }
    }

    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
      lease_start_date: "",
      lease_end_date: "",
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeTypeList(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedTypeList: value,
      type: type,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCityList(value) {
    let city_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_id.push(item.value);
      });
    }
    if (value !== null) {
      this.getAllRealetedData(value.value);
    }
    const city_id_value = value !== null ? value.value : [];
    this.setState({
      selectedCityList: value,
      city_id: city_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeStatusList(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    const status_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStatusList: value,
      status_id: status_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  // changeStatus() {
  //   this.setState({ status_id: !this.state.status_id });
  //   this.validator.hideMessages();
  //   if (!this.state.status_id) {
  //     this.validator.showMessages();
  //   } else {
  //     this.validator.showMessageFor("room_name");
  //   }
  // }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Leases";
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ confirm_back: 0 });
      this.setState({ viewMode: 0 });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.setState({
        errors: "",
      });
      this.validator.hideMessages();
    } else {
      this.setState({ confirm_back: 0 });
      this.setState({ viewMode: 1 });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.setState({
        errors: "",
      });
      this.validator.hideMessages();
    }
  }

  removePhoto(){
    this.setState({resident_photo:"",imageUrl:""});
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        <Container className="innter-container pl-0">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <LeasingSidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyLeasingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-10 right"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns info_box_model">
                    <h3>{this.getTitle()}</h3>
                  </div>
                </div>
                <div className="col-md-12 pd-lg-r-15 pd-xs-r-0">
                  <div className="scrolling-carousel pd-l-0">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="info"
                      onSelect={(e) => this.getActiveClass(e)}
                      eventKey={this.state.activeClass}
                    >
                      <ScrollingCarousel
                        rightArrow={true}
                        leftArrow={true}
                        className="swipesection"
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="info"
                            id="infoForm"
                            className={
                              this.state.activeClass === "info" ? "active" : ""
                            }
                          >
                            1. Info
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={
                              this.state.listing_slug !== undefined
                                ? "listingPrice"
                                : "info"
                            }
                            id={
                              this.state.listing_slug !== undefined
                                ? "listingPrice"
                                : "info"
                            }
                            // className={(this.state.activeClass === "listingPrice" && this.state.listing_slug !== undefined)
                            //   ? "active"
                            //   : ""
                            // }
                            className={
                              this.state.activeClass === "listingPrice"
                                ? "active"
                                : ""
                            }
                            // disabled={
                            //   this.state.listing_slug !== undefined ? false : true
                            // }
                            onClick={() => {
                              if (
                                this.state.listing_slug !== undefined &&
                                this.state.confirm_back === 1
                              )
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  return true;
                                else
                                  this.props.history.push(
                                    this.props.match.params.propertySlug !==
                                      undefined
                                      ? "/properties/view/" +
                                          this.state.property_slug +
                                          "/leasing/" +
                                          this.state.listing_slug +
                                          "/info"
                                      : "/leasing/view/" +
                                          this.state.property_slug +
                                          "/" +
                                          this.state.listing_slug
                                  );
                              else
                                document
                                  .querySelector("#flashButton")
                                  .classList.add("flashSavebtn1");
                              // if (this.state.listing_slug === undefined) (
                              //   document.querySelector('#flashButton').classList.add('flashSavebtn1')
                              // )
                            }}
                          >
                            2. Price
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={
                              this.state.listing_slug !== undefined
                                ? "unitsDocuments"
                                : "info"
                            }
                            id={
                              this.state.listing_slug !== undefined
                                ? "unitsDocuments"
                                : "info"
                            }
                            // className={(this.state.activeClass === "unitsDocuments" && this.state.listing_slug !== undefined)
                            //   ? "active"
                            //   : ""
                            // }
                            className={
                              this.state.activeClass === "unitsDocuments"
                                ? "active"
                                : ""
                            }
                            // disabled={
                            //   this.state.listing_slug !== undefined ? false : true
                            // }
                            onClick={() => {
                              if (
                                this.state.listing_slug !== undefined &&
                                this.state.confirm_back === 1
                              )
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  return true;
                                else
                                  this.props.history.push(
                                    "/properties/view/" +
                                      this.state.property_slug +
                                      "/leasing/" +
                                      this.state.listing_slug +
                                      "/info"
                                  );
                              else
                                document
                                  .querySelector("#flashButton")
                                  .classList.add("flashSavebtn1");
                              // if (this.state.listing_slug === undefined) (
                              //   document.querySelector('#flashButton').classList.add('flashSavebtn1')
                              // )
                            }}
                          >
                            3. Documents
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                            <Nav.Link
                              eventKey="listingPreview"
                              id="listingPreview"
                              className={
                                this.state.activeClass === "listingPreview"
                                  ? "active"
                                  : ""
                              }
                              onClick={() =>
                                this.getTabData(this.state.listing_slug)
                              }
                              disabled={
                                this.state.listing_slug !== undefined ? false : true
                              }
                            >
                              3. Preview
                            </Nav.Link>
                          </Nav.Item> */}
                      </ScrollingCarousel>
                      <Tab.Content>
                        <Tab.Pane eventKey="info">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                            {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
                            <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
                              <button
                                type="button"
                                className="btn-success-outline-small"
                                onClick={() => {
                                  if (this.state.listing_slug !== undefined)
                                    if (this.state.confirm_back === 1)
                                      if (
                                        window.confirm(
                                          "Do you want to exit without saving?"
                                        )
                                      )
                                        this.props.history.push(
                                          this.props.match.params
                                            .propertySlug !== undefined
                                            ? "/properties/view/" +
                                                this.state.property_slug +
                                                "/leasing/" +
                                                this.state.listing_slug +
                                                "/info"
                                            : "/leasing/view/" +
                                                this.state.property_slug +
                                                "/" +
                                                this.state.listing_slug
                                        );
                                      else return false;
                                    else
                                      this.props.history.push(
                                        this.props.match.params.propertySlug !==
                                          undefined
                                          ? "/properties/view/" +
                                              this.state.property_slug +
                                              "/leasing/" +
                                              this.state.listing_slug +
                                              "/info"
                                          : "/leasing/view/" +
                                              this.state.property_slug +
                                              "/" +
                                              this.state.listing_slug
                                      );
                                  else if (this.state.confirm_back === 1)
                                    if (
                                      window.confirm(
                                        "Do you want to exit without saving?"
                                      )
                                    )
                                      this.props.history.push(
                                        this.props.match.params.propertySlug !==
                                          undefined
                                          ? "/properties/view/" +
                                              this.state.property_slug +
                                              "/leasing"
                                          : "/leasing"
                                      );
                                    else return false;
                                  else
                                    this.props.history.push(
                                      this.props.match.params.propertySlug !==
                                        undefined
                                        ? "/properties/view/" +
                                            this.state.property_slug +
                                            "/leasing"
                                        : "/leasing"
                                    );
                                }}
                              >
                                Cancel
                              </button>

                              {this.state.slug !== undefined &&
                              global.userPermissions.checkPermission(
                                "leases-update"
                              ) ? (
                                <button
                                  className="btn-success ml-3"
                                  type="button"
                                  id="flashButton"
                                  disabled={this.state.isSubmit ? true : false}
                                  onClick={this.saveOrUpdateLeasingData}
                                >
                                  {this.state.isSubmit ? global.loader : "Save"}
                                </button>
                              ) : global.userPermissions.checkPermission(
                                  "leases-add"
                                ) ? (
                                <button
                                  className="btn-success ml-3"
                                  type="button"
                                  id="flashButton"
                                  disabled={this.state.isSubmit ? true : false}
                                  onClick={this.saveOrUpdateLeasingData}
                                >
                                  {this.state.isSubmit ? global.loader : "Save"}
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                            </div>

                            <form id="listing_form">
                              <div className="m-0">
                                {/* leasing form */}
                                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15">
                                  <h3 className="mg-l-0 background-head">
                                    Basic
                                  </h3>
                                </div>

                                <div className="row align-items-basline pd-b-30 border-bottom-0 pd-r-0">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Unit{" "}
                                    </label>

                                    <Select
                                      styles={customStyles}
                                      isClearable={true}
                                      className="multiselect"
                                      menuPlacement="auto"
                                      value={this.state.selectedUnitList}
                                      options={this.state.newUnitList}
                                      onChange={(value) =>
                                        this.handleChangeUnitList(value)
                                      }
                                      defaultValue={this.state.selectedUnitList}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />

                                    {this.state.errors.unit_slug ? (
                                      <div className="text-danger">
                                        {this.state.errors.unit_slug}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "unit",
                                        this.state.unit_slug,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Type{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>

                                    <Select
                                      styles={customStyles}
                                      isClearable={true}
                                      className="multiselect"
                                      menuPlacement="auto"
                                      value={this.state.selectedTypeList}
                                      options={this.state.typeList}
                                      onChange={(value) =>
                                        this.handleChangeTypeList(value)
                                      }
                                      defaultValue={this.state.selectedTypeList}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />

                                    {
                                      this.state.errors.type ? (
                                        <div className="text-danger">
                                          {this.state.errors.type}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                      //  (
                                      //   this.validator.message(
                                      //     "type",
                                      //     this.state.type,
                                      //     "required",
                                      //     { className: "text-danger" }
                                      //   )
                                      // )
                                    }
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Lease Signed Date{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>

                                    <DatePicker
                                      className="form-control max_width_100"
                                      showMonthDropdown
                                      showYearDropdown
                                      scrollableYearDropdown
                                      autoComplete="off"
                                      disabledKeyboardNavigation
                                      strictParsing
                                      yearDropdownItemNumber={
                                        global.yearDropdownItemNumber
                                      }
                                      //maxDate={this.state.lease_end_date}
                                      name="lease_signed_date"
                                      dateFormat="MM/dd/yy"
                                      // minDate={new Date()}
                                      maxDate={this.state.lease_end_date}
                                      selected={this.state.lease_signed_date}
                                      onChange={(data) =>
                                        this.changeHandler({
                                          target: {
                                            type: "date",
                                            name: "lease_signed_date",
                                            value: data,
                                          },
                                        })
                                      }
                                    />
                                    {
                                      this.state.errors.lease_signed_date ? (
                                        <div className="text-danger">
                                          {this.state.errors.lease_signed_date}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                      //  (
                                      //   this.validator.message(
                                      //     "lease_signed_date",
                                      //     this.state.lease_signed_date,
                                      //     "required",
                                      //     { className: "text-danger" }
                                      //   )
                                      // )
                                    }
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Lease Start Date
                                    </label>
                                    <DatePicker
                                      className="form-control max_width_100"
                                      showMonthDropdown
                                      showYearDropdown
                                      scrollableYearDropdown
                                      disabledKeyboardNavigation
                                      strictParsing
                                      autoComplete="off"
                                      yearDropdownItemNumber={
                                        global.yearDropdownItemNumber
                                      }
                                      // minDate={this.state.available_from ? this.state.available_from : ''}
                                      name="lease_start_date"
                                      dateFormat="MM/dd/yy"
                                      selected={this.state.lease_start_date}
                                      excludeDates={this.state.disabled_dates.map(
                                        (item, index) => {
                                          return item.dt;
                                        }
                                      )}
                                      onChange={(data) =>
                                        this.changeHandler({
                                          target: {
                                            type: "date",
                                            name: "lease_start_date",
                                            value: data,
                                          },
                                        })
                                      }
                                    />
                                    {this.state.errors.lease_start_date ? (
                                      <div className="text-danger">
                                        {this.state.errors.lease_start_date}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "lease_start_date",
                                        this.state.lease_start_date,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Lease End Date
                                      {/* {" "}
                                      <span className="optional">(Optional)</span> */}
                                    </label>
                                    <DatePicker
                                      className="form-control max_width_100"
                                      showMonthDropdown
                                      showYearDropdown
                                      scrollableYearDropdown
                                      disabledKeyboardNavigation
                                      strictParsing
                                      minDate={this.state.lease_start_date}
                                      maxDate={this.state.maxCheckoutDate}
                                      // /minDate={this.state.lease_start_date}
                                      autoComplete="off"
                                      yearDropdownItemNumber={
                                        global.yearDropdownItemNumber
                                      }
                                      name="lease_end_date"
                                      dateFormat="MM/dd/yy"
                                      selected={this.state.lease_end_date}
                                      onChange={(data) =>
                                        this.changeHandler({
                                          target: {
                                            type: "date",
                                            name: "lease_end_date",
                                            value: data,
                                          },
                                        })
                                      }
                                    />
                                    {this.state.errors.lease_end_date ? (
                                      <div className="text-danger">
                                        {this.state.errors.lease_end_date}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "lease_end_date",
                                        this.state.lease_end_date,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15 d-flex align-items-baseline">
                                    <ul className="formcheck-section mg-b-0">
                                      <li>
                                        <label className="form-label text-left pd-b-5">
                                          Roll-over
                                        </label>
                                        <Form.Check
                                          name="roll_over"
                                          type={"checkbox"}
                                          id={`roll_over`}
                                          label={""}
                                          className={"ckbox"}
                                          onChange={this.changeHandler}
                                          checked={this.state.roll_over}
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15">
                                  <h3 className="mg-l-0 background-head">
                                    Pricing
                                  </h3>
                                </div>
                                <div className="row align-items-center pd-b-30 border-bottom-0">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Monthly Rent{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>
                                    <div className="input-group-prepend prefix-dollar">
                                      <span className="input-group-text">
                                        $
                                      </span>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="monthly_rent"
                                        value={
                                          this.state.monthly_rent
                                            ? this.state.monthly_rent.replace(
                                                /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                ""
                                              )
                                            : "" || ""
                                        }
                                        maxLength="10"
                                        onKeyPress={
                                          global.onKeyPressEvent.floatValidation
                                        }
                                      />
                                    </div>
                                    {
                                      this.state.errors.monthly_rent ? (
                                        <div className="text-danger">
                                          {this.state.errors.monthly_rent}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                      // (
                                      //   this.validator.message(
                                      //     "monthly_rent",
                                      //     this.state.monthly_rent,
                                      //     "required",
                                      //     { className: "text-danger" }
                                      //   )
                                      // )
                                    }
                                  </div>
                                  {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Rent Start Date
                                      {" "}
                                      <span className="optional">(Optional)</span>
                                    </label>
                                    <DatePicker
                                      className="form-control max_width_100"
                                      showMonthDropdown
                                      showYearDropdown
                                      scrollableYearDropdown
                                      autoComplete="off"
                                      disabledKeyboardNavigation
                                      strictParsing
                                      yearDropdownItemNumber={
                                        global.yearDropdownItemNumber
                                      }
                                      //maxDate={this.state.lease_end_date}
                                      name="rent_start_date"
                                      dateFormat="MM/dd/yy"
                                      minDate={new Date()}

                                      // maxDate={this.state.lease_end_date}
                                      selected={this.state.rent_start_date}
                                      onChange={(data) =>
                                        this.changeHandler({
                                          target: {
                                            type: "date",
                                            name: "rent_start_date",
                                            value: data,
                                          },
                                        })
                                      }
                                    />
                                    {this.state.errors.rent_start_date ? (
                                      <div className="text-danger">
                                        {this.state.errors.rent_start_date}
                                      </div>
                                    ) : ''
                                      //  (
                                      //   this.validator.message(
                                      //     "rent_start_date",
                                      //     this.state.rent_start_date,
                                      //     "required",
                                      //     { className: "text-danger" }
                                      //   )
                                      // )
                                    }
                                  </div> */}
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Deposit{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>
                                    <div className="input-group-prepend prefix-dollar">
                                      <span className="input-group-text">
                                        $
                                      </span>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="deposit"
                                        value={
                                          this.state.deposit
                                            ? this.state.deposit.replace(
                                                /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                ""
                                              )
                                            : "" || ""
                                        }
                                        maxLength="10"
                                        onKeyPress={
                                          global.onKeyPressEvent.floatValidation
                                        }
                                      />
                                    </div>
                                    {
                                      this.state.errors.deposit ? (
                                        <div className="text-danger">
                                          {this.state.errors.deposit}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                      //  (
                                      //   this.validator.message(
                                      //     "deposit",
                                      //     this.state.deposit,
                                      //     "required",
                                      //     { className: "text-danger" }
                                      //   )
                                      // )
                                    }
                                  </div>
                                  {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Lease Status
                                    </label>
                                     <Select
                                      styles={customStyles}
                                      isClearable={true}
                                      menuPlacement="auto"
                                      className="multiselect"
                                      options={this.state.statusList}
                                      value={this.state.selectedStatusList}
                                      defaultValue={this.state.selectedStatusList}
                                      getOptionValue={option => `${option.label}`}
                                      onChange={(value) => this.handleChangeStatusList(value)}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: '#fff',
                                          primary75: '#000',
                                          primary50: '#000',
                                          primary25: '#000',
                                        }
                                      })}
                                    />

                                    <b
                                      className={
                                        this.state.status_id ? "active" : "inactive"
                                      }
                                    >
                                    </b>
                                    <div
                                      className={
                                        this.state.status_id
                                          ? "az-toggle on"
                                          : "az-toggle"
                                      }
                                      onClick={() =>
                                        this.setState({
                                          status_id: !this.state.status_id,
                                        })
                                      }
                                    >
                                    <span></span>
                                    </div>

                                    {this.state.errors.status_id ? (
                                      <div className="text-danger">
                                        {this.state.errors.status_id}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "lease_status",
                                        this.state.status_id,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    )}
                                    {/* <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                                      <b
                                        className={
                                          this.state.status_id
                                            ? "active"
                                            : "inactive"
                                        }
                                      ></b>
                                      <div
                                        className={
                                          this.state.status_id
                                            ? "az-toggle on"
                                            : "az-toggle"
                                        }
                                        onClick={() => this.changeStatus()}
                                      >
                                        <span></span>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15">
                                  <h3 className="mg-l-0 background-head">
                                    Resident Contact Info
                                  </h3>
                                </div>
                                <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-15 pd-r-0">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Email{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="email"
                                      value={this.state.email || ""}
                                      maxLength="50"
                                    />
                                    {
                                      this.state.errors.email ? (
                                        <div className="text-danger">
                                          {this.state.errors.email}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                      //  (
                                      //   this.validator.message(
                                      //     "email",
                                      //     this.state.email,
                                      //     "required|email",
                                      //     { className: "text-danger" }
                                      //   )
                                      // )
                                    }
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      First Name{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="first_name"
                                      value={this.state.first_name || ""}
                                      maxLength="50"
                                    />
                                    {this.state.errors.first_name ? (
                                      <div className="text-danger">
                                        {this.state.errors.first_name}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "first_name",
                                        this.state.first_name,
                                        "max:50",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Last Name{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="last_name"
                                      value={this.state.last_name || ""}
                                      maxLength="50"
                                    />
                                    {
                                      this.state.errors.last_name ? (
                                        <div className="text-danger">
                                          {this.state.errors.last_name}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                      // (
                                      //   this.validator.message(
                                      //     "last_name",
                                      //     this.state.last_name,
                                      //     "required",
                                      //     { className: "text-danger" }
                                      //   )
                                      // )
                                    }
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Phone{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>
                                    <PhoneInput
                                      country={global.country}
                                      disableSearchIcon
                                      // autoFormat= "true"
                                      enableSearch="true"
                                      countryCodeEditable="true"
                                      value={this.state.phone_number}
                                      onChange={this.onChangePhone}
                                    />
                                    {
                                      this.state.errors.phone_number ? (
                                        <div className="text-danger">
                                          {this.state.errors.phone_number}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                      // (
                                      //   this.validator.message(
                                      //     "phone_number",
                                      //     this.state.phone_number,
                                      //     "required",
                                      //     { className: "text-danger" }
                                      //   )
                                      // )
                                    }
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Photo{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>

                                    <div className="fileUpload d-flex flex-wrap justify-content-between">
                                      <span>
                                        <label className="hand-cursor">
                                          <input
                                            onChange={this.onImageChange}
                                            type="file"
                                            name="resident_photo"
                                          />
                                          {this.state.imageUrl ? (
                                            <img
                                              src={this.state.imageUrl}
                                              alt=""
                                            />
                                          ) : (
                                            <img src={hostImg} alt="" />
                                          )}
                                        </label>
                                      </span>
                                      {this.state.listing_slug ? 
                                      <p className="hand-cursor remove-pic remove-pic-three m-0" onClick={this.removePhoto}></p>
                                        : ""
                                    }
                                    </div>
                                    {/* <span className="d-inline-flex align-items-start justify-content-start infosize">
                                    <img src={infosize} className="mg-r-5" />
                                    <p>
                                      {" "}
                                      Recommended size - 1024px by 683px (3:2 ratio). Max file
                                      size - 5 MB
                                    </p>
                                  </span> */}
                                  </div>
                                </div>

                                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15">
                                  <h3 className="mg-l-0 background-head">
                                    Alternative Address
                                  </h3>
                                </div>
                                <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-15 pd-r-0">
                                  <GooglePlaces
                                    getChildData={this.getChildData.bind(this)}
                                    unit={this.state.street_1}
                                    street1={this.state.street_2}
                                    city={this.state.city_name}
                                    state={this.state.state_name}
                                    county={this.state.county_name}
                                    country={this.state.country_name}
                                    zipcode={this.state.zipcode}
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="listingPrice">
                          <LeasingFees
                            listing_slug={this.state.listing_slug}
                            property_slug={this.state.property_slug}
                            confirmBackStatus={this.getConfirmStatus}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="unitsDocuments">
                          <LeasingDocuments
                            listing_slug={this.state.listing_slug}
                            property_slug={this.state.property_slug}
                            confirmBackStatus={this.getConfirmStatus}
                          />
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="listingPreview">
                            {this.state.isRefresh && (
                              <Preview
                                listing_slug={this.state.listing_slug}
                                property_slug={this.state.property_slug}
                                listingSlug={this.listingSlug}
                              />
                            )}
                          </Tab.Pane> */}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default LeasingForm;
