//                                                           //
//  Program: ResidentPayoutsReports.jsx                              //
//  Application: Report                                      //
//  Option: Property Reports Listing                         //
//  Developer: NP 		                                     //
//  Date: 2022-08-30                                         //
//                                                           //
/////////////////////////////////////////////////////////   ///

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { Container, Tab, Modal, InputGroup, Form } from "react-bootstrap";
import PayoutSidebar from "../common/PayoutSidebar";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import ReportService from "../../services/ReportService";
import Select from "react-select";
import UnitsService from "../../services/UnitsService";
import Moment from "moment";
import MultiImageService from "./../../services/MultiImageService";
import Alerts from "../common/Alerts";
import AllPropertyPayoutSidebar from "../common/AllPropertyPayoutSidebar";

class ResidentPayoutsReportsCreate extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      create_payment_array: [],
      is_draft: false,
      is_pay_success: false,
      pay_amount_val: "",
      payout_date_val: new Date(),
      payAmountArray: [],
      errors: {},
      importModal: false,
      file_url: "",
      file_name: "",
      open: true,
      eventKey: "0",
      is_inactive: false,
      isSubmit: false,
      isSave: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      module_search: "",
      module_search_display: "",
      exportMesssage: "",
      exportFilePath: "",
      exportResult: "",
      alertType: "xlsx",
      is_search: 0,
      isSearch: global.isSearch,
      is_pay_out_modal: false,
      input: {},
      unitList: [],
      selectedUnitList: [],
      unitRentStatusList: [],
      selectedUnitRentStatusList: [],
      unit_rent_status_slug: "",
      unit_rent_status_label: "",
      sendData: [],
      selectAll: true,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      totalPayOutList: {},
      saveSendModel: false,
      saveModel: false,
      deleteRoleModal: false,
      slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      pathname: window.location.pathname,
      paymentMode: {},
      paymentDate: {},
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: true,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      configPay: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: true,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      newUnitList: [],
      extraButtons: [],
      columns: [
        {
          key: "pay_check",
          text: (
            <Form.Check
              type="checkbox"
              name="selectAll"
              className="all-checkbox"
              checked={this.getSelectAll}
              onChange={(e) => this.changeHandlerSelectAll(e)}
            />
          ),
          className:
            "pay_check hand-cursor text-center td_clickable report_fst_td",
          cell: (record, i) => {
            let paidAmountName = "pay_check_" + record.reservation_slug;
            let paidAmountId = "pay_check_" + record.reservation_slug;
            let checked = record.checked;
            if (["Note", "Total"].includes(record.checkout_date)) {
              return "";
            }
            return (
              <Form.Check
                type="checkbox"
                name={paidAmountName}
                value=""
                className={`pay-check check-pay-` + record.unit_slug}
                id={paidAmountId}
                checked={checked}
                onChange={(e) =>
                  this.changeHandlerCheckBox(
                    e,
                    i,
                    record.reservation_slug,
                    record.resident_earning,
                    record.unit_slug
                  )
                }
              />
            );
          },
        },
        {
          key: "unit_name",
          text: "Unit",
          className: "unit_number hand-cursor text-center td_clickable",
          cell: (record) => {
            return record.unit_name;
          },
        },
        {
          key: "Check In Date",
          text: "Check In",
          className: "checkin_date hand-cursor text-center td_clickable",
          cell: (record) => {
            return record.checkin_date;
          },
        },
        {
          key: "Check Out Date",
          text: "Check Out",
          className: "checkout_date hand-cursor text-center td_clickable",
          cell: (record) => {
            if (["Total"].includes(record.checkout_date)) {
              const options = [{ value: "Check", label: "Check" }];
              return (
                <span
                  id="to-pay"
                  className={
                    (record.checkout_date === "Date Range Grand Total"
                      ? "grand_total1"
                      : "to-pay1") + " resident-to"
                  }
                >
                  <Select
                    value={options.value}
                    options={options}
                    isDisabled={true}
                    defaultValue={options[0]}
                  />
                </span>
              );
            } else if (["Note"].includes(record.checkout_date)) {
              return record.rent_status ? record.rent_status : "";
            }
            return record.checkout_date;
          },
        },
        {
          key: "Nights Booked",
          text: "# Nights Booked",
          className: "total_stay_nights hand-cursor text-center td_clickable",
          // changeHandlerPaymentDate
          cell: (record) => {
            if (["Total"].includes(record.checkout_date)) {
              return (
                <Form.Control
                  placeholder="Payment #"
                  type="text"
                  value={record.payment_reference}
                  name={`payment_ref_no_` + record.unit_resident_slug}
                  onChange={(e) =>
                    this.changeHandlerPaymentRef(e, record.unit_resident_slug)
                  }
                />
              );
            }
            return record.total_stay_nights;
          },
        },
        {
          key: "total_nightly_rate",
          text: "Nightly Revenue",
          className: "hand-cursor text-center td_clickable",
          cell: (record) => {
            if (["Total"].includes(record.checkout_date)) {
              return (
                <Form.Control
                  placeholder="Payout date"
                  type="date"
                  value={record.payout_date}
                  name={`payment_date_` + record.unit_resident_slug}
                  onChange={(e) =>
                    this.changeHandlerPaymentDate(e, record.unit_resident_slug)
                  }
                />
              );
            } else if (["Note"].includes(record.checkout_date)) {
              return "";
            }
            return "$" + record.nightly_per_amount;
          },
        },
        {
          key: "host_service_fee",
          text: "Channel Fees",
          className: "hand-cursor text-center td_clickable",
          cell: (record) => {
            if (["Total"].includes(record.checkout_date)) {
              return (
                <Form.Control
                  placeholder="Memo"
                  type="text"
                  value={record.memo}
                  name={`memo_` + record.unit_resident_slug}
                  onChange={(e) =>
                    this.changeHandlerPaymentMemo(e, record.unit_resident_slug)
                  }
                />
              );
            } else if (["Note"].includes(record.checkout_date)) {
              return "";
            }
            return "$" + record.host_service_fee;
          },
        },
        {
          key: "resolution_fees",
          text: "Resolution Fees (Resident $)",
          className: "cleaning_fee hand-cursor text-center td_clickable",
          cell: (record) => {
            if (["Total"].includes(record.checkout_date)) {
              return (
                <Form.Control
                  placeholder="Proof"
                  type="text"
                  value={record.payment_proof}
                  name={`payment_proof_` + record.unit_resident_slug}
                  onChange={(e) =>
                    this.changeHandlerPaymentProof(e, record.unit_resident_slug)
                  }
                />
              );
            } else if (["Note"].includes(record.checkout_date)) {
              return (
                <Form.Control
                  placeholder="Note"
                  type="text"
                  value={record.pay_note}
                  name={`pay_note_` + record.unit_resident_slug}
                  onChange={(e) =>
                    this.changeHandlerPayNote(e, record.unit_resident_slug)
                  }
                />
              );
            }
            return "$" + record.to_resident_total;
          },
        },
        {
          key: "cleaning_fees",
          text: "Cleaning Fees (Resident $)",
          className: "cleaning_fee hand-cursor text-center td_clickable",
          cell: (record) => {
            if (["Total"].includes(record.checkout_date)) {
              return "";
            } else if (["Note"].includes(record.checkout_date)) {
              return "";
            }
            return "$" + record.res_cleaning_fee;
          },
        },
        {
          key: "resident_payout",
          text: "Resident Payout",
          className: "cleaning_fee hand-cursor text-center td_clickable",
          cell: (record) => {
            return "$" + record.resident_earning;
          },
        },
        {
          key: "payout_amount",
          text: "Paid",
          className: "paid_amount hand-cursor text-center td_clickable",
          cell: (record, i) => {
            let paidAmountName = "paid_amount_" + record.reservation_slug;
            let paidAmountId = "paid_amount_" + record.reservation_slug;
            if (record.checkout_date === "Total") {
              paidAmountName = "paid_amount_" + record.unit_resident_slug;
              paidAmountId = "paid_amount_" + record.unit_resident_slug;
              let amountDisplay =
                parseFloat(record.pays.replace(/,/g, "")) +
                parseFloat(
                  record.extra_amount
                    ? record.extra_amount.replace(/,/g, "")
                    : 0
                );
              return (
                <Form.Control
                  type="text"
                  name={paidAmountName}
                  value={global.onKeyPressEvent.numberWithCommasForDecimal(
                    parseFloat(amountDisplay).toFixed(2).replace(/\.00$/, "")
                  )}
                  id={paidAmountId}
                  className="border-0"
                  disabled
                  readOnly
                />
              );
            } else if (record.checkout_date === "Note") {
              paidAmountName = "extra_amount_" + record.unit_resident_slug;
              paidAmountId = "extra_amount_" + record.unit_resident_slug;
              return (
                <Form.Control
                  type="text"
                  onKeyPress={
                    global.onKeyPressEvent.negativeFloatValidation
                  }
                  name={paidAmountName}
                  value={record.extra_amount}
                  id={paidAmountId}
                  placeholder="Adjust amount"
                  onChange={(e) =>
                    this.changeHandlerExtraAmount(e, record.unit_resident_slug)
                  }
                />
              );
            }
            return (
              <Form.Control
                type="text"
                name={paidAmountName}
                value={record.pays}
                id={paidAmountId}
                className="border-0"
                disabled
                readOnly
              />
            );
          },
        },
      ],
    };
    this.saveOrUpdatePayout = this.saveOrUpdatePayout.bind(this);
    this.draftPayoutStatus = this.draftPayoutStatus.bind(this);
    this.succssPayoutStatus = this.succssPayoutStatus.bind(this);
    this.changeHandlerPayoutAmount = this.changeHandlerPayoutAmount.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.removeTotalClass = this.removeTotalClass.bind(this);
    this.changeHandlerExtraAmount = this.changeHandlerExtraAmount.bind(this);
    this.changeHandlerSelectAll = this.changeHandlerSelectAll.bind(this);
  }
  getSelectAll = true;

  componentDidMount() {
    this.getPayOutReports();
    this.gerDefaultInOutDate();
  }

  changeHandlerPaymentMode = (event, unitResSlug) => {
    let data = this.state.records;
    data.forEach((item) => {
      let unit_res_slug = item.unit_resident_slug;
      if (unit_res_slug.startsWith(unitResSlug)) {
        item.payment_mode = event.target.value;
        item.payment_method = event.target.value;
      }
    });
    this.setState({ records: data });
  };

  changeHandlerExtraAmount = (event, unitResSlug) => {
    let data = this.state.records;
    data.forEach((item) => {
      let unit_res_slug = item.unit_resident_slug;
      if (unit_res_slug.startsWith(unitResSlug)) {
        item.extra_amount = event.target.value;
      }
    });
    this.setState({ records: data });
  };

  changeHandlerPaymentDate11 = (event) => {
    let data = this.state.records;
    let unitResSlug = event.target.slug;
    if (event.target.type === "date" && event.target.value !== null) {
      data.forEach((item) => {
        let unit_res_slug = item.unit_resident_slug;
        if (unit_res_slug.startsWith(unitResSlug)) {
          item.payout_date = Moment(event.target.value).toDate();
        }
      });
    }
    this.setState({ records: data });
  };

  changeHandlerPaymentDate = (event, unitResSlug) => {
    let data = this.state.records;
    data.forEach((item) => {
      let unit_res_slug = item.unit_resident_slug;
      if (unit_res_slug.startsWith(unitResSlug)) {
        item.payout_date = event.target.value;
      }
    });
    this.setState({ records: data });
  };

  changeHandlerPaymentRef = (event, unitResSlug) => {
    let data = this.state.records;
    data.forEach((item) => {
      let unit_res_slug = item.unit_resident_slug;
      if (unit_res_slug.startsWith(unitResSlug)) {
        item.payment_reference = event.target.value;
      }
    });
    this.setState({ records: data });
  };

  changeHandlerPayNote = (event, unitResSlug) => {
    let data = this.state.records;
    data.forEach((item) => {
      let unit_res_slug = item.unit_resident_slug;
      if (unit_res_slug.startsWith(unitResSlug)) {
        item.pay_note = event.target.value;
      }
    });
    this.setState({ records: data });
  };

  changeHandlerPaymentProof = (event, unitResSlug) => {
    let data = this.state.records;
    data.forEach((item) => {
      let unit_res_slug = item.unit_resident_slug;
      if (unit_res_slug.startsWith(unitResSlug)) {
        item.payment_proof = event.target.value;
      }
    });
    this.setState({ records: data });
  };

  changeHandlerPaymentMemo = (event, unitResSlug) => {
    let data = this.state.records;
    data.forEach((item) => {
      let unit_res_slug = item.unit_resident_slug;
      if (unit_res_slug.startsWith(unitResSlug)) {
        item.memo = event.target.value;
      }
    });
    this.setState({ records: data });
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };
  openSaveModal(slug) {
    this.setState({ saveModel: true });
  }

  closeSaveModal() {
    this.setState({ saveModel: false });
  }
  openSaveSendModal(slug) {
    this.setState({ saveSendModel: true });
  }

  closeSaveSendModal() {
    this.setState({ saveSendModel: false });
  }
  async clearFilter() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.setState({ is_search: 0 });
    this.setState({
      checkout_date_from: firstDay,
      checkout_date_to: lastDay,
      unit_slug: "",
      selectedUnitList: [],
      unitRentStatusList: [],
      selectedUnitRentStatusList: [],
      unit_rent_status_slug: "",
      unit_rent_status_label: "",
    });
    this.setState({ module_search: "" });
    await this.removeTotalClass();
    this.getPayOutReports();
  }

  saveOrUpdatePayout = async (e, is_payment_success_status) => {
    e.preventDefault();
    this.setState({ showSpinner: true });

    if (is_payment_success_status === 1) {
      this.setState({ isSubmit: true });
    } else {
      this.setState({ isSave: true });
    }

    let crePaymentArray = [];
    let res = "";

    if (this.state.records.length > 0) {
      let checkOutFromDate = this.state.checkout_date_from
        ? Moment(this.state.checkout_date_from).format("YYYY-MM-DD")
        : "";
      let checkOutDateTo = this.state.checkout_date_to
        ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
        : "";

      let unitDataMap = {};

      this.state.records.forEach((item) => {
        if (item.checked) {
          const {
            unit_slug,
            resident_slug,
            reservation_slug,
            resident_earning,
            total_stay_nights,
            total_booking_count,
            extra_amount,
            pay_note,
            payment_reference,
            memo,
            payment_proof,
            payout_date,
            payment_method,
          } = item;

          const payout_amount = parseFloat(resident_earning);
          const stay_nights = parseInt(total_stay_nights) || 0;
          const bookings = total_booking_count
            ? parseInt(total_booking_count)
            : reservation_slug
            ? 1
            : 0;
          const extraAmount = parseFloat(extra_amount) || 0;

          if (!unit_slug) return;

          if (!unitDataMap[unit_slug]) {
            unitDataMap[unit_slug] = {
              unit_slug: unit_slug,

              payment_mode: "",
              payment_method: "",
              payment_reference: payment_reference || "",
              pay_note: pay_note || "",
              extra_amount: extra_amount || "",
              resident_slug: resident_slug,
              payout_payment: 0,
              payout_total_payouts: 0,
              payout_total_stay_nights: 0,
              payout_total_bookings: 0,
              payout_start_date: checkOutFromDate,
              payout_end_date: checkOutDateTo,
              is_payment_success_status: 1,
              memo: memo || "",
              payment_proof: payment_proof || "",
              payout_date: payout_date || "",
              reservations: [],
            };
          }

          const unitData = unitDataMap[unit_slug];
          unitData.payout_total_stay_nights += stay_nights;
          unitData.payout_total_bookings += bookings;
          unitData.payment_reference = payment_reference;
          unitData.extra_amount = extra_amount;
          unitData.pay_note = pay_note;
          unitData.memo = memo;
          unitData.payment_proof = payment_proof;
          unitData.payout_date = payout_date;
          unitData.payment_method = payment_method;

          if (reservation_slug) {
            unitData.reservations.push({
              resident_detail_slug: resident_slug,
              reservation_slug: reservation_slug,
              payout_amount: payout_amount.toFixed(2),
            });
            unitData.payout_total_payouts += payout_amount;
          } else {
            unitData.reservations.push({
              resident_detail_slug: resident_slug,
              reservation_slug: "",
              payout_amount: 0,
            });
          }

          unitData.payout_total_payouts += extraAmount;
        }
      });

      crePaymentArray = Object.values(unitDataMap).map((unitData) => {
        unitData.payout_payment = (
          unitData.reservations.reduce(
            (sum, reservation) => sum + parseFloat(reservation.payout_amount),
            0
          ) + parseFloat(unitData.extra_amount || 0)
        ).toFixed(2);
        return unitData;
      });
    }

    let inputData = {
      property_slug: this.state.property_slug
        ? this.state.property_slug
        : this.props.property_slug,
      payment_summary: crePaymentArray,
      is_payment_success_status: is_payment_success_status,
    };

    if (this.state.slug !== undefined) {
      let inputData1 = { summary_slug: this.state.slug };
      let passUpdateData = {
        ...inputData,
        ...inputData1,
      };
      res = await ReportService.updateResidentPayout(passUpdateData);
    } else {
      res = await ReportService.createResidentPayout(inputData);
    }

    this.setState({
      showSpinner: false,
      saveSendModel: false,
      saveModel: false,
      isSubmit: false,
    });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );

    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.props.match.params.propertySlug !== undefined
              ? "/properties/view/" +
                  this.state.property_slug +
                  "/res-payout-summary"
              : "/res-payout-summary"
          ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.message ? res.message : "Error!",
        alertModalMessage: alertMessage !== "" ? alertMessage : res.message,
      });
    }
  };

  draftPayoutStatus = async (e) => {
    this.setState({
      is_draft: true,
      is_pay_success: false,
    });
    //this.saveOrUpdatePayout();
  };

  succssPayoutStatus = async (e) => {
    this.setState({
      is_pay_success: true,
      is_draft: false,
    });
    //this.saveOrUpdatePayout();
  };

  async ExportData() {
    this.setState({ showSpinner: true, isSubmit: true, isSave: true });
    let data = {
      property_slug: this.state.property_slug,
    };

    let checkOutFromDate = this.state.checkout_date_from
      ? Moment(this.state.checkout_date_from).format("YYYY-MM-DD")
      : "";
    let checkOutDateTo = this.state.checkout_date_to
      ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
      : "";
    let queryString =
      "is_export=1" +
      "&file_type=" +
      this.state.alertType +
      "&checkout_date_from=" +
      checkOutFromDate +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&checkout_date_to=" +
      checkOutDateTo;

    let res = await ReportService.exportResidentPayOutReport(data, queryString);
    if (res.data.filePath) {
      this.getDownloadFile(res.data.filePath, res.data.fileName);
      this.setState({ exportMesssage: res.message });
      this.setState({ exportResult: data });
      this.setState({ exportFilePath: res.data.filePath });
    }
    this.setState({ showSpinner: false, isSubmit: false, isSave: false });
  }

  async getDownloadFile(url, name) {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  exportToggle = () => {
    if (this.state.isModel === 1) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: 0 });
      this.setState({ showSpinner: false, isSubmit: false, isSave: false });
    } else {
      this.setState({ isModel: 1 });
    }
  };

  gerDefaultInOutDate() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.setState({ checkout_date_from: firstDay });
    this.setState({ checkout_date_to: lastDay });
  }

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }

  async showPayoutReport(unitSlug) {
    this.setState({ showSpinner: true, loading: true });
    var listPay = [];
    var totalRecordsPay = 0;
    let payoutDetailsTitle;
    let data = {
      property_slug: this.state.property_slug,
    };
    let checkOutFromDate = this.state.checkout_date_from
      ? Moment(this.state.checkout_date_from).format("YYYY-MM-DD")
      : "";
    let checkOutDateTo = this.state.checkout_date_to
      ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
      : "";
    let queryString =
      "unit_slug=" +
      unitSlug +
      "&checkout_date_from=" +
      checkOutFromDate +
      "&checkout_date_to=" +
      checkOutDateTo;
    let res = await ReportService.showPayOutReport(data, queryString);
    if (global.successStatus.includes(res.status)) {
      listPay = res.data.data ? res.data.data : [];
      totalRecordsPay = res.data.data.length ? res.data.data.length : 0;
      payoutDetailsTitle = res.data.data.length
        ? res.data.data[0].aka_name + "-" + res.data.data[0].unit_number
        : "Pay Out Report";
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record_pay: totalRecordsPay,
      record_pay: listPay,
      payoutDetailsTitle: payoutDetailsTitle,
    });
    this.payoutDataModalToggle();
  }

  payoutDataModalToggle = () => {
    if (this.state.is_pay_out_modal === 1) {
      this.setState({ is_pay_out_modal: 0 });
    } else {
      this.setState({ is_pay_out_modal: 1 });
    }
  };

  getPayOutReports = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var slug_query = "";
    var totalRecords = 0;
    var res = "";
    if (this.state.slug !== undefined) {
      slug_query = "&summary_slug=" + this.state.slug;
      res = await ReportService.getResidentPayOutReportsEdit(
        this.state.property_slug,
        queryString + slug_query
      );
    } else {
      res = await ReportService.getResidentPayOutReportsCreate(
        this.state.property_slug,
        queryString + slug_query
      );
    }
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      totalRecords = list ? list.length : 0;
      var paymentDataArray = [];
      if (totalRecords > 0) {
        res.data.forEach((item, i) => {
          paymentDataArray.push({
            unit_slug: item.unit_slug,
            unit_resident_slug: item.unit_resident_slug,
            reservation_slug: item.reservation_slug,
            payout_amount: item.resident_earning,
          });
        });
      }
    }

    if (totalRecords > 0) {
      list.forEach((item, i) => {
        if (item.checkout_date !== "Note") {
          item.checked = true;
          item.payment_mode = this.state.slug ? item.payment_method : "";
          item.payment_method = this.state.slug ? item.payment_method : "";
          item.memo = this.state.slug ? item.memo : "";
          item.payment_reference = this.state.slug
            ? item.payment_reference
            : "";
          item.pay_note = this.state.slug ? item.pay_note : "";
          item.extra_amount = this.state.slug ? item.extra_amount : "";
          item.payout_date = this.state.slug ? item.payout_date : "";
          item.payment_proof = this.state.slug ? item.payment_proof : "";
          item.pays = item.resident_earning;
        }
      });
    }

    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
      sendData: res.data,
      //sendData:paymentDataArray
    });
    this.addTotalClass();
  };

  /** Add Class on page load */
  addTotalClass = async () => {
    const child = document.getElementsByClassName("to-pay1");
    let childNo = child.length;
    for (let i = 0; i < childNo; i++) {
      let ele = child[i].parentElement.parentElement;
      ele.classList.add("to_total");
    }
    const childDiff = document.getElementsByClassName("diff-line1");
    let childNoDiff = childDiff.length;
    for (let i = 0; i < childNoDiff; i++) {
      let ele = childDiff[i].parentElement.parentElement;
      if (ele) {
        ele.classList.add("to_diff");
      }
    }
    const childFut = document.getElementsByClassName("future-row1");
    let childNoFut = childFut.length;
    for (let i = 0; i < childNoFut; i++) {
      let ele = childFut[i].parentElement.parentElement;
      if (ele) {
        ele.classList.add("to_future");
      }
    }
    if (document.getElementsByClassName("grand_total1").length > 0) {
      const grand_total = document.getElementsByClassName("grand_total1")[0];
      let ele1 = grand_total.parentElement.parentElement;
      ele1.classList.add("grand_total");
    }
    if (document.getElementsByClassName("future-grand1").length > 0) {
      const grand_total_fu =
        document.getElementsByClassName("future-grand1")[0];
      let ele2 = grand_total_fu.parentElement.parentElement;
      ele2.classList.add("grand_total_future");
    }
    if (document.getElementsByClassName("past-grand1").length > 0) {
      const grand_total_fu = document.getElementsByClassName("past-grand1")[0];
      let ele2 = grand_total_fu.parentElement.parentElement;
      ele2.classList.add("grand_total_past");
    }
  };

  removeTotalClass = async () => {
    const child = document.getElementsByClassName("to-pay1");
    let childNo = child.length;
    for (let i = 0; i < childNo; i++) {
      let ele = child[i].parentElement.parentElement;
      ele.classList.remove("to_total");
    }
    const childFut = document.getElementsByClassName("future-row1");
    let childNoFut = childFut.length;
    for (let i = 0; i < childNoFut; i++) {
      let ele = childFut[i].parentElement.parentElement;
      if (ele) {
        ele.classList.remove("to_future");
      }
    }
    const childDiff = document.getElementsByClassName("diff-line1");
    let childNoDiff = childDiff.length;
    for (let i = 0; i < childNoDiff; i++) {
      let ele = childDiff[i].parentElement.parentElement;
      if (ele) {
        ele.classList.remove("to_diff");
      }
    }
    if (document.getElementsByClassName("grand_total1").length > 0) {
      const grand_total = document.getElementsByClassName("grand_total1")[0];
      let ele1 = grand_total.parentElement.parentElement;
      ele1.classList.remove("grand_total");
    }
    if (document.getElementsByClassName("future-grand1").length > 0) {
      const grand_total_fu =
        document.getElementsByClassName("future-grand1")[0];
      let ele2 = grand_total_fu.parentElement.parentElement;
      ele2.classList.remove("grand_total_future");
    }
    if (document.getElementsByClassName("past-grand1").length > 0) {
      const grand_total_fu = document.getElementsByClassName("past-grand1")[0];
      let ele2 = grand_total_fu.parentElement.parentElement;
      ele2.classList.remove("grand_total_past");
    }
  };

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;
    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_name, tower, total_tower_count }) => ({
            value: slug,
            label: unit_name,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getUnitData();
      this.setState({ isSearch: 1 });
      const unitRentStatusList = [
        { value: "unpaid", label: "Unpaid" },
        { value: "paid", label: "Paid" },
      ];
      this.setState({ unitRentStatusList: unitRentStatusList });
    }
  };

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    const selected_unit_number = value !== null ? value.label : [];
    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
      unit_lable: selected_unit_number,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeUnitRentStatusList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_rent_slug_value = value !== null ? value.value : [];
    const selected_unit_label = value !== null ? value.label : [];
    this.setState({
      selectedUnitRentStatusList: value,
      unit_rent_status_slug: unit_rent_slug_value,
      unit_rent_status_label: selected_unit_label,
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeHandlerSelectAll = async (event) => {
    const checked = event.target.checked;
    let data = [...this.state.records];

    // Calculate total_pays for related items
    let total_pays = {};
    data.forEach((item) => {
      if (!total_pays[item.unit_slug]) {
        total_pays[item.unit_slug] = 0;
      }
      if (
        checked &&
        item.reservation_slug !== "" &&
        item.checkout_date !== "Total" &&
        item.checkout_date !== "Note"
      ) {
        total_pays[item.unit_slug] += parseFloat(
          item.resident_earning.replace(/,/g, "")
        );
      }
    });

    // Update pays and checked state for all items
    data = data.map((item) => {
      if (item.checkout_date === "Total") {
        return {
          ...item,
          pays: total_pays[item.unit_slug].toFixed(2),
          checked: checked,
        };
      } else {
        return {
          ...item,
          pays: checked ? item.resident_earning : "0.00",
          checked: checked,
        };
      }
    });

    this.setState(
      {
        records: data,
        selectAll: checked,
      },
      this.updateColumns
    );
  };

  changeHandlerCheckBox = async (
    event,
    index,
    reservation_slug,
    resident_earning,
    unit_slug
  ) => {
    let data = [...this.state.records];
    let item = data[index];

    // Toggle the checked state
    item.checked = !item.checked;

    // Update pays directly for reservation_slug === ""
    if (reservation_slug === "") {
      item.pays = item.checked ? resident_earning : "0.00";
    } else {
      // Calculate total_pays for related items
      let total_pays = {};
      data.forEach((item) => {
        if (!total_pays[item.unit_slug]) {
          total_pays[item.unit_slug] = 0;
        }
        if (
          item.checked &&
          item.reservation_slug !== "" &&
          item.checkout_date !== "Total" &&
          item.checkout_date !== "Note"
        ) {
          total_pays[item.unit_slug] += parseFloat(
            item.resident_earning.replace(/,/g, "")
          );
        }
      });

      // Update pays for related items with checkout_date === "Total"
      data.forEach((item) => {
        if (item.unit_slug === unit_slug && item.checkout_date === "Total") {
          item.pays = total_pays[item.unit_slug].toFixed(2);
        }
      });

      item.pays = item.checked ? resident_earning : "0.00";
    }

    // Determine the state of selectAll
    const allChecked = data.every((item) => item.checked);

    this.setState(
      {
        records: data,
        selectAll: allChecked,
      },
      this.updateColumns
    );
  };

  updateColumns = () => {
    let newColumns = this.state.columns.map((item) => {
      if (item.key === "pay_check") {
        return {
          ...item,
          text: (
            <Form.Check
              type="checkbox"
              name="selectAll"
              className="all-checkbox"
              checked={this.state.selectAll}
              onChange={(e) => this.changeHandlerSelectAll(e)}
            />
          ),
          cell: (record, i) => {
            let paidAmountName = "pay_check_" + record.reservation_slug;
            let paidAmountId = "pay_check_" + record.reservation_slug;
            let checked = record.checked;
            if (["Note", "Total"].includes(record.checkout_date)) {
              return "";
            }
            return (
              <Form.Check
                type="checkbox"
                name={paidAmountName}
                value=""
                className={`pay-check check-pay-` + record.unit_slug}
                id={paidAmountId}
                checked={checked}
                onChange={(e) =>
                  this.changeHandlerCheckBox(
                    e,
                    i,
                    record.reservation_slug,
                    record.resident_earning,
                    record.unit_slug
                  )
                }
              />
            );
          },
        };
      }
      return item;
    });

    this.setState({ columns: newColumns });
  };

  changeHandlerPayoutAmount = async (
    event,
    index,
    reservation_slug,
    resident_earning
  ) => {
    let payAmountVal = event.target.value;
    let data = this.state.records;
    data.forEach((item) => {
      if (item.reservation_slug === reservation_slug) {
        item.pays = payAmountVal;
      }
    });
    await this.setState({ records: data });
  };

  changeHandler = async (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    await this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  async filterData() {
    let checkOutFromDate = this.state.checkout_date_from
      ? Moment(this.state.checkout_date_from).format("YYYY-MM-DD")
      : "";
    let checkOutDateTo = this.state.checkout_date_to
      ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
      : "";
    let queryString =
      "&checkout_date_from=" +
      checkOutFromDate +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&checkout_date_to=" +
      checkOutDateTo +
      "&is_rent_paid=" +
      (this.state.unit_rent_status_slug
        ? this.state.unit_rent_status_slug
        : "") +
      "&is_search=1";
    let moduleSearchString = "";
    let searchDisplay = "";
    moduleSearchString = this.state.checkout_date_from
      ? "check in date - " + this.state.checkout_date_from
      : "";
    moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString + " &" + " unit - " + this.state.unit_slug
        : " unit - " + this.state.unit_slug
      : moduleSearchString;
    searchDisplay =
      "Check In Date- " +
      Moment(checkOutFromDate).format("MM/DD/YY") +
      ", " +
      "Check Out Date- " +
      Moment(checkOutDateTo).format("MM/DD/YY");
    if (this.state.unit_lable) {
      searchDisplay = searchDisplay + ", Unit- " + this.state.unit_lable;
    }
    if (this.state.unit_rent_status_label) {
      searchDisplay =
        searchDisplay + ", Unit Rent - " + this.state.unit_rent_status_label;
    }
    if (
      this.state.checkout_date_from ||
      this.state.unit_slug ||
      this.state.checkout_date_to
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString });
      this.setState({ module_search_display: searchDisplay });
      await this.removeTotalClass();
      this.getPayOutReports(queryString);
    }
  }

  /* table events */
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getPayOutReports(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getPayOutReports(queryString);
    }
  };

  rowClickedHandlerPay = (event, data, rowIndex) => {
    if (data.unit_slug) {
      this.showPayoutReport(data.unit_slug);
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {
    /* if (data.reservation_slug) {
      let route = "reservations/view/" + data.reservation_slug;
      window.open(route, "_blank");
    } */
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    {this.props.match.params.propertySlug !== undefined ? (
                      <PayoutSidebar property_slug={this.state.property_slug} />
                    ) : (
                      <AllPropertyPayoutSidebar
                        property_slug={this.state.property_slug}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-10 right">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      {this.state.slug === undefined ? (
                        <h3>Create Resident Payout</h3>
                      ) : (
                        <h3>Draft Payout</h3>
                      )}
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {this.state.slug === undefined ? (
                          <button
                            className="btn-success-outline-small mr-3 button-width"
                            onClick={this.searchToggle}
                          >
                            Search
                          </button>
                        ) : (
                          ""
                        )}
                        <button
                          type="button"
                          className="btn-success-outline-small mr-3 button-width"
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/reservations"
                                    : "/reservations"
                                );
                              else return false;
                            else
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/res-payout-summary"
                                  : "/res-payout-summary"
                              );
                          }}
                        >
                          Cancel
                        </button>
                        {this.state.slug === undefined ? (
                          <button
                            className="btn-success-outline-small mr-3 button-width"
                            onClick={(e) => this.openSaveModal()}
                            disabled={this.state.isSave ? true : false}
                          >
                            {this.state.saveModel
                              ? global.loader
                              : this.state.slug !== undefined
                              ? "Save"
                              : "Save"}
                          </button>
                        ) : (
                          ""
                        )}
                        <button
                          className="btn-success-outline-small mr-3 button-width"
                          onClick={(e) => this.openSaveSendModal()}
                          disabled={this.state.isSubmit ? true : false}
                        >
                          {this.state.saveSendModel
                            ? global.loader
                            : this.state.slug !== undefined
                            ? "Save & Send"
                            : "Save & Send"}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>

                  {this.state.is_search === 1 ? (
                    <div className="col-md-12 pd-lg-l-45 pd-lg-r-45 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                      {"Search applied on: " + this.state.module_search_display}
                      <button
                        onClick={this.clearFilter}
                        className="mg-l-15 btn-success-outline-small"
                      >
                        Clear
                      </button>
                    </div>
                  ) : (
                    <div className="col-md-12 pd-lg-l-45 pd-md-l-45  pd-lg-r-45 pd-md-r-30   mg-t-0 d-inline-flex align-items-center justify-content-between">
                      {`Search applied on: Check Out From Date -  ${Moment(
                        this.state.checkout_date_from
                      ).format("MM/DD/YY")} , Check Out To Date - ${Moment(
                        this.state.checkout_date_to
                      ).format("MM/DD/YY")} `}
                    </div>
                  )}
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-lg-l-0 pd-lg-r-15 pd-xs-r-0">
                    <div className="scrolling-carousel pd-l-15 scroll-slide">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="all"
                        onSelect={(e) => this.getActiveClass(e)}
                        eventKey={this.state.activeClass}
                        className="pd-r-60"
                      >
                        <Tab.Content className="m-0">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <div className="mg-t-18 brandList pd-l-15 pd-r-15 payout-report-tbl- resident-payout-table earning-report-tbl gen-res-tbl">
                              <div className=" pd-lg-l-0 pd-xs-l-0">
                                <div className="scroll-report-table payout_tble_cust">
                                  <ReactDatatable
                                    className="table no-wrap mg-b-30 pd-b-2  brandtable border table_last_row report_fst_th"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Create Payout</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Check Out From Date</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="checkout_date_from"
                    selected={this.state.checkout_date_from}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "checkout_date_from",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Check Out To Date</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="checkout_date_to"
                      selected={this.state.checkout_date_to}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "checkout_date_to",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Unit Rent</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedUnitRentStatusList}
                      options={this.state.unitRentStatusList}
                      onChange={(value) =>
                        this.handleChangeUnitRentStatusList(value)
                      }
                      defaultValue={this.state.selectedUnitRentStatusList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={() => this.filterData()}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Export  popup modal */}
        <Modal show={this.state.isModel} className="modal-xs" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.exportToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Export</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <InputGroup className="mt-0">
                  <label>Select Export File Type : </label>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="xlsx"
                        checked={this.state.alertType === "xlsx" ? true : false}
                        type={"radio"}
                        id="xlsx"
                        label={"XLSX"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="csv"
                        checked={this.state.alertType === "csv" ? true : false}
                        type={"radio"}
                        id="csv"
                        label={"CSV"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="pdf"
                        checked={this.state.alertType === "pdf" ? true : false}
                        type={"radio"}
                        id="pdf"
                        label={"PDF"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                  </div>
                </InputGroup>

                {this.state.exportMesssage !== "" && (
                  <div>
                    {this.state.exportMesssage}
                    <br></br>
                    <br></br>
                    {/* {this.state.exportFilePath !== undefined && (
                      <a href={this.state.exportFilePath}> Download File </a>
                    )} */}
                  </div>
                )}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="mx-auto- mg-r-30 btn btn btn-success btn-block"
                    onClick={() => this.ExportData()}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Export"}
                  </button>
                  <button
                    type="button"
                    className="mx-auto- m-0  btn btn-outline-light btn-block"
                    onClick={() => this.exportToggle()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Pay Out Report Data */}
        <Modal
          show={this.state.is_pay_out_modal}
          className="modal-large searchModalbox"
          centered
          size="lg"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.payoutDataModalToggle()}
            >
              {global.closee}
            </span>
            <h3 className="text-center mb-0">
              {this.state.payoutDetailsTitle} Payout Detail
            </h3>
            <div className="row col-12 m-auto pd-lg-l-15 pd-lg-r-0 pd-xs-l-0 pd-xs-r-0">
              <div className="payout-report-tbl payout-details-tbl">
                <ReactDatatable
                  className="table no-wrap mg-b-30 pd-b-2 brandtable border table_last_row"
                  config={
                    this.state.total_record_pay > global.page_size
                      ? this.state.configPay
                      : this.state.configPay
                  }
                  records={this.state.record_pay}
                  columns={this.state.columns_pay}
                  extraButtons={this.state.extraButtons}
                  dynamic={true}
                  total_record={this.state.total_record_pay}
                  onChange={this.tableChangeHandler}
                  onRowClicked={this.rowClickedHandlerPay}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete amenity popup modal */}
        <Modal
          show={this.state.saveModel}
          onHide={() => {
            if (window.confirm("Are you sure to save report?"))
              this.closeSaveModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeSaveModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <h3>Draft Report </h3>
              <p>Do you want to save report as Draft?</p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeSaveModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={(e) => this.saveOrUpdatePayout(e, 0)}
                  disabled={this.state.isSave ? true : false}
                >
                  {this.state.isSave ? global.loader : "Save "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete amenity popup modal */}
        <Modal
          show={this.state.saveSendModel}
          onHide={() => {
            if (window.confirm("Are you sure to save report?"))
              this.closeSaveModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeSaveSendModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <h3>Finalize Payout</h3>
              <p>
                Do you really want to save the Report? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeSaveSendModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block button-width"
                  onClick={(e) => this.saveOrUpdatePayout(e, 1)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Save & Send "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default ResidentPayoutsReportsCreate;
