//     							                          //
//  Program: HouseKeepingSidebar.jsx                      //
//  Application: Property                                 //
//  Option: common component House Keeping Sidebar        //
//  Developer: NP 		                                  //
//  Date: 2022-04-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";

class HouseKeepingSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  toggleChildMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".left");
    data.classList.replace("left", "hide");
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Cleaning
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  {/* <span>Cleaning</span> */}
                  <ul>
                    {global.userPermissions.checkPermission(
                      "cleaning-calendar-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("housekeeping-calender")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/housekeeping-calender"
                          }
                        >
                          Calendar
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "cleaning-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("housekeeping-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/housekeeping-list"
                          }
                        >
                          Cleaning
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "maintenance-requests-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("problems")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/problems"
                          }
                        >
                          Problems
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>

                <li>
                  {global.userPermissions.checkPermission(
                    "maintenance-calendar-list"
                  ) ||
                  global.userPermissions.checkPermission(
                    "maintenance-requests-list"
                  ) ? (
                    <span>Maintenance</span>
                  ) : (
                    ""
                  )}
                  <ul>
                    {global.userPermissions.checkPermission(
                      "maintenance-calendar-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("maintenance-calendar")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/maintenance-calendar"
                          }
                        >
                          Calendar
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "maintenance-requests-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("mros")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/mros"
                          }
                        >
                          Maintenance Requests
                        </Link>
                      </li>
                    )}
                    {/* {global.userPermissions.checkPermission(
                      "maintenance-calendar-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("checklists")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/checklists"
                          }
                        >
                          Checklists
                        </Link>
                      </li>
                    )} */}
                    {/* {global.userPermissions.checkPermission(
                      "inspections-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("inspections")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/inspections"
                          }
                        >
                          Inspections
                        </Link>
                      </li>
                    )} */}
                  </ul>
                </li>

                {/* <li>
                  <span>Vendors</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "work-orders-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("work-order")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/work-order"
                          }
                        >
                          Work Orders
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("vendors-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("housekeeping-vendoor-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/housekeeping-vendoor-list"
                          }
                        >
                          Vendors
                        </Link>
                      </li>
                    )}
                  </ul>
                </li> */}
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
             {/* New submenu */}
            <div className="newSubmenu">
              {/* <a href="/" className="back subMenu"> <i className="fas fa-chevron-left"></i> Listings</a> */}
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Cleaning
              </span>
              <ul>
                <li>
                  {/* <span>Cleaning</span> */}
                  <ul>
                    {global.userPermissions.checkPermission(
                      "cleaning-calendar-list"
                    ) && (
                      <li>
                        <span>Cleaning</span>
                        <ul>
                          {global.userPermissions.checkPermission(
                            "cleaning-calendar-list"
                          ) && (
                            <li>
                              <Link
                                className={
                                  this.isPathActive("housekeeping-calender")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                data-toggle="tab"
                                to={
                                  "/properties/view/" +
                                  (this.props.property_slug
                                    ? this.props.property_slug
                                    : this.state.property_slug) +
                                  "/housekeeping-calender"
                                }
                              >
                                Calendar
                              </Link>
                            </li>
                          )}
                          {global.userPermissions.checkPermission(
                            "cleaning-list"
                          ) && (
                            <li>
                              <Link
                                className={
                                  this.isPathActive("housekeeping-list")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                data-toggle="tab"
                                to={
                                  "/properties/view/" +
                                  (this.props.property_slug
                                    ? this.props.property_slug
                                    : this.state.property_slug) +
                                  "/housekeeping-list"
                                }
                              >
                                Cleaning
                              </Link>
                            </li>
                          )}
                          {global.userPermissions.checkPermission(
                            "maintenance-requests-list"
                          ) && (
                            <li>
                              <Link
                                className={
                                  this.isPathActive("problems")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                data-toggle="tab"
                                to={
                                  "/properties/view/" +
                                  (this.props.property_slug
                                    ? this.props.property_slug
                                    : this.state.property_slug) +
                                  "/problems"
                                }
                              >
                                Problems
                              </Link>
                            </li>
                          )}
                        </ul>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "cleaning-list"
                    ) && (
                      <li>
                        {global.userPermissions.checkPermission(
                          "maintenance-calendar-list"
                        ) ||
                        global.userPermissions.checkPermission(
                          "maintenance-requests-list"
                        ) ? (
                          <span>Maintenance</span>
                        ) : (
                          ""
                        )}
                        <ul>
                          {global.userPermissions.checkPermission(
                            "maintenance-calendar-list"
                          ) && (
                            <li>
                              <Link
                                className={
                                  this.isPathActive("maintenance-calendar")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                data-toggle="tab"
                                to={
                                  "/properties/view/" +
                                  (this.props.property_slug
                                    ? this.props.property_slug
                                    : this.state.property_slug) +
                                  "/maintenance-calendar"
                                }
                              >
                                Calendar
                              </Link>
                            </li>
                          )}
                          {global.userPermissions.checkPermission(
                            "maintenance-requests-list"
                          ) && (
                            <li>
                              <Link
                                className={
                                  this.isPathActive("mros")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                data-toggle="tab"
                                to={
                                  "/properties/view/" +
                                  (this.props.property_slug
                                    ? this.props.property_slug
                                    : this.state.property_slug) +
                                  "/mros"
                                }
                              >
                                Maintenance Requests
                              </Link>
                            </li>
                          )}
                          {/* {global.userPermissions.checkPermission(
                            "checklists-list"
                          ) && (
                            <li>
                              <Link
                                className={
                                  this.isPathActive("checklists")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                data-toggle="tab"
                                to={
                                  "/properties/view/" +
                                  (this.props.property_slug
                                    ? this.props.property_slug
                                    : this.state.property_slug) +
                                  "/checklists"
                                }
                              >
                                Checklists
                              </Link>
                            </li>
                          )} */}
                          {/* {global.userPermissions.checkPermission(
                            "inspections-list"
                          ) && (
                            <li>
                              <Link
                                className={
                                  this.isPathActive("inspections")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                data-toggle="tab"
                                to={
                                  "/properties/view/" +
                                  (this.props.property_slug
                                    ? this.props.property_slug
                                    : this.state.property_slug) +
                                  "/inspections"
                                }
                              >
                                Inspections
                              </Link>
                            </li>
                          )} */}
                          {/* <li>
                            <Link
                              className={
                                this.isPathActive("maintenance-checklists")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/maintenance-checklists"}
                            >
                              Checklists
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                this.isPathActive("maintenance-inbox")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/maintenance-inbox"}
                            >
                              Inbox
                            </Link>
                          </li> */}
                        </ul>
                      </li>
                    )}
                    {/* {global.userPermissions.checkPermission(
                      "maintenance-requests-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("problems")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/problems"
                          }
                        >
                          Problems
                        </Link>
                      </li>
                    )} */}
                  </ul>
                </li>

                <li>
                  {global.userPermissions.checkPermission(
                    "maintenance-calendar-list"
                  ) ||
                  global.userPermissions.checkPermission(
                    "maintenance-requests-list"
                  ) ? (
                    <span>Maintenance</span>
                  ) : (
                    ""
                  )}
                  <ul>
                    {global.userPermissions.checkPermission(
                      "maintenance-calendar-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("maintenance-calendar")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/maintenance-calendar"
                          }
                        >
                          Calendar
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "maintenance-requests-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("mros")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/mros"
                          }
                        >
                          Maintenance Requests
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "checklists-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("checklists")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/checklists"
                          }
                        >
                          Checklists
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "inspections-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("inspections")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/inspections"
                          }
                        >
                          Inspections
                        </Link>
                      </li>
                    )}
                    {/* <li>
                      <Link
                        className={
                          this.isPathActive("maintenance-checklists")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/maintenance-checklists"}
                      >
                        Checklists
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          this.isPathActive("maintenance-inbox")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/maintenance-inbox"}
                      >
                        Inbox
                      </Link>
                    </li> */}
                  </ul>
                </li>

                {/* <li>
                  <span>Vendors</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "work-orders-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("work-order")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/work-order"
                          }
                        >
                          Work Orders
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "vendors-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("housekeeping-vendoor-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/housekeeping-vendoor-list"
                          }
                        >
                          Vendors
                        </Link>
                      </li>
                    )}
                  </ul>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default HouseKeepingSidebar;
