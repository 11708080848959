////////////////////////////////////////////////////////////
//     							                          //
//  Program: Applicants.jsx                                    //
//  Application: influencers                                    //
//  Option: List all influencers                                //
//  Developer: Ashish Kumar 						              //
//  Date: 2022-02-09                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import { Container, Modal, InputGroup, Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import ApplicantsService from "../../services/ApplicantsService";
import Moment from "moment";
import PhoneInput from "react-phone-input-2";
import LeasingSidebar from "../common/LeasingSidebar";
import queryString from "query-string";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import filterIcon from "./../../assets/images/filter-gray.svg";

let queryParams = queryString.parse(window.location.search);

export class Applicants extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      is_upcoming: false,
      start_date: "",
      end_date: "",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: -1,
      property_slug: this.props.match.params.propertySlug,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found.",
        },
      },
      extraButtons: [],
      columns: [
        // {
        //     key: "applicant_photo",
        //     text: "Applicant Photo",
        //     className: "applicant_photo hand-cursor td_clickable",
        //     sortable: true,
        //     cell: (record) => {
        //         return <img src={record.applicant_photo_url ? record.applicant_photo_url : photo} className="brand_list_image" alt="" />;
        //     },
        // },
        {
          key: "first_name",
          text: "First Name",
          className: "first_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.first_name ? record.first_name : "";
          },
        },
        {
          key: "last_name",
          text: "Last Name",
          className: "last_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.last_name ? record.last_name : "";
          },
        },
        {
          key: "email",
          text: "Email",
          className: "email hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        {
          key: "phone_number",
          text: "Phone",
          className: "phone_number hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            var lastTenDigits = "";
            const cleanedNumber = record.phone_number
              ? record.phone_number.replace(/[\s-]/g, "")
              : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits = record.phone_number ? record.phone_number : [];
            }

            var number = record.phone_number ? lastTenDigits.trim().replace(/[^0-9]/g, "") : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return record.phone_number ? finalnumber : "";
          },
        },
        {
          key: "total_applicant_details_count",
          text: "Number of Inquiry",
          className:
            "total_applicant_details_count hand-cursor td_clickable text-left",
          sortable: false,
          cell: (record) => {
            return record.total_applicant_details_count
              ? record.total_applicant_details_count
              : "0";
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.getData = this.getData.bind(this);
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  filterData() {
    let queryString =
      "first_name=" +
      (this.state.first_name ? this.state.first_name : "") +
      "&last_name=" +
      (this.state.last_name ? this.state.last_name : "") +
      "&email=" +
      (this.state.email ? this.state.email : "") +
      "&phone_number=" +
      (this.state.phone_number ? this.state.phone_number : "") +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.first_name
      ? "Applicant First Name - " + this.state.first_name
      : "";

    moduleSearchString = this.state.last_name
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          "Applicant Last Name - " +
          this.state.last_name
        : "Applicant Last Name - " + this.state.last_name
      : moduleSearchString;

    moduleSearchString = this.state.email
      ? moduleSearchString
        ? moduleSearchString + " ," + " Email - " + this.state.email
        : " Email - " + this.state.email
      : moduleSearchString;

    moduleSearchString = this.state.phone_number
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Phone Number - " +
          this.state.phone_number
        : " Phone Number - " + this.state.phone_number
      : moduleSearchString;

    if (
      this.state.first_name ||
      this.state.last_name ||
      this.state.email ||
      this.state.phone_number
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(queryString);
      //this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      // this.setState({ showSpinner: true, loading: true });
      if (queryParams.isGlobal === "1") {
        this.getData(
          "is_search=1&" +
            queryParams.global_col_name +
            "=" +
            queryParams.global_search
        );
      } else {
        this.getData();
      }
    }
    // this.setState({ showSpinner: false, loading: false });
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      if (queryParams.isGlobal === "1") {
        this.getData(
          "is_search=1&" +
            queryParams.global_col_name +
            "=" +
            queryParams.global_search
        );
      } else {
        this.getData();
      }
    }, 200);
    this.setState({
      module_search: [],
      is_search: 0,
    });
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await ApplicantsService.getApplicants(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
  }

  customFilter() {
    let startDate = this.state.start_date
      ? Moment(this.state.start_date).format("YYYY-MM-DD")
      : "";
    let endDate = this.state.end_date
      ? Moment(this.state.end_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "start_date=" +
      startDate +
      "&end_date=" +
      endDate +
      "&is_upcoming=" +
      (this.state.is_upcoming === true ? 1 : 0) +
      "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ is_upcoming: false, start_date: "", end_date: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
            window.scrollTo(0, 0);
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString + "&is_list=1"),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString + "&is_list=1");
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Applicants";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      this.props.match.params.propertySlug !== undefined
        ? "/properties/view/" +
            this.state.property_slug +
            "/applicants/view/" +
            data.slug
        : this.state.property_slug
        ? "/applicants/view/" + this.state.property_slug + "/" + data.slug
        : "/applicants/view/" + data.property_slug + "/" + data.slug
    );
  };

  render() {
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <LeasingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyLeasingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}

                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      {/* <div className="d-flex flex-wrap justify-content-end pd-l-0 pd-r-0 mg-b-0">
                                            <Link to={"applicants/add"}>
                                                <button className="btn-success-outline-small mr-3">
                                                    <img src={pluswIcon} alt="" /> Add
                                                </button>
                                            </Link>
                                        </div> */}
                      <button
                        onClick={this.searchToggle}
                        className="btn-success-outline-small ml-3"
                      >
                        <img src={filterIcon} alt="filterIcon" />{" "}
                        {this.state.isSearch === 1 ? "Search" : "Search"}
                      </button>
                    </div>
                  </div>

                  {this.props.match.params.propertySlug === undefined ? (
                    <>
                      <div className="row pd-lg-l-15 pd-xs-l-15">
                        <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                          <OutSidePropertySelectBox
                            propertySlug={this.getPropertySlug.bind(this)}
                            outSidePropertySlug={this.state.outSidePropertySlug}
                          />
                        </div>
                        <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                          {this.state.is_search === 1 ? (
                            <>
                              <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                                <div className="custm-filter">
                                  <div>
                                    {" "}
                                    <label className="form-label text-left pd-b-2 mb-0">
                                      Search Applied On
                                    </label>
                                  </div>
                                  <div className="filter_bg_cust">
                                    {this.state.module_search.map((item, i) => {
                                      return <span key={i}>{item}</span>;
                                    })}
                                    <button
                                      onClick={this.clearFilter}
                                      className="btn-fliter-clear"
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-xxl-12 pd-r-15 pd-l-15">
                        {this.state.is_search === 1 ? (
                          <>
                            <div className="col-xxl-12 col-sm-12 pd-lg-r-15 pd-lg-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                              <div className="custm-filter">
                                <div>
                                  {" "}
                                  <label className="form-label text-left pd-b-2 mb-0">
                                    Search Applied On
                                  </label>
                                </div>
                                <div className="filter_bg_cust">
                                  {this.state.module_search.map((item, i) => {
                                    return <span key={i}>{item}</span>;
                                  })}
                                  <button
                                    onClick={this.clearFilter}
                                    className="btn-fliter-clear"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                  <div className="scrolling-carousel pd-l-15 pd-xs-l-0 pd-lg-l-45 pd-xs-r-0 pd-lg-r-0">
                    <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      {queryParams.isGlobal === "1" ? (
                        <>
                          <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                            {"Search applied on: " +
                              queryParams.global_col_label +
                              " - " +
                              queryParams.global_search}
                            <button
                              onClick={this.clearFilter}
                              className="mg-l-15 btn-success-outline-small"
                            >
                              Clear
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {this.state.total_record > 0 ||
                      this.state.is_search === 1 ? (
                        <div className="mg-t-18 brandList p-0">
                          <div className="pd-lg-l-0 pd-xs-l-15">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              // loading={this.state.loading}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              onRowClicked={this.rowClickedHandler}
                            />
                          </div>
                        </div>
                      ) : (
                        // <NoData msg="applicants" />
                        <div className="mg-t-30 text-center">
                          <p>No data found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.searchToggle();
          }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Applicants</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Applicant First Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="first_name"
                      value={this.state.first_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      placeholder="Applicant First Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Phone Number</label>
                  <div className="col-md-12 p-0">
                    <PhoneInput
                      country={global.country}
                      disableSearchIcon
                      // autoFormat= "true"
                      enableSearch="true"
                      countryCodeEditable="true"
                      value={this.state.phone_number}
                      onChange={(phone_number) =>
                        this.setState({ phone_number })
                      }
                      placeholder="Phone Number"
                    />
                    {/* <Form.Control
                      onChange={this.changeHandler}
                      name="phone_number"
                      value={this.state.phone_number}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      placeholder="Phone Number"
                    /> */}
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Applicant Last Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="last_name"
                      value={this.state.last_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      placeholder="Applicant Last Name"
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Email</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="email"
                      value={this.state.email}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      placeholder="Email"
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Export  popup modal */}
      </>
    );
  }
}

export default Applicants;
