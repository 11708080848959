////////////////////////////////////////////////////////////
//     							                          //
//  Program: ClubService.js                              //
//  Application: Club                                    //
//  Option: Used for manage club                        //
//  Developer: Somin Kumar  						      //
//  Date: 2023-09-08                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class ClubService {

  getClubist(queryString) {
    let endPoint = "clubs" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getClub(slug) {
    let endPoint = "clubs/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createClub(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "clubs";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateClub(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "clubs/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteClub(slug) {
    let endPoint = "clubs/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  uploadClubLogo(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'upload-club-logo';
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      'endPoint': endPoint,
      'addditionalHeaderData': addditionalHeaderData,
      'postData': postData
    }
    let response = ApiService.postCall(inputData);
    return response;
  }

  uploadClubMainImage(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'upload-club-main-image';
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      'endPoint': endPoint,
      'addditionalHeaderData': addditionalHeaderData,
      'postData': postData
    }
    let response = ApiService.postCall(inputData);
    return response;
  }

  // for brand legal entity data
  getClubLegalEntity(slug) {
    let endPoint = "club-legel-entity/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateClubLegalEntity(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "insert-or-update-club-legel-entity";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

}

export default new ClubService();
