////////////////////////////////////////////////////////////
//     							                                      //
//  Program: OutSidePropertySelectBox.jsx                 //
//  Application: OutSidePropertySelectBox                 //
//  Option: List of all Property                          //
//  Developer: Ashish Kumar					                      //
//  Date: 2022-02-03                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import PropertyService from "../../services/PropertyService";
import Select from "react-select";

export class OutSidePropertySelectBox extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      errors: {},
      propertyList: [],
      newPropertyList: [],
      selectedPropertyList: [],
      propertyListTotal: 0,
    };

    this.handleChangePropertyList = this.handleChangePropertyList.bind(this);
  }

  componentDidMount() {
    this.getPropertyList();
  }

  /* to get property list data */
  async getPropertyList(queryString = "") {
    var propertyList = [];
    var propertyListTotal = 0;
    var selectedPropertyList = [{value: '', label: "All Properties"}];
    let res = await PropertyService.getPropertyList(
      "is_global_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;
    }
    const newPropertyList =
      propertyListTotal > 0
        ? propertyList.map(({ slug, property_name }) => ({
            value: slug,
            label: property_name,
          }))
        : [];
    if (
      localStorage.getItem("outSidePorpertySlug") !== undefined &&
      localStorage.getItem("outSidePorpertySlug") !== null &&
      localStorage.getItem("outSidePorpertySlug") !== ""
    ) {
      if (propertyListTotal > 0) {
        propertyList.map((opt, i) => {
          if (opt.slug === localStorage.getItem("outSidePorpertySlug")) {
            selectedPropertyList = [
              {
                value: opt.slug,
                label: opt.property_name,
              },
            ];
          }
        });
      }
    } 
    // else {
    //   if (propertyListTotal > 0) {
    //     selectedPropertyList = [
    //       {
    //         value: propertyList[0].slug,
    //         label: propertyList[0].property_name,
    //       },
    //     ];
    //     localStorage.setItem("outSidePorpertySlug", propertyList[0].slug);
    //   }
    // }
    const customOptions = [
      {
        value: '',
        label: 'All Properties',
      },
      ...newPropertyList,
    ];
    this.setState({
      propertyList: propertyList,
      propertyListTotal: propertyListTotal,
      newPropertyList: customOptions,
      selectedPropertyList: selectedPropertyList,
    });
    this.props.propertySlug(localStorage.getItem("outSidePorpertySlug"));
  }

  handleChangePropertyList(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    const property_slug_value = value !== null ? value.value : [];
    const property_slug_label = value !== null ? value.label : [];
    this.setState({
      selectedPropertyList: value,
      property_slug: property_slug_value,
    });
    this.props.propertySlug(property_slug_value);
    localStorage.setItem("outSidePorpertySlug", property_slug_value);
    localStorage.setItem("selectedPropertyName", property_slug_label);
    this.setState({ confirm_back: 1 });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-0 pd-lg-r-30 pd-l-0 pd-xs-r-0 mg-b-20">
        <label className="form-label text-left pd-b-2 mb-0">Property</label>
        <Select
          styles={customStyles}
          className="multiselect property_select"
          menuPlacement="bottom"
          value={this.state.selectedPropertyList}
          options={this.state.newPropertyList}
          onChange={(value) => this.handleChangePropertyList(value)}
          defaultValue={this.state.selectedPropertyList}
          theme={(theme) => ({
            ...theme,
            borderRadius: 2,
            colors: {
              ...theme.colors,
              primary: "#fff",
              primary75: "#000",
              primary50: "#000",
              primary25: "#000",
            },
          })}
        />
      </div>
    );
  }
}

export default OutSidePropertySelectBox;
