//                                                           //
//  Program: RentalUnitLookup.jsx                               //
//  Application: Rental United                               //
//  Option: Rental Lookup tables                             //
//  Developer: NP 		                                     //
//  Date: 2022-09-13                                         //
//                                                           //
/////////////////////////////////////////////////////////   ///

import React, { Component } from "react";
import RUSidebar from "../common/RUSidebar";
import Select from "react-select";
import { Container, Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import PropertyService from "../../services/PropertyService";
import UnitsService from "../../services/UnitsService";
import RentalService from "../../services/RentalService";
import SimpleReactValidator from "simple-react-validator";

class RentalUnitLookup extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      input: {},
      errors: {},
      propertyList: [],
      newPropertyList:[],
      selectedPropertyList:[],
      unitList: [],
      selectedUnitList: [],
      property_slug: "",
      unit_slug:"",
      is_managed_by_private_person : false,
      is_managed_by_owner : false,
      security_deposit : "",
      location_description : ""
    };
    this.saveData = this.saveData.bind(this);
  }

  componentDidMount(){
    this.getPropertyData();
  }

  async getPropertyData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var propertyList = [];
    var propertyListTotal = 0;
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0
    }
    const newPropertyList = propertyListTotal > 0 ? propertyList.map(({ slug, property_name }) => ({
      value: slug,
      label: property_name,
    })) : [];
    this.setState({ propertyList: propertyList, newPropertyList: newPropertyList, propertyListTotal: propertyListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  async getUnitDetails(value) {
    if (value !== undefined) {
      var resData = {};

      let res = await UnitsService.getUnits(value);
      if (global.successStatus.includes(res.status)) {
        resData.is_managed_by_private_person = res.data.ruUnitData ? (res.data.ruUnitData.is_managed_by_private_person === 1 ? true : false) : false
        resData.is_managed_by_owner = res.data.ruUnitData ? (res.data.ruUnitData.is_managed_by_owner === 1 ? true : false) : false
        resData.security_deposit = res.data.ruUnitData ? res.data.ruUnitData.security_deposit : ""
        resData.location_description = res.data.ruUnitData ? res.data.ruUnitData.location_description : ""
      }
      this.setState(resData);
    }
  }

  handleChangePropertyList(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    if(value){
      this.getUnitData(value.value);
    }
    this.setState({
      selectedPropertyList: value,
      property_slug: value.value,
    });
  }

  async getUnitData(value) {
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + value
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number , unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    if(value){
      this.getUnitDetails(value.value);
    }
    this.setState({
      selectedUnitList: value,
      unit_slug: value.value
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  saveData = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
        is_managed_by_private_person:this.state.is_managed_by_private_person === true ? 1 : 0,
        is_managed_by_owner:this.state.is_managed_by_owner === true ? 1 : 0,
        security_deposit: this.state.security_deposit ? this.state.security_deposit : "",
        location_description: this.state.location_description ? this.state.location_description : ""
      };
     
      let res = await RentalService.createUnitData(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        // setTimeout(
        //   () =>
        //     this.props.history.push(
        //       "/properties/view/" +
        //       this.state.property_slug +
        //       "/guest/view/" +
        //       this.state.slug
        //     ),
        //   global.redirect_time
        // );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
     
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  render() {
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
          color: (state.isSelected && state.isFocused) ? "white" : (state.isSelected ? "black" : state.isFocused && "white"),
          backgroundColor: (state.isSelected && state.isFocused) ? 'black' : (state.isFocused && "black"),
        })
      }
    return (
      <>
        <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
            <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                <div className="child-menu-section">
                    <aside>
                    <RUSidebar />
                    </aside>
                </div>
                </div>
                <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>Unit</h3>
                    <div className="d-flex justify-content-end">
                      {global.userPermissions.checkPermission('ru-integration-update') &&
                        <button
                        type="button"
                        className="btn-success ml-3"
                        onClick={this.saveData}
                        disabled={this.state.isSubmit ? true : false}
                        >
                        {this.state.isSubmit
                            ? global.loader : "Save"}
                        </button>
                      }
                    </div>
                    </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                    <form id="units_form">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                        <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                        />
                        </div>
                        <div className="m-0">
                        <div className="row align-items-center pd-b-0 border-bottom-0  pd-xs-l-0 pd-xs-r-0  pd-lg-l-0 pd-lg-r-15">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Property{" "}
                            </label>
                            <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedPropertyList}
                            options={this.state.newPropertyList}
                            getOptionValue={option => `${option.label}`}
                            onChange={(value) => this.handleChangePropertyList(value)}
                            defaultValue={this.state.selectedPropertyList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: '#fff',
                                primary75: '#000',
                                primary50: '#000',
                                primary25: '#000',
                              }
                            })}
                          />
                            {this.state.errors.property_slug ? (
                                <div className="text-danger">
                                {this.state.errors.property_slug}
                                </div>
                            ) : (
                                this.validator.message(
                                "property",
                                this.state.property_slug,
                                "required",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Unit{" "}
                            </label>
                              <Select
                                styles={customStyles}
                                isClearable={true}
                                className="multiselect"
                                menuPlacement="auto"
                                value={this.state.selectedUnitList}
                                options={this.state.newUnitList}
                                onChange={(value) =>
                                  this.handleChangeUnitList(value)
                                }
                                defaultValue={this.state.selectedUnitList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                            {this.state.errors.unit_slug ? (
                              <div className="text-danger">
                                {this.state.errors.unit_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "unit",
                                this.state.unit_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                            </div>
                        </div>
                        {this.state.property_slug && this.state.unit_slug ?
                        <div className="row align-items-center pd-b-0 border-bottom-0  pd-xs-l-0 pd-xs-r-0  pd-lg-l-0 pd-lg-r-15">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Security Deposit{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="security_deposit"
                                value={this.state.security_deposit}
                                maxLength="50"
                                onKeyPress={
                                  global.onKeyPressEvent.floatValidation
                                }
                            />
                            {this.state.errors.security_deposit ? (
                                <div className="text-danger">
                                {this.state.errors.security_deposit}
                                </div>
                            ) : (
                                this.validator.message(
                                "security_deposit",
                                this.state.security_deposit,
                                "max:50",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Managed by Private{" "}
                            </label>
                              <Form.Check
                                name="is_managed_by_private_person"
                                type={"checkbox"}
                                id={`is_managed_by_private_person`}
                                label=""
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.is_managed_by_private_person}
                              />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Managed by Owner
                            </label>
                              <Form.Check
                                name="is_managed_by_owner"
                                type={"checkbox"}
                                id={`is_managed_by_owner`}
                                label=""
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.is_managed_by_owner}
                              />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Location Description{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="location_description"
                                value={this.state.location_description}
                                maxLength="1000"
                                as="textarea"
                            />
                            {this.state.errors.location_description ? (
                                <div className="text-danger">
                                {this.state.errors.location_description}
                                </div>
                            ) : (
                                this.validator.message(
                                "location_description",
                                this.state.location_description,
                                "max:1000",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                        </div>
                        : ''}
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
        </Container>
        </main>
      </>
    );
  }
}
export default RentalUnitLookup