/*-------------------------------------------------------
||Program           : GuestReviews.jsx
||Application       : Reservation
||Option            : Reservation - Need to create a Listing screen for Guest Rating
||Developer         : Chandra Shekhar
||Date              : 14-July-2023
||Task ID           : PRJ423-T1730
||Status            : In Use
----------------------------------------------------------*/

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import { Container, Modal, InputGroup, Form } from "react-bootstrap";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Alerts from "../common/Alerts";
import NoDataSearch from "../common/NoDataSearch";
import CalendarSidebar from "../common/CalendarSidebar";
import queryString from "query-string";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyReservationSidebar from "../common/AllPropertyReservationSidebar";
import ReservationService from "../../services/ReservationService";
import StarRatings from "react-star-ratings";
import Select from "react-select";
import UnitsService from "../../services/UnitsService";
import DatePicker from "react-datepicker";
import Moment from "moment";

let queryParams = queryString.parse(window.location.search);

export class GuestReviews extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      tableQueryString: "",
      customQueryString: "",
      start_date: "",
      end_date: "",
      input: {},
      showAlertModal: false,
      endDateError: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      filter_value: "",
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "reservation_slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "reservation_slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "unit_name",
          text: "Unit Name",
          className: "unit_name",
          sortable: true,
          cell: (record) => {
            return record.unit_name ? record.unit_name : "";
          },
        },
        {
          key: "recipient_name",
          text: "Guest Name",
          className: "recipient_name",
          sortable: true,
          cell: (record) => {
            return record.recipient_name ? record.recipient_name : "";
          },
        },
        {
          key: "confirmation_code",
          text: "Confirmation Code",
          className: "confirmation_code",
          sortable: true,
          cell: (record) => {
            return record.confirmation_code ? record.confirmation_code : "";
          },
        },
        {
          key: "created_at",
          text: "Check-Out",
          className: "created_at",
          sortable: true,
          cell: (record) => {
            return record.created_at
              ? Moment(record.created_at).format(global.dateFormat)
              : "";
          },
        },
        {
          key: "rating",
          text: "Rating",
          className: "rating text-left",
          sortable: true,
          cell: (record) => {
            // return record.rating ? record.rating : "";
            return (
              <StarRatings
                rating={record.rating ? parseFloat(record.rating) : 0}
                starRatedColor="rgb(255,165,52)"
                numberOfStars={5}
                starDimension="20px"
              />
            );
          },
        },
      ],
      module_search: "",
      is_search: 0,
      isSearch: global.isSearch,
      guest_name: "",
      confirmation_code: "",
      rating: "",
      unit_slug: "",
      unit_number: "",
      selectedUnitList: [],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      if (queryParams.isGlobal === "1") {
        this.getData(
          "is_search=1&" +
            queryParams.global_col_name +
            "=" +
            queryParams.global_search
        );
      } else {
        this.getData();
      }
    }
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      if (queryParams.isGlobal === "1") {
        this.getData(
          "is_search=1&" +
            queryParams.global_col_name +
            "=" +
            queryParams.global_search
        );
      } else {
        this.getData();
      }
    }, 200);
    this.setState({
      selectedUnitList: [],
      module_search: [],
      is_search: 0,
    });
  }

  async getData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    let res = await ReservationService.getReviews(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" +
        (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      guest_name: "",
      confirmation_code: "",
      rating: "",
    });
    this.setState({ module_search: "" });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getUnitData();
      this.setState({ isSearch: 1 });
    }
  };

  filterData() {
    let startDate = this.state.start_date
      ? Moment(this.state.start_date).format("YYYY-MM-DD")
      : "";
    let endDate = this.state.end_date
      ? Moment(this.state.end_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "guest_name=" +
      (this.state.guest_name ? this.state.guest_name : "") +
      "&confirmation_code=" +
      (this.state.confirmation_code ? this.state.confirmation_code : "") +
      "&rating=" +
      (this.state.rating ? this.state.rating : "") +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&start_date=" +
      startDate +
      "&end_date=" +
      endDate +
      "&is_search=1";
    let moduleSearchString = "";

    moduleSearchString = this.state.guest_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Guest Name - " + this.state.guest_name
        : " Guest Name - " + this.state.guest_name
      : moduleSearchString;

    moduleSearchString = this.state.confirmation_code
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Confirmation Code - " +
          this.state.confirmation_code
        : " Confirmation Code - " + this.state.confirmation_code
      : moduleSearchString;

    moduleSearchString = this.state.rating
      ? moduleSearchString
        ? moduleSearchString + " ," + " Rating Value - " + this.state.rating
        : " Rating Value - " + this.state.rating
      : moduleSearchString;

      moduleSearchString = this.state.start_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Start Date - " +
          Moment(this.state.start_date).format(global.dateFormat)
        : " Start Date - " +
          Moment(this.state.start_date).format(global.dateFormat)
      : moduleSearchString;

      moduleSearchString = this.state.end_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " End Date - " +
          Moment(this.state.end_date).format(global.dateFormat)
        : " End Date - " +
          Moment(this.state.end_date).format(global.dateFormat)
      : moduleSearchString;

    moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Unit - " +
          this.state.selectedUnitList.label
        : " Unit - " + this.state.selectedUnitList.label
      : moduleSearchString;
      

    if (
      this.state.guest_name ||
      this.state.confirmation_code ||
      this.state.rating ||
      this.state.unit_slug ||
      this.state.start_date  ||
      this.state.end_date
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(queryString);
    }
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_number: value.label,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Guest Reviews";
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      this.props.match.params.propertySlug !== undefined
        ? "/properties/view/" +
            data.property_slug +
            "/reservations/view/" +
            data.reservation_slug
        : this.state.property_slug
        ? "/properties/view/" +
          this.state.property_slug +
          "/reservations/view/" +
          data.reservation_slug
        : "/properties/view/" +
          data.property_slug +
          "/reservations/view/" +
          data.reservation_slug
    );
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <CalendarSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyReservationSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button>
                      </div>
                    </div>
                  </div>

                  {this.props.match.params.propertySlug === undefined ? (
                    <div className="col-xxl-2 col-sm-3 pd-r-0 pd-lg-l-45 pd-xs-l-15 ">
                      <OutSidePropertySelectBox
                        propertySlug={this.getPropertySlug.bind(this)}
                        outSidePropertySlug={this.state.outSidePropertySlug}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="scrolling-carousel pd-l-15 pd-r-15">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-l-30 pd-r-30">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      {queryParams.isGlobal === "1" ? (
                        <>
                          <div className="col-md-12 pd-lg-l-30 pd-xs-l-15 pd-lg-r-15 mg-t-0 d-inline-flex align-items-center justify-content-between ">
                            {"Search applied on: " +
                              queryParams.global_col_label +
                              " - " +
                              queryParams.global_search}
                            <button
                              onClick={this.clearFilter}
                              className="mg-l-15 btn-success-outline-small"
                            >
                              Clear
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.is_search === 1 ? (
                        <>
                          <div className="col-xxl-12 col-sm-12 pd-lg-r-15 pd-lg-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                            <div className="custm-filter">
                              <div>
                                {" "}
                                <label className="form-label text-left pd-b-2 mb-0">
                                  Search Applied On
                                </label>
                              </div>
                              <div className="filter_bg_cust">
                                {this.state.module_search.map((item, i) => {
                                  return <span key={i}>{item}</span>;
                                })}
                                <button
                                  onClick={this.clearFilter}
                                  className="btn-fliter-clear"
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {this.state.total_record > 0 ||
                      this.state.filter_value !== "" ||
                      this.state.is_search === 1 ? (
                        <div className="row d-block mg-t-18 brandList ml-0 p-l-4 pd-r-0">
                          <div className="pd-lg-l-0 pd-xs-l-15 pd-lg-r-0 pd-xs-r-15">
                            <ReactDatatable
                              className={
                                global.userPermissions.checkPermission(
                                  "guests-update"
                                )
                                  ? "table no-wrap mg-b-30 pd-b-2 text-align-left hand-cursor"
                                  : "table no-wrap mg-b-30 pd-b-2 text-align-left"
                              }
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              onRowClicked={
                                global.userPermissions.checkPermission(
                                  "guests-update"
                                ) && this.rowClickedHandler
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <NoDataSearch
                          msg={global.commonMessages.checkMessage(
                            "SEARCHGUESTREVIEWS"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Guest Reviews</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Guest Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="guest_name"
                      value={this.state.guest_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Confirmation Code</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="confirmation_code"
                      value={this.state.confirmation_code}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Rating</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="rating"
                      value={this.state.rating}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Start Date</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="start_date"
                    selected={this.state.start_date}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "start_date",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
              <InputGroup className={`mt-3 ${this.state.endDateError ? 'has-error' : ''}`}>
                  <label>End Date</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="end_date"
                    selected={this.state.end_date}
                    dateFormat="MM/dd/yy"
                    onChange={(data) => {
                    const endDate = new Date(data);
                    const startDate = this.state.start_date;
                    if (endDate < startDate) {
                      this.setState({ endDateError: true });
                    } else {
                      this.setState({ endDateError: false });
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "end_date",
                          value: data,
                        },
                      });
                    }
                  }}
                  />
                   {this.state.endDateError && <div className="text-danger">End date cannot be earlier than start date.</div>}
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default GuestReviews;
