////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Reservations.jsx                                    //
//  Application: Reservations                                    //
//  Option: List all Reservations                                //
//  Developer: Ashish Kumar					                      //
//  Date: 2022-04-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import ReservationService from "../../services/ReservationService";
import PropertyService from "../../services/PropertyService";
import UnitsService from "../../services/UnitsService";
import GuestService from "../../services/GuestService";
import ChannelService from "../../services/ChannelService";
import ErrorLogo from "./../../assets/images/errorImage.svg";
import SuccessLogo from "./../../assets/images/successImage.svg";
import { Link } from "react-router-dom";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Modal,
  InputGroup,
  Form,
} from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import Alerts from "../common/Alerts";
import NoData from "../common/NoData";
import CalendarSidebar from "../common/CalendarSidebar";
import Moment from "moment";
import moment from "moment-timezone";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Select from "react-select";
import DatePicker from "react-datepicker";
import repeatGuest from "./../../assets/images/guest-repeat-blue.svg";
import queryString from "query-string";
import exportIcon from "./../../assets/images/export.svg";
import { FileUploader } from "react-drag-drop-files";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyReservationSidebar from "../common/AllPropertyReservationSidebar";
import pdfIcon from "../../assets/images/pdf.png";
import TransferExport from "../property/TransferExport";
import * as Vars from "./../../Vars";
import MultiImageService from "./../../services/MultiImageService";

let queryParams = queryString.parse(window.location.search);
const mockData = Vars.default.mockData;

export class Reservations extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      errors: {},
      importModal: false,
      file_url: "",
      file_name: "",
      open: true,
      eventKey: "1",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      isSearch: global.isSearch,
      module_search: "",
      alertType: "xlsx",
      exportMesssage: "",
      exportFilePath: "",
      exportResult: "",
      is_search: 0,
      input: {},
      exportOptionIsError: false,
      exportOptionColErrorMessage: "",
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      is_available: "",
      available_from: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      unitsFilter: [],
      filter_value: "",
      selectChannelList: [],
      selectedStatusList: [],
      selectedChannelList: [],
      transferDataSource: [],
      newChannelList: [],
      newStatusList: [
        { key: "1", value: "1", label: "Confirmed" },
        { key: "2", value: "2", label: "Cancelled" },
        { key: "3", value: "3", label: "Pending" },
      ],
      guestList: [],
      newGuestList: [],
      selectedGuestList: [],
      activeClass: "all",
      isGuestImageModal: false,
      front_image_url: "",
      back_image_url: "",
      selfi_image_url: "",
      tab: 1,
      search_status_id: "",
      reservationTabNumber: localStorage.getItem("reservation_tab_number")
        ? parseInt(localStorage.getItem("reservation_tab_number"))
        : "",
      // date: this.props.match.params.date,
      listing_slug: this.props.match.params.listingSlug,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "checkin_date",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config2: {
        sort: {
          column: "checkin_date",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config3: {
        sort: {
          column: "created_at",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "checkin_date",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "unit_id",
          text: "Unit",
          className: "unit_id hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            let return_url = this.rowClickedHandler(record);
            return record.unit && record.unit.unit_name ? (
              <Link to={return_url} target="_blank">
                {record.unit.unit_name}
              </Link>
            ) : record.unit && record.unit.unit_number ? (
              <Link to={return_url} target="_blank">
                {record.unit.unit_number}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            const currentDate = new Date();

            // var dateFrom = Moment(record.checkin_date).format("MM/DD/YYYY");
            // var dateTo = Moment(record.checkout_date).format("MM/DD/YYYY");
            // var dateCheck = Moment(currentDate).format("MM/DD/YYYY");

            var dateFrom = Moment(record.checkin_date).format(
              "MM/DD/YYYY HH:mm:ss"
            );
            var dateTo = Moment(record.checkout_date).format(
              "MM/DD/YYYY HH:mm:ss"
            );
            var dateCheck = Moment(currentDate).format("MM/DD/YYYY HH:mm:ss");

            // var d1 = dateFrom.split("/");
            // var d2 = dateTo.split("/");
            // var c = dateCheck.split("/");

            var date1 = new Date(dateFrom);
            var date2 = new Date(dateTo);
            var current = new Date(dateCheck);
            let return_url = this.rowClickedHandler(record);

            // var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
            // var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
            // var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

            if (
              record.status_id === 1 &&
              current.getTime() >= date1.getTime() &&
              current.getTime() <= date2.getTime()
            ) {
              // if (check >= from && check <= to) {
              return (
                <Link to={return_url} target="_blank">
                  Currently Hosting
                </Link>
              );
            } else if (
              record.status_id !== 2 &&
              current.getTime() > date2.getTime()
            ) {
              // if (check >= from && check <= to) {
              return (
                <>
                  <Link to={return_url} target="_blank">
                    <div className="">Completed</div>
                  </Link>
                  {/* {record.confirmation_code ? (
                    <div className="">{record.confirmation_code}</div>
                  ) : (
                    ""
                  )} */}
                </>
              );
            } else {
              return record.status_id === 1 ? (
                <>
                  <Link to={return_url} target="_blank">
                    <div className="">Confirmed</div>
                  </Link>
                  {/* {record.confirmation_code ? (
                    <div className="">{record.confirmation_code}</div>
                  ) : (
                    ""
                  )} */}
                </>
              ) : record.status_id === 3 ? (
                <>
                  <Link to={return_url} target="_blank">
                    <div className="">Payment Pending</div>
                  </Link>
                  {/* {record.confirmation_code ? (
                    <div className="">{record.confirmation_code}</div>
                  ) : (
                    ""
                  )} */}
                </>
              ) : (
                <>
                  <Link to={return_url} target="_blank">
                    <div className="text-danger">Cancelled</div>
                  </Link>
                  {/* {record.confirmation_code ? (
                    <div className="">{record.confirmation_code}</div>
                  ) : (
                    ""
                  )} */}
                </>
              );
            }
          },
        },
        {
          key: "guest_id",
          text: "Guest",
          className: "first_name hand-cursor ptagcolor td_clickable",
          sortable: true,
          cell: (record) => {
            let return_url = this.rowClickedHandler(record);

            return record.guest_user ? (
              record.guest_user && record.guest_user.is_id_verified === 1 ? (
                <Link to={return_url} target="_blank">
                  <div className="">
                    {record.full_name
                      ? record.full_name
                      : record.guest_user.first_name +
                        " " +
                        record.guest_user.last_name}

                    {record.is_repeat === 1 ? (
                      <img src={repeatGuest} alt="" title="Repeat Guest" />
                    ) : (
                      ""
                    )}
                    {record.adults ? (
                      <p className="m-0">
                        {record.adults !== 1
                          ? record.adults + " adults"
                          : record.adults + " adult"}
                        {record.children
                          ? record.children !== 1
                            ? ", " + record.children + " children"
                            : ", " + record.children + " child"
                          : ""}
                        {record.infants
                          ? record.infants !== 1
                            ? ", " + record.infants + " infants"
                            : ", " + record.infants + " infant"
                          : ""}
                        {record.pets
                          ? record.pets !== 1
                            ? ", " + record.pets + " pets"
                            : ", " + record.pets + " pet"
                          : ""}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </Link>
              ) : (
                <Link to={return_url} target="_blank">
                  <div className="">
                    {record.full_name
                      ? record.full_name
                      : record.guest_user.first_name +
                        " " +
                        record.guest_user.last_name}{" "}
                    {record.is_repeat === 1 ? (
                      <img src={repeatGuest} alt="" title="Repeat Guest" />
                    ) : (
                      ""
                    )}
                    {record.adults ? (
                      <p className="m-0">
                        {record.adults !== 1
                          ? record.adults + " adults"
                          : record.adults + " adult"}
                        {record.children
                          ? record.children !== 1
                            ? ", " + record.children + " children"
                            : ", " + record.children + " child"
                          : ""}
                        {record.infants
                          ? record.infants !== 1
                            ? ", " + record.infants + " infants"
                            : ", " + record.infants + " infant"
                          : ""}
                        {record.pets
                          ? record.pets !== 1
                            ? ", " + record.pets + " pets"
                            : ", " + record.pets + " pet"
                          : ""}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </Link>
              )
            ) : (
              ""
            );
          },
        },
        {
          key: "checkin_date",
          text: "Check-In",
          className: "checkin_date hand-cursor ptagcolor td_clickable",
          sortable: true,
          cell: (record) => {
            let return_url = this.rowClickedHandler(record);

            return record.checkin_date ? (
              <Link to={return_url} target="_blank">
                <div className="">
                  {Moment(record.checkin_date).format(global.dateFormat)}
                  {record.checkin_time ? (
                    <p className="m-0">{record.checkin_time}</p>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "checkout_date",
          text: "Check-Out",
          className: "checkout_date hand-cursor ptagcolor td_clickable",
          sortable: true,
          cell: (record) => {
            let return_url = this.rowClickedHandler(record);

            return record.checkout_date ? (
              <Link to={return_url} target="_blank">
                <div className="">
                  {Moment(record.checkout_date).format(global.dateFormat)}
                  {record.checkout_time ? (
                    <p className="m-0">{record.checkout_time}</p>
                  ) : (
                    ""
                  )}
                </div>
              </Link>
            ) : (
              ""
            );
          },
        },
        global.userType !== 4
          ? {
              key: "created_at",
              text: "Booked",
              className: "booked hand-cursor td_clickable",
              sortable: true,
              cell: (record) => {
                let return_url = this.rowClickedHandler(record);

                return record.created_at ? (
                  <Link to={return_url} target="_blank">
                    {Moment(record.created_at).format(global.dateFormat)}{" "}
                  </Link>
                ) : (
                  ""
                );
              },
            }
          : "",

        global.userType !== 4
          ? {
              key: "confirmation_code",
              text: "Confirmation Code",
              className: "confirmation_code hand-cursor ptagcolor td_clickable",
              sortable: true,
              cell: (record) => {
                let return_url = this.rowClickedHandler(record);
                return (
                  <Link to={return_url} target="_blank">
                    <div className="">
                      {record.confirmation_code ? record.confirmation_code : ""}
                      <p className="m-0">
                        {record.channel && record.channel.channel_name
                          ? record.channel.channel_name
                          : ""}
                      </p>
                    </div>
                  </Link>
                );
              },
            }
          : "",
        global.userType !== 4
          ? {
              key: "guest_paid",
              text: "Guest Paid",
              className: "guest_paid hand-cursor td_clickable",
              sortable: true,
              cell: (record) => {
                let return_url = this.rowClickedHandler(record);

                return record.guest_paid ? (
                  <Link to={return_url} target="_blank">
                    {"$" +
                      global.onKeyPressEvent.numberWithCommasForDecimal(
                        record.guest_paid.replace(/\.00$/, "")
                      )}{" "}
                  </Link>
                ) : (
                  ""
                );
              },
            }
          : "",
        global.userPermissions.checkPermission("reservations-guest-photos") && {
          key: "Identification",
          text: "Identification",
          className: "checkin_date hand-cursor guest_images",
          sortable: true,
          cell: (record) => {
            let AWS_URL_STG = process.env.REACT_APP_AWS_URL;
            // let fromImage = record.front_photo_name
            //   ? record.front_photo_name
            //   : null;
            // let backImage = record.back_photo_name
            //   ? record.back_photo_name
            //   : null;
            // let selfieImage = record.selfie_photo_name
            //   ? record.selfie_photo_name
            //   : null;
            let receiptPdf = record.receipt_pdf
              ? record.receipt_pdf !== null
                ? record.receipt_pdf
                : null
              : null;
            let is_guest_form_submiited = record.is_form_submited
              ? record.is_form_submited
              : null;

            let reservationCreatedAt = record.created_at
              ? record.created_at
              : null;
            const rDate =
              reservationCreatedAt !== null
                ? moment(reservationCreatedAt).format("l")
                : null;
            const oldReservationDate = new Date("3/15/2023");
            const oldRDate = moment(oldReservationDate).isBefore(rDate);

            const receipt_pdf_url =
              receiptPdf !== null
                ? `${AWS_URL_STG}/guest_form_agrement/${receiptPdf}`
                : null;

            return is_guest_form_submiited !== null &&
              is_guest_form_submiited !== 0 ? (
              <>
                <p
                  onClick={(e) => {
                    if (record.guest_info_deleted !== 1) {
                      this.showGuestImages(
                        e,
                        record.slug,
                        record.guest_form_created,
                        receipt_pdf_url
                      );
                    }
                  }}
                >
                  {record.guest_info_deleted === 1
                    ? "ID Archived"
                    : "ID submitted"}
                </p>
              </>
            ) : oldRDate ? (
              "ID not submitted yet"
            ) : (
              "ID not required"
            );
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.confirmImport = this.confirmImport.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.leftTableColumns = [
      {
        dataIndex: "title",
        title: "Name",
      },
    ];
    this.rightTableColumns = [
      {
        dataIndex: "title",
        title: "Name",
      },
    ];
  }

  async componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      switch (this.state.reservationTabNumber) {
        case 1:
          document.getElementById("currentlyHosting").click();
          break;

        case 3:
          document.getElementById("upcoming").click();
          break;
        case 4:
          document.getElementById("completed").click();
          break;

        case 6:
          document.getElementById("all").click();
          break;
        default:
          document.getElementById("all").click();
      }
      if (this.state.listing_slug) {
        var queryString =
          "listing_slug=" + this.state.listing_slug + "&is_custom=1";
        this.getData(queryString);
      }

      //cleaning staff login
      if (localStorage.getItem("user_type") === "4") {
        //get property data and store in session storage
        if (this.state.property_slug !== undefined) {
          let res = await PropertyService.getProperty(this.state.property_slug);

          if (global.successStatus.includes(res.status)) {
            sessionStorage.setItem(
              "property_slug",
              this.state.property_slug ? this.state.property_slug : ""
            );
            sessionStorage.setItem(
              "property_name",
              res.data.property_name ? res.data.property_name : ""
            );
            sessionStorage.setItem(
              "property_type",
              res.data.property_type
                ? res.data.property_type.property_type_name
                : ""
            );
            sessionStorage.setItem(
              "year_value",
              res.data.year_value ? res.data.year_value : ""
            );
            sessionStorage.setItem(
              "property_logo_url",
              res.data.property_logo_url ? res.data.property_logo_url : ""
            );
            sessionStorage.setItem(
              "property_logo_thumb_url",
              res.data.property_logo_thumb_url
                ? res.data.property_logo_thumb_url
                : ""
            );

            localStorage.setItem("property_slug", this.state.property_slug);
            localStorage.setItem("property_name", res.data.property_name);
            localStorage.setItem(
              "property_type",
              res.data.property_type.property_type_name
            );
            localStorage.setItem("year_value", res.data.year_value);
            localStorage.setItem(
              "property_logo_url",
              res.data.property_logo_url
            );
            localStorage.setItem(
              "property_logo_thumb_url",
              res.data.property_logo_thumb_url
            );

            this.props.propertyName(res.data.property_name);
            this.props.propertySlug(this.state.property_slug);
          }
        }
      }
    }
  }

  // @somin - transfer changes start
  onChange = (nextTargetKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
    var database_cols = [];
    mockData.forEach((mData) => {
      nextTargetKeys.forEach((callbackCols) => {
        if (callbackCols === mData.key) {
          database_cols = [...database_cols, mData.value];
        }
      });
    });
    this.setState({ export_col_send: database_cols });
  };
  // @somin Transfer changes added

  getTransferOptionList = () => {
    var selectedCols = mockData.filter((item) => {
      return item.module_name === "reservation_listing";
    });
    this.setState({
      transferDataSource: selectedCols,
    });
  };

  // @somin - transfer changes end

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      switch (this.state.reservationTabNumber) {
        case 1:
          document.getElementById("currentlyHosting").click();
          break;
        case 3:
          document.getElementById("upcoming").click();
          break;
        case 4:
          document.getElementById("completed").click();
          break;
        case 6:
          document.getElementById("all").click();
          break;
        default:
          document.getElementById("all").click();
      }
    }, 200);
    this.setState({
      selectedUnitList: [],
      module_search: [],
      is_search: 0,
    });
  }

  async getData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    // var todayDate = Moment(new Date()).format("YYYY-MM-DD");
    // var currentDateFilter = "checkin_date=" +  todayDate + "&is_custom=1";
    if (queryParams.isGlobal === "1") {
      document.getElementById("all").click();
      var res = await ReservationService.getReservationList(
        // "property_slug=" + this.state.property_slug + "&is_search=1&" + queryParams.global_col_name + "=" + queryParams.global_search + (queryString ? "&" + queryString : '')
        "property_slug=" +
          (this.state.property_slug ? this.state.property_slug : "") +
          "&is_search=1&" +
          queryParams.global_col_name +
          "=" +
          queryParams.global_search +
          "&tab=6" //global seach apply on tab=6 (all)
      );
    } else {
      res = await ReservationService.getReservationList(
        "property_slug=" +
          (this.state.property_slug ? this.state.property_slug : "") +
          (queryString ? "&" + queryString : "")
      );
    }
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];

      totalRecords = res.data ? res.data.total : 0;
      var guestFormImages = [];

      res.data.data.length > 0 &&
        res.data.data.forEach((item, i) => {
          guestFormImages = [
            ...guestFormImages,
            {
              slug: item.slug,
              // front_image: item.front_photo_name,
              // back_image: item.back_photo_name,
              // selfie_image: item.selfie_photo_name,
              verified_on: item.created_at,
              receipt_pdf: item.receipt_pdd,
            },
          ];
        });
    }
    this.setState({
      total_record: totalRecords,
      records: list,
      guestFormImages: guestFormImages,
    });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    this.setState({ is_search: 0 });
    // this.setState({checkin_date:"",checkout_date:"",booked_date:"",unit_slug:"",guest_slug:"",selectedUnitList:[],selectedResidentList:[],confirmation_code:"",guest_paid:""});
    this.setState({ module_search: "" });
    if (e === "currentlyHosting") {
      this.setState({ tab: 1 });
      // this.getData("date=current&is_custom=1");
      this.getData("tab=1&is_list=1");
      setTimeout(() => {
        localStorage.setItem(
          "reservation_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "upcoming") {
      this.setState({ tab: 3 });
      this.getData("tab=3&is_list=1");
      // this.getData("date=upcoming&is_custom=1");
      setTimeout(() => {
        localStorage.setItem(
          "reservation_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "completed") {
      this.setState({ tab: 4 });
      this.getData("tab=4&is_list=1");
      // this.getData("date=past&is_custom=1");
      localStorage.setItem(
        "reservation_tab_number",
        this.state.tab ? this.state.tab : ""
      );
    } else if (e === "cancelled") {
      this.setState({ tab: 5 });
      this.getData("tab=5&is_list=1");
      // this.getData("status_id=cancelled&is_custom=1");
      setTimeout(() => {
        localStorage.setItem(
          "reservation_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "all") {
      this.setState({ tab: 6 });
      this.getData("tab=6&is_list=1");
      // this.getData("&filter_value=&checkin_date=&checkout_date=&is_custom=1");
      setTimeout(() => {
        localStorage.setItem(
          "reservation_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "paymentPending") {
      this.setState({ tab: 2 });
      this.getData("tab=2&is_list=1");
      setTimeout(() => {
        localStorage.setItem(
          "reservation_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
    //  else if (e === "allReservations") {
    //   this.setState({ tab: 7 });
    //   //calendar api call
    //   setTimeout(() => {
    //     localStorage.setItem(
    //       "reservation_tab_number",
    //       this.state.tab ? this.state.tab : ""
    //     );
    //   }, 5);
    // }

    //  else {
    //   this.setState({ tab: 7 });
    //   this.getData();
    //   setTimeout(() => {
    //     localStorage.setItem(
    //       "reservation_tab_number",
    //       this.state.tab ? this.state.tab : ""
    //     );
    //   }, 5);
    // }
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" +
        (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get guest data list */
  async getGuestData(queryString = "") {
    var guestList = [];

    let res = await GuestService.getGuestList(
      "is_dropdown=1&property_slug=" +
        (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      guestList = res.data ? res.data : [];
    }
    const newGuestList = guestList.map(({ slug, first_name, last_name }) => ({
      value: slug,
      label: first_name + " " + last_name,
    }));
    this.setState({
      guestList: guestList,
      newGuestList: newGuestList,
    });
  }

  /* to get channel data list */
  async getChannelList() {
    this.setState({ showSpinner: true, loading: true });
    var selectChannelList = [];
    var selectChannelListTotal = 0;

    let res = await ChannelService.getChannel("is_dropdown=1");
    if (global.successStatus.includes(res.status)) {
      selectChannelList = res.data ? res.data : [];
      selectChannelListTotal = selectChannelList ? selectChannelList.length : 0;
    }
    const newChannelList =
      selectChannelListTotal > 0
        ? selectChannelList.map(({ slug, channel_name }) => ({
            value: slug,
            label: channel_name,
          }))
        : [
            { value: "1", label: "Property Website" },
            { value: "2", label: "Airbnb" },
            { value: "3", label: "OTA" },
          ];
    this.setState({
      selectChannelList: selectChannelList,
      selectChannelListTotal: selectChannelListTotal,
      newChannelList: newChannelList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async ExportData() {
    this.setState({ isSubmit: true, showSpinner: true });
    let exportOptionIsError = false;
    this.setState({ exportOptionColErrorMessage: "" });
    if (
      this.state.export_col_send == "" ||
      this.state.export_col_send == undefined
    ) {
      exportOptionIsError = true;
    }
    let data = {
      property_slug: this.state.property_slug,
      file_type: this.state.alertType,
      where_column:
        this.state.customQueryString.length != 0
          ? this.state.customQueryString
          : "tab=" + this.state.tab,
      columns: this.state.export_col_send,
    };
    if (!exportOptionIsError) {
      let res = await ReservationService.exportDataFilewise(data);
      if (res.data.filePath) {
        await this.getDownloadFile(res.data.filePath, res.data.fileName);
        this.setState({ exportMesssage: res.message });
        this.setState({ exportResult: data });
        this.setState({ exportFilePath: res.data.filePath });
        this.setState({ exportOptionColErrorMessage: "" });
        this.setState({ exportOptionIsError: false });
      }
    } else {
      this.setState({
        exportOptionColErrorMessage: "Please select and transfer column",
        exportOptionIsError: true,
      });
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  async getDownloadFile(url, name) {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeGuestList(value) {
    let guest_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        guest_slug.push(item.value);
      });
    }
    const guest_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedGuestList: value,
      guest_slug: guest_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getUnitData();
      this.getChannelList();
      // this.getGuestData();
      this.setState({ isSearch: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  async showGuestImages(e, slug, created_at, receipt_pdf_url) {
    const { isGuestImageModal, guestFormImages } = this.state;

    if (isGuestImageModal) {
      this.setState({
        isGuestImageModal: false,
        selfi_image_url: "",
        front_image_url: "",
        back_image_url: "",
      });
      return;
    }

    const slugExists = guestFormImages.some((data) => data.slug === slug);
    if (!slugExists) return;

    try {
      const res = await ReservationService.getPresignedGuestIds(
        `reservation_slug=${slug}`
      );
      if (!global.successStatus.includes(res.status)) return;

      const {
        front_photo_name: front_url,
        back_photo_name: back_url,
        selfie_photo_name: selfies_url,
      } = res.data || {};

      this.setState({
        isGuestImageModal: true,
        selfi_image_url: selfies_url || "",
        front_image_url: front_url || "",
        back_image_url: back_url || "",
        activated_at: created_at,
        receipt_pdf_url: receipt_pdf_url,
      });
    } catch (error) {
      console.error("Error fetching guest images:", error);
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeChannel(value) {
    let set_channel = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        set_channel.push(item.value);
      });
    }
    const set_channel_value = value !== null ? value.value : [];
    this.setState({
      selectedChannelList: value,
      set_channel: set_channel_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeStatus(value) {
    let search_status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        search_status_id.push(item.value);
      });
    }
    const status_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStatusList: value,
      search_status_id: status_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  customFilter() {
    let avaliableFrom = this.state.from_date
      ? Moment(this.state.from_date).format("YYYY-MM-DD")
      : "";
    let avaliableTo = this.state.to_date
      ? Moment(this.state.to_date).format("YYYY-MM-DD")
      : "";
    let filterValue = this.state.filter_value ? this.state.filter_value : "";
    let queryString =
      "filter_value=" +
      filterValue +
      "&checkin_date=" +
      avaliableFrom +
      "&checkout_date=" +
      avaliableTo +
      "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  clearFilter() {
    queryParams = {};
    // this.getData("is_list=1&tab="+this.state.tab);
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      checkin_date: "",
      checkout_date: "",
      booked_date_to: "",
      booked_date: "",
      unit_slug: "",
      guest_name: "",
      selectedUnitList: [],
      selectedResidentList: [],
      confirmation_code: "",
      guest_paid: "",
      set_channel: "",
      selectedChannelList: [],
    });
    this.setState({ module_search: "" });
  }

  filterData() {
    let checkIn = this.state.checkin_date
      ? Moment(this.state.checkin_date).format("YYYY-MM-DD")
      : "";
    let checkInTo = this.state.checkin_date_to
      ? Moment(this.state.checkin_date_to).format("YYYY-MM-DD")
      : "";
    let checkOut = this.state.checkout_date
      ? Moment(this.state.checkout_date).format("YYYY-MM-DD")
      : "";
    let checkOutTo = this.state.checkout_date_to
      ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
      : "";
    let booked = this.state.booked_date
      ? Moment(this.state.booked_date).format("YYYY-MM-DD")
      : "";
    let bookedTo = this.state.booked_date_to
      ? Moment(this.state.booked_date_to).format("YYYY-MM-DD")
      : "";
    let queryString =
      "checkin_date=" +
      checkIn +
      "&checkin_date_to=" +
      checkInTo +
      "&checkout_date=" +
      checkOut +
      "&checkout_date_to=" +
      checkOutTo +
      "&booked_date=" +
      booked +
      "&booked_date_to=" +
      bookedTo +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&listing_name=" +
      (this.state.listing_name ? this.state.listing_name : "") +
      "&guest_name=" +
      (this.state.guest_name ? this.state.guest_name : "") +
      "&confirmation_code=" +
      (this.state.confirmation_code ? this.state.confirmation_code : "") +
      "&channel_slug=" +
      (this.state.set_channel ? this.state.set_channel : "") +
      "&guest_paid=" +
      (this.state.guest_paid ? this.state.guest_paid : "") +
      (this.state.tab && this.state.tab === 6
        ? "&status_id=" +
          (this.state.search_status_id ? this.state.search_status_id : "")
        : "") +
      "&tab=" +
      this.state.tab +
      "&is_search=1";

    // let moduleSearchString =
    //   (this.state.checkin_date
    //     ? "check-in date - " +
    //     Moment(this.state.checkin_date).format("DD/MM/YY")
    //     : "") +
    //   (this.state.checkout_date
    //     ? " & check-out date - " +
    //     Moment(this.state.checkout_date).format("DD/MM/YY")
    //     : "") +
    //   (this.state.booked_date
    //     ? " & booked date - " +
    //     Moment(this.state.booked_date).format("DD/MM/YY")
    //     : "") +
    //   (this.state.booked_date_to
    //     ? " & booked date to - " +
    //     Moment(this.state.booked_date_to).format("DD/MM/YY")
    //     : "") +
    //   (this.state.unit_slug
    //     ? " & unit - " + this.state.selectedUnitList.label
    //     : "") +
    //   (this.state.listing_name
    //     ? " & listing name - " + this.state.listing_name
    //     : "") +
    //   (this.state.guest_slug
    //     ? " & guest - " + this.state.selectedGuestList.label
    //     : "") +
    //   (this.state.confirmation_code
    //     ? " & confirmation code - " + this.state.confirmation_code
    //     : "") +
    //   // (this.state.guest_paid ? "&total payout - " + this.state.guest_paid : '') +
    //   (this.state.status_id
    //     ? " & status - " + this.state.selectedResidentList.label
    //     : "");

    let moduleSearchString = "";
    moduleSearchString = this.state.checkin_date
      ? "Check-in Date From - " +
        Moment(this.state.checkin_date).format(global.dateFormat)
      : "";
    moduleSearchString = this.state.checkin_date_to
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Check-in Date To - " +
          Moment(this.state.checkin_date_to).format(global.dateFormat)
        : " Check-in Date To - " +
          Moment(this.state.checkin_date_to).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.checkout_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Check-out Date From - " +
          Moment(this.state.checkout_date).format(global.dateFormat)
        : " Check-out Date From - " +
          Moment(this.state.checkout_date).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.checkout_date_to
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Check-out Date To - " +
          Moment(this.state.checkout_date_to).format(global.dateFormat)
        : " Check-out Date To - " +
          Moment(this.state.checkout_date_to).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.booked_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Booked Date From - " +
          Moment(this.state.booked_date).format(global.dateFormat)
        : " Booked Date From - " +
          Moment(this.state.booked_date).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.booked_date_to
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Booked Date To  - " +
          Moment(this.state.booked_date_to).format(global.dateFormat)
        : " Booked Date To  - " +
          Moment(this.state.booked_date_to).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Unit - " +
          this.state.selectedUnitList.label
        : " Unit - " + this.state.selectedUnitList.label
      : moduleSearchString;
    moduleSearchString = this.state.guest_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Guest - " + this.state.guest_name
        : " Guest - " + this.state.guest_name
      : moduleSearchString;
    moduleSearchString = this.state.listing_name
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Listing Name - " +
          this.state.listing_name
        : " Listing Name - " + this.state.listing_name
      : moduleSearchString;
    moduleSearchString = this.state.confirmation_code
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Confirmation Code - " +
          this.state.confirmation_code
        : " Confirmation Code - " + this.state.confirmation_code
      : moduleSearchString;
    moduleSearchString = this.state.set_channel
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Channel - " +
          this.state.selectedChannelList.label
        : " Channel - " + this.state.selectedChannelList.label
      : moduleSearchString;
    moduleSearchString = this.state.search_status_id
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Status - " +
          this.state.selectedStatusList.label
        : " Status - " + this.state.selectedStatusList.label
      : moduleSearchString;
    if (
      this.state.checkin_date ||
      this.state.checkin_date_to ||
      this.state.checkout_date ||
      this.state.checkout_date_to ||
      this.state.booked_date ||
      this.state.unit_slug ||
      this.state.listing_name ||
      this.state.guest_name ||
      this.state.confirmation_code ||
      this.state.set_channel ||
      this.state.guest_paid ||
      this.state.status_id ||
      this.state.booked_date_to ||
      this.state.search_status_id
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(queryString);
    }
  }

  exportToggle = () => {
    this.setState({ isSubmit: false });
    if (this.state.isModel === 1) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: 0 });
    } else {
      this.setState({ isModel: 1 });
    }
    this.setState({
      exportMesssage: "",
      // export_col_send: "",
      exportOptionIsError: "",
      exportOptionColErrorMessage: "",
    });
    this.getTransferOptionList();
  };

  handleCallback = (data) => {
    var database_cols = [];
    mockData.forEach((mData) => {
      data.forEach((callbackCols) => {
        if (callbackCols === mData.key) {
          database_cols = [...database_cols, mData.value];
        }
      });
    });
    if (database_cols == "") {
      this.setState({
        exportOptionColErrorMessage: "Please select and transfer column",
      });
      this.setState({ exportOptionIsError: true });
    } else {
      this.setState({ exportOptionColErrorMessage: "" });
      this.setState({ exportOptionIsError: false });
    }
    this.setState({ export_col_send: database_cols });
  };

  resetDate() {
    this.setState({ tab: 6, filter_value: "", from_date: "", to_date: "" });
    this.getData("tab=6&is_list=1");
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
            window.scrollTo(0, 0);
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () =>
            this.getData(queryString + "&tab=" + this.state.tab + "&is_list=1"),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString + "&tab=" + this.state.tab + "&is_list=1");
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Reservations";
  }

  rowClickedHandler = (data) => {
    return this.props.match.params.propertySlug !== undefined
      ? "reservations/view/" + data.slug
      : this.state.property_slug
      ? "/reservations/view/" + this.state.property_slug + "/" + data.slug
      : "/reservations/view/" + data.property_slug + "/" + data.slug;
  };

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }

  handleChange = (file) => {
    this.setState({ file_name: file.name });
    this.setState({ file_url: file });
  };

  async confirmImport() {
    if (this.state.file_url) {
      this.setState({ showSpinner: true, isSubmit: true });
      const fileData = new FormData();
      fileData.append("import_file", this.state.file_url);
      fileData.append("file_name", this.state.file_name);
      fileData.append("property_slug", this.state.property_slug);
      let res = await ReservationService.importData(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({ importModal: false });
        this.setState({ importResponseModel: true });
        this.setState({ successResponse: true });
        this.setState({ successMessage: res.message });
        if (res.data.alertMessage) {
          const totalRecord = res.data.alertMessage.total_record;
          const successRecord = res.data.alertMessage.success_record;
          const skipRecord = res.data.alertMessage.skip_record;
          const skipData = res.data.alertMessage.skip_data;
          this.setState({
            totalRecord: totalRecord,
          });
          this.setState({
            successRecord: successRecord,
          });
          this.setState({
            skipRecord: skipRecord,
          });
          this.setState({
            skipData: skipData,
          });
        }
        this.getData();
      } else {
        this.setState({ importModal: false });
        this.setState({ importResponseModel: true });
        this.setState({ errorResponse: true });
        this.setState({ errorMessage: res.data.message });
        if (res.data.result.alertMessage) {
          const totalRecord = res.data.result.alertMessage.total_record;
          const successRecord = res.data.result.alertMessage.success_record;
          const skipRecord = res.data.result.alertMessage.skip_record;
          const skipData = res.data.result.alertMessage.skip_data;
          if (successRecord !== undefined) {
            this.setState({
              totalRecord: totalRecord,
            });
            this.setState({
              successRecord: successRecord,
            });
            this.setState({
              skipRecord: skipRecord,
            });
            this.setState({
              skipData: skipData,
            });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false, show: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  }

  confirmImportModal() {
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
    this.validator.hideMessages();
  }

  closeimportResponseModel() {
    this.setState({ importResponseModel: false });
    this.validator.hideMessages();
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <CalendarSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyReservationSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0 fout_btn_w">
                        {global.userPermissions.checkPermission(
                          "reservations-add"
                        ) && this.state.property_slug ? (
                          <Link
                            to={
                              this.props.match.params.propertySlug !== undefined
                                ? "/properties/view/" +
                                  this.state.property_slug +
                                  "/reservations/add"
                                : "/reservations/add/" +
                                  this.state.property_slug
                            }
                          >
                            <button className="btn-success-outline-small ">
                              <img src={pluswIcon} alt="" /> Add
                            </button>
                          </Link>
                        ) : (
                          ""
                        )}
                        <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small ml-3"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button>
                        {global.userPermissions.checkPermission(
                          "reservations-export"
                        ) && (
                          <button
                            className="btn-success-outline-small ml-3"
                            onClick={this.exportToggle}
                          >
                            Export
                          </button>
                        )}
                        {global.userPermissions.checkPermission(
                          "reservations-import"
                        ) && (
                          <button
                            type="button"
                            onClick={() => this.confirmImportModal()}
                            className="btn-success-outline ml-3"
                          >
                            <img src={exportIcon} alt="Import" /> Import
                          </button>
                        )}
                        {/* {this.state.tab === 6 ? (
                        <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small mr-3"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button>
                      ) : (
                        ""
                      )} */}
                      </div>
                    </div>
                  </div>
                  {this.props.match.params.propertySlug === undefined ? (
                    <>
                      <div className="row pd-lg-l-15 pd-xs-l-15">
                        <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                          <OutSidePropertySelectBox
                            propertySlug={this.getPropertySlug.bind(this)}
                            outSidePropertySlug={this.state.outSidePropertySlug}
                          />
                        </div>
                        <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                          {queryParams.isGlobal === "1" ? (
                            <>
                              <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                {"Search applied on: " +
                                  queryParams.global_col_label +
                                  " - " +
                                  queryParams.global_search}
                                <button
                                  onClick={this.clearFilter}
                                  className="mg-l-15 btn-success-outline-small"
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.is_search === 1 ? (
                            <>
                              <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                                <div className="custm-filter">
                                  <div>
                                    {" "}
                                    <label className="form-label text-left pd-b-2 mb-0">
                                      Search Applied On
                                    </label>
                                  </div>
                                  <div className="filter_bg_cust">
                                    {this.state.module_search.map((item, i) => {
                                      return <span key={i}>{item}</span>;
                                    })}
                                    <button
                                      onClick={this.clearFilter}
                                      className="btn-fliter-clear"
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-xxl-12 pd-r-15 pd-l-15">
                      {queryParams.isGlobal === "1" ? (
                        <>
                          <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between justify_mob_filter scrolling-carousel">
                            {"Search applied on: " +
                              queryParams.global_col_label +
                              " - " +
                              queryParams.global_search}
                            <button
                              onClick={this.clearFilter}
                              className="mg-l-15 btn-success-outline-small"
                            >
                              Clear
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.is_search === 1 ? (
                        <>
                          <div className="col-xxl-12 col-sm-12 pd-lg-r-15 pd-lg-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                            <div className="custm-filter">
                              <div>
                                {" "}
                                <label className="form-label text-left pd-b-2 mb-0">
                                  Search Applied On
                                </label>
                              </div>
                              <div className="filter_bg_cust">
                                {this.state.module_search.map((item, i) => {
                                  return <span key={i}>{item}</span>;
                                })}
                                <button
                                  onClick={this.clearFilter}
                                  className="btn-fliter-clear"
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  <div className="scrolling-carousel pd-l-15 scroll-slide">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="all"
                      onSelect={(e) => this.getActiveClass(e)}
                      eventKey={this.state.activeClass}
                      className="pd-r-60"
                    >
                      <ScrollingCarousel
                        rightArrow={true}
                        leftArrow={true}
                        className="swipesection scroll_nav_mob"
                      >
                        {/* <Nav.Item>
                        <Nav.Link
                          eventKey="allReservations"
                          id="allReservations"
                          className={
                            this.state.activeClass === "allReservations"
                              ? "active"
                              : ""
                          }
                        >
                         Calendar
                        </Nav.Link>
                      </Nav.Item> */}
                        <Nav.Item>
                          <Nav.Link
                            eventKey="currentlyHosting"
                            id="currentlyHosting"
                            className={
                              this.state.activeClass === "currentlyHosting"
                                ? "active"
                                : ""
                            }
                          >
                            Currently Hosting
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link
                            eventKey="upcoming"
                            id="upcoming"
                            className={
                              this.state.activeClass === "upcoming"
                                ? "active"
                                : ""
                            }
                          >
                            Upcoming
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="completed"
                            id="completed"
                            className={
                              this.state.activeClass === "completed"
                                ? "active"
                                : ""
                            }
                          >
                            Completed
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link
                            eventKey="cancelled"
                            id="cancelled"
                            className={
                              this.state.activeClass === "cancelled"
                                ? "active"
                                : ""
                            }
                          >
                            Cancelled
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="paymentPending"
                            id="paymentPending"
                            className={
                              this.state.activeClass === "paymentPending"
                                ? "active"
                                : ""
                            }
                          >
                            Payment Pending
                          </Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                          <Nav.Link
                            eventKey="all"
                            id="all"
                            className={
                              this.state.activeClass === "all" ? "active" : ""
                            }
                          >
                            All
                          </Nav.Link>
                        </Nav.Item>
                      </ScrollingCarousel>
                      <Tab.Content>
                        {/* <Tab.Pane eventKey="allReservations">
                        <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                         <ReservationCalendar property_slug={this.state.property_slug} />
                        </div>
                      </Tab.Pane> */}
                        <Tab.Pane eventKey="currentlyHosting">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <div className="col-md-12 pd-l-30 pd-r-30">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                            </div>

                            {this.state.total_record !== 0 ||
                            this.state.filter_value !== "" ||
                            this.state.is_search === 1 ||
                            queryParams.isGlobal === "1" ? (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                <div className="pd-lg-l-0 pd-xs-l-15">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap reservationTable table_last_row"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    loading={this.state.loading}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    // onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              </div>
                            ) : (
                              <NoData
                                msg={global.commonMessages.checkMessage(
                                  "CURRENTLY_HOSTING_RESERVATIONS"
                                )}
                              />
                            )}
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="upcoming">
                          {this.state.tab === 3 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <div className="col-md-12 pd-l-30 pd-r-30">
                                <Alerts
                                  show={this.state.showAlertModal}
                                  type={this.state.alertModalType}
                                  title={this.state.alertModalTitle}
                                  message={this.state.alertModalMessage}
                                />
                              </div>

                              {this.state.total_record !== 0 ||
                              this.state.filter_value !== "" ||
                              this.state.is_search === 1 ||
                              queryParams.isGlobal === "1" ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  {/* {queryParams.isGlobal === "1" ? (
                                    <>
                                      <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                        {"Search applied on: " +
                                          queryParams.global_col_label +
                                          " - " +
                                          queryParams.global_search}
                                        <button
                                          onClick={this.clearFilter}
                                          className="mg-l-15 btn-success-outline-small"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.is_search === 1 ? (
                                    <>
                                      <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                                        <div className="custm-filter">
                                          <div> <label className="form-label text-left pd-b-5 mb-0">Search Applied On</label></div>
                                          <div className="filter_bg_cust">
                                            {
                                              this.state.module_search.map((item, i) => {
                                                return (
                                                  <span key={i}>{item}</span>
                                                )
                                              })
                                            }
                                        <button
                                          onClick={this.clearFilter}
                                              className="btn-fliter-clear"
                                            >Clear</button>
                                      </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                  <div className="pd-lg-l-0 pd-xs-l-15">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap reservationTable table_last_row"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config2
                                          : this.state.config2
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      // onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <NoData
                                  msg={global.commonMessages.checkMessage(
                                    "UPCOMING_RESERVATIONS"
                                  )}
                                />
                              )}
                            </div>
                          )}
                        </Tab.Pane>

                        <Tab.Pane eventKey="completed">
                          {this.state.tab === 4 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <div className="col-md-12 pd-l-30 pd-r-30">
                                <Alerts
                                  show={this.state.showAlertModal}
                                  type={this.state.alertModalType}
                                  title={this.state.alertModalTitle}
                                  message={this.state.alertModalMessage}
                                />
                              </div>

                              {this.state.total_record !== 0 ||
                              this.state.filter_value !== "" ||
                              this.state.is_search === 1 ||
                              queryParams.isGlobal === "1" ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  {/* {queryParams.isGlobal === "1" ? (
                                    <>
                                      <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                        {"Search applied on: " +
                                          queryParams.global_col_label +
                                          " - " +
                                          queryParams.global_search}
                                        <button
                                          onClick={this.clearFilter}
                                          className="mg-l-15 btn-success-outline-small"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.is_search === 1 ? (
                                    <>
                                      <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                                        <div className="custm-filter">
                                          <div> <label className="form-label text-left pd-b-5 mb-0">Search Applied On</label></div>
                                          <div className="filter_bg_cust">
                                            {
                                              this.state.module_search.map((item, i) => {
                                                return (
                                                  <span key={i}>{item}</span>
                                                )
                                              })
                                            }
                                        <button
                                          onClick={this.clearFilter}
                                              className="btn-fliter-clear"
                                            >Clear</button>
                                      </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                  <div className="pd-lg-l-0 pd-xs-l-15">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap reservationTable table_last_row"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      // onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <NoData msg="completed reservations" />
                              )}
                            </div>
                          )}
                        </Tab.Pane>

                        {/* <Tab.Pane eventKey="cancelled">
                          {this.state.tab === 5 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <div className="col-md-12 pd-l-30 pd-r-30">
                                <Alerts
                                  show={this.state.showAlertModal}
                                  type={this.state.alertModalType}
                                  title={this.state.alertModalTitle}
                                  message={this.state.alertModalMessage}
                                />
                              </div>

                              {this.state.total_record !== 0 ||
                                this.state.filter_value !== "" ||
                                this.state.is_search === 1 ||
                                queryParams.isGlobal === "1" ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  {queryParams.isGlobal === "1" ? <><div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">{"Search applied on: " + queryParams.global_col_label + " - " + queryParams.global_search}
                                    <button onClick={this.clearFilter} className="mg-l-15 btn-success-outline-small">Clear</button></div>
                                  </> : ""}
                                  {this.state.is_search === 1 ? <><div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">{"Search applied on: " + this.state.module_search}
                                    <button onClick={this.clearFilter} className="mg-l-15 btn-success-outline-small">Clear</button></div>
                                  </> : ''}
                                  <div className="pd-lg-l-0 pd-xs-l-15">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap reservationTable"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    loading={this.state.loading}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    // onRowClicked={this.rowClickedHandler}
                                  />
                                  </div>
                                </div>
                              ) : (
                                <NoData msg="cancelled reservations" />
                              )}
                            </div>
                          )}
                        </Tab.Pane>

                        <Tab.Pane eventKey="paymentPending">
                          {this.state.tab === 2 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <div className="col-md-12 pd-l-30 pd-r-30">
                                <Alerts
                                  show={this.state.showAlertModal}
                                  type={this.state.alertModalType}
                                  title={this.state.alertModalTitle}
                                  message={this.state.alertModalMessage}
                                />
                              </div>

                              {this.state.total_record !== 0 ||
                                this.state.filter_value !== "" ||
                                this.state.is_search === 1 ||
                                queryParams.isGlobal === "1" ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  {queryParams.isGlobal === "1" ? <><div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">{"Search applied on: " + queryParams.global_col_label + " - " + queryParams.global_search}
                                    <button onClick={this.clearFilter} className="mg-l-15 btn-success-outline-small">Clear</button></div>
                                  </> : ""}
                                  {this.state.is_search === 1 ? <><div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">{"Search applied on: " + this.state.module_search}
                                    <button onClick={this.clearFilter} className="mg-l-15 btn-success-outline-small">Clear</button></div>
                                  </> : ''}
                                  <div className="pd-lg-l-0 pd-xs-l-15">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap reservationTable"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    loading={this.state.loading}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    // onRowClicked={this.rowClickedHandler}
                                  />
                                  </div>
                                </div>
                              ) : (
                                <NoData msg="payment pending reservations" />
                              )}
                            </div>
                          )}
                        </Tab.Pane> */}

                        <Tab.Pane eventKey="all">
                          {this.state.tab === 6 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <div className="col-md-12 pd-l-30 pd-r-30">
                                <Alerts
                                  show={this.state.showAlertModal}
                                  type={this.state.alertModalType}
                                  title={this.state.alertModalTitle}
                                  message={this.state.alertModalMessage}
                                />
                              </div>
                              {this.state.showFilter ? (
                                <>
                                  <Row className="align-items-center order_form col-md-12 p-0 mb-0">
                                    <Col
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      className="mb-md-0 mb-3 pl-3 pr-0"
                                    >
                                      Reservations that start or end within the
                                      following dates.
                                    </Col>
                                  </Row>
                                  <Row className="align-items-center order_form col-md-12 p-0 mb-0">
                                    <Col
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      className="mb-md-0 mb-3 pl-3 pr-0"
                                    >
                                      <label className="form-label text-left pd-b-5">
                                        From
                                      </label>
                                    </Col>
                                    <Col
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      className="mb-md-0 mb-3 pl-3 pr-0"
                                    >
                                      <label className="form-label text-left pd-b-5">
                                        Until
                                      </label>
                                    </Col>
                                    <Col
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      className="mb-md-0 mb-3 p-0"
                                    ></Col>
                                  </Row>
                                  <Row className="align-items-center order_form col-md-12 p-0 mb-3">
                                    <Col
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      className="mb-md-0 mb-3 pl-3 pr-0"
                                    >
                                      <DatePicker
                                        className="form-control max_width_100"
                                        scrollableYearDropdown
                                        showMonthDropdown
                                        showYearDropdown
                                        disabledKeyboardNavigation
                                        strictParsing
                                        autoComplete="off"
                                        yearDropdownItemNumber={
                                          global.yearDropdownItemNumber
                                        }
                                        name="from_date"
                                        maxDate={this.state.to_date}
                                        //placeholderText="Start Date"
                                        dateFormat="MM-dd-yy"
                                        selected={this.state.from_date}
                                        onChange={(data) =>
                                          this.changeHandler({
                                            target: {
                                              type: "date",
                                              name: "from_date",
                                              value: data,
                                            },
                                          })
                                        }
                                      />
                                    </Col>
                                    <Col
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      className="mb-md-0 mb-3 pl-3 pr-0"
                                    >
                                      <DatePicker
                                        className="form-control max_width_100"
                                        scrollableYearDropdown
                                        showMonthDropdown
                                        showYearDropdown
                                        disabledKeyboardNavigation
                                        strictParsing
                                        autoComplete="off"
                                        yearDropdownItemNumber={
                                          global.yearDropdownItemNumber
                                        }
                                        name="to_date"
                                        minDate={this.state.from_date}
                                        //placeholderText="Start Date"
                                        dateFormat="MM-dd-yy"
                                        selected={this.state.to_date}
                                        onChange={(data) =>
                                          this.changeHandler({
                                            target: {
                                              type: "date",
                                              name: "to_date",
                                              value: data,
                                            },
                                          })
                                        }
                                      />
                                    </Col>

                                    <Col md={4} lg={4} xl={4} className="mt-0">
                                      <Row className="d-flex justify-content-end">
                                        <button
                                          onClick={this.customFilter}
                                          className="btn-success mg-l-0 mg-r-15"
                                        >
                                          Apply
                                        </button>
                                        <button
                                          onClick={this.resetDate}
                                          className="btn-success-outline"
                                        >
                                          Reset
                                        </button>
                                      </Row>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                ""
                              )}
                              {this.state.total_record !== 0 ||
                              this.state.filter_value !== "" ||
                              this.state.is_search === 1 ||
                              queryParams.isGlobal === "1" ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  {/* {queryParams.isGlobal === "1" ? (
                                    <>
                                      <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                        {"Search applied on: " +
                                          queryParams.global_col_label +
                                          " - " +
                                          queryParams.global_search}
                                        <button
                                          onClick={this.clearFilter}
                                          className="mg-l-15 btn-success-outline-small"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.is_search === 1 ? (
                                    <>
                                      <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                                        <div className="custm-filter">
                                          <div> <label className="form-label text-left pd-b-5 mb-0">Search Applied On</label></div>
                                          <div className="filter_bg_cust">
                                            {
                                              this.state.module_search.map((item, i) => {
                                                return (
                                                  <span key={i}>{item}</span>
                                                )
                                              })
                                            }
                                        <button
                                          onClick={this.clearFilter}
                                              className="btn-fliter-clear"
                                            >Clear</button>
                                      </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                  <div className="pd-lg-l-0 pd-xs-l-15">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap reservationTable table_last_row"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config3
                                          : this.state.config3
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      // onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <NoData
                                  msg={global.commonMessages.checkMessage(
                                    "RESERVATIONS"
                                  )}
                                />
                              )}
                            </div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Reservations</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Listing Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="listing_name"
                      value={this.state.listing_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Listing Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Check-In Date From</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="checkin_date"
                    selected={this.state.checkin_date}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "checkin_date",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Check-Out Date From</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="checkout_date"
                      selected={this.state.checkout_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "checkout_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Booked Date From</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="booked_date"
                      selected={this.state.booked_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "booked_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Channel</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      className="multiselect"
                      options={this.state.newChannelList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeChannel(value)}
                      value={this.state.selectedChannelList}
                      defaultValue={this.state.selectedChannelList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Guest</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="guest_name"
                      value={this.state.guest_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                    {/* <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedGuestList}
                      options={this.state.newGuestList}
                      onChange={(value) => this.handleChangeGuestList(value)}
                      defaultValue={this.state.selectedResidentList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    /> */}
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Confirmation Code/Internal Code</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="confirmation_code"
                      value={this.state.confirmation_code}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Check-In Date To</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="checkin_date_to"
                    selected={this.state.checkin_date_to}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "checkin_date_to",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Check-Out Date to</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="checkout_date_to"
                      selected={this.state.checkout_date_to}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "checkout_date_to",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Booked Date To</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="booked_date_to"
                      selected={this.state.booked_date_to}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "booked_date_to",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>
                {this.state.tab === 6 ? (
                  <InputGroup className="mt-3">
                    <label>Status</label>
                    <div className="col-md-12 p-0">
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        menuPlacement="auto"
                        className="multiselect"
                        options={this.state.newStatusList}
                        getOptionValue={(option) => `${option.label}`}
                        onChange={(value) => this.handleChangeStatus(value)}
                        value={this.state.selectedStatusList}
                        defaultValue={this.state.selectedStatusList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",
                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />
                    </div>
                  </InputGroup>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Export  popup modal */}
        <Modal show={this.state.isModel} className="modal-large" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.exportToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Export</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="col-lg-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0 mt-3">
                  <TransferExport
                    className="data_export_move table_export_move"
                    titles={["Database Columns", "Selected Database Columns"]}
                    dataSource={this.state.transferDataSource}
                    targetKeys={this.state.targetKeys}
                    // disabled={disabled}
                    showSearch={true}
                    onChange={this.onChange}
                    filterOption={(inputValue, item) =>
                      item.title.indexOf(inputValue) !== -1
                    }
                    filteredItems={this.state.transferDataSource}
                    leftColumns={this.leftTableColumns}
                    rightColumns={this.rightTableColumns}
                  />
                  {this.state.exportOptionColErrorMessage !== "" && (
                    <div className="text-danger">
                      {this.state.exportOptionColErrorMessage}
                    </div>
                  )}
                </div>
                <InputGroup className="mt-3 pl-3">
                  <label>Select Export File Type : </label>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15 me-5">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="xlsx"
                        checked={this.state.alertType === "xlsx" ? true : false}
                        type={"radio"}
                        id="xlsx"
                        label={"XLSX"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-3 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="csv"
                        checked={this.state.alertType === "csv" ? true : false}
                        type={"radio"}
                        id="csv"
                        label={"CSV"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                  </div>
                </InputGroup>

                {this.state.exportMesssage !== "" && (
                  <div className="mt-3 pl-3">
                    {this.state.exportMesssage}
                    <br></br>
                    <br></br>
                    {/*{this.state.exportFilePath !== undefined && (
                      <a href={this.state.exportFilePath}> Download File </a>
                    )} */}
                  </div>
                )}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="mx-auto- mg-r-30 btn btn btn-success btn-block"
                    onClick={() => this.ExportData()}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Export"}
                  </button>
                  <button
                    type="button"
                    className="mx-auto- m-0 btn btn-outline-light btn-block"
                    onClick={() => this.exportToggle()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* import data modal */}
        <Modal
          show={this.state.importModal}
          className="modal-xs importModalbox"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-center mt-0 row">
                  <form name="myFile">
                    <FileUploader
                      onChange={this.changeHandler}
                      handleChange={this.handleChange}
                      name="file_name"
                      types={["csv", "xlsx", "xls", "ods"]}
                      label="Drop File Here Max Size 30 MB"
                    />

                    {this.state.errors.file_name ? (
                      <div className="text-danger">
                        {this.state.errors.file_name}
                      </div>
                    ) : (
                      this.validator.message(
                        "import_file",
                        this.state.file_name,
                        "required",
                        { className: "text-danger" }
                      )
                    )}

                    <div className="d-flex justify-content-center mt-3 ">
                      <button
                        type="button"
                        className="m-0 btn btn-outline-light btn-block"
                        onClick={() => this.closeConfirmImportModal()}
                      >
                        Cancel
                      </button>

                      <button
                        type="button"
                        className="m-0 btn btn btn-success btn-block"
                        onClick={this.confirmImport}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit ? global.loader : "Import"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row col-12 m-0 download-sample">
              <Link
                to="/import_sample/Reservation.xlsx"
                target="_blank"
                download="SampleReservation.xlsx"
              >
                Download sample template for Import
              </Link>
            </div>
          </Modal.Body>
        </Modal>
        {/* import data modal */}
        <Modal
          show={this.state.importResponseModel}
          className="modal-xs recordDetailmodalbox"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeimportResponseModel()}
            >
              {global.closee}
            </span>
            {/* <h3 className="text-center">Record detail</h3> */}
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-between mt-0 row">
                  {this.state.errorResponse == true ? (
                    <div className="msg">
                      <img src={ErrorLogo} alt="filterIcon" />{" "}
                      <p>{this.state.errorMessage}</p>{" "}
                    </div>
                  ) : (
                    <div className="msg">
                      <img src={SuccessLogo} alt="filterIcon" />
                      <p>{this.state.successMessage}</p>{" "}
                    </div>
                  )}
                  {this.state.totalRecord ? (
                    <div className="moreMsg">
                      <div className="row flex-wrap m-0">
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Total Record
                          </p>
                          <p className="media-body">{this.state.totalRecord}</p>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Success Record
                          </p>
                          <p className="media-body">
                            {this.state.successRecord}
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Skip Record
                          </p>
                          <p className="media-body">{this.state.skipRecord}</p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <p className="az-profile-name-text pl-0">Skip Data</p>
                          <p
                            className="media-body pr-3"
                            dangerouslySetInnerHTML={{
                              __html: this.state.skipData
                                ? this.state.skipData
                                : "No any record skip",
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.isGuestImageModal}
          centered
          className="modal-large agreementdocModal"
        >
          <Modal.Body className="width_402 p-4 border_radius_14 ">
            <div className="">
              {" "}
              <span
                className="welcomeClose"
                onClick={() => this.showGuestImages()}
              >
                {global.closee}
              </span>
              {this.state.front_image_url &&
                this.state.back_image_url &&
                this.state.selfi_image_url && (
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <h3> Front Image</h3>
                        <img
                          onError={(e) => {
                            e.currentTarget.src = this.state.front_image_url;
                          }}
                          src={this.state.front_image_url}
                          alt="Front_image"
                          className="doc_upld_guest"
                          onClick={(e) =>
                            window.open(this.state.front_image_url, "_blank")
                          }
                        />
                      </div>

                      <div className="col-md-4">
                        <h3> Back Image</h3>
                        <img
                          onError={(e) => {
                            e.currentTarget.src = this.state.back_image_url;
                          }}
                          src={this.state.back_image_url}
                          alt="Back_image"
                          className="doc_upld_guest"
                          onClick={(e) =>
                            window.open(this.state.back_image_url, "_blank")
                          }
                        />
                      </div>

                      <div className="col-md-4">
                        <h3> Selfie Image</h3>
                        <img
                          onError={(e) => {
                            e.currentTarget.src = this.state.selfi_image_url;
                          }}
                          src={this.state.selfi_image_url}
                          alt="Selfi_image"
                          className="selfi_img"
                          onClick={(e) =>
                            window.open(this.state.selfi_image_url, "_blank")
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <p>
                        Verified on: &nbsp;
                        {this.state.activated_at
                          ? moment(moment.utc(this.state.activated_at).toDate())
                              .local()
                              .format(global.dateTimeFormat)
                          : ""}
                      </p>
                    </div>
                    <div>
                      <p>
                        Guest agreement PDF:{" "}
                        {this.state.receipt_pdf_url !== null ? (
                          <img
                            src={pdfIcon}
                            alt="receipt_pdf"
                            className="receipt_pdf_guest"
                            onClick={(e) =>
                              window.open(this.state.receipt_pdf_url, "_blank")
                            }
                          />
                        ) : (
                          "N/A"
                        )}
                      </p>
                    </div>
                  </>
                )}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Reservations;
