////////////////////////////////////////////////////////////
//  Program: InspectionView.jsx                           //
//  Application:  Inspection View                         //
//  Option: List of Template                              //
//  Developer: Ashish Kumar                               //
//  Date: 2022-05-20                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Container, Table, Modal } from "react-bootstrap";
import HouseKeepingSidebar from "../common/HouseKeepingSidebar";
import InspectionService from "../../services/InspectionService";
import Moment from "moment";
import blacktick from "./../../assets/images/black-tick.svg";
import bluetick from "./../../assets/images/blue-tick.svg";
import redtick from "./../../assets/images/red-tick.svg";
import { Link } from "react-router-dom";
import brownArrow from "./../../assets/images/brown-arrow.svg";
import deleteicon from "../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import modaldeleteicon from "./../../assets/images/delete.svg";
export class InspectionView extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      showBasicModal: false,
      slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug,
      submission: [],
      mro: [],
      answer: global.mroAnswer,
      isSubmit: false,
      loading: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.deleteInspection = this.deleteInspection.bind(this);
    this.opendeleteInspectionModal = this.opendeleteInspectionModal.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await InspectionService.getInspectionBySlug(this.state.slug);

      if (global.successStatus.includes(res.status)) {
        resData = res.data.data ? res.data.data : {};
        resData.inspection_no = resData.inspection_no
          ? resData.inspection_no
          : "";
        resData.zone_type = resData.zone_type
          ? resData.zone_type.zone_type_name
          : "";
        resData.checklist = resData.checklist
          ? resData.checklist.checklist_name
          : "";
        resData.property = resData.property
          ? resData.property.property_name
          : "";
        resData.technician = resData.inspector
          ? resData.inspector.staff_contact_name
          : "";

        resData.status_id = resData.status_id === 1 ? true : false;
        this.setState(resData);
        this.setState({
          time_out: Moment(resData.completed_on).format(global.dateTimeFormat),
        });
        this.setState({
          time_in: Moment(resData.started_on).format(global.dateTimeFormat),
        });
      }
      this.setState({ loading: false });
    }
  }

  opendeleteInspectionModal(slug) {
    this.setState({ deleteInspectionModal: true, slug: slug });
  }

  closedeleteInspectionModal() {
    this.setState({ deleteInspectionModal: false, slug: "" });
  }

  async deleteInspection(slug) {
    this.setState({ showSpinner: true });
    let res = await InspectionService.deleteInspection(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteInspectionModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" + this.state.property_slug + "/inspections"
          ),
        global.redirect_time
      );
    } else {
      this.closedeleteInspectionModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  closeModal() {
    this.setState({ showBasicModal: false });
  }

  getTitle() {
    return "Inspections";
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <HouseKeepingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {this.state.slug !== undefined ? (
                        <>
                          <span
                            onClick={() => {
                              this.opendeleteInspectionModal(this.state.slug);
                            }}
                            className="deleteicon mg-r-20"
                          >
                            <img src={deleteicon} alt="" />
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15 pd-r-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pl-lg-l-30 pd-xs-l-15 pr-lg-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="m-0">
                      <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-xs-l-15 border-0">
                        <h3 className="mg-l-0 background-head">Info</h3>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pl-0">
                          <p className="az-profile-name-text pl-0">
                            Inspection No.
                          </p>
                          <p className="media-body">
                            {this.state.inspection_no
                              ? this.state.inspection_no
                              : ""}
                          </p>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pl-0">
                          <p className="az-profile-name-text pl-0">In Time</p>
                          <p className="media-body">
                            {this.state.time_in ? this.state.time_in : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pl-0">
                          <p className="az-profile-name-text pl-0">Out Time</p>
                          <p className="media-body">
                            {this.state.time_out ? this.state.time_out : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pl-0">
                          <p className="az-profile-name-text pl-0">Applicable</p>
                          <p className="media-body">
                            {this.state.zone_type ? this.state.zone_type : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pl-0">
                          <p className="az-profile-name-text pl-0">
                            Technician
                          </p>
                          <p className="media-body">
                            {this.state.technician
                              ? this.state.technician
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pl-0">
                          <p className="az-profile-name-text pl-0">Checklist</p>
                          <p className="media-body">
                            {this.state.checklist ? this.state.checklist : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pl-0">
                          <p className="az-profile-name-text pl-0">Property</p>
                          <p className="media-body">
                            {this.state.property ? this.state.property : ""}
                          </p>
                        </div>
                      </div>
                      <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-xs-l-15 border-0">
                        <h3 className="mg-l-0 mg-t-15 background-head">Task</h3>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 InspectionView pl-0">
                          <Table className="table mg-b-0 tableFormatesimple">
                            <thead>
                              <tr>
                                <th>TASK</th>
                                <th>YES</th>
                                <th>NO</th>
                                <th className="az-inspection-blue">NOTES</th>
                                <th className="az-inspection-red">MRO</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.category
                                ? this.state.category.map((element, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <th
                                        colSpan="5"
                                        className="p-0 border-0"
                                      >
                                        <caption>
                                          {element.category_name
                                            ? element.category_name
                                            : ""}
                                        </caption>
                                      </th>
                                    </tr>

                                    {element.tasks
                                      ? element.tasks.map((el, indexes) => (
                                        <React.Fragment key={indexes}>
                                          <tr>
                                            <td
                                              scope="row"
                                              className="az-inspection-black"
                                            >
                                              {" "}
                                              {el.task_name
                                                ? el.task_name
                                                : ""}{" "}
                                            </td>
                                            <td>
                                              {" "}
                                              {el.task_status === 1 ? (
                                                <img
                                                  src={blacktick}
                                                  alt=""
                                                />
                                              ) : (
                                                ""
                                              )}{" "}
                                            </td>
                                            <td>
                                              {" "}
                                              {el.task_status === 2 ? (
                                                <img
                                                  src={blacktick}
                                                  alt=""
                                                />
                                              ) : (
                                                ""
                                              )}{" "}
                                            </td>
                                            <td>
                                              {" "}
                                              {el.note !== "" ? (
                                                <label
                                                  className="log hand-cursor text-primary"
                                                  onClick={() =>
                                                    this.setState({
                                                      showBasicModal: true,
                                                      title: el.task_name,
                                                      note: el.note,
                                                    })
                                                  }
                                                >
                                                  <img
                                                    src={bluetick}
                                                    alt=""
                                                  />
                                                </label>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </td>
                                            <td>
                                              {" "}
                                              <code>
                                                {el.is_mro_with_note ===
                                                  1 ? (
                                                  <img
                                                    src={redtick}
                                                    alt=""
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </code>
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      ))
                                      : ""}
                                  </React.Fragment>
                                ))
                                : ""}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-xs-l-15 border-0">
                        <h3 className="mg-l-0 mg-t-15 background-head">MRO</h3>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pl-0">
                          <Table striped className="mg-b-0">
                            <thead>
                              <tr>
                                <th className="border-right-0">MRO Written</th>
                                <th className="border-left-0">View Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.category
                                ? this.state.category.map((elm, j) =>
                                  elm.tasks
                                    ? elm.tasks.map((el, i) =>
                                      el.is_mro_with_note === 1 ? (
                                        <tr key={i}>
                                          <td
                                            scope="row"
                                            className="az-inspection-black border-right-0"
                                          >
                                            {" "}
                                            {el.mro
                                              ? el.mro.title
                                              : ""}{" "}
                                            <Link
                                              to={
                                                "/properties/view/" +
                                                this.state.property_slug +
                                                "/mros/" +
                                                el.mro.slug
                                              }
                                              className="az-inspection-brown"
                                            >
                                              {" "}
                                              <code>
                                                {" "}
                                                (#
                                                {el.mro
                                                  ? el.mro.mro_number
                                                  : ""}
                                                -MRO
                                              </code>{" "}
                                              )
                                            </Link>{" "}
                                          </td>
                                          <td className="border-left-0 pl-5">
                                            <i className="mdi mdi-eye-outline text-primary"></i>
                                            <Link
                                              to={
                                                "/properties/view/" +
                                                this.state.property_slug +
                                                "/mros/" +
                                                el.mro.slug
                                              }
                                              className=""
                                            >
                                              <img
                                                src={brownArrow}
                                                alt=""
                                              />
                                            </Link>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )
                                    )
                                    : ""
                                )
                                : ""}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.showBasicModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.closeModal()}>
              {global.closee}
            </span>

            <h3 className="text-center">Notes</h3>
            <p className="text-center media-body model_first_p">
              {this.state.title}
            </p>
            <p className="text-center">{this.state.note}</p>
            <p className="text-center">
              {this.state.mroImages
                ? this.state.mroImages.map((record, i) => {
                  return (
                    <>
                      <div key={"Data" + i} className="img_close">
                        <img
                          src={record.image_url}
                          className="brand_list_image"
                          alt={record.slug}
                        />
                      </div>
                    </>
                  );
                })
                : ""}
            </p>

            <div className="d-flex justify-content-between mt-3 row">
              <button
                type="button"
                className="mx-auto m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeModal()}
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.deleteInspectionModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteInspectionModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteInspectionModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className=" m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteInspection(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default InspectionView;
