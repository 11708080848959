/////////////////////////////////////////////////////////////
//  Program: messages fileter.jsx                          //
//  Application: messages                                  //
//  Option: Home Sharing Listing                           //
//  Developer: Kiran                                   //
//  Date: 2022-03-07                                       //
///////////////////////////////////////////////////////// ///
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import PropertyService from "../../services/PropertyService";
import UnitsService from "../../services/UnitsService";

class MessageFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNewModelOpenFilter:this.props.isFilterModel,
            selectedProList: [],
            roleList:global.RoleListForQuickReply ? global.RoleListForQuickReply : null,
            role_slug: global.currerntRoleSlug ? global.currerntRoleSlug : null,
            currently_hosting:0,
            check_in_today:0,
            check_out_today:0,
            check_in_tomorrow:0,
            check_out_tomorrow:0,
            past_guest:0,
            future_guest:0,
            current_resident:0,
            Past_resident:0,
            future_resident:0,
            is_support_thread:0,
            filterString: this.props.filterString ? this.props.filterString : "",
            property_slug:this.props.property_slug,
            proSelect: this.props.property_slug ? true : false,
          is_filter: 0,
          checked_status:false,
        };
        this.openModalFilter = this.openModalFilter.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.cancelModalFilter = this.cancelModalFilter.bind(this);
        this.customFilter = this.customFilter.bind(this);
    }
    componentDidMount() { 
        console.log("component filter", this.state.filter);
        this.getPropertyList();
        if(this.state.filterString  != undefined){
            var parts = this.state.filterString.split(/[&]/);
            var mapped = parts.map(item => {return item.split(/[=]/);});
            const result = mapped.reduce((obj, arrValue) => (obj[arrValue[0]] = arrValue[1], obj), {});
            if(result.currently_hosting == 1){
                this.setState({ currently_hosting : 1});
            }
            if(result.check_in_today == 1){
                this.setState({ check_in_today : 1});
            }
            if(result.check_out_today == 1){
                this.setState({ check_out_today : 1});
            }
            if(result.check_in_tomorrow == 1){
                this.setState({ check_in_tomorrow : 1});
            }
            if(result.check_out_tomorrow == 1){
                this.setState({ check_out_tomorrow : 1});
            }
            if(result.past_guest == 1){
                this.setState({ past_guest : 1});
            }
            if(result.future_guest == 1){
                this.setState({ future_guest : 1});
            }
            if(result.Past_resident == 1){
                this.setState({ Past_resident : 1});
            }
            if(result.current_resident == 1){
                this.setState({ current_resident : 1});
            }
            if(result.future_resident == 1){
                this.setState({ future_resident : 1});
            }
            if(result.unread_count == 1){
                this.setState({ unread_count : 1});
            }
            if(result.is_support_thread == 1){
                this.setState({ is_support_thread : 1});
            }
            
            if(result.role != undefined){
            if (this.state.roleList.length > 0) {
                this.state.roleList.map(item => {
                if(item.value == result.role){
                    var roleOption =  { label: item.label, value: item.value};
                    this.handleChangeRoleList(roleOption);
                }
                });
            }
            }
            setTimeout(() => {
                if(result.property_slug != undefined){
                    if (this.state.newProList != undefined && this.state.newProList.length > 0) {
                        this.state.newProList.map(item => {
                        if(item.value == result.property_slug){
                        var proOption =  { label: item.label, value: item.value};
                        this.handleChangeProList(proOption);
                        }
                    });
                    }
                }
            }, 2000);
            setTimeout(() => {
            if(result.unit_slug != undefined && this.state.newUnitList != undefined){
                if (this.state.newUnitList.length > 0) {
                    this.state.newUnitList.map(item => {
                        if(item.value == result.unit_slug){
                        var unitOption =  { label: item.label, value: item.value};
                        this.handleChangeUnitList(unitOption);
                        }
                    });
                    }
                }
            }, 4000);
        }
    }
    async getPropertyList(queryString = "") {
        var proList = [];
        var proListTotal = 0;
        let res = await PropertyService.getPropertyList(
        "is_dropdown=1" + (queryString ? "&" + queryString : "")
        );
        if (global.successStatus.includes(res.status)) {
            proList = res.data ? res.data : [];
            proListTotal = proList ? proList.length : 0;
        }
        const newProList =
        proListTotal > 0
        ? proList.map(({ slug, property_name }) => ({
            value: slug,
            label: property_name,
            }))
        : [];
        this.setState({
            newProList: newProList,
            proListTotal: proListTotal,
           
        });
        if(this.state.property_slug != undefined &&  this.state.newProList != undefined){
            this.state.newProList.map(item => {
                if(item.value == this.state.property_slug){
                 var proOption =  { label: item.label, value: item.value};
                 this.handleChangeProList(proOption);
                }
             });
        }
    }
    handleChangeProList(value) {
        let pro_id = [];
        if (value != null && value.length > 0) {
            value.forEach((item, i) => {
                pro_id.push(item.value);
            });
        }
        this.setState({
            selectedProList: value,
            pro_id: value.value,
            property_slug: value.value,
          filter: 1,
          is_filter: 1,
        });
        this.getUnitList(value.value);
       
    }
    /* to get unit data list */
    async getUnitList(propertySlug, queryString = "") {
        this.setState({ showSpinner: true, loading: true });
        var unitList = [];
        var unitListTotal = 0;

        let res = await UnitsService.getUnitsList(
        "is_dropdown=1&property_slug=" + propertySlug,
        queryString ? "&" + queryString : ""
        );
        if (global.successStatus.includes(res.status)) {
        unitList = res.data ? res.data : [];
        unitListTotal = unitList ? unitList.length : 0;
        }
        const newUnitList =
        unitListTotal > 0
            ? unitList.map(({ slug, unit_number, unit_name }) => ({
                value: slug,
                label: unit_name ? unit_name : unit_number,
            }))
            : [];
        this.setState({
        unitList: unitList,
        unitListTotal: unitListTotal,
        newUnitList: newUnitList,
        });
        this.setState({ showSpinner: false, loading: false });
    } 
    handleChangeUnitList(value) {
        let unit_slug = [];
        if (value != null && value.length > 0) {
          value.forEach((item, i) => {
            unit_slug.push(item.value);
          });
        }
        const unit_slug_value = value !== null ? value.value : [];
        this.setState({
          selectedUnitList: value,
          unit_slug: unit_slug_value,
        });
    }
    handleChangeRoleList(value) {
        let role_id = [];
        if (value != null && value.length > 0) {
        value.forEach((item, i) => {
            role_id.push(item.value);
        });
        }
        this.setState({
        selectedRoleList: value,
            role_id: value.value,
          filter: 1,
          is_filter: 1,
        });    
    }
    changeHandler = (event) => {
        let name = event.target.name;
      let value = event.target.checked;
        var currentStatus=0;
        if(name == "currently_hosting"){
            if(this.state.currently_hosting == 0){
                currentStatus = 1;
            } 
            this.setState({ currently_hosting : currentStatus, checked_status: value });
        }
        if(name == "check_in_today"){
            if(this.state.check_in_today == 0){
                currentStatus = 1;
            }
            this.setState({ check_in_today : currentStatus});
        }
        if(name == "check_out_today"){
            if(this.state.check_out_today == 0){
                currentStatus = 1;
            }
            this.setState({ check_out_today : currentStatus});
        }
        if(name == "check_in_tomorrow"){
            if(this.state.check_in_tomorrow == 0){
                currentStatus = 1;
            }
            this.setState({ check_in_tomorrow : currentStatus});
        }
        if(name == "check_out_tomorrow"){
            if(this.state.check_out_tomorrow == 0){
                currentStatus = 1;
            }
            this.setState({ check_out_tomorrow : currentStatus});
        }
        if(name == "unread_count"){
            if(this.state.unread_count == 0){
                currentStatus = 1;
            }
            this.setState({ unread_count : currentStatus});
        }
        if(name == "past_guest"){
            if(this.state.past_guest == 0){
                currentStatus = 1;
            }
            this.setState({ past_guest : currentStatus});
        }
        if(name == "future_guest"){
            if(this.state.future_guest == 0){
                currentStatus = 1;
            }
            this.setState({ future_guest : currentStatus});
        }
        if(name == "Past_resident"){
            if(this.state.Past_resident == 0){
                currentStatus = 1;
            }
            this.setState({ Past_resident : currentStatus});
        }
        if(name == "current_resident"){
            if(this.state.current_resident == 0){
                currentStatus = 1;
            }
            this.setState({ current_resident : currentStatus});
        }
        if(name == "future_resident"){
            if(this.state.future_resident == 0){
                currentStatus = 1;
            }
            this.setState({ future_resident : currentStatus});
        }
        if(name == "is_support_thread"){
            if(this.state.is_support_thread == 0){
                currentStatus = 1;
            }
            this.setState({ is_support_thread : currentStatus});
      }
      if (value === true) {
        this.setState({ is_filter : 1});
      } else {
        this.setState({ is_filter : 0});
      } 
    }
    customFilter() {
      let queryString = "is_filter=" + this.state.is_filter +
          "&property_slug=" + this.state.property_slug +
           "&unit_slug=" + this.state.unit_slug +
           "&role_slug=" + this.state.role_slug +    
           "&check_in_today=" + this.state.check_in_today +
           "&check_out_today=" + this.state.check_out_today +
           "&check_in_tomorrow=" + this.state.check_in_tomorrow +
           "&check_out_tomorrow=" + this.state.check_out_tomorrow +
           "&past_guest=" + this.state.past_guest +
           "&future_guest=" + this.state.future_guest +
           "&Past_resident=" + this.state.Past_resident +
           "&current_resident=" + this.state.current_resident +
           "&future_resident=" + this.state.future_resident +
           "&currently_hosting=" + this.state.currently_hosting +
           "&role=" + this.state.role_id +  
           "&is_support_thread=" + this.state.is_support_thread +  
        "&unread_count=" + this.state.unread_count;   
        if (
          this.state.property_slug ||
          this.state.unit_slug ||
          this.state.role_slug ||
          this.state.check_in_today ||
          this.state.check_out_today ||
          this.state.check_in_tomorrow ||
          this.state.check_out_tomorrow ||
          this.state.past_guest ||
          this.state.future_guest ||
          this.state.Past_resident ||
          this.state.current_resident ||
          this.state.future_resident ||
          this.state.currently_hosting ||
          this.state.role_id ||
          this.state.is_support_thread
        ) {
          this.setState({ customQueryString: queryString });
          this.props.customThreadfilter(queryString, 1);
          this.setState({ is_filter: 1 });
          // this.searchToggle();
          // this.setState({ is_search: 1 });
          // this.setState({ module_search: moduleSearchString.split(",") });
        }
        
       

        
    }
    openModalFilter() {
        this.setState({
            isNewModelOpenFilter: true,
        });
    }
    closeModalFilter() {
        this.setState({
            isNewModelOpenFilter: false,
        });
        this.props.isFilterModelUpdate(0);
    }
    cancelModalFilter() {
        this.setState({
            isNewModelOpenFilter: false,
            is_filter: 0,
            filter:false,
        });
        let queryString = "";
        this.props.isFilterModelUpdate(0);
        this.props.customThreadfilter(queryString, 1);
      
    }
    render() {
        const customStyles = {
        option: (provided, state) => ({
        ...provided,
        color:
            state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
            state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
        }),
    };
    return (
      <Modal
        show={this.state.isNewModelOpenFilter}
        onHide={() => this.closeModalFilter("isNewModelOpenFilter")}
        className="modal-large"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Body>
          <h3 className="text-center mb-2">Filter Messages</h3>
          <span
            className="welcomeClose"
            onClick={() => this.closeModalFilter("isNewModelOpenFilter")}
          >
            {global.closee}
          </span>
          <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
            <div className="col-lg-9 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="row">
                <div className="col-md-6">
                  <InputGroup className="mt-3">
                    <label>Property</label>
                    <div className="col-md-12 p-0">
                      <Select
                        isDisabled={this.state.proSelect}
                        styles={customStyles}
                        className="multiselect"
                        menuPlacement="auto"
                        value={this.state.selectedProList}
                        options={this.state.newProList}
                        getOptionValue={(option) => `${option.label}`}
                        onChange={(value) => this.handleChangeProList(value)}
                        defaultValue={this.state.selectedProList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",
                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />
                    </div>
                  </InputGroup>
                </div>
                <div className="col-md-6">
                  <InputGroup className="mt-3">
                    <label>Unit</label>
                    <div className="col-md-12 p-0">
                      <Select
                        styles={customStyles}
                        className="multiselect"
                        menuPlacement="auto"
                        value={this.state.selectedUnitList}
                        options={this.state.newUnitList}
                        //   getOptionValue={(option) => `${option.label}`}
                        onChange={(value) => this.handleChangeUnitList(value)}
                        defaultValue={this.state.selectedUnitList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",
                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />
                    </div>
                  </InputGroup>
                </div>
              </div>
            </div>
            <div className="col-lg-3 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
              <InputGroup className="mt-3">
                <label>Role Group</label>
                <div className="col-md-12 p-0">
                  <Select
                    styles={customStyles}
                    className="multiselect"
                    menuPlacement="auto"
                    value={this.state.selectedRoleList}
                    options={this.state.roleList}
                    getOptionValue={(option) => `${option.label}`}
                    onChange={(value) => this.handleChangeRoleList(value)}
                    defaultValue={this.state.selectedRoleList}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 2,
                      colors: {
                        ...theme.colors,
                        primary: "#fff",
                        primary75: "#000",
                        primary50: "#000",
                        primary25: "#000",
                      },
                    })}
                  />
                </div>
              </InputGroup>
            </div>
            <div className="col-lg-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="row">
                <div className="col-md-6">
                  <Form.Check
                    type="checkbox"
                    id="currently_hosting"
                    label="Currently Hosting"
                    className="ckbox mg-t-15"
                    name="currently_hosting"
                    checked={this.state.currently_hosting === 1 ? true : false}
                    onChange={this.changeHandler}
                  />
                  <Form.Check
                    type="checkbox"
                    id="check_in_today"
                    //value="2"
                    label="Check-In Today"
                    className="ckbox mg-t-15"
                    name="check_in_today"
                    checked={this.state.check_in_today === 1 ? true : false}
                    onChange={this.changeHandler}
                  />
                  <Form.Check
                    type="checkbox"
                    id="check_out_today"
                    // value="3"
                    label="Check-Out Today"
                    className="ckbox mg-t-15"
                    name="check_out_today"
                    checked={this.state.check_out_today === 1 ? true : false}
                    onChange={this.changeHandler}
                  />
                </div>
                <div className="col-md-6 pd-lg-l-0 pd-xs-l-15">
                  <Form.Check
                    type="checkbox"
                    id="check_in_tomorrow"
                    /// value="4"
                    label="Check-In Tomorrow"
                    className="ckbox mg-t-15"
                    name="check_in_tomorrow"
                    checked={this.state.check_in_tomorrow === 1 ? true : false}
                    onChange={this.changeHandler}
                  />
                  <Form.Check
                    type="checkbox"
                    id="check_out_tomorrow"
                    // value="5"
                    label="Check-Out Tomorrow"
                    className="ckbox mg-t-15"
                    name="check_out_tomorrow"
                    checked={this.state.check_out_tomorrow === 1 ? true : false}
                    onChange={this.changeHandler}
                  />
                  <Form.Check
                    type="checkbox"
                    id="past_guest"
                    label="Past Guest"
                    className="ckbox mg-t-15"
                    name="past_guest"
                    checked={this.state.past_guest === 1 ? true : false}
                    onChange={this.changeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="row">
                <div className="col-md-6">
                  <Form.Check
                    type="checkbox"
                    id="future_guest"
                    label="Future Guest"
                    className="ckbox mg-t-15"
                    name="future_guest"
                    checked={this.state.future_guest === 1 ? true : false}
                    onChange={this.changeHandler}
                  />
                  <Form.Check
                    type="checkbox"
                    id="current_resident"
                    label="Current Resident"
                    className="ckbox mg-t-15"
                    name="current_resident"
                    checked={this.state.current_resident === 1 ? true : false}
                    onChange={this.changeHandler}
                  />

                  <Form.Check
                    type="checkbox"
                    id="Past_resident"
                    label="Past Resident"
                    className="ckbox mg-t-15"
                    name="Past_resident"
                    checked={this.state.Past_resident === 1 ? true : false}
                    onChange={this.changeHandler}
                  />
                </div>
                <div className="col-md-6 pd-lg-l-0 pd-xs-l-15">
                  <Form.Check
                    type="checkbox"
                    id="future_resident"
                    label="Future Resident"
                    className="ckbox mg-t-15"
                    name="future_resident"
                    checked={this.state.future_resident === 1 ? true : false}
                    onChange={this.changeHandler}
                  />

                  <Form.Check
                    type="checkbox"
                    id="unread_count"
                    label="Unread"
                    className="ckbox mg-t-15 d-none"
                    name="unread_count"
                    checked={this.state.unread_count === 1 ? true : false}
                    onChange={this.changeHandler}
                  />
                  {this.props.property_slug ? (
                    ""
                  ) : (
                    <>
                      <Form.Check
                        type="checkbox"
                        id="is_support_thread"
                        label="OH Support"
                        className="ckbox mg-t-15"
                        name="is_support_thread"
                        checked={
                          this.state.is_support_thread === 1 ? true : false
                        }
                        onChange={this.changeHandler}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4 row">
            <button
              type="button"
              className="m-0 btn btn-outline-light btn-block"
              onClick={() => this.cancelModalFilter()}
            >
              Clear
            </button>
            <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                  onClick={() => this.customFilter()}
                  disabled={this.state.is_filter === 0 ? true : false}
              >
              {this.state.is_filter === 1 ? "Filter" : "Filter"}
              </button>
              
            
          </div>
        </Modal.Body>
      </Modal>
    );
    }
}
export default MessageFilter;
