/*-------------------------------------------------------
||Program           : GuestReview.jsx
||Application       : Snapshot
||Option            : Snapshot - Display most recent received reviews in one widget
||Developer         : Chandra Shekhar
||Date              : 10-July-2023
||Status            : In Use
----------------------------------------------------------*/
import React, { Component } from "react";
import { withRouter } from "react-router";
import CompanyService from "../../services/CompanyService";
import NoBooking from "../../assets/images/snapshoticons/Bookings.svg";
import StarRatings from "react-star-ratings";
import { Link } from "react-router-dom";

class GuestReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertySlug: this.props.propertySlug,
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      reservationCount: 0,
      review_list: [],
    };
  }

  componentDidMount() {
    this.setState({ review_list: [] });
    this.setState({ spinnerLoader: true, loading: true });
    this.setState({reservationCount : 0});
    if (this.state.propertySlug) {
      this.getData(this.state.propertySlug);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ review_list: [] });
    this.setState({ spinnerLoader: true, loading: true });
    this.setState({reservationCount : 0});
    if (nextProps.propertySlug) {
      this.getData(nextProps.propertySlug);
    }
  }

  async getData(propertySlug) {
    var resData = {};
    let res = await CompanyService.getGuestReviw(propertySlug);
    if (global.successStatus.includes(res.status)) {
      resData.review_list = res.data ? res.data : [];
      this.setState({
        resData: resData,
        reservationCount: res.data.length,
      });
    }
    this.setState({ spinnerLoader: false, loading: false });
  }

  handleClickCheckinList(property_slug, reservation_slug) {
    if (reservation_slug) {
      const win = window.open(
        "/properties/view/" +
          property_slug +
          "/reservations/view/" +
          reservation_slug,
        "_blank"
      );
      win.focus();
    } else {
      return false;
    }
  }

  render() {
    return (
      <div className="col-xxl-6 col-md-6 col-sm-12 col-12 mg-b-20">
        <div className="card card-table-two">
          <div className=" d-flex justify-content-between">
            <div className="">
              <h6 className="card-title">Reviews</h6>
              <span className="d-block mg-b-0">Recent guest review</span>
            </div>
              <Link className="btn-snap-view" to={"/reviews"}>
                View all
              </Link>
          </div>
          {this.state.reservationCount > 0 ? (
            <div className="table-responsive">
              <table className="table table-dashboard-two mg-b-0">
                <thead>
                  <tr>
                    <th className="wd-lg-25p tx-left">S.No</th>
                    <th className="wd-lg-25p tx-left">Unit Name</th>
                    <th className="wd-lg-25p text-left">Guest Name</th>
                    <th className="wd-lg-25p text-left">Confirmation Code</th>
                    <th className="wd-lg-25p text-left">Rating</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.resData.review_list.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        value={item}
                        onClick={() =>
                          this.handleClickCheckinList(
                            item.property_slug,
                            item.reservation_slug
                          )
                        }
                      >
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {i + 1}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.unit_name ? item.unit_name : ""}
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.recipient_name ? item.recipient_name : ""}
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.confirmation_code ? item.confirmation_code : ""}
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">
                          {/* {item.rating && item.rating > 0
                            ? item.rating + "/6"
                            : ""} */}
                          <StarRatings
                            rating={item.rating ? parseFloat(item.rating) : 0}
                            starRatedColor="rgb(255,165,52)"
                            numberOfStars={5}
                            starDimension="20px"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <div className="table-responsive overflow-hidden">
                {this.state.spinnerLoader ? (
                  global.snapshotloader
                ) : (
                  <div className="text-center">
                    <img src={NoBooking} alt="" />
                    <p>No Guest Reviews.</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(GuestReview);
