/////////////////////////////////////////////////////
//     							                   //
//  Program: Basic.jsx                             //
//  Application: Property                          //
//  Option: List all Property Basic info           //
//  Developer: NP, Prakash 						   //
//  Date: 2022-02-09                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import brandsmallLogo from "./../../assets/images/brand_placeholder.png";
import PropertyService from "../../services/PropertyService";
import PropertyTypeService from "../../services/PropertyTypeService";
import TimezoneService from "../../services/TimezoneService";
import BrandService from "../../services/BrandService";
import { Modal } from "react-bootstrap";
import ListingService from "../../services/ListingService";
import PropertySuitabilityService from "../../services/PropertySuitabilityService";
import ParkingTypeService from "../../services/ParkingTypeService";
import SmokingTypeService from "../../services/SmokingTypeService";
import LeedBuildingService from "../../services/LeedBuildingService";
import CommServedService from "../../services/CommServedService";
import OwnershipTypeService from "../../services/OwnershipTypeService";
import MasterDataService from "../../services/MasterDataService";
import UserService from "../../services/UserService";
import { Container, Form, Table } from "react-bootstrap";
import FileWidget from "../fileUploader/FileWidget";
import RentalService from "../../services/RentalService";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import propertyImg from "./../../assets/images/property_placeholder.png";
import propertyLogo from "./../../assets/images/property_placeholder.png";
import PropertySidebar from "../common/PropertySidebar";
import CountryService from "../../services/CountryService";
import CountyService from "../../services/CountyService";
import StateService from "../../services/StateService";
import CityService from "../../services/CityService";
import MetroService from "../../services/MetroService";
import SubMetroService from "../../services/SubMetroService";
import LocationTypeService from "../../services/LocationTypeService";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import Moment from "moment";
import ClubService from "../../services/ClubService";
export class Basic extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      // property_Slug: this.props.propertySlug,
      property_slug: this.props.match.params.propertySlug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      publishData: false,
      affordable_component_check: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      propertyList: [],
      timeZoneList: [],
      newTimeZoneList: [],
      selectedTimeZoneList: [],
      yearList: [],
      brandList: [],
      newBrandList: [],
      newClubList: [],
      selectedBrandList: [],
      selectedClubList: [],
      suitabilityList: [],
      parkingTypeList: [],
      newParkingTypeList: [],
      selectedParkingTypeList: [],
      suitability_id: [],
      newSuitabilityList: [],
      selectedSuitabilityList: [],
      ownershipTypeList: [],
      newOwnershipTypeList: [],
      selectedOwnershipTypeList: [],
      leedBuildingList: [],
      newLeedBuildingList: [],
      selectedLeedBuildingList: [],
      minimumAgeList: [],
      newMinimumAgeList: [],
      selectedMinimumAgeList: [],
      lockTypeList: global.lockTypeList,
      selectedLockTypeList: [],
      ada_compliant: 2,
      imageUrl: propertyLogo,
      imgPropUrl: propertyImg,
      brand_image: brandsmallLogo,
      status_id: "",
      confirm_back: 0,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type")
        ? sessionStorage.getItem("property_type")
        : "",
      property_name: sessionStorage.getItem("property_name")
        ? sessionStorage.getItem("property_name")
        : "",
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      descShow: 0,
      domain_name: "",
      pms_property_name: "",
      lease_inquiry_url: "",
      descShowRules: 0,
      street_1: "",
      street_2: "",
      locationTypeList: [],
      location_type_id: [],
      newLocationTypeList: [],
      selectedLocationTypeList: [],
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      metroList: [],
      selectedMetroList: [],
      newMetroList: [],
      subMetroList: [],
      newSubMetroList: [],
      selectedSubMetroList: [],
      propertyListTotal: 0,
      ownershipTypeListTotal: 0,
      brandListTotal: 0,
      locationTypeListTotal: 0,
      commServedListTotal: 0,
      leedBuildingListTotal: 0,
      smokingTypeListTotal: 0,
      parkingTypeListTotal: 0,
      cityListTotal: 0,
      countyListTotal: 0,
      timeZoneListTotal: 0,
      propertyPhoneNumber: "",
      orionHausPhoneNumber: "",
      property_office_data: [
        {
          day: 1,
          label: "Monday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 2,
          label: "Tuesday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 3,
          label: "Wednesday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 4,
          label: "Thursday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 5,
          label: "Friday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 6,
          label: "Saturday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 7,
          label: "Sunday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
      ],
      dayList: global.dayList,
      isRefresh: 0,
      housekeeping_window_hour: 0,
      check_in_time: new Date(),
      check_out_time: new Date(),
      noiseaware_org_id: "",
      //for rental
      days_before_arrival: "",
      how_to_arrive: "",
      check_out_until: "",
      checkin_place: "",
      late_arrival_fee: "",
      late_arrival_fee_from: "",
      late_arrival_fee_to: "",
      early_departure_fee: "",
      early_departure_fee_from: "",
      early_departure_fee_to: "",
      // cancellation_validto : "",
      // cancellation_validfrom : "",
      // space : "",
      access: "",
      neighborhood: "",
      notes: "",
      orion_haus_account_manager_slug: "",
      property_manager_slug: "",
      accountManagerList: [],
      newAccountManagerList: [],
      selectedAccountManagerList: [],
      selectedPropertyManagerList: [],
      descShowPropertyNotes: 0,
      property_notes : ""
    };
    this.propertyLogo = React.createRef();
    this.propertyImg = React.createRef();
    this.changeView = this.changeView.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.publishAllListing = this.publishAllListing.bind(this);
    this.getPropertyAffordable = this.getPropertyAffordable.bind(this);
    this.fullText = this.fullText.bind(this);
    this.fullTextRules = this.fullTextRules.bind(this);
    this.onLogoImageChange = this.onLogoImageChange.bind(this);
    this.openWebsite = this.openWebsite.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
    this.phoneNumberAutoFormat = this.phoneNumberAutoFormat.bind(this);
    this.fullTextPropertyNotes = this.fullTextPropertyNotes.bind(this);
  }

  async componentDidMount() {
    this.setState({ publishData: true });
    this.setState({ confirm_back: 0 });
    this.getData();
    this.getPropertyOfficeHoursData();
    this.getUserList();
    this.setState({ publishData: false });
  }

  fullTextPropertyNotes() {
    if (this.state.descShowPropertyNotes === 0) {
      this.setState({ descShowPropertyNotes: 1 });
    } else {
      this.setState({ descShowPropertyNotes: 0 });
    }
  }

  async getUserList() {
    //  this.setState({ showSpinner: true, loading: true });
    var accountManagerList = [];

    let res = await UserService.getUserDropdown(
      "roleTitle=OH_MANAGER&roleTitle=PROPERTY_MANAGER"
    );
    if (global.successStatus.includes(res.status)) {
      accountManagerList = res.data ? res.data : [];
    }
    const newAccountManagerList = accountManagerList.map(
      ({ slug, first_name, last_name }) => ({
        value: slug,
        label: first_name + " " + last_name,
      })
    );
    this.setState({
      newAccountManagerList: newAccountManagerList,
      accountManagerList: accountManagerList,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  openXMlModal() {
    this.setState({ xmlRoleModal: true });
    // this.setState({ pushListingRequestXml: records.push_listing_request_xml, pushListingResponseXml:records.push_listing_response_xml});
  }
  closeFullSyncModal() {
    this.setState({ xmlRoleModal: false });
    // this.setState({ pushListingRequestXml: "" ,pushListingResponseXml: ""});
  }

  // =================[For Full sync (Madhav)]===================
  async publishAllListing() {
    this.setState({ publishData: true });
    let property_slug = {
      slug: this.state.property_slug ? this.state.property_slug : "",
      slug_name: "property_level",
    };
    let res = await ListingService.publishAllListing(property_slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      // setTimeout(
      //   () => this.setState({ showAlertModal: false }),
      //   global.alert_close_time
      // );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
      });
      // setTimeout(
      //   () => this.setState({ showAlertModal: false }),
      //   global.alert_close_time
      // );
    }
    this.setState({ publishData: false });
    this.closeFullSyncModal();
  }

  handleChangeAccountManagerList(value) {
    let orion_haus_account_manager_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        orion_haus_account_manager_slug.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedAccountManagerList: value,
      orion_haus_account_manager_slug: state_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangePropertyManagerList(value) {
    let property_manager_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_manager_slug.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedPropertyManagerList: value,
      property_manager_slug: state_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  async getData() {
    if (this.state.property_slug !== undefined) {
      var resData = {};
      var todayDate = Moment(new Date()).format("YYYY-MM-DD");

      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        sessionStorage.setItem(
          "property_slug",
          this.state.property_slug ? this.state.property_slug : ""
        );
        sessionStorage.setItem(
          "property_name",
          res.data.property_name ? res.data.property_name : ""
        );
        sessionStorage.setItem(
          "property_type",
          res.data.property_type
            ? res.data.property_type.property_type_name
            : ""
        );
        sessionStorage.setItem(
          "year_value",
          res.data.year_value ? res.data.year_value : ""
        );
        sessionStorage.setItem(
          "property_logo_url",
          res.data.property_logo_url ? res.data.property_logo_url : ""
        );
        sessionStorage.setItem(
          "property_logo_thumb_url",
          res.data.property_logo_thumb_url
            ? res.data.property_logo_thumb_url
            : ""
        );

        localStorage.setItem("property_slug", this.state.property_slug);
        localStorage.setItem("property_name", res.data.property_name);
        localStorage.setItem(
          "property_type",
          res.data.property_type.property_type_name
        );
        localStorage.setItem("year_value", res.data.year_value);
        localStorage.setItem("property_logo_url", res.data.property_logo_url);
        localStorage.setItem(
          "property_logo_thumb_url",
          res.data.property_logo_thumb_url
        );
        // this.props.affordable(res.data.affordable_component_check);
        // this.props.logo(res.data.property_name, res.data.property_type.property_type_name, res.data.year_value, res.data.property_logo_url,this.state.property_slug);

        this.props.propertyName(
          res.data.property_name ? res.data.property_name : ""
        );
        this.props.propertySlug(this.state.property_slug);

        // resData = res.data ? res.data : {}
        resData.property_name = res.data.property_name
          ? res.data.property_name
          : "";
        resData.aka_name = res.data.aka_name ? res.data.aka_name : "";
        resData.domain_name = res.data.domain_name ? res.data.domain_name : "";
        resData.lease_inquiry_url = res.data.lease_inquiry_url
          ? res.data.lease_inquiry_url
          : "";
        resData.sort_order =
          res.data.sort_order === 0 ? "" : res.data.sort_order;
        //for checkbox
        // resData.observer_day_ligth_saving =
        //   res.data.observer_day_ligth_saving === 1 ? true : false;
        resData.insurance_required =
          res.data.insurance_required === 1 ? true : false;
        resData.international_property =
          res.data.international_property === 1 ? true : false;
        resData.allow_direct_booking =
          res.data.allow_direct_booking === 1 ? true : false;
        resData.appear_on_oh = res.data.appear_on_oh === 1 ? true : false;
        // resData.full_listing = res.data.full_listing === 1 ? true : false;
        resData.pet_allowed = res.data.pet_allowed === 1 ? true : false;
        resData.children_friendly =
          res.data.children_friendly === 1 ? true : false;
        resData.infant_friendly = res.data.infant_friendly === 1 ? true : false;
        resData.affordable_component_check =
          res.data.affordable_component_check === 1 ? true : false;
        resData.status_id = res.data.status_id === 1 ? true : false;
        //for view
        resData.suitability_name_data = res.data.suitability_name_data
          ? res.data.suitability_name_data
          : "";
        //for view
        resData.street_1 = res.data.street_1 ? res.data.street_1 : "";
        resData.street_2 = res.data.street_2 ? res.data.street_2 : "";
        resData.country = res.data.country ? res.data.country.country_name : "";
        resData.country_name = res.data.country
          ? res.data.country.country_name
          : "";
        resData.county_name = res.data.county
          ? res.data.county.county_name
          : "";
        resData.state_name = res.data.state ? res.data.state.state_name : "";
        resData.city = res.data.city ? res.data.city.city_name : "";

        resData.zip_code = res.data.zip_code ? res.data.zip_code : "";
        resData.latitude = res.data.latitude ? res.data.latitude : "";
        resData.longitude = res.data.longitude ? res.data.longitude : "";
        resData.google_map_link = res.data.google_map_link
          ? res.data.google_map_link
          : "";
        resData.location_type_name_data = res.data.location_type_name_data
          ? res.data.location_type_name_data
          : "";
        resData.pms_property_name = res.data.pms_property_name
          ? res.data.pms_property_name
          : "";
        resData.ownership_type_name = res.data.ownership_type
          ? res.data.ownership_type.ownership_type_name
          : "";
        resData.brand_name = res.data.brand ? res.data.brand.brand_name : "";
        resData.club_name = res.data.club ? res.data.club.club_name : "";
        resData.pms_property_name = res.data.pms_property_name
          ? res.data.pms_property_name
          : "";

        //for edit
        resData.ada_compliant = res.data.ada_compliant
          ? res.data.ada_compliant
          : 2;
        resData.property_type_id = res.data.property_type
          ? res.data.property_type.slug
          : "";
        resData.brand_id = res.data.brand ? res.data.brand.slug : "";
        resData.timezone_id = res.data.timezone ? res.data.timezone.slug : "";
        resData.parking_type_id = res.data.parking_type
          ? res.data.parking_type.slug
          : "";
        resData.smoking_type_id = res.data.smoking_type
          ? res.data.smoking_type.slug
          : "";
        resData.leed_building_id = res.data.leed_building
          ? res.data.leed_building.slug
          : "";

        resData.communities_served_name_data = res.data
          .communities_served_name_data
          ? res.data.communities_served_name_data
          : "";
        resData.leed_building_name = res.data.leed_building
          ? res.data.leed_building.leed_building_name
          : "";
        resData.smoking_type_name = res.data.smoking_type
          ? res.data.smoking_type.smoking_type_name
          : "";
        resData.parking_type_name = res.data.parking_type
          ? res.data.parking_type.parking_type_name
          : "";
        resData.property_type_name = res.data.property_type
          ? res.data.property_type.property_type_name
          : "";
        resData.timezone_name = res.data.timezone
          ? res.data.timezone.timezone_name
          : "";
        resData.minimum_age_value = res.data.minimumAge
          ? res.data.minimumAge.name
          : "";
        resData.lock_type = res.data.lock_type ? res.data.lock_type : null;
        resData.country_id = res.data.country ? res.data.country.slug : "";
        resData.county_id = res.data.county ? res.data.county.slug : "";
        resData.state_id = res.data.state ? res.data.state.slug : "";
        resData.city_id = res.data.city ? res.data.city.slug : "";
        resData.property_notes = res.data.property_notes ? res.data.property_notes : "";
        // resData.noiseaware_org_id = res.data.noiseaware_org_id ? res.data.noiseaware_org_id : "";

        resData.property_phone_number = res.data.property_phone_number
          ? res.data.property_phone_number
          : "";

        resData.orionhaus_name = res.data.orionhaus_name
          ? res.data.orionhaus_name
          : "";
        resData.orionhaus_email = res.data.orionhaus_email
          ? res.data.orionhaus_email
          : "";
        resData.orionhaus_phone_number = res.data.orionhaus_phone_number
          ? res.data.orionhaus_phone_number
          : "";
        if (res.data && res.data.orionhaus_phone_number) {
          var orionHausPhoneNumber = await this.phoneNumberAutoFormat(
            res.data.orionhaus_phone_number
          );
        }
        if (res.data && res.data.property_phone_number) {
          var propertyPhoneNumber = await this.phoneNumberAutoFormat(
            res.data.property_phone_number
          );
        }

        resData.property_email = res.data.property_email
          ? res.data.property_email
          : "";
        resData.property_manager_name = res.data.property_manager_name
          ? res.data.property_manager_name
          : "";
        resData.maintenance_email = res.data.maintenance_email
          ? res.data.maintenance_email
          : "";
        resData.invoice_email = res.data.invoice_email
          ? res.data.invoice_email
          : "";
        // resData.check_in_time_view = res.data.check_in_time ? res.data.check_in_time : "";
        resData.check_in_time_view = res.data.check_in_time
          ? Moment(res.data.check_in_time, "hh:mm A").format("HH:mm:ss")
          : "";
        resData.check_in_time = res.data.check_in_time
          ? new Date(todayDate + " " + resData.check_in_time_view)
          : "";

        // resData.check_out_time_view = res.data.check_out_time ? res.data.check_out_time : "";
        resData.check_out_time_view = res.data.check_out_time
          ? Moment(res.data.check_out_time, "hh:mm A").format("HH:mm:ss")
          : "";
        resData.check_out_time = res.data.check_out_time
          ? new Date(todayDate + " " + resData.check_out_time_view)
          : "";

        resData.digital_lock_view = res.data.digital_lock === 1 ? true : false;
        resData.digital_lock = res.data.digital_lock === 1 ? true : false;
        resData.minimum_age = res.data.minimum_age ? res.data.minimum_age : "";
        resData.total_affordable_unit = res.data.total_affordable_unit
          ? res.data.total_affordable_unit
          : "";
        resData.asset_managers = res.data.asset_managers
          ? res.data.asset_managers
          : "";
        resData.housekeeping_window_hour = res.data.housekeeping_window_hour
          ? res.data.housekeeping_window_hour
          : "";
        resData.imageUrl = res.data.property_logo_thumb_url
          ? res.data.property_logo_thumb_url
          : propertyLogo;
        resData.property_logo = res.data.property_logo
          ? res.data.property_logo
          : "";
        resData.imgPropUrl = res.data.property_main_image_url
          ? res.data.property_main_image_url
          : propertyImg;
        resData.year_value = res.data.year_value ? res.data.year_value : "";
        resData.brand_image = res.data.brand
          ? res.data.brand.brand_logo_url
          : brandsmallLogo;
        // for rental
        resData.days_before_arrival = res.data.ruPropertyData
          ? res.data.ruPropertyData.days_before_arrival
          : "";
        resData.how_to_arrive = res.data.ruPropertyData
          ? res.data.ruPropertyData.how_to_arrive
          : "";
        resData.check_out_until = res.data.ruPropertyData
          ? res.data.ruPropertyData.check_out_until
          : "";
        resData.checkin_place = res.data.ruPropertyData
          ? res.data.ruPropertyData.checkin_place
          : "";
        resData.late_arrival_fee = res.data.ruPropertyData
          ? res.data.ruPropertyData.late_arrival_fee
          : "";
        resData.late_arrival_fee_from = res.data.ruPropertyData
          ? res.data.ruPropertyData.late_arrival_fee_from
          : "";
        resData.late_arrival_fee_to = res.data.ruPropertyData
          ? res.data.ruPropertyData.late_arrival_fee_to
          : "";
        resData.early_departure_fee = res.data.ruPropertyData
          ? res.data.ruPropertyData.early_departure_fee
          : "";
        resData.early_departure_fee_from = res.data.ruPropertyData
          ? res.data.ruPropertyData.early_departure_fee_from
          : "";
        resData.early_departure_fee_to = res.data.ruPropertyData
          ? res.data.ruPropertyData.early_departure_fee_to
          : "";
        // resData.cancellation_validto = res.data.ruPropertyData ? res.data.ruPropertyData.cancellation_validto : ""
        // resData.cancellation_validfrom = res.data.ruPropertyData ? res.data.ruPropertyData.cancellation_validfrom : ""
        // resData.space = res.data.ruPropertyData ? res.data.ruPropertyData.space : ""
        resData.access = res.data.ruPropertyData
          ? res.data.ruPropertyData.access
          : "";
        resData.neighborhood = res.data.ruPropertyData
          ? res.data.ruPropertyData.neighborhood
          : "";
        resData.notes = res.data.ruPropertyData
          ? res.data.ruPropertyData.notes
          : "";

        //for manager
        resData.orion_haus_account_manager_slug =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.slug
            ? res.data.orion_haus_account_manager.slug
            : "";
        resData.property_manager_slug =
          res.data &&
          res.data.property_manager_info &&
          res.data.property_manager_info.slug
            ? res.data.property_manager_info.slug
            : "";
        // resData.orion_haus_account_manager = res.data.orion_haus_account_manager ? res.data.orion_haus_account_manager : ""
        // resData.orion_haus_email = res.data.orion_haus_email ? res.data.orion_haus_email : ""
        // resData.orion_haus_phone_number = res.data.orion_haus_phone_number ? res.data.orion_haus_phone_number : ""
        // resData.property_manager = res.data.property_manager ? res.data.property_manager : ""
        // resData.property_manager_email = res.data.property_manager_email ? res.data.property_manager_email : ""
        // resData.property_manager_phone_number = res.data.property_manager_phone_number ? res.data.property_manager_phone_number : ""

        if (this.state.viewMode === 1) {
          var selectedSuitabilityList = [];
          res.data.suitabilityData.forEach((item, i) => {
            selectedSuitabilityList = [
              ...selectedSuitabilityList,
              {
                value: item.slug,
                label: item.suitability_name,
              },
            ];
          });

          var selectedPropertyList = [];
          selectedPropertyList = res.data.property_type
            ? [
                {
                  value: res.data.property_type.slug,
                  label: res.data.property_type.property_type_name,
                },
              ]
            : "";

          var selectedBrandList = [];
          selectedBrandList = res.data.brand
            ? [
                {
                  value: res.data.brand.slug,
                  label: res.data.brand.brand_name,
                },
              ]
            : "";
          var selectedClubList = [];
          selectedClubList = res.data.club
            ? [
                {
                  value: res.data.club.slug,
                  label: res.data.club.club_name,
                },
              ]
            : "";

          var selectedTimeZoneList = [];
          selectedTimeZoneList = res.data.timezone
            ? [
                {
                  value: res.data.timezone.slug,
                  label: res.data.timezone.timezone_name,
                },
              ]
            : "";

          var selectedParkingTypeList = [];
          selectedParkingTypeList = res.data.parking_type
            ? [
                {
                  value: res.data.parking_type.slug,
                  label: res.data.parking_type.parking_type_name,
                },
              ]
            : "";

          var selectedCommServedList = [];
          res.data.communitiesServedData.forEach((item, i) => {
            selectedCommServedList = [
              ...selectedCommServedList,
              {
                value: item.slug,
                label: item.communities_served_name,
              },
            ];
          });

          var selectedOwnershipTypeList = [];
          selectedOwnershipTypeList = res.data.ownership_type
            ? [
                {
                  value: res.data.ownership_type.slug,
                  label: res.data.ownership_type.ownership_type_name,
                },
              ]
            : "";

          var selectedSmokingTypeList = [];
          selectedSmokingTypeList = res.data.smoking_type
            ? [
                {
                  value: res.data.smoking_type.slug,
                  label: res.data.smoking_type.smoking_type_name,
                },
              ]
            : "";

          var selectedLeedBuildingList = [];
          selectedLeedBuildingList = res.data.leed_building
            ? [
                {
                  value: res.data.leed_building.slug,
                  label: res.data.leed_building.leed_building_name,
                },
              ]
            : "";

          var selectedMinimumAgeList = [];
          var selectedParkingList = [];
          if (res.data.masterData) {
            res.data.masterData.map((item) => {
              if (resData.minimum_age === item.slug) {
                selectedMinimumAgeList = res.data.masterData
                  ? [
                      {
                        value: item.slug,
                        label: item.name,
                      },
                    ]
                  : "";
              }

              if (resData.parking === item.slug) {
                selectedParkingList = res.data.masterData
                  ? [
                      {
                        value: item.slug,
                        label: item.name,
                      },
                    ]
                  : "";
              }
            });
          }

          var selectedLocationTypeList = [];
          res.data.LocationData.forEach((item, i) => {
            selectedLocationTypeList = [
              ...selectedLocationTypeList,
              {
                value: item.slug,
                label: item.location_type_name,
              },
            ];
          });

          var selectedCityList = [];
          selectedCityList = res.data.city
            ? [
                {
                  value: res.data.city.slug,
                  label: res.data.city.city_name,
                },
              ]
            : "";
          var selectedStateList = [];
          selectedStateList = res.data.state
            ? [
                {
                  value: res.data.state.slug,
                  label: res.data.state.state_name,
                },
              ]
            : "";
          var selectedCountyList = [];
          selectedCountyList = res.data.county
            ? [
                {
                  value: res.data.county.slug,
                  label: res.data.county.county_name,
                },
              ]
            : "";
          var selectedCountryList = [];
          selectedCountryList = res.data.country
            ? [
                {
                  value: res.data.country.slug,
                  label: res.data.country.country_name,
                },
              ]
            : "";
          var selectedAccountManagerList = [];
          selectedAccountManagerList = res.data.orion_haus_account_manager
            ? [
                {
                  value: res.data.orion_haus_account_manager.slug,
                  label:
                    res.data.orion_haus_account_manager.first_name +
                    " " +
                    res.data.orion_haus_account_manager.last_name,
                },
              ]
            : "";
          var selectedPropertyManagerList = [];
          selectedPropertyManagerList = res.data.property_manager_info
            ? [
                {
                  value: res.data.property_manager_info.slug,
                  label:
                    res.data.property_manager_info.first_name +
                    " " +
                    res.data.property_manager_info.last_name,
                },
              ]
            : "";
          var selectedLockTypeList = [];
          selectedLockTypeList = res.data.lock_type
            ? [
                {
                  value:
                    res.data.lock_type === 1
                      ? 1
                      : res.data.lock_type === 2
                      ? 2
                      : res.data.lock_type === 3
                      ? 3
                      : res.data.lock_type === 4
                      ? 4
                      : 5,
                  label:
                    res.data.lock_type === 1
                      ? "KeyCafe"
                      : res.data.lock_type === 2
                      ? "Latch"
                      : res.data.lock_type === 3
                      ? "Igloo"
                      : res.data.lock_type === 4
                      ? "Yale"
                      : "Schlage",
                },
              ]
            : "";
        }
      }
      this.setState(resData);
      this.setState({
        propertyPhoneNumber: propertyPhoneNumber,
        orionHausPhoneNumber: orionHausPhoneNumber,
        selectedSuitabilityList: selectedSuitabilityList,
        selectedPropertyList: selectedPropertyList,
        selectedBrandList: selectedBrandList,
        selectedClubList: selectedClubList,
        selectedTimeZoneList: selectedTimeZoneList,
        selectedParkingTypeList: selectedParkingTypeList,
        selectedAccountManagerList: selectedAccountManagerList,
        selectedPropertyManagerList: selectedPropertyManagerList,
        selectedLockTypeList: selectedLockTypeList,
      });
      this.setState({
        selectedCommServedList: selectedCommServedList,
        selectedOwnershipTypeList: selectedOwnershipTypeList,
        selectedSmokingTypeList: selectedSmokingTypeList,
        selectedLeedBuildingList: selectedLeedBuildingList,
        selectedMinimumAgeList: selectedMinimumAgeList,
        selectedParkingList: selectedParkingList,
      });
      this.setState({
        selectedLocationTypeList: selectedLocationTypeList,
        selectedCityList: selectedCityList,
        selectedStateList: selectedStateList,
        selectedCountyList: selectedCountyList,
        selectedCountryList: selectedCountryList,
      });
      this.setState({
        suitability_id: selectedSuitabilityList,
        communities_served_id: selectedCommServedList,
        location_type_id: selectedLocationTypeList,
        ownership_type_id: selectedOwnershipTypeList,
      });
    }
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }

    return finalnumber;
    // this.setState({ phone_number_frmt: finalnumber });
  };

  async getLocationTypeData(queryString = "") {
    // this.setState({ showSpinner: true, loading: true });
    var locationTypeList = [];
    var locationTypeListTotal = 0;
    let res = await LocationTypeService.getLocationType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      locationTypeList = res.data ? res.data : [];
      locationTypeListTotal = locationTypeList ? locationTypeList.length : 0;
    }
    const newLocationTypeList =
      locationTypeListTotal > 0
        ? locationTypeList.map(({ slug, location_type_name }) => ({
            value: slug,
            label: location_type_name,
          }))
        : [];
    this.setState({
      locationTypeList: locationTypeList,
      newLocationTypeList: newLocationTypeList,
      locationTypeListTotal: locationTypeListTotal,
    });
    // this.setState({ showSpinner: false, loading: false });
  }

  async getOwnershipTypeData(queryString = "") {
    // this.setState({ showSpinner: true, loading: true });
    var ownershipTypeList = [];
    var ownershipTypeListTotal = 0;
    let res = await OwnershipTypeService.getOwnership(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      ownershipTypeList = res.data ? res.data : [];
      ownershipTypeListTotal = ownershipTypeList ? ownershipTypeList.length : 0;
    }
    const newOwnershipTypeList =
      ownershipTypeListTotal > 0
        ? ownershipTypeList.map(({ slug, ownership_type_name }) => ({
            value: slug,
            label: ownership_type_name,
          }))
        : [];
    this.setState({
      ownershipTypeList: ownershipTypeList,
      newOwnershipTypeList: newOwnershipTypeList,
      ownershipTypeListTotal: ownershipTypeListTotal,
    });
    // this.setState({ showSpinner: false, loading: false });
  }

  async getMinimumAgeData(queryString = "") {
    // this.setState({ showSpinner: true, loading: true });
    var minimumAgeList = [];
    var minimumAgeListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=minimum_age",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      minimumAgeList = res.data ? res.data : [];
      minimumAgeListTotal = minimumAgeList ? minimumAgeList.length : 0;
    }
    const newMinimumAgeList =
      minimumAgeListTotal > 0
        ? minimumAgeList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : [];
    this.setState({
      minimumAgeList: minimumAgeList,
      minimumAgeListTotal: minimumAgeListTotal,
      newMinimumAgeList: newMinimumAgeList,
    });
    // this.setState({ showSpinner: false, loading: false });
  }

  async getLeedBuildingData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var leedBuildingList = [];
    var leedBuildingListTotal = 0;
    let res = await LeedBuildingService.getLeedBuilding(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      leedBuildingList = res.data ? res.data : [];
      leedBuildingListTotal = leedBuildingList ? leedBuildingList.length : 0;
    }
    const newLeedBuildingList =
      leedBuildingListTotal > 0
        ? leedBuildingList.map(({ slug, leed_building_name }) => ({
            value: slug,
            label: leed_building_name,
          }))
        : [];
    this.setState({
      leedBuildingList: leedBuildingList,
      newLeedBuildingList: newLeedBuildingList,
      leedBuildingListTotal: leedBuildingListTotal,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getCommServedData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var commServedList = [];
    var commServedListTotal = 0;
    let res = await CommServedService.getCommServed(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      commServedList = res.data ? res.data : [];
      commServedListTotal = commServedList ? commServedList.length : 0;
    }
    const newCommServedList =
      commServedListTotal > 0
        ? commServedList.map(({ slug, communities_served_name }) => ({
            value: slug,
            label: communities_served_name,
          }))
        : [];
    this.setState({
      commServedList: commServedList,
      newCommServedList: newCommServedList,
      commServedListTotal: commServedListTotal,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getSmokingTypeData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var smokingTypeList = [];
    var smokingTypeListTotal = 0;
    let res = await SmokingTypeService.getSmokingType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      smokingTypeList = res.data ? res.data : [];
      smokingTypeListTotal = smokingTypeList ? smokingTypeList.length : 0;
    }
    const newSmokingTypeList =
      smokingTypeListTotal > 0
        ? smokingTypeList.map(({ slug, smoking_type_name }) => ({
            value: slug,
            label: smoking_type_name,
          }))
        : [];
    this.setState({
      smokingTypeList: smokingTypeList,
      newSmokingTypeList: newSmokingTypeList,
      smokingTypeListTotal: smokingTypeListTotal,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getCityData(stateValue, countyValue, queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;
    let res = await CityService.getCity(
      "is_dropdown=1&state_slug=" + stateValue + "&county_slug=" + countyValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList =
      cityListTotal > 0
        ? cityList.map(({ slug, city_name }) => ({
            value: slug,
            label: city_name,
          }))
        : [];
    this.setState({
      cityList: cityList,
      newCityList: newCityList,
      cityListTotal: cityListTotal,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getAllRealetedData(slug, queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await CityService.getDataByCitySlug(
      slug,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.country_id = res.data.countryData
        ? res.data.countryData.country.slug
        : "";
      resData.country_name = res.data.countryData
        ? res.data.countryData.country.country_name
        : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.countryData.country
        ? [
            {
              value: res.data.countryData.country
                ? res.data.countryData.country.slug
                : "",
              label: res.data.countryData.country
                ? res.data.countryData.country.country_name
                : "",
            },
          ]
        : "";
      resData.county_id = res.data.county ? res.data.county.slug : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county
        ? [
            {
              value: res.data.county.slug ? res.data.county.slug : "",
              label: res.data.county.county_name
                ? res.data.county.county_name
                : "",
            },
          ]
        : "";
      resData.state_id = res.data.state ? res.data.state.slug : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      var selectedStateList = [];
      selectedStateList = res.data.state
        ? [
            {
              value: res.data.state.slug ? res.data.state.slug : "",
              label: res.data.state.state_name ? res.data.state.state_name : "",
            },
          ]
        : "";
    }
    this.setState(resData);
    this.setState({
      selectedStateList: selectedStateList,
      selectedCountyList: selectedCountyList,
      selectedCountryList: selectedCountryList,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getPropertyOfficeHoursData() {
    //  this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var property_office_data = [];
      var property_office_data_total = 0;

      let res = await PropertyService.getPropertyOfficeHours(
        this.state.property_slug
      );

      if (global.successStatus.includes(res.status)) {
        property_office_data = res.data ? res.data : [];
        property_office_data_total = property_office_data
          ? property_office_data.length
          : 0;
        if (property_office_data_total > 0) {
          this.setState({
            property_office_data: property_office_data,
            property_office_data_total: property_office_data_total,
          });
        }
      }
    }
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getCountryData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var countryList = [];

    let res = await CountryService.getCountry(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
    }
    const newCountryList = countryList.map(({ slug, country_name }) => ({
      value: slug,
      label: country_name,
    }));
    this.setState({ countryList: countryList, newCountryList: newCountryList });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getCountyData(value, queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var countyList = [];
    var countyListTotal = 0;
    let res = await CountyService.getCounty(
      "is_dropdown=1&country_slug=" +
        value +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
      countyListTotal = countyList ? countyList.length : 0;
    }
    const newCountyList =
      countyListTotal > 0
        ? countyList.map(({ slug, county_name }) => ({
            value: slug,
            label: county_name,
          }))
        : [];
    this.setState({
      countyList: countyList,
      newCountyList: newCountyList,
      countyListTotal: countyListTotal,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getStateData(countryValue, countyValue, queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var stateList = [];

    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
        countryValue +
        "&county_slug=" +
        countyValue +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
    }
    const newStateList = stateList.map(({ slug, state_name }) => ({
      value: slug,
      label: state_name,
    }));
    this.setState({ stateList: stateList, newStateList: newStateList });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getMetroData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var metroList = [];

    let res = await MetroService.getMetro(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      metroList = res.data ? res.data : [];
    }
    const newMetroList = metroList.map(({ slug, metro_name }) => ({
      value: slug,
      label: metro_name,
    }));
    this.setState({ metroList: metroList, newMetroList: newMetroList });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getSubMetroData(value, queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var subMetroList = [];

    let res = await SubMetroService.getSubMetro(
      "is_dropdown=1&metro_slug=" +
        value +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      subMetroList = res.data ? res.data : [];
    }
    const newSubMetroList = subMetroList.map(({ slug, sub_metro_name }) => ({
      value: slug,
      label: sub_metro_name,
    }));
    this.setState({
      subMetroList: subMetroList,
      newSubMetroList: newSubMetroList,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getPropertyData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var propertyList = [];
    var propertyListTotal = 0;
    let res = await PropertyTypeService.getPropertyType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;
    }
    const newPropertyList =
      propertyListTotal > 0
        ? propertyList.map(({ slug, property_type_name }) => ({
            value: slug,
            label: property_type_name,
          }))
        : [];
    this.setState({
      propertyList: propertyList,
      newPropertyList: newPropertyList,
      propertyListTotal: propertyListTotal,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getTimezoneData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var timeZoneList = [];
    var timeZoneListTotal = 0;
    let res = await TimezoneService.getTimezone(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      timeZoneList = res.data ? res.data : [];
      timeZoneListTotal = timeZoneList ? timeZoneList.length : 0;
    }
    const newTimeZoneList =
      timeZoneListTotal > 0
        ? timeZoneList.map(({ slug, timezone_name }) => ({
            value: slug,
            label: timezone_name,
          }))
        : [];
    this.setState({
      timeZoneList: timeZoneList,
      newTimeZoneList: newTimeZoneList,
      timeZoneListTotal: timeZoneListTotal,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getBrandData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var brandList = [];
    var brandListTotal = 0;
    let res = await BrandService.getBrandList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      brandList = res.data ? res.data : [];
      brandListTotal = brandList ? brandList.length : 0;
    }
    const newBrandList =
      brandListTotal > 0
        ? brandList.map(({ slug, brand_name }) => ({
            value: slug,
            label: brand_name,
          }))
        : [];
    this.setState({
      brandList: brandList,
      newBrandList: newBrandList,
      brandListTotal: brandListTotal,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getClubData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var clubList = [];
    var clubsListTotal = 0;
    let res = await ClubService.getClubist(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      clubList = res.data ? res.data : [];
      clubsListTotal = clubList ? clubList.length : 0;
    }
    const newClubList =
      clubsListTotal > 0
        ? clubList.map(({ slug, club_name }) => ({
            value: slug,
            label: club_name,
          }))
        : [];
    this.setState({
      clubList: clubList,
      newClubList: newClubList,
      clubsListTotal: clubsListTotal,
    });
  }

  async getSuitabilityData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var suitabilityList = [];

    let res = await PropertySuitabilityService.getPropertySuitability(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      suitabilityList = res.data ? res.data : [];
    }
    const newSuitabilityList = suitabilityList.map(
      ({ slug, suitability_name }) => ({
        value: slug,
        label: suitability_name,
      })
    );
    this.setState({
      suitabilityList: suitabilityList,
      newSuitabilityList: newSuitabilityList,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async getParkingTypeData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var parkingTypeList = [];
    var parkingTypeListTotal = 0;
    let res = await ParkingTypeService.getParkingType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      parkingTypeList = res.data ? res.data : [];
      parkingTypeListTotal = parkingTypeList ? parkingTypeList.length : 0;
    }
    const newParkingTypeList =
      parkingTypeListTotal > 0
        ? parkingTypeList.map(({ slug, parking_type_name }) => ({
            value: slug,
            label: parking_type_name,
          }))
        : [];
    this.setState({
      parkingTypeList: parkingTypeList,
      newParkingTypeList: newParkingTypeList,
      parkingTypeListTotal: parkingTypeListTotal,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  handleChangeSmokingTypeList(value) {
    let smoking_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        smoking_type_id.push(item.value);
      });
    }
    const smoking_type_id_value = value !== null ? value.value : [];
    this.setState({
      selectedSmokingTypeList: value,
      smoking_type_id: smoking_type_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeLockTypeList(value) {
    let lock_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        lock_type.push(item.value);
      });
    }
    this.setState({
      selectedLockTypeList: value,
      lock_type: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeMinimumAgeList(value) {
    let minimum_age = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        minimum_age.push(item.value);
      });
    }
    const minimum_age_value = value !== null ? value.value : [];
    this.setState({
      selectedMinimumAgeList: value,
      minimum_age: minimum_age_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCommServedList(value) {
    let communities_served_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        communities_served_id.push(item.value);
      });
    }
    this.setState({
      selectedCommServedList: value,
      communities_served_id: communities_served_id,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeOwnershipTypeList(value) {
    let ownership_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        ownership_type_id.push(item.value);
      });
    }
    const ownership_type_id_value = value !== null ? value.value : [];
    this.setState({
      selectedOwnershipTypeList: value,
      ownership_type_id: ownership_type_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeLeedBuildingList(value) {
    let leed_building_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        leed_building_id.push(item.value);
      });
    }
    const leed_building_id_value = value !== null ? value.value : [];
    this.setState({
      selectedLeedBuildingList: value,
      leed_building_id: leed_building_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeSuitabilityList(value) {
    let suitability_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        suitability_id.push(item.value);
      });
    }
    this.setState({
      selectedSuitabilityList: value,
      suitability_id: suitability_id,
    });
  }

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    let property_office_data1 = this.state.property_office_data;
    if (
      event.target.name === "start_time" ||
      event.target.name === "end_time" ||
      event.target.name === "closed"
    ) {
      property_office_data1[index][event.target.name] = value;
      this.setState({ property_office_data: property_office_data1 });
    } else {
      input[event.target.name] = value;
      this.setState({
        [event.target.name]: value,
        input: input,
        // housekeeping_window_hour: hours
      });
    }

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    // var confirmBack = 1;
    // this.props.confirmBackStatus(confirmBack);
    // this.setState({ confirm_back: 1 });
  };

  changeHandlerCheckInTime(date) {
    this.setState({ check_in_time: date });
    var format = Moment(date, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
    this.setState({ check_in_time_view: format });

    var a = Moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
    var b = Moment(this.state.check_out_time, "YYYY-MM-DD HH:mm:ss").format(
      "DD/MM/YYYY HH:mm:ss"
    );

    var diff = Moment.utc(
      Moment(b, "DD/MM/YYYY HH:mm:ss").diff(Moment(a, "DD/MM/YYYY HH:mm:ss"))
    ).format("HH");
    var hwh = 24 - diff;
    if (!isNaN(hwh)) {
      this.setState({ housekeeping_window_hour: hwh });
    }
  }

  changeHandlerCheckOutTime(date) {
    this.setState({ check_out_time: date });
    var format = Moment(date, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
    this.setState({ check_out_time_view: format });

    var a = Moment(this.state.check_in_time, "YYYY-MM-DD HH:mm:ss").format(
      "DD/MM/YYYY HH:mm:ss"
    );
    var b = Moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");

    var diff = Moment.utc(
      Moment(b, "DD/MM/YYYY HH:mm:ss").diff(Moment(a, "DD/MM/YYYY HH:mm:ss"))
    ).format("HH");
    var hwh = 24 - diff;
    if (!isNaN(hwh)) {
      this.setState({ housekeeping_window_hour: hwh });
    }
  }

  handleChangePropertyList(value) {
    let property_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_type_id.push(item.value);
      });
    }
    this.setState({
      selectedPropertyList: value,
      property_type_id: value.value,
    });
  }

  handleChangeBrandList(value) {
    let brand_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        brand_id.push(item.value);
      });
    }
    this.setState({
      selectedBrandList: value,
      brand_id: value.value,
    });
  }

  handleChangeClubList(value) {
    let club_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        club_id.push(item.value);
      });
    }
    this.setState({
      selectedClubList: value,
      club_id: value.value,
    });
  }

  handleChangeParkingTypeList(value) {
    let parking_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        parking_type_id.push(item.value);
      });
    }
    this.setState({
      selectedParkingTypeList: value,
      parking_type_id: value.value,
    });
  }

  handleChangeTimeZoneList(value) {
    let timezone_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        timezone_id.push(item.value);
      });
    }
    this.setState({
      selectedTimeZoneList: value,
      timezone_id: value.value,
    });
  }

  // Custom validation rules
  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.year_value !== undefined &&
      input.year_value !== "" &&
      input.year_value !== null &&
      input.year_value.length < 4
    ) {
      isValid = false;
      errors["year_value"] = "The year built must be at least 4 numbers.";
    }
    if (
      input.description !== undefined &&
      input.description !== "" &&
      input.description !== null &&
      input.description.length > 100000
    ) {
      isValid = false;
      errors["description"] =
        "The unit description character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  // onMainImageChange = async (event) => {
  //     if (event.target.files[0] !== undefined) {
  //       this.setState({ showSpinner: true });
  //       const fileData = new FormData();
  //       fileData.append(
  //         "property_main_image",
  //         event.target.files[0],
  //         event.target.files[0].name
  //       );
  //       let res = await PropertyService.uploadPropertyMainImg(fileData);
  //       if (global.successStatus.includes(res.status)) {
  //         this.setState({
  //           property_main_image: res.data.fileName,
  //           imgPropUrl: res.data.filePath,
  //         });
  //         this.setState({ showSpinner: false });
  //       } else if (res.status === 413) {
  //         this.setState({
  //           showSpinner: false,
  //           showAlertModal: true,
  //           alertModalType: "error",
  //           alertModalTitle: "Error!",
  //           alertModalMessage: "Content Too Large",
  //         });
  //       } else {
  //         let alertMessage = "";
  //         if (res.data.result.errorDetail !== null) {
  //           let errors = {};
  //           res.data.errorCode = "Validation Failed";
  //           res.data.result.errorDetail.forEach((item) => {
  //             errors[item.errorField] = item.errorMessage[0];
  //             // alertMessage += item.errorMessage[0]+'\n';
  //             res.data.errorMessage = "";
  //           });
  //           this.setState({ errors: errors });
  //         }
  //         this.setState({
  //           showSpinner: false,
  //           showAlertModal: true,
  //           alertModalType: "error",
  //           alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
  //           alertModalMessage:
  //             alertMessage !== "" ? alertMessage : res.data.message,
  //         });
  //       }
  //       setTimeout(
  //         () => this.setState({ showAlertModal: false }),
  //         global.alert_close_time
  //       );
  //     }
  //   };

  onLogoImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "property_logo",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await PropertyService.uploadPropertyLogo(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          property_logo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  saveOrUpdateProperty = async (e) => {
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });

      const FileWidgetObj = new FileWidget();
      const output = await FileWidgetObj.fileUploadFun("register_from");

      this.state.suitability_id = this.state.selectedSuitabilityList
        ? this.state.selectedSuitabilityList.map(function (obj) {
            return obj.value;
          })
        : "";

      this.state.location_type_id = this.state.selectedLocationTypeList
        ? this.state.selectedLocationTypeList.map(function (obj) {
            return obj.value;
          })
        : "";

      let multiLocationTypeString = this.state.location_type_id
        ? this.state.location_type_id.join(",")
        : "";

      this.state.communities_served_id = this.state.selectedCommServedList
        ? this.state.selectedCommServedList.map(function (obj) {
            return obj.value;
          })
        : "";

      let multiCommServedString = this.state.communities_served_id
        ? this.state.communities_served_id.join(",")
        : "";

      let inputData = {
        tab: "basic",
        ownership_type_slug: this.state.ownership_type_id,
        brand_slug: this.state.brand_id,
        club_slug: this.state.club_id,
        communities_served_slug: multiCommServedString,
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        domain_name: this.state.domain_name,
        pms_property_name: this.state.pms_property_name,
        lease_inquiry_url: this.state.lease_inquiry_url,
        property_type_slug: this.state.property_type_id,
        noiseaware_org_id: this.state.noiseaware_org_id,
        property_notes: this.state.property_notes,
        street_1: this.state.street_1,
        street_2: this.state.street_2,
        city_slug: this.state.city_id,
        zip_code: this.state.zip_code,
        country_slug: this.state.country_id,
        county_slug: this.state.county_id,
        state_slug: this.state.state_id,
        asset_managers: this.state.asset_managers,
        property_phone_number: this.state.property_phone_number,
        dial_code: this.state.dial_code,
        property_email: this.state.property_email,
        property_manager_name: this.state.property_manager_name,
        maintenance_email: this.state.maintenance_email,
        invoice_email: this.state.invoice_email,
        check_in_time: this.state.check_in_time
          ? Moment(this.state.check_in_time, "YYYY-MM-DD HH:mm:ss").format(
              "hh:mm A"
            )
          : "",
        check_out_time: this.state.check_out_time
          ? Moment(this.state.check_out_time, "YYYY-MM-DD HH:mm:ss").format(
              "hh:mm A"
            )
          : "",
        housekeeping_window_hour: this.state.housekeeping_window_hour
          ? this.state.housekeeping_window_hour
          : "",

        latitude: this.state.latitude,
        longitude: this.state.longitude,
        google_map_link: this.state.google_map_link,
        location_type_slug: multiLocationTypeString,
        timezone_slug: this.state.timezone_id,
        // observer_day_ligth_saving:
        //   this.state.observer_day_ligth_saving === true ? 1 : 2,
        insurance_required: this.state.insurance_required === true ? 1 : 0,
        international_property:
          this.state.international_property === true ? 1 : 2,
        allow_direct_booking: this.state.allow_direct_booking === true ? 1 : 0,
        appear_on_oh: this.state.appear_on_oh === true ? 1 : 0,
        year_value: this.state.year_value,
        leed_building_slug: this.state.leed_building_id,
        ada_compliant: this.state.ada_compliant,
        smoking_type_slug: this.state.smoking_type_id,
        parking_type_slug: this.state.parking_type_id,
        digital_lock: this.state.digital_lock === true ? 1 : 2,
        minimum_age: this.state.minimum_age ? this.state.minimum_age : "",

        affordable_financed: 0,
        affordable_component_check:
          this.state.affordable_component_check === true ? 1 : 0,
        total_affordable_unit: this.state.total_affordable_unit
          ? this.state.total_affordable_unit
          : "",

        // tax_id: this.state.tax_id,
        // full_listing: this.state.full_listing === true ? 1 : 2,
        // home_page_title: this.state.home_page_title,
        // suitability_slug: multiSuitabilityString,
        pet_allowed: this.state.pet_allowed === true ? 1 : 0,
        children_friendly: this.state.children_friendly === true ? 1 : 0,
        infant_friendly: this.state.infant_friendly === true ? 1 : 0,

        property_logo: this.state.property_logo,
        property_main_image: this.state.property_main_image,
        status_id: this.state.status_id === true ? 1 : 2, //hide status id for now
        // status_id: 2,
        sort_order: this.state.sort_order,
        availableFiles: output.response,
        deletedFiles: this.state.deletedFileList,

        // orion_haus_account_manager: this.state.orion_haus_account_manager,
        // orion_haus_email: this.state.orion_haus_email,
        // orion_haus_phone_number: this.state.orion_haus_phone_number,
        // property_manager: this.state.property_manager,
        // property_manager_email: this.state.property_manager_email,
        // property_manager_phone_number: this.state.property_manager_phone_number,
        orion_haus_account_manager_slug:
          this.state.orion_haus_account_manager_slug,
        property_manager_slug: this.state.property_manager_slug,
        lock_type: this.state.lock_type ? this.state.lock_type : "",
        orionhaus_name: this.state.orionhaus_name
          ? this.state.orionhaus_name
          : "",
        orionhaus_email: this.state.orionhaus_email
          ? this.state.orionhaus_email
          : "",
        orionhaus_phone_number: this.state.orionhaus_phone_number
          ? this.state.orionhaus_phone_number
          : "",
      };

      let inputData1 = {
        //office hours tab data
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        property_office_data: this.state.property_office_data
          ? this.state.property_office_data
          : [],
      };

      //for rental
      let inputData3 = {
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        days_before_arrival: this.state.days_before_arrival
          ? this.state.days_before_arrival
          : "",
        how_to_arrive: this.state.how_to_arrive ? this.state.how_to_arrive : "",
        check_out_until: this.state.check_out_until
          ? this.state.check_out_until
          : "",
        checkin_place: this.state.checkin_place ? this.state.checkin_place : "",
        late_arrival_fee: this.state.late_arrival_fee
          ? this.state.late_arrival_fee
          : "",
        late_arrival_fee_from: this.state.late_arrival_fee_from
          ? this.state.late_arrival_fee_from
          : "",
        late_arrival_fee_to: this.state.late_arrival_fee_to
          ? this.state.late_arrival_fee_to
          : "",
        early_departure_fee: this.state.early_departure_fee
          ? this.state.early_departure_fee
          : "",
        early_departure_fee_from: this.state.early_departure_fee_from
          ? this.state.early_departure_fee_from
          : "",
        early_departure_fee_to: this.state.early_departure_fee_to
          ? this.state.early_departure_fee_to
          : "",
        // cancellation_validto: this.state.cancellation_validto ? this.state.cancellation_validto : "",
        // cancellation_validfrom: this.state.cancellation_validfrom ? this.state.cancellation_validfrom : "",
        // space: this.state.space ? this.state.space : "",
        access: this.state.access ? this.state.access : "",
        neighborhood: this.state.neighborhood ? this.state.neighborhood : "",
        notes: this.state.notes ? this.state.notes : "",
      };

      if (this.state.property_slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        // inputData.status_id = this.state.status_id ? this.state.status_id : 2;
        // this.props.logo(this.state.property_name,this.state.property_type,this.state.year_value,this.state.imageUrl);

        let res = await PropertyService.updateProperty(
          this.state.property_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          let res2 = await PropertyService.updatePropertyOfficeHours(
            inputData1
          );
          if (global.successStatus.includes(res2.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res2.message ? res2.message : "Success",
            });
            // this.setState({ viewMode: 0 });
            // this.getPropertyOfficeHoursData();
          } else {
            //  let alertMessage = "";
            if (res2.data.result.errorDetail !== null) {
              let errors = {};
              res2.data.errorCode = "Validation Failed";
              res2.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                // alertMessage += item.errorMessage[0]+'\n';
                res2.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
            // this.setState({
            //   showAlertModal: true,
            //   alertModalType: "error",
            //   alertModalTitle: res.data.message ? res.data.message : "Error!",
            //   alertModalMessage:
            //     alertMessage !== "" ? alertMessage : res.data.message,
            // });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          sessionStorage.setItem(
            "property_slug",
            this.state.property_Slug ? this.state.property_Slug : ""
          );
          sessionStorage.setItem(
            "property_name",
            res.data.property_name ? res.data.property_name : ""
          );
          sessionStorage.setItem(
            "property_type",
            res.data.property_type
              ? res.data.property_type.property_type_name
              : ""
          );
          sessionStorage.setItem(
            "year_value",
            res.data.year_value ? res.data.year_value : ""
          );
          sessionStorage.setItem(
            "property_logo_url",
            res.data.property_logo_thumb_url
              ? res.data.property_logo_thumb_url
              : ""
          );

          localStorage.setItem("property_slug", this.state.property_slug);
          localStorage.setItem("property_name", res.data.property_name);
          localStorage.setItem("property_type", res.data.property_type);
          localStorage.setItem("year_value", res.data.year_value);
          localStorage.setItem("property_logo_url", res.data.property_logo_url);
          // this.getData();
          // this.getPropertyAffordable();
          // this.setState({ viewMode: 0 });
          this.changeView();
        } else {
          //  let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }

        let res3 = await RentalService.createPropertyData(inputData3);
        if (global.successStatus.includes(res3.status)) {
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "success",
          //   alertModalTitle: "Success",
          //   alertModalMessage: res3.message ? res3.message : "Success",
          // });
        } else {
          if (res3.data.result.errorDetail) {
            let errors = {};
            res3.data.errorCode = "Validation Failed";
            res3.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res3.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res3.data.errorCode
              ? res3.data.errorCode
              : "Error!",
            alertModalMessage: res3.data.message ? res3.data.message : "Error!",
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      // const err = this.validator.errorMessages;
      // const err1 = Object.values(this.validator.errorMessages);
      // if(Object.values(this.validator.errorMessages) !== null){
      //   const err2 = Object.keys(this.validator.errorMessages);
      //   console.log(err,err1,err2);
      // }

      // err.forEach(element => {
      //   console.log("1",element);
      //   if(element !== null){
      //     console.log("2",element);
      //     const input = document.getElementById(element);
      //     input.scrollIntoView({behavior: 'smooth'});
      //   }
      // });

      // const err = Object.keys(this.validator.errorMessages);
      //  console.log("err", err, err[0]);
      // // const input = document.getElementsByName(err[0]);
      // const input = document.getElementById(err[0]);
      // console.log("input", input);
      // input.scrollIntoView({behavior: 'smooth'});

      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  // get affordable
  async getPropertyAffordable() {
    //  this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await PropertyService.getPropertyAffordable(
      this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      resData.affordable_component_check = res.data.affordable_component_check
        ? res.data.affordable_component_check
        : 0;
    }
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  changeView() {
    // var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      //   this.props.confirmBackStatus(confirmBack);
      this.setState({ selectedSuitabilityList: [] });
      this.setState({ newSuitabilityList: [] });
      this.setState({ suitability_id: "" });
      this.getData();
      this.getPropertyOfficeHoursData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      //   this.props.confirmBackStatus(confirmBack);
      this.setState({ selectedSuitabilityList: [] });
      this.setState({ newSuitabilityList: [] });
      this.setState({ suitability_id: "" });
      this.getData();
      this.getSuitabilityData();
      this.getOwnershipTypeData();
      this.getCommServedData();
      this.getPropertyData();
      this.getTimezoneData();
      this.getBrandData();
      this.getClubData();
      this.getSuitabilityData();
      this.getParkingTypeData();
      this.getPropertyOfficeHoursData();
      this.getCityData();
      this.getLocationTypeData();
      this.getLeedBuildingData();
      this.getSmokingTypeData();
      this.getMinimumAgeData();
    }
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextRules() {
    if (this.state.descShowRules === 0) {
      this.setState({ descShowRules: 1 });
    } else {
      this.setState({ descShowRules: 0 });
    }
  }

  handleChangeLocationTypeList(value) {
    let location_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        location_type_id.push(item.value);
      });
    }
    this.setState({
      selectedLocationTypeList: value,
      location_type_id: location_type_id,
    });
  }

  handleChangeCityList(value) {
    this.getAllRealetedData(value.value);
    let city_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_id.push(item.value);
      });
    }
    this.setState({
      selectedCityList: value,
      city_id: value.value,
    });
  }

  handleChangeStateList(value) {
    let state_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_id.push(item.value);
      });
    }
    this.setState({
      selectedStateList: value,
      state_id: value.value,
    });
  }

  handleChangeCountyList(value) {
    let county_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        county_id.push(item.value);
      });
    }
    this.setState({
      selectedCountyList: value,
      county_id: value.value,
    });
  }

  handleChangeCountryList(value) {
    let country_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_id.push(item.value);
      });
    }
    this.setState({
      selectedCountryList: value,
      country_id: value.value,
    });
  }
  handleChangeMetroList(value) {
    let metro_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        metro_id.push(item.value);
      });
    }
    this.setState({
      selectedMetroList: value,
      metro_id: value.value,
    });
  }
  handleChangeSubMetroList(value) {
    let sub_metro_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        sub_metro_id.push(item.value);
      });
    }
    this.setState({
      selectedSubMetroList: value,
      sub_metro_id: value.value,
    });
  }

  openWebsite() {
    var url = this.state.google_map_link ? this.state.google_map_link : "";
    window.open(url, "_blank");
  }

  removePhoto() {
    this.setState({ property_logo: "", imageUrl: "" });
  }

  render() {
    var todayDate = Moment(new Date()).format("YYYY-MM-DD");

    const url = this.state.google_map_link ? this.state.google_map_link : "";
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container>
            {/* <div className="row row-xs">
            <div className="col-md-10 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="backpage">
                <Link
                  onClick={() => {
                    if (this.state.confirm_back === 1)
                      if (
                        window.confirm("Do you want to exit without saving?")
                      )
                        this.props.history.push("/properties");
                      else return false;
                    else this.props.history.push("/properties");
                  }}
                >
                  Properties
                </Link>
                <span> / </span>
                <span>Property</span>
              </div>
            </div>
            <div className="col-md-2 mt-3">
              <p className="d-flex justify-content-end">
                <span className="back-parent d-flex align-items-center">
                  <Link
                    onClick={() => {
                      if (this.state.confirm_back === 1)
                        if (
                          window.confirm("Do you want to exit without saving?")
                        )
                          this.props.history.push("/properties");
                        else return false;
                      else this.props.history.push("/properties");
                    }}
                  >
                    <img src={BackButton} alt="" className="back-button" />
                  </Link>
                  <Link
                    onClick={() => {
                      if (this.state.confirm_back === 1)
                        if (
                          window.confirm("Do you want to exit without saving?")
                        )
                          this.props.history.push("/properties");
                        else return false;
                      else this.props.history.push("/properties");
                    }}
                  >
                    Back
                  </Link>
                </span>
              </p>
            </div>
          </div> */}
            {/* <div className="col-md-12 pl-0 pr-0">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div> */}
          </Container>

          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    {/* <PropertyViewSidebar /> */}
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
                <div className="col-md-10 right">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="prop-head pd-l-15">
                    <aside className="d-flex"> </aside>
                  </div>

                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>Basics</h3>
                      <div className="d-flex">
                        {this.state.viewMode === 0 ? (
                          <>
                            {global.userPermissions.checkPermission(
                              "single-unit-update"
                            ) && (
                              <button
                                className="btn-success-outline-small float-right syncBtn"
                                // onClick={this.publishAllListing}
                                onClick={() => this.openXMlModal()}
                              >
                                {this.state.publishData
                                  ? global.loader
                                  : "Full Sync"}
                              </button>
                            )}
                            {global.userPermissions.checkPermission(
                              "property-update"
                            ) && (
                              <button
                                className="btn-success-outline-small ml-3"
                                onClick={this.changeView}
                              >
                                <img src={editIcon} alt="edit" />
                                Edit
                              </button>
                            )}
                          </>
                        ) : (
                          <div className="d-flex">
                            <button
                              type="button"
                              className="btn-success-outline-small"
                              onClick={() => {
                                if (this.state.confirm_back === 1)
                                  if (
                                    window.confirm(
                                      "Do you want to exit without saving?"
                                    )
                                  )
                                    this.changeView();
                                  else return false;
                                else this.changeView();
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn-success ml-3"
                              onClick={this.saveOrUpdateProperty}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="scrolling-carousel pl-3">
                    <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15 listing_detail">
                      {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
                      <div className="d-flex justify-content-end">
                        {/* <Link to={'/properties/update/'+this.props.property_slug+"/basic"} ><button className="btn-success-outline-small"><img src={editIcon}/>Edit</button></Link> */}
                      </div>

                      {Array.isArray(this.state.alertModalMessage) ? (
                        <>
                          {this.state.alertModalMessage ? (
                            this.state.alertModalMessage.map((opt, item) => {
                              return (
                                <div
                                  className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-t-15"
                                  key={item}
                                >
                                  <Alerts
                                    show={this.state.showAlertModal}
                                    // show="true"
                                    type={this.state.alertModalType}
                                    title={this.state.alertModalTitle}
                                    message={opt}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                          <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                          />
                        </div>
                      )}

                      <div className="m-0">
                        {this.state.viewMode === 0 &&
                        global.userPermissions.checkPermission(
                          "property-list"
                        ) ? (
                          <>
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                              <h3 className="mg-l-5 background-head">Basic</h3>
                            </div>
                            <div className="row align-items-center pd-b-30 pd-xs-b-0 pd-lg-b-0 pd-l-30 pd-r-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4- mg-t-5 mg-md-t-0 pd-l-0 pd-r-30 mg-b-15">
                                <div className="demo-avatar-group d-flex justify-content-left align-items-start flex-column">
                                  <div className="az-img-user avatar-md online mg-b-8">
                                    <figure>
                                      {this.state.status_id === true ? (
                                        <b className="status-active"></b>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.status_id === false ? (
                                        <b className="status-inactive"></b>
                                      ) : (
                                        ""
                                      )}
                                      <img
                                        src={
                                          this.props.imgFile
                                            ? this.props.imgFile
                                            : this.state.imageUrl
                                            ? this.state.imageUrl
                                            : propertyLogo
                                        }
                                        alt="Basic Property ImageFile"
                                        className="rounded-circle"
                                      />
                                    </figure>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-4- mg-lg-t-5 mg-md-t-0 pd-l-0 pd-r-0 pd-r-0 mg-lg-b-15">
                                <div className="row mg-xs-0 mg-lg-15 flex-wrap mg-xl-l-20">
                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-l-0 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-15 pd-lg-l-25  pd-xl-l-15 pd-lg-r-0 pd-xl-r-22">
                                    <p className="az-profile-name-text pl-0">
                                      DBA
                                    </p>
                                    <p className="media-body">
                                      {this.state.property_name
                                        ? this.state.property_name
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-l-0 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-15 pd-lg-l-25 pd-xl-l-38 pd-lg-r-0">
                                    <p className="az-profile-name-text pl-0">
                                      Property SKU
                                    </p>
                                    <p className="media-body">
                                      {this.state.aka_name
                                        ? this.state.aka_name
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row m-0 border-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Property Type
                                </p>
                                <p className="media-body">
                                  {this.state.property_type_name
                                    ? this.state.property_type_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Ownership Type
                                </p>
                                <p className="media-body">
                                  {this.state.ownership_type_name
                                    ? this.state.ownership_type_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Brand
                                </p>
                                <p className="media-body">
                                  {this.state.brand_name
                                    ? this.state.brand_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Club
                                </p>
                                <p className="media-body">
                                  {this.state.club_name
                                    ? this.state.club_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Location Type
                                </p>
                                <p className="media-body">
                                  {this.state.location_type_name_data
                                    ? this.state.location_type_name_data
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  PMS Property Name
                                </p>
                                <p className="media-body">
                                  {this.state.pms_property_name
                                    ? this.state.pms_property_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Class Type
                                </p>
                                <p className="media-body">
                                  {this.state.communities_served_name_data
                                    ? this.state.communities_served_name_data
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  LEED Certified
                                </p>
                                <p className="media-body">
                                  {this.state.leed_building_name
                                    ? this.state.leed_building_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Year Built
                                </p>
                                <p className="media-body">
                                  {this.state.year_value
                                    ? this.state.year_value
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Smoking Type
                                </p>
                                <p className="media-body">
                                  {this.state.smoking_type_name
                                    ? this.state.smoking_type_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Minimun Age to Rent
                                </p>
                                <p className="media-body">
                                  {this.state.minimum_age_value
                                    ? this.state.minimum_age_value
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Domain Name
                                </p>
                                <p className="media-body">
                                  {this.state.domain_name
                                    ? this.state.domain_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Lease Inquiry Url
                                </p>
                                <p className="media-body text-break">
                                  {this.state.lease_inquiry_url
                                    ? this.state.lease_inquiry_url
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Lock Type
                                </p>
                                <p className="media-body">
                                  {this.state.lock_type === 1
                                    ? "KeyCafe"
                                    : this.state.lock_type === 2
                                    ? "Latch"
                                    : this.state.lock_type === 3
                                    ? "Igloo"
                                    : this.state.lock_type === 4
                                    ? "Yale"
                                    : this.state.lock_type === 5
                                    ? "Schlage"
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Affordable
                                </p>
                                <p className="media-body">
                                  {this.state.affordable_component_check ===
                                  true
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                              {this.state.affordable_component_check ===
                              true ? (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                  <p className="az-profile-name-text pl-0">
                                    Total Affordable Units
                                  </p>
                                  <p className="media-body">
                                    {this.state.total_affordable_unit
                                      ? this.state.total_affordable_unit
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Renter's Insurance Required
                                </p>
                                <p className="media-body">
                                  {this.state.insurance_required === true
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Pet Friendly
                                </p>
                                <p className="media-body">
                                  {this.state.pet_allowed === true
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Suitable for Children
                                </p>
                                <p className="media-body">
                                  {this.state.children_friendly === true
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Suitable for Infants
                                </p>
                                <p className="media-body">
                                  {this.state.infant_friendly === true
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  ADA Compliant
                                </p>
                                <p className="media-body">
                                  {this.state.ada_compliant === 1
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Allow Direct Booking
                                </p>
                                <p className="media-body">
                                  {this.state.allow_direct_booking === true
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Appear on Orion Haus
                                </p>
                                <p className="media-body">
                                  {this.state.appear_on_oh === true
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                              <h3 className="mg-l-5 background-head">Notes</h3>
                            </div>
                            <div className="row m-0 border-bottom-0 ">
                              <div className="col-md-12 p-0">
                                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 p-0 border-bottom-0">
                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Property Notes
                                    </p>
                                    <p className="media-body">
                                      {this.state.property_notes ? (
                                        <p className="p_wrap1">
                                          {this.state.descShowPropertyNotes
                                            ? this.state.property_notes
                                            : this.state.property_notes.slice(
                                                0,
                                                global.showMoreLength
                                              )}

                                          {this.state.property_notes.length >
                                            global.showMoreLength && (
                                            <span
                                              onClick={
                                                this.fullTextPropertyNotes
                                              }
                                            >
                                              {this.state
                                                .descShowPropertyNotes ? (
                                                <p className="hand-cursor showmore">
                                                  Show Less{" "}
                                                </p>
                                              ) : (
                                                <p className="hand-cursor showmore">
                                                  ...Show more{" "}
                                                </p>
                                              )}
                                            </span>
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                              <h3 className="mg-l-5 background-head">
                                Location
                              </h3>
                            </div>
                            <div className="row m-0 border-bottom-0 ">
                              <div className="col-md-12 p-0">
                                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 p-0 border-bottom-0">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Street 1
                                    </p>
                                    <p className="media-body">
                                      {this.state.street_1
                                        ? this.state.street_1
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Street 2
                                    </p>
                                    <p className="media-body">
                                      {this.state.street_2
                                        ? this.state.street_2
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      City
                                    </p>
                                    <p className="media-body">
                                      {this.state.city ? this.state.city : ""}
                                    </p>
                                  </div>
                                </div>

                                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 p-0 border-bottom-0">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      State
                                    </p>
                                    <p className="media-body">
                                      {this.state.state_name
                                        ? this.state.state_name
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Zip Code
                                    </p>
                                    <p className="media-body">
                                      {this.state.zip_code
                                        ? this.state.zip_code
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      County
                                    </p>
                                    <p className="media-body">
                                      {this.state.county_name
                                        ? this.state.county_name
                                        : ""}
                                    </p>
                                  </div>
                                </div>

                                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 p-0 border-bottom-0">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Country
                                    </p>
                                    <p className="media-body">
                                      {this.state.country
                                        ? this.state.country
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      International
                                    </p>
                                    <p className="media-body">
                                      {this.state.international_property ===
                                      true
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </div>

                                  {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Observes Daylight Savings
                                </p>
                                <p className="media-body">
                                  {this.state.observer_day_ligth_saving === true
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div> */}

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      {" "}
                                      Timezone
                                    </p>
                                    <p className="media-body">
                                      {this.state.timezone_name
                                        ? this.state.timezone_name
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Latitude
                                    </p>
                                    <p className="media-body">
                                      {this.state.latitude
                                        ? this.state.latitude
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Longitude
                                    </p>
                                    <p className="media-body">
                                      {this.state.longitude
                                        ? this.state.longitude
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Google Map Link
                                </p>
                                <p
                                  className="hand-cursor break-word link-url"
                                  onClick={this.openWebsite}
                                >
                                  {url}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                              <h3 className="mg-l-5 background-head">
                                Orion Haus Contact
                              </h3>
                            </div>
                            <div className="row m-0 border-0">
                              <div className="col-md-12 p-0">
                                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 p-0">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Name
                                    </p>
                                    <p className="media-body">
                                      {this.state.orionhaus_name
                                        ? this.state.orionhaus_name
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Email
                                    </p>
                                    <p className="media-body">
                                      {this.state.orionhaus_email
                                        ? this.state.orionhaus_email
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Phone
                                    </p>
                                    <p className="media-body">
                                      {this.state.orionHausPhoneNumber
                                        ? this.state.orionHausPhoneNumber
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                              <h3 className="mg-l-5 background-head">
                                Property Contact
                              </h3>
                            </div>
                            <div className="row m-0 border-0">
                              <div className="col-md-12 p-0">
                                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 p-0">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Property Manager
                                    </p>
                                    <p className="media-body">
                                      {this.state.property_manager_name
                                        ? this.state.property_manager_name
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Email
                                    </p>
                                    <p className="media-body">
                                      {this.state.property_email
                                        ? this.state.property_email
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Phone
                                    </p>
                                    <p className="media-body">
                                      {this.state.propertyPhoneNumber
                                        ? this.state.propertyPhoneNumber
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Maintenance Email
                                    </p>
                                    <p className="media-body">
                                      {this.state.maintenance_email
                                        ? this.state.maintenance_email
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Invoice Email
                                    </p>
                                    <p className="media-body">
                                      {this.state.invoice_email
                                        ? this.state.invoice_email
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <p className="az-profile-name-text pl-0">
                                      Asset Manager
                                    </p>
                                    <p className="media-body">
                                      {this.state.asset_managers
                                        ? this.state.asset_managers
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <h3 className="mg-l-5 background-head">
                                  Timings
                                </h3>
                              </div>
                              <div className="col-md-12 pd-l-15 pd-r-60 mg-l-0 pd-b-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-0">
                                <div className="table-responsive pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                  <Table hover className="mg-b-0">
                                    <thead>
                                      <tr>
                                        <th className="w-30">Day</th>
                                        <th className="w-30">Start Time</th>
                                        <th className="w-30">End Time</th>
                                        <th className="w-10">Closed</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.property_office_data.map(
                                        (item, i) => {
                                          return (
                                            <tr key={i}>
                                              <td>
                                                {item.label ? item.label : ""}
                                              </td>
                                              <td>
                                                {item.start_time
                                                  ? item.start_time
                                                  : ""}
                                              </td>
                                              <td>
                                                {item.end_time
                                                  ? item.end_time
                                                  : ""}
                                              </td>
                                              <td>
                                                {item.closed === 1
                                                  ? "Yes"
                                                  : "No"}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>

                            <div className="row align-items-center pd-b-30 border-bottom-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-0 pd-lg-r-15">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Check In Time
                                </p>
                                <p className="media-body">
                                  {this.state.check_in_time_view
                                    ? Moment(
                                        this.state.check_in_time_view,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Check Out Time
                                </p>
                                <p className="media-body">
                                  {this.state.check_out_time_view
                                    ? Moment(
                                        this.state.check_out_time_view,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <p className="az-profile-name-text pl-0">
                                  Housekeeping Window Hours
                                </p>
                                <p className="media-body">
                                  {this.state.housekeeping_window_hour
                                    ? this.state.housekeeping_window_hour +
                                      " Hours"
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {this.state.viewMode === 1 ? (
                          <div className="m-0">
                            <form id="register_from">
                              {global.userPermissions.checkPermission(
                                "property-edit-basic-info"
                              ) && (
                                <>
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-5 background-head">
                                      Basic
                                    </h3>
                                  </div>
                                  <div className="row align-items-center pd-b-0 pd-xs-b-0 pd-lg-b-0 pd-l-30 pd-r-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4- mg-t-5 mg-md-t-0 pd-l-0 pd-r-30 mg-b-15">
                                      <div className="fileUpload d-flex- flex-wrap justify-content-between">
                                        <span>
                                          <label className="hand-cursor">
                                            <input
                                              onChange={this.onLogoImageChange}
                                              type="file"
                                              name="property_logo"
                                            />
                                            {this.state.imageUrl ? (
                                              <img
                                                src={this.state.imageUrl}
                                                alt="Logo"
                                              />
                                            ) : (
                                              <img
                                                src={propertyLogo}
                                                alt="Logo"
                                              />
                                            )}
                                          </label>
                                        </span>
                                        <ul className="ruLink">
                                          <li>
                                            <label>
                                              <input
                                                onChange={
                                                  this.onLogoImageChange
                                                }
                                                type="file"
                                                name="property_logo"
                                              />
                                            </label>
                                            Change Logo
                                          </li>
                                        </ul>
                                        <div>
                                          {" "}
                                          <p
                                            className="hand-cursor remove-pic m-0 remove-pic-four"
                                            onClick={this.removePhoto}
                                          ></p>
                                        </div>
                                      </div>

                                      {/* {this.state.errors.property_logo ? (
                                        <div className="text-danger">
                                          {this.state.errors.property_logo}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "property_logo",
                                          this.state.property_logo,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )} */}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-4- mg-lg-t-5 mg-md-t-0 pd-l-0 pd-r-0 pd-r-0 mg-lg-b-15">
                                      <div className="row mg-xs-0 mg-lg-15 flex-wrap mg-xl-l-20">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-l-0 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-15 pd-lg-l-25  pd-xl-l-15 pd-lg-r-0 pd-xl-r-22">
                                          <label className="form-label text-left pd-b-5">
                                            DBA{" "}
                                            {/* <span className="text-danger">*</span> */}
                                          </label>
                                          <Form.Control
                                            type="text"
                                            onChange={this.changeHandler}
                                            name="property_name"
                                            value={this.state.property_name}
                                            maxLength="50"
                                          />
                                          {this.state.errors.property_name ? (
                                            <div className="text-danger">
                                              {this.state.errors.property_name}
                                            </div>
                                          ) : (
                                            this.validator.message(
                                              "dba",
                                              this.state.property_name,
                                              "required|max:50",
                                              { className: "text-danger" }
                                            )
                                          )}
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-l-0 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-15 pd-lg-l-25 pd-xl-l-38 pd-lg-r-0">
                                          <label className="form-label text-left pd-b-5">
                                            Property SKU
                                            {/* <span className="text-danger">*</span> */}
                                          </label>
                                          <Form.Control
                                            onChange={this.changeHandler}
                                            type="text"
                                            name="aka_name"
                                            onKeyPress={
                                              global.onKeyPressEvent
                                                .alphaNumValidation
                                            }
                                            value={this.state.aka_name}
                                            maxLength="3"
                                          />
                                          {this.state.errors.aka_name ? (
                                            <div className="text-danger">
                                              {this.state.errors.aka_name}
                                            </div>
                                          ) : (
                                            this.validator.message(
                                              "property_sku",
                                              this.state.aka_name,
                                              "required|alpha_num_dash|max:3",
                                              { className: "text-danger" }
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row align-items-baseline pd-lg-b-30 pd-xs-b-15 border-bottom-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-0">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Property Type{" "}
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedPropertyList}
                                        options={this.state.newPropertyList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangePropertyList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedPropertyList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.errors.property_type_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.property_type_id}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "property_type",
                                          this.state.property_type_id,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Ownership Type
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={
                                          this.state.selectedOwnershipTypeList
                                        }
                                        options={
                                          this.state.newOwnershipTypeList
                                        }
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeOwnershipTypeList(
                                            value
                                          )
                                        }
                                        defaultValue={
                                          this.state.selectedOwnershipTypeList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {/* <select
                    className=""
                    onChange={this.changeHandler}
                    name="ownership_type_id"
                    value={this.state.ownership_type_id}
                    >
                    <option key="" value="">
                        Select Ownership Type
                    </option>
                    {this.state.ownershipTypeList
                        // .sort((a, b) => (a.label > b.label ? 1 : -1))
                        .map((option) => (
                        <option key={option.slug} value={option.slug}>
                            {option.ownership_type_name}
                        </option>
                        ))}
                    </select> */}
                                      {/* {this.state.errors.ownership_type_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.ownership_type_id}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "ownership_type",
                                          this.state.ownership_type_id,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )} */}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Brand
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedBrandList}
                                        options={this.state.newBrandList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeBrandList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedBrandList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.errors.brand_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.brand_id}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "brand",
                                          this.state.brand_id,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Club
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedClubList}
                                        options={this.state.newClubList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeClubList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedClubList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.errors.brand_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.brand_id}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "brand",
                                          this.state.brand_id,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Location Type{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        isMulti
                                        styles={customStyles}
                                        menuPlacement="auto"
                                        className="multiselect"
                                        value={
                                          this.state.selectedLocationTypeList
                                        }
                                        options={this.state.newLocationTypeList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeLocationTypeList(
                                            value
                                          )
                                        }
                                        defaultValue={
                                          this.state.selectedLocationTypeList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.errors.location_type_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.location_type_id}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30 ">
                                      <label className="form-label text-left pd-b-5">
                                        PMS Property Name
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="pms_property_name"
                                        value={this.state.pms_property_name}
                                        className="form-control max_width_100"
                                        id="inlineFormInput"
                                        type="text"
                                        autoComplete="off"
                                      />
                                      {this.state.errors.pms_property_name ? (
                                        <div className="text-danger">
                                          {this.state.errors.pms_property_name}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Class Type
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        isMulti
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={
                                          this.state.selectedCommServedList
                                        }
                                        options={this.state.newCommServedList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeCommServedList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedCommServedList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.errors
                                        .communities_served_id ? (
                                        <div className="text-danger">
                                          {
                                            this.state.errors
                                              .communities_served_id
                                          }
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        LEED Certified
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={
                                          this.state.selectedLeedBuildingList
                                        }
                                        options={this.state.newLeedBuildingList}
                                        onChange={(value) =>
                                          this.handleChangeLeedBuildingList(
                                            value
                                          )
                                        }
                                        defaultValue={
                                          this.state.selectedLeedBuildingList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />

                                      {this.state.errors.leed_building_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.leed_building_id}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Year Built
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="year_value"
                                        value={this.state.year_value}
                                        className="form-control max_width_100"
                                        id="inlineFormInput"
                                        type="text"
                                        minLength="4"
                                        maxLength="4"
                                        autoComplete="off"
                                        onKeyPress={
                                          global.onKeyPressEvent
                                            .numberValidation
                                        }
                                      />
                                      {this.state.errors.year_value ? (
                                        <div className="text-danger">
                                          {this.state.errors.year_value}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Smoking Type
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={
                                          this.state.selectedSmokingTypeList
                                        }
                                        options={this.state.newSmokingTypeList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeSmokingTypeList(
                                            value
                                          )
                                        }
                                        defaultValue={
                                          this.state.selectedSmokingTypeList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />

                                      {this.state.errors.smoking_type_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.smoking_type_id}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                              <label className="form-label text-left pd-b-5">
                                Parking Type
                                <span className="optional">(Optional)</span>
                              </label>
                              <Select
                                styles={customStyles}
                                className="multiselect"
                                menuPlacement="auto"
                                value={this.state.selectedParkingTypeList}
                                options={this.state.newParkingTypeList}
                                getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangeParkingTypeList(value)
                                }
                                defaultValue={
                                  this.state.selectedParkingTypeList
                                }
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />

                              {this.state.errors.parking_type_id ? (
                                <div className="text-danger">
                                  {this.state.errors.parking_type_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div> */}
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30 ">
                                      <label className="form-label text-left pd-b-5">
                                        Minimum Age to Rent
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={
                                          this.state.selectedMinimumAgeList
                                        }
                                        options={this.state.newMinimumAgeList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeMinimumAgeList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedMinimumAgeList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />

                                      {this.state.errors.minimum_age ? (
                                        <div className="text-danger">
                                          {this.state.errors.minimum_age}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30 ">
                                      <label className="form-label text-left pd-b-5">
                                        Domain Name
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="domain_name"
                                        value={this.state.domain_name}
                                        className="form-control max_width_100"
                                        id="inlineFormInput"
                                        type="text"
                                        // minLength="4"
                                        // maxLength="4"
                                        autoComplete="off"
                                        // onKeyPress={
                                        //   global.onKeyPressEvent.numberValidation
                                        // }
                                      />
                                      {/* {this.state.errors.domain_name ? (
                                        <div className="text-danger">
                                          {this.state.errors.domain_name}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "domain_name",
                                          this.state.domain_name,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )} */}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Lease Inquiry Url
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="lease_inquiry_url"
                                        value={this.state.lease_inquiry_url}
                                        className="form-control max_width_100"
                                        id="inlineFormInput"
                                        type="text"
                                        // minLength="4"
                                        // maxLength="4"
                                        autoComplete="off"
                                        // onKeyPress={
                                        //   global.onKeyPressEvent.numberValidation
                                        // }
                                      />
                                      {this.state.errors.lease_inquiry_url ? (
                                        <div className="text-danger">
                                          {this.state.errors.lease_inquiry_url}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Lock Type{" "}
                                        {/* <span className="text-danger">*</span> */}
                                      </label>

                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedLockTypeList}
                                        options={this.state.lockTypeList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeLockTypeList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedLockTypeList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.errors.lock_type ? (
                                        <div className="text-danger">
                                          {this.state.errors.lock_type}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "lock_type",
                                          this.state.lock_type,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-0 d-flex align-items-start affortable pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <div className="mg-xs-b-15 mg-lg-b-0">
                                        <ul className="formcheck-section addcheckproperty mg-b-0">
                                          <li>
                                            <label
                                              htmlFor="affordable_component_check"
                                              className="form-label text-left pd-b-5 hand-cursor"
                                            >
                                              Affordable
                                            </label>
                                            <Form.Check
                                              name="affordable_component_check"
                                              type={"checkbox"}
                                              id={`affordable_component_check`}
                                              label={""}
                                              className={"ckbox"}
                                              onChange={this.changeHandler}
                                              checked={
                                                this.state
                                                  .affordable_component_check
                                              }
                                            />
                                          </li>
                                        </ul>
                                      </div>
                                      {this.state.affordable_component_check ? (
                                        <div className="mg-xs-b-15 mg-lg-b-0 w-100">
                                          <label className="form-label text-left pd-b-5">
                                            Total Affordable Units
                                          </label>
                                          <Form.Control
                                            type="text"
                                            onChange={this.changeHandler}
                                            name="total_affordable_unit"
                                            value={
                                              this.state.total_affordable_unit
                                            }
                                            maxLength="50"
                                          />
                                          {this.state.errors
                                            .total_affordable_unit ? (
                                            <div className="text-danger">
                                              {
                                                this.state.errors
                                                  .total_affordable_unit
                                              }
                                            </div>
                                          ) : (
                                            this.validator.message(
                                              "total_affordable_unit",
                                              this.state.total_affordable_unit,
                                              "numeric|min:0,num",
                                              { className: "text-danger" }
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 d-flex align-items-start pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <ul className="formcheck-section addcheckproperty mg-b-0">
                                        <li>
                                          <label
                                            htmlFor="insurance_required"
                                            className="form-label text-left pd-b-5 hand-cursor"
                                          >
                                            Renter’s Insurance Required
                                          </label>
                                          <Form.Check
                                            name="insurance_required"
                                            type={"checkbox"}
                                            id={`insurance_required`}
                                            label={""}
                                            className={"ckbox"}
                                            onChange={this.changeHandler}
                                            checked={
                                              this.state.insurance_required
                                            }
                                          />
                                        </li>
                                        <li>
                                          <label
                                            htmlFor="pet_allowed"
                                            className="form-label text-left pd-b-5 hand-cursor"
                                          >
                                            Pet Friendly
                                          </label>
                                          <Form.Check
                                            name="pet_allowed"
                                            type={"checkbox"}
                                            id={`pet_allowed`}
                                            label={""}
                                            className={"ckbox"}
                                            onChange={this.changeHandler}
                                            checked={this.state.pet_allowed}
                                          />
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15 d-flex align-items-baseline">
                                      <ul className="formcheck-section addcheckproperty mg-b-0">
                                        <li>
                                          <label
                                            htmlFor="children_friendly"
                                            className="form-label text-left pd-b-5 hand-cursor"
                                          >
                                            Suitable for Children
                                          </label>
                                          <Form.Check
                                            name="children_friendly"
                                            type={"checkbox"}
                                            id={`children_friendly`}
                                            label={""}
                                            className={"ckbox"}
                                            onChange={this.changeHandler}
                                            checked={
                                              this.state.children_friendly
                                            }
                                          />
                                        </li>
                                        <li>
                                          <label
                                            htmlFor="infant_friendly"
                                            className="form-label text-left pd-b-5 hand-cursor"
                                          >
                                            Suitable for Infants
                                          </label>
                                          <Form.Check
                                            name="infant_friendly"
                                            type={"checkbox"}
                                            id={`infant_friendly`}
                                            label={""}
                                            className={"ckbox"}
                                            onChange={this.changeHandler}
                                            checked={this.state.infant_friendly}
                                          />
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-0 pd-r-30 pd-r-40 mg-b-0 d-flex align-items-baseline pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <ul className="formcheck-section addcheckproperty mg-b-0">
                                        <li>
                                          <label className="form-label text-left pd-b-5 mg-l-0">
                                            ADA Compliant
                                          </label>
                                          <div className="d-flex mg-l-0">
                                            <Form.Check
                                              onChange={this.changeHandler}
                                              name="ada_compliant"
                                              value="1"
                                              checked={
                                                this.state.ada_compliant === 1
                                                  ? true
                                                  : false
                                              }
                                              type={"radio"}
                                              id="1"
                                              label={"Yes"}
                                              className={"rdiobox mg-r-20"}
                                            />
                                            <Form.Check
                                              onChange={this.changeHandler}
                                              name="ada_compliant"
                                              value="2"
                                              checked={
                                                this.state.ada_compliant === 2
                                                  ? true
                                                  : false
                                              }
                                              type={"radio"}
                                              id="2"
                                              label={"No"}
                                              className={"rdiobox"}
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-lg-t-5 mg-xs-t-0 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-lg-b-15 mg-xs-b-0 d-flex align-items-baseline">
                                      <ul className="formcheck-section addcheckproperty mg-b-0">
                                        <li>
                                          <label
                                            htmlFor="allow_direct_booking"
                                            className="form-label text-left pd-b-5 hand-cursor"
                                          >
                                            Allow Direct Booking
                                          </label>
                                          <Form.Check
                                            name="allow_direct_booking"
                                            type={"checkbox"}
                                            id={`allow_direct_booking`}
                                            label={""}
                                            className={"ckbox"}
                                            onChange={this.changeHandler}
                                            checked={
                                              this.state.allow_direct_booking
                                            }
                                          />
                                        </li>
                                        <li>
                                          <label
                                            htmlFor="appear_on_oh"
                                            className="form-label text-left pd-b-5 hand-cursor"
                                          >
                                            Appear on Orion Haus
                                          </label>
                                          <Form.Check
                                            name="appear_on_oh"
                                            type={"checkbox"}
                                            id={`appear_on_oh`}
                                            label={""}
                                            className={"ckbox"}
                                            onChange={this.changeHandler}
                                            checked={this.state.appear_on_oh}
                                          />
                                        </li>
                                      </ul>
                                    </div>
                                    {/* <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15 min-rent-last">
                              <label className="form-label text-left pd-b-5">
                                NoiseAware ORG ID
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="noiseaware_org_id"
                                value={this.state.noiseaware_org_id}
                                maxLength="11"
                                onKeyPress={global.onKeyPressEvent.numberValidation}
                              />
                              {this.state.errors.noiseaware_org_id ? (
                                <div className="text-danger">
                                  {this.state.errors.noiseaware_org_id}
                                </div>
                              ) : ""}
                            </div> */}
                                  </div>

                                  {/* rental fields start */}
                                  {/* {this.state.property_slug ? <>
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                            <h3 className="mg-l-5 background-head">Channel Manager</h3>
                          </div>
                          <div className="row align-items-center pd-b-0 border-bottom-0  pd-xs-l-0 pd-xs-r-0  pd-lg-l-0 pd-lg-r-15">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                  Days before arrival{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="days_before_arrival"
                                  value={this.state.days_before_arrival}
                                  maxLength="50"
                                  onKeyPress={global.onKeyPressEvent.numberValidation}
                              />
                              {this.state.errors.days_before_arrival ? (
                                  <div className="text-danger">
                                  {this.state.errors.days_before_arrival}
                                  </div>
                              ) : ''}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                  How to arrive{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="how_to_arrive"
                                  value={this.state.how_to_arrive}
                                  maxLength="1000"
                                  as="textarea"
                              />
                              {this.state.errors.how_to_arrive ? (
                                  <div className="text-danger">
                                  {this.state.errors.how_to_arrive}
                                  </div>
                              ) : (
                                  this.validator.message(
                                  "how_to_arrive",
                                  this.state.how_to_arrive,
                                  "max:1000",
                                  { className: "text-danger" }
                                  )
                              )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                  Checkout Until{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <InputMask
                                  name="check_out_until"
                                  onChange={this.changeHandler}
                                  value={
                                    this.state.check_out_until !== ""
                                      ? this.state.check_out_until || ""
                                      : "00:00AM"
                                  }
                                  mask="99:99 aa"
                                />
                              {this.state.errors.check_out_until ? (
                                  <div className="text-danger">
                                  {this.state.errors.check_out_until}
                                  </div>
                              ) : ''}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                  Checkin Place{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="checkin_place"
                                  value={this.state.checkin_place}
                                  maxLength="50"
                              />
                              {this.state.errors.checkin_place ? (
                                  <div className="text-danger">
                                  {this.state.errors.checkin_place}
                                  </div>
                              ) : (
                                  this.validator.message(
                                  "checkin_place",
                                  this.state.checkin_place,
                                  "max:50",
                                  { className: "text-danger" }
                                  )
                              )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                  Late Arrival Fee{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="late_arrival_fee"
                                  value={this.state.late_arrival_fee}
                                  maxLength="5"
                                  onKeyPress={global.onKeyPressEvent.floatValidation}
                              />
                              {this.state.errors.late_arrival_fee ? (
                                  <div className="text-danger">
                                  {this.state.errors.late_arrival_fee}
                                  </div>
                              ) : (
                                  this.validator.message(
                                  "late_arrival_fee",
                                  this.state.late_arrival_fee,
                                  "max:8",
                                  { className: "text-danger" }
                                  )
                              )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                  Late Arrival Fee From{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <InputMask
                                  name="late_arrival_fee_from"
                                  onChange={this.changeHandler}
                                  value={
                                    this.state.late_arrival_fee_from !== ""
                                      ? this.state.late_arrival_fee_from || ""
                                      : "00:00AM"
                                  }
                                  mask="99:99 aa"
                                />
                              {this.state.errors.late_arrival_fee_from ? (
                                  <div className="text-danger">
                                  {this.state.errors.late_arrival_fee_from}
                                  </div>
                              ) : ''}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Late Arrival Fee To{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <InputMask
                                  name="late_arrival_fee_to"
                                  onChange={this.changeHandler}
                                  value={
                                    this.state.late_arrival_fee_to !== ""
                                      ? this.state.late_arrival_fee_to || ""
                                      : "00:00AM"
                                  }
                                  mask="99:99 aa"
                                />
                              {this.state.errors.late_arrival_fee_to ? (
                                  <div className="text-danger">
                                  {this.state.errors.late_arrival_fee_to}
                                  </div>
                              ) : ''}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                  Early Departure Fee{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="early_departure_fee"
                                  value={this.state.early_departure_fee}
                                  maxLength="5"
                                  onKeyPress={global.onKeyPressEvent.floatValidation}
                              />
                              {this.state.errors.early_departure_fee ? (
                                  <div className="text-danger">
                                  {this.state.errors.early_departure_fee}
                                  </div>
                              ) : (
                                  this.validator.message(
                                  "early_departure_fee",
                                  this.state.early_departure_fee,
                                  "max:8",
                                  { className: "text-danger" }
                                  )
                              )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                  Early Departure Fee From{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <InputMask
                                  name="early_departure_fee_from"
                                  onChange={this.changeHandler}
                                  value={
                                    this.state.early_departure_fee_from !== ""
                                      ? this.state.early_departure_fee_from || ""
                                      : "00:00AM"
                                  }
                                  mask="99:99 aa"
                                />
                              {this.state.errors.early_departure_fee_from ? (
                                  <div className="text-danger">
                                  {this.state.errors.early_departure_fee_from}
                                  </div>
                              ) : ''}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Early Departure Fee To{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <InputMask
                                  name="early_departure_fee_to"
                                  onChange={this.changeHandler}
                                  value={
                                    this.state.early_departure_fee_to !== ""
                                      ? this.state.early_departure_fee_to || ""
                                      : "00:00AM"
                                  }
                                  mask="99:99 aa"
                                />
                              {this.state.errors.early_departure_fee_to ? (
                                  <div className="text-danger">
                                  {this.state.errors.early_departure_fee_to}
                                  </div>
                              ) : ''}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                  Access{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="access"
                                  value={this.state.access}
                                  maxLength="1000"
                                  as="textarea"
                              />
                              {this.state.errors.access ? (
                                  <div className="text-danger">
                                  {this.state.errors.access}
                                  </div>
                              ) : (
                                  this.validator.message(
                                  "access",
                                  this.state.access,
                                  "max:50",
                                  { className: "text-danger" }
                                  )
                              )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                  Notes{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="notes"
                                  value={this.state.notes}
                                  maxLength="1000"
                                  as="textarea"
                              />
                              {this.state.errors.notes ? (
                                  <div className="text-danger">
                                  {this.state.errors.notes}
                                  </div>
                              ) : (
                                  this.validator.message(
                                  "notes",
                                  this.state.notes,
                                  "max:1000",
                                  { className: "text-danger" }
                                  )
                              )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                  Neighborhood{" "}
                                  <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="neighborhood"
                                  value={this.state.neighborhood}
                                  maxLength="1000"
                                  as="textarea"
                              />
                              {this.state.errors.neighborhood ? (
                                  <div className="text-danger">
                                  {this.state.errors.neighborhood}
                                  </div>
                              ) : (
                                  this.validator.message(
                                  "neighborhood",
                                  this.state.neighborhood,
                                  "max:1000",
                                  { className: "text-danger" }
                                  )
                              )}
                              </div>
                          </div>
                          </> : ''} */}
                                  {/* rental fields end */}
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-5 background-head">
                                      Notes
                                    </h3>
                                  </div>
                                  <div className="row align-items-center pd-lg-b-30 pd-xs-b-0  border-bottom-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-0 pd-lg-r-15">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Property Notes{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        rows={4}
                                        onChange={this.changeHandler}
                                        name="property_notes"
                                        value={this.state.property_notes || ""}
                                      />
                                      {this.state.errors.property_notes ? (
                                        <div className="text-danger">
                                          {this.state.errors.property_notes}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-5 background-head">
                                      Location
                                    </h3>
                                  </div>
                                  <div className="row align-items-center pd-lg-b-30 pd-xs-b-0  border-bottom-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-0 pd-lg-r-15">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Street 1
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="street_1"
                                        value={this.state.street_1}
                                      />
                                      {this.state.errors.street_1 ? (
                                        <div className="text-danger">
                                          {this.state.errors.street_1}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "street_1",
                                          this.state.street_1,
                                          "required|max:100",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left  pd-b-5">
                                        Street 2{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="street_2"
                                        value={this.state.street_2}
                                      />
                                      {this.state.errors.street_2 ? (
                                        <div className="text-danger">
                                          {this.state.errors.street_2}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "street_2",
                                          this.state.street_2,
                                          "max:100",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        City
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedCityList}
                                        options={this.state.newCityList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeCityList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedCityList
                                        }
                                        autoComplete="off"
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="city_id"
                  value={this.state.city_id}
                >
                  <option key="" value="">
                    Select City
                  </option>
                  {this.state.cityList.map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.city_name}
                      </option>
                    ))}
                </select> */}
                                      {this.state.errors.city_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.city_id}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "city",
                                          this.state.city_id,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        State
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Form.Control
                                        type="text"
                                        className="custom-disable"
                                        value={this.state.state_name}
                                        disabled={true}
                                      />
                                      {/* {this.state.errors.state_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.state_id}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "state",
                                          this.state.state_id,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )} */}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Zip Code
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="zip_code"
                                        value={this.state.zip_code}
                                        maxLength="5"
                                        onKeyPress={
                                          global.onKeyPressEvent
                                            .numberValidation
                                        }
                                      />
                                      {this.state.errors.zip_code ? (
                                        <div className="text-danger">
                                          {this.state.errors.zip_code}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "zip_code",
                                          this.state.zip_code,
                                          "required|max:5",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        County
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedCountyList}
                                        options={this.state.newCountyList.filter(
                                          (option) => {
                                            const val1 = this.state.county_id
                                              ? this.state.county_id
                                              : "";
                                            if (val1 === option.slug)
                                              return true;
                                          }
                                        )}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeCountyList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedCountyList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="county_id"
                  value={this.state.county_id}
                >
                  <option key="" value="">
                    Select County
                  </option>
                  {this.state.countyList
                    .filter((option) => {
                      const val1 = this.state.county_id
                        ? this.state.county_id
                        : "";
                      if (val1 === option.slug) return true;
                    })
                    .map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.county_name}
                      </option>
                    ))}
                </select> */}
                                      {this.state.errors.county_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.county_id}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Country
                                      </label>
                                      <Form.Control
                                        type="text"
                                        className="custom-disable"
                                        value={this.state.country_name}
                                        disabled={true}
                                      />
                                      {/* {this.state.errors.country_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.country_id}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "country",
                                          this.state.country_id,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )} */}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 d-flex align-items-baseline pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <ul className="formcheck-section addcheckproperty addCheckmob">
                                        <li>
                                          <label
                                            htmlFor="international_property"
                                            className="form-label text-left pd-b-5 hand-cursor"
                                          >
                                            International
                                          </label>
                                          <Form.Check
                                            name="international_property"
                                            type={"checkbox"}
                                            id={`international_property`}
                                            label={""}
                                            className={"ckbox"}
                                            onChange={this.changeHandler}
                                            checked={
                                              this.state.international_property
                                            }
                                          />
                                        </li>
                                        {/* <li>
                                  <label className="form-label text-left pd-b-5">
                                    Observes Daylight Savings
                                  </label>
                                  <Form.Check
                                    name="observer_day_ligth_saving"
                                    type={"checkbox"}
                                    id={`observer_day_ligth_saving`}
                                    label={""}
                                    className={"ckbox"}
                                    onChange={this.changeHandler}
                                    checked={
                                      this.state.observer_day_ligth_saving
                                    }
                                  />
                                </li> */}
                                      </ul>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Timezone
                                        {/* <span className="optional">(Optional)</span> */}
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedTimeZoneList}
                                        options={this.state.newTimeZoneList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeTimeZoneList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedTimeZoneList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.errors.timezone_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.timezone_id}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "timezone",
                                          this.state.timezone_id,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}

                                      {/* <select
                        className=""
                        onChange={this.changeHandler}
                        name="timezone_id"
                        value={this.state.timezone_id}

                        >
                        <option key="" value="">
                            Select Property Timezone
                        </option>
                        {this.state.timeZoneList
                            // .sort((a, b) => (a.label > b.label ? 1 : -1))
                            .map((option) => (
                            <option
                                key={option.slug}
                                value={option.slug}
                            >
                                {option.timezone_name}
                            </option>
                            ))}
                        </select> */}
                                      {this.state.errors.timezone_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.timezone_id}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Google Map Link{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="google_map_link"
                                        value={this.state.google_map_link}
                                      />
                                      {this.state.errors.google_map_link ? (
                                        <div className="text-danger">
                                          {this.state.errors.google_map_link}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "google_map_link",
                                          this.state.google_map_link,
                                          "max:500",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Latitude{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="latitude"
                                        value={this.state.latitude}
                                      />
                                      {this.state.errors.latitude ? (
                                        <div className="text-danger">
                                          {this.state.errors.latitude}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "latitude",
                                          this.state.latitude,
                                          "max:50",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left mg-b-0 pd-b-5">
                                        Longitude{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="longitude"
                                        value={this.state.longitude}
                                      />
                                      {this.state.errors.longitude ? (
                                        <div className="text-danger">
                                          {this.state.errors.longitude}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "longitude",
                                          this.state.longitude,
                                          "max:50",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-5 background-head">
                                      Orion Haus Contact
                                    </h3>
                                  </div>
                                  <div className="row align-items-center pd-lg-b-30 pd-xs-b-0 border-bottom-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-0 pd-lg-r-15">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Name
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="orionhaus_name"
                                        value={this.state.orionhaus_name}
                                        maxLength="50"
                                      />
                                      {this.state.errors.orionhaus_name ? (
                                        <div className="text-danger">
                                          {this.state.errors.orionhaus_name}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Email
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="orionhaus_email"
                                        value={this.state.orionhaus_email}
                                        maxLength="50"
                                      />
                                      {this.state.errors.orionhaus_email ? (
                                        <div className="text-danger">
                                          {this.state.errors.orionhaus_email}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "email",
                                          this.state.orionhaus_email,
                                          "email",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Phone
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>

                                      <PhoneInput
                                        country={global.country}
                                        disableSearchIcon
                                        // autoFormat= "true"
                                        enableSearch="true"
                                        countryCodeEditable="true"
                                        value={
                                          this.state.orionhaus_phone_number
                                        }
                                        onChange={(orionhaus_phone_number) =>
                                          this.setState({
                                            orionhaus_phone_number,
                                          })
                                        }
                                      />
                                      {this.state.errors
                                        .orionhaus_phone_number ? (
                                        <div className="text-danger">
                                          {
                                            this.state.errors
                                              .orionhaus_phone_number
                                          }
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-5 background-head">
                                      Property Contact
                                    </h3>
                                  </div>
                                  <div className="row align-items-center pd-lg-b-30 pd-xs-b-0 border-bottom-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-0 pd-lg-r-15">
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Property Manager{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="property_manager_name"
                                        value={this.state.property_manager_name}
                                        maxLength="50"
                                      />
                                      {/* <Select
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={
                                          this.state.selectedPropertyManagerList
                                        }
                                        options={
                                          this.state.newAccountManagerList
                                        }
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangePropertyManagerList(
                                            value
                                          )
                                        }
                                        defaultValue={
                                          this.state.selectedPropertyManagerList
                                        }
                                        autoComplete="off"
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.errors
                                        .property_manager_slug ? (
                                        <div className="text-danger">
                                          {
                                            this.state.errors
                                              .property_manager_slug
                                          }
                                        </div>
                                      ) : (
                                        ""
                                      )} */}
                                    </div>{" "}
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Email
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="property_email"
                                        value={this.state.property_email}
                                        maxLength="50"
                                      />
                                      {this.state.errors.property_email ? (
                                        <div className="text-danger">
                                          {this.state.errors.property_email}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "email",
                                          this.state.property_email,
                                          "email",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>{" "}
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Phone
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      {/* <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="property_phone_number"
                                value={this.state.property_phone_number}
                                // onPaste={(e) => {
                                //   e.preventDefault();
                                //   return false;
                                // }}
                                // onCopy={(e) => {
                                //   e.preventDefault();
                                //   return false;
                                // }}
                                onKeyPress={
                                  global.onKeyPressEvent.phoneValidation
                                }
                                maxLength="15"
                              /> */}
                                      <PhoneInput
                                        country={global.country}
                                        disableSearchIcon
                                        // autoFormat= "true"
                                        enableSearch="true"
                                        countryCodeEditable="true"
                                        value={this.state.property_phone_number}
                                        onChange={(property_phone_number) =>
                                          this.setState({
                                            property_phone_number,
                                          })
                                        }
                                      />
                                      {this.state.errors
                                        .property_phone_number ? (
                                        <div className="text-danger">
                                          {
                                            this.state.errors
                                              .property_phone_number
                                          }
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Maintenance Email
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="maintenance_email"
                                        value={this.state.maintenance_email}
                                        maxLength="50"
                                      />
                                      {this.state.errors.maintenance_email ? (
                                        <div className="text-danger">
                                          {this.state.errors.maintenance_email}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "maintenance_email",
                                          this.state.maintenance_email,
                                          "email",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Invoice Email
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="invoice_email"
                                        value={this.state.invoice_email}
                                        maxLength="50"
                                      />
                                      {this.state.errors.invoice_email ? (
                                        <div className="text-danger">
                                          {this.state.errors.invoice_email}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "invoice_email",
                                          this.state.invoice_email,
                                          "email",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Asset Manager
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="asset_managers"
                                        value={this.state.asset_managers}
                                        maxLength="50"
                                      />
                                      {this.state.errors.asset_managers ? (
                                        <div className="text-danger">
                                          {this.state.errors.asset_managers}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Orion Haus Account Manager{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={
                                          this.state.selectedAccountManagerList
                                        }
                                        options={
                                          this.state.newAccountManagerList
                                        }
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeAccountManagerList(
                                            value
                                          )
                                        }
                                        defaultValue={
                                          this.state.selectedAccountManagerList
                                        }
                                        autoComplete="off"
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.errors
                                        .orion_haus_account_manager_slug ? (
                                        <div className="text-danger">
                                          {
                                            this.state.errors
                                              .orion_haus_account_manager_slug
                                          }
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  {/* <div className="row align-items-center pd-lg-b-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-15">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Orion Haus Account Manager{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="orion_haus_account_manager"
                                value={this.state.orion_haus_account_manager || ""}
                                maxLength="100"
                              />
                              {this.state.errors.orion_haus_account_manager ? (
                                <div className="text-danger">
                                  {this.state.errors.orion_haus_account_manager}
                                </div>
                              ) : (
                                this.validator.message(
                                  "orion_haus_account_manager",
                                  this.state.orion_haus_account_manager,
                                  "max:100",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Orion Haus Email{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="orion_haus_email"
                                value={this.state.orion_haus_email || ""}
                                maxLength="70"
                              />
                              {this.state.errors.orion_haus_email ? (
                                <div className="text-danger">
                                  {this.state.errors.orion_haus_email}
                                </div>
                              ) : (
                                this.validator.message(
                                  "orion_haus_email",
                                  this.state.orion_haus_email,
                                  "email|max:70",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Orion Haus Phone{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <PhoneInput
                                country={global.country}
                                enableSearch="true"
                                countryCodeEditable="true"
                                value={this.state.orion_haus_phone_number}
                                onChange={(orion_haus_phone_number) =>
                                  this.setState({ orion_haus_phone_number })
                                }
                              />
                              {this.state.errors.orion_haus_phone_number ? (
                                <div className="text-danger">
                                  {this.state.errors.orion_haus_phone_number}
                                </div>
                              ) : (
                                this.validator.message(
                                  "orion_haus_phone_number",
                                  this.state.orion_haus_phone_number,
                                  "max:15",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Property Manager{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="property_manager"
                                value={this.state.property_manager || ""}
                                maxLength="100"
                              />
                              {this.state.errors.property_manager ? (
                                <div className="text-danger">
                                  {this.state.errors.property_manager}
                                </div>
                              ) : (
                                this.validator.message(
                                  "property_manager",
                                  this.state.property_manager,
                                  "max:100",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Property Manager Email{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="property_manager_email"
                                value={this.state.property_manager_email || ""}
                                maxLength="70"
                              />
                              {this.state.errors.property_manager_email ? (
                                <div className="text-danger">
                                  {this.state.errors.property_manager_email}
                                </div>
                              ) : (
                                this.validator.message(
                                  "property_manager_email",
                                  this.state.property_manager_email,
                                  "email|max:70",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Property Manager Phone{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <PhoneInput
                                country={global.country}
                                enableSearch="true"
                                countryCodeEditable="true"
                                value={this.state.property_manager_phone_number}
                                onChange={(property_manager_phone_number) =>
                                  this.setState({ property_manager_phone_number })
                                }
                              />
                              {this.state.errors.property_manager_phone_number ? (
                                <div className="text-danger">
                                  {this.state.errors.property_manager_phone_number}
                                </div>
                              ) : (
                                this.validator.message(
                                  "property_manager_phone_number",
                                  this.state.property_manager_phone_number,
                                  "max:15",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div> */}
                                </>
                              )}
                              {global.userPermissions.checkPermission(
                                "property-edit-office-timings"
                              ) && (
                                <>
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-5 background-head">
                                      Timings
                                    </h3>
                                  </div>
                                  <div className="row align-items-center pd-lg-b-30 pd-xs-b-15 border-bottom-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-15 pd-lg-r-15">
                                    <div className="row  col-lg-10 col-12 pd-l-0 border-bottom-0">
                                      <label className="col-md-1 col-3 pd-l-0 mg-r-5"></label>
                                      <label className="col-lg-3 col-3 pd-l-0">
                                        From
                                      </label>
                                      <label className="col-lg-3 col-3  pd-l-0 mg-r-0">
                                        To
                                      </label>
                                      <label className="row col-lg-1 col-3 mg-l-0 text-center">
                                        Closed
                                      </label>
                                    </div>
                                    {this.state.property_office_data.map(
                                      (item, index) => (
                                        <React.Fragment key={index}>
                                          <div className="row col-lg-10 col-12 align-items-center pd-t-0 pd-b-10 pl-0 border-bottom-0">
                                            <div className="col-md-1 col-3 pl-0 pd-b-5 mg-r-5">
                                              <label className="form-label mg-b-0 text-right d-flex justify-content-end">
                                                {item.label}
                                              </label>
                                            </div>
                                            <div className="col-lg-3 col-3 mg-t-5 mg-md-t-0 pl-0 react-time-picker">
                                              <InputMask
                                                name="start_time"
                                                onChange={(e) =>
                                                  this.changeHandler(e, index)
                                                }
                                                value={item.start_time}
                                                disabled={
                                                  item.closed ? true : false
                                                }
                                                mask="99:99 aa"
                                              />
                                            </div>
                                            <div className="col-lg-3 col-3 mg-t-5 mg-md-t-0 pl-0">
                                              <InputMask
                                                name="end_time"
                                                onChange={(e) =>
                                                  this.changeHandler(e, index)
                                                }
                                                value={item.end_time}
                                                disabled={
                                                  item.closed ? true : false
                                                }
                                                mask="99:99 aa"
                                              />
                                            </div>
                                            <div className="row col-lg-1 col-3 mg-t-5 mg-md-t-0 pd-l-0 pr-0 justify-content-center align-items-center">
                                              {/* <div className="ckbox form-check d-flex align-items-center justify-content-center mg-t-0"> */}
                                              <input
                                                type="checkbox"
                                                id={index}
                                                name="closed"
                                                className="form-check-input"
                                                onChange={(e) =>
                                                  this.changeHandler(e, index)
                                                }
                                                checked={item.closed || ""}
                                              />
                                              <label
                                                htmlFor={index}
                                                className="form-check-label"
                                              ></label>
                                              {/* </div> */}
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      )
                                    )}
                                  </div>
                                </>
                              )}
                              {global.userPermissions.checkPermission(
                                "property-edit-checkin-checkout-timings"
                              ) && (
                                <>
                                  <div className="row align-items-center pd-b-30 border-bottom-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-0 pd-lg-r-15">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left  pd-b-5">
                                        Check In Time
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <DatePicker
                                        // selected={this.state.check_in_time}
                                        selected={
                                          new Date(
                                            todayDate +
                                              " " +
                                              this.state.check_in_time_view
                                          )
                                        }
                                        onChange={(date) =>
                                          this.changeHandlerCheckInTime(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        onKeyDown={(e) => {
                                          e.preventDefault();
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Check Out Time
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <DatePicker
                                        selected={
                                          new Date(
                                            todayDate +
                                              " " +
                                              this.state.check_out_time_view
                                          )
                                        }
                                        onChange={(date) =>
                                          this.changeHandlerCheckOutTime(date)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        onKeyDown={(e) => {
                                          e.preventDefault();
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Housekeeping Window Hours
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        name="housekeeping_window_hour"
                                        onKeyPress={
                                          global.onKeyPressEvent
                                            .numberValidation
                                        }
                                        onChange={this.changeHandler}
                                        value={
                                          this.state.housekeeping_window_hour
                                        }
                                        maxLength="1"
                                      />
                                      {this.state.errors
                                        .housekeeping_window_hour ? (
                                        <div className="text-danger">
                                          {
                                            this.state.errors
                                              .housekeeping_window_hour
                                          }
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="row align-items-center pd-t-30 pd-b-30 pd-l-30 pd-r-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                <div className="col-md-1 pl-0">
                                  <label className="form-label text-left mg-b-0">
                                    Status
                                  </label>
                                </div>

                                <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                                  <b
                                    className={
                                      this.state.status_id
                                        ? "active"
                                        : "inactive"
                                    }
                                  ></b>
                                  <div
                                    className={
                                      this.state.status_id
                                        ? "az-toggle on"
                                        : "az-toggle"
                                    }
                                    onClick={() =>
                                      this.setState({
                                        status_id: !this.state.status_id,
                                      })
                                    }
                                  >
                                    <span></span>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="ht-50"></div> */}
            </div>
          </Container>
        </main>
        <Modal show={this.state.xmlRoleModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeFullSyncModal()}
              >
                {global.closee}
              </span>
              <h3>Sync the Option?</h3>
              <p>
                Do you really want to Sync this? This process cannot be undone.
              </p>

              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeFullSyncModal()}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className=" m-0 btn btn btn-danger btn-block"
                  onClick={this.publishAllListing}
                >
                  {this.state.publishData ? global.loader : "Full Sync"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Basic;
