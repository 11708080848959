////////////////////////////////////////////////////////////
//     							                          //
//  Program: Events.jsx                                    //
//  Application: Events                                    //
//  Option: List all Events                                //
//  Developer: Ashish Kumar 						              //
//  Date: 2022-02-09                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Tab, Nav } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import Alerts from "../common/Alerts";
import EventsService from "../../services/EventsService";
// import filterIcon from "./../../assets/images/filter-gray.svg";
import NoData from "../common/NoData";
import DatePicker from "react-datepicker";
import Moment from "moment";
import PropertySidebar from "../common/PropertySidebar";
import copyIcon from "./../../assets/images/copy.png";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import queryString from "query-string";

let queryParams = queryString.parse(window.location.search);

export class Events extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      is_upcoming: false,
      start_date: "",
      end_date: "",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: -1,
      copyPageNumber: 0,
      page_number: 1,
      tab: 1,
      activeClass: "current",
      property_slug: this.props.match.params.propertySlug,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "event_name",
          text: "Name",
          className: "event_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.slug ? (
              <Link to={"events/view/" + record.slug} className="">
                {record.event_name}
              </Link>
            ) : (
              record.event_name
            );
          },
        },
        {
          key: "event_type_id",
          text: "Event Type",
          className: "event_type_id hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.event_type ? (
              <Link to={"events/view/" + record.slug} className="">
                {record.event_type.event_type_name}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "event_date",
          text: "Event Date",
          className: "event_date hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.event_date ? (
              <Link to={"events/view/" + record.slug} className="">
                {Moment(record.event_date, "MM/DD/YYYY").format(
                  global.dateFormat
                )}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "event_location",
          text: "Event Location",
          className: "event_location hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.event_location ? (
              <Link to={"events/view/" + record.slug} className="">
                {record.event_location}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "start_time",
          text: "Start Time",
          className: "start_time hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.start_time ? (
              <Link to={"events/view/" + record.slug} className="">
                {record.start_time}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "end_time",
          text: "End Time",
          className: "end_time hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.end_time ? (
              <Link to={"events/view/" + record.slug} className="">
                {record.end_time}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id td_clickable",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <Link to={"events/view/" + record.slug} className="">
                <b className="status-active"></b>
              </Link>
            ) : (
              <Link to={"events/view/" + record.slug} className="">
                <b className="status-inactive"></b>
              </Link>
            );
          },
        },
        global.userPermissions.checkPermission('events-add') &&
        {
          key: "action",
          text: "Action",
          className: "action hand-cursor",
          sortable: false,
          cell: (record) => {
            return (
              <img
                src={copyIcon}
                alt="Copy"
                onClick={() => this.copyEvent(record.slug)}
                className="copyClass"
              />
            );
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.copyEvent = this.copyEvent.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  componentDidMount() {
    document.getElementById("current").click();
    // this.getData("tab=1");
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "current") {
      this.setState({ tab: 1 });
      this.getData("tab=1");
    } else if (e === "upcoming") {
      this.setState({ tab: 2 });
      this.getData("tab=2");
    } else if (e === "completed") {
      this.setState({ tab: 3 });
      this.getData("tab=3");
    } else if (e === "cancelled") {
      this.setState({ tab: 4 });
      this.getData("tab=4");
    } else if (e === "all") {
      this.setState({ tab: 5 });
      this.getData();
    } else {
      this.setState({ tab: 0 });
      this.getData();
    }
  }

  async copyEvent(slug) {
    let res = await EventsService.copyEvents(slug);
    if (global.successStatus.includes(res.status)) {
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" + this.state.property_slug + "/events"
          ),
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        }),
        global.redirect_time
      );
      this.setState({ copyPageNumber: 1 });
      this.getData("tab=" + this.state.tab);
      // setTimeout(() => {
      //   var elements = document.getElementsByClassName("page-item active");
      //   while (elements.length > 0) {
      //     elements[0].classList.remove("active");
      //   }
      //   const firstChild = document.getElementsByClassName("page-item");
      //   firstChild[1].classList.add("active");
      // }, 4000);
    } else {
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    if (queryParams.isGlobal === "1") {
      var res = await EventsService.getEvents(
        "property_slug=" +
          this.state.property_slug +
          "&isGlobal=1&global_search=" +
          queryParams.global_search +
          (this.state.copyPageNumber === 1
            ? "&filter_value=&page=" +
              this.state.page_number +
              "&tab=" +
              this.state.tab
            : queryString
            ? "&" + queryString
            : "")
      );
    } else {
      res = await EventsService.getEvents(
        "property_slug=" +
          this.state.property_slug +
          (this.state.copyPageNumber === 1
            ? "&filter_value=&page=" +
              this.state.page_number +
              "&tab=" +
              this.state.tab
            : queryString
            ? "&" + queryString
            : "")
      );
    }
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      // totalRecords = list ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
      copyPageNumber: 0,
    });
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
  }

  customFilter() {
    let startDate = this.state.start_date
      ? Moment(this.state.start_date).format("YYYY-MM-DD")
      : "";
    let endDate = this.state.end_date
      ? Moment(this.state.end_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "start_date=" +
      startDate +
      "&end_date=" +
      endDate +
      "&is_upcoming=" +
      (this.state.is_upcoming === true ? 1 : 0) +
      "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ tab: 5, is_upcoming: false, start_date: "", end_date: "" });
    this.getData("tab=5");
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString + "tab=" + this.state.tab),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString + "tab=" + this.state.tab);
    }
    this.setState({ page_number: data.page_number });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Events";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  // rowClickedHandler = (event, data, rowIndex) => {
  //   this.props.history.push("events/view/" + data.slug);
  // };

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {global.userPermissions.checkPermission("events-add") && (
                        <Link to={"events/add"}>
                          <button className="btn-success-outline-small ">
                            <img src={pluswIcon} alt="" /> Add
                          </button>
                        </Link>
                      )}
                      {/* {this.state.total_record > 0 && this.state.tab === 5 ? (
                        <button
                          onClick={this.filterToggle}
                          className="btn-success-outline-small"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.showFilter === 1 ? "Filter" : "Filter"}
                        </button>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15 scroll-slide mg-lg-l-0 mg-xs-l-15">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="current"
                    onSelect={(e) => this.getActiveClass(e)}
                    eventKey={this.state.activeClass}
                    className="pd-r-60"
                  >
                    <ScrollingCarousel
                      rightArrow={true}
                      leftArrow={true}
                      className="swipesection"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="current"
                          id="current"
                          className={
                            this.state.activeClass === "current" ? "active" : ""
                          }
                        >
                          Current
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          eventKey="upcoming"
                          id="upcoming"
                          className={
                            this.state.activeClass === "upcoming"
                              ? "active"
                              : ""
                          }
                        >
                          Upcoming
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="completed"
                          id="completed"
                          className={
                            this.state.activeClass === "completed"
                              ? "active"
                              : ""
                          }
                        >
                          Completed
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          eventKey="all"
                          id="all"
                          className={
                            this.state.activeClass === "all" ? "active" : ""
                          }
                        >
                          All
                        </Nav.Link>
                      </Nav.Item>
                    </ScrollingCarousel>
                    <Tab.Content>
                      <Tab.Pane eventKey="current">
                        <div className=" dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0">
                            <Alerts
                              show={this.state.showAlertModal}
                              type={this.state.alertModalType}
                              title={this.state.alertModalTitle}
                              message={this.state.alertModalMessage}
                            />
                          </div>

                          {this.state.showFilter ? (
                            <>
                              <Row className="align-items-center order_form col-md-12 pr-0 mb-3">
                                {/* <Col md={2} className="mb-md-0 mb-3 p-0"></Col>
                    <Col md={5} className="mb-md-0 mb-0 pl-3 pr-0"></Col>
                    <Col md={5} className="mb-md-0 mb-0 pl-3 pr-0"></Col> */}
                              </Row>
                              <Row className="align-items-baseline order_form col-md-12 pr-0 mb-3">
                                <Col md={2} className="mb-md-0 mb-3 p-0">
                                  <label className="form-label text-left pd-b-5">
                                    Upcoming Events
                                  </label>
                                  <Form.Check
                                    name="is_upcoming"
                                    type={"checkbox"}
                                    id={`is_upcoming`}
                                    label={""}
                                    className={"ckbox"}
                                    onChange={this.changeHandler}
                                    checked={this.state.is_upcoming}
                                  />
                                </Col>
                                <Col md={5} className="mb-md-0 mb-3 pl-3 pr-0">
                                  <label className="form-label text-left pd-b-5">
                                    From
                                  </label>
                                  <DatePicker
                                    className="form-control max_width_100"
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    disabledKeyboardNavigation
                                    strictParsing
                                    autoComplete="off"
                                    yearDropdownItemNumber={
                                      global.yearDropdownItemNumber
                                    }
                                    name="start_date"
                                    // placeholderText="Start Date & Time"
                                    dateFormat="MM/dd/yy"
                                    selected={this.state.start_date}
                                    onChange={(data) =>
                                      this.changeHandler({
                                        target: {
                                          type: "date",
                                          name: "start_date",
                                          value: data,
                                        },
                                      })
                                    }
                                  />
                                </Col>
                                <Col md={5} className="mb-md-0 mb-3 pl-3 pr-0">
                                  <label className="form-label text-left pd-b-5">
                                    To
                                  </label>
                                  <DatePicker
                                    className="form-control max_width_100"
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    disabledKeyboardNavigation
                                    strictParsing
                                    autoComplete="off"
                                    yearDropdownItemNumber={
                                      global.yearDropdownItemNumber
                                    }
                                    name="end_date"
                                    // placeholderText="End Date & Time"
                                    dateFormat="MM/dd/yy"
                                    selected={this.state.end_date}
                                    onChange={(data) =>
                                      this.changeHandler({
                                        target: {
                                          type: "date",
                                          name: "end_date",
                                          value: data,
                                        },
                                      })
                                    }
                                  />
                                </Col>
                                <Col md={12} lg={12} xl={12} className="mt-3">
                                  <Row className="d-flex justify-content-end">
                                    <button
                                      onClick={this.customFilter}
                                      className="btn-success mg-l-0 mg-r-15"
                                    >
                                      Apply
                                    </button>
                                    <button
                                      onClick={this.resetDate}
                                      className="btn-success-outline"
                                    >
                                      Reset
                                    </button>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            ""
                          )}

                          {queryParams.isGlobal === "1" ? (
                            <>
                              <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                {"Search applied on: " +
                                  queryParams.global_col_label +
                                  " - " +
                                  queryParams.global_search}
                                <button
                                  onClick={this.clearFilter}
                                  className="mg-l-15 btn-success-outline-small"
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.total_record > 0 ||
                          this.state.filter_value !== "" ? (
                            <div className="mg-t-18 brandList">
                              <ReactDatatable
                                className="table no-wrap mg-b-30 pd-b-2"
                                config={
                                  this.state.total_record > global.page_size
                                    ? this.state.config
                                    : this.state.config
                                }
                                records={this.state.records}
                                columns={this.state.columns}
                                extraButtons={this.state.extraButtons}
                                dynamic={true}
                                // loading={this.state.loading}
                                total_record={this.state.total_record}
                                onChange={this.tableChangeHandler}
                                //  onRowClicked={this.rowClickedHandler}
                              />
                            </div>
                          ) : (
                            <NoData
                              msg={global.commonMessages.checkMessage("EVENST")}
                            />
                          )}
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="upcoming">
                        <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0">
                            <Alerts
                              show={this.state.showAlertModal}
                              type={this.state.alertModalType}
                              title={this.state.alertModalTitle}
                              message={this.state.alertModalMessage}
                            />
                          </div>

                          {queryParams.isGlobal === "1" ? (
                            <>
                              <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                {"Search applied on: " +
                                  queryParams.global_col_label +
                                  " - " +
                                  queryParams.global_search}
                                <button
                                  onClick={this.clearFilter}
                                  className="mg-l-15 btn-success-outline-small"
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.total_record > 0 ||
                          this.state.filter_value !== "" ? (
                            <div className="mg-t-18 brandList">
                              <ReactDatatable
                                className="table no-wrap mg-b-30 pd-b-2"
                                config={
                                  this.state.total_record > global.page_size
                                    ? this.state.config
                                    : this.state.config
                                }
                                records={this.state.records}
                                columns={this.state.columns}
                                extraButtons={this.state.extraButtons}
                                dynamic={true}
                                // loading={this.state.loading}
                                total_record={this.state.total_record}
                                onChange={this.tableChangeHandler}
                                //  onRowClicked={this.rowClickedHandler}
                              />
                            </div>
                          ) : (
                            <NoData msg="events" />
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="completed">
                        <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0">
                            <Alerts
                              show={this.state.showAlertModal}
                              type={this.state.alertModalType}
                              title={this.state.alertModalTitle}
                              message={this.state.alertModalMessage}
                            />
                          </div>

                          {queryParams.isGlobal === "1" ? (
                            <>
                              <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                {"Search applied on: " +
                                  queryParams.global_col_label +
                                  " - " +
                                  queryParams.global_search}
                                <button
                                  onClick={this.clearFilter}
                                  className="mg-l-15 btn-success-outline-small"
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.total_record > 0 ||
                          this.state.filter_value !== "" ? (
                            <div className="mg-t-18 brandList">
                              <ReactDatatable
                                className="table no-wrap mg-b-30 pd-b-2"
                                config={
                                  this.state.total_record > global.page_size
                                    ? this.state.config
                                    : this.state.config
                                }
                                records={this.state.records}
                                columns={this.state.columns}
                                extraButtons={this.state.extraButtons}
                                dynamic={true}
                                // loading={this.state.loading}
                                total_record={this.state.total_record}
                                onChange={this.tableChangeHandler}
                                //  onRowClicked={this.rowClickedHandler}
                              />
                            </div>
                          ) : (
                            <NoData msg="events" />
                          )}
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="all">
                        <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0">
                            <Alerts
                              show={this.state.showAlertModal}
                              type={this.state.alertModalType}
                              title={this.state.alertModalTitle}
                              message={this.state.alertModalMessage}
                            />
                          </div>

                          {this.state.showFilter ? (
                            <>
                              <Row className="align-items-center order_form col-md-12 pr-0 mb-3">
                                {/* <Col md={2} className="mb-md-0 mb-3 p-0"></Col>
                    <Col md={5} className="mb-md-0 mb-0 pl-3 pr-0"></Col>
                    <Col md={5} className="mb-md-0 mb-0 pl-3 pr-0"></Col> */}
                              </Row>
                              <Row className="align-items-baseline order_form col-md-12 pr-0 mb-3">
                                <Col md={2} className="mb-md-0 mb-3 p-0">
                                  <label className="form-label text-left pd-b-5">
                                    Upcoming Events
                                  </label>
                                  <Form.Check
                                    name="is_upcoming"
                                    type={"checkbox"}
                                    id={`is_upcoming`}
                                    label={""}
                                    className={"ckbox"}
                                    onChange={this.changeHandler}
                                    checked={this.state.is_upcoming}
                                  />
                                </Col>
                                <Col md={5} className="mb-md-0 mb-3 pl-3 pr-0">
                                  <label className="form-label text-left pd-b-5">
                                    From
                                  </label>
                                  <DatePicker
                                    className="form-control max_width_100"
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    disabledKeyboardNavigation
                                    strictParsing
                                    autoComplete="off"
                                    yearDropdownItemNumber={
                                      global.yearDropdownItemNumber
                                    }
                                    name="start_date"
                                    // placeholderText="Start Date & Time"
                                    dateFormat="MM/dd/yy"
                                    selected={this.state.start_date}
                                    onChange={(data) =>
                                      this.changeHandler({
                                        target: {
                                          type: "date",
                                          name: "start_date",
                                          value: data,
                                        },
                                      })
                                    }
                                  />
                                </Col>
                                <Col md={5} className="mb-md-0 mb-3 pl-3 pr-0">
                                  <label className="form-label text-left pd-b-5">
                                    To
                                  </label>
                                  <DatePicker
                                    className="form-control max_width_100"
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    disabledKeyboardNavigation
                                    strictParsing
                                    autoComplete="off"
                                    yearDropdownItemNumber={
                                      global.yearDropdownItemNumber
                                    }
                                    name="end_date"
                                    // placeholderText="End Date & Time"
                                    dateFormat="MM/dd/yy"
                                    selected={this.state.end_date}
                                    onChange={(data) =>
                                      this.changeHandler({
                                        target: {
                                          type: "date",
                                          name: "end_date",
                                          value: data,
                                        },
                                      })
                                    }
                                  />
                                </Col>
                                <Col md={12} lg={12} xl={12} className="mt-3">
                                  <Row className="d-flex justify-content-end">
                                    <button
                                      onClick={this.customFilter}
                                      className="btn-success mg-l-0 mg-r-15"
                                    >
                                      Apply
                                    </button>
                                    <button
                                      onClick={this.resetDate}
                                      className="btn-success-outline"
                                    >
                                      Reset
                                    </button>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            ""
                          )}

                          {queryParams.isGlobal === "1" ? (
                            <>
                              <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                {"Search applied on: " +
                                  queryParams.global_col_label +
                                  " - " +
                                  queryParams.global_search}
                                <button
                                  onClick={this.clearFilter}
                                  className="mg-l-15 btn-success-outline-small"
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.total_record > 0 ||
                          this.state.filter_value !== "" ? (
                            <div className="mg-t-18 brandList">
                              <ReactDatatable
                                className="table no-wrap mg-b-30 pd-b-2"
                                config={
                                  this.state.total_record > global.page_size
                                    ? this.state.config
                                    : this.state.config
                                }
                                records={this.state.records}
                                columns={this.state.columns}
                                extraButtons={this.state.extraButtons}
                                dynamic={true}
                                // loading={this.state.loading}
                                total_record={this.state.total_record}
                                onChange={this.tableChangeHandler}
                                //  onRowClicked={this.rowClickedHandler}
                              />
                            </div>
                          ) : (
                            <NoData msg="events" />
                          )}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default Events;
