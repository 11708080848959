////////////////////////////////////////////////////////////
//     							                          //
//  Program: KBCategory.jsx                            //
//  Application: Property                                   //
//  Option: to view KB Category data                   //
//  Developer: Saurabh Shukla  			                  //
//  Date: 2022-01-05                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form, InputGroup } from "react-bootstrap";

import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import KBCategoryService from "../../services/KBCategoryService";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import NoData from "../common/NoData";
import MasterSidebar from "../common/MasterSidebar";
import Select from "react-select";

export class KBCategory extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",

      //for KB Category
      addKBCategoryModel: false,
      editKBCategoryModel: false,
      deleteKBCategoryModel: false,
      KBCategoryList: [],
      selectedContentTypeList: [],
      KBCategoryListTotal: 0,
      kb_category_name: "",
      newContentTypeList: [{
        value:0,
        label:"FAQ"
      },
      {
        value:1,
        label:"CMS Category"
      }]
    };
    this.changeHandler = this.changeHandler.bind(this);

    /*for KB Category */
    this.openAddKBCategoryModel = this.openAddKBCategoryModel.bind(this);
    this.openEditKBCategoryModel = this.openEditKBCategoryModel.bind(this);
    this.openDeleteKBCategoryModel =
      this.openDeleteKBCategoryModel.bind(this);
    this.saveKBCategory = this.saveKBCategory.bind(this);
    this.getKBCategoryDetails = this.getKBCategoryDetails.bind(this);
    this.updateKBCategory = this.updateKBCategory.bind(this);
    this.deleteKBCategory = this.deleteKBCategory.bind(this);
  }

  componentDidMount() {
    this.getKBCategoryData();
  }

  /* to get KB Category data */
  async getKBCategoryData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var KBCategoryList = [];
    var KBCategoryListTotal = 0;

    let res = await KBCategoryService.getKBCategory(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      KBCategoryList = res.data.data ? res.data.data : [];
      KBCategoryListTotal = KBCategoryList ? KBCategoryList.length : 0;
    }
    this.setState({
      KBCategoryList: KBCategoryList,
      KBCategoryListTotal: KBCategoryListTotal
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save KB Category data */
  saveKBCategory = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.kb_category_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        kb_category_name: this.state.kb_category_name,
        sort_order: this.state.sort_order,
        category: this.state.selectedContentTypeList.value,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await KBCategoryService.createKBCategory(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddKBCategoryModel();
        this.setState({
          kb_category_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getKBCategoryData();
      } else {
        // let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get KB Category details data */
  async getKBCategoryDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      var selectedContentTypeList = [];

      let res = await KBCategoryService.getKBCategoryBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.kb_category_name = res.data.kb_category_name
          ? res.data.kb_category_name
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;

        selectedContentTypeList = res.data.category === 0
            ? [{
              value:0,
              label:"FAQ"}]
            : [{
              value:1,
              label:"CMS Category"
            }];
      }
      this.setState(resData);
      this.setState({selectedContentTypeList:selectedContentTypeList});

    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update KB Category data */
  updateKBCategory = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.kb_category_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        kb_category_name: this.state.kb_category_name,
        sort_order: this.state.sort_order,
        category: this.state.category,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await KBCategoryService.updateKBCategory(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditKBCategoryModel();
        this.setState({
          kb_category_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getKBCategoryData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete KB Category data */
  async deleteKBCategory(slug) {
    this.setState({ showSpinner: true });
    let res = await KBCategoryService.deleteKBCategory(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteKBCategoryModel();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getKBCategoryData();
    } else {
      this.closeDeleteKBCategoryModel();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* for KB Category modal */
  openAddKBCategoryModel() {
    this.setState({ addKBCategoryModel: true });
  }

  closeAddKBCategoryModel() {
    this.setState({ addKBCategoryModel: false });
    this.setState({
      kb_category_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openEditKBCategoryModel(slug) {
    this.getKBCategoryDetails(slug);
    this.setState({ editKBCategoryModel: true });
    this.setState({ slug: slug });
  }

  closeEditKBCategoryModel() {
    this.setState({ editKBCategoryModel: false });
    this.setState({
      slug: "",
      kb_category_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openDeleteKBCategoryModel(slug) {
    this.setState({ deleteKBCategoryModel: true });
    this.setState({ slug: slug });
  }

  closeDeleteKBCategoryModel() {
    this.setState({ deleteKBCategoryModel: false });
    this.setState({ slug: "" });
  }
  
  handleChangeContentTypeList(value) {
    let category = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        category.push(item.value);
      });
    }
    this.setState({
      selectedContentTypeList: value,
      category: value.value,
    });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "FAQ Category";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                            <Button
                              variant="outline-dark btn-block btn-with-icon"
                              onClick={this.openAddKBCategoryModel}
                            >
                              <img src={pluswIcon} /> Add
                            </Button>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <Table hover className="mg-b-0">
                        <thead>
                          <tr>
                            <th className="w-40">Category</th>
                            <th className="w-40">Module</th>
                            <th className="w-25">Status</th>
                            {global.userPermissions.checkPermission(
                              "master-data-delete"
                            ) && <th className="text-center">Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.KBCategoryListTotal > 0 ? (
                            this.state.KBCategoryList.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="hand-cursor"
                                    onClick={() => {
                                      global.userPermissions.checkPermission(
                                        "master-data-delete"
                                      ) &&
                                        this.openEditKBCategoryModel(
                                          item.slug
                                        );
                                    }}
                                  >
                                    {item.kb_category_name}
                                  </td>
                                    {item.category === 0 ? (
                                      <td
                                      className="hand-cursor"
                                      onClick={() => {
                                        global.userPermissions.checkPermission(
                                          "master-data-delete"
                                        ) &&
                                          this.openEditKBCategoryModel(
                                            item.slug
                                          );
                                      }}
                                    >
                                      FAQ
                                    </td>
                                    ) : (
                                      <td
                                    className="hand-cursor"
                                    onClick={() => {
                                      global.userPermissions.checkPermission(
                                        "master-data-delete"
                                      ) &&
                                        this.openEditKBCategoryModel(
                                          item.slug
                                        );
                                    }}
                                  >
                                    CMS Category
                                  </td>
                                    )}
                                  <td>
                                    {item.status_id === 1 ? (
                                      <b className="active"></b>
                                    ) : (
                                      <b></b>
                                    )}
                                  </td>
                                  {global.userPermissions.checkPermission(
                                    "master-data-delete"
                                  ) && (
                                      <td>
                                        <div className="btn-icon-list d-block text-center">
                                          <img
                                            src={deleteicon}
                                            alt="Delete"
                                            onClick={() =>
                                              this.openDeleteKBCategoryModel(
                                                item.slug
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                        </div>
                                      </td>
                                    )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="9" align="center">
                                <NoData msg="active faq category" />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add KB Category popup modal */}
        <Modal
          show={this.state.addKBCategoryModel}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddKBCategoryModel();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddKBCategoryModel()}
            >
              {global.closee}
            </span>

            <h3 className="text-center">FAQ Category</h3>
            <label>Category</label>
            <Form.Control
              onChange={this.changeHandler}
              name="kb_category_name"
              value={this.state.kb_category_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
            // placeholder="KB Category Name"
            />
            {this.state.errors.kb_category_name ? (
              <div className="text-danger">
                {this.state.errors.kb_category_name}
              </div>
            ) : (
              this.validator.message(
                "category_name",
                this.state.kb_category_name,
                "required",
                { className: "text-danger" }
              )
            )}
            <label className="mt-3">
              Module{" "}
              {/* <span className="optional">(Optional)</span> */}
            </label>
            <Select
              styles={customStyles}
              className="multiselect"
              menuPlacement="auto"
              id="inlineFormInput"
              options={this.state.newContentTypeList}
              getOptionValue={(option) => `${option.label}`}
              onChange={(value) =>
                this.handleChangeContentTypeList(value)
              }
              theme={(theme) => ({
                ...theme,
                borderRadius: 2,
                colors: {
                  ...theme.colors,
                  primary: "#000" 
                },
              })}
            />

            <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
              // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "Active" : "Inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddKBCategoryModel()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveKBCategory}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit KB Category popup modal */}
        <Modal
          show={this.state.editKBCategoryModel}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditKBCategoryModel();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditKBCategoryModel()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update FAQ Category</h3>
            <label>Category </label>
            <Form.Control
              onChange={this.changeHandler}
              name="kb_category_name"
              value={this.state.kb_category_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
            // placeholder="KB Category Name"
            />
            {this.state.errors.kb_category_name ? (
              <div className="text-danger">
                {this.state.errors.kb_category_name}
              </div>
            ) : (
              this.validator.message(
                "kb_category_name",
                this.state.kb_category_name,
                "required",
                { className: "text-danger" }
              )
            )}
            <label className="mt-3">
              Module{" "}
              {/* <span className="optional">(Optional)</span> */}
            </label>
            <Select
              styles={customStyles}
              className="multiselect"
              menuPlacement="auto"
              id="inlineFormInput"
              value={this.state.selectedContentTypeList}
              options={this.state.newContentTypeList}
              getOptionValue={(option) => `${option.label}`}
              onChange={(value) =>
                this.handleChangeContentTypeList(value)
              }
              defaultValue={this.state.selectedContentTypeList}
              theme={(theme) => ({
                ...theme,
                borderRadius: 2,
                colors: {
                  ...theme.colors,
                  primary: "#000" 
                },
              })}
            />
            <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
              // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "Active" : "Inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditKBCategoryModel()}
              >
                Cancel
              </button>
              <button
                type="button"
                className=" m-0 btn btn btn-success btn-block"
                onClick={this.updateKBCategory}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete KB Category popup modal */}
        <Modal
          show={this.state.deleteKBCategoryModel}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteKBCategoryModel();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteKBCategoryModel()}
              >
                {global.closee}
              </span>

              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteKBCategoryModel()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteKBCategory(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default KBCategory;
