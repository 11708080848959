////////////////////////////////////////////////////////////
//     							                                      //
//  Program: GuestForm.jsx                                //
//  Application:  Guest                                   //
//  Option: For add or update Guest                       //
//  Developer: NP                                         //
//  Date: 2022-04-18                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";

import { Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import SimpleReactValidator from "simple-react-validator";
// import CountryService from "../../services/CountryService";
// import CountyService from "../../services/CountyService";
// import StateService from "../../services/StateService";
// import CityService from "../../services/CityService";
import GuestService from "../../services/GuestService";
import CountryService from "../../services/CountryService";
// import Select from "react-select";
import CalendarSidebar from "../common/CalendarSidebar";
import PhoneInput from 'react-phone-input-2'
import GooglePlaces from "../common/GooglePlaces";
import AllPropertyReservationSidebar from "../common/AllPropertyReservationSidebar";

export class GuestForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      property_slug: this.props.match.params.propertySlug ? this.props.match.params.propertySlug : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      status_id: true,
      pathname: window.location.pathname,
      confirm_back: 0,
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      countryListTotal: 0,
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      cityListTotal: 0,
      is_id_verified: false,
    };

    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateGuest = this.saveOrUpdateGuest.bind(this);
    this.getChildData = this.getChildData.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);

  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await GuestService.getGuest(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData.first_name = res.data ? res.data.first_name : "";
        resData.last_name = res.data ? res.data.last_name : "";
        resData.email = res.data ? res.data.email : "";
        resData.phone_number = res.data ? res.data.phone_number : "";
        resData.address = res.data ? res.data.address : "";
        resData.zipcode = res.data ? res.data.zip_code : "";
        resData.passport = res.data ? res.data.passport : "";
        resData.comments = res.data ? res.data.comments : "";
        resData.street_1 = res.data ? res.data.street_1 : "";
        resData.street_2 = res.data ? res.data.street_2 : "";
        resData.country_name = res.data && res.data.country ? res.data.country.country_name : "";
        resData.city_name = res.data && res.data.city ? res.data.city.city_name : "";
        resData.county_name = res.data && res.data.county ? res.data.county.county_name : "";
        resData.state_name = res.data && res.data.state ? res.data.state.state_name : "";
        resData.is_id_verified = res.data ? res.data.is_id_verified === 1 ? true : false : false;
      }
      this.setState(resData);
      this.defaultData = resData;
    }
  }

  async getChildData(unit,street1,city,state,county,country,zipcode){
    this.setState({street_1:unit});
    this.setState({street_2:street1});
    this.setState({city_name:city});
    this.setState({state_name:state});
    this.setState({county_name:county});
    this.setState({country_name:country});
    this.setState({zipcode:zipcode});
    //api call for check location
    let inputData = {
      place_country_name: country ? country : '',
      place_state_name: state ? state : '',
      place_county_name: county ? county : '',
      place_city_name: city ? city : '',
    };

    // if(state && country){
      let res = await CountryService.checkLocation(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          countryId: res.data.countryId,
          stateId: res.data.countryId,
          countyId: res.data.countyId,
          cityId: res.data.cityId,
        });
      }
    // }
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }

  saveOrUpdateGuest = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        remove_redis: this.state.slug !== undefined ? "yes" : "",
        property_slug: this.state.property_slug
          ? this.state.property_slug
          : this.props.property_slug,
        first_name: this.state.first_name ? this.state.first_name : "",
        last_name: this.state.last_name ? this.state.last_name : "",
        email: this.state.email ? this.state.email : "",
        phone_number: this.state.phone_number ? this.state.phone_number : "",
        dial_code : this.state.dial_code ? this.state.dial_code : "",
        passport: this.state.passport ? this.state.passport : "",
        comments: this.state.comments ? this.state.comments : "",
        status_id: this.state.status_id === true ? 1 : 2,
        is_id_verified: this.state.is_id_verified === true ? 1 : 2,

        street_1: this.state.street_1 ? this.state.street_1 : "",
        street_2: this.state.street_2 ? this.state.street_2 : "",
        zip_code: this.state.zipcode ? this.state.zipcode : "",
        country_id: this.state.countryId ? this.state.countryId : "",
        state_id: this.state.stateId ? this.state.stateId : "",
        county_id: this.state.countyId ? this.state.countyId : "",
        city_id: this.state.cityId ? this.state.cityId : "",
      };

      if (this.state.slug !== undefined) {
        let res = await GuestService.updateGuest(this.state.slug, inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(this.props.match.params.propertySlug !== undefined ?
                "/properties/view/" +
                this.state.property_slug +
                "/guest/view/" +
                this.state.slug : "/guest/view/"+this.state.property_slug+"/"+this.state.slug
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }
      } else {
        let res = await GuestService.createGuest(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(this.props.match.params.propertySlug !== undefined ?
                "/properties/view/" + this.state.property_slug + "/guest" : "/guest"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
    });

    this.validator.showMessageFor(event.target.name);

    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Guest";
    } else {
      return "Guest";
    }
  }

  onChangePhone(value, country, e, formattedValue){
    //console.log(value, country, e, formattedValue);
    this.setState({phone_number:value});
    this.setState({dial_code:country.dialCode});
}

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
            {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <CalendarSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyReservationSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      {this.state.slug !== undefined ? (
                        <Link
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(this.props.match.params.propertySlug !== undefined ?
                                  "/properties/view/" +
                                  this.state.property_slug +
                                  "/guest" : "/guest/view/"+this.state.property_slug+"/"+this.state.slug
                                );
                              else return false;
                            else
                              this.props.history.push(this.props.match.params.propertySlug !== undefined ?
                                "/properties/view/" +
                                this.state.property_slug +
                                "/guest" :  "/guest/view/"+this.state.property_slug+"/"+this.state.slug
                              );
                          }}
                        >
                          <button
                            type="button"
                            className="btn-success-outline-small"
                          >
                            Cancel
                          </button>
                        </Link>
                      ) : (
                        <Link
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(this.props.match.params.propertySlug !== undefined ?
                                  "/properties/view/" +
                                  this.state.property_slug +
                                  "/guest" : "/guest"
                                );
                              else return false;
                            else
                              this.props.history.push(this.props.match.params.propertySlug !== undefined ?
                                "/properties/view/" +
                                this.state.property_slug +
                                "/guest" : "/guest"
                              );
                          }}
                        >
                          <button
                            type="button"
                            className="btn-success-outline-small"
                          >
                            Cancel
                          </button>
                        </Link>
                      )}


                      {global.userPermissions.checkPermission(
                        "guests-update"
                      ) && (
                      <button
                        type="button"
                        className="btn-success ml-3"
                        onClick={this.saveOrUpdateGuest}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                            ? "Save"
                            : "Save"}
                      </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <form id="units_form">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-l-30 pd-r-30 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="m-0">
                        <div className="row align-items-center pd-b-0 border-bottom-0  pd-xs-l-0 pd-xs-r-0  pd-lg-l-0 pd-lg-r-15">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              First Name{" "}
                            </label>
                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="first_name"
                              value={this.state.first_name}
                              maxLength="50"
                            />
                            {this.state.errors.first_name ? (
                              <div className="text-danger">
                                {this.state.errors.first_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "first_name",
                                this.state.first_name,
                                "required|max:50",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Last Name{" "}
                            </label>
                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="last_name"
                              value={this.state.last_name}
                              maxLength="50"
                            />
                            {this.state.errors.last_name ? (
                              <div className="text-danger">
                                {this.state.errors.last_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "last_name",
                                this.state.last_name,
                                "required|max:50",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Email{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="email"
                              value={this.state.email}
                              maxLength="50"
                            />
                            {this.state.errors.email ? (
                              <div className="text-danger">
                                {this.state.errors.email}
                              </div>
                            ) : (
                              this.validator.message(
                                "email",
                                this.state.email,
                                "email|max:50",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Phone{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <PhoneInput
                              country={global.country}
                              disableSearchIcon
                              // autoFormat= "true"
                              enableSearch= "true"
                              countryCodeEditable= "true"
                              value={this.state.phone_number}
                              onChange={this.onChangePhone}

                            />
                            {this.state.errors.phone_number ? (
                              <div className="text-danger">
                                {this.state.errors.phone_number}
                              </div>
                            ) : (
                              this.validator.message(
                                "phone",
                                this.state.phone_number,
                                "max:15",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Passport{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="passport"
                              value={this.state.passport}
                              maxLength="50"
                            />
                            {this.state.errors.passport ? (
                              <div className="text-danger">
                                {this.state.errors.passport}
                              </div>
                            ) : (
                              this.validator.message(
                                "passport",
                                this.state.passport,
                                "max:20",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Verified
                            </label>
                            <Form.Check
                              name="is_id_verified"
                              type={"checkbox"}
                              id={`is_id_verified`}
                              label={""}
                              className={"ckbox"}
                              onChange={this.changeHandler}
                              checked={this.state.is_id_verified}
                            />
                          </div> */}
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Comments{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              // type="text"
                              as="textarea"
                              onChange={this.changeHandler}
                              name="comments"
                              value={this.state.comments}
                              maxLength="500"
                            />
                            {this.state.errors.comments ? (
                              <div className="text-danger">
                                {this.state.errors.comments}
                              </div>
                            ) : (
                              this.validator.message(
                                "comments",
                                this.state.comments,
                                "max:500",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                            <h3 className="mg-l-0 background-head">Address</h3>
                          </div>
                          <GooglePlaces getChildData={this.getChildData.bind(this)} unit={this.state.street_1} street1={this.state.street_2} city={this.state.city_name} state={this.state.state_name} county={this.state.county_name} country={this.state.country_name} zipcode={this.state.zipcode} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default GuestForm;
