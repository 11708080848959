//                                                           //
//  Program: HomeSharingReport.jsx                              //
//  Application: Report                                      //
//  Option: Homesharing Report Listing                         //
//  Developer: NP 		                                     //
//  Date: 2022-08-30                                         //
//                                                           //
/////////////////////////////////////////////////////////   ///

import React, { Component } from "react";
import { Container, Tab, Modal, InputGroup, Form } from "react-bootstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import ReportSidebar from "../common/ReportSidebar";
import ReportService from "../../services/ReportService";
import Moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import UnitsService from "../../services/UnitsService";
import MultiImageService from "./../../services/MultiImageService";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyReportSidebar from "../common/AllPropertyReportSidebar";

class HomeSharingReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      pathname: window.location.pathname,
      property_slug: this.props.match.params.propertySlug,
      isSubmit: false,
      module_search: "",
      module_search_display: "",
      exportMesssage: "",
      exportFilePath: "",
      exportResult: "",
      alertType: "xlsx",
      is_search: 0,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "unit_number",
          text: "Unit",
          className: "unit_number text-center td_clickable",
          sortable: true,
          width: 300,
          cell: (record) => {
            if (record.unit_number) {
              return record.aka_name + "-" + record.unit_number;
            } else {
              return "";
            }
          },
        },
        {
          key: "Resident Name",
          text: "Resident Name",
          className: "text-center td_clickable",
          width: 300,
          cell: (record) => {
            if (record.final_resident_name) {
              return (
                <span id="to-pay" className="grand_total1">
                  {record.final_resident_name}
                </span>
              );
            } else if (record.resident_name) {
              return record.resident_name;
            } else {
              return "";
            }
          },
        },
        {
          key: "payout_recipient",
          text: "Payout Recipient",
          className: "text-center td_clickable",
          width: 300,
          cell: (record) => {
            if (record.final_w9_resident_name) {
              return (
                <span id="to-pay" className="grand_total1">
                  {record.final_w9_resident_name}
                </span>
              );
            } else if (record.w9_resident_name) {
              return record.w9_resident_name;
            } else {
              return "";
            }
          },
        },
        {
          key: "current_lease_rate",
          text: "Current Lease Rate",
          className: "adr text-center td_clickable",
          sortable: false,
          width: 300,
          cell: (record) => {
            if (record.current_lease_rate) {
              return record.current_lease_rate;
            } else if (record.final_lease_rate) {
              return record.final_lease_rate;
            } else {
              return "$0.00";
            }
          },
        },
        {
          key: "property_earning",
          text: "Building Homesharing Income",
          className: "adr text-center td_clickable",
          sortable: false,
          width: 300,
          cell: (record) => {
            if (record.property_earning) {
              return record.property_earning;
            } else if (record.final_property_earning) {
              return record.final_property_earning;
            } else {
              return "$0.00";
            }
          },
        },
        {
          key: "rent_and_home_sharing",
          text: "Homesharing Income + Current Lease income",
          className: "adr  text-center td_clickable",
          sortable: false,
          width: 300,
          cell: (record) => {
            if (record.rent_and_home_sharing) {
              return record.rent_and_home_sharing;
            } else if (record.final_rent_home_sharing) {
              return record.final_rent_home_sharing;
            } else {
              return "$0.00";
            }
          },
        },
        {
          key: "total_booked_days",
          text: "No of Nights Booked",
          className: "total_booked_days text-center td_clickable",
          sortable: false,
          width: 300,
          cell: (record) => {
            if (record.total_booked_days) {
              return record.total_booked_days;
            } else if (record.final_total_booked_days) {
              return record.final_total_booked_days;
            } else {
              return "0";
            }
          },
        },
        {
          key: "Resident Payout",
          text: "Resident Payout",
          className: "adr resident_payout text-center td_clickable",
          width: 300,
          cell: (record) => {
            if (record.resident_earning) {
              return record.resident_earning;
            } else if (record.final_resident_earning) {
              return record.final_resident_earning;
            } else {
              return "$0.00";
            }
          },
        },
      ],
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      start_date: new Date(),
      end_date: new Date(),
    };
    this.getEarningsReports = this.getEarningsReports.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.removeTotalClass = this.removeTotalClass.bind(this);
  }

  async clearFilter() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.setState({ is_search: 0 });
    this.setState({
      start_date: firstDay,
      end_date: lastDay,
      unit_slug: "",
      selectedUnitList: [],
    });
    this.setState({ module_search: "" });
    await this.removeTotalClass();
    this.getEarningsReports();
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      this.getEarningsReports();
      this.gerDefaultInOutDate();
    }
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.getEarningsReports();
      this.gerDefaultInOutDate();
    }, 200);
    this.setState({
      selectedUnitList: [],
      module_search: [],
      is_search: 0,
    });
  }

  gerDefaultInOutDate() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.setState({ start_date: firstDay });
    this.setState({ end_date: lastDay });
  }
  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_name, tower, total_tower_count }) => ({
            value: slug,
            label: unit_name,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getUnitData();
      // this.getResidentData();
      this.setState({ isSearch: 1 });
    }
  };

  exportToggle = () => {
    if (this.state.isModel === 1) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: 0 });
      this.setState({ showSpinner: false, isSubmit: false });
    } else {
      this.setState({ isModel: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  async ExportData() {
    this.setState({ showSpinner: true, isSubmit: true });
    let data = {
      property_slug: this.state.property_slug,
    };
    let leaseStart = this.state.start_date
      ? Moment(this.state.start_date).format("YYYY-MM-DD")
      : "";
    let leaseEnd = this.state.end_date
      ? Moment(this.state.end_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "property_slug=" +
      (this.state.property_slug ? this.state.property_slug : "") +
      "&is_export=1" +
      "&file_type=" +
      this.state.alertType +
      "&start_date=" +
      leaseStart +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&end_date=" +
      leaseEnd;
    let res = await ReportService.exportHomeSharingReport(queryString);
    if (res.data.filePath) {
      this.getDownloadFile(res.data.filePath, res.data.fileName);
      this.setState({ exportMesssage: res.message });
      this.setState({ exportResult: data });
      this.setState({ exportFilePath: res.data.filePath });
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  async getDownloadFile(url, name) {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  exportToggle = () => {
    if (this.state.isModel === 1) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: 0 });
    } else {
      this.setState({ isModel: 1 });
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  async filterData() {
    let leaseStart = this.state.start_date
      ? Moment(this.state.start_date).format("YYYY-MM-DD")
      : "";
    let leaseStartSearch = this.state.start_date
      ? Moment(this.state.start_date).format("MM/DD/YY")
      : "";
    let leaseEnd = this.state.end_date
      ? Moment(this.state.end_date).format("YYYY-MM-DD")
      : "";
    let leaseEndSearch = this.state.end_date
      ? Moment(this.state.end_date).format("MM/DD/YY")
      : "";
    let queryString =
      "start_date=" +
      leaseStart +
      "&end_date=" +
      leaseEnd +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&is_search=1";

    let moduleSearchString = "";
    let moduleSearchDisplay = "";
    moduleSearchString = this.state.start_date
      ? "Start Date - " + leaseStart
      : "";
    moduleSearchString = this.state.end_date
      ? moduleSearchString
        ? moduleSearchString + " ," + " End Date - " + leaseEnd
        : " End Date - " + this.state.end_date
      : moduleSearchString;
    moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString + " ," + " Unit - " + this.state.unit_slug
        : " Unit - " + this.state.unit_slug
      : moduleSearchString;

    moduleSearchDisplay = this.state.start_date
      ? "Start Date - " + leaseStartSearch
      : "";
    moduleSearchDisplay = this.state.end_date
      ? moduleSearchDisplay
        ? moduleSearchDisplay + ", " + " End Date - " + leaseEndSearch
        : " End Date - " + this.state.end_date
      : moduleSearchDisplay;
    moduleSearchDisplay = this.state.unit_lable
      ? moduleSearchDisplay
        ? moduleSearchDisplay + ", " + " Unit - " + this.state.unit_lable
        : " Unit - " + this.state.unit_lable
      : moduleSearchDisplay;

    if (this.state.start_date || this.state.end_date || this.state.unit_slug) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString });
      this.setState({ module_search_display: moduleSearchDisplay.split(',') });
      await this.removeTotalClass();
      this.getEarningsReports(queryString);
    }
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    const selected_unit_number = value !== null ? value.label : [];
    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
      unit_lable: selected_unit_number,
    });
    this.setState({ confirm_back: 1 });
  }

  // report-earnings
  getEarningsReports = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;

    let res = await ReportService.getPropertyHomeSharingReports(
      "property_slug=" + (this.state.property_slug ? this.state.property_slug : "") + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = list ? list.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
    this.addTotalClass();
  };

  /** Add Class on page load */
  addTotalClass = () => {
    if (document.getElementsByClassName("grand_total1").length > 0) {
      const grand_total = document.getElementsByClassName("grand_total1")[0];
      let ele1 = grand_total.parentElement.parentElement;
      ele1.classList.add("grand_total");
    }
  };

  removeTotalClass = async () => {
    if (document.getElementsByClassName("grand_total1").length > 0) {
      const grand_total = document.getElementsByClassName("grand_total1")[0];
      let ele1 = grand_total.parentElement.parentElement;
      ele1.classList.remove("grand_total");
    }
  };

  /* table events */
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getEarningsReports(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getEarningsReports(queryString);
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {
    // this.props.history.push("units/" + data.slug + "/unit-basic");
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <ReportSidebar property_slug={this.state.property_slug} />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyReportSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>Homesharing Income For Building Owner</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        <button
                          className="btn-success-outline-small mr-3"
                          onClick={this.searchToggle}
                        >
                          Search
                        </button>
                        <button
                            className="btn-success-outline-small mr-0"
                            onClick={this.exportToggle}
                          >
                            Export
                          </button>


                      </div>
                    </div>
                  </div>

                  
                  {this.props.match.params.propertySlug === undefined ? (
                     <>
                     <div className="row pd-lg-l-15 pd-xs-l-15">
                       <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                      <OutSidePropertySelectBox
                        propertySlug={this.getPropertySlug.bind(this)}
                        outSidePropertySlug={this.state.outSidePropertySlug}
                      />
                    </div>
                    <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                  {this.state.is_search === 1 ? (
                    <>
                      {/* <div className="col-md-12 pd-lg-l-45 pd-lg-r-45 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                        {"Search applied on: " +
                          this.state.module_search_display}
                        <button
                          onClick={this.clearFilter}
                          className="mg-l-15 btn-success-outline-small"
                        >
                          Clear
                        </button>
                      </div> */}
                        <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                          <div className="custm-filter">
                          <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                          <div className="filter_bg_cust">
                            {
                              this.state.module_search_display.map((item, i) => {
                                return (
                                  <span key={i}>{item}</span>
                                )
                              })
                            }
                            <button
                              onClick={this.clearFilter}
                              className="btn-fliter-clear"
                            >Clear</button>
                      </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    // <div className="col-md-12 pd-lg-l-45 pd-md-l-45  pd-lg-r-45 pd-md-r-30   mg-t-0 d-inline-flex align-items-center justify-content-between">
                    //   {`Search applied on: Start Date - ${Moment(
                    //     this.state.start_date
                    //   ).format("MM/DD/YY")} , End Date - ${Moment(
                    //     this.state.end_date
                    //   ).format("MM/DD/YY")}`}
                    // </div>
                    <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                    <div className="custm-filter">
                        <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                        <div className="filter_bg_cust">
                          <span>
                            {`Start Date -  ${Moment(
                        this.state.start_date
                            ).format("MM/DD/YY")} `}
                          </span>
                          <span>
                            {` End Date - ${Moment(
                        this.state.end_date
                            ).format("MM/DD/YY")}
                          `}
                          </span>
                    </div>
                      </div>
                    </div>
                  )}
                    </div>
                      </div>
                      </>
                  ) : (
                    <div className="col-xxl-12 pd-r-15 pd-l-15">
                      {this.state.is_search === 1 ? (
                    <>
                      {/* <div className="col-md-12 pd-lg-l-45 pd-lg-r-45 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                        {"Search applied on: " +
                          this.state.module_search_display}
                        <button
                          onClick={this.clearFilter}
                          className="mg-l-15 btn-success-outline-small"
                        >
                          Clear
                        </button>
                      </div> */}
                        <div className="col-xxl-12 col-sm-12 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                          <div className="custm-filter">
                          <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                          <div className="filter_bg_cust">
                            {
                              this.state.module_search_display.map((item, i) => {
                                return (
                                  <span key={i}>{item}</span>
                                )
                              })
                            }
                            <button
                              onClick={this.clearFilter}
                              className="btn-fliter-clear"
                            >Clear</button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    // <div className="col-md-12 pd-lg-l-45 pd-md-l-45  pd-lg-r-45 pd-md-r-30   mg-t-0 d-inline-flex align-items-center justify-content-between">
                    //   {`Search applied on: Start Date - ${Moment(
                    //     this.state.start_date
                    //   ).format("MM/DD/YY")} , End Date - ${Moment(
                    //     this.state.end_date
                    //   ).format("MM/DD/YY")}`}
                    // </div>
                    <div className="col-xxl-12 col-sm-12 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                    <div className="custm-filter">
                        <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                        <div className="filter_bg_cust">
                          <span>
                            {`Start Date -  ${Moment(
                              this.state.start_date
                            ).format("MM/DD/YY")} `}
                          </span>
                          <span>
                            {` End Date - ${Moment(
                              this.state.end_date
                            ).format("MM/DD/YY")}
                          `}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                    </div>
                  )}
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-lg-l-0 pd-lg-r-15 pd-xs-r-0">
                    <div className="scrolling-carousel pd-l-15 scroll-slide">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="all"
                        onSelect={(e) => this.getActiveClass(e)}
                        eventKey={this.state.activeClass}
                        className="pd-r-60"
                      >
                        <Tab.Content className="m-0">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <div className="mg-t-18 brandList pd-l-15 pd-r-15 Report_table  earning-report-tbl ">
                              <div className="pd-lg-l-0 pd-xs-l-0">
                                <div className="scroll-report-table">
                                  <ReactDatatable
                                    className="table no-wrap mg-b-30 pd-b-2 brandtable  earning_reports border table_last_row"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    // loading={this.state.loading}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">
              Search in Homesharing Income For Building Owner
            </h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Start Date</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="start_date"
                    selected={this.state.start_date}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "start_date",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>End Date</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="end_date"
                      selected={this.state.end_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "end_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Export  popup modal */}
        <Modal show={this.state.isModel} className="modal-xs" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.exportToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Export</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <InputGroup className="mt-0">
                  <label>Select Export File Type : </label>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="xlsx"
                        checked={this.state.alertType === "xlsx" ? true : false}
                        type={"radio"}
                        id="xlsx"
                        label={"XLSX"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="csv"
                        checked={this.state.alertType === "csv" ? true : false}
                        type={"radio"}
                        id="csv"
                        label={"CSV"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                  </div>
                </InputGroup>

                {this.state.exportMesssage !== "" && (
                  <div>
                    {this.state.exportMesssage}
                    <br></br>
                    <br></br>
                    {/* {this.state.exportFilePath !== undefined &&
            <a href={this.state.exportFilePath} > Download File </a>
          } */}
                  </div>
                )}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="mx-auto- mg-r-30 btn btn btn-success btn-block"
                    onClick={() => this.ExportData()}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Export"}
                  </button>
                  <button
                    type="button"
                    className="mx-auto- m-0  btn btn-outline-light btn-block"
                    onClick={() => this.exportToggle()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default HomeSharingReport;
