////////////////////////////////////////////////////////////
//     							                          //
//  Program: City.jsx                                  //
//  Application: Location                                 //
//  Option: to view City data                          //
//  Developer: Ashish Kumar  						      //
//  Date: 2022-01-31                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form, InputGroup } from "react-bootstrap";
import Alerts from "../common/Alerts";
import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import CityService from "../../services/CityService";
import modaldeleteicon from "./../../assets/images/delete.svg";
import CountyService from "../../services/CountyService";
import StateService from "../../services/StateService";
import pluswIcon from "./../../assets/images/plus.svg";
import Select from "react-select";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import NoData from "../common/NoData";

export class City extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for City
      addCityModal: false,
      editCityModal: false,
      deleteCityModal: false,
      cityList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      cityListTotal: 0,
      countyListTotal: 0,
      stateListTotal: 0,
      city_name: "",
      state_slug: "",
      county_slug: "",
      sort_order: "",
    };

    this.changeHandler = this.changeHandler.bind(this);

    /* for City */
    this.openAddCityModal = this.openAddCityModal.bind(this);
    this.openEditCityModal = this.openEditCityModal.bind(this);
    this.openDeleteCityModal = this.openDeleteCityModal.bind(this);
    this.saveCity = this.saveCity.bind(this);
    this.updateCity = this.updateCity.bind(this);
    this.getCityDetails = this.getCityDetails.bind(this);
    this.deleteCity = this.deleteCity.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }

  componentDidMount() {
    this.getCityData();
  }

  /* to get state list data */
  async getStateData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var stateList = [];
    var stateListTotal = 0;

    let res = await StateService.getStates(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
      stateListTotal = stateList ? stateList.length : 0;
    }
    const newStateList =
      stateListTotal > 0
        ? stateList.map(({ slug, state_name }) => ({
            value: slug,
            label: state_name,
          }))
        : [];
    this.setState({
      stateList: stateList,
      stateListTotal: stateListTotal,
      newStateList: newStateList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get county list data */
  async getCountyData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countyList = [];
    var countyListTotal = 0;

    let res = await CountyService.getCounty(
      "is_dropdown=1&state_slug=" + value,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
      countyListTotal = countyList ? countyList.length : 0;
    }
    const newCountyList =
      countyListTotal > 0
        ? countyList.map(({ slug, county_name }) => ({
            value: slug,
            label: county_name,
          }))
        : [];
    this.setState({
      countyList: countyList,
      countyListTotal: countyListTotal,
      newCountyList: newCountyList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get City list data */
  async getCityData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;

    let res = await CityService.getCity(queryString ? "&" + queryString : "");
    if (global.successStatus.includes(res.status)) {
      cityList = res.data.data ? res.data.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    this.setState({
      cityList: cityList,
      cityListTotal: cityListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save City data */
  saveCity = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.city_name &&
      !this.validator.errorMessages.state_slug &&
      !this.validator.errorMessages.county_slug &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        city_name: this.state.city_name,
        state_slug: this.state.state_slug,
        county_slug: this.state.county_slug,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await CityService.createCity(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddCityModal();
        this.setState({
          city_name: "",
          state_slug: "",
          county_slug: "",
          sort_order: "",
          status_id: true,
        });
        this.getCityData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get City details */
  async getCityDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await CityService.getCityBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.city_name = res.data.city_name ? res.data.city_name : "";
        resData.state_slug = res.data.state ? res.data.state.slug : "";
        resData.county_slug = res.data.county ? res.data.county.slug : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        var selectedStateList = [];
        selectedStateList = res.data.state
          ? [
              {
                value: res.data.state.slug,
                label: res.data.state.state_name,
              },
            ]
          : "";
        var selectedCountyList = [];
        selectedCountyList = res.data.county
          ? [
              {
                value: res.data.county.slug,
                label: res.data.county.county_name,
              },
            ]
          : "";
      }
      this.setState(resData);
      this.setState({
        selectedStateList: selectedStateList,
        selectedCountyList: selectedCountyList,
      });
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /*to update City data */
  updateCity = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.city_name &&
      !this.validator.errorMessages.state_slug &&
      !this.validator.errorMessages.county_slug &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        city_name: this.state.city_name,
        state_slug: this.state.state_slug,
        county_slug: this.state.county_slug,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await CityService.updateCity(this.state.slug, inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditCityModal();
        this.setState({
          city_name: "",
          state_slug: "",
          county_slug: "",
          sort_order: "",
          status_id: true,
        });
        this.getCityData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete City data */
  async deleteCity(slug) {
    this.setState({ showSpinner: true });
    let res = await CityService.deleteCity(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteCityModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getCityData();
    } else {
      this.closeDeleteCityModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closeDeleteCityModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    if (event.target.name === "state_slug") {
      this.getCountyData(event.target.value);
    }
  };

  handleChangeStateList(value) {
    let state_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_slug.push(item.value);
      });
    }
    const state_slug_value = value !== null ? value.value : [];
    if (value !== null) {
      this.getCountyData(value.value);
    }
    this.setState({
      selectedStateList: value,
      state_slug: state_slug_value,
      selectedCountyList: [],
      county_slug: "",
    });
  }

  handleChangeCountyList(value) {
    let county_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        county_slug.push(item.value);
      });
    }
    const county_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCountyList: value,
      county_slug: county_slug_value,
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* City Modals */
  openAddCityModal() {
    this.getCountyData();
    this.getStateData();
    this.setState({ addCityModal: true });
  }

  closeAddCityModal() {
    this.setState({ addCityModal: false });
    this.setState({
      city_name: "",
      state_slug: "",
      county_slug: "",
      sort_order: "",
      errors: "",
      status_id: true,
      selectedStateList: [],
      selectedCountyList: [],
    });
    this.validator.hideMessages();
  }

  openEditCityModal(slug) {
    this.getCityDetails(slug);
    this.getCountyData();
    this.getStateData();
    this.setState({ editCityModal: true });
    this.setState({ slug: slug });
  }

  closeEditCityModal() {
    this.setState({ editCityModal: false });
    this.setState({
      slug: "",
      city_name: "",
      state_slug: "",
      county_slug: "",
      sort_order: "",
      errors: "",
      status_id: true,
      selectedStateList: [],
      selectedCountyList: [],
    });
    this.validator.hideMessages();
  }

  openDeleteCityModal(slug) {
    this.setState({ deleteCityModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteCityModal() {
    this.setState({ deleteCityModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
    const scrollId = document.getElementById("test");
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "City";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        {/* City card */}
        {/* <Card>
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="28"
              id="28"
              onClick={() => this.setOpen(!this.state.open, "28")}
            >
              City
              <i className="mdi mdi-view-headline"> </i>
              {this.state.open && this.state.eventKey === "28" ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-up"></i>
              )}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="28">
            <Card.Body>
              <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
                <Button
                  variant="outline-dark btn-block btn-with-icon"
                  onClick={this.openAddCityModal}
                >
                  <img src={pluswIcon} /> Add
                </Button>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="table-responsive">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-30">City Name</th>
                      <th className="w-30">State Name</th>
                      <th className="w-30">County Name</th>
                      <th className="w-15">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody id="test">
                    {this.state.cityListTotal > 0 ? (
                      this.state.cityList.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td
                              className="hand-cursor"
                              onClick={() => this.openEditCityModal(item.slug)}
                            >
                              {item.city_name}
                            </td>
                            <td>{item.state ? item.state.state_name : ""}</td>
                            <td>
                              {item.county ? item.county.county_name : ""}
                            </td>
                            <td>
                              {item.status_id === 1 ? (
                                <b className="active"></b>
                              ) : (
                                <b></b>
                              )}
                            </td>
                            <td>
                              <div className="btn-icon-list d-block text-center">
                                <img
                                  src={deleteicon}
                                  alt="Delete"
                                  onClick={() =>
                                    this.openDeleteCityModal(item.slug)
                                  }
                                  className="hand-cursor"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          No rows found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}

        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                          <Button
                            variant="outline-dark btn-block btn-with-icon"
                            onClick={this.openAddCityModal}
                          >
                            <img src={pluswIcon} alt="" /> Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      {/* <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
              <Button
                variant="outline-dark btn-block btn-with-icon"
                onClick={this.openAddCityModal}
              >
                <img src={pluswIcon} alt="" /> Add
              </Button>
            </div> */}
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.cityListTotal > 0 ? (
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th className="w-30">City Name</th>
                              <th className="w-30">State Name</th>
                              <th className="w-30">County Name</th>
                              <th className="w-15">Status</th>
                              {global.userPermissions.checkPermission(
                                "master-data-delete"
                              ) && <th className="text-center">Action</th>}
                            </tr>
                          </thead>
                          <tbody id="test">
                            {this.state.cityListTotal > 0 ? (
                              this.state.cityList.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      className="hand-cursor"
                                      onClick={() => {
                                        global.userPermissions.checkPermission(
                                          "master-data-delete"
                                        ) && this.openEditCityModal(item.slug);
                                      }}
                                    >
                                      {item.city_name}
                                    </td>
                                    <td>
                                      {item.state ? item.state.state_name : ""}
                                    </td>
                                    <td>
                                      {item.county
                                        ? item.county.county_name
                                        : ""}
                                    </td>
                                    <td>
                                      {item.status_id === 1 ? (
                                        <b className="active"></b>
                                      ) : (
                                        <b></b>
                                      )}
                                    </td>
                                    {global.userPermissions.checkPermission(
                                      "master-data-delete"
                                    ) && (
                                      <td>
                                        <div className="btn-icon-list d-block text-center">
                                          <img
                                            src={deleteicon}
                                            alt=""
                                            onClick={() =>
                                              this.openDeleteCityModal(
                                                item.slug
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                  There are no cities added in the system. You
                                  may add them by clicking Add button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage("CITY")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add City popup modal */}
        <Modal
          show={this.state.addCityModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddCityModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddCityModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add City</h3>
            <label>City Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="city_name"
              value={this.state.city_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              //   placeholder="City Name"
            />
            {this.state.errors.city_name ? (
              <div className="text-danger">{this.state.errors.city_name}</div>
            ) : (
              this.validator.message(
                "city_name",
                this.state.city_name,
                "required|max:50",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>State</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newStateList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeStateList(value)}
                  defaultValue={this.state.selectedStateList}
                  value={this.state.selectedStateList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="state_slug"
                value={this.state.state_slug}
              >
                <option key="" value="">
                  Select State
                </option>
                {this.state.stateList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.state_name}
                  </option>
                ))}
              </select> */}
              {this.state.errors.state_slug ? (
                <div className="text-danger">
                  {this.state.errors.state_slug}
                </div>
              ) : (
                this.validator.message(
                  "state",
                  this.state.state_slug,
                  "required",
                  { className: "text-danger" }
                )
              )}
            </InputGroup>

            <InputGroup className="mt-3">
              <label>
                County <span className="optional">(Optional)</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newCountyList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCountyList(value)}
                  defaultValue={this.state.selectedCountyList}
                  value={this.state.selectedCountyList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="county_slug"
                value={this.state.county_slug}
              >
                <option key="" value="">
                  Select County
                </option>
                {this.state.countyList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.county_name}
                  </option>
                ))}
              </select> */}
            </InputGroup>

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
              //  placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddCityModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveCity}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit City popup modal */}
        <Modal
          show={this.state.editCityModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditCityModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditCityModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update City</h3>
            <label>City Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="city_name"
              value={this.state.city_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              //    placeholder="City Name"
            />
            {this.state.errors.city_name ? (
              <div className="text-danger">{this.state.errors.city_name}</div>
            ) : (
              this.validator.message(
                "city_name",
                this.state.city_name,
                "required|max:50",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>State</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newStateList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeStateList(value)}
                  defaultValue={this.state.selectedStateList}
                  value={this.state.selectedStateList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="state_slug"
                value={this.state.state_slug}
              >
                <option key="" value="">
                  Select State
                </option>
                {this.state.stateList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.state_name}
                  </option>
                ))}
              </select> */}
              {this.state.errors.state_slug ? (
                <div className="text-danger">
                  {this.state.errors.state_slug}
                </div>
              ) : (
                this.validator.message(
                  "state",
                  this.state.state_slug,
                  "required",
                  { className: "text-danger" }
                )
              )}
            </InputGroup>

            <InputGroup className="mt-3">
              <label>
                County <span className="optional">(Optional)</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newCountyList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCountyList(value)}
                  defaultValue={this.state.selectedCountyList}
                  value={this.state.selectedCountyList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="county_slug"
                value={this.state.county_slug}
              >
                <option key="" value="">
                  Select County
                </option>
                {this.state.countyList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.county_name}
                  </option>
                ))}
              </select> */}
            </InputGroup>
            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
             //   placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditCityModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateCity}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete City popup modal */}
        <Modal
          show={this.state.deleteCityModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteCityModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteCityModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteCityModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCity(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default City;
