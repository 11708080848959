import React from "react";
import { Button } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import { Form } from "react-bootstrap";
import ThemeService from "../../services/ThemeServices";
import ThemeServices from "../../services/ThemeServices";

class ThemeGeneralSectionSetup extends React.Component {
  constructor(props) {

    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      showSpinner: false,
      loading: false,
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      brandslug: this.props.brandSlug,
      property_slug: this.props.propertySlug,
      pathname: window.location.pathname,
      page_id: this.props.page_slug ? this.props.page_slug : "",
      section_id: this.props.section_slug ? this.props.section_slug : "",
      detailSlug: "",
      pageList: [],
      pageListTotal: 0,
      selectedPageList: [],
      sectionList: [],
      sectionListTotal: 0,
      selectedSectionList: [],
      //for cms data
      cmsPageNameList: [],
      cmsPageNameListTotal: 0,
      reference_img: "",
      displayRefImg: false,
      cmsGenerateData: [],
      cmsGeneralDataSlug: "",
      cmsGenerateDataTotal: 0,
    };
    this.updateCmsPages = this.updateCmsPages.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleColorSelection = this.handleColorSelection.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.changeHandlerImage = this.changeHandlerImage.bind(this);
    this.changeHandlerSectionImage = this.changeHandlerSectionImage.bind(this);
    this.backToPages = this.backToPages.bind(this);
  }

  componentDidMount() {
    this.getCmsGeneralData();
  }

  async getCmsGeneralData() {
    this.setState({ showSpinner: true, loading: true });
    var cmsGenerateData = [];
    var cmsGenerateDataTotal = 0;
    var cmsGeneralDataSlug = "";
    var resData = {};
    let res = "";
    if (this.props.level === 3) {
      res = await ThemeServices.getCmsGeneralData(
        "property_slug=" +
          this.props.propertySlug +
          "&refid=" +
          this.props.level
      );
    } else if (this.props.level === 2) {
      res = await ThemeService.getCmsGeneralData(
        "brand_slug=" +
          this.props.brandSlug +
          "&refid=" +
          this.props.level
      );
    } else {

      res = await ThemeService.getCmsGeneralData(
          "refid=" +
          this.props.level
      );
    }
    if (global.successStatus.includes(res.status)) {
      cmsGenerateData = res.data && res.data.general_data ? res.data.general_data : [];
      cmsGeneralDataSlug = res.data && res.data.slug ? res.data.slug : [];
      cmsGenerateDataTotal = cmsGenerateData ? cmsGenerateData.length : 0;
    }
    this.setState(resData);
    this.setState({
      cmsGenerateData: cmsGenerateData,
      cmsGeneralDataSlug: cmsGeneralDataSlug,
      cmsGenerateDataTotal: cmsGenerateDataTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  updateCmsPages = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        general_data_slug: this.state.cmsGeneralDataSlug,
        general_data: this.state.cmsGenerateData,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await ThemeService.updateCmsGeneralDetails(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });

        this.getCmsGeneralData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  handleColorSelection = (color) => {
    this.setState({ isColorPicker: false });
  };

  setColorPicker = () => {
    this.setState({ isColorPicker: true });
  };

  handleChangeComplete = (color) => {
    this.setState({ backgroundColor: color.hex });
  };

  changeHandler = (event, index, index1 = "") => {
    let cmsGenerateData = this.state.cmsGenerateData;
    cmsGenerateData.LINK.[event.target.name] = event.target.value;
    this.setState({ cmsPageNameList: cmsGenerateData });
  }

  changeHandlerImage = async (event) => {
    //api call for image upload and set in state
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true, isSubmit: true });
      const fileData = new FormData();
      fileData.append(
        "cms_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("resizeOptions", "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200");
      fileData.append("mediaType", "1");
      let res = await ThemeService.uploadCmsImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          backgroundImage: res.data.filePath,
        });
        this.setState({ showSpinner: false, isSubmit: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          isSubmit: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          isSubmit: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  changeHandlerSectionImage = async (event, index, index1 = "") => {
    //api call for image upload and set in state
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true, isSubmit: true });
      const fileData = new FormData();
      fileData.append(
        "cms_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("resizeOptions", "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200");
      fileData.append("mediaType", "1");
      let res = await ThemeService.uploadCmsImage(fileData);
      if (global.successStatus.includes(res.status)) {
       
        let cmsPageNameList1 = this.state.cmsPageNameList;
        if (index1 !== "") {
          cmsPageNameList1[index][index1]["url"] = res.data.filePath;
        } else {
          cmsPageNameList1[index]["url"] = res.data.filePath;
        }
        this.setState({ cmsPageNameList: cmsPageNameList1 });

        this.setState({ showSpinner: false, isSubmit: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          isSubmit: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          isSubmit: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  handleChangePageList(value) {
    this.setState({ section_id: "", selectedSectionList: [], cmsPageNameList: [], cmsPageNameListTotal: 0, backgroundColor: "", backgroundImage: "", detailSlug: "" });
    let page_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        page_id.push(item.value);
      });
    }
    if (value !== null) {
      this.getcmsPageSectionDropdown(value.value);
    }
    const city_id_value = value !== null ? value.value : [];
    this.setState({
      selectedPageList: value,
      page_id: city_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeSectionList(value) {
    let section_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        section_id.push(item.value);
      });
    }
    this.setState({ reference_img: value.reference_img });
    if (value !== null) {
      this.getCmsGeneralData('', value.value);
    }
    const city_id_value = value !== null ? value.value : [];
    this.setState({
      selectedSectionList: value,
      section_id: city_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  backToPages() {
    var redirectBtn = document.getElementById("left-tabs-example-tab-PageSection");
    redirectBtn.click();
  }

  render() {
   
    return (
      <>
        <div className="row dashboardRightcard background-none row-xs mg-t-15 pd-t-0 swipersectionBtns">
          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
           
            {global.userPermissions.checkPermission('brand-cms-update') &&
              <Button
                className="btn-success-outline-small mr-3 button-width"
                onClick={this.updateCmsPages}
              >
                {this.state.isSubmit ? global.loader : "Update"}
              </Button>
            }
          </div>
          <div className="col-md-12">
            <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 mg-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>

            {/* new content */}

            <form id="listing_form">
              <div className="m-0">
                <div className="row align-items-start pd-b-5 border-bottom-0 mg-t-15 pd-lg-l-0 pd-xs-l-15">
                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 d-none">
                    <h3 className="mg-l-0 background-head">Social</h3>
                  </div>
                  <div className="col-lg-10 pd-b-5 border-bottom-0 mg-t-0 pd-lg-r-50 pd-xs-r-0 pd-l-0 h-100vh">
                    <div className="cmsleftSection d-flex flex-wrap">

                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Facebook URL
                        </label>

                        <Form.Control
                          type="text"
                          onChange={this.changeHandler}
                          name="facebook_url"
                          value={this.state.cmsGenerateData.LINK ? this.state.cmsGenerateData.LINK.facebook_url : ""}

                        />
                        {this.state.errors.facebook_url ? (
                          <div className="text-danger">
                            {this.state.errors.facebook_url}
                          </div>
                        ) : (
                          this.validator.message(
                            "facebook_url",
                            this.state.facebook_url,
                            "max:50",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Twitter URL
                        </label>

                        <Form.Control
                          type="text"
                          onChange={this.changeHandler}
                          name="twitter_url"
                          value={this.state.cmsGenerateData.LINK ? this.state.cmsGenerateData.LINK.twitter_url : ""}

                        />
                        {this.state.errors.twitter_url ? (
                          <div className="text-danger">
                            {this.state.errors.twitter_url}
                          </div>
                        ) : (
                          this.validator.message(
                            "twitter_url",
                            this.state.twitter_url,
                            "max:50",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Instagram URL
                        </label>

                        <Form.Control
                          type="text"
                          onChange={this.changeHandler}
                          name="instagram_url"
                          value={this.state.cmsGenerateData.LINK ? this.state.cmsGenerateData.LINK.instagram_url : ""}

                        />
                        {this.state.errors.instagram_url ? (
                          <div className="text-danger">
                            {this.state.errors.instagram_url}
                          </div>
                        ) : (
                          this.validator.message(
                            "instagram_url",
                            this.state.instagram_url,
                            "max:50",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Linkedin URL
                        </label>

                        <Form.Control
                          type="text"
                          onChange={this.changeHandler}
                          name="linkedin_url"
                          value={this.state.cmsGenerateData.LINK ? this.state.cmsGenerateData.LINK.linkedin_url : ""}

                        />
                        {this.state.errors.linkedin_url ? (
                          <div className="text-danger">
                            {this.state.errors.linkedin_url}
                          </div>
                        ) : (
                          this.validator.message(
                            "linkedin_url",
                            this.state.linkedin_url,
                            "max:50",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Printerest URL
                        </label>

                        <Form.Control
                          type="text"
                          onChange={this.changeHandler}
                          name="printerest_url"
                          value={this.state.cmsGenerateData.LINK ? this.state.cmsGenerateData.LINK.printerest_url : ""}

                        />
                        {this.state.errors.printerest_url ? (
                          <div className="text-danger">
                            {this.state.errors.printerest_url}
                          </div>
                        ) : (
                          this.validator.message(
                            "printerest_url",
                            this.state.printerest_url,
                            "max:50",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Youtube URL
                        </label>

                        <Form.Control
                          type="text"
                          onChange={this.changeHandler}
                          name="youtube_url"
                          value={this.state.cmsGenerateData.LINK ? this.state.cmsGenerateData.LINK.youtube_url : ""}

                        />
                        {this.state.errors.youtube_url ? (
                          <div className="text-danger">
                            {this.state.errors.youtube_url}
                          </div>
                        ) : (
                          this.validator.message(
                            "youtube_url",
                            this.state.youtube_url,
                            "max:50",
                            { className: "text-danger" }
                          )
                        )}
                      </div>

                    </div>
                  </div>

                  <div className="col-lg-4  pd-0">
                   
                  </div>

                </div>

              </div>
            </form>



          </div>
        </div>

      </>
    );
  }
}

export default ThemeGeneralSectionSetup