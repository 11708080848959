///////////////////////////////////////////////////////////////
//  Program: MonthlyInvestorReport.jsx                       //
//  Application: Report                                      //
//  Option: Monthly Investor Report                          //
//  Developer: NP 		                                       //
//  Date: 2024-12-26                                         //
//                                                           //
///////////////////////////////////////////////////////////////

import React, { useState, useEffect } from "react";
import { Container, Modal, InputGroup, Form } from "react-bootstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import ReportSidebar from "../common/ReportSidebar";
import ReportService from "../../services/ReportService";
import Moment from "moment";
// import Select from "react-select";
import DatePicker from "react-datepicker";
// import UnitsService from "../../services/UnitsService";
import MultiImageService from "../../services/MultiImageService";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyReportSidebar from "../common/AllPropertyReportSidebar";

const MonthlyInvestorReport = (props) => {
  const [state, setState] = useState({
    input: {},
    pathname: window.location.pathname,
    property_slug: props.match.params.propertySlug,
    isSubmit: false,
    module_search: "",
    module_search_display: "",
    exportMesssage: "",
    exportFilePath: "",
    exportResult: "",
    alertType: "xlsx",
    is_search: 0,
    records: [],
    config: {
      sort: {
        column: "",
        order: "",
      },
      key_column: "slug",
      page_size: global.page_size,
      length_menu: global.page_length_menu,
      show_length_menu: true,
      show_filter: global.show_filter,
      show_pagination: global.show_pagination,
      pagination: "advance",
      button: {
        excel: global.excel_button,
        print: global.print_button,
        extra: false,
      },
      language: {
        no_data_text: "No data found",
      },
    },

    extraButtons: [],
    columns: [
      {
        key: "property_name",
        text: "Property Name",
        className: "property_name text-center td_clickable",
        sortable: true,
        cell: (record) => {
          if (record.property_name) {
            return record.property_name;
          } else {
            return "";
          }
        },
      },
      {
        key: "property_address",
        text: "Property Address",
        className: "property_address text-center td_clickable",
        sortable: true,
        cell: (record) => {
          if (record.property_address) {
            return record.property_address;
          } else {
            return "";
          }
        },
      },
      // {
      //   key: "status",
      //   text: "Status",
      //   className: "status text-center td_clickable",
      //   sortable: true,
      //   cell: (record) => {
      //     if (record.status) {
      //       return record.status;
      //     } else {
      //       return "";
      //     }
      //   },
      // },
      {
        key: "units",
        text: "Units",
        className: "units text-center td_clickable",
        sortable: true,
        cell: (record) => {
          if (record.unit_count) {
            return record.unit_count;
          } else {
            return "";
          }
        },
      },
      {
        key: "listing_count",
        text: "Listing Count",
        className: "listing_count text-center td_clickable",
        sortable: true,
        cell: (record) => {
          if (record.listing_count) {
            return record.listing_count;
          } else {
            return "";
          }
        },
      },
      // {
      //   key: "resident_interest",
      //   text: "Resident Interest",
      //   className: "resident_interest text-center td_clickable",
      //   sortable: true,
      //   cell: (record) => {
      //     if (record.unit_number) {
      //       return record.resident_interest;
      //     } else {
      //       return "";
      //     }
      //   },
      // },
      // {
      //   key: "agreements_executed",
      //   text: "Agreements Executed",
      //   className: "agreements_executed text-center td_clickable",
      //   sortable: true,
      //   cell: (record) => {
      //     if (record.unit_number) {
      //       return record.agreements_executed;
      //     } else {
      //       return "";
      //     }
      //   },
      // },
      // {
      //   key: "pending_agreements",
      //   text: "Pending Agreements",
      //   className: "pending_agreements text-center td_clickable",
      //   sortable: true,
      //   cell: (record) => {
      //     if (record.unit_number) {
      //       return record.pending_agreements;
      //     } else {
      //       return "";
      //     }
      //   },
      // },
      // {
      //   key: "resident_conversion_rate",
      //   text: "Resident Conversion Rate",
      //   className: "resident_conversion_rate text-center td_clickable",
      //   sortable: true,
      //   cell: (record) => {
      //     if (record.unit_number) {
      //       return record.resident_conversion_rate;
      //     } else {
      //       return "";
      //     }
      //   },
      // },
      // {
      //   key: "days_to_conversion",
      //   text: "Days to Conversion",
      //   className: "days_to_conversion text-center td_clickable",
      //   sortable: true,
      //   cell: (record) => {
      //     if (record.unit_number) {
      //       return record.days_to_conversion;
      //     } else {
      //       return "";
      //     }
      //   },
      // },
      // {
      //   key: "first_booking_date",
      //   text: "First Booking Days",
      //   className: "first_booking_date text-center td_clickable",
      //   sortable: true,
      //   cell: (record) => {
      //     if (record.unit_number) {
      //       return record.first_booking_date;
      //     } else {
      //       return "";
      //     }
      //   },
      // },
      // {
      //   key: "first_stay_days",
      //   text: "First Stay Days",
      //   className: "first_stay_days text-center td_clickable",
      //   sortable: true,
      //   cell: (record) => {
      //     if (record.unit_number) {
      //       return record.first_stay_days;
      //     } else {
      //       return "";
      //     }
      //   },
      // },
      // {
      //   key: "avg_revenue_door",
      //   text: "Avg. Revenue/Door",
      //   className: "avg_revenue_door text-center td_clickable",
      //   sortable: true,
      //   cell: (record) => {
      //     if (record.unit_number) {
      //       return record.avg_revenue_door;
      //     } else {
      //       return "";
      //     }
      //   },
      // },
      {
        key: "avg_adr",
        text: "Avg. ADR",
        className: "avg_adr text-center td_clickable",
        sortable: true,
        cell: (record) => {
          if (record.avg_adr) {
            return "$" + record.avg_adr;
          } else {
            return "";
          }
        },
      },
      {
        key: "avg_occupancy",
        text: "Avg. Occupancy",
        className: "avg_occupancy text-center td_clickable",
        sortable: true,
        cell: (record) => {
          if (record.avg_occupancy) {
            return record.avg_occupancy;
          } else {
            return "";
          }
        },
      },
      {
        key: "nights_sold_vs_available",
        text: "Nights Sold vs. Available",
        className: "nights_sold_vs_available text-center td_clickable",
        sortable: true,
        cell: (record) => {
          if (record.nights_sold_vs_available) {
            return record.nights_sold_vs_available;
          } else {
            return "";
          }
        },
      },
    ],
    unitList: [],
    newUnitList: [],
    selectedUnitList: [],
    start_date: new Date(),
    end_date: new Date(),
  });

  useEffect(() => {
    if (props.match.params.propertySlug !== undefined) {
      getEarningsReports();
      gerDefaultInOutDate();
    }
  }, [props.match.params.propertySlug]);

  const clearFilter = async () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setState((prevState) => ({
      ...prevState,
      is_search: 0,
      start_date: firstDay,
      end_date: lastDay,
      unit_slug: "",
      selectedUnitList: [],
      module_search: "",
    }));
    await removeTotalClass();
    getEarningsReports();
  };

  const getPropertySlug = (property_slug) => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        property_slug: property_slug,
        selectedUnitList: [],
        module_search: [],
        is_search: 0,
      }));
      getEarningsReports(property_slug);
      gerDefaultInOutDate();
    }, 100);
  };

  const gerDefaultInOutDate = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setState((prevState) => ({
      ...prevState,
      start_date: firstDay,
      end_date: lastDay,
    }));
  };

  // const getUnitData = async (queryString = "") => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     showSpinner: true,
  //     loading: true,
  //   }));
  //   var unitList = [];
  //   var unitListTotal = 0;

  //   let res = await UnitsService.getUnitsList(
  //     "is_dropdown=1&property_slug=" +
  //       (state.property_slug ? state.property_slug : ""),
  //     queryString ? "&" + queryString : ""
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     unitList = res.data ? res.data : [];
  //     unitListTotal = unitList ? unitList.length : 0;
  //   }
  //   const newUnitList =
  //     unitListTotal > 0
  //       ? unitList.map(({ slug, unit_name, tower, total_tower_count }) => ({
  //           value: slug,
  //           label: unit_name,
  //         }))
  //       : [];
  //   setState((prevState) => ({
  //     ...prevState,
  //     unitList: unitList,
  //     unitListTotal: unitListTotal,
  //     newUnitList: newUnitList,
  //     showSpinner: false,
  //     loading: false,
  //   }));
  // };

  const searchToggle = () => {
    if (state.isSearch === 1) {
      setState((prevState) => ({ ...prevState, isSearch: 0 }));
    } else {
      // getUnitData();
      setState((prevState) => ({ ...prevState, isSearch: 1 }));
    }
  };

  const exportToggle = () => {
    if (state.isModel === 1) {
      setState((prevState) => ({
        ...prevState,
        exportMesssage: "",
        isModel: 0,
        showSpinner: false,
        isSubmit: false,
      }));
    } else {
      setState((prevState) => ({ ...prevState, isModel: 1 }));
    }
  };

  const ExportData = async () => {
    setState((prevState) => ({
      ...prevState,
      showSpinner: true,
      isSubmit: true,
    }));
    let data = {
      property_slug: state.property_slug,
    };
    let leaseStart = state.start_date
      ? Moment(state.start_date).format("YYYY-MM-DD")
      : "";
    let leaseEnd = state.end_date
      ? Moment(state.end_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "property_slug=" +
      (state.property_slug ? state.property_slug : "") +
      "&is_export=1" +
      "&file_type=" +
      state.alertType +
      "&start_date=" +
      leaseStart +
      "&end_date=" +
      leaseEnd;
    let res = await ReportService.getMonthlyInvestorReports(queryString);
    if (res.data.filePath) {
      getDownloadFile(res.data.filePath, res.data.fileName);
      setState((prevState) => ({
        ...prevState,
        exportMesssage: res.message,
        exportResult: data,
        exportFilePath: res.data.filePath,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      showSpinner: false,
      isSubmit: false,
    }));
  };

  const getDownloadFile = async (url, name) => {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    setState((prevState) => ({
      ...prevState,
      showSpinner: true,
      loading: true,
    }));
    let inputData = {};
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    setState((prevState) => ({
      ...prevState,
      ...resData,
      showSpinner: false,
      loading: false,
    }));
  };

  const changeHandler = (event) => {
    let input = state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: value,
      input: input,
    }));
  };

  const changeExport = (evt) => {
    let value = evt.target.value;
    setState((prevState) => ({ ...prevState, alertType: value }));
  };

  const filterData = async () => {
    let leaseStart = state.start_date
      ? Moment(state.start_date).format("YYYY-MM-DD")
      : "";
    let leaseStartSearch = state.start_date
      ? Moment(state.start_date).format("MM/DD/YY")
      : "";
    let leaseEnd = state.end_date
      ? Moment(state.end_date).format("YYYY-MM-DD")
      : "";
    let leaseEndSearch = state.end_date
      ? Moment(state.end_date).format("MM/DD/YY")
      : "";
    let queryString =
      "&start_date=" + leaseStart + "&end_date=" + leaseEnd + "&is_search=1";

    let moduleSearchString = "";
    let moduleSearchDisplay = "";
    moduleSearchString = state.start_date ? "Start Date - " + leaseStart : "";
    moduleSearchString = state.end_date
      ? moduleSearchString
        ? moduleSearchString + " ," + " End Date - " + leaseEnd
        : " End Date - " + state.end_date
      : moduleSearchString;

    moduleSearchDisplay = state.start_date
      ? "Start Date - " + leaseStartSearch
      : "";
    moduleSearchDisplay = state.end_date
      ? moduleSearchDisplay
        ? moduleSearchDisplay + ", " + " End Date - " + leaseEndSearch
        : " End Date - " + state.end_date
      : moduleSearchDisplay;

    if (state.start_date || state.end_date || state.unit_slug) {
      setState((prevState) => ({
        ...prevState,
        customQueryString: queryString,
        is_search: 1,
        module_search: moduleSearchString,
        module_search_display: moduleSearchDisplay.split(","),
      }));
      searchToggle();
      await removeTotalClass();
      getEarningsReports(state.property_slug, queryString);
    }
  };

  // const handleChangeUnitList = (value) => {
  //   let unit_slug = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       unit_slug.push(item.value);
  //     });
  //   }
  //   const unit_slug_value = value !== null ? value.value : [];
  //   const selected_unit_number = value !== null ? value.label : [];
  //   setState((prevState) => ({
  //     ...prevState,
  //     selectedUnitList: value,
  //     unit_slug: unit_slug_value,
  //     unit_lable: selected_unit_number,
  //     confirm_back: 1,
  //   }));
  // };

  const getEarningsReports = async (
    property_slug = state.property_slug,
    queryString = ""
  ) => {
    setState((prevState) => ({
      ...prevState,
      showSpinner: true,
      loading: true,
    }));

    let list = [];
    let totalRecords = 0;

    let res = await ReportService.getMonthlyInvestorReports(
      (property_slug ? `property_slug=${property_slug}` : "") +
        (queryString ? `&${queryString}` : "")
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      totalRecords = list.length;
    }

    setState((prevState) => ({
      ...prevState,
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    }));

    addTotalClass();
  };

  const addTotalClass = () => {
    if (document.getElementsByClassName("grand_total1").length > 0) {
      const grand_total = document.getElementsByClassName("grand_total1")[0];
      let ele1 = grand_total.parentElement.parentElement;
      ele1.classList.add("grand_total");
    }
  };

  const removeTotalClass = async () => {
    if (document.getElementsByClassName("grand_total1").length > 0) {
      const grand_total = document.getElementsByClassName("grand_total1")[0];
      let ele1 = grand_total.parentElement.parentElement;
      ele1.classList.remove("grand_total");
    }
  };

  const tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      setState((prevState) => ({
        ...prevState,
        tableQueryString: queryString,
      }));
    } else {
      queryString += "&" + state.customQueryString;
      setState((prevState) => ({
        ...prevState,
        tableQueryString: queryString,
      }));
    }

    if (data.filter_value !== state.filter_value) {
      clearTimeout(state.filter_time);
      setState((prevState) => ({
        ...prevState,
        filter_time: setTimeout(
          () => getEarningsReports(state.property_slug, queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      }));
    } else {
      getEarningsReports(state.property_slug, queryString);
    }
  };

  const rowClickedHandler = (event, data, rowIndex) => {
    // this.props.history.push("units/" + data.slug + "/unit-basic");
  };

  // const customStyles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     color:
  //       state.isSelected && state.isFocused
  //         ? "white"
  //         : state.isSelected
  //         ? "black"
  //         : state.isFocused && "white",
  //     backgroundColor:
  //       state.isSelected && state.isFocused
  //         ? "black"
  //         : state.isFocused && "black",
  //   }),
  // };

  return (
    <>
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <ReportSidebar property_slug={state.property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyReportSidebar
                      property_slug={state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>Monthly Investor</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      <button
                        className="btn-success-outline-small mr-3"
                        onClick={searchToggle}
                      >
                        Search
                      </button>
                      {global.userPermissions.checkPermission(
                        "monthly-investor-report-export"
                      ) && (
                        <button
                          className="btn-success-outline-small mr-0"
                          onClick={exportToggle}
                        >
                          Export
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {props.match.params.propertySlug === undefined ? (
                  <>
                    <div className="row pd-lg-l-15 pd-xs-l-15">
                      <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                        <OutSidePropertySelectBox
                          propertySlug={getPropertySlug}
                          outSidePropertySlug={state.outSidePropertySlug}
                        />
                      </div>
                      <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                        {state.is_search === 1 ? (
                          <>
                            <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                              <div className="custm-filter">
                                <div>
                                  <label className="form-label text-left pd-b-2 mb-0">
                                    Search Applied On
                                  </label>
                                </div>
                                <div className="filter_bg_cust">
                                  {state.module_search_display.map(
                                    (item, i) => {
                                      return <span key={i}>{item}</span>;
                                    }
                                  )}
                                  <button
                                    onClick={clearFilter}
                                    className="btn-fliter-clear"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                            <div className="custm-filter">
                              <div>
                                <label className="form-label text-left pd-b-2 mb-0">
                                  Search Applied On
                                </label>
                              </div>
                              <div className="filter_bg_cust">
                                <span>
                                  {`Start Date -  ${Moment(
                                    state.start_date
                                  ).format("MM/DD/YY")} `}
                                </span>
                                <span>
                                  {` End Date - ${Moment(state.end_date).format(
                                    "MM/DD/YY"
                                  )}`}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-xxl-12 pd-r-15 pd-l-15">
                    {state.is_search === 1 ? (
                      <>
                        <div className="col-xxl-12 col-sm-12 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                          <div className="custm-filter">
                            <div>
                              <label className="form-label text-left pd-b-2 mb-0">
                                Search Applied On
                              </label>
                            </div>
                            <div className="filter_bg_cust">
                              {state.module_search_display.map((item, i) => {
                                return <span key={i}>{item}</span>;
                              })}
                              <button
                                onClick={clearFilter}
                                className="btn-fliter-clear"
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-xxl-12 col-sm-12 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                        <div className="custm-filter">
                          <div>
                            <label className="form-label text-left pd-b-2 mb-0">
                              Search Applied On
                            </label>
                          </div>
                          <div className="filter_bg_cust">
                            <span>
                              {`Start Date -  ${Moment(state.start_date).format(
                                "MM/DD/YY"
                              )} `}
                            </span>
                            <span>
                              {` End Date - ${Moment(state.end_date).format(
                                "MM/DD/YY"
                              )}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-lg-l-0 pd-lg-r-15 pd-xs-r-0">
                  <div className="scrolling-carousel pd-l-15 scroll-slide">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="mg-t-18 brandList pd-l-15 pd-r-15 Report_table  earning-report-tbl ">
                        <div className="pd-lg-l-0 pd-xs-l-0">
                          <div className="scroll-report-table">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2 brandtable  earning_reports border table_last_row"
                              config={
                                state.total_record > global.page_size
                                  ? state.config
                                  : state.config
                              }
                              records={state.records}
                              columns={state.columns}
                              extraButtons={state.extraButtons}
                              dynamic={true}
                              total_record={state.total_record}
                              onChange={tableChangeHandler}
                              onRowClicked={rowClickedHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>

      {/* Module search popup modal */}
      <Modal
        show={state.isSearch}
        className="modal-large searchModalbox"
        centered
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            filterData();
          }
        }}
      >
        <Modal.Body className="width_402 p-4 border_radius_14">
          <span className="welcomeClose" onClick={() => searchToggle()}>
            {global.closee}
          </span>
          <h3 className="text-center">
            Search in Homesharing Income For Building Owner
          </h3>
          <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
            <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
              <InputGroup className="mt-3">
                <label>Start Date</label>
                <DatePicker
                  className="form-control max_width_100"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  disabledKeyboardNavigation
                  strictParsing
                  autoComplete="off"
                  yearDropdownItemNumber={global.yearDropdownItemNumber}
                  name="start_date"
                  selected={state.start_date}
                  dateFormat="MM/dd/yy"
                  onChange={(data) =>
                    changeHandler({
                      target: {
                        type: "date",
                        name: "start_date",
                        value: data,
                      },
                    })
                  }
                />
              </InputGroup>

              {/* <InputGroup className="mt-3">
                <label>Unit</label>
                <div className="col-md-12 p-0">
                  <Select
                    styles={customStyles}
                    isClearable={true}
                    className="multiselect"
                    menuPlacement="auto"
                    value={state.selectedUnitList}
                    options={state.newUnitList}
                    onChange={(value) => handleChangeUnitList(value)}
                    defaultValue={state.selectedUnitList}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 2,
                      colors: {
                        ...theme.colors,
                        primary: "#fff",
                        primary75: "#000",
                        primary50: "#000",
                        primary25: "#000",
                      },
                    })}
                  />
                </div>
              </InputGroup> */}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
              <InputGroup className="mt-3">
                <label>End Date</label>
                <div className="col-md-12 p-0">
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="end_date"
                    selected={state.end_date}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      changeHandler({
                        target: {
                          type: "date",
                          name: "end_date",
                          value: data,
                        },
                      })
                    }
                  />
                </div>
              </InputGroup>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3 row">
            <button
              type="button"
              className="m-0 btn btn-outline-light btn-block"
              onClick={() => searchToggle()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="m-0 btn btn btn-success btn-block"
              onClick={filterData}
              disabled={state.isSubmit ? true : false}
            >
              {state.isSubmit ? global.loader : "Search"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* Module Export  popup modal */}
      <Modal show={state.isModel} className="modal-xs" centered>
        <Modal.Body className="width_402 p-4 border_radius_14">
          <span className="welcomeClose" onClick={() => exportToggle()}>
            {global.closee}
          </span>
          <h3 className="text-center">Export</h3>
          <div className="row col-12 m-0">
            <div className="col-lg-12 col-md-12 col-sm-12 p-0">
              <InputGroup className="mt-0">
                <label>Select Export File Type : </label>
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                    <Form.Check
                      onChange={(e) => changeExport(e)}
                      name="alert_type"
                      value="xlsx"
                      checked={state.alertType === "xlsx" ? true : false}
                      type={"radio"}
                      id="xlsx"
                      label={"XLSX"}
                      className={"rdiobox mg-r-20"}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                    <Form.Check
                      onChange={(e) => changeExport(e)}
                      name="alert_type"
                      value="csv"
                      checked={state.alertType === "csv" ? true : false}
                      type={"radio"}
                      id="csv"
                      label={"CSV"}
                      className={"rdiobox mg-r-20"}
                    />
                  </div>
                </div>
              </InputGroup>

              {state.exportMesssage !== "" && (
                <div>
                  {state.exportMesssage}
                  <br></br>
                  <br></br>
                </div>
              )}
              <div className="d-flex justify-content-center mt-3">
                <button
                  type="button"
                  className="mx-auto- mg-r-30 btn btn btn-success btn-block"
                  onClick={() => ExportData()}
                  disabled={state.isSubmit ? true : false}
                >
                  {state.isSubmit ? global.loader : "Export"}
                </button>
                <button
                  type="button"
                  className="mx-auto- m-0  btn btn-outline-light btn-block"
                  onClick={() => exportToggle()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MonthlyInvestorReport;
