////////////////////////////////////////////////////////////
//     							                          //
//  Program: Applicants.jsx                                    //
//  Application: influencers                                    //
//  Option: List all influencers                                //
//  Developer: Ashish Kumar 						              //
//  Date: 2022-02-09                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import CrmSettingSidebar from "../common/CrmSettingSidebar";
import { Container, Modal, InputGroup, Form } from "react-bootstrap";
import SubscribersService from "../../services/SubscribersService";
import Alerts from "../common/Alerts";
import Moment from "moment";
import queryString from "query-string";
import filterIcon from "./../../assets/images/filter-gray.svg";

let queryParams = queryString.parse(window.location.search);

export class CrmContact extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      open: true,
      eventKey: "0",
      is_upcoming: false,
      start_date: "",
      end_date: "",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      alertType: "xlsx",
      total_record: 0,
      property_slug: this.props.match.params.propertySlug,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "first_name",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "first_name",
          text: "First Name",
          className: "first_name hand-cursor td_clickable",
          sortable: false,
          cell: (record) => {
            return record.first_name ? record.first_name : "";
          },
        },
        {
          key: "last_name",
          text: "Last Name",
          className: "last_name hand-cursor td_clickable",
          sortable: false,
          cell: (record) => {
            return record.last_name ? record.last_name : "";
          },
        },
        {
          key: "email",
          text: "Email",
          className: "email hand-cursor td_clickable text-left",
          sortable: false,
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        {
          key: "created_at",
          text: "Inquiry at",
          className: "created_at hand-cursor td_clickable text-left date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.createdAt
              ? Moment(Moment.utc(record.createdAt, "YYYY-MM-DDTHH:mm:ss"))
                  .local()
                .format(global.dateTimeFormat
                )
              : "";
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.getContactusData = this.getContactusData.bind(this);
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }

  async ExportData() {
    let data = {
      property_slug: this.state.property_slug,
      file_type: this.state.alertType,
      where_column: this.state.customQueryString,
    };
    let res = await SubscribersService.exportDataFilewiseContact(data);
    this.setState({ exportMesssage: res.message });
    this.setState({ exportResult: data });
    this.setState({ exportFilePath: res.data.filePath });
  }
  filterData() {
    let queryString =
      "first_name=" +
      (this.state.first_name ? this.state.first_name : "") +
      "&last_name=" +
      (this.state.last_name ? this.state.last_name : "") +
      "&email=" +
      (this.state.email ? this.state.email : "") +
      "&status_id=" +
      (this.state.status_id ? this.state.status_id : "") +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.first_name
      ? "First Name - " + this.state.first_name
      : "";

    moduleSearchString = this.state.last_name
      ? moduleSearchString
        ? moduleSearchString + " ," + "Last Name - " + this.state.last_name
        : "Last Name - " + this.state.last_name
      : moduleSearchString;

    moduleSearchString = this.state.email
      ? moduleSearchString
        ? moduleSearchString + " ," + " Email - " + this.state.email
        : " Email - " + this.state.email
      : moduleSearchString;

    if (this.state.first_name || this.state.last_name || this.state.email) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getContactusData(queryString);
    }
  }
  exportToggle = () => {
    if (this.state.isModel === 1) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: 0 });
    } else {
      this.setState({ isModel: 1 });
    }
  };
  componentDidMount() {
    if (queryParams.isGlobal === "1") {
      this.getContactusData(
        "is_search=1&" +
          queryParams.global_col_name +
          "=" +
          queryParams.global_search
      );
    } else {
      this.getContactusData();
    }
  }

  async getContactusData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;

    let res = await SubscribersService.getContactus(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      this.setState({ records: [] });
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
  }

  resetDate() {
    this.setState({ is_upcoming: false, start_date: "", end_date: "" });
    this.getContactusData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getContactusData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getContactusData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Website Inquiry";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <CrmSettingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </aside>
                  </div>
                </div>

                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex">
                        <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small ml-3"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button>
                        <button
                          onClick={this.exportToggle}
                          className="btn-success-outline-small ml-3"
                        >
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.state.is_search === 1 ? (
                    <>
                      <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                        <div className="custm-filter">
                          <div>
                            {" "}
                            <label className="form-label text-left pd-b-2 mb-0">
                              Search Applied On
                            </label>
                          </div>
                          <div className="filter_bg_cust">
                            {this.state.module_search.map((item, i) => {
                              return <span key={i}>{item}</span>;
                            })}
                            <button
                              onClick={this.clearFilter}
                              className="btn-fliter-clear"
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="scrolling-carousel pd-l-15 pd-xs-l-0 pd-lg-l-45 pd-xs-r-0 pd-lg-r-0">
                    <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      {queryParams.isGlobal === "1" ? (
                        <>
                          <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                            {"Search applied on: " +
                              queryParams.global_col_label +
                              " - " +
                              queryParams.global_search}
                            <button
                              onClick={this.clearFilter}
                              className="mg-l-15 btn-success-outline-small"
                            >
                              Clear
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {this.state.total_record > 0 ? (
                        <div className="mg-t-18 brandList p-0">
                          <div className="pd-lg-l-0 pd-xs-l-15">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              // loading={this.state.loading}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              //nRowClicked={this.rowClickedHandler}
                            />
                          </div>
                        </div>
                      ) : (
                        // <NoData msg="applicants" />
                        <div className="mg-t-30 text-center">
                          <p>No data found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.searchToggle();
          }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Inquiry</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>First Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="first_name"
                      value={this.state.first_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      placeholder="First Name"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Last Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="last_name"
                      value={this.state.last_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      placeholder="Last Name"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Email</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="email"
                      value={this.state.email}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      placeholder="Email"
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Export  popup modal */}
        <Modal show={this.state.isModel} className="modal-xs" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.exportToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Export</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <InputGroup className="mt-0">
                  <label>Select Export File Type : </label>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="xlsx"
                        checked={this.state.alertType === "xlsx" ? true : false}
                        type={"radio"}
                        id="xlsx"
                        label={"XLSX"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="csv"
                        checked={this.state.alertType === "csv" ? true : false}
                        type={"radio"}
                        id="csv"
                        label={"CSV"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                  </div>
                </InputGroup>

                {this.state.exportMesssage !== "" && (
                  <div>
                    {this.state.exportMesssage}
                    <br></br>
                    <br></br>
                    {this.state.exportFilePath !== undefined && (
                      <a href={this.state.exportFilePath}> Download File </a>
                    )}
                  </div>
                )}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="mx-auto- mg-r-30 btn btn btn-success btn-block"
                    onClick={() => this.ExportData()}
                  >
                    {this.state.isSubmit ? global.loader : "Export"}
                  </button>
                  <button
                    type="button"
                    className="mx-auto- m-0  btn btn-outline-light btn-block"
                    onClick={() => this.exportToggle()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default CrmContact;
