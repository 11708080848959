/////////////////////////////////////////////////////
//     							                   //
//  Program: AppPropertySetting.jsx                             //
//  Application: Property                          //
//  Option: List all Property Lead Tracking Contacts info           //
//  Developer: Ashish Kumar					   //
//  Date: 2022-04-04                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import AppPropertySettingService from "../../services/AppPropertySettingService";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import MarketingSidebar from "../common/MarketingSidebar";
import Photo from "./../../assets/images/eventimg_placeholder.png";
// import MobileFrame from "./../../assets/images/mobile-frame.png";
import ReactDevicePreview from "react-device-preview";

export class AppPropertySetting extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      property_slug: this.props.match.params.propertySlug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      confirm_back: 0,
      app_property_logo: "",
      app_property_main_image: "",
      profile_cover_image: "",
      appPropertyLogoUrl: "",
      appPropertyMainImageUrl: "",
      profileCoverImageUrl: "",
      status_id: true,
    };
    this.changeView = this.changeView.bind(this);
    this.saveOrUpdateImages = this.saveOrUpdateImages.bind(this);
    this.removePhoto1 = this.removePhoto1.bind(this);
    this.removePhoto2 = this.removePhoto2.bind(this);
    this.removePhoto3 = this.removePhoto3.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    this.getData();
    this.setState({ showSpinner: false, loading: false });
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "app_property_logo",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("h1", 235);
      fileData.append("h2", 250);
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await AppPropertySettingService.uploadAppPropertyLogo(fileData);
      // console.log(res);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          app_property_logo: res.data.fileName,
          appPropertyLogoUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  onImageChange2 = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "app_property_main_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("h1", 235);
      fileData.append("h2", 250);
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await AppPropertySettingService.uploadAppPropertyMainImage(
        fileData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          app_property_main_image: res.data.fileName,
          appPropertyMainImageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  onImageChange3 = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "profile_cover_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("h1", 235);
      fileData.append("h2", 250);
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await AppPropertySettingService.uploadProfileCoverImage(
        fileData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          profile_cover_image: res.data.fileName,
          profileCoverImageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res =
        await AppPropertySettingService.getAppPropertySettingByPropertySlug(
          this.state.property_slug
        );
      // console.log(res);
      if (global.successStatus.includes(res.status)) {
        // resData = res.data ? res.data : {};
        resData.app_property_main_image = res.data
          ? res.data.app_property_main_image
          : "";
        resData.app_property_logo = res.data ? res.data.app_property_logo : "";
        resData.profile_cover_image = res.data
          ? res.data.profile_cover_image
          : "";
        resData.appPropertyLogoUrl = res.data
          ? res.data.app_property_logo_url
          : "";
        resData.appPropertyMainImageUrl = res.data
          ? res.data.app_property_main_image_url
          : "";
        resData.profileCoverImageUrl = res.data
          ? res.data.profile_cover_image_url
          : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdateImages = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug,
        app_property_logo: this.state.app_property_logo,
        app_property_main_image: this.state.app_property_main_image,
        profile_cover_image: this.state.profile_cover_image,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await AppPropertySettingService.updateAppPropertySetting(
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({ viewMode: 0 });
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
    }
  }

  getTitle() {
    return "Property App";
  }

  openWebsite() {
    var url = this.state.property_website_link
      ? this.state.property_website_link
      : "";
    window.open(url, "_blank");
  }
  removePhoto1() {
    this.setState({ staff_contact_image: "", appPropertyLogoUrl: "" });
  }
  removePhoto2() {
    this.setState({ app_property_main_image: "", appPropertyMainImageUrl: "" });
  }
  removePhoto3() {
    this.setState({ profile_cover_image: "", profileCoverImageUrl: "" });
  }

  render() {
    // const url = this.state.property_website_link ? this.state.property_website_link : ''
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <MarketingSidebar property_slug={this.state.property_slug} />
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {this.state.viewMode === 0 ? (
                        global.userPermissions.checkPermission(
                          "app-cms-update"
                        ) && (
                          <button
                            className="btn-success-outline-small"
                            onClick={this.changeView}
                          >
                            <img src={editIcon} />
                            Edit
                          </button>
                        )
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.changeView();
                                else return false;
                              else this.changeView();
                            }}
                          >
                            Cancel
                          </button>
                          {global.userPermissions.checkPermission(
                            "app-cms-update"
                          ) && (
                            <button
                              className="btn-success ml-3"
                              onClick={this.saveOrUpdateImages}
                            >
                              {" "}
                              Save
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>

                    {this.state.viewMode === 0 ? (
                      <>
                        <div className="row pd-xs-l-30 pd-xs-r-0 pd-lg-l-45  pd-lg-r-10 ">
                          <div className="col-md-4">
                            <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 mg-b-30">
                              <div className="demo-avatar-group d-flex justify-content-left align-items-start flex-column">
                                <p className="az-profile-name-text pl-0 pd-lg-b-15 pd-xs-b-15">
                                  App Property Logo
                                </p>
                                <div className="az-img-user avatar-md online mg-b-8">
                                  <figure>
                                    <b
                                      className={
                                        this.state.status_id === 1
                                          ? "status-active"
                                          : "status-inactive"
                                      }
                                    ></b>
                                    <img
                                      src={
                                        this.state.appPropertyLogoUrl
                                          ? this.state.appPropertyLogoUrl
                                          : Photo
                                      }
                                      alt=""
                                      className="rounded-circle"
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0">
                              <div className="demo-avatar-group d-flex justify-content-left align-items-start flex-column">
                                <p className="az-profile-name-text pl-0 pd-lg-b-15 pd-xs-b-15">
                                  Profile Cover Image
                                </p>
                                <div className="az-img-user avatar-md online mg-b-8">
                                  <figure>
                                    <b
                                      className={
                                        this.state.status_id === 1
                                          ? "status-active"
                                          : "status-inactive"
                                      }
                                    ></b>
                                    <img
                                      src={
                                        this.state.profileCoverImageUrl
                                          ? this.state.profileCoverImageUrl
                                          : Photo
                                      }
                                      alt="Profile Cover Image"
                                      className="rounded-circle"
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div> */}
                          </div>
                          <div className="col-md-4">
                            <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-15 pd-xs-l-0">
                              <div className="demo-avatar-group d-flex justify-content-left align-items-start flex-column">
                                <p className="az-profile-name-text pl-0 pd-lg-b-15 pd-xs-b-15">
                                  App Property Main Image
                                </p>
                                <div className="az-img-user avatar-md online mg-b-8">
                                  <figure>
                                    <b
                                      className={
                                        this.state.status_id === 1
                                          ? "status-active"
                                          : "status-inactive"
                                      }
                                    ></b>
                                    <img
                                      src={
                                        this.state.appPropertyMainImageUrl
                                          ? this.state.appPropertyMainImageUrl
                                          : Photo
                                      }
                                      alt=""
                                      className="rounded-circle"
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 pd-lg-t-0 pd-xs-t-15">
                            <ReactDevicePreview device="iphonex" scale="0.6">
                              <figure>
                                <b
                                  className={
                                    this.state.status_id === 1
                                      ? "status-active d-none"
                                      : "status-inactive d-none"
                                  }
                                ></b>
                                <img
                                  src={
                                    this.state.appPropertyLogoUrl
                                      ? this.state.appPropertyLogoUrl
                                      : Photo
                                  }
                                  alt=""
                                  className="logo-img rounded-circle"
                                />
                              </figure>
                              <b
                                className={
                                  this.state.status_id === 1
                                    ? "status-active d-none"
                                    : "status-inactive d-none"
                                }
                              ></b>
                              <img
                                src={
                                  this.state.appPropertyMainImageUrl
                                    ? this.state.appPropertyMainImageUrl
                                    : Photo
                                }
                                alt=""
                                className="background-image"
                              />
                            </ReactDevicePreview>
                            {/* <img src={MobileFrame} alt="" /> */}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.viewMode === 1 ? (
                      <div className="m-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-10">
                        <div className="row align-items-basline pd-t-0 pd-b-0 border-bottom-0">
                          <div className="col-md-4 pd-l-15 pd-r-20">
                            <div className="mg-t-0">
                              <label className="form-label text-left pd-lg-b-35 pd-xs-b-15">
                                App Property Logo{" "}
                              </label>

                              <div className="fileUpload d-flex flex-wrap justify-content-between app_file_img">
                                <span>
                                  <label className="hand-cursor">
                                    <input
                                      onChange={this.onImageChange}
                                      type="file"
                                      name="app_property_logo"
                                      tabIndex="1"
                                    />

                                    <img
                                      src={
                                        this.state.appPropertyLogoUrl
                                          ? this.state.appPropertyLogoUrl
                                          : Photo
                                      }
                                      alt="App Property Logo"
                                      className="rounded-circle"
                                    />
                                  </label>
                                </span>
                                <p
                                  className="hand-cursor remove-img-logos m-0"
                                  onClick={this.removePhoto1}
                                ></p>
                              </div>
                              {/* {this.state.errors.app_property_logo ? (
                              <div className="text-danger">
                                {this.state.errors.app_property_logo}
                              </div>
                            ) : (
                              ""
                            )} */}
                              {this.state.errors.app_property_logo ? (
                                <div className="text-danger">
                                  {this.state.errors.app_property_logo}
                                </div>
                              ) : (
                                this.validator.message(
                                  "app_property_logo",
                                  this.state.app_property_logo,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>

                            {/* <div className="mg-t-30">
                            <label className="form-label text-left pd-lg-b-15 pd-xs-b-15">
                              Profile Cover Image
                            </label>

                              <div className="fileUpload d-flex flex-wrap justify-content-between app_file_img">
                                <span>
                                  <label className="hand-cursor">
                                    <input
                                      onChange={this.onImageChange3}
                                      type="file"
                                      name="profile_cover_image"
                                      tabIndex="1"
                                    />

                                    <img
                                      src={
                                        this.state.profileCoverImageUrl
                                          ? this.state.profileCoverImageUrl
                                          : Photo
                                      }
                                      alt="Profile Cover Image"
                                      className="rounded-circle"
                                    />
                                  </label>
                                </span>
                                <p className="hand-cursor remove-img-logos m-0" onClick={this.removePhoto3}></p>
                              </div>
                               
                            {this.state.errors.profile_cover_image ? (
                              <div className="text-danger">
                                {this.state.errors.profile_cover_image}
                              </div>
                            ) : (
                              this.validator.message(
                                "profile_cover_image",
                                this.state.profile_cover_image,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                            </div> */}
                          </div>

                          <div className="col-md-4 pd-lg-l-25 pd-xs-l-15 pd-r-30 ">
                            <div className="mg-t-0">
                              <label className="form-label text-left pd-b-5">
                                App Property Main Image
                                <p className="optional">
                                  (Aspect Ratio 1242*2208)
                                </p>
                                {/* {" "}
                              <span className="optional">(Optional)</span> */}
                              </label>

                              <div className="fileUpload d-flex flex-wrap justify-content-between app_file_img">
                                <span>
                                  <label className="hand-cursor">
                                    <input
                                      onChange={this.onImageChange2}
                                      type="file"
                                      name="app_property_main_image"
                                      tabIndex="2"
                                    />
                                    <img
                                      src={
                                        this.state.appPropertyMainImageUrl
                                          ? this.state.appPropertyMainImageUrl
                                          : Photo
                                      }
                                      alt="App Property Main"
                                    />
                                  </label>
                                </span>
                                <p
                                  className="hand-cursor remove-img-logos m-0"
                                  onClick={this.removePhoto2}
                                ></p>
                              </div>
                              {/* {this.state.errors.app_property_main_image ? (
                              <div className="text-danger">
                                {this.state.errors.app_property_main_image}
                              </div>
                            ) : (
                              ""
                            )} */}
                              {this.state.errors.app_property_main_image ? (
                                <div className="text-danger">
                                  {this.state.errors.app_property_main_image}
                                </div>
                              ) : (
                                this.validator.message(
                                  "app_property_main_image",
                                  this.state.app_property_main_image,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 pd-l-20 pd-r-30">
                            <ReactDevicePreview device="iphonex" scale="0.6">
                              <figure>
                                <img
                                  src={
                                    this.state.appPropertyLogoUrl
                                      ? this.state.appPropertyLogoUrl
                                      : Photo
                                  }
                                  alt="App Property Logo"
                                  className="background-image rounded-circle"
                                />
                              </figure>
                              <img
                                src={
                                  this.state.appPropertyMainImageUrl
                                    ? this.state.appPropertyMainImageUrl
                                    : Photo
                                }
                                alt="App Property Main"
                                className="background-image"
                              />
                            </ReactDevicePreview>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default AppPropertySetting;
