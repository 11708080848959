///////////////////////////////////////////////
//     							             //
//  Program: CorporateAppService.js                 //
//  Application: Services                    //
//  Option: Used for Applicants data              //
//  Developer: Raj Kumar                          //
//  Date: 2023-03-23                                      //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class CorporateAppService {
    getCorporateApp(queryString) {
        let endPoint = "corporate-app" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }
    getUploadedVideoData(queryString) {
        let endPoint =
          "get-cms-upload-video" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};
    
        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
      }    

    getCorporateAppBySlug(slug) {
        let endPoint = "corporate-app/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }
    getVideoDataBySlug(slug) {
        let endPoint = "get-video-by-slug/" + slug;
        let addditionalHeaderData = {};
        let postData = {};
        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
      }
    

    getArticleCategory() {
        let endPoint = "get-article-category";
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateCorporateApp(slug, data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "corporate-app/" + slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.putCall(inputData);
        return response;
    }

    createCorporateApp(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "corporate-app";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }
    createVideoUpload(data) {
        delete data.errors;
        delete data.input;
    
        let endPoint = "upload-video-data";
        let addditionalHeaderData = {};
        let postData = data;
    
        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
      }
      updateVideoUpload(slug, data) {
        delete data.errors;
        delete data.input;
    
        let endPoint = "update-video-data/" + slug;
        let addditionalHeaderData = {};
        let postData = data;
    
        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
      }
      deleteVideo(slug, video_file) {
        let endPoint = "cms-video-delete/" + slug + "/" + video_file;
        let addditionalHeaderData = {};
        let postData = {};
        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.deleteCall(inputData);
        return response;
      }            

    deleteCorporateApp(slug) {
        let endPoint = "corporate-app/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.deleteCall(inputData);
        return response;
    }
}

export default new CorporateAppService();
