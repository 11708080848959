////////////////////////////////////////////////////////////
//     							                                      //
//  Program: HousekeepingList.jsx                         //
//  Application: Housekeeping                             //
//  Option: List all Housekeeping                         //
//  Developer: NP				                                  //
//  Date: 2022-06-20                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import ReservationService from "../../services/ReservationService";
import PropertyService from "../../services/PropertyService";
import { Container, Modal, InputGroup, Form, Tab, Nav } from "react-bootstrap";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import HouseKeepingSidebar from "../common/HouseKeepingSidebar";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Moment from "moment";
import Alerts from "../common/Alerts";
import UnitsService from "../../services/UnitsService";
import Select from "react-select";
import DatePicker from "react-datepicker";
import queryString from "query-string";
import { Link } from "react-router-dom";
import pluswIcon from "./../../assets/images/plus.svg";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertiesCleaningSidebar from "../common/AllPropertiesCleaningSidebar";

let queryParams = queryString.parse(window.location.search);
export class HousekeepingList extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      eventKey: "today",
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      is_search: 0,
      module_search: "",
      newStatusList: [
        { label: "Completed", value: 1 },
        { label: "Pending", value: 2 },
        { label: "Assigned", value: 3 },
      ],
      cleaningList: [
        { label: "Reservation", value: 1 },
        { label: "Resident", value: 2 },
        { label: "Direct", value: 3 },
      ],

      cleaningTabNumber: localStorage.getItem("cleaning_tab_number")
        ? parseInt(localStorage.getItem("cleaning_tab_number"))
        : "",
      selectedStatusList: [],
      selectedCleaning: [],
      tab: 1,
      filter_value: "",
      lastUpdatedSlug: "",
      listing_slug: this.props.match.params.listingSlug,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      outside_property_slug: this.props.match.params.outsidePropertySlug,
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "asign_completed_at",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "asign_completed_at",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: false,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "unit_number",
          text: "Unit",
          className: "unit_number hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.unit_name ? record.unit_name : record.unit_number;
          },
        },
        {
          key: "scheduled_on",
          text: "Schedule Start",
          className: "started_on hand-cursor tnbr_schdl_date date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record && record.started_on
              ? Moment(record.started_on).format(global.dateTimeFormat)
              : "";
          },
        },
        {
          key: "completed_on",
          text: "Schedule Completion",
          className: "completed_on hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record && record.completed_on
              ? Moment(record.completed_on).format(global.dateTimeFormat)
              : "";
          },
        },
        {
          key: "tbnb_project_id",
          text: "Project No.",
          className: "tbnb_project_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.tbnb_project_id ? record.tbnb_project_id : "";
          },
        },
        {
          key: "asign_started_at",
          text: "Actual Start",
          className: "asign_started_at hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record && record.asign_started_at
              ? Moment(record.asign_started_at).format(global.dateTimeFormat)
              : "";
          },
        },
        {
          key: "asign_completed_at",
          text: "Actual Completion",
          className: "asign_completed_at hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record && record.asign_completed_at
              ? Moment(record.asign_completed_at).format(global.dateTimeFormat)
              : "";
          },
        },
        {
          key: "cleaner_name",
          text: "Cleaner Name",
          className: "cleaner_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.cleaner_name && record.cleaner_name !== null
              ? record.cleaner_name
              : "";
          },
        },
        {
          key: "cleaning_type",
          text: "Cleaning Type",
          className: "type hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.cleaning_type == 1
              ? "Reservation"
              : record.cleaning_type == 2
              ? "Resident"
              : record.cleaning_type == 3
              ? "Direct"
              : "";
          },
        },
        {
          key: "booking_code",
          text: "Cleaning Access Code",
          className: "cleaning_access_code hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.keycafe_ref_table_id == 2 ? record.booking_code : "";
          },
        },
        {
          key: "timeTaken",
          text: "Time Taken",
          className: "time_taken hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.timeTaken && record.timeTaken !== null
              ? record.timeTaken
              : "";
          },
        },
        {
          key: "inspection_status",
          text: "Cleaning Status",
          className: "inspection_status text-left hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.status_display ? record.status_display : "";
          },
        },
        {
          key: "resident_name",
          text: "Account Type",
          className: "resident_name text-left hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.resident_name ? record.resident_name : "Concierge";
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  async componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      switch (this.state.cleaningTabNumber) {
        case 1:
          document.getElementById("today").click();
          break;
        case 2:
          document.getElementById("tomorrow").click();
          break;
        case 3:
          document.getElementById("completed").click();
          break;
        case 4:
          document.getElementById("pending").click();
          break;
        case 5:
          document.getElementById("all").click();
          break;
        default:
          document.getElementById("today").click();
      }
    }
    if (localStorage.getItem("user_type") === "3") {
      //get property data and store in session storage
      if (this.state.property_slug) {
        let res = await PropertyService.getProperty(this.state.property_slug);
        if (global.successStatus.includes(res.status)) {
          sessionStorage.setItem(
            "property_slug",
            this.state.property_slug ? this.state.property_slug : ""
          );
          sessionStorage.setItem(
            "property_name",
            res.data.property_name ? res.data.property_name : ""
          );
          sessionStorage.setItem(
            "property_type",
            res.data.property_type
              ? res.data.property_type.property_type_name
              : ""
          );
          sessionStorage.setItem(
            "year_value",
            res.data.year_value ? res.data.year_value : ""
          );
          sessionStorage.setItem(
            "property_logo_url",
            res.data.property_logo_url ? res.data.property_logo_url : ""
          );
          sessionStorage.setItem(
            "property_logo_thumb_url",
            res.data.property_logo_thumb_url
              ? res.data.property_logo_thumb_url
              : ""
          );

          localStorage.setItem("property_slug", this.state.property_slug);
          localStorage.setItem("property_name", res.data.property_name);
          localStorage.setItem(
            "property_type",
            res.data.property_type.property_type_name
          );
          localStorage.setItem("year_value", res.data.year_value);
          localStorage.setItem("property_logo_url", res.data.property_logo_url);
          localStorage.setItem(
            "property_logo_thumb_url",
            res.data.property_logo_thumb_url
          );

          this.props.propertyName(res.data.property_name);
          this.props.propertySlug(this.state.property_slug);
        }
      }
    }
    // this.getData();
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      switch (this.state.cleaningTabNumber) {
        case 1:
          document.getElementById("today").click();
          break;
        case 2:
          document.getElementById("tomorrow").click();
          break;
        case 3:
          document.getElementById("completed").click();
          break;
        case 4:
          document.getElementById("pending").click();
          break;
        case 5:
          document.getElementById("all").click();
          break;
        default:
          document.getElementById("today").click();
      }
    }, 200);
    this.setState({
      selectedUnitList: [],
      module_search: [],
      is_search: 0,
    });
  }

  async getData(tab, queryString = "") {
    if (queryString.includes("is_search=1")) {
      this.setState({ records: [] });
    }
    var list = [];
    var totalRecords = 0;
    let res = await ReservationService.getHousekeepingList(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        "&tab=" +
        (tab ? tab : this.state.tab) +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }

    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  async handleChangeStatusList(value, slug) {
    this.setState({ lastUpdatedSlug: slug });
    let status_id = [];

    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }

    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });

    this.setState({ lastUpdatedSlug: "" });
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;
    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" +
        (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
      this.setState({ selectedCleaning: [] });
      this.setState({ cleaning_type: "" });
      this.setState({ selectedUnitList: [] });
      this.setState({ selectedStatusList: [] });
      this.setState({ cleaner_name: "" });
      this.setState({ project_number: "" });
      this.setState({ unit_slug: "" });
      this.setState({ schedule_start_date: "" });
      this.setState({ status_id: "" });
    } else {
      this.getUnitData();
      this.setState({ isSearch: 1 });
    }
  };

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCleaningTypeList(value) {
    let cleaning_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        cleaning_type.push(item.value);
      });
    }
    const cleaning_type_value = value !== null ? value.value : [];
    this.setState({
      selectedCleaning: value,
      cleaning_type: cleaning_type_value,
    });
    this.setState({ confirm_back: 1 });
  }

  customFilter() {
    let date = "";
    if (this.state.schedule_start_date) {
      date = Moment(this.state.schedule_start_date).format("YYYY-MM-DD");
    }
    let schedule_completion_date = "";
    if (this.state.schedule_completion_date) {
      schedule_completion_date = Moment(
        this.state.schedule_completion_date
      ).format("YYYY-MM-DD");
    }

    let actual_start_date = "";
    if (this.state.actual_start_date) {
      actual_start_date = Moment(this.state.actual_start_date).format(
        "YYYY-MM-DD"
      );
    }

    let actual_completion_date = "";
    if (this.state.actual_completion_date) {
      actual_completion_date = Moment(this.state.actual_completion_date).format(
        "YYYY-MM-DD"
      );
    }

    let queryString =
      "user_slug=" +
      this.state.user_slug +
      "&schedule_start_date=" +
      date +
      "&schedule_completion_date=" +
      schedule_completion_date +
      "&actual_start_date=" +
      actual_start_date +
      "&actual_completion_date=" +
      actual_completion_date +
      "&is_filter=1&" +
      "&project_number=" +
      this.state.project_number +
      "&cleaner_name=" +
      this.state.cleaner_name +
      "&cleaner_type=" +
      this.state.cleaner_type +
      "&status=" +
      this.state.status;
    this.getData(queryString);
  }

  resetDate() {
    this.setState({
      schedule_start_date: "",
      schedule_completion_date: "",
      actual_start_date: "",
      actual_completion_date: "",
      user_slug: "",
      project_number: "",
      cleaner_name: "",
      cleaner_type: "",
      status: "",
    });
    this.getData();
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
            window.scrollTo(0, 0);
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(this.state.tab, queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(this.state.tab, queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Cleaning";
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    this.setState({ selectedCleaning: [] });
    this.setState({ selectedUnitList: [] });
    this.setState({ selectedStatusList: [] });
    this.setState({ cleaner_name: "" });
    this.setState({ project_number: "" });

    if (e === "today") {
      this.setState({ tab: 1 });
      this.getData(1);
      setTimeout(() => {
        localStorage.setItem(
          "cleaning_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "tomorrow") {
      this.setState({ tab: 2 });
      this.getData(2);
      setTimeout(() => {
        localStorage.setItem(
          "cleaning_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "completed") {
      this.setState({ tab: 3 });
      this.getData(3);
      setTimeout(() => {
        localStorage.setItem(
          "cleaning_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "pending") {
      this.setState({ tab: 4 });
      this.getData(4);
      setTimeout(() => {
        localStorage.setItem(
          "cleaning_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "all") {
      this.setState({ tab: 5 });
      this.getData(5);
      setTimeout(() => {
        localStorage.setItem(
          "cleaning_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
    this.setState({ is_search: 0 });
  }
  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      this.props.match.params.propertySlug !== undefined
        ? "housekeeping-list/" + data.slug
        : this.state.property_slug
        ? "/housekeeping-list/view/" +
          this.state.property_slug +
          "/" +
          data.slug
        : "/housekeeping-list/view/" + data.property_slug + "/" + data.slug
    );
  };

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      schedule_start_date: "",
      schedule_completion_date: "",
      actual_start_date: "",
      actual_completion_date: "",
      unit_slug: "",
      project_number: "",
      cleaner_name: "",
      status_id: "",
      cleaner_type: "",
      selectedUnitList: [],
    });
    this.setState({ module_search: "" });
  }

  filterData() {
    let schedule_start_date = this.state.schedule_start_date
      ? Moment(this.state.schedule_start_date).format("YYYY-MM-DD")
      : "";

    let queryString =
      "schedule_start_date=" +
      schedule_start_date +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&project_number=" +
      (this.state.project_number ? this.state.project_number : "") +
      "&cleaner_name=" +
      (this.state.cleaner_name ? this.state.cleaner_name : "") +
      "&cleaning_type=" +
      (this.state.cleaning_type ? this.state.cleaning_type : "") +
      "&status_id=" +
      (this.state.status_id ? this.state.status_id : "") +
      "&tab=" +
      this.state.tab +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.schedule_start_date
      ? "Schedule Start Date - " +
        Moment(this.state.schedule_start_date).format(global.dateFormat)
      : "";

    moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Unit - " +
          this.state.selectedUnitList.label
        : " Unit - " + this.state.selectedUnitList.label
      : moduleSearchString;

    moduleSearchString = this.state.project_number
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Project Number - " +
          this.state.project_number
        : " Project Number- " + this.state.project_number
      : moduleSearchString;

    moduleSearchString = this.state.cleaner_name
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Cleaner Name - " +
          this.state.cleaner_name
        : " Cleaner Name - " + this.state.cleaner_name
      : moduleSearchString;
    moduleSearchString = this.state.cleaning_type
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Cleaning Type - " +
          this.state.selectedCleaning.label
        : " Cleaning Type - " + this.state.selectedCleaning.label
      : moduleSearchString;
    moduleSearchString = this.state.status_id
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Status - " +
          this.state.selectedStatusList.label
        : " Status - " + this.state.selectedStatusList.label
      : moduleSearchString;
    if (
      this.state.schedule_start_date ||
      this.state.unit_slug ||
      this.state.project_number ||
      this.state.cleaner_name ||
      this.state.cleaning_type ||
      this.state.status_id
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(this.state.tab, queryString);
    }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <HouseKeepingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertiesCleaningSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3 className="mb-0">{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {global.userPermissions.checkPermission(
                        "cleaning-manual-cleaning"
                      ) &&
                        this.state.property_slug && (
                          <Link
                            to={
                              this.props.match.params.propertySlug !== undefined
                                ? "/properties/view/" +
                                  this.state.property_slug +
                                  "/housekeeping/add"
                                : "/housekeeping/add/" +
                                  this.state.property_slug
                            }
                          >
                            <button className="btn-success-outline-small ">
                              <img src={pluswIcon} alt="" /> Add
                            </button>
                          </Link>
                        )}
                      <button
                        onClick={this.searchToggle}
                        className="btn-success-outline-small ml-3"
                      >
                        <img src={filterIcon} alt="filterIcon" />{" "}
                        {this.state.isSearch === 1 ? "Search" : "Search"}
                      </button>
                    </div>
                  </div>
                </div>
                {this.props.match.params.propertySlug === undefined ? (
                  <>
                    <div className="row pd-lg-l-15 pd-xs-l-15">
                      <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                        <OutSidePropertySelectBox
                          propertySlug={this.getPropertySlug.bind(this)}
                          outSidePropertySlug={this.state.outSidePropertySlug}
                        />
                      </div>
                      <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                        {queryParams.isGlobal === "1" ? (
                          <>
                            <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between justify_mob_filter scrolling-carousel">
                              {"Search applied on: " +
                                queryParams.global_col_label +
                                " - " +
                                queryParams.global_search}
                              <button
                                onClick={this.clearFilter}
                                className="mg-l-15 btn-success-outline-small"
                              >
                                Clear
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.is_search === 1 ? (
                          <>
                            <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                              <div className="custm-filter">
                                <div>
                                  {" "}
                                  <label className="form-label text-left pd-b-2 mb-0">
                                    Search Applied On
                                  </label>
                                </div>
                                <div className="filter_bg_cust">
                                  {this.state.module_search.map((item, i) => {
                                    return <span key={i}>{item}</span>;
                                  })}
                                  <button
                                    onClick={this.clearFilter}
                                    className="btn-fliter-clear"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-xxl-12 pd-r-15 pd-l-15">
                    {queryParams.isGlobal === "1" ? (
                      <>
                        <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                          {"Search applied on: " +
                            queryParams.global_col_label +
                            " - " +
                            queryParams.global_search}
                          <button
                            onClick={this.clearFilter}
                            className="mg-l-15 btn-success-outline-small"
                          >
                            Clear
                          </button>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.is_search === 1 ? (
                      <>
                        <div className="col-xxl-12 col-sm-12 pd-lg-r-15 pd-lg-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                          <div className="custm-filter">
                            <div>
                              {" "}
                              <label className="form-label text-left pd-b-2 mb-0">
                                Search Applied On
                              </label>
                            </div>
                            <div className="filter_bg_cust">
                              {this.state.module_search.map((item, i) => {
                                return <span key={i}>{item}</span>;
                              })}
                              <button
                                onClick={this.clearFilter}
                                className="btn-fliter-clear"
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <div className="scrolling-carousel pd-l-15 pd-r-15 scroll-slide">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="today"
                    onSelect={(e) => this.getActiveClass(e)}
                    eventKey={this.state.activeClass}
                    className="pd-r-60"
                  >
                    <ScrollingCarousel
                      rightArrow={true}
                      leftArrow={true}
                      className="swipesection scroll_nav_mob"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="today"
                          id="today"
                          className={
                            this.state.activeClass === "today" ? "active" : ""
                          }
                        >
                          Today
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="tomorrow"
                          id="tomorrow"
                          className={
                            this.state.activeClass === "tomorrow"
                              ? "active"
                              : ""
                          }
                        >
                          Tomorrow
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="pending"
                          id="pending"
                          className={
                            this.state.activeClass === "pending" ? "active" : ""
                          }
                        >
                          Upcoming
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="completed"
                          id="completed"
                          className={
                            this.state.activeClass === "completed"
                              ? "active"
                              : ""
                          }
                        >
                          Past
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="all"
                          id="all"
                          className={
                            this.state.activeClass === "all" ? "active" : ""
                          }
                        >
                          All
                        </Nav.Link>
                      </Nav.Item>
                    </ScrollingCarousel>
                    <Tab.Content>
                      <Tab.Pane eventKey="today">
                        {this.state.tab === 1 && (
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <Alerts
                              show={this.state.showAlertModal}
                              type={this.state.alertModalType}
                              title={this.state.alertModalTitle}
                              message={this.state.alertModalMessage}
                            />
                            {this.state.total_record !== 0 ? (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                <div className="pd-lg-l-0 pd-xs-l-15">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList houskeeping_tbl mg-b-100 housecln_tbl"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={
                                      global.userPermissions.checkPermission(
                                        "cleaning-update"
                                      ) && this.rowClickedHandler
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100 housecln_tbl"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={
                                      global.userPermissions.checkPermission(
                                        "cleaning-update"
                                      ) && this.rowClickedHandler
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Tab.Pane>

                      <Tab.Pane eventKey="tomorrow">
                        {this.state.tab === 2 && (
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <Alerts
                              show={this.state.showAlertModal}
                              type={this.state.alertModalType}
                              title={this.state.alertModalTitle}
                              message={this.state.alertModalMessage}
                            />
                            {this.state.total_record !== 0 ? (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                <div className="pd-lg-l-0 pd-xs-l-15">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList houskeeping_tbl mg-b-100 housecln_tbl"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={
                                      global.userPermissions.checkPermission(
                                        "cleaning-update"
                                      ) && this.rowClickedHandler
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                {queryParams.isGlobal === "1" ? (
                                  <>
                                    <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                      {"Search applied on: " +
                                        queryParams.global_col_label +
                                        " - " +
                                        queryParams.global_search}
                                      <button
                                        onClick={this.clearFilter}
                                        className="mg-l-15 btn-success-outline-small"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100 housecln_tbl"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={
                                      global.userPermissions.checkPermission(
                                        "cleaning-update"
                                      ) && this.rowClickedHandler
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Tab.Pane>

                      <Tab.Pane eventKey="pending">
                        {this.state.tab === 4 && (
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <div className="col-md-12 pl-0 pr-0">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                            </div>
                            {this.state.total_record !== 0 ? (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                <div className="pd-lg-l-0 pd-xs-l-15">
                                  {queryParams.isGlobal === "1" ? (
                                    <>
                                      <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                        {"Search applied on: " +
                                          queryParams.global_col_label +
                                          " - " +
                                          queryParams.global_search}
                                        <button
                                          onClick={this.clearFilter}
                                          className="mg-l-15 btn-success-outline-small"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList houskeeping_tbl mg-b-100 housecln_tbl"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={
                                      global.userPermissions.checkPermission(
                                        "cleaning-update"
                                      ) && this.rowClickedHandler
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                {queryParams.isGlobal === "1" ? (
                                  <>
                                    <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                      {"Search applied on: " +
                                        queryParams.global_col_label +
                                        " - " +
                                        queryParams.global_search}
                                      <button
                                        onClick={this.clearFilter}
                                        className="mg-l-15 btn-success-outline-small"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100 housecln_tbl"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={
                                      global.userPermissions.checkPermission(
                                        "cleaning-update"
                                      ) && this.rowClickedHandler
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Tab.Pane>

                      <Tab.Pane eventKey="completed">
                        {this.state.tab === 3 && (
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <div className="col-md-12 pl-0 pr-0">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                            </div>
                            {this.state.total_record !== 0 ? (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                <div className="pd-lg-l-0 pd-xs-l-15">
                                  {queryParams.isGlobal === "1" ? (
                                    <>
                                      <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                        {"Search applied on: " +
                                          queryParams.global_col_label +
                                          " - " +
                                          queryParams.global_search}
                                        <button
                                          onClick={this.clearFilter}
                                          className="mg-l-15 btn-success-outline-small"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList houskeeping_tbl mg-b-100 housecln_tbl"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={
                                      global.userPermissions.checkPermission(
                                        "cleaning-update"
                                      ) && this.rowClickedHandler
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                {queryParams.isGlobal === "1" ? (
                                  <>
                                    <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                      {"Search applied on: " +
                                        queryParams.global_col_label +
                                        " - " +
                                        queryParams.global_search}
                                      <button
                                        onClick={this.clearFilter}
                                        className="mg-l-15 btn-success-outline-small"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100 housecln_tbl"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={
                                      global.userPermissions.checkPermission(
                                        "cleaning-update"
                                      ) && this.rowClickedHandler
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Tab.Pane>

                      <Tab.Pane eventKey="all">
                        {this.state.tab === 5 && (
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <div className="col-md-12 pl-0 pr-0">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                            </div>
                            {this.state.total_record !== 0 ? (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                <div className="pd-lg-l-0 pd-xs-l-15">
                                  {queryParams.isGlobal === "1" ? (
                                    <>
                                      <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                        {"Search applied on: " +
                                          queryParams.global_col_label +
                                          " - " +
                                          queryParams.global_search}
                                        <button
                                          onClick={this.clearFilter}
                                          className="mg-l-15 btn-success-outline-small"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList houskeeping_tbl mg-b-100 housecln_tbl"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={
                                      global.userPermissions.checkPermission(
                                        "cleaning-update"
                                      ) && this.rowClickedHandler
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                {queryParams.isGlobal === "1" ? (
                                  <>
                                    <div className="col-md-12 pd-l-0 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                      {"Search applied on: " +
                                        queryParams.global_col_label +
                                        " - " +
                                        queryParams.global_search}
                                      <button
                                        onClick={this.clearFilter}
                                        className="mg-l-15 btn-success-outline-small"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100 housecln_tbl"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={
                                      global.userPermissions.checkPermission(
                                        "cleaning-update"
                                      ) && this.rowClickedHandler
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </Container>

        {/* Housekeeping Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Cleaning</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Schedule Start</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="schedule_start_date"
                      selected={this.state.schedule_start_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "schedule_start_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Project Number</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="project_number"
                      value={this.state.project_number}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Cleaner Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="cleaner_name"
                      value={this.state.cleaner_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Status</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      name="status_id"
                      value={this.state.selectedStatusList}
                      options={this.state.newStatusList}
                      onChange={(value) => this.handleChangeStatusList(value)}
                      defaultValue={this.state.selectedStatusList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Cleaning Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      name="cleaning_type"
                      value={this.state.selectedCleaning}
                      options={this.state.cleaningList}
                      onChange={(value) =>
                        this.handleChangeCleaningTypeList(value)
                      }
                      defaultValue={this.state.selectedCleaning}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default HousekeepingList;
