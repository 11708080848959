//     							                          //
//  Program: ClubSide.jsx                           //
//  Application: Club                                 //
//  Option: common component Club Sidebar              //
//  Developer: Sonmin 		                                  //
//  Date: 2023-09-08                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
class ClubSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
      club_slug: sessionStorage.getItem("club_slug")
        ? sessionStorage.getItem("club_slug")
        : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }



  toggleChildMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".left");
    data.classList.replace("left", "hide");
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i> Back to Clubs
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <ul>
               
                    {global.userPermissions.checkPermission("brand-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("club-info")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/club/view/" +
                            (this.props.club_slug
                              ? this.props.club_slug
                              : this.state.club_slug) +
                            "/club-info"
                          }
                        >
                          Overview
                        </Link>
                      </li>
                    )}
           
                    {global.userPermissions.checkPermission(
                      "brand-cms-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("club-cms")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/club/view/" +
                            (this.props.club_slug
                              ? this.props.club_slug
                              : this.state.club_slug) +
                            "/club-cms"
                          }
                        >
                          CMS
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              {/* <a href="/" className="back subMenu"> <i className="fas fa-chevron-left"></i> Listings</a> */}
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Clubs
              </span>
              <ul>
                {global.userPermissions.checkPermission("brand-list") && (
                  <li>
                    <Link
                      className={
                        this.isPathActive("brand-info")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      to={
                        "/clus/view/" +
                        (this.props.club_slug
                          ? this.props.club_slug
                          : this.state.club_slug) +
                        "/club-info"
                      }
                    >
                      Overview
                    </Link>
                  </li>
                )}
                {global.userPermissions.checkPermission("brand-cms-list") && (
                  <li>
                    <Link
                      className={
                        this.isPathActive("brand-cms")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      to={
                        "/club/view/" +
                        (this.props.club_slug
                          ? this.props.club_slug
                          : this.state.club_slug) +
                        "/club-cms"
                      }
                    >
                      CMS
                    </Link>
                  </li>
                )}
              </ul>
            </div>        
          </div>
        </div>
      </>
    );
  }
}
export default ClubSidebar;
