////////////////////////////////////////////////////////////
//     							                          //
//  Program: RoomType.jsx                                //
//  Application: Room                                   //
//  Option: to view RoomType data                       //
//  Developer: Ashish Kumar                               //
//  Date: 2022-01-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form, InputGroup } from "react-bootstrap";
import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import RoomTypeService from "../../services/RoomTypeService";
import modaldeleteicon from "./../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import NoData from "../common/NoData";

export class RoomType extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for Room Type
      addRoomTypeModal: false,
      editRoomTypeModal: false,
      deleteRoomTypeModal: false,
      roomTypeList: [],
      roomTypeListTotal: 0,
      room_type_name: "",
      aka_name: "",
      sort_order: "",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.openAddRoomTypeModal = this.openAddRoomTypeModal.bind(this);
    this.openEditRoomTypeModal = this.openEditRoomTypeModal.bind(this);
    this.openDeleteRoomTypeModal = this.openDeleteRoomTypeModal.bind(this);
    this.saveRoomType = this.saveRoomType.bind(this);
    this.getRoomTypeDetails = this.getRoomTypeDetails.bind(this);
    this.updateRoomType = this.updateRoomType.bind(this);
    this.deleteRoomType = this.deleteRoomType.bind(this);
  }

  componentDidMount() {
    this.getRoomTypeData();
  }

  async getRoomTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var roomTypeList = [];
    var roomTypeListTotal = 0;

    let res = await RoomTypeService.getRoomType(
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      roomTypeList = res.data.data ? res.data.data : [];
      roomTypeListTotal = roomTypeList ? roomTypeList.length : 0;
    }
    this.setState({
      roomTypeList: roomTypeList,
      roomTypeListTotal: roomTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  saveRoomType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.room_type_name &&
      !this.validator.errorMessages.aka_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        room_type_name: this.state.room_type_name,
        aka_name: this.state.aka_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await RoomTypeService.createRoomType(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddRoomTypeModal();
        this.setState({
          room_type_name: "",
          aka_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getRoomTypeData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  async getRoomTypeDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await RoomTypeService.getRoomTypeBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.room_type_name = res.data.room_type_name
          ? res.data.room_type_name
          : "";
        resData.aka_name = res.data.aka_name ? res.data.aka_name : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  updateRoomType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.room_type_name &&
      !this.validator.errorMessages.aka_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        room_type_name: this.state.room_type_name,
        aka_name: this.state.aka_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await RoomTypeService.updateRoomType(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditRoomTypeModal();
        this.setState({
          room_type_name: "",
          aka_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getRoomTypeData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  async deleteRoomType(slug) {
    this.setState({ showSpinner: true });
    let res = await RoomTypeService.deleteRoomType(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteRoomTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getRoomTypeData();
    } else {
      this.closeDeleteRoomTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  openAddRoomTypeModal() {
    this.setState({ addRoomTypeModal: true });
  }

  closeAddRoomTypeModal() {
    this.setState({ addRoomTypeModal: false });
    this.setState({
      room_type_name: "",
      aka_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openEditRoomTypeModal(slug) {
    this.getRoomTypeDetails(slug);
    this.setState({ editRoomTypeModal: true });
    this.setState({ slug: slug });
  }

  closeEditRoomTypeModal() {
    this.setState({ editRoomTypeModal: false });
    this.setState({
      slug: "",
      room_type_name: "",
      aka_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openDeleteRoomTypeModal(slug) {
    this.setState({ deleteRoomTypeModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteRoomTypeModal() {
    this.setState({ deleteRoomTypeModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });

    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "auto",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Room Type";
  }

  render() {
    return (
      <>
        {/* Room Type card */}
        {/* <Card>
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="15"
              id="15"
              onClick={(e) => this.setOpen(!this.state.open, e)}
            >
              Room Type
              <i className="mdi mdi-view-headline"> </i>
              {this.state.open && this.state.eventKey === "15" ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-up"></i>
              )}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="15">
            <Card.Body>
              <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
                <Button
                  variant="outline-dark btn-block btn-with-icon"
                  onClick={this.openAddRoomTypeModal}
                >
                  <img src={pluswIcon} /> Add
                </Button>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="table-responsive">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-40">Room Type Name</th>
                      <th className="w-40">AKA Name</th>
                      <th className="w-15">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.roomTypeListTotal > 0 ? (
                      this.state.roomTypeList.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td
                              className="hand-cursor"
                              onClick={() =>
                                this.openEditRoomTypeModal(item.slug)
                              }
                            >
                              {item.room_type_name}
                            </td>
                            <td>{item.aka_name}</td>
                            <td>
                              {item.status_id === 1 ? (
                                <b className="active"></b>
                              ) : (
                                <b></b>
                              )}
                            </td>
                            <td>
                              <div className="btn-icon-list d-block text-center">
                                <img
                                  src={deleteicon}
                                  alt="Delete"
                                  onClick={() =>
                                    this.openDeleteRoomTypeModal(item.slug)
                                  }
                                  className="hand-cursor"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          No rows found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}

        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                          <Button
                            variant="outline-dark btn-block btn-with-icon"
                            onClick={this.openAddRoomTypeModal}
                          >
                            <img src={pluswIcon} /> Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.roomTypeListTotal > 0 ? (
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th className="w-40">Room Type Name</th>
                              <th className="w-40">AKA Name</th>
                              <th className="w-15">Status</th>
                              {global.userPermissions.checkPermission(
                                "master-data-delete"
                              ) && <th className="text-center">Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.roomTypeListTotal > 0 ? (
                              this.state.roomTypeList.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      className="hand-cursor"
                                      onClick={() => {
                                        global.userPermissions.checkPermission(
                                          "master-data-update"
                                        ) &&
                                          this.openEditRoomTypeModal(item.slug);
                                      }}
                                    >
                                      {item.room_type_name}
                                    </td>
                                    <td>{item.aka_name}</td>
                                    <td>
                                      {item.status_id === 1 ? (
                                        <b className="active"></b>
                                      ) : (
                                        <b></b>
                                      )}
                                    </td>
                                    {global.userPermissions.checkPermission(
                                      "master-data-delete"
                                    ) && (
                                      <td>
                                        <div className="btn-icon-list d-block text-center">
                                          <img
                                            src={deleteicon}
                                            alt="Delete"
                                            onClick={() =>
                                              this.openDeleteRoomTypeModal(
                                                item.slug
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                  There are no room types added in the system.
                                  You may add them by clicking Add button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage("ROOMTYPE")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add Room Type popup modal */}
        <Modal
          show={this.state.addRoomTypeModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddRoomTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddRoomTypeModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Room Type</h3>
            <InputGroup className="mt-3">
              <label>Room Type Name</label>
              <Form.Control
                onChange={this.changeHandler}
                name="room_type_name"
                value={this.state.room_type_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
                // placeholder="Room Type Name"
              />
            </InputGroup>
            {this.state.errors.room_type_name ? (
              <div className="text-danger">
                {this.state.errors.room_type_name}
              </div>
            ) : (
              this.validator.message(
                "room_type_name",
                this.state.room_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>AKA Name</label>
              <Form.Control
                onChange={this.changeHandler}
                name="aka_name"
                onKeyPress={global.onKeyPressEvent.alphaNumValidation}
                value={this.state.aka_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="AKA Name"
                maxLength="3"
              />
            </InputGroup>
            {this.state.errors.aka_name ? (
              <div className="text-danger">{this.state.errors.aka_name}</div>
            ) : (
              this.validator.message(
                "aka_name",
                this.state.aka_name,
                "required|alpha_num_dash|max:3",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddRoomTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveRoomType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit Room Type popup modal */}
        <Modal
          show={this.state.editRoomTypeModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditRoomTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditRoomTypeModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Room Type</h3>
            <InputGroup className="mt-3">
              <label>Room Type Name</label>
              <Form.Control
                onChange={this.changeHandler}
                name="room_type_name"
                value={this.state.room_type_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
                // placeholder="Room Type Name"
              />
            </InputGroup>
            {this.state.errors.room_type_name ? (
              <div className="text-danger">
                {this.state.errors.room_type_name}
              </div>
            ) : (
              this.validator.message(
                "room_type_name",
                this.state.room_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>AKA Name</label>
              <Form.Control
                onChange={this.changeHandler}
                name="aka_name"
                onKeyPress={global.onKeyPressEvent.alphaNumValidation}
                value={this.state.aka_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="AKA Name"
                maxLength="3"
              />
            </InputGroup>
            {this.state.errors.aka_name ? (
              <div className="text-danger">{this.state.errors.aka_name}</div>
            ) : (
              this.validator.message(
                "aka_name",
                this.state.aka_name,
                "required|alpha_num_dash|max:3",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditRoomTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateRoomType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete popup modal */}
        <Modal
          show={this.state.deleteRoomTypeModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteRoomTypeModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteRoomTypeModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteRoomTypeModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteRoomType(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default RoomType;
