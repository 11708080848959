////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Signin.jsx                                   //
//  Application: authentication                           //
//  Option: For sign in into project                      //
//  Developer: NP  						                            //
//  Date: 2021-12-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import AuthenticationService from "../../services/AuthenticationService";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import BuildingOnboardingService from "../../services/BuildingOnboardingService";
import Timer from "./Timer";

class Signin extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      isSubmit: false,
      showAlertModal: true,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      email: "",
      password: "",
      otp: "", // Added state for OTP
      isOtpSent: false, // Added state to track OTP step
      headerToken: localStorage.getItem("headerToken"),
      totalCount: 11,
      setpsCount: 0,
      maskedEmail: ""
    };

    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.signIn = this.signIn.bind(this);
    this.verifyOtp = this.verifyOtp.bind(this); // Bind the new OTP verification method
    this.resendOtp = this.resendOtp.bind(this); // Bind the new OTP verification method
    this.getSettingData = this.getSettingData.bind(this);
    this.getOnboardingSetpsCount = this.getOnboardingSetpsCount.bind(this);
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  componentDidMount() {
    // this.getSettingData();
    // if(this.state.headerToken !== null || this.state.headerToken !== ''){
    //     localStorage.clear();
    //     sessionStorage.clear();
    // }
  }

  async getSettingData() {
    let res = await AuthenticationService.getSettingData();
    if (global.successStatus.includes(res.status)) {
      global.login_image = res.data ? res.data.static_images.login_image : "";
      global.welcome_image = res.data
        ? res.data.static_images.welcome_image
        : "";
    }
  }

  signIn = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      var user_token = Math.floor(Math.random(12) * 1000000000000 + 1);
      // Validate all fields before data submission
      this.setState({ isSubmit: true, user_token: user_token });
      let input = {
        email: this.state.email,
        password: this.state.password,
        otp_type: 1,
        user_token: user_token,
      };
      let res = await AuthenticationService.signin(input);
      if (
        global.successStatus.includes(res.status) &&
        res.data.mfa_enabled == 1
      ) {
        var maskedEmail = this.maskEmail(this.state.email);
        this.setState({maskedEmail});
        // Check if OTP is required
        if (res.data.otpRequired) {
          this.setState({
            isOtpSent: true, // Set OTP sent state to true
            alertModalType: "success",
            alertModalTitle: "OTP Sent",
            alertModalMessage: "An OTP has been sent to your email address.",
            showAlertModal: true,
          });
        } else {
          // Handle regular login without OTP
          this.handleLoginSuccess(res);
        }
      } else if (
        global.successStatus.includes(res.status) &&
        res.data.mfa_enabled == 0
      ) {
        localStorage.setItem(
          "headerToken",
          res.data.headerToken ? res.data.headerToken : ""
        );
        global.headerToken = res.data.headerToken ? res.data.headerToken : "";
        global.currerntRoleSlug = res.data.currerntRoleSlug
          ? res.data.currerntRoleSlug
          : "";
        localStorage.setItem("currerntRoleSlug", res.data.currerntRoleSlug);
        global.currerntRoleSlug = res.data.currerntRoleSlug
          ? res.data.currerntRoleSlug
          : "";
        localStorage.setItem("currentRoleTitle", res.data.currerntRoleTitle);
        global.currentRoleTitle = res.data.currerntRoleTitle
          ? res.data.currerntRoleTitle
          : "";
        localStorage.setItem(
          "currerntRoleGroup",
          res.data.currerntRoleGroup ? res.data.currerntRoleGroup : ""
        );
        // localStorage.setItem('isLogin', 1);
        localStorage.setItem("isPopupShow", 1);
        localStorage.setItem(
          "userData",
          res.data.user_data ? JSON.stringify(res.data.user_data) : {}
        );
        localStorage.setItem(
          "permissions",
          res.data.permissionList ? res.data.permissionList : []
        );
        localStorage.setItem(
          "roleList",
          res.data.roleList ? JSON.stringify(res.data.roleList) : {}
        );
        // localStorage.setItem('user_type', res.data.currerntRoleGroup ? res.data.currerntRoleGroup : '');
        localStorage.setItem(
          "propertyList",
          res.data.propertyList ? JSON.stringify(res.data.propertyList) : []
        );
        localStorage.setItem(
          "brandList",
          res.data.brandList ? JSON.stringify(res.data.brandList) : []
        );
        localStorage.setItem("messageCount", 1);
        localStorage.setItem("notifyCount", 1);
        localStorage.setItem("message_page", 1);
        this.setState({ messagePageActive: 1 });
        localStorage.setItem(
          "currerntPropSlug",
          res.data.propertyList ? res.data.propertyList[0].slug : ""
        );
        sessionStorage.setItem(
          "currerntPropSlug",
          res.data.propertyList ? res.data.propertyList[0].slug : ""
        );
        if (
          res.data &&
          res.data.propertyList &&
          res.data.propertyList[0] &&
          res.data.propertyList[0].slug
        ) {
          await this.getOnboardingSetpsCount(res.data.propertyList[0].slug);
        }
        if (
          res.data.roleList &&
          res.data.roleList[0] &&
          res.data.roleList[0].defaultLandingPage === 3
        ) {
          if (
            ((res.data.roleList &&
              res.data.roleList[0] &&
              res.data.roleList[0].roleId === 1) ||
              (res.data.roleList &&
                res.data.roleList[0] &&
                res.data.roleList[0].roleId === 2)) &&
            this.state.totalCount !== this.state.setpsCount
          ) {
            window.location.href =
              "/properties/view/" +
              (res.data &&
              res.data.propertyList &&
              res.data.propertyList[0] &&
              res.data.propertyList[0].slug
                ? res.data.propertyList[0].slug
                : "") +
              "/building-onboarding";
          } else {
            window.location.href = "/properties";
          }
        } else if (
          res.data.roleList &&
          res.data.roleList[0] &&
          res.data.roleList[0].defaultLandingPage === 1
        ) {
          window.location.href = "/dashboard";
        } else if (
          res.data.roleList &&
          res.data.roleList[0] &&
          res.data.roleList[0].defaultLandingPage === 4
        ) {
          if (
            (res.data.roleList &&
              res.data.roleList[0] &&
              res.data.roleList[0].roleId === 1) ||
            (res.data.roleList &&
              res.data.roleList[0] &&
              res.data.roleList[0].roleId === 2 &&
              this.state.totalCount !== this.state.setpsCount)
          ) {
            window.location.href =
              "/properties/view/" +
              (res.data &&
              res.data.propertyList &&
              res.data.propertyList[0] &&
              res.data.propertyList[0].slug
                ? res.data.propertyList[0].slug
                : "") +
              "/building-onboarding";
          } else {
            window.location.href = "/properties";
          }
        } else if (
          res.data.roleList &&
          res.data.roleList[0] &&
          res.data.roleList[0].defaultLandingPage === 2
        ) {
          window.location.href = "/snapshot";
        } else {
          window.location.href = "/snapshot";
        }
      } else if (res.status === 401) {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error",
          alertModalMessage: res.data.message ? res.data.message : "Error",
        });
      } else if (res.status === 422) {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error",
          alertModalMessage: res.data.message ? res.data.message : "Error",
        });
      } else if (res.status === 400) {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error",
          alertModalMessage: res.data.message ? res.data.message : "Error",
        });
      } else {
        this.handleErrors(res);
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
      this.setState({ isSubmit: false });
    } else {
      this.validator.showMessages();
    }
  };

  maskEmail(email) {
    // Split the email into local part and domain part
    const [localPart, domain] = email.split("@");

    // Mask part of the local part
    const maskedLocalPart = localPart.slice(0, 2) + "****";

    // Combine masked local part with domain
    return `${maskedLocalPart}@${domain}`;
  }
  
  verifyOtp = async (e) => {
    e.preventDefault();
    this.setState({ isSubmit: true });
    let input = {
      user_token: this.state.user_token,
      otp: this.state.otp,
    };
    let res = await AuthenticationService.verifyOtp(input);
    if (global.successStatus.includes(res.status)) {
      localStorage.setItem(
        "headerToken",
        res.data.headerToken ? res.data.headerToken : ""
      );
      global.headerToken = res.data.headerToken ? res.data.headerToken : "";
      global.currerntRoleSlug = res.data.currerntRoleSlug
        ? res.data.currerntRoleSlug
        : "";
      localStorage.setItem("currerntRoleSlug", res.data.currerntRoleSlug);
      global.currerntRoleSlug = res.data.currerntRoleSlug
        ? res.data.currerntRoleSlug
        : "";
      localStorage.setItem("currentRoleTitle", res.data.currerntRoleTitle);
      global.currentRoleTitle = res.data.currerntRoleTitle
        ? res.data.currerntRoleTitle
        : "";
      localStorage.setItem(
        "currerntRoleGroup",
        res.data.currerntRoleGroup ? res.data.currerntRoleGroup : ""
      );
      // localStorage.setItem('isLogin', 1);
      localStorage.setItem("isPopupShow", 1);
      localStorage.setItem(
        "userData",
        res.data.user_data ? JSON.stringify(res.data.user_data) : {}
      );
      localStorage.setItem(
        "permissions",
        res.data.permissionList ? res.data.permissionList : []
      );
      localStorage.setItem(
        "roleList",
        res.data.roleList ? JSON.stringify(res.data.roleList) : {}
      );
      // localStorage.setItem('user_type', res.data.currerntRoleGroup ? res.data.currerntRoleGroup : '');
      localStorage.setItem(
        "propertyList",
        res.data.propertyList ? JSON.stringify(res.data.propertyList) : []
      );
      localStorage.setItem(
        "brandList",
        res.data.brandList ? JSON.stringify(res.data.brandList) : []
      );
      localStorage.setItem("messageCount", 1);
      localStorage.setItem("notifyCount", 1);
      localStorage.setItem("message_page", 1);
      this.setState({ messagePageActive: 1 });
      localStorage.setItem(
        "currerntPropSlug",
        res.data.propertyList ? res.data.propertyList[0].slug : ""
      );
      sessionStorage.setItem(
        "currerntPropSlug",
        res.data.propertyList ? res.data.propertyList[0].slug : ""
      );
      if (
        res.data &&
        res.data.propertyList &&
        res.data.propertyList[0] &&
        res.data.propertyList[0].slug
      ) {
        await this.getOnboardingSetpsCount(res.data.propertyList[0].slug);
      }
      if (
        res.data.roleList &&
        res.data.roleList[0] &&
        res.data.roleList[0].defaultLandingPage === 3
      ) {
        if (
          ((res.data.roleList &&
            res.data.roleList[0] &&
            res.data.roleList[0].roleId === 1) ||
            (res.data.roleList &&
              res.data.roleList[0] &&
              res.data.roleList[0].roleId === 2)) &&
          this.state.totalCount !== this.state.setpsCount
        ) {
          window.location.href =
            "/properties/view/" +
            (res.data &&
            res.data.propertyList &&
            res.data.propertyList[0] &&
            res.data.propertyList[0].slug
              ? res.data.propertyList[0].slug
              : "") +
            "/building-onboarding";
        } else {
          window.location.href = "/properties";
        }
      } else if (
        res.data.roleList &&
        res.data.roleList[0] &&
        res.data.roleList[0].defaultLandingPage === 1
      ) {
        window.location.href = "/dashboard";
      } else if (
        res.data.roleList &&
        res.data.roleList[0] &&
        res.data.roleList[0].defaultLandingPage === 4
      ) {
        if (
          (res.data.roleList &&
            res.data.roleList[0] &&
            res.data.roleList[0].roleId === 1) ||
          (res.data.roleList &&
            res.data.roleList[0] &&
            res.data.roleList[0].roleId === 2 &&
            this.state.totalCount !== this.state.setpsCount)
        ) {
          window.location.href =
            "/properties/view/" +
            (res.data &&
            res.data.propertyList &&
            res.data.propertyList[0] &&
            res.data.propertyList[0].slug
              ? res.data.propertyList[0].slug
              : "") +
            "/building-onboarding";
        } else {
          window.location.href = "/properties";
        }
      } else if (
        res.data.roleList &&
        res.data.roleList[0] &&
        res.data.roleList[0].defaultLandingPage === 2
      ) {
        window.location.href = "/snapshot";
      } else {
        window.location.href = "/snapshot";
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Invalid OTP",
        alertModalMessage: res.data.message
          ? res.data.message
          : "Invalid OTP. Please try again.",
        isSubmit: false,
      });
    }
  };

  resendOtp = async () => {
    this.setState({ loader: true });
    var inputData = {
      user_token: this.state.user_token,
    };
    let res = await AuthenticationService.resendOtp(inputData);
    var otp = "";
    var user_token = "";
    if (global.successStatus.includes(res.status)) {
      otp = res.data && res.data.otp ? res.data.otp : "";
      user_token = res.data && res.data.user_token ? res.data.user_token : "";
      this.setState({
        otp: otp,
        user_token: user_token,
      });
    }
    this.setState({ loader: false });
  };

  handleErrors(res) {
    if (
      res.data.result.errorDetail !== null &&
      res.data.result.errorDetail !== undefined
    ) {
      let errors = {};
      res.data.errorCode = "Validation Failed";
      res.data.result.errorDetail.forEach((item) => {
        errors[item.errorField] = item.errorMessage[0];
        res.data.errorMessage = "";
      });
      this.setState({ errors: errors });
    }
    this.setState({
      showAlertModal: true,
      alertModalType: "error",
      alertModalTitle: "Error",
      alertModalMessage: res.data.message ? res.data.message : "Error",
    });
  }

  async getOnboardingSetpsCount(slug) {
    var totalCount = 0;
    var setpsCount = 0;

    let res = await BuildingOnboardingService.getOnboardingStepsCount(slug);
    if (global.successStatus.includes(res.status)) {
      totalCount = res.data ? res.data.totalCount : 0;
      setpsCount = res.data ? res.data.setpsCount : 0;
    }

    this.setState({
      totalCount: totalCount,
      setpsCount: setpsCount,
      menuPermissonLoad: true,
    });
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.validator.showMessageFor(event.target.name);
  };

  getTitle() {
    return process.env.REACT_APP_PROJECT_TITLE
      ? process.env.REACT_APP_PROJECT_TITLE
      : "";
  }

  render() {
    return (
      <div>
        <div className="az-signin-wrapper">
          <div className="az-card-signin">
            <div className="az-logo d-flex flex-column">
              <img
                src={require("../../assets/images/ohai-logo.svg").default}
                alt=""
              ></img>
              <h2>Welcome To OHAI.</h2>
            </div>
            <div className="az-signin-header">
              <h3 className="text-center">Sign in</h3>
              {!this.state.isOtpSent && (
                <p className="text-center p-dark">
                  Please sign in to continue.
                </p>
              )}
              {this.state.isOtpSent && (
                <>
                  <p className="text-center p-dark">
                    Please enter the code to log in.
                  </p>
                  <p className="text-center p-dark">
                    <b>the code sent to :</b> {this.state.maskedEmail}
                  </p>
                </>
              )}
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              {!this.state.isOtpSent ? (
                <React.Fragment>
                  <form onSubmit={this.signIn}>
                    <div className="form-group">
                      <input
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.changeHandler}
                        maxLength="50"
                      />

                      {this.validator.message(
                        "email",
                        this.state.email,
                        "email|required",
                        { className: "text-danger" }
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.changeHandler}
                        maxLength="25"
                      />

                      {this.validator.message(
                        "password",
                        this.state.password,
                        "required",
                        { className: "text-danger" }
                      )}
                    </div>
                    <button
                      className="btn btn-info btn-block button-green"
                      type="submit"
                    >
                      {this.state.isSubmit ? global.loader : "Let's go"}
                    </button>
                  </form>
                  <div className="az-signin-footer text-center mt-3">
                    <p>
                      <Link to="/forgot-password" className="tx-16">
                        Forgot password?
                      </Link>
                    </p>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <form onSubmit={this.verifyOtp}>
                    <div className="form-group">
                      <input
                        name="otp"
                        className="form-control"
                        placeholder="Enter OTP"
                        value={this.state.otp}
                        onChange={this.changeHandler}
                        maxLength="6"
                      />

                      {this.validator.message(
                        "otp",
                        this.state.otp,
                        "required|numeric",
                        { className: "text-danger" }
                      )}
                    </div>
                    <button
                      className="btn btn-info btn-block button-green"
                      type="submit"
                    >
                      {this.state.isSubmit ? global.loader : "Verify OTP"}
                    </button>
                  </form>
                  <Timer
                    minutes={global.countdown_time} // env data ( Pass the no of minutes:seconds that you want count )
                    resendEvent={this.resendOtp.bind(this)}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="az-copyright text-center">
            <p>© {global.current_year} OHAI, Inc. All rights reserved.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Signin;
