//////////////////////////////////////////////////////////
//  Program: LisitingSidebar.jsx                        //
//  Application: Listing                                //
//  Option: common component Listing Sidebar            //
//  Developer: Ashish Kumar 		                        //
//  Date: 2022-04-06                                    //
//                                                      //
//////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";

class LisitingSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }
  
  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  toggleChildMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".left");
    data.classList.replace("left", "hide");
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Listings
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  {/* <span>Listings</span>  */}
                  <ul>
                  {global.userPermissions.checkPermission('listing-calendar-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("list-calendar")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/list-calendar"}
                      >
                        Calendar
                      </Link>
                    </li>
                  } 
                  {global.userPermissions.checkPermission('single-unit-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("listing")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/listing"}
                      >
                        Listings
                      </Link>
                    </li>
                  }
                  {global.userPermissions.checkPermission(
                      "home-sharing-inquiries"
                    ) && (
                      <li>
                       <Link
                          className={
                            this.isPathActive(
                                "/homesharing-inquiries/view"
                            ) || this.isPathActive(
                                "/homesharing-inquiries"
                            ) || this.isPathActive(
                                "/homesharing-inquiries/update/"
                            ) || this.isPathActive(
                                "/homesharing-inquiries/add"
                            )
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            window.location.pathname.includes("/properties/view/")
                                  ? "/properties/view/" + this.props.property_slug + "/homesharing-inquiries"
                                  : "/homesharing-inquiries"
                          }
                        >
                          Homesharing Inquiries
                        </Link>
                      </li>
                    )}
                    {/* {global.userPermissions.checkPermission('multi-unit-list') &&
                      <li>
                      <Link
                        className={
                          this.isPathActive("multi-unit-listing")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/multi-unit-listing"}
                      >
                        Multi Unit
                      </Link>
                    </li>
                    } */}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
             {/* New submenu */}
            <div className="newSubmenu">
            {/* <a href="/" className="back subMenu"> <i className="fas fa-chevron-left"></i> Listings</a> */}
            <span
              className="back"
              onClick={(event) => this.toggleHeaderMenu(event)}
            >
              <i className="fas fa-chevron-left"></i> Back to Listings
            </span>
            <ul>
              <li>
                {/* <span>Listings</span>  */}
                <ul>
                {global.userPermissions.checkPermission('listing-calendar-list') &&
                  <li>
                    <Link
                      className={
                        this.isPathActive("list-calendar")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/list-calendar"}
                    >
                      Calendar
                    </Link>
                  </li>
                } 
                {global.userPermissions.checkPermission('single-unit-list') &&
                  <li>
                    <Link
                      className={
                        this.isPathActive("listing")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/listing"}
                    >
                      Listings
                    </Link>
                  </li>
                }
                {global.userPermissions.checkPermission(
                      "home-sharing-inquiries"
                    ) && (
                      <li>
                       <Link
                          className={
                            this.isPathActive(
                                "/homesharing-inquiries/view"
                            ) || this.isPathActive(
                                "/homesharing-inquiries"
                            ) || this.isPathActive(
                                "/homesharing-inquiries/update/"
                            ) || this.isPathActive(
                                "/homesharing-inquiries/add"
                            )
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            window.location.pathname.includes("/properties/view/")
                                  ? "/properties/view/" + this.props.property_slug + "/homesharing-inquiries"
                                  : "/homesharing-inquiries"
                          }
                        >
                          Homesharing Inquiries
                        </Link>
                      </li>
                    )}
                  {/* {global.userPermissions.checkPermission('multi-unit-list') &&
                    <li>
                    <Link
                      className={
                        this.isPathActive("multi-unit-listing")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/multi-unit-listing"}
                    >
                      Multi Unit
                    </Link>
                  </li>
                  } */}
                </ul>
              </li>
            </ul>
            </div>
          </div>

        </div>
      </>
    );
  }
}
export default LisitingSidebar;
