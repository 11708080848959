///////////////////////////////////////////////////////////////
//  Program: ResidentPayoutsReportsEdit.jsx                  //
//  Application: Report                                      //
//  Option: Property Reports Listing                         //
//  Developer: Ashish Kumar	                                 //
//  Date: 2023-07-04                                         //
//                                                           //
///////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { Container, Tab, Modal, InputGroup, Form } from "react-bootstrap";
import PayoutSidebar from "../common/PayoutSidebar";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import ReportService from "../../services/ReportService";
import Select from "react-select";
import UnitsService from "../../services/UnitsService";
import Moment from "moment";
import MultiImageService from "./../../services/MultiImageService";
import Alerts from "../common/Alerts";
import AllPropertyPayoutSidebar from "../common/AllPropertyPayoutSidebar";

class PropertyPayoutsReportsEdit extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      create_payment_array: [],
      is_draft: false,
      is_pay_success: false,
      pay_amount_val: "",
      payAmountArray: [],
      errors: {},
      importModal: false,
      file_url: "",
      file_name: "",
      open: true,
      eventKey: "0",
      is_inactive: false,
      isSubmit: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      module_search: "",
      module_search_display: "",
      exportMesssage: "",
      exportFilePath: "",
      exportResult: "",
      alertType: "xlsx",
      is_search: 0,
      isSearch: global.isSearch,
      is_pay_out_modal: false,
      input: {},
      unitList: [],
      selectedUnitList: [],
      sendData: [],
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      totalPayOutList: {},
      deleteRoleModal: false,
      slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      pathname: window.location.pathname,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: true,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      configPay: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: true,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      newUnitList: [],
      extraButtons: [],
      columns: [
        {
          key: "unit_name",
          text: "Unit",
          className: "unit_name hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            if (!record.is_reservation) {
              return (
                <span id="to-pay" className="to-pay1 resident-to">
                  {"Property: " + record.unit_name}
                </span>
              );
            } else if (record.is_reservation === 2) {
              return <span className="future-row1"></span>;
            } else if (record.is_reservation === 1) {
              return record.unit_name ? record.unit_name : "";
            }
          },
        },
        {
          key: "Check In Date",
          text: "Check In",
          className: "checkin_date hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            if (record.is_reservation === 1) {
              return record.checkin_date
                ? Moment(record.checkin_date).format("MM/DD/YY")
                : "";
            } else if (record.is_reservation === 2) {
              return record.resident_name;
            }
            return record.resident_name ? record.resident_name : "";
          },
        },
        {
          key: "Check Out Date",
          text: "Check Out",
          className: "checkout_date hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            if (record.is_reservation === 1) {
              return record.checkout_date
                ? Moment(record.checkout_date).format("MM/DD/YY")
                : "";
            } else if (record.is_reservation === 2) {
              return "";
            }
            return "Mode: " + record.payment_method;
          },
        },
        {
          key: "Nights Booked",
          text: "No of Nights Booked",
          className: "total_stay_nights hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            if (record.is_reservation === 1) {
              return record.total_stay_nights ? record.total_stay_nights : "";
            } else if (record.is_reservation === 2) {
              return "";
            }
            return "Payment #: " + record.payment_ref_no;
          },
        },
        {
          key: "total_nightly_rate",
          text: "Nightly Revenue",
          className: "hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            if (record.is_reservation === 1) {
              return record.nightly_per_amount
                ? "$" +
                    global.onKeyPressEvent.numberWithCommasForDecimal(
                      record.nightly_per_amount
                    )
                : "";
            } else if (record.is_reservation === 2) {
              return "";
            }
            // return "Payment #: " + record.payment_ref_no;
          },
        },
        {
          key: "host_service_fee",
          text: "Channel Fees",
          className: "hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            if (record.is_reservation === 1) {
              return record.channel_fees
                ? "$" +
                    global.onKeyPressEvent.numberWithCommasForDecimal(
                      record.channel_fees
                    )
                : "";
            } else if (record.is_reservation === 2) {
              return "";
            }
            return record.payout_date
              ? "Payment Date: " + Moment(record.payout_date).format("MM/DD/YY")
              : "";
          },
        },
        {
          key: "resolution_fees",
          text: "Resolution Fees (Property $)",
          className: "cleaning_fee hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            if (record.is_reservation === 1) {
              return record.resolution_fees
                ? "$" +
                    global.onKeyPressEvent.numberWithCommasForDecimal(
                      record.resolution_fees
                    )
                : "";
            } else if (record.is_reservation === 2) {
              return "";
              //return "Note: " + record.payout_note;
            }
            return "Memo: " + record.memo;
          },
        },
        {
          key: "property_payout",
          text: "Property Payout",
          className: "cleaning_fee hand-cursor text-center td_clickable",
          width: 300,
          cell: (record) => {
            if (record.is_reservation === 1) {
              return record.payable_amount
                ? "$" +
                    global.onKeyPressEvent.numberWithCommasForDecimal(
                      record.payable_amount
                    )
                : "";
            } else if (record.is_reservation === 2) {
              // return record.extra_amount
              //   ? "$" +
              //       global.onKeyPressEvent.numberWithCommasForDecimal(
              //         record.extra_amount
              //       )
              //   : "";
            }
            return record.payout_total_payouts
              ? "$" +
                  global.onKeyPressEvent.numberWithCommasForDecimal(
                    record.payout_total_payouts
                  )
              : "";
          },
        },
      ],
    };
    this.saveOrUpdatePayout = this.saveOrUpdatePayout.bind(this);
    this.draftPayoutStatus = this.draftPayoutStatus.bind(this);
    this.succssPayoutStatus = this.succssPayoutStatus.bind(this);
    this.changeHandlerPayoutAmount = this.changeHandlerPayoutAmount.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.removeTotalClass = this.removeTotalClass.bind(this);
  }

  componentDidMount() {
    this.getPayOutReports();
    this.gerDefaultInOutDate();
  }

  async clearFilter() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.setState({ is_search: 0 });
    this.setState({
      checkout_date_from: firstDay,
      checkout_date_to: lastDay,
      unit_slug: "",
      selectedUnitList: [],
    });
    this.setState({ module_search: "" });
    await this.removeTotalClass();
    this.getPayOutReports();
  }

  saveOrUpdatePayout = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true, isSubmit: true });
    let crePaymentArray = [];
    if (this.state.sendData.length > 0) {
      let checkOutFromDate = this.state.checkout_date_from
        ? Moment(this.state.checkout_date_from).format("YYYY-MM-DD")
        : "";
      let checkOutDateTo = this.state.checkout_date_to
        ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
        : "";
      //CREATE ARRAY USING FOREACH LOOP OF API RESPONSE
      //CREATE TOTAL PAYMENT DATA
      //ASSIGN STATIC TOTAL VALUE
      this.state.sendData.forEach((item, i) => {
        let payData = {};
        if (item.unit_slug) {
          payData.unit_slug = item.unit_slug;
          payData.payment_mode = "CASH";
          payData.payment_method = "CASH";
          payData.payment_reference = "ACVDFRE56788";
          payData.resident_slug = item.resident_slug;
          payData.payout_total_payouts = item.resident_earning;
          payData.payout_total_stay_nights = item.total_stay_nights;
          payData.payout_total_bookings =
            item.total_booking_count !== undefined
              ? item.total_booking_count
              : 0;
          payData.payout_start_date = checkOutFromDate;
          payData.payout_end_date = checkOutDateTo;
          payData.is_payment_success_status = 1;
          payData.memo = "ABC STATIC";
          if (item.reservation_slug != "") {
            payData.reservations = [
              {
                reservation_slug: item.reservation_slug,
                payout_amount: item.resident_earning,
              },
            ];
          } else {
            payData.reservations = [];
          }

          let unitExist = 0;

          crePaymentArray.forEach((paymentValue, paymentIndex) => {
            if (
              paymentValue.unit_slug === item.unit_slug &&
              item.reservation_slug === ""
            ) {
              crePaymentArray[paymentIndex].payment_mode = "CASH";
              crePaymentArray[paymentIndex].payment_method = "CASH";
              crePaymentArray[paymentIndex].payment_reference = "ACVDFRE56788";
              crePaymentArray[paymentIndex].resident_slug = item.resident_slug;
              crePaymentArray[paymentIndex].payout_total_payouts =
                item.resident_earning;
              crePaymentArray[paymentIndex].payout_total_stay_nights =
                item.total_stay_nights;
              crePaymentArray[paymentIndex].payout_total_bookings =
                item.total_booking_count;
              crePaymentArray[paymentIndex].payout_start_date =
                checkOutFromDate;
              crePaymentArray[paymentIndex].payout_end_date = checkOutDateTo;
              crePaymentArray[paymentIndex].is_payment_success_status = 1;
              crePaymentArray[paymentIndex].memo = "TEST";
              unitExist = 1;
            }
            if (
              paymentValue.unit_slug === item.unit_slug &&
              item.reservation_slug != ""
            ) {
              crePaymentArray[paymentIndex].reservations.push({
                reservation_slug: item.reservation_slug,
                payout_amount: item.resident_earning,
              });
              unitExist = 1;
            }
          });
          if (unitExist === 0) {
            crePaymentArray.push(payData);
          }
        }
      });
    }

    let inputData = {
      property_slug: this.state.property_slug
        ? this.state.property_slug
        : this.props.property_slug,
      payment_summary: crePaymentArray,
    };

    let res = await ReportService.createPropertyPayout(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.props.match.params.propertySlug !== undefined
              ? "/properties/view/" +
                  this.state.property_slug +
                  "/property-payout-summary"
              : "/property-payout-summary"
          ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.message ? res.message : "Error!",
        alertModalMessage: alertMessage !== "" ? alertMessage : res.message,
      });
    }

    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  draftPayoutStatus = async (e) => {
    this.setState({
      is_draft: true,
      is_pay_success: false,
    });
  };

  succssPayoutStatus = async (e) => {
    this.setState({
      is_pay_success: true,
      is_draft: false,
    });
  };

  async ExportData() {
    this.setState({ showSpinner: true, isSubmit: true });
    let data = {
      property_slug: this.state.property_slug,
    };

    let checkOutFromDate = this.state.checkout_date_from
      ? Moment(this.state.checkout_date_from).format("YYYY-MM-DD")
      : "";
    let checkOutDateTo = this.state.checkout_date_to
      ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
      : "";
    let queryString =
      "is_export=1" +
      "&file_type=" +
      this.state.alertType +
      "&checkout_date_from=" +
      checkOutFromDate +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&checkout_date_to=" +
      checkOutDateTo;

    let res = await ReportService.exportPropertyPayOutReport(data, queryString);
    if (res.data.filePath) {
      this.getDownloadFile(res.data.filePath, res.data.fileName);
      this.setState({ exportMesssage: res.message });
      this.setState({ exportResult: data });
      this.setState({ exportFilePath: res.data.filePath });
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  async getDownloadFile(url, name) {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  exportToggle = () => {
    if (this.state.isModel === 1) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: 0 });
      this.setState({ showSpinner: false, isSubmit: false });
    } else {
      this.setState({ isModel: 1 });
    }
  };

  gerDefaultInOutDate() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.setState({ checkout_date_from: firstDay });
    this.setState({ checkout_date_to: lastDay });
  }

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }

  async showPayoutReport(unitSlug, propertySlug) {
    this.setState({ showSpinner: true, loading: true });
    var listPay = [];
    var totalRecordsPay = 0;
    let payoutDetailsTitle;
    let data = {
      property_slug: this.state.property_slug,
    };
    let checkOutFromDate = this.state.checkout_date_from
      ? Moment(this.state.checkout_date_from).format("YYYY-MM-DD")
      : "";
    let checkOutDateTo = this.state.checkout_date_to
      ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
      : "";
    let queryString =
      "unit_slug=" +
      unitSlug +
      "&checkout_date_from=" +
      checkOutFromDate +
      "&checkout_date_to=" +
      checkOutDateTo;
    let res = await ReportService.showPayOutReport(data, queryString);
    if (global.successStatus.includes(res.status)) {
      listPay = res.data.data ? res.data.data : [];
      totalRecordsPay = res.data.data.length ? res.data.data.length : 0;
      payoutDetailsTitle = res.data.data.length
        ? res.data.data[0].aka_name + "-" + res.data.data[0].unit_number
        : "Pay Out Report";
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record_pay: totalRecordsPay,
      record_pay: listPay,
      payoutDetailsTitle: payoutDetailsTitle,
    });
    this.payoutDataModalToggle();
  }

  payoutDataModalToggle = () => {
    if (this.state.is_pay_out_modal === 1) {
      this.setState({ is_pay_out_modal: 0 });
    } else {
      this.setState({ is_pay_out_modal: 1 });
    }
  };

  getPayOutReports = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var file_name = "";
    var file_url = "";
    var slug_query = "";
    var totalRecords = 0;
    if (this.state.slug !== undefined) {
      slug_query = "summary_slug=" + this.state.slug;
    }
    let res = await ReportService.getPropertyPayOutReportsView(
      queryString +
        slug_query +
        (this.state.property_slug
          ? "&property_slug=" + this.state.property_slug
          : "") +
        "&payout_type=2"
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    if (list.length > 0) {
      let last_val = list.length - 1;
      file_name =
        list[last_val].payout_file_name !== undefined
          ? list[last_val].payout_file_name
          : "";
      file_url =
        list[last_val].payout_file_url !== undefined
          ? list[last_val].payout_file_url
          : "";
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
      sendData: res.data,
      file_name: file_name,
      file_url: file_url,
    });
    this.addTotalClass();
  };

  /** Add Class on page load */
  addTotalClass = async () => {
    const child = document.getElementsByClassName("to-pay1");
    let childNo = child.length;
    for (let i = 0; i < childNo; i++) {
      let ele = child[i].parentElement.parentElement;
      ele.classList.add("to_total");
    }
    const childDiff = document.getElementsByClassName("diff-line1");
    let childNoDiff = childDiff.length;
    for (let i = 0; i < childNoDiff; i++) {
      let ele = childDiff[i].parentElement.parentElement;
      if (ele) {
        ele.classList.add("to_diff");
      }
    }
    const childFut = document.getElementsByClassName("future-row1");
    let childNoFut = childFut.length;
    for (let i = 0; i < childNoFut; i++) {
      let ele = childFut[i].parentElement.parentElement;
      if (ele) {
        ele.classList.add("to_future");
      }
    }
    if (document.getElementsByClassName("grand_total1").length > 0) {
      const grand_total = document.getElementsByClassName("grand_total1")[0];
      let ele1 = grand_total.parentElement.parentElement;
      ele1.classList.add("grand_total");
    }
    if (document.getElementsByClassName("future-grand1").length > 0) {
      const grand_total_fu =
        document.getElementsByClassName("future-grand1")[0];
      let ele2 = grand_total_fu.parentElement.parentElement;
      ele2.classList.add("grand_total_future");
    }
    if (document.getElementsByClassName("past-grand1").length > 0) {
      const grand_total_fu = document.getElementsByClassName("past-grand1")[0];
      let ele2 = grand_total_fu.parentElement.parentElement;
      ele2.classList.add("grand_total_past");
    }
  };

  removeTotalClass = async () => {
    const child = document.getElementsByClassName("to-pay1");
    let childNo = child.length;
    for (let i = 0; i < childNo; i++) {
      let ele = child[i].parentElement.parentElement;
      ele.classList.remove("to_total");
    }
    const childFut = document.getElementsByClassName("future-row1");
    let childNoFut = childFut.length;
    for (let i = 0; i < childNoFut; i++) {
      let ele = childFut[i].parentElement.parentElement;
      if (ele) {
        ele.classList.remove("to_future");
      }
    }
    const childDiff = document.getElementsByClassName("diff-line1");
    let childNoDiff = childDiff.length;
    for (let i = 0; i < childNoDiff; i++) {
      let ele = childDiff[i].parentElement.parentElement;
      if (ele) {
        ele.classList.remove("to_diff");
      }
    }
    if (document.getElementsByClassName("grand_total1").length > 0) {
      const grand_total = document.getElementsByClassName("grand_total1")[0];
      let ele1 = grand_total.parentElement.parentElement;
      ele1.classList.remove("grand_total");
    }
    if (document.getElementsByClassName("future-grand1").length > 0) {
      const grand_total_fu =
        document.getElementsByClassName("future-grand1")[0];
      let ele2 = grand_total_fu.parentElement.parentElement;
      ele2.classList.remove("grand_total_future");
    }
    if (document.getElementsByClassName("past-grand1").length > 0) {
      const grand_total_fu = document.getElementsByClassName("past-grand1")[0];
      let ele2 = grand_total_fu.parentElement.parentElement;
      ele2.classList.remove("grand_total_past");
    }
  };

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;
    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" +
        (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_name, tower, total_tower_count }) => ({
            value: slug,
            label: unit_name,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getUnitData();
      this.setState({ isSearch: 1 });
    }
  };

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    const selected_unit_number = value !== null ? value.label : [];
    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
      unit_lable: selected_unit_number,
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeHandlerPayoutAmount = async (
    event,
    index,
    reservation_slug,
    resident_earning
  ) => {
    let payamountVal = event.target.value;
    var payoutRowArray = [];
    payoutRowArray.push({
      reservation_slug: reservation_slug,
      payout_amount: payamountVal,
    });
  };

  changeHandler = async (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    await this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  async filterData() {
    let checkOutFromDate = this.state.checkout_date_from
      ? Moment(this.state.checkout_date_from).format("YYYY-MM-DD")
      : "";
    let checkOutDateTo = this.state.checkout_date_to
      ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
      : "";
    let queryString =
      "&checkout_date_from=" +
      checkOutFromDate +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&checkout_date_to=" +
      checkOutDateTo +
      "&is_search=1";
    let moduleSearchString = "";
    let searchDisplay = "";
    moduleSearchString = this.state.checkout_date_from
      ? "check in date - " + this.state.checkout_date_from
      : "";
    moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString + " &" + " unit - " + this.state.unit_slug
        : " unit - " + this.state.unit_slug
      : moduleSearchString;
    searchDisplay =
      "Check In Date- " +
      Moment(checkOutFromDate).format("MM/DD/YY") +
      ", " +
      "Check Out Date- " +
      Moment(checkOutDateTo).format("MM/DD/YY");
    if (this.state.unit_lable) {
      searchDisplay = searchDisplay + ", Unit- " + this.state.unit_lable;
    }
    if (
      this.state.checkout_date_from ||
      this.state.unit_slug ||
      this.state.checkout_date_to
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString });
      this.setState({ module_search_display: searchDisplay });
      await this.removeTotalClass();
      this.getPayOutReports(queryString);
    }
  }

  /* table events */
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getPayOutReports(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getPayOutReports(queryString);
    }
  };

  rowClickedHandlerPay = (event, data, rowIndex) => {
    if (data.unit_slug) {
      this.showPayoutReport(data.unit_slug);
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {
    /* if (data.reservation_slug) {
      let route = "reservations/view/" + data.reservation_slug;
      window.open(route, "_blank");
    } */
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    {this.props.match.params.propertySlug !== undefined ? (
                      <PayoutSidebar property_slug={this.state.property_slug} />
                    ) : (
                      <AllPropertyPayoutSidebar
                        property_slug={this.state.property_slug}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-10 right">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>Generated Property Payout</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {this.state.file_url !== "" && (
                          <button
                            className="btn-success-outline-small mr-3"
                            onClick={() =>
                              this.getDownloadFile(
                                this.state.file_url,
                                this.state.file_name
                              )
                            }
                          >
                            Download
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn-success-outline-small"
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/reservations"
                                    : "/resevations"
                                );
                              else return false;
                            else
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/property-payout-summary"
                                  : "/property-payout-summary"
                              );
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-lg-l-0 pd-lg-r-15 pd-xs-r-0">
                    <div className="scrolling-carousel pd-l-15 scroll-slide">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="all"
                        onSelect={(e) => this.getActiveClass(e)}
                        eventKey={this.state.activeClass}
                        className="pd-r-60"
                      >
                        <Tab.Content className="m-0">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <div className="mg-t-18 brandList pd-l-15 pd-r-15 payout-report-tbl- resident-payout-table earning-report-tbl">
                              <div className=" pd-lg-l-0 pd-xs-l-0">
                                <div className="scroll-report-table">
                                  <ReactDatatable
                                    className="table no-wrap mg-b-30 pd-b-2  brandtable border table_last_row"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Create Payout</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Check Out From Date</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="checkout_date_from"
                    selected={this.state.checkout_date_from}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "checkout_date_from",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Check Out To Date</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="checkout_date_to"
                      selected={this.state.checkout_date_to}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "checkout_date_to",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={() => this.filterData()}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Export  popup modal */}
        <Modal show={this.state.isModel} className="modal-xs" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.exportToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Export</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <InputGroup className="mt-0">
                  <label>Select Export File Type : </label>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="xlsx"
                        checked={this.state.alertType === "xlsx" ? true : false}
                        type={"radio"}
                        id="xlsx"
                        label={"XLSX"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="csv"
                        checked={this.state.alertType === "csv" ? true : false}
                        type={"radio"}
                        id="csv"
                        label={"CSV"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="pdf"
                        checked={this.state.alertType === "pdf" ? true : false}
                        type={"radio"}
                        id="pdf"
                        label={"PDF"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                  </div>
                </InputGroup>

                {this.state.exportMesssage !== "" && (
                  <div>
                    {this.state.exportMesssage}
                    <br></br>
                    <br></br>
                    {/* {this.state.exportFilePath !== undefined && (
                      <a href={this.state.exportFilePath}> Download File </a>
                    )} */}
                  </div>
                )}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="mx-auto- mg-r-30 btn btn btn-success btn-block"
                    onClick={() => this.ExportData()}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Export"}
                  </button>
                  <button
                    type="button"
                    className="mx-auto- m-0  btn btn-outline-light btn-block"
                    onClick={() => this.exportToggle()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Pay Out Report Data */}
        <Modal
          show={this.state.is_pay_out_modal}
          className="modal-large searchModalbox"
          centered
          size="lg"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.payoutDataModalToggle()}
            >
              {global.closee}
            </span>
            <h3 className="text-center mb-0">
              {this.state.payoutDetailsTitle} Payout Detail
            </h3>
            <div className="row col-12 m-auto pd-lg-l-15 pd-lg-r-0 pd-xs-l-0 pd-xs-r-0">
              <div className="payout-report-tbl payout-details-tbl">
                <ReactDatatable
                  className="table no-wrap mg-b-30 pd-b-2 brandtable border table_last_row"
                  config={
                    this.state.total_record_pay > global.page_size
                      ? this.state.configPay
                      : this.state.configPay
                  }
                  records={this.state.record_pay}
                  columns={this.state.columns_pay}
                  extraButtons={this.state.extraButtons}
                  dynamic={true}
                  total_record={this.state.total_record_pay}
                  onChange={this.tableChangeHandler}
                  onRowClicked={this.rowClickedHandlerPay}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default PropertyPayoutsReportsEdit;
