//     							                          //
//  Program: .jsx                            //
//  Application: Property                                   //
//  Option: Home Sharing Listing                               //
//  Developer: Somin Dadhaniya  						              //
//  Date: 2023-09-08                                               //
//                                                             //
/////////////////////////////////////////////////////////     ///
import React, { Component } from "react";
import { Container, Tabs, Tab, Form } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import CountryService from "../../services/CountryService";
import CountyService from "../../services/CountyService";
import StateService from "../../services/StateService";
import ClubService from "../../services/ClubService";
import ClientService from "../../services/ClientService";
import UserService from "../../services/UserService";
import brandLogo from "./../../assets/images/brand_placeholder.png";
import Alerts from "../common/Alerts";
import CityService from "../../services/CityService";
import PhoneInput from "react-phone-input-2";


export class ClubForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      errors: {},
      slug: this.props.match.params.slug,
      isSubmit: false,
      club_logo: "",
      club_main_image: "",
      imageUrl: brandLogo,
      status_id: false,
      sort_order: "",
      confirm_back: 0,
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      clientList: [],
      selectedClientList: [],
      newClientList: [],
      clientListTotal: 0,
      countyListTotal: 0,
      cityListTotal: 0,
      orion_haus_account_manager_slug: "",
      asset_manager_slug: "",
      heading: "",
      currerntRoleSlug: global.currerntRoleSlug
        ? global.currerntRoleSlug
        : localStorage.getItem("currerntRoleSlug")
          ? localStorage.getItem("currerntRoleSlug")
          : "",
      currentRoleTitle: global.currentRoleTitle
        ? global.currentRoleTitle
        : localStorage.getItem("currentRoleTitle")
          ? localStorage.getItem("currentRoleTitle")
          : "",
      accountManagerList: [],
      newAccountManagerList: [],
      selectedAccountManagerList: [],
      assetManagerList: [],
      newAssetManagerList: [],
      selectedAssetManagerList: [],
    };
    this.brandLogo = React.createRef();
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateClub = this.saveOrUpdateClub.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleChangeClientList = this.handleChangeClientList.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
    this.removePhotoMainImage = this.removePhotoMainImage.bind(this)
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    if (this.state.slug !== undefined) {
      this.getCountyData();
      this.getStateData();
      this.getCityData();
      var resData = {};
      let res = await ClubService.getClub(this.state.slug);

      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.club_name = res.data.club_name;
        resData.aka_name = res.data.aka_name;
        resData.legal_entity_name = res.data.legal_entity_name;
        resData.tax_id = res.data.tax_id;
        resData.club_logo = res.data.club_logo;
        resData.heading = res.data.header;
        resData.imageUrl = res.data ? res.data.club_logo_url : brandLogo;
        resData.imageUrlMainImage = res.data
          ? res.data.club_main_image_url
          : brandLogo;
        resData.street_1 = res.data.street_1;
        resData.street_2 = res.data.street_2;
        resData.city = res.data.city;
        resData.country_id = res.data.country_slug ? res.data.country_slug : "";
        resData.county_id = res.data.county_slug ? res.data.county_slug : "";
        resData.state_id = res.data.state_slug ? res.data.state_slug : "";
        resData.city_id = res.data.city_slug ? res.data.city_slug : "";
        resData.country_name = res.data.country
          ? res.data.country.country_name
          : "";
        resData.state_name = res.data.state ? res.data.state.state_name : "";
        resData.zipcode = res.data.zipcode;
        resData.contact_name = res.data.contact_name;
        resData.phone_number = res.data.phone_number;
        resData.email = res.data.email;
        resData.term_condition = res.data.term_condition
          ? res.data.term_condition
          : "";
        resData.website_cookie = res.data.website_cookie
          ? res.data.website_cookie
          : "";
        resData.club_domain = res.data.club_domain;
        resData.sort_order =
          res.data.sort_order === 0 ? "" : res.data.sort_order;
        resData.status_id = res.data.status_id === 1 ? true : false;

        resData.orion_haus_account_manager_slug =
          res.data &&
            res.data.orion_haus_account_manager &&
            res.data.orion_haus_account_manager.slug
            ? res.data.orion_haus_account_manager.slug
            : "";
        resData.asset_manager_slug =
          res.data && res.data.asset_manager && res.data.asset_manager.slug
            ? res.data.asset_manager.slug
            : "";

        var selectedClientList = [];
        selectedClientList = res.data.client
          ? [
            {
              value: res.data.client.slug,
              label: res.data.client.client_name,
            },
          ]
          : "";
        var selectedCityList = [];
        selectedCityList = res.data.city
          ? [
            {
              value: res.data.city.slug,
              label: res.data.city.city_name,
            },
          ]
          : "";
        var selectedStateList = [];
        selectedStateList = res.data.state
          ? [
            {
              value: res.data.state.slug,
              label: res.data.state.state_name,
            },
          ]
          : "";
        var selectedCountyList = [];
        selectedCountyList = res.data.county
          ? [
            {
              value: res.data.county.slug,
              label: res.data.county.county_name,
            },
          ]
          : "";
        var selectedCountryList = [];
        selectedCountryList = res.data.country
          ? [
            {
              value: res.data.country.slug,
              label: res.data.country.country_name,
            },
          ]
          : "";
        var selectedAccountManagerList = [];
        selectedAccountManagerList = res.data.orion_haus_account_manager
          ? [
            {
              value: res.data.orion_haus_account_manager.slug,
              label:
                res.data.orion_haus_account_manager.first_name +
                " " +
                res.data.orion_haus_account_manager.last_name,
            },
          ]
          : "";
        var selectedAssetManagerList = [];
        selectedAssetManagerList = res.data.asset_manager
          ? [
            {
              value: res.data.asset_manager.slug,
              label:
                res.data.asset_manager.first_name +
                " " +
                res.data.asset_manager.last_name,
            },
          ]
          : "";
      }
      this.setState(resData);
      this.setState({
        selectedClientList: selectedClientList,
        selectedCityList: selectedCityList,
        selectedStateList: selectedStateList,
        selectedCountyList: selectedCountyList,
        selectedCountryList: selectedCountryList,
        selectedAccountManagerList: selectedAccountManagerList,
        selectedAssetManagerList: selectedAssetManagerList,
      });

      this.defaultData = resData;
    }
    this.getClientData();
    this.getCountryData();
    this.getCityData();
    this.getAccountManagerList();
    this.getAssetManagerList();
  }


  //Domin name validation REGEX
  urlPatternValidation = URL => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(URL);
  };


  onImageChange = async (event) => {
    this.setState({ showSpinner: true, loading: true });
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "club_logo",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
      );
      fileData.append("mediaType", "1");
      let res = await ClubService.uploadClubLogo(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          club_logo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
        this.state.errors.club_logo = "";

      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
        this.validator.hideMessageFor("club_logo")
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
    this.setState({ showSpinner: false, loading: false });
  };

  onMainImageChange = async (event) => {
    this.setState({ showSpinner: true, loading: true });
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "club_main_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
      );
      fileData.append("mediaType", "1");
      let res = await ClubService.uploadClubMainImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          club_main_image: res.data.fileName,
          imageUrlMainImage: res.data.filePath,
        });
        this.setState({ showSpinner: false });
        this.state.errors.club_main_image = "";
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
    this.setState({ showSpinner: false, loading: false });
  };

  async getCountryData(queryString = "") {
    var countryList = [];

    let res = await CountryService.getCountry(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
    }
    const newCountryList = countryList.map(({ slug, country_name }) => ({
      value: slug,
      label: country_name,
    }));
    this.setState({ countryList: countryList, newCountryList: newCountryList });
  }

  async getStateData(countryValue, queryString = "") {
    var stateList = [];

    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
      countryValue +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
    }
    const newStateList = stateList.map(({ slug, state_name }) => ({
      value: slug,
      label: state_name,
    }));
    this.setState({ stateList: stateList, newStateList: newStateList });
  }

  async getCountyData(value, queryString = "") {
    var countyList = [];
    var countyListTotal = 0;

    let res = await CountyService.getCounty(
      "is_dropdown=1&state_slug=" +
      value +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
      countyListTotal = countyList ? countyList.length : 0;
    }
    const newCountyList =
      countyListTotal > 0
        ? countyList.map(({ slug, county_name }) => ({
          value: slug,
          label: county_name,
        }))
        : [];
    this.setState({
      countyList: countyList,
      newCountyList: newCountyList,
      countyListTotal: countyListTotal,
    });
  }

  async getCityData(stateValue, countyValue, queryString = "") {
    var cityList = [];
    var cityListTotal = 0;
    let res = await CityService.getCity(
      "is_dropdown=1&state_slug=" + stateValue + "&county_slug=" + countyValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList =
      cityListTotal > 0
        ? cityList.map(({ slug, city_name }) => ({
          value: slug,
          label: city_name,
        }))
        : [];
    this.setState({
      cityList: cityList,
      newCityList: newCityList,
      cityListTotal: cityListTotal,
    });
  }

  async getClientData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var clientList = [];
    var clientListTotal = 0;
    let res = await ClientService.getClientList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      clientList = res.data ? res.data : [];
      clientListTotal = clientList ? clientList.length : 0;
    }
    const newClientList =
      clientListTotal > 0
        ? clientList.map(({ slug, client_name }) => ({
          value: slug,
          label: client_name,
        }))
        : [];
    this.setState({
      clientList: clientList,
      newClientList: newClientList,
      clientListTotal: clientListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdateClub = async (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        club_name: this.state.club_name,  //DBA Name
        aka_name: this.state.aka_name,   // SKU Name
        legal_entity_name: this.state.legal_entity_name,
        tax_id: this.state.tax_id,
        club_logo: this.state.club_logo,
        club_main_image: this.state.club_main_image,
        heading: this.state.heading,
        contact_name: this.state.contact_name,
        phone_number: this.state.phone_number,
        dial_code: this.state.dial_code,
        email: this.state.email,
        club_domain: this.state.club_domain,
        term_condition: this.state.term_condition,
        website_cookie: this.state.website_cookie,
        status_id: this.state.status_id === true ? 1 : 2,
        sort_order: this.state.sort_order,
      };

      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        let res = await ClubService.updateClub(this.state.slug, inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/club/view/" + this.state.slug + "/club-info"
              ),
            global.redirect_time
          );
        } else {
          // let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
            const input = document.getElementsByClassName("text-danger");
            if (input.length > 0) {
              input[0].scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start",
              });
            }
          }

        }
      } else {
        let res = await ClubService.createClub(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/clubs"),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
            //scroll to error logic
            const input = document.getElementsByClassName("text-danger");
            if (input.length > 0) {
              input[0].scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start",
              });
            }
          }

        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  async getAllRealetedData(slug, queryString = "") {
    var resData = {};
    let res = await CityService.getDataByCitySlug(
      slug,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      resData.country_id = res.data.countryData
        ? res.data.countryData.country.slug
        : "";
      resData.country_name = res.data.countryData
        ? res.data.countryData.country.country_name
        : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.countryData.country
        ? [
          {
            value: res.data.countryData.country
              ? res.data.countryData.country.slug
              : "",
            label: res.data.countryData.country
              ? res.data.countryData.country.country_name
              : "",
          },
        ]
        : "";
      resData.county_id = res.data.county ? res.data.county.slug : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county
        ? [
          {
            value: res.data.county.slug ? res.data.county.slug : "",
            label: res.data.county.county_name
              ? res.data.county.county_name
              : "",
          },
        ]
        : "";
      resData.state_id = res.data.state ? res.data.state.slug : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      var selectedStateList = [];
      selectedStateList = res.data.state
        ? [
          {
            value: res.data.state.slug ? res.data.state.slug : "",
            label: res.data.state.state_name ? res.data.state.state_name : "",
          },
        ]
        : "";
    }
    this.setState(resData);
    this.setState({
      selectedStateList: selectedStateList,
      selectedCountyList: selectedCountyList,
      selectedCountryList: selectedCountryList,
    });
  }

  changeHandler = (event) => {
    this.setState({ errors: "" });
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate(event);
    this.setState({ confirm_back: 1 });
  };

  handleChangeClientList(value) {
    let client_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        client_id.push(item.value);
      });
    }
    this.setState({
      selectedClientList: value,
      client_id: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  async getAccountManagerList() {
    var accountManagerList = [];

    let res = await UserService.getUserDropdown(
      "roleTitle=ADMIN"
    );
    if (global.successStatus.includes(res.status)) {
      accountManagerList = res.data ? res.data : [];
    }
    const newAccountManagerList = accountManagerList.map(
      ({ slug, first_name, last_name }) => ({
        value: slug,
        label: first_name + " " + last_name,
      })
    );
    this.setState({
      newAccountManagerList: newAccountManagerList,
      accountManagerList: accountManagerList,
    });
  }

  async getAssetManagerList() {
    var assetManagerList = [];

    let res = await UserService.getUserDropdown(
      "roleTitle=ASSET_MANAGER"
    );
    if (global.successStatus.includes(res.status)) {
      assetManagerList = res.data ? res.data : [];
    }
    const newAssetManagerList = assetManagerList.map(
      ({ slug, first_name, last_name }) => ({
        value: slug,
        label: first_name + " " + last_name,
      })
    );
    this.setState({
      newAssetManagerList: newAssetManagerList,
      assetManagerList: assetManagerList,
    });
  }

  handleChangeAccountManagerList(value) {
    let orion_haus_account_manager_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        orion_haus_account_manager_slug.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedAccountManagerList: value,
      orion_haus_account_manager_slug: state_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeAssetManagerList(value) {
    let asset_manager_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        asset_manager_slug.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedAssetManagerList: value,
      asset_manager_slug: state_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCityList(value) {
    let city_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_id.push(item.value);
      });
    }
    if (value !== null) {
      this.getAllRealetedData(value.value);
    }
    const city_id_value = value !== null ? value.value : [];
    this.setState({
      selectedCityList: value,
      city_id: city_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeStateList(value) {
    let state_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_id.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStateList: value,
      state_id: state_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCountyList(value) {
    let county_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        county_id.push(item.value);
      });
    }

    const county_id_value = value !== null ? value.value : [];
    this.setState({
      selectedCountyList: value,
      county_id: county_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCountryList(value) {
    let country_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_id.push(item.value);
      });
    }
    const country_id_value = value !== null ? value.value : [];
    this.setState({
      selectedCountryList: value,
      country_id: country_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
  }

  customValidate(event) {

    if (event.target.name == "club_domain") {
      const isTrueVal = !event.target.value || this.urlPatternValidation(event.target.value);
      return isTrueVal;
    }

    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update Brand";
    } else {
      return "Add Brand";
    }
  }

  removePhoto() {
    this.setState({ imageUrl: "" });
  }
  removePhotoMainImage() {
    this.setState({ imageUrlMainImage: "" });
  }

  render() {
    return (
      <div>
        <main>
          <Container className="innter-container pr-0 over-fl">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-12">
                  <Container>
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap ">
                          <h3>
                            {this.state.slug !== undefined
                              ? this.state.club_name
                                ? this.state.club_name
                                : ""
                              : "Club"}
                          </h3>
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn-success-outline"
                              onClick={() => {
                                if (this.state.confirm_back === 1)
                                  if (
                                    window.confirm(
                                      "Do you want to exit without saving?"
                                    )
                                  )
                                    this.state.slug !== undefined
                                      ? this.props.history.push(
                                        "/club/view/" +
                                        this.state.slug +
                                        "/club-info"
                                      )
                                      : this.props.history.push("/clubs");
                                  else return false;
                                else
                                  this.state.slug !== undefined
                                    ? this.props.history.push(
                                      "/club/view/" +
                                      this.state.slug +
                                      "/club-info"
                                    )
                                    : this.props.history.push("/clubs");
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn-success ml-3"
                              type="button"
                              onClick={this.saveOrUpdateClub}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : this.state.slug !== undefined
                                  ? "Save"
                                  : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 pd-lg-l-30 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="col-md-12 mg-t-15 pd-lg-r-0 pd-xs-r-15">
                      <div className="tabNavigation">
                        <Tabs
                          defaultActiveKey="Basics"
                          id="uncontrolled-tab-example"
                        >
                          <Tab eventKey="Basics" title="Basics">
                            <div className="m-0">
                              <div className="row align-items-center pd-t-0 pd-lg-b-30 pd-xs-b-0 border-bottom-0 mg-lg-t-15 mg-xs-t-15">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    DBA
                                  </label>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="club_name"
                                    value={this.state.club_name || ""}
                                    maxLength="50"
                                  />
                                  {this.state.errors.club_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.club_name}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "dba",
                                      this.state.club_name,
                                      "required|max:50",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Club SKU
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    onKeyPress={
                                      global.onKeyPressEvent.alphaNumValidation
                                    }
                                    name="aka_name"
                                    value={this.state.aka_name || ""}
                                    maxLength="3"
                                  />
                                  {this.state.errors.aka_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.aka_name}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "club_sku",
                                      this.state.aka_name,
                                      "required|alpha_num_dash|max:3",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Legal Entity{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    maxLength="50"
                                    type="text"
                                    name="legal_entity_name"
                                    value={this.state.legal_entity_name || ""}
                                  />
                                  {this.state.errors.legal_entity_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.legal_entity_name}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "legal_entity_name",
                                      this.state.legal_entity_name,
                                      "max:50",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Tax ID{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="tax_id"
                                    value={this.state.tax_id || ""}
                                    maxLength="50"
                                    type="text"
                                  />
                                  {this.state.errors.tax_id ? (
                                    <div className="text-danger">
                                      {this.state.errors.tax_id}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "tax_id",
                                      this.state.tax_id,
                                      "max:50",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>

                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Domain Name
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="club_domain"
                                    value={this.state.club_domain || ""}
                                    maxLength="70"
                                  />
                                  {this.state.errors.club_domain ? (
                                    <div className="text-danger">
                                      {this.state.errors.club_domain}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "domain name",
                                      this.state.club_domain,
                                      "url|required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Contact Name{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="contact_name"
                                    value={this.state.contact_name || ""}
                                    maxLength="50"
                                  />
                                  {this.state.errors.contact_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.contact_name}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "contact_name",
                                      this.state.contact_name,
                                      "max:50",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>

                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Phone{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <PhoneInput
                                    country={global.country}
                                    enableSearch="true"
                                    countryCodeEditable="true"
                                    value={this.state.phone_number}
                                    onChange={(phone_number) =>
                                      this.setState({ phone_number })
                                    }
                                  />
                                  {this.state.errors.phone_number ? (
                                    <div className="text-danger">
                                      {this.state.errors.phone_number}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "phone",
                                      this.state.phone_number,
                                      "max:15",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Email{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="email"
                                    value={this.state.email || ""}
                                    maxLength="70"
                                  />
                                  {this.state.errors.email ? (
                                    <div className="text-danger">
                                      {this.state.errors.email}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "email",
                                      this.state.email,
                                      "email|max:70",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-5">
                                    Heading

                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="heading"
                                    value={this.state.heading || ""}
                                    maxLength="200"
                                  />
                                  {this.state.errors.heading ? (
                                    <div className="text-danger">
                                      {this.state.errors.heading}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "heading",
                                      this.state.heading,
                                      "required|max:200",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>

                              </div>

                              <div className="row align-items-center pd-lg-b-10 pd-xs-b-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 border-bottom-0">
                                <div className="col-md-1 pl-0">
                                  <label className="form-label mg-b-0">
                                    Logo
                                  </label>
                                </div>
                                {/* col */}
                                <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">

                                  <div className="fileUpload d-flex flex-wrap justify-content-between">
                                    <span>
                                      <label className="hand-cursor">
                                        <input
                                          onChange={this.onImageChange}
                                          type="file"
                                          name="club_logo"
                                        />
                                        {this.state.imageUrl ? (
                                          <img
                                            src={this.state.imageUrl}
                                            alt="Logo"
                                          />
                                        ) : (
                                          <img
                                            src={brandLogo}
                                            alt="Logo"
                                          />
                                        )}
                                      </label>
                                    </span>

                                    <ul className="ruLink">
                                      <li>
                                        <label>
                                          <input
                                            onChange={this.onImageChange}
                                            type="file"
                                            name="club_logo"
                                            tabIndex="2"
                                          />
                                        </label>
                                        Change Logo
                                      </li>
                                    </ul>

                                    <p className={this.state.errors.club_logo ? " hand-cursor remove-pic remove-pic-two m-0 image-error-club" : "hand-cursor remove-pic remove-pic-two m-0"} onClick={this.removePhoto}></p>

                                  </div>
                                  {this.state.errors.club_logo ? (
                                    <div className="text-danger">
                                      {this.state.errors.club_logo}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "club_logo",
                                      this.state.club_logo,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}

                                </div>

                                {/* col */}
                              </div>
                              <div className="row align-items-center pd-lg-b-10 pd-xs-b-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 border-bottom-0">
                                <div className="col-md-1 pl-0">
                                  <label className="form-label mg-b-0">
                                    Main Image
                                  </label>
                                </div>
                                {/* col */}
                                <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">

                                  <div className="fileUpload d-flex flex-wrap justify-content-between">
                                    <span>
                                      <label className="hand-cursor">
                                        <input
                                          onChange={this.onMainImageChange}
                                          type="file"
                                          name="club_main_image"
                                        />
                                        {this.state.imageUrlMainImage ? (
                                          <img
                                            src={this.state.imageUrlMainImage}
                                            alt="main_image"
                                          />
                                        ) : (
                                          <img
                                            src={brandLogo}
                                            alt="main_image"
                                          />
                                        )}
                                      </label>
                                    </span>

                                    <ul className="ruLink">
                                      <li>
                                        <label>
                                          <input
                                            onChange={this.onMainImageChange}
                                            type="file"
                                            name="main_image"
                                            tabIndex="2"
                                          />
                                        </label>
                                        Main  Image
                                      </li>
                                    </ul>
                                    <p className={this.state.errors.club_main_image ? " hand-cursor remove-pic remove-pic-two m-0 image-error-club" : "hand-cursor remove-pic remove-pic-two m-0"} onClick={this.removePhotoMainImage}></p>

                                  </div>
                                  {this.state.errors.club_main_image ? (
                                    <div className="text-danger">
                                      {this.state.errors.club_main_image}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "club_main_image",
                                      this.state.club_main_image,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>

                              </div>
                              <div className="m-0">
                                {/* row */}
                                <div className="row align-items-center pd-lg-b-30 pd-xs-b-15 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 border-bottom-0 mg-t-30">
                                  <div className="col-md-1 pl-0">
                                    <label className="form-label text-left mg-b-0">
                                      Status
                                    </label>
                                  </div>
                                  <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start">
                                    <b
                                      className={
                                        this.state.status_id
                                          ? "active"
                                          : "inactive"
                                      }
                                    >
                                      {this.state.status_id
                                        ? "Active"
                                        : "Inactive"}
                                    </b>
                                    <div
                                      className={
                                        this.state.status_id
                                          ? "az-toggle on"
                                          : "az-toggle"
                                      }
                                      onClick={() => this.changeStatus()}
                                    >
                                      <span></span>
                                    </div>
                                  </div>
                                  {/* col */}
                                  {/* row */}
                                </div>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </Container>
        </main>
      </div>
    );
  }
}

export default ClubForm;