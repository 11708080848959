import React from "react";
import { Container, Tab, Nav } from "react-bootstrap";
//import Alerts from "../common/Alerts";
import ReactDatatable from "@ashvin27/react-datatable";
import { Link } from "react-router-dom";
import NoData from "../../common/NoData";
import SettingsSidebar from "../../common/SettingsSidebar";
import pluswIcon from "./../../../assets/images/plus.svg";
import { useState, useEffect } from "react";
import NoiseawareService from "../../../services/NoiseawareService";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
//import UnitMapping from "./UnitMapping";
import AlertSetting from "./AlertSetting";
import NoiseawareActivity from "./NoiseawareActivity";
import { Form } from "react-bootstrap";

const NoiseawareTemplate = (props) => {
  const [state, setState] = useState({
    total_record: 0,
    records: [],
    extraButtons: [],
    tableQueryString: "",
    customQueryString: "",
    filter_value: "",
    property_slug: props.match.params.propertySlug,
    activeClass: "alerts",
    columns: [
      {
        key: "template_name",
        text: "Alert Name",
        className: "hand-cursor",
        width: "100px",
        sortable: true,
        cell: (record) => {
          return record.template_name
            ? record.template_name.charAt(0).toUpperCase() +
                record.template_name
                  .replace(/([A-Z])/g, " $1")
                  .trim()
                  .slice(1)
            : "";
        },
      },
      {
        key: "template_body",
        text: "Alert Message",
        className: "hand-cursor text-left",
        width: "400px",
        sortable: true,
        cell: (record) => {
          return record.template_body ? record.template_body : "";
        },
      },
      {
        key: "user_roles",
        text: "User Role(s)",
        className: "hand-cursor text-left",
        width: "200px",
        sortable: false,
        cell: (record) => {
          return record.role_data ? record.role_data : "";
        },
      },
      {
        key: "users",
        text: "User(s)",
        className: "hand-cursor text-left",
        width: "200px",
        sortable: false,
        cell: (record) => {
          return record.user_data ? record.user_data : "";
        },
      },
    ],
    config: {
      sort: {
        column: "",
        order: "",
      },
      key_column: "slug",
      page_size: global.page_size,
      length_menu: global.page_length_menu,
      show_length_menu: true,
      show_filter: global.show_filter,
      show_pagination: false,
      button: {
        excel: global.excel_button,
        print: global.print_button,
        extra: false,
      },
      language: {
        no_data_text: "No data found.",
      },
    },
  });

  const [isNoise, setIsNoise] = useState(0);

  useEffect(() => {
    getNoiseawareSetting();
    getData();
  }, []);

  function getActiveClass(e) {
    setState({ ...state, activeClass: e });
  }

  const getData = async (queryString = "") => {
    var list = [];
    var totalRecords = 0;
    let res = await NoiseawareService.getTemplateList(
      "property_slug=" + state.property_slug + "&" + queryString
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : {};
      totalRecords = res.data ? res.data.total : 0;
    }
    setState({
      ...state,
      total_record: totalRecords,
      records: list,
    });
  };

  const getNoiseawareSetting = async (queryString = "") => {
    var flag = [];
    let res = await NoiseawareService.getNoiseawareSetting(
      "property_slug=" + state.property_slug
    );

    if (global.successStatus.includes(res.status)) {
      flag = res.data ? res.data.is_use_noiseaware : 0;
      if (flag === 1) {
        setIsNoise("1");
      } else {
        setIsNoise("0");
      }
      // totalRecords = res.data ? res.data.total : 0;
    }
    //setIsNoise('1');
  };

  const rowClickedHandler = (event, data, rowIndex) => {
    global.userPermissions.checkPermission("noiseaware-update") &&
      props.history.push("noiseaware-template/update/" + data.slug);
  };

  const noiseChangeHandler = async (evt) => {
    let value = evt.target.value;
    let res = [];
    let flag;

    if (isNoise === "1") {
      flag = 0;
    } else {
      flag = 1;
    }

    let inputData = {
      property_slug: state.property_slug,
      flag: flag,
    };

    res = await NoiseawareService.saveNoiseSetting(inputData);
    if (global.successStatus.includes(res.status)) {
      if (flag === 0) {
        setIsNoise(0);
      } else {
        setIsNoise(value);
      }
    }
  };

  const tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      setState({ ...state, tableQueryString: queryString });
    } else {
      queryString += "&" + state.customQueryString;
      setState({ ...state, tableQueryString: queryString });
    }

    if (data.filter_value !== state.filter_value) {
      clearTimeout(state.filter_time);
      setState({
        ...state,
        filter_time: setTimeout(() => getData(queryString), global.filter_time),
        filter_value: data.filter_value,
      });
    } else {
      getData(queryString);
    }
  };

  return (
    <main>
      <Container className="innter-container">
        <div className="d-flex flex-column">
          <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
            <div className="col-md-2 left">
              <div className="child-menu-section">
                <aside>
                  <SettingsSidebar property_slug={state.property_slug} />
                </aside>
              </div>
            </div>
            <div className="col-md-10 right">
              <div className="col-md-12 mg-t-20 mg-b-10"></div>
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns">
                  <h3>NoiseAware Settings </h3>
                </div>
              </div>
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-15 pd-b-10 pd-lg-l-45 pd-xs-l-15 pd-r-0">
                <Form.Check
                  onChange={noiseChangeHandler}
                  name="alert_type_e2[]"
                  checked={isNoise === "1" ? true : false}
                  type={"checkbox"}
                  id="100"
                  value="1"
                  label={"Is this property using NoiseAware?"}
                  className={"ckbox mg-r-150"}
                />
              </div>
              {isNoise === "1" ? (
                <div className="scrolling-carousel pd-l-15">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="alerts"
                    onSelect={(e) => getActiveClass(e)}
                    eventKey={state.activeClass}
                  >
                    <ScrollingCarousel
                      rightArrow={true}
                      leftArrow={true}
                      className="swipesection scroll_nav_mob"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="alerts"
                          id="infoForm"
                          className={
                            state.activeClass === "alerts" ? "active" : ""
                          }
                        >
                          Alert
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          eventKey="alert-activity"
                          id="alert-activity"
                          className={
                            state.activeClass === "alert-activity"
                              ? "active"
                              : ""
                          }
                        >
                          Alert Activity
                        </Nav.Link>
                      </Nav.Item>
                    </ScrollingCarousel>
                    <Tab.Content>
                      <Tab.Pane eventKey="alerts">
                        <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns  table_search_hide">
                          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns noisebutton">
                            {global.userPermissions.checkPermission(
                              "noiseaware-add"
                            ) && (
                              <Link to={"noiseaware-template/add"}>
                                <button className="btn-success-outline-small mr-3">
                                  <img src={pluswIcon} alt="" /> Add
                                </button>
                              </Link>
                            )}
                          </div>
                          {state.total_record > 0 ||
                          state.filter_value !== "" ? (
                            <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block">
                              <div className="pd-lg-l-0 pd-xs-l-15">
                                <ReactDatatable
                                  className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                                  config={
                                    state.total_record > global.page_size
                                      ? state.config
                                      : state.config
                                  }
                                  records={state.records}
                                  columns={state.columns}
                                  extraButtons={state.extraButtons}
                                  dynamic={true}
                                  total_record={state.total_record}
                                  onChange={tableChangeHandler}
                                  onRowClicked={rowClickedHandler}
                                />
                              </div>
                            </div>
                          ) : (
                            <NoData
                              msg={global.commonMessages.checkMessage("ALERT")}
                            />
                          )}
                        </div>
                        <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                          <h3 className="mg-l-0 mg-t-15 background-head">
                            Alert Notification
                          </h3>
                        </div>
                        <AlertSetting propertySlug={state.property_slug} />
                      </Tab.Pane>

                      <Tab.Pane eventKey="alert-activity">
                        <NoiseawareActivity
                          propertySlug={state.property_slug}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Container>
    </main>
  );
};

export default NoiseawareTemplate;
