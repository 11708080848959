////////////////////////////////////////////////////////////
//     							                          //
//  Program: AccessibilityFeatureType.jsx                            //
//  Application: Property                                   //
//  Option: to view Accessibility Feature Type data                   //
//  Developer: Ashish Kumar  			                  //
//  Date: 2022-05-11                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";

import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import AccessibilityFeatureTypeService from "../../services/AccessibilityFeatureTypeService";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import NoData from "../common/NoData";

import MasterSidebar from "../common/MasterSidebar";

export class AccessibilityFeatureType extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",

      //for Accessibility Feature Type
      addAccessibilityFeatureTypeModal: false,
      editAccessibilityFeatureTypeModal: false,
      deleteAccessibilityFeatureTypeModal: false,
      accessibilityFeatureTypeList: [],
      accessibilityFeatureTypeListTotal: 0,
      accessibility_feature_type_name: "",
    };
    this.changeHandler = this.changeHandler.bind(this);

    /*for Accessibility Feature Type */
    this.openAddAccessibilityFeatureTypeModal =
      this.openAddAccessibilityFeatureTypeModal.bind(this);
    this.openEditAccessibilityFeatureTypeModal =
      this.openEditAccessibilityFeatureTypeModal.bind(this);
    this.openDeleteAccessibilityFeatureTypeModal =
      this.openDeleteAccessibilityFeatureTypeModal.bind(this);
    this.saveAccessibilityFeatureType =
      this.saveAccessibilityFeatureType.bind(this);
    this.getAccessibilityFeatureTypeDetails =
      this.getAccessibilityFeatureTypeDetails.bind(this);
    this.updateAccessibilityFeatureType =
      this.updateAccessibilityFeatureType.bind(this);
    this.deleteAccessibilityFeatureType =
      this.deleteAccessibilityFeatureType.bind(this);
  }

  componentDidMount() {
    this.getAccessibilityFeatureTypeData();
  }

  /* to get Accessibility Feature Type data */
  async getAccessibilityFeatureTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var accessibilityFeatureTypeList = [];
    var accessibilityFeatureTypeListTotal = 0;

    let res = await AccessibilityFeatureTypeService.getAccessibilityFeatureType(
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      accessibilityFeatureTypeList = res.data.data ? res.data.data : [];
      accessibilityFeatureTypeListTotal = accessibilityFeatureTypeList
        ? accessibilityFeatureTypeList.length
        : 0;
    }
    this.setState({
      accessibilityFeatureTypeList: accessibilityFeatureTypeList,
      accessibilityFeatureTypeListTotal: accessibilityFeatureTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save Accessibility Feature Type data */
  saveAccessibilityFeatureType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.accessibility_feature_type_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        accessibility_feature_type_name:
          this.state.accessibility_feature_type_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res =
        await AccessibilityFeatureTypeService.createAccessibilityFeatureType(
          inputData
        );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddAccessibilityFeatureTypeModal();
        this.setState({
          accessibility_feature_type_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getAccessibilityFeatureTypeData();
      } else {
        // let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage:
        //     alertMessage !== "" ? alertMessage : res.data.errorMessage,
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get Accessibility Feature Type details data */
  async getAccessibilityFeatureTypeDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res =
        await AccessibilityFeatureTypeService.getAccessibilityFeatureTypeBySlug(
          slug
        );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.accessibility_feature_type_name = res.data
          .accessibility_feature_type_name
          ? res.data.accessibility_feature_type_name
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update Accessibility Feature Type data */
  updateAccessibilityFeatureType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.accessibility_feature_type_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        accessibility_feature_type_name:
          this.state.accessibility_feature_type_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res =
        await AccessibilityFeatureTypeService.updateAccessibilityFeatureType(
          this.state.slug,
          inputData
        );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditAccessibilityFeatureTypeModal();
        this.setState({
          accessibility_feature_type_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getAccessibilityFeatureTypeData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete Accessibility Feature Type data */
  async deleteAccessibilityFeatureType(slug) {
    this.setState({ showSpinner: true });
    let res =
      await AccessibilityFeatureTypeService.deleteAccessibilityFeatureType(
        slug
      );
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAccessibilityFeatureTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getAccessibilityFeatureTypeData();
    } else {
      this.closeDeleteAccessibilityFeatureTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* for Accessibility Feature Type modal */
  openAddAccessibilityFeatureTypeModal() {
    this.setState({ addAccessibilityFeatureTypeModal: true });
  }

  closeAddAccessibilityFeatureTypeModal() {
    this.setState({ addAccessibilityFeatureTypeModal: false });
    this.setState({
      accessibility_feature_type_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openEditAccessibilityFeatureTypeModal(slug) {
    this.getAccessibilityFeatureTypeDetails(slug);
    this.setState({ editAccessibilityFeatureTypeModal: true });
    this.setState({ slug: slug });
  }

  closeEditAccessibilityFeatureTypeModal() {
    this.setState({ editAccessibilityFeatureTypeModal: false });
    this.setState({
      slug: "",
      accessibility_feature_type_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openDeleteAccessibilityFeatureTypeModal(slug) {
    this.setState({ deleteAccessibilityFeatureTypeModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteAccessibilityFeatureTypeModal() {
    this.setState({ deleteAccessibilityFeatureTypeModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Accessibility Feature Types";
  }

  render() {
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                          <Button
                            variant="outline-dark btn-block btn-with-icon"
                            onClick={this.openAddAccessibilityFeatureTypeModal}
                          >
                            <img src={pluswIcon} alt="" /> Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      {/* <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
                        <Button
                          variant="outline-dark btn-block btn-with-icon"
                          onClick={this.openAddAccessibilityFeatureTypeModal}
                        >
                          <img src={pluswIcon} alt="" /> Add
                        </Button>
                      </div> */}
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.accessibilityFeatureTypeListTotal > 0 ? (
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th className="w-50">
                                Accessibility Feature Type Name
                              </th>
                              <th className="w-25">Status</th>
                              {global.userPermissions.checkPermission(
                                "master-data-delete"
                              ) && <th className="text-center">Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.accessibilityFeatureTypeListTotal >
                            0 ? (
                              this.state.accessibilityFeatureTypeList.map(
                                (item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td
                                        className="hand-cursor"
                                        onClick={() => {
                                          global.userPermissions.checkPermission(
                                            "master-data-update"
                                          ) &&
                                            this.openEditAccessibilityFeatureTypeModal(
                                              item.slug
                                            );
                                        }}
                                      >
                                        {item.accessibility_feature_type_name}
                                      </td>
                                      <td>
                                        {item.status_id === 1 ? (
                                          <b className="active"></b>
                                        ) : (
                                          <b></b>
                                        )}
                                      </td>
                                      {global.userPermissions.checkPermission(
                                        "master-data-delete"
                                      ) && (
                                        <td>
                                          <div className="btn-icon-list d-block text-center">
                                            <img
                                              src={deleteicon}
                                              alt="Delete"
                                              onClick={() =>
                                                this.openDeleteAccessibilityFeatureTypeModal(
                                                  item.slug
                                                )
                                              }
                                              className="hand-cursor"
                                            />
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                  There are no accessibility feature types added
                                  in the system. You may add them by clicking
                                  Add button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage(
                            "ACCESSIBILITYFEATURETYPE"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add Accessibility Feature Type popup modal */}
        <Modal
          show={this.state.addAccessibilityFeatureTypeModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddAccessibilityFeatureTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddAccessibilityFeatureTypeModal()}
            >
              {global.closee}
            </span>

            <h3 className="text-center">Add Accessibility Feature Type</h3>
            <label>Accessibility Feature Type Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="accessibility_feature_type_name"
              value={this.state.accessibility_feature_type_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Amenity Type Name"
            />
            {this.state.errors.accessibility_feature_type_name ? (
              <div className="text-danger">
                {this.state.errors.accessibility_feature_type_name}
              </div>
            ) : (
              this.validator.message(
                "accessibility_feature_type_name",
                this.state.accessibility_feature_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "Active" : "Inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddAccessibilityFeatureTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className=" m-0 btn btn btn-success btn-block"
                onClick={this.saveAccessibilityFeatureType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit Accessibility Feature Type popup modal */}
        <Modal
          show={this.state.editAccessibilityFeatureTypeModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditAccessibilityFeatureTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditAccessibilityFeatureTypeModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Accessibility Feature Type</h3>
            <label>Accessibility Feature Type Name </label>
            <Form.Control
              onChange={this.changeHandler}
              name="accessibility_feature_type_name"
              value={this.state.accessibility_feature_type_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Amenity Type Name"
            />
            {this.state.errors.accessibility_feature_type_name ? (
              <div className="text-danger">
                {this.state.errors.accessibility_feature_type_name}
              </div>
            ) : (
              this.validator.message(
                "accessibility_feature_type_name",
                this.state.accessibility_feature_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "Active" : "Inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditAccessibilityFeatureTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className=" m-0 btn btn btn-success btn-block"
                onClick={this.updateAccessibilityFeatureType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete Accessibility Feature Type popup modal */}
        <Modal show={this.state.deleteAccessibilityFeatureTypeModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteAccessibilityFeatureTypeModal()}
              >
                {global.closee}
              </span>

              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() =>
                    this.closeDeleteAccessibilityFeatureTypeModal()
                  }
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() =>
                    this.deleteAccessibilityFeatureType(this.state.slug)
                  }
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default AccessibilityFeatureType;
