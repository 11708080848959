////////////////////////////////////////////////////////////
//     							                                       //
//  Program: Ambassadors.jsx                               //
//  Application: Ambassadors                               //
//  Option: List all Ambassadors                           //
//  Developer: Sunny Raj 						                       //
//  Date: 2023-07-12                                       //
//                                                         //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { Container, Nav, Tab } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import Alerts from "../common/Alerts";
import AmbassadorService from "../../services/AmbassadorService";
import NoData from "../common/NoData";
import Moment from "moment";
import PropertySidebar from "../common/PropertySidebar";
import photo from "./../../assets/images/profile-nogender.png";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import queryString from "query-string";

let queryParams = queryString.parse(window.location.search);

export class Ambassadors extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      is_upcoming: false,
      start_date: "",
      end_date: "",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: -1,
      property_slug: this.props.match.params.propertySlug,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "photo",
          text: "Photo",
          className: "photo hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return (
              <img
                src={record.photo_thumb_url ? record.photo_thumb_url : photo}
                className="brand_list_image"
                alt="Brand Logo"
              />
            );
          },
        },
        {
          key: "name",
          text: "Name",
          className: "name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.name ? record.name : "";
          },
        },
        {
            key: "email",
            text: "Email",
            className: "email td_clickable",
            sortable: true,
            cell: (record) => {
                let email = record && record.email ? record.email : "";
              return email;
              
            },
          },

        {
          key: "status_id",
          text: "Status",
          className: "status_id td_clickable",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active"></b>
            ) : (
              <b className="status-inactive"></b>
            );
          },
        },
      ],
      tab: "active",
      ambassadorTabData: localStorage.getItem("ambassadors_tab_data")
        ? localStorage.getItem("ambassadors_tab_data")
        : "",
      activeClass: "activeTab",
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.setOpen = this.setOpen.bind(this);
    // this.copyEvent = this.copyEvent.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      switch (this.state.ambassadorTabData) {
      case "active":
        document.getElementById("activeTab").click();
        break;
      case "inactive":
        document.getElementById("inactiveTab").click();
        break;
      default:
        document.getElementById("activeTab").click();
    }
    }

    if (queryParams.isGlobal === "1") {
      this.getData(
        "is_search=1&tab=" + this.state.ambassadorTabData + "&" +
        queryParams.global_col_name +
        "=" +
        queryParams.global_search
      );
    } 
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      switch (this.state.ambassadorTabData) {
        case "active":
          document.getElementById("activeTab").click();
          break;
        case "inactive":
          document.getElementById("inactiveTab").click();
          break;
        default:
          document.getElementById("activeTab").click();
      }
    }, 200);
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "ambassadors_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "ambassadors_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async getData(queryString = "") {

    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await AmbassadorService.getAmbassador(
      "property_slug=" +
      (this.state.property_slug ? this.state.property_slug : "") +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }

    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  customFilter() {
    let startDate = this.state.start_date
      ? Moment(this.state.start_date).format("YYYY-MM-DD")
      : "";
    let endDate = this.state.end_date
      ? Moment(this.state.end_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "start_date=" +
      startDate +
      "&end_date=" +
      endDate +
      "&is_upcoming=" +
      (this.state.is_upcoming === true ? 1 : 0) +
      "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData("tab=" + this.state.tab + "&" + queryString);
  }

  resetDate() {
    this.setState({ is_upcoming: false, start_date: "", end_date: "" });
    this.getData("tab=" + this.state.tab);
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData("tab=" + this.state.tab + "&" + queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Ambassadors";
  }



  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      this.props.match.params.propertySlug !== undefined
        ? "/properties/view/" + this.state.property_slug + "/ambassadors/view/" + data.slug
        : this.state.property_slug
          ? "/ambassadors/view/" + this.state.property_slug  + "/" + data.slug
          : "/ambassadors/view/" + data.property.property_slug + "/" + data.slug
    );
  };

  render() {
    return (
      <main>

        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ?
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>
                : ""}
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {global.userPermissions.checkPermission(
                        "ambassadors-add"
                      ) && this.state.property_slug && (
                          <Link to={
                            this.props.match.params.propertySlug !==
                              undefined
                              ? "/properties/view/" +
                              this.state.property_slug +
                              "/ambassadors/add/"
                              : "/ambassadors/add/" +
                              this.state.property_slug
                          }
                          >
                            <button className="btn-success-outline-small">
                              <img src={pluswIcon} alt="" /> Add
                            </button>
                          </Link>
                        )}
                    </div>
                  </div>
                </div>
                {queryParams.isGlobal === "1" ? (
                  <>
                    <div className="col-md-12 pd-l-30 pd-r-30 mg-t-30 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                      {"Search applied on: " +
                        queryParams.global_col_label +
                        " - " +
                        queryParams.global_search}
                      <button
                        onClick={this.clearFilter}
                        className="mg-l-15 btn-success-outline-small"
                      >
                        Clear
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.props.match.params.propertySlug === undefined ? (
                      <>
                          <div className="row p-0">
                          <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-30">
                            <OutSidePropertySelectBox
                              propertySlug={this.getPropertySlug.bind(this)}
                              outSidePropertySlug={this.state.outSidePropertySlug}
                            />
                          </div>
                          <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                            {queryParams.isGlobal === "1" ? (
                              <>
                                <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                  {"Search applied on: " +
                                    queryParams.global_col_label +
                                    " - " +
                                    queryParams.global_search}
                                  <button
                                    onClick={this.clearFilter}
                                    className="mg-l-15 btn-success-outline-small"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {this.state.is_search === 1 ? (
                              <>
                                <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                                  <div className="custm-filter">
                                    <div>
                                      {" "}
                                      <label className="form-label text-left pd-b-5 mb-0">
                                        Search Applied On
                                      </label>
                                    </div>
                                    <div className="filter_bg_cust">
                                      {this.state.module_search.map((item, i) => {
                                        return <span key={i}>{item}</span>;
                                      })}
                                      <button
                                        onClick={this.clearFilter}
                                        className="btn-fliter-clear"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-xxl-12 pd-r-15 pd-l-15">
                        {queryParams.isGlobal === "1" ? (
                          <>
                            <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between justify_mob_filter scrolling-carousel">
                              {"Search applied on: " +
                                queryParams.global_col_label +
                                " - " +
                                queryParams.global_search}
                              <button
                                onClick={this.clearFilter}
                                className="mg-l-15 btn-success-outline-small"
                              >
                                Clear
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.is_search === 1 ? (
                          <>
                            <div className="col-xxl-12 col-sm-12 pd-lg-r-15 pd-lg-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                              <div className="custm-filter">
                                <div>
                                  {" "}
                                  <label className="form-label text-left pd-b-5 mb-0">
                                    Search Applied On
                                  </label>
                                </div>
                                <div className="filter_bg_cust">
                                  {this.state.module_search.map((item, i) => {
                                    return <span key={i}>{item}</span>;
                                  })}
                                  <button
                                    onClick={this.clearFilter}
                                    className="btn-fliter-clear"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <div className="scrolling-carousel scroll-slide mg-lg-l-0 mg-xs-l-15">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="all"
                        onSelect={(e) => this.getActiveClass(e)}
                        eventKey={this.state.activeClass}
                        className="pd-r-60"
                      >
                        <ScrollingCarousel
                          rightArrow={true}
                          leftArrow={true}
                          className="swipesection"
                        >
                          <Nav.Item>
                            <Nav.Link
                              eventKey="activeTab"
                              id="activeTab"
                              className={
                                this.state.activeClass === "activeTab"
                                  ? "active"
                                  : ""
                              }
                            >
                              Active
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link
                              eventKey="inactiveTab"
                              id="inactiveTab"
                              className={
                                this.state.activeClass === "inactiveTab"
                                  ? "active"
                                  : ""
                              }
                            >
                              Inactive
                            </Nav.Link>
                          </Nav.Item>
                        </ScrollingCarousel>
                        <Tab.Content>
                          <Tab.Pane eventKey="activeTab">
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              {this.state.total_record > 0 ||
                                this.state.filter_value !== "" ? (
                                <div className="mg-t-18 brandList">
                                  <ReactDatatable
                                    className="table no-wrap mg-b-30 pd-b-2"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    // loading={this.state.loading}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              ) : (
                                <NoData msg="active ambassadors" />
                              )}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="inactiveTab">
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              {this.state.total_record > 0 ||
                                this.state.filter_value !== "" ? (
                                <div className="mg-t-18 brandList">
                                  <ReactDatatable
                                    className="table no-wrap mg-b-30 pd-b-2"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    // loading={this.state.loading}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              ) : (
                                <NoData msg="inactive ambassadors" />
                              )}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default Ambassadors;
