////////////////////////////////////////////////////////////
//     							                                      //
//  Program: AutomationProcessingLog.jsx                  //
//  Application: Automation Import                        //
//  Option: List all Floor                                //
//  Developer: Anil kumar gupta 						              //
//  Date: 2023-03-06                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import DataHubAutomationService from "../../services/DataHubAutomationService";
import modaldeleteicon from "./../../assets/images/delete.svg";
import { Container, Modal } from "react-bootstrap";
import Alerts from "../common/Alerts";
import NoData from "../common/NoData";
import SettingsSidebar from "../common/SettingsSidebar";
import queryString from "query-string";
import Moment from "moment";
import ReportSidebar from "../common/ReportSidebar";

let queryParams = queryString.parse(window.location.search);

export class AutomationProcessingLog extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      module_search: "",
      is_search: 0,
      isViewMode: 0,
      input: {},
      isTestMailModalOpen: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      TestModal:false,
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      is_report_page: false,
      total_record: 0,
      property_slug: this.props.match.params.propertySlug,
      is_export_automation_slug: "",
      module_name: this.props.match.params.module_name,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "module_name",
          text: "Module",
          className: "module_name hand-cursor",
          sortable: true,
          //width: 200,
          cell: (record) => {
            return record.module_name == "unit_availability"
              ? "Unit Availability"
              : record.module_name == "unit_listing"
              ? "Units"
              : record.module_name == "unit_rent"
              ? "Rent"
              : record.module_name == "leasing_listing"
              ? "Leases"
              : record.module_name == "reservation_listing"
              ? "Reservation"
              : "";
          },
        },
        {
          key: "log_type",
          text: "Log Type",
          className: "log_type",
          sortable: false,
          cell: (records) => {
            let autoType = records.export_automation_id ? "Export" : "Import";
            return records.log_type ? records.log_type + " " + autoType : autoType;
          },
        },
        {
          key: "attachment_name",
          text: "Attachment",
          className: "attachment_name hand-cursor",
          sortable: true,
          width: 300,
          cell: (record) => {
            return record.attachment_name
              ? record.attachment_name
              : record.attachment_name;
          },
        },
        {
          key: "total_records",
          text: "Total Records",
          className: "total_records hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return (this.state.is_export_automation_slug) ? "-" : record.total_records;
          },
        },
        {
          key: "total_inserted_records",
          text: " Inserted/Updated Records",
          className: "total_inserted_records hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return (this.state.is_export_automation_slug) ? "-" : record.total_inserted_records;
          },
        },
        {
          key: "total_skipped_records",
          text: " Skipped Records",
          className: "total_skipped_records hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return (this.state.is_export_automation_slug) ? "-" : record.total_skipped_records;
          },
        },
        {
          key: "total_failed_records",
          text: " Failed Records",
          className: "total_failed_records hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return (this.state.is_export_automation_slug) ? "-" : record.total_failed_records;
          },
        },
        {
          key: "execution_starts_at",
          text: "Execution Starts At",
          className: "execution_starts_at hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.execution_starts_at
              ? Moment(record.execution_starts_at).format(global.dateFormat) +
                  " | " +
                  Moment(record.execution_starts_at).format("hh:mm:ss A")
              : "";
          },
        },
        {
          key: "execution_ends_at",
          text: "Execution Ends At",
          className: "execution_ends_at hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.execution_ends_at
              ? Moment(record.execution_ends_at).format(global.dateFormat) +
                  " | " +
                  Moment(record.execution_ends_at).format("hh:mm:ss A")
              : "";
          },
        },
        {
          key: "status_remarks",
          text: "Remarks",
          className: "status_remarks hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.status_remarks ? record.status_remarks : "";
          },
        },
        {
          key: "unit_updated_count",
          text: "Updated Records",
          className: "status_remarks hand-cursor text-left",
          sortable: true,
          cell: (record) => (
            <p className="m-0">
              {this.state.isTestMailModalOpen ? (
                <span>{record.unit_updated_count ? record.unit_updated_count : ""}</span>
              ) : (
                <a
                  href="#"
                  onClick={() => this.openTestMailModal(record.module_name)}
                  className="hand-cursor text-none"
                >
                  {record.unit_updated_count ? record.unit_updated_count : ""}
                </a>
              )}
            </p>
          ),
        } 
      ],
      columns_export: [
        {
          key: "module_name",
          text: "Module",
          className: "module_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.module_name == "unit_availability"
              ? "Unit Availability"
              : record.module_name == "unit_listing"
              ? "Units"
              : record.module_name == "leasing_listing"
              ? "Leases"
              : record.module_name == "reservation_listing"
              ? "Reservation"
              : "";
          },
        },
        {
          key: "log_type",
          text: "Log Type",
          className: "log_type",
          sortable: false,
          cell: (records) => {
            let autoType = records.export_automation_id ? "Export" : "Import";
            return records.log_type ? records.log_type + " " + autoType : autoType;
          },
        },
        {
          key: "attachment_name",
          text: "Attachment",
          className: "attachment_name hand-cursor",
          sortable: true,
          width: 300,
          cell: (record) => {
            return record.attachment_name
              ? record.attachment_name
              : record.attachment_name;
          },
        },
        {
          key: "execution_starts_at",
          text: "Execution Starts At",
          className: "execution_starts_at hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.execution_starts_at
              ? Moment(record.execution_starts_at).format(global.dateFormat) +
                  " | " +
                  Moment(record.execution_starts_at).format("hh:mm:ss A")
              : "";
          },
        },
        {
          key: "execution_ends_at",
          text: "Execution Ends At",
          className: "execution_ends_at hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.execution_ends_at
              ? Moment(record.execution_ends_at).format(global.dateFormat) +
                  " | " +
                  Moment(record.execution_ends_at).format("hh:mm:ss A")
              : "";
          },
        },
        {
          key: "status_remarks",
          text: "Remarks",
          className: "status_remarks hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.status_remarks ? record.status_remarks : "";
          },
        },
      ],
      remark_description: "",
      remark_unit_description:"",
    };
    this.closeModal = this.closeModal.bind(this);
    this.viewToggle = this.viewToggle.bind(this);
    this.closedeleteModal = this.closedeleteModal.bind(this);
    
  }
  closedeleteModal() {
    this.setState({ deleteAutomationmodel: false,});
  }
  opendeleteModal(slug) {
    this.setState({ deleteAutomationmodel: true, slug: slug,TestModal: false ,isViewMode: 0});
  }
  componentDidMount() {
    if (queryParams.isGlobal === "1") {
      this.getData(
        "is_search=1&" +
          queryParams.global_col_name +
          "=" +
          queryParams.global_search
      );
    } else {
      //this.getData();
    }
  }
  closeMailModal() {
    this.setState({ TestModal: false,isViewMode: 0 });
    this.setState({ selected_module: "" ,remark_unit_description:""});
  }

  openTestMailModal() {
    this.setState({ TestModal: true,slug:this.state.slug});
  }

  /* to delete data */
  async deleteUnitData(slug) {
    this.setState({ showSpinner: true });
    let res = await DataHubAutomationService.DeletedUpdatedUnit(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getData();
    } else {
      this.closedeleteModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closedeleteModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
 
  async UNSAFE_componentWillReceiveProps() {
    const queryString1 = window.location.search;
    const urlParams = new URLSearchParams(queryString1);
    const getRequestFrom = urlParams.get('request_from');
    const isReportPage = urlParams.get('is_report_page');
    if (getRequestFrom) {
      await this.setState({
        is_export_automation_slug : getRequestFrom
      });
      this.getData(queryString, getRequestFrom);
    } else {
      this.getData(queryString);
    }
    if (isReportPage) {
      await this.setState({
        is_report_page : isReportPage
      });
    }
  }

  async getData(queryString = "", getRequestFrom = "") {
    getRequestFrom = (this.state.is_export_automation_slug != "") ? this.state.is_export_automation_slug : getRequestFrom;
    var list = [];
    var totalRecords = 0;
    var inputData = {
      property_slug: this.state.property_slug,
      module_name: this.state.module_name,
      is_export_automation_slug: getRequestFrom
    };
    this.setState({
      is_export_automation_slug: getRequestFrom
    });
    let res = await DataHubAutomationService.GetAutomationProcessingLogs(
      inputData
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  viewToggle = () => {
    if (this.state.isViewMode === 1) {
      this.setState({ isViewMode: 0, remark_description: "" });
    } else {
      this.setState({ isViewMode: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Automation Processing Logs";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.setState({ isViewMode: 1, remark_description: data.remarks , remark_unit_description: data.unit_remarks,slug:data.slug});
  };

  render() {
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                  {
                    this.state.is_report_page ?
                      <ReportSidebar property_slug={this.state.property_slug} />
                    :
                      <SettingsSidebar property_slug={this.state.property_slug} />
                  }
                  </div>
                </div>
                <div className="col-md-10 right">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                    </div>
                  </div>
                  <div className="scrolling-carousel pd-l-15">
                    <div className="dashboardRightcard background-none pt-0 swipersectionBtns  pd-l-30 pd-r-30">
                      <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns auto_process_top_btn">
                        <button
                          type="button"
                          className="btn-success-outline-small mr-3"
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/automation-data-hub-settings"
                                    : "/automation-data-hub-settings"
                                );
                              else return false;
                            else
                              if (this.state.is_export_automation_slug != "") {
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/export-automation"
                                    : "/export-automation"
                                  );   
                              } else {
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/automation-data-hub-settings"
                                    : "/automation-data-hub-settings"
                                  );
                              }
                          }}
                        >
                          Cancel
                        </button>
                      </div>

                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      {this.state.total_record > 0 ||
                      this.state.filter_value !== "" ? (
                        <div className="mg-t-18 brandList pd-l-15 d-block table_issue_roles_rg">
                          <div className="pd-lg-l-0 pd-xs-l-15">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={ (this.state.is_export_automation_slug) ? this.state.columns_export : this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              onRowClicked={
                                global.userPermissions.checkPermission(
                                  "floors-update"
                                ) && this.rowClickedHandler
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <NoData msg="Automation Logs" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
  {/* Confirm Test mail popup modal */}

  <Modal
          show={this.state.TestModal}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14 logs_popup">
            <span
              className="welcomeClose"
              onClick={() => this.closeMailModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <h3>Updated Records</h3>
              <div
              className="post__content text-left"
              dangerouslySetInnerHTML={{
                __html: this.state.remark_unit_description,
              }}
            ></div>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeMailModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.opendeleteModal(this.state.slug)}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        
        <Modal show={this.state.deleteAutomationmodel} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() =>
                    this.deleteUnitData(this.state.slug)
                  }
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module search popup modal */}
        {this.state.TestModal === true ? '' :(
        <Modal
          show={this.state.isViewMode}
          className="logs_modal_pops_main"
          centered
        >
          {/* Modal content goes here */}
          <Modal.Body className="width_402 p-4 border_radius_14 logs_popup">
            <span className="welcomeClose" onClick={() => this.viewToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Processing Log Detail</h3>

            <div
              className="post__content"
              dangerouslySetInnerHTML={{
                __html: this.state.remark_description,
              }}
            ></div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.viewToggle()}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
          </Modal>
      ) }
      </>
    );
  }
}

export default AutomationProcessingLog;
