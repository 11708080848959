/////////////////////////////////////////////////////
//     							                   //
//  Program: Compliance.jsx                        //
//  Application: Property                          //
//  Option: List all Property Compliance  info     //
//  Developer: Prashant Gupta				       //
//  Date: 2023-02-09                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import PropertySidebar from "../common/PropertySidebar";


export class Compliance extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      property_slug: this.props.match.params.propertySlug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      confirm_back: 0,
    };
    this.changeView = this.changeView.bind(this);
    this.saveOrUpdateProperty = this.saveOrUpdateProperty.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    this.getData();
    this.setState({ showSpinner: false, loading: false });
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.state.property_slug);
    //   console.log(res);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.property_name = res.data && res.data.property_name ? res.data.property_name : ""
        resData.aka_name = res.data && res.data.aka_name ? res.data.aka_name : ""
        resData.homesharing_cap = res.data && res.data.homesharing_cap ? res.data.homesharing_cap : ""
        resData.max_no_night_unit = res.data && res.data.max_no_night_unit ? res.data.max_no_night_unit : ""
        resData.license_number = res.data && res.data.license_number ? res.data.license_number : ""

      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdateProperty = async (e) => {
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        tab: 'compliance',
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        homesharing_cap: this.state.homesharing_cap,
        max_no_night_unit: this.state.max_no_night_unit,
        license_number: this.state.license_number,
       
      };

      if (this.state.property_slug !== undefined) {
        let res = await PropertyService.updateProperty(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getData();
          this.setState({ viewMode: 0 });
        } else {
          //  let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };
  

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
    }
  }

  getTitle() {
    return "Compliance";
  }



  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <PropertySidebar
                    property_slug={this.state.property_slug}
                  />
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {this.state.viewMode === 0 ? (
                        <>
                      {global.userPermissions.checkPermission('lead-tracking-update') &&
                        <button
                          className="btn-success-outline-small"
                          onClick={this.changeView}
                        >
                          <img src={editIcon} />
                          Edit
                        </button>
                      }
                      </> ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.changeView();
                                else return false;
                              else this.changeView();
                            }}
                          >
                            Cancel
                          </button>
                        {global.userPermissions.checkPermission('lead-tracking-update') &&
                          <button
                            className="btn-success ml-3"
                            onClick={this.saveOrUpdateProperty}
                          >
                            {" "}
                            Save
                          </button>
                        }
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel">
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                    <div className="col-md-12 pd-lg-l-45 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>

                    {this.state.viewMode === 0 ? (
                      <div className="row pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                        <div className="row col-md-12 mg-t-0 mg-md-t-0 mg-l-0 pd-lg-l-15 pd-xs-l-0 pd-t-30">
                          <div className="col-md-4">
                            <p className="az-profile-name-text pl-0">
                               Homesharing % cap
                            </p>
                            <p className="media-body">
                              {this.state.homesharing_cap
                                ? this.state.homesharing_cap
                                : ""}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p className="az-profile-name-text pl-0">
                            Max no of allowed nights per unit in a calendar year
                            </p>
                            <p className="media-body">
                              {this.state.max_no_night_unit
                               ? this.state.max_no_night_unit
                                : ""}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p className="az-profile-name-text pl-0">
                            License Number
                            </p>
                            <p className="media-body">
                              {this.state.license_number
                               ? this.state.license_number
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.viewMode === 1 ? (
                      <div className="m-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-0">
                        <div className="row align-items-center pd-t-30 pd-b-30 border-bottom-0">
                            
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                            Homesharing % cap{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              type="number"
                              name="homesharing_cap"
                              value={this.state.homesharing_cap}
                            />
                            {this.state.errors.homesharing_cap ? (
                              <div className="text-danger">
                                {this.state.errors.homesharing_cap}
                              </div>
                            ) : (
                              this.validator.message(
                                "Homesharing % cap",
                                this.state.homesharing_cap,
                                "homesharing_cap",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                            Max no of allowed nights per unit in a calendar year{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              type="number"
                              name="max_no_night_unit"
                              value={this.state.max_no_night_unit}
                            />
                            {this.state.errors.max_no_night_unit ? (
                              <div className="text-danger">
                                {this.state.errors.max_no_night_unit}
                              </div>
                            ) : (
                              this.validator.message(
                                "Max no of allowed nights per unit in a calendar year",
                                this.state.max_no_night_unit,
                                "max_no_night_unit",
                                { className: "text-danger" }
                              )
                            )}
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                            License Number
                              <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              type="text"
                              name="license_number"
                              value={this.state.license_number}
                            />
                            {this.state.errors.license_number ? (
                              <div className="text-danger">
                                {this.state.errors.license_number}
                              </div>
                            ) : (
                              this.validator.message(
                                "License Number",
                                this.state.license_number,
                                "License Number",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          

                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default Compliance;
