////////////////////////////////////////////////////////////
//     							                       //
//  Program: FileUploadService.js                        //
//  Application: File Upload                          //
//  Option: Used for upload files                        //
//  Developer: Ashish Kumar         					 //
//  Date: 2022-02-22                                   //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class FileUploadService {
  uploadDataFile(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "upload";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.uploadFileCall(inputData);
    return response;
  }

  uploadFromEditor(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "upload-on-s3";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.uploadFileCall(inputData);
    return response;
  }
  uploadCmsVideo(data, slug) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "upload-on-s4";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.uploadFileCall(inputData);
    return response;
  }
}

export default new FileUploadService();
