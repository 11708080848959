////////////////////////////////////////////////////////////
//     							                          //
//  Program: DocumentType.jsx                           //
//  Application: Misc                                   //
//  Option: to view DocumentType data                  //
//  Developer: NP                               //
//  Date: 2022-03-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import DocumentTypeService from "../../services/DocumentTypeService";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import NoData from "../common/NoData";

export class DocumentType extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for Document type
      addDocumentTypeModal: false,
      editDocumentTypeModal: false,
      deleteDocumentTypeModal: false,
      documentTypeList: [],
      documentTypeListTotal: 0,
      document_category_name: "",
      is_onboarding: false,
      sort_order: "",
    };
    this.changeHandler = this.changeHandler.bind(this);

    /* for Document Type */
    this.openAddDocumentTypeModal = this.openAddDocumentTypeModal.bind(this);
    this.openEditDocumentTypeModal = this.openEditDocumentTypeModal.bind(this);
    this.openDeleteDocumentTypeModal =
      this.openDeleteDocumentTypeModal.bind(this);
    this.saveDocumentType = this.saveDocumentType.bind(this);
    this.getDocumentTypeDetails = this.getDocumentTypeDetails.bind(this);
    this.updateDocumentType = this.updateDocumentType.bind(this);
    this.deleteDocumentType = this.deleteDocumentType.bind(this);
  }

  componentDidMount() {
    this.getDocumentTypeData();
  }

  /* To get Document type list data */
  async getDocumentTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var documentTypeList = [];
    var documentTypeListTotal = 0;

    let res = await DocumentTypeService.getDocumentType(
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      documentTypeList = res.data.data ? res.data.data : [];
      documentTypeListTotal = documentTypeList ? documentTypeList.length : 0;
    }
    this.setState({
      documentTypeList: documentTypeList,
      documentTypeListTotal: documentTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To save Document type data */
  saveDocumentType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.document_category_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        document_category_name: this.state.document_category_name,
        sort_order: this.state.sort_order,
        is_onboarding: this.state.is_onboarding === true ? 1 : 0,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await DocumentTypeService.createDocumentType(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddDocumentTypeModal();
        this.setState({
          document_category_name: "",
          is_onboarding: false,
          sort_order: "",
          status_id: true,
        });
        this.getDocumentTypeData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get Document type details data  */
  async getDocumentTypeDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await DocumentTypeService.getDocumentTypeBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.document_category_name = res.data.document_category_name
          ? res.data.document_category_name
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        resData.is_onboarding = res.data.is_onboarding === 1 ? true : false;
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update Document type data */
  updateDocumentType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.document_category_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        document_category_name: this.state.document_category_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
        is_onboarding: this.state.is_onboarding === true ? 1 : 2,
      };

      let res = await DocumentTypeService.updateDocumentType(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditDocumentTypeModal();
        this.setState({
          document_category_name: "",
          is_onboarding: false,
          sort_order: "",
          status_id: true,
        });
        this.getDocumentTypeData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete Document type data */
  async deleteDocumentType(slug) {
    this.setState({ showSpinner: true });
    let res = await DocumentTypeService.deleteDocumentType(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteDocumentTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getDocumentTypeData();
    } else {
      this.closeDeleteDocumentTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* Document Type Modals */
  openAddDocumentTypeModal() {
    this.setState({ addDocumentTypeModal: true });
  }

  closeAddDocumentTypeModal() {
    this.setState({ addDocumentTypeModal: false });
    this.setState({
      document_category_name: "",
      is_onboarding: false,
      sort_order: "",
      errors: "",
      status_id: true,
    });
    this.validator.hideMessages();
  }

  openEditDocumentTypeModal(slug) {
    this.getDocumentTypeDetails(slug);
    this.setState({ editDocumentTypeModal: true });
    this.setState({ slug: slug });
  }

  closeEditDocumentTypeModal() {
    this.setState({ editDocumentTypeModal: false });
    this.setState({
      slug: "",
      document_category_name: "",
      is_onboarding: false,
      sort_order: "",
      errors: "",
      status_id: true,
    });
    this.validator.hideMessages();
  }

  openDeleteDocumentTypeModal(slug) {
    this.setState({ deleteDocumentTypeModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteDocumentTypeModal() {
    this.setState({ deleteDocumentTypeModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Document Type";
  }

  render() {
    return (
      <>
        {/* Document Type card */}
        {/* <Card>
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="6"
              id="6"
              onClick={(e) => this.setOpen(!this.state.open, e)}
            >
              Document Type
              <i className="mdi mdi-view-headline"> </i>
              {this.state.open && this.state.eventKey === "6" ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-up"></i>
              )}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
                <Button
                  variant="outline-dark btn-block btn-with-icon"
                  onClick={this.openAddDocumentTypeModal}
                >
                  <img src={pluswIcon}/> Add
                </Button>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="table-responsive">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-50">Document Type Name</th>
                      <th className="w-25">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.documentTypeListTotal > 0 ? (
                      this.state.documentTypeList.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="hand-cursor"  onClick={() =>
                                    this.openEditDocumentTypeModal(item.slug)
                                  }>{item.document_category_name}</td>
                            <td>
                              {item.status_id === 1 ? (
                                <b className="active"></b>
                              ) : (
                                <b></b>
                              )}
                            </td>
                            <td>
                              <div className="btn-icon-list d-block text-center">
                                <img
                                  src={deleteicon}
                                  alt="Delete"
                                  onClick={() =>
                                    this.openDeleteDocumentTypeModal(
                                      item.slug
                                    )
                                  }
                                  className="hand-cursor"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          No rows found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}

        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                          <Button
                            variant="outline-dark btn-block btn-with-icon"
                            onClick={this.openAddDocumentTypeModal}
                          >
                            <img src={pluswIcon} /> Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      {/* <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
            <Button
              variant="outline-dark btn-block btn-with-icon"
              onClick={this.openAddDocumentTypeModal}
            >
              <img src={pluswIcon}/> Add
            </Button>
          </div> */}
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.documentTypeListTotal > 0 ? (
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th className="w-50">Document Type Name</th>
                              <th className="w-50">Is Onboarding</th>
                              <th className="w-25">Status</th>
                              {global.userPermissions.checkPermission(
                                "master-data-delete"
                              ) && <th className="text-center">Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.documentTypeListTotal > 0 ? (
                              this.state.documentTypeList.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      className="hand-cursor"
                                      onClick={() => {
                                        global.userPermissions.checkPermission(
                                          "master-data-update"
                                        ) &&
                                          this.openEditDocumentTypeModal(
                                            item.slug
                                          );
                                      }}
                                    >
                                      {item.document_category_name}
                                    </td>
                                    <td>
                                      {item.is_onboarding === 1 ? "Yes" : "No"}
                                    </td>
                                    <td>
                                      {item.status_id === 1 ? (
                                        <b className="active"></b>
                                      ) : (
                                        <b></b>
                                      )}
                                    </td>
                                    {global.userPermissions.checkPermission(
                                      "master-data-delete"
                                    ) && (
                                      <td>
                                        <div className="btn-icon-list d-block text-center">
                                          <img
                                            src={deleteicon}
                                            alt="Delete"
                                            onClick={() =>
                                              this.openDeleteDocumentTypeModal(
                                                item.slug
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                  There are no document types added in the
                                  system. You may add them by clicking Add
                                  button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage(
                            "DOCUMENTTYPE"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add Document Type popup modal */}
        <Modal
          show={this.state.addDocumentTypeModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddDocumentTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddDocumentTypeModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Document Type</h3>
            <label>Document Type Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="document_category_name"
              value={this.state.document_category_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Document Type Name"
            />
            {this.state.errors.document_category_name ? (
              <div className="text-danger">
                {this.state.errors.document_category_name}
              </div>
            ) : (
              this.validator.message(
                "document_category_name",
                this.state.document_category_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <label
              htmlFor="international_property"
              className="form-label text-left pd-b-5 hand-cursor mg-t-5"
            >
              Is Onboarding
            </label>

            <Form.Check
              name="is_onboarding"
              type={"checkbox"}
              id={`is_onboarding`}
              label={""}
              className={"ckbox"}
              onChange={this.changeHandler}
              checked={this.state.is_onboarding}
            />

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddDocumentTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveDocumentType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit Document Type popup modal */}
        <Modal
          show={this.state.editDocumentTypeModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditDocumentTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditDocumentTypeModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Document Type</h3>
            <label>Document Type Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="document_category_name"
              value={this.state.document_category_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Document Type Name"
            />
            {this.state.errors.document_category_name ? (
              <div className="text-danger">
                {this.state.errors.document_category_name}
              </div>
            ) : (
              this.validator.message(
                "document_category_name",
                this.state.document_category_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <label
              htmlFor="international_property"
              className="form-label text-left pd-b-5 hand-cursor mg-t-5"
            >
              Is Onboarding
            </label>

            <Form.Check
              name="is_onboarding"
              type={"checkbox"}
              id={`is_onboarding`}
              label={""}
              className={"ckbox"}
              onChange={this.changeHandler}
              checked={this.state.is_onboarding}
            />

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditDocumentTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateDocumentType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete Document Type popup modal */}
        <Modal
          show={this.state.deleteDocumentTypeModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteDocumentTypeModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteDocumentTypeModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteDocumentTypeModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteDocumentType(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default DocumentType;
