////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Listings.jsx                                    //
//  Application: Listings                                    //
//  Option: List all Listing                                //
//  Developer: Ashish Kumar					                      //
//  Date: 2022-02-03                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import ListingService from "../../services/ListingService";
import UnitsService from "../../services/UnitsService";
import ResidentService from "../../services/ResidentService";
import MasterDataService from "../../services/MasterDataService";
import ErrorLogo from "./../../assets/images/errorImage.svg";
import SuccessLogo from "./../../assets/images/successImage.svg";
import { Link } from "react-router-dom";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import {
  Container,
  Row,
  Col,
  Modal,
  InputGroup,
  Form,
  Nav,
  Tab,
} from "react-bootstrap";
import LisitingSidebar from "../common/LisitingSidebar";
import pluswIcon from "./../../assets/images/plus.svg";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Alerts from "../common/Alerts";
import Moment from "moment";
import NoData from "../common/NoData";
import DatePicker from "react-datepicker";
import Select from "react-select";
import queryString from "query-string";
import copyIcon from "./../../assets/images/copy.png";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import exportIcon from "./../../assets/images/export.svg";
import { FileUploader } from "react-drag-drop-files";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyLisitingSidebar from "../common/AllPropertyLisitingSidebar";
let queryParams = queryString.parse(window.location.search);
export class Listings extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      errors: {},
      importModal: false,
      file_url: "",
      file_name: "",
      open: true,
      eventKey: "3",
      is_inactive: false,
      is_copied: true,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      isSearch: global.isSearch,
      isModel: global.isModel,
      alertType: "xlsx",
      module_search: "",
      exportMesssage: "",
      exportFilePath: "",
      exportResult: "",
      is_search: 0,
      input: {},
      showAlertModal: false,
      alertModalType: "",
        errorResponse : false,
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      residentList: [],
      newResidentList: [],
      selectedResidentList: [],
      rentingTypeList: [],
      newRentingTypeList: [],
      selectedRentingTypeList: [],
      lisitngList: [],
      newListingList: [],
      importResponseModel: false,
      selectedListingList: [],
      tab: "active",
      listingTabData: localStorage.getItem("listing_tab_data")
        ? localStorage.getItem("listing_tab_data")
        : "",
      activeClass: "activeTab",
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      selectedStatusList: [],
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "unit_id",
          text: "Unit",
          className: "unit_id hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.unit ? (
              <Link
                to={
                  this.props.match.params.propertySlug !== undefined
                    ? "/properties/view/" +
                      this.state.property_slug +
                      "/listing/" +
                      record.slug +
                      "/info"
                    : this.state.property_slug
                    ? "/listing/view/" +
                      this.state.property_slug +
                      "/" +
                      record.slug +
                      "/info"
                    : "/listing/view/" +
                      record.property_slug +
                      "/" +
                      record.slug +
                      "/info"
                }
                className=""
              >
                {record.unit && record.unit.unit_name
                  ? record.unit.unit_name
                  : record.unit.unit_number}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "listing_name",
          text: "Listing Name",
          className: "listing_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.listing_name ? (
              <Link
                to={
                  this.props.match.params.propertySlug !== undefined
                    ? "/properties/view/" +
                      this.state.property_slug +
                      "/listing/" +
                      record.slug +
                      "/info"
                    : this.state.property_slug
                    ? "/listing/view/" +
                      this.state.property_slug +
                      "/" +
                      record.slug +
                      "/info"
                    : "/listing/view/" +
                      record.property_slug +
                      "/" +
                      record.slug +
                      "/info"
                }
                className=""
              >
                {record.listing_name}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "user_id",
          text: "Resident",
          className: "first_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.residentUser &&
              record.residentUser.first_name &&
              record.residentUser.last_name ? (
              <Link
                to={
                  this.props.match.params.propertySlug !== undefined
                    ? "/properties/view/" +
                      this.state.property_slug +
                      "/listing/" +
                      record.slug +
                      "/info"
                    : this.state.property_slug
                    ? "/listing/view/" +
                      this.state.property_slug +
                      "/" +
                      record.slug +
                      "/info"
                    : "/listing/view/" +
                      record.property_slug +
                      "/" +
                      record.slug +
                      "/info"
                }
                className=""
              >
                {record.residentUser.first_name +
                  " " +
                  record.residentUser.last_name}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "nightly_rate",
          text: "ADR",
          className: "nightly_rate hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.nightly_rate ? (
              <Link
                to={
                  this.props.match.params.propertySlug !== undefined
                    ? "/properties/view/" +
                      this.state.property_slug +
                      "/listing/" +
                      record.slug +
                      "/info"
                    : this.state.property_slug
                    ? "/listing/view/" +
                      this.state.property_slug +
                      "/" +
                      record.slug +
                      "/info"
                    : "/listing/view/" +
                      record.property_slug +
                      "/" +
                      record.slug +
                      "/info"
                }
                className=""
              >
                {"$" +
                  global.onKeyPressEvent.numberWithCommasForDecimal(
                    Math.round(record.nightly_rate)
                  )}
              </Link>
            ) : (
              "$0"
            );
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <Link
                to={
                  this.props.match.params.propertySlug !== undefined
                    ? "/properties/view/" +
                      this.state.property_slug +
                      "/listing/" +
                      record.slug +
                      "/info"
                    : this.state.property_slug
                    ? "/listing/view/" +
                      this.state.property_slug +
                      "/" +
                      record.slug +
                      "/info"
                    : "/listing/view/" +
                      record.property_slug +
                      "/" +
                      record.slug +
                      "/info"
                }
                className=""
              >
                <b className="status-active m-0">Active</b>{" "}
              </Link>
            ) : (
              <Link
                to={
                  this.props.match.params.propertySlug !== undefined
                    ? "/properties/view/" +
                      this.state.property_slug +
                      "/listing/" +
                      record.slug +
                      "/info"
                    : this.state.property_slug
                    ? "/listing/view/" +
                      this.state.property_slug +
                      "/" +
                      record.slug +
                      "/info"
                    : "/listing/view/" +
                      record.property_slug +
                      "/" +
                      record.slug +
                      "/info"
                }
                className=""
              >
                <b className="status-inactive">InActive</b>
              </Link>
            );
          },
        },
        global.userPermissions.checkPermission("single-unit-add") && {
          key: "action",
          text: "Action",
          className: "action hand-cursor",
          sortable: false,
          cell: (record) => {
            return (
              <>
                {this.state.is_copied ? (
                  <OverlayTrigger
                    trigger="click"
                    placement="top"
                    overlay={
                      <Popover
                        id="popover-basic"
                        className="popover-primary copide_box"
                      >
                        <Popover.Title as="h3" className="p-0">
                          Copied
                        </Popover.Title>
                      </Popover>
                    }
                  >
                    <Button className="btn btn-copy_bg">
              <img
                src={copyIcon}
                alt="Copy"
                onClick={() => this.copyListing(record.slug)}
                className="copyClass"
              />
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <Button className="btn btn-copy_bg">
                    <img
                      src={copyIcon}
                      alt="Copy"
                      onClick={() => this.copyListing(record.slug)}
                      className="copyClass"
                    />
                  </Button>
                )}
              </>
            );
          },
        },
      ],
      furnished_data: [
        {
          label: "Furnished",
          value: 1,
          slug: "furnished"
        },
        {
          label: "Unfurnished",
          value: 2,
          slug: "unfurnished"
        }
      ],
      guest_count : ""
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.deleteRooms = this.deleteRooms.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.copyListing = this.copyListing.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.confirmImport = this.confirmImport.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    switch (this.state.listingTabData) {
      case "active":
        document.getElementById("activeTab").click();
        break;
      case "inactive":
        document.getElementById("inactiveTab").click();
        break;
      default:
        document.getElementById("activeTab").click();
    }
    // if (queryParams.isGlobal === "1") {
    //   this.getData(
    //     "is_search=1&tab=" + this.state.listingTabData + "&" +
    //       queryParams.global_col_name +
    //       "=" +
    //       queryParams.global_search
    //   );
    // } else {
    //   // this.getData();
    // }
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      if (queryParams.isGlobal === "1") {
        this.getData(
          "is_search=1&tab=" +
            this.state.listingTabData +
            "&" +
            queryParams.global_col_name +
            "=" +
            queryParams.global_search
        );
      } else {
        this.getData("tab=active");
      }

      setTimeout(() => {
        localStorage.setItem(
          "listing_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      if (queryParams.isGlobal === "1") {
        this.getData(
          "is_search=1&tab=" +
            this.state.listingTabData +
            "&" +
            queryParams.global_col_name +
            "=" +
            queryParams.global_search
        );
      } else {
        this.getData("tab=inactive");
      }

      setTimeout(() => {
        localStorage.setItem(
          "listing_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async copyListing(slug, queryString = "listing_copy=1") {
    let res = await ListingService.copyListing(slug,  (queryString ? queryString : ""));
    if (global.successStatus.includes(res.status)) {
      setTimeout(
        () =>
          this.props.history.push(this.props.match.params.propertySlug !== undefined ?
            "/properties/view/" + this.state.property_slug + "/listing" : "/listings"
          ),
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        }),
        global.redirect_time
      );
      this.setState({ copyPageNumber: 1 });
      this.setState({ is_copied: false });
      this.getData("tab=" + this.state.tab);
      this.copyListingImages(slug);
    } else {
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async copyListingImages(slug) {
    await ListingService.copyListingImg(slug);
    // if (global.successStatus.includes(res.status)) {
    //   setTimeout(
    //     () =>
    //       this.props.history.push(
    //         "/properties/view/" + this.state.property_slug + "/listing"
    //       ),
    //     this.setState({
    //       showAlertModal: true,
    //       alertModalType: "success",
    //       alertModalTitle: "Success",
    //       alertModalMessage: res.message ? res.message : "Success",
    //     }),
    //     global.redirect_time
    //   );
    //   this.setState({ copyPageNumber: 1 });
    //   this.getData("tab=" + this.state.tab);
    // } else {
    //   let alertMessage = "";
    //   this.setState({
    //     showAlertModal: true,
    //     alertModalType: "error",
    //     alertModalTitle: res.data.message ? res.data.message : "Error!",
    //     alertModalMessage:
    //       alertMessage !== "" ? alertMessage : res.data.message,
    //   });
    // }
    // this.setState({ showSpinner: false });
    // setTimeout(
    //   () => this.setState({ showAlertModal: false }),
    //   global.alert_close_time
    // );
  }

  async getData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    var res = [];
    if (
      this.props.match.params.propertySlug !== undefined ||
      this.state.property_slug !== undefined
    ) {
      res = await ListingService.getListingList(
        "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
          (queryString ? "&" + queryString : "")
      );
    }
    // else {
    //   res = await ListingService.getListingList(
    //     queryString ? "&" + queryString : ""
    //   );
    // }

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      // totalRecords = list.length ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
      is_copied: true,
    });
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  //to get resident data for search filter
  async getResidentData(queryString = "") {
    var residentList = [];
    var residentListTotal = 0;

    let res = await ResidentService.getResidentList(
      "is_dropdown=1&property_slug=" + (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      residentList = res.data ? res.data : [];
      residentListTotal = residentList ? residentList.length : 0;
    }
    const newResidentList =
      residentListTotal > 0
        ? residentList.map(({ slug, first_name, last_name }) => ({
            value: slug,
            label: first_name + " " + last_name,
          }))
        : [];
    this.setState({
      residentList: residentList,
      residentListTotal: residentListTotal,
      newResidentList: newResidentList,
    });
  }

  /* to get renting type data */
  async getRentingTypeData(queryString = "") {
    var rentingTypeList = [];
    var rentingTypeListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=renting_type",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      rentingTypeList = res.data ? res.data : [];
      rentingTypeListTotal = rentingTypeList ? rentingTypeList.length : 0;
    }
    const newRentingTypeList =
      rentingTypeListTotal > 0
        ? rentingTypeList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : [];
    this.setState({
      rentingTypeList: rentingTypeList,
      rentingTypeListTotal: rentingTypeListTotal,
      newRentingTypeList: newRentingTypeList,
    });
  }

  /* to get listing data list */
  async getListingData(queryString = "") {
    var listingList = [];
    var listingListTotal = 0;

    let res = await ListingService.getListingList(
      "is_dropdown=1&property_slug=" + (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      listingList = res.data ? res.data : [];
      listingListTotal = listingList ? listingList.length : 0;
    }
    const newListingList =
      listingListTotal > 0
        ? listingList.map(({ slug, listing_name, unit }) => ({
            value: slug,
            label: unit
              ? listing_name +
                (unit && unit.unit_name ? " (" + unit.unit_name + ") " : "")
              : listing_name,
          }))
        : [];
    this.setState({
      listingList: listingList,
      listingListTotal: listingListTotal,
      newListingList: newListingList,
    });
  }

  async ExportData() {
    let data = {
      property_slug: this.state.property_slug,
      file_type: this.state.alertType,
      where_column:
        this.state.customQueryString.length != 0
          ? this.state.customQueryString
          : "tab=" + this.state.tab,
    };
    let res = await ListingService.exportDataFilewise(data);
    this.setState({ exportMesssage: res.message });
    this.setState({ exportResult: data });
    this.setState({ exportFilePath: res.data.filePath });
  }

  handleChangeStatusList(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeResidentList(value) {
    let resident_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        resident_slug.push(item.value);
      });
    }
    const resident_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedResidentList: value,
      resident_slug: resident_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeRentingTypeList(value) {
    let renting_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        renting_type.push(item.value);
      });
    }
    const renting_type_value = value !== null ? value.value : [];
    if (value !== null && value.label !== "Entire Unit") {
      this.setState({ checkListingType: 1 });
    } else {
      this.setState({ checkListingType: 0 });
    }
    this.setState({
      selectedRentingTypeList: value,
      renting_type: renting_type_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeListingList(value) {
    let listing_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        listing_slug.push(item.value);
      });
    }
    const listing_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedListingList: value,
      listing_slug: listing_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeFurnishedList(value){
    let furnished_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        furnished_slug.push(item.value);
      });
    }
    const furnished_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedFurnishedList: value,
      furnished_slug: furnished_slug_value,
    });
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      switch (this.state.listingTabData) {
        case "active":
          document.getElementById("activeTab").click();
          break;
        case "inactive":
          document.getElementById("inactiveTab").click();
          break;
        default:
          document.getElementById("activeTab").click();
      }
    }, 200);
    this.setState({
      unit_number: "",
      module_search: [],
      is_search: 0,
    });
  }

  filterData() {
    let startDate = this.state.from_date
      ? Moment(this.state.from_date).format("YYYY-MM-DD")
      : "";
    let endDate = this.state.to_date
      ? Moment(this.state.to_date).format("YYYY-MM-DD")
      : "";

    let queryString =
      "from_date=" +
      startDate +
      "&to_date=" +
      endDate +
      // "&listing_slug=" +
      // (this.state.listing_slug ? this.state.listing_slug : '') +
      "&listing_name=" +
      (this.state.listing_name ? this.state.listing_name : "") +
      "&unit_name=" +
      (this.state.unit_number ? this.state.unit_number : "") +
      "&listing_type_slug=" +
      (this.state.renting_type ? this.state.renting_type : "") +
      "&resident_name=" +
      (this.state.resident_name ? this.state.resident_name : "") +
      // "&resident_slug=" +
      // (this.state.resident_slug ? this.state.resident_slug : "") +
      "&nightly_rate=" +
      (this.state.nightly_rate ? this.state.nightly_rate : "") +
      "&total_amount=" +
      (this.state.total_amount ? this.state.total_amount : "") +
      "&furniture_type=" +
      (this.state.furnished_slug ? this.state.furnished_slug : "") +
      "&guest_count=" +
      (this.state.guest_count ? this.state.guest_count : "") +
      // "&status_id=" +
      // (this.state.status_id ? this.state.status_id : "") +
      "&tab=" +
      this.state.tab +
      "&is_search=1";

    // let moduleSearchString =
    //   // (this.state.listing_slug ? "listing - " + this.state.selectedListingList.label : '') +
    //   (this.state.listing_name ? "listing name - " + this.state.listing_name : "") +
    //   (this.state.unit_slug ? " & unit - " + this.state.selectedUnitList.label : "") +
    //   (this.state.renting_type ? " & renting type - " + this.state.selectedRentingTypeList.label : "") +
    //   (this.state.resident_slug ? " & resident - " + this.state.selectedResidentList.label : "") +
    //   (this.state.from_date ? " & from date - " + startDate : "") +
    //   (this.state.to_date ? " & to date - " + endDate : "") +
    //   (this.state.nightly_rate ? " & nightly rate - " + this.state.nightly_rate : "") +
    //   (this.state.total_amount ? " & total amount - " + this.state.total_amount : "") +
    //   (this.state.status_id ? " & status - " + this.state.selectedStatusList.label : "");

    let moduleSearchString = "";
    moduleSearchString = this.state.listing_name
      ? "Listing Name - " + this.state.listing_name
      : "";
    moduleSearchString = this.state.unit_number
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Unit - " +
          this.state.unit_number
        : " Unit - " + this.state.unit_number
      : moduleSearchString;
    moduleSearchString = this.state.renting_type
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Renting Type - " +
          this.state.selectedRentingTypeList.label
        : " Renting Type - " + this.state.selectedRentingTypeList.label
      : moduleSearchString;
    moduleSearchString = this.state.resident_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Resident - " + this.state.resident_name
        : " Resident - " + this.state.resident_name
      : moduleSearchString;
    moduleSearchString = this.state.from_date
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " From Date - " +
        Moment(this.state.from_date).format(global.dateFormat)
        : " From Date - " +
        Moment(this.state.from_date).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.to_date
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " To Date - " +
          Moment(this.state.to_date).format(global.dateFormat)
        : " To Date - " + Moment(this.state.to_date).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.nightly_rate
      ? moduleSearchString
        ? moduleSearchString + " ," + "ADR - " + this.state.nightly_rate
        : " ADR - " + this.state.nightly_rate
      : moduleSearchString;
    moduleSearchString = this.state.total_amount
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Total Amount - " +
          this.state.total_amount
        : "Total Amount - " + this.state.total_amount
      : moduleSearchString;
    moduleSearchString = this.state.status_id
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Status - " +
          this.state.selectedStatusList.label
        : " Status - " + this.state.selectedStatusList.label
      : moduleSearchString;
    moduleSearchString = this.state.furnished_slug
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
        " Furniture Type - " +
          this.state.selectedFurnishedList.label
        : " Furniture Type - " + this.state.selectedFurnishedList.label
      : moduleSearchString;
    moduleSearchString = this.state.guest_count ? (moduleSearchString ? (moduleSearchString + " ," + " Guest Count - " + this.state.guest_count) : (" Guest Count - " + this.state.guest_count)) : moduleSearchString

    if (
      this.state.listing_name ||
      this.state.unit_number ||
      this.state.renting_type ||
      this.state.resident_name ||
      this.state.from_date ||
      this.state.to_date ||
      this.state.nightly_rate ||
      this.state.total_amount ||
      this.state.furnished_slug ||
      this.state.guest_count ||
      this.state.status_id
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData("tab=" + this.state.tab + "&" + queryString);
  }

  resetDate() {
    this.setState({ start_date: "" });
    this.setState({ end_date: "" });
    this.getData("tab=" + this.state.tab);
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getUnitData();
      // this.getResidentData();
      this.getRentingTypeData();
      this.getListingData();
      this.setState({ isSearch: 1 });
    }
  };

  exportToggle = () => {
    if (this.state.isModel === 1) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: 0 });
    } else {
      this.setState({ isModel: 1 });
    }
  };

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      from_date: "",
      to_date: "",
      listing_slug: "",
      unit_slug: "",
      resident_name: "",
      nightly_rate: "",
      total_amount: "",
      furnished_slug:"",
      guest_count : "",
      status_id: "",
      selectedListingList: [],
      selectedUnitList: [],
      selectedRentingTypeList: [],
      selectedResidentList: [],
      selectedStatusList: [],
      selectedFurnishedList:[]
    });
    this.setState({ module_search: "" });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData("tab=" + this.state.tab + "&" + queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  async deleteRooms(slug) {
    this.setState({ showSpinner: true });
    let res = await ListingService.deleteListing(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.getData();
    } else {
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  getTitle() {
    return "Listings";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }

  handleChange = (file) => {
    this.setState({ file_name: file.name });
    this.setState({ file_url: file });
  };

  async confirmImport() {
    if (this.state.file_url) {
      this.setState({ showSpinner: true, isSubmit: true });
      const fileData = new FormData();
      fileData.append("import_file", this.state.file_url);
      fileData.append("file_name", this.state.file_name);
      fileData.append("property_slug", this.state.property_slug);
      let res = await ListingService.importData(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({ importModal: false });
        this.setState({ importResponseModel: true });
        this.setState({ successResponse: true });
        this.setState({ successMessage: res.message });
        if (res.data.alertMessage) {
          const totalRecord = res.data.alertMessage.total_record;
          const successRecord = res.data.alertMessage.success_record;
          const skipRecord = res.data.alertMessage.skip_record;
          const skipData = res.data.alertMessage.skip_data;
          this.setState({
            totalRecord: totalRecord,
          });
          this.setState({
            successRecord: successRecord,
          });
          this.setState({
            skipRecord: skipRecord,
          });
          this.setState({
            skipData: skipData,
          });
        }
        this.getData();
      } else {
        this.setState({ importModal: false });
        this.setState({ importResponseModel: true });
        this.setState({ errorResponse: true });
        this.setState({ errorMessage: res.data.message });
        if (res.data.result.alertMessage) {
          const totalRecord = res.data.result.alertMessage.total_record;
          const successRecord = res.data.result.alertMessage.success_record;
          const skipRecord = res.data.result.alertMessage.skip_record;
          const skipData = res.data.result.alertMessage.skip_data;
          if (successRecord !== undefined) {
            this.setState({
              totalRecord: totalRecord,
            });
            this.setState({
              successRecord: successRecord,
            });
            this.setState({
              skipRecord: skipRecord,
            });
            this.setState({
              skipData: skipData,
            });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false, show: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  }

  confirmImportModal() {
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
    this.validator.hideMessages();
  }

  closeimportResponseModel() {
    this.setState({ importResponseModel: false });
    this.validator.hideMessages();
  }

  // rowClickedHandler = (event, data, rowIndex) => {
  //   this.props.history.push("listing/" + data.slug + "/info");
  // };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container pl-3">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <LisitingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyLisitingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  {/* <div className="col-md-12"> */}

                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0 pd-lg-l-15">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex flex-wrap justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "single-unit-add"
                        ) &&
                          this.state.property_slug && (
                            <Link
                              to={
                                this.props.match.params.propertySlug !==
                                undefined
                                  ? "listing/add"
                                  : "listing/add/" + this.state.property_slug
                              }
                            >
                              <button className="btn-success-outline-small ml-3">
                                <img src={pluswIcon} alt="" /> Add
                              </button>
                            </Link>
                          )}
                        <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small ml-3"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button>
                        {global.userPermissions.checkPermission(
                          "single-unit-export"
                        ) && (
                          <button
                            className="btn-success-outline-small ml-3"
                            onClick={this.exportToggle}
                          >
                            Export
                          </button>
                        )}
                        {global.userPermissions.checkPermission(
                          "single-unit-import"
                        ) &&
                        <button
                          type="button"
                          onClick={() => this.confirmImportModal()}
                          className="btn-success-outline-small ml-3"
                        >
                          {" "}
                          <img src={exportIcon} /> Import{" "}
                        </button>
                      }
                      </div>
                    </div>
                  </div>
                  <div className="scrolling-carousel pd-l-15 Listingview">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.showFilter ? (
                        <Row className="align-items-center order_form">
                          <Col
                            md={3}
                            lg={5}
                            xl={5}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <input
                              name="filter_value"
                              className="form-control"
                              autoComplete="off"
                              // placeholder="Filter Value"
                              value={this.state.filter_value}
                              onChange={this.changeHandler}
                              maxLength="30"
                            />{" "}
                          </Col>
                          <Col md={5} lg={5} xl={5}>
                            <Row>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-30 mg-r-20"
                              >
                                Search
                              </button>
                              <button
                                onClick={this.resetDate}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}

                      {queryParams.isGlobal === "1" ? (
                        <>
                          <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between  scrolling-carousel">
                            {"Search applied on: " +
                              queryParams.global_col_label +
                              " - " +
                              (queryParams.global_col_name == "from_date" ||
                              queryParams.global_col_name == "to_date"
                                ? Moment(queryParams.global_search).format(
                                    global.dateFormat
                                  )
                                : queryParams.global_search)}
                            <button
                              onClick={this.clearFilter}
                              className="mg-l-15 btn-success-outline-small"
                            >
                              Clear
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {this.props.match.params.propertySlug === undefined ? (
                         <>
                         <div className="row pd-lg-l-15 pd-xs-l-15">
                            <div className="col-xxl-2 col-sm-3 pd-r-lg-15 pd-xs-l-0 pd-lg-l-30 pd-xs-l-15">
                              <OutSidePropertySelectBox
                                propertySlug={this.getPropertySlug.bind(this)}
                                outSidePropertySlug={this.state.outSidePropertySlug}
                              />
                            </div>
                            <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                            {this.state.is_search === 1 ? (
                              <>
                                <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                                  <div className="custm-filter">
                                    <div> <label className="form-label text-left pd-b-3 mb-0">Search Applied On</label></div>
                                    <div className="filter_bg_cust">
                                      {
                                        this.state.module_search.map((item, i) => {
                                          return (
                                            <span key={i}>{item}</span>
                                          )
                                        })
                                      }
                                      <button
                                        onClick={this.clearFilter}
                                        className="btn-fliter-clear"
                                      >Clear</button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                        ""
                      )}
                            </div>
                        </div>
                        </>
                      ) : (
                        <>
                        <div className="col-xxl-12 pd-r-15 pd-lg-l-0 pd-xs-l-15 mb-2">
                        {this.state.is_search === 1 ? (
                              <>
                                <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                                  <div className="custm-filter">
                                    <div> <label className="form-label text-left pd-b-3 mb-0">Search Applied On</label></div>
                                    <div className="filter_bg_cust">
                                      {
                                        this.state.module_search.map((item, i) => {
                                          return (
                                            <span key={i}>{item}</span>
                                          )
                                        })
                                      }
                                      <button
                                        onClick={this.clearFilter}
                                        className="btn-fliter-clear"
                                      >Clear</button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                        ""
                      )}
                        </div>
                        </>
                      )}
                      <div className="scrolling-carousel pd-l-0 scroll-slide">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="all"
                          onSelect={(e) => this.getActiveClass(e)}
                          eventKey={this.state.activeClass}
                          className="pd-r-60"
                        >
                          <ScrollingCarousel
                            rightArrow={true}
                            leftArrow={true}
                            className="swipesection scroll_nav_mob"
                          >
                            <Nav.Item>
                              <Nav.Link
                                eventKey="activeTab"
                                id="activeTab"
                                className={
                                  this.state.activeClass === "activeTab"
                                    ? "active"
                                    : ""
                                }
                              >
                                Listed
                              </Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                              <Nav.Link
                                eventKey="inactiveTab"
                                id="inactiveTab"
                                className={
                                  this.state.activeClass === "inactiveTab"
                                    ? "active"
                                    : ""
                                }
                              >
                                Unlisted
                              </Nav.Link>
                            </Nav.Item>
                          </ScrollingCarousel>
                          <Tab.Content>
                            <Tab.Pane eventKey="activeTab">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                {this.state.total_record > 0 ||
                                this.state.filter_value !== "" ? (
                                  <div className="mg-t-18 brandList">
                                     <div className="pd-lg-l-0 pd-xs-l-15">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap"
                                      config={this.state.config}
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      // loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      // onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                  </div>
                                ) : (
                                  <NoData msg={global.commonMessages.checkMessage("LISTING_ACTIVE")} />
                                )}
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="inactiveTab">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                {this.state.total_record > 0 ||
                                this.state.filter_value !== "" ? (
                                  <div className="mg-t-18 brandList">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap"
                                      config={this.state.config}
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      // loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      // onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                ) : (
                                  <NoData msg={global.commonMessages.checkMessage("LISTING_INACTIVE")} />
                                )}
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Listing</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    {/* <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    /> */}
                     <Form.Control
                      onChange={this.changeHandler}
                      name="unit_number"
                      value={this.state.unit_number}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Listing Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="listing_name"
                      value={this.state.listing_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedRentingTypeList}
                      options={this.state.newRentingTypeList}
                      onChange={(value) =>
                        this.handleChangeRentingTypeList(value)
                      }
                      defaultValue={this.state.selectedRentingTypeList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Resident</label>
                  <div className="col-md-12 p-0">
                    {/* <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedResidentList}
                      options={this.state.newResidentList}
                      onChange={(value) => this.handleChangeResidentList(value)}
                      defaultValue={this.state.selectedResidentList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    /> */}
                    <Form.Control
                      onChange={this.changeHandler}
                      name="resident_name"
                      value={this.state.resident_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                <label>Guest Count</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="guest_count"
                      value={this.state.guest_count}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      onKeyPress={global.onKeyPressEvent.numberValidation}
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>From</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="from_date"
                      selected={this.state.from_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "from_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>To</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="to_date"
                      selected={this.state.to_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "to_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>ADR</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="nightly_rate"
                      value={this.state.nightly_rate}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Furnished Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedFurnishedList}
                      options={this.state.furnished_data}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeFurnishedList(value)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Export  popup modal */}
        <Modal show={this.state.isModel} className="modal-xs" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.exportToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Export</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <InputGroup className="mt-0">
                  <label>Select Export File Type : </label>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="xlsx"
                        checked={this.state.alertType === "xlsx" ? true : false}
                        type={"radio"}
                        id="xlsx"
                        label={"XLSX"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="csv"
                        checked={this.state.alertType === "csv" ? true : false}
                        type={"radio"}
                        id="csv"
                        label={"CSV"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                  </div>
                </InputGroup>

                {this.state.exportMesssage !== "" && (
                  <div>
                    {this.state.exportMesssage}
                    <br></br>
                    <br></br>
                    {this.state.exportFilePath !== undefined && (
                      <a href={this.state.exportFilePath}> Download File </a>
                    )}
                  </div>
                )}
                <div className="d-flex justify-content-center mt-3">
                <button
                    type="button"
                    className="mx-auto- mg-r-30 btn btn btn-success btn-block"
                    onClick={() => this.ExportData()}
                  >
                    {this.state.isSubmit ? global.loader : "Export"}
                  </button>
                  <button
                    type="button"
                    className="mx-auto- m-0  btn btn-outline-light btn-block"
                    onClick={() => this.exportToggle()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* import data modal */}
        <Modal
          show={this.state.importModal}
          className="modal-xs importModalbox"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-center mt-0 row">
                  <form name="myFile">
                    <FileUploader
                      onChange={this.changeHandler}
                      handleChange={this.handleChange}
                      name="file_name"
                      types={["csv", "xlsx", "xls", "ods"]}
                      label="Drop File Here Max Size 30 MB"
                    />

                    {this.state.errors.file_name ? (
                      <div className="text-danger">
                        {this.state.errors.file_name}
                      </div>
                    ) : (
                      this.validator.message(
                        "import_file",
                        this.state.file_name,
                        "required",
                        { className: "text-danger" }
                      )
                    )}

                    <div className="d-flex justify-content-center mt-3 ">
                      <button
                        type="button"
                        className="m-0 btn btn-outline-light btn-block"
                        onClick={() => this.closeConfirmImportModal()}
                      >
                        Cancel
                      </button>

                      <button
                        type="button"
                        className="m-0 btn btn btn-success btn-block"
                        onClick={this.confirmImport}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit ? global.loader : "Import"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row col-12 m-0 download-sample">
              <Link
                to="/import_sample/Listing.xlsx"
                target="_blank"
                download="SampleListing.xlsx"
              >
                Download sample template for Import
              </Link>
            </div>
          </Modal.Body>
        </Modal>
        {/* import data modal */}
        <Modal
          show={this.state.importResponseModel}
          className="modal-xs recordDetailmodalbox"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeimportResponseModel()}
            >
              {global.closee}
            </span>
            {/* <h3 className="text-center">Record detail</h3> */}
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-between mt-0 row">
                  {this.state.errorResponse == true ? (
                    <div className="msg">
                      <img src={ErrorLogo} alt="filterIcon" />{" "}
                      <p>{this.state.errorMessage}</p>{" "}
                    </div>
                  ) : (
                    <div className="msg">
                      <img src={SuccessLogo} alt="filterIcon" />
                      <p>{this.state.successMessage}</p>{" "}
                    </div>
                  )}
                  {this.state.totalRecord ?
                  <div className="moreMsg">
                    <div className="row flex-wrap m-0">
                      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <p className="az-profile-name-text pl-0">Total Record</p>
                        <p className="media-body">{this.state.totalRecord}</p>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <p className="az-profile-name-text pl-0">Success Record</p>
                        <p className="media-body">{this.state.successRecord}</p>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <p className="az-profile-name-text pl-0">Skip Record</p>
                        <p className="media-body">{this.state.skipRecord}</p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <p className="az-profile-name-text pl-0">Skip Data</p>
                        <p className="media-body pr-3" dangerouslySetInnerHTML={{__html:this.state.skipData ? this.state.skipData : "No any record skip"}}></p>
                      </div>
                    </div>
                  </div>
                  : "" }
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Listings;
