import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Modal, Form, Carousel, Tab, Nav } from "react-bootstrap";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import lisitngImage from "./../../assets/images/PropPlaceMainImg.png";
import SimpleReactValidator from "simple-react-validator";
import ListingService from "../../services/ListingService";
import MultiImageService from "../../services/MultiImageService";
import FileWidget from "../fileUploader/FileWidget";
import close from "../../assets/images/close-wel.svg";
import Alerts from "../common/Alerts";
import deleteImg from "../../assets/images/delete.svg";
import zoomImg from "../../assets/images/zoom.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
// import infosize from "../../assets/images/Info.svg";
// import pluswIcon from "./../../assets/images/plus.svg";
import addImg from "./../../assets/images/add-img.png";
import Cropper from "react-easy-crop";
// import "../fileUploader/crop.css";
import getCroppedImg from "../fileUploader/cropImage";
import UploadCropedImage from "../fileUploader/UploadCropedImage";
import Slider from "@material-ui/core/Slider";
// import { Draggable } from "react-drag-reorder";
import Gallery from "react-grid-gallery";
import GalleryProperty from "react-grid-gallery";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import dotIcon from "./../../assets/images/3dots.png";
import { Dropdown } from "react-bootstrap";
import mainImg from "./../../assets/images/main-img.svg";
import interiorImg from "./../../assets/images/interior.svg";
import Download_img from "../../assets/images/download_svg.svg";
import NoData from "../common/NoData";

// import Images from "../marketing/Images";
export class ListingImages extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      slug: "",
      loading: false,
      showSpinner: false,
      importModal: false,
      newRelatedFilesDesc: [],
      lisitng_main_image: "",
      imageUrl: lisitngImage,
      lisitngImageListTotal: 0,
      multiImageListTotal: 0,
      imageLoop: 30,
      multiImageListEdit: [],
      sortOrderForImage: 1,
      isImageEdit: false,
      crop: { x: 0.1, y: 0.1 },
      zoom: 1,
      aspect: 4 / 3,
      rotation: 0,
      croppedAreaPixels: null,
      croppedImage: null,
      croppedImageArr: undefined,
      display_file_name: "",
      currentFile: [],
      isCrop: false,
      reorderdImg: [],
      sortOrderImg: [],
      dragOff: 1,
      newMultiImageList: [],
      newMultiImageListPro: [],
      multiImageListTotalPro: 0,
      is_main_img: false,
      is_web_img: false,
      is_interior_img: false,
      property_slug: this.props.property_slug,
      publishListingModal: false,
      publishMessage: "",
      multiImageListPro: [],
    };
    this.lisitngImage = React.createRef();
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeView = this.changeView.bind(this);
    // this.changeView2 = this.changeView2.bind(this);
    this.confirmImportModal = this.confirmImportModal.bind(this);
    // this.saveOrImage = this.saveOrImage.bind(this);
    this.closeConfirmImportModal = this.closeConfirmImportModal.bind(this);
    this.deleteListingImg = this.deleteListingImg.bind(this);
    this.opendeleteListingImgModal = this.opendeleteListingImgModal.bind(this);
    this.newRelatedFilesDescChange = this.newRelatedFilesDescChange.bind(this);
    this.openListingListImageModal = this.openListingListImageModal.bind(this);
    this.openEditListingDescModal = this.openEditListingDescModal.bind(this);
    this.closeEditListingDescModal = this.closeEditListingDescModal.bind(this);
    this.closeListingListImageModal =
      this.closeListingListImageModal.bind(this);
    this.cropperSet = this.cropperSet.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.cancelCrop = this.cancelCrop.bind(this);
    this.getChangedPos = this.getChangedPos.bind(this);
    this.EditImgSet = this.EditImgSet.bind(this);
    this.openPublishListingModal = this.openPublishListingModal.bind(this);
    this.closePublishListingModal = this.closePublishListingModal.bind(this);
    this.publishListing = this.publishListing.bind(this);
    this.getDownloadFile = this.getDownloadFile.bind(this);
  }

  cropperSet = () => {
    this.setState({ isImageEdit: true });
  };

  EditImgSet = () => {
    this.setState({ isImageEdit: false });
  };

  cancelCrop = () => {
    this.closeEditListingDescModal();
    this.setState({ isImageEdit: false, isCrop: false, zoom: 0.04 });
  };

  cropImage = async () => {
    await this.setState({ isCrop: true });
    // console.log(12121);
    await this.showCroppedImage();
    document.getElementById("left-tabs-example-tab-description").click();
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = async (croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels);
    await this.setState({ croppedAreaPixels });
    // await this.showCroppedImage();
    // this.setState({ isImageEdit: false})
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  showCroppedImage = async () => {
    if (this.state.isCrop) {
      const croppedImage = await getCroppedImg(
        this.state.editModeCropImg,
        this.state.croppedAreaPixels,
        this.state.rotation,
        this.state.display_file_name
      );
      this.setState({
        croppedImage: croppedImage.url,
        croppedImageArr: croppedImage.file,
      });
      // console.log(this.state.croppedImageArr, croppedImage, "1");
      this.setState({ isImageEdit: false });
    }
  };

  newRelatedFilesDescChange(index, value) {
    let newRelatedFilesDesc = this.state.newRelatedFilesDesc;
    newRelatedFilesDesc[index] = value;
    this.setState({ newRelatedFilesDesc: newRelatedFilesDesc });
  }

  openListingListImageModal() {
    this.setState({ roomListImageModal: true });
  }

  closeListingListImageModal() {
    this.setState({ roomListImageModal: false });
  }

  opendeleteListingImgModal(slug) {
    this.closeEditListingDescModal();
    this.setState({ deleteListingImgModal: true, slug: slug });
  }

  closedeleteListingImgModal() {
    this.setState({ deleteListingImgModal: false, slug: "", description: "" });
  }

  openEditListingDescModal(
    slug,
    img_src,
    editModeCropImg,
    description,
    is_main_img,
    is_interior_img,
    display_file_name,
    currentFile
  ) {
    this.setState({
      openEditListingDescModal: true,
      slug: slug,
      img_src: img_src,
      editModeCropImg: editModeCropImg,
      description: description,
      is_main_img: is_main_img,
      is_interior_img: is_interior_img,
      display_file_name: display_file_name,
      currentFile: currentFile,
    });
  }

  closeEditListingDescModal() {
    this.setState({
      openEditListingDescModal: false,
      slug: "",
      description: "",
      is_main_img: "",
      is_interior_img: "",
      isImageEdit: false,
      img_src: "",
      editModeCropImg: "",
      croppedImage: "",
      currentFile: null,
      isCrop: false,
      display_file_name: "",
      zoom: 0.4,
    });
  }

  openListingImgModal(j) {
    this.setState({ addImgModal: true, sortOrderForImage: j });
  }

  closeListingImgModal() {
    this.setState({
      addImgModal: false,
      slug: "",
      description: "",
      is_main_img: "",
      is_interior_img: "",
    });
  }

  async componentDidMount() {
    if (this.props.listing_slug !== undefined) {
      this.setState({ viewMode: 0 });
    }
    this.getMulitImagesData();
    this.getMulitImagesDataProperty();
    // setTimeout(() => console.clear(), 5000);
  }

  async getMulitImagesData(deletedImage = false) {
    if (this.props.listing_slug !== undefined) {
      let inputData = {
        path: "listing_units",
        refTableId: 10,
        slug: this.props.listing_slug,
        mediaType: 1,
        property_slug: this.state.property_slug,
      };
      let res = await MultiImageService.getImages(inputData);

      var multiImageList = [];
      var multiImageListEdit = [];
      var multiImageListTotal = 0;
      if (global.successStatus.includes(res.status)) {
        multiImageList = res.data ? res.data : [];
        multiImageListTotal = multiImageList.length;
        if (multiImageListTotal > 0) {
          multiImageList.forEach((item, i) => {
            multiImageListEdit[item.sort_order] = item;
          });
        }
      }
      multiImageList.map((opt, i) => {
        var orgFile = opt.file_name;
        var src = opt.src;
        var mediumImg = opt.medium_img;
        opt.file_name = mediumImg;
        opt.medium_img = mediumImg;
        opt.editModeCropImg = opt.cropImgSrc;
        multiImageList[i]["caption"] = opt.description ? opt.description : "";
        multiImageList[i]["src"] = orgFile;
        multiImageList[i]["thumbnail"] = mediumImg;
        // multiImageList[i]["thumbnailWidth"] = 1;
        // multiImageList[i]["thumbnailHeight"] = 1;
        // multiImageList[i]["rowHeight"] = 1;
        // multiImageList[i]["maxRows"] = 3;
        // multiImageList[i]["vwidth"] = 1;
        multiImageList[i]["main_image"] = orgFile;
        multiImageList[i]["tags"] = [
          {
            value: global.userPermissions.checkPermission(
              "single-unit-update"
            ) && (
              <img
                src={deleteImg}
                alt=""
                onClick={() => {
                  global.userPermissions.checkPermission(
                    "single-unit-update"
                  ) && this.opendeleteListingImgModal(opt.slug);
                }}
              />
            ),
            key: `delete image ${i}`,
            title: `delete image`,
          },
          {
            value: <img src={zoomImg} alt="" />,
            key: `zoom image ${i}`,
            title: `zoom image`,
          },
          {
            value: (
              <img
                src={Download_img}
                alt=""
                download={true}
                onClick={() => this.getDownloadFile(src, opt.display_file_name)}
              />
            ),
            key: `download image ${i}`,
            title: `download image`,
          },
        ];
      });
      const newMultiImageList = multiImageList;
      let orderResponse = this.setImageOrder(multiImageList, deletedImage);
      if (orderResponse) {
        this.setState({
          multiImageList: multiImageList,
          multiImageListTotal: multiImageListTotal,
          multiImageListEdit: multiImageListEdit,
          newMultiImageList: newMultiImageList,
        });
      }
      if (this.state.viewMode === 1) {
        this.setState({ viewMode: 1 });
      } else {
        if (this.state.multiImageListTotal > 0) {
          this.setState({ viewMode: 0 });
        } else {
          this.setState({ viewMode: 1 });
        }
      }
    }
  }

  setImageOrder = async (imageList, deletedImage) => {
    //check how many image has 0 index
    let zeroIndexImages = imageList.filter((data, index) => {
      return data.sort_order === 0;
    });

    // if any images has more then  2 indexes then we need to reorder the images in index sequesnce
    if (zeroIndexImages.length > 0 || deletedImage === true) {
      //set image sequnce from zero last index plus 1
      imageList.forEach((data, index) => {
        data.sort_order = index + 1;
      });

      let inputData = {
        path: "listing_units",
        refTableId: 3,
        slug: this.props.listing_slug,
        mediaType: 1,
        reorder_images: imageList,
      };

      //call api to set new sort order that will save new order in data base.
      let res = await MultiImageService.reorderImages(inputData);
      //if response is 200 ,again call recall the componant to see corect data.
      if (res.status === 200) {
        this.getMulitImagesData();
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  async getMulitImagesDataProperty() {
    if (this.state.property_slug !== undefined) {
      let inputData = {
        path: "properties",
        refTableId: 3,
        slug: this.state.property_slug,
        mediaType: 1,
      };
      let res = await MultiImageService.getImages(inputData);
      var multiImageList = [];
      var multiImageListEdit = [];
      var multiImageListTotalPro = 0;
      if (global.successStatus.includes(res.status)) {
        multiImageList = res.data ? res.data : [];
        multiImageListTotalPro = multiImageList.length;
        if (multiImageListTotalPro > 0) {
          multiImageList.forEach((item, i) => {
            var sort_order = multiImageList.length;
            multiImageListEdit[item.sort_order] = sort_order - i;
          });
        }
      }
      multiImageList.map((opt, i) => {
        var orgFile = opt.file_name;
        var mediumImg = opt.medium_img;
        opt.file_name = mediumImg;
        opt.medium_img = mediumImg;
        opt.editModeCropImg = opt.cropImgSrc; //cropImg;  //orgFile;
        multiImageList[i]["caption"] = opt.description ? opt.description : "";
        multiImageList[i]["src"] = orgFile;
        multiImageList[i]["thumbnail"] = mediumImg;
        // multiImageList[i]["thumbnailWidth"] = 1;
        // multiImageList[i]["thumbnailHeight"] = 1;
        // multiImageList[i]["rowHeight"] = 1;
        // multiImageList[i]["maxRows"] = 3;
        // multiImageList[i]["vwidth"] = 1;
        multiImageList[i]["main_image"] = orgFile;
        multiImageList[i]["tags"] = [];
      });
      const newMultiImageListPro = multiImageList;
      setTimeout(() => console.log(newMultiImageListPro), 5000);
      this.setState({
        multiImageListPro: multiImageList,
        multiImageListTotalPro: multiImageListTotalPro,
        // multiImageListEdit: multiImageListEdit,
        newMultiImageListPro: newMultiImageListPro,
      });
    }
  }

  /*file download */
  async getDownloadFile(url, name) {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  submitModalImage = async (event) => {
    this.setState({ showSpinner: true, isSubmit: true });
    const FileWidgetObj = new FileWidget();
    const output = await FileWidgetObj.fileUploadFun(
      "lisitng_form"
      // this.state.newRelatedFilesDesc
    );
    this.closeListingImgModal();
    let errorMessage = "";
    if (output.status !== false) {
      var isError = output.response[0].filesData.some((data) => {
        if (data.fileStatus === 500) {
          errorMessage = data.fileMessage;
        }
        return data.fileStatus === 500;
      });
    }
    if (output.status !== false) {
      if (!isError) {
        if (output.response[0].filesData) {
          if (output.response[0].filesData.length > 0) {
            let inputData = {
              availableFiles: output.response,
              deletedFiles: this.state.deletedFileList,
            };

            if (this.props.listing_slug !== undefined) {
              let res = await MultiImageService.submitMultiImage(inputData);
              if (global.successStatus.includes(res.status)) {
                // this.setState({
                //   showAlertModal: true,
                //   alertModalType: "success",
                //   alertModalTitle: "Success",
                //   alertModalMessage: res.message ? res.message : "Success",
                // });
                this.setState({ description: "" });
                this.setState({ newRelatedFilesDesc: [], dragOff: 0 });
                this.getMulitImagesData();
                this.openPublishListingModal(res.message);
              } else {
                let alertMessage = "";
                if (
                  res.data.errorCode === "validationFailed" &&
                  res.data.errorDetail !== null
                ) {
                  let errors = {};
                  res.data.errorCode = "Validation Failed";
                  res.data.errorDetail.forEach((item) => {
                    errors[item.errorField] = item.errorMessage[0];
                    // alertMessage += item.errorMessage[0]+'\n';
                    res.data.errorMessage = "";
                  });
                  this.setState({ errors: errors });
                }
                this.setState({
                  showAlertModal: true,
                  deleteImgModal: false,
                  alertModalType: "error",
                  alertModalTitle: res.data.message
                    ? res.data.message
                    : "Error!",
                  alertModalMessage:
                    alertMessage !== "" ? alertMessage : res.data.message,
                });
              }
            }
          }
        }
      } else {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: output.response[0].filesData
            ? errorMessage
            : "Success",
        });
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: output.response[0].validationMessage[0][0],
      });
    }
    this.setState({ dragOff: 1 });
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
    this.setState({ showSpinner: false, isSubmit: false });
  };

  async reorderImageListing() {
    this.setState({ showSpinner: true, loading: true });
    if (this.props.listing_slug !== undefined) {
      let inputData = {
        path: "listing_units",
        refTableId: 10,
        slug: this.props.listing_slug,
        mediaType: 1,
        // reorder_images: this.state.reorderdImg,
        reorder_images: this.state.sortOrderImg,
      };
      let res = await MultiImageService.reorderImages(inputData);
      if (global.successStatus.includes(res.status)) {
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "success",
        //   alertModalTitle: "Success",
        //   alertModalMessage: res.message ? res.message : "Success",
        // });
        // this.setState({ multiImageListEdit: [], reorderdImg: [], dragOff: 0 });
        // this.getMulitImagesData();
        this.openPublishListingModal();
      } else {
        let alertMessage = "";
        if (
          res.data.errorCode === "validationFailed" &&
          res.data.errorDetail !== null
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          deleteImgModal: false,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
    this.setState({ dragOff: 1 });
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
    this.setState({ showSpinner: false, loading: false });
  }

  async setCoverImg(slug, sortOrder, type) {
    let inputUpdateData = "";
    if (type === "Main") {
      inputUpdateData = {
        refTableId: 10,
        is_listing_main_img : 1,
        propertySlug: this.props.listing_slug,
        mediaType: 1,
        slug: slug ? slug : "",
        sortOrder: sortOrder,
      };
    } else if (type === "Interior") {
      inputUpdateData = {
        is_interior_img: 1,
        refTableId: 10,
        propertySlug: this.props.listing_slug,
        mediaType: 1,
        slug: slug ? slug : "",
      };
    } else if (type === "PropertyMain") {
      inputUpdateData = {
        refTableId: 3,
        is_listing_main_img : 1,
        propertySlug: this.props.property_slug,
        listingSlug : this.props.listing_slug,
        mediaType: 1,
        slug: slug ? slug : "",
        sortOrder: sortOrder,
      };
    } else {
      inputUpdateData = {
        is_web_img: 0,
        refTableId: 10,
        propertySlug: this.props.listing_slug,
        mediaType: 1,
        slug: slug ? slug : "",
      };
    }

    let resMain;
    resMain = await MultiImageService.updateSingleImageDesc(inputUpdateData);
    if (global.successStatus.includes(resMain.status)) {
      // this.setState({
      //   showAlertModal: true,
      //   alertModalType: "success",
      //   alertModalTitle: "Success",
      //   alertModalMessage: resMain.message ? resMain.message : "Success",
      // });
      // this.closeEditPropDescModal();
      this.setState({ propertyImageListTotal: 0, dragOff: 0 });
      this.getMulitImagesData();
      this.getMulitImagesDataProperty();
      this.openPublishListingModal(resMain.message);
    } else {
      let alertMessage = "";
      if (resMain.data.result.errorDetail !== null) {
        let errors = {};
        resMain.data.errorCode = "Validation Failed";
        resMain.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          // alertMessage += item.errorMessage[0]+'\n';
          resMain.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
      this.setState({
        showSpinner: false,
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: resMain.data.errorCode
          ? resMain.data.errorCode
          : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : resMain.data.message,
      });
    }
  }

  async updateImgDesc() {
    let inputUpdateData = {
      slug: this.state.slug ? this.state.slug : "",
      description: this.state.description ? this.state.description : "",
      // is_main_img: this.state.is_main_img === true ? 1 : 0,
      // is_web_img: this.state.is_web_img === true ? 1 : 0,
      // is_interior_img: this.state.is_interior_img === true ? 1 : 0,
      refTableId: 10,
      propertySlug: this.props.listing_slug,
      mediaType: 1,
    };
    let resMain;
    if (this.state.isCrop) {
      this.deleteListingImg(this.state.currentFile.slug);
      let inputData = {
        fieldName: "modal_name",
        uploadedTo: "s3",
        slug: this.props.listing_slug,
        refTableId: 10,
        destinationFolder: "listing_units",
        displayFileName: "listing_units",
        documentTypeSlug: this.props.listing_slug,
        description: this.state.description,
        is_main_img: this.state.is_main_img,
        is_interior_img: this.state.is_interior_img,
        mediaType: 1,
        h1: 65,
        h2: 300,
        sortOrder: this.state.currentFile.sort_order,
        statusId: this.state.currentFile.status_id,
        propertySlug: this.state.property_slug,
        checkValidation: true,
        validationRules: {
          allowedType: "image/jpeg,image/jpg,image/png,image/webp",
          maxFileSize: 1000,
          maxFileSizeType: "mb",
          maxFileAllowed: 10,
          fieldName: "modal_name",
        },
        resizeOptions:
          "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200",
        fileObject: this.state.croppedImageArr,
      };
      let UploadCropedImageObj = new UploadCropedImage();
      resMain = await UploadCropedImageObj.UploadCropedImageToServer(inputData);
      let inputData1 = {
        availableFiles: resMain.response,
      };
      let res = await MultiImageService.submitMultiImage(inputData1);
      if (global.successStatus.includes(res.status)) {
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "success",
        //   alertModalTitle: "Success",
        //   alertModalMessage: res.message ? res.message : "Success",
        // });
        this.setState({
          description: "",
          img_src: "",
          editModeCropImg: "",
          croppedImage: "",
          zoom: 0.4,
          currentFile: null,
          isCrop: false,
          display_file_name: "",
          slug: "",
          openEditPropDescModal: false,
          isImageEdit: false,
        });
        this.setState({ newRelatedFilesDesc: [], dragOff: 0 });
        this.getMulitImagesData();
        this.openPublishListingModal();
      } else {
        let alertMessage = "";
        if (
          res.data.errorCode === "validationFailed" &&
          res.data.errorDetail !== null
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          deleteImgModal: false,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      // console.log(res, "res");
    } else {
      resMain = await MultiImageService.updateSingleImageDesc(inputUpdateData);
      if (global.successStatus.includes(resMain.status)) {
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "success",
        //   alertModalTitle: "Success",
        //   alertModalMessage: resMain.message ? resMain.message : "Success",
        // });
        this.closeEditListingDescModal();
        this.setState({ propertyImageListTotal: 0, dragOff: 0 });
        this.getMulitImagesData();
        this.openPublishListingModal(resMain.message);
      } else {
        let alertMessage = "";
        if (resMain.data.result.errorDetail !== null) {
          let errors = {};
          resMain.data.errorCode = "Validation Failed";
          resMain.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            resMain.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: resMain.data.errorCode
            ? resMain.data.errorCode
            : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : resMain.data.message,
        });
      }
    }
    this.setState({ showSpinner: false, isSubmit: false });
    this.setState({ dragOff: 1 });
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
  }

  // saveOrImage = async (e) => {
  //   e.preventDefault();

  //   this.setState({ showSpinner: true, isSubmit: true });
  //   if (this.props.listing_slug) {
  //     const FileWidgetObj = new FileWidget();
  //     const output = await FileWidgetObj.fileUploadFun(
  //       "lisitng_form",
  //       this.state.newRelatedFilesDesc
  //     );
  //     if (output.status !== false) {
  //       if (output.response[0].filesData) {
  //         if (output.response[0].filesData.length > 0) {
  //           let inputData = {
  //             availableFiles: output.response,
  //             deletedFiles: this.state.deletedFileList,
  //           };

  //           if (this.props.listing_slug !== undefined) {
  //             let res = await MultiImageService.submitMultiImage(inputData);
  //             if (global.successStatus.includes(res.status)) {
  //               this.closedeleteListingImgModal();
  //               this.setState({
  //                 showAlertModal: true,
  //                 alertModalType: "success",
  //                 alertModalTitle: "Success",
  //                 alertModalMessage: res.message ? res.message : "Success",
  //               });
  //               this.setState({ viewMode: 0 });
  //               this.setState({ newRelatedFilesDesc: [] });
  //               // setTimeout(
  //               //   () => document.getElementById("listingAmenities").click(),
  //               //   global.redirect_time
  //               // );
  //               this.setState({viewMode: 1});
  //               this.getMulitImagesData();
  //             } else {
  //               this.closedeleteListingImgModal();
  //               let alertMessage = "";
  //               if (res.data.result.errorDetail !== null) {
  //                 let errors = {};
  //                 res.data.errorCode = "Validation Failed";
  //                 res.data.result.errorDetail.forEach((item) => {
  //                   errors[item.errorField] = item.errorMessage[0];
  //                   // alertMessage += item.errorMessage[0]+'\n';
  //                   res.data.errorMessage = "";
  //                 });
  //                 this.setState({ errors: errors });
  //               }
  //               this.setState({
  //                 showSpinner: false,
  //                 showAlertModal: true,
  //                 alertModalType: "error",
  //                 alertModalTitle: res.data.errorCode
  //                   ? res.data.errorCode
  //                   : "Error!",
  //                 alertModalMessage:
  //                   alertMessage !== "" ? alertMessage : res.data.message,
  //               });
  //             }
  //           }
  //         }
  //       }
  //     } else {
  //       this.setState({
  //         showAlertModal: true,
  //         alertModalType: "error",
  //         alertModalTitle: "Error!",
  //         alertModalMessage: "The file should be png or jpg.",
  //       });
  //     }

  //     if (this.state.lisitngImageListTotal > 0) {
  //       let inputUpdateData = {
  //         image_data: this.state.lisitngImageList
  //           ? this.state.lisitngImageList
  //           : [],
  //       };

  //       let res = await MultiImageService.updateMultiImage(inputUpdateData);
  //       if (global.successStatus.includes(res.status)) {
  //         this.setState({
  //           showAlertModal: true,
  //           alertModalType: "success",
  //           alertModalTitle: "Success",
  //           alertModalMessage: res.message ? res.message : "Success",
  //         });
  //         this.setState({ viewMode: 0, lisitngImageListTotal: 0 });
  //         this.getMulitImagesData();
  //         // setTimeout(
  //         //   () => document.getElementById("listingAmenities").click(),
  //         //   global.redirect_time
  //         // );
  //       } else {
  //         let alertMessage = "";
  //         if (res.data.result.errorDetail !== null) {
  //           let errors = {};
  //           res.data.errorCode = "Validation Failed";
  //           res.data.result.errorDetail.forEach((item) => {
  //             errors[item.errorField] = item.errorMessage[0];
  //             // alertMessage += item.errorMessage[0]+'\n';
  //             res.data.errorMessage = "";
  //           });
  //           this.setState({ errors: errors });
  //         }
  //         this.setState({
  //           showSpinner: false,
  //           showAlertModal: true,
  //           alertModalType: "error",
  //           alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
  //           alertModalMessage:
  //             alertMessage !== "" ? alertMessage : res.data.message,
  //         });
  //       }
  //     }
  //   } else {
  //     this.setState({
  //       showSpinner: false,
  //       showAlertModal: true,
  //       alertModalType: "error",
  //       alertModalTitle: "Error!",
  //       alertModalMessage: "Please create your listing first.",
  //     });
  //     this.setState({ viewMode: 0, lisitngImageListTotal: 0 });
  //     this.getMulitImagesData();
  //     // setTimeout(
  //     //   () => document.getElementById("infoForm").click(),
  //     //   global.alert_close_time
  //     // );
  //   }
  //   this.setState({ showSpinner: false, isSubmit: false });
  //   setTimeout(
  //     () => this.setState({ showAlertModal: false }),
  //     global.alert_close_time
  //   );

  //   this.setState({ viewMode: 0 });
  // };

  // async getImagesData() {
  //   var resData = {};
  //   let res = await ListingService.getListing(this.props.listing_slug);
  //   if (global.successStatus.includes(res.status)) {
  //     resData.imageUrl = res.data.lisitng_main_image_url
  //       ? res.data.lisitng_main_image_url
  //       : "";
  //     this.setState(resData);
  //     this.props.imgFile(resData.imageUrl);
  //   }
  // }

  async deleteListingImg(slug) {
    this.setState({ showSpinner: true });
    let inputData = {
      path: "listing_units",
      refTableId: 10,
      slug: this.props.listing_slug,
      mediaType: 1,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closeEditListingDescModal();
      this.closedeleteListingImgModal();
      // this.setState({
      //   showAlertModal: true,
      //   alertModalType: "success",
      //   alertModalTitle: "Success",
      //   alertModalMessage: res.message ? res.message : "Success",
      // });
      this.setState({ multiImageListEdit: [], reorderdImg: [], dragOff: 0 });
      this.getMulitImagesData(true);
      this.openPublishListingModal();
    } else {
      this.closedeleteListingImgModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.setState({ dragOff: 1 });
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  confirmImportModal(i) {
    this.setState({ importModal: true });
    this.setState({ carouselZoom: i });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value = event.target.value;
    let lisitngImageList = [...this.state.multiImageList];
    var lisitngImageListTotal = 0;
    if (event.target.name === "description") {
      lisitngImageList[index][event.target.name] = value;
      lisitngImageListTotal = lisitngImageList.length;
      this.setState({
        lisitngImageList: lisitngImageList,
        lisitngImageListTotal: lisitngImageListTotal,
      });
    } else {
      input[event.target.name] = value;
      this.setState({ [event.target.name]: value, input: input });
    }
  };

  changeHandlerDesc = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    this.getMulitImagesData();
    this.getMulitImagesDataProperty();
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
    } else {
      this.setState({ viewMode: 1 });
    }
  }

  getChangedPos = (currentPos, newPos) => {
    let imgUpdatedArray = [];
    [...Array(this.state.imageLoop)].map((e, j) => {
      if (this.state.multiImageListEdit[j + 1] !== undefined) {
        imgUpdatedArray.push(this.state.multiImageListEdit[j + 1]);
      } else {
        imgUpdatedArray.push({});
      }
    });
    let newimgArray = this.array_move(imgUpdatedArray, currentPos, newPos);

    newimgArray.map((item, k) => {
      if (newimgArray[k].sort_order !== undefined) {
        newimgArray[k].sort_order = k + 1;
      }
    });
    setTimeout(() => {
      this.setState({ reorderdImg: newimgArray });
      this.reorderImageListing();
    }, 10);
  };

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  openPublishListingModal(message) {
    this.setState({ publishListingModal: true, publishMessage: message });
  }

  closePublishListingModal() {
    this.setState({ publishListingModal: false, publishMessage: "" });
  }

  async publishListing() {
    this.setState({ publishData: true });
    let inputData = {
      data: {
        listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
        property_slug: this.props.property_slug ? this.props.property_slug : "",
        unit_slug: this.props.unit_slug ? this.props.unit_slug : "",
        floor_plan_slug: this.props.floor_plan_slug
          ? this.props.floor_plan_slug
          : "",
      },
    };
    let res = await ListingService.publishListing(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
    this.closePublishListingModal();
    this.setState({ publishData: false });
  }

  render() {
    const SortableItem = SortableElement(({ value }) => (
      <div className="list-img">
        <div className="addImg uploaded">
          <div className="label-img">
            {value.is_main_img === 1 ? (
              <img src={mainImg} alt="" title="Main image" />
            ) : (
              ""
            )}
            {value.is_interior_img === 1 ? (
              <img src={interiorImg} alt="" title="Interior image" />
            ) : (
              ""
            )}
          </div>
          <Dropdown className="d-flex justify-content-end">
            <Dropdown.Toggle variant="secondary-" className="pd-0">
              <div className="navMob d-flex align-items-center">
                <img src={dotIcon} alt="" width="35px" title="Edit" />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="tx-13">
              <Dropdown.Item
                onClick={() => {
                  this.openEditListingDescModal(
                    value.slug,
                    value.file_name,
                    value.editModeCropImg,
                    value.description,
                    value.is_main_img === 1 ? true : false,
                    value.is_interior_img === 1 ? true : false,
                    value.display_file_name,
                    value
                  );
                }}
              >
                Edit
              </Dropdown.Item>
              {value.is_main_img === 0 ? (
                <Dropdown.Item
                  onClick={() =>
                    this.setCoverImg(value.slug, value.sort_order, "Main")
                  }
                >
                  Set as main image
                </Dropdown.Item>
              ) : (
                ""
              )}
              {value.is_interior_img === 0 ? (
                <Dropdown.Item
                  onClick={() => this.setCoverImg(value.slug, "Interior")}
                >
                  Set as interior image
                </Dropdown.Item>
              ) : (
                ""
              )}
              <Dropdown.Item
                onClick={() => this.opendeleteListingImgModal(value.slug)}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <figure>
            <img src={value.medium_img} alt="" title="Drag" />
          </figure>
        </div>
      </div>
    ));

    const SortableItemProperty = SortableElement(({ value }) => (
      <div className="list-img">
        <div className="addImg uploaded">
          {/* <div className="label-img">
            {value.is_main_img === 1 ? (
              <img src={mainImg} alt="" title="Main image" />
            ) : (
              ""
            )}
          </div> */}
          <Dropdown className="d-flex justify-content-end">
            <Dropdown.Toggle variant="secondary-" className="pd-0">
              <div className="navMob d-flex align-items-center">
                <img src={dotIcon} alt="" width="35px" title="Edit" />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="tx-13">
              {value.is_main_img === 0 || value.is_main_img === 1 ? (
                <Dropdown.Item
                  onClick={() =>
                    this.setCoverImg(value.slug, value.sort_order, "PropertyMain")
                  }
                >
                  Set as main image
                </Dropdown.Item>
              ) : (
                ""
              )}
            </Dropdown.Menu>
          </Dropdown>
          <figure>
            <img src={value.medium_img} alt="" title="Drag" />
          </figure>
        </div>
      </div>
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className="list-section d-flex flex-wrap">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${value.slug}`}
              sortIndex={index}
              index={index}
              value={value}
            />
          ))}
          <div
            className="addImg addNewimage- addlistingNewimage"
            onClick={() => this.openListingImgModal()}
          >
            <figure className="d-flex justify-content-center align-items-center flex-column h-100">
              <img src={addImg} alt="" className="plus" />
              <span>Add more</span>
            </figure>
          </div>
        </div>
      );
    });

    const SortableListProperty = SortableContainer(({ items }) => {
      return (
        <div className="list-section d-flex flex-wrap">
          {items.map((value, index) => (
            <SortableItemProperty
              key={`item-${value.slug}`}
              sortIndex={index}
              index={index}
              value={value}
            />
          ))}
         
        </div>
      );
    });

    const onSortEnd = (oldIndex, newIndex) => {
      var oldIndex1 = oldIndex.oldIndex;
      var newIndex1 = oldIndex.newIndex;

      let newimgArray = arrayMoveImmutable(
        this.state.multiImageList,
        oldIndex1,
        newIndex1
      );
      this.setState({ multiImageList: newimgArray, sortOrderImg: newimgArray });
      newimgArray.map((item, k) => {
        if (newimgArray[k].sort_order !== undefined) {
          newimgArray[k].sort_order = k + 1;
        }
      });
      setTimeout(() => {
        this.reorderImageListing();
      }, 100);
    };
    // console.clear();
    return (
      <>
        <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <div className="col-md-12 d-flex justify-content-end pd-l-0 pd-r-0 mg-b-15 swiperBtns">
            {this.state.viewMode === 0 &&
            global.userPermissions.checkPermission("single-unit-update") ? (
              <button
                className="btn-success-outline-small mr-3"
                onClick={this.changeView}
              >
                <img src={editIcon} alt="" />
                Edit
              </button>
            ) : (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn-success-outline-small mr-3"
                  onClick={
                    this.state.viewMode === 2
                      ? this.changeView2
                      : this.changeView
                  }
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div className="col-md-12 pd-l-30 pd-r-30 mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          {this.state.viewMode === 0 ? (
            <React.Fragment>
              <div className="row row-xs align-items-center justify-content-center pd-t-10 pd-b-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-20 mg-l-2 addmultiImages- listingImagsection">
                {/* {this.state.multiImageListTotal > 0
                ? this.state.multiImageList.map((opt, i) => {
                    return (
                      <span key={i} className="welcomeClose float-end">
                        <img src={close} alt="" />
                      </span>
                    );
                  })
                : ""} */}
                {this.state.multiImageListTotal > 0 ? (
                  <>
                    <Gallery
                      images={
                        this.state.newMultiImageList
                          ? this.state.newMultiImageList
                          : ""
                      }
                      enableImageSelection={false}
                      showImageCount={false}
                    />
                    {/* <ul className="d-flex flex-wrap">
                {this.state.multiImageListTotal > 0
                  ? this.state.multiImageList.map((option, i) => (
                    <li key={i}>
                      <div className="avatar-squre-listing mg-b-0">
                        <figure>
                          <img
                            src={deleteImg}
                            className="del-img"
                            alt=""
                            onClick={() =>
                              this.opendeleteListingImgModal(option.slug)
                            }
                          />
                          <Link
                            to={"#"}
                            className="overlay"
                            onClick={() => this.confirmImportModal(i)}
                          >
                            <span>
                              <img
                                src={deleteImg}
                                alt=""
                                onClick={() => {
                                  this.opendeleteListingImgModal(option.slug);
                                }}
                              />
                            </Link>
                          </figure>
                        </div>
                      </li>
                    ))
                  : "There are no images added in the system. You may add them by clicking Edit button."}
              </ul> */}
                  </>
                ) : (
                  "There are no images added in the system. You may add them by clicking Edit button."
                )}
              </div>
              {/* room image images modal */}
              <Modal
                show={this.state.roomListImageModal}
                onHide={() => this.closeListingListImageModal()}
                centered
                className="propsinglemodal"
              >
                <Modal.Body>
                  <span
                    className="welcomeClose"
                    onClick={() => this.closeListingListImageModal()}
                  >
                    <img src={close} alt="" />
                  </span>
                  <img
                    src={
                      this.state.imageUrl ? this.state.imageUrl : lisitngImage
                    }
                    alt=""
                  />
                </Modal.Body>
              </Modal>
              {/* properties listing images modal */}
              <Modal
                show={this.state.importModal}
                onHide={() => this.closeConfirmImportModal()}
                centered
                className="propertylistmodal"
              >
                <Modal.Body>
                  <span
                    className="welcomeClose"
                    onClick={() => this.closeConfirmImportModal()}
                  >
                    <img src={close} alt="" />
                  </span>
                  <Carousel
                    activeIndex={this.state.carouselZoom}
                    onSelect={this.confirmImportModal}
                    prevIcon={
                      this.state.multiImageListTotal === 1 ? (
                        false
                      ) : (
                        <span
                          aria-hidden="true"
                          className="carousel-control-prev-icon"
                        />
                      )
                    }
                    nextIcon={
                      this.state.multiImageListTotal === 1 ? (
                        false
                      ) : (
                        <span
                          aria-hidden="true"
                          className="carousel-control-next-icon"
                        />
                      )
                    }
                  >
                    {this.state.multiImageListTotal > 0
                      ? this.state.multiImageList.map((option, i) => (
                          <Carousel.Item key={i}>
                            <img
                              src={option.file_name}
                              className="d-block w-100 op-3"
                              alt="..."
                            />
                            {option.description !== undefined &&
                            option.description !== null &&
                            option.description !== "" &&
                            option.description !== "undefined" ? (
                              <Carousel.Caption>
                                <p>
                                  {option.description ? option.description : ""}
                                </p>
                              </Carousel.Caption>
                            ) : (
                              ""
                            )}
                          </Carousel.Item>
                        ))
                      : ""}
                  </Carousel>
                </Modal.Body>
              </Modal>
            </React.Fragment>
          ) : (
            ""
          )}
          {this.state.viewMode === 1 ? (
            <>
              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 EditListingimg">
                <div className="m-0">
                  {/* <form id="lisitng_form">
                  <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                    <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0">
                      <FileWidget
                        fieldName="file_name"
                        fieldTitle="Select Other Images"
                        fieldLabel="Images"
                        checkValidation="true"
                        allowedType="image/jpg,image/png"
                        maxFileSize={2}
                        maxFileSizeType="mb"
                        maxFileAllowed={10}
                        uploadOn="s3"
                        slug={this.props.listing_slug}
                        refTableId={10}
                        mediaType={1}
                        sortOrder={this.state.multiImageListTotal}
                        statusId=""
                        drag="false"
                        dragText="Add Image (Multiple)"
                        inputClassName="form-control"
                        labelClassName="form-label"
                        uploadType="multiple"
                        formID="lisitng_form"
                        resizeOptions="width:200,height:200,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:200,height:200,maintainAspectRatio:true,resizeDestinationFolder:thumb"
                        destinationFolder="listing_units"
                        uploadedFiles={this.state.file_name}
                        deletedFiles={this.state.deletedFileList}
                        acceptOnly="image/jpg,image/png"
                        displayPreviewStyle="vertical"
                        newRelatedFilesDescChange={
                          this.newRelatedFilesDescChange
                        }
                      />
                    </div>
                  </div>
                </form> */}
                  {/* new Design add images */}
                  <div className="row row-xs align-items-center pd-t-0 addmultiImages- listingImagviewsection pd-l-25 pd-r-20 ">
                    <SortableList
                      items={this.state.multiImageList}
                      onSortEnd={onSortEnd}
                      axis={"xy"}
                      distance={1}
                      // useDragHandle="true"
                      //pressDelay={200}
                      pressThreshold={5}
                    />
                    {/*
                            <div className="addImg addNewimage" onClick={() => this.openListingImgModal()}>
                              <figure className="d-flex justify-content-center align-items-center flex-column h-100">
                                <img
                                  src={addImg}
                                  alt=""
                                  className="plus"
                                />
                                <span>Add more</span>
                              </figure>
                            </div> */}
                    {/* <ul className="d-flex flex-wrap">
                    {this.state.dragOff === 1 && this.state.multiImageListTotal > 0 ? (
                      <>
                        <Draggable onPosChange={this.getChangedPos}>
                          {[...Array(this.state.imageLoop)].map((e, j) => {
                            return (
                              <React.Fragment key={j}>
                                {this.state.multiImageListEdit[j + 1] !==
                                  undefined ? (
                                  <li>
                                    <div className="addImg uploaded">
                                      <figure>
                                        <img
                                          src={
                                            this.state.multiImageListEdit[j + 1]
                                              .medium_img
                                              ? this.state.multiImageListEdit[
                                                j + 1
                                              ].medium_img
                                              : ""
                                          }
                                          alt=""
                                          onClick={() => {
                                            this.openEditListingDescModal(
                                              this.state.multiImageListEdit[
                                                j + 1
                                              ].slug,
                                              this.state.multiImageListEdit[
                                                j + 1
                                              ].file_name,
                                              this.state.multiImageListEdit[
                                                j + 1
                                              ].description,
                                              this.state.multiImageListEdit[
                                                j + 1
                                              ].display_file_name,
                                              this.state.multiImageListEdit[
                                              j + 1
                                              ]
                                            );
                                          }}
                                        />
                                        <img
                                          src={pluswIcon}
                                          alt=""
                                          className="plus"
                                        />
                                      </figure>
                                      {j === 0 ? <h5>Main Image</h5> : ""}
                                    </div>
                                  </li>
                                ) : (
                                  <li key={j}>
                                    <div className="addImg">
                                      <figure
                                        onClick={() =>
                                          this.openListingImgModal(j + 1)
                                        }
                                      >
                                        <img
                                          src={pluswIcon}
                                          alt=""
                                          className="plus"
                                        />
                                      </figure>
                                      {j === 0 ? <h5>Main Image</h5> : ""}
                                    </div>
                                  </li>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </Draggable>
                      </>
                    ) : (
                      <>
                        {" "}
                        {[...Array(this.state.imageLoop)].map((e, j) => {
                          return (
                            <React.Fragment key={j}>
                              {this.state.multiImageListEdit[j + 1] !==
                                undefined ? (
                                <li>
                                  <div className="addImg uploaded">
                                    <figure>
                                      <img
                                        src={
                                          this.state.multiImageListEdit[j + 1]
                                            .file_name
                                            ? this.state.multiImageListEdit[
                                              j + 1
                                            ].file_name
                                            : ""
                                        }
                                        alt=""
                                        onClick={() => {
                                          this.openEditListingDescModal(
                                            this.state.multiImageListEdit[j + 1]
                                              .slug,
                                            this.state.multiImageListEdit[j + 1]
                                              .file_name,
                                            this.state.multiImageListEdit[j + 1]
                                              .description,
                                            this.state.multiImageListEdit[j + 1]
                                              .display_file_name,
                                            this.state.multiImageListEdit[j + 1]
                                          );
                                        }}
                                      />
                                      <img
                                        src={pluswIcon}
                                        alt=""
                                        className="plus"
                                      />
                                    </figure>
                                    {j === 0 ? <h5>Main Image</h5> : ""}
                                  </div>
                                </li>
                              ) : (
                                <li key={j}>
                                  <div className="addImg">
                                    <figure
                                      onClick={() =>
                                        this.openListingImgModal(j + 1)
                                      }
                                    >
                                      <img
                                        src={pluswIcon}
                                        alt=""
                                        className="plus"
                                      />
                                    </figure>
                                    {j === 0 ? <h5>Main Image</h5> : ""}
                                  </div>
                                </li>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </>
                    )}

                  </ul> */}
                  </div>

                  {/* end new Design add images */}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {this.state.multiImageListTotalPro > 0 ? (
            <>
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 mg-t-15 background-head">
                  {" "}
                  Property Images
                </h3>
              </div>
              {this.state.viewMode === 0 ? (
              <React.Fragment>
                <div className="row row-xs align-items-center justify-content-center pd-t-30 pd-b-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-0 mg-l-0 mg-l-15 addmultiImages">
                  {this.state.multiImageListTotalPro > 0 ? (
                    <React.Fragment>
                      <GalleryProperty
                        backdropClosesModal={false}
                        preloadNextImage={true}
                        images={
                          this.state.newMultiImageListPro
                            ? this.state.newMultiImageListPro
                            : ""
                        }
                        enableImageSelection={false}
                        showImageCount={false}
                      />
                    </React.Fragment>
                  ) : (
                    <NoData msg="images" action=" Edit " />
                  )}
                </div>
              </React.Fragment>
               ) : (
                ""
              )}
               {this.state.viewMode === 1 ? (
                <>
                  {/* new Design add images */}
                  <div className="row row-xs align-items-center pd-t-20 pd-b-30  pd-xs-l-15 pd-xs-r-0 pd-lg-l-8 pd-lg-r-0 addmultiImages">
                    <SortableListProperty
                      items={this.state.newMultiImageListPro}
                      onSortEnd={onSortEnd}
                      axis={"xy"}
                      distance={1}
                      // useDragHandle="true"
                      //pressDelay={200}
                      pressThreshold={5}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : null}
          <Modal show={this.state.deleteListingImgModal} centered>
            <Modal.Body className="width_402 p-4 border_radius_14">
              <div className="text-center">
                <img src={modaldeleteicon} alt="" />
                <h3>Are you sure?</h3>
                <p>
                  Do you really want to delete this image? This process cannot
                  be undone.
                </p>
                <div className="d-flex justify-content-center mt-3 row">
                  <button
                    type="button"
                    className="m-0 btn btn-outline-light btn-block"
                    onClick={() => this.closedeleteListingImgModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="m-0 btn btn btn-danger btn-block"
                    onClick={() => this.deleteListingImg(this.state.slug)}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Delete"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* Add image popup */}
          <Modal show={this.state.addImgModal} centered>
            <Modal.Body className="width_402 border_radius_14">
              <form id="lisitng_form" className="text-left">
                <div>
                  <figure className="mg-auto m-auto text-center">
                    {" "}
                    <FileWidget
                      fieldName="modal_name"
                      fieldTitle="Drop image here"
                      fieldLabel="Drop image here"
                      checkValidation="true"
                      allowedType="image/jpg,image/jpeg,image/png"
                      maxFileSize={10}
                      maxFileSizeType="mb"
                      maxFileAllowed={50}
                      uploadOn="s3"
                      slug={this.props.listing_slug}
                      refTableId={10}
                      displayFileName="listing_units"
                      documentTypeSlug={this.props.listing_slug}
                      description={this.state.description}
                      mediaType={1}
                      sortOrder={this.state.multiImageListTotal}
                      statusId={1}
                      h1={65}
                      h2={300}
                      helpText="to download sample file."
                      sampleFileUrl="/sample.jpg"
                      drag="true"
                      dragText="Drop image here"
                      inputClassName="form-control"
                      labelClassName="form-label"
                      formID="lisitng_form"
                      uploadType="multiple"
                      resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
                      destinationFolder="listing_units"
                      propertySlug={this.state.property_slug}
                      uploadedFiles={this.state.profile_image2}
                      deletedFiles={this.state.deletedFileList}
                      acceptOnly="image/jpg,image/jpeg,image/png"
                      displayPreviewStyle="vertical"
                      deleteImgModal={this.state.addImgModal}
                      newRelatedFilesDescChange={this.newRelatedFilesDescChange}
                      isModal="yes"
                      closeListingImgModal={this.closeListingImgModal}
                    />
                  </figure>
                  <label className="text-left">Description</label>
                  <Form.Control
                    onChange={this.changeHandlerDesc}
                    name="description"
                    value={this.state.description}
                    className="form-control max_width_100 crop-text"
                    type="text"
                    rows="4"
                    as="textarea"
                    autoComplete="off"
                    resize="resize"
                    maxLength="255"
                  />
                  <div className="clearfix"> </div>
                  <div className="d-flex justify-content-between mt-3 row">
                    <button
                      type="button"
                      className="mx-auto m-0 btn btn-outline-light btn-block"
                      onClick={() => this.closeListingImgModal()}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="mx-auto m-0 btn btn btn-success btn-block"
                      onClick={(e) => this.submitModalImage(e)}
                      disabled={this.state.isSubmit ? true : false}
                    >
                      {this.state.isSubmit ? global.loader : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>
          {/* New cropper modalbox */}
          <Modal
            show={this.state.openEditListingDescModal}
            onHide={this.closeEditListingDescModal}
            centered
            className="cropModal"
          >
            <Modal.Header>
              <h2>Edit Photo</h2>
              <img
                alt=""
                src={close}
                onClick={() => this.closeEditListingDescModal()}
                className="mb-0"
              />
            </Modal.Header>
            <Modal.Body className="width_402 p-2  pt-4  pb-4 border_radius_14 modal-body">
              <div className="col-md-7">
                {this.state.isImageEdit ? (
                  <figure className="mg-auto m-auto text-center">
                    <Cropper
                      minZoom={0.1}
                      image={this.state.editModeCropImg}
                      crop={this.state.crop}
                      zoom={this.state.zoom}
                      aspect={this.state.aspect}
                      restrictPosition={false}
                      onCropChange={this.onCropChange}
                      onCropComplete={this.onCropComplete}
                      onZoomChange={this.onZoomChange}
                      objectFit="horizontal-cover" //"vertical-cover" "contain" "auto-cover"
                    />
                  </figure>
                ) : (
                  <figure className="mg-auto m-auto text-center">
                    <img
                      src={
                        this.state.croppedImage
                          ? this.state.croppedImage
                          : this.state.img_src
                      }
                      alt=""
                      className="w-100"
                    />
                  </figure>
                )}
              </div>
              <div className="col-md-5 pr-3">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="description"
                >
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="description"
                        onClick={this.EditImgSet}
                      >
                        Description
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Edit" onClick={this.cropperSet}>
                        Edit
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="description">
                      <label className="form-label text-left pd-b-5">
                        Description
                      </label>
                      <Form.Control
                        onChange={this.changeHandlerDesc}
                        name="description"
                        value={this.state.description}
                        className="form-control max_width_100 crop-text"
                        type="text"
                        rows="2"
                        as="textarea"
                        autoComplete="off"
                        resize="resize"
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Edit" className="d-flex">
                      <div className="controls d-flex w-100 flex-column">
                        <p className="az-profile-name-text pl-0">Zoom</p>
                        <Slider
                          value={this.state.zoom}
                          min={1}
                          max={10}
                          step={1}
                          aria-labelledby="Zoom"
                          onChange={(e, zoom) => this.onZoomChange(zoom)}
                          // classes={{ container: "slider" }}
                        />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-between w-100 m-0 pl-2 pr-2  ">
                <img
                  src={deleteImg}
                  alt=""
                  onClick={() =>
                    this.opendeleteListingImgModal(this.state.slug)
                  }
                  className="removeFile btn-multilist-outline-danger w-auto mt-2 mb-0 hand-cursor"
                />
                {this.state.isImageEdit ? (
                  <>
                    <button
                      type="button"
                      className="mx-auto m-0 btn btn-outline-light btn-block"
                      onClick={this.cancelCrop}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="m-0 btn col-md-3 btn btn-success btn-block"
                      onClick={this.cropImage}
                      disabled={this.state.isSubmit ? true : false}
                    >
                      {this.state.isSubmit ? global.loader : "Edit Image"}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="mx-auto m-0 btn btn-outline-light btn-block"
                      onClick={() => this.closeEditListingDescModal()}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="m-0 btn btn btn-success btn-block"
                      onClick={() => this.updateImgDesc(this.state.slug)}
                      disabled={this.state.isSubmit ? true : false}
                    >
                      {this.state.isSubmit ? global.loader : "Save"}
                    </button>
                  </>
                )}
              </div>
            </Modal.Footer>
          </Modal>
        </div>

        <Modal
          show={this.state.publishListingModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closedeleteListingModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closePublishListingModal()}
              >
                {global.closee}
              </span>
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              {/* <h3>Publish to OTA?</h3> */}
              <h3></h3>
              <p>
                {this.state.publishMessage
                  ? this.state.publishMessage
                  : "Listing is successfully updated! Your changes are not published to OTA yet, do you want to publish now ? You can still publish from Sync tab later."}
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block button-width"
                  onClick={() => this.closePublishListingModal()}
                >
                  Sync Later
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block button-width"
                  onClick={this.publishListing}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Sync Now"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ListingImages;
