////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Units.jsx                                    //
//  Application: Units                                    //
//  Option: List all Units                                //
//  Developer: Ashish Kumar					                      //
//  Date: 2022-02-03                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import UnitsService from "../../services/UnitsService";
import { Container, Row, Col, Modal, InputGroup, Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import NoData from "../common/NoData";
import LeasingService from "../../services/LeasingService";
import Moment from "moment";
import DatePicker from "react-datepicker";
import LeasingSidebar from "../common/LeasingSidebar";
import FloorPlanService from "../../services/FloorPlanService";
import Select from "react-select";
import TowerService from "../../services/TowerService";
import FloorService from "../../services/FloorService";
import MasterDataService from "../../services/MasterDataService";
import exportIcon from "./../../assets/images/export.svg";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import ErrorLogo from "./../../assets/images/errorImage.svg";
import SuccessLogo from "./../../assets/images/successImage.svg";
import { read, utils } from "xlsx";
import * as Vars from "./../../Vars";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";
import filterIcon from "./../../assets/images/filter-gray.svg";
import queryString from "query-string";
import DataHubAutomationService from "../../services/DataHubAutomationService";
let queryParams = queryString.parse(window.location.search);
const DatabaseCols = Vars.default.DatabaseCols.filter((item) => {
  return item.module_name == "unit_availability";
});
const DatabaseColMapping = Vars.default.DatabaseColMapping.filter((item) => {
  return item.module_name == "unit_availability";
});

const unitAvailabilityRequiredFields = Vars.default.unitAvailabilityRequiredFields;
export class UnitsAvailability extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "3",
      file_url: "",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      errors: {},
      import_type: 1,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      file_name: "",
      alertModalMessage: "",
      is_available: "",
      available_from: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      unitsFilter: [],
      filter_value: "",
      module_search: "",
      isSearch: global.isSearch,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "available_from",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "available_from",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "unit_number",
          text: "Unit",
          className: "unit_number",
          sortable: true,
          cell: (record) => {
            return record.unit_name ? record.unit_name : record.unit_number;
          },
        },
        {
          key: "floor_plan_id",
          text: "Floor Plan",
          className: "floor_plan_id",
          sortable: true,
          cell: (record) => {
            return record.floor_plan
              ? record.floor_plan.property_floor_plan_name +
                  (record.floor_plan.square_foot
                    ? " (" +
                      record.floor_plan.square_foot.replace(/\.00$/, "") +
                      " Sq. ft)"
                    : "")
              : "";
          },
        },
        {
          key: "floor_id",
          text: "Floor",
          className: "floor_id",
          sortable: true,
          cell: (record) => {
            return record.floor ? record.floor.floor_name : "";
          },
        },
        {
          key: "tower_id",
          text: "Tower",
          className: "tower_id",
          sortable: true,
          cell: (record) => {
            return record.tower ? record.tower.tower_name : "";
          },
        },
        {
          key: "bedroom",
          text: "Bedroom(s)",
          className: "bedroom",
          sortable: false,
          cell: (record) => {
            return record.floor_plan ? record.floor_plan.bedroom : "";
          },
        },
        {
          key: "bath",
          text: "Bathroom(s)",
          className: "bath",
          sortable: false,
          cell: (record) => {
            return record.floor_plan ? record.floor_plan.bath : "";
          },
        },
        {
          key: "furnished",
          text: "Furnished Type",
          className: "furnished",
          sortable: true,
          cell: (record) => {
            return record.furnishedData ? record.furnishedData.name : "";
          },
        },
        {
          key: "available_from",
          text: "Available From",
          className: "available_from",
          sortable: true,
          cell: (record) => {
            return record.available_from
              ? Moment(record.available_from).format(global.dateFormat)
              : "";
          },
        },
        {
          key: "base_price",
          text: "Rent",
          className: "base_price",
          sortable: true,
          cell: (record) => {
            return record.base_price
              ? "$" +
                  global.onKeyPressEvent.numberWithCommasForDecimal(
                    parseFloat(record.base_price)
                      .toFixed(2)
                      .replace(/\.00$/, "")
                      .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
                  )
              : "";
          },
        },
      ],
      floorPlanList: [],
      newFloorPlanList: [],
      selectedFloorPlanList: [],
      floorPlanListTotal: 0,
      towerList: [],
      newTowerList: [],
      selectedTowerList: [],
      towerListTotal: 0,
      floorList: [],
      newFloorList: [],
      selectedFloorList: [],
      floorListTotal: 0,
      furnishedList: [],
      newFurnishedList: [],
      selectedFurnishedList: [],
      furnishedListTotal: 0,
      file_rows: [],
      file_columns: [],
      available_file_columns: [],
      database_cols_mapping: DatabaseColMapping,
      ConfirmationModal: false,
      is_date_invalid: false,
      requiredColumnsErrMsg : '',
    };

    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.confirmImport = this.confirmImport.bind(this);
    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  async openConfirmationModal() {
    if (this.state.file_url) {

      const missingColumns = this.state.database_cols_mapping.filter((item) => {
        console.log(item);
        return (
          unitAvailabilityRequiredFields.includes(item.database_column) &&
          item.file_column === ""
        );
      });

      if (missingColumns.length > 0) {
        this.setState({
          requiredColumnsErrMsg: "Please select all required column.",
        });
        return; // return missingColumns if you want to return the array
      } else {
        this.setState({ requiredColumnsErrMsg: "" });
      }
      this.setState({
        showSpinner: true,
        isSubmit: true,
        ConfirmationModal: false,
      });
      const fileData = new FormData();
      fileData.append(
        "mapped_columns",
        JSON.stringify(this.state.database_cols_mapping)
      );
      fileData.append("import_file", this.state.file_url);
      fileData.append("file_name", this.state.file_name);
      fileData.append("property_slug", this.state.property_slug);
      fileData.append("import_type", this.state.import_type);
      let res = await DataHubAutomationService.UnitAvaibilityimportPreview(
        fileData
      );
      if (global.successStatus.includes(res.status)) {
        if (res.data) {
          const totalRecord = res.data.total_record;
          const successRecord = res.data.success_record;
          const skipRecord = res.data.skip_record;
          const recordError = res.data.record_error_array;
          const FailedRecord = res.data.failed_record;
          const is_date_invalid = res.data.is_date_invalid;
          if (is_date_invalid > 0) {
            this.setState({
              is_date_invalid: true,
            });
          } else {
            this.setState({
              is_date_invalid: false,
            });
          }
          this.setState({
            totalRecord: totalRecord,
          });
          this.setState({
            successRecord: successRecord,
          });
          this.setState({
            skipRecord: skipRecord,
          });
          this.setState({
            recordError: recordError,
          });
          this.setState({
            FailedRecord: FailedRecord,
          });
        }
      } else {
        if (res.data) {
          const totalRecord = res.data.total_record;
          const successRecord = res.data.success_record;
          const skipRecord = res.data.skip_record;
          const recordError = res.data.record_error_array;
          const FailedRecord = res.data.failed_record;
          const is_date_invalid = res.data.is_date_invalid;
          if (is_date_invalid > 0) {
            this.setState({
              is_date_invalid: true,
            });
          } else {
            this.setState({
              is_date_invalid: false,
            });
          }
          if (successRecord !== undefined) {
            this.setState({
              totalRecord: totalRecord,
            });
            this.setState({
              successRecord: successRecord,
            });
            this.setState({
              skipRecord: skipRecord,
            });
            this.setState({
              recordError: recordError,
            });
            this.setState({
              FailedRecord: FailedRecord,
            });
          }
        }
      }
      setTimeout(() => {
        this.setState({ showSpinner: false, isSubmit: false, show: false });
        this.setState({ ConfirmationModal: true });
      }, 2000);
    } else {
      this.setState({ ConfirmationModal: false });
      this.validator.showMessages();
    }
  }

  closeConfirmationModal() {
    this.setState({ ConfirmationModal: false });
  }

  componentDidMount() {
    if (queryParams.isGlobal === "1") {
      this.getData(
        "is_search=1&" +
          queryParams.global_col_name +
          "=" +
          queryParams.global_search
      );
    } else {
      this.getData();
    }
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.getData();
    }, 200);
    this.setState({
      unit_name: "",
      module_search: [],
      is_search: 0,
    });
  }

  closeimportResponseModel() {
    this.setState({ importResponseModel: false });
    this.validator.hideMessages();
    window.location.reload();
  }

  async getTowerData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var towerList = [];
    var towerListTotal = 0;

    let res = await TowerService.getTower(
      "is_dropdown=1&property_slug=" + (this.state.property_slug ? this.state.property_slug : ""), 
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      towerList = res.data ? res.data : [];
      towerListTotal = towerList ? towerList.length : 0;
    }
    const newTowerList =
      towerListTotal > 0
        ? towerList.map(({ slug, tower_name }) => ({
            value: slug,
            label: tower_name,
          }))
        : [];
    this.setState({
      towerList: towerList,
      towerListTotal: towerListTotal,
      newTowerList: newTowerList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getFloorData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var floorList = [];
    var floorListTotal = 0;

    let res = await FloorService.getFloor(
      "is_dropdown=1&property_slug=" + (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      floorList = res.data ? res.data : [];
      floorListTotal = floorList ? floorList.length : 0;
    }
    const newFloorList =
      floorListTotal > 0
        ? floorList.map(({ slug, floor_name }) => ({
            value: slug,
            label: floor_name,
          }))
        : [];
    this.setState({
      floorList: floorList,
      floorListTotal: floorListTotal,
      newFloorList: newFloorList,
    });
    this.setState({ showSpinner: false, loading: false });
  }
  handleChange = (file) => {
    this.setState({ database_cols_mapping: DatabaseColMapping });
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, { raw: true });
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            header: 0,
            defval: "",
          });
          if (rows.length > 0) {
            let columns = Object.keys(rows[0]);
            let available_columns = columns.map((col) => {
              let val = col
                .replace("-", "_")
                .replace(/[^\w ]/g, "")
                .replace(/^\s+|\s+$/gm, "")
                .split(" ")
                .join("_")
                .replace("-", "_")
                .toLowerCase();
              return {
                label: col,
                value: val,
              };
            });
            this.setState({
              file_rows: rows,
              file_columns: columns,
              available_file_columns: available_columns,
            });
          } else {
            this.setState({ file_name: "" });
            this.setState({ file_url: "" });
            return false;
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
    this.setState({ file_name: file.name });
    this.setState({ file_url: file });
  };

  async confirmImport() {
    if (this.state.file_url) {
      this.setState({
        showSpinner: true,
        isSubmit: true,
        ConfirmationModal: false,
      });
      const fileData = new FormData();
      fileData.append(
        "mapped_columns",
        JSON.stringify(this.state.database_cols_mapping)
      );
      fileData.append("import_file", this.state.file_url);
      fileData.append("file_name", this.state.file_name);
      fileData.append("property_slug", this.state.property_slug);
      fileData.append("import_type", this.state.import_type);
      let res = await LeasingService.UnitAvaibilityimportData(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({ importModal: false });
        this.setState({ importResponseModel: true });
        this.setState({ successResponse: true });
        this.setState({ successMessage: res.message });
        this.setState({
          file_rows: [],
          file_columns: [],
          available_file_columns: [],
          database_cols_mapping: DatabaseColMapping,
          requiredColumnsErrMsg : '',
        });
        if (res.data) {
          const totalRecord = res.data.total_record;
          const successRecord = res.data.success_record;
          const skipRecord = res.data.skip_record;
          const recordError = res.data.record_error_array;
          const FailedRecord = res.data.failed_record;
          this.setState({
            totalRecord: totalRecord,
          });
          this.setState({
            successRecord: successRecord,
          });
          this.setState({
            skipRecord: skipRecord,
          });
          this.setState({
            recordError: recordError,
          });
          this.setState({
            FailedRecord: FailedRecord,
          });
        }
        // this.getData();
        //window.location.reload();
      } else {
        this.setState({ database_cols_mapping: DatabaseColMapping });
        this.setState({ importModal: false });
        this.setState({ importResponseModel: true });
        this.setState({ errorResponse: true });
        this.setState({ errorMessage: res.data.message });
        if (res.data) {
          const totalRecord = res.data.total_record;
          const successRecord = res.data.success_record;
          const skipRecord = res.data.skip_record;
          const recordError = res.data.record_error_array;
          const FailedRecord = res.data.failed_record;
          if (successRecord !== undefined) {
            this.setState({
              totalRecord: totalRecord,
            });
            this.setState({
              successRecord: successRecord,
            });
            this.setState({
              skipRecord: skipRecord,
            });
            this.setState({
              recordError: recordError,
            });
            this.setState({
              FailedRecord: FailedRecord,
            });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false, show: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ ConfirmationModal: false });
      this.validator.showMessages();
    }
  }
  async getFloorPlanData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var floorPlanList = [];
    var floorPlanListTotal = 0;

    let res = await FloorPlanService.getFloorPlan(
      "is_dropdown=1&property_slug=" + (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      floorPlanList = res.data ? res.data : [];
      floorPlanListTotal = floorPlanList ? floorPlanList.length : 0;
    }
    const newFloorPlanList =
      floorPlanListTotal > 0
        ? floorPlanList.map(
            ({ slug, property_floor_plan_name, square_foot }) => ({
              value: slug,
              label:
                property_floor_plan_name +
                (square_foot
                  ? " (" + square_foot.replace(/\.00$/, "") + " Sq. ft)"
                  : ""),
            })
          )
        : [];
    this.setState({
      floorPlanList: floorPlanList,
      floorPlanListTotal: floorPlanListTotal,
      newFloorPlanList: newFloorPlanList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  handleChangeFurnishedList(value) {
    let furnished = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        furnished.push(item.value);
      });
      
    }
    const furnished_value = value !== null ? value.value : [];
    const furnished_label = value !== null ? value.label : [];
    this.setState({
      selectedFurnishedList: value,
      furnished: furnished_value,
      furnished_label: furnished_label,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeTowerList(value) {
    let tower_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        tower_slug.push(item.value);
      });
    }
    const tower_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedTowerList: value,
      tower_slug: tower_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeFloorList(value) {
    let floor_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        floor_slug.push(item.value);
      });
    }
    const floor_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedFloorList: value,
      floor_slug: floor_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeFloorPlanList(value) {
    let floor_plan_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        floor_plan_slug.push(item.value);
      });
    }
    const floor_plan_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedFloorPlanList: value,
      floor_plan_slug: floor_plan_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  async getFurnishedData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var furnishedList = [];
    var furnishedListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=furnished",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      furnishedList = res.data ? res.data : [];
      furnishedListTotal = furnishedList ? furnishedList.length : 0;
    }
    const newFurnishedList =
      furnishedListTotal > 0
        ? furnishedList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : [];
    this.setState({
      furnishedList: furnishedList,
      furnishedListTotal: furnishedListTotal,
      newFurnishedList: newFurnishedList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    let res = await UnitsService.getUnitsAvailabilityList(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        "&" +
        queryString
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  filterToggle = () => {
    this.getFloorPlanData();
    this.getFloorData();
    this.getTowerData();
    this.getFurnishedData();
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customFilter() {
    let floor_plan_slug = this.state.floor_plan_slug
      ? this.state.floor_plan_slug
      : "";
    let floor_slug = this.state.floor_slug ? this.state.floor_slug : "";
    let tower_slug = this.state.tower_slug ? this.state.tower_slug : "";
    let furnished = this.state.furnished ? this.state.furnished : "";
    let avaliableFrom = this.state.available_from
      ? Moment(this.state.available_from).format("YYYY-MM-DD")
      : "";
    let filterValue = this.state.filter_value ? this.state.filter_value : "";
    let queryString =
      "filter_value=" +
      filterValue +
      "&floor_plan_slug=" +
      floor_plan_slug +
      "&floor_slug=" +
      floor_slug +
      "&tower_slug=" +
      tower_slug +
      "&available_from=" +
      avaliableFrom +
      "&furnished=" +
      furnished +
      "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }
  confirmImportModal() {
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({
      file_rows: [],
      file_columns: [],
      available_file_columns: [],
      database_cols_mapping: DatabaseColMapping,
      requiredColumnsErrMsg: "",
      file_url: "",
      file_name: "",
      property_slug: "",
      import_type: "",
      importModal: false,
    });
    this.validator.hideMessages();
    //need to reload page if file field is mapped 
    for (let i = 0; i < DatabaseColMapping.length; i++) {
      if (DatabaseColMapping[i].file_column !== '') {
        window.location.reload();
      }
    }
  }

  resetDate() {
    this.setState({
      is_available: [],
      available_from: "",
      filter_value: "",
      selectedFloorPlanList: [],
      selectedFloorList: [],
      selectedTowerList: [],
      selectedFurnishedList: [],
      floor_plan_slug: "",
      floor_slug: "",
      tower_slug: "",
      furnished: "",
      file_rows: [],
      file_columns: [],
      available_file_columns: [],
      database_cols_mapping: DatabaseColMapping,
      requiredColumnsErrMsg : '',
    });
    this.getData();
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Units Availability";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  handleChangeImportColsList(value, index) {
    let elements = [...this.state.database_cols_mapping];
    elements[index].file_column = value.value;
    this.setState({ elements });
  }

  searchToggle = () => {
    if (this.state.isSearch === true) {
      this.setState({ isSearch: false });
    } else {
      this.getFloorData();
      this.getFloorPlanData();
      this.getTowerData();
      this.getFurnishedData();
      this.setState({ isSearch: true });
    }
  };
  filterData() {
    let availableFrom = this.state.available_from
      ? Moment(this.state.available_from).format("YYYY-MM-DD")
      : "";
    let queryString =
      "unit_name=" +
      (this.state.unit_name ? this.state.unit_name : "") +
      "&bed_room=" +
      (this.state.bed_room ? this.state.bed_room : "") +
      "&bath_room=" +
      (this.state.bath_room ? this.state.bath_room : "") +
      "&floor_slug=" +
      (this.state.floor_slug ? this.state.floor_slug : "") +
      "&floor_plan_slug=" +
      (this.state.floor_plan_slug ? this.state.floor_plan_slug : "") +
      "&furnished=" +
      (this.state.furnished ? this.state.furnished : "") +
      "&rent=" +
      (this.state.rent ? this.state.rent : "") +
      "&tower_slug=" +
      (this.state.tower_slug ? this.state.tower_slug : "") +
      "&available=" +
      (this.state.available ? this.state.available : "") +
      "&available_from=" +
      (this.state.available_from ? availableFrom : "") +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.unit_name
      ? "Unit Name - " + this.state.unit_name
      : "";
    moduleSearchString = this.state.bed_room
      ? moduleSearchString
        ? moduleSearchString + " ," + " Bed Room - " + this.state.bed_room
        : " Bed Room - " + this.state.bed_room
      : moduleSearchString;
    moduleSearchString = this.state.bath_room
      ? moduleSearchString
        ? moduleSearchString + " ," + " Bath Room - " + this.state.bath_room
        : " Bath Room - " + this.state.bath_room
      : moduleSearchString;
    moduleSearchString = this.state.tower_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Tower - " +
          this.state.selectedTowerList.label
        : " Tower - " + this.state.selectedTowerList.label
      : moduleSearchString;
    moduleSearchString = this.state.floor_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Floor - " +
          this.state.selectedFloorList.label
        : " Floor - " + this.state.selectedFloorList.label
      : moduleSearchString;
    moduleSearchString = this.state.floor_plan_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Floor Plan - " +
          this.state.selectedFloorPlanList.label
        : " Floor Plan - " + this.state.selectedFloorPlanList.label
      : moduleSearchString;

    moduleSearchString = this.state.rent
      ? moduleSearchString
        ? moduleSearchString + " ," + " Rent - " + this.state.rent
        : " Rent - " + this.state.rent
      : moduleSearchString;
    moduleSearchString = this.state.furnished
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Furnished Type - " +
          this.state.furnished_label
        : " Furnished Type - " + this.state.furnished_label
      : moduleSearchString;
    moduleSearchString = this.state.available_from
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Available From - " +
          Moment(this.state.available_from).format(global.dateFormat)
        : " Available From - " +
          Moment(this.state.available_from).format(global.dateFormat)
      : moduleSearchString;
    if (
      this.state.unit_name ||
      this.state.bed_room ||
      this.state.bath_room ||
      this.state.floor_slug ||
      this.state.floor_plan_slug ||
      this.state.tower_slug ||
      this.state.rent ||
      this.state.furnished ||
      this.state.available_from
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(queryString);
    }
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      unit_name: "",
      bed_room: "",
      bath_room: "",
      floor_slug: "",
      floor_plan_slug: "",
      rent: "",
      tower_slug: "",
      furnished: "",
      available_from: "",
      selectedFloorList: [],
      selectedFloorPlanList: [],
      selectedTowerList: [],
    });
    this.setState({ module_search: "" });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <LeasingSidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyLeasingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {global.userPermissions.checkPermission(
                        "units-import"
                      ) && (
                        <button
                          type="button"
                          onClick={() => this.confirmImportModal()}
                          className="btn-success-outline ml-3"
                        >
                          {" "}
                          <img src={exportIcon} alt="" /> Import{" "}
                        </button>
                      )}
                      <button
                        onClick={this.searchToggle}
                        className="btn-success-outline-small ml-3"
                      >
                        <img src={filterIcon} alt="filterIcon" />{" "}
                        {this.state.isSearch === 1 ? "Search" : "Search"}
                      </button>
                    </div>
                  </div>
                </div>
                {this.props.match.params.propertySlug === undefined ? (
                  <>
                    <div className="row pd-lg-l-45 pd-xs-l-15">
                      <div className="col-xxl-2 col-md-3 col-sm-4">
                    <OutSidePropertySelectBox
                      propertySlug={this.getPropertySlug.bind(this)}
                      outSidePropertySlug={this.state.outSidePropertySlug}
                    />
                  </div>
                      <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                        {queryParams.isGlobal === "1" ? (
                          <>
                            <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between ">
                              {"Search applied on: " +
                                queryParams.global_col_label +
                                " - " +
                                (queryParams.global_col_name == "available_from"
                                  ? Moment(queryParams.global_search).format(
                                      global.dateFormat
                                    )
                                  : queryParams.global_search)}
                              <button
                                onClick={this.clearFilter}
                                className="mg-l-15 btn-success-outline-small"
                              >
                                Clear
                              </button>
                            </div>
                          </>
                ) : (
                  ""
                )}
                        {this.state.is_search === 1 ? (
                          <>
                            <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-0 d-inline-flex align-items-start justify-content-end justify_mob_filter">
                              <div className="custm-filter">
                                <div>
                                  {" "}
                                  <label className="form-label text-left pd-b-2 mb-0">
                                    Search Applied On
                                  </label>
                                </div>
                                <div className="filter_bg_cust">
                                  {this.state.module_search.map((item, i) => {
                                    return <span key={i}>{item}</span>;
                                  })}
                                  <button
                                    onClick={this.clearFilter}
                                    className="btn-fliter-clear"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-xxl-12 pd-r-15 pd-l-15">
                      {this.state.is_search === 1 ? (
                        <>
                          <div className="col-xxl-12 col-sm-12 pd-lg-r-15 pd-lg-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                            <div className="custm-filter">
                              <div>
                                {" "}
                                <label className="form-label text-left pd-b-2 mb-0">
                                  Search Applied On
                                </label>
                              </div>
                              <div className="filter_bg_cust">
                                {this.state.module_search.map((item, i) => {
                                  return <span key={i}>{item}</span>;
                                })}
                                <button
                                  onClick={this.clearFilter}
                                  className="btn-fliter-clear"
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
                <div className="scrolling-carousel allpanding">
                  <div className="row d-flex justify-content-between align-items-center pd-l-15 pd-r-15 mg-b-0 pd-b-2 mg-t-20"></div>
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-l--30 pd-r-30">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.state.showFilter ? (
                      <>
                        <Row className="align-items-end order_form col-md-12 pl-0 pr-0 mb-3 m-0">
                          <Col
                            md={3}
                            lg={3}
                            xl={3}
                            className="mb-md-0 mb-3 pl-3 pr-3"
                          >
                            <label className="form-label text-left pd-b-5">
                              Floor plan
                            </label>
                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedFloorPlanList}
                              options={this.state.newFloorPlanList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeFloorPlanList(value)
                              }
                              defaultValue={this.state.selectedFloorPlanList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </Col>
                          <Col
                            md={3}
                            lg={3}
                            xl={3}
                            className="mb-md-0 mb-3 pl-3 pr-3"
                          >
                            <label className="form-label text-left pd-b-5">
                              Floor
                            </label>
                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedFloorList}
                              options={this.state.newFloorList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeFloorList(value)
                              }
                              defaultValue={this.state.selectedFloorList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </Col>
                          <Col
                            md={3}
                            lg={3}
                            xl={3}
                            className="mb-md-0 mb-3 pl-3 pr-3"
                          >
                            <label className="form-label text-left pd-b-5">
                              Tower
                            </label>
                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedTowerList}
                              options={this.state.newTowerList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeTowerList(value)
                              }
                              defaultValue={this.state.selectedTowerList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </Col>
                          <Col
                            md={3}
                            lg={3}
                            xl={3}
                            className="mb-md-0 mb-3 pl-3 pr-3"
                          >
                            <label className="form-label text-left pd-b-5">
                              Furnished
                            </label>
                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedFurnishedList}
                              options={this.state.newFurnishedList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeFurnishedList(value)
                              }
                              defaultValue={this.state.selectedFurnishedList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </Col>
                        </Row>

                        <Row className="align-items-end order_form col-md-12 pl-0 pr-0 mb-3 m-0">
                          <Col
                            md={3}
                            lg={3}
                            xl={3}
                            className="mb-md-0 mb-3 pl-3 pr-3"
                          >
                            <label className="form-label text-left pd-b-5">
                              Date
                            </label>
                            <DatePicker
                              className="form-control max_width_100"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="available_from"
                              dateFormat="MM/dd/yy"
                              selected={this.state.available_from}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "available_from",
                                    value: data,
                                  },
                                })
                              }
                            />
                          </Col>

                          <Col md={3} lg={3} xl={3} className="mt-0">
                            <Row className="d-flex justify-content-end">
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-0 mg-r-15"
                              >
                                Apply
                              </button>
                              <button
                                onClick={this.resetDate}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}

                    {this.state.total_record > 0 ||
                    this.state.filter_value !== "" ? (
                      <div className="mg-t-18 brandList  row d-block">
                        <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-25 pd-xs-r-15">
                          <ReactDatatable
                            className="table no-wrap mg-b-30 pd-b-2 table_last_row"
                            config={
                              this.state.total_record > global.page_size
                                ? this.state.config
                                : this.state.config
                            }
                            records={this.state.records}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            total_record={this.state.total_record}
                            onChange={this.tableChangeHandler}
                          />
                        </div>
                      </div>
                    ) : (
                      <NoData msg="units availability" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal
          show={this.state.importModal}
          className=" importModalbox import_model_big"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="row">
              <div className=" col-md-12 m-0">
                {this.state.file_rows.length ? (
                  <div className="view_tbl_bdr">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive view_tbl_exp">
                          <table className="table file_preview_tbl">
                            <thead>
                              <tr>
                                <th scope="col">Id</th>
                                {this.state.file_columns.length
                                  ? this.state.file_columns.map(
                                      (cols, index) => (
                                        <th scope="col" key={index}>
                                          {cols}
                                        </th>
                                      )
                                    )
                                  : ""}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.file_rows.length
                                ? this.state.file_rows.map(
                                    (file_row, index) => {
                                      let colVals = Object.values(file_row);
                                      return (
                                        <tr key={index}>
                                          <th scope="row">{index + 1}</th>
                                          {colVals.map((colVal, idx) => {
                                            return <td key={idx}>{colVal}</td>;
                                          })}
                                        </tr>
                                      );
                                    }
                                  )
                                : ""}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="row mt-2">
                  <div className="col-md-8 ">
                    <div className="row desk_expo">
                      <div className="col-md-6 pr-0">
                        <div className="export_tbl_fst">
                          <p>Database Columns</p>
                        </div>
                      </div>
                      <div className="col-md-6 pl-0 ">
                        <div className="export_tbl_fst">
                          <p>File Column</p>
                        </div>
                      </div>
                    </div>
                    <div className="file_export_hgt">
                      <div className="col-md-6 p-0">
                        <div className="export_tbl_fst">
                          <p className="mob-expo">Columns</p>
                          <ul>
                            {DatabaseCols.map((column, index) => {
                              return <li key={index}>{column.label}{unitAvailabilityRequiredFields.includes(
                                    column.value
                                  ) ? (
                                    <label className="text-danger m-0">*</label>
                                  ) : (
                                    ""
                                  )}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6 p-0">
                        <div className="export_tbl_sec">
                          <p className="mob-expo">Select Columns</p>
                          <ul>
                            {DatabaseCols.map((column, index) => {
                              return (
                                <li key={index}>
                                  <Select
                                    placeholder={"Select For " + column.label}
                                    styles={customStyles}
                                    className="multiselect exp_filter_slect"
                                    menuPlacement="bottom"
                                    menuPosition="fixed"
                                    options={this.state.available_file_columns}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeImportColsList(
                                        value,
                                        index
                                      )
                                    }
                                    defaultValue={[]}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-4 m-0 download-sample elx_pdf_epo pt-0">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                      <div className="">
                        <form name="myFile">
                          <FileUploader
                            onChange={this.changeHandler}
                            handleChange={this.handleChange}
                            name="file_name"
                            types={["csv", "xlsx", "xls", "ods"]}
                            label="Drop File Here Max Size 30 MB"
                          />

                          {this.state.errors.file_name ? (
                            <div className="text-danger">
                              {this.state.errors.file_name}
                            </div>
                          ) : (
                            this.validator.message(
                              "import_file",
                              this.state.file_name,
                              "required",
                              { className: "text-danger" }
                            )
                          )}

                          {this.state.requiredColumnsErrMsg !== "" ? (
                            <div className="text-danger">
                              {this.state.requiredColumnsErrMsg}
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="d-flex justify-content-center mt-3 ">
                            <button
                              type="button"
                              className=" m-0 btn btn-outline-light btn-block"
                              onClick={() => this.closeConfirmImportModal()}
                            >
                              Cancel
                            </button>

                            <button
                              type="button"
                              className="m m-0 btn btn btn-success btn-block"
                              onClick={() => this.openConfirmationModal()}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit ? global.loader : "Import"}
                            </button>
                          </div>
                        </form>
                      </div>
                      <Link
                        to="/import_sample/unit-avail.csv"
                        target="_blank"
                        download="UnitAvailbility.csv"
                        className="dwon_imp_file"
                      >
                        Download sample template for Import
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* search filter modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Units Availability</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Unit Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="unit_name"
                      value={this.state.unit_name ?? ""}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Bedroom</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="bed_room"
                      value={this.state.bed_room ?? ""}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Bathroom</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="bath_room"
                      value={this.state.bath_room ?? ""}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Rent</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="rent"
                      value={this.state.rent ?? ""}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Available From</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="available_from"
                    selected={this.state.available_from}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "available_from",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Floor Plan</label>
                  <div className="col-md-12 p-0">
                    <Select
                      className="multiselect"
                      isClearable={true}
                      menuPlacement="auto"
                      value={this.state.selectedFloorPlanList ?? ""}
                      options={this.state.newFloorPlanList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeFloorPlanList(value)
                      }
                      defaultValue={this.state.selectedFloorPlanList}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Floor</label>
                  <div className="col-md-12 p-0">
                    <Select
                      className="multiselect"
                      isClearable={true}
                      menuPlacement="auto"
                      value={this.state.selectedFloorList ?? ""}
                      options={this.state.newFloorList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeFloorList(value)}
                      defaultValue={this.state.selectedFloorList}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Tower</label>
                  <div className="col-md-12 p-0">
                    <Select
                      className="multiselect"
                      isClearable={true}
                      menuPlacement="auto"
                      value={this.state.selectedTowerList ?? ""}
                      options={this.state.newTowerList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeTowerList(value)}
                      defaultValue={this.state.selectedTowerList}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Furnished Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      className="multiselect"
                      isClearable={true}
                      menuPlacement="auto"
                      value={this.state.selectedFurnishedList ?? ""}
                      options={this.state.newFurnishedList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeFurnishedList(value)
                      }
                      defaultValue={this.state.selectedFurnishedList}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* import data modal */}
        <Modal
          show={this.state.importResponseModel}
          className="modal-xs"
          centered
        >
          <Modal.Body className="width_402 p-0 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeimportResponseModel()}
            >
              {global.closee}
            </span>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-between mt-0 row">
                  {this.state.errorResponse == true ? (
                    <div className="msg col-12 text-center mt-4">
                      <img src={ErrorLogo} alt="filterIcon" className="mb-2" />{" "}
                      <p className="mb-0">{this.state.errorMessage}</p>{" "}
                    </div>
                  ) : (
                    <div className="msg col-12 text-center mt-4">
                      <img
                        src={SuccessLogo}
                        alt="filterIcon"
                        className="mb-2"
                      />
                      <p className="mb-0">{this.state.successMessage}</p>{" "}
                    </div>
                  )}
                  {this.state.totalRecord ? (
                    <div className="mt-0">
                      <div className="row flex-wrap m-0">
                        <div className="row p-4 pb-0-cust">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <p className="az-profile-name-text pl-0">
                              Total Record
                            </p>
                            <p className="media-body">
                              {this.state.totalRecord}{" "}
                            </p>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <p className="az-profile-name-text pl-0">
                              Success Record
                            </p>
                            <p className="media-body">
                              {this.state.successRecord}{" "}
                            </p>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <p className="az-profile-name-text pl-0">
                              Skip Record
                            </p>
                            <p className="media-body">
                              {this.state.skipRecord}{" "}
                            </p>
                          </div>{" "}
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <p className="az-profile-name-text pl-0">
                              Failed Record
                            </p>
                            <p className="media-body">
                              {this.state.FailedRecord}
                            </p>
                          </div>
                        </div>
                        <div className="row pl-rm-cust pb-0-cust w-100">
                          <div className="col-sm-12">
                            <p className="az-profile-name-text pl-0">
                              Log Informations
                            </p>
                          </div>
                        </div>
                        <div className="row information-logs-container w-100">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">
                            <p
                              className="media-body pr-3"
                              dangerouslySetInnerHTML={{
                                __html: this.state.recordError
                                  ? this.state.recordError
                                  : "No any record skipped.",
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Confirm popup modal */}
        <Modal
          show={this.state.ConfirmationModal}
          onHide={() => {
            if (
              window.confirm(
                "Are you sure to exit the pop up without run Confirmation?"
              )
            )
              this.closeConfirmationModal();
          }}
          centered
          className="exp_conf_modal"
        >
          <Modal.Body className="width_402 p-0 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeConfirmationModal()}
            >
              {global.closee}
            </span>
            {/* before import preview */}
            <div className="moreMsg mt-3">
              <div className="row flex-wrap m-0">
                <div className="row p-4 pb-0-cust">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="az-profile-name-text pl-0">Total Record</p>
                    <p className="media-body">{this.state.totalRecord} </p>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="az-profile-name-text pl-0">Success Record</p>
                    <p className="media-body">{this.state.successRecord} </p>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="az-profile-name-text pl-0">Skip Record</p>
                    <p className="media-body">{this.state.skipRecord} </p>
                  </div>{" "}
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="az-profile-name-text pl-0">Failed Record</p>
                    <p className="media-body">{this.state.FailedRecord}</p>
                  </div>
                </div>
                <div className="row pl-rm-cust pb-0-cust w-100">
                  <div className="col-sm-12">
                    <p className="az-profile-name-text pl-0">
                      Log Informations
                    </p>
                  </div>
                </div>
                <div className="row information-logs-container w-100">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">
                    <p
                      className="media-body mb-0 pr-3"
                      dangerouslySetInnerHTML={{
                        __html: this.state.recordError
                          ? this.state.recordError
                          : "No any record skipped.",
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            {/* end code before import preview */}
            <div className="text-center p-4">
              <h3>Ready for import?</h3>
              <p>
                Do you really want to run this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeConfirmationModal()}
                >
                  Cancel
                </button>
                {this.state.is_date_invalid == false ? (
                  <button
                    type="button"
                    className="m-0 btn btn btn-danger btn-block"
                    disabled={this.state.isSubmit ? true : false}
                    onClick={this.confirmImport}
                  >
                    {this.state.isSubmit ? global.loader : "Yes"}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="m-0 btn btn btn-danger btn-block"
                    disabled={true}
                  >
                    {this.state.isSubmit ? global.loader : "Yes"}
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default UnitsAvailability;
