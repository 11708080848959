/////////////////////////////////////////////////////
//     							                   //
//  Program: ArrivalInstructions.jsx                       //
//  Application: Property                          //
//  Option: List all Property Guest Manual info    //
//  Developer:NP					               //
//  Date: 2022-09-15                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
import FileUploadService from "../../services/FileUploadService";
import renderHTML from "react-render-html";
import { Container } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { Jodit } from "jodit";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import PropertySidebar from "../common/PropertySidebar";
import NoData from "../common/NoData";
export class ArrivalInstructions extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      property_slug: this.props.match.params.propertySlug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      confirm_back: 0,
      descShow: 0,
      descShowRules: 0,
      descShowRulesCreditCard: 0,
      // descShowRulesParkingPayment: 0,
      // descShowRulesParkingInstruction: 0,
      descShowHealthSafety: 0,
      descShowGettingAround: 0,
      lock_type: 0,
    };
    this.editorConfig = {
      extraButtons: ["uploadImage", "uploadImageVideo"],
    };
    this.changeView = this.changeView.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.fullText = this.fullText.bind(this);
    this.fullTextRules = this.fullTextRules.bind(this);
    this.fullTextRulesCreditCard = this.fullTextRulesCreditCard.bind(this);
    // this.fullTextRulesParkingPayment = this.fullTextRulesParkingPayment.bind(this);
    // this.fullTextRulesParkingInstruction = this.fullTextRulesParkingInstruction.bind(this);
    this.fullTextHealthSafety = this.fullTextHealthSafety.bind(this);
    this.fullTextGettingAround = this.fullTextGettingAround.bind(this);
    this.setContent = this.setContent.bind(this);
    this.setContent = this.setContent.bind(this);
    this.setContentCreditCard = this.setContentCreditCard.bind(this);
    // this.setContentParkingPayment = this.setContentParkingPayment.bind(this);
    // this.setContentParkingInstruction = this.setContentParkingInstruction.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    this.getData();
    this.uploadImageButton();
    this.uploadVideoButton();
    this.setState({ showSpinner: false, loading: false });
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        // resData = res.data ? res.data : {};
        resData.slug = res.data && res.data.slug ? res.data.slug : "";
        resData.property_name =
          res.data && res.data.property_name ? res.data.property_name : "";
        resData.aka_name =
          res.data && res.data.aka_name ? res.data.aka_name : "";
        resData.arrival_instruction =
          res.data && res.data.arrival_instruction
            ? res.data.arrival_instruction
            : "";
        resData.credit_card_instructions =
          res.data && res.data.credit_card_instructions
            ? res.data.credit_card_instructions
            : "";
        resData.lock_type =
          res.data && res.data.lock_type ? res.data.lock_type : 0;
        // resData.parking_payment_instructions = res.data && res.data.parking_payment_instructions ? res.data.parking_payment_instructions : ""
        // resData.parking_instructions = res.data && res.data.parking_instructions ? res.data.parking_instructions : ""
      }

      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: "Upload image",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.imageUpload(editor);
      },
    };
  };

  imageUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];
      console.log("imageFile", imageFile);

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", imageFile, imageFile.name);
      fileData.append("fileName", imageFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertImage(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editor.selection.insertNode(image);
  };

  uploadVideoButton = () => {
    Jodit.defaultOptions.controls.uploadImageVideo = {
      name: "Upload video",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.videoUpload(editor);
      },
    };
  };

  videoUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = async function () {
      const videoFile = input.files[0];
      console.log("videoFile", videoFile);

      if (!videoFile) {
        return;
      }

      if (!videoFile.name.match(/\.(mp3|mp4|mov)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", videoFile, videoFile.name);
      fileData.append("fileName", videoFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertVideo(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertVideo = (editor, url) => {
    const video = editor.selection.j.createInside.element("video");
    video.setAttribute("src", url);
    editor.selection.insertNode(video);
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  // Custom validation rules
  customValidate() {
    let isValid = true;
    return isValid;
  }

  saveOrUpdateProperty = async (e) => {
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputData = {
        tab: "arrival-instructions",
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        // description: this.state.description,
        arrival_instruction: this.state.arrival_instruction,
        credit_card_instructions: this.state.credit_card_instructions,
        // parking_payment_instructions: this.state.parking_payment_instructions,
        // parking_instructions: this.state.parking_instructions

        // health_safety: this.state.health_safety,
        // getting_around: this.state.getting_around,
      };

      if (this.state.slug !== undefined) {
        let res = await PropertyService.updateProperty(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getData();
          this.setState({ viewMode: 0 });
        } else {
          //let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      } else {
        let res = await PropertyService.createProperty(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getData();
          this.setState({ viewMode: 0 });
        } else {
          // let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      }
    }
  };

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.getData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.getData();
    }
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextRules() {
    if (this.state.descShowRules === 0) {
      this.setState({ descShowRules: 1 });
    } else {
      this.setState({ descShowRules: 0 });
    }
  }

  fullTextRulesCreditCard() {
    if (this.state.descShowRulesCreditCard === 0) {
      this.setState({ descShowRulesCreditCard: 1 });
    } else {
      this.setState({ descShowRulesCreditCard: 0 });
    }
  }

  // fullTextRulesParkingPayment() {
  //   if (this.state.descShowRulesParkingPayment === 0) {
  //     this.setState({ descShowRulesParkingPayment: 1 });
  //   } else {
  //     this.setState({ descShowRulesParkingPayment: 0 });
  //   }
  // }

  // fullTextRulesParkingInstruction() {
  //   if (this.state.descShowRulesParkingInstruction === 0) {
  //     this.setState({ descShowRulesParkingInstruction: 1 });
  //   } else {
  //     this.setState({ descShowRulesParkingInstruction: 0 });
  //   }
  // }

  fullTextHealthSafety() {
    if (this.state.descShowHealthSafety === 0) {
      this.setState({ descShowHealthSafety: 1 });
    } else {
      this.setState({ descShowHealthSafety: 0 });
    }
  }

  fullTextGettingAround() {
    if (this.state.descShowGettingAround === 0) {
      this.setState({ descShowGettingAround: 1 });
    } else {
      this.setState({ descShowGettingAround: 0 });
    }
  }

  getTitle() {
    return "Arrival Instructions";
  }

  setContent(value) {
    this.setState({
      arrival_instruction: value,
    });
  }

  setContentCreditCard(value) {
    this.setState({
      credit_card_instructions: value,
    });
  }

  // setContentParkingPayment(value){
  //   this.setState({
  //     parking_payment_instructions:value
  //   })
  // }

  // setContentParkingInstruction(value){
  //   this.setState({
  //     parking_instructions:value
  //   })
  // }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {this.state.viewMode === 0 ? (
                        global.userPermissions.checkPermission(
                          "house-rules-update"
                        ) && (
                          <button
                            className="btn-success-outline-small"
                            onClick={this.changeView}
                          >
                            <img src={editIcon} alt="" />
                            Edit
                          </button>
                        )
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.changeView();
                                else return false;
                              else this.changeView();
                            }}
                          >
                            Cancel
                          </button>
                          {global.userPermissions.checkPermission(
                            "house-rules-update"
                          ) && (
                            <button
                              className="btn-success ml-3"
                              onClick={this.saveOrUpdateProperty}
                            >
                              Save
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                    <div className="col-md-12 pd-l-15 pd-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        // show="true"
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.state.viewMode === 0 ? (
                      <>
                        {this.state.arrival_instruction ? (
                          <div className="row mg-b-15">
                            <div className="col-md-12 pd-l-30 pd-r-30 pd-lg-t-25">
                              <p className="az-profile-name-text pl-0">
                                Arrival Instructions:
                              </p>
                              <div className="para">
                                {this.state.arrival_instruction ? ( //check if property_rules is exist
                                  this.state.arrival_instruction.length >
                                  global.showMoreLength ? ( //check if length is long
                                    this.state.descShowRules === 0 ? (
                                      <>
                                        <HTMLEllipsis
                                          unsafeHTML={
                                            this.state.arrival_instruction
                                              ? this.state.arrival_instruction
                                              : ""
                                          }
                                          maxLine="6"
                                          ellipsis=""
                                          trimright="true"
                                          basedOn="letters"
                                        />
                                        <p
                                          className="hand-cursor showmore"
                                          onClick={this.fullTextRules}
                                        >
                                          Show More
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        {renderHTML(
                                          "" + this.state.arrival_instruction
                                        )}
                                        <p
                                          className="hand-cursor showmore"
                                          onClick={this.fullTextRules}
                                        >
                                          Show Less
                                        </p>
                                      </>
                                    )
                                  ) : (
                                    renderHTML(
                                      "" + this.state.arrival_instruction
                                    )
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <NoData msg="Arrival Instructions" action=" Edit " />
                        )}
                        {this.state.credit_card_instructions ? (
                          <div className="row mg-b-15">
                            <div className="col-md-12 pd-l-30 pd-r-30 pd-lg-t-25">
                              <p className="az-profile-name-text pl-0">
                                Credit Card Instructions:
                              </p>
                              <div className="para">
                                {this.state.credit_card_instructions ? ( //check if property_rules is exist
                                  this.state.credit_card_instructions.length >
                                  global.showMoreLength ? ( //check if length is long
                                    this.state.descShowRulesCreditCard === 0 ? (
                                      <>
                                        <HTMLEllipsis
                                          unsafeHTML={
                                            this.state.credit_card_instructions
                                              ? this.state
                                                  .credit_card_instructions
                                              : ""
                                          }
                                          maxLine="6"
                                          ellipsis=""
                                          trimright="true"
                                          basedOn="letters"
                                        />
                                        <p
                                          className="hand-cursor showmore"
                                          onClick={this.fullTextRulesCreditCard}
                                        >
                                          Show More
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        {renderHTML(
                                          "" +
                                            this.state.credit_card_instructions
                                        )}
                                        <p
                                          className="hand-cursor showmore"
                                          onClick={this.fullTextRulesCreditCard}
                                        >
                                          Show Less
                                        </p>
                                      </>
                                    )
                                  ) : (
                                    renderHTML(
                                      "" + this.state.credit_card_instructions
                                    )
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <NoData
                            msg="Credit Card Instructions"
                            action=" Edit "
                          />
                        )}
                        {/* {this.state.parking_payment_instructions ?
                        <div className="row mg-b-15">
                          <div className="col-md-12 pd-l-30 pd-r-30 pd-lg-t-25">
                            <p className="az-profile-name-text pl-0">
                            Parking Payment Instructions:
                            </p>
                            <div className="para">
                              {this.state.parking_payment_instructions ? ( //check if property_rules is exist
                                this.state.parking_payment_instructions.length >
                                global.showMoreLength ? ( //check if length is long
                                  this.state.descShowRulesParkingPayment === 0 ? (
                                    <>
                                      <HTMLEllipsis
                                        unsafeHTML={
                                          this.state.parking_payment_instructions
                                            ? this.state.parking_payment_instructions
                                            : ""
                                        }
                                        maxLine="6"
                                        ellipsis=""
                                        trimright="true"
                                        basedOn="letters"
                                      />
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={this.fullTextRulesParkingPayment}
                                      >
                                        Show More
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      {renderHTML("" + this.state.parking_payment_instructions)}
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={this.fullTextRulesParkingPayment}
                                      >
                                        Show Less
                                      </p>
                                    </>
                                  )
                                ) : (
                                  renderHTML("" + this.state.parking_payment_instructions)
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        : <NoData
                          msg="Parking Payment Instructions"
                          action=" Edit "
                        /> } */}
                        {/* {this.state.parking_instructions ?
                        <div className="row mg-b-15">
                          <div className="col-md-12 pd-l-30 pd-r-30 pd-lg-t-25">
                            <p className="az-profile-name-text pl-0">
                                Parking Instructions:
                            </p>
                            <div className="para">
                              {this.state.parking_instructions ? ( //check if property_rules is exist
                                this.state.parking_instructions.length >
                                global.showMoreLength ? ( //check if length is long
                                  this.state.descShowRulesParkingInstruction === 0 ? (
                                    <>
                                      <HTMLEllipsis
                                        unsafeHTML={
                                          this.state.parking_instructions
                                            ? this.state.parking_instructions
                                            : ""
                                        }
                                        maxLine="6"
                                        ellipsis=""
                                        trimright="true"
                                        basedOn="letters"
                                      />
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={this.fullTextRulesParkingInstruction}
                                      >
                                        Show More
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      {renderHTML("" + this.state.parking_instructions)}
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={this.fullTextRulesParkingInstruction}
                                      >
                                        Show Less
                                      </p>
                                    </>
                                  )
                                ) : (
                                  renderHTML("" + this.state.parking_instructions)
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        : <NoData
                          msg="Parking Instructions"
                          action=" Edit "
                        /> } */}
                      </>
                    ) : (
                      ""
                    )}

                    {this.state.viewMode === 1 ? (
                      <>
                        <div className="m-0">
                          <div className="row align-items-center pd-t-25 pd-b-30 border-bottom-0">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-15 ">
                              <label className="form-label text-left pd-b-5">
                                Arrival Instructions: [Parking-Spot]{" "}
                                {this.state.lock_type === 1
                                  ? "[Key-Cafe-Code]"
                                  : this.state.lock_type === 2
                                  ? "[Latch-Door-Code] [Latch-Laundry-Code]"
                                  : ""}
                                [Wifi-Name] [Wifi-Password] [Unit-Number]
                                [Address]
                              </label>

                              {/* <CKEditor
                              editor={ClassicEditor}
                              config={{
                                removePlugins: [
                                  "EasyImage",
                                  "ImageUpload",
                                  "MediaEmbed",
                                  "Table",
                                ],
                              }}
                              name="arrival_instruction"
                              id="arrival_instruction"
                              minHeight="50px"
                              data={this.state.arrival_instruction}
                              onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                  writer.setStyle(
                                    "height",
                                    "200px",
                                    editor.editing.view.document.getRoot()
                                  );
                                });
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                this.changeHandler({
                                  target: {
                                    type: "ckeditor",
                                    name: "arrival_instruction",
                                    value: data,
                                  },
                                });
                              }}
                            /> */}

                              <JoditEditor
                                config={this.editorConfig}
                                ref={null}
                                name="arrival_instruction"
                                id="arrival_instruction"
                                value={this.state.arrival_instruction}
                                onBlur={(newContent) =>
                                  this.setContent(newContent)
                                }
                                // onChange={(newContent) =>
                                //   this.setContent(newContent)
                                // }
                              />
                              {this.state.errors.arrival_instruction ? (
                                <div className="text-danger">
                                  {this.state.errors.arrival_instruction}
                                </div>
                              ) : (
                                this.validator.message(
                                  "arrival_instruction",
                                  this.state.arrival_instruction,
                                  "max:100000",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="m-0">
                          <div className="row align-items-center pd-t-25 pd-b-30 border-bottom-0">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-15 ">
                              <label className="form-label text-left pd-b-5">
                                Credit Card Instructions:
                              </label>
                              <JoditEditor
                                ref={null}
                                config={this.editorConfig}
                                name="credit_card_instructions"
                                id="credit_card_instructions"
                                value={this.state.credit_card_instructions}
                                onBlur={(newContent) =>
                                  this.setContentCreditCard(newContent)
                                }
                                // onChange={(newContent) =>
                                //   this.setContentCreditCard(newContent)
                                // }
                              />
                              {this.state.errors.credit_card_instructions ? (
                                <div className="text-danger">
                                  {this.state.errors.credit_card_instructions}
                                </div>
                              ) : (
                                this.validator.message(
                                  "credit_card_instructions",
                                  this.state.credit_card_instructions,
                                  "max:100000",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className="m-0">
                        <div className="row align-items-center pd-t-25 pd-b-30 border-bottom-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-15 ">
                            <label className="form-label text-left pd-b-5">
                            Parking Payment Instructions:
                             
                            </label>

                            <JoditEditor
                              ref={null}
                              config={this.editorConfig}
                              name="parking_payment_instructions"
                              id="parking_payment_instructions"
                              value={this.state.parking_payment_instructions}
                              onBlur={newContent => this.setContentParkingPayment(newContent)}
                              onChange={newContent => this.setContentParkingPayment(newContent)}
                            />
                            {this.state.errors.parking_payment_instructions ? (
                              <div className="text-danger">
                                {this.state.errors.parking_payment_instructions}
                              </div>
                            ) : (
                              this.validator.message(
                                "parking_payment_instructions",
                                this.state.parking_payment_instructions,
                                "max:100000",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                      </div> */}
                        {/* <div className="m-0">
                        <div className="row align-items-center pd-t-25 pd-b-30 border-bottom-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-15 ">
                            <label className="form-label text-left pd-b-5">
                            Parking Instructions: [Unit-Number] [Parking-Spot]
                            </label>

                            <JoditEditor
                              ref={null}
                              config={this.editorConfig}
                              name="parking_instructions"
                              id="parking_instructions"
                              value={this.state.parking_instructions}
                              onBlur={newContent => this.setContentParkingInstruction(newContent)}
                              onChange={newContent => this.setContentParkingInstruction(newContent)}
                            />
                            {this.state.errors.parking_instructions ? (
                              <div className="text-danger">
                                {this.state.errors.parking_instructions}
                              </div>
                            ) : (
                              this.validator.message(
                                "parking_instructions",
                                this.state.parking_instructions,
                                "max:100000",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                      </div> */}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default ArrivalInstructions;
