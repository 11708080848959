///////////////////////////////////////////////
//     							             //
//  Program: IglooService.js                 //
//  Application: Services                    //
//  Option: Used for Igloo Module              //
//  Developer: Ravi                //
//  Date: 2023-08-22                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class IglooService {
  getIglooLock(queryString) {
    let endPoint = "get-igloo-units" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {endPoint,addditionalHeaderData, postData};
    let response = ApiService.getCall(inputData);
    return response;
  }

  /* Get Igloo Instruction --------------------------- */
  updateLatchInstruction(data) {
    let endPoint = "update-latch-instruction";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {endPoint, addditionalHeaderData, postData};
    let response = ApiService.postCall(inputData);
    return response;
  }
  /* Mapping Igloo locks with Units --------------------------- */
  mappingIglooUnits(data) {
    let endPoint = "map-units";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {endPoint, addditionalHeaderData, postData};
    let response = ApiService.postCall(inputData);
    return response;
  }
 /* Get igloo Instruction --------------------------- */
getIglooInstruction(data) {
  let endPoint = "update-igloo-instruction";
  let addditionalHeaderData = {};
  let postData = data;

  let inputData = {
    endPoint: endPoint,
    addditionalHeaderData: addditionalHeaderData,
    postData: postData,
  };
  let response = ApiService.postCall(inputData);
  return response;
}

getIglooInstructionlock(queryString) {
  let endPoint = "get-igloo-instruction" + (queryString ? "?" + queryString : "");
  let addditionalHeaderData = {};
  let postData = {};

  let inputData = {
    endPoint: endPoint,
    addditionalHeaderData: addditionalHeaderData,
    postData: postData,
  };
  let response = ApiService.getCall(inputData);
  return response;
}

addManualAccess(data) {
  delete data.errors;
  delete data.input;

  let endPoint = "igloo-access";
  let addditionalHeaderData = {};
  let postData = data;

  let inputData = {
    endPoint: endPoint,
    addditionalHeaderData: addditionalHeaderData,
    postData: postData,
  };
  let response = ApiService.postCall(inputData);
  return response;
}

getIglooAccess(queryString) {
  let endPoint = "get-igloo-access"+ (queryString ? '?' + queryString : '');
  let addditionalHeaderData = {};
  let postData = {};

  let inputData = {
    endPoint: endPoint,
    addditionalHeaderData: addditionalHeaderData,
    postData: postData,
  };
  let response = ApiService.getCall(inputData);
  return response;
}

}
export default new IglooService();
