////////////////////////////////////////////////////////////
//     							                                      //
//  Program: BuildingOnboarding.jsx                       //
//  Application: BuildingOnboarding                       //
//  Developer: Ashish Kumar                               //
//  Date: 2024-03-18                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import propertyLogo from "./../../assets/images/property_placeholder.png";
import propertyImg from "./../../assets/images/overview-placeholder.jpg";
import onboradingImg from "./../../assets/images/onborading.png";
import { Container, Form, Nav, Tab, Modal, InputGroup } from "react-bootstrap";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import PropertyService from "../../services/PropertyService";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import CityService from "../../services/CityService";
import FileWidget from "../fileUploader/FileWidget";
import pdfIcon from "../../assets/images/pdf.png";
import wordIcon from "../../assets/images/word.png";
import excelIcon from "../../assets/images/excel.png";
import formatNotSupported from "../../assets/images/not-supported.png";
import Moment from "moment";
import MultiImageService from "../../services/MultiImageService";
import NoData from "../common/NoData";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
// import Alerts from "../common/Alerts";
import JoditEditor from "jodit-react";
import FileUploadService from "../../services/FileUploadService";
import { Jodit } from "jodit";
import renderHTML from "react-render-html";
import NumberFormat from "react-number-format";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import StripePayoutService from "../../services/StripePayoutService";
import Amenities from "../marketing/Amenities";
import PropertyDocuments from "../property/PropertyDocuments";
import queryString from "query-string";
import BuildingOnboardingService from "../../services/BuildingOnboardingService";
import brandsmallLogo from "./../../assets/images/brand_placeholder.png";
import onbordhomeicon from "./../../assets/images/onbord-home-icon.svg";
import bankinfoimg from "./../../assets/images/onboard-bankinfo-img.png";
import permitplanimg from "./../../assets/images/permitplanimg.png";
// import parkingplan from "./../../assets/images/parking-plan.png";
import stafftraining from "./../../assets/images/staff-training.png";
import announcehomeshare from "./../../assets/images/announce-home-sharing.png";
import PhoneInput from "react-phone-input-2";
import UserService from "../../services/UserService";

let queryParams = queryString.parse(window.location.search);

class BuildingOnboarding extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      publishData: false,
      property_slug: this.props.match.params.propertySlug,
      property_main_image_url: propertyImg,
      property_logo_url: propertyLogo,
      showSpinner: false,
      activeClass: "homeTab",
      buildingOnboardingTab: localStorage.getItem(
        "building_onboarding_tab_data"
      )
        ? localStorage.getItem("building_onboarding_tab_data")
        : "",
      disabledNextButton: false,
      disabledBackButton: false,

      /* Home Tab Start */
      basicStep: 1,
      checkboxArray: [
        { label: "Basics", name: "basic_tab", checked: false },
        { label: "Media & Files", name: "files_tab", checked: false },
        { label: "Amenities", name: "amenities_tab", checked: false },
        { label: "Parking Plan", name: "parking_tab", checked: false },
        { label: "Banking Info", name: "banking_information", checked: false },
        { label: "CSV Import", name: "csv_tab", checked: false },
        { label: "Lease Addendum", name: "lease_tab", checked: false },
        { label: "Staff Training", name: "property_staff_tab", checked: false },
        {
          label: "Announce Homesharing",
          name: "announce_homesharing_tab",
          checked: false,
        },
        {
          label: "Install Key Locker",
          name: "install_key_locker_tab",
          checked: false,
        },
        { label: "Permit Plan", name: "permit_plan_tab", checked: false },
      ],
      /* Home Tab End */

      /* Basic Tab Start */
      imageUrl: propertyLogo,
      imgPropUrl: propertyImg,
      brand_image: brandsmallLogo,
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      property_manager_slug: "",
      newAccountManagerList: [],
      selectedPropertyManagerList: [],
      selectedLockTypeList: [],
      lockTypeList: global.lockTypeList,
      property_name: "",
      street_1: "",
      state_name: "",
      zip_code: "",
      country_name: "",
      property_manager_name: "",
      property_email: "",
      property_phone_number: "",
      orionhaus_name: "",
      orionhaus_email: "",
      orionhaus_phone_number: "",
      /* Basic Tab End */

      /* Parking Tab Start */
      viewParkingMode: 0,
      descShowRulesParkingInstruction: 0,
      descShowRulesParkingPayment: 0,
      /* Parking Tab End */

      /* Banking Info Tab Start */
      is_stripe_setup: false,
      stripeStatus: 0,
      stripeAccountLink: "",
      stripe_account_id: "",
      /* Banking Info Tab End */

      /* CSV Import Tab Start */
      unit_import: false,
      current_leases: false,
      rent_collection: false,
      unit_import_data: [],
      rent_collection_data: [],
      current_lease_data: [],
      fileUploaderRefresh: false,
      /* CSV Import Tab End */
      wizardViewChange: 0,
    };
    this.getActiveClass = this.getActiveClass.bind(this);
    this.tabChanges = this.tabChanges.bind(this);
    this.handleHomeClick = this.handleHomeClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);

    /* Basic Tab Start */
    this.propertyLogo = React.createRef();
    this.propertyImg = React.createRef();
    this.changeHandler = this.changeHandler.bind(this);
    /* Basic Tab End */

    /* Parking Tab Start */
    this.editorConfig = {
      extraButtons: ["uploadImage", "uploadImageVideo"],
    };

    this.saveOrUpdateParking = this.saveOrUpdateParking.bind(this);
    this.changeParkingView = this.changeParkingView.bind(this);
    this.fullTextRulesParkingInstruction =
      this.fullTextRulesParkingInstruction.bind(this);
    this.setContentParkingInstruction =
      this.setContentParkingInstruction.bind(this);
    this.fullTextRulesParkingPayment =
      this.fullTextRulesParkingPayment.bind(this);
    this.setContentParkingPayment = this.setContentParkingPayment.bind(this);
    /* Parking Tab End */
    this.phoneNumberAutoFormat = this.phoneNumberAutoFormat.bind(this);

    /* CSV Import Tab Start */
    this.uploadCSV = this.uploadCSV.bind(this);
    /* CSV Import Tab End */

    this.propertyDocView = this.propertyDocView.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      switch (this.state.buildingOnboardingTab) {
        case "homeTab":
          document.getElementById("homeTab").click();
          this.setState({
            disabledBackButton: true,
            disabledNextButton: false,
          });
          break;
        case "BasicsTab":
          document.getElementById("BasicsTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "FilesTab":
          document.getElementById("FilesTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "AmenitiesTab":
          document.getElementById("AmenitiesTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "ParkingPlanTab":
          document.getElementById("ParkingPlanTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "BankingInfoTab":
          document.getElementById("BankingInfoTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "CSVImportTab":
          document.getElementById("CSVImportTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "PermitPlanTab":
          document.getElementById("PermitPlanTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "LeaseAddendumTab":
          document.getElementById("LeaseAddendumTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "StaffTrainingTab":
          document.getElementById("StaffTrainingTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "AnnounceHomesharingTab":
          document.getElementById("AnnounceHomesharingTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "InstallKeyLockerTab":
          document.getElementById("InstallKeyLockerTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: true,
          });
          break;
        default:
          document.getElementById("homeTab").click();
          this.setState({
            disabledBackButton: true,
            disabledNextButton: false,
          });
      }
    }, 100);

    /* Home Tab Start */
    this.getHomeTabData();
    this.getCmsContent();
    /* Home Tab End */

    /* Basic Tab Start */
    this.getPropertyData(); // Get data from server when first time load the
    this.getCityData(); // get city list for select box in basic
    this.getUserList();
    /* Basic Tab End */

    /* Banking Info Tab Start */
    this.getPropertyStripeAccount();
    this.getPropertyStripeW9Info();
    if (queryParams.onboardingCompleted === 1) {
      document.getElementById("BankingInfoTab").click();
    }
    /* Banking Info Tab End */

    /* CSV Import Tab Start */
    this.getCSVData();
    /* CSV Import Tab End */
  }

  /* Home Tab Start */
  saveOrUpdateHomeTabs = async (e) => {
    this.setState({ showSpinner: true, isSubmit: true });
    if (this.validator.allValid() && this.customValidate()) {
      let inputData = {
        property_slug: this.state.property_slug ?? null,
        homeCheckbox: this.state.checkboxArray ?? null,
      };

      let res = await BuildingOnboardingService.saveOrUpdateHomeTabs(
        this.state.property_slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        document.getElementById("BasicsTab").click();
      } else {
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];

            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
    }
    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  isCheckboxChecked(name) {
    const checkbox = this.state.checkboxArray.find(
      (item) => item.name === name
    );
    return checkbox ? checkbox.checked : false;
  }

  changeHomeCheckboxHandler = (event) => {
    const { name, checked } = event.target;
    const { checkboxArray } = this.state;
    const existingIndex = checkboxArray.findIndex((item) => item.name === name);
    const defaultLabel =
      existingIndex !== -1 ? checkboxArray[existingIndex]?.label : "";

    if (existingIndex !== -1) {
      checkboxArray[existingIndex] = { label: defaultLabel, name, checked };
    } else {
      checkboxArray.push({ label: defaultLabel, name, checked });
    }

    this.setState({ checkboxArray: [...checkboxArray] });
  };

  async getHomeTabData() {
    var checkBoxArr = [];
    var checkBoxArrLength = 0;
    let res = await BuildingOnboardingService.getHomeTabData(
      this.state.property_slug
        ? "property_slug=" + this.state.property_slug
        : ""
    );
    if (global.successStatus.includes(res.status)) {
      checkBoxArrLength = res.data ? res.data.length : 0;
      checkBoxArr = res.data
        ? res.data
        : [
            { label: "Basics", name: "basic_tab", checked: false },
            { label: "Media & Files", name: "files_tab", checked: false },
            { label: "Amenities", name: "amenities_tab", checked: false },
            { label: "Parking Plan", name: "parking_tab", checked: false },
            {
              label: "Banking Info",
              name: "banking_information",
              checked: false,
            },
            {
              label: "CSV Import",
              name: "csv_tab",
              checked: false,
            },
            { label: "Lease Addendum", name: "lease_tab", checked: false },
            {
              label: "Staff Training",
              name: "property_staff_tab",
              checked: false,
            },
            {
              label: "Announce Homesharing",
              name: "announce_homesharing_tab",
              checked: false,
            },
            {
              label: "Install Key Locker",
              name: "install_key_locker_tab",
              checked: false,
            },
            { label: "Permit Plan", name: "permit_plan_tab", checked: false },
          ];
      const values = [];
      if (checkBoxArrLength > 0) {
        checkBoxArr.forEach((item, i) => {
          values.push({
            label: item.label,
            name: item.name,
            checked: item.checked,
          });
        });
      } else {
        checkBoxArr = [
          { label: "Basics", name: "basic_tab", checked: false },
          { label: "Media & Files", name: "files_tab", checked: false },
          { label: "Amenities", name: "amenities_tab", checked: false },
          { label: "Parking Plan", name: "parking_tab", checked: false },
          {
            label: "Banking Info",
            name: "banking_information",
            checked: false,
          },
          { label: "CSV Import", name: "csv_tab", checked: false },
          { label: "Lease Addendum", name: "lease_tab", checked: false },
          {
            label: "Staff Training",
            name: "property_staff_tab",
            checked: false,
          },
          {
            label: "Announce Homesharing",
            name: "announce_homesharing_tab",
            checked: false,
          },
          {
            label: "Install Key Locker",
            name: "install_key_locker_tab",
            checked: false,
          },
          { label: "Permit Plan", name: "permit_plan_tab", checked: false },
        ];
      }
    }
    this.setState({ checkboxArray: checkBoxArr });
  }
  /* Home Tab End */

  /* Basic Tab Start */
  async getCityData(stateValue, countyValue, queryString = "") {
    var cityList = [];
    var cityListTotal = 0;
    let res = await CityService.getCity(
      "is_dropdown=1&state_slug=" + stateValue + "&county_slug=" + countyValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList =
      cityListTotal > 0
        ? cityList.map(({ slug, city_name }) => ({
            value: slug,
            label: city_name,
          }))
        : [];
    this.setState({
      cityList: cityList,
      newCityList: newCityList,
      cityListTotal: cityListTotal,
    });
  }

  async getUserList() {
    var accountManagerList = [];

    let res = await UserService.getUserDropdown(
      "roleTitle=OH_MANAGER&roleTitle=PROPERTY_MANAGER"
    );
    if (global.successStatus.includes(res.status)) {
      accountManagerList = res.data ? res.data : [];
    }
    const newAccountManagerList = accountManagerList.map(
      ({ slug, first_name, last_name }) => ({
        value: slug,
        label: first_name + " " + last_name,
      })
    );
    this.setState({
      newAccountManagerList: newAccountManagerList,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangePropertyManagerList(value) {
    let property_manager_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_manager_slug.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedPropertyManagerList: value,
      property_manager_slug: state_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeLockTypeList(value) {
    let lock_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        lock_type.push(item.value);
      });
    }
    this.setState({
      selectedLockTypeList: value,
      lock_type: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  async getPropertyData() {
    if (this.state.property_slug !== undefined) {
      var resData = {};
      var property_phone_number = "";
      var property_manager_phone_number = "";
      var todayDate = Moment(new Date()).format("YYYY-MM-DD");
      let res = await PropertyService.getProperty(this.state.property_slug);

      if (global.successStatus.includes(res.status)) {
        /* For All tabs start */
        sessionStorage.setItem(
          "property_slug",
          this.state.property_slug ? this.state.property_slug : ""
        );
        sessionStorage.setItem(
          "property_name",
          res.data.property_name ? res.data.property_name : ""
        );
        sessionStorage.setItem(
          "property_sku",
          res.data.aka_name ? res.data.aka_name : ""
        );
        sessionStorage.setItem(
          "property_type",
          res.data.property_type
            ? res.data.property_type.property_type_name
            : ""
        );
        sessionStorage.setItem(
          "year_value",
          res.data.year_value ? res.data.year_value : ""
        );
        sessionStorage.setItem(
          "property_logo_url",
          res.data.property_logo_url ? res.data.property_logo_url : propertyLogo
        );
        sessionStorage.setItem(
          "property_logo_thumb_url",
          res.data.property_logo_thumb_url
            ? res.data.property_logo_thumb_url
            : ""
        );

        localStorage.setItem("property_slug", this.state.property_slug);
        localStorage.setItem("property_name", res.data.property_name);
        localStorage.setItem("property_sku", res.data.aka_name);
        localStorage.setItem(
          "property_type",
          res.data.property_type.property_type_name
        );
        localStorage.setItem("year_value", res.data.year_value);
        localStorage.setItem("property_logo_url", res.data.property_logo_url);
        localStorage.setItem(
          "property_logo_thumb_url",
          res.data.property_logo_thumb_url
        );
        localStorage.setItem(
          "propertyLockType",
          res.data.lock_type ? res.data.lock_type : 0
        );
        if (
          res.data &&
          res.data.ownership_type &&
          res.data.ownership_type.slug
        ) {
          localStorage.setItem("ownership", res.data.ownership_type.slug);
        }
        this.props.propertyName(res.data.property_name);
        this.props.propertySlug(res.data.slug);
        this.props.propertyLogo(res.data.property_logo_thumb_url);
        this.props.propertySKU(res.data.aka_name);

        resData.property_name =
          res.data && res.data.property_name ? res.data.property_name : "";
        resData.aka_name =
          res.data && res.data.aka_name ? res.data.aka_name : "";
        /* For All tabs End */

        /* For Basic Tab Start */
        resData.property_manager_name = res.data.property_manager_name
          ? res.data.property_manager_name
          : "";
        resData.orionhaus_name = res.data.orionhaus_name
          ? res.data.orionhaus_name
          : "";
        resData.orionhaus_email = res.data.orionhaus_email
          ? res.data.orionhaus_email
          : "";
        resData.orionhaus_phone_number = res.data.orionhaus_phone_number
          ? res.data.orionhaus_phone_number
          : "";
        resData.property_phone_number = res.data.property_phone_number
          ? res.data.property_phone_number
          : "";
        resData.domain_name =
          res.data && res.data.domain_name ? res.data.domain_name : "";
        resData.street_1 =
          res.data && res.data.street_1 ? res.data.street_1 : "";

        resData.zip_code =
          res.data && res.data.zip_code ? res.data.zip_code : "";

        resData.brand_id = res.data.brand ? res.data.brand.slug : "";
        resData.ownership_type_slug =
          res.data && res.data.ownership_type_slug
            ? res.data.ownership_type_slug
            : "";
        resData.property_type_slug =
          res.data && res.data.property_type_slug
            ? res.data.property_type_slug
            : "";
        resData.lock_type =
          res.data && res.data.lock_type ? res.data.lock_type : "";
        resData.timezone_id = res.data.timezone ? res.data.timezone.slug : "";

        resData.status_id =
          res.data && res.data.status_id ? res.data.status_id : "";

        resData.state_id = res.data.state ? res.data.state.slug : "";
        resData.city_id = res.data.city ? res.data.city.slug : "";
        resData.country_id = res.data.country ? res.data.country.slug : "";

        resData.country_name = res.data.country
          ? res.data.country.country_name
          : "";

        resData.state_name = res.data.state ? res.data.state.state_name : "";
        resData.city = res.data.city ? res.data.city.city_name : "";

        var selectedCityList = [];
        selectedCityList = res.data.city
          ? [
              {
                value: res.data.city.slug,
                label: res.data.city.city_name,
              },
            ]
          : "";
        var selectedStateList = [];
        selectedStateList = res.data.state
          ? [
              {
                value: res.data.state.slug,
                label: res.data.state.state_name,
              },
            ]
          : "";

        var selectedCountryList = [];
        selectedCountryList = res.data.country
          ? [
              {
                value: res.data.country.slug,
                label: res.data.country.country_name,
              },
            ]
          : "";

        var selectedOwnershipTypeList = [];
        selectedOwnershipTypeList = res.data.ownership_type
          ? [
              {
                value: res.data.ownership_type.slug,
                label: res.data.ownership_type.ownership_type_name,
              },
            ]
          : "";

        var selectedPropertyManagerList = [];
        selectedPropertyManagerList = res.data.property_manager_info
          ? [
              {
                value: res.data.property_manager_info.slug,
                label:
                  res.data.property_manager_info.first_name +
                  " " +
                  res.data.property_manager_info.last_name,
              },
            ]
          : "";
        var selectedLockTypeList = [];
        selectedLockTypeList = res.data.lock_type
          ? [
              {
                value: res.data.lock_type === 1 ? 1 : 2,
                label: res.data.lock_type === 1 ? "KeyCafe" : "Latch",
              },
            ]
          : "";
        resData.property_type_id = res.data.property_type
          ? res.data.property_type.slug
          : "";
        resData.lease_inquiry_url = res.data.lease_inquiry_url
          ? res.data.lease_inquiry_url
          : "";
        resData.sort_order =
          res.data.sort_order === 0 ? "" : res.data.sort_order;
        resData.orion_haus_account_manager_slug =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.slug
            ? res.data.orion_haus_account_manager.slug
            : "";
        resData.insurance_required =
          res.data.insurance_required === 1 ? true : false;
        resData.international_property =
          res.data.international_property === 1 ? true : false;
        resData.allow_direct_booking =
          res.data.allow_direct_booking === 1 ? true : false;
        resData.appear_on_oh = res.data.appear_on_oh === 1 ? true : false;

        resData.pet_allowed = res.data.pet_allowed === 1 ? true : false;
        resData.children_friendly =
          res.data.children_friendly === 1 ? true : false;
        resData.infant_friendly = res.data.infant_friendly === 1 ? true : false;
        resData.affordable_component_check =
          res.data.affordable_component_check === 1 ? true : false;
        resData.suitability_name_data = res.data.suitability_name_data
          ? res.data.suitability_name_data
          : "";
        resData.latitude = res.data.latitude ? res.data.latitude : "";
        resData.longitude = res.data.longitude ? res.data.longitude : "";
        resData.google_map_link = res.data.google_map_link
          ? res.data.google_map_link
          : "";
        resData.location_type_name_data = res.data.location_type_name_data
          ? res.data.location_type_name_data
          : "";
        resData.pms_property_name = res.data.pms_property_name
          ? res.data.pms_property_name
          : "";
        resData.ownership_type_name = res.data.ownership_type
          ? res.data.ownership_type.ownership_type_name
          : "";
        resData.brand_name = res.data.brand ? res.data.brand.brand_name : "";
        resData.club_name = res.data.club ? res.data.club.club_name : "";

        resData.ada_compliant = res.data.ada_compliant
          ? res.data.ada_compliant
          : 2;
        resData.parking_type_id = res.data.parking_type
          ? res.data.parking_type.slug
          : "";
        resData.smoking_type_id = res.data.smoking_type
          ? res.data.smoking_type.slug
          : "";
        resData.leed_building_id = res.data.leed_building
          ? res.data.leed_building.slug
          : "";

        resData.communities_served_name_data = res.data
          .communities_served_name_data
          ? res.data.communities_served_name_data
          : "";
        resData.leed_building_name = res.data.leed_building
          ? res.data.leed_building.leed_building_name
          : "";
        resData.smoking_type_name = res.data.smoking_type
          ? res.data.smoking_type.smoking_type_name
          : "";
        resData.parking_type_name = res.data.parking_type
          ? res.data.parking_type.parking_type_name
          : "";
        resData.property_type_name = res.data.property_type
          ? res.data.property_type.property_type_name
          : "";
        resData.timezone_name = res.data.timezone
          ? res.data.timezone.timezone_name
          : "";
        resData.minimum_age_value = res.data.minimumAge
          ? res.data.minimumAge.name
          : "";
        resData.property_email = res.data.property_email
          ? res.data.property_email
          : "";

        resData.maintenance_email = res.data.maintenance_email
          ? res.data.maintenance_email
          : "";
        resData.invoice_email = res.data.invoice_email
          ? res.data.invoice_email
          : "";

        resData.check_in_time_view = res.data.check_in_time
          ? Moment(res.data.check_in_time, "hh:mm A").format("HH:mm:ss")
          : "";
        resData.check_in_time = res.data.check_in_time
          ? new Date(todayDate + " " + resData.check_in_time_view)
          : "";

        resData.check_out_time_view = res.data.check_out_time
          ? Moment(res.data.check_out_time, "hh:mm A").format("HH:mm:ss")
          : "";
        resData.check_out_time = res.data.check_out_time
          ? new Date(todayDate + " " + resData.check_out_time_view)
          : "";

        resData.digital_lock = res.data.digital_lock === 1 ? true : false;
        resData.minimum_age = res.data.minimum_age ? res.data.minimum_age : "";
        resData.total_affordable_unit = res.data.total_affordable_unit
          ? res.data.total_affordable_unit
          : "";
        resData.asset_managers = res.data.asset_managers
          ? res.data.asset_managers
          : "";
        resData.housekeeping_window_hour = res.data.housekeeping_window_hour
          ? res.data.housekeeping_window_hour
          : "";
        resData.imageUrl = res.data.property_logo_thumb_url
          ? res.data.property_logo_thumb_url
          : propertyLogo;
        resData.property_logo = res.data.property_logo
          ? res.data.property_logo
          : "";
        resData.imgPropUrl = res.data.property_main_image_url
          ? res.data.property_main_image_url
          : propertyImg;
        resData.year_value = res.data.year_value ? res.data.year_value : "";
        resData.brand_image = res.data.brand
          ? res.data.brand.brand_logo_url
          : brandsmallLogo;

        var selectedCommServedList = [];
        res.data.communitiesServedData.forEach((item, i) => {
          selectedCommServedList = [
            ...selectedCommServedList,
            {
              value: item.slug,
              label: item.communities_served_name,
            },
          ];
        });
        resData.property_manager_slug =
          res.data &&
          res.data.property_manager_info &&
          res.data.property_manager_info.slug
            ? res.data.property_manager_info.slug
            : "";
        /* For Basic Tab End */

        /* For Parking Tab Start */
        resData.parking_fee =
          res.data && res.data.parking_fee
            ? res.data.parking_fee.replace(/\.00$/, "")
            : "";
        resData.parking_instructions =
          res.data && res.data.parking_instructions
            ? res.data.parking_instructions
            : "";
        resData.parking_payment_instructions =
          res.data && res.data.parking_payment_instructions
            ? res.data.parking_payment_instructions
            : "";
        /* For Parking Tab Start */
      }
      this.setState({
        property_phone_number: property_phone_number,
        property_manager_phone_number: property_manager_phone_number,
        selectedCityList: selectedCityList,
        selectedStateList: selectedStateList,
        selectedCountryList: selectedCountryList,
        ownership_type_id: selectedOwnershipTypeList,
        selectedCommServedList: selectedCommServedList,
        selectedPropertyManagerList: selectedPropertyManagerList,
        selectedLockTypeList: selectedLockTypeList,
      });
      this.setState(resData);
    }
  }

  handleChangeCityList(value) {
    this.getAllRealetedData(value.value);
    let city_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_id.push(item.value);
      });
    }
    this.setState({
      selectedCityList: value,
      city_id: value.value,
    });
  }

  async getAllRealetedData(slug, queryString = "") {
    var resData = {};
    let res = await CityService.getDataByCitySlug(
      slug,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      resData.country_id = res.data.countryData
        ? res.data.countryData.country.slug
        : "";
      resData.country_name = res.data.countryData
        ? res.data.countryData.country.country_name
        : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.countryData.country
        ? [
            {
              value: res.data.countryData.country
                ? res.data.countryData.country.slug
                : "",
              label: res.data.countryData.country
                ? res.data.countryData.country.country_name
                : "",
            },
          ]
        : "";
      resData.county_id = res.data.county ? res.data.county.slug : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county
        ? [
            {
              value: res.data.county.slug ? res.data.county.slug : "",
              label: res.data.county.county_name
                ? res.data.county.county_name
                : "",
            },
          ]
        : "";
      resData.state_id = res.data.state ? res.data.state.slug : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      var selectedStateList = [];
      selectedStateList = res.data.state
        ? [
            {
              value: res.data.state.slug ? res.data.state.slug : "",
              label: res.data.state.state_name ? res.data.state.state_name : "",
            },
          ]
        : "";
      resData.country_name = res.data.countryData
        ? res.data.countryData.country.country_name
        : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
    }
    this.setState(resData);
    this.setState({
      selectedStateList: selectedStateList,
      selectedCountyList: selectedCountyList,
      selectedCountryList: selectedCountryList,
    });
  }

  saveOrUpdateProperty = async (e) => {
    this.setState({ showSpinner: true, isSubmit: true });
    if (this.validator.allValid() && this.customValidate()) {
      this.state.communities_served_id = this.state.selectedCommServedList
        ? this.state.selectedCommServedList.map(function (obj) {
            return obj.value;
          })
        : "";
      let multiCommServedString = this.state.communities_served_id
        ? this.state.communities_served_id.join(",")
        : "";

      let inputData = {
        tab: "basic",
        property_name: this.state.property_name ?? null,
        aka_name: this.state.aka_name ?? null,
        domain_name: this.state.domain_name ?? null,
        street_1: this.state.street_1 ?? null,
        city_slug: this.state.city_id ?? null,
        zip_code: this.state.zip_code ?? null,
        country_slug: this.state.country_id ?? null,
        state_slug: this.state.state_id ?? null,
        brand_slug: this.state.brand_id ?? null,
        ownership_type_slug: this.state.ownership_type_id ?? null,
        property_type_slug: this.state.property_type_id ?? null,
        lock_type: this.state.lock_type ?? null,
        timezone_slug: this.state.timezone_id ?? null,
        status_id: this.state.status_id ?? null,

        communities_served_slug: multiCommServedString,
        pms_property_name: this.state.pms_property_name,
        lease_inquiry_url: this.state.lease_inquiry_url,

        street_2: this.state.street_2,
        asset_managers: this.state.asset_managers,
        property_phone_number: this.state.property_phone_number,
        dial_code: this.state.dial_code,
        property_email: this.state.property_email,
        maintenance_email: this.state.maintenance_email,
        invoice_email: this.state.invoice_email,
        check_in_time: this.state.check_in_time
          ? Moment(this.state.check_in_time, "YYYY-MM-DD HH:mm:ss").format(
              "hh:mm A"
            )
          : "",
        check_out_time: this.state.check_out_time
          ? Moment(this.state.check_out_time, "YYYY-MM-DD HH:mm:ss").format(
              "hh:mm A"
            )
          : "",
        housekeeping_window_hour: this.state.housekeeping_window_hour
          ? this.state.housekeeping_window_hour
          : "",
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        google_map_link: this.state.google_map_link,
        insurance_required: this.state.insurance_required === true ? 1 : 0,
        international_property:
          this.state.international_property === true ? 1 : 2,
        allow_direct_booking: this.state.allow_direct_booking === true ? 1 : 0,
        appear_on_oh: this.state.appear_on_oh === true ? 1 : 0,
        year_value: this.state.year_value,
        leed_building_slug: this.state.leed_building_id,
        ada_compliant: this.state.ada_compliant,
        smoking_type_slug: this.state.smoking_type_id,
        parking_type_slug: this.state.parking_type_id,
        digital_lock: this.state.digital_lock === true ? 1 : 2,
        minimum_age: this.state.minimum_age ? this.state.minimum_age : "",
        affordable_financed: 0,
        affordable_component_check:
          this.state.affordable_component_check === true ? 1 : 0,
        total_affordable_unit: this.state.total_affordable_unit
          ? this.state.total_affordable_unit
          : "",
        pet_allowed: this.state.pet_allowed === true ? 1 : 0,
        children_friendly: this.state.children_friendly === true ? 1 : 0,
        infant_friendly: this.state.infant_friendly === true ? 1 : 0,
        property_logo: this.state.property_logo,
        sort_order: this.state.sort_order,
        orion_haus_account_manager_slug:
          this.state.orion_haus_account_manager_slug,
        property_manager_slug: this.state.property_manager_slug,
        property_manager_name: this.state.property_manager_name,
        orionhaus_name: this.state.orionhaus_name,
        orionhaus_email: this.state.orionhaus_email,
        orionhaus_phone_number: this.state.orionhaus_phone_number,
      };
      if (this.state.property_slug !== undefined) {
        let res = await PropertyService.updateProperty(
          this.state.property_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          sessionStorage.setItem(
            "property_slug",
            this.state.property_Slug ? this.state.property_Slug : ""
          );
          sessionStorage.setItem(
            "property_name",
            res.data.property_name ? res.data.property_name : ""
          );
          sessionStorage.setItem(
            "property_type",
            res.data.property_type
              ? res.data.property_type.property_type_name
              : ""
          );
          sessionStorage.setItem(
            "year_value",
            res.data.year_value ? res.data.year_value : ""
          );
          sessionStorage.setItem(
            "property_logo_url",
            res.data.property_logo_thumb_url
              ? res.data.property_logo_thumb_url
              : ""
          );
          localStorage.setItem("property_slug", this.state.property_slug);
          localStorage.setItem("property_name", res.data.property_name);
          localStorage.setItem("property_type", res.data.property_type);
          localStorage.setItem("year_value", res.data.year_value);
          localStorage.setItem("property_logo_url", res.data.property_logo_url);
          if (this.state.basicStep === 2) {
            document.getElementById("FilesTab").click();
          }
          setTimeout(() => {
            if (this.state.basicStep === 1) {
              this.setState({ basicStep: 2 });
            }
          }, 150);
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];

              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };
  /* Basic Tab End */

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.description !== undefined &&
      input.description !== "" &&
      input.description !== null &&
      input.description.length > 100000
    ) {
      isValid = false;
      errors["description"] =
        "The document description character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  propertyDocView(viewChange) {
    setTimeout(() => {
      this.setState({ wizardViewChange: viewChange });
    }, 100);
  }

  /* Parking Tab Start */
  fullTextRulesParkingInstruction() {
    if (this.state.descShowRulesParkingInstruction === 0) {
      this.setState({ descShowRulesParkingInstruction: 1 });
    } else {
      this.setState({ descShowRulesParkingInstruction: 0 });
    }
  }

  fullTextRulesParkingPayment() {
    if (this.state.descShowRulesParkingPayment === 0) {
      this.setState({ descShowRulesParkingPayment: 1 });
    } else {
      this.setState({ descShowRulesParkingPayment: 0 });
    }
  }

  setContentParkingPayment(value) {
    this.setState({
      parking_payment_instructions: value,
    });
  }

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: "Upload image",
      exec: async (editor) => {
        await this.imageUpload(editor);
      },
    };
  };

  imageUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", imageFile, imageFile.name);
      fileData.append("fileName", imageFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertImage(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editor.selection.insertNode(image);
  };

  uploadVideoButton = () => {
    Jodit.defaultOptions.controls.uploadImageVideo = {
      name: "Upload video",
      exec: async (editor) => {
        await this.videoUpload(editor);
      },
    };
  };

  videoUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = async function () {
      const videoFile = input.files[0];

      if (!videoFile) {
        return;
      }

      if (!videoFile.name.match(/\.(mp3|mp4|mov)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", videoFile, videoFile.name);
      fileData.append("fileName", videoFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertVideo(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertVideo = (editor, url) => {
    const video = editor.selection.j.createInside.element("video");
    video.setAttribute("src", url);
    editor.selection.insertNode(video);
  };

  setContentParkingInstruction(value) {
    this.setState({
      parking_instructions: value,
    });
  }

  saveOrUpdateParking = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        tab: "parking-instructions",
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        parking_instructions: this.state.parking_instructions,
        parking_payment_instructions: this.state.parking_payment_instructions,
        parking_fee: this.state.parking_fee,
      };
      if (this.state.property_slug !== undefined) {
        let res = await PropertyService.updateProperty(
          this.state.property_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
        this.setState({ pet_data: [] });
      }
      this.changeParkingView();
      this.getPropertyData();
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  // get cms content
  async getCmsContent() {
    var resData = {};
    let res = await BuildingOnboardingService.getCmsContent(this.state.property_slug);
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
      resData.building_onboarding_permit_plan =
        resData.building_onboarding_permit_plan
          ? resData.building_onboarding_permit_plan
          : "";
      resData.building_onboarding_lease_addendum =
        resData.building_onboarding_lease_addendum
          ? resData.building_onboarding_lease_addendum
          : "";
      resData.building_onboarding_staff_training =
        resData.building_onboarding_staff_training
          ? resData.building_onboarding_staff_training
          : "";
      resData.building_onboarding_announce_homesharing =
        resData.building_onboarding_announce_homesharing
          ? resData.building_onboarding_announce_homesharing
          : "";
      resData.building_onboarding_install_key_locker_latch =
        resData.building_onboarding_install_key_locker_latch
          ? resData.building_onboarding_install_key_locker_latch
          : "";
      resData.building_onboarding_install_key_locker_keycafe =
        resData.building_onboarding_install_key_locker_keycafe
          ? resData.building_onboarding_install_key_locker_keycafe
          : "";
      this.setState(resData);
    }
  }

  changeParkingView() {
    if (this.state.viewParkingMode === 1) {
      this.setState({ viewParkingMode: 0, wizardViewChange: 0 });
    } else {
      this.setState({ viewParkingMode: 1, wizardViewChange: 1 });
    }
  }
  /* Parking Tab End */

  /* Banking Info Tab Start */
  getPropertyStripeAccount = async () => {
    if (this.state.property_slug && this.state.property_slug !== undefined) {
      this.setState({ showSpinner: true, loading: true });
      let res = await StripePayoutService.getPropertyStripeStatus(
        this.state.property_slug
      );
      if (global.successStatus.includes(res.status)) {
        var stripeStatus = res.data ? res.data.property_stripe_status : 0;
        var stripeAccountLink = res.data ? res.data.property_stripe_link : "";
      }
      this.setState({
        showSpinner: false,
        loading: false,
        stripeStatus: stripeStatus,
        stripeAccountLink: stripeAccountLink,
      });
    }
  };

  getPropertyStripeW9Info = async () => {
    if (this.state.property_slug && this.state.property_slug !== undefined) {
      var resData = {};
      let res = await StripePayoutService.getPropertyStripeW9Info(
        this.state.property_slug
      );

      if (global.successStatus.includes(res.status)) {
        resData.business_name = res.data ? res.data.w9_resident_name : "";
        resData.business_street_1 = res.data ? res.data.street_1 : "";
        resData.business_street_2 = res.data ? res.data.street_2 : "";
        resData.business_zipcode = res.data ? res.data.zipcode : "";
        resData.business_country_name = res.data ? res.data.country_name : "";
        resData.business_city_name = res.data ? res.data.city_name : "";
        resData.business_county_name = res.data ? res.data.county_name : "";
        resData.business_state_name = res.data ? res.data.state_name : "";
        resData.business_email = res.data ? res.data.email : "";
        resData.stripe_account_id = res.data ? res.data.stripe_account_id : "";
        resData.bank_name = res.data ? res.data.bank_name : "";
        resData.last4 = res.data ? res.data.last4 : "";
        resData.ssn_number = res.data ? res.data.ssn_number : "";
        resData.ein_number = res.data ? res.data.ein_number : "";
        resData.disgraded_entity_name = res.data
          ? res.data.disgraded_entity_name
          : "";
        resData.external_object = res.data ? res.data.external_object : "";
        resData.exp_month = res.data ? res.data.exp_month : "";
        resData.exp_year = res.data ? res.data.exp_year : "";
        resData.external_brand = res.data ? res.data.external_brand : "";
        resData.business_phone_number =
          res.data && res.data.business_phone_number
            ? await this.phoneNumberAutoFormat(res.data.business_phone_number)
            : "";
        resData.business_signature_name = res.data
          ? res.data.signature_name
          : "";
        resData.business_signature_date = res.data
          ? Moment(res.data.signature_date).format("MM/DD/YY")
          : "";
      }
      this.setState(resData);
    }
  };

  stripeAccount = () => {
    if (this.state.is_stripe_setup === true) {
      this.setState({ is_stripe_setup: false });
    } else {
      this.setState({ is_stripe_setup: true });
    }
  };

  updateStripeAccount = () => {
    window.open(this.state.stripeAccountLink + this.state.stripe_account_id);
  };

  confirmStripeClose = () => {
    if (this.state.is_stripe_setup === true) {
      this.setState({ is_stripe_setup: false });
    } else {
      this.setState({ is_stripe_setup: true });
    }
  };

  createStripeAccount = async () => {
    let inputData = {
      property_slug: this.state.property_slug,
    };
    var queryString = "";
    if (this.props.match.params.propertySlug === undefined) {
      queryString = "is_global=1";
    }
    let res = await StripePayoutService.createPropertyStripeAccount(
      inputData,
      queryString
    );
    if (global.successStatus.includes(res.status)) {
      var stripe_url = res.data ? res.data.url : "";
      var stripe_account_id = res.data ? res.data.stripe_account_id : "";
      if (stripe_url) {
        if (stripe_account_id) {
          this.setState({
            stripeStatus: 1,
            stripe_account_id: stripe_account_id,
          });
        }
        setTimeout(() => window.open(stripe_url), global.redirect_time);
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ is_stripe_setup: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    return finalnumber;
  };
  /* Banking Info Tab End */

  /* CSV Import Tab Start */
  async getCSVData(queryString = "") {
    this.setState({
      showSpinner: true,
      loading: true,
      fileUploaderRefresh: false,
    });

    const inputData = {
      path: "property_document_form",
      refTableId: 3,
      slug: this.state.property_slug,
      mediaType: 2,
      docsSlug: this.state.prop_doc_slug,
      propertySlug: this.state.property_slug,
    };

    let unit_import = false;
    let rent_collection = false;
    let current_leases = false;
    let unit_import_data = [];
    let current_lease_data = [];
    let rent_collection_data = [];

    const res = await MultiImageService.getDocuments(inputData, queryString);

    if (global.successStatus.includes(res.status)) {
      const list = res.data ? res.data.data : [];
      if (list && list.length > 0) {
        for (const opt of list) {
          if (opt.document_slug === "I9L2XNG8BQ37") {
            unit_import_data.push({
              display_file_name: opt.display_file_name,
              file_name: opt.file_name,
              slug: opt.slug,
            });
            unit_import = opt.daily_delivery === 1 ? true : false;
          } else if (opt.document_slug === "W5FYC0RBJXL1") {
            current_lease_data.push({
              display_file_name: opt.display_file_name,
              file_name: opt.file_name,
              slug: opt.slug,
            });
            current_leases = opt.daily_delivery === 1 ? true : false;
          } else if (opt.document_slug === "VKJL2ZSQ7F0U") {
            rent_collection_data.push({
              display_file_name: opt.display_file_name,
              file_name: opt.file_name,
              slug: opt.slug,
            });
            rent_collection = opt.daily_delivery === 1 ? true : false;
          }
        }
      }
    }

    this.setState({
      unit_import,
      current_leases,
      rent_collection,
      unit_import_data,
      current_lease_data,
      rent_collection_data,
      showSpinner: false,
      loading: false,
    });
  }

  // Callback function to receive value from child
  onValidationChange = (value) => {
    setTimeout(() => {
      this.setState({ valueFromChild: value });
    }, 50);
  };

  previewFilesInParent = (value) => {};

  uploadCSV = async (e) => {
    // e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });

      await this.uploadFile(
        "unit_import_csv",
        "I9L2XNG8BQ37",
        this.state.unit_import_data,
        "unit_import_error",
        this.state.unit_import
      );
      await this.uploadFile(
        "current_leases_csv",
        "W5FYC0RBJXL1",
        this.state.current_lease_data,
        "current_lease_error",
        this.state.current_leases
      );
      await this.uploadFile(
        "rent_collection_csv",
        "VKJL2ZSQ7F0U",
        this.state.rent_collection_data,
        "rent_collection_error",
        this.state.rent_collection
      );
      this.setState({ showSpinner: false, isSubmit: false });
      // this.setState({
      //   showAlertModal: true,
      //   alertModalType: "success",
      //   alertModalTitle: "Success",
      //   alertModalMessage: "Success",
      // });
      this.setState({ fileUploaderRefresh: true });
      this.getCSVData();
      if (Object.keys(this.state.errors).length == 0) {
        document.getElementById("PermitPlanTab").click();
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
    this.setState({ selected_file_name: "" });
  };

  uploadFile = async (
    fileType,
    documentTypeSlug,
    fileData,
    errorKey,
    dailyDelivery
  ) => {
    if (!fileData || fileData.length === 0) {
      let errors = this.state.errors;
      const fileWidgetObj = new FileWidget();
      const output = await fileWidgetObj.fileUploadFun(fileType);
      if (output.status !== false) {
        let inputData = {
          display_file_name: "",
          document_type_slug: documentTypeSlug,
          file_name: "",
          description: "",
          media_type: 2,
          ref_id: this.state.property_slug || "",
          sort_order: "",
          status_id: 1,
          availableFiles: output.response,
          deletedFiles: this.state.deletedFileList,
          daily_delivery: dailyDelivery === true ? 1 : 0,
        };

        if (
          output.response[0].filesData &&
          output.response[0].filesData.length > 0
        ) {
          let res = await MultiImageService.submitMultiImage(inputData);
          if (global.successStatus.includes(res.status)) {
            return;
          } else if (res.data.result.errorDetail) {
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        } else {
          // errors[errorKey] = "Please select recommended file size.";
          // this.setState({ errors: errors });
        }
      } else {
        let checkError = output.response[0].validationMessage[0];
        if (checkError[0].includes("30 mb") === true) {
          errors[errorKey] = "The file should not be greater than 30 mb.";
        } else {
          errors[errorKey] = "The file should be xls, xlsx, csv.";
        }
        this.setState({ errors: errors });
      }
    }
  };

  openDeleteDocModal(slug) {
    this.setState({ deleteDocModal: true, delete_slug: slug });
  }

  closeDeleteDocModal() {
    this.setState({ deleteDocModal: false });
  }

  async deleteDoc(slug) {
    this.setState({ showSpinner: true, fileUploaderRefresh: true });
    let inputData = {
      path: "property_document_form",
      refTableId: 3,
      slug: this.state.property_slug,
      mediaType: 2,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.getCSVData();
      this.setState({ valueFromChild: 0 });
    } else {
      this.closeDeleteDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false, fileUploaderRefresh: false });
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
  }

  /* CSV Import Tab End */

  tabChanges(tabs) {
    setTimeout(() => {
      switch (tabs) {
        case "homeTab":
          document.getElementById("homeTab").click();
          this.setState({
            disabledBackButton: true,
            disabledNextButton: false,
          });
          break;
        case "BasicsTab":
          document.getElementById("BasicsTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "FilesTab":
          document.getElementById("FilesTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "AmenitiesTab":
          document.getElementById("AmenitiesTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "ParkingPlanTab":
          document.getElementById("ParkingPlanTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "BankingInfoTab":
          document.getElementById("BankingInfoTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "CSVImportTab":
          document.getElementById("CSVImportTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "PermitPlanTab":
          document.getElementById("PermitPlanTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "LeaseAddendumTab":
          document.getElementById("LeaseAddendumTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "StaffTrainingTab":
          document.getElementById("StaffTrainingTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "AnnounceHomesharingTab":
          document.getElementById("AnnounceHomesharingTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "InstallKeyLockerTab":
          document.getElementById("InstallKeyLockerTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: true,
          });
          break;
        default:
          document.getElementById("homeTab").click();
          this.setState({
            disabledBackButton: true,
            disabledNextButton: false,
          });
      }
    }, 100);
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "homeTab") {
      this.getHomeTabData();
      this.setState({
        buildingOnboardingTab: "homeTab",
        disabledBackButton: true,
        disabledNextButton: false,
      });
      setTimeout(() => {
        localStorage.setItem(
          "building_onboarding_tab_data",
          this.state.buildingOnboardingTab
            ? this.state.buildingOnboardingTab
            : ""
        );
      }, 5);
    } else if (e === "BasicsTab") {
      this.getPropertyData();
      this.setState({
        buildingOnboardingTab: "BasicsTab",
        disabledBackButton: false,
        disabledNextButton: false,
      });
      setTimeout(() => {
        localStorage.setItem(
          "building_onboarding_tab_data",
          this.state.buildingOnboardingTab
            ? this.state.buildingOnboardingTab
            : ""
        );
      }, 5);
    } else if (e === "FilesTab") {
      this.setState({
        buildingOnboardingTab: "FilesTab",
        disabledBackButton: false,
        disabledNextButton: false,
      });
      setTimeout(() => {
        localStorage.setItem(
          "building_onboarding_tab_data",
          this.state.buildingOnboardingTab
            ? this.state.buildingOnboardingTab
            : ""
        );
      }, 5);
    } else if (e === "AmenitiesTab") {
      this.setState({
        buildingOnboardingTab: "AmenitiesTab",
        disabledBackButton: false,
        disabledNextButton: false,
      });
      setTimeout(() => {
        localStorage.setItem(
          "building_onboarding_tab_data",
          this.state.buildingOnboardingTab
            ? this.state.buildingOnboardingTab
            : ""
        );
      }, 5);
    } else if (e === "ParkingPlanTab") {
      this.getPropertyData();
      this.setState({
        buildingOnboardingTab: "ParkingPlanTab",
        disabledBackButton: false,
        disabledNextButton: false,
      });
      setTimeout(() => {
        localStorage.setItem(
          "building_onboarding_tab_data",
          this.state.buildingOnboardingTab
            ? this.state.buildingOnboardingTab
            : ""
        );
      }, 5);
    } else if (e === "BankingInfoTab") {
      this.getPropertyStripeAccount();
      this.getPropertyStripeW9Info();
      this.setState({
        buildingOnboardingTab: "BankingInfoTab",
        disabledBackButton: false,
        disabledNextButton: false,
      });
      setTimeout(() => {
        localStorage.setItem(
          "building_onboarding_tab_data",
          this.state.buildingOnboardingTab
            ? this.state.buildingOnboardingTab
            : ""
        );
      }, 5);
    } else if (e === "CSVImportTab") {
      this.setState({
        buildingOnboardingTab: "CSVImportTab",
        disabledBackButton: false,
        disabledNextButton: false,
      });
      setTimeout(() => {
        localStorage.setItem(
          "building_onboarding_tab_data",
          this.state.buildingOnboardingTab
            ? this.state.buildingOnboardingTab
            : ""
        );
      }, 5);
    } else if (e === "PermitPlanTab") {
      this.setState({
        buildingOnboardingTab: "PermitPlanTab",
        disabledBackButton: false,
        disabledNextButton: false,
      });
      setTimeout(() => {
        localStorage.setItem(
          "building_onboarding_tab_data",
          this.state.buildingOnboardingTab
            ? this.state.buildingOnboardingTab
            : ""
        );
      }, 5);
    } else if (e === "LeaseAddendumTab") {
      this.setState({
        buildingOnboardingTab: "LeaseAddendumTab",
        disabledBackButton: false,
        disabledNextButton: false,
      });
      setTimeout(() => {
        localStorage.setItem(
          "building_onboarding_tab_data",
          this.state.buildingOnboardingTab
            ? this.state.buildingOnboardingTab
            : ""
        );
      }, 5);
    } else if (e === "StaffTrainingTab") {
      this.setState({
        buildingOnboardingTab: "StaffTrainingTab",
        disabledBackButton: false,
        disabledNextButton: false,
      });
      setTimeout(() => {
        localStorage.setItem(
          "building_onboarding_tab_data",
          this.state.buildingOnboardingTab
            ? this.state.buildingOnboardingTab
            : ""
        );
      }, 5);
    } else if (e === "AnnounceHomesharingTab") {
      this.setState({
        buildingOnboardingTab: "AnnounceHomesharingTab",
        disabledBackButton: false,
        disabledNextButton: false,
      });
      setTimeout(() => {
        localStorage.setItem(
          "building_onboarding_tab_data",
          this.state.buildingOnboardingTab
            ? this.state.buildingOnboardingTab
            : ""
        );
      }, 5);
    } else if (e === "InstallKeyLockerTab") {
      this.setState({
        buildingOnboardingTab: "InstallKeyLockerTab",
        disabledBackButton: false,
        disabledNextButton: true,
      });
      setTimeout(() => {
        localStorage.setItem(
          "building_onboarding_tab_data",
          this.state.buildingOnboardingTab
            ? this.state.buildingOnboardingTab
            : ""
        );
      }, 5);
    }
  }

  handleHomeClick(e) {
    setTimeout(() => {
      var homeTab = document.getElementById("homeTab");
      if (homeTab) {
        homeTab.click();
        this.setState({ disabledBackButton: true });
      }
    }, 100);
  }

  handleBackClick(e) {
    setTimeout(() => {
      switch (this.state.buildingOnboardingTab) {
        case "homeTab":
          this.setState({
            disabledBackButton: true,
            disabledNextButton: false,
          });
          // this.getHomeTabData();
          break;
        case "BasicsTab":
          if (this.state.basicStep === 1) {
            document.getElementById("homeTab").click();
            this.setState({
              disabledBackButton: true,
              disabledNextButton: false,
            });
          }
          setTimeout(() => {
            if (this.state.basicStep === 2) {
              this.setState({ basicStep: 1 });
            }
          }, 150);

          // this.getHomeTabData();
          break;
        case "FilesTab":
          this.setState({ basicStep: 2 });
          document.getElementById("BasicsTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "AmenitiesTab":
          document.getElementById("FilesTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "ParkingPlanTab":
          document.getElementById("AmenitiesTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "BankingInfoTab":
          document.getElementById("ParkingPlanTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "CSVImportTab":
          document.getElementById("BankingInfoTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "PermitPlanTab":
          document.getElementById("CSVImportTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
            valueFromChild: 0,
          });
          break;
        case "LeaseAddendumTab":
          document.getElementById("PermitPlanTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "StaffTrainingTab":
          document.getElementById("LeaseAddendumTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "AnnounceHomesharingTab":
          document.getElementById("StaffTrainingTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "InstallKeyLockerTab":
          document.getElementById("AnnounceHomesharingTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: true,
          });
          break;
        default:
          document.getElementById("homeTab").click();
          this.setState({
            disabledBackButton: true,
            disabledNextButton: false,
          });
      }
    }, 100);
  }

  handleNextClick(e) {
    setTimeout(() => {
      switch (this.state.buildingOnboardingTab) {
        case "homeTab":
          this.saveOrUpdateHomeTabs();
          // document.getElementById("BasicsTab").click();
          this.setState({
            disabledBackButton: true,
            disabledNextButton: false,
          });
          break;
        case "BasicsTab":
          this.saveOrUpdateProperty();
          // document.getElementById("FilesTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "FilesTab":
          document.getElementById("AmenitiesTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "AmenitiesTab":
          document.getElementById("ParkingPlanTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "ParkingPlanTab":
          document.getElementById("BankingInfoTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "BankingInfoTab":
          document.getElementById("CSVImportTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
            valueFromChild: 0,
          });
          break;
        case "CSVImportTab":
          this.uploadCSV();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "PermitPlanTab":
          document.getElementById("LeaseAddendumTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "LeaseAddendumTab":
          document.getElementById("StaffTrainingTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "StaffTrainingTab":
          document.getElementById("AnnounceHomesharingTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: false,
          });
          break;
        case "AnnounceHomesharingTab":
          document.getElementById("InstallKeyLockerTab").click();
          this.setState({
            disabledBackButton: false,
            disabledNextButton: true,
          });
          break;
        case "InstallKeyLockerTab":
          this.setState({
            disabledBackButton: false,
            disabledNextButton: true,
          });
          break;
        default:
          document.getElementById("homeTab").click();
          this.setState({
            disabledBackButton: true,
            disabledNextButton: false,
          });
      }
    }, 100);
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <React.Fragment>
        <main>
          <Container>
            <div className="scrolling-carousel scroll-slide onbording-pg">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="homeTab"
                className="pd-r-60"
                onSelect={(e) => this.getActiveClass(e)}
                eventKey={this.state.activeClass}
              >
                <ScrollingCarousel
                  rightArrow=""
                  leftArrow=""
                  className="swipesection pd-t-20 menu-onborad"
                >
                  <Nav.Item>
                    <Nav.Link
                      eventKey="homeTab"
                      id="homeTab"
                      className={
                        this.state.activeClass === "homeTab" ? "active" : ""
                      }
                      disabled={
                        this.state.wizardViewChange === 1 ? true : false
                      }
                    >
                      <img src={onbordhomeicon} alt="Home-Icon" />
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="BasicsTab"
                      id="BasicsTab"
                      className={
                        this.state.activeClass === "BasicsTab" ? "active" : ""
                      }
                      disabled={
                        this.state.wizardViewChange === 1 ? true : false
                      }
                    >
                      Basics
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="FilesTab"
                      id="FilesTab"
                      className={
                        this.state.activeClass === "FilesTab" ? "active" : ""
                      }
                      disabled={
                        this.state.wizardViewChange === 1 ? true : false
                      }
                    >
                      Media & Files
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="AmenitiesTab"
                      id="AmenitiesTab"
                      className={
                        this.state.activeClass === "AmenitiesTab"
                          ? "active"
                          : ""
                      }
                      disabled={
                        this.state.wizardViewChange === 1 ? true : false
                      }
                    >
                      Amenities
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="ParkingPlanTab"
                      id="ParkingPlanTab"
                      className={
                        this.state.activeClass === "ParkingPlanTab"
                          ? "active"
                          : ""
                      }
                      disabled={
                        this.state.wizardViewChange === 1 ? true : false
                      }
                    >
                      Parking Plan
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="BankingInfoTab"
                      id="BankingInfoTab"
                      className={
                        this.state.activeClass === "BankingInfoTab"
                          ? "active"
                          : ""
                      }
                      disabled={
                        this.state.wizardViewChange === 1 ? true : false
                      }
                    >
                      Banking Info
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="CSVImportTab"
                      id="CSVImportTab"
                      className={
                        this.state.activeClass === "CSVImportTab"
                          ? "active"
                          : ""
                      }
                      disabled={
                        this.state.wizardViewChange === 1 ? true : false
                      }
                    >
                      CSV Import
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="PermitPlanTab"
                      id="PermitPlanTab"
                      className={
                        this.state.activeClass === "PermitPlanTab"
                          ? "active"
                          : ""
                      }
                      disabled={
                        this.state.wizardViewChange === 1 ? true : false
                      }
                    >
                      Permit Plan
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="LeaseAddendumTab"
                      id="LeaseAddendumTab"
                      className={
                        this.state.activeClass === "LeaseAddendumTab"
                          ? "active"
                          : ""
                      }
                      disabled={
                        this.state.wizardViewChange === 1 ? true : false
                      }
                    >
                      Lease Addendum
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="StaffTrainingTab"
                      id="StaffTrainingTab"
                      className={
                        this.state.activeClass === "StaffTrainingTab"
                          ? "active"
                          : ""
                      }
                      disabled={
                        this.state.wizardViewChange === 1 ? true : false
                      }
                    >
                      Staff Training
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="AnnounceHomesharingTab"
                      id="AnnounceHomesharingTab"
                      className={
                        this.state.activeClass === "AnnounceHomesharingTab"
                          ? "active"
                          : ""
                      }
                      disabled={
                        this.state.wizardViewChange === 1 ? true : false
                      }
                    >
                      Announce Homesharing
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="InstallKeyLockerTab"
                      id="InstallKeyLockerTab"
                      className={
                        this.state.activeClass === "InstallKeyLockerTab"
                          ? "active"
                          : ""
                      }
                      disabled={
                        this.state.wizardViewChange === 1 ? true : false
                      }
                    >
                      Install Key Locker
                    </Nav.Link>
                  </Nav.Item>
                </ScrollingCarousel>
                <Tab.Content className="mg-b-100">
                  <Tab.Pane eventKey="homeTab">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                        {/*<Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        /> */}
                      </div>
                      <div className="mg-t-40 pd-md-l-30 pd-md-r-30 mainBox">
                        <div className="row row-xs mg-t-0 pd-t-2 cardsection">
                          <div className="col-md-5 col-lg-5 col-xl-4 mg-b-30">
                            <div className="overview-image m-auto text-center">
                              <figure>
                                <img
                                  src={onboradingImg}
                                  alt="pro-image"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                          <div className="col-md-7 col-lg-7 col-xl-8">
                            <div className="onborad_review">
                              <h3 className="mg-l-0 background-head">
                                Onboarding Overview
                              </h3>
                              <p>
                                This and the following page outline the steps
                                required to begin homesharing in your buildings.
                                There should be one point of contact from your
                                side managing the completion of each of these
                                steps, and that person can work directly with
                                the Orion Haus point of contact for any
                                questions along the way
                              </p>
                              <p>
                                There will be one scheduled recurring meeting
                                each week, which can be attended by all
                                stakeholders. These meetings will continue
                                throughout the onboarding process as well as
                                after launch to monitor the homesharing program
                                as it progresses in its live state.
                              </p>
                            </div>

                            <div className="mark_as_complete_box">
                              <div className="mark_as_lbox"></div>
                              <div className="step-process-info step-succes">
                                <label htmlFor="">Mark as Completed</label>
                              </div>
                            </div>

                            <div className="steps_count">
                              <div
                                className="step-way hand-cursor onboard_stap_way"
                                onClick={(e) => this.tabChanges("BasicsTab")}
                              >
                                <span>1.</span>Building Basics
                              </div>
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  onChange={(e) =>
                                    this.changeHomeCheckboxHandler(e)
                                  }
                                  name="basic_tab"
                                  checked={this.isCheckboxChecked("basic_tab")}
                                  // checked={this.state.homeCheckbox.basic_tab}
                                  type={"checkbox"}
                                  id="basic_tab"
                                  label={""}
                                  className={"ckbox"}
                                />
                              </div>
                            </div>
                            <div className="steps_count">
                              <div
                                className="step-way hand-cursor onboard_stap_way"
                                onClick={(e) => this.tabChanges("FilesTab")}
                              >
                                <span>2.</span>Media & Files
                              </div>
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  onChange={(e) =>
                                    this.changeHomeCheckboxHandler(e)
                                  }
                                  name="files_tab"
                                  // checked={this.state.homeCheckbox.files_tab}
                                  checked={this.isCheckboxChecked("files_tab")}
                                  type={"checkbox"}
                                  id="files_tab"
                                  label={""}
                                  className={"ckbox"}
                                />
                              </div>
                            </div>
                            <div className="steps_count">
                              <div
                                className="step-way hand-cursor onboard_stap_way"
                                onClick={(e) => this.tabChanges("AmenitiesTab")}
                              >
                                <span>3.</span>Amenities
                              </div>
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  onChange={(e) =>
                                    this.changeHomeCheckboxHandler(e)
                                  }
                                  name="amenities_tab"
                                  // checked={
                                  //   this.state.homeCheckbox.amenities_tab
                                  // }
                                  checked={this.isCheckboxChecked(
                                    "amenities_tab"
                                  )}
                                  type={"checkbox"}
                                  id="amenities_tab"
                                  label={""}
                                  className={"ckbox"}
                                />
                              </div>
                            </div>
                            <div className="steps_count">
                              <div
                                className="step-way hand-cursor onboard_stap_way"
                                onClick={(e) =>
                                  this.tabChanges("ParkingPlanTab")
                                }
                              >
                                <span>4.</span>Parking
                              </div>
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  onChange={(e) =>
                                    this.changeHomeCheckboxHandler(e)
                                  }
                                  name="parking_tab"
                                  // checked={this.state.homeCheckbox.parking_tab}
                                  checked={this.isCheckboxChecked(
                                    "parking_tab"
                                  )}
                                  type={"checkbox"}
                                  id="parking_tab"
                                  label={""}
                                  className={"ckbox"}
                                />
                              </div>
                            </div>
                            <div className="steps_count">
                              <div
                                className="step-way hand-cursor onboard_stap_way"
                                onClick={(e) =>
                                  this.tabChanges("BankingInfoTab")
                                }
                              >
                                <span>5.</span>Banking Information
                              </div>
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  onChange={(e) =>
                                    this.changeHomeCheckboxHandler(e)
                                  }
                                  name="banking_information"
                                  // checked={
                                  //   this.state.homeCheckbox.banking_information
                                  // }
                                  checked={this.isCheckboxChecked(
                                    "banking_information"
                                  )}
                                  type={"checkbox"}
                                  id="banking_information"
                                  label={""}
                                  className={"ckbox"}
                                />
                              </div>
                            </div>
                            <div className="steps_count">
                              <div
                                className="step-way hand-cursor onboard_stap_way"
                                onClick={(e) => this.tabChanges("CSVImportTab")}
                              >
                                <span>6.</span> CSV - Units Import, Current
                                Leases, Rent Collection
                              </div>
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  onChange={(e) =>
                                    this.changeHomeCheckboxHandler(e)
                                  }
                                  name="csv_tab"
                                  // checked={this.state.homeCheckbox.csv_tab}
                                  checked={this.isCheckboxChecked("csv_tab")}
                                  type={"checkbox"}
                                  id="csv_tab"
                                  label={""}
                                  className={"ckbox"}
                                />
                              </div>
                            </div>
                            <div className="steps_count">
                              <div
                                className="step-way hand-cursor onboard_stap_way"
                                onClick={(e) =>
                                  this.tabChanges("LeaseAddendumTab")
                                }
                              >
                                <span>7.</span>Lease Addendum
                              </div>
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  onChange={(e) =>
                                    this.changeHomeCheckboxHandler(e)
                                  }
                                  name="lease_tab"
                                  // checked={this.state.homeCheckbox.lease_tab}
                                  checked={this.isCheckboxChecked("lease_tab")}
                                  type={"checkbox"}
                                  id="lease_tab"
                                  label={""}
                                  className={"ckbox"}
                                />
                              </div>
                            </div>
                            <div className="steps_count">
                              <div
                                className="step-way hand-cursor onboard_stap_way"
                                onClick={(e) =>
                                  this.tabChanges("StaffTrainingTab")
                                }
                              >
                                <span>8.</span>Property Staff Training
                              </div>
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  onChange={(e) =>
                                    this.changeHomeCheckboxHandler(e)
                                  }
                                  name="property_staff_tab"
                                  // checked={
                                  //   this.state.homeCheckbox.property_staff_tab
                                  // }
                                  checked={this.isCheckboxChecked(
                                    "property_staff_tab"
                                  )}
                                  type={"checkbox"}
                                  id="property_staff_tab"
                                  label={""}
                                  className={"ckbox"}
                                />
                              </div>
                            </div>
                            <div className="steps_count">
                              <div
                                className="step-way hand-cursor onboard_stap_way"
                                onClick={(e) =>
                                  this.tabChanges("AnnounceHomesharingTab")
                                }
                              >
                                <span>9.</span>Announce Homesharing to Residents
                              </div>
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  onChange={(e) =>
                                    this.changeHomeCheckboxHandler(e)
                                  }
                                  name="announce_homesharing_tab"
                                  // checked={
                                  //   this.state.homeCheckbox
                                  //     .announce_homesharing_tab
                                  // }
                                  checked={this.isCheckboxChecked(
                                    "announce_homesharing_tab"
                                  )}
                                  type={"checkbox"}
                                  id="announce_homesharing_tab"
                                  label={""}
                                  className={"ckbox"}
                                />
                              </div>
                            </div>
                            <div className="steps_count">
                              <div
                                className="step-way hand-cursor onboard_stap_way"
                                onClick={(e) =>
                                  this.tabChanges("InstallKeyLockerTab")
                                }
                              >
                                <span>10.</span>Install Your Key Locker
                              </div>
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  onChange={(e) =>
                                    this.changeHomeCheckboxHandler(e)
                                  }
                                  name="install_key_locker_tab"
                                  // checked={
                                  //   this.state.homeCheckbox
                                  //     .install_key_locker_tab
                                  // }
                                  checked={this.isCheckboxChecked(
                                    "install_key_locker_tab"
                                  )}
                                  type={"checkbox"}
                                  id="install_key_locker_tab"
                                  label={""}
                                  className={"ckbox"}
                                />
                              </div>
                            </div>
                            <div className="steps_count">
                              <div
                                className="step-way hand-cursor onboard_stap_way"
                                onClick={(e) =>
                                  this.tabChanges("PermitPlanTab")
                                }
                              >
                                <span>11.</span>Finalize Permitting Plan
                              </div>
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  onChange={(e) =>
                                    this.changeHomeCheckboxHandler(e)
                                  }
                                  name="permit_plan_tab"
                                  // checked={
                                  //   this.state.homeCheckbox.permit_plan_tab
                                  // }
                                  checked={this.isCheckboxChecked(
                                    "permit_plan_tab"
                                  )}
                                  type={"checkbox"}
                                  id="permit_plan_tab"
                                  label={""}
                                  className={"ckbox"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="BasicsTab">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                        {/*  <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        /> */}
                      </div>
                      {this.state.basicStep === 1 ? (
                        <React.Fragment>
                          <div className="mg-t-40 mg-b-80 mainBox pd-md-l-15 pd-md-r-15">
                            <div className="row mx-0">
                              <div className="col-md-6 p-0">
                                <div className="row">
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-0 background-head">
                                      Basics
                                    </h3>
                                  </div>
                                </div>
                                <div className="row align-items-center">
                                  <div className="col-md-12">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        Property Name
                                      </label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="property_name"
                                        value={this.state.property_name}
                                        maxLength="50"
                                      />
                                      {this.state.errors.property_name ? (
                                        <div className="text-danger">
                                          {this.state.errors.property_name}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "name",
                                          this.state.property_name,
                                          "required|max:50",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12- mg-t-5 mg-md-t-0 pd-l-0 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-25  pd-xl-l-15 pd-lg-r-0 pd-xl-r-22">
                                      <label className="form-label text-left pd-b-5">
                                        Website
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="domain_name"
                                        value={this.state.domain_name}
                                        className="form-control max_width_100"
                                        id="inlineFormInput"
                                        type="text"
                                        autoComplete="off"
                                      />
                                      {this.state.errors.domain_name ? (
                                        <div className="text-danger">
                                          {this.state.errors.domain_name}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "website",
                                          this.state.domain_name,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div> */}
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        Lock Type
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedLockTypeList}
                                        options={this.state.lockTypeList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeLockTypeList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedLockTypeList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.errors.lock_type ? (
                                        <div className="text-danger">
                                          {this.state.errors.lock_type}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "lock_type",
                                          this.state.lock_type,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 p-0">
                                <div className="row">
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-0 background-head">
                                      Address
                                    </h3>
                                  </div>
                                </div>
                                <div className="row align-items-center">
                                  <div className="col-md-12">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        Street
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="street_1"
                                        value={this.state.street_1}
                                      />
                                      {this.state.errors.street_1 ? (
                                        <div className="text-danger">
                                          {this.state.errors.street_1}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "street",
                                          this.state.street_1,
                                          "required|max:100",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        City
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedCityList}
                                        options={this.state.newCityList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeCityList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedCityList
                                        }
                                        autoComplete="off"
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />

                                      {this.state.errors.city_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.city_id}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "city",
                                          this.state.city_id,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        State
                                      </label>
                                      <Form.Control
                                        type="text"
                                        className="custom-disable"
                                        value={this.state.state_name}
                                        disabled={true}
                                      />
                                      {/* {this.state.errors.state_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.state_id}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "state",
                                          this.state.state_id,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )} */}
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        Zip Code
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="zip_code"
                                        value={this.state.zip_code}
                                        maxLength="5"
                                        onKeyPress={
                                          global.onKeyPressEvent
                                            .numberValidation
                                        }
                                      />
                                      {this.state.errors.zip_code ? (
                                        <div className="text-danger">
                                          {this.state.errors.zip_code}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "zip_code",
                                          this.state.zip_code,
                                          "required|max:5",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        Country
                                      </label>
                                      <Form.Control
                                        type="text"
                                        className="custom-disable"
                                        value={this.state.country_name}
                                        disabled={true}
                                      />
                                      {/* {this.state.errors.country_id ? (
                                        <div className="text-danger">
                                          {this.state.errors.country_id}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "country",
                                          this.state.country_id,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      {this.state.basicStep === 2 ? (
                        <React.Fragment>
                          <div className="mg-t-40 mg-b-80 mainBox pd-md-l-15 pd-md-r-15">
                            <div className="row mx-0">
                              <div className="col-md-6 pd-xs-l-0 pd-xs-r-0">
                                <div className="row">
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-0 background-head">
                                      Property Contact
                                    </h3>
                                  </div>
                                </div>
                                <div className="row align-items-center">
                                  <div className="col-md-12">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        Property Manager
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="property_manager_name"
                                        value={this.state.property_manager_name}
                                        maxLength="50"
                                      />
                                      {/* {this.state.errors
                                        .property_manager_name ? (
                                        <div className="text-danger">
                                          {
                                            this.state.errors
                                              .property_manager_name
                                          }
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "property_manager_name",
                                          this.state.property_manager_name,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )} */}
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        Email
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="property_email"
                                        value={this.state.property_email}
                                        maxLength="50"
                                      />
                                      {this.state.errors.property_email ? (
                                        <div className="text-danger">
                                          {this.state.errors.property_email}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "property_email",
                                          this.state.property_email,
                                          "email",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        Phone
                                      </label>
                                      <PhoneInput
                                        country={global.country}
                                        disableSearchIcon
                                        // autoFormat= "true"
                                        enableSearch="true"
                                        countryCodeEditable="true"
                                        value={this.state.property_phone_number}
                                        onChange={(property_phone_number) =>
                                          this.setState({
                                            property_phone_number,
                                          })
                                        }
                                      />
                                      {this.state.errors
                                        .property_phone_number ? (
                                        <div className="text-danger">
                                          {
                                            this.state.errors
                                              .property_phone_number
                                          }
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 pd-xs-l-0 pd-xs-r-0">
                                <div className="row">
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                                    <h3 className="mg-l-0 background-head">
                                      Orion Haus Contact
                                    </h3>
                                  </div>
                                </div>
                                <div className="row align-items-center">
                                  <div className="col-md-12">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      {/* <p className="az-profile-name-text pl-0">
                                        Name
                                      </p>
                                      <p className="media-body">
                                        {this.state.orionhaus_name
                                          ? this.state.orionhaus_name
                                          : ""}
                                      </p> */}
                                      <label className="form-label text-left pd-b-5">
                                        Name
                                      </label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="orionhaus_name"
                                        value={this.state.orionhaus_name}
                                        maxLength="50"
                                        disabled={true}
                                      />
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        Email
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="orionhaus_email"
                                        value={this.state.orionhaus_email}
                                        maxLength="50"
                                        disabled={true}
                                      />
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        Phone
                                      </label>
                                      <PhoneInput
                                        country={global.country}
                                        disableSearchIcon
                                        disabled={true}
                                        enableSearch="true"
                                        countryCodeEditable="true"
                                        value={
                                          this.state.orionhaus_phone_number
                                        }
                                        onChange={(orionhaus_phone_number) =>
                                          this.setState({
                                            orionhaus_phone_number,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane className="onbording_file" eventKey="FilesTab">
                    <PropertyDocuments
                      property_slug={this.state.property_slug}
                      buildingOnboardingTab={1}
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                      propertyDocView={this.propertyDocView}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="AmenitiesTab">
                    <Amenities
                      property_slug={this.state.property_slug}
                      buildingOnboardingTab={1}
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                      propertyDocView={this.propertyDocView}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="ParkingPlanTab">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                        {/* <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        /> */}
                      </div>
                      <div className="mg-t-20 pd-md-l-15 pd-md-r-15 mainBox">
                        <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center mg-r-15">
                          {this.state.viewParkingMode === 0 ? (
                            <button
                              className="btn-new-cust"
                              onClick={this.changeParkingView}
                            >
                              Edit
                            </button>
                          ) : (
                            <React.Fragment>
                              <button
                                className="btn-new-cust-o mg-r-20"
                                onClick={this.changeParkingView}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn-new-cust"
                                onClick={this.saveOrUpdateParking}
                              >
                                Save
                              </button>
                            </React.Fragment>
                          )}
                        </div>
                        <div className="col-md-12 pd-xs-l-0 pd-xs-r-0 pd-md-l-15 pd-md-r-15">
                          <div className="onborad_review mg-xs-t-15 mt-0">
                            <h3 className="mg-l-0 background-head">
                              Parking Plan
                            </h3>
                            {/* <p>
                              Guests will be directed to park at the side lot by
                              the bank, and if that is full they will be
                              directed to enter the parking garage and park in
                              an unnumbered spot on a certain floor.
                            </p>
                            <p>
                              Parking access cards will hopefully be added to
                              the resident's guest key sets.
                            </p> */}
                          </div>
                        </div>
                        {this.state.viewParkingMode === 0 ? (
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-12 pd-xs-l-0 pd-xs-r-0 pd-md-l-15 pd-md-r-15">
                                <div className="col-md-6 mg-t-40 mg-md-t-0 p-0">
                                  <div className="col-md-12 p-0">
                                    <p className="az-profile-name-text pl-0">
                                      Fees
                                    </p>
                                    <p className="media-body">
                                      {this.state.parking_fee ? (
                                        <NumberFormat
                                          value={global.onKeyPressEvent.numberWithCommasForDecimal(
                                            parseFloat(this.state.parking_fee)
                                              .toFixed(2)
                                              .replace(/\.00$/, "")
                                              .replace(
                                                /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                ""
                                              )
                                          )}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                </div>

                                <div className="col-md-12 mg-t-40 mg-md-t-0 p-0 ">
                                  <div className="col-md-12 p-0">
                                    <p className="az-profile-name-text pl-0">
                                      Parking Instructions:
                                    </p>
                                    <div className="para">
                                      {this.state.parking_instructions ? ( //check if property_rules is exist
                                        this.state.parking_instructions.length >
                                        global.showMoreLength ? ( //check if length is long
                                          this.state
                                            .descShowRulesParkingInstruction ===
                                          0 ? (
                                            <>
                                              <HTMLEllipsis
                                                unsafeHTML={
                                                  this.state
                                                    .parking_instructions
                                                    ? this.state
                                                        .parking_instructions
                                                    : ""
                                                }
                                                maxLine="6"
                                                ellipsis=""
                                                trimright="true"
                                                basedOn="letters"
                                              />
                                              <p
                                                className="hand-cursor showmore"
                                                onClick={
                                                  this
                                                    .fullTextRulesParkingInstruction
                                                }
                                              >
                                                Show More
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              {renderHTML(
                                                "" +
                                                  this.state
                                                    .parking_instructions
                                              )}
                                              <p
                                                className="hand-cursor showmore"
                                                onClick={
                                                  this
                                                    .fullTextRulesParkingInstruction
                                                }
                                              >
                                                Show Less
                                              </p>
                                            </>
                                          )
                                        ) : (
                                          renderHTML(
                                            "" + this.state.parking_instructions
                                          )
                                        )
                                      ) : (
                                        <NoData
                                          msg="Parking Instructions"
                                          action=" Edit "
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mg-t-40 mg-md-t-0 p-0 ">
                                  <div className="col-md-12 pd-lg-l-15 p-0">
                                    <p className="az-profile-name-text pl-0">
                                      Payment Instructions:
                                    </p>
                                    <div className="para">
                                      {this.state
                                        .parking_payment_instructions ? ( //check if property_rules is exist
                                        this.state.parking_payment_instructions
                                          .length > global.showMoreLength ? ( //check if length is long
                                          this.state
                                            .descShowRulesParkingPayment ===
                                          0 ? (
                                            <>
                                              <HTMLEllipsis
                                                unsafeHTML={
                                                  this.state
                                                    .parking_payment_instructions
                                                    ? this.state
                                                        .parking_payment_instructions
                                                    : ""
                                                }
                                                maxLine="6"
                                                ellipsis=""
                                                trimright="true"
                                                basedOn="letters"
                                              />
                                              <p
                                                className="hand-cursor showmore"
                                                onClick={
                                                  this
                                                    .fullTextRulesParkingPayment
                                                }
                                              >
                                                Show More
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              {renderHTML(
                                                "" +
                                                  this.state
                                                    .parking_payment_instructions
                                              )}
                                              <p
                                                className="hand-cursor showmore"
                                                onClick={
                                                  this
                                                    .fullTextRulesParkingPayment
                                                }
                                              >
                                                Show Less
                                              </p>
                                            </>
                                          )
                                        ) : (
                                          renderHTML(
                                            "" +
                                              this.state
                                                .parking_payment_instructions
                                          )
                                        )
                                      ) : (
                                        <NoData
                                          msg="Payment Instructions"
                                          action=" Edit "
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="col-md-6 pd-xs-l-0 pd-xs-r-0 pd-md-l-20 pd-md-r-20">
                                <div className="overview-image m-auto text-center">
                                  <figure>
                                    <img
                                      src={parkingplan}
                                      alt="parking-plan"
                                      className="img-fluid"
                                    />
                                  </figure>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        ) : (
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-12 pd-xs-l-0 pd-xs-r-0 pd-md-l-15 pd-md-r-15">
                                <div className="">
                                  <div className="mg-b-20">
                                    <div className="col-md-4 p-0">
                                      <InputGroup className="mt-3">
                                        <label className="form-label text-left pd-b-5 w-100">
                                          Fees
                                        </label>
                                        <div className="input-group-prepend prefix-dollar w-100">
                                          <span className="input-group-text">
                                            $
                                          </span>
                                          <Form.Control
                                            onChange={this.changeHandler}
                                            name="parking_fee"
                                            value={this.state.parking_fee}
                                            className="form-control max_width_100 w-100"
                                            id="parking_fee"
                                          />
                                        </div>
                                      </InputGroup>
                                      {this.state.errors.parking_fee ? (
                                        <div className="text-danger">
                                          {this.state.errors.parking_fee}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "parking_fee",
                                          this.state.parking_fee,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                  </div>

                                  <div className="">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 mg-b-15 ">
                                      <label className="form-label text-left pd-b-5">
                                        Parking Instructions: [Unit-Number]
                                        [Parking-Spot]
                                      </label>

                                      <JoditEditor
                                        ref={null}
                                        config={this.editorConfig}
                                        name="parking_instructions"
                                        id="parking_instructions"
                                        value={this.state.parking_instructions}
                                        onBlur={(newContent) =>
                                          this.setContentParkingInstruction(
                                            newContent
                                          )
                                        }
                                        onChange={(newContent) =>
                                          this.setContentParkingInstruction(
                                            newContent
                                          )
                                        }
                                      />

                                      {this.state.errors
                                        .parking_instructions ? (
                                        <div className="text-danger">
                                          {
                                            this.state.errors
                                              .parking_instructions
                                          }
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "parking_instructions",
                                          this.state.parking_instructions,
                                          "max:100000",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <div className="">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 mg-b-15 ">
                                      <label className="form-label text-left pd-b-5">
                                        Payment Instructions:
                                      </label>

                                      <JoditEditor
                                        ref={null}
                                        config={this.editorConfig}
                                        name="parking_payment_instructions"
                                        id="parking_payment_instructions"
                                        value={
                                          this.state
                                            .parking_payment_instructions
                                        }
                                        onBlur={(newContent) =>
                                          this.setContentParkingPayment(
                                            newContent
                                          )
                                        }
                                        onChange={(newContent) =>
                                          this.setContentParkingPayment(
                                            newContent
                                          )
                                        }
                                      />

                                      {this.state.errors
                                        .parking_payment_instructions ? (
                                        <div className="text-danger">
                                          {
                                            this.state.errors
                                              .parking_payment_instructions
                                          }
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "parking_payment_instructions",
                                          this.state
                                            .parking_payment_instructions,
                                          "max:100000",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-md-6 pd-xs-l-0 pd-xs-r-0 pd-md-l-20 pd-md-r-20">
                                <div className="overview-image m-auto text-center">
                                  <figure>
                                    <img
                                      src={parkingplan}
                                      alt="parking-plan-image"
                                      className="img-fluid"
                                    />
                                  </figure>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="BankingInfoTab">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                        {/* <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        /> */}
                      </div>
                      <div className="mg-t-40 pd-md-l-30 pd-md-r-30 row mainBox">
                        {this.state.stripeStatus === 0 ? (
                          <React.Fragment>
                            <div className="col-md-6">
                              <div className="onborad_review">
                                <h3 className="mg-l-0 background-head">
                                  Banking Information
                                </h3>
                                {this.state.stripeStatus === 0 ? (
                                  <React.Fragment>
                                    <ul className="onbaord_bank_info list-unstyled p-0">
                                      <li
                                        className="hand-cursor link-url"
                                        onClick={this.stripeAccount}
                                      >
                                        Click here to set up your banking
                                        information on Stripe
                                      </li>
                                    </ul>

                                    <p>
                                      To receive payments from Orion Haus,
                                      please set up your Stripe account for
                                      payouts. Begin by creating an account on
                                      the Stripe website and completing the
                                      necessary verification steps. Once
                                      verified, access your payout settings
                                      within your Stripe dashboard and add your
                                      bank account details accurately. If
                                      required, complete any additional identity
                                      verification steps and submit a W-9 form
                                      for U.S. taxpayers. After reviewing and
                                      confirming all information, you'll be
                                      ready to start receiving payments directly
                                      into your bank account according to Orion
                                      Haus's payout schedule.
                                    </p>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="overview-image m-auto text-center">
                                <figure>
                                  <img
                                    src={bankinfoimg}
                                    alt="pro"
                                    className="img-fluid"
                                  />
                                </figure>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                        {this.state.stripeStatus === 1 ? (
                          <React.Fragment>
                            <div className="col-md-6 pd-md-l-30">
                              <div className="onborad_review">
                                <h3 className="mg-l-0 background-head">
                                  Banking Information
                                </h3>
                                <React.Fragment>
                                  <p>Stripe account setup is in progress.</p>
                                  <p>
                                    To receive payments from Orion Haus, please
                                    set up your Stripe account for payouts.
                                    Begin by creating an account on the Stripe
                                    website and completing the necessary
                                    verification steps. Once verified, access
                                    your payout settings within your Stripe
                                    dashboard and add your bank account details
                                    accurately. If required, complete any
                                    additional identity verification steps and
                                    submit a W-9 form for U.S. taxpayers. After
                                    reviewing and confirming all information,
                                    you'll be ready to start receiving payments
                                    directly into your bank account according to
                                    Orion Haus's payout schedule.
                                  </p>
                                </React.Fragment>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="overview-image m-auto text-center">
                                <figure>
                                  <img
                                    src={bankinfoimg}
                                    alt="pro"
                                    className="img-fluid"
                                  />
                                </figure>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                        {this.state.stripeStatus === 2 ? (
                          <React.Fragment>
                            <div className="col-md-6">
                              <div className="onborad_review">
                                <h3 className="mg-l-0 background-head">
                                  Banking Information
                                </h3>
                                <React.Fragment>
                                  <div className="row pd-b-5 border-bottom-0 mg-t-15 px-0">
                                    {this.state.external_object ===
                                    "bank_account" ? (
                                      <React.Fragment>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                          <p className="az-profile-name-text pl-0">
                                            Bank Name
                                          </p>
                                          <p className="media-body">
                                            {this.state.bank_name
                                              ? this.state.bank_name
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                          <p className="az-profile-name-text pl-0">
                                            Bank Account Number
                                          </p>
                                          <p className="media-body">
                                            {this.state.last4
                                              ? "************" +
                                                this.state.last4
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="col-12">
                                          {this.state.stripeAccountLink !==
                                            "" &&
                                          this.state.stripe_account_id !==
                                            "" ? (
                                            <ul className="onbaord_bank_info list-unstyled p-0">
                                              <li
                                                className="hand-cursor link-url"
                                                onClick={
                                                  this.updateStripeAccount
                                                }
                                              >
                                                Click here to update your
                                                banking information on Stripe
                                              </li>
                                            </ul>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.external_object === "card" ? (
                                      <React.Fragment>
                                        {" "}
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                          <p className="az-profile-name-text pl-0">
                                            Brand
                                          </p>
                                          <p className="media-body">
                                            {this.state.external_brand
                                              ? this.state.external_brand
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                          <p className="az-profile-name-text pl-0">
                                            Card Number
                                          </p>
                                          <p className="media-body">
                                            {this.state.last4
                                              ? "**********" + this.state.last4
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                          <p className="az-profile-name-text pl-0">
                                            Expiry Month
                                          </p>
                                          <p className="media-body">
                                            {this.state.exp_month
                                              ? this.state.exp_month
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                          <p className="az-profile-name-text pl-0">
                                            Expiry Year
                                          </p>
                                          <p className="media-body">
                                            {this.state.exp_year
                                              ? this.state.exp_year
                                              : ""}
                                          </p>
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                    {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Stripe Account ID
                                    </p>
                                    <p className="media-body">
                                      {this.state.stripe_account_id
                                        ? this.state.stripe_account_id
                                        : ""}
                                    </p>
                                  </div> */}
                                  </div>
                                </React.Fragment>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="onborad_review">
                                <h3 className="mg-l-0 background-head">
                                  W9 Info
                                </h3>
                                <div className="row pd-b-5 border-bottom-0 mg-t-15 px-0">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      <label className="form-label text-left pd-b-5">
                                        Name or Business Name
                                        <span className="optional">
                                          (if different)
                                        </span>
                                      </label>
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_name
                                        ? this.state.business_name
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      SSN
                                    </p>
                                    <p className="media-body">
                                      {this.state.ssn_number
                                        ? this.state.ssn_number
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      EIN
                                    </p>
                                    <p className="media-body">
                                      {this.state.ein_number
                                        ? this.state.ein_number
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Disregarded Entity Name
                                    </p>
                                    <p className="media-body">
                                      {this.state.disgraded_entity_name
                                        ? this.state.disgraded_entity_name
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Email
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_email
                                        ? this.state.business_email
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Phone number
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_phone_number
                                        ? this.state.business_phone_number
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Street Address
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_street_1
                                        ? this.state.business_street_1
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Apt. no./House no.
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_street_2
                                        ? this.state.business_street_2
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      City
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_city_name
                                        ? this.state.business_city_name
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Zip Code
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_zipcode
                                        ? this.state.business_zipcode
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      State
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_state_name
                                        ? this.state.business_state_name
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Country
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_country_name
                                        ? this.state.business_country_name
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Signed Date
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_signature_date
                                        ? this.state.business_signature_date
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Your Signature
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_signature_name
                                        ? this.state.business_signature_name
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="CSVImportTab" className="CSVImportTab">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                        {/* <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        /> */}
                      </div>
                      <div className="mg-t-40 pd-md-l-30 pd-md-r-30 row mainBox">
                        <div className="col-md-12">
                          <div className="onborad_review">
                            <h3 className="mg-l-0 background-head">
                              CSV Import
                            </h3>
                            <p>
                              Orion Haus needs to receive CSV data from your PMS
                              to tell our app which residents live in which
                              apartments, if those residents have paid their
                              rent, and which apartments are available for lease
                              up.
                            </p>
                            {/* <p>
                              Program these 3 CSV files for regular sending to
                              tech@orionhaus.com
                            </p> */}
                          </div>
                        </div>
                      </div>

                      <div className="mg-md-t-0 mg-lg-t-20 pd-md-l-30 pd-md-r-30 row mainBox">
                        <div className="col-md-12">
                          <div className="row lease_card_row mb-3">
                            <div className="col-md-6 col-lg-4">
                              <div className="onbord_lease_card pd-r-50">
                                <div className="onborad_review mb-2">
                                  <h3 className="mg-l-0 background-head">
                                    Import Units
                                  </h3>
                                  <b className="instuction_title">
                                    Instructions:
                                  </b>
                                  <p className="mb-0">
                                    This CSV will provide our system the initial
                                    set-up of your property, giving us
                                    information on the units and floor plans.
                                  </p>
                                </div>

                                <ul className="onbaord_bank_info">
                                  <li>
                                    Send cadence: one-time for property set-up
                                  </li>
                                  <li>
                                    Note: If you want to restrict homesharing to
                                    certain units, only include those units in
                                    this report.
                                  </li>
                                </ul>

                                <Link
                                  to="/import_sample/unit.csv"
                                  target="_blank"
                                  download="unit.csv"
                                  className="link-url mg-b-10"
                                >
                                  Download sample template for Import
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                              <div className="onbord_lease_card pd-r-50">
                                <div className="onborad_review mb-2">
                                  <h3 className="mg-l-0 background-head">
                                    Import current leases
                                  </h3>
                                  <b className="instuction_title">
                                    Instructions:
                                  </b>
                                  <p className="mb-0">
                                    This CSV will provide our system with an
                                    ongoing list of who is living in which
                                    units, who will be moving in (signed, future
                                    leases), and providing our system the
                                    ability to market your upcoming lease
                                    availabilities.
                                  </p>
                                </div>

                                <ul className="onbaord_bank_info">
                                  <li>Send cadence: daily</li>
                                  <li>
                                    Note: If you want to restrict homesharing,
                                    do not include them in this report.
                                  </li>
                                  <li>
                                    Note: If you want to restrict homesharing to
                                    certain units, only include those units in
                                    this report.
                                  </li>
                                </ul>

                                <Link
                                  to="/import_sample/lease.csv"
                                  target="_blank"
                                  download="lease.csv"
                                  className="link-url mg-b-10"
                                >
                                  Download sample template for Import
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                              <div className="onbord_lease_card pd-r-50">
                                <div className="onborad_review mb-2">
                                  <h3 className="mg-l-0 background-head">
                                    Import Rent collection
                                  </h3>
                                  <b className="instuction_title">
                                    Instructions:
                                  </b>
                                  <p className="mb-0">
                                    This CSV will let us know if your residents
                                    have paid their rent, and if so, then they
                                    are eligible to receive their homesharing
                                    payout. If residents have not paid their
                                    rent, their homesharing payout is withheld
                                    until they do. This CSV is not required, but
                                    recommended.
                                  </p>
                                </div>

                                <ul className="onbaord_bank_info">
                                  <li>Send cadence: daily</li>
                                </ul>

                                <Link
                                  to="/import_sample/rent.csv"
                                  target="_blank"
                                  download="rent.csv"
                                  className="link-url mg-b-10"
                                >
                                  Download sample template for Import
                                </Link>
                              </div>
                            </div>
                            {/* <button
                              className="btn btn-cust-small"
                              onClick={this.uploadCSV}
                            >
                              Save
                            </button> */}
                          </div>

                          <div className="row lease_card_row">
                            <div className="col-md-6 col-lg-4">
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  name="unit_import"
                                  type={"checkbox"}
                                  id={`unit_import`}
                                  label={"Programmed for Daily delivery"}
                                  className={"ckbox"}
                                  onChange={this.changeHandler}
                                  checked={this.state.unit_import}
                                />
                              </div>
                              {this.state.fileUploaderRefresh === false && (
                                <form id="unit_import_csv" className="pd-r-50">
                                  <div className="d-flex flex-row align-items-center upload upload-files-section">
                                    <FileWidget
                                      fieldName="file_name"
                                      fieldTitle="Select File"
                                      checkValidation="true"
                                      allowedType="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                                      maxFileSize={30}
                                      maxFileSizeType="mb"
                                      maxFileAllowed={10}
                                      uploadOn="s3"
                                      slug={this.state.property_slug}
                                      refTableId="3"
                                      displayFileName=""
                                      documentTypeSlug="I9L2XNG8BQ37"
                                      mediaType="2"
                                      dailyDelivery={
                                        this.state.unit_import === true ? 1 : 0
                                      }
                                      sortOrder=""
                                      statusId=""
                                      helpText="to download sample file."
                                      sampleFileUrl="/sample.jpg"
                                      drag="true"
                                      dragText="Select or drop file size upto 30 MB"
                                      inputClassName="form-control"
                                      labelClassName="form-label"
                                      formID="unit_import_csv"
                                      uploadType="single"
                                      resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
                                      propertySlug={this.state.property_slug}
                                      destinationFolder="property_document_form"
                                      uploadedFiles={this.state.profile_image2}
                                      deletedFiles={this.state.deletedFileList}
                                      validationFire={
                                        this.state.unit_import_data &&
                                        this.state.unit_import_data.length > 0
                                          ? "unit_import"
                                          : 0
                                      }
                                      acceptOnly="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                                      displayPreviewStyle="horizontal"
                                      onValidationChange={
                                        this.onValidationChange
                                      }
                                      previewFilesInParent={
                                        this.previewFilesInParent
                                      }
                                    />
                                  </div>
                                  {this.state.valueFromChild ===
                                  "unit_import_validation" ? (
                                    <div className="text-danger">
                                      File has already been added. Please delete
                                      the file before proceeding with the new
                                      file.
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.errors.unit_import_error ? (
                                    <div className="text-danger">
                                      {this.state.errors.unit_import_error}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="onbording_csv_uploadedfile">
                                    <div className="container px-0">
                                      <div className="">
                                        {this.state.unit_import_data &&
                                          this.state.unit_import_data.length >
                                            0 &&
                                          this.state.unit_import_data.map(
                                            (val, key) => {
                                              return (
                                                <React.Fragment key={key}>
                                                  <div className="">
                                                    <div className="d-flex align-items-center">
                                                      <img
                                                        className="onbord_file_preview_icon mg-r-5"
                                                        src={
                                                          val.file_name.match(
                                                            /.(jpg|jpeg|png|gif)$/i
                                                          )
                                                            ? val.file_name
                                                            : val.file_name.match(
                                                                /.(pdf)$/i
                                                              )
                                                            ? pdfIcon
                                                            : val.file_name.match(
                                                                /.(docx|doc)$/i
                                                              )
                                                            ? wordIcon
                                                            : val.file_name.match(
                                                                /.(xlsx|csv|xls)$/i
                                                              )
                                                            ? excelIcon
                                                            : formatNotSupported
                                                        }
                                                        alt={
                                                          val.display_file_name
                                                        }
                                                      />
                                                      <p className="title m-0 w-100">
                                                        <strong>
                                                          {
                                                            val.display_file_name
                                                          }
                                                        </strong>
                                                      </p>
                                                      <div
                                                        data-fileid={key}
                                                        onClick={() =>
                                                          this.openDeleteDocModal(
                                                            val.slug
                                                          )
                                                        }
                                                      >
                                                        <span aria-hidden="true">
                                                          <img
                                                            src={deleteicon}
                                                            className="hand-cursor"
                                                            aria-hidden="true"
                                                          />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              )}
                            </div>

                            <div className="col-md-6 col-lg-4">
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  name="current_leases"
                                  type={"checkbox"}
                                  id={`current_leases`}
                                  label={"Programmed for Daily delivery"}
                                  className={"ckbox"}
                                  onChange={this.changeHandler}
                                  checked={this.state.current_leases}
                                />
                              </div>
                              {this.state.fileUploaderRefresh === false && (
                                <form
                                  id="current_leases_csv"
                                  className="pd-r-50"
                                >
                                  <div className="d-flex flex-row align-items-center upload upload-files-section">
                                    <FileWidget
                                      fieldName="file_name"
                                      fieldTitle="Select File"
                                      checkValidation="true"
                                      allowedType="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                                      maxFileSize={30}
                                      maxFileSizeType="mb"
                                      maxFileAllowed={10}
                                      uploadOn="s3"
                                      slug={this.state.property_slug}
                                      refTableId="3"
                                      displayFileName=""
                                      documentTypeSlug="W5FYC0RBJXL1"
                                      mediaType="2"
                                      dailyDelivery={
                                        this.state.current_leases === true
                                          ? 1
                                          : 0
                                      }
                                      sortOrder=""
                                      statusId=""
                                      helpText="to download sample file."
                                      sampleFileUrl="/sample.jpg"
                                      drag="true"
                                      dragText="Select or drop file size upto 30 MB"
                                      inputClassName="form-control"
                                      labelClassName="form-label"
                                      formID="current_leases_csv"
                                      uploadType="single"
                                      resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
                                      propertySlug={this.state.property_slug}
                                      destinationFolder="property_document_form"
                                      uploadedFiles={this.state.profile_image2}
                                      deletedFiles={this.state.deletedFileList}
                                      validationFire={
                                        this.state.current_lease_data &&
                                        this.state.current_lease_data.length > 0
                                          ? "current_leases"
                                          : 0
                                      }
                                      acceptOnly="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                                      displayPreviewStyle="horizontal"
                                      onValidationChange={
                                        this.onValidationChange
                                      }
                                      previewFilesInParent={
                                        this.previewFilesInParent
                                      }
                                    />
                                  </div>

                                  {this.state.valueFromChild ===
                                  "current_leases_validation" ? (
                                    <div className="text-danger">
                                      File has already been added. Please delete
                                      the file before proceeding with the new
                                      file.
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.errors.current_lease_error ? (
                                    <div className="text-danger">
                                      {this.state.errors.current_lease_error}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="onbording_csv_uploadedfile">
                                    <div className="container px-0">
                                      <div className="">
                                        {this.state.current_lease_data &&
                                          this.state.current_lease_data.length >
                                            0 &&
                                          this.state.current_lease_data.map(
                                            (val, key) => {
                                              return (
                                                <React.Fragment key={key}>
                                                  <div className="">
                                                    <div className="d-flex align-items-center">
                                                      <img
                                                        className="onbord_file_preview_icon mg-r-5"
                                                        src={
                                                          val.file_name.match(
                                                            /.(jpg|jpeg|png|gif)$/i
                                                          )
                                                            ? val.file_name
                                                            : val.file_name.match(
                                                                /.(pdf)$/i
                                                              )
                                                            ? pdfIcon
                                                            : val.file_name.match(
                                                                /.(docx|doc)$/i
                                                              )
                                                            ? wordIcon
                                                            : val.file_name.match(
                                                                /.(xlsx|csv|xls)$/i
                                                              )
                                                            ? excelIcon
                                                            : formatNotSupported
                                                        }
                                                        alt={
                                                          val.display_file_name
                                                        }
                                                      />
                                                      <p className="title m-0 w-100">
                                                        <strong>
                                                          {
                                                            val.display_file_name
                                                          }
                                                        </strong>
                                                      </p>
                                                      <div
                                                        className="button"
                                                        data-fileid={key}
                                                        onClick={() =>
                                                          this.openDeleteDocModal(
                                                            val.slug
                                                          )
                                                        }
                                                      >
                                                        <span aria-hidden="true">
                                                          <img
                                                            src={deleteicon}
                                                            className="hand-cursor"
                                                            aria-hidden="true"
                                                          />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              )}
                            </div>

                            <div className="col-md-6 col-lg-4">
                              <div className="step-process-info step-succes onboard_stap_process">
                                <Form.Check
                                  name="rent_collection"
                                  type={"checkbox"}
                                  id={`rent_collection`}
                                  label={"Programmed for Daily delivery"}
                                  className={"ckbox"}
                                  onChange={this.changeHandler}
                                  checked={this.state.rent_collection}
                                />
                              </div>
                              {this.state.fileUploaderRefresh === false && (
                                <form
                                  id="rent_collection_csv"
                                  className="pd-r-50"
                                >
                                  <div className="d-flex flex-row align-items-center upload upload-files-section">
                                    <FileWidget
                                      fieldName="file_name"
                                      fieldTitle="Select File"
                                      checkValidation="true"
                                      allowedType="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                                      maxFileSize={30}
                                      maxFileSizeType="mb"
                                      maxFileAllowed={10}
                                      uploadOn="s3"
                                      slug={this.state.property_slug}
                                      refTableId="3"
                                      displayFileName=""
                                      documentTypeSlug="VKJL2ZSQ7F0U"
                                      mediaType="2"
                                      dailyDelivery={
                                        this.state.rent_collection === true
                                          ? 1
                                          : 0
                                      }
                                      sortOrder=""
                                      statusId=""
                                      helpText="to download sample file."
                                      sampleFileUrl="/sample.jpg"
                                      drag="true"
                                      dragText="Select or drop file size upto 30 MB"
                                      inputClassName="form-control"
                                      labelClassName="form-label"
                                      formID="rent_collection_csv"
                                      uploadType="single"
                                      resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
                                      propertySlug={this.state.property_slug}
                                      destinationFolder="property_document_form"
                                      uploadedFiles={this.state.profile_image2}
                                      deletedFiles={this.state.deletedFileList}
                                      validationFire={
                                        this.state.rent_collection_data &&
                                        this.state.rent_collection_data.length >
                                          0
                                          ? "rent_collection"
                                          : 0
                                      }
                                      acceptOnly="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                                      displayPreviewStyle="horizontal"
                                      onValidationChange={
                                        this.onValidationChange
                                      }
                                      previewFilesInParent={
                                        this.previewFilesInParent
                                      }
                                    />
                                  </div>
                                  {this.state.valueFromChild ===
                                  "rent_collection_validation" ? (
                                    <div className="text-danger">
                                      File has already been added. Please delete
                                      the file before proceeding with the new
                                      file.
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.errors.rent_collection_error ? (
                                    <div className="text-danger">
                                      {this.state.errors.rent_collection_error}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="onbording_csv_uploadedfile">
                                    <div className="container px-0">
                                      <div className="">
                                        {this.state.rent_collection_data &&
                                          this.state.rent_collection_data
                                            .length > 0 &&
                                          this.state.rent_collection_data.map(
                                            (val, key) => {
                                              return (
                                                <React.Fragment key={key}>
                                                  <div className="">
                                                    <div className="d-flex align-items-center">
                                                      <img
                                                        className="onbord_file_preview_icon mg-r-5"
                                                        src={
                                                          val.file_name.match(
                                                            /.(jpg|jpeg|png|gif)$/i
                                                          )
                                                            ? val.file_name
                                                            : val.file_name.match(
                                                                /.(pdf)$/i
                                                              )
                                                            ? pdfIcon
                                                            : val.file_name.match(
                                                                /.(docx|doc)$/i
                                                              )
                                                            ? wordIcon
                                                            : val.file_name.match(
                                                                /.(xlsx|csv|xls)$/i
                                                              )
                                                            ? excelIcon
                                                            : formatNotSupported
                                                        }
                                                        alt={
                                                          val.display_file_name
                                                        }
                                                      />
                                                      <p className="title m-0 w-100">
                                                        <strong>
                                                          {
                                                            val.display_file_name
                                                          }
                                                        </strong>
                                                      </p>

                                                      <div
                                                        className="button"
                                                        data-fileid={key}
                                                        onClick={() =>
                                                          this.openDeleteDocModal(
                                                            val.slug
                                                          )
                                                        }
                                                      >
                                                        <span aria-hidden="true">
                                                          <img
                                                            src={deleteicon}
                                                            className="hand-cursor"
                                                            aria-hidden="true"
                                                          />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="PermitPlanTab">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                        {/* <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        /> */}
                      </div>
                      {this.state.building_onboarding_permit_plan !== "" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.building_onboarding_permit_plan,
                          }}
                        ></div>
                      ) : (
                        <div className="mg-t-40 pd-md-l-30 pd-md-r-30 row mainBox">
                          <div className="col-md-6">
                            <div className="onborad_review">
                              <h3 className="mg-l-0 background-head">
                                Finalize Permitting Plan
                              </h3>
                              <p>
                                <a
                                  href="https://www.austintexas.gov/department/code-compliance-guide-short-term-rentals"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="link-url"
                                >
                                  Austin Permit Website
                                </a>
                              </p>
                              <ul className="onbaord_bank_info">
                                <li>Permit cap: 25% = 78 units</li>
                                <li>
                                  Marketing will be non-specific "limited
                                  availability"
                                </li>
                                <li>Permit cost: $863.80</li>
                                <li>Permit timeline: 6-8 weeks</li>
                                <li>
                                  Permit filing: OH is supplying local counsel
                                  who will assist in filing permits
                                </li>
                                <li>
                                  OH will pay for first 10 permits, after
                                  Residents or Building
                                </li>
                                <li>
                                  Management will stay in control of release of
                                  ability of slots
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="overview-image m-auto text-center">
                              <figure>
                                <img
                                  src={permitplanimg}
                                  alt="pro"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="LeaseAddendumTab">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                        {/* <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        /> */}
                      </div>
                      {this.state.building_onboarding_lease_addendum ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.building_onboarding_lease_addendum,
                          }}
                        ></div>
                      ) : (
                        <React.Fragment>
                          {" "}
                          <div className="mg-t-40 pd-md-l-30 pd-md-r-30 row mainBox">
                            <div className="col-md-12 pd-md-r-0">
                              <div className="onborad_review pd-md-r-30">
                                <h3 className="mg-l-0 background-head">
                                  Set up Lease Addendum
                                </h3>
                                <p>
                                  The lease addendum is a very important element
                                  of a successful homesharing program in your
                                  community. By using the lease addendum in
                                  normal leasing activities, each tenant will be
                                  ready to homeshare as they like, and you will
                                  have given the ability to homeshare just as
                                  long as they do it in the confines of the
                                  Homesharing Program
                                </p>

                                <p>
                                  Note: It is much easier to have all existing
                                  residents sign the lease addendum and include
                                  the lease addendum for all new leases than to
                                  spot-pick and try to remember who needs a
                                  lease addendum or not.
                                </p>

                                <p>
                                  Lease Addendum Template:{" "}
                                  <Link
                                    to="https://docs.google.com/document/d/1-iNfMTsacQson94K2IIcr0DyGmLRGYfDw8bFs6dRmi0/edit"
                                    className="link-url"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    LINK
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mg-t-20 pd-md-l-30 pd-md-r-30 row">
                            <div className="col-md-12 pd-md-r-0">
                              <div className="row lease_card_row">
                                <div className="col-md-4">
                                  <div className="onbord_lease_card">
                                    <div className="onborad_review">
                                      <h3 className="mg-l-0 background-head">
                                        How to Add Lease Addendum into Blue Moon
                                      </h3>
                                    </div>

                                    <ol className="pd-l-15">
                                      <li>
                                        Go to Settings {">"} Manage Custom Forms
                                      </li>
                                      <li>Click the Add Form Button</li>
                                      <li>Select New Form</li>
                                      <li>
                                        That will open the Text Editor box
                                      </li>
                                      <li>
                                        Add a Form Title (this prints on the
                                        form): “Homesharing Addendum”
                                      </li>
                                      <li>
                                        Add a description (not printed on the
                                        form): “Homesharing Addendum”
                                      </li>
                                      <li>
                                        Copy and paste the content from the
                                        lease addendum document in the text area
                                        of the Text Editor
                                      </li>
                                      <li>Click Save at the bottom</li>
                                      <li>
                                        In Manage {">"} Custom Forms, check the
                                        box next to the new form, which adds it
                                        to your account
                                      </li>
                                    </ol>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="onbord_lease_card">
                                    <div className="onborad_review">
                                      <h3 className="mg-l-0 background-head">
                                        Send the Lease Addendum to Existing
                                        Residents
                                      </h3>
                                    </div>

                                    <ol className="pd-l-15">
                                      <li>Go to Manage Leases</li>
                                      <li>
                                        Click the 3 dots to the right of each
                                        lease
                                      </li>
                                      <li>Select Print</li>
                                      <li>
                                        Scroll down to the new Homesharing
                                        Addendum and select Print Single
                                      </li>
                                      <li>
                                        Fill in Resident's email address and
                                        click Initiate eSignature Request
                                      </li>
                                    </ol>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="onbord_lease_card">
                                    <div className="onborad_review">
                                      <h3 className="mg-l-0 background-head">
                                        Include the Lease Addendum in All New
                                        Leases
                                      </h3>
                                      <p>
                                        When you are making a new lease, in the
                                        Print menu ensure that Homesharing
                                        Addendum is selected
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="StaffTrainingTab">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                        {/* <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        /> */}
                      </div>
                      {this.state.building_onboarding_staff_training ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.building_onboarding_staff_training,
                          }}
                        ></div>
                      ) : (
                        <div className="mg-t-40 pd-md-l-30 pd-md-r-30 row mainBox">
                          <div className="col-md-7">
                            <div className="onborad_review">
                              <h3 className="mg-l-0 background-head">
                                Property Staff Traning
                              </h3>
                              <p>
                                Orion Haus team members will be on site to
                                support the actual staff and resident
                                onboarding. We will meet with the on-site
                                property manager and leasing team to review the
                                homesharing program, how it works, and set them
                                up with accounts for the OHAI app. We will need
                                an hour on that Monday afternoon over lunch, and
                                will be around the rest of the week in the
                                common areas to answer any follow up questions
                              </p>

                              <h3 className="mg-l-0 background-head mg-t-30">
                                Announce Homesharing to Residents
                              </h3>
                              <p>
                                Orion Haus will provide you content to send an
                                email to residents to express interest in the
                                homesharing program. This email will invite the
                                residents to chat with an OH team member in a
                                common area, who will review the program, talk
                                about the permitting process, and how to get
                                ready for homesharing.
                              </p>

                              <h3 className="mg-l-0 background-head mg-t-30">
                                Add Content to Website
                              </h3>
                              <p>
                                We recommend adding marketing for your
                                homesharing amenity to your website with a
                                banner. Here are some ideas to share with your
                                marketing department:
                              </p>
                              <ul className="staff_train_list">
                                <li>
                                  <p className="mg-b-0">
                                    Flexible living at its best
                                  </p>
                                </li>
                                <li>
                                  <p className="mg-b-0">
                                    Make money living here
                                  </p>
                                </li>
                                <li>
                                  <p className="mg-b-0">
                                    Highgrove is homesharing-friendly
                                  </p>
                                </li>
                              </ul>
                              <p>
                                Flexible living at Highgrove is super easy. Make
                                money when you're away traveling for work,
                                heading out for vacation, or just spending time
                                at a friends' place over the weekend. Ask us how
                                we make it easy to homeshare at Highgrove.
                              </p>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="overview-image m-auto text-center">
                              <figure>
                                <img
                                  src={stafftraining}
                                  alt="staff-training"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="AnnounceHomesharingTab"
                    className="AnnounceHomesharingTab"
                  >
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                        {/* <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        /> */}
                      </div>
                      {this.state.building_onboarding_announce_homesharing ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state
                                .building_onboarding_announce_homesharing,
                          }}
                        ></div>
                      ) : (
                        <div className="mg-t-40 pd-md-l-30 pd-md-r-30 row mainBox">
                          <div className="col-md-7">
                            <div className="onborad_review">
                              <h3 className="mg-l-0 background-head">
                                Send the Lease Addendum to Existing Residents
                              </h3>
                              <ol className="anounce_home_shareList mb-5">
                                <li>
                                  <p className="mg-b-5">Go to Manage Leases</p>
                                </li>
                                <li>
                                  <p className="mg-b-5">
                                    Click the 3 dots to the right of each lease
                                  </p>
                                </li>
                                <li>
                                  <p className="mg-b-5">Select Print</p>
                                </li>
                                <li>
                                  <p className="mg-b-5">
                                    Scroll down to the new Homesharing Addendum
                                    and select Print Single
                                  </p>
                                </li>
                                <li>
                                  <p className="mg-b-5">
                                    Fill in Resident's email address and click
                                    Initiate eSignature Request
                                  </p>
                                </li>
                              </ol>
                              <h3 className="mg-l-0 background-head">
                                Include the Lease Addendum in All New Lease
                              </h3>
                              <p>
                                When you are making a new lease, in the Print
                                menu ensure that Homesharing Addendum is
                                selected.
                              </p>
                              <h3 className="mg-l-0 background-head mg-t-30 ">
                                Market Homesharing to Residents & New Residents
                              </h3>
                              <p>
                                Orion Haus will send out follow up emails over
                                the coming days after the building's
                                announcement that will review the homesharing
                                program, how it works, and how to get ready for
                                homesharing.
                              </p>
                              <p>
                                Even though these messages will be sent
                                automatically from OHAI, it is a good idea to
                                continue to push the homesharing program to your
                                residents, talk with them, and encourage
                                participation. It's the best way to earn
                                additional income for your property.
                              </p>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="overview-image m-auto text-center">
                              <figure>
                                <img
                                  src={announcehomeshare}
                                  alt="announce-home"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="InstallKeyLockerTab">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                        {/* <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        /> */}
                      </div>
                      {this.state.lock_type === 1 ? (
                        <React.Fragment>
                          {this.state
                            .building_onboarding_install_key_locker_keycafe ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state
                                    .building_onboarding_install_key_locker_keycafe,
                              }}
                            ></div>
                          ) : (
                            <React.Fragment>
                              <div className="mg-t-40 pd-md-l-30 pd-md-r-30 row mainBox">
                                <div className="col-md-12">
                                  <div className="onborad_review">
                                    <h3 className="mg-l-0 background-head">
                                      Install Your Key Locker (Keycafe)
                                    </h3>
                                    <p>
                                      Orion Haus will receive the key lockers
                                      from the distribution center, and they
                                      will be sent to your property after
                                      inspection. Soon after they arrive, an OH
                                      team member will schedule with you to
                                      visit the property and guide the
                                      installation, which will be performed by
                                      your maintenance team.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mg-t-20 pd-l-30 pd-r-30 row">
                                <div className="col-md-12 pd-xs-l-0 pd-xs-r-0 pd-md-l-15">
                                  <div className="row lease_card_row">
                                    <div className="col-md-4">
                                      <div className="onbord_lease_card">
                                        <div className="onborad_review">
                                          <h3 className="mg-l-0 background-head">
                                            Access Detail
                                          </h3>
                                        </div>

                                        <ol className="pd-l-15">
                                          <li>
                                            Guests will arrive to the property
                                            near the leasing office and will be
                                            directed to the key locker, where
                                            they will pick up their keys
                                          </li>
                                          <li>
                                            They will then be directed to the
                                            parking location.
                                          </li>
                                          <li>
                                            They will use their fob to enter the
                                            building.
                                          </li>
                                          <li>
                                            They will use their metal key to
                                            enter the apartment.
                                          </li>
                                        </ol>
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      <div className="onbord_lease_card">
                                        <div className="onborad_review">
                                          <h3 className="mg-l-0 background-head">
                                            Installation Location
                                          </h3>
                                          <p>
                                            The key locker is planned to be
                                            installed on the wall between the
                                            two buildings in a 3x2 pattern, 46"
                                            from the ground. Installation w
                                          </p>
                                          <p>
                                            Please ensure that the electrical
                                            works and that the base station can
                                            be plugged in, and not be un-plugged
                                            (either intentionally or
                                            unintentionally). If that wall has
                                            an ethernet connection, we can use
                                            that to connect the box to the
                                            internet, otherwise, it can be
                                            connected to the WiFi.
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      <div className="onbord_lease_card">
                                        <div className="onborad_review">
                                          <h3 className="mg-l-0 background-head">
                                            Installation Instructions
                                          </h3>
                                          <p>
                                            When you are making a new lease, in
                                            the Print menu ensure that
                                            Homesharing Addendum is selected
                                          </p>
                                          <p className="mb-1">
                                            SmartBox Installation:{" "}
                                            <Link
                                              to="https://support.keycafe.com/hc/en-us/articles/360043490192-MS5-Base-SmartBox-Installation"
                                              className="link-url"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Link
                                            </Link>
                                          </p>
                                          <p className="mb-1">
                                            Ethernet Connectivity:{" "}
                                            <Link
                                              to="https://support.keycafe.com/hc/en-us/articles/4411309204244-Ethernet-Connectivity"
                                              className="link-url"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Link
                                            </Link>
                                          </p>
                                          <p className="mb-1">
                                            WiFi Connectivity:{" "}
                                            <Link
                                              to="https://support.keycafe.com/hc/en-us/articles/4405925086356-WiFi-Connectivity"
                                              className="link-url"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Link
                                            </Link>
                                          </p>
                                          <p className="mb-1">
                                            Cellular Connectivity:{" "}
                                            <Link
                                              to="https://support.keycafe.com/hc/en-us/articles/360043934791-Cellular-Connectivity"
                                              className="link-url"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Link
                                            </Link>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      {this.state.lock_type === 2 ? (
                        <React.Fragment>
                          {this.state
                            .building_onboarding_install_key_locker_latch ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  this.state
                                    .building_onboarding_install_key_locker_latch,
                              }}
                            ></div>
                          ) : (
                            <React.Fragment>
                              <div className="mg-t-40 pd-md-l-30 pd-md-r-30 row mainBox">
                                <div className="col-md-12">
                                  <div className="onborad_review">
                                    <h3 className="mg-l-0 background-head">
                                      Install Your Key Locker (Latch)
                                    </h3>
                                    <p>
                                      Orion Haus will receive the key lockers
                                      from the distribution center, and they
                                      will be sent to your property after
                                      inspection. Soon after they arrive, an OH
                                      team member will schedule with you to
                                      visit the property and guide the
                                      installation, which will be performed by
                                      your maintenance team.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mg-t-20 pd-l-30 pd-r-30 row">
                                <div className="col-md-12 pd-xs-l-0 pd-xs-r-0 pd-md-l-15">
                                  <div className="row lease_card_row">
                                    <div className="col-md-4">
                                      <div className="onbord_lease_card">
                                        <div className="onborad_review">
                                          <h3 className="mg-l-0 background-head">
                                            Access Detail
                                          </h3>
                                        </div>

                                        <ol className="pd-l-15">
                                          <li>
                                            Guests will arrive to the property
                                            near the leasing office and will be
                                            directed to the key locker, where
                                            they will pick up their keys
                                          </li>
                                          <li>
                                            They will then be directed to the
                                            parking location.
                                          </li>
                                          <li>
                                            They will use their fob to enter the
                                            building.
                                          </li>
                                          <li>
                                            They will use their metal key to
                                            enter the apartment.
                                          </li>
                                        </ol>
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      <div className="onbord_lease_card">
                                        <div className="onborad_review">
                                          <h3 className="mg-l-0 background-head">
                                            Installation Location
                                          </h3>
                                          <p>
                                            The key locker is planned to be
                                            installed on the wall between the
                                            two buildings in a 3x2 pattern, 46"
                                            from the ground. Installation w
                                          </p>
                                          <p>
                                            Please ensure that the electrical
                                            works and that the base station can
                                            be plugged in, and not be un-plugged
                                            (either intentionally or
                                            unintentionally). If that wall has
                                            an ethernet connection, we can use
                                            that to connect the box to the
                                            internet, otherwise, it can be
                                            connected to the WiFi.
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      <div className="onbord_lease_card">
                                        <div className="onborad_review">
                                          <h3 className="mg-l-0 background-head">
                                            Installation Instructions
                                          </h3>
                                          <p>
                                            When you are making a new lease, in
                                            the Print menu ensure that
                                            Homesharing Addendum is selected
                                          </p>
                                          <p className="mb-1">
                                            SmartBox Installation:{" "}
                                            <Link
                                              to="https://support.keycafe.com/hc/en-us/articles/360043490192-MS5-Base-SmartBox-Installation"
                                              className="link-url"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Link
                                            </Link>
                                          </p>
                                          <p className="mb-1">
                                            Ethernet Connectivity:{" "}
                                            <Link
                                              to="https://support.keycafe.com/hc/en-us/articles/4411309204244-Ethernet-Connectivity"
                                              className="link-url"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Link
                                            </Link>
                                          </p>
                                          <p className="mb-1">
                                            WiFi Connectivity:{" "}
                                            <Link
                                              to="https://support.keycafe.com/hc/en-us/articles/4405925086356-WiFi-Connectivity"
                                              className="link-url"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Link
                                            </Link>
                                          </p>
                                          <p className="mb-1">
                                            Cellular Connectivity:{" "}
                                            <Link
                                              to="https://support.keycafe.com/hc/en-us/articles/360043934791-Cellular-Connectivity"
                                              className="link-url"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Link
                                            </Link>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{" "}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      {this.state.lock_type === 3 ? (
                        <React.Fragment>
                          <div className="mg-t-40 pd-md-l-30 pd-md-r-30 row mainBox">
                            <div className="col-md-12 pd-md-l-15">
                              <div className="onborad_review">
                                <h3 className="onbording_heading">
                                  Install Your Key Locker (Igloo)
                                </h3>
                                <p>
                                  Orion Haus will receive the key lockers from
                                  the distribution center, and they will be sent
                                  to your property after inspection. Soon after
                                  they arrive, an OH team member will schedule
                                  with you to visit the property and guide the
                                  installation, which will be performed by your
                                  maintenance team.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="mg-t-20 pd-l-30 pd-r-30 row">
                            <div className="col-md-12 pd-xs-l-0 pd-xs-r-0 pd-md-l-15">
                              <div className="row lease_card_row">
                                <div className="col-md-4">
                                  <div className="onbord_lease_card">
                                    <div className="onborad_review">
                                      <h3 className="mg-l-0 background-head">
                                        Access Detail
                                      </h3>
                                    </div>

                                    <ol className="pd-l-15">
                                      <li>
                                        Guests will arrive to the property near
                                        the leasing office and will be directed
                                        to the key locker, where they will pick
                                        up their keys
                                      </li>
                                      <li>
                                        They will then be directed to the
                                        parking location.
                                      </li>
                                      <li>
                                        They will use their fob to enter the
                                        building.
                                      </li>
                                      <li>
                                        They will use their metal key to enter
                                        the apartment.
                                      </li>
                                    </ol>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="onbord_lease_card">
                                    <div className="onborad_review">
                                      <h3 className="mg-l-0 background-head">
                                        Installation Location
                                      </h3>
                                      <p>
                                        The key locker is planned to be
                                        installed on the wall between the two
                                        buildings in a 3x2 pattern, 46" from the
                                        ground. Installation w
                                      </p>
                                      <p>
                                        Please ensure that the electrical works
                                        and that the base station can be plugged
                                        in, and not be un-plugged (either
                                        intentionally or unintentionally). If
                                        that wall has an ethernet connection, we
                                        can use that to connect the box to the
                                        internet, otherwise, it can be connected
                                        to the WiFi.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="onbord_lease_card">
                                    <div className="onborad_review">
                                      <h3 className="mg-l-0 background-head">
                                        Installation Instructions
                                      </h3>
                                      <p>
                                        When you are making a new lease, in the
                                        Print menu ensure that Homesharing
                                        Addendum is selected
                                      </p>
                                      <p className="mb-1">
                                        SmartBox Installation:{" "}
                                        <Link
                                          to="https://support.keycafe.com/hc/en-us/articles/360043490192-MS5-Base-SmartBox-Installation"
                                          className="link-url"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Link
                                        </Link>
                                      </p>
                                      <p className="mb-1">
                                        Ethernet Connectivity:{" "}
                                        <Link
                                          to="https://support.keycafe.com/hc/en-us/articles/4411309204244-Ethernet-Connectivity"
                                          className="link-url"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Link
                                        </Link>
                                      </p>
                                      <p className="mb-1">
                                        WiFi Connectivity:{" "}
                                        <Link
                                          to="https://support.keycafe.com/hc/en-us/articles/4405925086356-WiFi-Connectivity"
                                          className="link-url"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Link
                                        </Link>
                                      </p>
                                      <p className="mb-1">
                                        Cellular Connectivity:{" "}
                                        <Link
                                          to="https://support.keycafe.com/hc/en-us/articles/360043934791-Cellular-Connectivity"
                                          className="link-url"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Link
                                        </Link>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
                {this.state.wizardViewChange === 0 && (
                  <div className="wizd-btn-fix">
                    <div className="wizard-flex">
                      <div className="wiz__btn_50">
                        <button
                          className="btn btn-cust-small home-btn"
                          onClick={(e) => this.handleHomeClick(e)}
                        >
                          Home
                        </button>
                        <button
                          disabled={
                            this.state.disabledBackButton === true
                              ? true
                              : false
                          }
                          className={
                            this.state.disabledBackButton === true
                              ? "btn btn-cust-small-o ml-3 disabled"
                              : "btn btn-cust-small-o ml-3 back-btn"
                          }
                          onClick={(e) => this.handleBackClick(e)}
                        >
                          Back
                        </button>
                      </div>
                      <div className="wiz__btn_50">
                        <button
                          disabled={
                            this.state.disabledNextButton === true
                              ? true
                              : this.state.isSubmit
                              ? true
                              : false
                          }
                          className={
                            this.state.disabledNextButton === true
                              ? "btn btn-cust-small disabled"
                              : "btn btn-cust-small next-btn"
                          }
                          onClick={(e) => this.handleNextClick(e)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Tab.Container>
            </div>
          </Container>
          <Modal show={this.state.deleteDocModal} centered>
            <Modal.Body className="width_402 p-4 border_radius_14">
              <div className="text-center">
                <span
                  className="welcomeClose"
                  onClick={() => this.closeDeleteDocModal()}
                >
                  {global.closee}
                </span>
                <img src={modaldeleteicon} alt="modal-delete-icon" />
                <h3>Delete the Option?</h3>
                <p>
                  Do you really want to delete this option? This process cannot
                  be undone.
                </p>
                <div className="d-flex justify-content-center mt-3 row">
                  <button
                    type="button"
                    className="m-0 btn btn-outline-light btn-block"
                    onClick={() => this.closeDeleteDocModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="m-0 btn btn btn-danger btn-block"
                    onClick={() => this.deleteDoc(this.state.delete_slug)}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Delete"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.is_stripe_setup}
            className=" searchModalbox1 confirm_modal_box"
            centered
          >
            <Modal.Body className="width_402 p-4 border_radius_14 modal-body">
              <span
                className="welcomeClose"
                onClick={() => this.confirmStripeClose()}
              >
                {global.closee}
              </span>

              <h3 className="text-center mt-3">
                Are you sure you want to create stripe account?
              </h3>
              <div className="row col-12 m-0">
                <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                  <div className="d-flex justify-content-center mt-3 row">
                    <button
                      type="button"
                      className="m-0 btn btn btn-success btn-block"
                      onClick={() => this.createStripeAccount()}
                    >
                      {this.state.isSend ? global.loader : "Yes"}
                    </button>
                    <button
                      type="button"
                      className="mx-auto- m-0  btn btn-outline-light btn-block ml-3"
                      onClick={() => this.confirmStripeClose()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </main>
      </React.Fragment>
    );
  }
}
export default BuildingOnboarding;
