////////////////////////////////////////////////////////////
//     							                                      //
//  Program: GeneralTax.jsx                           //
//  Application: Financial                                   //
//  Option: to view GeneralTax data                  //
//  Developer: Ashish Kumar                               //
//  Date: 2022-01-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form, InputGroup } from "react-bootstrap";
import Alerts from "../common/Alerts";
import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import GeneralTaxService from "../../services/GeneralTaxService";
import TaxTypeService from "../../services/TaxTypeService";
import CountryService from "../../services/CountryService";
import CountyService from "../../services/CountyService";
import StateService from "../../services/StateService";
import CityService from "../../services/CityService";
import pluswIcon from "./../../assets/images/plus.svg";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import NoData from "../common/NoData";

export class GeneralTax extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for general tax
      addGeneralTaxModal: false,
      editGeneralTaxModal: false,
      deleteGeneralTaxModal: false,
      generalTaxList: [],
      taxTypeList: [],
      newTaxTypeList: [],
      selectedTaxTypeList: [],
      generalTaxListTotal: 0,
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      county_slug: [],
      newCountyList: [],
      selectedCountyList: [],
      general_tax_name: "",
      tax_type_slug: "",
      general_tax_value: "",
      country_slug: "",
      stateList: [],
      state_slug: [],
      newStateList: [],
      selectedStateList: [],
      cityList: [],
      city_slug: [],
      newCityList: [],
      selectedCityList: [],
      sort_order: "",
      selected: 1,
    };
    this.changeHandler = this.changeHandler.bind(this);

    /* for General Tax */
    this.openAddGeneralTaxModal = this.openAddGeneralTaxModal.bind(this);
    this.openEditGeneralTaxModal = this.openEditGeneralTaxModal.bind(this);
    this.openDeleteGeneralTaxModal = this.openDeleteGeneralTaxModal.bind(this);
    this.saveGeneralTax = this.saveGeneralTax.bind(this);
    this.getGeneralTaxDetails = this.getGeneralTaxDetails.bind(this);
    this.updateGeneralTax = this.updateGeneralTax.bind(this);
    this.deleteGeneralTax = this.deleteGeneralTax.bind(this);
  }

  componentDidMount() {
    this.getGeneralTaxData();
    this.getCountryData();
    this.getCountyData();
    this.getStatesData();
    this.getCityData();
    this.getTaxTypeData();
  }

  /* To get Tax Type list data */
  async getTaxTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var taxTypeList = [];
    var taxTypeListTotal = 0;

    let res = await TaxTypeService.getTaxType(
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      taxTypeList = res.data.data ? res.data.data : [];
      taxTypeListTotal = taxTypeList ? taxTypeList.length : 0;
    }
    const newTaxTypeList =
      taxTypeListTotal > 0
        ? taxTypeList.map(({ slug, tax_type_name }) => ({
            value: slug,
            label: tax_type_name,
          }))
        : [];
    this.setState({
      taxTypeList: taxTypeList,
      taxTypeListTotal: taxTypeListTotal,
      newTaxTypeList: newTaxTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get country list data */
  async getCountryData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countryList = [];
    var countryListTotal = 0;

    let res = await CountryService.getCountry(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data.data ? res.data.data : [];
      countryListTotal = countryList ? countryList.length : 0;
    }
    const newCountryList =
      countryListTotal > 0
        ? countryList.map(({ slug, country_name }) => ({
            value: slug,
            label: country_name,
          }))
        : [];
    this.setState({
      countryList: countryList,
      countryListTotal: countryListTotal,
      newCountryList: newCountryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get state list data */
  async getStatesData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var stateList = [];
    var stateListTotal = 0;

    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" + value,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
      stateListTotal = stateList ? stateList.length : 0;
    }
    const newStateList =
      stateListTotal > 0
        ? stateList.map(({ slug, state_name }) => ({
            value: slug,
            label: state_name,
          }))
        : [];
    this.setState({
      stateList: stateList,
      stateListTotal: stateListTotal,
      newStateList: newStateList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get county list data */
  async getCountyData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countyList = [];
    var countyListTotal = 0;

    let res = await CountyService.getCounty(
      "is_dropdown=1&state_slugs=" + value,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
      countyListTotal = countyList ? countyList.length : 0;
    }
    const newCountyList =
      countyListTotal > 0
        ? countyList.map(({ slug, county_name }) => ({
            value: slug,
            label: county_name,
          }))
        : [];
    this.setState({
      countyList: countyList,
      countyListTotal: countyListTotal,
      newCountyList: newCountyList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get city list data */
  async getCityData(value, value1, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;

    let res = await CityService.getCity(
      "is_dropdown=1&state_slugs=" + value + "&county_slugs=" + value1,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList =
      cityListTotal > 0
        ? cityList.map(({ slug, city_name }) => ({
            value: slug,
            label: city_name,
          }))
        : [];
    this.setState({
      cityList: cityList,
      cityListTotal: cityListTotal,
      newCityList: newCityList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  // /* To get General Tax list data */
  async getGeneralTaxData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var generalTaxList = [];
    var generalTaxListTotal = 0;

    let res = await GeneralTaxService.getGeneralTax(
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      generalTaxList = res.data ? res.data : [];
      generalTaxListTotal = generalTaxList ? generalTaxList.length : 0;
    }
    this.setState({
      generalTaxList: generalTaxList,
      generalTaxListTotal: generalTaxListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To save General Tax data */
  saveGeneralTax = async (e) => {
    e.preventDefault();
    if (
      this.validate() &&
      this.customValidate() &&
      !this.validator.errorMessages.general_tax_name &&
      !this.validator.errorMessages.tax_type_slug &&
      !this.validator.errorMessages.general_tax_value &&
      !this.validator.errorMessages.country_slug &&
      !this.validator.errorMessages.county_slug &&
      !this.validator.errorMessages.state_slug &&
      !this.validator.errorMessages.city_slug &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputData = {
        general_tax_name: this.state.general_tax_name,
        tax_type_slug: this.state.tax_type_slug,
        general_tax_value: this.state.general_tax_value,
        country_slug: this.state.country_slug,
        county_slug: this.state.county_slug,
        state_slug: this.state.state_slug,
        city_slug: this.state.city_slug,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await GeneralTaxService.createGeneralTax(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddGeneralTaxModal();
        this.setState({
          general_tax_name: "",
          tax_type_slug: "",
          general_tax_value: "",
          country_slug: "",
          county_slug: "",
          state_slug: "",
          city_slug: "",
          sort_order: "",
          status_id: true,
        });
        this.getGeneralTaxData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get General Tax details data  */
  async getGeneralTaxDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      var selectedStateList = this.state.selectedStateList;
      var selectedCountyList = this.state.selectedCountyList;
      var selectedCityList = this.state.selectedCityList;
      let res = await GeneralTaxService.getGeneralTaxBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.cost_payable_at_name = res.data.cost_payable_at_name
          ? res.data.cost_payable_at_name
          : "";
        resData.tax_type_slug = res.data.tax_type_slug
          ? res.data.tax_type_slug
          : "";
        resData.general_tax_value = res.data.general_tax_value
          ? parseFloat(res.data.general_tax_value)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.country_slug = res.data.country_slug
          ? res.data.country_slug
          : "";
        resData.county_slug = res.data.county_slug ? res.data.county_slug : [];
        resData.state_slug = res.data.state_slug ? res.data.state_slug : [];
        resData.city_slug = res.data.city_slug ? res.data.city_slug : [];
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;

        res.data.cityData &&
          res.data.cityData.forEach((item, i) => {
            selectedCityList = [
              ...selectedCityList,
              {
                value: item.id,
                label: item.name,
              },
            ];
          });
        res.data.stateData &&
          res.data.stateData.forEach((item, i) => {
            selectedStateList = [
              ...selectedStateList,
              {
                value: item.id,
                label: item.name,
              },
            ];
          });
        res.data.countyData &&
          res.data.countyData.forEach((item, i) => {
            selectedCountyList = [
              ...selectedCountyList,
              {
                value: item.id,
                label: item.name,
              },
            ];
          });
        var selectedCountryList = [];
        selectedCountryList = res.data.country_slug
          ? [
              {
                value: res.data.country_slug,
                label: res.data.country_name,
              },
            ]
          : "";
        var selectedTaxTypeList = [];
        selectedTaxTypeList = res.data.tax_type_slug
          ? [
              {
                value: res.data.tax_type_slug,
                label: res.data.tax_type_name,
              },
            ]
          : "";
      }

      if (res.data.tax_type_name.includes("$")) {
        this.setState({ selected: 1 });
      } else {
        this.setState({ selected: 0 });
      }
      this.getStatesData(resData.country_slug);
      this.getCountyData(resData.state_slug);
      this.getCityData(resData.state_slug, resData.county_slug);
      this.setState(resData);
      this.setState({
        // state_slug: state_slug,
        // city_slug: city_slug,
        // county_slug: county_slug,
        selectedStateList: selectedStateList,
        selectedCityList: selectedCityList,
        selectedCountyList: selectedCountyList,
        selectedTaxTypeList: selectedTaxTypeList,
        selectedCountryList: selectedCountryList,
      });
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update General Tax data */
  updateGeneralTax = async (e) => {
    e.preventDefault();
    if (
      this.validate() &&
      this.customValidate() &&
      !this.validator.errorMessages.general_tax_name &&
      !this.validator.errorMessages.tax_type_slug &&
      !this.validator.errorMessages.general_tax_value &&
      !this.validator.errorMessages.country_slug &&
      !this.validator.errorMessages.county_slug &&
      !this.validator.errorMessages.state_slug &&
      !this.validator.errorMessages.city_slug &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });

      let value = this.state.selectedStateList;
      let state_slugs = [];
      if (value != null && value.length > 0) {
        value.forEach((item, i) => {
          state_slugs.push(item.value);
        });
      }

      let value1 = this.state.selectedCountyList;
      let county_slugs = [];
      if (value1 != null && value1.length > 0) {
        value1.forEach((item, i) => {
          county_slugs.push(item.value);
        });
      }

      let value2 = this.state.selectedCityList;
      let city_slugs = [];
      if (value2 != null && value2.length > 0) {
        value2.forEach((item, i) => {
          city_slugs.push(item.value);
        });
      }

      let inputData = {
        general_tax_name: this.state.general_tax_name,
        tax_type_slug: this.state.tax_type_slug,
        general_tax_value: parseFloat(this.state.general_tax_value),
        country_slug: this.state.country_slug,
        county_slug: county_slugs,
        state_slug: state_slugs,
        city_slug: city_slugs,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await GeneralTaxService.updateGeneralTax(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditGeneralTaxModal();
        this.setState({
          general_tax_name: "",
          tax_type_slug: "",
          general_tax_value: "",
          country_slug: "",
          county_slug: "",
          state_slug: "",
          city_slug: "",
          sort_order: "",
          status_id: true,
        });
        this.getGeneralTaxData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete General Tax data */
  async deleteGeneralTax(slug) {
    this.setState({ showSpinner: true });
    let res = await GeneralTaxService.deleteGeneralTax(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteGeneralTaxModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getGeneralTaxData();
    } else {
      this.closeDeleteGeneralTaxModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    if (event.target.name === "country_slug") {
      this.getStatesData(event.target.value);
      this.setState({ state_slug: "", selectedStateList: [] });
    }
    if (event.target.name === "state_slug") {
      this.setState({ county_slug: "", selectedCountyList: [] });
      this.setState({ city_slug: "", selectedCityList: [] });
    }

    if (event.target.name === "general_tax_value") {
      let general_tax_value =
        event.target.name === "general_tax_value"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.general_tax_value
          ? this.state.general_tax_value.replace(
              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
              ""
            )
          : "";
      this.setState({ general_tax_value: general_tax_value });
    }
  };

  handleChangeState(value) {
    let state_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_slug.push(item.value);
      });
    }
    if (value !== null) {
      this.getCountyData(state_slug);
      this.getCityData(state_slug, "");
    }
    this.setState({ selectedStateList: value, state_slug: state_slug });
    this.validator.hideMessages();
  }

  handleChangeCounty(value) {
    let county_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        county_slug.push(item.value);
      });
    }
    if (value !== null) {
      this.getCityData("", county_slug);
    }
    this.setState({ selectedCountyList: value, county_slug: county_slug });
    this.validator.hideMessages();
  }

  handleChangeCity(value) {
    let city_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_slug.push(item.value);
      });
    }
    this.setState({ selectedCityList: value, city_slug: city_slug });
    this.validator.hideMessages();
  }

  handleChangeCountryList(value) {
    let country_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_slug.push(item.value);
      });
    }
    const country_slug_value = value !== null ? value.value : [];
    if (value !== null) {
      this.getStatesData(value.value);
    }

    this.setState({
      selectedCountryList: value,
      country_slug: country_slug_value,
      selectedStateList: [],
      state_slug: "",
      selectedCountyList: [],
      county_slug: "",
      selectedCityList: [],
      city_slug: "",
    });
  }

  handleChangeTaxTypeList(value) {
    let tax_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        tax_type_slug.push(item.value);
      });
    }
    const tax_type_slug_value = value !== null ? value.value : [];
    if (value !== null) {
      var selected = value.label;
      if (selected) {
        if (selected.includes("$")) {
          this.setState({ selected: 1 });
        } else {
          this.setState({ selected: 2 });
        }
      }
    }
    this.setState({
      selectedTaxTypeList: value,
      tax_type_slug: tax_type_slug_value,
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* General Tax Modals */
  openAddGeneralTaxModal() {
    this.setState({ addGeneralTaxModal: true });
  }

  closeAddGeneralTaxModal() {
    this.setState({ addGeneralTaxModal: false });
    this.setState({
      general_tax_name: "",
      tax_type_slug: "",
      general_tax_value: "",
      country_slug: "",
      state_slug: "",
      county_slug: "",
      city_slug: "",
      sort_order: "",
      status_id: true,
      selectedcityList: [],
      selectedStateList: [],
      selectedCountyList: [],
      selectedTaxTypeList: [],
      selectedCountryList: [],
      errors: {},
    });
    this.validator.hideMessages();
  }

  openEditGeneralTaxModal(slug) {
    this.getGeneralTaxDetails(slug);
    this.setState({ editGeneralTaxModal: true });
    this.setState({ slug: slug });
  }

  closeEditGeneralTaxModal() {
    this.setState({ editGeneralTaxModal: false });
    this.setState({
      slug: "",
      general_tax_name: "",
      tax_type_slug: "",
      general_tax_value: "",
      country_slug: "",
      state_slug: "",
      county_slug: "",
      city_slug: "",
      sort_order: "",
      status_id: true,
      selectedCityList: [],
      selectedStateList: [],
      selectedCountyList: [],
      selectedTaxTypeList: [],
      selectedCountryList: [],
      errors: {},
    });
    this.validator.hideMessages();
  }

  openDeleteGeneralTaxModal(slug) {
    this.setState({ deleteGeneralTaxModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteGeneralTaxModal() {
    this.setState({ deleteGeneralTaxModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  validate() {
    let errors = this.state.errors;
    if (!this.state.general_tax_value) {
      if (this.state.selected !== 1) {
        errors["general_tax_value"] = "The Percentage (%) field is required.";
      }
      if (this.state.selected === 1) {
        errors["general_tax_value"] = "The Amount ($) field is required.";
      }
    }
    this.setState({ errors: errors });
    return true;
  }

  getTitle() {
    return "Taxes";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                          <Button
                            variant="outline-dark btn-block btn-with-icon"
                            onClick={this.openAddGeneralTaxModal}
                          >
                            <img src={pluswIcon} alt="" /> Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      {/* <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
                        <Button
                          variant="outline-dark btn-block btn-with-icon"
                          onClick={this.openAddGeneralTaxModal}
                        >
                          <img src={pluswIcon} alt="" /> Add
                        </Button>
                      </div> */}
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.generalTaxListTotal > 0 ? (
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th>Tax Name</th>
                              <th>Tax Type</th>
                              <th>Tax Value</th>
                              <th>Country</th>
                              <th>State</th>
                              <th>County</th>
                              <th>City</th>
                              <th>Status</th>
                              {global.userPermissions.checkPermission(
                                "master-data-delete"
                              ) && <th className="text-center">Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.generalTaxListTotal > 0 ? (
                              this.state.generalTaxList.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      className="hand-cursor"
                                      onClick={() => {
                                        global.userPermissions.checkPermission(
                                          "master-data-update"
                                        ) &&
                                          this.openEditGeneralTaxModal(
                                            item.slug
                                          );
                                      }}
                                    >
                                      {item.general_tax_name}
                                    </td>
                                    <td> {item.tax_type_name} </td>
                                    <td>
                                      {" "}
                                      {item.tax_type_name.includes("$") ? (
                                        <NumberFormat
                                          value={parseFloat(
                                            item.general_tax_value
                                          )
                                            .toFixed(2)
                                            .replace(/\.00$/, "")
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                        />
                                      ) : (
                                        parseFloat(item.general_tax_value)
                                          .toFixed(2)
                                          .replace(/\.00$/, "")
                                          .replace(
                                            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                            ""
                                          ) + "%"
                                      )}
                                    </td>
                                    <td> {item.country_name} </td>
                                    <td> {item.state_name} </td>
                                    <td> {item.county_name} </td>
                                    <td> {item.city_name} </td>
                                    <td>
                                      {item.status_id === 1 ? (
                                        <b className="active"></b>
                                      ) : (
                                        <b></b>
                                      )}
                                    </td>
                                    {global.userPermissions.checkPermission(
                                      "master-data-delete"
                                    ) && (
                                      <td>
                                        <div className="btn-icon-list d-block text-center">
                                          <img
                                            src={deleteicon}
                                            alt="Delete"
                                            onClick={() =>
                                              this.openDeleteGeneralTaxModal(
                                                item.slug
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                  There are no taxes added in the system. You
                                  may add them by clicking Add button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage("GENERALTAX")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add General Tax popup modal */}
        <Modal
          show={this.state.addGeneralTaxModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddGeneralTaxModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddGeneralTaxModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Tax</h3>
            <InputGroup>
              <label>Tax Name</label>
              <Form.Control
                onChange={this.changeHandler}
                name="general_tax_name"
                value={this.state.general_tax_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
                // placeholder="Tax"
              />
              {this.state.errors.general_tax_name ? (
                <div className="text-danger">
                  {this.state.errors.general_tax_name}
                </div>
              ) : (
                this.validator.message(
                  "general_tax_name",
                  this.state.general_tax_name,
                  "required",
                  { className: "text-danger" }
                )
              )}
            </InputGroup>

            <InputGroup className="mt-3">
              <label>Tax Type</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newTaxTypeList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeTaxTypeList(value)}
                  defaultValue={this.state.selectedTaxTypeList}
                  value={this.state.selectedTaxTypeList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="tax_type_slug"
                value={this.state.tax_type_slug}
              >
                <option key="" value="">
                  Select Tax Type
                </option>
                {this.state.taxTypeList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.tax_type_name}
                  </option>
                ))}
              </select> */}
            </InputGroup>
            {this.state.errors.tax_type_slug ? (
              <div className="text-danger">
                {this.state.errors.tax_type_slug}
              </div>
            ) : (
              this.validator.message(
                "tax_type_slug",
                this.state.tax_type_slug,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mb-3 mt-4">
              <label>
                {this.state.selected === 1 ? "Amount" : "Percentage"}{" "}
              </label>
              <div className="col-md-12 p-0">
                <div className={this.state.selected === 1 ? "input-group-prepend prefix-dollar" : "input-group-prepend prefix-dollar percanteg-prefix"}>
                  {this.state.selected === 1 ? (
                    <span className="input-group-text">$</span>
                  ) : (
                    ""
                  )}
                  <Form.Control
                    onChange={this.changeHandler}
                    name="general_tax_value"
                    value={
                      this.state.general_tax_value
                        ? this.state.general_tax_value.replace(
                            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                            ""
                          )
                        : "" || ""
                    }
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    onKeyPress={global.onKeyPressEvent.floatValidation}
                    maxLength="50"
                    autoComplete="off"
                    // placeholder={
                    //   this.state.selected === 1
                    //     ? "Amount ($)"
                    //     : "Percentage (%)"
                    // }
                  />
                  {this.state.selected !== 1 ? (
                    <span className="input-group-text">%</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.state.errors.general_tax_value ? (
                <div className="text-danger">
                  {this.state.errors.general_tax_value}
                </div>
              ) : (
                ""
              )}
            </InputGroup>

            <InputGroup className="mt-3">
              <label>
                Country <span className="optional">(Optional)</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newCountryList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCountryList(value)}
                  // defaultValue={this.state.selectedCountryList}
                  value={this.state.selectedCountryList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="country_slug"
                value={this.state.country_slug}
              >
                <option key="" value="">
                  Select Country
                </option>
                {this.state.countryList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.country_name}
                  </option>
                ))}
              </select> */}
            </InputGroup>

            <InputGroup className="mt-3">
              <label>
                State <span className="optional">(Optional)</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  isMulti
                  styles={customStyles}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newStateList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeState(value)}
                  // defaultValue={this.state.selectedStateList}
                  value={this.state.selectedStateList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>

            <InputGroup className="mt-3">
              <label>
                County <span className="optional">(Optional)</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  isMulti
                  styles={customStyles}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newCountyList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCounty(value)}
                  // defaultValue={this.state.selectedCountyList}
                  value={this.state.selectedCountyList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>

            <InputGroup className="mt-3">
              <label>
                City <span className="optional">(Optional)</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  isMulti
                  styles={customStyles}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newCityList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCity(value)}
                  // defaultValue={this.state.selectedCityList}
                  value={this.state.selectedCityList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddGeneralTaxModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveGeneralTax}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit General Tax popup modal */}
        <Modal
          show={this.state.editGeneralTaxModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditGeneralTaxModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditGeneralTaxModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Tax</h3>
            <InputGroup>
              <label>Tax Name</label>
              <Form.Control
                onChange={this.changeHandler}
                name="general_tax_name"
                value={this.state.general_tax_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
                // placeholder="Tax"
              />
              {this.state.errors.general_tax_name ? (
                <div className="text-danger">
                  {this.state.errors.general_tax_name}
                </div>
              ) : (
                this.validator.message(
                  "general_tax_name",
                  this.state.general_tax_name,
                  "required",
                  { className: "text-danger" }
                )
              )}
            </InputGroup>
            <InputGroup className="mt-3">
              <label>Tax Type</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newTaxTypeList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeTaxTypeList(value)}
                  defaultValue={this.state.selectedTaxTypeList}
                  value={this.state.selectedTaxTypeList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="tax_type_slug"
                value={this.state.tax_type_slug}
              >
                <option key="" value="">
                  Select Tax Type
                </option>
                {this.state.taxTypeList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.tax_type_name}
                  </option>
                ))}
              </select> */}
            </InputGroup>
            {this.state.errors.tax_type_slug ? (
              <div className="text-danger">
                {this.state.errors.tax_type_slug}
              </div>
            ) : (
              this.validator.message(
                "tax_type_slug",
                this.state.tax_type_slug,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mb-3 mt-4">
              <label>
                {this.state.selected === 1 ? "Amount ($)" : "Percentage (%)"}{" "}
              </label>
              <div className="col-md-12 p-0">
                <div className="input-group-prepend prefix-dollar">
                  {this.state.selected === 1 ? (
                    <span className="input-group-text">$</span>
                  ) : (
                    ""
                  )}
                  <Form.Control
                    onChange={this.changeHandler}
                    name="general_tax_value"
                    value={
                      this.state.general_tax_value
                        ? this.state.general_tax_value.replace(
                            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                            ""
                          )
                        : "" || ""
                    }
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="50"
                    onKeyPress={global.onKeyPressEvent.floatValidation}
                    autoComplete="off"
                    // placeholder={
                    //   this.state.selected === 1
                    //     ? "Amount ($)"
                    //     : "Percentage (%)"
                    // }
                  />
                  {this.state.selected !== 1 ? (
                    <span className="input-group-text">%</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.state.errors.general_tax_value ? (
                <div className="text-danger">
                  {this.state.errors.general_tax_value}
                </div>
              ) : (
                ""
              )}
            </InputGroup>

            <InputGroup className="mt-3">
              <label>
                Country <span className="optional">(Optional)</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newCountryList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCountryList(value)}
                  defaultValue={this.state.selectedCountryList}
                  value={this.state.selectedCountryList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="country_slug"
                value={this.state.country_slug}
              >
                <option key="" value="">
                  Select Country
                </option>
                {this.state.countryList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.country_name}
                  </option>
                ))}
              </select> */}
            </InputGroup>

            <InputGroup className="mt-3">
              <label>
                State <span className="optional">(Optional)</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  isMulti
                  styles={customStyles}
                  menuPlacement="auto"
                  defaultValue={this.state.selectedStateList}
                  value={this.state.selectedStateList}
                  className="multiselect"
                  options={this.state.newStateList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeState(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>

            <InputGroup className="mt-3">
              <label>
                County <span className="optional">(Optional)</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  isMulti
                  styles={customStyles}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newCountyList}
                  value={this.state.selectedCountyList}
                  defaultValue={this.state.selectedCountyList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCounty(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>

            <InputGroup className="mt-3">
              <label>
                City <span className="optional">(Optional)</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  isMulti
                  styles={customStyles}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newCityList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCity(value)}
                  defaultValue={this.state.selectedCityList}
                  value={this.state.selectedCityList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditGeneralTaxModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateGeneralTax}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete Tax Type popup modal */}
        <Modal
          show={this.state.deleteGeneralTaxModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteGeneralTaxModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteGeneralTaxModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteGeneralTaxModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteGeneralTax(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default GeneralTax;
