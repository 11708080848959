////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Preview.jsx                                     //
//  Application: Preview                                     //
//  Option: For view Preview details                         //
//  Developer: Ashish Kumar 				                      //
//  Date: 2022-04-12                                     //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import ListingService from "../../services/ListingService";
import TaxService from "../../services/TaxService";
import PropertyCostService from "../../services/PropertyCostService";
import Alerts from "../common/Alerts";
import Moment from "moment";
// import { Link } from "react-router-dom";
import { Modal, Table, Carousel, ListGroup } from "react-bootstrap";
import hostImg from "./../../assets/images/profile-nogender.png";
// import HTMLEllipsis from "react-lines-ellipsis/lib/html";
// import renderHTML from "react-render-html";
import MultiImageService from "../../services/MultiImageService";
import AmenityService from "../../services/AmenityService";
import close from "../../assets/images/close-wel.svg";
import Gallery from "react-grid-gallery";
import GalleryProperty from "react-grid-gallery";

import zoomImg from "../../assets/images/zoom.svg";
import PetPolicyService from "../../services/PetPolicyService";
import ReactDatatable from "@ashvin27/react-datatable";
// import ShowMoreText from "react-show-more-text";
export class Preview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bed_type_name: "",
      balcony_name: "",
      toilet_name: "",
      bathroom: "",
      descShow: 0,
      descShow2: 0,
      descShowGuestManual: 0,
      descShow1: 0,
      descShowTheSpace: 0,
      status_id: "",
      newMultiImageList: [],
      errorList: [],
      domain_name: "",
      multiImageListTotal: 0,
      newMultiImageListPro: [],
      multiImageListTotalPro: 0,
      multiImageListPro: [],
      auto_pricing: false,
      listingPriceLab: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "from_date",
          text: "From",
          className: "from_date",
          sortable: true,
          cell: (record) => {
            return record.from_date
              ? Moment(record.from_date, "YYYY/MM/DD").format(global.dateFormat)
              : "";
          },
        },
        {
          key: "to_date",
          text: "To",
          className: "to_date",
          sortable: true,
          cell: (record) => {
            return record.to_date
              ? Moment(record.to_date, "YYYY/MM/DD").format(global.dateFormat)
              : "";
          },
        },
        {
          key: "special_price",
          text: "Nightly Rate",
          className: "special_price",
          sortable: true,
          cell: (record) => {
            return record.special_price
              ? "$" +
                  global.onKeyPressEvent.numberWithCommasForDecimal(
                    record.special_price.replace(/\.00$/, "")
                  )
              : "";
          },
        },
      ],
    };
    this.fullText = this.fullText.bind(this);
    this.fullTextTheSpace = this.fullTextTheSpace.bind(this);
    this.fullText2 = this.fullText2.bind(this);
    this.openWebsite = this.openWebsite.bind(this);
    this.fullText1 = this.fullText1.bind(this);
    this.fullTextGuestManual = this.fullTextGuestManual.bind(this);
    this.openCustomLinkWebsite = this.openCustomLinkWebsite.bind(this);
    this.confirmImportModal = this.confirmImportModal.bind(this);
    this.closeConfirmImportModal = this.closeConfirmImportModal.bind(this);
    this.publishListing = this.publishListing.bind(this);
  }

  async componentDidMount() {
    if (
      this.props.listing_slug !== undefined ||
      this.state.listing_slug !== undefined
    ) {
      var resData = {};
      var listingData = {};
      let res = await ListingService.getListing(this.props.listing_slug);

      if (global.successStatus.includes(res.status)) {
        listingData = res.data ? res.data : {};
        resData.brand_id = res.data.property ? res.data.property.brand_id : "";
        resData.theme = res.data.property ? res.data.property.theme : "";
        resData.property_seo_slug = res.data.property
          ? res.data.property.property_seo_slug
          : "";
        resData.listing_seo_slug = res.data ? res.data.listing_seo_slug : "";
        resData.domain_name =
          res.data && res.data.property ? res.data.property.domain_name : "";
        resData.listing_name = res.data.listing_name
          ? res.data.listing_name
          : "";

        resData.headline = res.data.ruListingData
          ? res.data.ruListingData.headline
          : "";
        resData.guest_manual = res.data.guest_manual
          ? res.data.guest_manual
          : "";
        resData.unit_number = res.data.unit ? res.data.unit.unit_number : "";
        resData.unit_name = res.data.unit ? res.data.unit.unit_name : "";
        resData.floor_plan_name =
          res.data &&
          res.data.unit &&
          res.data.unit.floor_plan &&
          res.data.unit.floor_plan.property_floor_plan_name
            ? res.data.unit.floor_plan.property_floor_plan_name
            : "";
        resData.room_type = res.data.room_type
          ? res.data.room_type.room_type_name
          : "";
        resData.room_category_name = res.data.room_category
          ? res.data.room_category.room_category_name
          : "";

        resData.room_size = res.data.room_size ? res.data.room_size : "";
        resData.bedroom_lock = res.data.bedroom_lock
          ? res.data.bedroom_lock
          : "";
        resData.suit = res.data.suit ? res.data.suit : "";
        resData.host_photo = res.data.host_photo ? res.data.host_photo : "";
        resData.host_photo_url = res.data.host_photo_url
          ? res.data.host_photo_url
          : hostImg;
        resData.host_photo_thumb_url = res.data.host_photo_thumb_url
          ? res.data.host_photo_thumb_url
          : hostImg;

        resData.host_name = res.data.host_name ? res.data.host_name : "";
        resData.host_email = res.data.host_email ? res.data.host_email : "";

        resData.host_bio = res.data.host_bio ? res.data.host_bio : "";
        resData.listing_type = res.data.listing_type
          ? res.data.listing_type
          : "";
        resData.bed_type = res.data.bed_type ? res.data.bed_type : "";
        resData.balcony = res.data.balcony ? res.data.balcony : "";
        resData.toilet = res.data.toilet ? res.data.toilet : "";
        resData.bathroom = res.data.bathroom ? res.data.bathroom : "";
        resData.kitchen = res.data.kitchen ? res.data.kitchen : "";
        resData.living_room = res.data.living_room ? res.data.living_room : "";
        resData.status_id = res.data.status_id === 1 ? "Active" : "Inactive";
        resData.bedroom =
          res.data &&
          res.data.unit &&
          res.data.unit.floor_plan &&
          res.data.unit.floor_plan.bedroom
            ? res.data.unit.floor_plan.bedroom
            : "";
        resData.bath =
          res.data &&
          res.data.unit &&
          res.data.unit.floor_plan &&
          res.data.unit.floor_plan.bath
            ? res.data.unit.floor_plan.bath
            : "";
        resData.maximum_number_of_guests =
          res.data.unit && res.data.unit.maximum_number_of_guests
            ? res.data.unit.maximum_number_of_guests
            : "";
        resData.custom_link = res.data.custom_link ? res.data.custom_link : "";
        resData.wi_fi_name = res.data.wi_fi_name ? res.data.wi_fi_name : "";
        resData.wi_fi_password = res.data.wi_fi_password
          ? res.data.wi_fi_password
          : "";
        resData.parking_slot = res.data.parking_slot
          ? res.data.parking_slot
          : "";
        resData.listing_pet_allowed =
          res.data.listing_pet_allowed === 1 ? true : false;
        resData.property_pet_allowed = res.data.property
          ? res.data.property.pet_allowed
          : false;
        resData.airbnb_account_id = res.data.airbnb_account_id
          ? res.data.airbnb_account_id
          : "";
        resData.license_number = res.data.ruListingLicenseData
          ? res.data.ruListingLicenseData.licensenumber
          : "";
        resData.issue_date =
          res.data.ruListingLicenseData &&
          res.data.ruListingLicenseData.issue_date
            ? Moment(
                res.data.ruListingLicenseData.issue_date,
                "YYYY-MM-DD"
              ).format(global.dateFormat)
            : null;
        resData.expiration_date =
          res.data.ruListingLicenseData &&
          res.data.ruListingLicenseData.expiration_date
            ? Moment(
                res.data.ruListingLicenseData.expiration_date,
                "YYYY-MM-DD"
              ).format(global.dateFormat)
            : null;
        resData.exemption_reason = res.data.ruListingLicenseData
          ? res.data.ruListingLicenseData.exemption_reason
          : "";
        resData.nightly_rate = res.data.nightly_rate
          ? parseFloat(res.data.nightly_rate)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";

        resData.from_date = res.data.from_date
          ? Moment(res.data.from_date, "MM/DD/YYYY").format(global.dateFormat)
          : "";
        resData.to_date = res.data.to_date
          ? Moment(res.data.to_date, "MM/DD/YYYY").format(global.dateFormat)
          : "";
        resData.listing_description = res.data.listing_description
          ? res.data.listing_description
          : "";
        resData.the_space = res.data.the_space ? res.data.the_space : "";
        resData.nightly_rate = res.data.nightly_rate
          ? res.data.nightly_rate
          : "";
        resData.security_deposite = res.data.security_deposite
          ? parseFloat(res.data.security_deposite)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.taxes = res.data.taxes
          ? parseFloat(res.data.taxes)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.additional_fee = res.data.additional_fee
          ? parseFloat(res.data.additional_fee)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.total_nightly_fee = res.data.total_nightly_fee
          ? parseFloat(res.data.total_nightly_fee)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.cleaning_fee = res.data.cleaning_fee
          ? parseFloat(res.data.cleaning_fee)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.auto_pricing =
          res.data && res.data.auto_pricing && res.data.auto_pricing === 1
            ? true
            : false;
        // resData.total_oh_earning = res.data.total_oh_earning
        //   ? res.data.total_oh_earning
        //   : "";
        // resData.total_property_earning = res.data.total_property_earning
        //   ? res.data.total_property_earning
        //   : "";
        // resData.total_amount = res.data.total_amount
        //   ? res.data.total_amount
        //   : "";
        res.data.masterData.map((item) => {
          if (resData.listing_type === item.slug) {
            const renting_type = item.name;
            this.setState({ renting_type_name: renting_type });
          }
          if (resData.bed_type === item.slug) {
            const bed_type = item.name;
            this.setState({ bed_type_name: bed_type });
          }
          if (resData.balcony === item.slug) {
            const balcony = item.name;
            this.setState({ balcony_name: balcony });
          }
          if (resData.toilet === item.slug) {
            const toilet = item.name;
            this.setState({ toilet_name: toilet });
          }
          if (resData.bathroom === item.slug) {
            const bathroom = item.name;
            this.setState({ bathroom_name: bathroom });
          }
          if (resData.kitchen === item.slug) {
            const kitchen = item.name;
            this.setState({ kitchen_name: kitchen });
          }
          if (resData.living_room === item.slug) {
            const living_room = item.name;
            this.setState({ living_room_name: living_room });
          }
        });
        //for resident
        resData.first_name =
          res.data.residentUser && res.data.residentUser.first_name
            ? res.data.residentUser.first_name
            : "";
        resData.last_name =
          res.data.residentUser && res.data.residentUser.last_name
            ? res.data.residentUser.last_name
            : "";
        resData.email =
          res.data.residentUser && res.data.residentUser.email
            ? res.data.residentUser.email
            : "";
        if (res.data.residentUser && res.data.residentUser.phone_number) {
          this.phoneNumberAutoFormat(res.data.residentUser.phone_number);
        }

        if (resData.auto_pricing === true) {
          this.getListingPirceLabData();
        } else {
          this.getSpecialPriceData();
          this.getLosDiscountData();
        }
        this.getUnavailableData();
      }

      this.getPetPolicyData();
      this.getMulitImagesData();
      this.getListingLevelAmenitiesData();
      this.getFloorPlanLevelAmenitiesData();
      this.getUnitLevelAmenitiesData();
      this.getFeesData();
      this.getTaxData();
      this.getSTRData();
      this.setState(resData);
      this.setState({ listingData: listingData });
      this.getPropertyLevelAmenitiesData();
      this.getMulitImagesDataProperty();
    }
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    this.setState({ phone_number: finalnumber });
  };

  async getSpecialPriceData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    var checkDate = {};
    var dates = [];
    let res = await ListingService.getListingSpecialPriceList(
      "listing_slug=" +
        this.props.listing_slug +
        (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      checkDate = res.data ? res.data : {};

      totalRecords = list.length ? list.length : 0;
      //totalRecords = res.data ? res.data.total : 0
    }
    if (totalRecords > 0) {
      checkDate.map((item, index) => {
        let staretDate = new Date(item.from_date + " 00:00:00");
        let endDate = new Date(item.to_date + " 00:00:00");
        const date = new Date(staretDate.getTime());

        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      });
      this.setState({ disabled_dates: dates });
    } else {
      this.setState({ disabled_dates: [] });
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  async getLosDiscountData(queryString = "") {
    var list1 = [];
    var totalRecords1 = 0;
    var checkDate1 = {};
    var dates1 = [];
    let res = await ListingService.getListingLOSDiscountList(
      "listing_slug=" +
        this.props.listing_slug +
        (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      list1 = res.data ? res.data : [];
      checkDate1 = res.data ? res.data : {};

      totalRecords1 = list1.length ? list1.length : 0;
      //totalRecords = res.data ? res.data.total : 0
    }
    if (totalRecords1 > 0) {
      checkDate1.map((item, index) => {
        let staretDate1 = new Date(item.from_date + " 00:00:00");
        let endDate1 = new Date(item.to_date + " 00:00:00");
        const date1 = new Date(staretDate1.getTime());

        while (date1 <= endDate1) {
          dates1.push(new Date(date1));
          date1.setDate(date1.getDate() + 1);
        }
      });
      this.setState({ los_disabled_dates: dates1 });
    } else {
      this.setState({ los_disabled_dates: [] });
    }
    this.setState({
      total_record1: totalRecords1,
      records1: list1,
    });
  }

  async getListingPirceLabData(queryString = "") {
    var listingPriceLab = [];
    var listingPriceLabTotal = 0;
    let res = await ListingService.getListingPriceLabList(
      "listing_slug=" +
        this.props.listing_slug +
        (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      listingPriceLab = res.data ? res.data.data : [];
      listingPriceLabTotal = res.data ? res.data.total : 0;
    }
    this.setState({
      listingPriceLabTotal: listingPriceLabTotal,
      listingPriceLab: listingPriceLab,
    });
  }

  tableChangeHandler1 = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      // queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getListingPirceLabData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getListingPirceLabData(queryString);
    }
  };

  async getPetPolicyData() {
    //this.setState({ showSpinner: true, loading: true });
    var multiList = [];
    var multiListTotal = 0;

    let res = await PetPolicyService.getPetPolicy(
      this.props.property_slug + "/3"
    );
    if (global.successStatus.includes(res.status)) {
      multiList = res.data.petPolicyRestrictions
        ? res.data.petPolicyRestrictions
        : [];
      multiListTotal = multiList ? multiList.length : 0;
    }
    this.setState({
      multiList: multiList,
      multiListTotal: multiListTotal,
    });
    //this.setState({ showSpinner: false, loading: false });
  }

  async getUnavailableData(queryString = "") {
    var UnavailableDateList = [];
    var UnavailableDateListTotal = 0;
    let res = await ListingService.getListingUnavailableDateList(
      "listing_slug=" +
        this.props.listing_slug +
        "&key=resident_stay" +
        (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      UnavailableDateList = res.data ? res.data : [];
      UnavailableDateListTotal = UnavailableDateList.length
        ? UnavailableDateList.length
        : 0;
    }

    this.setState({
      UnavailableDateListTotal: UnavailableDateListTotal,
      UnavailableDateList: UnavailableDateList,
    });
  }

  async publishListing() {
    this.setState({ errorList: [] });
    let inputData = {
      //first tab data
      listing_slug: this.state.listing_slug ? this.state.listing_slug : "",
      property_slug: this.props.property_slug ? this.props.property_slug : "",
      unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
      listing_data: this.state.listingData ? this.state.listingData : {},
      //for host
      host_name: this.state.host_name ? this.state.host_name : "",
      host_email: this.state.host_email ? this.state.host_email : "",
      host_phone_number: this.state.host_phone_number
        ? this.state.host_phone_number
        : "",
      host_bio: this.state.host_bio ? this.state.host_bio : "",
      host_photo: this.state.host_photo ? this.state.host_photo : "",
      //for images
      images: this.state.newMultiImageList,
      //for amenities
      listing_amenities: this.state.listingAmenitiesList
        ? this.state.listingAmenitiesList
        : [],
      property_amenities: this.state.propertyLevelAmenitiesList
        ? this.state.propertyLevelAmenitiesList
        : [],
      floorplan_amenities: this.state.floorPlanLevelAmenitiesList
        ? this.state.floorPlanLevelAmenitiesList
        : [],
      unit_amenities: this.state.unitLevelAmenitiesList
        ? this.state.unitLevelAmenitiesList
        : [],
      //fees
      fees: this.state.costList ? this.state.costList : [],
      //taxes
      taxes: this.state.taxList ? this.state.taxList : [],
      //for price
      nightly_rate: this.state.nightly_rate ? this.state.nightly_rate : "",
      total_nightly_fee: this.state.total_nightly_fee
        ? this.state.total_nightly_fee
        : "",
      cleaning_fee: this.state.cleaning_fee ? this.state.cleaning_fee : "",
    };
    let res = await ListingService.publishListing(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
        errorList:
          res.data && res.data.result && res.data.result.error_list
            ? res.data.result.error_list
            : [],
      });
    }
  }

  async getMulitImagesData() {
    if (this.props.listing_slug !== undefined) {
      let inputData = {
        path: "listing_units",
        refTableId: 10,
        slug: this.props.listing_slug,
        mediaType: 1,
        property_slug: this.props.property_slug,
      };
      let res = await MultiImageService.getImages(inputData);

      var multiImageList = [];
      var multiImageListTotal = 0;
      if (global.successStatus.includes(res.status)) {
        multiImageList = res.data ? res.data : [];
        multiImageListTotal = multiImageList.length;
      }
      // const newMultiImageList =
      //   multiImageListTotal > 0
      //     ? multiImageList.map((opt, i) => ({
      //       src: opt.file_name,
      //       thumbnail: opt.mediumImg,
      //       caption: opt.description,
      //       tags: [{ value: <img src={zoomImg} alt="" />, key: i }],
      //     }))
      //     : [];
      multiImageList.map((opt, i) => {
        var orgFile = opt.file_name;
        var mediumImg = opt.medium_img;
        opt.file_name = mediumImg;
        opt.medium_img = mediumImg;
        multiImageList[i]["caption"] = opt.description ? opt.description : "";
        multiImageList[i]["src"] = orgFile;
        multiImageList[i]["thumbnail"] = mediumImg;
        // multiImageList[i]["thumbnailWidth"] = 1;
        // multiImageList[i]["thumbnailHeight"] = 1;
        // multiImageList[i]["rowHeight"] = 1;
        // multiImageList[i]["maxRows"] = 3;
        // multiImageList[i]["vwidth"] = 1;
        multiImageList[i]["main_image"] = orgFile;
        multiImageList[i]["tags"] = [
          {
            value: <img src={zoomImg} alt="" />,
            key: `zoom image ${i}`,
            title: `zoom image ${i}`,
          },
        ];
      });
      const newMultiImageList = multiImageList;
      this.setState({
        multiImageList: multiImageList,
        multiImageListTotal: multiImageListTotal,
        newMultiImageList: newMultiImageList,
      });
    }
  }

  /* to get property amenities data */
  async getListingLevelAmenitiesData() {
    this.setState({ showSpinner: true, loading: true });
    var listingAmenitiesList = [];
    var listingAmenitiesListTotal = 0;
    var selectedAmenities = [];
    let res = await AmenityService.getPropertyAmenities(
      this.props.listing_slug + "/2"
    );
    if (global.successStatus.includes(res.status)) {
      listingAmenitiesList = res.data ? res.data : [];
      listingAmenitiesListTotal = listingAmenitiesList
        ? listingAmenitiesList.length
        : 0;
    }
    listingAmenitiesList.forEach((opt) => {
      selectedAmenities.push(opt.slug);
    });

    this.setState({
      listingAmenitiesList: listingAmenitiesList,
      listingAmenitiesListTotal: listingAmenitiesListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get property level amenities data */
  async getPropertyLevelAmenitiesData() {
    var propertyLevelAmenitiesList = [];
    var propertyLevelAmenitiesListTotal = 0;
    let res = await AmenityService.getPropertyAmenities(
      this.props.property_slug + "/1"
    );
    if (global.successStatus.includes(res.status)) {
      propertyLevelAmenitiesList = res.data ? res.data : [];
      propertyLevelAmenitiesListTotal = propertyLevelAmenitiesList
        ? propertyLevelAmenitiesList.length
        : 0;
    }
    this.setState({
      propertyLevelAmenitiesList: propertyLevelAmenitiesList,
      propertyLevelAmenitiesListTotal: propertyLevelAmenitiesListTotal,
    });
  }

  /* to get floor plan level amenities data */
  async getFloorPlanLevelAmenitiesData() {
    var floorPlanLevelAmenitiesList = [];
    var floorPlanLevelAmenitiesListTotal = 0;
    let res = await AmenityService.getPropertyAmenities(
      this.props.floor_plan_slug + "/3"
    );
    if (global.successStatus.includes(res.status)) {
      floorPlanLevelAmenitiesList = res.data ? res.data : [];
      floorPlanLevelAmenitiesListTotal = floorPlanLevelAmenitiesList
        ? floorPlanLevelAmenitiesList.length
        : 0;
    }
    this.setState({
      floorPlanLevelAmenitiesList: floorPlanLevelAmenitiesList,
      floorPlanLevelAmenitiesListTotal: floorPlanLevelAmenitiesListTotal,
    });
  }

  async getMulitImagesDataProperty() {
    if (this.props.property_slug !== undefined) {
      let inputData = {
        path: "properties",
        refTableId: 3,
        slug: this.props.property_slug,
        mediaType: 1,
      };
      console.log("inputData", inputData);
      let res = await MultiImageService.getImages(inputData);
      var multiImageListPro = [];
      var multiImageListEdit = [];
      var multiImageListTotalPro = 0;
      if (global.successStatus.includes(res.status)) {
        multiImageListPro = res.data ? res.data : [];
        multiImageListTotalPro = multiImageListPro.length;
        if (multiImageListTotalPro > 0) {
          multiImageListPro.forEach((item, i) => {
            var sort_order = multiImageListPro.length;
            multiImageListEdit[item.sort_order] = sort_order - i;
          });
        }
      }
      multiImageListPro.map((opt, i) => {
        var orgFile = opt.file_name;
        var mediumImg = opt.medium_img;
        opt.file_name = mediumImg;
        opt.medium_img = mediumImg;
        opt.editModeCropImg = opt.cropImgSrc; //cropImg;  //orgFile;
        multiImageListPro[i]["caption"] = opt.description
          ? opt.description
          : "";
        multiImageListPro[i]["src"] = orgFile;
        multiImageListPro[i]["thumbnail"] = mediumImg;
        // multiImageListPro[i]["thumbnailWidth"] = 1;
        // multiImageListPro[i]["thumbnailHeight"] = 1;
        // multiImageListPro[i]["rowHeight"] = 1;
        // multiImageListPro[i]["maxRows"] = 3;
        // multiImageListPro[i]["vwidth"] = 1;
        multiImageListPro[i]["main_image"] = orgFile;
        multiImageListPro[i]["tags"] = [];
      });
      const newMultiImageListPro = multiImageListPro;
      setTimeout(() => console.log(newMultiImageListPro), 5000);
      this.setState({
        multiImageListPro: multiImageListPro,
        multiImageListTotalPro: multiImageListTotalPro,
        // multiImageListEdit: multiImageListEdit,
        newMultiImageListPro: newMultiImageListPro,
      });
    }
  }
  /* to get unit level amenities data */
  async getUnitLevelAmenitiesData() {
    var unitLevelAmenitiesList = [];
    var unitLevelAmenitiesListTotal = 0;
    let res = await AmenityService.getPropertyAmenities(
      this.props.unit_slug + "/4"
    );
    if (global.successStatus.includes(res.status)) {
      unitLevelAmenitiesList = res.data ? res.data : [];
      unitLevelAmenitiesListTotal = unitLevelAmenitiesList
        ? unitLevelAmenitiesList.length
        : 0;
    }
    this.setState({
      unitLevelAmenitiesList: unitLevelAmenitiesList,
      unitLevelAmenitiesListTotal: unitLevelAmenitiesListTotal,
    });
  }

  async getFeesData() {
    this.setState({ showSpinner: true, loading: true });
    var costList = [];
    var costListTotal = 0;

    let res = await PropertyCostService.getAllCosts(
      this.props.listing_slug + "/5"
    );
    if (global.successStatus.includes(res.status)) {
      costList = res.data ? res.data : "";
      costListTotal = costList ? costList.length : 0;
    }
    this.setState({ costList: costList, costListTotal: costListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  async getTaxData() {
    this.setState({ showSpinner: true, loading: true });
    var taxList = [];
    var taxListTotal = 0;
    let res = await TaxService.getTax(
      "property_slug=" + this.props.property_slug + "&is_active=1"
    );
    if (global.successStatus.includes(res.status)) {
      taxList = res.data ? res.data.data : [];
      taxListTotal = taxList ? taxList.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      taxListTotal: taxListTotal,
      taxList: taxList,
    });
    this.getTaxesData();
  }

  async getTaxesData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var masterTaxList = [];
    var masterTaxTotalRecords = 0;
    var both_total_record = 0;
    let res = await TaxService.getTax(
      "property_slug=" +
        this.props.property_slug +
        "&is_property=1" +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      masterTaxList = res.data ? res.data : [];
      masterTaxTotalRecords = masterTaxList ? masterTaxList.length : 0;
    }
    if (masterTaxTotalRecords > 0) {
      masterTaxList.map((item, i) => {
        masterTaxList[i]["property_level_tax_name"] = item.general_tax_name;
        masterTaxList[i]["tax_type_name"] = item.tax_type_name;
        masterTaxList[i]["property_amount"] = item.general_tax_value;
        masterTaxList[i]["status_id"] = item.status_id;
        masterTaxList[i]["is_disable"] = true;
      });
    }
    // this.setState({ showSpinner: false, loading: false, });
    this.setState({
      masterTaxTotalRecords: masterTaxTotalRecords,
      masterTaxList: masterTaxList,
    });
    both_total_record =
      parseInt(masterTaxTotalRecords) + parseInt(this.state.taxListTotal);
    if (this.state.taxListTotal !== 0) {
      this.setState({ taxList: [...this.state.taxList, ...masterTaxList] });
      this.setState({ taxListTotal: both_total_record });
    } else {
      this.setState({ taxList: masterTaxList });
      this.setState({ taxListTotal: masterTaxTotalRecords });
    }
  }

  async getSTRData() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};

    let res = await PropertyCostService.getSTRFees(
      "slug=" + this.props.property_slug + "&ref_table_id=3"
    );
    if (global.successStatus.includes(res.status)) {
      resData.pet_fee_for_guest = res.data ? res.data.fee_value : "";
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextTheSpace() {
    if (this.state.descShowTheSpace === 0) {
      this.setState({ descShowTheSpace: 1 });
    } else {
      this.setState({ descShowTheSpace: 0 });
    }
  }

  fullTextGuestManual() {
    if (this.state.descShowGuestManual === 0) {
      this.setState({ descShowGuestManual: 1 });
    } else {
      this.setState({ descShowGuestManual: 0 });
    }
  }

  fullText1() {
    if (this.state.descShow1 === 0) {
      this.setState({ descShow1: 1 });
    } else {
      this.setState({ descShow1: 0 });
    }
  }

  fullText2() {
    if (this.state.descShow2 === 0) {
      this.setState({ descShow2: 1 });
    } else {
      this.setState({ descShow2: 0 });
    }
  }

  getDataonTabClick() {
    this.getInfoData();
  }

  confirmImportModal(i) {
    this.setState({ importModal: true });
    this.setState({ carouselZoom: i });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  openWebsite() {
    // var path = this.state.domain_name
    // var path = this.state.domain_name
    //   ? this.state.domain_name
    //   : "";
    var path = process.env.REACT_APP_WEBSITE_ENDPOINT
      ? process.env.REACT_APP_WEBSITE_ENDPOINT
      : "https://www.orionhaus.com";
    var seoSlug = this.state.property_seo_slug
      ? this.state.property_seo_slug
      : this.props.property_slug;
    var seoSlug2 = this.props.listing_slug
      ? this.props.listing_slug
      : this.state.listing_slug;
    window.open(path + "/" + seoSlug + "/rooms/" + seoSlug2, "_blank");
  }
  openCustomLinkWebsite() {
    // var airBNBPath = process.env.REACT_AIRBNB_LISITING_ENDPOINT
    //   ? process.env.REACT_AIRBNB_LISITING_ENDPOINT
    //   : "https://www.airbnb.com/h/";
    // var airBNBUrl = this.state.custom_link
    //   ? this.state.custom_link.toLowerCase()
    //   : "";
    // window.open(airBNBPath + airBNBUrl, "_blank");
    var airBNBUrl = this.state.custom_link
      ? this.state.custom_link.toLowerCase()
      : "";
    window.open(airBNBUrl, "_blank");
  }
  render() {
    var path = process.env.REACT_APP_WEBSITE_ENDPOINT
      ? process.env.REACT_APP_WEBSITE_ENDPOINT
      : "https://www.orionhaus.com";
    var seoSlug = this.state.property_seo_slug
      ? this.state.property_seo_slug
      : this.props.property_slug;
    var seoSlug2 = this.props.listing_slug;
    const url = path + "/" + seoSlug + "/rooms/" + seoSlug2;

    // var airBNBPath = global.air_Bnb_create_url
    //   ? global.air_Bnb_create_url
    //   : "airbnb.com/h/";
    // const airBNBUrl = this.state.custom_link
    //   ? airBNBPath + this.state.custom_link.toLowerCase()
    //   : "";
    const airBNBUrl = this.state.custom_link
      ? this.state.custom_link.toLowerCase()
      : "";

    return (
      <>
        <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns listing_detail">
          <div className="d-flex flex-wrap justify-content-end bothBtn swiperBtns">
            {/* <button
              className="btn-success-outline-small"
              onClick={this.publishListing}
            >
              Publish
            </button> */}
          </div>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
            <Alerts
              show={this.state.showAlertModal}
              // show="true"
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
            {this.state.errorList.map((item, i) => {
              return (
                <>
                  <ListGroup>
                    <ListGroup.Item className="text-danger" key={i}>
                      {item}
                    </ListGroup.Item>
                  </ListGroup>
                </>
              );
            })}
          </div>
          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 mg-t-15">
            <h3 className="mg-l-0 mg-t-0 background-head">Preview Link</h3>
          </div>
          <div className="row col-md-12 mg-l-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 pd-xs-b-15">
            <h6
              className="media-body hand-cursor link-url"
              onClick={this.openWebsite}
            >
              {url}
            </h6>
          </div>
          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
            <h3 className="mg-l-0 mg-t-0 background-head">Info</h3>
          </div>
          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Listing Name</p>
              <p className="media-body">
                {this.state.listing_name ? this.state.listing_name : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Unit</p>
              <p className="media-body">
                {this.state.unit_name ? (
                  <a
                    className="link-url"
                    href={
                      "/properties/view/" +
                      this.props.property_slug +
                      "/units/" +
                      this.props.unit_slug +
                      "/unit-basic"
                    }
                  >
                    {this.state.unit_name}
                  </a>
                ) : (
                  this.state.unit_number
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Floor Plan</p>
              <p className="media-body">
                <a
                  className="link-url"
                  href={
                    "/properties/view/" +
                    this.props.property_slug +
                    "/floor-plans/view/" +
                    this.state.floor_plan_slug
                  }
                >
                  {this.state.floor_plan_name}
                </a>
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Listing Type</p>
              <p className="media-body">
                {this.state.renting_type_name
                  ? this.state.renting_type_name
                  : ""}
              </p>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">From</p>
              <p className="media-body">
                {this.state.from_date ? this.state.from_date : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">To</p>
              <p className="media-body">
                {this.state.to_date ? this.state.to_date : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Airbnb Link</p>
              {/* <p className="media-body">
                {this.state.custom_link ? this.state.custom_link : ""}
              </p> */}
              <p
                className="media-body hand-cursor break-word view-link"
                onClick={this.openCustomLinkWebsite}
              >
                {airBNBUrl ? airBNBUrl : ""}
              </p>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">
                {this.state.bedroom > 1 ? "Bedrooms" : "Bedroom"}
              </p>
              <p className="media-body">
                {this.state.bedroom ? this.state.bedroom : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">
                {this.state.bath > 1 ? "Bathrooms" : "Bathroom"}
              </p>
              <p className="media-body">
                {this.state.bath ? this.state.bath : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Guest Count</p>
              <p className="media-body">
                {this.state.maximum_number_of_guests
                  ? this.state.maximum_number_of_guests
                  : ""}
              </p>
            </div>
            {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p
                className="az-profile-name-text pl-0"
                title="Below pets are prohibited at property"
              >
                Prohibited Pets
              </p>
              <p className="media-body">
                {this.state.multiListTotal > 0
                  ? this.state.multiList.map(
                      (option, i) =>
                        option.pet_type_name +
                        (i < this.state.multiListTotal - 1 ? ", " : "") +
                        ""
                    )
                  : ""}
              </p>
            </div> */}
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Parking Space</p>
              <p className="media-body">
                {this.state.parking_slot ? this.state.parking_slot : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Wi-Fi Name</p>
              <p className="media-body">
                {this.state.wi_fi_name ? this.state.wi_fi_name : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Wi-Fi Password</p>
              <p className="media-body">
                {this.state.wi_fi_password ? this.state.wi_fi_password : ""}
              </p>
            </div>
            {this.state.property_pet_allowed === 1 ? (
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                <p className="az-profile-name-text pl-0">
                  Do you want to allow pets?
                </p>
                <p className="media-body">
                  {this.state.property_pet_allowed === 1
                    ? this.state.listing_pet_allowed === true
                      ? "Yes"
                      : "No"
                    : "Since the property is not pet friendly, you can not select pets in the listing."}
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">License Number</p>
              <p className="media-body">
                {this.state.license_number ? this.state.license_number : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Issue Date</p>
              <p className="media-body">
                {this.state.issue_date ? this.state.issue_date : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Expiration Date</p>
              <p className="media-body">
                {this.state.expiration_date ? this.state.expiration_date : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Airbnb Account ID</p>
              <p className="media-body">
                {this.state.airbnb_account_id
                  ? this.state.airbnb_account_id
                  : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Status</p>
              <p className="media-body">
                {this.state.status_id ? this.state.status_id : ""}
              </p>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12col-xxl-6">
              <p className="az-profile-name-text pl-0">Listing Description</p>
              <div className="media-body">
                {this.state.listing_description ? (
                  <p className="p_wrap1">
                    {this.state.descShow
                      ? this.state.listing_description
                      : this.state.listing_description.slice(
                          0,
                          global.showMoreLength
                        )}

                    {this.state.listing_description.length >
                      global.showMoreLength && (
                      <span onClick={this.fullText}>
                        {this.state.descShow ? (
                          <p className="hand-cursor showmore">Show Less </p>
                        ) : (
                          <p className="hand-cursor showmore">...Show more </p>
                        )}
                      </span>
                    )}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <p className="az-profile-name-text pl-0">The Space</p>
              <div className="media-body">
                {this.state.the_space ? (
                  <p className="p_wrap1">
                    {this.state.descShowTheSpace
                      ? this.state.the_space
                      : this.state.the_space.slice(0, global.showMoreLength)}

                    {this.state.the_space.length > global.showMoreLength && (
                      <span onClick={this.fullTextTheSpace}>
                        {this.state.descShowTheSpace ? (
                          <p className="hand-cursor showmore">Show Less </p>
                        ) : (
                          <p className="hand-cursor showmore">...Show more </p>
                        )}
                      </span>
                    )}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <p className="az-profile-name-text pl-0">Guest Manual</p>
              <div className="media-body">
                {this.state.guest_manual ? (
                  <p className="p_wrap1">
                    {this.state.descShowGuestManual
                      ? this.state.guest_manual
                      : this.state.guest_manual.slice(0, global.showMoreLength)}

                    {this.state.guest_manual.length > global.showMoreLength && (
                      <span onClick={this.fullTextGuestManual}>
                        {this.state.descShowGuestManual ? (
                          <p className="hand-cursor showmore">Show Less </p>
                        ) : (
                          <p className="hand-cursor showmore">...Show more </p>
                        )}
                      </span>
                    )}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {this.state.renting_type_name !== "Entire Unit" ? (
            <>
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 mg-t-0 background-head">Room</h3>
              </div>
              <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-l-30 pd-r-15">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">Room Category</p>
                  <p className="media-body">
                    {this.state.room_category_name
                      ? this.state.room_category_name
                      : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">Room Type</p>
                  <p className="media-body">
                    {this.state.room_type ? this.state.room_type : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">Bed Type</p>
                  <p className="media-body">
                    {this.state.bed_type_name ? this.state.bed_type_name : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">
                    Room Size (Sq. ft)
                  </p>
                  <p className="media-body">
                    {this.state.room_size
                      ? this.state.room_size.replace(/\.00$/, "")
                      : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">Balcony</p>
                  <p className="media-body">
                    {this.state.balcony_name ? this.state.balcony_name : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">Toilet </p>
                  <p className="media-body">
                    {this.state.toilet_name ? this.state.toilet_name : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">Bathroom </p>
                  <p className="media-body">
                    {this.state.bathroom_name ? this.state.bathroom_name : ""}
                  </p>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">Kitchen </p>
                  <p className="media-body">
                    {this.state.kitchen_name ? this.state.kitchen_name : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">Living Room </p>
                  <p className="media-body">
                    {this.state.living_room_name
                      ? this.state.living_room_name
                      : ""}
                  </p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">Bedroom Lock </p>
                  <p className="media-body">
                    {this.state.bedroom_lock === 1 ? "yes" : "No"}
                  </p>
                </div>
                {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">Suit</p>
                  <p className="media-body">
                    {this.state.suit === 1 ? "yes" : "No"}
                  </p>
                </div> */}
              </div>
            </>
          ) : (
            ""
          )}
          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
            <h3 className="mg-l-0 mg-t-15 background-head">Resident</h3>
          </div>
          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">First Name</p>
              <p className="media-body">
                {this.state.first_name ? this.state.first_name : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Last Name</p>
              <p className="media-body">
                {this.state.last_name ? this.state.last_name : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Phone</p>
              <p className="media-body">
                {this.state.phone_number ? this.state.phone_number : ""}
              </p>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
              <p className="az-profile-name-text pl-0">Email</p>
              <p className="media-body">
                {this.state.email ? this.state.email : ""}
              </p>
            </div>
          </div>
          {/* <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
            <h3 className="mg-l-0 mg-t-0 background-head">Host</h3>
          </div>
          <div className="row pd-l-15 pd-r-45 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
            <div className="col-md-12 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-0">
              <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <div className="row mg-0">
                    <div className="brand-sm-logo mg-b-8">
                      <figure>
                        <img
                          src={
                            this.state.host_photo_thumb_url
                              ? this.state.host_photo_thumb_url
                              : hostImg
                          }
                          alt=""
                          className="rounded-circle"
                        />
                      </figure>
                    </div>
                    <div className="mg-l-6">
                      <p className="az-profile-name-text pl-0">Name:</p>
                      <p className="media-body">
                        {this.state.host_name ? this.state.host_name : ""}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">Email </p>
                  <p className="media-body">
                    {this.state.host_email ? this.state.host_email : ""}
                  </p>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                  <p className="az-profile-name-text pl-0">Phone</p>
                  <p className="media-body">
                    {this.state.host_phone_number ?  this.state.host_phone_number : ""}
                  </p>
                </div>
              </div>

              <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <p className="az-profile-name-text pl-0">Bio/Story</p>
                  <div className="media-body">
                    {this.state.host_bio ? (
                      <p className="p_wrap1">
                        {this.state.descShow
                          ? this.state.host_bio.slice(0, global.showMoreLength)
                          : this.state.host_bio}

                        {this.state.host_bio.length > global.showMoreLength && (
                          <span onClick={this.fullText}>
                            {this.state.descShow ? (
                              <p className="hand-cursor showmore">
                                ...Show more{" "}
                              </p>
                            ) : (
                              <p className="hand-cursor showmore">Show Less </p>
                            )}
                          </span>
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div> */}
          {this.state.multiImageListTotal > 0 && (
            <>
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 mg-t-0 background-head">
                  {" "}
                  Listing Images
                </h3>
              </div>
              <div className="row row-xs align-items-center justify-content-center pd-t-0 mg-t-30 pd-xs-l-15 pd-xs-r-30 pd-lg-l-20 pd-lg-r-20 addmultiImages">
                <Gallery
                  images={
                    this.state.newMultiImageList
                      ? this.state.newMultiImageList
                      : ""
                  }
                  enableImageSelection={false}
                  showImageCount={false}
                />
              </div>
            </>
          )}
          {this.state.multiImageListTotalPro > 0 && (
            <>
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 mg-t-15 background-head">
                  {" "}
                  Property Images
                </h3>
              </div>
              <div className="row row-xs align-items-center justify-content-center pd-t-0 mg-t-30 pd-xs-l-15 pd-xs-r-30 pd-lg-l-20 pd-lg-r-20 addmultiImages">
                <GalleryProperty
                  backdropClosesModal={false}
                  preloadNextImage={true}
                  images={
                    this.state.newMultiImageListPro
                      ? this.state.newMultiImageListPro
                      : ""
                  }
                  enableImageSelection={false}
                  showImageCount={false}
                />
              </div>
            </>
          )}
          {this.state.listingAmenitiesListTotal > 0 ? (
            <>
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 mg-t-0 background-head">
                  Listing Amenities
                </h3>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-xs-l-0 pd-lg-l-45 pd-xs-r-0 pd-lg-r-15">
                    {/* <h3>What this place offers</h3> */}
                    <div className="aminityListView">
                      <ul className="attraction">
                        {this.state.listingAmenitiesListTotal > 0 ? (
                          this.state.listingAmenitiesList.map((item, i) => {
                            return (
                              <li className="m-2" key={i}>
                                {item.amenity_name ? item.amenity_name : ""}
                              </li>
                            );
                          })
                        ) : (
                          <h6 className="mg-t-20">
                            There are no listing amenities added in the system.
                            You may add them by amenities section.
                          </h6>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {this.state.propertyLevelAmenitiesListTotal > 0 ? (
            <>
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 mg-t-0 background-head">
                  Property Amenities
                </h3>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-xs-l-30 pd-lg-l-45 pd-xs-r-0 pd-lg-r-15">
                    {/* <h3>What this place offers</h3> */}
                    <div className="aminityListView">
                      <ul className="attraction">
                        {this.state.propertyLevelAmenitiesListTotal > 0 ? (
                          this.state.propertyLevelAmenitiesList.map(
                            (item, i) => {
                              return (
                                <li className="m-2" key={i}>
                                  {item.amenity_name ? item.amenity_name : ""}
                                </li>
                              );
                            }
                          )
                        ) : (
                          <h6 className="mg-t-20">
                            There are no property amenities added in the system.
                          </h6>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {this.state.floorPlanLevelAmenitiesListTotal > 0 ? (
            <>
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 mg-t-0 background-head">
                  Floor Plan Amenities
                </h3>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-xs-l-15 pd-lg-l-45 pd-xs-r-0 pd-lg-r-15">
                    {/* <h3>What this place offers</h3> */}
                    <div className="aminityListView">
                      <ul className="attraction">
                        {this.state.floorPlanLevelAmenitiesListTotal > 0 ? (
                          this.state.floorPlanLevelAmenitiesList.map(
                            (item, i) => {
                              return (
                                <li className="m-2" key={i}>
                                  {item.amenity_name ? item.amenity_name : ""}
                                </li>
                              );
                            }
                          )
                        ) : (
                          <h6 className="mg-t-20">
                            There are no floor plan amenities added in the
                            system.
                          </h6>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {this.state.unitLevelAmenitiesListTotal > 0 ? (
            <>
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 mg-t-0 background-head">
                  Unit Amenities
                </h3>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-xs-l-0 pd-lg-l-45 pd-xs-r-0 pd-lg-r-15">
                    {/* <h3>What this place offers</h3> */}
                    <div className="aminityListView">
                      <ul className="attraction">
                        {this.state.unitLevelAmenitiesListTotal > 0 ? (
                          this.state.unitLevelAmenitiesList.map((item, i) => {
                            return (
                              <li className="m-2" key={i}>
                                {item.amenity_name ? item.amenity_name : ""}
                              </li>
                            );
                          })
                        ) : (
                          <h6 className="mg-t-20">
                            There are no unit amenities added in the system.
                          </h6>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="row m-0">
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 mg-t-0 background-head">Price</h3>
            </div>
            <div className="row col-md-12 mg-md-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 border-bottom-0">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 pd-l-30 pd-r-30 pl-0 listing_detail">
                <p className="az-profile-name-text pl-0">PriceLabs Enabled</p>
                <p className="media-body">
                  {this.state.auto_pricing === true ? "Yes" : "No"}
                </p>
              </div>
              {this.state.auto_pricing === false ? (
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 pd-l-30 pd-r-30 pl-0">
                  <p className="az-profile-name-text pl-0">Base Price</p>
                  <p className="media-body">
                    {this.state.nightly_rate
                      ? "$" +
                        global.onKeyPressEvent.numberWithCommasForDecimal(
                          this.state.nightly_rate.replace(/\.00$/, "")
                        )
                      : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 pd-l-30 pd-r-30 pl-0">
                <p className="az-profile-name-text pl-0"> Cleaning Fee</p>
                <p className="media-body">
                  {this.state.cleaning_fee
                    ? "$" +
                      global.onKeyPressEvent.numberWithCommasForDecimal(
                        this.state.cleaning_fee.replace(/\.00$/, "")
                      )
                    : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 pd-l-30 pd-r-30 pl-0">
                <p className="az-profile-name-text pl-0">Security Deposit</p>
                <p className="media-body">
                  {this.state.security_deposite
                    ? "$" +
                      global.onKeyPressEvent.numberWithCommasForDecimal(
                        this.state.security_deposite.replace(/\.00$/, "")
                      )
                    : ""}
                </p>
              </div>
            </div>
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 background-head mg-t-0">General Fees</h3>
            </div>
            <div className="table-responsive pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <Table hover className="">
                <thead>
                  <tr>
                    <th className="w-20">Fee Type</th>
                    <th className="w-20">Payable By</th>
                    <th className="w-20">Payable At</th>

                    <th className="w-20">Refundable</th>
                    <th className="w-20">Estimated</th>
                    <th className="w-20 text-left">Mandatory</th>
                    <th className="w-20 text-left">Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.costListTotal > 0 ? (
                    <>
                      {this.state.costList.map((option, i) => {
                        return (
                          <tr
                            key={i}
                            // onClick={() => this.getCostDetails(item.slug)}
                          >
                            <td>
                              {option.cost_type
                                ? option.cost_type.cost_type_name
                                : ""}
                            </td>
                            <td>
                              {option.cost_payable_by
                                ? option.cost_payable_by.cost_payable_by_name
                                : ""}
                            </td>
                            <td>
                              {option.cost_payable_at
                                ? option.cost_payable_at.cost_payable_at_name
                                : ""}
                            </td>

                            <td>{option.refundable === 1 ? "Yes" : "No"}</td>
                            <td>{option.estimated === 1 ? "Yes" : "No"}</td>
                            <td className="text-left">
                              {option.mandatory === 1 ? "Yes" : "No"}
                            </td>
                            <td className="text-left">
                              {option.cost_name
                                ? "$" +
                                  global.onKeyPressEvent.numberWithCommasForDecimal(
                                    option.cost_name.replace(/\.00$/, "")
                                  )
                                : ""}
                            </td>
                          </tr>
                        );
                      })}{" "}
                      {/* <tr>
                        <td>
                          <strong>Total General Fees</strong>
                        </td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <strong>
                            {this.state.additional_fee
                              ? "$" +
                                global.onKeyPressEvent.numberWithCommasForDecimal(
                                  parseFloat(this.state.additional_fee)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")
                                )
                              : ""}
                          </strong>
                        </td>
                      </tr> */}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="9" align="center" className="text-center">
                        There are no general fees added in the system.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            <div className="col-lg-12 mg-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 background-head pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                Occupancy Tax Distribution
              </h3>
            </div>
            <div className="table-responsive pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <Table hover className="">
                <thead>
                  <tr>
                    <th className="w-30">Name</th>
                    <th className="w-30 text-left">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.taxListTotal > 0 ||
                  this.state.taxOccupancyListTotal > 0 ? (
                    <React.Fragment>
                      {this.state.taxList.map((option, i) => {
                        var aa = option.tax_type_name
                          ? option.tax_type_name
                          : option.tax_type
                          ? option.tax_type.tax_type_name
                          : "";
                        return (
                          <tr key={i}>
                            <td>
                              {option.property_level_tax_name
                                ? option.property_level_tax_name
                                : ""}
                            </td>

                            <td className="text-left">
                              {option.property_amount
                                ? aa.includes("$")
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      parseFloat(option.property_amount)
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                        .replace(
                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                          ""
                                        )
                                    )
                                  : parseFloat(option.property_amount)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                      .replace(
                                        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                        ""
                                      ) + "%"
                                : ""}
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  ) : (
                    <tr>
                      <td colSpan="9" align="center">
                        There are no occupancy taxes added in the system.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <div className="background-head d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                <h3 className="mb-0">Auto Pricing (PriceLabs)</h3>
              </div>
            </div>
            {this.state.auto_pricing === true ? (
              <React.Fragment>
                <div className="col-lg-12 pd-xs-l-15 pd-lg-l-0 pd-xs-r-0 pd-lg-r-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    {this.state.listingPriceLabTotal > 0 ? (
                      <div className="mg-t-18 brandList auto-pricing-tbl">
                        <div className="pd-lg-l-0 smart_price_row">
                          <ReactDatatable
                            className="table mg-b-30 pd-b-2 add-no-wrap"
                            config={this.state.config}
                            records={this.state.listingPriceLab}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            total_record={this.state.listingPriceLabTotal}
                            onChange={this.tableChangeHandler1}
                          />
                        </div>
                      </div>
                    ) : (
                      <p className="pets_no_text">
                        Adjust your PriceLabs settings directly from your
                        PriceLabs account.
                      </p>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-30 pd-lg-b-20">
                PriceLabs automated price is not enabled.
              </p>
            )}
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <div className="background-head d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                <h3 className="mb-0">Seasonal Price</h3>
              </div>
            </div>
            {this.state.auto_pricing === false ? (
              <React.Fragment>
                <div className="row col-md-12 mg-lg-t-0 mg-xs-t-10 mg-md-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 border-bottom-0">
                  <div className="table-responsive">
                    <Table hover className="table_heading_top">
                      <thead>
                        <tr>
                          <th className="w-40">From</th>
                          <th className="w-40">To</th>
                          <th className="w-40">Nightly Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.total_record > 0 ? (
                          this.state.records.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {Moment(item.from_date, "YYYY/MM/DD").format(
                                    global.dateFormat
                                  )}
                                </td>
                                <td>
                                  {Moment(item.to_date, "YYYY/MM/DD").format(
                                    global.dateFormat
                                  )}
                                </td>
                                <td>
                                  {item.special_price
                                    ? "$" +
                                      global.onKeyPressEvent.numberWithCommasForDecimal(
                                        item.special_price.replace(/\.00$/, "")
                                      )
                                    : ""}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="9" align="center">
                              There is no seasonal pricing added in the system.
                              You may add them by clicking Add button.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-30 pd-lg-b-20">
                Since pricelabs is enabled, you can not select seasonal price.
              </p>
            )}
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <div className="background-head d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                <h3 className="mb-0"> Discounts: Length of Stay</h3>
              </div>
            </div>
            {this.state.auto_pricing === false ? (
              <React.Fragment>
                <div className="row col-md-12 mg-lg-t-0 mg-xs-t-10 mg-md-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 border-bottom-0">
                  <div className="table-responsive">
                    <Table hover className="table_heading_top">
                      <thead>
                        <tr>
                          <th className="w-40">From</th>
                          <th className="w-40">To</th>
                          <th className="w-40">Stays From (days)</th>
                          <th className="w-40">Stays Up To (days) </th>
                          <th className="w-40">Discount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.total_record1 > 0 ? (
                          this.state.records1.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {Moment(item.from_date, "YYYY/MM/DD").format(
                                    global.dateFormat
                                  )}
                                </td>
                                <td>
                                  {Moment(item.to_date, "YYYY/MM/DD").format(
                                    global.dateFormat
                                  )}
                                </td>
                                <td>
                                  {item.stays_from ? item.stays_from : ""}
                                </td>
                                <td>
                                  {item.stays_upto ? item.stays_upto : ""}
                                </td>
                                <td>
                                  {item.discount
                                    ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                        item.discount
                                      ) + "%"
                                    : ""}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="9" align="center">
                              There are no length of stay discounts added in the
                              system. You may add them by clicking Add button.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-30 pd-lg-b-20">
                Since pricelabs is enabled, you can not select length of stay
                discounts.
              </p>
            )}
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 background-head mg-t-0">Resident Stay</h3>
            </div>
            <div className="table-responsive pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <Table hover className="">
                <thead>
                  <tr>
                    <th className="w-40">From</th>
                    <th className="w-40">To</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.UnavailableDateListTotal > 0 ? (
                    this.state.UnavailableDateList.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {Moment(item.from_date, "YYYY/MM/DD").format(
                              global.dateFormat
                            )}
                          </td>
                          <td>
                            {Moment(item.to_date, "YYYY/MM/DD").format(
                              global.dateFormat
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="9" align="center">
                        There are no unavailable date added in the system.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.importModal}
          onHide={() => this.closeConfirmImportModal()}
          centered
          className="propertylistmodal"
        >
          <Modal.Body>
            <span
              className="welcomeClose"
              onClick={() => this.closeConfirmImportModal()}
            >
              <img src={close} alt="" />
            </span>
            <Carousel
              activeIndex={this.state.carouselZoom}
              onSelect={this.confirmImportModal}
              prevIcon={
                this.state.multiImageListTotal === 1 ? (
                  false
                ) : (
                  <span
                    aria-hidden="true"
                    className="carousel-control-prev-icon"
                  />
                )
              }
              nextIcon={
                this.state.multiImageListTotal === 1 ? (
                  false
                ) : (
                  <span
                    aria-hidden="true"
                    className="carousel-control-next-icon"
                  />
                )
              }
            >
              {this.state.multiImageListTotal > 0
                ? this.state.multiImageList.map((option, i) => (
                    <Carousel.Item key={i}>
                      <img
                        src={option.file_name}
                        className="d-block w-100 op-3"
                        alt="..."
                      />
                      {option.description !== undefined &&
                      option.description !== null &&
                      option.description !== "" &&
                      option.description !== "undefined" ? (
                        <Carousel.Caption>
                          <p>{option.description ? option.description : ""}</p>
                        </Carousel.Caption>
                      ) : (
                        ""
                      )}
                    </Carousel.Item>
                  ))
                : ""}
            </Carousel>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Preview;
