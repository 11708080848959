////////////////////////////////////////////////////////////
//     							                          //
//  Program: AirDNACityMapping.jsx                         //
//  Application: AirDNA city lookup                        //
//  Developer: Devendra				                          //
//  Date: 07/06/2023                    //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
// import LookUpMappingService from "../../services/LookUpMappingService";
import { Container, Button } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import { Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import RUMappingService from "../../services/RUMappingService";
// import deleteicon from "../../assets/images/delete.svg";

export class AirDNACityMapping extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      isPageLoaded: true,
      open: true,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      is_available: "",
      available_from: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      airdna_address: "",
      unitsFilter: [],
      mappingArray: [],
      city_address: "",
      airdna_city_index: "",
      city_data: [],
      pro_city_id: [],
      mapping_city: [],
      air_dna_mapping: [],
      newStatusList: [
        { label: "Pending", value: 2 },
        { label: "Completed", value: 1 },
      ],
      selectedStatusList: [],
      selectedCleaningList: [],
      tab: 6,
      filter_value: "",
      lastUpdatedSlug: "",
      listing_slug: this.props.match.params.listingSlug,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "scheduled_on",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "scheduled_on",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: false,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "city_name",
          text: "City",
          className: "city_name",
          sortable: true,
          cell: (record) => {
            return record.city_name;
          },
        },
        {
          key: "inspection_status",
          text: "AirDNA City",
          className: "inspection_status drop_cust_w",
          sortable: true,
          cell: (record, index) => {
            return (
              <div className="text_btn_both">
                <Form.Control
                  onChange={(event) => this.changeHandler(event, index)}
                  name="city_address"
                  value={record.city_address}
                  className="form-control"
                  type="text"
                  placeholder="Please enter city name, state code"
                />
                {global.userPermissions.checkPermission(
                  "turnoverbnb-mapping-add"
                ) && (
                  <button
                    type="button"
                    className="btn btn-sync"
                    onClick={this.getCityId}
                  >
                    <i className="fas fa-sync"></i>
                  </button>
                )}
              </div>
            );
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.setMapping = this.setMapping.bind(this);
    this.getCityId = this.getCityId.bind(this);
  }
  async componentDidMount() {
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }
  changeHandler = (event, index) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
      airdna_city_index: index,
    });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customFilter() {
    let queryString = "&status_id=" + this.state.status_id + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ filter_value: "", status_id: "", selectedStatusList: [] });
    this.getData();
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
            window.scrollTo(0, 0);
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(this.state.tab, queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(this.state.tab, queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "AirDna Cities Mapping";
  }
  getCityId = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        city_address: this.state.city_address,
      };
      let res = await RUMappingService.getAirDnaCityData(inputData);
      if (global.successStatus.includes(res.status)) {
        var totalRecords = 0;
        var newList1 = [];
        var newList2 = [];
        var air_dna_city_data = {};
        var mappingArray = [];
        newList2 = this.state.pro_city_id;
        totalRecords = res.data ? res.data.num_items : 0;
        newList1 =
          totalRecords > 0
            ? newList2.forEach((item2, index2) => {
                res.data.items.forEach((item, i) => {
                  if (this.state.airdna_city_index === index2) {
                    air_dna_city_data = {
                      property_id: item2.property_id,
                      property_city_id: item2.property_city_id,
                      airdna_city_id: item.city.id,
                      city_address: this.state.city_address,
                    };
                    mappingArray.push(air_dna_city_data);
                  }
                });
              })
            : [];
        this.setState({
          city_address: newList1,
          air_dna_mapping: mappingArray,
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
    }
  };

  async getData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    var newList = [];
    let res = await RUMappingService.getPropertyCitiesLookUpMappingList(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.cityData : [];
      totalRecords = res.data.cityData ? res.data.cityData.length : 0;
      newList =
        totalRecords > 0
          ? res.data.cityData.map((item, index) => ({
              property_city_id: item.city_id,
              property_city_name: item.city_name,
              state_name: item.state_name,
              property_id: item.get_properties,
            }))
          : [];
    }
    this.setState({
      total_record: totalRecords,
      pro_city_id: newList,
      records: list,
    });
  }
  async setMapping() {
    let inputData = {
      mappingArray: this.state.air_dna_mapping,
    };
    var res = await RUMappingService.setAirDnaCitiesMapping(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      window.location.reload();
    } else {
      if (res.data.result.errorDetail) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
    }
  }

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <MasterSidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right settingsidebarNav">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {global.userPermissions.checkPermission(
                        "master-data-update"
                      ) && (
                        <Button
                          variant="outline-dark btn-block btn-with-icon"
                          onClick={this.setMapping}
                          disabled={
                            this.state.air_dna_mapping.length > 0 ? false : true
                          }
                        >
                          <img src={pluswIcon} alt="" /> Save
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="col-md-12 pd-lg-l-45 pd-lg-r-30 pd-md-l-45 pd-md-r-30 pd-xs-l-15 pd-xs-r-0">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>

                  {this.state.total_record !== 0 ? (
                    <div className="mg-t-18 brandList">
                      <div className="pd-lg-l-15 pd-xs-l-15 pd-md-l-30 lookup_table">
                        <ReactDatatable
                          className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList mg-b-100 table_last_row "
                          config={
                            this.state.total_record > global.page_size
                              ? this.state.config
                              : this.state.config
                          }
                          records={this.state.records}
                          columns={this.state.columns}
                          extraButtons={this.state.extraButtons}
                          dynamic={true}
                          // loading={this.state.loading}
                          total_record={this.state.total_record}
                          onChange={this.tableChangeHandler}
                          onRowClicked={this.rowClickedHandler}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="mg-t-30 text-center">
                      <p>No cities mapping</p>
                    </div>
                  )}
                </div>

                {this.state.total_record !== 0 ? (
                  <div className="mg-t-18 brandList">
                    <div className="pd-lg-l-15 pd-xs-l-15 pd-md-l-30 lookup_table">
                      <ReactDatatable
                        className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList mg-b-100 table_last_row "
                        config={
                          this.state.total_record > global.page_size
                            ? this.state.config
                            : this.state.config
                        }
                        records={this.state.records}
                        columns={this.state.columns}
                        extraButtons={this.state.extraButtons}
                        dynamic={true}
                        // loading={this.state.loading}
                        total_record={this.state.total_record}
                        onChange={this.tableChangeHandler}
                        onRowClicked={this.rowClickedHandler}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="mg-t-30 text-center">
                    <p>No cities mapping</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default AirDNACityMapping;