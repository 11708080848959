////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Faqs.jsx                                //
//  Application:  faqs                                   //
//  Option: listing faqs                       //
//  Developer: Saurabh Shukla                          //
//  Date: 2022-02-09                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { Container, Modal, Tab, Nav } from "react-bootstrap"; // InputGroup
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import pluswIcon from "./../../assets/images/plus.svg";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import MultiImageService from "../../services/MultiImageService";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Alerts from "../common/Alerts";
import Moment from "moment";
import FaqService from "../../services/FaqService";
import PropertyService from "../../services/PropertyService";
import Select from "react-select";
import queryString from "query-string";
import NoData from "../common/NoData";

let queryParams = queryString.parse(window.location.search);

export class Faqs extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      eventKey: "0",
      total_record: 0,
      records: [],
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      status_id: "",
      selectedRentingTypeList: "",
      propertyList: [],
      propertyListTotal: 0,
      newPropertyList: [],
      selectedPropertyList: [],
      kbCategoryList: [],
      newkbCategoryList: [],
      newkbCategoryListTotal: 0,
      selectedkbCategoryList: [],
      kbSubCategoryList: [],
      newkbSubCategoryList: [],
      newkbSubCategoryListTotal: 0,
      selectedkbSubCategoryList: [],
      selectedModuleList: [],
      module_type: [],
      kbSubCategoryListTotal: 0,
      description: "",
      title: "",
      sort_order: "",
      confirm_back: 0,
      // kb_category_slug: "",
      kb_sub_category_slug: "",
      descShow: 1,
      faqList: [],
      faqListTotal: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      // add table for faq list
      columns: [
        {
          key: "title",
          text: "Question",
          className: "title hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.title ? record.title : "";
          },
        },
        {
          key: "module_type",
          text: "Module",
          className: "title hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.module_type ? record.module_type : "";
          },
        },
        // {
        //   key: "kb_category_id",
        //   text: "Category",
        //   className: "client_logo hand-cursor",
        //   sortable: true,
        //   cell: (record) => {
        //     return record.kb_category && record.kb_category.kb_category_name
        //       ? record.kb_category.kb_category_name
        //       : "";
        //   },
        // },
        // {
        //   key: "kb_sub_category_id",
        //   text: "Sub Category",
        //   className: "kb_sub_category_name hand-cursor",
        //   sortable: true,
        //   cell: (record) => {
        //     return record.sub_kb_category &&
        //       record.sub_kb_category.kb_sub_category_name
        //       ? record.sub_kb_category.kb_sub_category_name
        //       : "";
        //   },
        // },
        {
          key: "updated_at",
          text: "Last Updated On",
          className: "descriptions hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            var dateTimeConversion = Moment(record.updated_at).format(
              "MM/DD/YY[\r\n]h:mm A"
            );
            var dateTime =
              record && record.updated_at ? dateTimeConversion : "";
            return dateTime;
          },
        },
        {
          key: "sort_order",
          text: "Sort Order",
          className: "descriptions hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.sort_order ? record.sort_order : "";
          },
        },
      ],
      tab: "active",
      faqTabData: localStorage.getItem("faq_tab_data")
        ? localStorage.getItem("faq_tab_data")
        : "",
      activeClass: "activeTab",
      // end for faq list
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangePropertyList = this.handleChangePropertyList.bind(this);
    // this.handleChangeKbCategoryList =
    //   this.handleChangeKbCategoryList.bind(this);
    // this.handleChangeKbSubCategoryList =
    //   this.handleChangeKbSubCategoryList.bind(this);
    this.fullText = this.fullText.bind(this);
    this.opensearchAmenityModal = this.opensearchAmenityModal.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.handleChangeModuleList = this.handleChangeModuleList.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
  }

  /*for amenity search modal */
  opensearchAmenityModal() {
    this.setState({ searchAmenityModal: true });
  }

  clearFilter() {
    var moduleSearchString = "";
    moduleSearchString = this.state.property_slug
      ? moduleSearchString
        ? moduleSearchString +
          " &" +
          " kb_category_slug - " +
          this.state.kb_category_slug
        : " kb_category_slug - " + this.state.kb_category_slug
        ? moduleSearchString +
          " &" +
          " kb_sub_category_slug - " +
          this.state.kb_sub_category_slug
        : " kb_sub_category_slug - " + this.state.kb_sub_category_slug
        ? moduleSearchString + " &" + "module_type - " + this.state.module_type
        : "module_type - " + this.state.module_type
      : moduleSearchString;

    this.setState({ module_search: moduleSearchString });

    if (
      this.state.property_slug &&
      this.state.module_type
      // this.state.kb_category_slug &&
      // this.state.kb_sub_category_slug
    ) {
      this.setState({
        property_slug: "",
        // kb_category_slug: "",
        // kb_sub_category_slug: "",
        module_type: "",
      });
      this.setState({ module_search: "", is_search: 0 });
      this.props.history.push("/faqs");
    } else {
      window.location.search = "";
      this.setState({ is_search: 0 });
      this.setState({
        property_slug: "",
        // kb_category_slug: "",
        // kb_sub_category_slug: "",
        module_type: "",
      });
      this.setState({ module_search: "" });
    }
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    if (queryString == "") {
      queryString = "tab=active";
    }
    let res = await FaqService.getFaq(queryString ? queryString : "");
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = list ? res.data.total : 0;
    }

    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  /* to search amenity data */
  searchAmenity = async (e) => {
    e.preventDefault();
    let queryString = this.getQueryString();

    var moduleSearchString = "";
    moduleSearchString = this.state.property_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " property_slug - " +
          this.state.selectedPropertyList.label
        : "Property Name - " + this.state.selectedPropertyList.label
      : moduleSearchString;

    moduleSearchString = this.state.kb_category_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Category Name - " +
          this.state.selectedkbCategoryList.label
        : "Category Name - " + this.state.selectedkbCategoryList.label
      : moduleSearchString;

    moduleSearchString = this.state.kb_sub_category_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Sub Category Name - " +
          this.state.selectedkbSubCategoryList.label
        : " Sub Category Name - " + this.state.selectedkbSubCategoryList.label
      : moduleSearchString;

    moduleSearchString = this.state.selectedModuleList.value
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          "Module -" +
          this.state.selectedModuleList.label
        : "Module - " + this.state.selectedModuleList.label
      : moduleSearchString;

    if (
      // this.state.kb_category_slug ||
      this.state.property_slug ||
      // this.state.kb_sub_category_slug ||
      this.state.module_type
    ) {
      this.setState({ customQueryString: queryString });
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(queryString);
      this.closesearchAmenityModal();
    }
  };

  closesearchAmenityModal() {
    this.setState({ searchAmenityModal: false });
    this.validator.hideMessages();
  }

  /* To get KbCategory list data */
  async getKbCategory(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var kbCategoryList = [];
    var kbCategoryListTotal = 0;

    let res = await FaqService.getKbCategory(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      kbCategoryList = res.data ? res.data : [];
      kbCategoryListTotal = kbCategoryList ? kbCategoryList.length : 0;
      // kbCategoryTotal: res.data ? res.data.length() : [];
    }

    const newkbCategoryList =
      kbCategoryListTotal > 0
        ? kbCategoryList.map(({ slug, kb_category_name }) => ({
            value: slug,
            label: kb_category_name,
          }))
        : [];
    this.setState({
      kbCategoryList: kbCategoryList,
      kbCategoryListTotal: kbCategoryListTotal,
      newkbCategoryList: newkbCategoryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /*file download */
  async getDownloadFile(url, name) {
    let file_name = "";
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  /* To get KbSubCategory list data */
  async getKbSubCategory(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var kbSubCategoryList = [];
    var kbSubCategoryListTotal = 0;

    let res = await FaqService.getKbSubCategory(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      kbSubCategoryList = res.data.data ? res.data.data : [];
      kbSubCategoryListTotal = kbSubCategoryList ? kbSubCategoryList.length : 0;
    }
    const newkbSubCategoryList =
      kbSubCategoryListTotal > 0
        ? kbSubCategoryList.map(({ slug, kb_sub_category_name }) => ({
            value: slug,
            label: kb_sub_category_name,
          }))
        : [];

    // const newList =
    //   kbSubCategoryListTotal > 0
    //     ? kbSubCategoryList.map((data) => {
    //         this.state.records.map((record) => {
    //           if (data.kb_sub_category_id === record.kb_sub_category_id) {
    //             {
    //               data.kb_sub_category_id;
    //             }
    //           }
    //         });
    //       })
    //     : [];
    // console.log("newList", newList);

    this.setState({
      kbSubCategoryList: kbSubCategoryList,
      kbSubCategoryListTotal: kbSubCategoryListTotal,
      newkbSubCategoryList: newkbSubCategoryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getPropertyData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var propertyList = [];
    var newPropertyList = [];
    var propertyListTotal = 0;

    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;

      newPropertyList =
        propertyListTotal > 0
          ? propertyList.map(({ slug, property_name }) => ({
              value: slug,
              label: property_name,
            }))
          : [];
    }
    this.setState({
      propertyList: propertyList,
      newPropertyList: newPropertyList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    if (this.props.match.params.propertySlug !== undefined) {
      switch (this.state.influencerTabData) {
        case "active":
          document.getElementById("activeTab").click();
          break;
        case "inactive":
          document.getElementById("inactiveTab").click();
          break;
        default:
          document.getElementById("activeTab").click();
      }
    }
    if (queryParams.isGlobal === "1") {
      this.getData(
        "is_search=1&tab=" +
          this.state.influencerTabData +
          "&" +
          queryParams.global_col_name +
          "=" +
          queryParams.global_search
      );
    } else {
      // this.getKbCategory();
      // this.getKbSubCategory();
      this.getPropertyData();
      this.getData();
      this.setState({
        Module: [
          { value: "1", label: "Reservation" },
          { value: "2", label: "Listing" },
          { value: "3", label: "Property" },
          { value: "4", label: "General" },
          { value: "5", label: "Access Support-Latch" },
          { value: "6", label: "Access Support-Keycafe" },
        ],
      });
      if (this.state.slug !== undefined) {
        var resData = {};
        // var inquiryData = [];
        let res = await FaqService.getFaqBySlug(this.state.slug);
        if (global.successStatus.includes(res.status)) {
          resData = res.data ? res.data : {};
          var selectedkbCategoryList = [];
          selectedkbCategoryList = res.data
            ? [
                {
                  value: res.data.kb_category_slug,
                  label: res.data.kb_category_name,
                },
              ]
            : "";
          var selectedkbSubCategoryList = [];
          selectedkbSubCategoryList = res.data
            ? [
                {
                  value: res.data.kb_sub_category_slug,
                  label: res.data.kb_sub_category_name,
                },
              ]
            : "";
          var selectedPropertyList = [];
          selectedPropertyList = res.data
            ? [
                {
                  value: res.data.property_slug,
                  label: res.data.property_name,
                },
              ]
            : "";

          var selectedModuleList =
            res.data.module_type == "Reservation"
              ? [{ value: 1, label: "Reservation" }]
              : res.data.module_type == "Property"
              ? [{ value: 2, label: "Property" }]
              : res.data.module_type == "Listing"
              ? [{ value: 3, label: "Listing" }]
              : res.data.module_type == "General"
              ? [{ value: 4, label: "General" }]
              : res.data.module_type == "Access Support-Latch"
              ? [{ value: 5, label: "Access Support-Latch" }]
              : res.data.module_type == "Access Support-Keycafe"
              ? [{ value: 6, label: "Access Support-Keycafe" }]
              : res.data.module_type === "OHAI Assist Knowledge Only"
              ? [{ value: 7, label: "OHAI Assist Knowledge Only" }]
              : res.data.module_type === "Viewable in Guest FAQs"
              ? [{ value: 8, label: "Viewable in Guest FAQs" }]
              : "";
        }
        this.setState(resData);
        this.setState({
          selectedkbSubCategoryList: selectedkbSubCategoryList,
          selectedkbCategoryList: selectedkbCategoryList,
          selectedPropertyList: selectedPropertyList,
          selectedModuleList: selectedModuleList,
        });
      }
      this.setState({ showSpinner: false, loading: false });
    }
  }

  getQueryString() {
    // let queryString =
    //   "property_slug=" +
    //   (this.state.property_slug ? this.state.property_slug : "") +
    //   "&kb_category_slug=" +
    //   (this.state.kb_category_slug ? this.state.kb_category_slug : "") +
    //   "&kb_sub_category_slug=" +
    //   (this.state.kb_sub_category_slug ? this.state.kb_sub_category_slug : "") +
    //   "&module_type=" +
    //   (this.state.module_type ? this.state.module_type : "");

    let queryString =
      "property_slug=" +
      (this.state.property_slug ? this.state.property_slug : "") +
      "&module_type=" +
      (this.state.module_type ? this.state.module_type : "");

    return queryString;
  }

  handleChangePropertyList(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.setState({
      selectedPropertyList: value,
      property_slug: value.value,
    });
  }

  // handleChangeKbCategoryList(value) {
  //   let kb_category_slug = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       kb_category_slug.push(item.value);
  //     });
  //   }
  //   this.setState({
  //     selectedkbCategoryList: value,
  //     kb_category_slug: value.value,
  //   });
  // }

  // handleChangeKbSubCategoryList(value) {
  //   let kb_sub_category_slug = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       kb_sub_category_slug.push(item.value);
  //     });
  //   }
  //   this.setState({
  //     selectedkbSubCategoryList: value,
  //     kb_sub_category_slug: value.value,
  //   });
  // }

  handleChangeModuleList(value) {
    let module_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        module_type.push(item.label);
      });
    }
    this.setState({
      selectedModuleList: value,
      module_type: value.label,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData("tab=" + this.state.tab + "&" + queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "FAQs";
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("/faq/view/" + data.slug);
  };

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "faq_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "faq_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-12">
                <div className="col-md-12 mg-t-20 mg-b-10 "></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {global.userPermissions.checkPermission("faqs-add") && (
                        <Link to={"faq/add"}>
                          <button className="btn-success-outline-small ml-3">
                            <img src={pluswIcon} alt="" /> Add
                          </button>
                        </Link>
                      )}
                      <button
                        onClick={this.opensearchAmenityModal}
                        className="btn-success-outline-small ml-3"
                      >
                        <img src={filterIcon} alt="filterIcon" />{" "}
                        {this.state.isSearch === 1 ? "Search" : "Search"}
                      </button>
                    </div>
                  </div>
                  {this.state.is_search === 1 ? (
                    <>
                      <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel mg-t-20">
                        <div className="custm-filter">
                          <div>
                            {" "}
                            <label className="form-label text-left pd-b-0 mb-0 pd-lg-t-0 pd-xs-t-15">
                              Search Applied On
                            </label>
                          </div>
                          <div className="filter_bg_cust ">
                            {this.state.module_search.map((item, i) => {
                              return <span key={i}>{item}</span>;
                            })}
                            <button
                              onClick={this.clearFilter}
                              className="btn-fliter-clear"
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="m-0">
                      <div className="">
                        <div className="scrolling-carousel scroll-slide">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="all"
                            onSelect={(e) => this.getActiveClass(e)}
                            eventKey={this.state.activeClass}
                            className="pd-r-60"
                          >
                            <ScrollingCarousel
                              rightArrow={true}
                              leftArrow={true}
                              className="swipesection"
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="activeTab"
                                  id="activeTab"
                                  className={
                                    this.state.activeClass === "activeTab"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Active
                                </Nav.Link>
                              </Nav.Item>

                              <Nav.Item>
                                <Nav.Link
                                  eventKey="inactiveTab"
                                  id="inactiveTab"
                                  className={
                                    this.state.activeClass === "inactiveTab"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Inactive
                                </Nav.Link>
                              </Nav.Item>
                            </ScrollingCarousel>
                            <Tab.Content>
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                {this.state.total_record > 0 ||
                                this.state.filter_value ? (
                                  <div className="mg-t-0 brandList faq_tbl pl-0">
                                    <div className="col-12">
                                      <ReactDatatable
                                        className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                        config={
                                          this.state.total_record >
                                          global.page_size
                                            ? this.state.config
                                            : this.state.config
                                        }
                                        records={this.state.records}
                                        columns={this.state.columns}
                                        extraButtons={this.state.extraButtons}
                                        dynamic={true}
                                        // loading={this.state.loading}
                                        total_record={this.state.total_record}
                                        onChange={this.tableChangeHandler}
                                        onRowClicked={this.rowClickedHandler}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <NoData msg="faqs" />
                                )}
                              </div>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        {/* search  amenity popup modal */}
        <Modal
          show={this.state.searchAmenityModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without Searching?"))
              this.closesearchAmenityModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closesearchAmenityModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Search FAQ</h3>
            <div className="col-sm-12 p-0 mg-b-15">
              <label className="form-label text-left pd-b-0">Property</label>
              <Select
                styles={customStyles}
                className="multiselect"
                menuPlacement="auto"
                value={this.state.selectedPropertyList}
                options={this.state.newPropertyList}
                getOptionValue={(option) => `${option.label}`}
                onChange={(value) => this.handleChangePropertyList(value)}
                defaultValue={this.state.selectedPropertyList}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 2,
                  colors: {
                    ...theme.colors,
                    primary: "#fff",
                    primary75: "#000",
                    primary50: "#000",
                    primary25: "#000",
                  },
                })}
              />
            </div>

            {/* <div className="col-sm-12 p-0 mg-b-15">
              <label className="form-label text-left pd-b-0">Category</label>
              <Select
                styles={customStyles}
                className="multiselect"
                menuPlacement="auto"
                value={this.state.selectedkbCategoryList}
                options={this.state.newkbCategoryList}
                getOptionValue={(option) => `${option.label}`}
                onChange={(value) => this.handleChangeKbCategoryList(value)}
                defaultValue={this.state.selectedkbCategoryList}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 2,
                  colors: {
                    ...theme.colors,
                    primary: "#fff",
                    primary75: "#000",
                    primary50: "#000",
                    primary25: "#000",
                  },
                })}
              />
            </div>

            <div className="col-sm-12 p-0 mg-b-15">
              <label className="form-label text-left pd-b-0">
                Sub Category
              </label>
              <Select
                styles={customStyles}
                className="multiselect"
                menuPlacement="auto"
                value={this.state.selectedkbSubCategoryList}
                options={this.state.newkbSubCategoryList}
                getOptionValue={(option) => `${option.label}`}
                onChange={(value) => this.handleChangeKbSubCategoryList(value)}
                defaultValue={this.state.selectedkbSubCategoryList}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 2,
                  colors: {
                    ...theme.colors,
                    primary: "#fff",
                    primary75: "#000",
                    primary50: "#000",
                    primary25: "#000",
                  },
                })}
              />
            </div> */}

            <div className="col-sm-12 p-0 mg-b-15">
              <label className="form-label text-left pd-b-0">Module</label>
              <Select
                styles={customStyles}
                className="multiselect"
                menuPlacement="auto"
                value={this.state.selectedModuleList}
                options={this.state.Module}
                onChange={(value) => this.handleChangeModuleList(value)}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 2,
                  colors: {
                    ...theme.colors,
                    primary: "#fff",
                    primary75: "#000",
                    primary50: "#000",
                    primary25: "#000",
                  },
                })}
              />
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closesearchAmenityModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.searchAmenity}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default Faqs;
