import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import LatchService from "../../../services/LatchService";
import Moment from "moment";

class ActivityHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            total_record: 0,
            records: [],
            extraButtons: [],
            tableQueryString: "",
            customQueryString: "",
            filter_value: "",
            isSearch: global.isSearch,
            is_search: 0,
            door_name: "",
            input: {},
            property_slug: props.propertySlug,
            activeClass: "alert-activity",
            columns: [
                {
                    key: "created_at",
                    text: "Time",
                    className: "created_at",
                    sortable: true,
                    cell: (record) => record.created_at ? Moment(record.created_at).format(global.dateTimeFormat) : ""
                },
                {
                    key: "unit_name",
                    text: "Unit Name",
                    className: "unit_name",
                    sortable: true,
                    cell: (record) => record.unit_name || ""
                },
                {
                    key: "door_name",
                    text: "Door Name",
                    className: "door_name",
                    sortable: true,
                    cell: (record) => record.door_name || ""
                },
                {
                  key: "full_name",
                  text: "Name",
                  className: "full_name text-left",
                  sortable: true,
                  cell: (record) => record.full_name || ""
                },
                {
                  key: "usr_email",
                  text: "Email",
                  className: "usr_email text-left",
                  sortable: true,
                  cell: (record) => record.usr_email || ""
                },
                {
                    key: "response_msg",
                    text: "Access Status",
                    className: "response_msg text-left",
                    sortable: true,
                    cell: (record) => record.response_msg || ""
                },
                {
                  key: "api_response",
                  text: "Message",
                  className: "api_response text-left",
                  sortable: true,
                  cell: (record) => record.api_response ? record.api_response.replace(/"/g, " ") :  '' ,
                },
            ],
            config: {
                sort: { column: "created_at", order: "desc", },
                key_column: "latch_access_event_id",
                page_size: global.page_size,
                length_menu: global.page_length_menu,
                show_length_menu: true,
                show_filter: global.show_filter,
                show_pagination: global.show_pagination,
                pagination: "advance",
                button: { excel: global.excel_button, print: global.print_button, extra: false },
                language: { no_data_text: "No data found.", },
            },
        };
    }
    async componentDidMount() {
        this.getData()
    }
    async getData(queryString = "") {
        var list = [];
        var totalRecords = 0;
        let final_string = `property_slug=${this.state.property_slug}&${queryString}`;
        let res = await LatchService.getLatchAccessEvents(final_string);
        if (global.successStatus.includes(res.status)) {
            list = res.data ? res.data.data : {};
            totalRecords = res.data ? res.data.total : 0;
        }
        this.setState({ total_record: totalRecords, records: list})
    }
    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
          .map((key) => {
            if (key === "sort_order" && data[key]) {
              return (
                encodeURIComponent("sort_order") +
                "=" +
                encodeURIComponent(data[key].order) +
                "&" +
                encodeURIComponent("sort_column") +
                "=" +
                encodeURIComponent(data[key].column)
              );
            } else {
              let newKey = key;
              if (key === "page_number") {
                newKey = "page";
                window.scrollTo(0, 0);
              } else if (key === "page_size") {
                newKey = "per_page";
              } else if (key === "sort_order") {
                data[key] = "asc";
              }
              return (
                encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
              );
            }
          })
          .join("&");
    
        if (data.filter_value !== "") {
          this.setState({ tableQueryString: queryString });
        } else {
          queryString += "&" + this.state.customQueryString;
          this.setState({ tableQueryString: queryString });
        }
    
        if (data.filter_value !== this.state.filter_value) {
          clearTimeout(this.state.filter_time);
          this.setState({
            filter_time: setTimeout(
              () =>
                this.getData(queryString + "&tab=" + this.state.tab + "&is_list=1"),
              global.filter_time
            ),
            filter_value: data.filter_value,
          });
        } else {
          this.getData(queryString + "&tab=" + this.state.tab + "&is_list=1");
        }
      };
    
    // tableChangeHandler = (data) => {
    //     this.setState({ showSpinner: false, loading: false });
    //     let queryString = `sort_column=${data.sort_order.column}&sort_order=${data.sort_order.order}`
    //     this.getData(queryString);
    // };
    render() {
        return (
            <>
                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                    <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block">
                        <div className="pd-lg-l-0 pd-xs-l-15">
                            <ReactDatatable
                                className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                                config={this.state.total_record > global.page_size ? this.state.config : this.state.config}
                                records={this.state.records}
                                columns={this.state.columns}
                                extraButtons={this.state.extraButtons}
                                dynamic={true}
                                total_record={this.state.total_record}
                                onChange={this.tableChangeHandler}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ActivityHistory;
