///////////////////////////////////////////////////////////
//  Program: Emails.jsx                                  //
//  Application: Emails                                  //
//  Option: add all Emails                               //
//  Developer:  NP                                       //
//  Date: 2022-12-05                                     //
///////////////////////////////////////////////////////////

import SettingSidebar from "../common/SettingSidebar";
import NoData from "../common/NoData";
// import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import React, { Component } from "react";
import { Container, Nav, Tab, Modal, InputGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import Select from "react-select";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
// import filterIcon from "./../../assets/images/filter-gray.svg";
import queryString from "query-string";
import SchedulerService from "../../services/SchedulerService";
import Moment from "moment-timezone";

let queryParams = queryString.parse(window.location.search);
class Schedular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: window.location.pathname,
      showSpinner: false,
      loading: false,
      total_record: 0,
      records: [],
      selectedTypeList: [],
      userCount: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      deleteRoleModal: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      typeList: [
        { key: 5, value: 5, label: "Guest" },
        { key: 4, value: 4, label: "Resident" },
      ],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "trigger_point",
          text: "Action",
          className: "title hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.trigger_point === 1
              ? "Booking Confirmed"
              : record.trigger_point === 2
              ? "Check In"
              : record.trigger_point === 3
              ? "Check Out"
              : record.trigger_point === 4
              ? "Daily"
              : record.trigger_point === 5
              ? "Newly Added Leases"
              : record.trigger_point === 6
              ? "Leases Expiring"
              : record.trigger_point === 7
              ? "Lease Inquiry"
              : record.trigger_point === 8
              ? "Listing Published"
              : record.trigger_point === 9
              ? "Early Cleaning Notification"
              : record.trigger_point === 11
              ? "Stripe Linked"
              : record.trigger_point === 12
              ? "Listing Info Updated"
              : record.trigger_point === 13
              ? "Turno Linked"
              : record.trigger_point === 14
              ? "Listing Submitted"
              : record.trigger_point === 15
              ? "Payout"
              : record.trigger_point === 16
              ? "Guest Form"
              : record.trigger_point === 17
              ? "Resident Check In"
              : record.trigger_point === 18
              ? "Resident Check Out"
              : record.trigger_point === 19
              ? "Cleaning Project Created"
              : record.trigger_point === 20
              ? "Cleaning Project Updated"
              : record.trigger_point === 21
              ? "Cleaning Project Deleted"
              : record.trigger_point === 22
              ? "Cleaning Started"
              : record.trigger_point === 23
              ? "Cleaning Ends"
              : record.trigger_point === 25
              ? "Problem Reported"
              : record.trigger_point === 26
              ? "1 Star review"
              : record.trigger_point === 27
              ? "2 Star review"
              : record.trigger_point === 28
              ? "3 Star review"
              : record.trigger_point === 29
              ? "4 Star review"
              : record.trigger_point === 30
              ? "5 Star review"
              : record.trigger_point === 31
              ? "Review Reminder"
              : record.trigger_point === 32
              ? "Reservation Inquiry"
              : record.trigger_point === 24
              ? "Cleaning Not Started"
              : record.trigger_point === 33
              ? "Cleaning Still Not Started"
              : record.trigger_point === 34
              ? "Guest Form Submitted":
              record.trigger_point === 35
              ? "Access Support Submitted"
              : record.trigger_point === 36
              ? "Homesharing Inquiry"
              : record.trigger_point === 37
              ? "Resident Stay Confirmed"
              : record.trigger_point === 39
              ? "Booking Cancelled"
              : record.trigger_point === 38
              ? "Booking Date Changed"
              : record.trigger_point === 40
              ? "Onboarding Completed Without Bookability"
              : record.trigger_point === 41
              ? "Resident Stay Delete"
              : record.trigger_point === 42
              ? "Homesharing Inquiry Approved Status"
              : record.trigger_point === 43
              ? "Building Onboarding Basics"
              : record.trigger_point === 44
              ? "Building Onboarding Basics Pending"
              : record.trigger_point === 45
              ? "Building Onboarding Media & Files"
              : record.trigger_point === 46
              ? "Building Onboarding Media & Files Pending"
              : record.trigger_point === 47
              ? "Building Onboarding Amenities"
              : record.trigger_point === 48
              ? "Building Onboarding Amenities Pending"
              : record.trigger_point === 49
              ? "Building Onboarding Parking Plan"
              : record.trigger_point === 50
              ? "Building Onboarding Parking Plan Pending"
              : record.trigger_point === 51
              ? "Building Onboarding Banking Info"
              : record.trigger_point === 52
              ? "Building Onboarding Banking Info Pending"
              : record.trigger_point === 53
              ? "Building Onboarding CSV Import"
              : record.trigger_point === 54
              ? "Building Onboarding CSV Import Pending"
              : record.trigger_point === 55
              ? "Building Onboarding Lease Addendum"
              : record.trigger_point === 56
              ? "Building Onboarding Lease Addendum Pending"
              : record.trigger_point === 57
              ? "Building Onboarding Staff Training"
              : record.trigger_point === 58
              ? "Building Onboarding Staff Training Pending"
              : record.trigger_point === 59
              ? "Building Onboarding Announce Homesharing"
              : record.trigger_point === 60
              ? "Building Onboarding Announce Homesharing Pending"
              : record.trigger_point === 61
              ? "Building Onboarding Install Key Locker"
              : record.trigger_point === 62
              ? "Building Onboarding Install Key Locker Pending"
              : record.trigger_point === 63
              ? "Building Onboarding Permit Plan"
              : record.trigger_point === 64
              ? "Building Onboarding Permit Plan Pending"
              : record.trigger_point === 65
              ? "Building Onboarding Completed"
              : record.trigger_point === 66
              ? "Payout Transfer"
              : record.trigger_point === 67
              ? "Listing Live"
              : "";
          },
        },
        {
          key: "trigger_time",
          text: "Trigger Time",
          className: "title hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.trigger_time === 1
              ? "Immediately"
              : record.trigger_time === 2
              ? "After"
              : record.trigger_time === 3
              ? "Before"
              : Moment(Moment.utc(record.timezone_time, 'hh:mm A')).local().format("hh:mm A");
          },
        },
        {
          key: "template_id",
          text: "Template",
          className: "title hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.template ? record.template.template_name : "";
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "title hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active m-0">Active</b>
            ) : (
              <b className="status-inactive">InActive</b>
            );
          },
        },
      ],
      tab: "active",
      brandTabData: localStorage.getItem("emailtemplate_tab_data")
        ? localStorage.getItem("emailtemplate_tab_data")
        : "",
      activeClass: "activeTab",
    };
    this.searchToggle = this.searchToggle.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }
  componentDidMount() {
    switch (this.state.brandTabData) {
      case "active":
        document.getElementById("activeTab").click();
        break;
      case "inactive":
        document.getElementById("inactiveTab").click();
        break;
      default:
        document.getElementById("activeTab").click();
    }
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "emailtemplate_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "emailtemplate_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  // to get data of template
  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await SchedulerService.getSchedulerList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData("tab=" + this.state.tab + "&" + queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };
  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  handleChangeUserList(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedTypeList: value,
      usertotype: type,
      filtertolable: value.label,
    });
    this.setState({ confirm_back: 1 });
  }

  filterData() {
    let queryString =
      "template_name=" +
      (this.state.template_name ? this.state.template_name : "") +
      "&to_email=" +
      (this.state.usertotype ? this.state.usertotype : "") +
      "&from=" +
      (this.state.from ? this.state.from : "") +
      "&is_search=1";
    // let moduleSearchString =
    //   (this.state.floor_name ? "first name - " + this.state.floor_name : '') +
    //   (this.state.aka_name ? " & tower SKU - " + this.state.aka_name : '');

    let moduleSearchString = "";
    moduleSearchString = this.state.template_name
      ? "template name - " + this.state.template_name
      : "";
    moduleSearchString = this.state.usertotype
      ? moduleSearchString
        ? moduleSearchString + " &" + " To - " + this.state.filtertolable
        : " To - " + this.state.filtertolable
      : moduleSearchString;
    moduleSearchString = this.state.from
      ? moduleSearchString
        ? moduleSearchString + " &" + " from - " + this.state.from
        : " type - " + this.state.from
      : moduleSearchString;

    if (this.state.template_name || this.state.usertotype || this.state.from) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString });
      this.getData(queryString);
    }
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({ template_name: "", usertotype: "", from: "" });
    this.setState({ module_search: "" });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }
  rowClickedHandler = (event, data, rowIndex) => {
    global.userPermissions.checkPermission("workflow-update") &&
      this.props.history.push("/scheduler/update/" + data.slug);
  };
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <SettingSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right">
                  <div className="scrolling-carousel">
                    <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                      <div className="row row-xs ">
                        <div className="col-md-12 mg-t-10 mg-b-10"></div>
                        <div className="col-md-12">
                          <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                            <h3 className="mb-0 brnd_heading">Workflow</h3>
                            <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                              {global.userPermissions.checkPermission(
                                "workflow-add"
                              ) && (
                              <Link to={"/scheduler/add"}>
                                <button className="btn-success-outline mg-r-20">
                                  <img src={pluswIcon} alt="Add" /> Add
                                </button>
                              </Link>
                              )}
                              {/* <button
                                onClick={this.searchToggle}
                                className="btn-success-outline-small ml-3"
                              >
                                <img src={filterIcon} alt="filterIcon" />{" "}
                                {this.state.isSearch === 1 ? "Search" : "Search"}
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Container className="p-0">
                      <div className="pd-lg-l-15 pd-xs-l-15">
                        {queryParams.isGlobal === "1" ? (
                          <>
                            <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                              {"Search applied on: " +
                                queryParams.global_col_label +
                                " - " +
                                queryParams.global_search}
                              <button
                                onClick={this.clearFilter}
                                className="mg-l-15 btn-success-outline-small"
                              >
                                Clear
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.is_search === 1 ? (
                          <>
                            <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                              {"Search applied on: " + this.state.module_search}
                              <button
                                onClick={this.clearFilter}
                                className="mg-l-15 btn-success-outline-small"
                              >
                                Clear
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="scrolling-carousel scroll-slide">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="all"
                            onSelect={(e) => this.getActiveClass(e)}
                            eventKey={this.state.activeClass}
                            className="pd-r-60"
                          >
                            <ScrollingCarousel
                              rightArrow={true}
                              leftArrow={true}
                              className="swipesection pd-t-0"
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="activeTab"
                                  id="activeTab"
                                  className={
                                    this.state.activeClass === "activeTab"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Active
                                </Nav.Link>
                              </Nav.Item>

                              <Nav.Item>
                                <Nav.Link
                                  eventKey="inactiveTab"
                                  id="inactiveTab"
                                  className={
                                    this.state.activeClass === "inactiveTab"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Inactive
                                </Nav.Link>
                              </Nav.Item>
                            </ScrollingCarousel>
                            <Tab.Content>
                              <Tab.Pane eventKey="activeTab">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-0 brandList">
                                      <ReactDatatable
                                        className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                        config={
                                          this.state.total_record >
                                          global.page_size
                                            ? this.state.config
                                            : this.state.config
                                        }
                                        records={this.state.records}
                                        columns={this.state.columns}
                                        extraButtons={this.state.extraButtons}
                                        dynamic={true}
                                        // loading={this.state.loading}
                                        total_record={this.state.total_record}
                                        onChange={this.tableChangeHandler}
                                        onRowClicked={this.rowClickedHandler}
                                      />
                                    </div>
                                  ) : (
                                    <NoData msg="active workflow" />
                                  )}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="inactiveTab">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-0 brandList">
                                      <ReactDatatable
                                        className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                        config={
                                          this.state.total_record >
                                          global.page_size
                                            ? this.state.config
                                            : this.state.config
                                        }
                                        records={this.state.records}
                                        columns={this.state.columns}
                                        extraButtons={this.state.extraButtons}
                                        dynamic={true}
                                        // loading={this.state.loading}
                                        total_record={this.state.total_record}
                                        onChange={this.tableChangeHandler}
                                        onRowClicked={this.rowClickedHandler}
                                      />
                                    </div>
                                  ) : (
                                    <NoData msg="inactive workflow" />
                                  )}
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Neighborhood</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Email Template Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="template_name"
                      value={this.state.template_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>To</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedTypeList}
                      options={this.state.typeList}
                      onChange={(value) => this.handleChangeUserList(value)}
                      defaultValue={this.state.selectedTypeList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>From</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="from"
                      value={this.state.from}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default Schedular;
