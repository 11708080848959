////////////////////////////////////////////////////////////
//     							                          //
//  Program: UnitCost.jsx                                 //
//  Application: Property                                 //
//  Option: List all room Cost                            //
//  Developer: Np						                  //
//  Date: 2022-02-16                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import PropertyCostService from "../../services/PropertyCostService";
import { Form, Table, Modal } from "react-bootstrap";
import Alerts from "../common/Alerts";
import CostTypeService from "../../services/CostTypeService";
import CostPayableByService from "../../services/CostPayableByService";
import CostPayableAtService from "../../services/CostPayableAtService";
import PetPolicyService from "../../services/PetPolicyService";
import Select from "react-select";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
import NumberFormat from 'react-number-format';

export class UnitCost extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      open: global.showOpen,
      eventKey: "",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      unit_slug: this.props.unit_slug,
      property_slug: this.props.property_slug,
      isSubmit: false,
      cost_name: "",
      estimated: false,
      mandatory: false,
      refundable: false,
      // sort_order: "",
      pathname: window.location.pathname,
      costTypeList: [],
      newCostTypeList: [],
      newSelectedCostTypeList: [],
      costPayableAtList: [],
      newCostPayableAtList: [],
      selectedCostPayableAtList: [],
      costPayableByList: [],
      newCostPayableByList: [],
      selectedCostPayableByList: [],
      selectedCostTypeList: [],
      alreadySelectedCostTypeList: [],
      costList: [],
      costListTotal: 0,
      cost_slug: "",
      confirm_back: 0,
      costTypeListTotal: 0,
      costPayableAtListTotal: 0,
      costPayableByListTotal: 0,
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateCost = this.saveOrUpdateCost.bind(this);
    this.getCostDetails = this.getCostDetails.bind(this);
    this.changeView = this.changeView.bind(this);
    this.openDeleteUnitCostModal = this.openDeleteUnitCostModal.bind(this);
    this.closeDeleteUnitCostModal = this.closeDeleteUnitCostModal.bind(this);
    this.deleteCost = this.deleteCost.bind(this);
  }

  openDeleteUnitCostModal(slug) {
    this.setState({ deleteUnitCostModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteUnitCostModal() {
    this.setState({ deleteUnitCostModal: false });
    this.setState({ slug: "" });
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getData();
    this.getPetPolicyData();
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    var costList = [];
    var costListTotal = 0;

    let res = await PropertyCostService.getAllCosts(
      this.state.unit_slug + "/2"
    );
    if (global.successStatus.includes(res.status)) {
      costList = res.data ? res.data : "";
      costListTotal = costList ? costList.length : 0;
    }
    this.setState({ costList: costList, costListTotal: costListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  async getPetPolicyData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    var resData = [];
    var multiList = [];
    var multiListTotal = 0;
    let res = await PetPolicyService.getPetPolicy(this.state.property_slug+"/3");
    if (global.successStatus.includes(res.status)) {
      list = res.data.petPolicyData ? res.data.petPolicyData : [];
      totalRecords = list ? list.length : 0;
      resData.pet_policy_restrictions = res.data.propertyData[0]
        ? res.data.propertyData[0].pet_policy_restrictions
        : null;
      resData.custom_pet_policy = res.data.propertyData[0]
        ? res.data.propertyData[0].custom_pet_policy
        : null;
      multiList = res.data.petPolicyRestrictions
        ? res.data.petPolicyRestrictions
        : [];
      multiListTotal = multiList ? multiList.length : 0;
    }
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
      multiList: multiList,
      multiListTotal: multiListTotal,
    });
  }

  async getCostDetails(slug) {
    this.setState({ cost_slug: slug });
    this.changeView();
    this.setState({ showSpinner: true, loading: true });
    if (this.state.unit_slug !== undefined) {
      var resData = {};
      let res = await PropertyCostService.getCostsBySlug(
        this.state.unit_slug + "/2/" + slug
      );
      if (global.successStatus.includes(res.status)) {
        resData.cost_name = res.data.cost_name ? res.data.cost_name : "";
        resData.cost_type_slug = res.data.cost_type
          ? res.data.cost_type.slug
          : "";
        resData.cost_payable_at_slug = res.data.cost_payable_at
          ? res.data.cost_payable_at.slug
          : "";
        resData.cost_payable_by_slug = res.data.cost_payable_by
          ? res.data.cost_payable_by.slug
          : "";
        resData.estimated = res.data.estimated === 1 ? true : false;
        resData.mandatory = res.data.mandatory === 1 ? true : false;
        resData.refundable = res.data.refundable === 1 ? true : false;
        resData.status_id = res.data.status_id === 1 ? true : false;

        var newSelectedCostTypeList = [];
        newSelectedCostTypeList = res.data.cost_type
          ? [
            {
              value: res.data.cost_type ? res.data.cost_type.slug : "",
              label: res.data.cost_type
                ? res.data.cost_type.cost_type_name
                : "",
            },
          ]
          : "";

        var selectedCostPayableAtList = [];
        selectedCostPayableAtList = res.data.cost_payable_at
          ? [
            {
              value: res.data.cost_payable_at
                ? res.data.cost_payable_at.slug
                : "",
              label: res.data.cost_payable_at
                ? res.data.cost_payable_at.cost_payable_at_name
                : "",
            },
          ]
          : "";

        var selectedCostPayableByList = [];
        selectedCostPayableByList = res.data.cost_payable_by
          ? [
            {
              value: res.data.cost_payable_by
                ? res.data.cost_payable_by.slug
                : "",
              label: res.data.cost_payable_by
                ? res.data.cost_payable_by.cost_payable_by_name
                : "",
            },
          ]
          : "";
      }
      this.setState(resData);
      this.setState({
        newSelectedCostTypeList: newSelectedCostTypeList,
        selectedCostPayableAtList: selectedCostPayableAtList,
        selectedCostPayableByList: selectedCostPayableByList,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Type list data */
  async getCostTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costTypeList = [];
    var costTypeListTotal = 0;
    let res = await CostTypeService.getCostType(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costTypeList = res.data ? res.data : [];
      costTypeListTotal = costTypeList ? costTypeList.length : 0;
    }
    const newCostTypeList = costTypeListTotal > 0 ? costTypeList.map(({ slug, cost_type_name }) => ({
      value: slug,
      label: cost_type_name,
    })) : [];
    this.setState({
      costTypeList: costTypeList,
      newCostTypeList: newCostTypeList,
      costTypeListTotal: costTypeListTotal
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getSelectedCostType() {
    this.setState({ showSpinner: true, loading: true });
    var selectedCostTypeList = [];

    let res = await CostTypeService.getSelectedCostType(
      this.state.unit_slug + "/2"
    );

    if (global.successStatus.includes(res.status)) {
      selectedCostTypeList = res.data ? res.data : [];
    }
    const alreadySelectedCostTypeList = selectedCostTypeList.map(
      ({ slug, cost_type_name }) => ({
        value: slug,
        label: cost_type_name,
      })
    );
    this.setState({
      selectedCostTypeList: selectedCostTypeList,
      alreadySelectedCostTypeList: alreadySelectedCostTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Payable At list data */
  async getCostPayableAtData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costPayableAtList = [];
    var costPayableAtListTotal = 0;
    let res = await CostPayableAtService.getCostPayableAt(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costPayableAtList = res.data ? res.data : [];
      costPayableAtListTotal = costPayableAtList ? costPayableAtList.length : 0;
    }
    const newCostPayableAtList = costPayableAtListTotal > 0 ? costPayableAtList.map(
      ({ slug, cost_payable_at_name }) => ({
        value: slug,
        label: cost_payable_at_name,
      })
    ) : [];
    this.setState({
      costPayableAtList: costPayableAtList,
      newCostPayableAtList: newCostPayableAtList,
      costPayableAtListTotal: costPayableAtListTotal
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Payable By list data */
  async getCostPayableByData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costPayableByList = [];
    var costPayableByListTotal = 0;
    let res = await CostPayableByService.getCostPayableBy(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costPayableByList = res.data ? res.data : [];
      costPayableByListTotal = costPayableByList ? costPayableByList.length : 0;
    }
    const newCostPayableByList = costPayableByListTotal > 0 ? costPayableByList.map(
      ({ slug, cost_payable_by_name }) => ({
        value: slug,
        label: cost_payable_by_name,
      })
    ) : [];
    this.setState({
      costPayableByList: costPayableByList,
      newCostPayableByList: newCostPayableByList,
      costPayableByListTotal: costPayableByListTotal
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async deleteCost(slug) {
    this.setState({ showSpinner: true });
    let res = await PropertyCostService.deleteCost(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteUnitCostModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({ viewMode: 0, cost_slug: "" });
      this.getData();
    } else {
      this.closeDeleteUnitCostModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  saveOrUpdateCost = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        level: 2,
        ref_slug: this.state.unit_slug,
        cost_type_slug: this.state.cost_type_slug,
        cost_payable_at_slug: this.state.cost_payable_at_slug,
        cost_payable_by_slug: this.state.cost_payable_by_slug,
        cost_name: this.state.cost_name,
        estimated: this.state.estimated === true ? 1 : 2,
        mandatory: this.state.mandatory === true ? 1 : 2,
        refundable: this.state.refundable === true ? 1 : 2,
        // cf: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.unit_slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        // inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await PropertyCostService.updateCosts(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.changeView();
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectedCostTypeList(value) {
    let cost_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        cost_type_slug.push(item.value);
      });
    }
    const cost_type_slug_value = value !== null ? value.value : [];
    this.setState({
      newSelectedCostTypeList: value,
      cost_type_slug: cost_type_slug_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleChangeCostPayableByList(value) {
    let cost_payable_by_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        cost_payable_by_slug.push(item.value);
      });
    }
    const cost_payable_by_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCostPayableByList: value,
      cost_payable_by_slug: cost_payable_by_slug_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleChangeCostPayableAtList(value) {
    let cost_payable_at_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        cost_payable_at_slug.push(item.value);
      });
    }
    const cost_payable_at_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCostPayableAtList: value,
      cost_payable_at_slug: cost_payable_at_slug_value,
    });

    this.setState({ confirm_back: 1 });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.setState({
        cost_slug: "",
        newSelectedCostTypeList: [],
        selectedCostPayableAtList: [],
        selectedCostPayableByList: [],
      });
      this.setState({
        cost_name: "",
        cost_type_slug: "",
        cost_payable_at_slug: "",
        cost_payable_by_slug: "",
        refundable: "",
        estimated: "",
        mandatory: "",
        errors: "",
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
      this.getData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });

      this.setState({
        cost_name: "",
        cost_type_slug: "",
        cost_payable_at_slug: "",
        cost_payable_by_slug: "",
        refundable: "",
        estimated: "",
        mandatory: "",
        errors: "",
        newSelectedCostTypeList: [],
        selectedCostPayableAtList: [],
        selectedCostPayableByList: [],
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
      if (this.state.cost_slug === "") {
        this.getCostTypeData();
        this.getSelectedCostType();
        this.getCostPayableAtData();
        this.getCostPayableByData();
      } else {
        this.getCostDetails(this.state.cost_slug);
      }
    }
  }

  getTitle() {
    return "Fees";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <div className="dashboardRightcard- dashbaord-pageHeadertitle- background-none pt-0 pd-r-30 unitTable unit_new_table">
          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
            {this.state.viewMode === 0 ? (
              <></>
            ) : (
              // <button
              //   className="btn-success-outline-small"
              //   onClick={this.changeView}
              // >
              //   <img src={pluswIcon} alt="Add" />
              //   Add
              // </button>
              <>
                {this.state.cost_slug !== "" ? (
                  <>
                    {/* <button
                                type="button"
                                onClick={() => {
                                  this.openDeleteUnitCostModal(
                                    this.state.cost_slug
                                  );
                                }}
                                className="btn-danger-outline mg-r-20"
                              >
                                <img src={deleteicon} alt="Delete" />
                                Delete
                              </button>{" "} */}
                    <span
                      onClick={() => {
                        this.openDeleteUnitCostModal(this.state.slug);
                      }}
                      className="deleteicon mg-r-20"
                    >
                      <img src={deleteicon} />
                    </span>
                  </>
                ) : (
                  ""
                )}
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn-success-outline-small"
                    onClick={() => {
                      if (this.state.confirm_back === 1)
                        if (
                          window.confirm("Do you want to exit without saving?")
                        )
                          this.changeView();
                        else return false;
                      else this.changeView();
                    }}
                  >
                    Cancel
                  </button>
                  {this.state.cost_slug === "" ? (
                    <button
                      className="btn-success ml-3"
                      onClick={this.saveOrUpdateCost}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="btn-success ml-3"
                      onClick={this.saveOrUpdateCost}
                    >
                      Save
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="col-md-12 pl-0 pr-0 mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          {this.state.viewMode === 0 ? (
            <div className="row m-0">
              <div className="col-lg-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0  mg-t-15">
                <h3 className="mg-l-5 background-head">General</h3>
              </div>
              <div className="table-responsive pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-20">Fee Type</th>
                      <th className="w-20">Payable By</th>
                      <th className="w-20">Payable At</th>
                      <th className="w-20">Fee</th>
                      <th className="w-20">Refundable</th>
                      <th className="w-20">Estimated</th>
                      <th className="w-20 text-left">Mandatory</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.costListTotal > 0 ? (
                      this.state.costList.map((option, i) => {
                        return (
                          <tr
                            key={i}
                          // onClick={() => this.getCostDetails(item.slug)}
                          >
                            <td>
                              {option.cost_type
                                ? option.cost_type.cost_type_name
                                : ""}
                            </td>
                            <td>
                              {option.cost_payable_by
                                ? option.cost_payable_by.cost_payable_by_name
                                : ""}
                            </td>
                            <td>
                              {option.cost_payable_at
                                ? option.cost_payable_at.cost_payable_at_name
                                : ""}
                            </td>
                            <td>{option.cost_name ? "$" + global.onKeyPressEvent.numberWithCommasForDecimal(option.cost_name.replace(/\.00$/, '')) : ""}</td>
                            <td>{option.refundable === 1 ? "Yes" : "No"}</td>
                            <td>{option.estimated === 1 ? "Yes" : "No"}</td>
                            <td className="text-left">{option.mandatory === 1 ? "Yes" : "No"}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          There are no fees added in the system.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                <h3 className="mg-l-5 background-head mg-t-15">Pet</h3>
              </div>
              
              <div className="col-md-12">
                <table
                  className="table table-hoverno-wrap mg-b-30 pd-b-2"
                  id="as-react-datatable"
                >
                  <thead>
                    <tr>
                      <th className=" text-" width="">
                        Pet Type
                      </th>
                      <th className=" text-" width="">
                        Max # Allowed
                      </th>
                      <th className=" text-" width="">
                        Deposit
                      </th>
                      <th className=" text-" width="">
                        One-Time Fee
                      </th>
                      <th className=" text-" width="">
                        Monthly Rent
                      </th>
                      <th className=" text- text-left" width="">
                        Weight
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.total_record > 0 ?
                      this.state.records.map((option, i) => (
                        <tr key={i}>
                          <td>
                            {option.pet_type
                              ? option.pet_type.pet_type_name
                              : ""}
                          </td>
                          <td>
                            {option.allowed_pet_number
                              ? option.allowed_pet_number
                              : ""}
                          </td>
                          <td>

                            {option.deposite_amount_per_pet
                              ? <NumberFormat value={option.deposite_amount_per_pet.replace(/\.00$/, '')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                              : ""}
                          </td>
                          <td>
                            {option.one_time_fee_per_type
                              ? <NumberFormat value={option.one_time_fee_per_type.replace(/\.00$/, '')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                              : ""}
                          </td>
                          <td>
                            {option.rent_per_type
                              ? <NumberFormat value={option.rent_per_type.replace(/\.00$/, '')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                              : ""}
                          </td>
                          <td className="text-left">
                            {option.weight_per_type
                              ? option.weight_per_type.replace(/\.00$/, '') + " lbs"
                              : ""}
                          </td>
                        </tr>
                      )) :
                      <tr>
                        <td colSpan="9" align="center">
                          There are no fees added in the system.
                        </td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}

          {this.state.viewMode === 1 ? (
            <div className="m-0">
              <div className="row align-items-center pd-t-30 pd-b-30 border-bottom-0">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                  <label className="form-label text-left pd-b-5">
                    Cost Type
                  </label>

                  <Select
                    styles={customStyles}
                    isClearable={true}
                    className="multiselect"
                    menuPlacement="auto"
                    value={this.state.newSelectedCostTypeList}
                    options={
                      this.state.newCostTypeList
                        ? this.state.newCostTypeList.filter((option) => {
                          const val1 =
                            this.state.alreadySelectedCostTypeList.find(
                              (opt) => {
                                return opt === option.slug;
                              }
                            );
                          if (val1) return false;
                          else return true;
                        })
                        : ""
                    }
                    getOptionValue={(option) => `${option.label}`}
                    onChange={(value) =>
                      this.handleChangeSelectedCostTypeList(value)
                    }
                    defaultValue={this.state.newSelectedCostTypeList}
                    isDisabled={this.state.cost_slug !== "" ? true : false}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 2,
                      colors: {
                        ...theme.colors,
                        primary: "#fff",
                        primary75: "#000",
                        primary50: "#000",
                        primary25: "#000",
                      },
                    })}
                  />

                  {this.state.errors.cost_type_slug ? (
                    <div className="text-danger">
                      {this.state.errors.cost_type_slug}
                    </div>
                  ) : (
                    this.validator.message(
                      "cost_type",
                      this.state.cost_type_slug,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                  <label className="form-label text-left pd-b-5">
                    Cost Amount ($){" "}
                  </label>

                  <Form.Control
                    onChange={this.changeHandler}
                    name="cost_name"
                    value={this.state.cost_name}
                    className="form-control max_width_100"
                    type="text"
                    onKeyPress={global.onKeyPressEvent.floatValidation}
                  />
                  {this.state.errors.cost_name ? (
                    <div className="text-danger">
                      {this.state.errors.cost_name}
                    </div>
                  ) : (
                    this.validator.message(
                      "cost_name",
                      this.state.cost_name,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                  <label className="form-label text-left pd-b-5">
                    Cost Payable At{" "}
                  </label>

                  <Select
                    styles={customStyles}
                    isClearable={true}
                    className="multiselect"
                    menuPlacement="auto"
                    value={this.state.selectedCostPayableAtList}
                    options={this.state.newCostPayableAtList}
                    getOptionValue={(option) => `${option.label}`}
                    onChange={(value) =>
                      this.handleChangeCostPayableAtList(value)
                    }
                    defaultValue={this.state.selectedCostPayableAtList}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 2,
                      colors: {
                        ...theme.colors,
                        primary: "#fff",
                        primary75: "#000",
                        primary50: "#000",
                        primary25: "#000",
                      },
                    })}
                  />

                  {this.state.errors.cost_payable_at_slug ? (
                    <div className="text-danger">
                      {this.state.errors.cost_payable_at_slug}
                    </div>
                  ) : (
                    this.validator.message(
                      "cost_payable_at",
                      this.state.cost_payable_at_slug,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                  <label className="form-label text-left pd-b-5">
                    Cost Payable By{" "}
                  </label>

                  <Select
                    styles={customStyles}
                    isClearable={true}
                    className="multiselect"
                    menuPlacement="auto"
                    value={this.state.selectedCostPayableByList}
                    options={this.state.newCostPayableByList}
                    getOptionValue={(option) => `${option.label}`}
                    onChange={(value) =>
                      this.handleChangeCostPayableByList(value)
                    }
                    defaultValue={this.state.selectedCostPayableByList}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 2,
                      colors: {
                        ...theme.colors,
                        primary: "#fff",
                        primary75: "#000",
                        primary50: "#000",
                        primary25: "#000",
                      },
                    })}
                  />

                  {this.state.errors.cost_payable_by_slug ? (
                    <div className="text-danger">
                      {this.state.errors.cost_payable_by_slug}
                    </div>
                  ) : (
                    this.validator.message(
                      "cost_payable_by",
                      this.state.cost_payable_by_slug,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
              </div>
              <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
              </div>

              <div className="row align-items-center pd-t-10 pd-b-40 pd-l-30 pd-r-30 border-bottom-0">
                <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0">
                  <ul className="formcheck-section">
                    <li>
                      <label className="form-label text-left mg-b-0">
                        Refundable
                      </label>
                      <Form.Check
                        name="refundable"
                        type={"checkbox"}
                        id={`refundable`}
                        label={""}
                        className={"ckbox"}
                        onChange={this.changeHandler}
                        checked={this.state.refundable}
                      />
                    </li>
                    <li>
                      <label className="form-label text-left mg-b-0  pd-l-0">
                        Estimated
                      </label>
                      <Form.Check
                        name="estimated"
                        type={"checkbox"}
                        id={`estimated`}
                        label={""}
                        className={"ckbox"}
                        onChange={this.changeHandler}
                        checked={this.state.estimated}
                      />
                    </li>
                    <li>
                      <label className="form-label text-left mg-b-0">
                        Mandatory
                      </label>
                      <Form.Check
                        name="mandatory"
                        type={"checkbox"}
                        id={`mandatory`}
                        label={""}
                        className={"ckbox"}
                        onChange={this.changeHandler}
                        checked={this.state.mandatory}
                      />
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
              </div>

              <div className="row align-items-center pd-t-30 pd-b-30 pd-l-30 pd-r-30 border-bottom-0">
                <div className="col-md-1 pl-0">
                  <label className="form-label text-left pd-b-5">Status</label>
                </div>

                <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                  <b
                    className={this.state.status_id ? "active" : "inactive"}
                  ></b>
                  <div
                    className={
                      this.state.status_id ? "az-toggle on" : "az-toggle"
                    }
                    onClick={() =>
                      this.setState({
                        status_id: !this.state.status_id,
                      })
                    }
                  >
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <Modal show={this.state.deleteUnitCostModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteUnitCostModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteUnitCostModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCost(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default UnitCost;
