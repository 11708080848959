////////////////////////////////////////////////////////////
//  Program: user.js                                      //
//  Application: RU GUEST COMMUNICATION                   //
//  Developer: Kiran Singh						          //
//  Date: 2022-09-13                                      //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class UserService {
  getUserList(queryString) {
    let endPoint = "get-user-list" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getUserDropdown(queryString) {
    let endPoint =
      "get-users-dropdown" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // get USER LIST FOR PM AND RESIDENT
  getUserListForMessage(queryString) {
    let endPoint =
      "get-user-list-message" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getUserDetail(slug) {
    let endPoint = "get-user-details/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  SendEmail(data) {
    let endPoint = "send-set-password";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  SendEmailGuest(data) {
    let endPoint = "send-set-password-guest";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  UserActivity(slug, queryString) {
    let endPoint =
      "get-user-activity-list/" + slug + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = [];
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new UserService();
