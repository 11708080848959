////////////////////////////////////////////////////////////
//     							                                      //
//  Program: UnitAmenities.jsx                            //
//  Application: Unit                                     //
//  Option: List all Unit Amenities                       //
//  Developer: Ashish Kumar					                      //
//  Date: 2022-07-05                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
// import { Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import AmenityService from "../../services/AmenityService";
import editIcon from "./../../assets/images/edit-pen-icon.svg";

export class UnitAmenities extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      open: global.showOpen,
      eventKey: "",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      isSubmit: false,
      pathname: window.location.pathname,
      importModal: false,
      amenitiesList: [],
      unitAmenitiesList: [],
      selectedAmenities: [],
      slugUncheckList: [],
      // confirm_back: 0,
      select_all: false,
      activeAmenities: 0,
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.changeView = this.changeView.bind(this);
    this.handleCheckboxChangeNew = this.handleCheckboxChangeNew.bind(this);
    this.getAmenitiesDetails = this.getAmenitiesDetails.bind(this);
    this.addUnitAmenities = this.addUnitAmenities.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  async componentDidMount() {
    // setTimeout(() => {
    //   this.getPropertyAmenitiesData();
    // }, 100);
    this.getAmenitiesData();
    this.getUnitLevelAmenitiesData();
  }

  /* to get floor plan level amenities data */
  async getUnitLevelAmenitiesData() {
    var unitLevelAmenitiesList = [];
    var unitLevelAmenitiesListTotal = 0;
    let res = await AmenityService.getPropertyAmenities(
      this.props.property_slug + "/4"
    );
    if (global.successStatus.includes(res.status)) {
      unitLevelAmenitiesList = res.data ? res.data : [];
      unitLevelAmenitiesListTotal = unitLevelAmenitiesList
        ? unitLevelAmenitiesList.length
        : 0;
    }
    this.setState({
      unitLevelAmenitiesList: unitLevelAmenitiesList,
      unitLevelAmenitiesListTotal: unitLevelAmenitiesListTotal,
    });
  }

  /* to amenities data */
  async getAmenitiesData(queryString = "") {
    var amenitiesList = [];
    var amenitiesListTotal = 0;
    var basicAmenityList = [];
    var finalAmenityList  = [];

    let res = await AmenityService.getAmenity(
      "level=4&is_mapped=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      amenitiesList = res.data ? res.data.data : [];
      basicAmenityList = res.data ? res.data.data : [];
      amenitiesListTotal = amenitiesList ? amenitiesList.length : 0;

      basicAmenityList.map((index,value) => {
        if(finalAmenityList[basicAmenityList[value]['amenity_type']['amenity_type_name']]!== undefined) {
          finalAmenityList[basicAmenityList[value]['amenity_type']['amenity_type_name']].push({amenity_name:basicAmenityList[value]['amenity_name'],amenity_slug:basicAmenityList[value]['slug'],checked:false});
        } 
        else{
          finalAmenityList[basicAmenityList[value]['amenity_type']['amenity_type_name']]=[{amenity_name:basicAmenityList[value]['amenity_name'],amenity_slug:basicAmenityList[value]['slug'],checked:false}];
        }
      })
    }
    if (amenitiesListTotal > 0) {
      amenitiesList.map((opt, i) => {
        if (opt.status_id === 1) {
          this.setState({ activeAmenities: 1 });
        }
      });
    }
    this.setState({
      basicAmenityList:basicAmenityList,
      amenitiesList: finalAmenityList,
      amenitiesListTotal: amenitiesListTotal,
    });

    this.getPropertyAmenitiesData();
  }

  /* to get property amenities data */
  async getPropertyAmenitiesData() {
    var unitAmenitiesList = [];
    var unitAmenitiesListTotal = 0;
    var selectedAmenities = [];
    let res = await AmenityService.getPropertyAmenities(
      this.props.unit_slug + "/4"
    );
    if (global.successStatus.includes(res.status)) {
      // unitAmenitiesList = res.data ? res.data : []
      unitAmenitiesListTotal = res.data ? res.data.length : 0

      var basicAmenityList = [...this.state.basicAmenityList];
      basicAmenityList.map((item, i) => {
        res.data.map((options) => {
          if (item.slug === options.slug) {
            basicAmenityList[i].checked = true;
          } else {
            if (basicAmenityList[i].checked !== true) {
              basicAmenityList[i].checked = false;
            }
          }
        });
      });

      var amenitiesList = [];

      basicAmenityList.map((index,value) => {
        if(amenitiesList[basicAmenityList[value]['amenity_type']['amenity_type_name']]!== undefined) {
          amenitiesList[basicAmenityList[value]['amenity_type']['amenity_type_name']].push({amenity_name:basicAmenityList[value]['amenity_name'],amenity_slug:basicAmenityList[value]['slug'],checked:basicAmenityList[value]['checked']});
        } 
        else{
          amenitiesList[basicAmenityList[value]['amenity_type']['amenity_type_name']]=[{amenity_name:basicAmenityList[value]['amenity_name'],amenity_slug:basicAmenityList[value]['slug'],checked:basicAmenityList[value]['checked']}];
        }
      })

      res.data.map((index,value) => {
        if(unitAmenitiesList[res.data[value]['amenity_type']['amenity_type_name']]!== undefined) {
          unitAmenitiesList[res.data[value]['amenity_type']['amenity_type_name']].push({amenity_name:res.data[value]['amenity_name'],amenity_slug:res.data[value]['amenity_slug'],checked:res.data[value]['checked']});
        } 
        else{
          unitAmenitiesList[res.data[value]['amenity_type']['amenity_type_name']]=[{amenity_name:res.data[value]['amenity_name'],amenity_slug:res.data[value]['amenity_slug'],checked:res.data[value]['checked']}];
        }
      })
    }
    res.data.forEach((opt) => {
      selectedAmenities.push(opt.slug);
    });
    // var checkAmenitiesList = this.state.amenitiesList;
    // this.state.amenitiesList.map((item, i) => {
    //   unitAmenitiesList.map((options) => {
    //     if (item.slug === options.slug) {
    //       checkAmenitiesList[i].checked = true;
    //     } else {
    //       if (checkAmenitiesList[i].checked !== true) {
    //         checkAmenitiesList[i].checked = false;
    //       }
    //     }
    //   });
    // });

    this.setState({
      selectedAmenities: selectedAmenities,
      amenitiesList: amenitiesList,
      unitAmenitiesList: unitAmenitiesList,
      unitAmenitiesListTotal: unitAmenitiesListTotal,
    });
    if (this.state.amenitiesListTotal === this.state.unitAmenitiesListTotal) {
      this.setState({ select_all: true });
    } else {
      this.setState({ select_all: false });
    }
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 1 });
    } else {
      if (this.state.unitAmenitiesListTotal > 0) {
        this.setState({ viewMode: 0 });
      } else {
        this.setState({ viewMode: 1 });
      }
    }
  }

  /* to save amenities data */
  addUnitAmenities = async (e) => {
    e.preventDefault();

    if (this.state.unit_slug || this.props.unit_slug) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        amenitiesData:
          this.state.selectedAmenities.length > 0
            ? this.state.selectedAmenities
            : [""],
      };

      let res = await AmenityService.addOrUpdateAmenity(
        this.props.unit_slug + "/4",
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        var check = document.getElementsByName("one");
        for (var i = 0; i < check.length; i++) {
          document.getElementsByName("one")[i].checked = false;
        }
        this.setState({ selectedAmenities: [] });
        this.getPropertyAmenitiesData();
        this.setState({ viewMode: 0 });
        // setTimeout(
        //   () => document.getElementById("listingPrice").click(),
        //   global.redirect_time
        // );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({
        showSpinner: false,
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: "Please create your unit first.",
      });
      setTimeout(
        () => document.getElementById("infoForm").click(),
        global.alert_close_time
      );
    }
  };

  //checkbox change handler
  handleCheckboxChangeNew = (event, index) => {
    let amenitiesList = this.state.amenitiesList;
    let newArray = [...this.state.selectedAmenities, event.target.id];
    if (this.state.selectedAmenities.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    Object.keys(amenitiesList).map((item, o) => {
      amenitiesList[item].map((options) => {
        if (event.target.id === options.amenity_slug) {
          options.checked = event.target.checked === true ? true : false;
        }
      });
    })
    this.setState({amenitiesList:amenitiesList});
    this.setState({ selectedAmenities: newArray });
  }
  /* To amenities details data */
  async getAmenitiesDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await AmenityService.getAmenityBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.amenity_name = res.data.amenity_name
          ? res.data.amenity_name
          : "";
        resData.amenity_type_name = res.data.amenity_type
          ? res.data.amenity_type.amenity_type_name
          : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.tag_line = res.data.tag_line ? res.data.tag_line : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  confirmImportModal(slug) {
    this.getAmenitiesDetails(slug);
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  changeHandler = (event) => {
    if (event.target.name === "select_all") {
      if (event.target.checked === true) {
        this.setState({select_all:true});
      }else{
        this.setState({select_all:false});
      }
    }
    let amenitiesList = this.state.amenitiesList;
    let newArray = [...this.state.selectedAmenities];

    if (this.state.selectedAmenities.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    
    Object.keys(amenitiesList).map((item, o) => {
      amenitiesList[item].map((options) => {
          options.checked = event.target.checked === true ? true : false;
          if (event.target.name === "select_all") {
            if (event.target.checked === true) {
              newArray.push(options.amenity_slug);
            } else {
              newArray = [""];
            }
          }
      });
    })

    this.setState({amenitiesList:amenitiesList});
    this.setState({ selectedAmenities: newArray });
  };

  handleCheckboxChange = (event, index) => {
    this.setState({ select_all: "" });
    let newArray = [...this.state.selectedAmenities, event.target.id];
    if (this.state.selectedAmenities.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    var amenitiesList = this.state.amenitiesList;
    amenitiesList[index].checked = event.target.checked === true ? true : false;
    this.setState({ selectedAmenities: newArray });
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.eventKey });
  }

  changeView() {
    // var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ selectedAmenities: [] });
      this.setState({ slugUncheckList: [] });
      this.setState({ confirm_back: 0 });
      this.getAmenitiesData();
      // this.props.confirmBackStatus(confirmBack);
      // this.getPropertyAmenitiesData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      // this.props.confirmBackStatus(confirmBack);
      this.getAmenitiesData();
      // this.getPropertyAmenitiesData();
    }
  }

  render() {
    return (
      <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <div className="d-flex flex-wrap justify-content-end bothBtn swiperBtns">
          {this.state.viewMode === 0 && global.userPermissions.checkPermission('units-update') ? (
            <button
              className="btn-success-outline-small mr-3"
              onClick={this.changeView}
            >
              <img src={editIcon} alt="Edit" />
              {this.state.unitAmenitiesListTotal > 0 ? "Edit" : "Edit"}
            </button>
          ) : (
            <div className="d-flex">
              <button
                type="button"
                className="btn-success-outline-small mg-r-20"
                onClick={this.changeView}
              >
                Cancel
              </button>
              {global.userPermissions.checkPermission('units-update') &&
              <button
                className="btn-success-outline-small mr-3"
                onClick={this.addUnitAmenities}
              >
                Save{" "}
              </button>
              }
            </div>
          )}
        </div>
        <div className="col-md-12 pd-l-30 pd-r-30 mg-t-15">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>
        {this.state.viewMode === 0 ? (
          <React.Fragment>
            <div className="m-0">
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 background-head">Unit Amenities</h3>
              </div>
              {this.state.unitAmenitiesListTotal > 0 ? (
                <React.Fragment>
                <div className="m-0">
                  {this.state.unitAmenitiesListTotal > 0
                    ? 
                    Object.keys(this.state.unitAmenitiesList).map(
                      (opt, i) => {
                        return(
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30" key={"amenity_type_"+i}>
                              <h3 className="mg-l-0 mg-t-15 background-head d-flex justify-content-between align-items-center">
                              {opt}
                              </h3>
                              <div className="aminityListView pd-l-10 pd-r-10 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-0">
                              <ul className="attraction">
                                {(this.state.unitAmenitiesList[opt]).map((k,v) => {
                                    return (
                                      <li className="m-2" key={"amenity_"+v}>{k.amenity_name}</li>
                                    );      
                                  }
                                )}
                              </ul>
                          </div>
                          </div>
                        )
                      }
                    )
                  : ""}
                </div>
              </React.Fragment>
              ) : (
                <h6 className="mg-t-20 text-center w-100">
                  There are no unit amenities added in the system. You may add
                  them by clicking Edit button.
                </h6>
              )}
            </div>
          </React.Fragment>
        ) : (
          ""
        )}

        {this.state.viewMode === 1 ? (
          <div className="m-0">
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 background-head d-flex justify-content-between align-items-center">
                Unit Amenities
              </h3>
            </div>
            <div className="row align-items-center pd-b-30 pd-l-30 pd-r-30 border-bottom-0 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-0 pd-r-0 pd-r-15 mg-b-0">
                {this.state.activeAmenities === 1 ? (
                  <React.Fragment>
                  {" "}
                  <div className="select-all-checkbox d-block w-100 mg-b-20">
                    <div className="ckbox form-check">
                      <label htmlFor="select_all" className="form-label text-left pd-b-5 mg-l-20 mg-r-10">
                        <b>Select All</b>
                      </label>
                      <input
                        name="select_all"
                        type={"checkbox"}
                        id={`select_all`}
                        onChange={(e) => this.changeHandler(e)}
                        className="form-check-input"
                        checked={this.state.select_all}
                      />
                      <label
                        title=""
                        htmlFor="select_all"
                        className="form-check-label"
                      ></label>
                    </div>
                  </div>
                   {this.state.amenitiesListTotal > 0
                ? Object.keys(this.state.amenitiesList).map((item, o) => {
                  return (
                    <>
                    <div className="col-lg-12 pd-xs-l-15 pd-lg-l-0 pd-xs-r-0 pd-lg-r-0" key={"amenity_type_"+o}>
                      <h3 className="mg-l-0 background-head d-flex justify-content-between align-items-center">
                        {item}
                      </h3>
                    </div>
                    <div className="row align-items-center border-bottom-0 ">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-0 pd-r-0 pd-r-40 mg-b-0">                             
                      <div className="aminityList">
                        {this.state.amenitiesListTotal > 0 ? (
                          <ul className="formcheck-section mg-b-0 flex-wrap">
                            {this.state.amenitiesListTotal > 0
                              ? this.state.amenitiesList[item].map((item1,i) => {
                                  var check_res = item1.checked === true ? "checked" : "";
                                    return (
                                      <React.Fragment key={"amenity_"+item1+"_"+i}>
                                        <li>
                                          <div className="ckbox form-check">
                                            <label htmlFor={item1.amenity_slug} className="form-label text-left mg-l-20 pd-b-5">
                                              {item1.amenity_name}
                                            </label>
                                            <input
                                              name="affordable_component_check"
                                              type="checkbox"
                                              id={item1.amenity_slug}
                                              className="form-check-input"
                                              onChange={(e) =>
                                                this.handleCheckboxChangeNew(e, item)
                                              }
                                              checked={check_res}
                                            />
                                            <label
                                              title=""
                                              htmlFor={item1.amenity_slug}
                                              className="form-check-label"
                                            ></label>
                                          </div>
                                        </li>
                                      </React.Fragment>
                                    );
                                })
                              : ""}
                          </ul>
                        ) : (
                          <h6 className="mg-t-20 d-block w-100 text-center">
                            There are no unit amenities added in the system.
                          </h6>
                        )}
                      </div>
                      </div>
                    </div>
                    </>
                    );
                  })
                : ""}
                </React.Fragment>
                ) : (
                  <h6 className="mg-t-20 text-center w-100">
                    There are no unit amenities added in the system.
                  </h6>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default UnitAmenities;