import React, { useState, useRef } from "react";
import { Container, Tab, Nav, Form, Modal } from "react-bootstrap";
import Alerts from "../../common/Alerts";
import SimpleReactValidator from "simple-react-validator";
// import { Link } from "react-router-dom";
// import NoData from "../common/NoData";
import deleteicon from "../../../assets/images/delete.svg";
import SettingsSidebar from "../../common/SettingsSidebar";
import NoiseawareService from "../../../services/NoiseawareService";
import modaldeleteicon from "./../../../assets/images/delete.svg";
import { useEffect } from "react";
import Select from "react-select";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
//import UnitMapping from "./UnitMapping";
//import AlertSetting from "./AlertSetting";
import NoiseawareActivity from "./NoiseawareActivity";

const NoiseawareTemplateForm = (props) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  // const getTitle = () => {
  //     return "NoiseAware Alert Setup";
  // };

  const [temp, setTemp] = useState(true);
  const [newCommServedList, setNewCommServedList] = useState([]);
  const [newCommUsersList, setNewCommUsersList] = useState([]);

  const [state, setState] = useState({
    errors: {},
    slug: props.match.params.slug,
    property_slug: props.match.params.propertySlug,
    activeClass: "alerts",
    template_name: "",
    template_subject: "",
    template_body: "",
    template_desc: "",
    alert_sent_to: 0,
    user_roles: "",
    user_list: "",
    selectedAlertList: [],
    newAlertList: [
      {
        value: "newNoise",
        label: "New Noise",
      },
      {
        value: "newDisconnect",
        label: "New Disconnect",
      },
      {
        value: "resolvedDisconnect",
        label: "Resolved Disconnect",
      },
      {
        value: "newTamper",
        label: "New Tamper",
      },
      {
        value: "newLowBattery",
        label: "New Low Battery",
      },
      {
        value: "newPropertyDisconnect",
        label: "New Property Disconnect",
      },
      {
        value: "resolvedPropertyDisconnect",
        label: "Resolved Property Disconnect",
      },
      {
        value: "newAdvancedAlert",
        label: "New Advanced Alert",
      },
      {
        value: "secondAdvancedAlert",
        label: "Second Advanced Alert",
      },
      {
        value: "thirdAdvancedAlert",
        label: "Third Advanced Alert",
      },
      {
        value: "resolvedAdvancedAlert",
        label: "Resolved Advanced Alert",
      },
      {
        value: "firstAutoResolveGuest",
        label: "First AutoResolve Guest",
      },
      {
        value: "secondAutoResolveGuest",
        label: "Second AutoResolve Guest",
      },
      {
        value: "thirdAutoResolvePM",
        label: "Third AutoResolve PM",
      },
    ],
    template_desc_info: {
      newNoise:
        "Noise levels above the set threshold has been detected. A warning message should be sent to the guest.",
      newDisconnect:
        "A device located at this property has not sent data for 30 mins deeming it disconnected.",
      resolvedDisconnect:
        "A device has resumed sending data deeming it reconnected.",
      newTamper: "A device has been physically moved or jarred.",
      newLowBattery: "A device's battery level is below a threshold.",
      newPropertyDisconnect:
        "All devices at this property have not sent data for 30 mins.",
      resolvedPropertyDisconnect:
        "At least one of the devices at a property has resumed sending data following a full property disconnect.",
      newAdvancedAlert:
        "Sustained noise levels above the set threshold has been detected.",
      secondAdvancedAlert:
        "Noise levels have sustained for another 10 mins following the newAdvancedAlert alert type.",
      thirdAdvancedAlert:
        "Noise levels have sustained for another 10 mins following the secondAdvancedAlert alert type.",
      resolvedAdvancedAlert:
        "Sustained noise levels that resulted in any of the advanced alert types above has now dropped below the threshold.",
      firstAutoResolveGuest:
        "Sustained noise levels above the set threshold has been detected. A warning message should be sent to the guest.",
      secondAutoResolveGuest:
        "Noise levels have sustained for another 10 mins following the firstAutoResolveGuest alert type. A firm warning message should be sent to the guest.",
      thirdAutoResolvePM:
        "Noise levels have sustained for another 10 mins following the secondAutoResolveGuest alert type. A message should be sent to the property manager to inform.",
    },
    //newCommServedList: [],
    // newCommUsersList: [
    //     {
    //         value: "1",
    //         label: "Admin E2logy",
    //     },
    //     {
    //         value: "2",
    //         label: "Elan E2logy",
    //     },
    //     {
    //         value: "3",
    //         label: "Mitesh E2logy",
    //     },
    //     {
    //         value: "4",
    //         label: "Saurabh E2logy",
    //     },
    //     {
    //         value: "5",
    //         label: "kanan Orion Haus",
    //     },

    // ],
    confirm_back: 0,
    isSubmit: false,
    showAlertModal: false,
    alertModalType: "",
    alertModalTitle: "",
    alertModalMessage: "",
    deleteTowerModal: false,
  });

  useEffect(() => {
    getDataFromSlug();
    getRoleData();
    getUserData();
  }, []);

  useEffect(() => {
    if (state.user_roles === "" && state.user_list === "") {
      simpleValidator.current.fields.user_roles = false;
      simpleValidator.current.errorMessages.user_roles =
        "You have to select atleast one Role or User";
    } else {
      simpleValidator.current.fields.user_roles = true;
      delete simpleValidator.current.errorMessages["user_roles"];
    }
  }, [temp]);

  function getActiveClass(e) {
    setState({ ...state, activeClass: e });
  }

  function changeHandler(evt) {
    let value = evt.target.value;
    value = evt.target.type === "radio" ? parseInt(value) : value;

    setState({
      ...state,
      [evt.target.name]: value,
      selectedCommServedList:
        evt.target.type === "radio" ? "" : state.selectedCommServedList,
      selectedCommUsersList:
        evt.target.type === "radio" ? "" : state.selectedCommUsersList,
      confirm_back: 1,
      errors: {},
    });
  }

  function handleChangeAlertType(value) {
    let template_name = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        template_name.push(item.value);
      });
    }
    const alert_slug_value = value !== null ? value.value : [];
    setState({
      ...state,
      selectedAlertList: value,
      template_desc: state.template_desc_info[alert_slug_value],
      template_name: alert_slug_value,
      confirm_back: 1,
    });
  }

  function handleChangeCommServedList(value) {
    let communities_served_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        communities_served_id.push(item.value);
      });
    }

    if (communities_served_id.length > 0) {
      simpleValidator.current.fields.user_roles = true;
      delete simpleValidator.current.errorMessages["user_roles"];
    } else {
      setTemp(!temp);
    }
    setState({
      ...state,
      selectedCommServedList: value,
      user_roles: communities_served_id,
      confirm_back: 1,
      errors: simpleValidator.current.errorMessages,
    });
  }

  function handleChangeCommUsersList(value) {
    let communities_users_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        communities_users_id.push(item.value);
      });
    }

    if (communities_users_id.length > 0) {
      simpleValidator.current.fields.user_roles = true;
      delete simpleValidator.current.errorMessages["user_roles"];
    } else {
      setTemp(!temp);
    }

    setState({
      ...state,
      selectedCommUsersList: value,
      user_list: communities_users_id,
      confirm_back: 1,
    });
  }

  function opendeleteTowerModal(slug) {
    setState({ ...state, deleteTowerModal: true, slug: slug });
  }

  function closedeleteTowerModal() {
    setState({ ...state, deleteTowerModal: false, slug: "" });
  }

  async function deleteTemplate(slug) {
    let res = await NoiseawareService.deleteTemplate(slug);
    closedeleteTowerModal();
    if (global.successStatus.includes(res.status)) {
      setState({
        ...state,
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(() => {
        props.history.push(
          "/properties/view/" + state.property_slug + "/noiseaware-template"
        );
      }, global.redirect_time);
    } else {
      setState({
        ...state,
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
      setTimeout(
        () => setState({ ...state, showAlertModal: false }),
        global.alert_close_time
      );
    }
  }

  async function getRoleData(queryString = "") {
    var roleList = [];
    var roleListTotal = 0;

    let res = await NoiseawareService.getRoles(
      "is_dropdown=1&is_from_noise=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      roleList = res.data ? res.data : [];
      roleListTotal = roleList ? roleList.length : 0;
    }
    const newRoleList =
      roleListTotal > 0
        ? roleList.map(({ role_id, role_title }) => ({
            value: role_id,
            label: role_title,
          }))
        : [];
    setNewCommServedList(newRoleList);
    setState({
      ...state,
      towerList: roleList,
      towerListTotal: roleListTotal,
    });
    // setState({ ...state, showSpinner: false, loading: false });
  }

  async function getUserData(queryString = "") {
    var userList = [];
    var userListTotal = 0;

    let res = await NoiseawareService.getPropertyUsers(
      "property_slug=" + state.property_slug
    );

    if (global.successStatus.includes(res.status)) {
      userList = res.data ? res.data : [];
      userListTotal = userList ? userList.length : 0;
    }

    const newUserList =
      userListTotal > 0
        ? userList.map(({ full_name, user_id }) => ({
            value: user_id,
            label: full_name,
          }))
        : [];
    setNewCommUsersList(newUserList);
  }

  const getDataFromSlug = async (event) => {
    if (state.slug !== undefined) {
      var resData = {};
      let res = await NoiseawareService.getTemplateBySlug(state.slug);

      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.template_name = res.data.template_name
          ? res.data.template_name
          : "";
        resData.template_subject = res.data.template_subject
          ? res.data.template_subject
          : "";
        resData.template_body = res.data.template_body
          ? res.data.template_body
          : "";
        resData.alert_to = res.data.alert_to ? res.data.alert_to : 1;
        resData.selectedCommServedList = res.data.role_data
          ? res.data.role_data
          : [];
        resData.selectedCommUsersList = res.data.user_data
          ? res.data.user_data
          : [];

        let customAlert = [];
        state.newAlertList.map((i, item) => {
          customAlert[i.value] = i.label;
        });

        let customRoles = [];
        resData.selectedCommServedList.map((i, item) => {
          customRoles.push(i.value);
        });

        let customUsers = [];
        resData.selectedCommUsersList.map((i, item) => {
          customUsers.push(i.value);
        });

        if (
          resData.selectedCommServedList.length > 0 ||
          resData.selectedCommUsersList.length > 0
        ) {
          simpleValidator.current.fields.user_roles = true;
        }

        setState({
          ...state,
          template_name: resData.template_name,
          template_body: resData.template_body,
          template_subject: resData.template_subject,
          selectedAlertList: {
            label: customAlert[resData.template_name],
            value: resData.template_name,
          },
          alert_sent_to: resData.alert_to,
          selectedCommServedList: resData.selectedCommServedList,
          selectedCommUsersList: resData.selectedCommUsersList,
          user_roles:
            resData.selectedCommServedList.length > 0
              ? customRoles.join(",")
              : "",
          user_list:
            resData.selectedCommUsersList.length > 0
              ? customUsers.join(",")
              : "",
          template_desc: state.template_desc_info[resData.template_name],
        });
      }
    }
  };

  const saveOrUpdateTemplate = async (event) => {
    event.preventDefault();
    let fields = simpleValidator.current.fields;
    let isError = 0;
    let res = [];
    let isSubmit = true;
    {
      Object.keys(fields).map((key) => {
        if (!fields[key]) {
          isError = 1;
        }
      });
    }

    if (
      (state.user_roles === "" && state.user_list === "") ||
      (state.user_roles.length === 0 && state.user_list.length === 0)
    ) {
      setTemp(!temp);
    } else {
      setTemp(!temp);
    }

    if (isError === 1) {
      isSubmit = false;
      setState({
        ...state,
        isSubmit: isSubmit,
        errors: simpleValidator.current.errorMessages,
      });
    } else {
      isSubmit = true;
      setState({
        ...state,
        isSubmit: isSubmit,
      });
      // if validations successfull API Call
      let commaSepRoles = Array.isArray(state.user_roles)
        ? state.user_roles.join(",")
        : state.user_roles;
      let commaSepUsers = Array.isArray(state.user_list)
        ? state.user_list.join(",")
        : state.user_list;
      let inputData = {
        property_slug: state.property_slug,
        template_name: state.template_name,
        template_subject: state.template_subject,
        template_body: state.template_body,
        alert_sent_to: state.alert_sent_to,
        user_roles: commaSepRoles,
        users_list: commaSepUsers,
      };

      if (state.slug !== undefined) {
        res = await NoiseawareService.updateTemplate(state.slug, inputData);

        if (global.successStatus.includes(res.status)) {
          setState({
            ...state,
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              props.history.push(
                "/properties/view/" +
                  state.property_slug +
                  "/noiseaware-template"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            setState({ errors: errors });
          }
        }
      } else {
        res = await NoiseawareService.createTemplate(inputData);
        if (global.successStatus.includes(res.status)) {
          isSubmit = false;
          setState({
            ...state,
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
            confirm_back: 1,
            isSubmit: isSubmit,
            errors: simpleValidator.current.errorMessages,
          });
          setTimeout(
            () =>
              props.history.push(
                "/properties/view/" +
                  state.property_slug +
                  "/noiseaware-template"
              ),
            global.redirect_time
          );
        }
      }
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state.isSelected && state.isFocused
          ? "white"
          : state.isSelected
          ? "black"
          : state.isFocused && "white",
      backgroundColor:
        state.isSelected && state.isFocused
          ? "black"
          : state.isFocused && "black",
    }),
  };

  return (
    <main>
      <Container className="innter-container">
        <div className="d-flex flex-column">
          <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
            <div className="col-md-2 left">
              <div className="child-menu-section">
                <aside>
                  <SettingsSidebar property_slug={state.property_slug} />
                </aside>
              </div>
            </div>

            <div className="col-md-10 right">
              <div className="col-md-12 mg-t-20 mg-b-10"></div>
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns">
                  <h3>NoiseAware Settings</h3>
                </div>
              </div>
              <div className="scrolling-carousel pd-l-15">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="alerts"
                  onSelect={(e) => getActiveClass(e)}
                  eventKey={state.activeClass}
                >
                  <ScrollingCarousel
                    rightArrow={true}
                    leftArrow={true}
                    className="swipesection scroll_nav_mob"
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="alerts"
                        id="infoForm"
                        className={
                          state.activeClass === "alerts" ? "active" : ""
                        }
                      >
                        Alert
                      </Nav.Link>
                    </Nav.Item>

                    {/* <Nav.Item>
                                            <Nav.Link
                                                eventKey="units"
                                                id="units"
                                                className={
                                                    state.activeClass === "units" ? "active" : ""
                                                }
                                            >
                                                2. Unit Mapping
                                            </Nav.Link>
                                        </Nav.Item> */}

                    <Nav.Item>
                      <Nav.Link
                        eventKey="alert-activity"
                        id="alert-activity"
                        className={
                          state.activeClass === "alert-activity" ? "active" : ""
                        }
                      >
                        Alert Activity
                      </Nav.Link>
                    </Nav.Item>
                  </ScrollingCarousel>
                  <Tab.Content>
                    <Tab.Pane eventKey="alerts">
                      <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                        <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
                          {state.slug !== undefined ? (
                            <>
                              {global.userPermissions.checkPermission(
                                "noiseaware-delete"
                              ) && (
                                <span
                                  onClick={() => {
                                    opendeleteTowerModal(state.slug);
                                  }}
                                  className="deleteicon mg-r-20 hand-cursor"
                                >
                                  <img src={deleteicon} alt="" />
                                </span>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  props.history.push(
                                    "/properties/view/" +
                                      state.property_slug +
                                      "/noiseaware-template"
                                  );
                                else return false;
                              else
                                props.history.push(
                                  "/properties/view/" +
                                    state.property_slug +
                                    "/noiseaware-template"
                                );
                            }}
                          >
                            Cancel
                          </button>

                          {state.slug !== undefined &&
                          global.userPermissions.checkPermission(
                            "noiseaware-update"
                          ) ? (
                            <button
                              className="btn-success mr-3"
                              type="button"
                              disabled={state.isSubmit ? true : false}
                              onClick={saveOrUpdateTemplate}
                            >
                              {state.isSubmit ? global.loader : "Save"}
                            </button>
                          ) : global.userPermissions.checkPermission(
                              "noiseaware-add"
                            ) ? (
                            <button
                              className="btn-success mr-3"
                              type="button"
                              disabled={state.isSubmit ? true : false}
                              onClick={saveOrUpdateTemplate}
                            >
                              {state.isSubmit ? global.loader : "Save"}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-12">
                          <form id="floor_form">
                            <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                              <Alerts
                                show={state.showAlertModal}
                                type={state.alertModalType}
                                title={state.alertModalTitle}
                                message={state.alertModalMessage}
                              />
                            </div>

                            <div className="row align-items-center border-bottom-0">
                              {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                                <label className="form-label text-left pd-b-5">
                                                    Template Name{" "}
                                                </label>
                                                <Form.Control
                                                    onChange={changeHandler}
                                                    name="template_name"
                                                    value={state.template_name}
                                                    className="form-control max_width_100"
                                                    type="text"
                                                />
                                                {simpleValidator.current.message('template_name', state.template_name, 'required')}
                                                {state.errors.template_name !== "" ? (
                                                    <div className="text-danger">
                                                        {state.errors.template_name} </div>)
                                                    : ''}
                                            </div> */}
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Alert Name{" "}
                                </label>

                                <Select
                                  className="multiselect"
                                  isClearable={true}
                                  menuPlacement="auto"
                                  value={state.selectedAlertList}
                                  options={state.newAlertList}
                                  getOptionValue={(option) => `${option.label}`}
                                  onChange={(value) =>
                                    handleChangeAlertType(value)
                                  }
                                  //defaultValue={state.selectedAlertList}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary: "black",
                                    },
                                  })}
                                />
                                {simpleValidator.current.message(
                                  "alert_name",
                                  state.template_name,
                                  "required"
                                )}
                                {state.errors.alert_name !== "" ? (
                                  <div className="text-danger">
                                    {state.errors.alert_name}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {state.template_desc !== "" ? (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-0">
                                  {"Note : " + state.template_desc}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="row align-items-center border-bottom-0">
                              <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Alert Subject{" "}
                                </label>
                                <Form.Control
                                  onChange={changeHandler}
                                  name="template_subject"
                                  value={state.template_subject}
                                  className="form-control max_width_100"
                                  type="text"
                                  autoComplete="off"
                                />
                                {simpleValidator.current.message(
                                  "alert_subject",
                                  state.template_subject,
                                  "required"
                                )}
                                {state.errors.alert_subject !== "" ? (
                                  <div className="text-danger">
                                    {state.errors.alert_subject}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="row align-items-center border-bottom-0">
                              <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Alert Message
                                  {
                                    "  ( Available Merge Fields :  [Unit No] [Guest Name] )"
                                  }
                                </label>
                                <Form.Control
                                  onChange={changeHandler}
                                  name="template_body"
                                  value={state.template_body}
                                  className="form-control max_width_100"
                                  type="text"
                                  rows="4"
                                  as="textarea"
                                  autoComplete="off"
                                />
                                {simpleValidator.current.message(
                                  "alert_message",
                                  state.template_body,
                                  "required"
                                )}
                                {state.errors.alert_message !== "" ? (
                                  <div className="text-danger">
                                    {state.errors.alert_message}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="row align-items-center border-bottom-0">
                              <ScrollingCarousel
                                rightArrow={true}
                                leftArrow={true}
                                className="swipesection scroll_nav_mob"
                              >
                                <h5 className="form-label text-left pd-b-5">
                                  Send alert to{" "}
                                </h5>
                              </ScrollingCarousel>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                <label className="form-label text-left pd-b-5 pt-2">
                                  User Role(s)
                                </label>

                                <Select
                                  isMulti
                                  styles={customStyles}
                                  className="multiselect"
                                  menuPlacement="auto"
                                  value={state.selectedCommServedList}
                                  options={newCommServedList}
                                  getOptionValue={(option) => `${option.label}`}
                                  onChange={(value) =>
                                    handleChangeCommServedList(value)
                                  }
                                  defaultValue={state.selectedCommServedList}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />

                                {/* {state.alert_sent_to === 1 || state.alert_sent_to === '1' ? simpleValidator.current.message('user_roles', state.user_roles, 'required') : ''} */}
                                {state.errors.user_roles ? (
                                  <div className="text-danger">
                                    {state.errors.user_roles}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  User(s){" "}
                                </label>

                                <Select
                                  isMulti
                                  styles={customStyles}
                                  className="multiselect"
                                  menuPlacement="auto"
                                  value={state.selectedCommUsersList}
                                  options={newCommUsersList}
                                  getOptionValue={(option) => `${option.label}`}
                                  onChange={(value) =>
                                    handleChangeCommUsersList(value)
                                  }
                                  defaultValue={state.selectedCommUsersList}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />
                                {state.alert_sent_to === 2 ||
                                state.alert_sent_to === "2"
                                  ? simpleValidator.current.message(
                                      "user_list",
                                      state.user_list,
                                      "required"
                                    )
                                  : ""}
                                {state.errors.user_list !== "" &&
                                state.alert_sent_to === 2 ? (
                                  <div className="text-danger">
                                    {state.errors.user_list}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="units">
                                            <UnitMapping propertySlug={state.property_slug} />
                                        </Tab.Pane> */}
                    <Tab.Pane eventKey="alert-activity">
                      <NoiseawareActivity propertySlug={state.property_slug} />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal id="adas" show={state.deleteTowerModal} centered>
        <Modal.Body className="width_402 p-4 border_radius_14">
          <div className="text-center">
            <span
              className="welcomeClose"
              onClick={() => closedeleteTowerModal()}
            >
              {global.closee}
            </span>
            <img src={modaldeleteicon} alt="modal-delete-icon" />
            <h3>Delete the Option?</h3>
            <p>
              Do you really want to delete this option? This process cannot be
              undone.
            </p>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => closedeleteTowerModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-danger btn-block"
                onClick={() => deleteTemplate(state.slug)}
                disabled={state.isSubmit ? true : false}
              >
                {state.isSubmit ? global.loader : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default NoiseawareTemplateForm;
