////////////////////////////////////////////////////////////
//     							                          //
//  Program: Timezone.jsx                           //
//  Application: Misc                                   //
//  Option: to view Timezone data                  //
//  Developer: Ashish Kumar                               //
//  Date: 2022-01-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import {
  // Button,
  Table,
  Modal,
  Form,
  InputGroup,
} from "react-bootstrap";
import Alerts from "../common/Alerts";
// import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import TimezoneService from "../../services/TimezoneService";
import CountryService from "../../services/CountryService";
// import pluswIcon from './../../assets/images/plus.svg';
import Select from "react-select";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import NoData from "../common/NoData";

export class Timezone extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for Timezone
      addTimezoneModal: false,
      editTimezoneModal: false,
      deleteTimezoneModal: false,
      timezoneList: [],
      timezoneListTotal: 0,
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],

      timezone_name: "",
      time_offset: "",
      country_slug: "",
      sort_order: "",
    };
    this.changeHandler = this.changeHandler.bind(this);

    /* for Timezone Type */
    this.openAddTimezoneModal = this.openAddTimezoneModal.bind(this);
    this.openEditTimezoneModal = this.openEditTimezoneModal.bind(this);
    this.openDeleteTimezoneModal = this.openDeleteTimezoneModal.bind(this);
    this.saveTimezone = this.saveTimezone.bind(this);
    this.getTimezoneDetails = this.getTimezoneDetails.bind(this);
    this.updateTimezone = this.updateTimezone.bind(this);
    this.deleteTimezone = this.deleteTimezone.bind(this);
  }

  componentDidMount() {
    this.getTimezoneData();
    this.getCountryData();
  }
  /* to get country list data */
  async getCountryData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countryList = [];
    var countryListTotal = 0;

    let res = await CountryService.getCountry(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data.data ? res.data.data : [];
      countryListTotal = countryList ? countryList.length : 0;
    }
    const newCountryList =
      countryListTotal > 0
        ? countryList.map(({ slug, country_name }) => ({
            value: slug,
            label: country_name,
          }))
        : [];
    this.setState({
      countryList: countryList,
      countryListTotal: countryListTotal,
      newCountryList: newCountryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To get Timezone type list data */
  async getTimezoneData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var timezoneList = [];
    var timezoneListTotal = 0;

    let res = await TimezoneService.getTimezone(
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      timezoneList = res.data.data ? res.data.data : [];
      timezoneListTotal = timezoneList ? timezoneList.length : 0;
    }
    this.setState({
      timezoneList: timezoneList,
      timezoneListTotal: timezoneListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To save Timezone type data */
  saveTimezone = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.timezone_name &&
      !this.validator.errorMessages.time_offset &&
      !this.validator.errorMessages.country_slug &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        timezone_name: this.state.timezone_name,
        time_offset: this.state.time_offset,
        country_slug: this.state.country_slug,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await TimezoneService.createTimezone(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddTimezoneModal();
        this.setState({
          timezone_name: "",
          time_offset: "",
          country_slug: "",
          sort_order: "",
          status_id: true,
        });
        this.getTimezoneData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get Timezone type details data  */
  async getTimezoneDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await TimezoneService.getTimezoneBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.timezone_name = res.data.timezone_name
          ? res.data.timezone_name
          : "";
        resData.time_offset = res.data.time_offset ? res.data.time_offset : "";
        resData.country_slug = res.data.country ? res.data.country.slug : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        var selectedCountryList = [];
        selectedCountryList = res.data.country
          ? [
              {
                value: res.data.country.slug,
                label:
                  res.data && res.data.country && res.data.country.country_name
                    ? res.data.country.country_name
                    : "",
              },
            ]
          : "";
      }
      this.setState(resData);
      this.setState({ selectedCountryList: selectedCountryList });
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update Timezone type data */
  updateTimezone = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.timezone_name &&
      !this.validator.errorMessages.time_offset &&
      !this.validator.errorMessages.country_slug &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        timezone_name: this.state.timezone_name,
        time_offset: this.state.time_offset,
        country_slug: this.state.country_slug,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await TimezoneService.updateTimezone(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditTimezoneModal();
        this.setState({
          timezone_name: "",
          time_offset: "",
          country_slug: "",
          sort_order: "",
          status_id: true,
        });
        this.getTimezoneData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete Timezone type data */
  async deleteTimezone(slug) {
    this.setState({ showSpinner: true });
    let res = await TimezoneService.deleteTimezone(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteTimezoneModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getTimezoneData();
    } else {
      this.closeDeleteTimezoneModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  handleChangeCountryList(value) {
    let country_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_slug.push(item.value);
      });
    }
    const country_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCountryList: value,
      country_slug: country_slug_value,
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* Timezone Type Modals */
  openAddTimezoneModal() {
    this.setState({ addTimezoneModal: true });
  }

  closeAddTimezoneModal() {
    this.setState({ addTimezoneModal: false });
    this.setState({
      timezone_name: "",
      time_offset: "",
      country_slug: "",
      sort_order: "",
      errors: "",
      status_id: true,
      selectedCountryList: [],
    });
    this.validator.hideMessages();
  }

  openEditTimezoneModal(slug) {
    this.getTimezoneDetails(slug);
    this.setState({ editTimezoneModal: true });
    this.setState({ slug: slug });
  }

  closeEditTimezoneModal() {
    this.setState({ editTimezoneModal: false });
    this.setState({
      slug: "",
      timezone_name: "",
      time_offset: "",
      country_slug: "",
      sort_order: "",
      errors: "",
      status_id: true,
      selectedCountryList: [],
    });
    this.validator.hideMessages();
  }

  openDeleteTimezoneModal(slug) {
    this.setState({ deleteTimezoneModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteTimezoneModal() {
    this.setState({ deleteTimezoneModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({ behavior: "auto", block: "start" });
  }

  getTitle() {
    return "Timezone";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        {/* Timezone Type card */}
        {/* <Card>
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="20"
              id="20"
              onClick={(e) => this.setOpen(!this.state.open, e)}
            >
              Timezone
              <i className="mdi mdi-view-headline"> </i>
              {this.state.open && this.state.eventKey === "20" ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-up"></i>
              )}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="20">
            <Card.Body>
              <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
                <Button
                  variant="outline-dark btn-block btn-with-icon"
                  onClick={this.openAddTimezoneModal}
                >
                  <img src={pluswIcon} /> Add
                </Button>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="table-responsive">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-30">Timezone Name</th>
                      <th className="w-30">Timezone Offset</th>
                      <th className="w-30">Country</th>
                      <th className="w-15">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.timezoneListTotal > 0 ? (
                      this.state.timezoneList.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="hand-cursor" onClick={() =>
                              this.openEditTimezoneModal(item.slug)
                            }>{item.timezone_name}</td>
                            <td>{item.time_offset}</td>
                            <td>{item.country.country_name}</td>
                            <td>
                              {item.status_id === 1 ? (
                                <b className="active"></b>
                              ) : (
                                <b></b>
                              )}
                            </td>
                            <td>
                              <div className="btn-icon-list d-block text-center">
                                <img
                                  src={deleteicon}
                                  alt="Delete"
                                  onClick={() =>
                                    this.openDeleteTimezoneModal(item.slug)
                                  }
                                  className="hand-cursor"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          No rows found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}

        <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {/* {global.userPermissions.checkPermission(
                        "master-data-add"
                      ) && (
                        <Button
                          variant="outline-dark btn-block btn-with-icon"
                          onClick={this.openAddTimezoneModal}
                        >
                          <img src={pluswIcon} /> Add
                        </Button>
                      )} */}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      {/* <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
              <Button
                variant="outline-dark btn-block btn-with-icon"
                onClick={this.openAddTimezoneModal}
              >
                <img src={pluswIcon} /> Add
              </Button>
            </div> */}
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.timezoneListTotal > 0 ? (
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th className="w-30">Timezone Name</th>
                              <th className="w-30">Timezone Offset</th>
                              <th className="w-30">Country</th>
                              <th className="w-15">Status</th>
                              {/* {global.userPermissions.checkPermission(
                              "master-data-delete"
                            ) && (
                            <th className="text-center">Action</th>
                            )} */}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.timezoneListTotal > 0 ? (
                              this.state.timezoneList.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    {/* <td className="hand-cursor" onClick={() =>
                                    {global.userPermissions.checkPermission(
                                      "master-data-update"
                                    ) && ( this.openEditTimezoneModal(item.slug) )}
                                  }>{item.timezone_name ? item.timezone_name : ''}</td> */}
                                    <td>
                                      {item.timezone_name
                                        ? item.timezone_name
                                        : ""}
                                    </td>
                                    <td>
                                      {item.time_offset ? item.time_offset : ""}
                                    </td>
                                    <td>
                                      {item.country && item.country.country_name
                                        ? item.country.country_name
                                        : ""}
                                    </td>
                                    <td>
                                      {item.status_id === 1 ? (
                                        <b className="active"></b>
                                      ) : (
                                        <b></b>
                                      )}
                                    </td>
                                    {/* {global.userPermissions.checkPermission(
                                    "master-data-delete"
                                  ) && (
                                  <td>
                                    <div className="btn-icon-list d-block text-center">
                                      <img
                                        src={deleteicon}
                                        alt="Delete"
                                        onClick={() =>
                                          this.openDeleteTimezoneModal(item.slug)
                                        }
                                        className="hand-cursor"
                                      />
                                    </div>
                                  </td>
                                  )} */}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                  There are no timezones added in the system.
                                  You may add them by clicking Add button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage("TIMEZONE")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* add Timezone popup modal */}
        <Modal
          show={this.state.addTimezoneModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddTimezoneModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddTimezoneModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Timezone</h3>
            <label>Timezone Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="timezone_name"
              value={this.state.timezone_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Timezone Name"
            />
            {this.state.errors.timezone_name ? (
              <div className="text-danger">
                {this.state.errors.timezone_name}
              </div>
            ) : (
              this.validator.message(
                "timezone_name",
                this.state.timezone_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Time Offset</label>
              <Form.Control
                onChange={this.changeHandler}
                name="time_offset"
                value={this.state.time_offset}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
                // placeholder="Time Offset"
              />
            </InputGroup>
            {this.state.errors.time_offset ? (
              <div className="text-danger">{this.state.errors.time_offset}</div>
            ) : (
              this.validator.message(
                "time_offset",
                this.state.time_offset,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Country</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newCountryList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCountryList(value)}
                  defaultValue={this.state.selectedCountryList}
                  value={this.state.selectedCountryList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="country_slug"
                value={this.state.country_slug}
              >
                <option key="" value="">
                  Select Country
                </option>
                {this.state.countryList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.country_name}
                  </option>
                ))}
              </select> */}
            </InputGroup>
            {this.state.errors.country_slug ? (
              <div className="text-danger">
                {this.state.errors.country_slug}
              </div>
            ) : (
              this.validator.message(
                "country",
                this.state.country_slug,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddTimezoneModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveTimezone}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit Timezone popup modal */}
        <Modal
          show={this.state.editTimezoneModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditTimezoneModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditTimezoneModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Timezone</h3>
            <label>Timezone Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="timezone_name"
              value={this.state.timezone_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Timezone Name"
            />
            {this.state.errors.timezone_name ? (
              <div className="text-danger">
                {this.state.errors.timezone_name}
              </div>
            ) : (
              this.validator.message(
                "timezone_name",
                this.state.timezone_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Time Offset</label>
              <Form.Control
                onChange={this.changeHandler}
                name="time_offset"
                value={this.state.time_offset}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
                // placeholder="Time Offset"
              />
            </InputGroup>
            {this.state.errors.time_offset ? (
              <div className="text-danger">{this.state.errors.time_offset}</div>
            ) : (
              this.validator.message(
                "time_offset",
                this.state.time_offset,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Country</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.newCountryList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeCountryList(value)}
                  defaultValue={this.state.selectedCountryList}
                  value={this.state.selectedCountryList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
              {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="country_slug"
                value={this.state.country_slug}
              >
                <option key="" value="">
                  Select Country
                </option>
                {this.state.countryList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.country_name}
                  </option>
                ))}
              </select> */}
            </InputGroup>
            {this.state.errors.country_slug ? (
              <div className="text-danger">
                {this.state.errors.country_slug}
              </div>
            ) : (
              this.validator.message(
                "country",
                this.state.country_slug,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditTimezoneModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateTimezone}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete Timezone Type popup modal */}
        <Modal
          show={this.state.deleteTimezoneModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteTimezoneModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteTimezoneModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteTimezoneModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteTimezone(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Timezone;
