import React, { Component } from "react";
import { Container, Modal } from "react-bootstrap";
import Moment from "moment";
import LatchService from "../../../services/LatchService";
import deleteicon from "../../../assets/images/delete.svg";
import { Link } from "react-router-dom";
import editIcon from "./../../../assets/images/edit-pen-icon.svg";

export class AccessView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      errors: {},
      latch_access_slug: this.props.match.params.latch_access_slug,
      property_slug: this.props.match.params.propertySlug,
      latchAccess: {},
      deleteModelLetch: false,
    };
    this.opendeleteModal = this.opendeleteModal.bind(this);
  }

  async componentDidMount() {
    this.getData();
  }

  opendeleteModal = () => {
    if (this.state.deleteModelLetch == false) {
      this.setState({ deleteModelLetch: true });
    } else {
      this.setState({ deleteModelLetch: false });
    }
  };
  async getData() {
    if (
      this.state.property_slug !== undefined &&
      this.state.latch_access_slug !== undefined
    ) {
      let resData = {};
      let res = await LatchService.getLatchAccessById(
        `latch_access_slug=${this.state.latch_access_slug}&property_slug=${this.state.property_slug}`
      );
    
      if (global.successStatus.includes(res.status)) {
        resData.unit_name = res.data ? res.data.unit_name : "";
        resData.unit_number = res.data ? res.data.unit_number : "";
        resData.unit_slug = res.data ? res.data.unit_slug : "";
        resData.checkin_date = res.data
          ? res.data.checkin_date !== null ? Moment(res.data.checkin_date).toDate(global.dateFormat) :""
          : "";
        resData.checkout_date = res.data
          ?  res.data.checkout_date !== null ? Moment(res.data.checkout_date).toDate(global.dateFormat) : ""
          : "";
        resData.email = res.data ? res.data.email : "";
        resData.access_code = res.data ? res.data.access_code : "";
        resData.first_name = res.data ? res.data.first_name : "";
        resData.last_name = res.data ? res.data.last_name : "";
       
        resData.checkin_time = res.data
          ? res.data.checkin_time !== null ? Moment(res.data.checkin_time).format("hh:mm A") : ""
          : "";
        resData.checkout_time = res.data
          ? res.data.checkout_time !== null ? Moment(res.data.checkout_time).format("hh:mm A") : ""
          : "";
        resData.key_type = res.data ? res.data.key_type : "";
        resData.door_name = res.data ? res.data.door_name : "";
      }
      this.setState({
        isLoaded: true,
        unit_name: resData.unit_name ? resData.unit_name : "",
        unit_slug:resData.unit_slug ? resData.unit_slug:"",
        checkin_date: resData.checkin_date
          ? resData.checkin_date
          : "",
        checkout_date: resData.checkout_date ? resData.checkout_date : "",
        email: resData.email ? resData.email : "",
        access_code: resData.access_code ? resData.access_code : "",
        first_name: resData.first_name ? resData.first_name : "",
        last_name: resData.last_name ? resData.last_name : "",
        key_type: resData.key_type ? resData.key_type : "",
        door_name: resData.door_name ? resData.door_name : "",
        checkin_time: resData.checkin_time
          ? resData.checkin_time
          : "",
        checkout_time: resData.checkout_time ? resData.checkout_time : "",
      });
    }
  }

  deleteLetchAccess = async () => {
    if (
      this.state.property_slug !== undefined &&
      this.state.latch_access_slug !== undefined
    ) {
      let inputData = {
        checkin_date: this.state.checkin_date
          ? Moment(this.state.checkin_date).toDate(global.dateFormat)
          : "",
        checkout_date: this.state.checkout_date
          ? Moment(this.state.checkout_date).toDate(global.dateFormat)
          : "",
        checkin_time: this.state.checkin_time,
        checkout_time: this.state.checkout_time,
        property_slug: this.state.property_slug,
        email: this.state.email ? this.state.email : "",
        unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
        // convertedTime : Moment(this.state.state.end_time, 'hh:mm A').format('HH:mm'),
        user_slug: this.state.email_slug ? this.state.email_slug : 0,
        first_name: this.state.first_name ? this.state.first_name : "",
        last_name: this.state.last_name ? this.state.last_name : "",
        from_where: 4,
        action_type: 3,
        latch_access_slug:this.state.latch_access_slug,
      };
      let res = await LatchService.createlatchmanualaccess(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });

        setTimeout(
          () =>
            this.props.history.push(
              this.props.match.params.propertySlug !== undefined
                ? "/properties/view/" + this.state.property_slug + "/latches"
                : "/latches/activity-view/" +
                    this.state.property_slug +
                    "/" +
                    this.state.slug
            ),
          global.redirect_time
        );
      } else {
        // console.log("error check update=>",res.data.message);
        if (res.data) {
          var mesgs = res.data.message ? res.data.message : "Validation Failed";
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: "error",
            alertModalMessage: mesgs,
          });
        }
      }
    }
  };
  closeDeleteModal = () => {
    this.setState({ deleteModelLetch: false });
  };
  getTitle() {
    return "Access View";
  }

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
                      {global.userPermissions.checkPermission(
                        "latch-manual-delete"
                      ) &&
                        this.state.key_type === "Manual" && (
                          <span
                            onClick={() => {
                              this.opendeleteModal(
                                this.state.latch_access_slug
                              );
                            }}
                            className="deleteicon mg-r-20 hand-cursor"
                          >
                            <img src={deleteicon} alt="Delete" />
                          </span>
                        )}

                      {global.userPermissions.checkPermission(
                        "latch-manual-update"
                      ) && (
                        <Link
                          to={
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                this.state.property_slug +
                                "/latches/activity-update/" +
                                this.state.latch_access_slug
                              : "/latches/activity-update/" +
                                this.state.property_slug +
                                "/" +
                                this.state.latch_access_slug
                          }
                        >
                          {this.state.key_type === "Manual" ? (
                            <button className="btn-success-outline ml-3">
                              <img src={editIcon} alt="" />
                              Edit
                            </button>
                          ) : (
                            ""
                          )}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-lg-l-15 pd-sm-l-30 pd-sm-r-30 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15"></div>
                    <div className="m-0">
                      <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                          <p className="az-profile-name-text pl-0">Unit</p>
                          <p className="media-body">
                            {this.state.unit_name || ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                          <p className="az-profile-name-text pl-0">
                            Start Time
                          </p>
                          <p className="media-body">
                            {this.state.checkin_date
                              ? Moment(
                                  this.state.checkin_date
                                ).format(global.dateTimeFormat)
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                          <p className="az-profile-name-text pl-0">
                            End Time
                          </p>
                          <p className="media-body">
                            {this.state.checkout_date
                              ? Moment(this.state.checkout_date).format(
                                  global.dateTimeFormat
                                )
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                          <p className="az-profile-name-text pl-0">Email</p>
                          <p className="media-body">
                            {this.state.email || ""}
                          </p>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                          <p className="az-profile-name-text pl-0">
                            First Name
                          </p>
                          <p className="media-body">
                            {this.state.first_name || ""}
                          </p>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                          <p className="az-profile-name-text pl-0">Last Name</p>
                          <p className="media-body">
                            {this.state.last_name || ""}
                          </p>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                          <p className="az-profile-name-text pl-0">Door Name</p>
                          <p className="media-body">
                            {this.state.door_name || ""}
                          </p>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                          <p className="az-profile-name-text pl-0">Type</p>
                          <p className="media-body">
                            {this.state.key_type || ""}
                          </p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteModelLetch} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={deleteicon} alt="modal-delete-icon" />
              <h3>Delete the latch access?</h3>
              <p>
                Do you really want to delete this latch access? This process
                cannot be undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() =>
                    this.deleteLetchAccess(this.state.latch_access_slug)
                  }
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default AccessView;
