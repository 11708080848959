///////////////////////////////////////////////////////////
//     							                          //
//  Program: PropertyOwnerForm.jsx                        //
//  Application: Property                                 //
//  Option: to view all Property Owners                   //
//  Developer: NP  						                            //
//  Date: 2022-04-02                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
import pluswIcon from "./../../assets/images/plus.svg";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import PropertySidebar from "../common/PropertySidebar";
import NoData from "../common/NoData";
import ReactDatatable from "@ashvin27/react-datatable";
export class PropertyOwner extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      // propertySlug: this.state.property_slug,
      filter_value: "",
      open: true,
      eventKey: "0",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      viewMode: 1,
      ownerList: [],
      ownerListTotal: 0,
      total_record: 0,
      addOwnerModal: false,
      editOwnerModal: false,
      deleteOwnerModal: false,
      isSubmit: false,
      input: {},
      errors: {},
      confirm_back: 0,
      ownerTypeList: [],
      newOwnerTypeList: [],
      selectedOwnerTypeList: [],
      property_slug: this.props.match.params.propertySlug,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "owner_type",
          text: "Owner Type",
          className: "owner_type hand-cursor",
          sortable: false,
          // width: 500,
          cell: (record) => {
            return record.owner_type
              ? record.owner_type === 1
                ? "GP"
                : "LP"
              : "";
          },
        },
        {
          key: "owner_name",
          text: "Owner Name",
          className: "owner_name hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.owner_name ? record.owner_name : "";
          },
        },
        // {
        //   key: "ein_ssn",
        //   text: "Ein/Ssn",
        //   className: "owner_name hand-cursor text-left",
        //   sortable: true,
        //   cell: (record) => {
        //     return record.ein_ssn ? record.ein_ssn : "";
        //   },
        // },
        {
          key: "amount",
          text: "Amount",
          className: "owner_name hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.amount ? "$" +  global.onKeyPressEvent.numberWithCommasForDecimal(record.amount.replace(/\.00$/, "")) : "";
          },
        },
        {
          key: "ownership",
          text: "Ownership",
          className: "owner_name hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.ownership ? record.ownership + "%" : "";
          },
        },
        // {
        //   key: "email",
        //   text: "Email",
        //   className: "owner_name hand-cursor text-left",
        //   sortable: true,
        //   cell: (record) => {
        //     return record.email ? record.email : "";
        //   },
        // },
        // {
        //   key: "phone",
        //   text: "Phone",
        //   className: "owner_name hand-cursor text-left",
        //   sortable: true,
        //   cell: (record) => {
        //     return record.phone ? record.phone : "";
        //   },
        // },
        // {
        //   key: "notes",
        //   text: " Notes",
        //   className: "owner_name hand-cursor text-left",
        //   sortable: true,
        //   cell: (record) => {
        //     return record.notes ? record.notes : "";
        //   },
        // },
      ],
    };
    this.changeView = this.changeView.bind(this);
    this.openaddOwnerModal = this.openaddOwnerModal.bind(this);
    this.openeditOwnerModal = this.openeditOwnerModal.bind(this);
    this.opendeleteOwnerModal = this.opendeleteOwnerModal.bind(this);
    this.saveOwner = this.saveOwner.bind(this);
    this.getOwnerDetails = this.getOwnerDetails.bind(this);
    this.updateOwner = this.updateOwner.bind(this);
    this.deleteOwner = this.deleteOwner.bind(this);
    this.getOwnerData = this.getOwnerData.bind(this);
    this.tableChangeHandler = this.tableChangeHandler.bind(this);
    this.resetDate = this.resetDate.bind(this);
  }

  componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getOwnerData();
    this.getOwnerTypeList();
  }

  async getOwnerTypeList() {
    const ownerTypeList = [
      { value: "1", label: "GP" },
      { value: "2", label: "LP" },
    ];
    this.setState({ ownerTypeList: ownerTypeList });
  }

  async getOwnerData(queryString) {
    //this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;

    let res = await PropertyService.getPropertyOwner(
      "property_slug=" + this.state.property_slug + (queryString ? "&" + queryString : ""));
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
    // this.setState({ showSpinner: false, loading: false });
  }

  /* to save ad agency data */
  saveOwner = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.owner_type &&
      !this.validator.errorMessages.owner_name
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug,
        owner_type: this.state.owner_type,
        owner_name: this.state.owner_name,
      };

      let res = await PropertyService.createPropertyOwner(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeaddOwnerModal();
        this.setState({
          owner_type: "",
          owner_name: "",
        });
        this.getOwnerData();
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.result.errorDetail[0].errorMessage
            ? res.data.result.errorDetail[0].errorMessage
            : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.errorMessage,
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get ad agency details data */
  async getOwnerDetails(slug) {
    // this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await PropertyService.getOwnerInfoBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData.owner_type = res.data.owner_type ? res.data.owner_type : "";
        var selectedOwnerTypeList = [];
        selectedOwnerTypeList =
          res.data.owner_type === 1
            ? [
              {
                value: 1,
                label: "GP",
              },
            ]
            : [
              {
                value: 2,
                label: "LP",
              },
            ];
        resData.owner_name = res.data.owner_name ? res.data.owner_name : "";
        resData.ein_ssn = res.data.ein_ssn ? res.data.ein_ssn : "";
        resData.amount = res.data.amount ? res.data.amount : "";
        resData.ownership = res.data.ownership ? res.data.ownership : "";
        resData.email = res.data.email ? res.data.email : "";
        resData.phone = res.data.phone ? res.data.phone : "";
        resData.notes = res.data.notes ? res.data.notes : "";
      }
      this.setState(resData);
      this.setState({ selectedOwnerTypeList: selectedOwnerTypeList });
    }
    // this.setState({ showSpinner: false, isSubmit: false });
  }

  resetDate() {
    this.getOwnerData();
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getOwnerData(queryString);
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getOwnerData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getOwnerData(queryString);
    }
  };

  /* to update ad agency data */
  updateOwner = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.owner_type &&
      !this.validator.errorMessages.owner_name
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug,
        owner_type: this.state.owner_type,
        owner_name: this.state.owner_name,
      };

      let res = await PropertyService.updatePropertyOwner(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeeditOwnerModal();
        this.setState({
          owner_type: "",
          owner_name: "",
        });
        this.getOwnerData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.result.errorDetail[0].errorMessage
            ? res.data.result.errorDetail[0].errorMessage
            : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete ad agency data */
  async deleteOwner(slug) {
    this.setState({ showSpinner: true });
    let res = await PropertyService.deletePropertyOwner(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.closedeleteOwnerModal();
      this.getOwnerData();
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closedeleteOwnerModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  openaddOwnerModal() {
    this.setState({ addOwnerModal: true });
    this.setState({ confirm_back: 0 });
  }

  closeaddOwnerModal() {
    this.setState({ addOwnerModal: false });
    this.setState({
      owner_type: "",
      owner_name: "",
    });
    this.validator.hideMessages();
    this.setState({ confirm_back: 0 });
  }

  openeditOwnerModal(slug) {
    this.getOwnerDetails(slug);
    this.setState({ editOwnerModal: true });
    this.setState({ slug: slug });
    this.setState({ confirm_back: 0 });
  }

  closeeditOwnerModal() {
    this.setState({ editOwnerModal: false });
    this.setState({
      owner_type: "",
      owner_name: "",
    });
    this.validator.hideMessages();
    this.setState({ confirm_back: 0 });
  }

  opendeleteOwnerModal(slug) {
    this.setState({ deleteOwnerModal: true });
    this.setState({ slug: slug });
    this.setState({ confirm_back: 0 });
  }

  closedeleteOwnerModal() {
    this.setState({ deleteOwnerModal: false });
    this.setState({ slug: "" });
    this.setState({ confirm_back: 0 });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.setState({ confirm_back: 1 });
    // var confirmBack = 1;
    // this.props.confirmBackStatus(confirmBack);
  };

  handleChangeOwnerType(value) {
    let owner_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        owner_type.push(item.value);
      });
    }
    const owner_type_value = value !== null ? value.value : [];
    this.setState({
      selectedOwnerTypeList: value,
      owner_type: owner_type_value,
    });
    this.setState({ confirm_back: 1 });
    // var confirmBack = 1;
    // this.props.confirmBackStatus(confirmBack);
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    // var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      // this.props.confirmBackStatus(confirmBack);
      this.getOwnerData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      // this.props.confirmBackStatus(confirmBack);
      this.getOwnerData();
    }
  }

  getTitle() {
    return "Owners";
  }

  // gotToUpdate(slug) {
  //   this.props.history.push(
  //     "/properties/view/" + this.state.property_slug + "/owners/update/" + slug
  //   );
  // }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      "/properties/view/" +
      this.state.property_slug +
      "/owners/update/" +
      data.slug
    );
  };

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  {/* <PropertyViewSidebar /> */}
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>

                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {this.state.viewMode === 0 ? (
                        <button
                          className="btn-success-outline-small"
                          onClick={this.changeView}
                        >
                          <img src={editIcon} alt="" />
                          Edit
                        </button>
                      ) : (
                        <div className="d-flex">
                        {global.userPermissions.checkPermission('owners-add') ?
                          <Link
                            to={
                              "/properties/view/" +
                              this.state.property_slug +
                              "/owners/add"
                            }
                          >
                            <button className="btn-success-outline-small ml-3">
                              <img src={pluswIcon} alt="" /> Add
                            </button>
                          </Link>
                        : ''}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.state.total_record !== 0 ||
                      this.state.filter_value !== "" ? (
                      <div className="mg-t-18 brandList pd-l-15 d-block">
                       <div className=" pd-lg-l-0 pd-xs-l-15">
                        <ReactDatatable
                          className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                          config={
                            this.state.total_record > global.page_size
                              ? this.state.config
                              : this.state.config
                          }
                          records={this.state.records}
                          columns={this.state.columns}
                          extraButtons={this.state.extraButtons}
                          dynamic={true}
                          // loading={this.state.loading}
                          total_record={this.state.total_record}
                          onChange={this.tableChangeHandler}
                          onRowClicked={this.rowClickedHandler}
                        />
                      </div>
                      </div>
                    ) : (
                      <NoData msg={global.commonMessages.checkMessage("OWNERS")} />
                    )}

                    {/* {this.state.viewMode === 1 ?
                      this.state.ownerListTotal > 0 ?
                      <div className="table-responsive">
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th className="w-30">Owner Type</th>
                              <th className="w-30 text-left">Owner Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ownerListTotal > 0 ? (
                              this.state.ownerList.map((item, i) => {
                                return (
                                  <tr className="hand-cursor" key={i}>
                                    <td onClick={()=>this.gotToUpdate(item.slug)}>
                                      {item.owner_type === 1 ? "GP" : "LP"}
                                    </td>
                                    <td onClick={()=>this.gotToUpdate(item.slug)} className="hand-cursor text-left">
                                      {item.owner_name ? item.owner_name : ""}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                There are no owners added in the system. You may add them by clicking Add button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                     : <NoData msg="owners" />
                     : "" } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default PropertyOwner;
