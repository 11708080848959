////////////////////////////////////////////////////////////
//     							                          //
//  Program: ApiService.js                                //
//  Application: Services                                 //
//  Option: Used to call all external/third party apis    //
//  Developer: CV  						                  //
//  Date: 2021-02-01                                      //
//                                                        //
////////////////////////////////////////////////////////////

import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const DEVICE_TYPE = process.env.REACT_APP_DEVICE_TYPE
  ? process.env.REACT_APP_DEVICE_TYPE
  : localStorage.getItem("deviceType")
  ? localStorage.getItem("deviceType")
  : "";
const PLATFORM_KEY = process.env.REACT_APP_PLATFORM_KEY
  ? process.env.REACT_APP_PLATFORM_KEY
  : localStorage.getItem("platformKey")
  ? localStorage.getItem("platformKey")
  : "";
const ROLE = global.currerntRoleSlug
  ? global.currerntRoleSlug
  : localStorage.getItem("currerntRoleSlug")
  ? localStorage.getItem("currerntRoleSlug")
  : "";
const HEADER_TOKEN = localStorage.getItem("headerToken")
  ? localStorage.getItem("headerToken")
  : "";
// const HEADER_X_TOKEN = process.env.REACT_APP_X_AUTH_TOKEN;
// const BASE_PATH = process.env.REACT_APP_BASE_PATH ? process.env.REACT_APP_BASE_PATH : ""
// const GEOLOCATION_IP_URL = process.env.REACT_APP_GEOLOCATION_IP_URL;

class ApiService {
  async getCall(inputData) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + HEADER_TOKEN,
        "device-type": DEVICE_TYPE,
        role: ROLE,
        "platform-key": PLATFORM_KEY,
      },
    };

    // Merge additional header data from request call
    if (
      inputData.addditionalHeaderData &&
      Object.keys(inputData.addditionalHeaderData).length > 0
    ) {
      axiosConfig.headers = {
        ...axiosConfig.headers,
        ...inputData.addditionalHeaderData,
      };
    }

    try {
      const res = await axios.get(
        API_BASE_URL + inputData.endPoint,
        axiosConfig
      );
      const res1 = res.data.other_data ? res.data.other_data : "";

      if (inputData.postData && inputData.postData.ispayout === 1) {
        return {
          status: res.status,
          message: res.data.message,
          data: res.data.result,
          other_data: res1,
        };
      }

      return {
        status: res.status,
        message: res.data.message,
        data: res.data.result,
        other_data: res1,
      };
    } catch (err) {
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 429)
      ) {
        window.location.href = "/logout";
        return; // Stop further execution as you are redirecting
      } else {
        return {
          status: err.response ? err.response.status : 503,
          message: err.message ? err.message : "Service Unavailable",
          data: err.response && err.response.data ? err.response.data : {},
        };
      }
    }
  }

  async postCall(inputData) {
    // var ipData = await this.getIpData();
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + HEADER_TOKEN,
        // 'X-Authorization': HEADER_X_TOKEN,
        "device-type": DEVICE_TYPE,
        role: ROLE,
        "platform-key": PLATFORM_KEY,
        // 'ipData': JSON.stringify(ipData)
      },
    };

    // Merdge addition header data from request call
    if (Object.keys(inputData.addditionalHeaderData).length > 0) {
      axiosConfig = {
        ...axiosConfig.headers,
        ...inputData.addditionalHeaderData,
      };
    }

    return axios
      .post(API_BASE_URL + inputData.endPoint, inputData.postData, axiosConfig)
      .then((res) => {
        return {
          status: res.status,
          message: res.data.message,
          data: res.data.result,
        };
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 429) {
          // return window.location.href = BASE_PATH+"/logout";
          return (window.location.href = "/logout");
        } else {
          return {
            status: err["response"] !== undefined ? err.response.status : 503,
            message:
              err["message"] !== undefined
                ? err.message
                : "Service Unavailable",
            data: err.response.data ? err.response.data : {},
          };
        }
      });
  }

  async putCall(inputData) {
    // var ipData = await this.getIpData();
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + HEADER_TOKEN,
        // 'X-Authorization': HEADER_X_TOKEN,
        "device-type": DEVICE_TYPE,
        role: ROLE,
        "platform-key": PLATFORM_KEY,
        // 'ipData': JSON.stringify(ipData)
      },
    };

    // Merdge addition header data from request call
    if (Object.keys(inputData.addditionalHeaderData).length > 0) {
      axiosConfig = {
        ...axiosConfig.headers,
        ...inputData.addditionalHeaderData,
      };
    }

    return axios
      .put(API_BASE_URL + inputData.endPoint, inputData.postData, axiosConfig)
      .then((res) => {
        return {
          status: res.status,
          message: res.data.message,
          data: res.data.result,
        };
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 429) {
          // return window.location.href = BASE_PATH+"/logout";
          return (window.location.href = "/logout");
        } else {
          return {
            status: err["response"] !== undefined ? err.response.status : 503,
            message:
              err["message"] !== undefined
                ? err.message
                : "Service Unavailable",
            data: err.response.data ? err.response.data : {},
          };
        }
      });
  }

  async deleteCall(inputData) {
    // var ipData = await this.getIpData();
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + HEADER_TOKEN,
        // 'X-Authorization': HEADER_X_TOKEN,
        "device-type": DEVICE_TYPE,
        role: ROLE,
        "platform-key": PLATFORM_KEY,
        // 'ipData': JSON.stringify(ipData)
      },
    };

    // Merdge addition header data from request call
    if (Object.keys(inputData.addditionalHeaderData).length > 0) {
      axiosConfig = {
        ...axiosConfig.headers,
        ...inputData.addditionalHeaderData,
      };
    }

    return axios
      .delete(API_BASE_URL + inputData.endPoint, axiosConfig)
      .then((res) => {
        return {
          status: res.status,
          message: res.data.message,
          data: res.data.result,
        };
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 429) {
          // return window.location.href = BASE_PATH+"/logout";
          return (window.location.href = "/logout");
        } else {
          return {
            status: err["response"] !== undefined ? err.response.status : 503,
            message:
              err["message"] !== undefined
                ? err.message
                : "Service Unavailable",
            data: err.response.data ? err.response.data : {},
          };
        }
      });
  }

  async uploadFileCall(inputData) {
    // var ipData = await this.getIpData();
    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + HEADER_TOKEN,
        // 'X-Authorization': HEADER_X_TOKEN,
        "device-type": DEVICE_TYPE,
        role: ROLE,
        "platform-key": PLATFORM_KEY,
        // 'ipData': JSON.stringify(ipData)
      },
    };

    // Merdge addition header data from request call
    if (Object.keys(inputData.addditionalHeaderData).length > 0) {
      axiosConfig = {
        ...axiosConfig.headers,
        ...inputData.addditionalHeaderData,
      };
    }

    return axios
      .post(API_BASE_URL + inputData.endPoint, inputData.postData, axiosConfig)
      .then((res) => {
        return {
          status: res.status,
          message: res.data.message,
          data: res.data.result,
        };
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 429) {
          // return window.location.href = BASE_PATH+"/logout";
          return (window.location.href = "/logout");
        } else {
          return {
            status: err["response"] !== undefined ? err.response.status : 503,
            message:
              err["message"] !== undefined
                ? err.message
                : "Service Unavailable",
            data: err.response.data ? err.response.data : {},
          };
        }
      });
  }

  // getIpData() {
  //     return axios.get(GEOLOCATION_IP_URL, [])
  //                 .then((res) => {
  //                     if (res) {
  //                         return res
  //                     } else {
  //                         return ''
  //                     }
  //                 })
  //                 .catch((err) => {
  //                     return err
  //                 })
  // }
}

export default new ApiService();
