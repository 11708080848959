import React from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { InputGroup, Modal, Form } from "react-bootstrap";
import { useState, useEffect, useImperativeHandle  } from "react";
import KeycafeService from "../../../services/KeycafeService";
import UnitsService from "../../../services/UnitsService";
import Moment from "moment";
import Select from "react-select";

const ActivityHistory = React.forwardRef(({ onSearch, ...props }, ref) => {
  const [newUnitList, setNewUnitList] = useState([]);
  const [selectedUnitList, setSelectedUnitList] = useState(null);
  const [unitNumber, setUnitNumber] = useState("");
  const [newmoduleSearchArray, setModuleSearchString] = useState("");
  const [isSearchArray, setisSearch] = useState(0);

  const [state, setState] = useState({
    extraData: window.location.pathname.split("/")[4],
    total_record: 0,
    records: [],
    extraButtons: [],
    tableQueryString: "",
    customQueryString: "",
    filter_value: "",
    isSearch: global.isSearch,
    is_search: 0,
    tab: 1,
    unit_number: "",
    input: {},
    lock_type: props.lock_type ? props.lock_type : 1,
    property_slug: props.property_slug ? props.property_slug : "",
    activeClass: "alert-activity",
    columns: [
      {
        key: "status_time",
        text: "Time",
        className: "available_from hand-cursor date_tbl_formate",
        sortable: true,
        cell: (record) => {
          return record.status_time
            ? Moment(record.status_time).format(global.dateTimeFormat)
            : "";
        },
      },
      {
        key: "type",
        text: "Activity",
        className: "tower_id hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.type ? record.type : "";
        },
      },

      {
        key: "exchanger_name",
        text: "Exchanger Name",
        className: "floor_id hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.exchanger_name ? record.exchanger_name : "";
        },
      },
      {
        key: "key_name",
        text: "Key Name",
        className: "unit_number hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.key_name ? record.key_name : "";
        },
      },
      {
        key: "serial_number",
        text: "Serial Number",
        className: "floor_plan_id hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.serial_number ? record.serial_number : "";
        },
      },
      {
        key: "bin_no",
        text: "Bin No",
        className: "available_from hand-cursor text-left",
        sortable: true,
        cell: (record) => {
          return record.bin_no ? record.bin_no : "";
        },
      },
    ],
    columns1: [
      {
        key: "status_time",
        text: "Time",
        className: "available_from hand-cursor date_tbl_formate",
        sortable: true,
        cell: (record) => {
          return record.status_time
            ? Moment(record.status_time).format(global.dateTimeFormat)
            : "";
        },
      },
      {
        key: "type",
        text: "Activity",
        className: "tower_id hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.type ? record.type : "";
        },
      },

      {
        key: "exchanger_name",
        text: "Exchanger Name",
        className: "floor_id hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.exchanger_name ? record.exchanger_name : "";
        },
      },
      {
        key: "key_name",
        text: "Unit Name",
        className: "unit_number hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.key_name ? record.key_name : "";
        },
      },
      {
        key: "serial_number",
        text: "Door Name",
        className: "floor_plan_id hand-cursor text-left",
        sortable: false,
        cell: (record) => {
          return record.serial_number ? record.serial_number : "";
        },
      },
    ],
    config: {
      sort: {
        column: "status_time",
        order: "desc",
      },
      key_column: "slug",
      page_size: global.page_size,
      length_menu: global.page_length_menu,
      show_length_menu: true,
      show_filter: global.show_filter,
      show_pagination: global.show_pagination,
      pagination: "advance",
      button: {
        excel: global.excel_button,
        print: global.print_button,
        extra: false,
      },
      language: {
        no_data_text: "No data found.",
      },
    },
  });

  function searchToggle() {
    if (state.isSearch === 1) {
      setState({ ...state, isSearch: 0 });
    } else {
      setState({ ...state, isSearch: 1 });
    }
  }

  function changeHandler(event) {
    let input = state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    setState({ ...state, [event.target.name]: value, input: input });
  }

  const tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      setState({ ...state, tableQueryString: queryString });
    } else {
      queryString += "&" + state.customQueryString;
      setState({ ...state, tableQueryString: queryString });
    }
    if (data.filter_value !== "") {
      clearTimeout(state.filter_time);
      const currentQueryString = queryString;
      setState({
        ...state,
        filter_value: data.filter_value,
        filter_time: setTimeout(() => {
          getDataHistory(currentQueryString);
        }, global.filter_time),
      });
    } else {
      getDataHistory(queryString + "&is_list=1");
    }
  };

  function filterData() {
    let queryString =
      "unit_name=" +
      (unitNumber ? unitNumber : "") +
      "&door_name=" +
      (state.serial_number ? state.serial_number : "") +
      "&key_name=" +
      (state.key_name ? state.key_name : "") +
      "&exchanger_name=" +
      (state.exchanger_name ? state.exchanger_name : "") +
      "&type=" +
      (state.type ? state.type : "") +
      "&serial_number=" +
      (state.serial_number ? state.serial_number : "") +
      "&lock_type=" +
      (props.lock_type ? props.lock_type : "") +
      "&is_search=1";
    let moduleSearchString = "";

    if (unitNumber) {
      if (moduleSearchString) {
        moduleSearchString += "Unit Name - " + unitNumber;
      } else {
        moduleSearchString = "Unit Name - " + unitNumber;
      }
    }
    if (props.lock_type === 2) {
      if (state.key_name) {
        if (moduleSearchString) {
          moduleSearchString += " , Unit Name - " + state.key_name;
        } else {
          moduleSearchString = "Unit Name - " + state.key_name;
        }
      }
    }
    if (props.lock_type === 1) {
      if (state.key_name) {
        if (moduleSearchString) {
          moduleSearchString += " , Key Name - " + state.key_name;
        } else {
          moduleSearchString = "Key Name - " + state.key_name;
        }
      }
    }

    if (state.exchanger_name) {
      if (moduleSearchString) {
        moduleSearchString += " , Exchanger Name - " + state.exchanger_name;
      } else {
        moduleSearchString = "Exchanger Name - " + state.exchanger_name;
      }
    }
    if (props.lock_type === 2) {
      if (state.serial_number) {
        if (moduleSearchString) {
          moduleSearchString += " , Door Name - " + state.serial_number;
        } else {
          moduleSearchString = "Door Name - " + state.serial_number;
        }
      }
    }
    if (props.lock_type === 1) {
      if (state.serial_number) {
        if (moduleSearchString) {
          moduleSearchString += " , Serial Number - " + state.serial_number;
        } else {
          moduleSearchString = "Serial Number - " + state.serial_number;
        }
      }
    }

    if (state.type) {
      if (moduleSearchString) {
        moduleSearchString += " , Activity - " + state.type;
      } else {
        moduleSearchString = "Activity - " + state.type;
      }
    }

    if (
      unitNumber ||
      state.key_name ||
      state.exchanger_name ||
      state.serial_number ||
      state.type
    ) {
      localStorage.setItem('moduleSearchString', moduleSearchString);
      setModuleSearchString(moduleSearchString);
      setState({
        ...state,
        customQueryString: queryString,
      });
      searchToggle();
      getDataHistory(queryString);
      onSearch();
    }
  }

  function clearFilter() {
    setUnitNumber(""); // Clear unitNumber state
    setModuleSearchString(""); // Clear newmoduleSearchArray state
    setisSearch(0); // Clear isSearchArray state
    getDataHistory();
  }

  const handleChildClick = () => {
    localStorage.removeItem('moduleSearchString');
    getDataHistory();
  };
  
  useImperativeHandle(ref, () => ({
    handleChildClick,
  }));

  useEffect(() => {
    setTimeout(() => {
      getDataHistory();
      getUnitData();
      // fetchData();
    }, 200);
  }, [state.is_search, props.property_slug]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state.isSelected && state.isFocused
          ? "white"
          : state.isSelected
          ? "black"
          : state.isFocused && "white",
      backgroundColor:
        state.isSelected && state.isFocused
          ? "black"
          : state.isFocused && "black",
    }),
  };

  /* to get unit data list */
  const getUnitData = async () => {
    try {
      const queryString = props.queryString || "";
      const response = await UnitsService.getUnitsList(
        `is_dropdown=1&property_slug=${props.property_slug}${
          queryString ? "&" + queryString : ""
        }`
      );

      if (global.successStatus.includes(response.status)) {
        const data = response.data || [];
        const total = data.length || 0;

        const newUnits =
          total > 0
            ? data.map(({ slug, unit_number, unit_name }) => ({
                value: slug,
                label: unit_name || unit_number,
              }))
            : [];
        setNewUnitList(newUnits);
      }
    } catch (error) {
      // Handle error here
      console.error("Error fetching unit data:", error);
    } finally {
      if (props.setShowSpinner) {
        props.setShowSpinner(false);
      }
      if (props.setLoading) {
        props.setLoading(false);
      }
    }
  };

  const handleChangeUnitList = (value) => {
    let unitSlug = [];
    if (value !== null && value.length > 0) {
      value.forEach((item) => {
        unitSlug.push(item.value);
      });
    }
    setSelectedUnitList(value);
    setUnitNumber(value ? value.label : "");
  };
  
  const getDataHistory = async (queryString = "") => {
    var list = [];
    var totalRecords = 0;

    let res = await KeycafeService.getKeycafeActivity(
      "property_slug=" +
        (props.property_slug ? props.property_slug : "") +
        "&lock_type=" +
        (props.lock_type ? props.lock_type : "") +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : {};
      totalRecords = res.data ? res.data.total : 0;
    }
    setState({
      ...state,
      total_record: totalRecords,
      records: list,
      isSearch: 0,
      is_search: state.unit_number === "" ? 0 : 1,
    });
  };

  return (
    <>
      {/* <main>

          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-12">
                */}
      <div className="scrolling-carousel">
        <div>
          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
            <div className="d-flex flex-wrap justify-content-end bothBtn swiperBtns key_header_activity">
              <button
                className="btn-success-outline-small"
                type="button"
                onClick={searchToggle}
              >
                Search
              </button>
            </div>
            {isSearchArray === 1 && newmoduleSearchArray ? (
              <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                <div className="custm-filter">
                  <div>
                    <label className="form-label text-left pd-b-2 mb-0">
                      Search Applied On
                    </label>
                  </div>
                  <div className="filter_bg_cust">
                    {newmoduleSearchArray.split(",").map((item, i) => {
                      return <span key={i}>{item.trim()}</span>;
                    })}
                    <button onClick={clearFilter} className="btn-fliter-clear">
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block">
              <div className="pd-lg-l-0 pd-xs-l-15">
                <ReactDatatable
                  className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                  config={
                    state.total_record > global.page_size
                      ? state.config
                      : state.config
                  }
                  records={state.records}
                  columns={
                    props.lock_type === 1 ? state.columns : state.columns1
                  }
                  extraButtons={state.extraButtons}
                  dynamic={true}
                  total_record={state.total_record}
                  onChange={tableChangeHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*        </div>
              </div>
            </div>
          </Container>
      </main> */}
      <Modal
        show={state.isSearch}
        className="modal-large searchModalbox"
        centered
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            this.filterData();
          }
        }}
      >
        <Modal.Body className="width_402 p-4 border_radius_14">
          <span className="welcomeClose" onClick={() => searchToggle()}>
            {global.closee}
          </span>
          <h3 className="text-center">Search in Key History</h3>
          <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
            <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
              {props.lock_type === 2 ? (
                <InputGroup className="mt-3">
                  <label>Unit Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={changeHandler}
                      name="key_name"
                      value={state.key_name}
                      className="form-control max_width_100"
                      id="inlineFormInput1"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              ) : (
                ""
              )}
              {props.lock_type === 1 ? (
                <InputGroup className="mt-3">
                  <label>Key Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={changeHandler}
                      name="key_name"
                      value={state.key_name}
                      className="form-control max_width_100"
                      id="inlineFormInput1"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              ) : (
                ""
              )}
              {props.lock_type === 1 ? (
                <InputGroup className="mt-3">
                  <label>Serial Number</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={changeHandler}
                      name="serial_number"
                      value={state.serial_number}
                      className="form-control max_width_100"
                      id="inlineFormInput1"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              ) : (
                ""
              )}
              {props.lock_type === 2 ? (
                <InputGroup className="mt-3">
                  <label>Exchanger Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={changeHandler}
                      name="exchanger_name"
                      value={state.exchanger_name}
                      className="form-control max_width_100"
                      id="inlineFormInput1"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              ) : (
                ""
              )}
              {props.lock_type === 4 ? (
                <InputGroup className="mt-3">
                  <label>Unit </label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={selectedUnitList}
                      options={newUnitList}
                      onChange={(value) => handleChangeUnitList(value)}
                      defaultValue={selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              ) : (
                ""
              )}
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
              {props.lock_type === 2 ? (
                <InputGroup className="mt-3">
                  <label>Door Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={changeHandler}
                      name="serial_number"
                      value={state.serial_number}
                      className="form-control max_width_100"
                      id="inlineFormInput1"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              ) : (
                ""
              )}
              {props.lock_type === 1 ? (
                <InputGroup className="mt-3">
                  <label>Exchanger Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={changeHandler}
                      name="exchanger_name"
                      value={state.exchanger_name}
                      className="form-control max_width_100"
                      id="inlineFormInput1"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              ) : (
                ""
              )}
              <InputGroup className="mt-3">
                <label>Activity</label>
                <div className="col-md-12 p-0">
                  <Form.Control
                    onChange={changeHandler}
                    name="type"
                    value={state.type}
                    className="form-control max_width_100"
                    id="inlineFormInput1"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                  />
                </div>
              </InputGroup>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3 row">
            <button
              type="button"
              className="m-0 btn btn-outline-light btn-block"
              onClick={() => searchToggle()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="m-0 btn btn btn-success btn-block"
              onClick={filterData}
              disabled={state.isSubmit ? true : false}
            >
              {state.isSubmit ? global.loader : "Search"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default ActivityHistory;
