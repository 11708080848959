////////////////////////////////////////////////////////////
//     							                                      //
//  Program: UploadVideo.jsx                              //
//  Application:  CorporateApp                            //
//  Option: listing uploaded video                        //
//  Developer: Devendra Kumar                             //
//  Date: 14/06/2023                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import CorporateAppService from "../../services/CorporateAppService";
import { Link } from "react-router-dom";
import { Container, Nav, Tab, Modal, InputGroup, Form } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import NoData from "../common/NoData";
import filterIcon from "./../../assets/images/filter-gray.svg";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import CMSSidebar from "../common/CMSSidebar";
import Moment from "moment";

//let queryParams = queryString.parse(window.location.search);

export class UploadVideo extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      isSearch: global.isSearch,
      module_search: "",
      is_search: 0,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      searchCorporateAppModal: false,
      loading: false,
      showSpinner: false,
      total_record: 0,
      url: "",
      title: "",
      private_content: "",
      description: "",
      isSubmit: false,
      records: [],
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      privateContentOption: [
        { label: "Yes", value: 1 },
        { label: "No", value: 2 },
      ],
      selectedStatusList: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "video_title",
          text: "Video Title",
          className: "title hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.video_title ? record.video_title : "";
          },
        },
        {
          key: "description",
          text: "Description",
          className: "title hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.description ? record.description : "";
          },
        },
        {
          key: "upload_date_time",
          text: "Uploaded Date",
          className: "sort_order hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.upload_date_time
              ? Moment(record.upload_date_time).format(global.dateTimeFormat)
              : "";
          },
        },
        {
          key: "stream_url",
          text: "Transcode URL",
          className: "sort_order hand-cursor text-break text-left ",
          sortable: true,
          cell: (record) => {
            return record.stream_url ? record.stream_url : "";
          },
        },
      ],
      tab: "active",
      clientTabData: localStorage.getItem("client_tab_data")
        ? localStorage.getItem("client_tab_data")
        : "",
      activeClass: "activeTab",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.opensearchCorporateAppModal =
      this.opensearchCorporateAppModal.bind(this);
    this.searchCorporateApp = this.searchCorporateApp.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  componentDidMount() {
    switch (this.state.clientTabData) {
      case "active":
        document.getElementById("activeTab").click();
        break;
      case "inactive":
        document.getElementById("inactiveTab").click();
        break;
      default:
        document.getElementById("activeTab").click();
    }
  }
  opensearchCorporateAppModal() {
    this.setState({ searchCorporateAppModal: true });
  }

  closesearchCorporateAppModal() {
    this.setState({ searchCorporateAppModal: false });
    this.validator.hideMessages();
  }

  /* to search corporate data */
  searchCorporateApp = async (e) => {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ isSubmit: true });
    var videoList = [];

    e.preventDefault();
    let inputData = "title=" + this.state.title + "&" + "url=" + this.state.url;
    let res = await CorporateAppService.getUploadedVideoData(inputData);

    var moduleSearchString = "";

    moduleSearchString = this.state.title
      ? "Title - " + this.state.title
      : moduleSearchString;

    moduleSearchString = this.state.url
      ? moduleSearchString
        ? moduleSearchString + " ," + " URL - " + this.state.url
        : " URL - " + this.state.url
      : moduleSearchString;

    if (this.state.title || this.state.url) {
      this.setState({ module_search: moduleSearchString.split(",") });
    }
    if (global.successStatus.includes(res.status)) {
      videoList = res.data ? res.data : [];
    }
    this.setState({
      records: videoList,
    });
    this.state.url = "";
    this.state.title = "";
    this.setState({ isSubmit: false });
    this.setState({ showSpinner: false, loading: false, is_search: 1 });
    this.setState({ searchCorporateAppModal: false });
  };

  clearFilter() {
    this.setState({
      module_search: "",
      is_search: 0,
      selectedPrivateContent: "",
      selectedCategory: "",
      title: "",
      url: "",
      privateContentSlug: "",
      privateCategorySlug: "",
    });
    this.getData("tab=" + this.state.tab);
  }
  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "client_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await CorporateAppService.getUploadedVideoData(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      totalRecords = res.data ? res.data.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData("tab=" + this.state.tab + "&" + queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("/upload-video/update/" + data.slug + "/info");
  };

  render() {
    return (
      <>
        <div>
          <main>
          <Container className="innter-container">
              <div className="d-flex flex-column">
                <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <aside>
                        <CMSSidebar />
                      </aside>
                    </div>
                  </div>
                  <div className="col-md-10 right">
                    <div className="scrolling-carousel">
                      <>
                        <div className="">
                          <div className="col-md-12 mg-t-20 mg-b-10"></div>
                          <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0 pd-lg-l-15">
                            <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                              <h3 className="brnd_heading mb-0">
                                Video Upload
                              </h3>
                              <div className="d-flex flex-wrap">
                                <Link to={"/upload-video/add"}>
                                  <button className="btn-success-outline mg-r-20 ml-3">
                                    <img src={pluswIcon} alt="Add" /> Add
                                  </button>
                                </Link>
                                <button
                                  onClick={this.opensearchCorporateAppModal}
                                  className="btn-success-outline-small ml-3"
                                >
                                  <img src={filterIcon} alt="filterIcon" />{" "}
                                  {this.state.isSearch === 1
                                    ? "Search"
                                    : "Search"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      <>
                        <div className="scrolling-carousel scroll-slide">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="all"
                            onSelect={(e) => this.getActiveClass(e)}
                            eventKey={this.state.activeClass}
                            className="pd-r-60"
                          >
                            <ScrollingCarousel
                              rightArrow={true}
                              leftArrow={true}
                              className="swipesection pd-t-20 d-none"
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="activeTab"
                                  id="activeTab"
                                  className={
                                    this.state.activeClass === "activeTab"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Active
                                </Nav.Link>
                              </Nav.Item>
                            </ScrollingCarousel>
                            {this.state.is_search === 1 ? (
                              <>
                                <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel mg-t-20">
                                  <div className="custm-filter">
                                    <div>
                                      {" "}
                                      <label className="form-label text-left pd-b-2 mb-0">
                                        Search Applied On
                                      </label>
                                    </div>
                                    <div className="filter_bg_cust">
                                      {this.state.module_search.map(
                                        (item, i) => {
                                          return <span key={i}>{item}</span>;
                                        }
                                      )}
                                      <button
                                        onClick={this.clearFilter}
                                        className="btn-fliter-clear"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            <Tab.Content>
                              <Tab.Pane eventKey="activeTab">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-20 brandList vdo_upload_tbl">
                                      <ReactDatatable
                                        className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                        config={
                                          this.state.total_record >
                                          global.page_size
                                            ? this.state.config
                                            : this.state.config
                                        }
                                        records={this.state.records}
                                        columns={this.state.columns}
                                        extraButtons={this.state.extraButtons}
                                        dynamic={true}
                                        total_record={this.state.total_record}
                                        onChange={this.tableChangeHandler}
                                        onRowClicked={this.rowClickedHandler}
                                      />
                                    </div>
                                  ) : (
                                    <NoData msg="active videos" />
                                  )}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="inactiveTab">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-20 brandList">
                                      <ReactDatatable
                                        className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                        config={
                                          this.state.total_record >
                                          global.page_size
                                            ? this.state.config
                                            : this.state.config
                                        }
                                        records={this.state.records}
                                        columns={this.state.columns}
                                        extraButtons={this.state.extraButtons}
                                        dynamic={true}
                                        total_record={this.state.total_record}
                                        onChange={this.tableChangeHandler}
                                        onRowClicked={this.rowClickedHandler}
                                      />
                                    </div>
                                  ) : (
                                    <NoData msg="inactive videos" />
                                  )}
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </main>
        </div>
        {/* Module search popup modal */}
        <Modal
          className="serch_coperate_modal"
          show={this.state.searchCorporateAppModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without Searching?"))
              this.closesearchCorporateAppModal();
          }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closesearchCorporateAppModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Search Videos</h3>
            <InputGroup className="mt-3">
              <label>Title</label>
              <Form.Control
                onChange={this.changeHandler}
                name="title"
                value={this.state.title}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
                placeholder="Title"
              />
            </InputGroup>
            <InputGroup className="mt-3">
              <label>URL</label>
              <div className="col-md-12 p-0">
                <Form.Control
                  onChange={this.changeHandler}
                  name="url"
                  value={this.state.url}
                  className="form-control max_width_100"
                  id="inlineFormInput"
                  type="text"
                  autoComplete="off"
                  placeholder="Url"
                />
              </div>
            </InputGroup>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closesearchCorporateAppModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.searchCorporateApp}
                disabled={
                  this.state.isSubmit ||
                  (this.state.title === "" &&
                    this.state.url === "" &&
                    !this.state.selectedPrivateContent &&
                    !this.state.selectedCategory)
                    ? true
                    : false
                }
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default UploadVideo;
