////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: NP 						                  //
//  Date: 2021-12-29                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import AuthenticationService from "../../services/AuthenticationService";
import SimpleReactValidator from "simple-react-validator";
import Moment from "moment";
import { Container } from "react-bootstrap";
import defaultImage from "./../../assets/images/profile-nogender.png";
import Alerts from "../common/Alerts";
import PhoneInput from "react-phone-input-2";
import PhoneNumberFormat from "../common/PhoneNumberFormat";

export class UpdateProfile extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      profile_thumb: "",
      imageUrl: "",
      imageThumbUrl: "",
      isSubmit: false,
      dial_code: "",
      role_title: localStorage.getItem("currentRoleTitle")
        ? localStorage.getItem("currentRoleTitle")
        : global.currentRoleTitle,
    };

    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true });
    var resData = {};
    let res = await AuthenticationService.getProfile();
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
      resData.phone = res.data.phone_number ? res.data.phone_number : "";
      resData.dial_code = res.data.dial_code_number
        ? res.data.dial_code_number
        : "";
      resData.imageUrl = res.data.profile_url ? res.data.profile_url : "";
      resData.imageThumbUrl = res.data.profile_thumb_url
        ? res.data.profile_thumb_url
        : "";

      this.props.imageUpload(res.data.profile_url);
      this.props.imageUpload(res.data.profile_thumb_url);
    }
    this.setState(resData);
    this.setState({ showSpinner: false });
  }

  updateProfile = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        phone: this.state.phone,
        dial_code: this.state.dial_code,
        profile_thumb: this.state.profile_thumb,
      };
      let res = await AuthenticationService.updateProfile(inputData);
      if (global.successStatus.includes(res.status)) {
        let resdata = await AuthenticationService.getProfile();
        if (global.successStatus.includes(resdata.status)) {
          var resData = resdata.data ? resdata.data : {};
          localStorage.setItem("userData", JSON.stringify(resData));
          this.props.imageUpload(resData.profile_url);
          this.props.imageUpload(resData.profile_thumb_url);
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
      } else {
        let alertMessage = "";
        if (
          res.data.errorCode === "validationFailed" &&
          res.data.errorDetail !== null
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.errorMessage,
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    if (event.target.type === "date" && event.target.value !== null) {
      event.target.value = Moment(event.target.value, "DD-MM-YYYY").toDate();
    }
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "profile_thumb",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");

      let res = await AuthenticationService.uploadProfileImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          profile_thumb: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        let errors = {};
        this.setState({ errors: errors });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  // Custom validation rules
  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  onChangePhone(value, country, e, formattedValue) {
    //   console.log(value, country, e, formattedValue);
    this.setState({ phone: value });
    this.setState({ dial_code: country.dialCode });
  }

  removePhoto() {
    this.setState({ profile_thumb: "", imageUrl: "" });
  }

  render() {
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <Container>
            <div className="row row-xs">
              <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>

              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                  <h3>Update Profile</h3>
                  <button
                    className="btn-success"
                    onClick={this.updateProfile}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-md-12 pl-0 pr-0 mg-t-30">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>
            <div className="row row-xs mg-t-30 pd-t-2 cardsection">
              <div className="col-md-5 col-lg-5 col-xl-3 mg-b-30 cardLeft">
                <div className="dashboardLeftcard az-profile-overview">
                  <div className="az-img-user ml-auto  mr-auto align-items-baseline mg-t-35">
                    <img
                      src={
                        this.state.imageUrl ? this.state.imageUrl : defaultImage
                      }
                      alt=""
                    />
                  </div>
                  {/* az-img-user */}

                  <div className="d-flex justify-content-center mg-b-20 mg-t-20 pd-b-4">
                    <div className="overview">
                      <h3 className="mb-2 text-center">
                        {this.state.first_name ? this.state.first_name : ""}{" "}
                        {this.state.last_name ? this.state.last_name : ""}
                      </h3>
                      <p className="media-body p-0 m-0 text-center">
                        {this.state.role_title ? this.state.role_title : ""}
                      </p>
                      <p className="media-body p-0 m-0 text-center">
                        {this.state.email ? this.state.email : ""}
                      </p>
                      <p className="media-body p-0 m-0 text-center">
                        {this.state.phone ? (
                          <PhoneNumberFormat phone_number={this.state.phone} />
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                {/* az-profile-overview */}
              </div>

              <div className="col-md-8 col-lg-7 col-xl-9 mg-b-30 cardRight">
                <div className="dashboardRightcard">
                  {/* form start */}

                  <div className="row align-items-center pd-t-30 pd-b-0 border-bottom-0">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                      <label className="form-label text-left  pd-b-5">
                        First Name
                        {/* <span className="text-danger">*</span> */}
                      </label>

                      <input
                        name="first_name"
                        className="form-control"
                        autoComplete="off"
                        placeholder="First Name"
                        value={this.state.first_name}
                        onChange={this.changeHandler}
                        maxLength="30"
                      />
                      {this.state.errors.first_name ? (
                        <div className="text-danger">
                          {this.state.errors.first_name}
                        </div>
                      ) : (
                        this.validator.message(
                          "first_name",
                          this.state.first_name,
                          "required|max:30",
                          { className: "text-danger" }
                        )
                      )}
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                      <label className="form-label text-left  pd-b-5">
                        Last Name
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        name="last_name"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Last Name"
                        value={this.state.last_name}
                        onChange={this.changeHandler}
                        maxLength="30"
                      />
                      {this.state.errors.last_name ? (
                        <div className="text-danger">
                          {this.state.errors.last_name}
                        </div>
                      ) : (
                        this.validator.message(
                          "last_name",
                          this.state.last_name,
                          "required|max:30",
                          { className: "text-danger" }
                        )
                      )}
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                      <label className="form-label text-left  pd-b-5">
                        Email
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        name="email"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.changeHandler}
                        readOnly
                      />
                      {this.state.errors.email ? (
                        <div className="text-danger">
                          {this.state.errors.email}
                        </div>
                      ) : (
                        this.validator.message(
                          "email",
                          this.state.email,
                          "required",
                          { className: "text-danger" }
                        )
                      )}
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                      <label className="form-label text-left  pd-b-5">
                        Phone
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <PhoneInput
                        country={global.country}
                        disableSearchIcon
                        // autoFormat= "true"
                        enableSearch="true"
                        countryCodeEditable="true"
                        value={this.state.phone}
                        onChange={this.onChangePhone}
                        // onChange={phone => this.setState({ phone })}
                      />

                      {this.state.errors.phone ? (
                        <div className="text-danger">
                          {this.state.errors.phone}
                        </div>
                      ) : (
                        this.validator.message(
                          "phone",
                          this.state.phone,
                          "required",
                          { className: "text-danger" }
                        )
                      )}
                    </div>
                  </div>
                  <div className="row align-items-center pd-t-0 pd-b-0 pd-l-30 pd-r-30 border-bottom-0">
                    {/* <div className="col-md-1 col-lg-1 mg-t-5 mg-md-t-0 pl-0">

                                        </div> */}
                    <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                      <label className="form-label pd-b-5 text-left">
                        Photo
                      </label>
                      <div className="profile-img-upload">
                        <div className="fileUpload d-flex flex-wrap justify-content-between">
                          <span>
                            <label className="hand-cursor">
                              <input
                                onChange={this.onImageChange}
                                type="file"
                                name="profile_thumb"
                              />
                              {this.state.imageUrl ? (
                                <img src={this.state.imageUrl} alt="" />
                              ) : (
                                <img src={defaultImage} alt="" />
                              )}
                            </label>
                          </span>

                          <ul className="ruLink">
                            <li>
                              <label>
                                <input
                                  onChange={this.onImageChange}
                                  type="file"
                                  name="profile_thumb"
                                />
                              </label>
                              Change Avatar
                            </li>
                          </ul>
                        </div>
                        {this.state.errors.profile_thumb ? (
                          <div className="text-danger">
                            {this.state.errors.profile_thumb}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <p>
                        <span
                          className="hand-cursor remove-pic"
                          onClick={this.removePhoto}
                        ></span>
                      </p>
                    </div>
                    {/* <div className="col-md-3">
                                           <p className="hand-cursor" onClick={this.removePhoto}>Remove photo</p>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
      </div>
    );
  }
}

export default UpdateProfile;
