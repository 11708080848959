///////////////////////////////////////////////////////////
//  Program: Emails.jsx                                  //
//  Application: Emails                                  //
//  Option: add all Emails                               //
//  Developer:  NP                                       //
//  Date: 2022-12-05                                     //
///////////////////////////////////////////////////////////

import SettingSidebar from "../common/SettingSidebar";
import Alerts from "../common/Alerts";
import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import EmailService from "../../services/EmailService";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import JoditEditor from 'jodit-react';
// import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";
// import { useRef } from "react";

// import FileWidget from "../fileUploader/FileWidget";

class EmailForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      slug: props.match.params.slug,
      confirm_back: 0,
      input: {},
      errors: {},
      usertotype: '',
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      selectedTypeList: [],
      selectedsecondlist: [],
      selectedCcList: [],
      staffList: [],
      newStaffList: [],
      selectedStaffToList: [],
      selectedStaffList: [],
      selectedBccStaffList: [],
      deleteTowerModal: false,
      status_id: true,
      typeList: [
        { key: 6, value: 6, label: 'User' },
        { key: 5, value: 5, label: 'Guest' },
        { key: 4, value: 4, label: 'Resident' },
        { key: 3, value: 3, label: 'Staff' },
        { key: 7, value: 7, label: 'Custom' }
      ],
      ccforList: [
        { key: 1, value: 3, label: 'Staff' },
        { key: 2, value: 2, label: 'Custom' },

      ],
      BccforList: [
        { key: 3, value: 3, label: 'Staff' },
        { key: 2, value: 2, label: 'Custom' },

      ],
      moduleList: global.modulelist,
      moduleTypeList: global.SystemmoduleReferlist.filter((item) => {
        if (item.slug === props.match.params.slug) {
          return item.slug
        }

      }),
      subjectVariableList: global.modulesubjectVariable.filter((item) => {
        if (item.slug === props.match.params.slug) {
          return item.slug
        }

      }),
      template_desc_info: {
        guest:
          "Message ( Available Merge Fields : [Guest Name] [Confirmation Code] [Check-In] [Check-Out] [Addres] [Unit No] )",
        staff: "Message ( Available Merge Fields : [Staff Name] [Unit No] )",
        resident:
          "Message ( Available Merge Fields : [Resident Name] [Unit No] )",
      },
    };
    this.editorConfig = {
      events:
      {
        afterInit: (instance) => { this.jodit = instance; }
      }
    };
    this.saveOrUpdateEmail = this.saveOrUpdateEmail.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerck = this.changeHandlerck.bind(this);
    this.setContent = this.setContent.bind(this);
  }

  componentDidMount() {
    this.getStaffData();
  }

  async getStaffData(queryString = "") {
    var staffList = [];
    var staffListTotal = 0;

    let res = await EmailService.getstaff(
      "is_dropdown=1"
    );
    if (global.successStatus.includes(res.status)) {
      staffList = res.data ? res.data : [];
      staffListTotal = staffList ? staffList.length : 0;
    }
    var newStaffList =
      staffListTotal > 0
        ? staffList.map(({ user_id, first_name, last_name, role_title, email }) => ({
          value: user_id,
          email: email,
          label: first_name + ' ' + last_name + ' (' + role_title + ')',
        }))
        : [];
    this.setState({
      staffListTotal: staffListTotal,
      newStaffList: newStaffList,
    });
    this.getDataFromSlug();
  }

  saveOrUpdateEmail = async (e) => {
    e.preventDefault();
    var cc = [];
    var bcc = [];
    this.setState({
      alertModalMessage: "",
    });
    if (this.validator.allValid() && this.customValidate()) {
      if (this.state.selectedStaffList != null) {
        this.state.selectedStaffList.forEach((item) => {
          cc = [...cc, item.email];
        });
      }

      if (this.state.selectedBccStaffList != null) {
        this.state.selectedBccStaffList.forEach((item) => {
          bcc = [...bcc, item.email];
        });

      }

      let cc_str = cc.join(",")
      let bcc_str = bcc.join(",")
      this.setState({ loading: true, isSubmit: true });
      let inputData = {
        template_name: this.state.template_name ? this.state.template_name : '',
        description: this.state.description ? this.state.description : '',
        from: this.state.from ? this.state.from + '@orionhaus.com' : '',
        to: this.state.usertotype ? this.state.usertotype : this.state.selectedTypeList[0].value ? this.state.selectedTypeList[0].value : '',
        email_to_id: this.state.usertotype == 3 ? this.state.selectedStaffToList.email : this.state.user_id_to ? this.state.selectedStaffToList.email : this.state.to === 3 ? this.state.selectedStaffToList[0].value : this.state.usertotype == 7 || this.state.to === 7 ? this.state.email_to_id : '',
        email_to_id_select: this.state.usertotype == 3 ? this.state.selectedStaffToList.label : this.state.user_id_to ? this.state.selectedStaffToList.label : this.state.to === 3 ? this.state.selectedStaffToList[0].label : '',
        cc_id: this.state.getvalueofcc ? this.state.getvalueofcc : this.state.cc_id ? this.state.cc_id : '',
        cc: this.state.getvalueofcc === 3 || this.state.cc_id == 3 ? cc_str : this.state.cc_id === 2 || this.state.getvalueofcc === 2 ? this.state.cc : '',
        bcc_id: this.state.getvalueofbcc ? this.state.getvalueofbcc : this.state.bcc_id ? this.state.bcc_id : '',
        bcc: this.state.getvalueofbcc === 3 || this.state.bcc_id == 3 ? bcc_str : this.state.bcc_id === 2 || this.state.getvalueofbcc === 2 ? this.state.bcc : '',
        custom_email: this.state.custom_email ? this.state.custom_email : '',
        subject: this.state.subject ? this.state.subject : '',
        body: this.state.body ? this.state.body : '',
        status_id: this.state.status_id ? this.state.status_id : 2,
      };
      if (this.state.slug !== undefined) {
        this.setState({ loading: true, isSubmit: true });
        let res = await EmailService.updateEmailTemplate(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/emails"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {

        this.setState({ loading: true, isSubmit: true });
        let res = await EmailService.createEmailTemplate(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/emails"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ loading: false, isSubmit: false });
      this.validator.showMessages();
    }
  };

  async getDataFromSlug() {
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await EmailService.getEmailTemplate(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        var fromemail = resData.from
        var dataemail = fromemail.split("@");
        resData.template_name = resData.template_name ? resData.template_name : "";
        resData.description = resData.description ? resData.description : "";
        resData.from = resData.from ? dataemail[0] : "";
        resData.cc_id = resData.cc_id ? resData.cc_id : "";
        resData.cc = resData.cc ? resData.cc : "";
        resData.users_ids_for_cc = resData.users_ids_for_cc ? resData.users_ids_for_cc : "";
        resData.bcc_id = resData.bcc_id ? resData.bcc_id : "";
        resData.bcc = resData.bcc ? resData.bcc : "";
        resData.users_ids_for_bcc = resData.users_ids_for_bcc ? resData.users_ids_for_bcc : "";
        resData.to = resData.to
          ? resData.to
          : "";
        resData.email_to_id = resData.to == 3 || resData.to == 7
          ? resData.email_to_id
          : "";
        resData.status_id = res.data ? res.data.status_id === 1 ? true : false : false;
        var ccdata = resData.cc_id == 3 ? resData.cc.split(',') : '';
        var bccdata = resData.bcc_id == 3 ? resData.bcc.split(',') : '';
        var selectedTypeList = [];
        selectedTypeList = res.data.to === 4 ? [{ key: 4, value: 4, label: 'Resident' }] : res.data.to === 3 ? [{ key: 3, value: 3, label: 'Staff' }] : res.data.to === 5 ? [{ key: 5, value: 5, label: 'Guest' }] : res.data.to === 6 ? [{ key: 6, value: 6, label: 'User' }] : res.data.to === 7 ? [{ key: 7, value: 7, label: 'Custom' }] : [];
        var selectedCcList = [];
        selectedCcList = res.data.cc_id === 3 ? [{ key: 3, value: 3, label: 'Staff' }] : res.data.cc_id === 2 ? [{ key: 2, value: 2, label: 'Custom' }] : []
        var selectedsecondlist = [];
        selectedsecondlist = res.data.bcc_id === 3 ? [{ key: 3, value: 3, label: 'Staff' }] : res.data.bcc_id === 2 ? [{ key: 2, value: 2, label: 'Custom' }] : []
        var selectedBccStaffList = [];
        if (resData.bcc_id === 3) {
          if (bccdata[0] !== '') {
            this.state.newStaffList.forEach((item1, i) => {
              bccdata.length > 0 &&
                bccdata.forEach((item, i) => {
                  if (item == item1.email) {
                    selectedBccStaffList = [
                      ...selectedBccStaffList,
                      {
                        value: res.data.bcc,
                        email: item1.email,
                        label: item1.label,
                      },
                    ];
                  }
                });
            });
          }
        }
        var selectedStaffToList = [];
        if (resData.to === 3) {
          selectedStaffToList = res.data
            ? [
              {
                value: res.data.email_to_id,
                label: res.data.email_to_id_select,
              },
            ]
            : "";
        }
        var selectedStaffList = [];
        if (resData.cc_id === 3) {
          if (ccdata[0] !== '') {
            this.state.newStaffList.forEach((item1, i) => {
              ccdata.length > 0 &&
                ccdata.forEach((item, i) => {
                  if (item == item1.email) {
                    selectedStaffList = [
                      ...selectedStaffList,
                      {
                        value: res.data.cc,
                        email: item1.email,
                        label: item1.label,
                      },
                    ];
                  }
                });
            });
          }
        }
      }
      this.setState({
        selectedTypeList: selectedTypeList,
        selectedStaffList: selectedStaffList,
        selectedCcList: selectedCcList,
        selectedsecondlist: selectedsecondlist,
        selectedBccStaffList: selectedBccStaffList,
        selectedStaffToList: selectedStaffToList
      });
      this.setState(resData);
    }

  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeStaffList(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    this.setState({
      selectedStaffList: value,
      user_id: user_id,
    });
    // console.log(this.state.selectedRoleList, this.state.role_id);
  }

  handleChangeStaffToList(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    this.setState({
      selectedStaffToList: value,
      user_id_to: value.value,
    });
  }

  handleChangeBccStaffList(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    this.setState({
      selectedBccStaffList: value,
      user_id: user_id,
    });
  }

  handleChangeUserList(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedTypeList: value,
      usertotype: type,
    });
    if (this.state.slug !== undefined) {
      this.setState({
        to: ''
      });
      if (this.state.to == 3) {
        this.setState({
          email_to_id: ''
        });
      }
    }
    this.setState({ confirm_back: 1 });
  }

  handleChangeCCList(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    // const type = value !== null ? value.value : [];
    this.setState({
      selectedCcList: value,
      getvalueofcc: value.value
    });
    this.setState({ confirm_back: 1 });
    if (this.state.slug !== undefined) {
      this.setState({
        cc_id: '',
        cc: ''
      });
      if (this.state.cc_id === 3) {
        this.setState({
          cc: ''
        });
      }
    }
  }

  handleChangeBCCList(value) {
    let newTypeList12 = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList12.push(item.value);
      });
    }
    // const type = value !== null ? value.value : [];
    this.setState({
      selectedsecondlist: value,
      getvalueofbcc: value.value
    });
    this.setState({ confirm_back: 1 });
    if (this.state.slug !== undefined) {
      this.setState({
        bcc_id: ''

      });
      if (this.state.bcc_id === 3) {
        this.setState({
          bcc: ''
        });
      }
    }
  }

  handleChangemodulereferList(value) {
    let newTypeList12 = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList12.push(item.value);
      });
    }
    let dynmicvariable = value.label;
    this.setState({
      selectedModulleRferList: value,
      dynmicvariable: dynmicvariable,
    });
  }

  handleChangeSubjectVariableList(value) {
    let newTypeList13 = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList13.push(item.value);
      });
    }
    let dynmicvariablesbject = value.label;
    this.setState({
      selectedSubjectList: value,
      dynmicvariablesbject: dynmicvariablesbject,
    });
  }

  insertbody = (e) => {
    var dynamictext = this.state.dynmicvariable ? this.state.dynmicvariable : '';
    this.jodit.selection.insertHTML(dynamictext ? '[' + dynamictext + ']' : '');
  };

  insertsubject = (e) => {
    let textToInsert = this.state.dynmicvariablesbject ? ' [' + this.state.dynmicvariablesbject + ']' : '';
    this.setState({
      subject: this.state.subject ? `${this.state.subject} ${textToInsert}` : ''
    });
  }

  changeHandlerck(event, index) {
    let value = event.target.value;
    this.setState({
      body: value
    })
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    // this.validator.hideMessages();
    // if (!this.state.status_id) {
    //   this.validator.showMessages();
    // } else {
    //   this.validator.showMessageFor("room_name");
    // }
  }

  setContent(value) {
    this.setState({
      body: value
    })
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <SettingSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel">
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">System Emails</h3>

                          <div className="d-flex justify-content-end">
                            <Link to={"/emails"}>
                              <button className="btn-success-outline mg-r-20">
                                Cancel
                              </button>
                            </Link>

                            {global.userPermissions.checkPermission(
                              "emails-update"
                            ) &&
                              <button
                                type="button"
                                className="btn-success-outline-small ml-3 button-width"
                                onClick={this.saveOrUpdateEmail}
                                disabled={this.state.isSubmit ? true : false}
                              >
                                {this.state.isSubmit
                                  ? global.loader
                                  : this.state.slug
                                    ? "Update"
                                    : "Save"}
                              </button>
                            }
                          </div>

                        </div>
                      </div>

                    </div>
                    <div className="pd-lg-l-0 pd-lg-t-20 ">
                      <div className="table-responsive roles   ">
                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0 mt-3">
                          <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                          />
                        </div>

                        <div className="mg-t-18 brandList table_issue_roles">
                          <div className=" ">
                            {/* <div className="row align-items-center pd-b-30 pd-xs-b-0 pd-lg-b-30 pd-l-30 pd-r-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30"> */}
                            <div className="row">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                <label className="form-label text-left pd-b-5">
                                  Email Template Name{" "}
                                </label>
                                <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="template_name"
                                  value={this.state.template_name}
                                  maxLength="100"
                                  //tabIndex="1"
                                  autoComplete="off"
                                />
                                {this.state.errors.template_name ? (
                                  <div className="text-danger">
                                    {this.state.errors.template_name}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "email template name",
                                    this.state.template_name,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                <label className="form-label text-left pd-b-5">
                                  From{" "}
                                </label>
                                <div className="col-md-8 pd-lg-l-0 pd-xs-l-0 pd-xs-r-0">
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="from"
                                    value={this.state.from}
                                    maxLength="50"
                                    //tabIndex="1"
                                    autoComplete="off"
                                  /></div>
                                <label>@orionhaus.com</label>
                                {this.state.errors.from ? (
                                  <div className="text-danger">
                                    {this.state.errors.from}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "from email",
                                    this.state.from,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-0 pd-lg-r-30">
                              <label className="form-label text-left pd-b-5">
                                Template Description{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                name="description"
                                value={this.state.description}
                                className="form-control max_width_100"
                                id="description"
                                as="textarea"
                                rows='3'
                              // placeholder="Username"
                              />
                            </div>
                            <div className="row">


                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                <label className="form-label text-left pd-b-5">
                                  To{" "}
                                </label>
                                <Select
                                  styles={customStyles}
                                  isClearable={true}
                                  className="multiselect"
                                  menuPlacement="auto"
                                  value={this.state.selectedTypeList}
                                  options={this.state.typeList}
                                  onChange={(value) =>
                                    this.handleChangeUserList(value)
                                  }
                                  defaultValue={
                                    this.state.selectedTypeList
                                  }
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />
                                {this.state.errors.to ? (
                                  <div className="text-danger">
                                    {this.state.errors.to}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "to",
                                    this.state.selectedTypeList,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}
                              </div>

                              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                {this.state.usertotype === 3 || this.state.to == 3 ? (
                                  <div className="col-12 p-0">
                                    <label className="form-label text-left pd-b-5">
                                      Staff Users {" "}
                                    </label>
                                    <Select
                                      styles={customStyles}
                                      menuPlacement="auto"
                                      className="multiselect"
                                      value={this.state.selectedStaffToList}
                                      options={this.state.newStaffList}
                                      getOptionValue={(option) => `${option.label}`}
                                      onChange={(value) =>
                                        this.handleChangeStaffToList(value)
                                      }
                                      defaultValue={
                                        this.state.selectedStaffToList
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />
                                  </div>) : ""}
                                {this.state.usertotype === 7 || this.state.to == 7 ? (
                                  <div className="col-12 p-0">
                                    <label className="form-label text-left pd-b-5">
                                      To (Custom Email)
                                    </label>
                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="email_to_id"
                                      value={this.state.email_to_id}
                                      maxLength="50"
                                      //tabIndex="1"
                                      autoComplete="off"
                                    />
                                    {this.state.errors.email_to_id ? (
                                      <div className="text-danger">
                                        {this.state.errors.email_to_id}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "Custom email",
                                        this.state.email_to_id,
                                        "email",
                                        { className: "text-danger" }
                                      )
                                    )}

                                  </div>) : ""}
                              </div>

                            </div>


                            <div className="row">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                <label className="form-label text-left pd-b-5">
                                  Cc {" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <Select
                                  styles={customStyles}
                                  isClearable={true}
                                  className="multiselect"
                                  menuPlacement="auto"
                                  value={this.state.selectedCcList}
                                  options={this.state.ccforList}
                                  onChange={(value) =>
                                    this.handleChangeCCList(value)
                                  }
                                  defaultValue={
                                    this.state.selectedCcList
                                  }
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                {this.state.getvalueofcc === 3 || this.state.cc_id == 3 ? (
                                  <div className="col-12 p-0">
                                    <label className="form-label text-left pd-b-5">
                                      Cc (Staff Users) {" "}
                                      <span className="optional">(Optional)</span>
                                    </label>
                                    <Select
                                      isMulti
                                      styles={customStyles}
                                      isClearable={true}
                                      menuPlacement="auto"
                                      className="multiselect"
                                      value={this.state.selectedStaffList}
                                      options={this.state.newStaffList}
                                      getOptionValue={(option) => `${option.label}`}
                                      onChange={(value) =>
                                        this.handleChangeStaffList(value)
                                      }
                                      defaultValue={
                                        this.state.selectedStaffList
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />
                                  </div>)
                                  : this.state.getvalueofcc === 2 || this.state.cc_id == 2 ? (
                                    <div className="col-12 p-0">
                                      <label className="form-label text-left pd-b-5">
                                        Cc (Custom Email)
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="cc"
                                        value={this.state.cc}
                                        maxLength="50"
                                        //tabIndex="1"
                                        autoComplete="off"
                                      />
                                      {this.state.errors.cc ? (
                                        <div className="text-danger">
                                          {this.state.errors.cc}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "Custom email",
                                          this.state.cc,
                                          "email",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                  ) : ''}
                              </div>

                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                <label className="form-label text-left pd-b-5">
                                  Bcc {" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <Select
                                  styles={customStyles}
                                  className="multiselect"
                                  menuPlacement="auto"
                                  value={this.state.selectedsecondlist}
                                  options={this.state.BccforList}
                                  onChange={(value) =>
                                    this.handleChangeBCCList(value)
                                  }
                                  defaultValue={
                                    this.state.selectedsecondlist
                                  }
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                {this.state.getvalueofbcc === 3 || this.state.bcc_id == 3 ? (
                                  <div className="col-12 p-0">
                                    <label className="form-label text-left pd-b-5">
                                      Bcc (Staff Users) {" "}
                                      <span className="optional">(Optional)</span>
                                    </label>
                                    <Select
                                      isMulti
                                      styles={customStyles}
                                      menuPlacement="auto"
                                      className="multiselect"
                                      value={this.state.selectedBccStaffList}
                                      options={this.state.newStaffList}
                                      getOptionValue={(option) => `${option.label}`}
                                      onChange={(value) =>
                                        this.handleChangeBccStaffList(value)
                                      }
                                      defaultValue={
                                        this.state.selectedBccStaffList
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />
                                  </div>)
                                  : this.state.getvalueofbcc === 2 || this.state.bcc_id == 2 ? (
                                    <div className="col-12 p-0">
                                      <label className="form-label text-left pd-b-5">
                                        Bcc (Custom Email)
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="bcc"
                                        value={this.state.bcc}
                                        maxLength="50"
                                        //tabIndex="1"
                                        autoComplete="off"
                                      />
                                      {this.state.errors.bcc ? (
                                        <div className="text-danger">
                                          {this.state.errors.bcc}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "Bcc Custom email",
                                          this.state.bcc,
                                          "email",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                  ) : ''}
                              </div>
                            </div>


                            {/* </div> */}
                            {/* <div className="row align-items-center pd-b-30 pd-xs-b-0 pd-lg-b-30 pd-l-30 pd-r-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30"> */}
                            <div className="row">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-5 col-xxl-5"></div>
                              <div className="col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mg-t-5 mg-md-t-0 mg-lg-b-0 mg-xs-b-15  pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 d-flex flex-wrap- justify-content-between align-items-center">
                                <div className="w-100 pd-lg-r-15">
                                  <label className="form-label text-left pd-b-5">
                                    Subject Variable{" "}
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    menuPlacement="auto"
                                    value={this.state.selectedSubjectList}
                                    options={this.state.subjectVariableList}
                                    onChange={(value) =>
                                      this.handleChangeSubjectVariableList(value)
                                    }
                                    defaultValue={
                                      this.state.selectedSubjectList
                                    }
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />
                                </div>

                                <button
                                  type="button"
                                  className="btn-success-outline-small mg-t-cust-23"
                                  onClick={this.insertsubject}
                                  disabled={this.state.isSubmit ? true : false}
                                >
                                  insert
                                </button>
                              </div>

                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                <label className="form-label text-left pd-b-5">
                                  Subject{" "}
                                  {/* <span className="optional">(Optional)</span> */}
                                </label>
                                <Form.Control
                                  onChange={this.changeHandler}
                                  type="text"
                                  name="subject"
                                  value={this.state.subject}
                                  autoComplete="off"
                                  id="subjecttext"
                                />
                                {this.state.errors.subject ? (
                                  <div className="text-danger">
                                    {this.state.errors.subject}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "subject",
                                    this.state.subject,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}

                              </div>
                            </div>

                            {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Module{" "}
                              </label>
                              <Select
                                styles={customStyles}
                                isClearable={true}
                                className="multiselect"
                                menuPlacement="auto"
                                value={this.state.selectedModulleList}
                                options={this.state.moduleList}
                                onChange={(value) =>
                                  this.handleChangeModuleList(value)
                                }
                                defaultValue={
                                  this.state.selectedModulleList
                                }
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                             
                            </div> */}
                            <div className="row">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-5 col-xxl-5"></div>
                              <div className="col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mg-t-5 mg-md-t-0 mg-lg-b-0 mg-xs-b-15  pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 d-flex flex-wrap- justify-content-between align-items-center">
                                <div className="w-100 pd-lg-r-15">
                                  <label className="form-label text-left pd-b-5">
                                    Body Variable{" "}
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    menuPlacement="auto"
                                    value={this.state.selectedModulleRferList}
                                    options={this.state.moduleTypeList}
                                    onChange={(value) =>
                                      this.handleChangemodulereferList(value)
                                    }
                                    defaultValue={
                                      this.state.selectedModulleRferList
                                    }
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="btn-success-outline-small mg-t-cust-23"
                                  onClick={this.insertbody}
                                  disabled={this.state.isSubmit ? true : false}
                                >
                                  insert
                                </button>
                              </div>
                            </div>
                            <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 jodit_cnt_box">
                                <label className="form-label text-left pd-b-5">
                                  Body{" "}
                                </label>
                                {/* <CKEditor
                                  id="textarea_email_body"
                                  editor={ClassicEditor}
                                  ref={this.myRef}
                                  onInit={editor => {
                                    console.log('Editor is ready to use!', editor);
                                  }}
                                  config={{
                                    removePlugins: [
                                      "EasyImage",
                                      "ImageUpload",
                                      "MediaEmbed",
                                      "Table",
                                    ],
                                    // plugins: [
                                    //   SourceEditing
                                    // ],
                                    // builtinPlugins: [
                                    //     "CodeBlock"
                                    // ],
                                    // toolbar: [...'SourceEditing'],
                                    // codeBlock: {
                                    //     languages: [
                                    //         { language: 'css', label: 'CSS' },
                                    //         { language: 'html', label: 'HTML' }
                                    //     ]
                                    // }
                                  }}
                                  name="body"
                                  minHeight="50px"
                                  data={this.state.body}
                                  onReady={(editor) => {
                                    editor.editing.view.change((writer) => {
                                      writer.setStyle(
                                        "height",
                                        "300px",
                                        editor.editing.view.document.getRoot()
                                      );
                                    });
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.changeHandlerck({
                                      target: {
                                        type: "ckeditor",
                                        name: "body",
                                        value: data,
                                      },
                                    });
                                  }}
                                /> */}
                                <JoditEditor
                                  config={this.editorConfig}
                                  name="body"
                                  id="body"
                                  value={this.state.body}
                                  // config={readonly:false}
                                  // tabIndex={1} // tabIndex of textarea
                                  onBlur={newContent => this.setContent(newContent)}
                                  onChange={newContent => this.setContent(newContent)}
                                />

                                {this.state.errors.body ? (
                                  <div className="text-danger">
                                    {this.state.errors.body}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "body",
                                    this.state.body,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}

                              </div>
                            </div>
                            {/* </div> */}
                            {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-30 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <div className="documnet-section-upload">
                              <label className="form-label text-left pd-b-5">
                                Upload File{" "}
                              </label>

                              <div className="d-flex flex-row align-items-center upload upload-files-section">
                                <FileWidget
                                  fieldName="file_name"
                                  fieldTitle="Select File"
                                  checkValidation="true"
                                  allowedType="image/png,image/jpeg,image/jpg,text/csv,application/*,doc,docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                                  maxFileSize={30}
                                  maxFileSizeType="mb"
                                  maxFileAllowed={10}
                                  uploadOn="s3"
                                  slug={this.state.property_slug}
                                  refTableId="3"
                                  displayFileName={this.state.display_file_name}
                                  documentTypeSlug={this.state.document_type_slug}
                                  // description={this.state.description}
                                  mediaType="2"
                                  sortOrder={
                                    this.state.sort_order !== ""
                                      ? this.state.sort_order
                                      : 0
                                  }
                                  statusId={this.state.status_id === true ? 1 : 2}
                                  helpText="to download sample file."
                                  sampleFileUrl="/sample.jpg"
                                  drag="true"
                                  dragText="Select or drop file size upto 30 MB"
                                  inputClassName="form-control"
                                  labelClassName="form-label"
                                  formID="property_document_form"
                                  uploadType="multiple"
                                  resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
                                  propertySlug={this.state.property_slug}
                                  destinationFolder="properties"
                                  uploadedFiles={this.state.profile_image2}
                                  deletedFiles={this.state.deletedFileList}
                                  acceptOnly="image/png,image/jpeg,image/jpg,text/csv,application/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                                  displayPreviewStyle="horizontal"
                                />
                              </div>
                            </div> */}
                            {/* </div> */}
                            {/* </div> */}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-8 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                              <div className="col-md-1 pl-0">
                                <label className="form-label text-left pd-b-5">
                                  Status
                                </label>
                              </div>

                              <div className="col-8 col-md-7 col-lg-7 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                                <b
                                  className={
                                    this.state.status_id === true ? "active" : "inactive"
                                  }
                                ></b>
                                <div
                                  className={
                                    this.state.status_id === true
                                      ? "az-toggle on"
                                      : "az-toggle"
                                  }
                                  onClick={() => this.changeStatus()}
                                >
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main >
    );
  }
}
export default EmailForm;
