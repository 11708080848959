////////////////////////////////////////////////////////////
//     							                           //
//  Program: Staff.jsx                                    //
//  Application: Staff                                    //
//  Option: List all Staff                                //
//  Developer: Ashish Kumar,NP 						                //
//  Date: 2022-02-13                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import StaffTypeService from "../../services/StaffTypeService";
import PropertyStaffService from "../../services/PropertyStaffService";
import modaldeleteicon from "./../../assets/images/delete.svg";
import NoData from "../common/NoData";
import PerfectScrollbar from "react-perfect-scrollbar";
import staffImg from "./../../assets/images/profile-nogender.png";
import pluswIcon from "./../../assets/images/plus.svg";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import deleteicon from "../../assets/images/delete.svg";
import PhoneNumberFormat from "../common/PhoneNumberFormat";
import ContactSidebar from "../common/ContactSidebar";
import Alerts from "../common/Alerts";

export class Staff extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      staffEventKey: "",
      is_inactive: false,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      pathname: window.location.pathname.includes("first"),
      staffPropList: [],
      staffPropListTotal: 0,
      staffTypeList: [],
      defaultStaff: "",
      selectedStaffTypeList: [],
      staffTypeListTotal: 0,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      staffData: 0,
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.deletePropStaff = this.deletePropStaff.bind(this);
    this.opendeletePropStaffModal = this.opendeletePropStaffModal.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.setStaffOpen = this.setStaffOpen.bind(this);
  }

  componentDidMount() {
    this.setState({ staffData: 0 });
    this.getAllStaffDetails();
  }

  async getAllStaffDetails() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.pathname === true) {
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" + this.state.property_slug + "/staff"
          ),
        global.redirect_time
      );
    }

    var staffPropList = [];
    var staffPropListTotal = 0;
    var defaultStaff = "";
    let res = await PropertyStaffService.getPropertyStaff(
      "property_slug=" + this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      staffPropList = res.data
        ? res.data.data.sort((a, b) =>
            a.staff_contact_name.localeCompare(b.staff_contact_name)
          )
        : [];
      staffPropListTotal = staffPropList.length;
      defaultStaff = staffPropListTotal > 0 ? staffPropList[0].slug : "";
    }
    const groupedByLetter = staffPropList.reduce((groups, contact) => {
      const letter = contact.staff_contact_name[0].toUpperCase();
      const group = groups[letter] || [];
      group.push(contact);
      groups[letter] = group;
      return groups;
    }, {});
    const groupedstaffPropList = Object.entries(groupedByLetter);
    this.setState({
      showSpinner: false,
      loading: false,
      staffPropList: staffPropList,
      staffPropListTotal: staffPropListTotal,
      defaultStaff: defaultStaff,
      groupedstaffPropList: groupedstaffPropList,
    });
    this.setState({ showSpinner: false, loading: false });
    if (staffPropListTotal > 0) {
      if (this.state.slug !== undefined && this.state.slug !== "") {
        this.getStaffDetails(this.state.slug);
      } else {
        this.getStaffDetails(this.state.defaultStaff);
      }
      this.setState({ staffData: 1 });
    }
  }

  async getStaffDetails(slug) {
    this.setState({ showSpinner: true, loading: true });

    var resData = {};
    var StaffDetailsTotal = [];
    let res = await PropertyStaffService.getPropertyStaffBySlug(slug);
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
    }
    this.setState(resData);
    this.setState({ StaffDetailsTotal: StaffDetailsTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  async getPropertyStaffType() {
    this.setState({ showSpinner: true, loading: true });
    var selectedStaffTypeList = [];
    let res = await PropertyStaffService.getPropertyStaffType(
      this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      selectedStaffTypeList = res.data ? res.data : [];
    }
    this.setState({ selectedStaffTypeList: selectedStaffTypeList });
  }

  async getStaffContactType() {
    this.setState({ showSpinner: true, loading: true });
    var staffTypeList = [];
    var staffTypeListTotal = 0;
    let res = await StaffTypeService.getStaffType("is_dropdown=1");
    if (global.successStatus.includes(res.status)) {
      staffTypeList = res.data ? res.data : [];
      staffTypeListTotal = staffTypeList.length;
    }

    this.setState({
      showSpinner: false,
      loading: false,
      staffTypeList: staffTypeList,
      staffTypeListTotal: staffTypeListTotal,
    });
  }

  // async getPropStaffByContactType(typeSlug) {
  //   this.setState({ showSpinner: true, loading: true });
  //   var staffPropList = [];
  //   var staffPropListTotal = 0;
  //   let res = await PropertyStaffService.getPropertyStaff(
  //     "is_dropdown=1&staff_contact_type_slug=" + typeSlug
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     staffPropList = res.data ? res.data : [];
  //     staffPropListTotal = staffPropList.length;
  //   }
  //   this.setState({
  //     showSpinner: false,
  //     loading: false,
  //     staffPropList: staffPropList,
  //     staffPropListTotal: staffPropListTotal,
  //   });
  // }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  opendeletePropStaffModal(slug) {
    this.setState({ deletePropStaffModal: true, slug: slug });
  }

  closedeletePropStaffModal() {
    this.setState({ deletePropStaffModal: false, slug: "" });
  }

  async deletePropStaff(slug) {
    this.setState({ showSpinner: true });
    let res = await PropertyStaffService.deletePropertyStaff(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeletePropStaffModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.setState({ staffData: 0, slug: "" });
      this.getAllStaffDetails();
    } else {
      this.closedeletePropStaffModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  selectStaff = (i, slug) => {
    this.getStaffDetails(slug);
    this.setState({ staffData: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Contacts";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  setStaffOpen(e) {
    this.setState({ staffEventKey: e.target.id });
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <ContactSidebar property_slug={this.state.property_slug} />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {global.userPermissions.checkPermission("staff-add") && (
                        <Link to={"staff/add"} className="mg-l-2">
                          <button className="btn-success-outline-small ">
                            <img src={pluswIcon} alt="" /> Add
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-45 pd-lg-r-30">
                  <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 p-10 mg-t-20">
                    <div>
                      <div className="container row d-flex app-contacts az-content-app az-content-contacts p-0">
                        <div className="az-content-left az-content-left-contacts pd-lg-l-0 pd-xs-l-15 side_top_navtab">
                          {/* <div className="d-flex align-items-center justify-content-between mg-b-30">

                              <Link
                                to={"staff/add"}
                                className="w-100 d-flex justify-content-end pd-l-30 pd-r-30 pd-t-0 pd-b-0 mg-t-0 mg-b-0"
                              >
                                <button className="btn-success-outline-small ">
                                  <img src={pluswIcon} alt="" /> Add
                                </button>
                              </Link>
                            </div> */}
                          {global.userPermissions.checkPermission(
                            "residents-list"
                          ) ||
                          global.userPermissions.checkPermission(
                            "vendors-list"
                          ) ||
                          global.userPermissions.checkPermission(
                            "staff-list"
                          ) ? (
                            <nav className="nav az-nav-line az-nav-line-chat">
                              <Link
                                to={
                                  "/properties/view/" +
                                  this.state.property_slug +
                                  "/staff"
                                }
                                data-toggle="tab"
                                className="nav-link active"
                              >
                                Staff
                              </Link>

                              <Link
                                to={
                                  "/properties/view/" +
                                  this.state.property_slug +
                                  "/vendor"
                                }
                                data-toggle="tab"
                                className="nav-link"
                              >
                                Vendor
                              </Link>
                              <Link
                                to={
                                  "/properties/view/" +
                                  this.state.property_slug +
                                  "/crm-resident"
                                }
                                data-toggle="tab"
                                className="nav-link"
                              >
                                Resident
                              </Link>
                              {/* <Link  to={"/properties/view/" + this.state.property_slug + "/vendor"} data-toggle="tab" className="nav-link">Guests</Link>
                              <Link  to={"/properties/view/" + this.state.property_slug + "/vendor"} data-toggle="tab" className="nav-link">Residents</Link> */}
                            </nav>
                          ) : (
                            ""
                          )}
                          <PerfectScrollbar
                            id="azContactList"
                            className="az-contacts-list h-auto"
                          >
                            {this.state.staffPropListTotal > 0
                              ? this.state.groupedstaffPropList
                                  .sort()
                                  .map((item, i) => {
                                    return (
                                      <React.Fragment key={i}>
                                        <div className="az-contact-label">
                                          {item[0]}
                                        </div>
                                        {item[1]
                                          .sort((a, b) =>
                                            a.staff_contact_name.localeCompare(
                                              b.staff_contact_name
                                            )
                                          )
                                          .map((items, index) => {
                                            return (
                                              <React.Fragment key={index}>
                                                <div
                                                  className={
                                                    this.state.slug ===
                                                    items.slug
                                                      ? "az-contact-item selected"
                                                      : "az-contact-item"
                                                  }
                                                  onClick={() =>
                                                    this.selectStaff(
                                                      index,
                                                      items.slug
                                                    )
                                                  }
                                                >
                                                  <div
                                                    key={i}
                                                    className="az-img-user online"
                                                  >
                                                    <img
                                                      src={
                                                        items.staff_contact_image_thumb_url
                                                          ? items.staff_contact_image_thumb_url
                                                          : staffImg
                                                      }
                                                      alt=""
                                                      onError={
                                                        "this.onerror=null;this.src='" +
                                                        staffImg +
                                                        "';"
                                                      }
                                                    />
                                                  </div>
                                                  <div className="az-contact-body">
                                                    <h6>
                                                      {items.staff_contact_name
                                                        ? items.staff_contact_name
                                                        : ""}
                                                    </h6>
                                                    <p className="tx-xthin mg-b-0">
                                                      {items.staff_contact_type
                                                        ? items
                                                            .staff_contact_type
                                                            .staff_contact_type_name
                                                        : ""}
                                                    </p>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          })}
                                      </React.Fragment>
                                    );
                                  })
                              : ""}
                          </PerfectScrollbar>
                          {/* az-contacts-list */}
                        </div>
                        {/* az-content-left */}
                        {this.state.staffData === 1 ? (
                          <div className="az-content-body az-content-body-contacts nav_tab_cnt pd-xs-l-0">
                            {/* az-contact-info-header */}
                            <div className="az-contact-info-header mg-t-50 border-bottom-0 pd-lg-l-30 pd-xs-l-15">
                              <div className="media">
                                <div className="az-img-user">
                                  <img
                                    src={
                                      this.state.staff_contact_image_thumb_url
                                        ? this.state
                                            .staff_contact_image_thumb_url
                                        : staffImg
                                    }
                                    alt=""
                                    onError={
                                      "this.onerror=null;this.src='" +
                                      staffImg +
                                      "';"
                                    }
                                  />
                                </div>
                                <div className="media-body pd-lg-l-15 pd-xs-l-0">
                                  <h4>
                                    {this.state.staff_contact_name
                                      ? this.state.staff_contact_name
                                      : ""}
                                  </h4>
                                  <p className="media-body mb-0">
                                    {this.state.staff_contact_type
                                      ? this.state.staff_contact_type
                                          .staff_contact_type_name
                                      : ""}
                                  </p>
                                </div>

                                <div className="az-contact-action">
                                  <div className=" d-flex flex-wrap justify-content-end bothBtn align-items-center">
                                    {global.userPermissions.checkPermission(
                                      "staff-delete"
                                    ) && (
                                      <span
                                        onClick={() => {
                                          this.opendeletePropStaffModal(
                                            this.state.slug
                                          );
                                        }}
                                        className="deleteicon mg-r-20 hand-cursor"
                                      >
                                        <img src={deleteicon} alt="Delete" />
                                      </span>
                                    )}
                                    <Link
                                      to={
                                        "/properties/view/" +
                                        this.state.property_slug +
                                        "/staff/update/" +
                                        this.state.slug
                                      }
                                      className="mg-l-2"
                                    >
                                      {global.userPermissions.checkPermission(
                                        "staff-update"
                                      ) && (
                                        <button className="btn-success-outline">
                                          <img src={editIcon} alt="Edit" />
                                          Edit
                                        </button>
                                      )}
                                    </Link>
                                  </div>
                                </div>
                              </div>

                              {/* media */}

                              {/* az-contact-action */}
                            </div>
                            <div className="az-contact-info-body">
                              <PerfectScrollbar className="media-list ">
                                <div className="media">
                                  <div className="media-body">
                                    <div>
                                      <label>Job Title</label>
                                      <span className="tx-medium">
                                        {this.state.job_title
                                          ? this.state.job_title
                                          : ""}
                                      </span>
                                    </div>
                                    <div>
                                      <label>Email</label>
                                      <span className="tx-medium">
                                        {this.state.staff_contact_email
                                          ? this.state.staff_contact_email
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  {/* media-body */}
                                </div>

                                <div className="media">
                                  <div className="media-body">
                                    <div>
                                      <label>Extension</label>
                                      <span className="tx-medium">
                                        {this.state.staff_contact_extension
                                          ? this.state.staff_contact_extension
                                          : ""}
                                      </span>
                                    </div>
                                    <div>
                                      <label>Phone</label>
                                      <span className="tx-medium">
                                        {this.state.staff_contact_number ? (
                                          <PhoneNumberFormat
                                            phone_number={
                                              this.state.staff_contact_number
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  {/* media-body */}
                                </div>

                                <div className="media">
                                  <div className="media-body">
                                    <div>
                                      <label>Reporting Manager</label>
                                      <span className="tx-medium">
                                        {this.state.reportingManagerType
                                          ? this.state.reportingManagerType
                                              .staff_contact_name
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  {/* media-body */}
                                </div>
                                {/* media */}
                              </PerfectScrollbar>
                              {/* media-list */}
                            </div>

                            {/* az-contact-info-body */}
                          </div>
                        ) : (
                          <div className="m-auto">
                            <NoData
                              msg={global.commonMessages.checkMessage(
                                "CRM_STAFF"
                              )}
                            />
                          </div>
                        )}
                        {/* az-content-body */}
                      </div>
                    </div>
                    {/* ) : (
                      <>
                        <Link
                          to={"staff/add"}
                          className="w-100 d-flex justify-content-end pd-l-30 pd-r-30 pd-t-20 pd-b-20 mg-t-4 mg-b-4"
                        >
                          <button className="btn-success-outline-small ">
                            <img src={pluswIcon} alt="" /> Add
                          </button>
                        </Link>
                        <NoData msg="staffs" />{" "}
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Modal show={this.state.deletePropStaffModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeletePropStaffModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deletePropStaff(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default Staff;
