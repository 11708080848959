////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ListingCalendar.jsx                            //
//  Application: Calendar                                 //
//  Option: List all reservations                         //
//  Developer: NP  						                  //
//  Date: 2022-07-29                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React from "react";
import { useCallback, useState } from "react";
import CalendarService from "../../services/CalendarService";
import copyIcon from "./../../assets/images/copy.png";
// import AirbnbLogo from "../../assets/images/airbnb_logo.png"
// import { Eventcalendar, Popup, formatDate } from '@mobiscroll/react';
import {
  Eventcalendar,
  setOptions,
  CalendarNav,
  SegmentedGroup,
  SegmentedItem,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
  Popup,
} from "@mobiscroll/react";
import { Link } from "react-router-dom";
import { Container, Button, Popover, OverlayTrigger } from "react-bootstrap";
import LisitingSidebar from "../common/LisitingSidebar";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyLisitingSidebar from "../common/AllPropertyLisitingSidebar";

setOptions({
  theme: "ios",
  themeVariant: "light",
  dateFormatLong: "MM/DD/YY",
});

const ListingCalendar = (props) => {
  const [view, setView] = React.useState("month");
  const [calView, setCalView] = React.useState({
    timeline: {
      type: "month",
    },
  });

  const [is_copied, setIsCopied] = useState(true);
  const [event, setEvent] = useState({});
  const [events, myEvents] = useState([]);
  const [resources, myResources] = useState([]);
  // const [unavailableDates, setUnavailableDates] = React.useState([]);
  const [availableDates, setAvailableDates] = React.useState([]);
  const [isPopupOpen, setOpen] = useState(false);
  // const property_slug = props.match.params.propertySlug;
  const [property_slug, setPropertySlug] = useState(
    props.match.params.propertySlug !== undefined
      ? props.match.params.propertySlug
      : localStorage.getItem("outSidePorpertySlug")
      ? localStorage.getItem("outSidePorpertySlug")
      : ""
  );
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");

  const onPageLoading = React.useCallback((event, inst) => {
    const year = event.month.getFullYear();
    const month = event.month.getMonth();
    if (props.match.params.propertySlug !== undefined) {
      setPropertySlug(props.match.params.propertySlug);
      setTimeout(() => {
        getReservationdata(props.match.params.propertySlug, year, month);
      }, 200);
    } else {
      const propSlug = localStorage.getItem("outSidePorpertySlug")
        ? localStorage.getItem("outSidePorpertySlug")
        : "";
      setPropertySlug(propSlug);
      setTimeout(() => {
        getReservationdata(
          localStorage.getItem("outSidePorpertySlug")
            ? localStorage.getItem("outSidePorpertySlug")
            : "",
          year,
          month
        );
      }, 200);
    }
  }, []);

  const getReservationdata = async (property_slug, year, month) => {
    // setPropertySlug(property_slug);
    setYear(year);
    setMonth(month);
    let res = await CalendarService.getReservations(
      "all?calendar=listing&year=" +
        year +
        "&month=" +
        (month + 1) +
        "&property_slug=" +
        (property_slug ? property_slug : "")
    );

    if (global.successStatus.includes(res.status)) {
      // setUnavailableDates(res.data.unavailableDatesData ? res.data.unavailableDatesData : []);
      setAvailableDates(
        res.data.availableDatesData ? res.data.availableDatesData : []
      );
      myEvents([
        ...res.data.reservationData,
        ...res.data.unavailableDatesData,
        ...res.data.pricingData,
      ]);
      myResources(res.data.unitData ? res.data.unitData : []);
    }
  };

  //event pills
  const myScheduleEvent = React.useCallback((data) => {
    const ev = data.original;
    const color = data.original.color;
    // const color = "#2c5046";
    return (
      <>
        {ev.title && ev.title !== null && ev.reservation_slug ? (
          <div
            className="md-timeline-template-event"
            style={{ borderColor: color, background: color }}
          >
            <div className="md-timeline-template-event-cont md-meeting-planner-wrapper">
              {/* <span className={"mbsc-icon mbsc-font-icon mbsc-icon-airplane"} style={{ background: color }}><img src={ev.img} alt="logo" /></span> */}
              <span className="md-timeline-template-title md-meeting-planner-title">
                {ev.title}
              </span>
            </div>
          </div>
        ) : ev.title && ev.title !== null && ev.title === "Resident Stay" ? (
          <div
            className="md-timeline-template-event"
            style={{ borderColor: color, background: color }}
          >
            <div className="md-timeline-template-event-cont md-meeting-planner-wrapper">
              {/* <span className={"mbsc-icon mbsc-font-icon mbsc-icon-airplane"} style={{ background: color }}><img src={ev.img} alt="logo" /></span> */}
              <span className="md-timeline-template-title md-meeting-planner-title">
                {ev.title} - {ev.resident_access_code}
              </span>
            </div>
          </div>
        ) : (
          <div className="">
            <div className="">
              <span className="md-timeline-template-title">{ev.title}</span>
            </div>
          </div>
        )}
      </>
    );
  }, []);

  //onclick of pill
  const openPopup = useCallback((event) => {
    if (
      event &&
      event.event &&
      event.event.title !== "" &&
      !event.event.title.includes("$")
    ) {
      setOpen(true);
      setEvent(event);
    }
  }, []);

  const closePopup = useCallback(() => {
    setOpen(false);
  }, []);

  const copyText = useCallback((confirmation_code) => {
    navigator.clipboard.writeText(confirmation_code);
    setTimeout(() => setIsCopied(false), 1000);
    setTimeout(() => setIsCopied(true), 1000);
  }, []);

  //unit and listing name on left
  const renderCustomResource = (resource) => {
    return (
      <div className="md-resource-header-template-cont" title={resource.name}>
        <div className="md-resource-header-template-name">
          {resource.unit_name_new ? resource.unit_name_new : resource.unit_name}
        </div>
        <div className="md-resource-header-template-name">
          {resource.resident_name}
        </div>
        <div className="md-resource-header-template-name">
          {resource.listing_name}
        </div>
      </div>
    );
  };

  //day week month view
  const renderMyHeader = () => {
    return (
      <React.Fragment>
        <CalendarPrev className="md-event-listing-prev" />
        <CalendarNav className="md-event-listing-nav" />
        <CalendarNext className="md-event-listing-next" />
        <div className="md-event-listing-picker">
          <SegmentedGroup value={view} onChange={changeView}>
            <SegmentedItem value="day">Day</SegmentedItem>
            <SegmentedItem value="week">Week</SegmentedItem>
            <SegmentedItem value="month">Month</SegmentedItem>
          </SegmentedGroup>
        </div>
        {/* <CalendarPrev className="md-event-listing-prev" /> */}
        <CalendarToday className="md-event-listing-today mr-4" />
        {/* <CalendarNext className="md-event-listing-next" /> */}
      </React.Fragment>
    );
  };

  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "day":
        calView = {
          timeline: {
            type: "day",
          },
        };
        break;
      case "week":
        calView = {
          timeline: {
            type: "week",
          },
        };
        break;
      case "month":
      default:
        calView = {
          timeline: {
            type: "month",
          },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };
  const getPropertySlugData = async (property_slug) => {
    setPropertySlug(property_slug);
    getReservationdata(property_slug, year, month);
  };
  return (
    <>
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <LisitingSidebar property_slug={property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyLisitingSidebar property_slug={property_slug} />
                  </div>
                </div>
              )}
              <div className="col-md-10 right">
                {/* <div className="col-md-12"> */}
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3 className="mb-0">Listings Calendar</h3>
                  </div>
                </div>

                {window.location.pathname.includes("global-list-calendar") ? (
                  <div className="col-xxl-2 col-sm-3 pd-r-0 pd-lg-l-45 pd-xs-l-15 mg-b-45">
                    <OutSidePropertySelectBox
                      propertySlug={getPropertySlugData}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-45 pd-lg-r-30">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="calendar-section pd-lg-l-0 pd-xs-l-15">
                      <Eventcalendar
                        theme="ios"
                        themeVariant="light"
                        clickToCreate={false}
                        dragToCreate={false}
                        dragToMove={false}
                        dragToResize={false}
                        view={calView}
                        data={events}
                        resources={resources}
                        renderScheduleEvent={myScheduleEvent}
                        cssClass="md-timeline-template md-event-listing"
                        onEventClick={(event) => openPopup(event)}
                        showEventTooltip={false}
                        // onEventHoverIn={onEventHoverIn}
                        // onEventHoverOut={onEventHoverOut}
                        renderResource={renderCustomResource}
                        renderHeader={renderMyHeader}
                        colors={availableDates}
                        onPageLoading={onPageLoading}
                      />
                      <Popup
                        display="center"
                        // fullScreen={true}
                        contentPadding={true}
                        headerText={
                          event && event.event && event.event.title
                            ? event.event.title
                            : ""
                        }
                        isOpen={isPopupOpen}
                        onClose={closePopup}
                      >
                        <div className="text-center popup">
                          <span
                            className="popupClose hand-cursor"
                            onClick={closePopup}
                          >
                            {global.closee}
                          </span>
                          {event &&
                          event.event &&
                          event.event.reservation_slug ? (
                            <>
                              {/* reservation popup start */}
                              <div className="calendar_event_copy">
                                <p className="az-profile-name-text pl-0">
                                  Reservation code{" "}
                                </p>{" "}
                                <p className="media-body">
                                  {" "}
                                  {event &&
                                  event.event &&
                                  event.event.confirmation_code
                                    ? event.event.confirmation_code
                                    : " "}
                                  <span>
                                    {is_copied ? (
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="top"
                                        overlay={
                                          <Popover
                                            id="popover-basic"
                                            className="popover-primary copide_box"
                                          >
                                            <Popover.Title
                                              as="h3"
                                              className="p-0"
                                            >
                                              Copied
                                            </Popover.Title>
                                          </Popover>
                                        }
                                      >
                                        <Button className="btn btn-copy_bg">
                                          <img
                                            src={copyIcon}
                                            alt="Copy"
                                            onClick={() =>
                                              copyText(
                                                event.event.confirmation_code
                                              )
                                            }
                                            className="copyClass"
                                          />
                                        </Button>
                                      </OverlayTrigger>
                                    ) : (
                                      <Button className="btn btn-copy_bg">
                                        <img
                                          src={copyIcon}
                                          alt="copy"
                                          className="copyClass"
                                          onClick={() =>
                                            copyText(
                                              event.event.confirmation_code
                                            )
                                          }
                                          // onClick={() => {
                                          //   navigator.clipboard.writeText(
                                          //     event.event.confirmation_code
                                          //   );
                                          // }}
                                        />
                                      </Button>
                                    )}
                                  </span>
                                </p>{" "}
                              </div>
                              <p className="az-profile-name-text pl-0">
                                Check-in date & time{" "}
                              </p>{" "}
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.view_start
                                  ? event.event.view_start
                                  : ""}
                              </p>
                              <p className="az-profile-name-text pl-0">
                                Check-out date & time{" "}
                              </p>
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.view_end
                                  ? event.event.view_end
                                  : ""}
                              </p>
                              <p className="az-profile-name-text pl-0">
                                No. of guests
                              </p>{" "}
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.guest
                                  ? event.event.guest
                                  : ""}
                              </p>
                              {window.location.pathname.includes(
                                "global-list-calendar"
                              ) ? (
                                <Link
                                  to={
                                    "/reservations/view/" +
                                    (property_slug
                                      ? property_slug
                                      : event &&
                                        event.event &&
                                        event.event.property_slug
                                      ? event.event.property_slug
                                      : "") +
                                    "/" +
                                    (event &&
                                    event.event &&
                                    event.event.reservation_slug
                                      ? event.event.reservation_slug
                                      : "")
                                  }
                                >
                                  <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                                    View
                                  </button>
                                </Link>
                              ) : (
                                <Link
                                  to={
                                    "/properties/view/" +
                                    (property_slug
                                      ? property_slug
                                      : event &&
                                        event.event &&
                                        event.event.property_slug
                                      ? event.event.property_slug
                                      : "") +
                                    "/reservations/view/" +
                                    (event &&
                                    event.event &&
                                    event.event.reservation_slug
                                      ? event.event.reservation_slug
                                      : "")
                                  }
                                >
                                  <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                                    View
                                  </button>
                                </Link>
                              )}
                              {/* reservation popup end */}
                            </>
                          ) : event && event.event && event.event.slug ? (
                            <>
                              {/* resident stay popup start */}
                              <p className="az-profile-name-text pl-0">
                                Check-in date & time{" "}
                              </p>{" "}
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.view_start
                                  ? event.event.view_start
                                  : ""}
                              </p>
                              <p className="az-profile-name-text pl-0">
                                Check-out date & time{" "}
                              </p>
                              <p className="media-body">
                                {" "}
                                {event && event.event && event.event.view_end
                                  ? event.event.view_end
                                  : ""}
                              </p>
                              <p className="az-profile-name-text pl-0">
                                Resident Access Code
                              </p>
                              <p className="media-body">
                                {" "}
                                {event &&
                                event.event &&
                                event.event.resident_access_code
                                  ? event.event.resident_access_code
                                  : ""}
                              </p>
                              <p className="az-profile-name-text pl-0">
                                Cleaner Access Code
                              </p>
                              <p className="media-body">
                                {" "}
                                {event &&
                                event.event &&
                                event.event.cleaner_access_code
                                  ? event.event.cleaner_access_code
                                  : ""}
                              </p>
                              {/* resident stay popup end */}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </Popup>
                      {/* <Popup
                          display="anchored"
                          isOpen={isTooltipOpen}
                          anchor={anchor}
                          touchUi={false}
                          showOverlay={false}
                          contentPadding={false}
                          closeOnOverlayClick={false}
                          width={350}
                          cssClass="md-tooltip"
                        >
                          <div
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                          >
                            <div className="md-tooltip-header">
                              <span className="md-tooltip-time">
                                {title} <br /> {time}
                              </span>
                            </div>
                          </div>
                        </Popup> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
};
export default ListingCalendar;
