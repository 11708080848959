////////////////////////////////////////////////////////////
//     							                          //
//  Program: LeasingDocuments.jsx                         //
//  Application: LeasingDocuments                         //
//  Option: add  LeasingDocuments                         //
//  Developer: NP			                              //
//  Date: 2022-05-18                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import { Row, Col, Modal } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import Alerts from "../common/Alerts";
import FileWidget from "../fileUploader/FileWidget";
import MultiImageService from "../../services/MultiImageService";
import fileDownload from "js-file-download";
import axios from "axios";
// import downloadDoc from "../../assets/images/download_icon.svg";
import deleteicon from "../../assets/images/delete.svg";
// import deleteImg from "../../assets/images/delete.svg";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import propDoc from "../../assets/images/doc_placeholder.png";
import DocumentTypeService from "../../services/DocumentTypeService";
import modaldeleteicon from "./../../assets/images/delete.svg";
import Select from "react-select";
// import infosize from "../../assets/images/Info.svg";
import NoData from "../common/NoData";
import pdfIcon from "../../assets/images/pdf.png";
import wordIcon from "../../assets/images/word.png";
import excelIcon from "../../assets/images/excel.png";
import imgIcon from "../../assets/images/imgIcon.png";
import { Popover, OverlayTrigger } from "react-bootstrap";
import copyIcon from "./../../assets/images/copy.png";
import Moment from "moment";
import fontIcon from "../../assets/images/font-file.png";
import textIcon from "../../assets/images/text-file.png";
import formatNotSupported from "../../assets/images/not-supported.png";
export class LeasingDocuments extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      isSubmit: false,
      viewMode: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      errors: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      confirm_back: 0,
      property_slug: this.props.property_slug,
      listing_slug: this.props.listing_slug,
      displayFileName: "",
      pathname: window.location.pathname,
      records: [],
      documentTypeList: [],
      newDocumentTypeList: [],
      selectedDocumentTypeList: [],
      documentTypeListTotal: 0,
      document_type_slug: "",
      display_file_name: "",
      files_data: [],
      media_type: "",
      file_name: "",
      description: "",
      sort_order: "",
      doc_cat_slug: "",
      open: false,
      eventKey: "",
      doc_slug: "",
      imgUrl: propDoc,
      status_id: true,
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      // columns: [
      //   // {
      //   //   key: "display_file_name",
      //   //   text: "Name",
      //   //   className: "display_file_name hand-cursor td_clickable",
      //   //   sortable: true,
      //   //   cell: (records) => {
      //   //     // return records.display_file_name;
      //   //     return records.document_slug ? (
      //   //       <span
      //   //         onClick={() => this.getUnitDocData(records.document_slug)}
      //   //         className="mg-r-10 hand-cursor"
      //   //       >
      //   //         {" "}
      //   //         {records.display_file_name}
      //   //       </span>
      //   //     ) : (
      //   //       records.display_file_name
      //   //     );
      //   //   },
      //   // },
      //   {
      //     key: "document_category_name",
      //     text: "Document Type",
      //     className: "document_category_name hand-cursor td_clickable",
      //     sortable: true,
      //     cell: (records) => {
      //       return records.document_category_name ? (
      //         <span
      //           onClick={() => this.getUnitDocData(records.document_slug)}
      //           className="mg-r-10 hand-cursor"
      //         >
      //           {records.document_category_name}
      //         </span>
      //       ) : (
      //         ""
      //       );
      //     },
      //   },
      //   {
      //     key: "file_count",
      //     text: "Number of Files",
      //     className: "file_count text-left",
      //     sortable: false,
      //     cell: (records) => {
      //       return records.file_count ? records.file_count : "";
      //     },
      //   },
      //   // {
      //   //   key: "file_name",
      //   //   text: "Action",
      //   //   className: "file_name",
      //   //   sortable: false,
      //   //   cell: (records) => {
      //   //     return (
      //   //       <>
      //   //         <img
      //   //           src={downloadDoc}
      //   //           onClick={() => {
      //   //             this.getDownloadFile(
      //   //               records.file_name,
      //   //               records.display_file_name
      //   //             );
      //   //           }}
      //   //           alt="Download File"
      //   //           className="mg-r-10 hand-cursor"
      //   //         />
      //   //       </>
      //   //     );
      //   //   },
      //   // },
      // ],
      columns: [
        {
          key: "document_category_name",
          text: "File Type",
          className: "document_category_name hand-cursor td_clickable",
          sortable: true,
          cell: (records) => {
            return records.document_category_name
              ? records.document_category_name
              : "";
          },
        },
        {
          key: "file_name",
          text: "File",
          className: "file_name text-left",
          sortable: false,
          cell: (records) => {
            var docExt = records.file_name.split(".").pop();
            return (
              <React.Fragment>
                <div className="viewfile-section">
                  <span className="viewfile d-flex align-items-center">
                    {docExt === "pdf" ? (
                      <>
                        <img
                          src={pdfIcon}
                          alt=""
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        />
                        <p
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        >
                          {records.display_file_name}
                        </p>
                      </>
                    ) : docExt === "doc" || docExt === "docx" ? (
                      <>
                        <img
                          src={wordIcon}
                          alt=""
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        />
                        <p
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        >
                          {records.display_file_name}
                        </p>
                      </>
                    ) : docExt === "xlsx" ||
                      docExt === "csv" ||
                      docExt === "xls" ? (
                      <>
                        <img
                          src={excelIcon}
                          alt=""
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        />
                        <p
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        >
                          {records.display_file_name}
                        </p>
                      </>
                    ) : docExt === "woff" ||
                      docExt === "woff2" ||
                      docExt === "ttf" ||
                      docExt === "otf" ? (
                      <>
                        <img
                          src={fontIcon}
                          alt=""
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        />
                        <p
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        >
                          {records.display_file_name}
                        </p>
                      </>
                    ) : docExt === "txt" ? (
                      <>
                        <img
                          src={textIcon}
                          alt=""
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        />
                        <p
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        >
                          {records.display_file_name}
                        </p>
                      </>
                    ) : (
                      <>
                        <img
                          src={imgIcon}
                          alt=""
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        />
                        <p
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        >
                          {records.display_file_name}
                        </p>
                      </>
                    )}
                  </span>
                </div>
              </React.Fragment>
            );
          },
        },
        {
          key: "created_at",
          text: "Upload Date",
          className: "created_at text-left date_tbl_formate",
          sortable: false,
          cell: (records) => {
            return (
              <span>
                {records.created_at
                  ? Moment(records.created_at, "YYYY-MM-DD HH:mm:ss").format(
                      global.dateTimeFormat
                    )
                  : ""}
              </span>
            );
          },
        },
        {
          key: "display_file_name",
          text: "Copy Path",
          className: "display_file_name text-left",
          sortable: false,
          cell: (records) => {
            return (
              <OverlayTrigger
                trigger="click"
                placement="top"
                overlay={
                  <Popover
                    id="popover-basic"
                    className="popover-primary copide_box"
                  >
                    <Popover.Title as="h3" className="p-0">
                      Copied
                    </Popover.Title>
                  </Popover>
                }
              >
                <img
                  src={copyIcon}
                  alt="Copy"
                  onClick={() => this.copyPropertyDocsFile(records.file_name)}
                  className="hand-cursor copyClass dashboardRightcard "
                />
              </OverlayTrigger>
            );
          },
        },
        {
          key: "slug",
          text: "Action",
          className: "slug text-left",
          sortable: false,
          cell: (records) => {
            return (
              <img
                src={deleteicon}
                className="hand-cursor"
                alt=""
                onClick={() => this.openDeleteDocModal(records.slug)}
              />
            );
          },
        },
      ],
      previewFiles: [],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.propDoc = React.createRef();
    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateUnitDocuments = this.saveOrUpdateUnitDocuments.bind(this);
    this.changeView = this.changeView.bind(this);
    this.deleteUnitDoc = this.deleteUnitDoc.bind(this);
    this.opendeleteUnitDocModal = this.opendeleteUnitDocModal.bind(this);
    // this.selected_display_file_name =
    //   this.selected_display_file_name.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.setState({ showSpinner: true, loading: true });
    this.getData();
    this.getDocumentTypeData();
    this.setState({ showSpinner: false, loading: false });
  }

  /*file download */
  async getDownloadFile(url, name) {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      path: "leases",
      refTableId: 11,
      slug: this.state.listing_slug,
      mediaType: 2,
      docList: 1,
      propertySlug: this.state.property_slug,
    };
    var resData = {};
    var list = [];
    var totalRecords = 0;
    let res = await MultiImageService.getDocuments(inputData, queryString);
    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      list = res.data ? res.data.data : [];
      // totalRecords = list ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  removeNewSelectedFile = async (key) => {
    let newFileList = this.state.previewFiles;
    delete newFileList[key];
    this.setState({
      previewFiles: newFileList,
    });
  };

  previewFilesInParent = (value) => {
    setTimeout(() => {
      this.setState({ previewFiles: value });
    }, 100);
  };

  async getUnitDocData(slug) {
    this.setState({ viewMode: 1, doc_cat_slug: slug });
    if (this.state.viewMode !== 1) {
      this.changeView();
    }
    this.setState({ showSpinner: true, loading: true });
    if (slug !== undefined) {
      var resData = {};
      var files_data = [];
      let inputData = {
        path: "leases",
        refTableId: 11,
        slug: this.state.listing_slug,
        mediaType: 2,
        docsSlug: slug,
        propertySlug: this.state.property_slug,
      };
      let res = await MultiImageService.getPropertyDocumentsBySlug(inputData);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.display_file_name = res.data.display_file_name
          ? res.data.display_file_name
          : "";
        resData.document_type_slug = res.data.document_type_data
          ? res.data.document_type_data.slug
          : "";
        resData.file_name = res.data.file_name ? res.data.file_name : "";

        // resData.description = res.data.description ? res.data.description : "";
        // resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        // resData.status_id = res.data.status_id ? res.data.status_id : "";

        var selectedDocumentTypeList = [];
        selectedDocumentTypeList = res.data.document_type_data
          ? [
              {
                value: res.data.document_type_data.slug,
                label: res.data.document_type_data.document_category_name,
              },
            ]
          : "";
        files_data = res.data.files_data ? res.data.files_data : [];
      }

      var fileExt = resData.file_name.split(".").pop();

      this.setState(resData);
      this.setState({
        selectedDocumentTypeList: selectedDocumentTypeList,
        fileExt: fileExt,
        files_data: files_data,
      });
    }
    if (this.state.files_data.length === 0) {
      this.changeView();
    }
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get document type data */
  async getDocumentTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var documentTypeList = [];
    var documentTypeListTotal = 0;

    let res = await DocumentTypeService.getDocumentType(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      documentTypeList = res.data ? res.data : [];
      documentTypeListTotal = documentTypeList ? documentTypeList.length : 0;
    }

    if (this.state.doc_cat_slug !== undefined) {
      var valuesToRemove = ["I9L2XNG8BQ37", "W5FYC0RBJXL1", "VKJL2ZSQ7F0U"];
      const newDocumentTypeList =
        documentTypeListTotal > 0
          ? documentTypeList
              .filter((item) => !valuesToRemove.includes(item.slug))
              .map(({ slug, document_category_name }) => ({
                value: slug,
                label: document_category_name,
              }))
          : [];
      var selectedDocumentTypeList =
        documentTypeListTotal > 0
          ? documentTypeList
              .map(({ slug, document_category_name }) => ({
                value: slug,
                label: document_category_name,
              }))
              .filter((opt) => {
                if (opt.label === "Lease" || opt.label === "Leases")
                  return true;
                else return false;
              })
          : [];
      var document_type_slug =
        documentTypeListTotal > 0 && selectedDocumentTypeList.length > 0
          ? selectedDocumentTypeList[0].value
          : [];
      this.setState({
        documentTypeList: documentTypeList,
        documentTypeListTotal: documentTypeListTotal,
        newDocumentTypeList: newDocumentTypeList,
        selectedDocumentTypeList: selectedDocumentTypeList,
        document_type_slug: document_type_slug,
      });
    } else {
      const newDocumentTypeList =
        documentTypeListTotal > 0
          ? documentTypeList.map(({ slug, document_category_name }) => ({
              value: slug,
              label: document_category_name,
            }))
          : [];
      this.setState({
        documentTypeList: documentTypeList,
        documentTypeListTotal: documentTypeListTotal,
        newDocumentTypeList: newDocumentTypeList,
      });
    }

    this.setState({ showSpinner: false, loading: false });
  }

  /* to get Default Selected document type data */
  //  async getDefaultSelectedDocumentTypeData(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var documentTypeList = [];
  //   var documentTypeListTotal = 0;

  //   let res = await DocumentTypeService.getDocumentType(
  //     "is_dropdown=1",
  //     queryString ? "&" + queryString : ""
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     documentTypeList = res.data ? res.data : [];
  //     documentTypeListTotal = documentTypeList ? documentTypeList.length : 0;
  //   }
  //   var selectedDocumentTypeList =
  //     documentTypeListTotal > 0
  //       ? documentTypeList
  //           .map(({ slug, document_category_name }) => ({
  //             value: slug,
  //             label: document_category_name,
  //           }))
  //           .filter((opt) => {
  //             if (opt.label === "Lease") return true;
  //             else return false;
  //           })
  //       : [];
  //   this.setState({
  //     selectedDocumentTypeList: selectedDocumentTypeList,
  //   });
  //   this.setState({ showSpinner: false, loading: false });
  // }

  opendeleteUnitDocModal(slug) {
    this.setState({ deleteUnitDocModal: true, slug: slug });
  }

  closedeleteUnitDocModal() {
    this.setState({ deleteUnitDocModal: false, slug: "" });
  }

  openDeleteDocModal(slug) {
    this.setState({ deleteDocModal: true, slug: slug });
  }

  closeDeleteDocModal() {
    this.setState({ deleteDocModal: false, slug: "" });
  }

  // selected_display_file_name(selected_display_file_name) {
  //   this.setState({ selected_file_name: selected_display_file_name });
  // }

  saveOrUpdateUnitDocuments = async (e) => {
    e.preventDefault();
    let errors = {};
    // var displayFileName = "";
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      const FileWidgetObj = new FileWidget();
      const output = await FileWidgetObj.fileUploadFun("lease_doc_form");
      // if (output.status !== false && output.response.length > 0) {
      //   if (output.response[0].filesData.length > 0) {
      //     displayFileName =
      //       output.response[0].filesData[0].orgFileName.split(".")[0];
      //     this.setState({ displayFileName: displayFileName });
      //   } else {
      //     displayFileName = "";
      //     this.setState({ displayFileName: "" });
      //   }
      // }
      if (output.status !== false) {
        let inputData = {
          // display_file_name: this.state.display_file_name
          //   ? this.state.display_file_name
          //   : this.state.displayFileName ? this.state.displayFileName : "",
          display_file_name: this.state.display_file_name
            ? this.state.display_file_name
            : "",
          document_type_slug: this.state.document_type_slug
            ? this.state.document_type_slug
            : "",
          file_name: this.state.file_name ? this.state.file_name : "",
          description: this.state.description ? this.state.description : "",
          media_type: 2,
          ref_id: this.state.listing_slug ? this.state.listing_slug : "",
          sort_order: this.state.sort_order ? this.state.sort_order : "",
          status_id: this.state.status_id === true ? 1 : 2,
          availableFiles: output.response,
          deletedFiles: this.state.deletedFileList,
          docsSlug: this.state.slug ? this.state.slug : "",
        };
        if (this.state.doc_slug !== undefined && this.state.doc_slug !== "") {
          // For active status 1:Active, 2:Inactive
          inputData.status_id = this.state.status_id ? this.state.status_id : 2;

          let res = await MultiImageService.submitMultiImage(inputData);
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            this.setState({ viewMode: 0 });
            this.getData();
          } else {
            let alertMessage = "";
            if (res.data.result.errorDetail !== null) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                // alertMessage += item.errorMessage[0]+'\n';
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
            this.setState({
              showSpinner: false,
              showAlertModal: true,
              alertModalType: "error",
              alertModalTitle: res.data.errorCode
                ? res.data.errorCode
                : "Error!",
              alertModalMessage:
                alertMessage !== "" ? alertMessage : res.data.message,
            });
          }
        } else {
          if (output.response[0].filesData) {
            if (output.response[0].filesData.length > 0) {
              let res = await MultiImageService.submitMultiImage(inputData);
              if (global.successStatus.includes(res.status)) {
                this.setState({
                  showAlertModal: true,
                  alertModalType: "success",
                  alertModalTitle: "Success",
                  alertModalMessage: res.message ? res.message : "Success",
                });
                this.setState({ viewMode: 0 });
                this.getData();
              } else {
                if (res.data.result.errorDetail) {
                  let errors = {};
                  res.data.errorCode = "Validation Failed";
                  res.data.result.errorDetail.forEach((item) => {
                    errors[item.errorField] = item.errorMessage[0];
                    res.data.errorMessage = "";
                  });
                  this.setState({ errors: errors });
                }
                this.setState({
                  showAlertModal: true,
                  alertModalType: "error",
                  alertModalTitle: res.data.errorCode
                    ? res.data.errorCode
                    : "Error!",
                  alertModalMessage: res.data.message
                    ? res.data.message
                    : "Error!",
                });
              }
            } else {
              errors["document"] = "Please select recommended file size.";
              this.setState({ errors: errors });
            }
          }
        }
      } else {
        let checkError = output.response[0].validationMessage[0];

        if (checkError[0].includes("30 mb") === true) {
          errors["document"] = "The file should not be greater than 30 mb.";
          this.setState({ errors: errors });
        } else {
          errors["document"] =
            "The file should be pdf, doc, docx, xls, xlsx, csv, png, jpg.";
          this.setState({ errors: errors });
        }
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
    this.setState({
      showSpinner: false,
      isSubmit: false,
      selected_file_name: "",
    });
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1, displayFileName: "" });
  };

  handleChangeDocumentTypeList(value) {
    let document_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        document_type_slug.push(item.value);
      });
    }
    const document_type_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedDocumentTypeList: value,
      document_type_slug: document_type_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleDownload = (url, file_name) => {
    let filePath = url;
    axios
      .get(`${filePath}`, {
        responseType: "blob",
      })
      .then((res) => {
        // let filename = filePath.replace(/^.*[\\/]/, '')
        let fileExtension;
        fileExtension = filePath.split(".");
        fileExtension = fileExtension[fileExtension.length - 1];
        fileDownload(res.data, `${file_name}.${fileExtension}`);
      });
  };

  async deleteUnitDoc(slug) {
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      path: "leases",
      refTableId: 11,
      slug: this.props.property_slug,
      mediaType: 2,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteUnitDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({ viewMode: 0, doc_slug: "" });
      this.getData(slug);
    } else {
      this.closedeleteUnitDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false, loading: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async deleteDoc(slug) {
    this.setState({ showSpinner: true });
    let inputData = {
      path: "leases",
      refTableId: 11,
      slug: this.state.listing_slug,
      mediaType: 2,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getUnitDocData(this.state.doc_cat_slug);
    } else {
      this.closeDeleteDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ start_date: "" });
    this.setState({ end_date: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.description !== undefined &&
      input.description !== "" &&
      input.description !== null &&
      input.description.length > 100000
    ) {
      isValid = false;
      errors["description"] =
        "The document description character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  getTitle() {
    return "Documents";
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.eventKey });
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.getData(this.state.listing_slug);
      this.setState({
        viewMode: 0,
        doc_cat_slug: "",
        display_file_name: "",
        document_type_slug: "",
        file_name: "",
        description: "",
        slug: "",
        doc_slug: "",
        files_data: [],
        confirm_back: 0,
        selectedDocumentTypeList: [],
        displayFileName: "",
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
    } else {
      this.getDocumentTypeData();
      this.setState({ viewMode: 1 });
      this.setState({
        display_file_name: "",
        document_type_slug: "",
        file_name: "",
        description: "",
        errors: "",
        slug: "",
        selectedDocumentTypeList: [],
        displayFileName: "",
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
    }
  }

  async copyLeaseDocsFile(fileUrl) {
    let message = "Document copied to clipboard";
    navigator.clipboard.writeText(fileUrl).then(
      setTimeout(
        window.location.reload(),
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: message ? message : "Success",
        }),
        global.redirect_time
      )
    );
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
            {this.state.viewMode === 0 &&
            global.userPermissions.checkPermission("leases-update") ? (
              <button
                className="btn-success-outline-small mr-3"
                onClick={this.changeView}
              >
                <img src={pluswIcon} alt="Add" />
                Add
              </button>
            ) : (
              <>
                {/* {this.state.doc_slug === undefined ||
                this.state.doc_slug === "" ? (
                  " "
                ) : (

                  <span
                    onClick={() => {
                      this.opendeleteUnitDocModal(this.state.slug);
                    }}
                    className="deleteicon mg-r-20 hand-cursor"
                  >
                    <img src={deleteicon} />
                  </span>
                )} */}

                <div className="d-flex">
                  <button
                    type="button"
                    className="btn-success-outline-small"
                    onClick={() => {
                      if (this.state.confirm_back === 1)
                        if (
                          window.confirm("Do you want to exit without saving?")
                        )
                          this.changeView();
                        else return false;
                      else this.changeView();
                    }}
                  >
                    Cancel
                  </button>
                  {global.userPermissions.checkPermission("leases-update") && (
                    <button
                      type="button"
                      className="btn-success ml-3"
                      onClick={this.saveOrUpdateUnitDocuments}
                      disabled={this.state.isSubmit ? true : false}
                    >
                      {this.state.doc_slug === undefined ||
                      this.state.doc_slug === ""
                        ? this.state.isSubmit
                          ? global.loader
                          : "Save"
                        : this.state.isSubmit
                        ? global.loader
                        : "Save"}
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          {this.state.viewMode === 0 ? (
            <>
              {this.state.showFilter ? (
                <Row className="align-items-center order_form">
                  <Col md={3} lg={5} xl={5} className="mb-md-0 mb-3 pl-0">
                    <input
                      name="filter_value"
                      className="form-control"
                      autoComplete="off"
                      // placeholder="Filter Value"
                      value={this.state.filter_value}
                      onChange={this.changeHandler}
                      maxLength="30"
                    />
                  </Col>
                  <Col md={5} lg={5} xl={5}>
                    <Row>
                      <button
                        onClick={this.customFilter}
                        className="btn-success mg-l-30 mg-r-20"
                      >
                        Search
                      </button>
                      <button
                        onClick={this.resetDate}
                        className="btn-success-outline"
                      >
                        Reset
                      </button>
                    </Row>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              {this.state.total_record > 0 || this.state.filter_value !== "" ? (
                <React.Fragment>
                  <div className="mg-t-18 brandList pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                    <ReactDatatable
                      className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                      config={
                        this.state.total_record > global.page_size
                          ? this.state.config
                          : this.state.config
                      }
                      records={this.state.records}
                      columns={this.state.columns}
                      extraButtons={this.state.extraButtons}
                      dynamic={true}
                      // loading={this.state.loading}
                      total_record={this.state.total_record}
                      onChange={this.tableChangeHandler}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <NoData
                  msg={global.commonMessages.checkMessage("LEASE_DOCUMENTS")}
                />
              )}
            </>
          ) : (
            ""
          )}
          {this.state.viewMode === 1 ? (
            <>
              <form id="lease_doc_form">
                <div className="m-0">
                  <div className="row align-items-center pd-t-0 pd-b-0 border-bottom-0 documnet-section">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <label className="form-label text-left pd-b-5">
                        Document Type{" "}
                      </label>
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        className="multiselect"
                        menuPlacement="auto"
                        value={this.state.selectedDocumentTypeList}
                        options={this.state.newDocumentTypeList}
                        getOptionValue={(option) => `${option.label}`}
                        onChange={(value) =>
                          this.handleChangeDocumentTypeList(value)
                        }
                        defaultValue={this.state.selectedDocumentTypeList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",

                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />

                      {this.state.errors.document_type_slug ? (
                        <div className="text-danger">
                          {this.state.errors.document_type_slug}
                        </div>
                      ) : (
                        this.validator.message(
                          "document_type",
                          this.state.document_type_slug,
                          "required",
                          { className: "text-danger" }
                        )
                      )}
                    </div>
                    {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                      <label className="form-label text-left pd-b-5">
                        Name <span className="optional">(Optional)</span>
                      </label>

                      <Form.Control
                        onChange={this.changeHandler}
                        id="display_file_name"
                        name="display_file_name"
                        value={
                          this.state.display_file_name
                            ? this.state.display_file_name
                            : this.state.selected_file_name
                        }
                        className="form-control max_width_100"
                        type="text"
                      />
                      {this.state.errors.display_file_name ? (
                        <div className="text-danger">
                          {this.state.errors.display_file_name}
                        </div>
                      ) : (
                        ""
                      )}
                    </div> */}
                  </div>

                  <div className="row align-items-baseline- pd-t-0 pd-b-0 border-bottom-0 h-100 documnet-section-upload">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <label className="form-label text-left pd-b-5">
                        Upload File{" "}
                      </label>
                      <div className="d-flex flex-row align-items-center upload upload-files-section">
                        <FileWidget
                          fieldName="file_name"
                          fieldTitle="Select File"
                          checkValidation="true"
                          allowedType="image/png,image/jpg,text/csv,application/*,doc,docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                          maxFileSize={30}
                          maxFileSizeType="mb"
                          maxFileAllowed={10}
                          uploadOn="s3"
                          slug={this.state.listing_slug}
                          refTableId="11"
                          displayFileName={this.state.display_file_name}
                          documentTypeSlug={this.state.document_type_slug}
                          description={this.state.description}
                          mediaType="2"
                          sortOrder={
                            this.state.sort_order !== ""
                              ? this.state.sort_order
                              : 0
                          }
                          statusId={this.state.status_id === true ? 1 : 2}
                          helpText="to download sample file."
                          sampleFileUrl="/sample.jpg"
                          drag="true"
                          dragText="Select or drop file size upto 30 MB"
                          inputClassName="form-control"
                          labelClassName="form-label"
                          formID="lease_doc_form"
                          uploadType="multiple"
                          resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
                          propertySlug={this.props.property_slug}
                          destinationFolder="leases"
                          uploadedFiles={this.state.profile_image2}
                          deletedFiles={this.state.deletedFileList}
                          acceptOnly="image/png,image/jpg,text/csv,text/txt,application/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                          displayPreviewStyle="horizontal"
                          validationFire={0}
                          previewFilesInParent={this.previewFilesInParent}
                          previewFiles={this.state.previewFiles}
                          // selected_display_file_name={
                          //   this.selected_display_file_name
                          // }
                        />
                      </div>
                      {/* <div className="col-md-12 p-0">
                        <span className="d-inline-flex  align-items-start justify-content-start infosize w-100">
                          <img src={infosize} className="mg-r-5" />
                          <p> Max file size - 30 MB</p>
                        </span>
                      </div> */}
                      {this.state.errors.document ? (
                        <div className="text-danger">
                          {this.state.errors.document}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.state.previewFiles !== undefined &&
                      this.state.previewFiles &&
                      this.state.previewFiles.length > 0 && (
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-15 pd-xs-r-15 mg-b-15 uploadSection">
                          <label className="form-label text-left pd-b-5">
                            Preview File{" "}
                          </label>

                          {this.state.previewFiles.map((val, key) => {
                            return (
                              <React.Fragment key={key}>
                                <div className="viewfile-section">
                                  <span className="viewfile d-flex align-items-center">
                                    <img
                                      className="mg-r-5 hand-cursor"
                                      src={
                                        val.name.match(/.(jpg|jpeg|png|gif)$/i)
                                          ? val.src
                                          : val.name.match(/.(pdf)$/i)
                                          ? pdfIcon
                                          : val.name.match(/.(docx|doc)$/i)
                                          ? wordIcon
                                          : val.name.match(/.(xlsx|csv|xls)$/i)
                                          ? excelIcon
                                          : val.name.match(
                                              /.(woff|woff2|ttf|otf)$/i
                                            )
                                          ? fontIcon
                                          : val.name.match(/.(txt)$/i)
                                          ? textIcon
                                          : formatNotSupported
                                      }
                                      alt=""
                                    />
                                    <p className="mg-r-5 hand-cursor">
                                      {val.name}
                                    </p>
                                  </span>
                                  <img
                                    src={deleteicon}
                                    className="hand-cursor"
                                    onClick={() =>
                                      this.removeNewSelectedFile(key)
                                    }
                                  />
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      )}
                    {/* {(this.state.doc_cat_slug !== undefined ||
                      this.state.doc_cat_slug !== "") &&
                    this.state.files_data.length > 0 ? (
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 uploadSection">
                        <label className="form-label text-left pd-b-5">
                          Uploaded Document{" "}
                        </label>
                        {this.state.doc_cat_slug !== undefined ||
                        this.state.doc_cat_slug !== "" ? (
                          <>
                            {this.state.files_data.length > 0
                              ? this.state.files_data.map((opt, i) => {
                                  var docExt = opt.file_name.split(".").pop();
                                  return (
                                    <React.Fragment key={i}>
                                      <div className="viewfile-section">
                                        <span className="viewfile d-flex align-items-center">
                                          {docExt === "pdf" ? (
                                            <>
                                              <img
                                                src={pdfIcon}
                                                alt=""
                                                className="mg-r-5 hand-cursor"
                                                onClick={() => {
                                                  this.getDownloadFile(
                                                    opt.file_name,
                                                    opt.display_file_name
                                                  );
                                                }}
                                              />
                                              <p
                                                className="mg-r-5 hand-cursor"
                                                onClick={() => {
                                                  this.getDownloadFile(
                                                    opt.file_name,
                                                    opt.display_file_name
                                                  );
                                                }}
                                              >
                                                {opt.display_file_name}
                                              </p>
                                            </>
                                          ) : docExt === "doc" ||
                                            docExt === "docx" ? (
                                            <>
                                              <img
                                                src={wordIcon}
                                                alt=""
                                                className="mg-r-5 hand-cursor"
                                                onClick={() => {
                                                  this.getDownloadFile(
                                                    opt.file_name,
                                                    opt.display_file_name
                                                  );
                                                }}
                                              />
                                              <p
                                                className="mg-r-5 hand-cursor"
                                                onClick={() => {
                                                  this.getDownloadFile(
                                                    opt.file_name,
                                                    opt.display_file_name
                                                  );
                                                }}
                                              >
                                                {opt.display_file_name}
                                              </p>
                                            </>
                                          ) : docExt === "xlsx" ||
                                            docExt === "csv" ||
                                            docExt === "xls" ? (
                                            <>
                                              <img
                                                src={excelIcon}
                                                alt=""
                                                className="mg-r-5 hand-cursor"
                                                onClick={() => {
                                                  this.getDownloadFile(
                                                    opt.file_name,
                                                    opt.display_file_name
                                                  );
                                                }}
                                              />
                                              <p
                                                className="mg-r-5 hand-cursor"
                                                onClick={() => {
                                                  this.getDownloadFile(
                                                    opt.file_name,
                                                    opt.display_file_name
                                                  );
                                                }}
                                              >
                                                {opt.display_file_name}
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src={imgIcon}
                                                alt=""
                                                className="mg-r-5 hand-cursor"
                                                onClick={() => {
                                                  this.getDownloadFile(
                                                    opt.file_name,
                                                    opt.display_file_name
                                                  );
                                                }}
                                              />
                                              <p
                                                className="mg-r-5 hand-cursor"
                                                onClick={() => {
                                                  this.getDownloadFile(
                                                    opt.file_name,
                                                    opt.display_file_name
                                                  );
                                                }}
                                              >
                                                {opt.display_file_name}
                                              </p>
                                            </>
                                          )}
                                        </span>
                                        <span>
                                          {opt.updated_at
                                            ? Moment(
                                                opt.updated_at,
                                                "YYYY-MM-DD HH:mm:ss"
                                              ).format(
                                                global.dateFormat + " HH:mm:ss"
                                              )
                                            : Moment(
                                                opt.created_at,
                                                "YYYY-MM-DD HH:mm:ss"
                                              ).format(
                                                global.dateFormat + " HH:mm:ss"
                                              )}
                                        </span>
                                        <OverlayTrigger
                                          trigger="click"
                                          placement="top"
                                          overlay={
                                            <Popover
                                              id="popover-basic"
                                              className="popover-primary copide_box"
                                            >
                                              <Popover.Title
                                                as="h3"
                                                className="p-0"
                                              >
                                                Copied
                                              </Popover.Title>
                                            </Popover>
                                          }
                                        >
                                          <img
                                            src={copyIcon}
                                            alt="Copy"
                                            onClick={() =>
                                              this.copyLeaseDocsFile(
                                                opt.file_name
                                              )
                                            }
                                            className="hand-cursor copyClass dashboardRightcard "
                                          />
                                        </OverlayTrigger>
                                        <img
                                          src={deleteicon}
                                          className="hand-cursor"
                                          alt=""
                                          onClick={() =>
                                            this.openDeleteDocModal(opt.slug)
                                          }
                                        />
                                      </div>
                                    </React.Fragment>
                                  );
                                })
                              : ""}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>

                  {/* <div className="row align-items-center pd-b-30 border-bottom-0">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">

                      <InputGroup className="mt-3">
                      <label className="form-label text-left pd-b-5 w-100">
                      Description <span className="optional">(Optional)</span>
                    </label>
                      <Form.Control
                        onChange={this.changeHandler}
                        name="description"
                        value={this.state.description}
                        className="form-control max_width_100"
                        id="description"
                        as="textarea"
                        rows={global.textareaRowLength}
                        // placeholder="Username"
                      />
                    </InputGroup>

                      {this.state.errors.description ? (
                        <div className="text-danger">
                          {this.state.errors.description}
                        </div>
                      ) : (
                        this.validator.message(
                          "description",
                          this.state.description,
                          "max:100000",
                          { className: "text-danger" }
                        )
                      )}
                    </div>
                  </div> */}
                </div>
              </form>
            </>
          ) : (
            ""
          )}
        </div>

        <Modal show={this.state.deleteUnitDocModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteUnitDocModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className=" m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteUnitDocModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className=" m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteUnitDoc(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.deleteDocModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteDocModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteDocModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteDoc(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default LeasingDocuments;
