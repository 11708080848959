////////////////////////////////////////////////////////////
//     							                          //
//  Program: CompanyOverview.jsx                                //
//  Application: Dashboard                                //
//  Option: to view Company Information                   //
//  Developer: NP  						                            //
//  Date: 2022-04-11                                      //
//                                                        //
////////////////////////////////////////////////////////////
import Moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";
import CompanyService from "../../services/CompanyService";
// import companyLogo from './../../assets/images/company_placeholder.png'
import NoBooking from "../../assets/images/snapshoticons/Bookings.svg";

class NewBookingsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertySlug: this.props.propertySlug,
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: "",
      total_client_count: "",
      checkins_today: [],
      checkouts_today: [],
      checkins_tomorrow: [],
      checkouts_tomorrow: [],
      checkInList: [],
      checkOutList: [],
      cleaningscheduled_today: [],
      cleaningscheduled_tomorrow: [],
      occupiedunits: [],
      bookedunits: [],
      signed_leases: [],
      new_booking_list: [],
    };
  }

  componentDidMount() {
    this.setState({ spinnerLoader: true, loading: true });
    this.setState({ new_booking_list: [] });
    if (this.state.propertySlug) {
      this.getData(this.state.propertySlug);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ spinnerLoader: true, loading: true });
    this.setState({ new_booking_list: [] });
    if (nextProps.propertySlug) {
      this.getData(nextProps.propertySlug);
    }
  }
  // for check_in info
  handleClickCheckinList(property_slug, reservation_slug) {
    // this.props.history.push(
    //     "/properties/view/" + property_slug + "/reservations/view/" + reservation_slug
    // );
    const win = window.open(
      "/properties/view/" +
        property_slug +
        "/reservations/view/" +
        reservation_slug,
      "_blank"
    );
    win.focus();
  }
  async getData(propertySlug) {
    this.setState({ spinnerLoader: true, loading: true });
    var resData = {};

    let res = await CompanyService.getNewlyBookings(propertySlug);

    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
      resData.new_booking_list = res.data.new_booking_list
        ? res.data.new_booking_list
        : [];
      resData.new_booking_list.sort((a, b) =>
        Moment(a.booking_date).format(global.timeFormat) <
        Moment(b.booking_date).format(global.timeFormat)
          ? 1
          : -1
      );
    }
    this.setState(resData);
    this.setState({ spinnerLoader: false, loading: false });
  }

  render() {
    return (
      <div className="card card-table-two">
        <div className=" d-flex justify-content-between">
          <div className="">
            <h6 className="card-title">New bookings</h6>
            <span className="d-block mg-b-0">
              Bookings added within the last 24 hours
            </span>
          </div>
          <h6 className="card-title">
            Total Count: {this.state.new_booking_list.length}
          </h6>
        </div>
        {this.state.new_booking_list.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-dashboard-two mg-b-0">
              <thead>
                <tr>
                  <th className="wd-lg-25p tx-left">S.No</th>
                  <th className="wd-lg-25p tx-left">Unit Name</th>
                  <th className="wd-lg-25p tx-left">Check-In Date</th>
                  <th className="wd-lg-25p tx-left">Check-Out Date</th>
                  <th className="wd-lg-25p tx-left">Amount Paid</th>
                  <th className="wd-lg-25p text-left">Booked at</th>
                  <th className="wd-lg-25p text-left">Channel</th>
                </tr>
              </thead>
              <tbody>
                {this.state.new_booking_list.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      value={item}
                      onClick={() =>
                        this.handleClickCheckinList(
                          item.property_slug,
                          item.reservation_slug
                        )
                      }
                    >
                      <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                        {i + 1}
                      </td>
                      <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                        {item.unit_name ? item.unit_name : ""}
                      </td>
                      <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable date_tbl_formate">
                        {item.checkin_date
                          ? Moment(item.checkin_date).format(global.dateFormat)
                          : ""}
                      </td>
                      <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable date_tbl_formate">
                        {item.checkout_date
                          ? Moment(item.checkout_date).format(global.dateFormat)
                          : ""}
                      </td>
                      <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                        {"$"}
                        {item.guest_paid ? item.guest_paid : ""}
                      </td>
                      <td className="text-left tx-medium tx-inverse hand-cursor td_clickable date_tbl_formate">
                        {item.booking_date
                          ? Moment(item.booking_date).format(global.timeFormat)
                          : ""}
                      </td>
                      <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                        {item.channel_name ? item.channel_name : ""}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <>
            <div className="table-responsive overflow-hidden">
              {this.state.spinnerLoader ? (
                global.snapshotloader
              ) : (
                <div className="text-center">
                  <img src={NoBooking} alt="" />
                  <p>No list of new bookings.</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}
export default withRouter(NewBookingsList);
