///////////////////////////////////////////////////////////
//  Program: Emails.jsx                                  //
//  Application: Emails                                  //
//  Option: add all Emails                               //
//  Developer:  bhavit                                       //
//  Date: 2022-12-05                                     //
///////////////////////////////////////////////////////////

import SettingSidebar from "../common/SettingSidebar";
import Alerts from "../common/Alerts";
import React, { Component } from "react";
import { Container, Form, Tab, Nav, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import EmailService from "../../services/EmailService";
import AllTemplateService from "../../services/AllTemplateService";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import JoditEditor from "jodit-react";
import { Jodit } from "jodit";
import FileUploadService from "../../services/FileUploadService";
import RolePermissionService from "../../services/RolePermissionService";

class TemplateForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      slug: props.match.params.slug,
      confirm_back: 0,
      input: {},
      errors: {},
      usertotype: "",
      emailformError: null,
      toError: null,
      subjectError: null,
      bodyError: null,
      appmessagetoError: null,
      appnotificationtoError: null,
      bodynotificationError: null,
      texttoError: null,
      bodytextError: null,
      templateError: null,
      checkboxError: null,
      appNotificationTitleError: null,
      isSubmit: false,
      showAlertModal: false,
      newRoleList: [],
      is_checkbox: [],
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      selectedTypeList: [],
      selectedTypeTestmessage: [],
      selectedAppNotification: [],
      selectedStaffAppnotification: [],
      selectedStafftextmessage: [],
      selectedAppmessage: [],
      selectedsecondlist: [],
      selectedStaffAppMessage: [],
      selectedRoleList: [],
      selectedCcList: [],
      staffList: [],
      newStaffList: [],
      selectedStaffToList: [],
      selectedStaffList: [],
      selectedBccStaffList: [],
      selectedHeader: [],
      headervalue : "",
      activeClass: "info",
      isRefreshlistingImages: false,
      isRefreshlistingAmenities: false,
      isRefreshlistingPrice: false,
      isRefreshlistingPetFee: false,
      isRefreshspecialPrice: false,
      isRefreshunavailableDates: false,
      deleteTowerModal: false,
      is_email_enable: true,
      is_message_enable: false,
      is_notification_enable: false,
      status_id: true,
      listingPopup: false,
      bodyPopup: false,
      appbodyPopup: false,
      appnotifybodyPopup: false,
      headerlogo: global.headerlogo,
      typeList: [
        // { key: 6, value: 6, label: 'User' },
        { key: 5, value: 5, label: "Guest" },
        { key: 4, value: 4, label: "Resident" },
        { key: 3, value: 3, label: "Staff" },
        { key: 7, value: 7, label: "Staff Role" },
        { key: 8, value: 8, label: "Property Manager" },
      ],
      ccforList: [
        { key: 1, value: 3, label: "Staff" },
        { key: 2, value: 2, label: "Custom" },
      ],
      BccforList: [
        { key: 3, value: 3, label: "Staff" },
        { key: 2, value: 2, label: "Custom" },
      ],
      moduleList: global.modulelist,
      moduleTypeList: global.moduleReferlist,
      appmodulelist: global.moduleReferlist,
      subjectVariableList: global.moduleReferlist,
      template_desc_info: {
        guest:
          "Message ( Available Merge Fields : [Guest Name] [Confirmation Code] [Check-In] [Check-Out] [Addres] [Unit No] )",
        staff: "Message ( Available Merge Fields : [Staff Name] [Unit No] )",
        resident:
          "Message ( Available Merge Fields : [Resident Name] [Unit No] )",
      },
    };
    this.textareaRef = React.createRef();
    this.textareaRefAppnotify = React.createRef();
    this.editorConfig = {
      extraButtons: ["uploadImage", "uploadImageVideo"],
      width: "auto",
      height: "auto",
      minHeight: 400,
      events: {
        afterInit: (instance) => {
          this.jodit = instance;
        },
      },
    };
    this.saveOrUpdateEmail = this.saveOrUpdateEmail.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerck = this.changeHandlerck.bind(this);
    this.openDeleteNearByAttractionModal =
      this.openDeleteNearByAttractionModal.bind(this);
    this.closeDeleteAttrcationModal =
      this.closeDeleteAttrcationModal.bind(this);
    this.setContent = this.setContent.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.openListingPopup = this.openListingPopup.bind(this);
    this.openBodyPopup = this.openBodyPopup.bind(this);
    this.openAppBodyPopup = this.openAppBodyPopup.bind(this);
    this.openAppNotificaionPopup = this.openAppNotificaionPopup.bind(this);
    this.handleChangeRoleList = this.handleChangeRoleList.bind(this);
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "email") {
      this.setState({ isRefreshlistingImages: true });
    } else if (e === "appMessage") {
      this.setState({ isRefreshlistingAmenities: true });
    } else if (e === "appNotification") {
      this.setState({ isRefreshlistingPrice: true });
    } else if (e === "textMessage") {
      this.setState({ isRefreshlistingPetFee: true });
    }
  }

  componentDidMount() {
    this.getStaffData();
    this.getRoleData();
    this.uploadImageButton();
    this.uploadVideoButton();
  }

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: "Upload image",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.imageUpload(editor);
      },
    };
  };

  imageUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", imageFile, imageFile.name);
      fileData.append("fileName", imageFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertImage(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editor.selection.insertNode(image);
  };

  uploadVideoButton = () => {
    Jodit.defaultOptions.controls.uploadImageVideo = {
      name: "Upload video",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.videoUpload(editor);
      },
    };
  };

  videoUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = async function () {
      const videoFile = input.files[0];

      if (!videoFile) {
        return;
      }

      if (!videoFile.name.match(/\.(mp3|mp4|mov)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", videoFile, videoFile.name);
      fileData.append("fileName", videoFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertVideo(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertVideo = (editor, url) => {
    const video = editor.selection.j.createInside.element("video");
    video.setAttribute("src", url);
    editor.selection.insertNode(video);
  };

  validate() {
    let emailformError = "";
    let toError = "";
    let subjectError = "";
    let headerError = "";
    let bodyError = "";
    let appmessagetoError = "";
    let bodynotificationError = "";
    let appnotificationtoError = "";
    let bodytextError = "";
    let texttoError = "";
    let templateError = "";
    let checkboxError = "";
    let emailbodyError = "";
    let appNotificationTitleError = "";
    if (!this.state.template_name) {
      templateError = "This field is required.";
    }
    // if(this.state.is_checkbox.length === 0)
    // {
    //   checkboxError = "One checkbox is required."
    // }
    if (this.state.is_email_enable == true) {
      if (!this.state.email_form) {
        emailformError = "This field is required.";
      }
      if (this.state.slug !== undefined) {
        if (this.state.selectedTypeList.length === 0) {
          toError = "This field is required.";
        }
      } else {
        if (!this.state.selectedTypeList.value) {
          toError = "This field is required.";
        }
      }
      if (this.state.slug !== undefined) {
        if (this.state.selectedHeader.length === 0) {
          headerError = "This field is required.";
        }
      } else {
        if (!this.state.selectedHeader.value) {
          headerError = "This field is required.";
        }
      }
      if (!this.state.subject) {
        subjectError = "This field is required.";
      }

      if (
        !this.state.email_body
          .replace(/(<([^>]+)>)/gi, "")
          .replace("&nbsp;", "")
      ) {
        emailbodyError = "This field is required.";
      }
    }

    if (this.state.is_message_enable == true) {
      if (this.state.slug !== undefined) {
        if (this.state.selectedAppmessage.length == 0) {
          appmessagetoError = "This field is required.";
        }
      } else {
        if (!this.state.selectedAppmessage.value) {
          appmessagetoError = "This field is required.";
        }
      }
      if (!this.state.app_message_body) {
        bodyError = "This field is required.";
      }
    }

    if (this.state.is_notification_enable == true) {
      if (this.state.slug !== undefined) {
        if (this.state.selectedAppNotification.length == 0) {
          appnotificationtoError = "This field is required.";
        }
      } else {
        if (!this.state.selectedAppNotification.value) {
          appnotificationtoError = "This field is required.";
        }
      }
      if (!this.state.app_notification_body) {
        bodynotificationError = "This field is required.";
      }
      if (!this.state.app_notification_title) {
        appNotificationTitleError = "This field is required.";
      }
    }
    if (this.state.is_text_message_enable == true) {
      if (this.state.slug !== undefined) {
        if (this.state.selectedTypeTestmessage.length == 0) {
          texttoError = "This field is required.";
        }
      } else {
        if (!this.state.selectedTypeTestmessage.value) {
          texttoError = "This field is required.";
        }
      }
      if (!this.state.test_message_body) {
        bodytextError = "This field is required.";
      }
    }
    if (
      emailformError ||
      toError ||
      subjectError ||
      headerError ||
      bodyError ||
      appmessagetoError ||
      appnotificationtoError ||
      bodynotificationError ||
      texttoError ||
      bodytextError ||
      templateError ||
      checkboxError ||
      emailbodyError ||
      appNotificationTitleError
    ) {
      this.setState({
        emailformError,
        toError,
        subjectError,
        headerError,
        bodyError,
        appmessagetoError,
        bodytextError,
        texttoError,
        bodynotificationError,
        appnotificationtoError,
        templateError,
        checkboxError,
        emailbodyError,
        appNotificationTitleError,
      });
      return false;
    }
    return true;
  }

  async getRoleData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var roleList = [];
    var newRoleList = [];
    var roleListTotal = 0;

    let res = await RolePermissionService.getRoles(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      roleList = res.data ? res.data : [];
      roleListTotal = roleList ? roleList.length : 0;

      newRoleList =
        roleListTotal > 0
          ? roleList.map(({ role_id, slug, role_title, role_group }) => ({
              value: slug,
              label: role_title,
              group: role_group,
              role_id: role_id,
            }))
          : [];
    }
    this.setState({ roleList: roleList, newRoleList: newRoleList });
  }

  async getStaffData(queryString = "") {
    var staffList = [];
    var staffListTotal = 0;

    let res = await EmailService.getstaff("is_dropdown=1");
    if (global.successStatus.includes(res.status)) {
      staffList = res.data ? res.data : [];
      staffListTotal = staffList ? staffList.length : 0;
    }
    var newStaffList =
      staffListTotal > 0
        ? staffList.map(
            ({
              user_id,
              first_name,
              last_name,
              role_title,
              email,
              phone_number,
            }) => ({
              value: user_id,
              phone_number: phone_number,
              email: email,
              label: first_name + " " + last_name + " (" + role_title + ")",
            })
          )
        : [];
    this.setState({
      staffListTotal: staffListTotal,
      newStaffList: newStaffList,
    });
    this.getDataFromSlug();
  }

  saveOrUpdateEmail = async (e) => {
    e.preventDefault();
    var cc = [];
    var bcc = [];
    var to_id = [];
    var appmessageto = [];
    var appnotificationto = [];
    var textmessageto = [];
    this.setState({
      alertModalMessage: "",
    });
    if (this.validate()) {
      if (this.state.selectedStaffList != null) {
        this.state.selectedStaffList.forEach((item) => {
          cc = [...cc, item.email];
        });
      }

      if (this.state.selectedBccStaffList != null) {
        this.state.selectedBccStaffList.forEach((item) => {
          bcc = [...bcc, item.email];
        });
      }

      if (this.state.selectedRoleList != null) {
        this.state.selectedRoleList.forEach((item) => {
          to_id = [...to_id, item.role_id];
        });
      }

      if (this.state.selectedStaffAppMessage != null) {
        this.state.selectedStaffAppMessage.forEach((item) => {
          appmessageto = [...appmessageto, item.phone_number];
        });
      }

      if (this.state.selectedStaffAppnotification != null) {
        this.state.selectedStaffAppnotification.forEach((item) => {
          appnotificationto = [...appnotificationto, item.phone_number];
        });
      }

      if (this.state.selectedStafftextmessage != null) {
        this.state.selectedStafftextmessage.forEach((item) => {
          textmessageto = [...textmessageto, item.phone_number];
        });
      }
      let toid_str = to_id.join(",");
      let cc_str = cc.join(",");
      let bcc_str = bcc.join(",");
      let appmessage_str = appmessageto.join(",");
      let appnotification_str = appnotificationto.join(",");
      let textmessage_str = textmessageto.join(",");
      this.setState({ loading: true, isSubmit: true });
      let inputData = {
        template_name: this.state.template_name ? this.state.template_name : "",
        template_desc: this.state.template_desc ? this.state.template_desc : "",
        email_form:
          this.state.is_email_enable == true
            ? this.state.email_form
              ? this.state.email_form + "@orionhaus.com"
              : ""
            : "",
        email_to:
          this.state.is_email_enable == true
            ? this.state.usertotype
              ? this.state.usertotype
              : this.state.selectedTypeList[0].value
              ? this.state.selectedTypeList[0].value
              : ""
            : "",
        email_to_id:
          this.state.is_email_enable == true
            ? this.state.usertotype === 3
              ? this.state.selectedStaffToList.email
              : this.state.user_id_to
              ? this.state.selectedStaffToList.email
              : this.state.email_to === 3
              ? this.state.selectedStaffToList[0].value
              : this.state.usertotype === 7 || this.state.email_to === 7
              ? toid_str
              : ""
            : "",
        email_cc_id:
          this.state.is_email_enable == true
            ? this.state.getvalueofcc
              ? this.state.getvalueofcc
              : this.state.email_cc_id
              ? this.state.email_cc_id
              : ""
            : "",
        email_cc:
          this.state.is_email_enable == true
            ? this.state.getvalueofcc === 3 || this.state.email_cc_id == 3
              ? cc_str
              : this.state.email_cc_id === 2 || this.state.getvalueofcc === 2
              ? this.state.email_cc
              : ""
            : "",
        email_bcc_id:
          this.state.is_email_enable == true
            ? this.state.getvalueofbcc
              ? this.state.getvalueofbcc
              : this.state.email_bcc_id
              ? this.state.email_bcc_id
              : ""
            : "",
        email_bcc:
          this.state.is_email_enable == true
            ? this.state.getvalueofbcc === 3 || this.state.email_bcc_id == 3
              ? bcc_str
              : this.state.email_bcc_id === 2 || this.state.getvalueofbcc === 2
              ? this.state.email_bcc
              : ""
            : "",
        header_logo_temp: this.state.is_email_enable == true ? this.state.headervalue
          ? this.state.headervalue
          : this.state.selectedHeader[0].value : "",
        subject:
          this.state.is_email_enable == true
            ? this.state.subject
              ? this.state.subject
              : ""
            : "",
        email_body:
          this.state.is_email_enable == true
            ? this.state.email_body
              ? this.state.email_body
              : ""
            : "",
        app_message_to:
          this.state.is_message_enable == true
            ? this.state.usertoapp
              ? this.state.usertoapp
              : this.state.app_message_to
            : "",
        app_message_to_id:
          this.state.is_message_enable == true
            ? this.state.usertoapp === 3 || this.state.app_message_to === 3
              ? appmessage_str
              : ""
            : "",
        app_message_body:
          this.state.is_message_enable == true
            ? this.state.app_message_body
              ? this.state.app_message_body
              : ""
            : "",
        app_notification_to:
          this.state.is_notification_enable == true
            ? this.state.usertoNotification
              ? this.state.usertoNotification
              : this.state.app_notification_to
            : "",
        app_notification_to_id:
          this.state.is_notification_enable == true
            ? this.state.usertoNotification === 3 ||
              this.state.app_notification_to === 3
              ? appnotification_str
              : ""
            : "",
        app_notification_title:
          this.state.is_notification_enable == true
            ? this.state.app_notification_title
              ? this.state.app_notification_title
              : ""
            : "",
        app_notification_body:
          this.state.is_notification_enable == true
            ? this.state.app_notification_body
              ? this.state.app_notification_body
              : ""
            : "",
        text_message_to:
          this.state.is_text_message_enable == true
            ? this.state.usertoTextmessage
              ? this.state.usertoTextmessage
              : this.state.text_message_to
            : "",
        text_message_to_id:
          this.state.is_text_message_enable == true
            ? this.state.usertoTextmessage === 3 ||
              this.state.text_message_to === 3
              ? textmessage_str
              : ""
            : "",
        test_message_body:
          this.state.is_text_message_enable == true
            ? this.state.test_message_body
              ? this.state.test_message_body
              : ""
            : "",
        is_email_enable: this.state.is_email_enable ? 1 : 0,
        is_message_enable: this.state.is_message_enable ? 1 : 0,
        is_notification_enable: this.state.is_notification_enable ? 1 : 0,
        is_text_message_enable: this.state.is_text_message_enable ? 1 : 0,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.slug !== undefined) {
        this.setState({ loading: true, isSubmit: true });
        let res = await AllTemplateService.updateTemplate(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/all-template"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        this.setState({ loading: true, isSubmit: true });
        let res = await AllTemplateService.createTemplate(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/all-template"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ loading: false, isSubmit: false });
      this.validator.showMessages();
    }
  };

  async getDataFromSlug() {
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await AllTemplateService.getTemplate(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        var fromemail = resData.email_form;
        var dataemail = fromemail.split("@");
        resData.template_name = resData.template_name
          ? resData.template_name
          : "";
        resData.template_desc = resData.template_desc
          ? resData.template_desc
          : "";
        resData.email_form = resData.email_form ? dataemail[0] : "";
        resData.email_cc_id = resData.email_cc_id ? resData.email_cc_id : "";
        resData.email_cc = resData.email_cc ? resData.email_cc : "";
        resData.email_bcc_id = resData.email_bcc_id ? resData.email_bcc_id : "";
        resData.email_bcc = resData.email_bcc ? resData.email_bcc : "";
        resData.email_to = resData.email_to ? resData.email_to : "";
        resData.email_to_id =
          resData.email_to === 3 || resData.email_to === 7
            ? resData.email_to_id
            : "";
        resData.status_id = res.data
          ? res.data.status_id === 1
            ? true
            : false
          : false;
        var toData =
          resData.email_to === 7 ? resData.email_to_id.split(",") : "";

        var ccdata =
          resData.email_cc_id === 3 ? resData.email_cc.split(",") : "";
        var bccdata =
          resData.email_bcc_id === 3 ? resData.email_bcc.split(",") : "";
        var messagedata =
          resData.app_message_to === 3
            ? resData.app_message_to_id.split(",")
            : "";
        var notificationdata =
          resData.app_notification_to === 3
            ? resData.app_notification_to_id.split(",")
            : "";
        var textmessagedata =
          resData.text_message_to === 3
            ? resData.text_message_to_id.split(",")
            : "";
        var selectedTypeList = [];
        selectedTypeList =
          res.data.email_to === 4
            ? [{ key: 4, value: 4, label: "Resident" }]
            : res.data.email_to === 3
            ? [{ key: 3, value: 3, label: "Staff" }]
            : res.data.email_to === 5
            ? [{ key: 5, value: 5, label: "Guest" }]
            : res.data.email_to === 7
            ? [{ key: 7, value: 7, label: "Staff Role" }]
            : res.data.email_to === 8
            ? [{ key: 8, value: 8, label: "Property Owner" }]
            : [];
        var selectedAppmessage = [];
        selectedAppmessage =
          res.data.app_message_to === 4
            ? [{ key: 4, value: 4, label: "Resident" }]
            : res.data.app_message_to === 3
            ? [{ key: 3, value: 3, label: "Staff" }]
            : res.data.app_message_to === 5
            ? [{ key: 5, value: 5, label: "Guest" }]
            : res.data.app_message_to === 6
            ? [{ key: 6, value: 6, label: "User" }]
            : [];
        var selectedAppNotification = [];
        selectedAppNotification =
          res.data.app_notification_to === 4
            ? [{ key: 4, value: 4, label: "Resident" }]
            : res.data.app_notification_to === 3
            ? [{ key: 3, value: 3, label: "Staff" }]
            : res.data.app_notification_to === 5
            ? [{ key: 5, value: 5, label: "Guest" }]
            : res.data.app_notification_to === 6
            ? [{ key: 6, value: 6, label: "User" }]
            : [];
        var selectedTypeTestmessage = [];
        selectedTypeTestmessage =
          res.data.text_message_to === 4
            ? [{ key: 4, value: 4, label: "Resident" }]
            : res.data.text_message_to === 3
            ? [{ key: 3, value: 3, label: "Staff" }]
            : res.data.text_message_to === 5
            ? [{ key: 5, value: 5, label: "Guest" }]
            : res.data.text_message_to === 6
            ? [{ key: 6, value: 6, label: "User" }]
            : [];
        var selectedCcList = [];
        selectedCcList =
          res.data.email_cc_id === 3
            ? [{ key: 3, value: 3, label: "Staff" }]
            : res.data.email_cc_id === 2
            ? [{ key: 2, value: 2, label: "Custom" }]
            : [];
        var selectedsecondlist = [];
        selectedsecondlist =
          res.data.email_bcc_id === 3
            ? [{ key: 3, value: 3, label: "Staff" }]
            : res.data.email_bcc_id === 2
            ? [{ key: 2, value: 2, label: "Custom" }]
            : [];
        var selectedRoleList = [];
        if (resData.email_to == 7) {
          if (toData[0] !== "") {
            this.state.newRoleList.forEach((item1, i) => {
              toData.length > 0 &&
                toData.forEach((item, i) => {
                  if (item == item1.role_id) {
                    selectedRoleList = [
                      ...selectedRoleList,
                      {
                        value: item,
                        label: item1.label,
                        role_id: item1.role_id,
                      },
                    ];
                  }
                });
            });
          }
        }
        var selectedBccStaffList = [];
        if (resData.email_bcc_id === 3) {
          if (bccdata[0] !== "") {
            this.state.newStaffList.forEach((item1, i) => {
              bccdata.length > 0 &&
                bccdata.forEach((item, i) => {
                  if (item == item1.email) {
                    selectedBccStaffList = [
                      ...selectedBccStaffList,
                      {
                        value: item,
                        email: item1.email,
                        label: item1.label,
                      },
                    ];
                  }
                });
            });
          }
        }
        var selectedStaffToList = [];
        if (resData.email_to === 3) {
          this.state.newStaffList.forEach((item, i) => {
            if (item.email == res.data.email_to_id) {
              selectedStaffToList = res.data
                ? [
                    {
                      value: res.data.email_to_id,
                      label: item.label,
                    },
                  ]
                : "";
            }
          });
        }
        var selectedStaffList = [];
        if (resData.email_cc_id === 3) {
          if (ccdata[0] !== "") {
            this.state.newStaffList.forEach((item1, i) => {
              ccdata.length > 0 &&
                ccdata.forEach((item, i) => {
                  if (item == item1.email) {
                    selectedStaffList = [
                      ...selectedStaffList,
                      {
                        value: res.data.email_cc,
                        email: item1.email,
                        label: item1.label,
                      },
                    ];
                  }
                });
            });
          }
        }

        var selectedStaffAppMessage = [];
        if (resData.app_message_to === 3) {
          this.state.newStaffList.forEach((item1, i) => {
            messagedata.length > 0 &&
              messagedata.forEach((item, i) => {
                if (item == item1.phone_number) {
                  selectedStaffAppMessage = [
                    ...selectedStaffAppMessage,
                    {
                      value: item,
                      phone_number: item1.phone_number,
                      label: item1.label,
                    },
                  ];
                }
              });
          });
        }

        var selectedStaffAppnotification = [];
        if (resData.app_notification_to === 3) {
          this.state.newStaffList.forEach((item1, i) => {
            notificationdata.length > 0 &&
              notificationdata.forEach((item, i) => {
                if (item == item1.phone_number) {
                  selectedStaffAppnotification = [
                    ...selectedStaffAppnotification,
                    {
                      value: item,
                      phone_number: item1.phone_number,
                      label: item1.label,
                    },
                  ];
                }
              });
          });
        }

        var selectedStafftextmessage = [];
        if (resData.text_message_to === 3) {
          this.state.newStaffList.forEach((item1, i) => {
            textmessagedata.length > 0 &&
              textmessagedata.forEach((item, i) => {
                if (item == item1.phone_number) {
                  selectedStafftextmessage = [
                    ...selectedStafftextmessage,
                    {
                      value: item,
                      phone_number: item1.phone_number,
                      label: item1.label,
                    },
                  ];
                }
              });
          });
        }
        var selectedHeader = [];
        selectedHeader =
          res.data.header_logo_temp === 1
            ? [{ key: 1, value: 1, label: "Property" }]
            : res.data.header_logo_temp === 2
            ? [{ key: 2, value: 2, label: "Orion Haus" }]
            : [];
      }
      this.setState({
        selectedTypeList: selectedTypeList,
        selectedStaffList: selectedStaffList,
        selectedCcList: selectedCcList,
        selectedsecondlist: selectedsecondlist,
        selectedBccStaffList: selectedBccStaffList,
        selectedRoleList: selectedRoleList,
        selectedStaffToList: selectedStaffToList,
        selectedAppmessage: selectedAppmessage,
        selectedAppNotification: selectedAppNotification,
        selectedTypeTestmessage: selectedTypeTestmessage,
        selectedStafftextmessage: selectedStafftextmessage,
        selectedStaffAppnotification: selectedStaffAppnotification,
        selectedStaffAppMessage: selectedStaffAppMessage,
        selectedHeader: selectedHeader,
        is_checkbox: [
          ...this.state.is_checkbox,
          ...[
            resData.is_email_enable,
            resData.is_message_enable,
            resData.is_notification_enable,
            resData.is_text_message_enable,
          ],
        ],
      });
      this.setState(resData);
    }
  }
  openListingPopup = () => {
    if (this.state.listingPopup == true) {
      this.setState({ listingPopup: false });
    } else {
      // this.getData();
      this.setState({ listingPopup: true });
    }
  };

  openBodyPopup = () => {
    if (this.state.bodyPopup == true) {
      this.setState({ bodyPopup: false });
    } else {
      // this.getData();
      this.setState({ bodyPopup: true });
    }
  };
  openAppBodyPopup = () => {
    if (this.state.appbodyPopup == true) {
      this.setState({ appbodyPopup: false });
    } else {
      // this.getData();
      this.setState({ appbodyPopup: true });
    }
  };

  openAppNotificaionPopup = () => {
    if (this.state.appnotifybodyPopup == true) {
      this.setState({ appnotifybodyPopup: false });
    } else {
      // this.getData();
      this.setState({ appnotifybodyPopup: true });
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? value : value;

    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
    });
    if (input.template_name !== "") {
      this.setState({ templateError: null });
    }
    if (input.email_form !== "") {
      this.setState({ emailformError: null });
    }
    if (input.subject !== "") {
      this.setState({ subjectError: null });
    }
    if (input.subject !== "") {
      this.setState({ subjectError: null });
    }
    if (input.app_message_body !== "") {
      this.setState({ bodyError: null });
    }
    if (input.app_notification_body !== "") {
      this.setState({ bodynotificationError: null });
    }
    if (input.app_notification_title !== "") {
      this.setState({ appNotificationTitleError: null });
    }
    if (input.test_message_body !== "") {
      this.setState({ bodytextError: null });
    }
    if (
      input.is_email_enable == true ||
      input.is_message_enable == true ||
      input.is_notification_enable == true ||
      input.is_text_message_enable == true
    ) {
      this.setState({ checkboxError: null });
    }
    if (this.state.slug == undefined) {
      var dayArr = [...this.state.is_checkbox];
      var checked = event.target.checked;
      if (checked == false) {
        dayArr = [...dayArr.slice(0, -1)];
      } else if (checked == true) {
        dayArr.push(value);
      }
      this.setState({ is_checkbox: dayArr });
    }
    this.setState({ confirm_back: 1 });
  };

  handleChangeStaffList(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    this.setState({
      selectedStaffList: value,
      user_id: user_id,
    });
  }

  handleChangeStaffToList(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    this.setState({
      selectedStaffToList: value,
      user_id_to: value.value,
    });
  }

  handleChangeRoleList(value) {
    let role_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        role_id.push(item.value);
      });
    }
    this.setState({
      selectedRoleList: value,
      role_id: role_id,
    });
  }

  handleChangeStaffAppMessage(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    this.setState({
      selectedStaffAppMessage: value,
      user_id: user_id,
    });
  }

  handleChangeStaffAppNotification(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    this.setState({
      selectedStaffAppnotification: value,
      user_id: user_id,
    });
  }

  handleChangeStafftextmessage(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    this.setState({
      selectedStafftextmessage: value,
      user_id: user_id,
    });
    this.setState({ texttoError: null });
  }

  handleChangeBccStaffList(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    this.setState({
      selectedBccStaffList: value,
      user_id: user_id,
    });
  }

  handleChangeUserList(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedTypeList: value,
      usertotype: type,
    });
    if (this.state.slug !== undefined) {
      this.setState({
        email_to: "",
      });
    }
    this.setState({ confirm_back: 1 });
    this.setState({ toError: "" });
  }

  handleChangeUserApp(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedAppmessage: value,
      usertoapp: type,
    });
    if (this.state.slug !== undefined) {
      this.setState({
        app_message_to: "",
      });
    }
    this.setState({ confirm_back: 1 });
    this.setState({ appmessagetoError: null });
  }

  handleChangeUserNotification(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedAppNotification: value,
      usertoNotification: type,
    });
    if (this.state.slug !== undefined) {
      this.setState({
        app_notification_to: "",
      });
    }
    this.setState({ confirm_back: 1 });
    this.setState({ appnotificationtoError: null });
  }

  handleChangeUserText(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedTypeTestmessage: value,
      usertoTextmessage: type,
    });
    if (this.state.slug !== undefined) {
      this.setState({
        text_message_to: "",
      });
    }
    this.setState({ confirm_back: 1 });
  }

  handleChangeCCList(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedCcList: value,
      newTypeList: newTypeList,
      getvalueofcc: type,
    });
    this.setState({ confirm_back: 1 });
    if (this.state.slug !== undefined) {
      this.setState({
        email_cc_id: "",
      });
      if (this.state.email_cc_id === 3) {
        this.setState({
          email_cc: "",
        });
      }
    }
  }

  handleChangeBCCList(value) {
    let newTypeList12 = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList12.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedsecondlist: value,
      getvalueofbcc: type,
      newTypeList12: newTypeList12,
    });
    this.setState({ confirm_back: 1 });
    if (this.state.slug !== undefined) {
      this.setState({
        email_bcc_id: "",
      });
      if (this.state.email_bcc_id === 3) {
        this.setState({
          email_bcc: "",
        });
      }
    }
  }

  handleChangemodulereferListemail(value) {
    let newTypeList12 = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList12.push(item.value);
      });
    }
    let dynmicvariable = value.label;
    this.setState({
      selectedModulleRferList: value,
      dynmicvariable: dynmicvariable,
    });
  }

  insertbodyemail = (e) => {
    var dynamictext = this.state.body_variable;
    this.jodit.selection.insertHTML("[" + dynamictext + "]");
    this.setState({ bodyPopup: false, body_variable: "" });
  };

  handleChangeSubjectVariableList(value) {
    let newTypeList13 = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList13.push(item.value);
      });
    }
    let dynmicvariablesbject = value.label;
    this.setState({
      selectedSubjectList: value,
      dynmicvariablesbject: dynmicvariablesbject,
    });
  }

  insertsubject = (e) => {
    let textToInsert = " [" + this.state.subject_variable + "]";
    this.setState({
      subject: this.state.subject
        ? `${this.state.subject} ${textToInsert}`
        : `${textToInsert}`,
    });
    this.setState({ listingPopup: false, subject_variable: "" });
  };

  handleChangeBodyVariableAppMessage(value) {
    let newTypeList13 = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList13.push(item.value);
      });
    }
    let dynmicvariablesbject = value.label;
    this.setState({
      selectedappmessagevariable: value,
      dynmicvariablesbject: dynmicvariablesbject,
    });
  }

  insertappmessage = () => {
    let textToInsert = this.state.app_body_variable
      ? " [" + this.state.app_body_variable + "]"
      : "";
    let textarea = this.textareaRef.current;
    let cursorPosition = textarea.selectionStart;
    let currentValue = textarea.value;
    let updatedValue =
      currentValue.substring(0, cursorPosition) +
      textToInsert +
      currentValue.substring(cursorPosition);

    this.setState(
      {
        app_message_body: updatedValue,
        appbodyPopup: false,
        app_body_variable: "",
      },
      () => {
        textarea.focus();
        textarea.selectionStart = cursorPosition + textToInsert.length;
        textarea.selectionEnd = cursorPosition + textToInsert.length;
      }
    );
  };

  handleChangeappnotificationvariable(value) {
    let newTypeList13 = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList13.push(item.value);
      });
    }
    let dynmicvariablesbject = value.label;
    this.setState({
      selectedappnotificationvariable: value,
      dynmicvariablesbject: dynmicvariablesbject,
    });
  }

  insertappnotification = () => {
    let textToInsert = " [" + this.state.app_body_notification_variable + "]";
    let textarea = this.textareaRefAppnotify.current;
    let cursorPosition = textarea.selectionStart;
    let currentValue = textarea.value;
    let updatedValue =
      currentValue.substring(0, cursorPosition) +
      textToInsert +
      currentValue.substring(cursorPosition);

    this.setState(
      {
        app_notification_body: updatedValue,
        appnotifybodyPopup: false,
        app_body_notification_variable: "",
      },
      () => {
        textarea.focus();
        textarea.selectionStart = cursorPosition + textToInsert.length;
        textarea.selectionEnd = cursorPosition + textToInsert.length;
      }
    );
  };

  handleChangetextmessagevariable(value) {
    let newTypeList13 = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList13.push(item.value);
      });
    }
    let dynmicvariablesbject = value.label;
    this.setState({
      selectedtextmessagevariable: value,
      dynmicvariablesbject: dynmicvariablesbject,
    });
  }

  inserttextmessagevariable = (e) => {
    let textToInsert = " [" + this.state.dynmicvariablesbject + "]";
    this.setState({
      test_message_body: this.state.test_message_body
        ? `${this.state.test_message_body} ${textToInsert}`
        : `${textToInsert}`,
    });
  };

  openDeleteNearByAttractionModal(slug) {
    this.setState({ deleteNearByAttractionModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteAttrcationModal() {
    this.setState({ deleteNearByAttractionModal: false });
    this.setState({ slug: "" });
  }

  async deleteCredential(slug) {
    this.setState({ showSpinner: true });
    let res = await AllTemplateService.deleteTemplate(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAttrcationModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      setTimeout(
        () => this.props.history.push("/all-template"),
        global.redirect_time
      );
    } else {
      this.closeDeleteAttrcationModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandlerck(event, index) {
    let value = event.target.value;
    this.setState({
      email_body: value,
    });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  }

  handleChangeHeader(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedHeader: value,
      headervalue: type,
    });
    this.setState({ headerError: "" });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
  }

  setContent(value) {
    this.setState({
      email_body: value,
    });
    this.setState({
      emailbodyError: "",
    });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <SettingSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel">
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">
                            Templates:{this.state.template_name}
                          </h3>

                          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                            {this.state.slug !== undefined ? (
                              <>
                                {global.userPermissions.checkPermission(
                                  "template-delete"
                                ) && (
                                  <span
                                    onClick={() => {
                                      this.openDeleteNearByAttractionModal(
                                        this.state.slug
                                      );
                                    }}
                                    className="deleteicon mg-r-20"
                                  >
                                    <img src={deleteicon} />
                                  </span>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <Link to={"/all-template"}>
                              <button className="btn-success-outline mg-r-20">
                                Cancel
                              </button>
                            </Link>
                            <button
                              type="button"
                              className="btn-success-outline-small ml-3 button-width"
                              onClick={this.saveOrUpdateEmail}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : this.state.slug
                                ? "Update"
                                : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="scrolling-carousel pd-l-15 pd-r-15">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="info"
                        onSelect={(e) => this.getActiveClass(e)}
                        eventKey={this.state.activeClass}
                      >
                        <ScrollingCarousel
                          rightArrow={true}
                          leftArrow={true}
                          className="swipesection scroll_nav_mob"
                        >
                          <Nav.Item>
                            <Nav.Link
                              eventKey="info"
                              id="infoForm"
                              className={
                                this.state.activeClass === "info"
                                  ? "active"
                                  : ""
                              }
                            >
                              Info
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link
                              eventKey="email"
                              id="email"
                              className={
                                this.state.activeClass === "email"
                                  ? "active"
                                  : ""
                              }
                            >
                              Email
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="appMessage"
                              id="appMessage"
                              className={
                                this.state.activeClass === "appMessage"
                                  ? "active"
                                  : ""
                              }
                            >
                              App Message
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="appNotification"
                              id="appNotification"
                              className={
                                this.state.activeClass === "appNotification"
                                  ? "active"
                                  : ""
                              }
                            >
                              App Notification
                            </Nav.Link>
                          </Nav.Item>
                          {/* <Nav.Item>
                            <Nav.Link
                              eventKey="textMessage"
                              id="textMessage"
                              className={
                                this.state.activeClass === "textMessage"
                                  ? "active"
                                  : ""
                              }

                            >
                              Text Message
                            </Nav.Link>
                          </Nav.Item> */}
                        </ScrollingCarousel>
                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15 ">
                          <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                          />
                        </div>
                        <Tab.Content>
                          <Tab.Pane eventKey="info">
                            <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                              <h3 className="mg-l-5 mg-t-15 background-head d-flex  align-items-center justify-content-start">
                                Info
                              </h3>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                              <label className="form-label text-left pd-b-5">
                                Template Name{" "}
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="template_name"
                                value={this.state.template_name}
                                maxLength="100"
                                //tabIndex="1"
                                autoComplete="off"
                              />
                              <div className="text-danger">
                                {this.state.templateError}
                              </div>
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                              <label className="form-label text-left pd-b-5">
                                Template Description{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                name="template_desc"
                                value={this.state.template_desc}
                                className="form-control max_width_100"
                                id="description"
                                as="textarea"
                                rows="3"
                                // placeholder="Username"
                              />
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-8 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                              <div className="col-md-1 pl-0">
                                <label className="form-label text-left pd-b-5">
                                  Status
                                </label>
                              </div>
                              <div className="col-8 col-md-7 col-lg-7 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                                <b
                                  className={
                                    this.state.status_id === true
                                      ? "active"
                                      : "inactive"
                                  }
                                ></b>
                                <div
                                  className={
                                    this.state.status_id === true
                                      ? "az-toggle on"
                                      : "az-toggle"
                                  }
                                  onClick={() => this.changeStatus()}
                                >
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="email">
                            <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                              <h3 className="mg-l-5 mg-t-15 background-head d-flex  align-items-center justify-content-start">
                                <div>
                                  <Form.Check
                                    onChange={this.changeHandler}
                                    name="is_email_enable"
                                    checked={this.state.is_email_enable}
                                    type={"checkbox"}
                                    id="emailcheckbox"
                                    value="1"
                                    label={""}
                                    className={"ckbox mg-r-10"}
                                  />
                                </div>
                                Email
                              </h3>
                            </div>

                            {this.state.is_email_enable == true ? (
                              <div className="mg-t-18 brandList table_issue_roles cred_drop_issue">
                                <div className=" ">
                                  {/* <div className="row align-items-center pd-b-30 pd-xs-b-0 pd-lg-b-30 pd-l-30 pd-r-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30"> */}
                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        From{" "}
                                      </label>
                                      <div className="col-md-12 p-0">
                                        <div className="input-group-prepend prefix-dollar percanteg-prefix mail-right-sym">
                                          <Form.Control
                                            type="text"
                                            onChange={this.changeHandler}
                                            name="email_form"
                                            value={this.state.email_form}
                                            maxLength="50"
                                            //tabIndex="1"
                                            autoComplete="off"
                                          />
                                          <span className="input-group-text percanteg_symbl">
                                            @orionhaus.com
                                          </span>
                                        </div>
                                      </div>

                                      <div className="text-danger">
                                        {this.state.emailformError}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        To{" "}
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedTypeList}
                                        options={this.state.typeList}
                                        onChange={(value) =>
                                          this.handleChangeUserList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedTypeList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      {this.state.toError ? (
                                        <div className="text-danger">
                                          {this.state.toError}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "to",
                                          this.state.selectedTypeList,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>

                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      {this.state.usertotype === 3 ||
                                      this.state.email_to == 3 ? (
                                        <div className="">
                                          <label className="form-label text-left pd-b-5">
                                            Staff Users{" "}
                                          </label>
                                          <Select
                                            styles={customStyles}
                                            menuPlacement="auto"
                                            className="multiselect"
                                            value={
                                              this.state.selectedStaffToList
                                            }
                                            options={this.state.newStaffList}
                                            getOptionValue={(option) =>
                                              `${option.label}`
                                            }
                                            onChange={(value) =>
                                              this.handleChangeStaffToList(
                                                value
                                              )
                                            }
                                            defaultValue={
                                              this.state.selectedStaffToList
                                            }
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 2,
                                              colors: {
                                                ...theme.colors,
                                                primary: "#fff",
                                                primary75: "#000",
                                                primary50: "#000",
                                                primary25: "#000",
                                              },
                                            })}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.usertotype === 7 ||
                                      this.state.email_to === 7 ? (
                                        <div className="">
                                          <label className="form-label text-left pd-b-5">
                                            Staff Role{" "}
                                          </label>
                                          <Select
                                            isMulti
                                            styles={customStyles}
                                            isClearable={true}
                                            className="multiselect roleselect"
                                            menuPlacement="auto"
                                            value={this.state.selectedRoleList}
                                            options={this.state.newRoleList}
                                            getOptionValue={(option) =>
                                              `${option.label}`
                                            }
                                            onChange={(value) =>
                                              this.handleChangeRoleList(value)
                                            }
                                            defaultValue={
                                              this.state.selectedRoleList
                                            }
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 2,
                                              colors: {
                                                ...theme.colors,
                                                primary: "#fff",
                                                primary75: "#000",
                                                primary50: "#000",
                                                primary25: "#000",
                                              },
                                            })}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Cc{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedCcList}
                                        options={this.state.ccforList}
                                        onChange={(value) =>
                                          this.handleChangeCCList(value)
                                        }
                                        defaultValue={this.state.selectedCcList}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      {this.state.getvalueofcc === 3 ||
                                      this.state.email_cc_id == 3 ? (
                                        <div className="">
                                          <label className="form-label text-left pd-b-5">
                                            Cc (Staff Users){" "}
                                            <span className="optional">
                                              (Optional)
                                            </span>
                                          </label>
                                          <Select
                                            isMulti
                                            styles={customStyles}
                                            isClearable={true}
                                            menuPlacement="auto"
                                            className="multiselect"
                                            value={this.state.selectedStaffList}
                                            options={this.state.newStaffList}
                                            getOptionValue={(option) =>
                                              `${option.label}`
                                            }
                                            onChange={(value) =>
                                              this.handleChangeStaffList(value)
                                            }
                                            defaultValue={
                                              this.state.selectedStaffList
                                            }
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 2,
                                              colors: {
                                                ...theme.colors,
                                                primary: "#fff",
                                                primary75: "#000",
                                                primary50: "#000",
                                                primary25: "#000",
                                              },
                                            })}
                                          />
                                        </div>
                                      ) : this.state.getvalueofcc === 2 ||
                                        this.state.email_cc_id == 2 ? (
                                        <div className="">
                                          <label className="form-label text-left pd-b-5">
                                            Cc (Custom Email)
                                            <span className="optional">
                                              (Optional)
                                            </span>
                                          </label>
                                          <Form.Control
                                            type="text"
                                            onChange={this.changeHandler}
                                            name="email_cc"
                                            value={this.state.email_cc}
                                            maxLength="50"
                                            //tabIndex="1"
                                            autoComplete="off"
                                          />
                                          {this.state.errors.cc ? (
                                            <div className="text-danger">
                                              {this.state.errors.cc}
                                            </div>
                                          ) : (
                                            this.validator.message(
                                              "Custom email",
                                              this.state.cc,
                                              "email",
                                              { className: "text-danger" }
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Bcc{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedsecondlist}
                                        options={this.state.BccforList}
                                        onChange={(value) =>
                                          this.handleChangeBCCList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedsecondlist
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      {this.state.getvalueofbcc === 3 ||
                                      this.state.email_bcc_id == 3 ? (
                                        <div className="">
                                          <label className="form-label text-left pd-b-5">
                                            Bcc (Staff Users){" "}
                                            <span className="optional">
                                              (Optional)
                                            </span>
                                          </label>
                                          <Select
                                            isMulti
                                            styles={customStyles}
                                            menuPlacement="auto"
                                            className="multiselect"
                                            value={
                                              this.state.selectedBccStaffList
                                            }
                                            options={this.state.newStaffList}
                                            getOptionValue={(option) =>
                                              `${option.label}`
                                            }
                                            onChange={(value) =>
                                              this.handleChangeBccStaffList(
                                                value
                                              )
                                            }
                                            defaultValue={
                                              this.state.selectedBccStaffList
                                            }
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 2,
                                              colors: {
                                                ...theme.colors,
                                                primary: "#fff",
                                                primary75: "#000",
                                                primary50: "#000",
                                                primary25: "#000",
                                              },
                                            })}
                                          />
                                        </div>
                                      ) : this.state.getvalueofbcc === 2 ||
                                        this.state.email_bcc_id == 2 ? (
                                        <div className="">
                                          <label className="form-label text-left pd-b-5">
                                            Bcc (Custom Email)
                                            <span className="optional">
                                              (Optional)
                                            </span>
                                          </label>
                                          <Form.Control
                                            type="text"
                                            onChange={this.changeHandler}
                                            name="email_bcc"
                                            value={this.state.email_bcc}
                                            maxLength="50"
                                            //tabIndex="1"
                                            autoComplete="off"
                                          />
                                          {this.state.errors.email_bcc ? (
                                            <div className="text-danger">
                                              {this.state.errors.email_bcc}
                                            </div>
                                          ) : (
                                            this.validator.message(
                                              "email_bcc",
                                              this.state.bcc,
                                              "email",
                                              { className: "text-danger" }
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      <div className="">
                                        <label className="form-label text-left pd-b-5">
                                          Header Logo{" "}
                                        </label>
                                        <Select
                                          styles={customStyles}
                                          isClearable={true}
                                          className="multiselect"
                                          menuPlacement="auto"
                                          value={this.state.selectedHeader}
                                          options={this.state.headerlogo}
                                          onChange={(value) =>
                                            this.handleChangeHeader(value)
                                          }
                                          defaultValue={
                                            this.state.selectedHeader
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#fff",
                                              primary75: "#000",
                                              primary50: "#000",
                                              primary25: "#000",
                                            },
                                          })}
                                        />
                                        {this.state.headerError ? (
                                        <div className="text-danger">
                                          {this.state.headerError}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "header logo",
                                          this.state.selectedHeader,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                        <label className="form-label text-left pd-b-5">
                                          Subject{" "}
                                          {/* <span className="optional">(Optional)</span> */}
                                        </label>
                                        <button
                                          className="btn btn_var_temp mg-b-5"
                                          onClick={this.openListingPopup}
                                        >
                                          Subject Variable
                                        </button>
                                      </div>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="subject"
                                        value={this.state.subject}
                                        autoComplete="off"
                                        id="subjecttext"
                                      />
                                      <div className="text-danger">
                                        {this.state.subjectError}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-12 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30 jodit_cnt_box">
                                      <div className="d-flex flex-wrap- justify-content-between align-items-center ">
                                        <label className="form-label text-left pd-b-5">
                                          Body{" "}
                                        </label>
                                        <button
                                          className="btn btn_var_temp mg-b-5"
                                          onClick={this.openBodyPopup}
                                        >
                                          Body Variable
                                        </button>
                                      </div>
                                      <JoditEditor
                                        config={this.editorConfig}
                                        ref={null}
                                        name="email_body"
                                        id="email_body"
                                        value={this.state.email_body}
                                        onBlur={(newContent) =>
                                          this.setContent(newContent)
                                        }
                                        onChange={(newContent) =>
                                          this.setContent(newContent)
                                        }
                                      />
                                      <div className="text-danger">
                                        {this.state.emailbodyError}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="appMessage">
                            <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                              <h3 className="mg-l-5 mg-t-15 background-head d-flex align-items-center justify-content-start">
                                <div>
                                  <Form.Check
                                    onChange={this.changeHandler}
                                    name="is_message_enable"
                                    checked={this.state.is_message_enable}
                                    type={"checkbox"}
                                    id="appmessagecheckbox"
                                    value="1"
                                    label={""}
                                    className={"ckbox mg-r-10"}
                                  />
                                </div>
                                App Message
                              </h3>
                            </div>

                            {this.state.is_message_enable == true ? (
                              <>
                                <div className="mg-t-18 brandList table_issue_roles cred_drop_issue">
                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        To{" "}
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={this.state.selectedAppmessage}
                                        options={this.state.typeList}
                                        onChange={(value) =>
                                          this.handleChangeUserApp(value)
                                        }
                                        defaultValue={
                                          this.state.selectedAppmessage
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      <div className="text-danger">
                                        {this.state.appmessagetoError}
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      {this.state.usertoapp === 3 ||
                                      this.state.app_message_to == 3 ? (
                                        <div className="">
                                          <label className="form-label text-left pd-b-5">
                                            Staff Users{" "}
                                          </label>
                                          <Select
                                            isMulti
                                            styles={customStyles}
                                            isClearable={true}
                                            menuPlacement="auto"
                                            className="multiselect"
                                            value={
                                              this.state.selectedStaffAppMessage
                                            }
                                            options={this.state.newStaffList}
                                            getOptionValue={(option) =>
                                              `${option.label}`
                                            }
                                            onChange={(value) =>
                                              this.handleChangeStaffAppMessage(
                                                value
                                              )
                                            }
                                            defaultValue={
                                              this.state.selectedStaffAppMessage
                                            }
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 2,
                                              colors: {
                                                ...theme.colors,
                                                primary: "#fff",
                                                primary75: "#000",
                                                primary50: "#000",
                                                primary25: "#000",
                                              },
                                            })}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                        <label className="form-label text-left pd-b-5  ">
                                          Body{" "}
                                          {/* <span className="optional">(Optional)</span> */}
                                        </label>
                                        <button
                                          className="btn btn_var_temp mg-b-5"
                                          onClick={this.openAppBodyPopup}
                                        >
                                          Body Variable
                                        </button>
                                      </div>
                                      <Form.Control
                                        ref={this.textareaRef}
                                        onChange={(e) =>
                                          this.setState({
                                            app_message_body: e.target.value,
                                          })
                                        }
                                        name="app_message_body"
                                        value={this.state.app_message_body}
                                        className="form-control max_width_100"
                                        type="text"
                                        rows="13"
                                        as="textarea"
                                        autoComplete="off"
                                      />
                                      <div className="text-danger">
                                        {this.state.bodyError}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="appNotification">
                            <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                              <h3 className="mg-l-5 mg-t-15 background-head d-flex align-items-center justify-content-start">
                                <div>
                                  <Form.Check
                                    onChange={this.changeHandler}
                                    name="is_notification_enable"
                                    checked={this.state.is_notification_enable}
                                    type={"checkbox"}
                                    value="1"
                                    id="appnotificationcheckbox"
                                    label={""}
                                    className={"ckbox mg-r-10"}
                                  />
                                </div>
                                App Notification
                              </h3>
                            </div>

                            {this.state.is_notification_enable == true ? (
                              <>
                                <div className="mg-t-18 brandList table_issue_roles cred_drop_issue">
                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        To{" "}
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={
                                          this.state.selectedAppNotification
                                        }
                                        options={this.state.typeList}
                                        onChange={(value) =>
                                          this.handleChangeUserNotification(
                                            value
                                          )
                                        }
                                        defaultValue={
                                          this.state.selectedAppNotification
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      <div className="text-danger">
                                        {this.state.appnotificationtoError}
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      {this.state.usertoNotification === 3 ||
                                      this.state.app_notification_to === 3 ? (
                                        <div className="">
                                          <label className="form-label text-left pd-b-5">
                                            Staff Users{" "}
                                          </label>
                                          <Select
                                            isMulti
                                            styles={customStyles}
                                            isClearable={true}
                                            menuPlacement="auto"
                                            className="multiselect"
                                            value={
                                              this.state
                                                .selectedStaffAppnotification
                                            }
                                            options={this.state.newStaffList}
                                            getOptionValue={(option) =>
                                              `${option.label}`
                                            }
                                            onChange={(value) =>
                                              this.handleChangeStaffAppNotification(
                                                value
                                              )
                                            }
                                            defaultValue={
                                              this.state
                                                .selectedStaffAppnotification
                                            }
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 2,
                                              colors: {
                                                ...theme.colors,
                                                primary: "#fff",
                                                primary75: "#000",
                                                primary50: "#000",
                                                primary25: "#000",
                                              },
                                            })}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                        <label className="form-label text-left pd-b-5">
                                          Title{" "}
                                          {/* <span className="optional">(Optional)</span> */}
                                        </label>
                                        {/* <button className="btn btn_var_temp mg-b-5" onClick={this.openListingPopup}>Title Variable</button> */}
                                      </div>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="app_notification_title"
                                        value={
                                          this.state.app_notification_title
                                        }
                                        autoComplete="off"
                                        id="subjecttext"
                                      />
                                      <div className="text-danger">
                                        {this.state.appNotificationTitleError}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <div className="d-flex flex-wrap- justify-content-between align-items-center">
                                        <label className="form-label text-left pd-b-5  ">
                                          Body{" "}
                                          {/* <span className="optional">(Optional)</span> */}
                                        </label>
                                        <button
                                          className="btn btn_var_temp mg-b-5"
                                          onClick={this.openAppNotificaionPopup}
                                        >
                                          Body Variable
                                        </button>
                                      </div>
                                      <Form.Control
                                        ref={this.textareaRefAppnotify}
                                        onChange={(e) =>
                                          this.setState({
                                            app_notification_body:
                                              e.target.value,
                                          })
                                        }
                                        name="app_notification_body"
                                        value={this.state.app_notification_body}
                                        className="form-control max_width_100"
                                        type="text"
                                        rows="13"
                                        as="textarea"
                                        autoComplete="off"
                                      />
                                      <div className="text-danger">
                                        {this.state.bodyError}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="textMessage">
                            <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                              <h3 className="mg-l-5 mg-t-15 background-head mg-l-5 mg-t-15 background-head d-flex align-items-center justify-content-start">
                                <div>
                                  <Form.Check
                                    onChange={this.changeHandler}
                                    name="is_text_message_enable"
                                    checked={this.state.is_text_message_enable}
                                    type={"checkbox"}
                                    id="textmessagecheckbox"
                                    label={""}
                                    className={"ckbox mg-r-10"}
                                  />
                                </div>
                                Text Message
                              </h3>
                            </div>
                            {this.state.is_text_message_enable == true ? (
                              <>
                                <div className="mg-t-18 brandList table_issue_roles cred_drop_issue">
                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        To{" "}
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        menuPlacement="auto"
                                        value={
                                          this.state.selectedTypeTestmessage
                                        }
                                        options={this.state.typeList}
                                        onChange={(value) =>
                                          this.handleChangeUserText(value)
                                        }
                                        defaultValue={
                                          this.state.selectedTypeTestmessage
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                      <div className="text-danger">
                                        {this.state.texttoError}
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                      {this.state.usertoTextmessage === 3 ||
                                      this.state.text_message_to === 3 ? (
                                        <div className="">
                                          <label className="form-label text-left pd-b-5">
                                            Staff Users{" "}
                                          </label>
                                          <Select
                                            isMulti
                                            styles={customStyles}
                                            isClearable={true}
                                            menuPlacement="auto"
                                            className="multiselect"
                                            value={
                                              this.state
                                                .selectedStafftextmessage
                                            }
                                            options={this.state.newStaffList}
                                            getOptionValue={(option) =>
                                              `${option.label}`
                                            }
                                            onChange={(value) =>
                                              this.handleChangeStafftextmessage(
                                                value
                                              )
                                            }
                                            defaultValue={
                                              this.state
                                                .selectedStafftextmessage
                                            }
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 2,
                                              colors: {
                                                ...theme.colors,
                                                primary: "#fff",
                                                primary75: "#000",
                                                primary50: "#000",
                                                primary25: "#000",
                                              },
                                            })}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-5 col-xxl-5"></div>
                                    <div className="col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mg-t-5 mg-md-t-0 mg-lg-b-0 mg-xs-b-15  pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 ">
                                      <div className=" d-flex flex-wrap- justify-content-between align-items-center">
                                        <div className=" w-100 pd-lg-r-15">
                                          <label className="form-label text-left pd-b-5">
                                            Body Variable{" "}
                                          </label>
                                          <Select
                                            styles={customStyles}
                                            isClearable={true}
                                            className="multiselect"
                                            menuPlacement="auto"
                                            value={
                                              this.state
                                                .selectedtextmessagevariable
                                            }
                                            options={this.state.appmodulelist}
                                            name="message"
                                            onChange={(value) =>
                                              this.handleChangetextmessagevariable(
                                                value
                                              )
                                            }
                                            defaultValue={
                                              this.state
                                                .selectedtextmessagevariable
                                            }
                                            isOptionDisabled={(option) =>
                                              option.isdisabled
                                            }
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 2,
                                              colors: {
                                                ...theme.colors,
                                                primary: "#fff",
                                                primary75: "#000",
                                                primary50: "#000",
                                                primary25: "#000",
                                              },
                                            })}
                                          />
                                        </div>
                                        <button
                                          type="button"
                                          className="btn-success-outline-small mg-t-cust-23"
                                          onClick={
                                            this.inserttextmessagevariable
                                          }
                                          disabled={
                                            this.state.isSubmit ? true : false
                                          }
                                        >
                                          insert
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                      <label className="form-label text-left pd-b-5">
                                        Body{" "}
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="test_message_body"
                                        value={this.state.test_message_body}
                                        className="form-control max_width_100"
                                        type="text"
                                        rows="14"
                                        as="textarea"
                                        autoComplete="off"
                                      />
                                      <div className="text-danger">
                                        {this.state.bodytextError}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Modal show={this.state.deleteNearByAttractionModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAttrcationModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCredential(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.listingPopup}
          className="showquickreplyModal model-temp_var"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.openListingPopup()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Insert Subject Variable</h3>
            <div className="row col-12 m-0">
              <div className="var_tmp_modal  mg-t-10">
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">
                    Status(Tabular list)
                  </h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value={"key_status"}
                        type={"radio"}
                        id="1"
                        label={"key_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value={"cleaning_status"}
                        type={"radio"}
                        id="2"
                        label={"cleaning_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="guest_email_status"
                        type={"radio"}
                        id="3"
                        label={"guest_email_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="new_reviews"
                        type={"radio"}
                        id="4"
                        label={"new_reviews"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="newly_sign_lease"
                        type={"radio"}
                        id="90"
                        label={"newly_sign_lease"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="expiring_lease"
                        type={"radio"}
                        id="91"
                        label={"expiring_lease"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="reservation_download"
                        type={"radio"}
                        id="92"
                        label={"reservation_download"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Cleaning</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="cleaning_access_code"
                        type={"radio"}
                        id="39"
                        label={"cleaning_access_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="cleaning_status"
                        type={"radio"}
                        id="40"
                        label={"cleaning_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Guest</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="guest_first_name"
                        type={"radio"}
                        id="5"
                        label={"guest_first_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="guest_last_name"
                        type={"radio"}
                        id="6"
                        label={"guest_last_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="guest_email"
                        type={"radio"}
                        id="7"
                        label={"guest_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="guest_phone_number"
                        type={"radio"}
                        id="8"
                        label={"guest_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Payout</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="resident_name"
                        type={"radio"}
                        id="501"
                        label={"resident_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="unit_name"
                        type={"radio"}
                        id="502"
                        label={"unit_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="property"
                        type={"radio"}
                        id="503"
                        label={"property"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="payout_amount"
                        type={"radio"}
                        id="504"
                        label={"payout_amount"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="payout_duration"
                        type={"radio"}
                        id="505"
                        label={"payout_duration"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Listing</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="listing_name"
                        type={"radio"}
                        id="9"
                        label={"listing_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="unit_name"
                        type={"radio"}
                        id="10"
                        label={"unit_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="bedroom"
                        type={"radio"}
                        id="11"
                        label={"bedroom"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="bathroom"
                        type={"radio"}
                        id="12"
                        label={"bathroom"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="parking_space"
                        type={"radio"}
                        id="132"
                        label={"parking_space"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="parking_slot"
                        type={"radio"}
                        id="13"
                        label={"parking_slot"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="wifi_name"
                        type={"radio"}
                        id="14"
                        label={"wifi_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="wifi_password"
                        type={"radio"}
                        id="15"
                        label={"wifi_password"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="guest_manual"
                        type={"radio"}
                        id="16"
                        label={"guest_manual"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Resident</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="resident_first_name"
                        type={"radio"}
                        id="17"
                        label={"resident_first_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="resident_last_name"
                        type={"radio"}
                        id="18"
                        label={"resident_last_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="resident_phone_number"
                        type={"radio"}
                        id="19"
                        label={"resident_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="resident_email"
                        type={"radio"}
                        id="20"
                        label={"resident_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="lease_start_date"
                        type={"radio"}
                        id="201"
                        label={"lease_start_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="lease_end_date"
                        type={"radio"}
                        id="202"
                        label={"lease_end_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="lease_expiry_reminders"
                        type={"radio"}
                        id="500"
                        label={"lease_expiry_reminders"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="person_name"
                        type={"radio"}
                        id="203"
                        label={"person_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="person_email"
                        type={"radio"}
                        id="204"
                        label={"person_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="person_phone_number"
                        type={"radio"}
                        id="205"
                        label={"person_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="property_email"
                        type={"radio"}
                        id="206"
                        label={"property_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="property_phone_number"
                        type={"radio"}
                        id="207"
                        label={"property_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Reservations</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="checkin_date"
                        type={"radio"}
                        id="21"
                        label={"checkin_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="checkout_date"
                        type={"radio"}
                        id="22"
                        label={"checkout_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="checkin_time"
                        type={"radio"}
                        id="23"
                        label={"checkin_time"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="checkout_time"
                        type={"radio"}
                        id="24"
                        label={"checkout_time"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="arrival_instruction"
                        type={"radio"}
                        id="253"
                        label={"arrival_instruction"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="property_address"
                        type={"radio"}
                        id="232"
                        label={"property_address"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="property_name"
                        type={"radio"}
                        id="253"
                        label={"property_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="guest_paid"
                        type={"radio"}
                        id="26"
                        label={"guest_paid"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="guest_access_code"
                        type={"radio"}
                        id="262"
                        label={"guest_access_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="confirmation_code"
                        type={"radio"}
                        id="27"
                        label={"confirmation_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="nightly_rate"
                        type={"radio"}
                        id="28"
                        label={"nightly_rate"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="total_night"
                        type={"radio"}
                        id="29"
                        label={"total_night"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="total_nightly_rate"
                        type={"radio"}
                        id="30"
                        label={"total_nightly_rate"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="cleaning_fee"
                        type={"radio"}
                        id="31"
                        label={"cleaning_fee"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="guest_service_fee"
                        type={"radio"}
                        id="32"
                        label={"guest_service_fee"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="resident_earning"
                        type={"radio"}
                        id="33"
                        label={"resident_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="oh_earning"
                        type={"radio"}
                        id="34"
                        label={"oh_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="property_earning"
                        type={"radio"}
                        id="35"
                        label={"property_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="channel_earning"
                        type={"radio"}
                        id="36"
                        label={"channel_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="resident_payout"
                        type={"radio"}
                        id="37"
                        label={"resident_payout"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="property_payout"
                        type={"radio"}
                        id="38"
                        label={"property_payout"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br></br>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.openListingPopup()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.insertsubject}
                disabled={this.state.subject_variable ? false : true}
              >
                {this.state.isSubmit ? global.loader : "Insert"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.bodyPopup}
          className="showquickreplyModal model-temp_var"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.openBodyPopup()}>
              {global.closee}
            </span>
            <h3 className="text-center">Insert Body Variable</h3>
            <div className="row col-12 m-0">
              <div className="var_tmp_modal  mg-t-10">
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">
                    Status(Tabular list)
                  </h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="key_status"
                        type={"radio"}
                        id="1"
                        label={"key_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="cleaning_status"
                        type={"radio"}
                        id="2"
                        label={"cleaning_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="guest_email_status"
                        type={"radio"}
                        id="3"
                        label={"guest_email_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="new_reviews"
                        type={"radio"}
                        id="4"
                        label={"new_reviews"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="newly_sign_lease"
                        type={"radio"}
                        id="90"
                        label={"newly_sign_lease"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="expiring_lease"
                        type={"radio"}
                        id="91"
                        label={"expiring_lease"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="reservation_download"
                        type={"radio"}
                        id="92"
                        label={"reservation_download"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="building_onboarding"
                        type={"radio"}
                        id="9237892"
                        label={"building_onboarding"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Weekly Stats</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="total_nights_booked"
                        type={"radio"}
                        id="1111"
                        label={"total_nights_booked"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="upcoming_week_reservations"
                        type={"radio"}
                        id="1112"
                        label={"upcoming_week_reservations"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="upcoming_reservations"
                        type={"radio"}
                        id="1113"
                        label={"upcoming_reservations"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="total_revenue"
                        type={"radio"}
                        id="1114"
                        label={"total_revenue"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="upcoming_revenue"
                        type={"radio"}
                        id="1190"
                        label={"upcoming_revenue"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="this_week_booking"
                        type={"radio"}
                        id="1191"
                        label={"this_week_booking"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="last_week_booking"
                        type={"radio"}
                        id="1192"
                        label={"last_week_booking"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="upcoming_week_booking"
                        type={"radio"}
                        id="1198"
                        label={"upcoming_week_booking"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Cleaning</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="cleaning_access_code"
                        type={"radio"}
                        id="39"
                        label={"cleaning_access_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="cleaning_status"
                        type={"radio"}
                        id="40"
                        label={"cleaning_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="cleaning_project_code"
                        type={"radio"}
                        id="4013"
                        label={"cleaning_project_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="cleaner_name"
                        type={"radio"}
                        id="4044"
                        label={"cleaner_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="cleaning_schedule"
                        type={"radio"}
                        id="4020"
                        label={"cleaning_schedule"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="cleaner_contact_name"
                        type={"radio"}
                        id="ywtgbdhdhdh"
                        label={"cleaner_contact_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Guest</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="guest_first_name"
                        type={"radio"}
                        id="5"
                        label={"guest_first_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="guest_last_name"
                        type={"radio"}
                        id="6"
                        label={"guest_last_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="guest_email"
                        type={"radio"}
                        id="7"
                        label={"guest_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="guest_phone_number"
                        type={"radio"}
                        id="8"
                        label={"guest_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Onboarding</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="onboarding_link"
                        type={"radio"}
                        id="hhgfty6545"
                        label={"onboarding_link"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="last_login"
                        type={"radio"}
                        id="louybbdfhj"
                        label={"last_login"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Listing</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="listing_name"
                        type={"radio"}
                        id="9"
                        label={"listing_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="unit_name"
                        type={"radio"}
                        id="10"
                        label={"unit_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="bedroom"
                        type={"radio"}
                        id="11"
                        label={"bedroom"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="bathroom"
                        type={"radio"}
                        id="12"
                        label={"bathroom"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="parking_space"
                        type={"radio"}
                        id="132"
                        label={"parking_space"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="parking_slot"
                        type={"radio"}
                        id="13"
                        label={"parking_slot"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="wifi_name"
                        type={"radio"}
                        id="14"
                        label={"wifi_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="wifi_password"
                        type={"radio"}
                        id="15"
                        label={"wifi_password"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="guest_manual"
                        type={"radio"}
                        id="16"
                        label={"guest_manual"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Resident</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="resident_first_name"
                        type={"radio"}
                        id="17"
                        label={"resident_first_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="resident_last_name"
                        type={"radio"}
                        id="18"
                        label={"resident_last_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="resident_phone_number"
                        type={"radio"}
                        id="19"
                        label={"resident_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="resident_checkin"
                        type={"radio"}
                        id="3025598"
                        label={"resident_checkin"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="resident_checkout"
                        type={"radio"}
                        id="8998547"
                        label={"resident_checkout"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="resident_email"
                        type={"radio"}
                        id="20"
                        label={"resident_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="lease_start_date"
                        type={"radio"}
                        id="201"
                        label={"lease_start_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="lease_end_date"
                        type={"radio"}
                        id="202"
                        label={"lease_end_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="lease_expiry_reminders"
                        type={"radio"}
                        id="500"
                        label={"lease_expiry_reminders"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="person_name"
                        type={"radio"}
                        id="203"
                        label={"person_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="person_email"
                        type={"radio"}
                        id="204"
                        label={"person_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="person_phone_number"
                        type={"radio"}
                        id="205"
                        label={"person_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="property_email"
                        type={"radio"}
                        id="206"
                        label={"property_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="property_phone_number"
                        type={"radio"}
                        id="207"
                        label={"property_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="turno_account_id"
                        type={"radio"}
                        id="2073456"
                        label={"turno_account_id"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Payout</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="resident_name"
                        type={"radio"}
                        id="501"
                        label={"resident_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="unit_name"
                        type={"radio"}
                        id="502"
                        label={"unit_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="property"
                        type={"radio"}
                        id="503"
                        label={"property"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="payout_amount"
                        type={"radio"}
                        id="504"
                        label={"payout_amount"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="payout_duration"
                        type={"radio"}
                        id="505"
                        label={"payout_duration"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="payout_start_date"
                        type={"radio"}
                        id="505hytcdg"
                        label={"payout_start_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="payout_end_date"
                        type={"radio"}
                        id="505poutbbjh"
                        label={"payout_end_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Access Support</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="notes"
                        type={"radio"}
                        id="356"
                        label={"notes"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="user_name"
                        type={"radio"}
                        id="357"
                        label={"user_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="latch_log"
                        type={"radio"}
                        id="358"
                        label={"latch_log"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="access_code"
                        type={"radio"}
                        id="359"
                        label={"access_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="lock_type"
                        type={"radio"}
                        id="360"
                        label={"lock_type"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="user_phone"
                        type={"radio"}
                        id="361"
                        label={"user_phone"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Reservations</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="checkin_date"
                        type={"radio"}
                        id="21"
                        label={"checkin_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="checkout_date"
                        type={"radio"}
                        id="22"
                        label={"checkout_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="checkin_time"
                        type={"radio"}
                        id="23"
                        label={"checkin_time"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="checkout_time"
                        type={"radio"}
                        id="24"
                        label={"checkout_time"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="arrival_instruction"
                        type={"radio"}
                        id="25"
                        label={"arrival_instruction"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="property_address"
                        type={"radio"}
                        id="232"
                        label={"property_address"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="city"
                        type={"radio"}
                        id="232113123"
                        label={"city"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="property_name"
                        type={"radio"}
                        id="253"
                        label={"property_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="guest_paid"
                        type={"radio"}
                        id="26"
                        label={"guest_paid"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="guest_access_code"
                        type={"radio"}
                        id="262"
                        label={"guest_access_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="confirmation_code"
                        type={"radio"}
                        id="27"
                        label={"confirmation_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="nightly_rate"
                        type={"radio"}
                        id="28"
                        label={"nightly_rate"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="total_night"
                        type={"radio"}
                        id="29"
                        label={"total_night"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="total_nightly_rate"
                        type={"radio"}
                        id="30"
                        label={"total_nightly_rate"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="cleaning_fee"
                        type={"radio"}
                        id="31"
                        label={"cleaning_fee"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="guest_service_fee"
                        type={"radio"}
                        id="32"
                        label={"guest_service_fee"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="resident_earning"
                        type={"radio"}
                        id="33"
                        label={"resident_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="oh_earning"
                        type={"radio"}
                        id="34"
                        label={"oh_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="property_earning"
                        type={"radio"}
                        id="35"
                        label={"property_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="channel_earning"
                        type={"radio"}
                        id="36"
                        label={"channel_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="resident_payout"
                        type={"radio"}
                        id="37"
                        label={"resident_payout"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="property_payout"
                        type={"radio"}
                        id="38"
                        label={"property_payout"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="key_cafe_code"
                        type={"radio"}
                        id="380mhft8"
                        label={"key_cafe_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="latch_door_code"
                        type={"radio"}
                        id="sseuljs38"
                        label={"latch_door_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="body_variable"
                        value="latch_laundry_code"
                        type={"radio"}
                        id="3hhdjvfh8"
                        label={"latch_laundry_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br></br>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.openBodyPopup()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.insertbodyemail}
                disabled={this.state.body_variable ? false : true}
              >
                {this.state.isSubmit ? global.loader : "Insert"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.appbodyPopup}
          className="showquickreplyModal model-temp_var"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.openAppBodyPopup()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Insert App Body Variable</h3>
            <div className="row col-12 m-0">
              <div className="var_tmp_modal  mg-t-10">
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">
                    Status(Tabular list)
                  </h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="key_status"
                        type={"radio"}
                        id="1"
                        label={"key_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="cleaning_status"
                        type={"radio"}
                        id="2"
                        label={"cleaning_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="guest_email_status"
                        type={"radio"}
                        id="3"
                        label={"guest_email_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="new_reviews"
                        type={"radio"}
                        id="4"
                        label={"new_reviews"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="newly_sign_lease"
                        type={"radio"}
                        id="90"
                        label={"newly_sign_lease"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="expiring_lease"
                        type={"radio"}
                        id="91"
                        label={"expiring_lease"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Cleaning</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="cleaning_access_code"
                        type={"radio"}
                        id="39"
                        label={"cleaning_access_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="cleaning_status"
                        type={"radio"}
                        id="40"
                        label={"cleaning_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="cleaner_contact_name"
                        type={"radio"}
                        id="qwertvbj"
                        label={"cleaner_contact_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Guest</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="guest_first_name"
                        type={"radio"}
                        id="5"
                        label={"guest_first_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="guest_last_name"
                        type={"radio"}
                        id="6"
                        label={"guest_last_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="guest_email"
                        type={"radio"}
                        id="7"
                        label={"guest_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="guest_phone_number"
                        type={"radio"}
                        id="8"
                        label={"guest_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Payout</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="resident_name"
                        type={"radio"}
                        id="501"
                        label={"resident_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="unit_name"
                        type={"radio"}
                        id="502"
                        label={"unit_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="property"
                        type={"radio"}
                        id="503"
                        label={"property"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="payout_amount"
                        type={"radio"}
                        id="504"
                        label={"payout_amount"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="payout_duration"
                        type={"radio"}
                        id="505"
                        label={"payout_duration"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="payout_start_date"
                        type={"radio"}
                        id="505hytcdaa"
                        label={"payout_start_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="payout_end_date"
                        type={"radio"}
                        id="505poutbbpo"
                        label={"payout_end_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Listing</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="listing_name"
                        type={"radio"}
                        id="9"
                        label={"listing_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="unit_name"
                        type={"radio"}
                        id="10"
                        label={"unit_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="bedroom"
                        type={"radio"}
                        id="11"
                        label={"bedroom"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="bathroom"
                        type={"radio"}
                        id="12"
                        label={"bathroom"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="parking_space"
                        type={"radio"}
                        id="132"
                        label={"parking_space"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="parking_slot"
                        type={"radio"}
                        id="13"
                        label={"parking_slot"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="wifi_name"
                        type={"radio"}
                        id="14"
                        label={"wifi_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="wifi_password"
                        type={"radio"}
                        id="15"
                        label={"wifi_password"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="guest_manual"
                        type={"radio"}
                        id="16"
                        label={"guest_manual"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Resident</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="resident_first_name"
                        type={"radio"}
                        id="17"
                        label={"resident_first_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="resident_last_name"
                        type={"radio"}
                        id="18"
                        label={"resident_last_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="resident_phone_number"
                        type={"radio"}
                        id="19"
                        label={"resident_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="resident_checkin"
                        type={"radio"}
                        id="191234"
                        label={"resident_checkin"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="resident_checkout"
                        type={"radio"}
                        id="19353"
                        label={"resident_checkout"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="resident_email"
                        type={"radio"}
                        id="20"
                        label={"resident_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="lease_start_date"
                        type={"radio"}
                        id="201"
                        label={"lease_start_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="lease_end_date"
                        type={"radio"}
                        id="202"
                        label={"lease_end_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="lease_expiry_reminders"
                        type={"radio"}
                        id="500"
                        label={"lease_expiry_reminders"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="person_name"
                        type={"radio"}
                        id="203"
                        label={"person_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="person_email"
                        type={"radio"}
                        id="204"
                        label={"person_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="person_phone_number"
                        type={"radio"}
                        id="205"
                        label={"person_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="property_email"
                        type={"radio"}
                        id="206"
                        label={"property_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="property_phone_number"
                        type={"radio"}
                        id="207"
                        label={"property_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="turno_account_id"
                        type={"radio"}
                        id="2070987"
                        label={"turno_account_id"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Reservations</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="checkin_date"
                        type={"radio"}
                        id="21"
                        label={"checkin_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="checkout_date"
                        type={"radio"}
                        id="22"
                        label={"checkout_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="checkin_time"
                        type={"radio"}
                        id="23"
                        label={"checkin_time"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="checkout_time"
                        type={"radio"}
                        id="24"
                        label={"checkout_time"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="arrival_instruction"
                        type={"radio"}
                        id="25"
                        label={"arrival_instruction"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="property_address"
                        type={"radio"}
                        id="232"
                        label={"property_address"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="city"
                        type={"radio"}
                        id="646748n"
                        label={"city"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="property_name"
                        type={"radio"}
                        id="253"
                        label={"property_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="guest_paid"
                        type={"radio"}
                        id="26"
                        label={"guest_paid"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="guest_access_code"
                        type={"radio"}
                        id="262"
                        label={"guest_access_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="confirmation_code"
                        type={"radio"}
                        id="27"
                        label={"confirmation_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="nightly_rate"
                        type={"radio"}
                        id="28"
                        label={"nightly_rate"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="total_night"
                        type={"radio"}
                        id="29"
                        label={"total_night"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="total_nightly_rate"
                        type={"radio"}
                        id="30"
                        label={"total_nightly_rate"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="cleaning_fee"
                        type={"radio"}
                        id="31"
                        label={"cleaning_fee"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="guest_service_fee"
                        type={"radio"}
                        id="32"
                        label={"guest_service_fee"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="resident_earning"
                        type={"radio"}
                        id="33"
                        label={"resident_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="oh_earning"
                        type={"radio"}
                        id="34"
                        label={"oh_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="property_earning"
                        type={"radio"}
                        id="35"
                        label={"property_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="channel_earning"
                        type={"radio"}
                        id="36"
                        label={"channel_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="resident_payout"
                        type={"radio"}
                        id="37"
                        label={"resident_payout"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="property_payout"
                        type={"radio"}
                        id="38"
                        label={"property_payout"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="key_cafe_code"
                        type={"radio"}
                        id="380mhftgdgd8"
                        label={"key_cafe_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="latch_door_code"
                        type={"radio"}
                        id="ssdddeuljs38"
                        label={"latch_door_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_variable"
                        value="latch_laundry_code"
                        type={"radio"}
                        id="3hhdqwejvfh8"
                        label={"latch_laundry_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br></br>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.openAppBodyPopup()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.insertappmessage}
                disabled={this.state.app_body_variable ? false : true}
              >
                {this.state.isSubmit ? global.loader : "Insert"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.appnotifybodyPopup}
          className="showquickreplyModal model-temp_var"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.openAppNotificaionPopup()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">
              Insert App Notification Body Variable
            </h3>
            <div className="row col-12 m-0">
              <div className="var_tmp_modal  mg-t-10">
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">
                    Status(Tabular list)
                  </h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="key_status"
                        type={"radio"}
                        id="1"
                        label={"key_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="cleaning_status"
                        type={"radio"}
                        id="2"
                        label={"cleaning_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="guest_email_status"
                        type={"radio"}
                        id="3"
                        label={"guest_email_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="new_reviews"
                        type={"radio"}
                        id="4"
                        label={"new_reviews"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="newly_sign_lease"
                        type={"radio"}
                        id="90"
                        label={"newly_sign_lease"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="expiring_lease"
                        type={"radio"}
                        id="91"
                        label={"expiring_lease"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Cleaning</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="cleaning_access_code"
                        type={"radio"}
                        id="39"
                        label={"cleaning_access_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="cleaning_status"
                        type={"radio"}
                        id="40"
                        label={"cleaning_status"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Guest</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="guest_first_name"
                        type={"radio"}
                        id="5"
                        label={"guest_first_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="guest_last_name"
                        type={"radio"}
                        id="6"
                        label={"guest_last_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="guest_email"
                        type={"radio"}
                        id="7"
                        label={"guest_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="guest_phone_number"
                        type={"radio"}
                        id="8"
                        label={"guest_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Payout</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="resident_name"
                        type={"radio"}
                        id="501"
                        label={"resident_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="unit_name"
                        type={"radio"}
                        id="502"
                        label={"unit_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="property"
                        type={"radio"}
                        id="503"
                        label={"property"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="payout_amount"
                        type={"radio"}
                        id="504"
                        label={"payout_amount"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="payout_duration"
                        type={"radio"}
                        id="505"
                        label={"payout_duration"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Listing</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="listing_name"
                        type={"radio"}
                        id="9"
                        label={"listing_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="unit_name"
                        type={"radio"}
                        id="10"
                        label={"unit_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="bedroom"
                        type={"radio"}
                        id="11"
                        label={"bedroom"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="bathroom"
                        type={"radio"}
                        id="12"
                        label={"bathroom"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="parking_space"
                        type={"radio"}
                        id="132"
                        label={"parking_space"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="parking_slot"
                        type={"radio"}
                        id="13"
                        label={"parking_slot"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="wifi_name"
                        type={"radio"}
                        id="14"
                        label={"wifi_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="wifi_password"
                        type={"radio"}
                        id="15"
                        label={"wifi_password"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="guest_manual"
                        type={"radio"}
                        id="16"
                        label={"guest_manual"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Resident</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="resident_first_name"
                        type={"radio"}
                        id="17"
                        label={"resident_first_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="resident_last_name"
                        type={"radio"}
                        id="18"
                        label={"resident_last_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="resident_phone_number"
                        type={"radio"}
                        id="19"
                        label={"resident_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="resident_phone_number"
                        type={"radio"}
                        id="19"
                        label={"resident_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="resident_checkin"
                        type={"radio"}
                        id="1963578"
                        label={"resident_checkin"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="resident_checkout"
                        type={"radio"}
                        id="2015698"
                        label={"resident_checkout"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="resident_email"
                        type={"radio"}
                        id="20"
                        label={"resident_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="lease_start_date"
                        type={"radio"}
                        id="201"
                        label={"lease_start_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="lease_end_date"
                        type={"radio"}
                        id="202"
                        label={"lease_end_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="subject_variable"
                        value="lease_expiry_reminders"
                        type={"radio"}
                        id="500"
                        label={"lease_expiry_reminders"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="person_name"
                        type={"radio"}
                        id="203"
                        label={"person_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="person_email"
                        type={"radio"}
                        id="204"
                        label={"person_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="person_phone_number"
                        type={"radio"}
                        id="205"
                        label={"person_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="property_email"
                        type={"radio"}
                        id="206"
                        label={"property_email"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="property_phone_number"
                        type={"radio"}
                        id="207"
                        label={"property_phone_number"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="turno_account_id"
                        type={"radio"}
                        id="20770345"
                        label={"turno_account_id"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="var_tmp_box">
                  <h4 className="text-left var_pop_head">Reservations</h4>
                  <ul>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="checkin_date"
                        type={"radio"}
                        id="21"
                        label={"checkin_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="checkout_date"
                        type={"radio"}
                        id="22"
                        label={"checkout_date"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="checkin_time"
                        type={"radio"}
                        id="23"
                        label={"checkin_time"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="checkout_time"
                        type={"radio"}
                        id="24"
                        label={"checkout_time"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="arrival_instruction"
                        type={"radio"}
                        id="25"
                        label={"arrival_instruction"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="property_address"
                        type={"radio"}
                        id="232"
                        label={"property_address"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="property_name"
                        type={"radio"}
                        id="253"
                        label={"property_name"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="guest_paid"
                        type={"radio"}
                        id="26"
                        label={"guest_paid"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="guest_access_code"
                        type={"radio"}
                        id="262"
                        label={"guest_access_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="confirmation_code"
                        type={"radio"}
                        id="27"
                        label={"confirmation_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="nightly_rate"
                        type={"radio"}
                        id="28"
                        label={"nightly_rate"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="total_night"
                        type={"radio"}
                        id="29"
                        label={"total_night"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="total_nightly_rate"
                        type={"radio"}
                        id="30"
                        label={"total_nightly_rate"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="cleaning_fee"
                        type={"radio"}
                        id="31"
                        label={"cleaning_fee"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="guest_service_fee"
                        type={"radio"}
                        id="32"
                        label={"guest_service_fee"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="resident_earning"
                        type={"radio"}
                        id="33"
                        label={"resident_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="oh_earning"
                        type={"radio"}
                        id="34"
                        label={"oh_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="property_earning"
                        type={"radio"}
                        id="35"
                        label={"property_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="channel_earning"
                        type={"radio"}
                        id="36"
                        label={"channel_earning"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="resident_payout"
                        type={"radio"}
                        id="37"
                        label={"resident_payout"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="property_payout"
                        type={"radio"}
                        id="38"
                        label={"property_payout"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="key_cafe_code"
                        type={"radio"}
                        id="380mh1233ft8"
                        label={"key_cafe_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="latch_door_code"
                        type={"radio"}
                        id="sse7555uljs38"
                        label={"latch_door_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                    <li>
                      <Form.Check
                        onChange={this.changeHandler}
                        name="app_body_notification_variable"
                        value="latch_laundry_code"
                        type={"radio"}
                        id="3hhd123547890jvfh8"
                        label={"latch_laundry_code"}
                        className={"rdiobox mg-r-20"}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br></br>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.openAppNotificaionPopup()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.insertappnotification}
                disabled={
                  this.state.app_body_notification_variable ? false : true
                }
              >
                {this.state.isSubmit ? global.loader : "Insert"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default TemplateForm;
