////////////////////////////////////////////////////////////
//     							                          //
//  Program: ResetPassword.jsx                            //
//  Application: authentication                           //
//  Option: For Reset Password into project               //
//  Developer: NP  						                            //
//  Date: 2021-12-22                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import AuthenticationService from '../../services/AuthenticationService'
import SimpleReactValidator from 'simple-react-validator'
import { Redirect, Link } from 'react-router-dom'
import BackButton from "../../assets/images/back-button.svg"
import Alerts from '../common/Alerts'

class ResetPassword extends Component {
  constructor(props) {
    super(props)

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: '',
      alertModalTitle: '',
      alertModalMessage: '',
      resetToken: '',
      newPassword: '',
      confirmNewPassword: '',
      token: this.props.match.params.token
    }

    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  resetPassword = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) { // Validate all fields before data submission
      this.setState({ isSubmit: true });
      let input = {
        new_password: this.state.newPassword,
        password_confirmation: this.state.confirmNewPassword,
        reset_token: this.state.token
      }
      let res = await AuthenticationService.resetPassword(input);
      if ((global.successStatus).includes(res.status)) {
        this.setState({ showAlertModal: true, alertModalType: 'success', alertModalTitle: 'Success', alertModalMessage: res.message ? res.message : 'Success' });
        setTimeout(() => this.props.history.push('/signin'), global.redirect_time);
      } else if (res.status === 400) {
        this.setState({ showAlertModal: true, alertModalType: 'error', alertModalTitle: 'Error', alertModalMessage: res.data.message ? res.data.message : 'Error' });
      } else {
        if (res.data.result.errorDetail !== null && res.data.result.errorDetail !== undefined) {
          let errors = {};
          res.data.errorCode = 'Validation Failed';
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = '';
          })
          this.setState({ errors: errors });
        }
        this.setState({ showAlertModal: true, alertModalType: 'error', alertModalTitle: (res.data.status) ? res.data.status : 'Error!', alertModalMessage: (res.data.message) ? res.data.message : 'Error!' });
      }
      this.setState({ isSubmit: false });
      setTimeout(() => this.setState({ showAlertModal: false }), global.alert_close_time);
    } else {
      this.validator.showMessages();
    }
  }

  changeHandler = (event) => {
    var errors = this.state.errors;
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === 'confirmNewPassword' && event.target.value.length >= 6) {
      var pw = event.target.name === 'newPassword' ? event.target.value : this.state.newPassword
      var cpw = event.target.name === 'confirmNewPassword' ? event.target.value : this.state.confirmNewPassword

      if (pw !== cpw) {
        errors['confirmNewPassword'] = "Password and confirm password must be same!";
        this.setState({ errors: errors });
      } else {
        errors['confirmNewPassword'] = "";
        this.setState({ errors: errors });
      }
    }

    // Validate
    this.validator.showMessageFor(event.target.name);
  }

  getTitle() {
    return process.env.REACT_APP_PROJECT_TITLE ? process.env.REACT_APP_PROJECT_TITLE : '';
  }

  render() {
    // Redirect to 2FA page if reset token not set
    if (!this.state.token) {
      return <Redirect to='/forgot-password' />;
    }
    return (
      <div>
        <div className="az-signin-wrapper">
          <div className="az-card-signin">
            <div className="az-logo d-flex flex-column">
              <img src={require("../../assets/images/ohai-logo.svg").default} alt=""></img>
              <h2>Welcome To OHAI.</h2>
            </div>
            <div className="az-signin-header">
              <h3 className='text-center'>Set Password</h3>
              <p className='text-center p-dark'>Please set a new password here.</p>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts show={this.state.showAlertModal} type={this.state.alertModalType} title={this.state.alertModalTitle} message={this.state.alertModalMessage} />
              </div>
              <form action="#/">
                <div className="form-group">
                  {/* <label>New Password</label> */}
                  <input type="password" name="newPassword" className="form-control" placeholder="New password"
                    value={this.state.password} onChange={this.changeHandler} maxLength="25" />

                  {this.validator.message('newPassword', this.state.newPassword, 'required|min:6', { className: 'text-danger' })}
                </div>
                <div className="form-group">
                  {/* <label>Confirm Password</label> */}
                  <input type="password" name="confirmNewPassword" className="form-control" placeholder="Confirm new password"
                    value={this.state.password} onChange={this.changeHandler} maxLength="25" />

                  {(this.state.errors.confirmNewPassword) ? <div className="text-danger">{this.state.errors.confirmNewPassword}</div> : this.validator.message('confirmNewPassword', this.state.confirmNewPassword, 'required|min:6', { className: 'text-danger' })}
                </div>
                <button className="btn btn-info btn-block button-green" onClick={this.resetPassword} disabled={this.state.isSubmit ? true : false}>{this.state.isSubmit ? global.loader : "Submit"}</button>

                <div className="az-signin-footer text-center mt-3">
                  <p><Link to="/signin"><img src={BackButton} alt="" className="back-button" /></Link><Link to="/signin">Back to Login</Link></p>
                </div>
              </form>

            </div>
          </div>
          <div className='az-copyright text-center'>
            <p>© {global.current_year} OHAI, Inc. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default ResetPassword
